import { useEffect } from "react";
import {
    Col,
    Form,
    Input,
    InputNumber,
    Row,
    Select,
    Button,
    Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
    PaymentGatewayFeeTypeEnums,
    PaymentGatewayIdentifierEnums,
    PaymentGatewayStatusEnums,
    PaymentGatewayTypeEnums,
} from "@/enums/paymentGatewayCollectionEnums";
import { PaymentGatewayModel } from "@/models/paymentGatewayCollectionModel";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import { SingleChunkUpload } from "@/components/SingleChunkUpload";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { IPaymentGatewayFormData } from "@/types/paymentGatewayCollection";

interface IProps {
    data?: PaymentGatewayModel;
    form: any;
    onFinish: (values: IPaymentGatewayFormData) => Promise<void>;
}

export const PaymentGatewayModification = ({
    data,
    form,
    onFinish,
}: IProps) => {
    const {
        allOptionsData: { currencyOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.CURRENCY);
    }, []);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                name: data.getName() || "",
                identifier: data.getIdentifier() || undefined,
                type: data.getType() || undefined,
                fee_type: data.getFeeType() || undefined,
                status: data.getStatus() || undefined,
                fee: data.getFee() || 0,
                small_logo:
                    data
                        .getLogo()
                        ?.getData()
                        .find((logo) => logo.getSmall()?.src)
                        ?.getSmall()?.src || "",
                medium_logo:
                    data
                        .getLogo()
                        ?.getData()
                        .find((logo) => logo.getMedium()?.src)
                        ?.getMedium()?.src || "",
                currencies:
                    data
                        .getCurrencies()
                        .getData()
                        .map((x) => x.getId()) || [],
                merchant_data: Object.entries(data.merchant_data || {}).map(
                    ([key, value]) => ({ key, value }),
                ),
            });
        } else {
            form.resetFields();
        }
    }, [data, currencyOptionsData, form]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            name="Add Payment Gateway"
        >
            <Row gutter={16}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Payment gateway name is required",
                            },
                        ]}
                    >
                        <Input placeholder="Payment gateway name" />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item
                        label="Currency"
                        name="currencies"
                        rules={[
                            { required: true, message: "Currency is required" },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            loading={currencyOptionsData.isLoading}
                            placeholder="Please select currencies"
                            options={currencyOptionsData.options}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        label="Identifier"
                        name="identifier"
                        rules={[
                            {
                                required: true,
                                message: "Identifier is required",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Please select an identifier"
                            options={transformEnumToLabeledValue(
                                PaymentGatewayIdentifierEnums,
                            ).map((x) => ({
                                ...x,
                                label: x.label.replace("_", " "),
                            }))}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} lg={12}>
                    <Form.Item
                        label="Type"
                        name="type"
                        rules={[
                            { required: true, message: "Type is required" },
                        ]}
                    >
                        <Select
                            placeholder="Please select a type"
                            options={transformEnumToLabeledValue(
                                PaymentGatewayTypeEnums,
                            ).map((x) => ({
                                ...x,
                                label: x.label.replace("_", " "),
                            }))}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} lg={12}>
                    <Form.Item
                        label="Fee Type"
                        name="fee_type"
                        rules={[
                            { required: true, message: "Fee type is required" },
                        ]}
                    >
                        <Select
                            placeholder="Please select a fee type"
                            options={transformEnumToLabeledValue(
                                PaymentGatewayFeeTypeEnums,
                            )}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item
                        label="Fee"
                        name="fee"
                        rules={[{ required: true, message: "Fee is required" }]}
                    >
                        <InputNumber
                            placeholder="Enter fee"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        label="Status"
                        name="status"
                        rules={[
                            { required: true, message: "Status is required" },
                        ]}
                    >
                        <Select
                            placeholder="Please select a status"
                            options={transformEnumToLabeledValue(
                                PaymentGatewayStatusEnums,
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="small_logo"
                        label="Small Size Logo"
                        rules={[
                            { required: true, message: "Logo is required" },
                        ]}
                    >
                        <SingleChunkUpload
                            form={form}
                            accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                            fieldName="small_logo"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="medium_logo"
                        label="Medium Size Logo"
                        rules={[
                            { required: true, message: "Logo is required" },
                        ]}
                    >
                        <SingleChunkUpload
                            form={form}
                            accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                            fieldName="medium_logo"
                        />
                    </Form.Item>
                </Col>
            </Row>

            {/* Merchant Data Dynamic Object Fields */}
            <Row gutter={16}>
                <Col span={24}>
                    <Form.List name="merchant_data">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: "flex",
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, "key"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Key is required",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter Key" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, "value"]}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Value is required",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter Value" />
                                        </Form.Item>
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                        />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Add Merchant Data
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
        </Form>
    );
};

export default PaymentGatewayModification;
