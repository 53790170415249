import { ITranslationEntry } from "../lang";

export const RU: ITranslationEntry = {
    translations: {
        notifications: "Уведомления",
        markAllAsRead: "Пометить все как прочитанные",
        poweredBy: "При поддержке",
        settings: "Настройки",
        noNewNotification: "Здесь пока ничего нового",
    },
    lang: "ru",
};
