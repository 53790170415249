import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import { CourierOptionResource } from "../../resources/private";

class CourierOptionService extends CommerceApiService {
    public courierOptionResource: CourierOptionResource;

    constructor() {
        super();
        this.courierOptionResource = new CourierOptionResource(this.config);
    }
}
export default CourierOptionService;
