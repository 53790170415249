export enum ExchangeListCollectionEnums {
    ExchangeListCollection = "ExchangeListCollection",
    ExchangeList = "ExchangeList",
}

export enum RegionalExchangeCollectionEnums {
    RegionalExchangeCollection = "RegionalExchangeCollection",
    RegionalExchange = "RegionalExchange",
}

export enum StoreExchangeCollectionEnums {
    StoreExchangeCollection = "StoreExchangeCollection",
    StoreExchange = "StoreExchange",
}

export enum CommissionRateTypeEnums {
    PERCENTAGE = "percentage",
    FIXED = "fixed",
}
