import {
    AgreeMendedPayload,
    ApprovePurchasePayload,
} from "@/lib/core-react/hooks/private/useDispute";
import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { IDisputeApprovedFormData } from "@/types/disputeDetailsCollection";
import { DisputeSettlementPreviewModel } from "@/models/disputeSettlementPreviewModel";

class DisputeResource extends BaseResource {
    discountsPath = `/api/discount/admin/discount/v1/discounts`;
    discountGroupPath = `/api/discount/admin/discount-group/v1/discount-groups`;
    discountRuleSchemaPath = `/api/discount/admin/discount-rule/v1/discounts-rule-schema`;
    discountUsageHistoryPath = `/api/discount/admin/discount-usage-history/v1/discount-usage-histories`;
    disputesPath = `/api/refund/admin/dispute/v1/disputes`;

    // Refund
    getDisputes(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.disputesPath}`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getDispute(
        id: number | string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.disputesPath}/${id}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getDisputePreview(
        id: number | string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.disputesPath}/${id}/purchase-approve-preview`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    cancelDispute(
        id: number | string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.disputesPath}/${id}/cancel`;
        return this.client.request("POST", path, undefined, {}, customHeaders);
    }

    approveDispute(
        id: number | string,
        payload: ApprovePurchasePayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.disputesPath}/${id}/approve-purchase`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    //{{commerceUrl}}/api/refund/admin/dispute/v1/disputes/:disputeId/agree-mandate
    agreeMandate(
        id: number | string,
        payload: AgreeMendedPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.disputesPath}/${id}/agree-mandate`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updateDispute(
        id: number,
        payload: IDisputeApprovedFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.disputesPath}/${id}/approve`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    //approve refund
    completeRefund(
        id: number | string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.disputesPath}/${id}/complete`;
        return this.client.request("POST", path, undefined, {}, customHeaders);
    }

    // get dispute settlement preview
    getDisputeSettlementPreview(
        id: number | string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<DisputeSettlementPreviewModel> {
        const path = `${this.disputesPath}/${id}/preview-settlement`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default DisputeResource;
