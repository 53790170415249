import { useAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import { addressCollectionAtom } from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import { useState } from "react";
import { CreateAddressRequestModel } from "@/models/buyPlaceOrderModel";
import { IDestinationWarehouseAddressForm } from "@/types/destinationWarehouseCollection";

// use this one when you need to pass params as object
export const useGetAddress = () => {
    const [addAddress, setAddAddress] = useAtom(addressCollectionAtom);
    const { addressService } = useService();
    const getAddress = async (params: Record<string, any>): Promise<void> => {
        setAddAddress({ ...addAddress, isLoading: true, error: null });
        try {
            const response = await addressService.addressResource.get(params);
            setAddAddress({
                ...addAddress,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setAddAddress({
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getAddress } as const;
};

// use this one when you need to pass params as string
export const useGetAddressV2 = () => {
    const [addresses, setAddresses] = useAtom(addressCollectionAtom);
    const { addressService } = useService();
    const getAddress = async (params?: string): Promise<void> => {
        setAddresses({ ...addresses, isLoading: true, error: null });
        try {
            const response =
                await addressService.addressResource.getAddress(params);
            setAddresses({
                ...addresses,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setAddresses({
                ...addresses,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };
    return { getAddress } as const;
};

export const useCreateAddress = () => {
    const { addressService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);

    const createAddress = async (
        user_id: number,
        country_id: string,
        payload: CreateAddressRequestModel,
    ) => {
        setIsLoading(true);
        try {
            const response = await addressService.addressResource.addAddress(
                user_id,
                country_id,
                payload,
            );
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createAddress, isLoading, isError };
};

export const useDwAddressCreate = () => {
    const { addressService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [addresses, setAddresses] = useAtom(addressCollectionAtom);
    const [isError, setError] = useState<string | null>(null);

    const createAddress = async (payload: IDestinationWarehouseAddressForm) => {
        setIsLoading(true);
        try {
            const response =
                await addressService.addressResource.addDwAddress(payload);
            setIsLoading(false);
            setAddresses({
                ...addresses,
                refetch: true,
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createAddress, isLoading, isError };
};

export const useDwAddressUpdate = () => {
    const { addressService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [addresses, setAddresses] = useAtom(addressCollectionAtom);

    const updateAddress = async (
        address_id: number,
        payload: IDestinationWarehouseAddressForm,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await addressService.addressResource.updateDwAddress(
                    address_id,
                    payload,
                );
            setIsLoading(false);
            setAddresses({ ...addresses, refetch: true });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateAddress, isLoading, isError };
};

export const useAddressDelete = () => {
    const { addressService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const deleteAddress = async (address_id: number) => {
        setIsLoading(true);
        try {
            const response =
                await addressService.addressResource.deleteAddress(address_id);
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
        }
    };
    return { deleteAddress, isLoading, isError };
};
