import { RegionEnum } from "@/enums/regionCollectionEnums";
import { IPagination } from "@/types/pagination";
import { IFilterType } from "@/types/filters";
import { ISelectOption } from "@/filters/Models/SelectOptionModel";
import { IRegionCollection } from "@/types/regionCollection";
import { RegionModel } from "./regionCollectionModel";

export class RegionCollectionModel {
    public readonly object: RegionEnum.RegionCollection;
    public readonly data: RegionModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;
    constructor(data: IRegionCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new RegionModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getSelectComponentSelectOptions(): ISelectOption[] {
        return this.data && this.data.length > 0
            ? this.data.map((item) => {
                  return { value: item.getId(), label: item.getName() };
              })
            : [];
    }
    getRegionIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.name));
        return obj;
    }
    getRegionCodesAsObject() {
        const obj: { [key: string]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.code));
        return obj;
    }
}
