import { ITranslationEntry } from "../lang";

export const BE: ITranslationEntry = {
    translations: {
        notifications: "Паведамленнi",
        markAllAsRead: "Пазначыць усе як прачытаныя",
        poweredBy: "Пры падтрымцы",
        settings: "Налады",
        noNewNotification: "Тут пакуль няма нічога новага",
    },
    lang: "be",
};
