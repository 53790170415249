import { ITranslationEntry } from "../lang";

export const PL: ITranslationEntry = {
    translations: {
        notifications: "Notyfikacje",
        markAllAsRead: "Oznacz wszystkie jako przeczytane",
        poweredBy: "Napędzany przez",
        settings: "Ustawienia",
        noNewNotification: "Nie ma tu jeszcze nic nowego do zobaczenia",
    },
    lang: "pl",
};
