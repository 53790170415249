import { Button, Form, FormInstance, Select } from "antd";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { ShippingCountryDataStatus } from "@/enums/shippingCountryCollectionEnums";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { useCreateShippingCountry } from "@/lib/core-react/hooks/private/useShippingCountry";

import React, { useEffect } from "react";
import { showError } from "@/helpers/showError";
import { ShippingCountryPostRequestModel } from "@/models/shippingCountryCollectionModel";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
    onHandleRefetch: () => void;
}

const CreateShippingCountry = ({
    setIsShowCreateModal,
    onHandleRefetch,
}: IProps) => {
    const { createShippingCountry, isLoading } = useCreateShippingCountry();

    const {
        allOptionsData: { regionOptionsData, countryOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData({});
    const formRef: React.Ref<FormInstance<any>> = React.createRef();

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.COUNTRY);
    }, []);

    const onFinish = async (value: ShippingCountryPostRequestModel) => {
        const payload = {
            status: value.status,
            country_id: value.country_id,
            region_id: value.region_id,
        };
        try {
            await createShippingCountry(
                payload as ShippingCountryPostRequestModel,
            );
            setIsShowCreateModal(false);
            onHandleRefetch();
        } catch (error) {
            showError(error, form);
        }
    };
    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Create_shipping_country"
                    ref={formRef}
                    style={{
                        margin: "0px 10px",
                        padding: "15px 10px 0px 10px",
                    }}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Region ID is required",
                            },
                        ]}
                        name="region_id"
                        label="Region"
                    >
                        <Select
                            allowClear
                            placeholder="Please select a Region"
                            onClick={() =>
                                onFetchFilterApi(filterResourceEnum.REGION)
                            }
                            onClear={() =>
                                onFetchFilterApi(filterResourceEnum.REGION)
                            }
                            options={regionOptionsData.options}
                            loading={regionOptionsData.isLoading}
                            notFoundContent={
                                regionOptionsData.isLoading ? LoaderSmall : null
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Country id is required",
                            },
                        ]}
                        name="country_id"
                        label="Shipping Country"
                    >
                        <Select
                            allowClear
                            placeholder="Please select a country"
                            options={countryOptionsData.options}
                            loading={countryOptionsData.isLoading}
                            notFoundContent={
                                countryOptionsData.isLoading
                                    ? LoaderSmall
                                    : null
                            }
                        />
                    </Form.Item>

                    <Form.Item required name="status" label="Status">
                        <Select placeholder="Please select a status">
                            {renderOptionsFromEnum(ShippingCountryDataStatus)}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default CreateShippingCountry;
