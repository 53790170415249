export enum filterResourceEnum {
    LABEL = "label",
    COUNTRY = "country",
    LANGUAGE = "language",
    USER = "user",
    AGENT_WAREHOUSE = "agentWarehouse",
    BUY_PRODUCTS = "buyProducts",
    DESTINATION_COUNTRY = "destinationCountry",
    DESTINATION_WAREHOUSE = "destinationWarehouse",
    DESTINATION_WAREHOUSE_2 = "destination-warehouse", // FIXME should remove after thread solve
    COURIER_OPTIONS = "courierOptions",
    ADDRESS = "address",
    DELIVERY_REQUESTS = "deliveryRequests",
    CUSTOMER = "customer",
    REGION = "region",
    DISCOUNT_GROUPS = "discountGroups",
    DISCOUNT = "discount",
    GATEWAY = "gateway",
    GATEWAY_TRANSACTION = "gatewayTransaction",
    CURRENCY = "currency",
    ADMIN = "admin",
    SHIPPING_COUNTRY = "shippingCountry",
    AGENT_COMPANY = "agentCompany",
    STORE = "store",
    BASE_SHIPPING_CATEGORY = "baseShippingCategory",
    WAREHOUSE = "warehouse",
    SHIPPING_CATEGORY = "shippingCategory",
    SHIPPING_CATEGORY_2 = "shipping-category", // FIXME should remove after thread solve
    WAREHOUSE_SHIPMENT = "warehouseShipment",
    SHIPMENT_PRODUCT = "shipmentProduct",
    SHIPMENT_PRODUCT_PACKAGE = "shipmentProductPackage",
    AP_CONFIGURATION = "apConfiguration",
    AGENT_ROLE = "agentRole",
    EXCHANGE_LIST = "exchangeList",
    EXCHANGE_GROUP = "exchangeGroup",
    PAYOUT_GATEWAY = "payoutGateway",
    PAYOUT_ACCOUNT = "payoutAccount",
    WALLET = "wallet",
    ORDER = "order",
    STRAPI_SLUG_CAMPAIGN = "STRAPI_SLUG_CAMPAIGN",
    ADDON_SERVICE_LIST = "ADDON_SERVICE_LIST",
    ORDER_HANDLER = "orderHandler",
}
