import React, { useEffect, createRef } from "react";
import { Button, Form, Input, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useCreateAdmin } from "@/lib/core-react/hooks/private/useAdminUser";
import { IUserCredentials } from "@/types/userCollection";
import { useAtom } from "jotai";
import { roleAtom } from "@/lib/core-react/store/store";
import { RoleCollectionModel } from "@/models/roleCollectionModel";
import { showError } from "@/helpers/showError";
import { useGetRole } from "@/lib/core-react/hooks/private";
import { responseTypeQuery } from "@/filters/constant";
import QueryString from "qs";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateAdminUser = ({ setIsShowCreateModal }: IProps) => {
    const { createAdmin, isLoading } = useCreateAdmin();
    const [{ data: roleData }] = useAtom(roleAtom);
    const { getRole } = useGetRole();

    const RoleData = roleData && new RoleCollectionModel(roleData);

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        getRole(QueryString.stringify(responseTypeQuery.minimal));
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value: IUserCredentials) => {
        try {
            await createAdmin(value);
            setIsShowCreateModal(false);
            notification["success"]({
                message: "Create Admin User successfully",
            });
        } catch (error) {
            showError(error, form);
        }
    };

    // const onValuesChange = (changedValues: any) => {
    //   Forms.formValueChange(form, changedValues);
    // };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Create Admin User"
            ref={formRef}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "User name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="User name" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Role is required" }]}
                name="role_ids"
                label="Role"
            >
                <Select
                    showSearch
                    options={RoleData?.getRolesLabeledValue()}
                    placeholder="Please select the Roles"
                    mode="multiple"
                    loading={isLoading}
                    notFoundContent="No options found"
                    filterOption={(input, option) => {
                        if (!option) return false;
                        return option.label
                            .toLowerCase()
                            .includes(input.toLowerCase());
                    }}
                />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "Email is required" },
                    {
                        type: "email",
                        message: "Please enter a valid email address",
                    },
                ]}
                label="Email"
                name="email"
            >
                <Input placeholder="Email" type="email" autoComplete="email" />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "Password is required" },
                    {
                        min: 8,
                        message: "Password must be at least 8 characters long",
                    },
                ]}
                label="Password"
                name="password"
            >
                <Input.Password placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
            {/*
      {isErrorVisible && (
        <Alert
          style={{ marginTop: "7px" }}
          message={isError}
          closable
          afterClose={() => setIsErrorVisible(false)}
          type="error"
          showIcon
        />
      )} */}
        </Form>
    );
};

export default CreateAdminUser;
