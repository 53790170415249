import { useService } from "../../contexts";
import { useState } from "react";
import { useAtom } from "jotai";
import { CurrencyCreateUpdateRequestModel } from "@/models/currency";
import { regionCurrency } from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";

export const useUpdateCurrency = () => {
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [regionCurrencyData, setRegionCurrencyData] = useAtom(regionCurrency);

    const updateCurrency = async (
        id: number,
        payload: CurrencyCreateUpdateRequestModel,
    ) => {
        setIsLoading(true);
        try {
            const response = await coreService.CurrencyResource.update(
                id,
                payload,
            );
            setIsLoading(false);
            setRegionCurrencyData({ ...regionCurrencyData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateCurrency, isLoading, isError };
};

export const useCreateCurrency = () => {
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [regionCurrencyData, setRegionCurrencyData] = useAtom(regionCurrency);

    const createCurrency = async (
        payload: CurrencyCreateUpdateRequestModel,
    ) => {
        setIsLoading(true);
        try {
            const response = await coreService.CurrencyResource.create(payload);
            setIsLoading(false);
            setRegionCurrencyData({ ...regionCurrencyData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { createCurrency, isLoading, isError };
};

export const useGetCurrency = () => {
    const [currency, setCurrency] = useAtom(regionCurrency);
    const { coreService } = useService();
    const getCurrency = async (params?: string): Promise<void> => {
        setCurrency({ ...currency, isLoading: true, error: null });
        try {
            const response = await coreService.CurrencyResource.get(params);
            setCurrency({
                ...currency,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setCurrency({
                ...currency,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return { getCurrency } as const;
};
