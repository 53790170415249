export const colors = {
    white: "#FFFFFF",
    BGLight: "#EDF0F2",
    BGDark: "#13131A",
    B98: "#F5F8FA",
    B85: "#D5D5D9",
    B80: "#BEBECC",
    B70: "#A1A1B2",
    B60: "#828299",
    B40: "#525266",
    B30: "#3D3D4D",
    B20: "#292933",
    B15: "#1E1E26",
    B17: "#23232B",
    success: "#4D9980",
    error: "#E54545",
    vertical: "linear-gradient(0deg,#FF512F 0%,#DD2476 100%)",
    horizontal: "linear-gradient(99deg,#DD2476 0% 0%,#FF512F 100% 100%)",
    loader: "#ef3f5a",
};

export declare type ColorScheme = "light" | "dark";
