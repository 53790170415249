import ChunkedUploady from "@rpldy/chunked-uploady";
import { DivUploadButton } from "./DivUploadButton";
import { FILE_UPLOAD_URL, FILE_UPLOAD_URL_TOKEN } from "@/consts/storage";
import setAndGetGuestData from "@/helpers/setAndGetGuestData";
import { getEnvironmentMode } from "@/utils/helper";
import { NamePath } from "antd/es/form/interface";
import { FormInstance } from "antd";

interface IProps {
    form: FormInstance<any>;
    fieldName: NamePath;
    accept?: string;
    uploadUrl?: string;
}
export const SingleChunkUpload = ({
    form,
    fieldName,
    accept = "image/jpeg,image/png,image/jpg,image/gif,image/webp",
}: IProps) => {
    const mode = getEnvironmentMode();

    const header = {
        Accept: "application/json",
        "X-GUEST-ID": setAndGetGuestData()?.guestId || "",
        "X-GUEST-SESSION": setAndGetGuestData()?.guestSession || "",
    };

    if (mode === "development") {
        header["X-SESSION-TOKEN"] = FILE_UPLOAD_URL_TOKEN;
    }
    return (
        <ChunkedUploady
            accept={accept}
            multiple={false}
            chunkSize={204800}
            withCredentials={true}
            method="POST"
            destination={{
                url: FILE_UPLOAD_URL,
                headers: header,
            }}
        >
            <DivUploadButton form={form} fieldName={fieldName} />
        </ChunkedUploady>
    );
};
