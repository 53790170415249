import {
    IPayoutGateway,
    IPayoutGatewayCollection,
    IPayoutGatewayFormSchema,
    IPayoutGatewayFormSchemaData,
    IPayoutGatewayLogo,
    IPayoutGatewayLogoCollection,
    IPayoutGatewayLogoImage,
} from "@/types/payoutGatewayCollection";
import { PaginationModel } from "./paginationModel";
import { CountryModel } from "./countryCollectionModel";
import { SelectProps } from "antd";

export class PayoutGatewayFormSchemaDataModel {
    type: string;
    label: string;
    placeholder: string;
    is_required: boolean;

    constructor(data: IPayoutGatewayFormSchemaData) {
        this.type = data.type;
        this.label = data.label;
        this.placeholder = data.placeholder;
        this.is_required = data.is_required;
    }

    getType = () => this.type;
    getLabel = () => this.label;
    getPlaceholder = () => this.placeholder;
    getIsRequired = () => this.is_required;
}

export class PayoutGatewayFormSchemaModel {
    [key: string]: PayoutGatewayFormSchemaDataModel;

    constructor(data: IPayoutGatewayFormSchema) {
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                this[key] = new PayoutGatewayFormSchemaDataModel(data[key]);
            }
        }
    }
}

export class PayoutGatewayLogoImageModel {
    url: string;
    width: number | null;
    height: number | null;

    constructor(data: IPayoutGatewayLogoImage) {
        this.url = data.url;
        this.width = data.width;
        this.height = data.height;
    }
}

export class PayoutGatewayLogoModel {
    object: string;
    small?: PayoutGatewayLogoImageModel;
    medium?: PayoutGatewayLogoImageModel;
    large?: PayoutGatewayLogoImageModel;

    constructor(data: IPayoutGatewayLogo) {
        this.object = data.object;
        if (data.small)
            this.small = new PayoutGatewayLogoImageModel(data.small);
        if (data.medium)
            this.medium = new PayoutGatewayLogoImageModel(data.medium);
        if (data.large)
            this.large = new PayoutGatewayLogoImageModel(data.large);
    }

    getSmall(): PayoutGatewayLogoImageModel | undefined {
        return this.small;
    }
    getMedium(): PayoutGatewayLogoImageModel | undefined {
        return this.medium;
    }
}

export class PayoutGatewayLogoCollectionModel {
    object: string;
    data: PayoutGatewayLogoModel[];

    constructor(data: IPayoutGatewayLogoCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new PayoutGatewayLogoModel(item));
    }

    getData() {
        return this.data;
    }
}

export class PayoutGatewayModel {
    object: string;
    id: number;
    name: string;
    slug: string;
    type: string;
    form_schema: PayoutGatewayFormSchemaModel;
    logos: PayoutGatewayLogoCollectionModel | null;
    country?: CountryModel;
    created_at: string;

    constructor(data: IPayoutGateway) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.type = data.type;
        this.form_schema = new PayoutGatewayFormSchemaModel(data.form_schema);
        this.logos = data.logos
            ? new PayoutGatewayLogoCollectionModel(data.logos)
            : null;
        this.created_at = data.created_at;
        this.country = data.country
            ? new CountryModel(data.country)
            : undefined;
    }

    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getSlug() {
        return this.slug;
    }
    getType() {
        return this.type;
    }
    // getFormSchema() {
    //   return this.form_schema;
    // }
    getLogo() {
        return this.logos;
    }

    getCountry() {
        return this.country;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class PayoutGatewayCollectionModel {
    object: string;
    data: PayoutGatewayModel[];
    pagination: PaginationModel;
    constructor(data: IPayoutGatewayCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new PayoutGatewayModel(item));
        this.pagination = new PaginationModel(data.pagination);
    }

    getData() {
        return this.data;
    }
    getPagination() {
        return this.pagination;
    }
    getPayoutgatewayById(id: number): PayoutGatewayModel | undefined {
        return this.data.find((item) => item.id === id);
    }

    getPayoutGatewayOptions(): SelectProps["options"] {
        return this.data && this.data.length > 0
            ? this.data.map((item) => {
                  return {
                      value: item.getId(),
                      label: item.getName(),
                  };
              })
            : [];
    }
}
