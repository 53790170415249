import {
    manageAdminUserRoutePermissions,
    manageAdminCustomerRoutePermission,
    manageAdminImpersonateRoutePermission,
} from "../permission-enum/admin-user-enum";
import manageAdminAclPermissions from "../permission-enum/ACL-enum";
import {
    agentWalletViewRoutePermissions,
    customerWalletViewRoutePermissions,
    masterWalletViewRoutePermissions,
    payoutGatewayManageRoutePermissions,
    payoutRequestManageRoutePermissions,
} from "../permission-enum/wallet-enum";
import { manageRefundRoutePermissions } from "../permission-enum/refund-enum";
import { manageRfqRoutePermissions } from "../permission-enum/RFQ-enum";
import {
    manageStoreExchangeGroupRoutePermission,
    manageStoreExchangeRoutePermissions,
    manageStoreRoutePermissions,
} from "../permission-enum/admin-store-enum";
import { manageAddonsRoutePermissions } from "../permission-enum/addon-service-enum";
import {
    agentCompanyManageRoutePermissions,
    agentWarehouseManageRoutePermissions,
    shippingBaseShippingCategoryManageRoutePermissions,
    shippingCategoryManageRoutePermissions,
    shippingCommissionManageRoutePermissions,
    shippingCountryManageRoutePermissions,
    shippingShipmentProductManageRoutePermissions,
    unlistedProductManageRoutePermissions,
} from "../permission-enum/admin-shipping-enum";
import {
    countryManageRoutePermissions,
    currencyManageRoutePermissions,
    languageManageRoutePermissions,
    regionManageRoutePermissions,
} from "../permission-enum/admin-core-enum";
import { destinationManageRoutePermissions } from "../permission-enum/destination-enum";
import {
    buyProductManageRoutePermissions,
    buyProductOrderCreateRoutePermissions,
    buyProductOrderEditRoutePermissions,
    buyProductOrderManageRoutePermissions,
} from "../permission-enum/purchase-enum";
import { buyProductTakeActionRoutePermissions } from "../permission-enum/take-actions";
import {
    gatewayTransactionsRoutePermissions,
    paymentGateWayManageRoutePermissions,
} from "../permission-enum/payment-enum";
import {
    agentInvoiceRuleGroupViewRoutePermissions,
    agentInvoiceViewRoutePermissions,
    customerInvoiceViewRoutePermissions,
} from "../permission-enum/invoice-enum";
import { harvestViewRoutePermissions } from "../permission-enum/harvest-enum";
import { manageAdminDeliveryRoutePermissions } from "../permission-enum/delivery-request-enum";

const permissionRoutes = {
    manageAdminUserRoutePermissions,
    manageAdminCustomerRoutePermission,
    manageAdminImpersonateRoutePermission,
    manageAdminAclPermissions,
    customerWalletViewRoutePermissions,
    manageRefundRoutePermissions,
    manageRfqRoutePermissions,
    manageStoreRoutePermissions,
    manageStoreExchangeRoutePermissions,
    manageStoreExchangeGroupRoutePermission,
    manageAddonsRoutePermissions,
    agentCompanyManageRoutePermissions,
    agentWarehouseManageRoutePermissions,
    agentWalletViewRoutePermissions,
    currencyManageRoutePermissions,
    countryManageRoutePermissions,
    languageManageRoutePermissions,
    regionManageRoutePermissions,
    shippingCountryManageRoutePermissions,
    shippingCategoryManageRoutePermissions,
    shippingBaseShippingCategoryManageRoutePermissions,
    shippingCommissionManageRoutePermissions,
    shippingShipmentProductManageRoutePermissions,
    destinationManageRoutePermissions,
    buyProductManageRoutePermissions,
    buyProductOrderManageRoutePermissions,
    buyProductTakeActionRoutePermissions,
    paymentGateWayManageRoutePermissions,
    payoutGatewayManageRoutePermissions,
    gatewayTransactionsRoutePermissions,
    buyProductOrderEditRoutePermissions,
    buyProductOrderCreateRoutePermissions,
    payoutRequestManageRoutePermissions,
    masterWalletViewRoutePermissions,
    customerInvoiceViewRoutePermissions,
    agentInvoiceViewRoutePermissions,
    agentInvoiceRuleGroupViewRoutePermissions,
    harvestViewRoutePermissions,
    unlistedProductManageRoutePermissions,
    manageAdminDeliveryRoutePermissions,
};

export default permissionRoutes;
