import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Skeleton,
    Typography,
    Card,
    Row,
    Col,
    Flex,
    Modal,
} from "antd";
import { useAtom } from "jotai";
import { useGetBuyOrdersDetail } from "@/lib/core-react/hooks/private";
import { buyOrderDetailAtom } from "@/lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import { BuyOrderOperationEnum } from "@/enums/buyOrderCollectionEnums";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import OrderInfo from "../../components/OrderInfo";
import OrderHandlerInfo from "../../components/OrderHandlerInfo";
import CustomerInfo from "../../../../components/shared/Order/CustomerInfo";
import BuyProductsTable from "../../BuyProducts/BuyProductsTable";
import { tw } from "@/consts/theme/tailwindTheme";
import CommonError from "@/components/Error/CommonError";
import LoaderSpin from "@/components/LoaderSpin";
import { parseObjectToString } from "@/helpers/stringify";
import getPagePathname from "@/helpers/getPagePathname";
import { showError } from "@/helpers/showError";
import isProductUrl from "@/helpers/isProductUrl";
import ProductUrlForm from "../../components/ProductUrlForm";

const EditOrder = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { getBuyOrdersDetail } = useGetBuyOrdersDetail();
    const [modalOpenType, setModalOpenType] = useState<
        "add_buy_product" | false
    >(false);

    useEffect(() => {
        if (id) {
            getBuyOrdersDetail(id);
        }
    }, [id]);

    const [{ data: orderDetail, isLoading, error, code, unAuthorized }] =
        useAtom(buyOrderDetailAtom);

    const handleGoToInventory = () => {
        try {
            if (!orderDetail || !id) {
                throw new Error("Product Not Found");
            }
            const queryData = {
                buyOrderId: orderDetail.getId(),
                region: orderDetail.getFirstProductRegionCode(),
                region_id: orderDetail.getFirstProductRegionId(),
                operation: BuyOrderOperationEnum.AddBuyOrderProduct,
                from: getPagePathname(),
            };
            navigate(`/service/inventory?${parseObjectToString(queryData)}`);
        } catch (error) {
            showError(error);
        }
    };

    const onFinish = (values) => {
        try {
            if (!orderDetail) {
                throw new Error("Product Not Found");
            }

            if (values && !isProductUrl(values.productUrl)) {
                throw new Error("Please Paste Product Url");
            }

            const queryData = {
                productUrl: values.productUrl,
                buyOrderId: orderDetail.getId(),
                region: orderDetail.getFirstProductRegionCode(),
                region_id: orderDetail.getFirstProductRegionId(),
                operation: BuyOrderOperationEnum.AddBuyOrderProduct,
                from: getPagePathname(),
            };
            navigate(
                `/service/inventory/product-detail?${parseObjectToString(queryData)}`,
            );
        } catch (error) {
            showError(error);
        }
    };
    if (isLoading) {
        return <LoaderSpin />;
    }

    if (error || unAuthorized) {
        return (
            <CommonError
                code={code}
                hidePageHeader
                message={error}
                unAuthorized={unAuthorized}
            />
        );
    }

    return (
        <>
            <PageHeader
                ghost={false}
                title="Order Details"
                style={{ marginTop: "16px" }}
                onBack={() => navigate(-1)}
            >
                <div>
                    {isLoading ? (
                        <Skeleton active />
                    ) : (
                        <Row gutter={20}>
                            <Col span={12}>
                                <Card title="Order Info">
                                    {orderDetail && (
                                        <OrderInfo
                                            paymentStatus={
                                                orderDetail.payment_status
                                            }
                                            oderStatus={
                                                orderDetail.order_status
                                            }
                                            oderType={orderDetail.order_type}
                                            orderCreatedAt={
                                                orderDetail.created_at
                                            }
                                            oderNumber={
                                                orderDetail.order_number
                                            }
                                            orderTag={orderDetail.order_tag}
                                            note={orderDetail.note}
                                        />
                                    )}
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Flex
                                    gap={20}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <Card
                                        style={{
                                            width: "100%",
                                        }}
                                        title="Order Handler"
                                    >
                                        {orderDetail &&
                                            orderDetail.order_handler && (
                                                <OrderHandlerInfo
                                                    orderHandler={
                                                        orderDetail.order_handler
                                                    }
                                                />
                                            )}
                                    </Card>
                                    <Card
                                        style={{
                                            width: "100%",
                                        }}
                                        title="Customer "
                                    >
                                        {orderDetail &&
                                            orderDetail.order_handler && (
                                                <CustomerInfo
                                                    user={orderDetail.customer}
                                                />
                                            )}
                                    </Card>
                                </Flex>
                            </Col>
                        </Row>
                    )}
                </div>

                <Flex
                    style={{
                        marginTop: 24,
                    }}
                    justify="space-between"
                    align="center"
                >
                    <Typography.Paragraph
                        style={{
                            fontSize: tw.fontSize.xl.fontSize,
                        }}
                        strong
                    >
                        Products
                    </Typography.Paragraph>
                    <div>
                        {checkActionPermission(
                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
                            <Button
                                key={"1"}
                                type="primary"
                                onClick={() =>
                                    setModalOpenType("add_buy_product")
                                }
                            >
                                Add Product
                            </Button>,
                            null,
                        )}
                    </div>
                </Flex>

                <BuyProductsTable
                    isOrderPage={true}
                    buyProductList={orderDetail?.getProducts()}
                />
            </PageHeader>
            <Modal
                title={"Add Product"}
                open={modalOpenType === "add_buy_product"}
                onCancel={() => setModalOpenType(false)}
                footer={null}
                width={600}
                destroyOnClose={true}
            >
                <ProductUrlForm
                    handleFinish={onFinish}
                    onGoToInventory={handleGoToInventory}
                />
            </Modal>
        </>
    );
};
export default EditOrder;
