import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { useService } from "@/lib/core-react/contexts";
import { adminUserCollection } from "@/lib/core-react/store/store";
import { useState } from "react";
import { IUserCredentials, IUserStatus } from "@/types/userCollection";

export const useAdminUserList = () => {
    const [adminUser, setAdminUser] = useAtom(adminUserCollection);

    const { adminUserService } = useService();

    const getAdminUser = async (params?: string): Promise<void> => {
        setAdminUser({ ...adminUser, isLoading: true, error: null });
        try {
            const response =
                await adminUserService.adminUserResource.getList(params);
            setAdminUser({
                ...adminUser,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setAdminUser({
                ...adminUser,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };

    return { getAdminUser } as const;
};

export const useOrderHandler = () => {
    const [orderHandler, setOrderHandler] = useAtom(adminUserCollection);

    const { adminUserService } = useService();

    const getOrderHandler = async (params?: string): Promise<void> => {
        setOrderHandler({ ...orderHandler, isLoading: true, error: null });
        try {
            const response =
                await adminUserService.adminUserResource.getOrderHandler(
                    params,
                );
            setOrderHandler({
                ...orderHandler,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setOrderHandler({
                ...orderHandler,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };

    return { getOrderHandler } as const;
};

export const useCreateAdmin = () => {
    const { adminUserService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [adminUser, setAdminUser] = useAtom(adminUserCollection);

    const createAdmin = async (payload: IUserCredentials) => {
        setIsLoading(true);
        try {
            const response =
                await adminUserService.adminUserResource.createAdmin(payload);
            setAdminUser({
                ...adminUser,
                isLoading: false,
                refetch: true,
            });
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setIsLoading(false);
            setError(getError(error));
            throw error;
        }
    };

    return { createAdmin, isLoading, isError };
};

export const useUpdateAdmin = () => {
    const { adminUserService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [adminUser, setAdminUser] = useAtom(adminUserCollection);

    const updateAdmin = async (payload: IUserStatus, user_id: number) => {
        setIsLoading(true);
        try {
            const response =
                await adminUserService.adminUserResource.UpdateAdmin(
                    payload,
                    user_id,
                );
            setAdminUser({
                ...adminUser,
                isLoading: false,
                refetch: true,
            });
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateAdmin, isLoading, isError };
};
