import { IPagination } from "@/types/pagination";
import { MemberCollectionEnums } from "@/enums/memberCollectionEnums";
import {
    IMember,
    IMemberCollection,
    IMemberRoleCollection,
    IMemberUser,
} from "@/types/memberCollection";
import { IFilterType } from "@/types/filters";

export class MemberUserModel {
    public readonly id: number;
    public readonly name: string;
    public readonly phone: string | null;
    public readonly email: string;
    public readonly shipping_mark: string;
    public readonly type: string;

    constructor(dataItem: IMemberUser) {
        this.email = dataItem.email;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.phone = dataItem.phone;
        this.shipping_mark = dataItem.shipping_mark;
        this.type = dataItem.type;
    }

    getEmail() {
        return this.email;
    }
    getPhone() {
        return this.phone;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getShippingMark() {
        return this.shipping_mark;
    }
    getType() {
        return this.type;
    }
}

export class MemberModel {
    public readonly object: MemberCollectionEnums.Member;
    public readonly id: number;
    public readonly user: MemberUserModel;
    public readonly status: string;
    public readonly member_since: string;
    public readonly roles: IMemberRoleCollection;

    constructor(dataItem: IMember) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.user = new MemberUserModel(dataItem.user);
        this.status = dataItem.status;
        this.member_since = dataItem.member_since;
        this.roles = dataItem.roles;
    }

    getId() {
        return this.id;
    }
    getUser() {
        return this.user;
    }
    getStatus() {
        return this.status;
    }
    getMemberSince() {
        return this.member_since;
    }
    getRoles() {
        return this.roles.data;
    }
}

export class MemberCollectionModel {
    public readonly object: MemberCollectionEnums.MemberCollection;
    public readonly data: MemberModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: IMemberCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new MemberModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getMemberById(id: number): MemberModel | undefined {
        return this.data.find((item) => item.id === id);
    }

    getFilters() {
        return this.filters;
    }
}
