import { getNodeLabel } from "../typeGuards";

import { callWebauthnFunction, NodeInputProps } from "./helpers";
import { Button } from "antd";

export function NodeInputButton({
    node,
    attributes,
    setValue,
    disabled,
    dispatchSubmit,
}: NodeInputProps) {
    const onClick = (
        e: React.MouseEvent | React.FormEvent<HTMLFormElement>,
    ) => {
        if (attributes.onclick) {
            e.stopPropagation();
            e.preventDefault();
            callWebauthnFunction(attributes.onclick);
            return;
        }

        setValue(attributes.value).then(() => dispatchSubmit(e));
    };

    return (
        <>
            <Button
                name={attributes.name}
                onClick={(e) => {
                    onClick(e);
                }}
                value={attributes.value || ""}
                disabled={attributes.disabled || disabled}
            >
                {getNodeLabel(node)}
            </Button>
        </>
    );
}
