import { Col, DatePicker, Form, FormInstance, Input, Select } from "antd";
import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IFilterType } from "@/types/filters";

import NumberRangeInput from "./NumberRangeSlider";
import { ResourceInput } from "./ResourceInput";
import { FieldType, ResourceDataType } from "@/filters/enum/FieldType";
import { getMatchedResource } from "@/filters/utils";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { tw } from "@/consts/theme/tailwindTheme";
import dayjs from "dayjs";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

export interface ICustomElementProps {
    availableRegionId?: string;
    type: string;
    label: string;
    name: string;
    placeholder: string | null;
    handleChange: (fields: IFilterType) => void;
    fieldValue: any;
    options: any[] | IFilterType | undefined;
    resource: string | null;
    data: string | null;
    form?: FormInstance;
}

const FilterComponentInputs = (props: ICustomElementProps) => {
    const { isMobile } = useWindowWidth();

    const {
        type,
        label,
        name,
        placeholder,
        handleChange,
        fieldValue,
        options,
        resource,
        data,
    } = props;
    const navigate = useNavigate();
    const location = useLocation();

    let fieldInput: ReactNode;

    // -----------------------ui-----------------------

    if (type === FieldType.Array) {
        fieldInput = (
            <Select
                showSearch
                value={fieldValue}
                onChange={(value) => handleChange({ [name]: value })}
                mode="multiple"
                placeholder={placeholder}
                filterOption={(input, option) =>
                    option && typeof option.children === "string"
                        ? (option.children as any)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                        : false
                }
            >
                {Object.keys(options as IFilterType).map((opt, idx) => (
                    <Option key={`${name}-${idx}`} value={opt}>
                        {(options as IFilterType)[opt]}
                    </Option>
                ))}
            </Select>
        );
    } else if (type === FieldType.DATE_RANGE) {
        const initialValue = fieldValue
            ? [dayjs(fieldValue[0]), dayjs(fieldValue[1])]
            : undefined;

        fieldInput = (
            <RangePicker
                showTime
                style={{ width: "100%" }}
                value={initialValue}
                onChange={(_, dateString) => {
                    const isEmptyArray = dateString.every(
                        (item) => item === "",
                    );
                    if (isEmptyArray) {
                        navigate(location.pathname);
                    } else {
                        const formattedDate = dateString.map((date) =>
                            moment(date).format("YYYY-MM-DD HH:mm:ss"),
                        );
                        return handleChange({ [name]: formattedDate });
                    }
                }}
            />
        );
    } else if (type === FieldType.NUMBER_RANGE) {
        fieldInput = (
            <NumberRangeInput
                name={name}
                handleChange={handleChange}
                fieldValue={fieldValue}
            />
        );
    } else if (type === FieldType.EXTERNAL_ARRAY) {
        fieldInput = (
            <Select
                value={fieldValue}
                onChange={(value) => handleChange({ [name]: value })}
                mode="multiple"
                placeholder={placeholder}
            >
                {(options as any[]).map((opt, idx) => (
                    <Option key={`${name}-${idx}`} value={opt.id}>
                        {opt.name}
                    </Option>
                ))}
            </Select>
        );
    } else if (type === FieldType.SELECT) {
        if (Array.isArray(options)) {
            if (options.length > 0 && !resource) {
                fieldInput = (
                    <Select
                        value={fieldValue}
                        allowClear
                        showSearch
                        onChange={(value) => handleChange({ [name]: value })}
                        placeholder={placeholder}
                    >
                        {options.map((data) => (
                            <Option key={`${data.value}`} value={data.value}>
                                {data.label}
                            </Option>
                        ))}
                    </Select>
                );
            }
        } else {
            if (options && !resource) {
                fieldInput = (
                    <Select
                        value={fieldValue}
                        allowClear
                        showSearch
                        onChange={(value) => handleChange({ [name]: value })}
                        placeholder={placeholder}
                    >
                        {Object.keys(options as IFilterType).map((opt, idx) => (
                            <Option key={`${name}-${idx}`} value={opt}>
                                {(options as IFilterType)[opt]}
                            </Option>
                        ))}
                    </Select>
                );
            } else {
                fieldInput =
                    data === ResourceDataType.EXTERNAL ||
                    getMatchedResource(resource) ? (
                        <ResourceInput {...props} />
                    ) : (
                        <></>
                    );
            }
        }
    } else if (type === FieldType.MULTI_SELECT) {
        if (Array.isArray(options)) {
            if (options && !resource) {
                fieldInput = (
                    <Select
                        value={fieldValue}
                        allowClear
                        mode="multiple"
                        showSearch
                        onChange={(value) => handleChange({ [name]: value })}
                        placeholder={placeholder}
                        options={options}
                    >
                        {/* {options?.map((data) => (
                            <Option key={`${data.value}`} value={data.value}>
                                {data.label}
                            </Option>
                        ))} */}
                    </Select>
                );
            }
        } else {
            fieldInput =
                data === ResourceDataType.EXTERNAL ||
                getMatchedResource(resource) ? (
                    <ResourceInput {...props} />
                ) : (
                    <></>
                );
        }
    } else if (type === FieldType.PRICE_RANGE) {
        const makePriceOptions = {
            1: "1",
            10: "10",
            50: "50",
            100: "100",
            300: "300",
            500: "500",
            1000: "1000",
            5000: "5000",
            10000: "10000",
        };
        fieldInput = (
            <Select
                value={fieldValue}
                onChange={(value) => handleChange({ [name]: value })}
                mode="multiple"
                placeholder={placeholder}
            >
                {Object.keys(makePriceOptions).map((opt, idx) => (
                    <Option key={`${name}-${idx}`} value={opt}>
                        {opt}
                    </Option>
                ))}
            </Select>
        );
    } else {
        fieldInput = (
            <Input
                placeholder={placeholder || undefined}
                value={fieldValue}
                onChange={(e) => handleChange({ [name]: e.target.value })}
            />
        );
    }
    return (
        <Col xs={24} md={12} xl={8} xxl={6} style={{ paddingRight: 0 }}>
            <Form.Item
                style={{
                    marginBottom: isMobile ? tw.spacing[4] : tw.spacing[6],
                    fontWeight: "bold",
                }}
                label={label}
            >
                {fieldInput}
            </Form.Item>
        </Col>
    );
};

export default FilterComponentInputs;
