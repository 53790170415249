import { Tabs } from "antd";
import ProfileTab from "./ProfileTab";
import SecurityTab from "./SecurityTab";
import NotificationTab from "./NotificationsTab";
import { PageHeader } from "@ant-design/pro-layout";

const UserSettings = () => {
    return (
        <div style={{ padding: "24px", maxWidth: "1200px", margin: "0 auto" }}>
            <PageHeader className="site-page-header" title="Settings" />
            <div style={{ marginTop: "24px" }}>
                <Tabs defaultActiveKey="profile">
                    <Tabs.TabPane tab="Profile" key="profile">
                        <ProfileTab />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Security" key="security">
                        <SecurityTab key={"security"} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Notification" key="notification">
                        <NotificationTab />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
};

export default UserSettings;
