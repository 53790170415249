// Enum for admin permissions in the Refund module
export enum ADMIN_REFUND_PERMISSION_ENUM {
    ADMIN_BUY_PRODUCT_DISPUTE_CREATE = "admin-buy-product-dispute-create",
    ADMIN_DISPUTE_VIEW = "admin-dispute-view",
    ADMIN_DISPUTE_APPROVE = "admin-dispute-approve",
    ADMIN_DISPUTE_COMPLETE = "admin-dispute-complete",
    ADMIN_SHIPMENT_PRODUCT_DISPUTE_ASSIGN = "admin-shipment-product-dispute-assign",
}

export const manageRefundRoutePermissions = [
    ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
];
