import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
    Button,
    Col,
    Dropdown,
    Form,
    message,
    Modal,
    notification,
    Row,
    Table,
    Tag,
    Typography,
} from "antd";
import { purchaseCommissionCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { AgentCompanyModel } from "@/models/agentCompanyCollectionModel";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { usePurchaseCommissionGet } from "@/lib/core-react/hooks/private";
import {
    PurchaseCommissionCollectionModel,
    PurchaseCommissionModel,
} from "@/models/purchaseCommissionCollectionModel";
import { BuyProductCommissionRateTypeEnum } from "@/enums/purchaseCommissionCollectionEnum";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { formatDate } from "@/utils/helpers";
import { ExtendedMenuItemType } from "@/types";
import {
    DownOutlined,
    EditFilled,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { MenuInfo } from "rc-menu/lib/interface";
import { PurchaseCommissionUpdate } from "./PurchaseCommissionUpdate";
import {
    IPurchaseCommissionFormData,
    IPurchaseCommissionUpdateData,
} from "@/types/purchaseCommissionCollection";
import {
    usePurchaseCommissionCreate,
    usePurchaseCommissionUpdate,
} from "@/lib/core-react/hooks/private/usePurchase";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import FiltersComponent from "@/components/FiltersComponent";
import { PageHeader } from "@ant-design/pro-layout";
import { showError } from "@/helpers/showError";
import { PurchaseCommissionModification } from "./PurchaseCommissionModification";
import { IFilterType } from "@/types/filters";
import CommonError from "@/components/Error/CommonError";

interface IProps {
    data: AgentCompanyModel;
}

export const PurchaseCommissionTable = ({ data }: IProps) => {
    const { getPurchaseCommission } = usePurchaseCommissionGet(data.getId());
    const {
        updatePurchaseCommission,
        isLoading: isLoadingUpdatePurchaseCommission,
    } = usePurchaseCommissionUpdate();
    const [
        {
            data: purchaseCommissionCollectionData,
            isLoading,
            error,
            unAuthorized,
            refetch,
        },
    ] = useAtom(purchaseCommissionCollectionAtom);

    const {
        filters,
        initializeAvailableFilter,
        handleFilterChange,
        isFirstCall,
        isFetched,
        handelFilterClear,
    } = useDataFilters(true);

    const {
        createPurchaseCommission,
        isLoading: isLoadingCreatePurchaseCommission,
    } = usePurchaseCommissionCreate();
    const { isMobile } = useWindowWidth();
    const { Text } = Typography;

    const [form] = Form.useForm();

    const [modalOpen, setModalOpen] = useState<
        "updateCommission" | "addCommission" | undefined
    >(undefined);

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest({}, getPurchaseCommission);
        }
    }, [data, filters]);

    const PurchaseCommissionCollectionData =
        purchaseCommissionCollectionData &&
        new PurchaseCommissionCollectionModel(purchaseCommissionCollectionData);

    // Pagination
    const paginationData = PurchaseCommissionCollectionData?.getPagination();
    // Filter
    const filterData = PurchaseCommissionCollectionData?.getFilters();

    const [selectedCommission, setSelectedCommission] = useState<
        PurchaseCommissionModel | undefined
    >(undefined);

    const handleMenuClick = (e: MenuInfo, record: PurchaseCommissionModel) => {
        if (e.key === "update-commission") {
            setModalOpen("updateCommission");
            setSelectedCommission(record);
        }
    };

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && filterData) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, filterData]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getPurchaseCommission,
        );
    };

    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getPurchaseCommission);
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        PurchaseCommissionCollectionData,
        handlePaginationChange,
    );

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "id",
            width: 70,
            align: "center" as const,
            render: (_: string, __: PurchaseCommissionModel, index: number) => {
                return (
                    <>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </>
                );
            },
        },
        {
            title: "Commission Details",
            dataIndex: "store",
            hidden: !isMobile,
            key: "store",
            width: 100,
            render: (_: string, record: PurchaseCommissionModel) => {
                return (
                    <>
                        <Text>
                            Store:{" "}
                            <Typography.Text>
                                {record.getStore()?.getName()}
                            </Typography.Text>
                        </Text>
                        <br />
                        <Text>
                            Rate:{" "}
                            <Typography.Text>
                                {record.getCommissionRate()}
                                {record.getCommissionRateType() ===
                                BuyProductCommissionRateTypeEnum.PERCENTAGE
                                    ? "%"
                                    : "(Fixed)"}
                            </Typography.Text>
                        </Text>
                        <br />
                        {record.getCreatedAt() ? (
                            <Tag color="purple">
                                {formatDate(record.getCreatedAt()!)}
                            </Tag>
                        ) : (
                            ""
                        )}
                    </>
                );
            },
        },
        {
            title: "Store",
            dataIndex: "store",
            hidden: isMobile,
            key: "store",
            width: 100,
            render: (_: string, record: PurchaseCommissionModel) => {
                return (
                    <>
                        <Typography.Text>
                            {record.getStore()?.getName()}
                        </Typography.Text>
                    </>
                );
            },
        },
        {
            title: "Rate",
            dataIndex: "commission_rate",
            key: "commission_rate",
            hidden: isMobile,
            width: 30,
            align: "center" as const,
            render: (_: string, record: PurchaseCommissionModel) => (
                <Typography.Text>
                    {record.getCommissionRate()}
                    {record.getCommissionRateType() ===
                    BuyProductCommissionRateTypeEnum.PERCENTAGE
                        ? "%"
                        : "(Fixed)"}
                </Typography.Text>
            ),
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            hidden: isMobile,
            align: "center" as const,
            width: 100,
            key: "created_at",
            render: (_: string, record: PurchaseCommissionModel) => {
                return record.getCreatedAt() ? (
                    <Tag color="purple">
                        {formatDate(record.getCreatedAt()!)}
                    </Tag>
                ) : (
                    ""
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            align: "center" as const,
            width: 100,
            hidden: !checkActionPermission(
                ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_COMMISSION_MANAGE,
                "checkActionPermission",
                null,
            ),
            render: (_: string, record: PurchaseCommissionModel) => {
                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_COMMISSION_MANAGE,
                        label: "Update",
                        key: "update-commission",
                        icon: <EditFilled />,
                        onClick: (e) => handleMenuClick(e, record),
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const onFinish = async (values: any) => {
        try {
            const payload: IPurchaseCommissionUpdateData = {
                store_id: values.store_id,
                commission_rate: values.commission_rate,
                commission_rate_type: values.commission_rate_type,
            };

            if (selectedCommission) {
                await updatePurchaseCommission(
                    data.getId(),
                    selectedCommission.getId(),
                    payload,
                );
                notification["success"]({
                    message: "Update Successfully",
                });

                if (filters) {
                    handleFilterChange(filters);
                }
                setSelectedCommission(undefined);
                setModalOpen(undefined);
            } else {
                message.error("No commission selected!");
            }
        } catch (error: any) {
            showError(error);
        }
    };

    const addCommissions = async (values: any) => {
        try {
            const payload: IPurchaseCommissionFormData = {
                store_commissions: values.store_commissions,
            };
            await createPurchaseCommission(data.getId(), payload);
            if (filters) {
                handleFilterChange(filters);
            }
            notification["success"]({
                message: "Create Successfully",
            });
            setModalOpen(undefined);
        } catch (error: any) {
            showError(error, form);
        }
    };
    if (unAuthorized || error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                hidePageHeader={true}
            />
        );
    }
    return (
        <>
            <Row style={{ minHeight: "500px" }}>
                <Col span={24}>
                    <PageHeader
                        style={{ marginTop: "10px" }}
                        ghost={false}
                        title="Stores"
                        extra={[
                            checkActionPermission(
                                ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_COMMISSION_MANAGE,
                                <Button
                                    key="1"
                                    onClick={() => {
                                        setModalOpen("addCommission");
                                    }}
                                    type="primary"
                                    icon={<PlusOutlined />}
                                >
                                    Add Commissions
                                </Button>,

                                null,
                            ),
                        ]}
                    >
                        <Row>
                            <Col span={24}>
                                <FiltersComponent
                                    handleProductFilter={handleProductFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={filterData}
                                    isFromProductReceived={true}
                                    isSubFilter={true}
                                />
                            </Col>
                        </Row>

                        <Table
                            //@ts-ignore
                            columns={columns}
                            size="small"
                            bordered
                            loading={isLoading}
                            dataSource={PurchaseCommissionCollectionData?.getData()}
                            pagination={paginationConfig}
                            rowKey="id"
                        />
                    </PageHeader>
                </Col>
            </Row>

            <Modal
                open={Boolean(modalOpen)}
                onCancel={() => {
                    setSelectedCommission(undefined);
                    setModalOpen(undefined);
                }}
                onOk={form.submit}
                okText={modalOpen === "addCommission" ? "Submit" : "Update"}
                okButtonProps={{
                    loading:
                        isLoadingUpdatePurchaseCommission ||
                        isLoadingCreatePurchaseCommission,
                }}
                title={
                    modalOpen === "addCommission"
                        ? "Add commission"
                        : "Update Commission"
                }
            >
                {modalOpen === "updateCommission" && selectedCommission && (
                    <PurchaseCommissionUpdate
                        selectedCommission={selectedCommission}
                        form={form}
                        onFinish={onFinish}
                    />
                )}
                {modalOpen === "addCommission" && data && (
                    <PurchaseCommissionModification
                        company={data}
                        form={form}
                        onFinish={addCommissions}
                    />
                )}
            </Modal>
        </>
    );
};
