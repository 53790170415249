import { PageHeader } from "@ant-design/pro-layout";
import { Button, Card, Dropdown, Modal, Table, Tag, Badge } from "antd";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { invoicePaymentRuleCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import {
    DeleteFilled,
    DownOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
    UserOutlined,
} from "@ant-design/icons";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { useInvoicePaymentRule } from "@/lib/core-react/hooks/private/useInvoicePaymentRule";
import { InvoicePaymentRuleModel } from "@/models/invoicePaymentRoleModel";
import { StatusTag } from "@/components";
import InvoiceRuleForm from "../component/createOrUpdateForm";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import CommonError from "@/components/Error/CommonError";
import InvoiceRuleGroupUsers from "../component/userList";

type ModalForType = "create" | "update" | "delete" | "attach-user";

const AgentInvoiceList = () => {
    const { getInvoicePaymentRules, deleteInvoicePaymentRules } =
        useInvoicePaymentRule();
    const [
        {
            data: invoicePaymentRoleCollectionData,
            isLoading,
            refetch,
            code,
            error,
            unAuthorized,
        },
    ] = useAtom(invoicePaymentRuleCollectionAtom);
    const [modalFor, setModalFor] = useState<ModalForType | undefined>(
        undefined,
    );
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [selectedRuleGroup, setSelectedRuleGroups] = useState<
        InvoicePaymentRuleModel | undefined
    >(undefined);

    const { isMobile } = useWindowWidth();
    const { filters, handleFilterChange, isFirstCall, isFetched } =
        useDataFilters();
    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(
                filters || {},
                getInvoicePaymentRules,
            );
        }
    }, [isFirstCall, isFetched, refetch]);

    const paginationData = invoicePaymentRoleCollectionData?.getPagination();

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getInvoicePaymentRules,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        invoicePaymentRoleCollectionData,
        handlePaginationChange,
    );

    const getModelTitle = (modelFor: ModalForType) => {
        switch (modelFor) {
            case "create":
                return `Create`;
            case "update":
                return `Update ${selectedRuleGroup?.getName()}`;
            case "attach-user":
                return `Users of  ${selectedRuleGroup?.getName()}`;
            default:
                return "";
        }
    };
    const getModalContent = () => {
        if (modalFor === "create") {
            return (
                <InvoiceRuleForm
                    modelFor="create"
                    selectedData={undefined}
                    setIsShowModal={setIsShowModal}
                />
            );
        } else if (modalFor === "update") {
            return (
                <InvoiceRuleForm
                    modelFor="update"
                    selectedData={selectedRuleGroup}
                    setIsShowModal={setIsShowModal}
                />
            );
        } else if (modalFor === "attach-user") {
            return (
                <InvoiceRuleGroupUsers
                    selectedData={selectedRuleGroup}
                    setIsShowModal={setIsShowModal}
                />
            );
        } else {
            return <div>Please select a proper action </div>;
        }
    };

    const handleDelete = async (id: number) => {
        await deleteInvoicePaymentRules(id);
    };
    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (
                _: string,
                _record: InvoicePaymentRuleModel,
                index: number,
            ) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center" as const,
            render: (_name, record: InvoicePaymentRuleModel) => {
                return (
                    <div color="purple">
                        {record.isDefault() ? (
                            <Badge.Ribbon text="Default">
                                <Card
                                    style={{
                                        borderRadius: "0px",
                                    }}
                                    size="small"
                                >
                                    {record.getName()}
                                </Card>
                            </Badge.Ribbon>
                        ) : (
                            <Card
                                style={{
                                    borderRadius: "0px",
                                    background: "transparent",
                                }}
                                size="small"
                            >
                                {record.getName()}
                            </Card>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 350,
            key: "status",
            align: "center" as const,
            render: (_status, record: InvoicePaymentRuleModel) => {
                const statuses = [
                    {
                        label: "Wallet Enabled",
                        method: record.isWalletEnabled(),
                    },
                    {
                        label: "Automatic Gateway Enabled",
                        method: record.isAutomaticGatewayEnabled(),
                    },
                    {
                        label: "Bank Deposit Enabled",
                        method: record.isBankDepositEnabled(),
                    },
                    {
                        label: "Mobile Merchant Enabled",
                        method: record.isMobileMerchantEnabled(),
                    },
                ];

                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {statuses.map((status) => (
                            <div
                                key={status.label}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    margin: "4px 0",
                                }}
                            >
                                <span style={{ fontWeight: "bold" }}>
                                    {status.label}:
                                </span>
                                <StatusTag
                                    slug={status.method ? "active" : "inactive"}
                                    text={status.method ? "active" : "inactive"}
                                />
                            </div>
                        ))}
                    </div>
                );
            },
        },

        {
            title: "Payment Percentages",
            dataIndex: "paymentPercentages",
            width: 350,
            key: "paymentPercentages",
            align: "center" as const,
            render: (_text, record: InvoicePaymentRuleModel) => {
                const paymentPercentages = [
                    {
                        label: "Wallet Payment",
                        value: record.getWalletPaymentPercentage(),
                    },
                    {
                        label: "Automatic Gateway Payment",
                        value: record.getAutomaticGatewayPaymentPercentage(),
                    },
                    {
                        label: "Bank Deposit Payment",
                        value: record.getBankDepositPaymentPercentage(),
                    },
                    {
                        label: "Mobile Merchant Payment",
                        value: record.getMobileMerchantPaymentPercentage(),
                    },
                ];

                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        {paymentPercentages.map((payment) => (
                            <div
                                key={payment.label}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    margin: "4px 0",
                                }}
                            >
                                <span style={{ fontWeight: "bold" }}>
                                    {payment.label}:
                                </span>
                                <span>{payment.value}%</span>
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            hidden: isMobile,
            key: "created_at",
            align: "center" as const,
            render: (_created_at, record: InvoicePaymentRuleModel) => {
                return <Tag color="purple">{record.getCreatedAt()}</Tag>;
            },
        },

        {
            align: "center" as const,
            title: "Actions",
            key: "actions",
            width: 100,
            fixed: isMobile ? "right" : undefined,
            render: (_: string, record: InvoicePaymentRuleModel) => {
                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_VIEW,
                        label: "Users",
                        key: "users",
                        icon: <UserOutlined />,
                        onClick: async () => {
                            setSelectedRuleGroups(record);
                            setModalFor("attach-user");
                            setIsShowModal(true);
                        },
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_MANAGE,
                        label: "Update",
                        key: "update",
                        icon: <EditOutlined />,
                        onClick: async () => {
                            setSelectedRuleGroups(record);
                            setModalFor("update");
                            setIsShowModal(true);
                        },
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_MANAGE,
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteFilled />,
                        onClick: async () => {
                            handleDelete(record.getId());
                        },
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                ];

                if (isMobile) {
                    menuItems.push({
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_VIEW,
                        label: (
                            <Tag color="purple">{record.getCreatedAt()}</Tag>
                        ),
                        key: "created_at",
                    });
                }

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Invoice payment rules"
                    style={{ marginTop: "10px" }}
                    extra={[
                        checkActionPermission(
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_MANAGE,
                            <Button
                                icon={<PlusOutlined />}
                                type="primary"
                                key="1"
                                onClick={() => {
                                    setIsShowModal(true);
                                    setModalFor("create");
                                }}
                            >
                                Create rule
                            </Button>,
                            null,
                        ),
                    ]}
                    onBack={() => window.history.back()}
                >
                    <div className="responsive-table-container">
                        <Table
                            loading={isLoading}
                            rowKey="id"
                            bordered={true}
                            dataSource={
                                invoicePaymentRoleCollectionData
                                    ? invoicePaymentRoleCollectionData.getData()
                                    : []
                            }
                            //@ts-ignore
                            columns={columns}
                            pagination={{
                                ...paginationConfig,
                                showTotal: (total) => (
                                    <div>
                                        Total{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                color: "green",
                                            }}
                                        >
                                            {total}
                                        </span>{" "}
                                        Items
                                    </div>
                                ),
                            }}
                            scroll={{ x: 1237 }}
                        />
                    </div>
                </PageHeader>
            </div>
            {isShowModal && modalFor && (
                <Modal
                    width={modalFor === "attach-user" ? 800 : undefined}
                    title={getModelTitle(modalFor)}
                    open={isShowModal}
                    footer={false}
                    onCancel={() => setIsShowModal(false)}
                >
                    {getModalContent()}
                </Modal>
            )}
        </>
    );
};

export default AgentInvoiceList;
