import { CheckCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Flex,
    Image,
    Modal,
    Space,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import { useAtom } from "jotai";
import { useTransactionsDetail } from "@/lib/core-react/hooks/private/useTransactions";
import { transactionDetailAtom } from "@/lib/core-react/store/store";
import { TransactionModelDetails } from "@/models/transactionDetailModels";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BasicDetailsCard from "./basicDetailsCard";
import InvoiceTable from "./InvoiceTable";
import CommonError from "@/components/Error/CommonError";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { PAYMENT_GATEWAY_PERMISSION_ENUM } from "@/consts/permission-enum/payment-enum";
import { DownloadPdfButton } from "@/components/DownloadPdfButton";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";

interface IAttachment {
    PDF: string[];
    images: string[];
}

const TransactionDetailSingle = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedAttachments, setSelectedAttachments] = useState<
        IAttachment | undefined
    >(undefined);
    const { getTransactionsDetail, isLoading, isError } =
        useTransactionsDetail();

    const [{ data: transactionDetail }] = useAtom(transactionDetailAtom);

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getTransactionsDetail(id);
        }
    }, [id]);

    const data =
        transactionDetail && new TransactionModelDetails(transactionDetail);

    const invoice = data?.association
        .getPaymentSession()
        .getSessionInvoices()
        .getData()[0];

    const buyProductAndShipmentProductInvoice = data?.association
        .getPaymentSession()
        .getSessionInvoices()
        .getData();
    const regionCode = data?.getRegion()?.getCode() ?? "N/A";

    const currency = data?.getCurrency()?.code ?? "N/A";

    const handleShowAttachments = (attachments: IAttachment) => {
        setSelectedAttachments(attachments);
        setIsModalVisible(true);
    };

    if (!isLoading && isError) {
        return <CommonError message={isError} />;
    }

    return (
        <PageHeader
            ghost={false}
            title={data ? `Transaction - #${data.getTransactionNumber()}` : ""}
            subTitle={
                data ? (
                    <Space style={{ textTransform: "lowercase" }}>
                        <Tag
                            icon={
                                data.getStatus() === "pending" ? (
                                    <SyncOutlined spin />
                                ) : (
                                    <CheckCircleOutlined />
                                )
                            }
                            color={
                                data.getStatus() === "pending"
                                    ? "processing"
                                    : data.getStatus() === "rejected"
                                      ? "error"
                                      : "success"
                            }
                        >
                            {data.getStatus().replaceAll("-", " ")}
                        </Tag>
                    </Space>
                ) : (
                    ""
                )
            }
            onBack={() => window.history.back()}
        >
            <div style={{ margin: "10px" }}>
                {invoice && (
                    <BasicDetailsCard
                        title={
                            <Flex
                                style={{
                                    height: 60,
                                }}
                                justify="space-between"
                                align="center"
                            >
                                <Typography.Text>Basic Details</Typography.Text>
                                {checkActionPermission(
                                    PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_VIEW,
                                    <Flex
                                        gap={8}
                                        align="center"
                                        justify="center"
                                    >
                                        {data &&
                                            data.categorizeAttachments().images
                                                .length > 0 && (
                                                <Tooltip
                                                    title="View Payment Slip"
                                                    key={"view_details"}
                                                >
                                                    <Button
                                                        style={{
                                                            padding: 0,
                                                        }}
                                                        onClick={() =>
                                                            handleShowAttachments(
                                                                data.categorizeAttachments(),
                                                            )
                                                        }
                                                    >
                                                        <ImageWithPlaceholder
                                                            preview={false}
                                                            style={{
                                                                width: 40,
                                                                height: 40,
                                                                overflow:
                                                                    "hidden",
                                                                objectFit:
                                                                    "contain",
                                                            }}
                                                            src={`${data.categorizeAttachments().images[0]}`}
                                                        />
                                                    </Button>
                                                </Tooltip>
                                            )}

                                        {data &&
                                            data.categorizeAttachments().PDF
                                                .length > 0 &&
                                            data
                                                .categorizeAttachments()
                                                .PDF.map((url) => (
                                                    <DownloadPdfButton
                                                        pdfUrl={url}
                                                    />
                                                ))}
                                    </Flex>,
                                    null,
                                )}
                            </Flex>
                        }
                        invoice={invoice}
                        currency={currency}
                        region={regionCode}
                    />
                )}

                {buyProductAndShipmentProductInvoice && (
                    <InvoiceTable
                        invoice={buyProductAndShipmentProductInvoice}
                        currency={currency}
                        region={regionCode}
                    />
                )}
            </div>

            <Modal
                open={isModalVisible}
                title="Attachment Slip & Images"
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                onOk={() => {
                    setIsModalVisible(false);
                }}
            >
                {selectedAttachments &&
                selectedAttachments.images.length > 0 ? (
                    <>
                        <Image
                            src={`${selectedAttachments.images[0]}`}
                            style={{ marginBottom: 10, maxWidth: "100%" }}
                        />
                        {selectedAttachments.images.length > 1 && (
                            <Image.PreviewGroup>
                                {selectedAttachments.images
                                    .slice(1)
                                    .map((image, index) => (
                                        <Image
                                            key={index}
                                            src={image}
                                            style={{
                                                marginBottom: 10,
                                                maxWidth: "100%",
                                            }}
                                            alt={`Attachment ${index + 1}`}
                                        />
                                    ))}
                            </Image.PreviewGroup>
                        )}
                    </>
                ) : (
                    <p>No images selected</p>
                )}
            </Modal>
        </PageHeader>
    );
};

export default TransactionDetailSingle;
