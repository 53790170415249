import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useAtomValue } from "jotai";

import { useGetUnlistedProducts } from "@/lib/core-react/hooks/private/useShipping";
import { unlistedProductsAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import { IFilterType } from "@/types/filters";

import FiltersComponent from "@/components/FiltersComponent";

import {
    UnlistedPackageCollectionModel,
    UnlistedPackageModel,
} from "@/models/unlistedPackageCollectionModel";
import UnlistedPackagesTable from "./UnlistedPackagesTable";

const UnlistedPackages = () => {
    const { getUnlistedProducts } = useGetUnlistedProducts();

    const {
        data: unlistedPackagesCollectionData,
        isLoading,
        refetch,
    } = useAtomValue(unlistedProductsAtom);

    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<UnlistedPackageModel[]>(
        [],
    );
    const onClearBulkSelectedState = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getUnlistedProducts);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    const unlistedPackagesCollection =
        unlistedPackagesCollectionData &&
        new UnlistedPackageCollectionModel(unlistedPackagesCollectionData);

    const filterData = unlistedPackagesCollection?.filters;

    useEffect(() => {
        if (!isFetched && unlistedPackagesCollection?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        unlistedPackagesCollection?.filters,
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getUnlistedProducts,
        );
        onClearBulkSelectedState();
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        unlistedPackagesCollection,
        handlePaginationChange,
    );

    // Filter Handler
    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getUnlistedProducts);
        onClearBulkSelectedState();
    };

    return (
        <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Unclaimed Products"
            style={{ marginTop: "10px" }}
        >
            <FiltersComponent
                isLoading={isLoading}
                handleProductFilter={handleFilter}
                handleFilterChange={handleFilterChange}
                handelFilterClear={handelFilterClear}
                isFetched={isFetched}
                filters={filters}
                filtersData={filterData}
            />

            <UnlistedPackagesTable
                isOrderPage={false}
                data={unlistedPackagesCollection?.getData()}
                isLoading={isLoading}
                paginationConfig={paginationConfig}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                onClearBulkSelectedState={onClearBulkSelectedState}
            />
        </PageHeader>
    );
};

export default UnlistedPackages;
