import { Form, Button, notification } from "antd";
import { useDeleteCampaign } from "@/lib/core-react/hooks/private/useCampaign";
import { CampaignModel } from "@/models/campaignCollectionModel";
import { showError } from "@/helpers/showError";
import { useForm } from "antd/es/form/Form";
import { IModalData } from "@/helpers/getModalTital";

interface IProps {
    handleModal: (modal_data: IModalData) => void;
    selectedCampaign: CampaignModel;
}

const DeleteCampaign = ({ handleModal, selectedCampaign }: IProps) => {
    const { deleteCampaign, isLoading } = useDeleteCampaign();
    const [form] = useForm();

    const onConfirmDelete = async () => {
        try {
            await deleteCampaign(selectedCampaign.id);
            notification["success"]({
                message: "Successfully Deleted Campaign",
            });
            handleModal({ action: false, data: null });
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <>
            <Form form={form} layout="vertical">
                <Form.Item>
                    <h3>Are you sure you want to delete this Campaign?</h3>
                </Form.Item>

                <Form.Item>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            style={{ minWidth: "100px" }}
                            disabled={isLoading}
                            onClick={() =>
                                handleModal({ action: false, data: null })
                            }
                        >
                            No
                        </Button>
                        <Button
                            style={{ marginLeft: "10px", minWidth: "100px" }}
                            type="primary"
                            loading={isLoading}
                            onClick={onConfirmDelete}
                        >
                            Yes
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};

export default DeleteCampaign;
