const ImpersonateIcon = ({ size = 14, color = "currentColor", ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            {...props}
        >
            <path
                d="M23.1326 8.49677C21.4314 7.58038 19.6442 6.93911 17.671 7.00459C16.5408 7.04246 15.4464 7.31349 14.3522 7.57198C13.7478 7.7145 13.1435 7.85958 12.5423 8.01607C12.3589 8.06395 12.1786 8.0847 12 8.09162C11.8215 8.08502 11.6411 8.06395 11.4579 8.01607C10.8565 7.85958 10.2524 7.7145 9.6478 7.57198C8.55347 7.31371 7.45918 7.04246 6.32904 7.00459C4.35563 6.93911 2.56843 7.58056 0.867452 8.49677C0.0932519 8.91381 -0.108389 9.28388 0.0503891 9.96329C0.239221 10.7712 0.490785 11.5706 0.851611 12.3157C1.17197 12.9778 1.52302 13.6287 1.91435 14.2609C2.54815 15.2843 3.23323 16.2082 4.42959 16.6346C5.15572 16.8932 5.89665 17.0375 6.66515 16.9915C7.09324 16.9658 7.48108 16.784 7.86332 16.596C8.24293 16.409 8.63077 16.2366 8.97214 15.9841C9.31106 15.7331 9.67143 15.4994 9.96567 15.2022C9.96567 15.2022 11.1829 14.0265 12 13.8459C12.8176 14.0267 14.0343 15.2022 14.0343 15.2022C14.3286 15.4994 14.689 15.7331 15.0282 15.9841C15.3694 16.2366 15.7576 16.4088 16.137 16.596C16.5189 16.784 16.9068 16.9657 17.3352 16.9915C18.1037 17.0374 18.8445 16.8932 19.5707 16.6346C20.767 16.2084 21.4524 15.2843 22.0857 14.2609C22.4772 13.6289 22.828 12.9778 23.1485 12.3157C23.5091 11.5706 23.7607 10.7714 23.9497 9.96329C24.1083 9.28388 23.9066 8.91381 23.1326 8.49677ZM6.01967 13.0688C4.48386 12.5503 3.68716 10.8214 3.68716 10.8214C3.68716 10.8214 5.38067 9.93342 6.91649 10.4516C8.45231 10.9698 9.24868 12.6987 9.24868 12.6987C9.24868 12.6987 7.55527 13.587 6.01967 13.0688ZM17.9804 13.0688C16.4447 13.587 14.751 12.6988 14.751 12.6988C14.751 12.6988 15.5478 10.9699 17.0837 10.4517C18.6192 9.93346 20.3127 10.8215 20.3127 10.8215C20.3127 10.8215 19.5164 12.5503 17.9804 13.0688Z"
                fill="#FD384F"
            />
            <defs>
                <clipPath id="clip0_12302_17314">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ImpersonateIcon;
