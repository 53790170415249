import { Collapse, Flex, Form, Checkbox, Typography, Tooltip } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { PermissionModel } from "@/models/permissionCollectionModel";
import { InfoCircleOutlined } from "@ant-design/icons";
import { permissionTooltipContent } from "@/consts/permissionTooltipContent";

const { Text } = Typography;
const { Panel } = Collapse;

interface IProps {
    permission: [string, { [region: string]: PermissionModel[] }];
    checkedPermission: number[];
    getRegionalSelectItems: any;
    updateFinalPermissions: (
        group: string,
        regionGroup: string,
        data: number[],
    ) => void;
    activeChildKey: string[];
    setActiveChildKey: React.Dispatch<React.SetStateAction<string[]>>;
}

export const PermissionGroup = ({
    permission,
    checkedPermission,
    updateFinalPermissions,
    activeChildKey,
    setActiveChildKey,
    getRegionalSelectItems,
}: IProps) => {
    const groupName = permission[0];
    const regions = permission[1];
    const allGeneralPermissions = regions["general"] || [];

    const [selectedValues, setSelectedValues] =
        useState<number[]>(checkedPermission);
    const [activeKey, setActiveKey] = useState<string[]>([groupName]);

    useEffect(() => {
        // Initialize selected values with default permissions
        setSelectedValues(checkedPermission);
    }, [checkedPermission]);

    const isChecked = useCallback(
        (id: number) => selectedValues.includes(id),
        [selectedValues],
    );

    const handleSelectionChange = useCallback(
        (id: number, checked: boolean) => {
            const newSelections = checked
                ? [...selectedValues, id]
                : selectedValues.filter((val) => val !== id);
            setSelectedValues(newSelections);
            return newSelections;
        },
        [selectedValues],
    );

    const handleCheckAll = useCallback(
        (checked: boolean, regionGroup: string) => {
            const regionPermissions = regions[regionGroup].map((p) => p.id);
            const newSelections = checked
                ? [...new Set([...selectedValues, ...regionPermissions])]
                : selectedValues.filter(
                      (id) => !regionPermissions.includes(id),
                  );

            setSelectedValues(newSelections);
            updateFinalPermissions(groupName, regionGroup, newSelections);
        },
        [regions, groupName, selectedValues, updateFinalPermissions],
    );

    const handleIndividualChange = useCallback(
        (id: number, _checked: boolean, regionGroup: string) => {
            // const updatedValues = handleSelectionChange(id, checked);
            updateFinalPermissions(groupName, regionGroup, [id]);
        },
        [handleSelectionChange, groupName, updateFinalPermissions],
    );

    const preventCollapseToggle = (e: React.MouseEvent) => e.stopPropagation();

    const onCollapseChange = (key: string | string[]) =>
        setActiveKey(Array.isArray(key) ? key : [key]);

    const onChildCollapseChange = (key: string | string[]) =>
        setActiveChildKey(Array.isArray(key) ? key : [key]);

    return (
        <Form.Item>
            <Collapse activeKey={activeKey} onChange={onCollapseChange}>
                <Panel
                    header={
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                minWidth: "305px",
                            }}
                        >
                            <Checkbox
                                indeterminate={
                                    selectedValues.length > 0 &&
                                    selectedValues.length <
                                        allGeneralPermissions.length
                                }
                                checked={allGeneralPermissions.every((p) =>
                                    isChecked(p.id),
                                )}
                                onChange={(e) =>
                                    handleCheckAll(e.target.checked, "general")
                                }
                                onClick={preventCollapseToggle}
                            >
                                <span
                                    style={{
                                        fontWeight: 700,
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {groupName.split(/[-_]/).join(" ")}
                                </span>
                            </Checkbox>
                        </div>
                    }
                    key={groupName}
                >
                    <div style={{ marginBottom: 16 }}>
                        <Checkbox.Group
                            value={allGeneralPermissions
                                .map((p) => p.id)
                                .filter(isChecked)}
                            onChange={(checkedValues) =>
                                updateFinalPermissions(
                                    groupName,
                                    "general",
                                    checkedValues,
                                )
                            }
                        >
                            <Flex vertical>
                                {allGeneralPermissions.map((item) => (
                                    <div
                                        key={item.id}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Checkbox
                                            value={item.id}
                                            checked={isChecked(item.id)}
                                            onChange={(e) =>
                                                handleIndividualChange(
                                                    item.id,
                                                    e.target.checked,
                                                    "general",
                                                )
                                            }
                                            onClick={preventCollapseToggle}
                                        >
                                            <Text
                                                style={{
                                                    textTransform: "capitalize",
                                                    whiteSpace: "nowrap",
                                                }}
                                            >
                                                {item.label}
                                            </Text>
                                        </Checkbox>
                                        <Tooltip
                                            title={
                                                permissionTooltipContent[
                                                    item.name
                                                ] || `${item.name}`
                                            }
                                        >
                                            <InfoCircleOutlined
                                                style={{
                                                    marginLeft: 8,
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                ))}
                            </Flex>
                        </Checkbox.Group>
                    </div>

                    {Object.entries(regions)
                        .filter(([regionName]) => regionName !== "general")
                        .map(([regionName, permissions]) => (
                            <Collapse
                                key={regionName}
                                activeKey={activeChildKey}
                                onChange={onChildCollapseChange}
                                style={{ marginBottom: "10px" }}
                            >
                                <Panel
                                    header={
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                minWidth: "305px",
                                            }}
                                        >
                                            <Checkbox
                                                indeterminate={
                                                    getRegionalSelectItems(
                                                        regionName,
                                                        groupName,
                                                    ).some
                                                }
                                                checked={
                                                    getRegionalSelectItems(
                                                        regionName,
                                                        groupName,
                                                    ).every
                                                }
                                                onChange={(e) =>
                                                    handleCheckAll(
                                                        e.target.checked,
                                                        regionName,
                                                    )
                                                }
                                                onClick={preventCollapseToggle}
                                            >
                                                <span
                                                    style={{
                                                        fontWeight: 700,
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {regionName
                                                        .split(/[-_]/)
                                                        .join(" ")}
                                                </span>
                                            </Checkbox>
                                        </div>
                                    }
                                    key={`${groupName}-${regionName}`}
                                >
                                    <Checkbox.Group
                                        value={permissions
                                            .map((p) => p.id)
                                            .filter(isChecked)}
                                        onChange={(checkedValues) =>
                                            updateFinalPermissions(
                                                groupName,
                                                regionName,
                                                checkedValues,
                                            )
                                        }
                                    >
                                        <Flex vertical>
                                            {permissions.map((item) => (
                                                <div
                                                    key={item.id}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Checkbox
                                                        value={item.id}
                                                        checked={isChecked(
                                                            item.id,
                                                        )}
                                                        onChange={(e) =>
                                                            handleIndividualChange(
                                                                item.id,
                                                                e.target
                                                                    .checked,
                                                                regionName,
                                                            )
                                                        }
                                                    >
                                                        <Text
                                                            style={{
                                                                textTransform:
                                                                    "capitalize",
                                                                whiteSpace:
                                                                    "nowrap",
                                                            }}
                                                        >
                                                            {item.label}
                                                        </Text>
                                                    </Checkbox>
                                                    <Tooltip
                                                        title={
                                                            permissionTooltipContent[
                                                                item.name
                                                            ] || `${item.name}`
                                                        }
                                                    >
                                                        <InfoCircleOutlined
                                                            style={{
                                                                marginLeft: 8,
                                                                cursor: "pointer",
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            ))}
                                        </Flex>
                                    </Checkbox.Group>
                                </Panel>
                            </Collapse>
                        ))}
                </Panel>
            </Collapse>
        </Form.Item>
    );
};

export default React.memo(PermissionGroup);
