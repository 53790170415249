import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import {
    IPurchaseCommissionFormData,
    IPurchaseCommissionUpdateData,
} from "@/types/purchaseCommissionCollection";

class PurchaseCommissionResource extends BaseResource {
    // Purchase Commission
    addPurchaseCommission(
        agentCompanyId: number,
        payload: IPurchaseCommissionFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/purchase-commission/v1/${agentCompanyId}/purchase-commissions`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    // Purchase Commission
    updatePurchaseCommission(
        agentCompanyId: number,
        commission_id: number,
        payload: IPurchaseCommissionUpdateData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/purchase-commission/v1/${agentCompanyId}/purchase-commissions/${commission_id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    getPurchaseCommission(
        agentCompanyId: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase/admin/purchase-commission/v1/${agentCompanyId}/purchase-commissions?expand=store${
            params ? `&${params}` : ""
        }`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default PurchaseCommissionResource;
