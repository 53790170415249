import ProductApiService from "@/lib/core/apiServices/productApiService";
import { ProductResource } from "../../resources/private";

class ProductService extends ProductApiService {
    public productResource: ProductResource;

    constructor() {
        super();

        this.productResource = new ProductResource(this.config);
    }
}

export default ProductService;
