import { Button, Col, Form, Input, Row, Select } from "antd";
import DynamicImageInput from "../DynamicImageInput";
import { useEffect, useState } from "react";
import ExchangePart from "../ExchangePart";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";

interface IProps {
    formSubmit: (values: any) => void;
    initialData: any;
    current: number;
    prev: () => void;
    isLoading: boolean;
}
const ProductDetailsForm = ({
    formSubmit,
    initialData,
    current,
    prev,
    isLoading,
}: IProps) => {
    const [form] = Form.useForm();
    const {
        allOptionsData: { storeOptionsData, addonServiceOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const [showExchange, setShowExchange] = useState(
        initialData.exchange ? true : false,
    );

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.STORE);
        onFetchFilterApi(filterResourceEnum.ADDON_SERVICE_LIST);
    }, []);

    const selectedStoreId = Form.useWatch("store_id", form);
    const defaultCurrencyCodeBasedOnId =
        storeOptionsData.options.find(
            (store) => store.value === selectedStoreId,
        )?.default_currency_code || "";

    const onFinish = (values) => {
        const formData = {
            ...values,
        };

        if (defaultCurrencyCodeBasedOnId) {
            formData.default_currency_code = defaultCurrencyCodeBasedOnId;
        }

        // Exchange
        if (showExchange) {
            formData.exchange = values.exchange;
        } else {
            formData.exchange = undefined;
        }
        formSubmit(formData);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialData}
        >
            <Form.Item
                name="product_title"
                label="Product Title"
                rules={[
                    { required: true, message: "Please input Product Title!" },
                ]}
            >
                <Input placeholder="Enter Product Title" />
            </Form.Item>
            <Form.Item
                name="product_link"
                label="Product Link"
                rules={[
                    { required: true, message: "Please input Product Link!" },
                ]}
            >
                <Input placeholder="Enter Product Link" />
            </Form.Item>
            <div>
                <p>
                    <span
                        style={{
                            color: "#ff4d4f",
                            fontFamily: "SimSun,sans-serif",
                        }}
                    >
                        *
                    </span>{" "}
                    Product Image
                </p>
                <DynamicImageInput
                    fieldName="product_image"
                    placeOrder="Product image url"
                    form={form}
                />
            </div>

            <Row gutter={50}>
                <Col span={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Store is required",
                            },
                        ]}
                        name={"store_id"}
                        label="Store"
                    >
                        <Select
                            loading={storeOptionsData.isLoading}
                            style={{ width: "100%" }}
                            placeholder="Select store"
                            options={storeOptionsData.options}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    {/* Exchange */}
                    <ExchangePart
                        setShowExchange={setShowExchange}
                        showExchange={showExchange}
                        default_currency_code={defaultCurrencyCodeBasedOnId}
                        form={form}
                    />
                </Col>
            </Row>

            <Form.Item name={"addon_service_ids"} label="Addon Service IDs">
                <Select
                    mode="tags"
                    loading={addonServiceOptionsData.isLoading}
                    style={{ width: "100%" }}
                    placeholder="Add Addon Service IDs"
                    options={addonServiceOptionsData.options}
                />
            </Form.Item>

            <Form.Item name="instruction" label="Instruction">
                <Input.TextArea showCount maxLength={100} />
            </Form.Item>

            <Form.Item>
                {current > 0 && (
                    <Button
                        style={{
                            margin: "0 8px",
                        }}
                        onClick={() => prev()}
                        loading={isLoading}
                    >
                        Previous
                    </Button>
                )}
                <Button loading={isLoading} type="primary" htmlType="submit">
                    Save and Continue
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ProductDetailsForm;
