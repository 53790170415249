import { HarvestJobResource } from "@/lib/core/resources/private";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";

class HarvestJobService extends CommerceApiService {
    public harvestJobResource: HarvestJobResource;

    constructor() {
        super();

        this.harvestJobResource = new HarvestJobResource(this.config);
    }
}
export default HarvestJobService;
