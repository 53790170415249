import { LoginFlow, UpdateLoginFlowBody } from "@ory/client";
import { useEffect, useState } from "react";
import { Row, Col, Spin } from "antd";
import logoImg from "/logo.svg";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import "./Login.css";
import { handleFlowError, handleGetFlowError } from "../pkg/errors";
import { AxiosError } from "axios";
import { Flow, LogoutLink, Values } from "../pkg";
import ory from "../pkg/sdk";
import { Typography } from "antd";
import { useLoginUserPermissionAndSetInLocalStorage } from "@/lib/core-react/hooks/private/usePermission";

const Login = () => {
    const { getCurrentUserPermissions } =
        useLoginUserPermissionAndSetInLocalStorage();
    const [flow, setFlow] = useState<LoginFlow | undefined>();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const location = useLocation();
    const returnTo = searchParams.get("return_to");
    const flowId = searchParams.get("flow");
    const refresh = searchParams.get("refresh");
    const aal = searchParams.get("aal");

    const onLogout = LogoutLink([aal, refresh]);

    useEffect(() => {
        if (!location || flow) {
            return;
        }

        if (flowId) {
            ory.getLoginFlow({ id: flowId })
                .then(({ data }) => {
                    setFlow(data);
                })
                .catch(handleGetFlowError(navigate, "login", setFlow));
            return;
        }
        ory.createBrowserLoginFlow({
            refresh: Boolean(refresh),
            aal: aal ? String(aal) : undefined,
            returnTo: returnTo ? String(returnTo) : undefined,
        })
            .then(({ data }) => {
                setFlow(data);
            })
            .catch(handleFlowError(navigate, "login", setFlow));
    }, [flowId, navigate, location, aal, refresh, returnTo, flow]);
    // }, [])

    const onSubmit = async (values: Partial<Values>): Promise<void> => {
        navigate(`/auth/login?flow=${flow?.id}`, { replace: true });
        // On submission, add the flow ID to the URL but do not navigate. This prevents the user loosing
        // his data when she/he reloads the page.

        ory.updateLoginFlow({
            flow: String(flow?.id),
            updateLoginFlowBody: values as UpdateLoginFlowBody,
        })
            // We logged in successfully! Let's bring the user home.
            .then(async () => {
                await getCurrentUserPermissions();

                if (flow?.return_to) {
                    window.location.href = flow?.return_to;
                    return;
                }
                navigate("/");
            })
            .then(() => {})
            .catch(handleFlowError(navigate, "login", setFlow))
            .catch((err: AxiosError) => {
                // If the previous handler did not catch the error it's most likely a form validation error
                if (err.response?.status === 400) {
                    // Yup, it is!
                    setFlow(err.response?.data);
                    return;
                }

                return Promise.reject(err);
            });
    };

    if (flow) {
        return (
            <Row
                justify="space-around"
                align="middle"
                style={{ background: "#fff", height: "100vh" }}
            >
                <Col xs={24} md={6} sm={8}>
                    <div
                        style={{
                            textAlign: "center",
                            padding: "30px",
                            marginTop: "50px",
                            marginBottom: "20px",
                        }}
                    >
                        <Link to={"/auth/login"}>
                            <img src={logoImg} alt="logo" />
                        </Link>
                    </div>
                    <div>
                        <div>
                            <Typography.Title
                                level={2}
                                style={{ margin: 0, textAlign: "center" }}
                            >
                                {(() => {
                                    if (flow?.refresh) {
                                        return "Confirm Action";
                                    } else if (flow?.requested_aal === "aal2") {
                                        return "Two-Factor Authentication";
                                    }
                                    return "Sign In";
                                })()}
                            </Typography.Title>
                            <Flow onSubmit={onSubmit} flow={flow} />
                        </div>
                        {aal || refresh ? (
                            <div>
                                <button
                                    data-testid="logout-link"
                                    onClick={onLogout}
                                >
                                    Log out
                                </button>
                            </div>
                        ) : null}
                    </div>
                </Col>
            </Row>
        );
    } else {
        return <Spin spinning={true} fullscreen={true} />;
    }
};

export default Login;

// deploy permission test
