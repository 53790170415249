import { PayoutRequestModel } from "@/models/payoutRequestCollectionModel";
import { ICashOutExcelRow } from "./CustomerWithdrawalHistory/CustomerWithdrawalHistory";

export const getExcelRows = (
    PayoutRequestModel: PayoutRequestModel,
): ICashOutExcelRow => {
    const row: ICashOutExcelRow = {
        request_number: PayoutRequestModel.request_number,
        customer_name: PayoutRequestModel.getCustomer()?.getName() || "Unknown",
        requested_amount: PayoutRequestModel.getRequestedAmount(),
        refund_method: PayoutRequestModel.getPayoutAccount()
            .getPayoutGateway()
            .getName(),
        account_holderName:
            PayoutRequestModel.getPayoutAccount().getAccountData()
                ?.account_name ||
            PayoutRequestModel.getPayoutAccount().getAccountData()
                ?.account_holder_name ||
            "N/A",
        bank_name:
            PayoutRequestModel.getPayoutAccount().getAccountData()?.bank_name ||
            "N/A",
        refund_number:
            PayoutRequestModel.getPayoutAccount().getAccountData()
                .account_number,
        routing_number:
            PayoutRequestModel.getPayoutAccount().getAccountData()
                .routing_number || "N/A",
    };

    return row;
};
