import { IAgentWarehouse } from "@/types/agentWarehouseCollection";
import {
    Address,
    IAgentWarehouseAddress,
    IShipmentProductAssociationSlot,
    IShipmentProductCustomerAssociation,
} from "@/types/shipmentProductCustomerAssociation";
import { AgentWarehouseModel } from "./buyOrderCollectionModel";
import { AgentCompanyModel } from "./shippingAddressModel";

export class AgentWarehouseAddressModel {
    public readonly object: string;
    public readonly id: number;
    public readonly address: Address;

    constructor(data: IAgentWarehouseAddress) {
        this.object = data.object;
        this.id = data.id;
        this.address = data.address;
    }

    getWarehouseLocation() {
        return `${this.address.display_address.state || ""}, ${this.address.display_address.city || ""}, ${this.address.display_address.street_address || ""}`;
    }
    getWarehouseCountry() {
        return this.address.address.country;
    }
}

export class ShipmentProductAssociationSlotModel {
    id: number;
    min_amount: number;
    max_amount: number;
    rate: number;
    created_at: string;

    constructor(payload: IShipmentProductAssociationSlot) {
        this.id = payload.id;
        this.min_amount = payload.min_amount;
        this.max_amount = payload.max_amount;
        this.rate = payload.rate;
        this.created_at = payload.created_at;
    }

    getId(): number {
        return this.id;
    }

    getMinAmount(): number {
        return this.min_amount;
    }

    getMaxAmount(): number {
        return this.max_amount;
    }

    getRate(): number {
        return this.rate;
    }

    getCreatedAt(): string {
        return this.created_at;
    }
}

export class ShipmentProductCustomerAssociationModel {
    object: "ShipmentProductCustomerAssociation";
    id: number;
    unit_type: string;
    shipping_type: string;
    shipping_mode: string;
    rate: number;
    weight: number;
    piece: number;
    cbm: number;
    agent_company: AgentCompanyModel;
    warehouse: IAgentWarehouse;
    warehouse_address: IAgentWarehouseAddress;
    association_slots: ShipmentProductAssociationSlotModel[];

    constructor(data: IShipmentProductCustomerAssociation) {
        this.object = data.object;
        this.id = data.id;
        this.unit_type = data.unit_type;
        this.shipping_mode = data.shipping_mode;
        this.shipping_type = data.shipping_type;
        this.rate = data.rate;
        this.weight = data.weight;
        this.piece = data.piece;
        this.cbm = data.cbm;
        this.agent_company = new AgentCompanyModel(data.agent_company);
        this.association_slots = data.association_slots.data.map(
            (s) => new ShipmentProductAssociationSlotModel(s),
        );
        this.warehouse_address = data.warehouse_address;
        this.warehouse = data.warehouse;
    }

    getId = () => this.id;
    getAgentCompany = () => this.agent_company;
    getUnitType = () => this.unit_type;
    getShippingMode = () => this.shipping_mode;
    getShippingType = () => this.shipping_type;
    getAssociationSlots = () => this.association_slots;
    getRate = () => this.rate;
    getWeight = () => this.weight;
    getPiece = () => this.piece;
    getCbm = () => this.cbm;
    getWarehouse = () => new AgentWarehouseModel(this.warehouse);
    getWarehouseAddress = () =>
        new AgentWarehouseAddressModel(this.warehouse_address);
}
