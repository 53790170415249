import { ITranslationEntry } from "../lang";

export const LT: ITranslationEntry = {
    translations: {
        notifications: "Pranešimai",
        markAllAsRead: "Pažymėti visus kaip skaitytus",
        poweredBy: "Sukurta",
        settings: "Nustatymai",
        noNewNotification: "Čia dar nieko naujo nematyti",
    },
    lang: "lt",
};
