import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";
import { CampaignDataModel } from "@/models/campaignStripModel";

export const useGetStripeSlugCampaignMinimal = () => {
    const { stripiService } = useService();
    const [strapiSlugCampaignOptionData, setStrapiSlugCampaign] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getStrapSlugCampaignMinimal = async (
        params?: string,
    ): Promise<void> => {
        setStrapiSlugCampaign((prev) => ({ ...prev, isLoading: true }));
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            const response =
                await stripiService.strapiResource.getSlugCampaign(params);

            const options =
                response && response.data
                    ? new CampaignDataModel(response.data).getOptions()
                    : [];

            setStrapiSlugCampaign({
                isLoading: false,
                options,
            });
        } catch (error: any) {
            setStrapiSlugCampaign({
                ...strapiSlugCampaignOptionData,
                isLoading: false,
            });
            throw error;
        }
    };
    return {
        getStrapSlugCampaignMinimal,
        strapiSlugCampaignOptionData,
    } as const;
};
