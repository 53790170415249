import AppContextProvider from "./AppContextProvider";
import AppThemeProvider from "./AppThemeProvider";
import { useThemeContext } from "./AppContextProvider/ThemeContextProvider";
import { Normalize } from "styled-normalize";
import React, { ReactElement } from "react";
import { GlobalStyles } from "../layout/GlobalStyle";
import { App, ConfigProvider } from "antd";
import { tw } from "@/consts/theme/tailwindTheme";

const AppProviders: React.FC<{ children?: ReactElement }> = ({ children }) => {
    const { theme } = useThemeContext();
    return (
        <AppContextProvider>
            <AppThemeProvider>
                <>
                    <GlobalStyles theme={theme} />
                    <Normalize />
                    <ConfigProvider
                        typography={{
                            style: {
                                margin: 0,
                                padding: 0,
                            },
                        }}
                        card={{
                            styles: {
                                body: { padding: 16 },
                            },
                            style: {
                                borderRadius: tw.borderRadius.lg,
                                boxShadow: tw.boxShadow.DEFAULT,
                                border: tw.border.none,
                            },
                        }}

                        // theme={{ token: { colorPrimary: "green" } }}
                    >
                        <App>{children}</App>
                    </ConfigProvider>
                </>
            </AppThemeProvider>
        </AppContextProvider>
    );
};

export default AppProviders;
