import { Typography } from "antd";
import { Flex } from "antd";
interface LabelWithLoadingProps {
    label: string;
    isLoading?: boolean;
    gap?: number;
}

const FacetCheckboxFormTitle = ({ label, gap = 12 }: LabelWithLoadingProps) => {
    return (
        <Flex align="center" gap={gap}>
            <Typography.Paragraph strong>{label}</Typography.Paragraph>
            {/* {isLoading && (
                <Spin indicator={<LoadingOutlined spin  />} size="small" />
            )} */}
        </Flex>
    );
};

export default FacetCheckboxFormTitle;
