import { ITranslationEntry } from "../lang";

export const GU: ITranslationEntry = {
    translations: {
        notifications: "સૂચના",
        markAllAsRead: "બધાને વાંચેલા તરીકે ચિહ્નિત કરો",
        poweredBy: "દ્વારા સંચાલિત",
        settings: "સેટિંગ્સ",
        noNewNotification: "હજુ સુધી અહીં જોવા માટે કંઈ નવું નથી",
    },
    lang: "gu",
};
