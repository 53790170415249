import {
    BuyProductsResource,
    PurchaseCommissionResource,
} from "../../resources/private";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";

class PurchaseService extends CommerceApiService {
    public buyProductsResource: BuyProductsResource;
    public purchaseCommissionResource: PurchaseCommissionResource;
    constructor() {
        super();

        this.buyProductsResource = new BuyProductsResource(this.config);
        this.purchaseCommissionResource = new PurchaseCommissionResource(
            this.config,
        );
    }
}

export default PurchaseService;
