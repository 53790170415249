import { DestinationWarehouseAddressModalType } from "@/types/destinationWarehouseCollection";

function getKeyByValue(value: string): string {
    const key =
        Object.keys(DestinationWarehouseAddressModalType).find(
            (key) =>
                DestinationWarehouseAddressModalType[
                    key as keyof typeof DestinationWarehouseAddressModalType
                ] === value,
        ) || "";

    return key.replaceAll("_", " ");
}

export { getKeyByValue };
