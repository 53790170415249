import { BREAK_POINTS } from "@/consts/appConstants";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";

export type FixedType = "left" | "right" | boolean;
export type AlignType =
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";

interface Props {
    title: string | undefined;
    width: number | undefined;
    fixed: FixedType;
    align: AlignType;
    key: string;
}

const useActionsProps = () => {
    const { width } = useWindowWidth();

    const props: Props = {
        title: width > BREAK_POINTS.XL ? "Actions" : "A",
        width: width > BREAK_POINTS.XL ? 90 : 60,
        fixed: width <= BREAK_POINTS.XL ? "right" : false,
        align: "center",
        key: "actions",
    };
    return props;
};

export default useActionsProps;
