import { MenuDataItem } from "@ant-design/pro-layout";
import { FormInstance } from "antd/lib";
import RenderAuthorize from "@/components/Authorized";
import {
    MOVEON_ADMIN_PERMISSIONS_STORAGE_ITY_KEY,
    MOVEON_ADMIN_TOKEN_KEY,
    MOVEON_ADMIN_USER_KEY,
    SUPER_ADMIN,
} from "@/consts/storage";
import { InvoiceStatus } from "@/models/invoiceCollectionModel";
import { IDisplayAddress } from "@/types/buyOrderDetail";
import {
    AllStatusEnum,
    InvoiceItemStatusEnum,
    OrderTypeEnum,
} from "@/types/InvoiceCollection";
import { IImpersonateSessionData, IUser } from "@/types/userCollection";
import { notification } from "antd";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import { BaseShippingCategoryDataModel } from "@/models/baseShippingCategory";
import { ShippingCategoryModel } from "@/models/shippingCategory";
import { InventoryCategoryModel } from "@/models/inventoryCollectionModel";

export const getStatusColor = (status: string): string => {
    if (!status) {
        return "default";
    }

    const colorMap: Record<string, string> = {
        pending: "#108ee9",
        approved: "#87d068",
        rejected: "#cd201f",
        reverted: "#f50",
    };

    return colorMap[status.toLowerCase()] || "#87d068";
};

export const processPriceValue = (value: string | number) => {
    const numberValue = Math.ceil(Number(value));
    return String(numberValue);
};

export const generateColor = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hex = (hash & 0x00ffffff).toString(16).toUpperCase();
    return "#" + "00000".substring(0, 6 - hex.length) + hex;
};

type Status =
    | InvoiceItemStatusEnum
    | OrderTypeEnum
    | AllStatusEnum
    | InvoiceStatus
    | string;

export const getStatusBadgeColor = (status: Status): string => {
    switch (status) {
        case AllStatusEnum.UNPAID:
            return "red";
        case AllStatusEnum.PARTIAL_PAID:
            return "#FFEFEE";
        case AllStatusEnum.PAID:
            return "#00893c";
        case AllStatusEnum.UNSETTLED:
            return "blue";
        case AllStatusEnum.PARTIALLY_SETTLED:
            return "purple";
        case AllStatusEnum.SETTLED:
            return "cyan";
        case AllStatusEnum.AWAITING_FULFILMENT:
            return "gold";
        case AllStatusEnum.FULFILLED:
            return "lime";
        case AllStatusEnum.PARTIALLY_REFUNDED:
            return "magenta";
        case AllStatusEnum.REFUNDED:
            return "volcano";
        case AllStatusEnum.ONLY_SHIP:
            return "#0098B9";
        case AllStatusEnum.BUY_AND_SHIP:
            return "#527be7";
        case "pending":
            return "lime";
        case "open-for-everyone":
        case "active":
            return "#24b263";
        default:
            return "gray";
    }
};

export const capitalize = (text: string | undefined | null): string => {
    if (!text) return "";

    return text.toLowerCase().replace(/(^|\s)\S/g, function (match) {
        return match.toUpperCase();
    });
};

export const truncateTitle = (title: string, maxLength: number) => {
    if (title && title.length > maxLength) {
        return title.substring(0, maxLength) + "...";
    }
    return title;
};

/**
 * Whether the current pathname exists in the route, if so, returns the current object, if not, it is undefined
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = <T extends MenuDataItem>(
    router: T[] = [],
    pathname: string,
): T | undefined => {
    const authority = router.find(
        ({ children, path = "/" }) =>
            (path && path === pathname) ||
            (children && getAuthorityFromRouter(children, pathname)),
    );
    if (authority) return authority;
    return undefined;
};

export function getAuthority(str?: string): string | string[] {
    const authorityString =
        typeof str === "undefined" && localStorage
            ? localStorage.getItem(MOVEON_ADMIN_PERMISSIONS_STORAGE_ITY_KEY)
            : str;
    let authority;
    try {
        if (authorityString) {
            authority = JSON.parse(authorityString);
        }
    } catch (_e) {
        authority = authorityString;
    }

    // If it is a string, return an array
    if (typeof authority === "string") {
        return [authority];
    }
    if (!authority) {
        return [];
    }
    return authority;
}

/**
 * Setting Authority
 * @param authority
 */
export function setAuthority(authority: string | string[]): void {
    const proAuthority =
        typeof authority === "string" ? [authority] : authority;
    localStorage.setItem(
        MOVEON_ADMIN_PERMISSIONS_STORAGE_ITY_KEY,
        JSON.stringify(proAuthority),
    );
    // auto reload
    reloadAuthorized();
}

export let Authorized = RenderAuthorize(getAuthority());

export const reloadAuthorized = (): void => {
    Authorized = RenderAuthorize(getAuthority());
};

export const getUserTokenFromLocalStorage = () => {
    return localStorage.getItem(MOVEON_ADMIN_TOKEN_KEY);
};
export const getUserFromLocalStorage = (): IUser | null => {
    const userJon = localStorage.getItem(MOVEON_ADMIN_USER_KEY);
    if (userJon) {
        return JSON.parse(userJon) as IUser;
    } else {
        return null;
    }
};

export const getUserPermissionFromLocalStorage = (): string[] => {
    const authority = localStorage.getItem(
        MOVEON_ADMIN_PERMISSIONS_STORAGE_ITY_KEY,
    );
    return authority ? (JSON.parse(authority) as string[]) : [];
};

export const setCurrentUserPermissionsInLocalStorage = (
    permissions: string[],
) => {
    localStorage.setItem(
        MOVEON_ADMIN_PERMISSIONS_STORAGE_ITY_KEY,
        JSON.stringify(permissions),
    );
    reloadAuthorized();
};

export const removeCurrentUserPermissionsFromStorage = () => {
    localStorage.removeItem(MOVEON_ADMIN_PERMISSIONS_STORAGE_ITY_KEY);
};
export const setCurrentUserSuperAdmin = () => {
    localStorage.setItem(SUPER_ADMIN, SUPER_ADMIN);
};

export const removeSuperAdminKey = () => {
    localStorage.removeItem(SUPER_ADMIN);
};

export const getIsCurrentUserSuperAdmin = () => {
    return localStorage.getItem(SUPER_ADMIN);
};
export const removeSuperAdminFromLocalStorage = () => {
    localStorage.removeItem(SUPER_ADMIN);
};
/**
 * Handles setting form errors from an API response.
 *
 * @param {FormInstance} form - The Ant Design form instance.
 * @param {Object} error - The error response object from the API.
 */
export const handleFormErrors = (form: FormInstance, error) => {
    if (error.response?.data?.errors) {
        const errors = error.response.data.errors;
        const errorFields = Object.keys(errors).map((field) => ({
            name: field,
            errors: errors[field],
        }));
        form.setFields(errorFields);
    }
};

export const getEnvironmentMode = (): "development" | "production" | string => {
    return import.meta.env.VITE_NODE_ENV || "production";
};

export const formatAddress = (
    address: IDisplayAddress,
    region: string[],
): string => {
    const {
        street_address,
        address_1,
        address_2,
        street,
        district,
        city,
        state,
        postal_code,
        country,
        area,
    } = address;

    const formattedAddress = street_address
        ? street_address
        : `${address_1} ${address_2}`;

    const postCode = postal_code ? postal_code : ``;

    if (region?.includes("BD")) {
        return `${formattedAddress}, ${area ?? ""}, ${district ?? ""} ${postCode ?? ""}, ${country ?? ""}`;
    }
    if (region?.includes("US")) {
        return `${formattedAddress}, ${city ?? ""}, ${state ?? ""}, ${country ?? ""}`;
    }
    if (region?.includes("AE")) {
        return `${address_1} ${address_2}, ${area ?? ""}, ${district ?? ""}-${postal_code ?? ""}, ${country ?? ""}`;
    }
    return `${street}, ${city}, ${state} ${postal_code}, ${country}`;
};

export const downloadImpersonateCredentialsAsTextFile = (
    response: IImpersonateSessionData,
) => {
    if (response) {
        const text = `Email: ${response?.email}\nPassword: ${atob(response.password)}`;
        const element = document.createElement("a");
        const file = new Blob([text], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "ImpersonateCredentials.txt";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        notification.success({
            message: "Download Impersonate Credentials Successfully",
            placement: "bottomLeft",
        });
    } else {
        notification.error({
            message: "Email and password is missing. Please try again.",
        });
    }
};

export const formatString = (input: string | null | undefined): string =>
    input
        ? input
              .toLowerCase()
              .replace(/[_-]+/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase())
        : "";

export const getStoreCommission = (
    fx_rate: number,
    commission_rate: number,
    commission_rate_type: CommissionRateTypeEnums,
): string => {
    let total: number = 0;

    if (!fx_rate || !commission_rate || !commission_rate_type) {
        return "0";
    }

    if (commission_rate_type === CommissionRateTypeEnums.PERCENTAGE) {
        total = fx_rate + (fx_rate * commission_rate) / 100;
        return `${total.toFixed(2)}%`;
    } else if (commission_rate_type === CommissionRateTypeEnums.FIXED) {
        total = fx_rate + commission_rate;
        return total.toFixed(2);
    }
    return total.toFixed(2);
};

export const checkNestedChildrenAndMakeItNull = (
    data:
        | BaseShippingCategoryDataModel[]
        | ShippingCategoryModel[]
        | InventoryCategoryModel[],
):
    | BaseShippingCategoryDataModel[]
    | ShippingCategoryModel[]
    | InventoryCategoryModel[] => {
    return data.map((item) => {
        if (item.children && item.children.length > 0) {
            return {
                ...item,
                children: checkNestedChildrenAndMakeItNull(item.children),
            };
        } else {
            return {
                ...item,
                children: null,
            };
        }
    });
};
