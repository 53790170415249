import { ITranslationEntry } from "../lang";

export const FR: ITranslationEntry = {
    translations: {
        notifications: "Notifications",
        markAllAsRead: "Tout marquer comme lus",
        poweredBy: "Propulsé par",
        settings: "Paramètres",
        noNewNotification: "Rien de nouveau à voir ici pour le moment",
    },
    lang: "fr",
};
