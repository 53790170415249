import {
    useGetProductById,
    useGetProductByUrl,
} from "@/lib/core-react/hooks/private/useProduct";
import { Button, Card, Flex, Form, notification, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { Input } from "antd";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { defaultQuery } from "@/consts/defaultQuery";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { inventoryProductDetailAtom } from "@/lib/core-react/store/store";

import { useState } from "react";
import { showSuccessAlert } from "@/helpers/showSuccess";

const MATCH = {
    PRODUCTS: "products",
};

type IAction = "refresh" | "search";

const OriginalProductLink = () => {
    const [form] = useForm();
    const { getProductDetailById, isLoading } = useGetProductById();
    const { getProductDetailByUrl, isLoading: isLoadingUrl } =
        useGetProductByUrl();

    const [productDetailResult] = useAtom(inventoryProductDetailAtom);
    const [vendorLink, setVendorLink] = useState<string | null>(null);
    const [actionType, setActionType] = useState<IAction>("search");

    const onFinish = (values) => {
        if (actionType === "search") {
            handleSearch(values.product_link, "search");
        } else if (actionType === "refresh") {
            handleSearch(values.product_link, "refresh");
        }
    };

    const handleSearch = async (submittedUrl, action: IAction) => {
        try {
            if (submittedUrl) {
                const urlParams = new URLSearchParams(
                    new URL(submittedUrl).search,
                );
                const embeddedUrl = urlParams.get("url");

                if (embeddedUrl) {
                    setVendorLink(embeddedUrl);
                    getProductDetailByUrl({
                        url: embeddedUrl,
                        region: defaultQuery.region,
                        locale: defaultQuery.locale,
                        apply_exchange: 1,
                        refresh: 1,
                    });

                    showSuccessAlert("Successfully Product Refresh");
                    form.resetFields();

                    return;
                }

                const regex = /^[A-Z0-9]{13}/;
                const match = submittedUrl.match(regex);
                const trimUrl = submittedUrl.trim().split("/").filter(Boolean);
                const lastPart = match
                    ? match[0]
                    : trimUrl
                          .filter(Boolean)
                          .map((slug, index) => {
                              if (slug === MATCH.PRODUCTS) {
                                  return trimUrl[index + 1].split("-")[0];
                              }
                          })
                          .filter(Boolean)
                          .join("");

                if (!lastPart) {
                    throw new Error(
                        "Please Provide a Valid Moveon Product Detail URL",
                    );
                }

                const firstPart = trimUrl[trimUrl.length - 1];
                const productId = `${firstPart}${lastPart}`;
                if (action === "refresh") {
                    await getProductDetailById(productId, {
                        region: defaultQuery.region,
                        locale: defaultQuery.locale,
                        apply_exchange: 1,
                        refresh: 1,
                    });
                    showSuccessAlert("Successfully Product Refresh");
                    form.resetFields();
                } else {
                    await getProductDetailById(productId, {
                        region: defaultQuery.region,
                        locale: defaultQuery.locale,
                        apply_exchange: 1,
                    });
                    form.resetFields();
                }
            }
        } catch (error) {
            notification.error({
                message: getErrorMessage(error),
            });
        }
    };

    const handleValuesChange = () => {
        // Clear vendorLink whenever the input value changes
        setVendorLink(null);
    };

    return (
        <>
            <Flex
                vertical
                gap={24}
                style={{
                    marginTop: 20,
                }}
            >
                <Form
                    onFinish={onFinish}
                    onValuesChange={handleValuesChange}
                    form={form}
                >
                    <Flex gap={12}>
                        <Form.Item
                            style={{ width: "100%" }}
                            name={"product_link"}
                        >
                            <Input
                                style={{ width: "100%" }}
                                placeholder="Paste Moveon Product URL"
                                allowClear
                            />
                        </Form.Item>

                        <Button
                            loading={isLoading || isLoadingUrl}
                            type="primary"
                            htmlType="submit"
                            onClick={() => setActionType("search")}
                        >
                            Search
                        </Button>

                        <Button
                            loading={isLoading || isLoadingUrl}
                            type="dashed"
                            htmlType="submit"
                            onClick={() => setActionType("refresh")}
                        >
                            Refresh
                        </Button>
                    </Flex>
                </Form>

                {actionType === "search" &&
                    (vendorLink || productDetailResult) && (
                        <Flex gap={16} vertical>
                            <Flex vertical gap={12}>
                                <Card>
                                    <Flex vertical gap={8}>
                                        <Typography>
                                            <Typography.Text>
                                                Original Link:{" "}
                                            </Typography.Text>
                                            <Link
                                                target="_blank"
                                                to={`${vendorLink || productDetailResult?.getVendorLink()}`}
                                            >
                                                <Typography.Link
                                                    copyable
                                                    strong
                                                >
                                                    {vendorLink ||
                                                        productDetailResult?.getVendorLink()}
                                                </Typography.Link>
                                            </Link>
                                        </Typography>
                                    </Flex>
                                </Card>
                            </Flex>
                        </Flex>
                    )}
            </Flex>
        </>
    );
};

export default OriginalProductLink;
