import {
    Avatar,
    Table,
    TableColumnsType,
    Tooltip,
    Image,
    Button,
    Tag,
} from "antd";
import { InvoiceTransactionAssociation } from "@/types/InvoiceCollection";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation
import { ArrowRightOutlined } from "@ant-design/icons";

interface IProps {
    data: InvoiceTransactionAssociation[];
    currency: string;
    userId: number | null | undefined;
}

const TransactionsAssociationTable = ({ data, currency, userId }: IProps) => {
    const columns: TableColumnsType<InvoiceTransactionAssociation> = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_: string, record: InvoiceTransactionAssociation) => {
                return <div>{record.id}</div>;
            },
        },
        {
            title: "Type",
            key: "type",
            render: (_: any, record: InvoiceTransactionAssociation) => {
                if (record.payment_transaction) {
                    return <Tag>{"Payment Transaction"}</Tag>;
                } else if (record.payment_gateway) {
                    return <Tag>{"Payment GateWay"}</Tag>;
                } else if (record.wallet) {
                    return <Tag color="green">{"Wallet"}</Tag>;
                }
            },
        },
        {
            title: "Associations",
            key: "associations",
            render: (_: any, record: InvoiceTransactionAssociation) => {
                if (record.payment_transaction) {
                    const {
                        transaction_number,
                        requested_amount,
                        amount,
                        reference,
                    } = record.payment_transaction;
                    return (
                        <div>
                            <div>
                                Transaction Number:{" "}
                                <Tooltip title={transaction_number}>
                                    <Link
                                        to={`/payment-transaction/${transaction_number}`}
                                    >
                                        <span style={{ fontWeight: "bold" }}>
                                            {transaction_number}
                                        </span>
                                    </Link>
                                </Tooltip>
                            </div>
                            <div>
                                Requested Amount:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {currency} {requested_amount}
                                </span>
                            </div>
                            <div>
                                Approved Amount:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {currency} {amount}
                                </span>
                            </div>
                            <div>
                                Reference:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                    {reference}
                                </span>
                            </div>
                        </div>
                    );
                } else if (record.payment_gateway) {
                    const { name, identifier, logos } = record.payment_gateway;
                    const logoUrl = logos?.data
                        .map((logo) => logo?.small?.src)
                        .find((url) => url);
                    return (
                        <div>
                            <div>Gateway Name: {name}</div>
                            <div>Identifier: {identifier}</div>
                            {logoUrl && (
                                <Avatar
                                    shape="circle"
                                    size={70}
                                    style={{ objectFit: "contain" }}
                                    src={<Image src={logoUrl} />}
                                />
                            )}
                        </div>
                    );
                } else if (record.wallet) {
                    return (
                        <div>
                            <Button
                                type="dashed"
                                onClick={() =>
                                    window.open(
                                        `/customer/user/${userId}/wallet`,
                                        "_blank",
                                    )
                                }
                                style={{ marginTop: 5 }}
                                icon={<ArrowRightOutlined />}
                            >
                                Go to Wallet
                            </Button>
                        </div>
                    );
                }
            },
        },
        {
            title: "Amount",
            key: "amount",
            render: (_: any, record: InvoiceTransactionAssociation) => {
                return (
                    <div>
                        {currency} {record?.amount}
                    </div>
                );
            },
        },
    ];

    return (
        <Table
            rowHoverable={false}
            bordered
            columns={columns}
            dataSource={data}
            pagination={false}
            rowKey={(r) => r.id}
            scroll={{ x: 600 }}
        />
    );
};

export default TransactionsAssociationTable;
