import {
    ICartContext,
    IPurchaseCart,
    IPurchaseCartCollection,
    IPurchaseCartExtended,
    IPurchaseCartItem,
    IPurchaseCartItemCollection,
    IPurchaseCartItemMoveonShipping,
    IPurchaseCartItemVariation,
    IPurchaseCartItemVariationCollection,
} from "@/types/PurchaseCartCollection";
import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import { RegionModel } from "./regionCollectionModel";
import { UserModel } from "./userCollectionModel";
import { CurrencyModel } from "./currencyCollectionModel";
import { IAgentCompany } from "@/types/payoutRequestCollection";
import { IDestinationWarehouse } from "@/types/destinationWarehouseCollection";
import { IShippingCategory } from "@/types/buyActionCollection";

export class PurchaseCartItemMoveonShippingModel {
    public readonly object: string;
    public readonly id: number;
    public readonly shipping_type: string;
    public readonly shipping_mode: string;
    public readonly unit_type: string;
    public readonly rate: number;
    public readonly agent_warehouse: IAgentCompany;
    public readonly destination_warehouse: IDestinationWarehouse;
    public readonly agent_category_price_read_id: number;
    public readonly shipping_category: IShippingCategory;

    constructor(data: IPurchaseCartItemMoveonShipping) {
        this.object = data.object;
        this.id = data.id;
        this.shipping_type = data.shipping_type;
        this.shipping_mode = data.shipping_mode;
        this.unit_type = data.unit_type;
        this.rate = data.rate;
        this.agent_warehouse = data.agent_warehouse;
        this.destination_warehouse = data.destination_warehouse;
        this.agent_category_price_read_id = data.agent_category_price_read_id;
        this.shipping_category = data.shipping_category;
    }

    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getAgentWarehouse() {
        return this.agent_warehouse;
    }

    getDestinationWarehouse() {
        return this.destination_warehouse;
    }

    getShippingCategory() {
        return this.shipping_category;
    }

    getShippingType(): string {
        return this.shipping_type;
    }

    getShippingMode(): string {
        return this.shipping_mode;
    }

    getUnitType(): string {
        return this.unit_type;
    }

    getRate(): number {
        return this.rate;
    }

    getAgentCategoryPriceReadId(): number {
        return this.agent_category_price_read_id;
    }
}

export class PurchaseCartItemVariationModel {
    public readonly object: string;
    public readonly id: number;
    public readonly sku_id: string;
    public readonly sku_original_id: string;
    public readonly image: string;
    public readonly quantity: number;
    public readonly unit_price: number;
    public readonly total_price: number;
    public readonly original_unit_price: number;
    public readonly original_total_price: number;

    constructor(data: IPurchaseCartItemVariation) {
        this.object = data.object;
        this.id = data.id;
        this.sku_id = data.sku_id;
        this.sku_original_id = data.sku_original_id;
        this.image = data.image;
        this.quantity = data.quantity;
        this.unit_price = data.unit_price;
        this.total_price = data.total_price;
        this.original_unit_price = data.original_unit_price;
        this.original_total_price = data.original_total_price;
    }

    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getSkuId(): string {
        return this.sku_id;
    }

    getSkuOriginalId(): string {
        return this.sku_original_id;
    }

    getImage(): string {
        return this.image;
    }

    getQuantity(): number {
        return this.quantity;
    }

    getUnitPrice(): number {
        return this.unit_price;
    }

    getTotalPrice(): number {
        return this.total_price;
    }

    getOriginalUnitPrice(): number {
        return this.original_unit_price;
    }

    getOriginalTotalPrice(): number {
        return this.original_total_price;
    }
}

export class PurchaseCartItemVariationCollectionModel {
    public readonly object: string;
    public readonly data: PurchaseCartItemVariationModel[];

    constructor(data: IPurchaseCartItemVariationCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new PurchaseCartItemVariationModel(item),
        );
    }

    getObject(): string {
        return this.object;
    }

    getData(): PurchaseCartItemVariationModel[] {
        return this.data;
    }
}

export class PurchaseCartItemModel {
    public readonly object: string;
    public readonly id: number;
    public readonly product_id: string;
    public readonly seller_id: string;
    public readonly seller_name: string;
    public readonly sort_order: number;
    public readonly product_title: string;
    public readonly product_image: string;
    public readonly total_quantity: number;
    public readonly includes_tax: number;
    public readonly sub_total: number;
    public readonly original_sub_total: number;
    public readonly original_total: number;
    public readonly total: number;
    public readonly discount_total: number;
    public readonly fx_rate: number;
    public readonly commission_rate: number;
    public readonly commission_rate_type: string;
    public readonly tax_total: number;
    public readonly shipping_provider: string;
    public readonly moveon_shipping: PurchaseCartItemMoveonShippingModel;
    public readonly variations: PurchaseCartItemVariationModel[];
    public readonly created_at: string;

    constructor(data: IPurchaseCartItem) {
        this.object = data.object;
        this.id = data.id;
        this.product_id = data.product_id;
        this.seller_id = data.seller_id;
        this.seller_name = data.seller_name;
        this.sort_order = data.sort_order;
        this.product_title = data.product_title;
        this.product_image = data.product_image;
        this.total_quantity = data.total_quantity;
        this.includes_tax = data.includes_tax;
        this.sub_total = data.sub_total;
        this.original_sub_total = data.original_sub_total;
        this.original_total = data.original_total;
        this.total = data.total;
        this.discount_total = data.discount_total;
        this.fx_rate = data.fx_rate;
        this.commission_rate = data.commission_rate;
        this.commission_rate_type = data.commission_rate_type;
        this.tax_total = data.tax_total;
        this.shipping_provider = data.shipping_provider;
        this.moveon_shipping = new PurchaseCartItemMoveonShippingModel(
            data.moveon_shipping,
        );
        this.created_at = data.created_at;
        this.variations = data.variations.data.map(
            (item) => new PurchaseCartItemVariationModel(item),
        );
    }

    getId(): number {
        return this.id;
    }

    getProductId(): string {
        return this.product_id;
    }

    getSellerId(): string {
        return this.seller_id;
    }

    getSellerName(): string {
        return this.seller_name;
    }

    getSortOrder(): number {
        return this.sort_order;
    }

    getProductTitle(): string {
        return this.product_title;
    }

    getProductImage(): string {
        return this.product_image;
    }

    getTotalQuantity(): number {
        return this.total_quantity;
    }

    getIncludesTax(): number {
        return this.includes_tax;
    }

    getSubTotal(): number {
        return this.sub_total;
    }

    getOriginalSubTotal(): number {
        return this.original_sub_total;
    }

    getOriginalTotal(): number {
        return this.original_total;
    }

    getTotal(): number {
        return this.total;
    }

    getDiscountTotal(): number {
        return this.discount_total;
    }

    getFxRate(): number {
        return this.fx_rate;
    }

    getCommissionRate(): number {
        return this.commission_rate;
    }

    getCommissionRateType(): string {
        return this.commission_rate_type;
    }

    getTaxTotal(): number {
        return this.tax_total;
    }

    getShippingProvider(): string {
        return this.shipping_provider;
    }

    getMoveonShipping(): PurchaseCartItemMoveonShippingModel {
        return this.moveon_shipping;
    }

    getVariations(): PurchaseCartItemVariationModel[] {
        return this.variations;
    }
}

export class PurchaseCartItemCollectionModel {
    public readonly object: string;
    public readonly data: PurchaseCartItemModel[];

    constructor(data: IPurchaseCartItemCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new PurchaseCartItemModel(item));
    }

    getData() {
        return this.data;
    }
}

export class PurchaseCartModel {
    id: number;
    guest_id: number | null;
    user?: UserModel;
    region: RegionModel;
    currency?: CurrencyModel;
    cart_type: string;
    note: string;
    context: ICartContext;
    created_at: string;

    public readonly items: PurchaseCartItemModel[];

    constructor(data: IPurchaseCart) {
        this.id = data.id;
        this.guest_id = data.guest_id;
        this.user = data.user ? new UserModel(data.user) : undefined;
        this.region = new RegionModel(data.region);
        this.currency = data.currency
            ? new CurrencyModel(data.currency)
            : undefined;
        this.cart_type = data.cart_type;
        this.context = {
            ip: data.context?.ip,
            user_agent: data.context?.user_agent,
            app_context: data.context?.app_context,
        };
        this.note = data.note;
        this.items = data.items.data.map(
            (item) => new PurchaseCartItemModel(item),
        );
        this.created_at = data.created_at;
    }

    getId() {
        return this.id;
    }

    getCurrency() {
        return this.currency;
    }

    getUser() {
        return this.user;
    }

    getRegion() {
        return this.region;
    }

    getCartType(): string {
        return this.cart_type;
    }

    getContext() {
        return this.context;
    }

    getNote(): string {
        return this.note;
    }

    getItems() {
        return this.items;
    }

    getCreatedAt(): string {
        return this.created_at;
    }
}

export class PurchaseCartCollectionModel {
    public readonly object: string;
    public readonly data: PurchaseCartModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: IPurchaseCartCollection) {
        this.object = data.object;
        this.data = data.data.map((cart) => new PurchaseCartModel(cart));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

    getData() {
        return this.data;
    }
    getProcessData(): IPurchaseCartExtended[] {
        return this.getData().flatMap((purchaseCart) =>
            purchaseCart.getItems().map((cart) => ({
                ...cart,
                user: purchaseCart.getUser(),
                region: purchaseCart.getRegion(),
                currency: purchaseCart.getCurrency(),
                guest_id: purchaseCart.guest_id,
                cart_type: purchaseCart.cart_type,
                note: purchaseCart.getNote(),
                created_at: cart.created_at,
                purchaseCartId: purchaseCart.id,
                purchaseCartItemId: cart.id,
            })),
        ) as unknown as IPurchaseCartExtended[];
    }

    getAdminCartData() {
        return this.getProcessData().filter(
            (item) => item.cart_type === "impersonate-cart",
        );
    }

    getCustomerCartData() {
        return this.getProcessData().filter(
            (item) => item.cart_type !== "impersonate-cart",
        );
    }

    getPagination(): IPagination {
        return this.pagination;
    }

    getFilters(): IFilterType {
        return this.filters;
    }
}
