import { ITranslationEntry } from "../lang";

export const CS: ITranslationEntry = {
    translations: {
        notifications: "Oznámení",
        markAllAsRead: "Označit všechna jako přečtená",
        poweredBy: "Přináší",
        settings: "Nastavení",
        noNewNotification: "Zatím tu není nic nového k vidění",
    },
    lang: "cs",
};
