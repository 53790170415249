import React, { memo, useMemo } from "react";
import ShippingText from "@/components/ShippingText";
import { IDisplayAddress } from "@/types/buyOrderDetail";
import { Typography } from "antd";
import { Flex } from "antd";

interface FormattedAddressProps {
    address: IDisplayAddress;
}

const FormattedAddress: React.FC<FormattedAddressProps> = ({ address }) => {
    const addressData = useMemo(() => {
        const data: [string, any][] = [];

        // Push the main fields in a specific order if they exist
        if (address.first_name) {
            data.push(["first_name", address.first_name]);
        }
        if (address.last_name) {
            data.push(["last_name", address.last_name]);
        }
        if (address.phone) {
            data.push(["phone", address.phone]);
        }
        if (address.email) {
            data.push(["email", address.email]);
        }
        // Add any other fields in the address object
        Object.entries(address).forEach(([key, value]) => {
            if (!["first_name", "last_name", "phone", "email"].includes(key)) {
                data.push([key, value]);
            }
        });
        return data;
    }, [address]);

    const addressElements = addressData.map(([label, value]) => (
        <ShippingText key={label} label={label.replace(/_/g, " ")}>
            {["phone", "email"].includes(label) ? (
                <Typography.Text
                    strong
                    copyable
                    style={{ textTransform: "capitalize" }}
                >
                    {value}
                </Typography.Text>
            ) : (
                <Typography.Text strong style={{ textTransform: "capitalize" }}>
                    {value}
                </Typography.Text>
            )}
        </ShippingText>
    ));

    return <Flex vertical>{addressElements}</Flex>;
};

export default memo(FormattedAddress);
