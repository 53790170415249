import TrackResource from "@/lib/core/resources/private/trackResource";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
class TrackService extends CommerceApiService {
    public trackResource: TrackResource;

    constructor() {
        super();

        this.trackResource = new TrackResource(this.config);
    }
}

export default TrackService;
