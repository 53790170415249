import { useService } from "@/lib/core-react";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import { AgentInvoiceDetailsModel } from "@/models/invoiceCollectionModel";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Result, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicDetailsCard from "../component/basicDetailsCard";
import InvoiceItem from "../component/invoiceItem";
import InvoiceTransaction from "../component/invoiceTransaction";

const AgentInvoiceDetails = () => {
    const navigate = useNavigate();
    const { invoiceService } = useService();
    const { id: invoiceId } = useParams<{ id: string }>();

    const [invoiceDetails, setInvoiceDetails] = useState<{
        isLoading: boolean;
        error: string | null;
        data: AgentInvoiceDetailsModel | undefined;
    }>({
        isLoading: true,
        error: null,
        data: undefined,
    });

    useEffect(() => {
        if (invoiceId) {
            getInvoiceDetailsById(invoiceId);
        }
    }, [invoiceId]);

    const getInvoiceDetailsById = async (
        id: number | string,
    ): Promise<void> => {
        setInvoiceDetails((prev) => ({
            ...prev,
            isLoading: true,
            error: null,
        }));

        try {
            const response =
                await invoiceService.invoiceResource.getAgentInvoiceDetails(id);
            setInvoiceDetails((prev) => ({
                ...prev,
                data: new AgentInvoiceDetailsModel(response.data),
                isLoading: false,
                error: null,
            }));
        } catch (error: any) {
            const errorMessage = getError(error);
            setInvoiceDetails((prev) => ({
                ...prev,
                isLoading: false,
                error: errorMessage,
            }));
        }
    };

    if (invoiceDetails.isLoading) {
        return <Skeleton active paragraph={{ rows: 20 }} />;
    }

    if (invoiceDetails.error) {
        return (
            <Result
                status="500"
                title="Please wait"
                subTitle={invoiceDetails.error || "Something went wrong."}
                extra={
                    <Button type="primary" onClick={() => navigate(-1)}>
                        Back Home
                    </Button>
                }
            />
        );
    }

    if (!invoiceDetails.data) {
        return (
            <Result
                status="404"
                title="No Invoice Found"
                subTitle="The invoice data might be missing or incorrect."
                extra={
                    <Button type="primary" onClick={() => navigate(-1)}>
                        Back Home
                    </Button>
                }
            />
        );
    }

    return (
        <div>
            <PageHeader
                onBack={() => navigate(-1)}
                title={`Invoice: ${invoiceDetails.data.getInvoiceNumber()}`}
                subTitle=""
            />
            {/* <AgentCompanyCard agentCompany={invoiceDetails.data.getAgentCompany()} /> */}
            <BasicDetailsCard
                detailsFor={"agent"}
                invoice={invoiceDetails.data}
                agentCompany={invoiceDetails?.data?.getAgentCompany()}
            />
            <InvoiceItem invoice={invoiceDetails.data} />
            <InvoiceTransaction
                transactionFor={"agent"}
                invoice={invoiceDetails.data}
            />
        </div>
    );
};

export default AgentInvoiceDetails;
