import React, { useState } from "react";
import {
    Button,
    Space,
    Table,
    Tag,
    Tooltip,
    Modal,
    Input,
    Form,
    notification,
    Spin,
    Row,
    Col,
    Tabs,
} from "antd";
import type { TableColumnsType } from "antd";
import { DisputeMandatesModel } from "@/models/disputeDetailsCollectionModel";
import { DisputeMandateStatusEnum } from "@/enums/disputeCollectionEnum";
import {
    AgreeMendedPayload,
    useAgreeMandate,
} from "@/lib/core-react/hooks/private/useDispute";
import { showError } from "@/helpers/showError";
import { Typography } from "antd";
import { useParams } from "react-router-dom";
const { Title, Text } = Typography;

interface MandatesTableProps {
    mandates: DisputeMandatesModel[];
    currency: string;
}

const MandatesTable: React.FC<MandatesTableProps> = ({
    mandates,
    currency,
}) => {
    // State for modal and selected mandate
    const { id: disputeId } = useParams();
    const parsedId: number = Number(disputeId);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedMandate, setSelectedMandate] =
        useState<DisputeMandatesModel | null>(null);
    const [form] = Form.useForm();
    const [value, setValue] = useState(0);
    const [activeTab, setActiveTab] = useState("1");

    const { agreeMandate, isLoading: isAgreeMandateLoading } =
        useAgreeMandate();

    const handleTabChange = (key: string) => {
        setActiveTab(key);
        form.resetFields();
        setValue(0);
    };

    // Handle modal submit
    const handleModalSubmit = () => {
        form.validateFields().then(async (values) => {
            if (selectedMandate) {
                try {
                    const assigned_amount =
                        activeTab === "1"
                            ? Number(values.agreeAmount)
                            : Number(values.customerReceive) -
                              (selectedMandate.getMoveonProfitPercentage() *
                                  Number(values.customerReceive)) /
                                  (100 +
                                      selectedMandate.getMoveonProfitPercentage());

                    const payload: AgreeMendedPayload = {
                        agreed_assigned_amount: Number(
                            assigned_amount.toFixed(2),
                        ),
                        mandate_id: selectedMandate._id,
                    };
                    await agreeMandate(parsedId, payload);
                    notification.success({
                        message: "Agreed mandate successfully",
                    });
                    setModalOpen(false);
                    form.resetFields();
                } catch (error) {
                    showError(error, form);
                }
            }
        });
    };

    const renderDataRow = (label, value) => (
        <Row style={{ marginBottom: 6 }}>
            <Col span={12}>
                <Text>{label}</Text>
            </Col>
            <Col span={12}>
                <Text>
                    {value} {currency}
                </Text>
            </Col>
        </Row>
    );
    //
    const renderContent = () => {
        if (isAgreeMandateLoading) {
            return (
                <div style={{ textAlign: "center", padding: "20px" }}>
                    <Spin size="large" />
                </div>
            );
        }

        const renderPurchaseDetails = () => {
            if (!selectedMandate) return null;

            const moveonProfitPercentage =
                selectedMandate.getMoveonProfitPercentage();
            let agentAgreed = 0;
            let moveonProfit = 0;
            let customerReceive = 0;

            if (activeTab === "1") {
                // Agent Agreement Tab Logic
                agentAgreed = value;
                moveonProfit = (moveonProfitPercentage * value) / 100;
                customerReceive = agentAgreed + moveonProfit;
            } else if (activeTab === "2") {
                // Customer Receive Tab Logic
                customerReceive = value;
                moveonProfit =
                    (moveonProfitPercentage * value) /
                    (100 + moveonProfitPercentage);
                agentAgreed = customerReceive - moveonProfit;
            }

            return (
                <>
                    <Title
                        level={5}
                        style={{
                            marginBottom: 8,
                        }}
                    >
                        Overview
                    </Title>
                    {renderDataRow(
                        "Agent Agreed:",
                        Number(agentAgreed).toFixed(2),
                    )}
                    {renderDataRow(
                        `MoveOn Agreed: (${Number(moveonProfitPercentage).toFixed(2)}%)`,
                        moveonProfit.toFixed(2),
                    )}
                    {renderDataRow(
                        "Customer Receive:",
                        Number(customerReceive).toFixed(2),
                    )}
                </>
            );
        };

        return <>{renderPurchaseDetails()}</>;
    };

    // Handle modal cancel
    const handleModalCancel = () => {
        setModalOpen(false);
        form.resetFields();
    };

    const columns: TableColumnsType<DisputeMandatesModel> = [
        {
            title: "Mandate To",
            dataIndex: "mandate_to",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space style={{ fontWeight: 700 }}>
                        {record?.getMandateTo()?.split("-")?.join(" ")}
                    </Space>
                );
            },
        },

        {
            title: "status",
            dataIndex: "status",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        <Tag
                            color={
                                record?.getStatus() ===
                                DisputeMandateStatusEnum.AGREED
                                    ? "success"
                                    : "warning"
                            }
                        >
                            {record.getStatus()}
                        </Tag>
                    </Space>
                );
            },
        },
        {
            title: "Agent Company",
            dataIndex: "agent_company",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {record?.getAgentCompany()?.getPrimaryName()
                            ? record?.getAgentCompany()?.getPrimaryName()
                            : "N/A"}
                    </Space>
                );
            },
        },
        {
            title: "Total Assigned",
            dataIndex: "total_assigned",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {Number(record?.getTotalAssigned())?.toFixed(2) ||
                            "N/A"}
                        {currency}
                    </Space>
                );
            },
        },
        {
            title: "Customer Total Cost",
            dataIndex: "customer_total_cost",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {Number(record?.getCustomerTotalCost())?.toFixed(2) ||
                            "N/A"}
                        {currency}
                    </Space>
                );
            },
        },
        {
            title: "Moveon Total Profit",
            dataIndex: "moveon_total_profit",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {Number(record?.getMoveonTotalProfit())?.toFixed(2) ||
                            "N/A"}
                        {currency}
                    </Space>
                );
            },
        },
        {
            title: "Moveon Profit Percentage",
            dataIndex: "moveon_profit_percentage",
            key: "_id",
            width: 200,
            render: (_tags: any, record) => {
                return (
                    <Space>
                        {Number(record?.getMoveonProfitPercentage())?.toFixed(
                            2,
                        ) + "%" || "N/A"}
                        {currency}
                    </Space>
                );
            },
        },

        {
            title: "Action",
            dataIndex: "action",
            key: "_id",
            width: 100,
            fixed: "right",
            render: (_tags: any, record) => {
                const canAgree = record?.getUiActions()?.can_agree;
                return (
                    <Tooltip
                        title={!canAgree ? "You can't agree this mandate" : ""}
                    >
                        <Button
                            disabled={!canAgree}
                            loading={isAgreeMandateLoading}
                            type={canAgree ? "primary" : "default"}
                            size="middle"
                            style={{ marginRight: 10 }}
                            onClick={() => {
                                setSelectedMandate(record);
                                setModalOpen(true);
                            }}
                        >
                            Agree
                        </Button>
                    </Tooltip>
                );
            },
        },
    ];

    return (
        <>
            <p
                style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                    padding: 0,
                    marginTop: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 20,
                }}
            >
                Mandates
            </p>
            <Table
                style={{ maxHeight: 280, overflow: "auto", marginTop: 0 }}
                scroll={{ x: "100%" }}
                rowKey="_id"
                bordered={true}
                columns={columns}
                dataSource={mandates}
                pagination={false}
                loading={false}
            />

            {/* Agreement Amount Modal */}
            <Modal
                title="Enter Agreement Amount"
                open={modalOpen}
                onOk={handleModalSubmit}
                onCancel={handleModalCancel}
                okText="Submit"
                confirmLoading={isAgreeMandateLoading}
            >
                <Form form={form} layout="vertical">
                    <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                        <Tabs.TabPane tab="Agent Agreement" key="1">
                            <Form.Item
                                label="Agent Agreement Amount:"
                                name="agreeAmount"
                                required={true}
                            >
                                <Input
                                    type="number"
                                    placeholder="Enter amount"
                                    addonAfter={currency}
                                    step="0.01"
                                    onChange={(e) =>
                                        setValue(Number(e.target.value))
                                    }
                                    max={selectedMandate?.getTotalAssigned()}
                                    required={true}
                                />
                            </Form.Item>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Customer Receive" key="2">
                            <Form.Item
                                label="Customer Receive Amount:"
                                name="customerReceive"
                                required={true}
                            >
                                <Input
                                    type="number"
                                    placeholder="Enter amount"
                                    addonAfter={currency}
                                    step="0.01"
                                    onChange={(e) =>
                                        setValue(Number(e.target.value))
                                    }
                                    max={selectedMandate?.getTotalAssigned()}
                                    required={true}
                                />
                            </Form.Item>
                        </Tabs.TabPane>
                    </Tabs>
                    {selectedMandate && (
                        <div>
                            <Title level={5}>Mandate Details</Title>
                            {renderDataRow(
                                "Total Assigned",
                                Number(
                                    selectedMandate.getTotalAssigned(),
                                )?.toFixed(2),
                            )}
                            {renderDataRow(
                                "Customer Total Cost",
                                Number(
                                    selectedMandate.getCustomerTotalCost(),
                                )?.toFixed(2),
                            )}
                        </div>
                    )}
                    {renderContent()}
                </Form>
            </Modal>
        </>
    );
};

export default MandatesTable;
