import { Checkbox, Flex, Form, Space, Typography } from "antd";
import { tw } from "@/consts/theme/tailwindTheme";
import { IRatingRangeData } from "@/types/productSearchResult";
import { AcceptInventoryProductsQueryKeys } from "@/consts/appConstants";
import { useEffect, useState } from "react";
import { FacetRangeFieldModel } from "@/models/ProductSearchCollectionModel";
import { useForm } from "antd/es/form/Form";
import usePageQuery from "@/helpers/usePageQuery";
import { useAtom } from "jotai";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import FacetCheckboxFormTitle from "./FacetCheckboxFormTitle";
import { productSearchQueryAtom } from "@/lib/core-react/store/inventoryProductsAtoms";
import Ratings from "@/pages/ProductManage/newComponents/SearchBox/ratings";

interface IProps {
    facetFields: FacetRangeFieldModel[] | undefined;
    payloadKey: AcceptInventoryProductsQueryKeys;
}

const RatingAverageRangeFacet = ({ facetFields }: IProps) => {
    const [form] = useForm();

    const { updateQuery, getCurrentQuery } = usePageQuery();

    const [fieldsData, setFieldsData] = useState<IRatingRangeData[]>();

    const [productSearchQuery, setProductSearchQuery] = useAtom(
        productSearchQueryAtom,
    );

    const { rating_avg } = productSearchQuery;

    useEffect(() => {
        if ((rating_avg && rating_avg.max) || rating_avg.min) {
            setProductSearchQuery((prev) => ({ ...prev, rating_avg }));
        }
    }, []);

    useEffect(() => {
        if (facetFields) {
            setFieldsData(facetFields);
        }
    }, [facetFields]);

    const currentQuery = getCurrentQuery();
    useEffect(() => {
        if (!currentQuery.rating_avg) {
            form.resetFields();
        }
    }, [currentQuery]);

    const onChange = (
        _isChecked: CheckboxChangeEvent,
        selected: IRatingRangeData,
    ) => {
        if (fieldsData) {
            const updateData = fieldsData.map((item) => {
                if (item.name === selected.name) {
                    form.setFieldValue(item.name, true);
                    return { ...item, isChecked: true };
                } else {
                    form.resetFields([item.name]);
                    return { ...item, isChecked: false };
                }
            });

            const selectedRating = updateData.find(
                (item) => item.isChecked === true,
            );

            if (selectedRating) {
                const rating_avg = {
                    min: selectedRating.from,
                    max: selectedRating.to,
                };

                updateQuery({ rating_avg });
                setProductSearchQuery((prev) => ({ ...prev, rating_avg }));
            }
        }
    };

    return (
        <Space direction="vertical" size={"small"}>
            <FacetCheckboxFormTitle label={"Rating"} />
            <Form form={form}>
                <Flex vertical gap={tw.spacing[2]}>
                    {fieldsData &&
                        fieldsData.map((data) => {
                            return (
                                <Form.Item
                                    key={data.name}
                                    noStyle
                                    name={`${data?.name}`}
                                >
                                    <Checkbox
                                        id={`${data?.name}`}
                                        checked={form.getFieldValue(data.name)}
                                        onChange={(e) => onChange(e, data)}
                                    >
                                        <Flex gap={6} align="center">
                                            {data.from && (
                                                <Ratings
                                                    rating={data.from}
                                                    width={14}
                                                />
                                            )}
                                            <Typography.Paragraph>
                                                {data.from}
                                            </Typography.Paragraph>
                                            <Typography.Paragraph>
                                                ({data.count})
                                            </Typography.Paragraph>
                                        </Flex>
                                    </Checkbox>
                                </Form.Item>
                            );
                        })}
                </Flex>
            </Form>
        </Space>
    );
};
export default RatingAverageRangeFacet;
