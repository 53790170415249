import {
    IShipmentOrder,
    IShipmentOrderCollection,
} from "@/types/shipmentOrderCollection";

import { IFilterType } from "@/types/filters";
import { PaginationModel } from "./paginationModel";
import { statusLabel } from "./statusLabel";
import { ShipmentProductModel } from "./shipmentProductCollectionModel";
import { CustomerUserModel } from "./customerUserCollectionModel";

export class ShipmentOrderModel {
    object: string;
    id: number;
    order_number: string;
    created_at: string;
    customer: CustomerUserModel;
    products: ShipmentProductModel[];

    constructor(data: IShipmentOrder) {
        this.object = data.object;
        this.id = data.id;
        this.order_number = data.order_number;
        this.created_at = data.created_at;
        this.products = data.products.data.map(
            (p) => new ShipmentProductModel(p),
        );
        this.customer = new CustomerUserModel(data.customer);
    }

    getId = () => this.id;
    getOrderNumber = () => this.order_number;
    getCreatedAt = () => this.created_at;
    getProducts = () => this.products;
    getCustomer = () => this.customer;
    hasProductWithDueInvoice = () => {
        return this.products.some((product) => {
            const transactionSummary = product
                .getCustomerInvoice()
                ?.getTransactionSummary();
            const due = transactionSummary?.due;
            return typeof due === "number" && due > 0;
        });
    };
}
export class ShipmentOrderCollectionModel {
    object: string;
    data: ShipmentOrderModel[];
    filters: IFilterType;
    pagination: PaginationModel;

    constructor(data: IShipmentOrderCollection) {
        this.object = data.object;
        this.filters = data.filters;
        this.pagination = new PaginationModel(data.pagination);
        this.data = data.data.map((item) => new ShipmentOrderModel(item));
    }

    getData = () => this.data;
    getFilters = () => this.filters;
    getPagination = () => this.pagination;

    getOrders() {
        return this.data.map((order) => {
            return {
                key: order.getId(),
                orderId: order.getOrderNumber(),
                customerName: order.getCustomer().getName(),
                customerPhone: order.getCustomer().getPhone(),
                customerEmail: order.getCustomer().getEmail(),
                customerId: order.getCustomer().getId(),
                shippingMark: order.getCustomer().getShippingMark(),
                createdAt: order.getCreatedAt(),
                products: order.getProducts(),
            };
        });
    }

    static getStatusLabel(value: string) {
        return statusLabel.find((item) => item.value === value);
    }
}
