import { UiText } from "@ory/client";

import { Typography } from "antd";

interface MessageProps {
    message: UiText;
}

export const Message = ({ message }: MessageProps) => {
    return (
        <Typography.Text
            type={
                message.type === "error"
                    ? "danger"
                    : message.type === "success"
                      ? "success"
                      : "warning"
            }
            data-testid={`ui/message/${message.id}`}
            style={{ paddingTop: "8px", paddingBottom: "4px" }}
        >
            {message.text}
        </Typography.Text>
    );
};

interface MessagesProps {
    messages?: Array<UiText>;
}

export const Messages = ({ messages }: MessagesProps) => {
    if (!messages) {
        // No messages? Do nothing.
        return null;
    }

    return (
        <div>
            {messages.map((message) => (
                <>
                    <Message key={message.id} message={message} />
                    <br />
                </>
            ))}
        </div>
    );
};
