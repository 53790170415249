import { useEffect, useMemo, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useAtomValue, useSetAtom } from "jotai";
import useDataFilters from "@/hooks/useDataFilters";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import { IFilterType } from "@/types/filters";

import FiltersComponent from "@/components/FiltersComponent";
import { HarvestActionEnums } from "@/enums/harvestJobCollectionEnums";
import { useGetBuyProductsForInvoice } from "@/lib/core-react/hooks/private/usePurchase";
import { ColumnsType } from "antd/es/table";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { BuyProductInfo } from "../../components";
import { Table } from "antd/lib";
import { BREAK_POINTS } from "@/consts/appConstants";
import {
    Alert,
    Button,
    Card,
    Dropdown,
    Flex,
    Form,
    Modal,
    notification,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import ShippingText from "@/components/ShippingText";
import { StatusTag } from "@/components";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import InvoiceSummaryTransaction from "../../../Shipping/ShipmentProducts/InvoiceSummary/InvoiceSummaryTransaction";
import useActionsProps from "@/helpers/useActionsProps";
import { HddFilled } from "@ant-design/icons";
import useActionsInvoice from "@/pages/Shipping/ShipmentProducts/InvoiceSummary/useActionsInvoice";
import { showError } from "@/helpers/showError";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { RegionModel } from "@/models/regionCollectionModel";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import InvoiceInfoBuyProduct from "./InvoiceInfoBuyProduct";
import { parseObjectToString } from "@/helpers/stringify";
import CommonError from "@/components/Error/CommonError";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { buyProductInvoiceCollectionAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";

const InvoiceSummaryBuyProduct = () => {
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();
    const {
        data: buyProductCollectionModel,
        refetch,
        isLoading: isBuyProductLoading,
        error,
        code,
    } = useAtomValue(buyProductInvoiceCollectionAtom);

    const setBuyProductInvoiceCollection = useSetAtom(
        buyProductInvoiceCollectionAtom,
    );
    const { getBuyProductsInvoice } = useGetBuyProductsForInvoice();
    const actionsProps = useActionsProps();
    const { isMobile } = useWindowWidth();
    const [form] = Form.useForm();

    const { paySession, isLoading: isLoadingSession } = useInvoice();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<BuyProductModel[]>([]);

    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
    }>();
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);
    const handleModal = async (payload: IModalData) => {
        try {
            setModalOpenType(payload.action);
            switch (payload.action) {
                case "pay-invoice":
                    setSelectedPayInvoiceData(payload.data);
                    break;
                case "refetch":
                    getBuyProductsInvoice(
                        parseObjectToString(filters as IFilterType),
                    );
                    setBuyProductInvoiceCollection((prev) => ({
                        ...prev,
                        refetch: true,
                    }));
                    break;
                default:
                    break;
            }
        } catch (error) {
            showError(error);
        }
    };

    const onCancel = () => {
        setModalOpenType(false);
        setSelectedPayInvoiceData(undefined);
    };
    const agentInvoiceIds = useMemo(() => {
        return selectedRows.reduce<number[]>((acc, row) => {
            const id = row.getAgentInvoiceId();
            if (id !== undefined) {
                acc.push(id);
            }
            return acc;
        }, []);
    }, [selectedRows]);

    const { getActions, getBulkActions } = useActionsInvoice({
        handleModal,
        agentInvoiceIds,
    });

    const onClearBulkSelectedState = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };
    const onChangeRow = (
        selectedRowKeys: React.Key[],
        rows: BuyProductModel[],
    ) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(rows);
    };

    //-------------------------- Api Call-------------------------------
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getBuyProductsInvoice);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    const filterData = buyProductCollectionModel?.getFilters();

    useEffect(() => {
        if (!isFetched && filterData) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, filterData]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getBuyProductsInvoice,
        );
        onClearBulkSelectedState();
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        buyProductCollectionModel,
        handlePaginationChange,
    );

    // Filter Handler
    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getBuyProductsInvoice);
        onClearBulkSelectedState();
    };

    //-------------------------- Logic---------------------------------

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, wallet_id, ...restValues } = values;
                if (
                    typeof wallet_id === "undefined" &&
                    typeof gateway_id === "undefined"
                ) {
                    notification.error({
                        message: "Please select a gateway or wallet",
                    });
                    return;
                }

                const key = gateway_id ? "gateway_id" : "wallet_id";
                const id = gateway_id || wallet_id;

                const payload = {
                    amount,
                    [key]: id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                ApiHelperModel.makeGetRequest(filters, getBuyProductsInvoice);
                setSelectedPayInvoiceData(undefined);
                setSelectedRowKeys([]);
                setSelectedRows([]);
                notification.success({
                    message: "Payment initiated successfully",
                });
                onCancel();
            } catch (error) {
                showError(error, form);
            }
        }
    };

    // --------------------------------ui------------------------------

    if (error) {
        return <CommonError message={error} code={code} />;
    }
    const columns: ColumnsType<BuyProductModel> = [
        {
            title: "Product Info",
            key: "product_info",
            width: 450,
            onCell: () => ({
                style: { verticalAlign: "top" },
            }),
            render: (_, buyProduct) => {
                return (
                    <>
                        <BuyProductInfo buyProduct={buyProduct} />
                    </>
                );
            },
        },
        {
            title: "Invoice",
            key: "invoice",
            onCell: () => ({
                style: { verticalAlign: "top" },
            }),
            width: 300,
            render: (_, buyProduct) => {
                return <InvoiceInfoBuyProduct buyProduct={buyProduct} />;
            },
        },
        {
            title: "Profit and Price",
            key: "profit-and-price",
            align: "start",
            width: 220,
            onCell: () => ({
                style: { verticalAlign: "top" },
            }),
            render: (_, buyProduct) => {
                return (
                    <>
                        <Flex vertical gap={12} style={{ marginTop: 12 }}>
                            {buyProduct.getProfit() > 0 ? (
                                <ShippingText label="Profit">
                                    <StatusTag
                                        color="green-inverse"
                                        slug=""
                                        text={`${roundToTwoDecimal(
                                            buyProduct.getProfit(),
                                        )} ${buyProduct.getRegionCurrencyCode()}`}
                                    />
                                </ShippingText>
                            ) : buyProduct.getProfit() === 0 ? (
                                <></>
                            ) : (
                                <ShippingText label="Loss">
                                    <StatusTag
                                        color="red-inverse"
                                        slug=""
                                        text={`${roundToTwoDecimal(
                                            buyProduct.getProfit(),
                                        )} ${buyProduct.getRegionCurrencyCode()}`}
                                    />
                                </ShippingText>
                            )}

                            {buyProduct.getBuyingPrice() ? (
                                <ShippingText label="Buying Price">
                                    <StatusTag
                                        color="orange"
                                        slug=""
                                        text={`${roundToTwoDecimal(
                                            buyProduct.getBuyingPrice(),
                                        )} ${buyProduct.getRegionCurrencyCode()}`}
                                    />
                                </ShippingText>
                            ) : (
                                <></>
                            )}

                            {buyProduct.getShippingPrice() ? (
                                <ShippingText label="Shipping Price">
                                    <StatusTag
                                        color="blue"
                                        slug=""
                                        text={`${roundToTwoDecimal(
                                            buyProduct.getShippingPrice(),
                                        )} ${buyProduct.getRegionCurrencyCode()}`}
                                    />
                                </ShippingText>
                            ) : (
                                <></>
                            )}

                            {buyProduct.getPurchaseCompanyName() ? (
                                <ShippingText label="Purchase By">
                                    <Typography.Text strong>
                                        <Tooltip
                                            title={buyProduct.getPurchaseCompanyName()} // Full name in tooltip
                                        >
                                            <Tag
                                                color="blue-inverse"
                                                style={{
                                                    maxWidth: "110px", // Set maximum width as needed
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    display: "inline-block",
                                                }}
                                            >
                                                {buyProduct.getPurchaseCompanyName()}{" "}
                                            </Tag>
                                        </Tooltip>
                                    </Typography.Text>
                                </ShippingText>
                            ) : (
                                <></>
                            )}
                        </Flex>
                    </>
                );
            },
        },
        {
            title: "Transaction",
            key: "transaction",
            align: "start",

            onCell: () => ({
                style: { verticalAlign: "top" },
            }),
            render: (_, buyProduct) => {
                if (
                    !buyProduct.getCustomerInvoice()?.hasTransactions() &&
                    !buyProduct.getAgentInvoice()?.hasTransactions()
                ) {
                    return (
                        <Alert
                            message="Transaction Not Available"
                            type="warning"
                            showIcon
                        />
                    );
                }

                return (
                    <>
                        <Flex vertical gap={12}>
                            {buyProduct
                                .getCustomerInvoice()
                                ?.hasTransactions() ? (
                                <Card title="Customer Transaction">
                                    <InvoiceSummaryTransaction
                                        transaction={
                                            buyProduct
                                                .getAgentInvoice()
                                                ?.getTransactions() || []
                                        }
                                    />
                                </Card>
                            ) : (
                                <>
                                    <Alert
                                        message="Customer Transaction Not Available"
                                        type="warning"
                                        showIcon
                                    />
                                </>
                            )}

                            {buyProduct.getAgentInvoice()?.getTransactions() ? (
                                <Card title="Agent Transaction">
                                    <InvoiceSummaryTransaction
                                        transaction={
                                            buyProduct
                                                .getAgentInvoice()
                                                ?.getTransactions() || []
                                        }
                                    />
                                </Card>
                            ) : (
                                <>
                                    <Alert
                                        message="Agent Transaction Not Available"
                                        type="warning"
                                        showIcon
                                    />
                                </>
                            )}
                        </Flex>
                    </>
                );
            },
        },
        {
            ...actionsProps,

            onCell: () => ({ style: { padding: 2.5 } }),
            render: (_, record) => {
                return (
                    <Dropdown
                        menu={{
                            items: getActions(record),
                        }}
                    >
                        <Button type="primary" icon={<HddFilled />} />
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Buy Products Invoice Summary"
                style={{ marginTop: "10px" }}
            >
                <FiltersComponent
                    isLoading={isBuyProductLoading}
                    handleProductFilter={handleFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={() => {
                        handelFilterClear();
                        onClearBulkSelectedState();
                    }}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                    harvestKey={
                        HarvestActionEnums.SHIPMENT_BUY_PRODUCT_EXPORT_HARVEST
                    }
                />
                <Flex gap={8} align="center">
                    <Dropdown
                        menu={{
                            items: getBulkActions(),
                        }}
                        disabled={selectedRowKeys.length === 0}
                    >
                        <Button disabled={selectedRowKeys.length === 0}>
                            Bulk Actions
                        </Button>
                    </Dropdown>
                    {selectedRows.length > 0 ? (
                        <Typography.Text>
                            Selected : {selectedRows.length}
                        </Typography.Text>
                    ) : (
                        <></>
                    )}
                </Flex>

                <Table
                    bordered
                    style={{
                        backgroundColor: "white",
                        marginTop: 16,
                        width: "100%",
                    }}
                    rowHoverable={false}
                    rowSelection={{
                        type: "checkbox",
                        selectedRowKeys,
                        columnWidth: 60,
                        fixed: true,
                        onChange: onChangeRow,

                        getCheckboxProps: (record) => ({
                            disabled: !record.getAgentInvoice(),
                        }),
                    }}
                    loading={isBuyProductLoading}
                    dataSource={buyProductCollectionModel?.getData()}
                    columns={columns}
                    rowKey={(r) => r.getId()}
                    pagination={{
                        ...paginationConfig,
                        showTotal: (total) => (
                            <div>
                                Total{" "}
                                <span
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        color: "green",
                                    }}
                                >
                                    {total}
                                </span>{" "}
                                Items
                            </div>
                        ),
                    }}
                    scroll={{ x: BREAK_POINTS.XL }}
                />
            </PageHeader>

            {selectedPayInvoiceData && (
                <Modal
                    width={isMobile ? "100%" : "60%"}
                    title="Pay Customer Invoice"
                    open={modalOpenType === "pay-invoice"}
                    onCancel={onCancel}
                    onOk={form.submit}
                    okText="Submit"
                    okButtonProps={{ loading: isLoadingSession }}
                    centered
                    destroyOnClose={true}
                >
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                </Modal>
            )}
        </>
    );
};

export default InvoiceSummaryBuyProduct;
