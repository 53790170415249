import { useRef, MutableRefObject, FC } from "react";
import { Col, Row, Tag, Typography, Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

interface IWalletCardProps {
    walletId: number | undefined;
    handleWalletBalanceClick: (id: number, code: string) => void;
    item: {
        code: string;
        totalAvailableBalance: number;
        totalReservedBalance: number;
        totalPendingBalance: number;
        symbol: string;
        walletId: number;
    }[];
}
const WalletCard: FC<IWalletCardProps> = ({
    item,
    handleWalletBalanceClick,
    walletId,
}) => {
    const sliderRef = useRef<HTMLDivElement | null>(
        null,
    ) as MutableRefObject<HTMLDivElement | null>;

    const scrollLeft = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                top: 0,
                left: -250,
                behavior: "smooth",
            });
        }
    };

    const scrollRight = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollBy({
                top: 0,
                left: 250,
                behavior: "smooth",
            });
        }
    };

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    style={{ position: "relative" }}
                >
                    <div
                        style={{
                            position: "absolute",
                            right: "25px",
                            top: "0%",
                            transform: "translateY(-50%)",
                            zIndex: 1,
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <Button
                            shape="circle"
                            icon={<LeftOutlined />}
                            onClick={scrollLeft}
                        />
                        <Button
                            shape="circle"
                            icon={<RightOutlined />}
                            onClick={scrollRight}
                        />
                    </div>
                    <div
                        ref={sliderRef}
                        style={{
                            display: "flex",
                            overflowX: "auto",
                            scrollBehavior: "smooth",
                            gap: "14px",
                            padding: "10px 4px",
                        }}
                    >
                        {item.map((wallet, index) => (
                            <div
                                key={index}
                                className="mainCard"
                                style={{
                                    minWidth: "250px",
                                    color: "#fff",
                                    padding: "10px",
                                    borderRadius: "8px",
                                    cursor: "pointer",
                                    background:
                                        wallet.walletId === walletId
                                            ? "#DFFFED"
                                            : "#ffffff",
                                }}
                                onClick={() => {
                                    handleWalletBalanceClick(
                                        wallet.walletId,
                                        wallet.code,
                                    );
                                }}
                            >
                                <div className="cardCustoms">
                                    <Text strong>Available Balance</Text>
                                    <div
                                        className="walletCardP"
                                        style={{ marginTop: "10px" }}
                                    >
                                        <div>
                                            <Title
                                                style={{
                                                    margin: 0,
                                                    color: "#00893C",
                                                    fontSize: 30,
                                                }}
                                            >
                                                {wallet.symbol}
                                                {wallet.totalAvailableBalance.toFixed(
                                                    2,
                                                )}
                                            </Title>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <Tag
                                                    bordered={false}
                                                    color="red"
                                                >
                                                    Pending: {wallet.symbol}
                                                    {wallet.totalPendingBalance}
                                                </Tag>
                                                <Tag
                                                    bordered={false}
                                                    color="volcano"
                                                    style={{
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    Reserved: {wallet.symbol}
                                                    {
                                                        wallet.totalReservedBalance
                                                    }
                                                </Tag>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default WalletCard;
