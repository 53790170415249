import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { Button, Flex, Form, Input, Select, Typography } from "antd";

import { SearchOutlined } from "@ant-design/icons";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { MoveOnLens } from "./MoveOnLens/MoveOnLens";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { useNavigate } from "react-router-dom";
import { parseObjectToString } from "@/helpers/stringify";
import isProductUrl from "@/helpers/isProductUrl";
import { initialProductSearchQuery } from "@/lib/core-react/store/inventoryProductsAtoms";
import usePageQuery from "@/helpers/usePageQuery";

const { Text } = Typography;

interface IFromData {
    image_url: string;
    country_codes: string[];
    keyword: string;
}

interface Props {
    handleOnSearch: (values: IFromData) => void;
    handleResetOnSearch: () => void;
    isLoading: boolean;
}
const SearchBox: React.FC<Props> = ({
    handleOnSearch,
    handleResetOnSearch,
    isLoading,
}) => {
    const { getCurrentQuery } = usePageQuery();

    const { isMobile } = useWindowWidth();
    const [form] = useForm();
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState("");

    const {
        allOptionsData: { countryOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.COUNTRY);

        form.setFieldValue(
            "country_codes",
            initialProductSearchQuery.country_codes,
        );
    }, []);

    // x------------------------------- API Calls for Data Fetch: End -------------------------------x //

    const handleUpload = (image_url: string) => {
        handleOnSearch({
            image_url,
            country_codes: [],
            keyword: "",
        });
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = e.target.value;

        setKeyword(searchTerm.trim());
    };

    const onReset = () => {
        form.resetFields();
        handleResetOnSearch();
    };

    const onFinish = (values: IFromData) => {
        if (values.keyword && isProductUrl(values.keyword.trim())) {
            navigate(
                `/service/inventory/product-detail?productUrl=${values.keyword}&${parseObjectToString(getCurrentQuery())}`,
            );
        } else {
            handleOnSearch(values);
        }
    };

    return (
        <>
            <Form
                style={{
                    display: "flex",
                    width: "100%",
                    maxWidth: 800,
                    gap: 4,
                    // border: tw.border.DEFAULT,
                }}
                form={form}
                name="search-form"
                onFinish={onFinish}
            >
                <Form.Item
                    noStyle
                    name="country_codes"
                    style={{
                        width: 200,
                    }}
                >
                    <Select
                        // defaultValue={region || DEFAULT_COUNTRY}
                        loading={countryOptionsData.isLoading}
                        onChange={(value) => {
                            form.setFieldValue("country_codes", [value]);
                        }}
                        style={{
                            width: 160,
                        }}
                    >
                        {countryOptionsData.options.map((country) => (
                            <Select.Option
                                key={country.code}
                                value={country.code}
                            >
                                <Flex align="center" gap={4}>
                                    <img
                                        src={`${country.flag}`}
                                        alt={"country flag"}
                                        style={{
                                            width: 20,
                                            height: 20,
                                            borderRadius: "4px",
                                        }}
                                    />
                                    <Text
                                        style={{
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {country.code}
                                    </Text>
                                </Flex>
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item noStyle name="keyword">
                    <Input
                        value={keyword}
                        onChange={handleChange}
                        autoComplete="off"
                        type="text"
                        name="keyword"
                        placeholder=" search by keyword or product link"
                    />
                </Form.Item>

                <Form.Item noStyle>
                    <MoveOnLens form={form} handleUpload={handleUpload} />
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        icon={<SearchOutlined />}
                    >
                        {isMobile ? " " : "Search"}
                    </Button>
                </Form.Item>
                <Form.Item noStyle>
                    <Button
                        disabled={isLoading}
                        type="dashed"
                        htmlType="button"
                        onClick={onReset}
                        // icon={<ClearOutlined />}
                    >
                        Clear
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default SearchBox;
