import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import {
    IInvoiceTransaction,
    IInvoiceTransactionAssociation,
    ITransaction,
    IWalletTransactionAssociation,
    IWalletTransactionCollection,
} from "@/types/walletTransactionCollection";
import {
    InvoiceBuyProductModel,
    InvoiceShipmentProductModel,
} from "./invoiceCollectionModel";
import { ITransactionInvoice } from "@/types/InvoiceCollection";

export class InvoiceTransactionAssociationModel {
    public readonly id: number;
    public readonly amount: number;
    public readonly created_at: string;

    constructor(dataItem: IInvoiceTransactionAssociation) {
        this.id = dataItem.id;
        this.amount = dataItem.amount;
        this.created_at = dataItem.created_at;
    }
}

export class InvoiceTransactionModel {
    public readonly object: string;
    public readonly associations: {
        data: {
            object: string;
            data: InvoiceTransactionAssociationModel[];
        };
    };
    public readonly transaction_number: string;
    public readonly amount: number;
    public readonly description: string | null;
    public readonly type: string;
    public readonly invoice: ITransactionInvoice;
    public readonly created_at: string;

    constructor(dataItem: IInvoiceTransaction) {
        this.object = dataItem.object;
        this.associations = dataItem.associations;
        this.transaction_number = dataItem.transaction_number;
        this.amount = dataItem.amount;
        this.description = dataItem.description;
        this.type = dataItem.type;
        this.invoice = dataItem.invoice;
        this.created_at = dataItem.created_at;
    }

    getTransactionNumber() {
        return this.transaction_number;
    }

    getAmount() {
        return this.amount;
    }

    getDescription() {
        return this.description;
    }

    getType() {
        return this.type;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class WalletTransactionAssociationModel {
    public readonly id: number;
    public readonly amount: number;
    public readonly invoice_transaction?: InvoiceTransactionModel;
    public readonly agent_invoice_transaction?: IInvoiceTransaction;
    public readonly shipment_product?: InvoiceShipmentProductModel;
    public readonly buy_product?: InvoiceBuyProductModel;
    public readonly created_at: string;

    constructor(dataItem: IWalletTransactionAssociation) {
        this.id = dataItem.id;
        this.amount = dataItem.amount;
        this.invoice_transaction = dataItem.invoice_transaction
            ? new InvoiceTransactionModel(dataItem.invoice_transaction)
            : undefined;
        this.agent_invoice_transaction = dataItem.agent_invoice_transaction;
        this.shipment_product = dataItem.shipment_product
            ? new InvoiceShipmentProductModel(dataItem.shipment_product)
            : undefined;
        this.buy_product = dataItem.buy_product
            ? new InvoiceBuyProductModel(dataItem.buy_product)
            : undefined;
        this.created_at = dataItem.created_at;
    }

    getId() {
        return this.id;
    }

    getAmount() {
        return this.amount;
    }

    getShipmentProduct() {
        return this.shipment_product;
    }

    getBuyProduct() {
        return this.buy_product;
    }

    getAgentInvoice() {
        return this.agent_invoice_transaction;
    }

    getInvoiceTransaction() {
        return this.invoice_transaction;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class TransactionModel {
    public readonly object: string;
    public readonly id: number;
    public readonly transaction_number: string;
    public readonly amount: number;
    public readonly description: string | null;
    public readonly type: string;
    public readonly source: string;
    public readonly available_on: string | null;
    public readonly released_on: string | null;
    public readonly status: string;
    public readonly associations: {
        object: string;
        data: WalletTransactionAssociationModel[];
    };
    public readonly created_at: string;

    constructor(dataItem: ITransaction) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.transaction_number = dataItem.transaction_number;
        this.amount = dataItem.amount;
        this.description = dataItem.description;
        this.type = dataItem.type;
        this.source = dataItem.source;
        this.available_on = dataItem.available_on;
        this.released_on = dataItem.released_on;
        this.status = dataItem.status;
        this.associations = {
            object: dataItem.associations.object,
            data: dataItem.associations.data.map(
                (item) => new WalletTransactionAssociationModel(item),
            ),
        };
        this.created_at = dataItem.created_at;
    }

    getId() {
        return this.id;
    }

    getTransactionNumber() {
        return this.transaction_number;
    }

    getAmount() {
        return this.amount;
    }

    getType() {
        return this.type;
    }

    getDescription() {
        return this.description;
    }

    getSource() {
        return this.source;
    }

    getAvailableOn() {
        return this.available_on;
    }

    getReleasedOn() {
        return this.released_on;
    }

    getStatus() {
        return this.status;
    }

    getAssociations() {
        return this.associations;
    }

    getCreateAt() {
        return this.created_at;
    }
}

export class TransactionCollectionModel {
    public readonly object: string;
    public readonly data: TransactionModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(dataItem: IWalletTransactionCollection) {
        this.object = dataItem.object;
        this.data = dataItem.data.map((item) => new TransactionModel(item));
        this.pagination = dataItem.pagination;
        this.filters = dataItem.filters;
    }

    getData() {
        return this.data;
    }

    getPagination() {
        return this.pagination;
    }

    getFilters() {
        return this.filters;
    }
}
