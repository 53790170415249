import { AgentCompanyCollectionEnums } from "@/enums/agentCompanyCollectionEnums";
import {
    AgentCompanyOperationStatusEnums,
    AgentCompanyVerificationStatusEnums,
} from "@/enums/shared";

export class AgentCompanyModel {
    object: AgentCompanyCollectionEnums.AgentCompany;
    id: number;
    primary_name: string;
    secondary_name: string;
    slug: string;
    operation_status: AgentCompanyOperationStatusEnums;
    verification_status: AgentCompanyVerificationStatusEnums;
    registered_country: number;
    company_type: string;
    director_name: string;
    designation: string;
    business_phone: string;
    years_of_experience: string;
    number_of_people_in_team: string;
    address: any;

    constructor(dataItem: any) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.primary_name = dataItem.primary_name;
        this.secondary_name = dataItem.secondary_name;
        this.slug = dataItem.slug;
        this.operation_status = dataItem.operation_status;
        this.verification_status = dataItem.verification_status;
        this.registered_country = dataItem.registered_country;
        this.company_type = dataItem.company_type;
        this.director_name = dataItem.director_name;
        this.designation = dataItem.designation;
        this.business_phone = dataItem.business_phone;
        this.years_of_experience = dataItem.years_of_experience;
        this.number_of_people_in_team = dataItem.number_of_people_in_team;
        this.address = dataItem.address;
    }

    getId() {
        return this.id;
    }
    getPrimaryName() {
        return this.primary_name;
    }
    getSecondaryName() {
        return this.secondary_name;
    }
    getSlug() {
        return this.slug;
    }
    getOperationStatus() {
        return this.operation_status;
    }
    getVerificationStatus() {
        return this.verification_status;
    }
    getRegisteredCountry() {
        return this.registered_country;
    }

    getCompanyType() {
        return this.company_type;
    }
    getDirectorName() {
        return this.director_name;
    }
    getDesignation() {
        return this.designation;
    }
    getBusinessPhone() {
        return this.business_phone;
    }
    getYearsOfExperience() {
        return this.years_of_experience;
    }
    getNumberOfPeopleInTeam() {
        return this.number_of_people_in_team;
    }
}
