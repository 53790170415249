import { Alert, Card, Flex } from "antd";
import InvoiceInfo from "@/pages/Shipping/ShipmentProducts/InvoiceSummary/InvoiceInfo";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import InvoiceInfoTitle from "./InvoiceInfoTitle";
import { SHIPMENT_PRODUCTS_PAGE } from "@/consts/pageRoute";
import getPagePathname from "@/helpers/getPagePathname";

const InvoiceInfoShipmentProduct = ({
    shipmentProduct,
}: {
    shipmentProduct: ShipmentProductModel;
}) => {
    const customerInvoice = shipmentProduct?.getCustomerInvoice()
        ? shipmentProduct.getCustomerInvoice()
        : shipmentProduct.getBuyProduct()
          ? shipmentProduct.getBuyProduct()?.getCustomerInvoice()
          : undefined;

    if (!customerInvoice && !shipmentProduct.getAgentInvoice()) {
        return (
            <Alert message="Invoice Not Available" type="warning" showIcon />
        );
    }

    return (
        <>
            <Flex vertical gap={12}>
                <Card
                    styles={{
                        body: {
                            padding: 8,
                        },

                        header: {
                            padding: 8,
                        },
                    }}
                    title={
                        <InvoiceInfoTitle
                            label="Customer Invoice"
                            requestFor="customer"
                            invoice={customerInvoice}
                            linkPath={`/invoice/customer-invoice/details/${customerInvoice?.getId()}`}
                        />
                    }
                >
                    {customerInvoice ? (
                        <InvoiceInfo
                            regionCurrencyCode={
                                shipmentProduct.getRegionCurrencyCode() || ""
                            }
                            invoice={customerInvoice}
                        />
                    ) : (
                        <Alert
                            message="Not Available"
                            type="warning"
                            showIcon
                        />
                    )}
                </Card>
                {SHIPMENT_PRODUCTS_PAGE !== getPagePathname() ? (
                    <Card
                        styles={{
                            body: {
                                padding: 8,
                            },

                            header: {
                                padding: 8,
                            },
                        }}
                        title={
                            <InvoiceInfoTitle
                                label="Agent Invoice"
                                requestFor="agent"
                                invoice={shipmentProduct.getAgentInvoice()}
                                linkPath={`/invoice/agent-invoice/details/${shipmentProduct.getAgentInvoice()?.getId()}`}
                            />
                        }
                    >
                        {shipmentProduct.getAgentInvoice() ? (
                            <InvoiceInfo
                                regionCurrencyCode={
                                    shipmentProduct.getRegionCurrencyCode() ||
                                    ""
                                }
                                invoice={shipmentProduct.getAgentInvoice()}
                            />
                        ) : (
                            <>
                                <Alert
                                    message="Not Available"
                                    type="warning"
                                    showIcon
                                />
                            </>
                        )}
                    </Card>
                ) : (
                    <></>
                )}
            </Flex>
        </>
    );
};

export default InvoiceInfoShipmentProduct;
