import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { storeStoreAtom } from "@/lib/core-react/store/store";
import { useState } from "react";
import { IStoreFormData, IStoreUpdateFormData } from "@/types/storeCollection";
import { notification } from "antd";

export const useStoreSync = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const syncStore = async (): Promise<void> => {
        setIsLoading(true);
        try {
            const res = await storeService.storeResource.sync();
            setIsLoading(false);
            notification.success({
                message: res.message,
            });
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };
    return { syncStore, isLoading } as const;
};

export const useGetStore = () => {
    const [store, setStore] = useAtom(storeStoreAtom);
    const { storeService } = useService();
    const getStore = async (params?: string): Promise<void> => {
        setStore({ ...store, isLoading: true, error: null });
        try {
            const response = await storeService.storeResource.get(params);
            setStore({
                ...store,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setStore({
                ...store,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return { getStore } as const;
};

export const useStoreCreate = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [storeCollectionData, setStoreCollectionData] =
        useAtom(storeStoreAtom);

    const createStore = async (payload: IStoreFormData) => {
        setIsLoading(true);
        try {
            const response = await storeService.storeResource.create(payload);
            setIsLoading(false);
            setStoreCollectionData({ ...storeCollectionData, refetch: true });
            notification.success({
                message: response.message,
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createStore, isLoading, isError };
};

export const useStoreUpdate = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [storeCollectionData, setStoreCollectionData] =
        useAtom(storeStoreAtom);

    const updateStore = async (
        storeId: number,
        payload: IStoreUpdateFormData,
    ) => {
        setIsLoading(true);
        try {
            const response = await storeService.storeResource.update(
                storeId,
                payload,
            );
            setIsLoading(false);
            setStoreCollectionData({ ...storeCollectionData, refetch: true });
            notification.success({
                message: response.message,
            });

            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateStore, isLoading, isError };
};

export const useStoreDelete = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [storeCollectionData, setStoreCollectionData] =
        useAtom(storeStoreAtom);

    const deleteStore = async (id: number) => {
        setIsLoading(true);
        try {
            const response = await storeService.storeResource.delete(id);
            setIsLoading(false);
            setStoreCollectionData({ ...storeCollectionData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { deleteStore, isLoading, isError };
};
