import { IProductDetailParams } from "@/types/productDetailExtended";
import { BaseResource } from "../public";
import { ResponsePromise } from "../../request/types/typings";
import { IProductSearchResult } from "@/types/productSearchResult";
import { InventoryCategoryResponseModel } from "@/models/inventoryCollectionModel";
import { InventoryBaseShippingCategoryMappingModel } from "@/models/inventoryBaseShippingCategoryMappingCollectionModel";
import { IBaseShippingCategoryMappingForm } from "@/types/inventoryCategoryCollection";
class ProductResource extends BaseResource {
    productPath = `/api/product/admin/v1`;

    // Inventory Category Tree
    async getCategoriesTree(
        params: Record<string, any>,
    ): ResponsePromise<InventoryCategoryResponseModel> {
        const path = `${this.productPath}/categories/tree?${params}`;
        const response = this.client.request("GET", path);
        return response;
    }

    // Inventory Category Tree By Name
    async getCategoriesTreeByName(
        name: string,
    ): ResponsePromise<InventoryCategoryResponseModel> {
        let path = `${this.productPath}/categories/tree`;
        if (name) {
            path = `${path}?name=${name}`;
        }
        const response = this.client.request("GET", path);
        return response;
    }

    // Inventory Base Shipping Category Mapping
    async getBaseShippingCategoryMappings(
        params: Record<string, any>,
    ): ResponsePromise<InventoryBaseShippingCategoryMappingModel> {
        const path = `${this.productPath}/categories/base-shipping-category/mappings${this.addQuery(params)}`;
        const response = this.client.request("GET", path);
        return response;
    }

    // Sync Inventory & Base Shipping Category Mapping
    async syncCategoryMapping(): ResponsePromise {
        const path = `${this.productPath}/categories/sync`;
        const response = this.client.request("POST", path);
        return response;
    }

    // Inventory & Base Shipping Category Mapping
    async createBaseShippingCategoryMapping(
        payload: IBaseShippingCategoryMappingForm,
    ): ResponsePromise {
        const path = `${this.productPath}/categories/base-shipping-category/mappings/attach`;
        const response = this.client.request("POST", path, payload);
        return response;
    }

    // Detach Inventory & Base Shipping Category Mapping
    async detachBaseShippingCategoryMapping(
        payload: IBaseShippingCategoryMappingForm,
    ): ResponsePromise {
        const path = `${this.productPath}/categories/base-shipping-category/mappings/detach`;
        const response = this.client.request("POST", path, payload);
        return response;
    }

    //Product List
    async getProductList(
        params: Record<string, any>,
        payload?: Record<string, any>,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<IProductSearchResult> {
        const endpoint = `/api/product/admin/v1/products/search${this.addQuery(params)}`;
        return await this.client.request(
            "POST",
            endpoint,
            payload,
            {},
            customHeaders,
        );
    }

    //Product Details
    async getProductDetails(
        params: Record<string, any>,
        productId: string,
    ): ResponsePromise {
        const query = this.addQuery(params);
        const path = `/api/product/admin/v1/products/find/${productId}${query}`;
        const response = this.client.request("GET", path);
        return response;
    }

    async getProductById(
        productId: string,
        params: Omit<IProductDetailParams, "url">,
    ): ResponsePromise {
        const path = `/api/product/admin/v1/products/find/${productId}${this.addQuery(params)}`;
        const response = this.client.request("GET", path);
        return response;
    }
    async getProductByUrl(params: IProductDetailParams): ResponsePromise {
        const path = `/api/product/admin/v1/products/find${this.addQuery(params)}`;
        const response = this.client.request("GET", path);
        return response;
    }

    //Product description
    async getDescription({
        params,
        productId,
    }: {
        params: Record<string, any>;
        productId: string;
    }): ResponsePromise {
        const path = `/api/product/admin/v1/products/find/${productId}/description${this.addQuery(params)}`;
        const response = this.client.request("GET", path);
        return response;
    }
}

export default ProductResource;
