import React from "react";
import { Button, Tag, Typography, notification } from "antd";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import { formatDateTime } from "../../utils/helpers";
import { downloadImpersonateCredentialsAsTextFile } from "../../utils/helper";
import { IImpersonateSessionData } from "@/types/userCollection";

const { Paragraph } = Typography;

interface ImpersonateResultView {
    response: IImpersonateSessionData;
}

const ImpersonateResultView: React.FC<ImpersonateResultView> = ({
    response,
}) => {
    const handleCopyToClipboard = () => {
        if (response) {
            const text = `Email: ${response.email}, Password: ${atob(response.password)}`;
            navigator.clipboard.writeText(text);
            notification.success({
                message: "Copied to clipboard!",
            });
        } else {
            notification.error({
                message: "Email and password is missing. Please try again.",
            });
        }
    };

    return (
        <div>
            <div style={{ padding: "10px", lineHeight: "10px" }}>
                <p>
                    <strong>Email:</strong>{" "}
                    <Paragraph copyable>{response.email}</Paragraph>
                </p>
                <p>
                    <strong>Password:</strong>{" "}
                    <Paragraph copyable>{atob(response.password)}</Paragraph>
                </p>
                <p>
                    <strong>Expire at:</strong>{" "}
                    <Tag color="magenta">
                        {formatDateTime(response.session.expired_at)}
                    </Tag>
                </p>
            </div>
            <Button icon={<CopyOutlined />} onClick={handleCopyToClipboard}>
                Copy
            </Button>
            <Button
                title="Download"
                icon={<DownloadOutlined />}
                style={{ marginLeft: "10px" }}
                onClick={() => {
                    if (response) {
                        downloadImpersonateCredentialsAsTextFile(response);
                    }
                }}
                type="primary"
            >
                Download
            </Button>
        </div>
    );
};

export default ImpersonateResultView;
