import { useAtom } from "jotai";
import { useState } from "react";
import {
    BuyProductCollectionModel,
    SequencesDataModel,
} from "@/models/buyProductCollectionModel";
import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import {
    buyOrderCollectionAtom,
    buyOrderDetailAtom,
} from "../../store/buyOrderAtoms/buyOrderAtoms";
import {
    IAddBuyOrderAddProductFormData,
    IAddVariationsPayload,
    IUpdateVariationsFormData,
} from "@/types/buyOrderDetail";
import { purchaseCommissionCollectionAtom } from "@/lib/core-react/store/store";
import {
    IPurchaseCommissionFormData,
    IPurchaseCommissionUpdateData,
} from "@/types/purchaseCommissionCollection";
import { IBuyOrderPlacePayload } from "@/types/buyOrderPlace";
import {
    BulkApprovedPayload,
    BulkRejectPayload,
    IOrderHandlerRequest,
    IShippingRateUpdatePayload,
    IShippingUpdatePayload,
    RejectPayload,
} from "@/types/buyOrderCollection";
import { notification } from "antd";
import {
    buyProductAtom,
    buyProductCollectionAtom,
    buyProductInvoiceCollectionAtom,
} from "../../store/buyProducts/buyProductsAtoms";
import {
    BuyOrderCollectionModel,
    BuyOrderWithProductModel,
} from "@/models/buyOrderCollectionModel";
import { ICancelVariationsAndChargesPayload } from "@/types/buyProductCollection";
import { getErrorCode } from "@/helpers/getErrorCode";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { isUnauthorized } from "@/helpers/isUnauthorized";
import { ICustomOrderPayload } from "@/pages/BuyAndShip/BuyOrders/components/BuyOrderCustomOrderCreate/components/CreateOrderSteps/CreateOrderSteps";
import { IRefundRequestForm } from "@/types/disputeCollection";

export const useSyncBuyProducts = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState(false);
    const syncProducts = async (payload: { ids: number[] }) => {
        try {
            setIsLoading(true);
            const response =
                await purchaseService.buyProductsResource.sync(payload);

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };
    return { syncProducts, isLoading } as const;
};
export const useCreateCustomOrder = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState(false);
    const createCustomOrder = async (payload: ICustomOrderPayload) => {
        try {
            setIsLoading(true);
            const response =
                await purchaseService.buyProductsResource.createCustomOrder(
                    payload,
                );

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };
    return { createCustomOrder, isLoading } as const;
};

export const useGetBuyOrders = () => {
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const { purchaseService } = useService();
    const getBuyOrders = async (params?: string): Promise<void> => {
        setBuyOrderCollection({
            ...buyOrderCollection,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.buyProductsResource.getBuyOrders(params);

            const processData =
                response && new BuyOrderCollectionModel(response);
            if (processData) {
                setBuyOrderCollection({
                    ...buyOrderCollection,
                    isLoading: false,
                    data: processData,
                    refetch: false,
                });
            }
        } catch (error: any) {
            setBuyOrderCollection({
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: getErrorCode(error),
                unAuthorized: isUnauthorized(error),
            });
        }
    };
    return { getBuyOrders } as const;
};

export const useGetBuyProducts = () => {
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );
    const { purchaseService } = useService();
    const getBuyProducts = async (params?: string): Promise<void> => {
        setBuyProductCollection({
            ...buyProductCollection,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.buyProductsResource.getBuyProducts(
                    params,
                );

            const processData =
                response && new BuyProductCollectionModel(response);
            setBuyProductCollection({
                ...buyProductCollection,
                isLoading: false,
                data: processData,
                refetch: false,
            });
        } catch (error: any) {
            setBuyProductCollection({
                ...buyProductCollection,
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                code: getErrorCode(error),
                unAuthorized: error.response?.status === 403,
            });
        }
    };
    return { getBuyProducts } as const;
};

export const useGetBuyProductsForInvoice = () => {
    const [buyProductInvoiceCollection, setBuyProductInvoiceCollection] =
        useAtom(buyProductInvoiceCollectionAtom);
    const { purchaseService } = useService();
    const getBuyProductsInvoice = async (params?: string): Promise<void> => {
        try {
            setBuyProductInvoiceCollection({
                ...buyProductInvoiceCollection,
                isLoading: true,
                error: null,
            });

            const response =
                await purchaseService.buyProductsResource.forInvoice(params);

            const processData =
                response && new BuyProductCollectionModel(response);
            setBuyProductInvoiceCollection({
                ...buyProductInvoiceCollection,
                isLoading: false,
                data: processData,
                refetch: false,
            });
        } catch (error) {
            setBuyProductInvoiceCollection({
                ...buyProductInvoiceCollection,
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                code: getErrorCode(error),
                unAuthorized: getErrorCode(error) === 403,
            });
            throw error;
        }
    };
    return { getBuyProductsInvoice } as const;
};

export const useGetBuyProduct = () => {
    const [buyProductDetails, setBuyProductDetails] = useAtom(buyProductAtom);
    const { purchaseService } = useService();
    const getBuyProduct = async (buy_product_id: number): Promise<void> => {
        setBuyProductDetails({
            ...buyProductDetails,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.buyProductsResource.getBuyProduct(
                    buy_product_id,
                );

            setBuyProductDetails({
                ...buyProductDetails,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
        } catch (error: any) {
            setBuyProductDetails({
                ...buyProductDetails,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: getErrorCode(error),
            });
        }
    };
    return { getBuyProduct, data: buyProductDetails } as const;
};

// This one is for Approving Product inside Orders
export const useApproveBuyProduct = () => {
    const { purchaseService } = useService();
    // const { getBuyProducts } = useGetBuyProducts();
    const { getBuyProduct } = useGetBuyProduct();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );

    const approveBuyProduct = async (
        buyProductID: number,
        // as we are handling RFQ product and Buy product in the same function, so we are allowing null values for commission_rate and agent_company_id in the payload. (Note: In case of RFQ product, we don't need to pass values for commission_rate and agent_company_id)
        payload: {
            commission_rate: number | null;
            agent_company_id: number | null;
        },
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.approveBuyProduct(
                buyProductID,
                payload,
            );
            setIsLoading(false);
            setBuyOrderCollection({
                ...buyOrderCollection,
                refetch: true,
            });

            // await getBuyProducts();
            await getBuyProduct(buyProductID);
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { approveBuyProduct, isLoading, isError };
};

export const useBulkApproveBuyProduct = () => {
    const { purchaseService } = useService();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const bulkApproveBuyProduct = async (payload: BulkApprovedPayload) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.bulkApproveBuyProduct(
                    payload,
                );
            setIsLoading(false);
            setBuyOrderCollection({
                ...buyOrderCollection,
                isLoading: false,
                refetch: false,
                data: response,
            });
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { bulkApproveBuyProduct, isLoading, isError };
};

export const useBulkRejectBuyProduct = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const bulkRejectBuyProduct = async (payload: BulkRejectPayload) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.bulkRejectBuyProduct(
                    payload,
                );
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { bulkRejectBuyProduct, isLoading, isError };
};

export const useRejectBuyProduct = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const rejectBuyProduct = async (
        buyProductID: number,
        payload: RejectPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.rejectBuyProduct(
                    buyProductID,
                    payload,
                );
            setIsLoading(false);
            setBuyOrderCollection({
                ...buyOrderCollection,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { rejectBuyProduct, isLoading, isError };
};

export const useBuyProductTakeActions = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const buyProductTakeAction = async (
        buy_product_id: number,
        payload: SequencesDataModel,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.buyProductTakeActions(
                    buy_product_id,
                    payload,
                );
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(error);
            setIsLoading(false);
            throw error;
        }
    };

    return { buyProductTakeAction, isLoading, isError };
};

export const useGetBuyOrdersDetail = () => {
    const [buyOrder, setBuyOrder] = useAtom(buyOrderDetailAtom);
    const { purchaseService } = useService();

    const getBuyOrdersDetail = async (orderId: string): Promise<void> => {
        setBuyOrder({
            ...buyOrder,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.buyProductsResource.getBuyOrdersDetail(
                    orderId,
                );
            const processData =
                response && new BuyOrderWithProductModel(response.data);
            if (processData) {
                setBuyOrder({
                    ...buyOrder,
                    isLoading: false,
                    data: processData,
                    refetch: false,
                });
            }
        } catch (error: any) {
            setBuyOrder({
                ...buyOrder,
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                code: getError(error),
            });
        }
    };
    return { getBuyOrdersDetail, data: buyOrder } as const;
};

// Variations
export const useBuyProductAddVariations = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const addVariations = async (
        buyProductID: number,
        payload: IAddVariationsPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.addVariations(
                    buyProductID,
                    payload,
                );
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { addVariations, isLoading, isError };
};

export const useUpdateVariations = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updateVariations = async (
        buyProductID: number,
        payload: IUpdateVariationsFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.updateVariations(
                    buyProductID,
                    payload,
                );
            setIsLoading(false);

            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateVariations, isLoading, isError };
};

// Products
export const useBuyOrderAddProduct = () => {
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const addProduct = async (
        buyOrderId: string,
        region: string,
        locale: string,
        payload: IAddBuyOrderAddProductFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.addProduct(
                    buyOrderId,
                    region,
                    locale,
                    payload,
                );
            setIsLoading(false);
            setBuyOrderCollection({
                ...buyOrderCollection,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { addProduct, isLoading, isError };
};

export const useDeleteBuyOrderProduct = () => {
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const deleteProduct = async (id: number) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.deleteProduct(id);
            setIsLoading(false);
            setBuyOrderCollection({ ...buyOrderCollection, refetch: true });
            notification["success"]({
                message: "Delete successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            notification["error"]({
                message: getError(error),
            });
        }
    };
    return { deleteProduct, isLoading, isError };
};

export const useBuyProductOrderPlace = () => {
    const [buyOrderCollection, setBuyOrderCollection] = useAtom(
        buyOrderCollectionAtom,
    );
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const buyProductOrderPlace = async (payload: IBuyOrderPlacePayload) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.buyProductOrderPlace(
                    payload,
                );
            setIsLoading(false);
            setBuyOrderCollection({ ...buyOrderCollection, refetch: true });

            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { buyProductOrderPlace, isLoading, isError };
};

// Purchase Commission
export const usePurchaseCommissionGet = (agentCompanyId: number) => {
    const [purchaseCommissionCollection, setPurchaseCommissionCollection] =
        useAtom(purchaseCommissionCollectionAtom);
    const { purchaseService } = useService();

    const getPurchaseCommission = async (params?: string): Promise<void> => {
        setPurchaseCommissionCollection({
            ...purchaseCommissionCollection,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await purchaseService.purchaseCommissionResource.getPurchaseCommission(
                    agentCompanyId,
                    params,
                );
            setPurchaseCommissionCollection({
                ...purchaseCommissionCollection,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPurchaseCommissionCollection({
                ...purchaseCommissionCollection,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };
    return { getPurchaseCommission } as const;
};

export const usePurchaseCommissionCreate = () => {
    const [purchaseCommissionCollection, setPurchaseCommissionCollection] =
        useAtom(purchaseCommissionCollectionAtom);
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const createPurchaseCommission = async (
        agentCompanyId: number,
        payload: IPurchaseCommissionFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseCommissionResource.addPurchaseCommission(
                    agentCompanyId,
                    payload,
                );
            setIsLoading(false);
            setPurchaseCommissionCollection({
                ...purchaseCommissionCollection,
                isLoading: false,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createPurchaseCommission, isLoading, isError };
};

export const usePurchaseCommissionUpdate = () => {
    const [purchaseCommissionCollection, setPurchaseCommissionCollection] =
        useAtom(purchaseCommissionCollectionAtom);
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updatePurchaseCommission = async (
        agentCompanyId: number,
        commission_id: number,
        payload: IPurchaseCommissionUpdateData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.purchaseCommissionResource.updatePurchaseCommission(
                    agentCompanyId,
                    commission_id,
                    payload,
                );
            setIsLoading(false);
            setPurchaseCommissionCollection({
                ...purchaseCommissionCollection,
                isLoading: false,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updatePurchaseCommission, isLoading, isError };
};

export const useUpdateFx = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const { getBuyProduct } = useGetBuyProduct();

    const updateFx = async (
        id: number,
        payload: { fx_rate: number; commission_rate: number | null },
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.updateFx(id, payload);
            await getBuyProduct(id);
            setIsLoading(false);
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateFx, isLoading, isError };
};

export const useSetCurrentFx = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const setCurrentFx = async (id: number) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.setCurrentFx(id);
            setIsLoading(false);
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { setCurrentFx, isLoading, isError };
};

export const useBuyProductsShippingUpdate = () => {
    const { purchaseService } = useService();
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const buyProductShippingUpdate = async (
        buyProductID: number,
        payload: IShippingUpdatePayload,
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.buyProductUpdateShipping(
                buyProductID,
                payload,
            );
            setBuyProductCollection({
                ...buyProductCollection,
                refetch: false,
            });
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { buyProductShippingUpdate, isLoading, isError };
};

export const useBuyProductsShippingPriceUpdate = () => {
    const { purchaseService } = useService();
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const buyProductShippingPriceUpdate = async (
        buyProductID: number,
        payload: IShippingRateUpdatePayload,
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.buyProductUpdateShippingPrice(
                buyProductID,
                payload,
            );
            setBuyProductCollection({
                ...buyProductCollection,
                refetch: false,
            });

            setIsLoading(false);
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { buyProductShippingPriceUpdate, isLoading, isError };
};
export const useCancelVariationsAndCharges = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const cancelVariationsCharges = async (
        buyProductID: number,
        payload: ICancelVariationsAndChargesPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseService.buyProductsResource.cancelVariationsAndCharges(
                    buyProductID,
                    payload,
                );
            setIsLoading(false);

            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { cancelVariationsCharges, isLoading, isError };
};

export const useBuyProductCancel = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState(false);
    const cancelBuyProduct = async (
        buyProductId: number,
        payload: ICancelVariationsAndChargesPayload,
    ) => {
        try {
            setIsLoading(true);
            const response =
                await purchaseService.buyProductsResource.cancelVariationsAndCharges(
                    buyProductId,
                    payload,
                );

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    return { cancelBuyProduct, isLoading };
};

export const useOrderHandlerAssign = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const orderHandlerAssign = async (payload: IOrderHandlerRequest) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.orderHandlerAssign(
                payload,
            );
            setIsLoading(false);
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { orderHandlerAssign, isLoading, isError };
};

// Refund Request
export const useRefundRequest = () => {
    const { purchaseService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );
    const [isError, setError] = useState<string>("");

    const refundRequest = async (
        buyProductId: number | string,
        payload: IRefundRequestForm,
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.refundRequest(
                buyProductId,
                payload,
            );
            setIsLoading(false);
            setBuyProductCollection({
                ...buyProductCollection,
                refetch: true,
            });
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { refundRequest, isLoading, isError };
};

// Vendor Order Id
export const useUpdateVendorOrderId = () => {
    const { purchaseService } = useService();
    const [buyProductCollection, setBuyProductCollection] = useAtom(
        buyProductCollectionAtom,
    );
    const { getBuyProduct } = useGetBuyProduct();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const updateVendorOrderId = async (
        buyProductId: number,
        payload: { vendor_order_id: string },
    ) => {
        setIsLoading(true);
        try {
            await purchaseService.buyProductsResource.updateVendorOrderId(
                buyProductId,
                payload,
            );
            setBuyProductCollection({
                ...buyProductCollection,
                refetch: false,
            });
            await getBuyProduct(buyProductId);
            setIsLoading(false);
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateVendorOrderId, isLoading, isError };
};
