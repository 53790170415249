import { useStyles } from "@/components/NovuPackage/store/styles";
import { css, cx } from "@emotion/css";

export function Footer() {
    const [footerStyles] = useStyles(["footer.root", "footer.title"]);

    return (
        <div className={cx("nc-footer", footerClassName, css(footerStyles))}>
            {/* See All */}
        </div>
    );
}

const footerClassName = css`
    text-align: center;
    border-radius: 7px;
    margin-top: 10px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    direction: ltr;

    svg {
        margin-left: 10px;
        width: 60px;
        position: relative;
        top: -1px;
    }
`;
