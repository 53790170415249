export enum PayoutRequestCollectionEnum {
    PayoutRequestCollection = "PayoutRequestCollection",
    PayoutRequest = "PayoutRequest",
}

export enum PayoutRequestStatusEnum {
    PENDING = "pending",
    PROCESSING = "processing",
    APPROVED = "approved",
    REJECTED = "rejected",
    CANCELLED = "cancelled",
}
