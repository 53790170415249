import React, { useEffect, createRef } from "react";
import { Button, Form, Input, notification, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Option } from "antd/es/mentions";
import { useUpdateCustomerUser } from "@/lib/core-react/hooks/private/useCustomerUser";

import { Forms } from "@/models/form";
import {
    CustomerUserModel,
    CustomerUserUpdateRequestModel,
} from "@/models/customerUserCollectionModel";
import { showError } from "@/helpers/showError";

interface IProps {
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCustomerUser: CustomerUserModel;
}

const UpdateCustomerUser = ({
    setIsShowUpdateModal,
    selectedCustomerUser,
}: IProps) => {
    const { updateCustomerUser, isLoading } = useUpdateCustomerUser();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value: CustomerUserUpdateRequestModel) => {
        try {
            const res = await updateCustomerUser(
                selectedCustomerUser.id,
                value,
            );
            setIsShowUpdateModal(false);
            notification["success"]({
                message: res.message,
            });
        } catch (error) {
            showError(error, form);
        }
    };

    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update User"
            onFieldsChange={onValuesChange}
            ref={formRef}
            initialValues={{
                name: selectedCustomerUser.name,
                is_active: selectedCustomerUser.getIsActive().toString(),
            }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "User name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="User name" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                label="Status"
                name="is_active"
            >
                <Select placeholder="Select status">
                    <Option value="1">Active</Option>
                    <Option value="0">Inactive</Option>
                </Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateCustomerUser;
