import { ITranslationEntry } from "../lang";

export const AM: ITranslationEntry = {
    translations: {
        notifications: "ማሳወቂያዎች",
        markAllAsRead: "ሁሉንም እንደተነበቡ ምልክት ያድርጉበት",
        poweredBy: "የተጎላበተ በ",
        settings: "ቅንብሮች",
        noNewNotification: "እዚህ ገና የሚታይ አዲስ ነገር የለም።",
    },
    lang: "am",
};
