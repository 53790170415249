import {
    ShippingCategoryStatusEnum,
    ShippingCategoryEnum,
} from "@/enums/shippingCategoryCollectionEnums";
import { TreeSelect } from "@/types";
import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import {
    IShippingCategoryCollection,
    IShippingCategory,
    IShippingCategoryRequest,
} from "@/types/shippingCategory";
import { RegionModel } from "./regionCollectionModel";

export class ShippingCategoryModel {
    public readonly object: ShippingCategoryEnum.ShippingCategory;
    public readonly id: string;
    public readonly name: string;
    public readonly slug: string;
    public readonly parent_id: string;
    public readonly base_shipping_category_id: string;
    public readonly region?: RegionModel;
    public readonly status: ShippingCategoryStatusEnum;
    public readonly lock_version: number;
    public readonly etag: string;
    public readonly created_at: string;
    public readonly children?: ShippingCategoryModel[];

    constructor(dataItem: IShippingCategory) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.parent_id = dataItem.parent_id;
        this.base_shipping_category_id = dataItem.base_shipping_category_id;
        this.region = dataItem.region
            ? new RegionModel(dataItem.region)
            : undefined;
        this.lock_version = dataItem.lock_version;
        this.etag = dataItem.etag;
        this.name = dataItem.name;
        this.slug = dataItem.slug;
        this.status = dataItem.status;
        this.created_at = dataItem.created_at;
        this.children = dataItem.children
            ? dataItem.children.map((item) => new ShippingCategoryModel(item))
            : undefined;
    }

    getId = () => this.id;
    getName = () => this.name;
    getStatus = () => this.status;
}

export class ShippingCategoryCollectionModel {
    public readonly object: ShippingCategoryEnum.ShippingCategoryCollection;
    public readonly data: ShippingCategoryModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: IShippingCategoryCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ShippingCategoryModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

    public search(term: string): ShippingCategoryModel[] {
        const trimmedTerm = term.trim().toLowerCase();

        const searchInChildren = (
            children: ShippingCategoryModel[],
            term: string,
        ) => {
            return children.filter(
                (child) =>
                    child.name.trim().toLowerCase().includes(trimmedTerm) ||
                    child.slug.trim().toLowerCase().includes(trimmedTerm) ||
                    (child.children &&
                        child.children?.length > 0 &&
                        searchInChildren(child.children, term).length > 0),
            );
        };

        return this.data.filter(
            (category) =>
                category.name.trim().toLowerCase().includes(trimmedTerm) ||
                category.slug.trim().toLowerCase().includes(trimmedTerm) ||
                (category.children &&
                    category.children?.length > 0 &&
                    searchInChildren(category.children, trimmedTerm).length >
                        0),
        );
    }

    getShippingCategoryTreeIds() {
        const treeData: TreeSelect[] = [];
        // Helper function to build the tree recursively
        function buildTree(dataItem: ShippingCategoryModel): TreeSelect {
            // @ts-ignore
            const node: TreeSelect = {
                value: dataItem.id,
                title: dataItem.name,
                children: [],
            };

            if (dataItem.children && dataItem.children.length > 0) {
                dataItem.children.forEach(
                    (childItem: ShippingCategoryModel) => {
                        const childNode = buildTree(childItem);
                        node.children.push(childNode);
                    },
                );
            }
            return node;
        }

        // Build the tree for each top-level data item
        this.data.forEach((dataItem) => {
            const treeItem = buildTree(dataItem);
            treeData.push(treeItem);
        });

        return treeData;
    }
}

//Post Request
export class ShippingCategoryRequestModel {
    public readonly region_id: number;
    public readonly base_shipping_category_id: string;
    public readonly name: string;
    public readonly slug: string;
    public readonly status: ShippingCategoryStatusEnum;

    constructor(reqData: IShippingCategoryRequest) {
        this.region_id = reqData.region_id;
        this.base_shipping_category_id = reqData.base_shipping_category_id;
        this.name = reqData.name;
        this.slug = reqData.slug;
        this.status = reqData.status;
    }
}
