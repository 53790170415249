import { Typography } from "antd";

const { Title } = Typography;

const NotificationTab = () => {
    return (
        <div style={{ padding: "24px" }}>
            <Title level={3}>Feature in development</Title>
        </div>
    );
};

export default NotificationTab;
