import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";

class CourierOptionResource extends BaseResource {
    path = `api/courier/admin/option/v1/`;
    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}options${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default CourierOptionResource;
