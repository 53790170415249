import React from "react";
export function Check(props: React.ComponentPropsWithoutRef<"svg">) {
    return (
        <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6 0.875C2.88281 0.875 0.375 3.40625 0.375 6.5C0.375 9.61719 2.88281 12.125 6 12.125C9.09375 12.125 11.625 9.61719 11.625 6.5C11.625 3.40625 9.09375 0.875 6 0.875ZM8.69531 5.46875L5.69531 8.46875C5.57812 8.60938 5.41406 8.65625 5.25 8.65625C5.0625 8.65625 4.89844 8.60938 4.78125 8.46875L3.28125 6.96875C3.02344 6.71094 3.02344 6.3125 3.28125 6.05469C3.53906 5.79688 3.9375 5.79688 4.19531 6.05469L5.25 7.08594L7.78125 4.55469C8.03906 4.29688 8.4375 4.29688 8.69531 4.55469C8.95312 4.8125 8.95312 5.21094 8.69531 5.46875Z"
                fill="#4D9980"
            />
        </svg>
    );
}
