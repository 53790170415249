import { IHarvestJobCreate } from "@/types/harvestBatchCollection";
import BaseResource from "../public/baseResource";
import { ResponsePromise } from "@/lib/core/request/types/typings";

class HarvestJobResource extends BaseResource {
    path = `/api/harvest-job/admin/harvest-job/v1`;

    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/harvest-jobs${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getContextSchema(customHeaders: Record<string, any> = {}): ResponsePromise {
        const path = `${this.path}/harvest-jobs/context-schema`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getSettingsSchema(
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/harvest-jobs/settings-schema`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    create(payload: IHarvestJobCreate<object, object>): ResponsePromise<any> {
        const path = `${this.path}/harvest-jobs`;
        return this.client.request("POST", path, payload, undefined, {});
    }
}

export default HarvestJobResource;
