import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Modal,
    Spin,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import { useAtom } from "jotai";
import { PaginationModel } from "@/models/pagination";
import { formatDateTime } from "@/utils/helpers";
import {
    purchaseCartCollectionAtom,
    shippingCartAtom,
    wishlistAtom,
} from "@/lib/core-react/store/store";
import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import { ShippingCartCollectionModel } from "@/models/shippingCartCollectionModel";
import { WishlistCollectionModel } from "@/models/wishlistCollectionModel";
import { StatusTag } from "@/components";
import WishlistTableColumns from "../../Wishlists/components/wishListTable";
import { getPurchaseCartColumns } from "../../PurchaseCarts/component/purchaseCartTable";
import { getShippingCartColumns } from "../../ShippingCarts/components/shippingCartTable";
import { useGetCartList } from "@/lib/core-react/hooks/private/usePurchaseCart";
import { useGetShippingCart } from "@/lib/core-react/hooks/private/useShippingCart";
import { useGetWishlist } from "@/lib/core-react/hooks/private/useWishlist";
import { LoadingOutlined } from "@ant-design/icons";
import ImpersonateIcon from "@/assets/ImpersonateIcon";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import {
    useCreateImpersonateSession,
    useGetRole,
} from "@/lib/core-react/hooks/private/useRole";
import {
    ExpiredUnit,
    IImpersonateSessionData,
    IImpersonationSession,
    UserType,
} from "@/types/userCollection";
import useOryAuth from "@/lib/core-react/utils/useOryAuth";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { responseTypeQuery } from "@/filters/constant";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { RoleModel } from "@/models/roleCollectionModel";
import { downloadImpersonateCredentialsAsTextFile } from "@/utils/helper";
import { showError } from "@/helpers/showError";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";
import ImpersonateResultView from "@/components/ImpersonateSessionCreate/impersonateResultView";

const { Paragraph, Text } = Typography;

interface IProps {
    selectedUser: CustomerUserModel;
    name?: string | "";
}

const CustomerDetailsViews: React.FC<IProps> = ({ selectedUser, name }) => {
    const { getCartList } = useGetCartList();
    const { getShippingCart } = useGetShippingCart();
    const { getWishlist } = useGetWishlist();
    const { createImpersonate, isLoading: impersonateLoading } =
        useCreateImpersonateSession();
    const { getRole } = useGetRole();

    const [isShowModal, setIsShowModal] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState("basic_information");
    const [impersonateData, setImpersonateData] =
        useState<IImpersonateSessionData>();

    const [
        {
            data: purchaseCartCollectionData,
            isLoading,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(purchaseCartCollectionAtom);
    const [
        {
            data: shippingCartData,
            isLoading: isShippingCartLoading,
            error: shippingError,
        },
    ] = useAtom(shippingCartAtom);
    const [
        {
            data: wishListData,
            isLoading: isWishListLoading,
            error: isWishListError,
        },
    ] = useAtom(wishlistAtom);

    const shippingCart =
        shippingCartData && new ShippingCartCollectionModel(shippingCartData);
    const wishListDataProcessed =
        wishListData && new WishlistCollectionModel(wishListData);

    const purchaseCartColumns = getPurchaseCartColumns(
        purchaseCartCollectionData?.pagination,
    );
    const shippingCartColumns = getShippingCartColumns(
        shippingCartData?.pagination,
    );
    const wishListColumns = WishlistTableColumns(wishListData?.pagination);

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        getCartList({
            user_id: selectedUser.id,
            page: pageCount,
            per_page: pageSize,
        });
    };

    const handleShippingPaginationChange = (
        pageCount: number,
        pageSize: number,
    ) => {
        getShippingCart(
            `user_id=${selectedUser.id}&page=${pageCount}&per_page=${pageSize}`,
        );
    };

    const handleWishListPaginationChange = (
        pageCount: number,
        pageSize: number,
    ) => {
        getWishlist(
            `user_id=${selectedUser.id}&page=${pageCount}&per_page=${pageSize}`,
        );
    };

    const paginationConfig = PaginationModel.getPaginationConfig(
        purchaseCartCollectionData,
        handlePaginationChange,
    );
    const shippingPaginationConfig = PaginationModel.getPaginationConfig(
        shippingCart,
        handleShippingPaginationChange,
    );
    const wishListPaginationConfig = PaginationModel.getPaginationConfig(
        shippingCart,
        handleWishListPaginationChange,
    );

    const handleTabChange = (key: string) => {
        setActiveTabKey(key);
        if (key === "shipping_cart") {
            getShippingCart(`user_id=${selectedUser.id}`);
        } else if (key === "purchase_cart") {
            getCartList({ user_id: selectedUser.id });
        } else if (key === "wishList") {
            getWishlist(`user_id=${selectedUser.id}`);
        }
    };

    const handleNameClick = () => {
        setIsShowModal(true);
    };

    const {
        allOptionsData: { adminOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { getUserEmail } = useOryAuth();

    const email = getUserEmail();

    const createImpersonateCredential = async () => {
        // Check if admin options data is available
        if (!adminOptionsData?.options?.length) {
            showError("Admin data not available");
            return;
        }

        try {
            let roleIds: number[] = [];

            if (selectedUser.type === UserType.Customer) {
                const roleCollectionData = await getRole(
                    "is_customer=1&response_type=minimal",
                );

                if (roleCollectionData?.length) {
                    const customerRole = roleCollectionData.find(
                        (role: RoleModel) =>
                            role.name.toLowerCase().includes("customer"),
                    );

                    if (!customerRole) {
                        showError("Customer role not found");
                        return;
                    }

                    roleIds = [customerRole.id];
                }
            }

            const payload: IImpersonationSession = {
                user_type: UserType.Customer,
                impersonatee_id: selectedUser.getId(),
                impersonator_id: adminOptionsData.options[0].value as number,
                expired_unit: ExpiredUnit.Day,
                expired_value: 2,
                intent: "",
                role_ids: roleIds,
            };

            const response = await createImpersonate(payload);

            if (response) {
                setImpersonateData(response);
                downloadImpersonateCredentialsAsTextFile(response);
                setIsShowModal(true);
            }
        } catch (error: any) {
            showError(error);
        }
    };

    useEffect(() => {
        if (shouldFetch && adminOptionsData?.options?.length) {
            createImpersonateCredential();
            setShouldFetch(false);
        }
    }, [shouldFetch, adminOptionsData.options]);

    const tabsItems = [
        {
            key: "basic_information",
            label: "Basic Information",
            children: (
                <>
                    <Paragraph>
                        <Text>Name: </Text>
                        <Text strong>{selectedUser.getName()}</Text>
                    </Paragraph>
                    {selectedUser.getEmail() && (
                        <Paragraph>
                            <Text>Email: </Text>
                            <Text strong>{selectedUser.getEmail()}</Text>
                        </Paragraph>
                    )}
                    {selectedUser.getPhone() && (
                        <Paragraph>
                            <Text>Phone: </Text>
                            <Text strong>{selectedUser.getPhone()}</Text>
                        </Paragraph>
                    )}
                    {selectedUser.getShippingMark() && (
                        <Paragraph>
                            <Text>Shipping Mark: </Text>
                            <Text strong>{selectedUser.getShippingMark()}</Text>
                        </Paragraph>
                    )}
                    {selectedUser.getStatus() && (
                        <Paragraph>
                            <Text>Status: </Text>
                            <StatusTag
                                slug={selectedUser.getStatus() || ""}
                                text={selectedUser.getStatus() || ""}
                            />
                        </Paragraph>
                    )}
                    <div style={{ marginTop: 10 }}>
                        {selectedUser.getCreatedAt() && (
                            <Paragraph>
                                <Text>Created At: </Text>
                                <Tag color="purple">
                                    {formatDateTime(selectedUser.created_at)}
                                </Tag>
                            </Paragraph>
                        )}
                    </div>
                    {selectedUser.getOrderHandler() && (
                        <Card
                            title="OrderHandler Details"
                            style={{ marginTop: 15 }}
                        >
                            <Paragraph>
                                <Text>Name: </Text>
                                <Text strong>
                                    {selectedUser.getOrderHandler()?.name}
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Email: </Text>
                                <Text strong>
                                    {selectedUser.getOrderHandler()?.email}
                                </Text>
                            </Paragraph>
                            {selectedUser.getOrderHandler()?.phone && (
                                <Paragraph>
                                    <Text>Phone: </Text>
                                    <Text strong>
                                        {selectedUser.getOrderHandler()?.phone}
                                    </Text>
                                </Paragraph>
                            )}
                        </Card>
                    )}
                </>
            ),
        },
        {
            key: "purchase_cart",
            label: "Purchase Cart",
            children: (
                <Spin spinning={isLoading}>
                    {error ? (
                        <div>
                            {unAuthorized
                                ? "Unauthorized access"
                                : `Error: ${error} (Code: ${code})`}
                        </div>
                    ) : (
                        <Table
                            columns={purchaseCartColumns}
                            dataSource={purchaseCartCollectionData?.getProcessData()}
                            loading={isLoading}
                            bordered
                            pagination={paginationConfig}
                            rowKey="id"
                            style={{ marginTop: 10 }}
                            scroll={{ x: 1440 }}
                        />
                    )}
                </Spin>
            ),
        },
        {
            key: "wishList",
            label: "WishList",
            children: (
                <Spin spinning={isWishListLoading}>
                    {isWishListError ? (
                        <div>Error: {isWishListError}</div>
                    ) : (
                        <Table
                            columns={wishListColumns}
                            dataSource={wishListDataProcessed?.getProcessData()}
                            loading={isWishListLoading}
                            bordered
                            pagination={wishListPaginationConfig}
                            rowKey="id"
                            style={{ marginTop: 10 }}
                            scroll={{ x: 1440 }}
                        />
                    )}
                </Spin>
            ),
        },
        {
            key: "shipping_cart",
            label: "Shipping Cart",
            children: (
                <Spin spinning={isShippingCartLoading}>
                    {shippingError ? (
                        <div>Error: {shippingError}</div>
                    ) : (
                        <Table
                            columns={shippingCartColumns}
                            dataSource={shippingCart?.getProcessData()}
                            loading={isShippingCartLoading}
                            bordered
                            pagination={shippingPaginationConfig}
                            rowKey="id"
                            style={{ marginTop: 10 }}
                            scroll={{ x: 1440 }}
                        />
                    )}
                </Spin>
            ),
        },
    ];

    return (
        <>
            <div>
                <Paragraph>
                    <Text>{name}: </Text>
                    <Text
                        strong
                        copyable
                        style={{ cursor: "pointer", color: "blue" }}
                        onClick={handleNameClick}
                    >
                        {selectedUser.getName()}
                    </Text>
                </Paragraph>
            </div>

            {isShowModal && (
                <Modal
                    title={
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <span>{`Customer (${selectedUser.getName()})`}</span>

                            {selectedUser.getId() &&
                                email &&
                                checkActionPermission(
                                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_IMPERSONATE_CREATE,
                                    <Tooltip title="Click to impersonate this customer">
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                setShouldFetch(true);
                                                onFetchFilterApi(
                                                    filterResourceEnum.ADMIN,
                                                    {
                                                        keyword: email,
                                                        ...responseTypeQuery.minimal,
                                                    },
                                                );
                                            }}
                                            loading={impersonateLoading}
                                            style={{
                                                width: 30,
                                                height: 30,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                border: "1px solid #FD384F",
                                                borderRadius: "50%",
                                                backgroundColor: "#FFF8F9",
                                                marginTop: "25px",
                                                marginRight: "25px",
                                            }}
                                            icon={
                                                impersonateLoading ? (
                                                    <LoadingOutlined />
                                                ) : (
                                                    <ImpersonateIcon
                                                        width={25}
                                                        height={15}
                                                        style={{
                                                            marginTop: -5,
                                                        }}
                                                    />
                                                )
                                            }
                                        />
                                    </Tooltip>,
                                    null,
                                )}
                        </div>
                    }
                    open={isShowModal}
                    footer={null}
                    onCancel={() => setIsShowModal(false)}
                    destroyOnClose
                    width="60%"
                >
                    <Tabs
                        defaultActiveKey="basic_information"
                        activeKey={activeTabKey}
                        onChange={handleTabChange}
                        items={tabsItems}
                    />
                </Modal>
            )}

            {isShowModal && impersonateData && (
                <Modal
                    title={
                        <ImpersonateCreateModalTitle
                            name={selectedUser?.name || ""}
                            email={selectedUser?.email || ""}
                            title="Impersonate Customer"
                        />
                    }
                    open={isShowModal}
                    onCancel={() => setIsShowModal(false)}
                    footer={null}
                    width={600}
                >
                    <ImpersonateResultView response={impersonateData} />
                </Modal>
            )}
        </>
    );
};

export default CustomerDetailsViews;
