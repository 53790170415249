import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
    Button,
    Dropdown,
    Modal,
    Popconfirm,
    Space,
    Table,
    Tag,
    Typography,
    notification,
} from "antd";
import {
    CheckCircleOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    HddFilled,
} from "@ant-design/icons";

import { destinationWarehouseMemberCollectionAtom } from "@/lib/core-react/store/store";
import {
    useDestinationWarehouseMemberDelete,
    useGetDestinationWarehouseMembers,
} from "@/lib/core-react/hooks/private/useDestinationWarehouse";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import useDataFilters from "@/hooks/useDataFilters";

import {
    DestinationWarehouseMemberCollectionModel,
    DestinationWarehouseMemberModel,
} from "@/models/destinationWarehouseCollectionModel";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { DestinationWarehouseMemberModalType } from "@/types/destinationWarehouseCollection";

import { getKeyByValue } from "./utils";
import { DestinationWarehouseMemberModification } from "./DestinationWarehouseMemberModification";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { DESTINATION_PERMISSION_ENUM } from "@/consts/permission-enum/destination-enum";
import { FiLogIn } from "react-icons/fi";
import { ADMIN_USER_PERMISSION_ENUM } from "@/consts/permission-enum/admin-user-enum";
import ImpersonateForm from "@/components/ImpersonateSessionCreate/impersonate";
import { UserType } from "@/types/userCollection";
import { CommonUserModel } from "@/models/shared";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";

interface IProps {
    destinationWarehouseId: number;
}

export const DestinationWarehouseMembersTable = ({
    destinationWarehouseId,
}: IProps) => {
    const { deleteMember, isLoading: isLoadingDelete } =
        useDestinationWarehouseMemberDelete();
    const { getDestinationWarehouseMembers } =
        useGetDestinationWarehouseMembers(destinationWarehouseId);
    const [selectedUser, setSelectedUser] = useState<
        CommonUserModel | undefined
    >(undefined);
    const [{ data: memberCollectionData, isLoading, refetch }] = useAtom(
        destinationWarehouseMemberCollectionAtom,
    );
    const MemberCollectionData =
        memberCollectionData &&
        new DestinationWarehouseMemberCollectionModel(memberCollectionData);
    const [isShowImpersonateModal, setIsShowImpersonateModal] =
        useState<boolean>(false);

    const [modalOpen, setModalOpen] = useState<
        DestinationWarehouseMemberModalType | undefined
    >(undefined);
    const [
        selectedDestinationWarehouseMemberId,
        setSelectedDestinationWarehouseMemberId,
    ] = useState<number | undefined>(undefined);

    const { filters, handleFilterChange, isFirstCall, isFetched } =
        useDataFilters();

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest({}, getDestinationWarehouseMembers);
        }
    }, [destinationWarehouseId, isFirstCall, filters, isFetched, refetch]);

    // Pagination
    const paginationData = MemberCollectionData?.getPagination();
    const { isMobile } = useWindowWidth();

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getDestinationWarehouseMembers,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        MemberCollectionData,
        handlePaginationChange,
    );

    const handleDeleteMember = async (id: number) => {
        try {
            await deleteMember(destinationWarehouseId, id);
            notification.success({ message: "Member removed successfully" });
            await getDestinationWarehouseMembers();
        } catch (error) {
            notification.error({ message: getError(error) });
        }
    };

    const onCancelModal = () => {
        setModalOpen(undefined);
        setSelectedDestinationWarehouseMemberId(undefined);
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "id",
            width: 50,
            ellipsis: true,
            align: "center" as const,
            render: (
                _: string,
                __: DestinationWarehouseMemberModel,
                index: number,
            ) => {
                return (
                    <>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </>
                );
            },
        },
        {
            title: "User",
            dataIndex: "user",
            width: 300,
            ellipsis: true,
            key: "user",
            render: (_: string, record: DestinationWarehouseMemberModel) => {
                return (
                    <Space direction="vertical">
                        <Space>
                            <Typography.Text strong>Name: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getName()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>Phone: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getPhone()}
                            </Typography.Text>
                        </Space>
                        <Space></Space>
                        <Space>
                            <Typography.Text strong>Email: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getEmail()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Shipping mark:{" "}
                            </Typography.Text>{" "}
                            <Typography.Text>
                                {record.getUser().getShippingMark()}
                            </Typography.Text>
                        </Space>
                        {!isMobile ? null : (
                            <>
                                {" "}
                                <Space>
                                    <Typography.Text strong>
                                        Roles:{" "}
                                    </Typography.Text>{" "}
                                    <Typography.Text>
                                        {record.getRoles() ? (
                                            <div
                                                style={{
                                                    textTransform: "capitalize",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    gap: 10,
                                                    width: 200,
                                                }}
                                            >
                                                {record
                                                    .getRoles()
                                                    .getData()
                                                    .map((role) => (
                                                        <Tag
                                                            key={role.id}
                                                            color="green"
                                                        >
                                                            {role.label}
                                                        </Tag>
                                                    ))}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Typography.Text>
                                </Space>
                                <Space>
                                    <Typography.Text strong>
                                        Status:{" "}
                                    </Typography.Text>{" "}
                                    <Typography.Text>
                                        <Tag
                                            icon={
                                                record.getStatus() ===
                                                "active" ? (
                                                    <CheckCircleOutlined />
                                                ) : (
                                                    <ExclamationCircleOutlined />
                                                )
                                            }
                                            color={
                                                record.getStatus() === "active"
                                                    ? "success"
                                                    : "warning"
                                            }
                                        >
                                            {record.getStatus()}
                                        </Tag>
                                    </Typography.Text>
                                </Space>
                            </>
                        )}
                    </Space>
                );
            },
        },
        {
            title: "Roles",
            dataIndex: "roles",
            hidden: isMobile,
            width: 200,
            ellipsis: true,
            key: "roles",
            render: (_: string, record: DestinationWarehouseMemberModel) => {
                return record.getRoles() ? (
                    <div
                        style={{
                            textTransform: "capitalize",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 10,
                            width: 200,
                        }}
                    >
                        {record
                            .getRoles()
                            .getData()
                            .map((role) => (
                                <Tag key={role.id} color="green">
                                    {role.getLabel()}
                                </Tag>
                            ))}
                    </div>
                ) : (
                    ""
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            hidden: isMobile,
            width: 100,
            ellipsis: true,
            align: "center" as const,
            key: "status",
            render: (_: string, record: DestinationWarehouseMemberModel) => {
                return (
                    <Tag
                        icon={
                            record.getStatus() === "active" ? (
                                <CheckCircleOutlined />
                            ) : (
                                <ExclamationCircleOutlined />
                            )
                        }
                        color={
                            record.getStatus() === "active"
                                ? "success"
                                : "warning"
                        }
                    >
                        {record.getStatus()}
                    </Tag>
                );
            },
        },
        {
            title: "Member since",
            dataIndex: "user",
            width: 200,
            ellipsis: true,
            hidden: isMobile,
            align: "center" as const,
            key: "user",
            render: (_: string, record: DestinationWarehouseMemberModel) => {
                return record.getMemberSince() ? (
                    <Tag color={"purple"}>{record.getMemberSince()}</Tag>
                ) : (
                    ""
                );
            },
        },
        checkActionPermission(
            [
                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_MANAGE,
                ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
            ],
            {
                title: "Actions",
                key: "actions",
                fixed: isMobile ? "right" : undefined,
                width: isMobile ? 80 : 150,
                ellipsis: true,
                render: (
                    _: string,
                    record: DestinationWarehouseMemberModel,
                ) => {
                    const items: ExtendedMenuItemType[] = [
                        {
                            permission:
                                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_MANAGE,
                            key: "1",
                            label: (
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setSelectedDestinationWarehouseMemberId(
                                            record.getId(),
                                        );
                                        setModalOpen(
                                            DestinationWarehouseMemberModalType.Update_Member,
                                        );
                                    }}
                                >
                                    <EditOutlined /> <span> Update member</span>
                                </div>
                            ),
                        },
                        {
                            icon: <FiLogIn />,
                            label: "Create Impersonate",
                            key: "login",
                            permission:
                                ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
                            onClick: async () => {
                                setIsShowImpersonateModal(true);
                                setSelectedUser(record.getUser());
                            },
                        },
                        {
                            permission:
                                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_MANAGE,
                            key: "2",
                            label: (
                                <Popconfirm
                                    title="Remove this member"
                                    description="Are you sure to remove this member?"
                                    onConfirm={() =>
                                        handleDeleteMember(record.getId())
                                    }
                                    onCancel={onCancelModal}
                                    okButtonProps={{ loading: isLoadingDelete }}
                                    okText="Yes"
                                    okType="danger"
                                    cancelText="No"
                                >
                                    <span onClick={(e) => e.stopPropagation()}>
                                        <DeleteOutlined /> Delete
                                    </span>
                                </Popconfirm>
                            ),
                            danger: true,
                        },
                    ];
                    if (isMobile) {
                        items.push({
                            permission:
                                DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MEMBER_VIEW,
                            label: (
                                <Tag color="purple">
                                    {record.getMemberSince()}
                                </Tag>
                            ),
                            key: "created_at",
                            disabled: true,
                        });
                    }

                    return (
                        <Dropdown
                            menu={{
                                items: items.filter((x) =>
                                    checkActionPermission(
                                        x.permission,
                                        x,
                                        null,
                                    ),
                                ),
                            }}
                        >
                            <Button icon={<HddFilled />}>
                                {isMobile ? (
                                    <DownOutlined />
                                ) : (
                                    <>
                                        Actions <DownOutlined />{" "}
                                    </>
                                )}
                            </Button>
                        </Dropdown>
                    );
                },
            },
            <></>,
        ),
    ];

    return (
        <>
            <div
                className="modalTableWrapper"
                style={{ minHeight: "41vh", marginTop: "20px" }}
            >
                <Table
                    //@ts-ignore
                    columns={columns}
                    size="small"
                    bordered
                    loading={isLoading}
                    dataSource={MemberCollectionData?.getData()}
                    pagination={paginationConfig}
                    rowKey="id"
                    scroll={{ x: 500 }}
                />
            </div>

            <Modal
                title={modalOpen && getKeyByValue(modalOpen)}
                open={Boolean(modalOpen)}
                footer={null}
                onCancel={onCancelModal}
            >
                {modalOpen ===
                    DestinationWarehouseMemberModalType.Update_Member &&
                    selectedDestinationWarehouseMemberId && (
                        <DestinationWarehouseMemberModification
                            onHide={onCancelModal}
                            destinationWarehouseId={destinationWarehouseId}
                            destinationWarehouseMemberId={
                                selectedDestinationWarehouseMemberId
                            }
                        />
                    )}
            </Modal>

            {isShowImpersonateModal && selectedUser && (
                <Modal
                    title={
                        <ImpersonateCreateModalTitle
                            name={selectedUser?.getName() || ""}
                            email={selectedUser?.getEmail() || ""}
                            title=""
                        />
                    }
                    open={isShowImpersonateModal}
                    destroyOnClose={true}
                    footer={false}
                    onCancel={() => setIsShowImpersonateModal(false)}
                >
                    <ImpersonateForm
                        userType={UserType.DwAdmin}
                        selectedUser={{ id: selectedUser.getId() }}
                        setIsModalVisible={setIsShowImpersonateModal}
                    />
                </Modal>
            )}
        </>
    );
};
