import LogoIcon from "@/assets/logo";
import { TransactionModel } from "@/models/transactionCollectionModels";
import { TransactionModelDetails } from "@/models/transactionDetailModels";
import { ICustomerUser } from "@/types/customerUserCollection";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect } from "react";

interface PrintProps {
    items: TransactionModel[];
    user?: string;
    customer?: ICustomerUser | undefined;
    setTrxDetails: Dispatch<
        SetStateAction<TransactionModelDetails | undefined>
    >;
}

const Print: React.FC<PrintProps> = ({
    items,
    user,
    customer,
    setTrxDetails,
}) => {
    const total = items
        .reduce((sum, item) => sum + parseFloat(item.amount.toFixed(2)), 0)
        .toFixed(2);
    const discount = prompt(
        `Total invoice amount is: ${total}. Enter discount amount: `,
        "0",
    );
    const invDate = moment().format("DD/MM/YYYY");
    const invNumber = new Date().getTime().toString().substring(3);

    useEffect(() => {
        return () => {
            setTrxDetails(undefined);
        };
    }, [setTrxDetails]);

    return (
        <>
            <style>{`
        * {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 12px;
        }
        table.receipt-head {
          margin-bottom: 20px;
          width: 100%;
        }
        table.receipt-head > tbody > tr > td {
          width: 50%;
          vertical-align: middle;
        }
        .receipt-info {
          width: 100%;
          border-collapse: collapse;
        }
        .brand-logo {
          width: 200px;
          height: auto;
        }
        table.receipt caption {
          text-align: center;
          font-weight: bold;
          margin-bottom: 10px;
          font-size: 1.4em;
          text-transform: uppercase;
        }
        table.receipt {
          border: 1px solid #000;
          width: 100%;
          border-collapse: collapse;
          margin-bottom: 20px;
        }
        table.receipt tr td,
        table.receipt tr th,
        table.receipt-info tr td {
          border: 1px solid #000;
          padding: 2px;
        }
      `}</style>
            <table className="receipt-head">
                <tbody>
                    <tr>
                        <td>
                            <LogoIcon
                                style={{
                                    width: 100,
                                    height: "50px",
                                }}
                            />
                        </td>
                        <td>
                            <h1>{"MoveOn Technologies Ltd."}</h1>
                            <p>
                                {
                                    "Plot 1020, Road 9, Avenue 9, Mirpur DOHS, Dhaka - 1216."
                                }{" "}
                                <br />
                                Phone: {"+8809666 78 3333"}, Email:{" "}
                                {"support@moveon.com.bd"}, Web:{" "}
                                {`https://moveon.com.bd`}
                            </p>
                            <table className="receipt-info">
                                <thead>
                                    <tr>
                                        <td>Date</td>
                                        <td>Receipt No.</td>
                                        <td>Prepared For</td>
                                        <td>Prepared By</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{invDate}</td>
                                        <td>{invNumber}</td>
                                        <td>
                                            {customer?.name ?? "N/A"}{" "}
                                            {customer?.phone
                                                ? `(${customer.phone})`
                                                : null}
                                        </td>
                                        <td>{user ?? "N/A"}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="receipt">
                <caption>Money Receipt</caption>
                <thead>
                    <tr>
                        <th>SL</th>
                        <th>Particular Details</th>
                        <th>Reference</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td align="center">
                                {`${index + 1}`.padStart(2, "0")}
                            </td>
                            <td align="center">{item.transaction_number}</td>
                            <td align="center">{item.gateway.name}</td>
                            <td align="right">{item.amount.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={3} align="right">
                            Total
                        </td>
                        <td align="right">{total}</td>
                    </tr>
                    <tr>
                        <td colSpan={3} align="right">
                            Discount/Rounding
                        </td>
                        <td align="right">
                            {discount ? parseFloat(discount).toFixed(2) : 0}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3} align="right">
                            Net Payable
                        </td>
                        <td align="right">
                            {discount
                                ? (
                                      parseFloat(total) - parseFloat(discount)
                                  ).toFixed(2)
                                : parseFloat(total)}
                        </td>
                    </tr>
                </tfoot>
            </table>
            <table className="receipt-head" style={{ marginTop: "75px" }}>
                <tbody>
                    <tr>
                        <td align="left">Customer Signature</td>
                        <td align="right">Prepared By</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default Print;
