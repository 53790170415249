import { ITranslationEntry } from "../lang";

export const BG: ITranslationEntry = {
    translations: {
        notifications: "известия",
        markAllAsRead: "маркирай всички като прочетени",
        poweredBy: "задвижвани от",
        settings: "настройки",
        noNewNotification: "Все още няма нищо ново за гледане тук",
    },
    lang: "bg",
};
