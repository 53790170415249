export function roundToTwoDecimal(value: unknown): number {
    let numberValue: number;

    if (typeof value === "number" && !isNaN(value)) {
        // If the value is already a valid number
        numberValue = value;
    } else if (typeof value === "string" && !isNaN(Number(value))) {
        // If it's a string that can be converted to a number
        numberValue = Number(value);
    } else {
        // If neither a valid number nor convertible string, return 0
        return 0;
    }

    // Perform rounding to two decimal places
    return Math.round(numberValue * 100) / 100;
}
