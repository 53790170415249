import { DimensionUnitEnum } from "@/enums/shipForMeContextEnums";
import { UnitTypeEnums } from "@/enums/shippingCoreEnums";
import { Card, Col, Form, InputNumber, Row, Select, Switch } from "antd";
import { FormInstance } from "antd/lib";
const { Option } = Select;
interface IProps {
    showMeasurements: boolean;
    setShowMeasurements: React.Dispatch<React.SetStateAction<boolean>>;
    form: FormInstance<any>;
}
const MeasurementsPart = ({
    showMeasurements,
    setShowMeasurements,
    form,
}: IProps) => {
    // Units for weight and dimensions
    const weightUnits: UnitTypeEnums[] = [
        UnitTypeEnums.KG,
        UnitTypeEnums.CBM,
        UnitTypeEnums.PIECE,
    ];
    const dimensionUnits: DimensionUnitEnum[] = [
        DimensionUnitEnum.IN,
        DimensionUnitEnum.CM,
    ];

    // Measurement Types
    const measurementTypes = ["weight", "length", "width", "height"];

    return (
        <Card
            title={
                <Col
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <h3 style={{ margin: 0 }}>Measurements</h3>
                    <Switch
                        checked={showMeasurements}
                        onChange={() => {
                            setShowMeasurements((pre) => !pre);
                            form.setFieldsValue({
                                measurement: undefined,
                            });
                        }}
                    />
                </Col>
            }
        >
            {showMeasurements ? (
                <Row gutter={[20, 20]}>
                    {measurementTypes.map((type) => {
                        return (
                            <Col span={12} key={type}>
                                <Card
                                    title={`${type.charAt(0).toUpperCase() + type.slice(1)}`}
                                >
                                    <Row
                                        gutter={24}
                                        style={{
                                            marginBottom: 16,
                                            alignItems: "center",
                                        }}
                                    >
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "measurement",
                                                    type,
                                                    "value",
                                                ]}
                                                label="Value"
                                                rules={[
                                                    {
                                                        required:
                                                            type === "weight",
                                                        message:
                                                            "Please enter a value",
                                                    },
                                                    {
                                                        type: "number",
                                                        min: 0.0000000000000001,
                                                        message:
                                                            "Value must be > 0",
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    style={{ width: "100%" }}
                                                    placeholder="Enter Value"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <Form.Item
                                                name={[
                                                    "measurement",
                                                    type,
                                                    "unit",
                                                ]}
                                                label="Unit"
                                                initialValue={
                                                    type === "weight"
                                                        ? UnitTypeEnums.KG
                                                        : DimensionUnitEnum.CM
                                                }
                                                rules={[
                                                    {
                                                        required:
                                                            type === "weight",
                                                        message:
                                                            "Please select a unit",
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Select Unit">
                                                    {type === "weight"
                                                        ? weightUnits.map(
                                                              (unit) => (
                                                                  <Option
                                                                      key={unit}
                                                                      value={
                                                                          unit
                                                                      }
                                                                  >
                                                                      {unit}
                                                                  </Option>
                                                              ),
                                                          )
                                                        : dimensionUnits.map(
                                                              (unit) => (
                                                                  <Option
                                                                      key={unit}
                                                                      value={
                                                                          unit
                                                                      }
                                                                  >
                                                                      {unit}
                                                                  </Option>
                                                              ),
                                                          )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        padding: "10px 0",
                    }}
                >
                    Enable Measurement to see details
                </div>
            )}
        </Card>
    );
};

export default MeasurementsPart;
