import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import {
    ITrackerAddCommentFormData,
    ITrackerReplyCommentFormData,
} from "@/types/timelineTracking";

class TrackResource extends BaseResource {
    path = `/api/track/admin/timeline-tracking/v1`;
    commentPath = `/api/track/admin/comment/v1/comments`;
    getTimelineTrackingShipmentProduct(
        productId: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}/shipment-product/${productId}/timeline-trackings`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getTimelineTrackingBuyProduct(
        productId: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}/buy-product/${productId}/timeline-trackings`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    createComment(
        payload: ITrackerAddCommentFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.commentPath}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updateComment(
        id: number,
        payload: ITrackerAddCommentFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.commentPath}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    deleteComment(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.commentPath}/${id}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }

    replyComment(
        id: number,
        payload: ITrackerReplyCommentFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.commentPath}/${id}/reply`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
}

export default TrackResource;
