export enum NumberOfTeamMemberEnum {
    "Less than 10 people" = "less_than_10_people",
    "10 to 50 people" = "10_to_50_people",
    "50 to 100 people" = "50_to_100_people",
    "above 100 people" = "above_100_people",
}

export enum YearsOfExperienceEnum {
    ">= 5 years" = "above_5_years",
    "3-5 years" = "3_to_5_years",
    "1-2 years" = "1_to_2_years",
    "<= 1 year" = "under_1_years",
}

export enum AgentDesignationEnum {
    Director = "director",
    Executive = "executive",
    Manager = "manager",
    Superviser = "superviser",
    Employee = "employee",
}

export enum CompanyTypeEnum {
    Cross_Border = "cross-border",
}
