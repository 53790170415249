import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Result, Row, Skeleton } from "antd";
import { useAtom } from "jotai";
import { masterWalletOverviewCollectionAtom } from "@/lib/core-react/store/store";
import { useEffect, useMemo, useState } from "react";
import { useWalletOverviewBalance } from "@/lib/core-react/hooks/private/useWallet";
import { WalletOverviewModel } from "@/models/walletOverviewCollectionModel";
import WalletCard from "../WalletCard";
import WalletTable from "./components/WalletTable";
import { useNavigate } from "react-router-dom";

const WalletOverview = () => {
    const { getWalletOverviewBalance } = useWalletOverviewBalance();
    const navigate = useNavigate();

    const [{ data: walletOverviewData, isLoading, error }] = useAtom(
        masterWalletOverviewCollectionAtom,
    );
    const [walletId, setWalletId] = useState<number | undefined>(undefined);
    const [currencyCode, setCurrencyCode] = useState<string | undefined>(
        undefined,
    );

    useEffect(() => {
        getWalletOverviewBalance();
    }, []);

    useEffect(() => {
        if (walletOverviewData) {
            if (walletOverviewData?.available?.data[0]?.id) {
                setWalletId(walletOverviewData.available.data[0].id);
                setCurrencyCode(
                    walletOverviewData.available.data[0].currency.code,
                );
            }
        }
    }, [walletOverviewData]);

    const walletOverviewCollectionData = useMemo(
        () => walletOverviewData && new WalletOverviewModel(walletOverviewData),
        [walletOverviewData],
    );

    // return <></>
    const currencyData = useMemo(
        () => walletOverviewCollectionData?.getByCurrency(),
        [walletOverviewCollectionData],
    );

    // Memoize the currencies list to prevent unnecessary re-renders
    const currencies = useMemo(
        () =>
            Object.entries(currencyData || {}).map(([currencyCode, data]) => ({
                code: currencyCode,
                totalAvailableBalance: data.totalAvailableBalance,
                totalReservedBalance: data.totalReservedBalance,
                totalPendingBalance: data.totalPendingBalance,
                symbol: data.symbol,
                walletId: data.walletIds[0],
            })),
        [currencyData],
    );

    // Handle wallet balance card click
    const handleWalletBalanceClick = (id: number, code: string) => {
        setWalletId(id);
        setCurrencyCode(code);
    };

    // Handle back navigation
    const handleBack = () => {
        navigate("/");
    };

    // Render error state if any
    if (!isLoading && error) {
        return (
            <Result
                subTitle={error}
                extra={
                    <Button onClick={handleBack} type="primary">
                        Back Home
                    </Button>
                }
            />
        );
    }

    return (
        <PageHeader
            ghost={false}
            title="Master Wallet"
            style={{ marginTop: "10px" }}
        >
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Row>
                    <WalletCard
                        key={walletId}
                        walletId={walletId}
                        handleWalletBalanceClick={handleWalletBalanceClick}
                        item={currencies}
                    />
                </Row>
            )}
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Row>
                    <Col span={24}>
                        {walletId && (
                            <WalletTable
                                key={walletId}
                                isLoading={isLoading}
                                walletId={walletId}
                                currencyCode={currencyCode}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </PageHeader>
    );
};

export default WalletOverview;
