import { ITranslationEntry } from "../lang";

export const AF: ITranslationEntry = {
    translations: {
        notifications: "Kennisgewings",
        markAllAsRead: "Merk alles as gelees",
        poweredBy: "Aangedryf deur",
        settings: "Instellings",
        noNewNotification: "Nog niks nuuts om hier te sien nie",
    },
    lang: "af",
};
