import {
    DownOutlined,
    EditOutlined,
    FileAddOutlined,
    HddFilled,
    PlusOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Dropdown, Input, Modal, Row, Table } from "antd";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import { useGetBaseShippingCategory } from "@/lib/core-react/hooks/private";
import { baseShippingCategoryAtom } from "@/lib/core-react/store/store";
import {
    BaseShippingCategoryDataModel,
    BaseShippingCategoryModel,
} from "@/models/baseShippingCategory";
import { PaginationModel } from "@/models/pagination";
import { useState, useEffect } from "react";
import UpdateBaseShippingCategory from "./components/UpdateBaseShippingCategory";
import CreateBaseShippingCategory from "./components/CreateBaseShippingCategory";
import AddChildren from "./components/AddChildren";
import { ApiHelperModel } from "@/models/apiHelper";
import { StatusTag } from "../../../components";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_SHIPPING_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { checkNestedChildrenAndMakeItNull } from "@/utils/helper";
import CommonError from "@/components/Error/CommonError";
import { useSyncBaseShippingCategoryResource } from "@/lib/core-react/hooks/private/useBaseShippingCategory";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { showError } from "@/helpers/showError";
import useRefetch from "@/hooks/useRefetch";
const { Column } = Table;
const BaseShippingCategory = () => {
    const { isMobile } = useWindowWidth();
    const { getBaseShippingCategory } = useGetBaseShippingCategory();
    const { syncBaseShippingCategory, isLoading: isLoadingSync } =
        useSyncBaseShippingCategoryResource();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredData, setFilteredData] = useState<
        BaseShippingCategoryDataModel[]
    >([]);
    const [
        {
            data: baseShippingData,
            isLoading,
            refetch,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(baseShippingCategoryAtom);

    const { filters, handleFilterChange, isFirstCall, isFetched } =
        useDataFilters();

    const { refetchListApi } = useRefetch();

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(filters, getBaseShippingCategory);
        }
    }, [isFirstCall, filters, isFetched, refetch]);

    // Base Shipping category
    const BaseShippingCategory =
        baseShippingData && new BaseShippingCategoryModel(baseShippingData);

    // Add Child
    const [isShowAddChildModal, setIsShowAddChildModal] =
        useState<boolean>(false);
    const [addChildPayload, setAddChildPayload] = useState<
        BaseShippingCategoryDataModel | undefined
    >(undefined);
    // Show Add child modal with data
    const showAddChildModal = (record: any) => {
        setAddChildPayload(record);
        setIsShowAddChildModal(true);
    };
    // Create
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

    //Update
    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
    const [updatePayload, setUpdatePayload] = useState<
        BaseShippingCategoryDataModel | undefined
    >(undefined);

    // Show update modal with data
    const showUpdateModal = (record: any) => {
        setUpdatePayload(record);
        setIsShowUpdateModal(true);
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getBaseShippingCategory,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        BaseShippingCategory,
        handlePaginationChange,
    );

    useEffect(() => {
        if (BaseShippingCategory) {
            if (searchTerm) {
                const filtered = BaseShippingCategory.search(searchTerm);
                setFilteredData(filtered);
            } else {
                setFilteredData(BaseShippingCategory.data);
            }
        }
    }, [searchTerm, baseShippingData]);

    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    const handleSync = async () => {
        try {
            await syncBaseShippingCategory();
            showSuccessAlert("Base shipping categories synced successfully");
            await refetchListApi();
        } catch (error) {
            showError(error);
        }
    };

    // // baseShipping  Category-> Global catagories

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Global categories"
                    style={{ marginTop: "10px" }}
                    extra={[
                        <>
                            <Button
                                style={{ padding: 0 }}
                                type="link"
                                htmlType="button"
                                icon={<SyncOutlined spin={isLoadingSync} />}
                                onClick={() => handleSync()}
                            >
                                Sync
                            </Button>
                            {checkActionPermission(
                                ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_MANAGE,
                                <Button
                                    icon={<PlusOutlined />}
                                    type="primary"
                                    key="1"
                                    onClick={() => setIsShowCreateModal(true)}
                                >
                                    Create global category
                                </Button>,

                                null,
                            )}
                        </>,
                    ]}
                >
                    <div>
                        <Row>
                            <Col xs={24} sm={24} md={9} lg={9}>
                                <Input.Search
                                    placeholder="Search by name or slug"
                                    value={searchTerm}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    onSearch={(value) => setSearchTerm(value)}
                                    allowClear
                                />
                            </Col>
                        </Row>
                        <Table
                            style={{ marginTop: "15px" }}
                            bordered
                            loading={isLoading}
                            rowKey={(r) => r.id}
                            pagination={paginationConfig}
                            dataSource={
                                checkNestedChildrenAndMakeItNull(
                                    filteredData,
                                ) as BaseShippingCategoryDataModel[]
                            }
                            scroll={{ x: 1000 }}
                        >
                            <Column title="Name" dataIndex="name" key="name" />

                            <Column title="Slug" dataIndex="slug" key="slug" />
                            <Column
                                title="Status"
                                dataIndex="status"
                                key="status"
                                render={(
                                    _,
                                    record: BaseShippingCategoryDataModel,
                                ) => (
                                    <StatusTag
                                        text={record.status}
                                        slug={record.status}
                                    />
                                )}
                            />
                            <Column
                                hidden={
                                    !checkActionPermission(
                                        ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_MANAGE,
                                        "checkPermission",
                                        null,
                                    )
                                }
                                title="Action"
                                key="action"
                                fixed={isMobile ? "right" : undefined}
                                align="center"
                                width={100}
                                render={(_, record) => {
                                    const items: ExtendedMenuItemType[] = [
                                        {
                                            permission:
                                                ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_MANAGE,
                                            key: "1",
                                            label: (
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        showUpdateModal(record)
                                                    }
                                                >
                                                    <EditOutlined />{" "}
                                                    <span> Update</span>
                                                </div>
                                            ),
                                        },
                                        {
                                            permission:
                                                ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_MANAGE,
                                            key: "2",
                                            label: (
                                                <div
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        showAddChildModal(
                                                            record,
                                                        )
                                                    }
                                                >
                                                    <FileAddOutlined />{" "}
                                                    <span> Add Child</span>
                                                </div>
                                            ),
                                        },
                                    ];

                                    return (
                                        <Dropdown
                                            menu={{
                                                items: items.filter((x) =>
                                                    checkActionPermission(
                                                        x.permission,
                                                        x,
                                                        null,
                                                    ),
                                                ),
                                            }}
                                        >
                                            <Button icon={<HddFilled />}>
                                                {isMobile ? (
                                                    <DownOutlined />
                                                ) : (
                                                    <>
                                                        Actions <DownOutlined />{" "}
                                                    </>
                                                )}
                                            </Button>
                                        </Dropdown>
                                    );
                                }}
                            />
                        </Table>
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={`Create Global Category`}
                open={isShowCreateModal}
                footer={false}
                onCancel={() => setIsShowCreateModal(false)}
            >
                <CreateBaseShippingCategory
                    setIsShowCreateModal={setIsShowCreateModal}
                />
            </Modal>

            <Modal
                title={`Update Global Category`}
                open={isShowUpdateModal}
                footer={false}
                onCancel={() => setIsShowUpdateModal(false)}
            >
                <UpdateBaseShippingCategory
                    setIsShowUpdateModal={setIsShowUpdateModal}
                    data={updatePayload as BaseShippingCategoryDataModel}
                />
            </Modal>
            <Modal
                title={`Add Children`}
                open={isShowAddChildModal}
                footer={false}
                onCancel={() => setIsShowAddChildModal(false)}
            >
                <AddChildren
                    setIsShowAddChildModal={setIsShowAddChildModal}
                    data={addChildPayload as BaseShippingCategoryDataModel}
                />
            </Modal>
        </>
    );
};

export default BaseShippingCategory;
