import { UiNodeWithCsrfToken } from "@/types/ory";
import { UiNode, UiNodeInputAttributes } from "@ory/client";
import { message } from "antd";

import moment from "moment";

const formateDate = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : null;
};

export const formatDateTimeLocal = (
    date: string | undefined,
): string | null => {
    return date ? moment(date).format("D MMMM, YYYY - h:mm A") : null;
};

const calculateShippingPrice = (rate, commission) => {
    if (commission) {
        rate = rate + rate * (commission / 100);
    } else {
        return rate;
    }
    return rate;
};

const formatDate = (dateData) => {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const today = new window.Date(dateData);
    let date = today.getDate().toString();
    if (date.length === 1) {
        date = "0" + date;
    }
    return (
        date + " " + monthNames[today.getMonth()] + " , " + today.getFullYear()
    );
};

const formatTime = (time) => {
    return new window.Date(time).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    });
};

const formatDateTime = (dateTime) => {
    if (!dateTime) {
        return "N/A";
    }
    return formatDate(dateTime) + " - " + formatTime(dateTime);
};

function isValidISODate(dateString) {
    // Regular expression to match ISO 8601 format
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,6})?Z$/;

    return regex.test(dateString);
}

const updateSize = (url, size) => {
    // console.log(url.replace(/\d+x\d+/, `${size}x${size}`))
    return url.replace(/_\d+x\d+/, `_${size}x${size}`);
};

export const updateSizeMore = (url, size) => {
    return url?.replace(/\d+x\d+/, `${size}x${size}`);
};

export const calculateFx = (valueFx, valueCommission) => {
    return (valueFx + valueFx * (valueCommission / 100)).toFixed(2);
};

export const exportToCsv = (filename, rows) => {
    const processRow = function (row) {
        let finalVal = "";
        for (let j = 0; j < row.length; j++) {
            let innerValue = row[j] === null ? "" : row[j]?.toString();
            if (row[j] instanceof Date) {
                innerValue = row[j]?.toLocaleString();
            }
            let result = innerValue?.replace(/"/g, '""');
            if (result.search(/([",\n])/g) >= 0) result = '"' + result + '"';
            if (j > 0) finalVal += ",";
            finalVal += result;
        }
        return finalVal + "\n";
    };

    let csvFile = "";
    for (let i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

export const formattedWithFloatAmount = (price) => {
    return Math.round(price * 100) / 100;
};

// Quick and simple export target #table_id into a csv
export const download_table_as_csv = (table_id, separator = ",") => {
    // Select rows from table_id
    const rows = document.querySelectorAll("table#" + table_id + " tr");
    // Construct csv
    const csv = [];
    for (let i = 0; i < rows.length; i++) {
        const row = [],
            cols = rows[i].querySelectorAll("td, th");
        for (let j = 0; j < cols.length; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            // @ts-ignore
            let data = cols[j].innerText
                .replace(/(\r\n|\n|\r)/gm, "")
                .replace(/(\s\s)/gm, " ");
            data = data.replace(/"/g, '""');
            // Push escaped string
            // @ts-ignore
            row.push('"' + data + '"');
        }
        // @ts-ignore
        csv.push(row.join(separator));
    }

    const csv_string = csv.join("\n");

    // Download it
    const filename =
        "Export_" + table_id + "_" + formatDateTime(new Date()) + ".csv";
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string),
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const toastErrors = (erorrs) => {
    if (erorrs?.errors) {
        const errors = erorrs.errors;
        Object.keys(errors)?.forEach((key) => {
            message.error(errors?.[key]?.[0]);
        });
    } else {
        message.error(erorrs?.message);
    }
};

const slugMaker = (text) => {
    if (text) {
        return text
            .trim()
            .replace(/\s+/g, " ")
            .split(" ")
            .join("-")
            .toLowerCase();
    }
    return "";
};

const transformEnumToLabeledValue = (enumObj) => {
    return Object.keys(enumObj).map((key) => ({
        label: key,
        value: enumObj[key],
    }));
};

export {
    formateDate,
    calculateShippingPrice,
    formatDate,
    formatTime,
    formatDateTime,
    isValidISODate,
    updateSize,
    toastErrors,
    slugMaker,
    transformEnumToLabeledValue,
};

// Define a type guard to check if a node has input attributes with a name and value
function hasCsrfToken(node: UiNode): node is UiNodeWithCsrfToken {
    return (
        node.attributes.node_type === "input" &&
        (node.attributes as UiNodeInputAttributes).name === "csrf_token" &&
        (node.attributes as UiNodeInputAttributes).value !== undefined
    );
}

// Helper function to get the value of csrf_token
export const getCsrfTokenValue = (nodes: UiNode[]): string | undefined => {
    const csrfNode = nodes.find(hasCsrfToken);
    return csrfNode ? csrfNode.attributes.value : undefined;
};

export const getFulfillmentStatusColor = (status: string): string => {
    switch (status) {
        case "awaiting-fulfilment":
            return "#FFA500"; // Orange (Pending)
        case "fulfilled":
            return "#4CAF50"; // Green (Fulfilled)
        case "cancelled":
            return "#FF4C4C"; // Red (Cancelled)
        case "refunded":
            return "#2196F3"; // Blue (Refunded)
        default:
            return "#808080"; // Grey (Unknown or Default)
    }
};

export const originalLinkToMoveOnLinkConversion = (
    id: string,
    title: string,
): string => {
    const last13 = id.slice(-13);

    const first13 = id.slice(0, 13);
    const slug = title
        .toLowerCase() // Convert to lowercase
        .replace(/[^\w\s-]/g, "") // Remove special characters
        .replace(/\s+/g, "-") // Replace spaces with hyphens
        .trim(); // Remove extra spaces around

    // Step 4: Construct the final URL
    const url = `https://moveon.global/BD_en/products/${last13}-${slug}/${first13}`;

    return url;
};

export const truncateText = (
    text: string | number | undefined,
    maxLength: number,
) => {
    if (text === undefined) {
        return ""; // Return an empty string for undefined
    }
    const str = String(text); // Convert the input to a string
    return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const getCurrentDateTime = (): string => {
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const now = new Date();

    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();

    let hours = now.getHours();
    const minutes = now.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12 || 12;

    // Format minutes with leading zero
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${day}_${month}_${year}-${hours}_${formattedMinutes}_${amPm}`;
};
