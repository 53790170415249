import { StatusTag } from "@/components";
import ShippingText from "@/components/ShippingText";
import { SHIPMENT_PRODUCTS_PAGE } from "@/consts/pageRoute";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";

import { CustomerInvoiceModel } from "@/models/buyProductCollectionModel";
import { Col, Flex, Row, Tooltip, Typography } from "antd";
import React from "react";

interface InvoiceDetailsProps {
    invoice: CustomerInvoiceModel | undefined | null;
    regionCurrencyCode: string;
    linkPath?: string;
}

const InvoiceInfo: React.FC<InvoiceDetailsProps> = ({
    invoice,
    regionCurrencyCode,
}) => {
    if (!invoice) return null;

    const isShipmentProductListPage =
        SHIPMENT_PRODUCTS_PAGE === window.location.pathname;

    const renderShippingText = (
        label: string,
        slug: string,
        value: number | null,
        color?: string,
    ) => {
        if (!value) return null;

        return (
            <ShippingText label={label}>
                <StatusTag
                    slug={slug}
                    color={color}
                    text={`${roundToTwoDecimal(value)} ${regionCurrencyCode}`}
                />
            </ShippingText>
        );
    };

    return (
        <Row>
            <Col span={isShipmentProductListPage ? 12 : 24}>
                <Flex vertical gap={8}>
                    {!isShipmentProductListPage && (
                        <ShippingText label="Invoice No">
                            <Tooltip
                                title={invoice.getInvoiceNumber()}
                                placement="top"
                            >
                                <Typography.Text
                                    strong
                                    style={{
                                        display: "block",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "170px",
                                        cursor: "pointer",
                                    }}
                                    copyable={{
                                        text: invoice.getInvoiceNumber(),
                                    }}
                                >
                                    {invoice.getInvoiceNumber()}
                                </Typography.Text>
                            </Tooltip>
                        </ShippingText>
                    )}
                    {renderShippingText(
                        "Total",
                        "",
                        invoice.getTotalAmount(),
                        "cyan-inverse",
                    )}
                    {renderShippingText("Paid", "paid", invoice.getPaid())}
                    {!isShipmentProductListPage &&
                        renderShippingText(
                            "Over Paid",
                            "overPaid",
                            invoice.getOverPaid(),
                        )}
                    {renderShippingText("Due", "due", invoice.getDue())}
                    {renderShippingText(
                        "Settled",
                        "settled",
                        invoice.getTransactionSummary().getSettled(),
                    )}
                    {renderShippingText(
                        "Refund",
                        "",
                        invoice.getRefundableAmount(),
                        "red",
                    )}
                </Flex>
            </Col>
            <Col
                span={isShipmentProductListPage ? 12 : 24}
                style={{
                    borderLeft: isShipmentProductListPage
                        ? "1px solid #f0f0f0"
                        : "none",
                    marginTop: 8,
                }}
            >
                <Flex
                    vertical={isShipmentProductListPage}
                    flex={1}
                    gap={2}
                    align="center"
                    justify={isShipmentProductListPage ? "center" : "start"}
                >
                    <div>
                        <Typography.Text underline={isShipmentProductListPage}>
                            Status
                        </Typography.Text>
                    </div>
                    <div>
                        <StatusTag
                            slug={invoice.getStatus()}
                            text={invoice.getStatus()}
                        />
                    </div>
                </Flex>
            </Col>
        </Row>
    );
};

export default InvoiceInfo;
