import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Dropdown,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
    Image,
    Modal,
    message,
    Tabs,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAtom, useSetAtom } from "jotai";
import { agentInvoiceCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import {
    CalculatorFilled,
    DownloadOutlined,
    DownOutlined,
    EditOutlined,
    EyeFilled,
    HddFilled,
    LoadingOutlined,
    PrinterOutlined,
} from "@ant-design/icons";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import {
    AgentInvoiceCollectionModel,
    AgentInvoiceModel,
    InvoiceStatus,
} from "@/models/invoiceCollectionModel";
import FiltersComponent from "@/components/FiltersComponent";
import { IFilterType } from "@/types/filters";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "@/components";
import { ExtendedMenuItemType } from "@/types";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import { formatString } from "@/utils/helper";
import CommonError from "@/components/Error/CommonError";
import Paragraph from "antd/es/typography/Paragraph";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { showError } from "@/helpers/showError";
import useActionsInvoice from "@/pages/Shipping/ShipmentProducts/InvoiceSummary/useActionsInvoice";
import { ColumnsType } from "antd/es/table";
import { IModalData } from "@/helpers/getModalTital";
import TabPane from "antd/es/tabs/TabPane";
const AgentInvoiceList = () => {
    const navigate = useNavigate();
    const {
        getAgentInvoice,
        settleInvoice,
        recalculateInvoiceData,
        handledownloadData,
    } = useInvoice();
    const [
        {
            data: invoiceCollectionData,
            isLoading,
            refetch,
            unAuthorized,
            error,
            code,
        },
    ] = useAtom(agentInvoiceCollectionAtom);
    const setAgentInvoiceCollection = useSetAtom(agentInvoiceCollectionAtom);

    const [isRecalculating, setIsRecalculating] = useState(false);
    const [loadingInvoices, setLoadingInvoices] = useState<
        Record<number, boolean>
    >({});
    const { isMobile } = useWindowWidth();
    const { Text } = Typography;
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
    } = useDataFilters();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<AgentInvoiceModel[]>([]);

    const onClearBulkSelectedState = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    const onChangeRow = (
        selectedRowKeys: React.Key[],
        rows: AgentInvoiceModel[],
    ) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(rows);
    };

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(filters || {}, getAgentInvoice);
        }
    }, [isFirstCall, isFetched, refetch]);

    const InvoiceCollectionData =
        invoiceCollectionData &&
        new AgentInvoiceCollectionModel(invoiceCollectionData);
    const filterData = InvoiceCollectionData?.getFilter();

    useEffect(() => {
        if (!isFetched && InvoiceCollectionData?.getFilter()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,

        InvoiceCollectionData?.getFilter(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAgentInvoice,
        );
    };

    const agentInvoiceIds = useMemo(() => {
        return selectedRows.reduce<number[]>((acc, row) => {
            const id = row.getId();
            if (id !== undefined) {
                acc.push(id);
            }
            return acc;
        }, []);
    }, [selectedRows]);

    const handleModal = async (payload: IModalData) => {
        try {
            switch (payload.action) {
                case "refetch":
                    setAgentInvoiceCollection((prev) => ({
                        ...prev,
                        refetch: true,
                    }));
                    onClearBulkSelectedState();
                    break;
                default:
                    break;
            }
        } catch (error) {
            showError(error);
        }
    };

    const { getBulkActions } = useActionsInvoice({
        handleModal,
        agentInvoiceIds,
    });

    const handleShowInvoice = (invoiceId: number) => {
        if (!invoiceId) return;
        navigate(`/invoice/agent-invoice/print/${invoiceId}`);
    };

    const handleShowDetail = async (invoiceId: number) => {
        const url = `/invoice/agent-invoice/details/${invoiceId}`;
        window.open(url, "_blank");
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        InvoiceCollectionData,
        handlePaginationChange,
    );

    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getAgentInvoice);
    };

    const handleDownloadInvoice = async (invoiceId: number) => {
        setLoadingInvoices((prev) => ({ ...prev, [invoiceId]: true })); // Set loading for this invoice
        try {
            const response = await handledownloadData(
                Number(invoiceId),
                "agent",
            );

            if (response?.data?.download_url) {
                const downloadUrl = response.data.download_url;

                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = "";
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Download URL not found in response");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
        } finally {
            setLoadingInvoices((prev) => ({ ...prev, [invoiceId]: false })); // Reset loading for this invoice
        }
    };

    const hasSelected = selectedRowKeys.length > 0;
    // Group transactions by region
    const groupedByRegion = selectedRows.reduce(
        (acc, curr) => {
            const regionName = curr.getRegion().code;
            const currencySymbol = curr.getRegion().currency?.code || "";

            if (!acc[regionName]) {
                acc[regionName] = {
                    totalAmount: 0,
                    settled: 0,
                    due: 0,
                    over_settled: 0,
                    currencySymbol,
                    count: 0,
                };
            }
            acc[regionName].totalAmount += curr.getTotalAmount() ?? 0;
            acc[regionName].settled +=
                curr.getTransactionSummary().settled ?? 0;
            acc[regionName].due += curr.getTransactionSummary().due ?? 0;
            acc[regionName].over_settled +=
                curr.getTransactionSummary().over_settled ?? 0;
            acc[regionName].count += 1;

            return acc;
        },
        {} as Record<
            string,
            {
                count: number;
                totalAmount: number;
                settled: number;
                due: number;
                over_settled: number;
                currencySymbol: string;
            }
        >,
    );

    if (unAuthorized || error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    const columns: ColumnsType<AgentInvoiceModel> = [
        {
            title: "ID",
            dataIndex: "id",
            key: "sl",
            width: 70,
            render: (_: string, record: AgentInvoiceModel) => {
                return <div>{record.getId()}</div>;
            },
        },
        {
            title: "Invoice info",
            key: "invoice_and_order",
            width: 230,
            render: (_: string, record: AgentInvoiceModel) => {
                return (
                    <>
                        <Paragraph>
                            <Text strong>Invoice : </Text>
                            <Text copyable>
                                {record.getInvoiceNumber().slice(0, 12)}
                            </Text>
                        </Paragraph>

                        <Paragraph>
                            <Text strong>Order : </Text>
                            <Text copyable>
                                {record.getOrder().order_number}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Region : </Text>
                            <StatusTag
                                slug={record.getRegion().code}
                                text={record.getRegion().code}
                            />{" "}
                        </Paragraph>
                        {checkActionPermission(
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                            <Button
                                type="dashed"
                                onClick={() =>
                                    handleDownloadInvoice(record.getId())
                                }
                                style={{ marginTop: 5 }}
                                icon={
                                    loadingInvoices[record.getId()] ? (
                                        <LoadingOutlined />
                                    ) : (
                                        <DownloadOutlined />
                                    )
                                }
                            >
                                Invoice
                            </Button>,
                            null,
                        )}
                    </>
                );
            },
        },
        {
            title: "Product Info",
            dataIndex: "order",
            width: 320,
            key: "product_info",
            render: (_: string, record: AgentInvoiceModel) => {
                const order = record.getOrder();
                const orderType = order.order_type;
                const buyProduct = record.getBuyProduct();
                const shipmentProduct = record.getShipmentProduct();

                const containerStyle = {
                    fontSize: "12px",
                    textAlign: "left" as const,
                };
                const labelStyle = {
                    fontWeight: "bold",
                };
                const valueStyle = {
                    marginLeft: "4px",
                };
                const linkStyle = {
                    color: "#1890ff", // Default link color
                    textDecoration: "underline",
                    cursor: "pointer",
                };

                if (
                    (orderType === "buy-and-ship" && buyProduct) ||
                    (orderType === "buy-and-ship" && shipmentProduct)
                ) {
                    const productNumber = buyProduct
                        ? buyProduct?.product_number
                        : shipmentProduct?.product_number;
                    const productStatus = buyProduct
                        ? buyProduct.status
                        : shipmentProduct?.status;

                    return (
                        <>
                            <div style={{ display: "flex" }}>
                                {orderType === "buy-and-ship" && buyProduct && (
                                    <div style={{ marginRight: 10 }}>
                                        <Image
                                            width={60}
                                            src={buyProduct?.product_image}
                                            alt="Product Image"
                                            style={{ borderRadius: 5 }}
                                        />
                                    </div>
                                )}
                                <div style={containerStyle}>
                                    <div>
                                        <span
                                            title={"Product Number"}
                                            style={labelStyle}
                                        >
                                            P.Number :{" "}
                                        </span>
                                        <Text
                                            style={{
                                                ...linkStyle,
                                                ...valueStyle,
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `${buyProduct?.id ? `/purchase/buy-products/detail/${buyProduct?.id}` : `/shipping/shipment-products/edit/${shipmentProduct?.id}`}`,
                                                )
                                            }
                                            copyable
                                        >
                                            {productNumber}
                                        </Text>
                                    </div>
                                    <div>
                                        <span style={labelStyle}>
                                            Order Type :{" "}
                                        </span>
                                        <span style={valueStyle}>
                                            <StatusTag
                                                slug={orderType}
                                                text={formatString(orderType)}
                                            />
                                        </span>
                                    </div>

                                    <div style={{ marginTop: "10px" }}>
                                        <StatusTag
                                            slug={productStatus || ""}
                                            text={formatString(productStatus)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                } else if (orderType === "only-ship" && shipmentProduct) {
                    const productNumber = shipmentProduct.product_number;
                    const productStatus = shipmentProduct.status;
                    const shippingMode = shipmentProduct.shipping_mode;
                    const shippingType = shipmentProduct.shipping_type;

                    return (
                        <>
                            <div>
                                <div style={containerStyle}>
                                    <div>
                                        <span style={labelStyle}>
                                            P. Number :{" "}
                                        </span>
                                        <Text
                                            style={{
                                                ...linkStyle,
                                                ...valueStyle,
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `/shipping/shipment-products/edit/${shipmentProduct?.id}`,
                                                )
                                            }
                                            copyable
                                        >
                                            {productNumber}
                                        </Text>
                                        <div>
                                            <span style={labelStyle}>
                                                Order Type :{" "}
                                            </span>
                                            <span style={valueStyle}>
                                                <StatusTag
                                                    slug={orderType}
                                                    text={orderType}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <span style={labelStyle}>
                                            Shipping Mode :{" "}
                                        </span>
                                        <span style={valueStyle}>
                                            {shippingMode}
                                        </span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}>
                                            Shipping Type :{" "}
                                        </span>
                                        <span style={valueStyle}>
                                            {shippingType}
                                        </span>
                                    </div>
                                    <div style={{ marginTop: "10px" }}>
                                        <StatusTag
                                            slug={productStatus}
                                            text={formatString(productStatus)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                }
                return null;
            },
        },
        {
            title: "Agent Company",
            dataIndex: "agentCompany",
            key: "agentCompany",
            render: (_: string, record: AgentInvoiceModel) => {
                return (
                    <div>
                        <Paragraph>
                            <Text>Name : </Text>
                            <Text>
                                {record.getAgentCompany()?.primary_name}{" "}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Status : </Text>
                            <StatusTag
                                slug={
                                    record.getAgentCompany()
                                        ?.verification_status || ""
                                }
                                text={
                                    record.getAgentCompany()
                                        ?.verification_status || ""
                                }
                            />
                        </Paragraph>
                    </div>
                );
            },
        },
        {
            title: "Amount",
            key: "transaction_summary",
            render: (_, record: AgentInvoiceModel) => {
                const currency = record.getRegion().currency?.code;
                return (
                    <>
                        <Paragraph>
                            <Text strong className="financial-text">
                                Total :{" "}
                            </Text>
                            <Text>{`${currency}${" "}${record.getTotalAmount().toFixed(2)}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong className="financial-text">
                                Settled :{" "}
                            </Text>
                            <Text>{` ${currency}${" "}${record.getTransactionSummary().settled?.toFixed(2)}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong className="financial-text">
                                Due :{" "}
                            </Text>
                            <Text>{` ${currency}${" "}${record.getTransactionSummary().due?.toFixed(2)}`}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong className="financial-text">
                                Over settled :{" "}
                            </Text>
                            <Text>{` ${currency}${" "}${record.getTransactionSummary().over_settled?.toFixed(2)}`}</Text>
                        </Paragraph>
                    </>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_: string, record: AgentInvoiceModel) => {
                const status = record.getStatus();
                const overSettled =
                    record.getTransactionSummary()?.over_settled;

                return (
                    <div>
                        <StatusTag slug={status} text={status} />
                        {overSettled !== undefined && overSettled > 0 && (
                            <Tag style={{ marginTop: 4 }} color="green">
                                Over Settled
                            </Tag>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            hidden: isMobile,
            render: (_created_at, record: AgentInvoiceModel) => {
                return <Tag color="purple">{record.getCreatedAt()}</Tag>;
            },
        },
        {
            title: "Details",
            dataIndex: "ud",
            key: "details",
            render: (_: string, record: AgentInvoiceModel) => {
                return (
                    <>
                        <Space size={4}>
                            <Tooltip title="Invoice Details">
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        handleShowDetail(record.getId());
                                    }}
                                    shape="round"
                                    icon={<EyeFilled />}
                                />
                            </Tooltip>
                            {/* <Tooltip title="Invoice">
                                <Button
                                    type="default"
                                    size="small"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => {
                                        handleShowInvoice(record.getId());
                                    }}
                                    shape="round"
                                    icon={<PrinterOutlined />}
                                />
                            </Tooltip> */}
                        </Space>
                    </>
                );
            },
        },

        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            render: (_: string, record: AgentInvoiceModel) => {
                const handleRecalculate = async () => {
                    setIsRecalculating(true);
                    try {
                        await recalculateInvoiceData(record.getId(), "agent");
                        message.success("successfully Recalculated");
                    } finally {
                        setIsRecalculating(false);
                    }
                };

                const handleDownloadInvoice = async () => {
                    try {
                        const response = await handledownloadData(
                            record.getId(),
                            "agent",
                        );

                        if (response?.data?.download_url) {
                            const downloadUrl = response.data.download_url;

                            const link = document.createElement("a");
                            link.href = downloadUrl;
                            link.download = "";
                            link.target = "_blank";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        } else {
                            console.error("Download URL not found in response");
                        }
                    } catch (error) {
                        console.error("Error downloading invoice:", error);
                    }
                };

                // const shouldDisplaySettlementMenuItem = (
                //     record: AgentInvoiceModel,
                // ) => {
                //     const isOverSettled =
                //         record.getTransactionSummary()?.over_settled ?? 0 > 0;
                //     return isOverSettled;
                // };

                const menuItems: ExtendedMenuItemType[] = [
                    // ...(shouldDisplaySettlementMenuItem(record)
                    //     ? [
                    //           {
                    //               permission:
                    //                   ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_SETTLE_AGENT_INVOICE,
                    //               label: "Settlement",
                    //               key: "update_settlement",
                    //               icon: <EditOutlined />,
                    //               onClick: async () => {
                    //                   await settleInvoice(
                    //                       record.getId(),
                    //                       "agent",
                    //                   );
                    //               },
                    //               style: { margin: "5px", padding: "8px 16px" },
                    //           },
                    //       ]
                    //     : []),
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_SETTLE_AGENT_INVOICE,
                        label: "Settlement",
                        key: "update_settlement",
                        icon: <EditOutlined />,
                        onClick: async () => {
                            Modal.confirm({
                                title: "Do you want to settle this invoice?",
                                content:
                                    "Select Ok, otherwise the process cannot success.",
                                async onOk() {
                                    try {
                                        await settleInvoice(
                                            record.getId(),
                                            "agent",
                                        );

                                        showSuccessAlert(
                                            `Successfully Settled Agent invoice`,
                                        );
                                    } catch (error) {
                                        showError(error);
                                    }
                                },
                                onCancel() {},
                            });
                        },
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_RECALCULATE_AGENT_INVOICE,
                        label: isRecalculating ? "Wait...." : "Re-calculate",
                        key: "re-calculate",
                        icon: <CalculatorFilled />,
                        onClick: handleRecalculate,
                        disabled: isRecalculating,
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                        label: "Download Invoice",
                        key: "download-invoice",
                        icon: <DownloadOutlined />,
                        onClick: handleDownloadInvoice,
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                ];

                if (isMobile) {
                    menuItems.push(
                        {
                            permission:
                                ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
                            label: "Details",
                            key: "details",
                            icon: <EyeFilled />,
                            onClick: () => handleShowDetail(record.getId()),
                            style: { margin: "5px", padding: "8px 16px" },
                        },
                        {
                            permission:
                                ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
                            label: "Invoice",
                            key: "details-invoice",
                            icon: <PrinterOutlined />,
                            onClick: () => handleShowInvoice(record.getId()),
                            style: { margin: "5px", padding: "8px 16px" },
                        },
                        {
                            permission:
                                ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
                            label: (
                                <Tag color="purple">
                                    {record.getCreatedAt()}
                                </Tag>
                            ),
                            key: "created_at",
                        },
                    );
                }

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Agent Invoices"
                    onBack={() => window.history.back()}
                >
                    <FiltersComponent
                        handleProductFilter={handleProductFilter}
                        handleFilterChange={handleFilterChange}
                        handelFilterClear={() => {
                            handelFilterClear();
                            onClearBulkSelectedState();
                        }}
                        isFetched={isFetched}
                        filters={filters}
                        filtersData={filterData}
                        isFromProductReceived={true}
                    />

                    {hasSelected && (
                        <div
                            style={{
                                margin: "20px 0",
                                textAlign: "right",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div style={{ marginBottom: 12 }}>
                                <Tabs
                                    defaultActiveKey={
                                        Object.keys(groupedByRegion)[0]
                                    }
                                >
                                    {Object.entries(groupedByRegion).map(
                                        ([
                                            regionName,
                                            {
                                                totalAmount,
                                                settled,
                                                due,
                                                over_settled,
                                                currencySymbol,
                                                count,
                                            },
                                        ]) => (
                                            <TabPane
                                                tab={
                                                    <span>
                                                        <Text
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                                fontSize: 14,
                                                            }}
                                                        >
                                                            {regionName}({count}
                                                            )
                                                        </Text>
                                                    </span>
                                                }
                                                key={regionName}
                                            >
                                                <div
                                                    style={{ display: "flex" }}
                                                >
                                                    <Paragraph>
                                                        <Text>Total : </Text>
                                                        <Tag
                                                            color="#008000"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currencySymbol}{" "}
                                                            {totalAmount.toFixed(
                                                                2,
                                                            )}
                                                        </Tag>
                                                    </Paragraph>
                                                    <Paragraph>
                                                        <Text>
                                                            Total Settled :{" "}
                                                        </Text>
                                                        <Tag
                                                            color="green"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currencySymbol}{" "}
                                                            {settled.toFixed(2)}
                                                        </Tag>
                                                    </Paragraph>

                                                    <Paragraph>
                                                        <Text>
                                                            Total Due :{" "}
                                                        </Text>
                                                        <Tag
                                                            color="orange"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currencySymbol}{" "}
                                                            {due.toFixed(2)}
                                                        </Tag>
                                                    </Paragraph>

                                                    <Paragraph>
                                                        <Text>
                                                            Total Over Settled :{" "}
                                                        </Text>
                                                        <Tag
                                                            color="red"
                                                            style={{
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            {currencySymbol}{" "}
                                                            {over_settled.toFixed(
                                                                2,
                                                            )}
                                                        </Tag>
                                                    </Paragraph>
                                                </div>
                                            </TabPane>
                                        ),
                                    )}
                                </Tabs>
                            </div>
                            <Dropdown
                                menu={{
                                    items: getBulkActions(),
                                }}
                                disabled={selectedRowKeys.length === 0}
                            >
                                <Button icon={<HddFilled />}>
                                    Bulk Actions
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    )}

                    {/* <Flex gap={8} align="center" style={{ marginBottom: 12 }}>
                        <Dropdown
                            menu={{
                                items: getBulkActions(),
                            }}
                            disabled={selectedRowKeys.length === 0}
                        >
                            <Button disabled={selectedRowKeys.length === 0}>
                                Bulk Actions
                            </Button>
                        </Dropdown>
                        {selectedRows.length > 0 ? (
                            <Typography.Text>
                                Selected : {selectedRows.length}
                            </Typography.Text>
                        ) : (
                            <></>
                        )}
                    </Flex> */}
                    <div className="responsive-table-container">
                        <Table
                            loading={isLoading}
                            rowKey={(r) => r.getId()}
                            bordered={true}
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys,
                                columnWidth: 60,
                                fixed: true,
                                onChange: onChangeRow,

                                getCheckboxProps: (record) => ({
                                    disabled:
                                        record.getStatus() !==
                                        InvoiceStatus.UNSETTLED,
                                }),
                            }}
                            dataSource={
                                InvoiceCollectionData
                                    ? InvoiceCollectionData.getData()
                                    : []
                            }
                            columns={columns}
                            pagination={{
                                ...paginationConfig,
                                showTotal: (total) => (
                                    <div>
                                        Total{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                color: "green",
                                            }}
                                        >
                                            {total}
                                        </span>{" "}
                                        Items
                                    </div>
                                ),
                            }}
                            scroll={{ x: 1600 }}
                        />
                    </div>
                </PageHeader>
            </div>
        </>
    );
};

export default AgentInvoiceList;
