import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Table, Row, Button, Col, Switch, Modal, notification } from "antd";
import { useAtom } from "jotai";
import { PlusOutlined } from "@ant-design/icons";

import { permissionAtom, roleAtom } from "@/lib/core-react/store/store";
import { useGetPermission } from "@/lib/core-react/hooks/private/usePermission";
import {
    useGetRole,
    useUpdateRole,
} from "@/lib/core-react/hooks/private/useRole";
import useDataFilters from "@/hooks/useDataFilters";

import {
    PermissionCollectionModel,
    PermissionModel,
} from "@/models/permissionCollectionModel";
import {
    RoleCollectionModel,
    RoleModel,
    RoleUpdateRequestModel,
} from "@/models/roleCollectionModel";
import { ApiHelperModel } from "@/models/apiHelper";
import RoleModification from "../Role/components/RoleModification";
import { v4 } from "uuid";
import { getError } from "@/lib/core-react/hooks/utils/errors";

interface RowData {
    key: string;
    name: string;
    [roleName: string]: boolean | string;
}

const makeData = (
    permissions: PermissionModel[],
    roles: RoleModel[],
): RowData[] => {
    return permissions.map((item) => {
        const rowData: RowData = {
            key: item.id.toString(),
            name: item.name,
        };

        for (let i = 0; i < roles.length; i++) {
            const found = roles[i]?.permissions?.data?.find(
                (perm) => perm.id === item.id,
            );
            rowData[roles[i].name] = found ? true : false;
        }

        return rowData;
    });
};

const Overview = () => {
    const { getRole } = useGetRole();
    const [{ data: roleData, isLoading, refetch }] = useAtom(roleAtom);
    const [isCall, setIsCall] = useState(false);
    const { getPermission } = useGetPermission();
    const [{ data: permissionData, isLoading: isLoadingPermission }] =
        useAtom(permissionAtom);

    const { updateRole, isLoading: isLoadingUpdate } = useUpdateRole();

    const [isShowCreateModal, setIsShowCreateModal] = useState(false);
    const { filters, isFirstCall, isFetched } = useDataFilters();

    const RoleData = roleData && new RoleCollectionModel(roleData);
    const PermissionData =
        permissionData && new PermissionCollectionModel(permissionData);

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(filters, getRole);
            ApiHelperModel.makeGetRequest({}, getPermission);
        }
    }, [isFirstCall, filters, isFetched, refetch]);

    const permissionColumns = [
        {
            title: "Permissions",
            dataIndex: "name",
            key: "name",
            width: 200,
        },
    ];

    const handlePermissionChange = async (
        text: boolean,
        record: RowData,
        item: RoleModel,
    ) => {
        setIsCall(true);
        const permission_ids: number[] = item.permissions?.data?.map(
            (permission) => permission.id,
        );
        if (text) {
            permission_ids.push(Number(record.key));
        } else {
            permission_ids.filter(
                (permission) => permission === Number(record.key),
            );
        }

        const payload: RoleUpdateRequestModel = {
            name: item.name,
            label: item.label,
            permission_ids,
        };
        try {
            await updateRole(item.id, payload);
            notification.success({
                message: "Successfully Role Update",
            });
        } catch (error) {
            notification.error({
                message: getError(error),
            });
            setIsCall(false);
        } finally {
            setIsCall(false);
        }
    };

    const roleColumns = RoleData
        ? RoleData.data.map((item) => {
              return {
                  align: "center",
                  title: item.name,
                  dataIndex: item.name,
                  item: item,
                  key: item.name,
                  width: 200,
                  render: (text: boolean, record: RowData) => {
                      return (
                          <Switch
                              defaultChecked={text}
                              onChange={(e) =>
                                  handlePermissionChange(e, record, item)
                              }
                              disabled={isLoadingUpdate || isCall}
                          />
                      );
                  },
              };
          })
        : [];

    const newColumns = [...permissionColumns, ...roleColumns];

    // combine table data of roles and permissions
    const tableData =
        RoleData && RoleData.data && PermissionData && PermissionData.data
            ? makeData(PermissionData?.data, RoleData?.data)
            : [];

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={"Roles and permissions"}
                        extra={[
                            <Button
                                key={v4()}
                                onClick={() => {
                                    setIsShowCreateModal(true);
                                }}
                                icon={<PlusOutlined />}
                                type="dashed"
                            >
                                Create new role
                            </Button>,
                        ]}
                        onBack={() => window.history.back()}
                    >
                        <Table
                            columns={newColumns}
                            dataSource={tableData}
                            loading={isLoading || isLoadingPermission}
                            bordered
                            pagination={false}
                            scroll={{ y: "70vh" }}
                        />
                    </PageHeader>
                </Col>
            </Row>

            <Modal
                title={`Create Role`}
                open={isShowCreateModal}
                footer={false}
                onCancel={() => setIsShowCreateModal(false)}
            >
                <RoleModification onHide={() => setIsShowCreateModal(false)} />
            </Modal>
        </>
    );
};

export default Overview;
