import {
    DisputeBuyProductEnum,
    DisputeCollectionEnum,
} from "@/enums/disputeCollectionEnum";
import { IFilterType } from "@/types/filters";
import { PaginationModel } from "./paginationModel";
import {
    IDispute,
    IDisputeBuyProduct,
    IDisputeCollection,
    IUIActions,
} from "@/types/disputeCollection";
import { BuyOrderModel } from "./buyProductCollectionModel";
import { StoreModel } from "./storeCollectionModel";
import { IProductSeller } from "@/types/buyOrderDetail";
import { AgentCompanyModel } from "./agentCompanyCollectionModel";
import { CustomerUserModel } from "./customerUserCollectionModel";
import { RegionModel } from "./regionCollectionModel";

export class DisputeBuyProductModel {
    object: DisputeBuyProductEnum.BuyProduct;
    id: number;
    product_number: string;
    product_id: string;
    product_vid: string;
    cart_item_id: number;
    product_title: string;
    product_image: string;
    product_link: string;
    shipping_provider: string;
    total_quantity: number;
    status: string;
    created_at: string;
    order: BuyOrderModel;
    store: StoreModel;
    product_seller: IProductSeller;
    constructor(dataItem: IDisputeBuyProduct) {
        this.object = dataItem?.object;
        this.id = dataItem?.id;
        this.product_number = dataItem?.product_number;
        this.product_id = dataItem?.product_id;
        this.product_title = dataItem?.product_title;
        this.product_link = dataItem?.product_link;
        this.shipping_provider = dataItem?.shipping_provider;
        this.status = dataItem?.status;
        this.total_quantity = dataItem?.total_quantity;
        this.product_image = dataItem?.product_image;
        this.cart_item_id = dataItem?.cart_item_id;
        this.product_vid = dataItem?.product_vid;
        this.created_at = dataItem?.created_at;
        this.order = new BuyOrderModel(dataItem.order);
        this.store = new StoreModel(dataItem.store);
        this.product_seller = dataItem?.product_seller;
    }

    getId = () => this.id;
    getProductId = () => this.product_id;
    getProductNumber = () => this.product_number;
    getProductTitle = () => this.product_title;
    getProductLink = () => this.product_link;
    getProductImage = () => this.product_image;
    getCreatedAt = () => this.created_at;
    getStatus = () => this.status;
    getOrder = () => this.order;
    getStore = () => this.store;
    getProductSeller = () => this.product_seller;
}

export class DisputeModel {
    private readonly id: number;
    private readonly dispute_number: string;
    private readonly intent: string;
    private readonly status: string;
    private readonly region: RegionModel;
    private readonly user: CustomerUserModel;
    private readonly buy_product: DisputeBuyProductModel;
    private readonly created_at: string;
    private readonly ui_actions: IUIActions;
    private readonly purchase_agent_company: AgentCompanyModel;

    constructor(dataItem: IDispute) {
        this.id = dataItem?.id;
        this.dispute_number = dataItem?.dispute_number;
        this.intent = dataItem?.intent;
        this.status = dataItem?.status;
        this.status = dataItem?.status;
        this.region = new RegionModel(dataItem.region);
        this.user = dataItem.user && new CustomerUserModel(dataItem.user);
        this.created_at = dataItem.created_at;
        this.buy_product = new DisputeBuyProductModel(dataItem.buy_product);
        this.ui_actions = dataItem.ui_actions;
        this.purchase_agent_company =
            dataItem.purchase_agent_company &&
            new AgentCompanyModel(dataItem.purchase_agent_company);
    }

    getId = () => this.id;
    getDisputeNumber = () => this.dispute_number;
    getDisputeIntent = () => this.intent;
    getStatus = () => this.status;
    getCreatedAt = () => this.created_at;
    getRegion = () => this.region;
    getRegionCode = () => this.region?.code;
    getBuyProduct = () => this.buy_product;
    getUserName = () => this.user?.name;
    getUserEmail = () => this.user?.email;
    getUserPhone = () => this.user?.phone;
    getShippingMark = () => this.user?.shipping_mark;
    getUIActions = () => this.ui_actions;
    getPurchaseAgentCompany = () => this.purchase_agent_company;
    getUser = () => this.user;
}

export class DisputeCollectionModel {
    public object: DisputeCollectionEnum.DiscountRuleCollection;
    public data: DisputeModel[];
    public filters: IFilterType;
    public pagination: PaginationModel;

    constructor(data: IDisputeCollection) {
        this.object = data?.object;
        this.data = data?.data?.map((item) => new DisputeModel(item));
        this.filters = data?.filters;
        this.pagination = new PaginationModel(data.pagination);
    }

    getData() {
        return this.data;
    }
    getPagination() {
        return this.pagination;
    }

    getFilters() {
        return this.filters;
    }
}
