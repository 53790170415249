import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import { isDevelopmentMode } from "@/helpers/isDevelopmentMode";

Sentry.init({
    dsn: "https://695a712429e8d3879c99188126427e20@o4508316818538496.ingest.us.sentry.io/4508316908388352",
    enabled: !isDevelopmentMode(),
    integrations: [
        Sentry.breadcrumbsIntegration({
            console: true,
            dom: true,
            fetch: true,
            history: true,
            xhr: true,
        }),
        Sentry.browserApiErrorsIntegration({
            setTimeout: true,
            setInterval: true,
            requestAnimationFrame: true,
            XMLHttpRequest: true,
            eventTarget: true,
        }),
        Sentry.captureConsoleIntegration({
            levels: ["error"],
        }),
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
