import {
    IAgentCategoryPriceRead,
    IAgentCategoryPriceReadCollection,
    IAgentCategoryPriceSlot,
    IAgentCategoryPriceSlotCollection,
    IAgentWarehouse,
    IBaseShippingCategory,
} from "@/types/agentCategoryPriceReadCollection";
import { IDestinationWarehouse } from "@/types/destinationWarehouseCollection";
import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";

export class AgentWarehouseModel {
    public object: string;
    public id: number;
    public name: string;
    public alias_name: string;
    public shipping_mark: string;
    public operation_status: string;
    public verification_status: string;
    public contact_person?: string;
    public contact_number?: string;
    public instruction: string;
    public company_name?: string;
    constructor(data: IAgentWarehouse) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.alias_name = data.alias_name;
        this.shipping_mark = data.shipping_mark;
        this.operation_status = data.operation_status;
        this.verification_status = data.verification_status;
        this.contact_person = data.contact_person;
        this.contact_number = data.contact_number;
        this.instruction = data.instruction;
        this.company_name = data.agent_company?.primary_name;
    }
    getId = () => this.id;
    getName = () => this.name;
    getCompanyName = () => this.company_name;
    getShippingMark = () => this.shipping_mark;
    getOperationStatus = () => this.operation_status;
    getVerificationStatus = () => this.verification_status;
    getContactPerson = () => this.contact_person;
    getContactNumber = () => this.contact_number;
    getInstruction = () => this.instruction;
}

export class BaseShippingCategoryModel {
    public object: string;
    public id: string;
    public name: string;
    public slug: string;

    constructor(data: IBaseShippingCategory) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.object = data.object;
    }
    getId = () => this.id;
    getName = () => this.name;
    getSlug = () => this.slug;
}

export class DestinationWarehouseModel {
    public object: string;
    public id: number;
    public slug: string;
    public name: string;
    public status: string;
    public created_at: string;
    constructor(data: IDestinationWarehouse) {
        this.object = data.object;
        this.slug = data.slug;
        this.id = data.id;
        this.name = data.name;
        this.status = data.status;
        this.created_at = data.created_at;
    }
    getId = () => this.id;
    getName = () => this.name;
    getSlug = () => this.slug;
    getStatus = () => this.status;
    getCreateAt = () => this.created_at;
}

export class AgentCategoryPriceSlotModel {
    public object: string;
    public id: number;
    public min_amount: number;
    public max_amount: number;
    public rate: number;
    constructor(data: IAgentCategoryPriceSlot) {
        this.object = data.object;
        this.min_amount = data.min_amount;
        this.id = data.id;
        this.max_amount = data.max_amount;
        this.rate = data.rate;
    }
    getId = () => this.id;
    getRate = () => this.rate;
    getMinAmount = () => this.min_amount;
    getMaxAmount = () => this.max_amount;
}

export class AgentCategoryPriceSlotCollectionModel {
    public object: string;
    public data: AgentCategoryPriceSlotModel[];
    constructor(data: IAgentCategoryPriceSlotCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new AgentCategoryPriceSlotModel(item),
        );
    }
    getData = () => (this.data && this.data.length > 0 ? this.data : []);
}

export class AgentCategoryPriceReadModel {
    public object: string;
    public id: number;
    public agent_warehouse: AgentWarehouseModel;
    public destination_warehouse: DestinationWarehouseModel;
    public shipping_type: string;
    public shipping_mode: string;
    public unit_type: string;
    public rate: number;
    public commission_rate: number;
    public commission_rate_type: string;
    public contain: string;
    public total_rate: number;
    max_shipping_time: number;
    min_shipping_time: number;
    shipping_time_unit: string;
    public slots?: AgentCategoryPriceSlotCollectionModel;
    constructor(data: IAgentCategoryPriceRead) {
        this.object = data.object;
        this.id = data.id;
        this.agent_warehouse = new AgentWarehouseModel(data.agent_warehouse);
        this.destination_warehouse = new DestinationWarehouseModel(
            data.destination_warehouse,
        );
        this.shipping_type = data.shipping_type;
        this.shipping_mode = data.shipping_mode;
        this.unit_type = data.unit_type;
        this.rate = data.rate;
        this.contain = data.contain;
        this.commission_rate = data.commission_rate;
        this.commission_rate_type = data.commission_rate_type;
        this.total_rate = data.total_rate;
        this.max_shipping_time = data.max_shipping_time;
        this.min_shipping_time = data.min_shipping_time;
        this.shipping_time_unit = data.shipping_time_unit;
        this.slots = data.slots
            ? new AgentCategoryPriceSlotCollectionModel(data.slots)
            : undefined;
    }
    getId = () => this.id;
    getAgentWarehouse = () => this.agent_warehouse;
    getDestinationWarehouse = () => this.destination_warehouse;
    getShippingType = () => this.shipping_type;
    getShippingMode = () => this.shipping_mode;
    getUnitType = () => this.unit_type;
    getRate = () => this.rate;
    getCommissionRate = () => this.commission_rate;
    getCommissionRateType = () => this.commission_rate_type;
    getTotalRate = () => this.total_rate;
    getMaxShippingTime = () => this.max_shipping_time;
    getMinShippingTime = () => this.min_shipping_time;
    getShippingTimeUnit = () => this.shipping_time_unit;
    getSlots = () => this.slots;
}

export class AgentCategoryPriceReadCollectionModel {
    public object: string;
    public data: AgentCategoryPriceReadModel[];
    public filters: IFilterType;
    public pagination: IPagination;
    constructor(data: IAgentCategoryPriceReadCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new AgentCategoryPriceReadModel(item),
        );
        this.filters = data.filters;
        this.pagination = data.pagination;
    }
    getData = () => this.data;
    getPagination() {
        return this.pagination;
    }
    getShippingTypes() {
        return [
            {
                label: "By Air",
                value: "air",
            },
            {
                label: "By Road",
                value: "road",
            },
            {
                label: "By Ship",
                value: "ship",
            },
        ];
    }
    getShippingModes() {
        return [
            {
                label: "Cargo",
                value: "cargo",
            },
            {
                label: "P2P",
                value: "p2p",
            },
            {
                label: "International",
                value: "international",
            },
        ];
    }
    getShippingContains() {
        return [
            {
                label: "Battery",
                value: "battery",
            },
            {
                label: "Liquid",
                value: "liquid",
            },
            {
                label: "Chemical",
                value: "chemical",
            },
            {
                label: "Copy",
                value: "copy",
            },
            {
                label: "Regular",
                value: "regular",
            },
        ];
    }
    getShippingUnits() {
        return [
            {
                label: "KG",
                value: "kg",
            },
            {
                label: "Piece",
                value: "piece",
            },
            {
                label: "CBM",
                value: "cbm",
            },
        ];
    }

    getFilters() {
        return this.filters;
    }
}
