import { ITranslationEntry } from "../lang";

export const BA: ITranslationEntry = {
    translations: {
        notifications: "Иҫкәрмәләр",
        markAllAsRead: "Барыһын да уҡылғанса тамғалағыҙ",
        poweredBy: "Эшләй",
        settings: "Көйләүҙәре",
        noNewNotification: "Монда әле яңа нәрсә юк",
    },
    lang: "ba",
};
