import { Flex, Spin } from "antd";

const LoaderSpin = () => {
    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "transparent",
                position: "relative",
            }}
        >
            <Flex
                align="center"
                justify="center"
                style={{
                    position: "absolute",
                    top: "30%",
                    left: "40%",
                    zIndex: 9999,
                    backgroundColor: "transparent",
                }}
            >
                <Spin />
            </Flex>
        </div>
    );
};

export default LoaderSpin;
