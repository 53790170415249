import { PageHeader } from "@ant-design/pro-layout";
import { Button, Result } from "antd";

interface IProps {
    message?: string | null;
    code?: number | string;
    unAuthorized?: boolean;
    hidePageHeader?: boolean;
}
const CommonError = ({
    message = "Sorry, the page you visited does not exist.",
    code,
    unAuthorized,
    hidePageHeader = false,
}: IProps) => {
    if (typeof code === "number") {
        code = String(code);
    }
    if (code === "401") {
        unAuthorized = true;
    }
    return (
        <div>
            {/* Page Header with Back Button */}
            {!hidePageHeader ? (
                <PageHeader
                    className="site-page-header"
                    onBack={() => window.history.back()}
                    title="Go Back"
                />
            ) : (
                <></>
            )}
            <Result
                title={unAuthorized ? "403" : code ?? null}
                subTitle={
                    unAuthorized
                        ? "Sorry, you are not authorized to access this page"
                        : message ?? "Network error"
                }
                extra={
                    <Button
                        onClick={() => window.location.reload()}
                        type="default"
                    >
                        Refresh
                    </Button>
                }
            />
        </div>
    );
};

export default CommonError;
