import { Space, Table, Tag, Typography } from "antd";
import { StatusTag } from "@/components";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { PaginationModel } from "@/models/pagination";
import {
    TransactionCollectionModel,
    TransactionModel,
} from "@/models/walletTransactionCollectionModel";
import { formatString } from "@/utils/helper";
import { useNavigate } from "react-router-dom";
import { formatDateTime } from "@/utils/helpers";

interface IWalletTableProps {
    isLoading: boolean;
    transactionsCollectionData: TransactionCollectionModel | undefined;
    handlePaginationChange: (pageCount: number, pageSize: number) => void;
    currencyCode?: string;
}

const WalletMasterTable = ({
    isLoading,
    transactionsCollectionData,
    handlePaginationChange,
    currencyCode,
}: IWalletTableProps) => {
    const navigate = useNavigate();
    const { isMobile } = useWindowWidth();
    const { Text } = Typography;

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (_: any, record: TransactionModel) => {
                return <div>{record.getId()}</div>;
            },
        },
        {
            title: "Withdrawal info",
            dataIndex: "name",
            key: "name",
            hidden: isMobile,
            render: (_: string, record: TransactionModel) => {
                return (
                    <Space direction="vertical" wrap>
                        <Space>
                            <Text strong>T. Number:</Text>
                            <Text copyable>
                                {record.getTransactionNumber()}
                            </Text>
                        </Space>
                        <div style={{ marginTop: -4 }}>
                            <Text strong>Status: </Text>
                            <StatusTag
                                slug={record.getStatus()}
                                text={record.getStatus()}
                            />
                        </div>
                        <div style={{ marginTop: -4 }}>
                            <Text strong>Type: </Text>
                            <StatusTag
                                slug={record.getType()}
                                text={record.getType()}
                            />
                        </div>
                        <div style={{ marginTop: -4 }}>
                            <Text strong>Source: </Text>
                            <StatusTag
                                slug={record.getSource()}
                                text={formatString(record.getSource())}
                            />
                        </div>
                    </Space>
                );
            },
        },
        {
            title: "Transaction Details",
            hidden: !isMobile,
            key: "transaction_details",
            render: (_: string, record: TransactionModel) => {
                const prefix = record.getType() === "credit" ? "+" : "-";
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            minWidth: "150px",
                        }}
                    >
                        <div style={{ marginBottom: "4px" }}>
                            <Text strong style={{ marginRight: "5px" }}>
                                Status:
                            </Text>
                            <StatusTag
                                text={record.getStatus()}
                                slug={record.getStatus()}
                            />
                        </div>
                        <div style={{ marginBottom: "4px" }}>
                            <Text strong style={{ marginRight: "5px" }}>
                                Type:
                            </Text>
                            <StatusTag
                                text={record.getType()}
                                slug={record.getType()}
                            />
                        </div>
                        <div>
                            <Text strong style={{ marginRight: "5px" }}>
                                Amount:
                            </Text>
                            {prefix} {record.amount}
                        </div>
                    </div>
                );
            },
        },

        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            hidden: isMobile,
            render: (amount: number, record: TransactionModel) => {
                const prefix = record.type === "credit" ? "+" : "-";
                return (
                    <div>
                        {prefix}
                        {currencyCode} {amount}
                    </div>
                );
            },
        },

        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            hidden: isMobile,
            render: (_created_at: any, record: TransactionModel) => {
                return (
                    <div>
                        <Tag color="purple">
                            {formatDateTime(record.getCreateAt())}
                        </Tag>
                    </div>
                );
            },
        },
    ];

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        transactionsCollectionData,
        handlePaginationChange,
    );

    // Columns for the inner table
    const innerColumns = [
        {
            title: "Invoice Number",
            key: "id",
            render: (_: any, association: any) => {
                if (association.invoice_transaction) {
                    const invoiceTrasaction = association.invoice_transaction;
                    return (
                        <>
                            <div>
                                <Text>
                                    {invoiceTrasaction.invoice.invoice_number}
                                </Text>
                            </div>
                        </>
                    );
                }
                if (association.agent_invoice_transaction) {
                    const invoiceTrasaction =
                        association.agent_invoice_transaction;
                    return (
                        <>
                            <div>
                                <Text>
                                    {invoiceTrasaction.transaction_number}
                                </Text>
                            </div>
                        </>
                    );
                }
            },
        },
        {
            title: "Status",
            key: "id",
            render: (_: any, association: any) => {
                if (association.invoice_transaction) {
                    const invoiceTrasaction = association.invoice_transaction;
                    return (
                        <>
                            <div>
                                <StatusTag
                                    slug={invoiceTrasaction.invoice.status}
                                    text={invoiceTrasaction.invoice.status}
                                />
                            </div>
                        </>
                    );
                }
                if (association.agent_invoice_transaction) {
                    const invoiceTrasaction =
                        association.agent_invoice_transaction;
                    return (
                        <>
                            <div>
                                <StatusTag
                                    slug={invoiceTrasaction.type}
                                    text={invoiceTrasaction.type}
                                />
                            </div>
                        </>
                    );
                }
            },
        },

        {
            title: "Amount",
            key: "id",
            render: (_: any, association: any) => {
                if (association.invoice_transaction) {
                    const invoiceTrasaction = association.invoice_transaction;
                    return (
                        <>
                            <div>
                                {currencyCode}{" "}
                                {invoiceTrasaction.invoice.total_amount}
                            </div>
                        </>
                    );
                }
                if (association.agent_invoice_transaction) {
                    const invoiceTrasaction =
                        association.agent_invoice_transaction;
                    return (
                        <>
                            <div>
                                {currencyCode} {invoiceTrasaction.amount}
                            </div>
                        </>
                    );
                }
            },
        },
        {
            title: "Transaction Number",
            key: "id",
            render: (_: any, association: any) => {
                if (association.invoice_transaction) {
                    const invoiceTrasaction = association.invoice_transaction;
                    return (
                        <>
                            <div>{invoiceTrasaction.transaction_number}</div>
                        </>
                    );
                }
                if (association.agent_invoice_transaction) {
                    const invoiceTrasaction =
                        association.agent_invoice_transaction;
                    return (
                        <>
                            <div>{invoiceTrasaction.transaction_number}</div>
                        </>
                    );
                }
            },
        },
        {
            title: "Product",
            key: "id",
            render: (_: any, association: any) => {
                const valueStyle = {
                    marginLeft: "4px",
                };
                const linkStyle = {
                    color: "#1890ff", // Default link color
                    textDecoration: "underline",
                    cursor: "pointer",
                };

                if (association.buy_product) {
                    const invoiceTransaction = association.buy_product;

                    return (
                        <div>
                            <div>
                                <Tag color={"blue"}>Buy And Ship</Tag>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <Text></Text>
                                <Text
                                    style={{ ...linkStyle, ...valueStyle }}
                                    copyable
                                    onClick={() =>
                                        navigate(
                                            `/purchase/buy-products/detail/${invoiceTransaction?.id}`,
                                        )
                                    }
                                >
                                    {invoiceTransaction.product_number}
                                </Text>
                            </div>
                        </div>
                    );
                }

                if (association.shipment_product) {
                    const invoiceTransaction = association.shipment_product;
                    return (
                        <div>
                            <div>
                                <div>
                                    <Tag color={"Green"}>Only Ship</Tag>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <Text
                                        style={{ ...linkStyle, ...valueStyle }}
                                        copyable
                                        onClick={() =>
                                            navigate(
                                                `/shipping/shipment-products/edit/${invoiceTransaction?.id}`,
                                            )
                                        }
                                    >
                                        {invoiceTransaction.product_number}
                                    </Text>
                                </div>
                            </div>
                        </div>
                    );
                }

                return null;
            },
        },
    ];

    const expandedRowRender = (record: any) => {
        const associations = record.getAssociations().data || [];
        return (
            <Table
                columns={innerColumns}
                dataSource={associations}
                pagination={false}
                rowKey="id"
                bordered={true}
            />
        );
    };

    const dataSource = transactionsCollectionData
        ? transactionsCollectionData.getData()
        : [];

    return (
        <Table
            style={{ marginTop: 10 }}
            loading={isLoading}
            rowKey="id"
            bordered={true}
            dataSource={dataSource}
            columns={columns}
            expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ["0"],
            }}
            pagination={paginationConfig}
            scroll={{ x: 1257 }}
        />
    );
};

export default WalletMasterTable;
