import {
    Button,
    Flex,
    Form,
    Input,
    InputNumber,
    Modal,
    Select,
    Table,
    TableColumnsType,
    message,
    notification,
} from "antd";
import { useState } from "react";
import { MultiChunkUpload } from "@/components/MultiChunkUpload";
import { IRefundRequestForm } from "@/types/disputeCollection";
import { useRefundRequest } from "@/lib/core-react/hooks/private/usePurchase";
import { BuyProductVariationsModel } from "@/models/buyProductCollectionModel";
import { Typography } from "antd/lib";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { getFulfillmentStatusColor } from "@/utils/helpers";
import { tw } from "@/consts/theme/tailwindTheme";
import { showError } from "@/helpers/showError";
import DescriptionField from "@/components/CustomFormElement/DescriptionField";

const { Paragraph } = Typography;

interface RefundFormProps {
    isOpen: boolean;
    onClose: () => void;
    variationId?: number;
    buyProductId: number;
    isBulkRefund?: boolean;
    variations?: BuyProductVariationsModel[];
}

const RefundForm = ({
    isOpen,
    onClose,
    variationId,
    buyProductId,
    isBulkRefund,
    variations,
    // onSubmit,
}: RefundFormProps) => {
    const { Text } = Typography;
    const [form] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { refundRequest, isLoading } = useRefundRequest();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [selectedVariations, setSelectedVariations] = useState<
        { id: number; quantity: number }[]
    >([]);

    const handleSubmit = async () => {
        try {
            // Validate selected variations have quantities
            const invalidSelections = selectedVariations.some(
                (variation) => !variation.quantity,
            );
            if (invalidSelections) {
                message.error("Please enter quantities for all selected items");
                return;
            }

            const values = await form.validateFields();
            const quantities = Object.entries(values)
                .filter(([key]) => key.startsWith("quantity_"))
                .reduce(
                    (obj, [key, value]) => {
                        const numericKey = key.split("_")[1];
                        obj[numericKey] = Number(value);
                        return obj;
                    },
                    {} as Record<string, number>,
                );

            const variationsPayload = isBulkRefund
                ? selectedVariations
                      .filter((variation) => quantities[variation.id])
                      .map((variation) => ({
                          id: variation.id,
                          quantity: quantities[variation.id],
                      }))
                : [
                      {
                          id: variationId!,
                          quantity: Number(values.quantity),
                      },
                  ];

            const payload: IRefundRequestForm = {
                variations: variationsPayload,
                received_item: values.received_item === "yes",
                reason: values.reason,
                description: values.description,
                images: values.images,
                videos: values.videos,
            };

            // make sure the images and videos are uploaded

            if (payload.images.length === 0 || payload.videos.length === 0) {
                notification.error({
                    message: "Error",
                    description:
                        "Please upload images and videos before submitting the refund request",
                });
                setIsSubmitting(false);
                return;
            }
            await refundRequest(buyProductId, payload);
            notification.success({
                message: "Refund request submitted",
                description:
                    "Your refund request has been submitted successfully",
            });
            onClose();
            form.resetFields();
        } catch (error) {
            showError(error, form);
        } finally {
            setIsSubmitting(false);
        }
    };

    const TEXT_SIZE = 12;

    const columns: TableColumnsType<BuyProductVariationsModel> = [
        {
            title: <Text style={{ fontSize: TEXT_SIZE }}>Meta</Text>,
            key: "meta",
            width: "200px", // Adjust based on your layout
            render: (_, variant) => (
                <div style={{ position: "relative", paddingRight: "50px" }}>
                    {/* Image and Properties */}
                    <Flex gap={4} align="flex-start">
                        <div style={{ marginTop: "10px" }}>
                            <ImageWithPlaceholder
                                style={{ width: 42, height: 42 }}
                                src={`${variant.getPropertyImageUrl()}`}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            {variant.getProperties().map((singleProperty) => {
                                const isLongValue =
                                    singleProperty.property_value_name.length >
                                    10;

                                return (
                                    <Typography key={singleProperty.id}>
                                        <Paragraph style={{ margin: 0 }}>
                                            <Text style={{ paddingRight: 8 }}>
                                                {singleProperty.property_name}:
                                            </Text>

                                            <Text
                                                strong
                                                ellipsis={{
                                                    tooltip: isLongValue
                                                        ? singleProperty.property_value_name
                                                        : undefined,
                                                }}
                                                style={{
                                                    maxWidth: "100px",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    fontSize: TEXT_SIZE,
                                                }}
                                            >
                                                {
                                                    singleProperty.property_value_name
                                                }
                                            </Text>
                                        </Paragraph>
                                    </Typography>
                                );
                            })}
                        </div>
                    </Flex>

                    {/* Fulfillment Status positioned in the top-right */}

                    <div
                        style={{
                            position: "absolute",
                            top: "-9px",
                            left: "-15px",
                            fontSize: "10px", // Small text size
                            padding: "2px 8px",
                        }}
                    >
                        <div
                            title={variant.getFulfillmentStatus()}
                            style={{
                                width: "14px",
                                height: "14px",
                                borderRadius: "50%",
                                background: getFulfillmentStatusColor(
                                    variant.getFulfillmentStatus(),
                                ),
                            }}
                        ></div>
                    </div>
                </div>
            ),
        },
        {
            title: <Text style={{ fontSize: TEXT_SIZE }}>Quantity</Text>,
            width: "70px",
            key: "Quantity",
            align: "center",
            render(_, variant: BuyProductVariationsModel) {
                return (
                    <Typography>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text style={{ fontSize: TEXT_SIZE }}>
                                {variant.getQuantity()}
                            </Text>
                        </Paragraph>
                    </Typography>
                );
            },
        },
        {
            title: "Quantity to Refund",
            dataIndex: "quantityToRefund",
            key: "quantity_to_refund",
            width: 100,
            align: "center" as any,
            render: (_, record: BuyProductVariationsModel) => {
                const isSelected = selectedVariations.some(
                    (v) => v.id === record.id,
                );
                const currentQuantity =
                    selectedVariations.find((v) => v.id === record.id)
                        ?.quantity || "";

                return (
                    <Form.Item
                        style={{ marginTop: "20px" }}
                        validateStatus={
                            isSelected && !currentQuantity ? "error" : ""
                        }
                        help={
                            isSelected && !currentQuantity
                                ? "Quantity required"
                                : ""
                        }
                        name={`quantity_${record.getId()}`}
                        initialValue={record.getQuantity()}
                    >
                        <InputNumber
                            type="number"
                            min={1}
                            max={record.getQuantity()}
                            placeholder="0"
                        />
                    </Form.Item>
                );
            },
        },
    ];

    return (
        <Modal
            title="Request Refund"
            open={isOpen}
            onCancel={onClose}
            width={800}
            destroyOnClose={true}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isSubmitting || isLoading}
                    onClick={handleSubmit}
                >
                    Submit Request
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                {isBulkRefund && (
                    <>
                        <Text>Choose the variations you want to refund</Text>
                        <Table
                            columns={columns}
                            dataSource={variations}
                            rowKey={(r) => r.getId()}
                            pagination={false}
                            rowSelection={{
                                type: "checkbox",
                                selectedRowKeys,
                                onChange: (keys, selectedRows) => {
                                    setSelectedRowKeys(keys);
                                    setSelectedVariations(selectedRows);
                                },
                            }}
                        />
                    </>
                )}
                {!isBulkRefund && (
                    <Form.Item
                        name="quantity"
                        label="Quantity to Refund"
                        rules={[
                            {
                                required: true,
                                message: "Please enter quantity",
                            },
                        ]}
                    >
                        <Input type="number" min={1} placeholder="0" />
                    </Form.Item>
                )}

                <Form.Item
                    name="received_item"
                    label="Received Item"
                    initialValue="yes"
                    rules={[
                        {
                            required: true,
                            message: "Please specify if item was received",
                        },
                    ]}
                >
                    <Select>
                        <Select.Option value="yes">Yes</Select.Option>
                        <Select.Option value="no">No</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="reason"
                    label="Reason"
                    rules={[
                        { required: true, message: "Please  write reason" },
                    ]}
                >
                    <Input.TextArea
                        placeholder="Please  write reason"
                        rows={2}
                    />
                </Form.Item>

                <DescriptionField maxLength={300} />
                <Form.Item
                    label="Images"
                    name={"images"}
                    rules={[
                        {
                            required: true,
                            message: "Please upload an image",
                        },
                    ]}
                >
                    <MultiChunkUpload fieldName="images" form={form} />
                </Form.Item>

                <Form.Item
                    label="Videos"
                    name={"videos"}
                    rules={[
                        {
                            required: true,
                            message: "Please upload a video",
                        },
                    ]}
                >
                    <MultiChunkUpload
                        fieldName="videos"
                        form={form}
                        accept="video/*"
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default RefundForm;
