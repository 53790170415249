import { ITranslationEntry } from "../lang";

export const KO: ITranslationEntry = {
    translations: {
        notifications: "알림",
        markAllAsRead: "모두 읽은 상태로 표시",
        poweredBy: "에 의해 구동",
        settings: "설정",
        noNewNotification: "여기에는 아직 새로운 내용이 없습니다.",
    },
    lang: "ko",
};
