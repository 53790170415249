import {
    PaymentGatewayFeeTypeEnums,
    PaymentGatewayTypeEnums,
} from "@/enums/paymentGatewayCollectionEnums";
import {
    TransactionsStatusEnums,
    TransactionsStatusEnumsForAutomatic,
} from "@/enums/transactionCollectionsEnums";
import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import {
    IAssociation,
    IGatewayCurrencyCollection,
    IImage,
    IPaymentGateway,
    IPaymentGatewayFormSchema,
    IPaymentSession,
    IPaymentSessionInvoice,
    IPaymentSessionInvoices,
    IPaymentTransactionAttachment,
    IPaymentTransactionAttachmentCollection,
    ITransaction,
    ITransactionCollection,
    IUpdateStatus,
} from "@/types/transactionCollection";
import { CurrencyModel } from "./currencyCollectionModel";
import { RegionModel } from "./buyActionCollectionModel";

export class PaymentGatewayModel {
    public readonly object: string;
    public readonly id: number;
    public readonly identifier: string;
    public readonly name: string;
    public readonly type: PaymentGatewayTypeEnums;
    public readonly fee: number;
    public readonly fee_type: PaymentGatewayFeeTypeEnums;
    public readonly merchant_data: { [key: string]: string } | null;
    public readonly form_schema: {
        [key: string]: IPaymentGatewayFormSchema;
    } | null;
    public readonly currencies: IGatewayCurrencyCollection;

    constructor(dataItem: IPaymentGateway) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.identifier = dataItem.identifier;
        this.name = dataItem.name;
        this.type = dataItem.type;
        this.fee = dataItem.fee;
        this.fee_type = dataItem.fee_type;
        this.merchant_data = dataItem.merchant_data;
        this.form_schema = dataItem.form_schema;
        this.currencies = dataItem.currencies;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getIdentifier() {
        return this.identifier;
    }
    getFee() {
        return this.fee;
    }
    getType() {
        return this.type;
    }
    getFeeType() {
        return this.fee_type;
    }
}

export class ImageModel {
    public readonly object: string;
    public readonly id: number;
    public readonly title: string | null;
    public readonly alt: string | null;
    public readonly src: string;
    public readonly height: number | null;
    public readonly width: number | null;
    public readonly created_at: string;

    constructor(data: IImage) {
        this.object = data.object;
        this.id = data.id;
        this.title = data.title;
        this.alt = data.alt;
        this.src = data.src;
        this.height = data.height;
        this.width = data.width;
        this.created_at = data.created_at;
    }
}

export class PaymentTransactionAttachmentModel {
    public readonly object: string;
    public readonly type: string;
    public readonly image: ImageModel;

    constructor(data: IPaymentTransactionAttachment) {
        this.object = data.object;
        this.type = data.type;
        this.image = new ImageModel(data.image);
    }
}

export class PaymentTransactionAttachmentCollectionModel {
    public readonly object: string;
    public readonly data: PaymentTransactionAttachmentModel[];

    constructor(data: IPaymentTransactionAttachmentCollection) {
        this.object = data.object;
        this.data = data.data
            ? data.data.map(
                  (item) => new PaymentTransactionAttachmentModel(item),
              )
            : [];
    }
}

export class PaymentAssociationModel {
    public readonly id: number;
    public readonly amount: number;
    public readonly paymentSession: PaymentSessionModel;
    public readonly createdAt: string;

    constructor(dataItem: IAssociation) {
        this.id = dataItem.id;
        this.amount = dataItem.amount;
        this.paymentSession = new PaymentSessionModel(dataItem.payment_session);
        this.createdAt = dataItem.created_at;
    }

    getId() {
        return this.id;
    }

    getAmount() {
        return this.amount;
    }

    getPaymentSession() {
        return this.paymentSession;
    }

    getCreatedAt() {
        return this.createdAt;
    }
}

export class PaymentSessionModel {
    public readonly object: string;
    public readonly sessionInvoices: PaymentSessionInvoicesModel;
    public readonly createdAt: string;

    constructor(dataItem: IPaymentSession) {
        this.object = dataItem.object;
        this.sessionInvoices = new PaymentSessionInvoicesModel(
            dataItem.session_invoices,
        );
        this.createdAt = dataItem.created_at;
    }

    getObject() {
        return this.object;
    }

    getSessionInvoices() {
        return this.sessionInvoices;
    }

    getCreatedAt() {
        return this.createdAt;
    }
}

export class PaymentSessionInvoicesModel {
    public readonly object: string;
    public readonly data: PaymentSessionInvoiceModel[];

    constructor(dataItem: IPaymentSessionInvoices) {
        this.object = dataItem.object;
        this.data = dataItem.data.map(
            (invoice) => new PaymentSessionInvoiceModel(invoice),
        );
    }

    getObject() {
        return this.object;
    }

    getData() {
        return this.data;
    }
}

export class PaymentSessionInvoiceModel {
    public readonly object: string;
    public readonly id: number;
    public readonly invoiceNumber: string;
    public readonly status: string;
    public readonly totalAmount: number;
    public readonly initialAmount: number;
    public readonly refundableAmount: number;
    public readonly transactionSummary: {
        paid: number;
        due: number;
        overpaid: number;
    };
    public readonly createdAt: string;

    constructor(dataItem: IPaymentSessionInvoice) {
        this.object = dataItem.object;
        this.id = dataItem.invoice.id;
        this.invoiceNumber = dataItem.invoice.invoice_number;
        this.status = dataItem.invoice.status;
        this.totalAmount = dataItem.invoice.total_amount;
        this.initialAmount = dataItem.invoice.initial_amount;
        this.refundableAmount = dataItem.invoice.refundable_amount;
        this.transactionSummary = {
            paid: dataItem.invoice.transaction_summary.paid,
            due: dataItem.invoice.transaction_summary.due,
            overpaid: dataItem.invoice.transaction_summary.overpaid,
        };
        this.createdAt = dataItem.created_at;
    }

    getObject() {
        return this.object;
    }

    getId() {
        return this.id;
    }

    getInvoiceNumber() {
        return this.invoiceNumber;
    }

    getStatus() {
        return this.status;
    }

    getTotalAmount() {
        return this.totalAmount;
    }

    getInitialAmount() {
        return this.initialAmount;
    }

    getRefundableAmount() {
        return this.refundableAmount;
    }

    getTransactionSummary() {
        return this.transactionSummary;
    }

    getCreatedAt() {
        return this.createdAt;
    }
}

export class TransactionModel {
    public readonly object: string;
    public readonly id: number;
    public readonly lock_version: number;
    public readonly etag: string;
    public readonly transaction_source: string;
    public readonly consent: string;
    public readonly gateway_id: number;
    public readonly gateway_transaction_id: number | null;
    public readonly currency_id: number;
    public readonly currency: CurrencyModel;
    public readonly region_id: null;
    public readonly region: RegionModel;
    public readonly requested_amount: number;
    public readonly amount: number;
    public readonly reference: string | null;
    public readonly transaction_number: string;
    public readonly idempotency_key: string;
    public readonly status:
        | TransactionsStatusEnums
        | TransactionsStatusEnumsForAutomatic;
    public readonly gateway: PaymentGatewayModel;
    public readonly attachments?: PaymentTransactionAttachmentCollectionModel;
    public readonly created_at: string;
    public readonly updated_at: string;
    private readonly reject_reason: string | null;

    constructor(dataItem: ITransaction) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.lock_version = dataItem.lock_version;
        this.etag = dataItem.etag;
        this.transaction_source = dataItem.transaction_source;
        this.consent = dataItem.consent;
        this.gateway_id = dataItem.gateway_id;
        this.gateway_transaction_id = dataItem.gateway_transaction_id;
        this.currency_id = dataItem.currency_id;
        this.currency = new CurrencyModel(dataItem.currency);
        this.region_id = dataItem.region_id;
        this.requested_amount = dataItem.requested_amount;
        this.amount = dataItem.amount;
        this.reference = dataItem.reference;
        this.transaction_number = dataItem.transaction_number;
        this.idempotency_key = dataItem.idempotency_key;
        this.region = new RegionModel(dataItem.region);
        this.status = dataItem.status;
        this.gateway = new PaymentGatewayModel(dataItem.gateway);
        this.attachments = dataItem.attachments
            ? new PaymentTransactionAttachmentCollectionModel(
                  dataItem.attachments,
              )
            : undefined;
        this.created_at = dataItem.created_at;
        this.updated_at = dataItem.updated_at;
        this.transaction_number = dataItem.transaction_number;
        this.reject_reason = dataItem.reject_reason;
    }
    categorizeAttachments = () => {
        const attachmentData = this.attachments
            ? this.attachments.data.reduce(
                  (acc, curr) => {
                      if (curr.image.src.endsWith(".pdf")) {
                          acc.PDF.push(curr.image.src);
                      } else {
                          acc.images.push(curr.image.src);
                      }
                      return acc;
                  },
                  {
                      PDF: [] as string[],
                      images: [] as string[],
                  },
              )
            : { PDF: [], images: [] };

        return attachmentData;
    };
    getId() {
        return this.id;
    }

    getStatus() {
        return this.status;
    }
    getRejectReason() {
        return this.reject_reason;
    }
    getAmount() {
        return this.amount;
    }
    getGateway() {
        return this.gateway;
    }
    getCurrency() {
        return this.currency;
    }

    getTransactionNumber() {
        return this.transaction_number;
    }

    getRegion() {
        return this.region;
    }

    getTransactionSource() {
        return this.transaction_source;
    }

    getRequestAmount() {
        return this.requested_amount;
    }

    getConsent() {
        return this.consent;
    }

    getCreated() {
        return this.created_at;
    }
}

export class TransactionCollectionModel {
    public readonly object: string;
    public readonly data: TransactionModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: ITransactionCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new TransactionModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getData() {
        return this.data;
    }
    getTransactionsById(id: number): TransactionModel | undefined {
        return this.data.find((item) => item.id === id);
    }

    getfilters() {
        return this.filters;
    }

    getPagination() {
        return this.pagination;
    }
}

export class UpdateStatusModel {
    public readonly id: number;
    public readonly status: string;

    constructor(data: IUpdateStatus) {
        this.id = data.id;
        this.status = data.status;
    }
}
