import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import { FileUploadResource } from "@/lib/core/resources/public";

class StorageService extends CommerceApiService {
    public fileUploadResource: FileUploadResource;

    constructor() {
        super();
        this.fileUploadResource = new FileUploadResource(this.config);
    }
}

export default StorageService;
