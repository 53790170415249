import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Dropdown,
    Flex,
    Image,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import {
    transactionCollectionAtom,
    transactionDetailAtom,
} from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { EditOutlined, HddFilled } from "@ant-design/icons";
import {
    TransactionCollectionModel,
    TransactionModel,
} from "@/models/transactionCollectionModels";
import {
    useTransactions,
    useTransactionsDetail,
} from "@/lib/core-react/hooks/private/useTransactions";
import UpdateStatus from "./components/UpdateStatus";
import FiltersComponent from "@/components/FiltersComponent";
import { IFilterType } from "@/types/filters";
import { StatusTag } from "@/components";
import { ExtendedMenuItemType } from "@/types";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { PAYMENT_GATEWAY_PERMISSION_ENUM } from "@/consts/permission-enum/payment-enum";
import { formatString } from "@/utils/helper";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { formatDateTime } from "@/utils/helpers";
import TransactionQuickViews from "./components/transactionQuickViewModal";
import { DownloadPdfButton } from "@/components/DownloadPdfButton";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import Print from "./components/print";
import { TransactionModelDetails } from "@/models/transactionDetailModels";
import ReactDOMServer from "react-dom/server";
import useOryAuth from "@/lib/core-react/utils/useOryAuth";

interface IAttachment {
    PDF: string[];
    images: string[];
}
const Transactions = () => {
    const { getTransactions } = useTransactions();
    const { getTransactionsDetail } = useTransactionsDetail();

    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<TransactionModel[]>([]);
    const [gatewayTotals, setGatewayTotals] = useState<
        Record<string, { totalAmount: number; currency: string }>
    >({});
    const [selectedTransactions, setSelectedTransactions] = useState<
        number | undefined
    >(undefined);

    const [selectedAttachments, setSelectedAttachments] = useState<
        IAttachment | undefined
    >(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updatePayload, setUpdatePayload] = useState<
        TransactionModel | undefined
    >(undefined);

    const [trxDetails, setTrxDetails] = useState<
        TransactionModelDetails | undefined
    >();
    const [receiptPrintOpen, setReceiptPrintOpen] = useState(false);

    const [setSelected, setSetSelected] = useState<string | undefined>(
        undefined,
    );
    const [isModalQuickView, setIsModalQuickView] = useState(false);

    const { Paragraph, Text } = Typography;
    const { isMobile } = useWindowWidth();
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    const [{ data: transactionCollectionData, isLoading, refetch }] = useAtom(
        transactionCollectionAtom,
    );

    const [{ data: transactionDetail }] = useAtom(transactionDetailAtom);

    useEffect(() => {
        if (selectedRows.length > 0) {
            // Ensure the value is passed as a string
            getTransactionsDetail(String(selectedRows[0].id));
        }
    }, [selectedRows]);

    const data =
        transactionDetail && new TransactionModelDetails(transactionDetail);

    useEffect(() => {
        if (
            (!isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter ||
            filters
        ) {
            ApiHelperModel.makeGetRequest(filters, getTransactions);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    const TransactionsCollectionData =
        transactionCollectionData &&
        new TransactionCollectionModel(transactionCollectionData);

    //const paginationData = TransactionsCollectionData?.getPagination();

    const filterData = TransactionsCollectionData?.getfilters();

    // Show update modal with data
    const showModal = (record: any) => {
        setUpdatePayload(record);
        setIsShowCreateModal(true);
    };

    const handleShowDetail = (record: number) => {
        setSelectedTransactions(record);
    };

    const handleShowAttachments = (attachments: IAttachment) => {
        setSelectedAttachments(attachments);
        setIsModalVisible(true);
    };

    const handleQuickView = (id: string) => {
        setSetSelected(id);
        setIsModalQuickView(true);
    };

    const handleCloseModal = () => {
        setSetSelected(undefined);
        setIsModalQuickView(false);
    };

    useEffect(() => {
        if (selectedTransactions !== undefined) {
            const url = `/payment/transaction/${selectedTransactions}`;
            window.open(url, "_blank");
        }
    }, [selectedTransactions]);

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && TransactionsCollectionData?.getfilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        TransactionsCollectionData?.getfilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getTransactions,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        TransactionsCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getTransactions);
    };

    const onSelectChange = (
        newSelectedRowKeys: React.Key[],
        newSelectedRows: TransactionModel[],
    ) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRows(newSelectedRows);

        const newGatewayTotals = newSelectedRows.reduce(
            (totals, row) => {
                const gatewayName = row.gateway.name;
                const amount = row.amount || 0;
                const currency = row.getCurrency().code;

                if (!totals[gatewayName]) {
                    totals[gatewayName] = {
                        totalAmount: 0,
                        currency: currency,
                    };
                }

                totals[gatewayName].totalAmount += amount;
                return totals;
            },
            {} as Record<string, { totalAmount: number; currency: string }>,
        );

        setGatewayTotals(newGatewayTotals);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const groupedByRegion = selectedRows.reduce(
        (groups, row) => {
            const region = row.region.getCode();
            const currency = row.currency.getCode();

            if (!groups[region]) {
                groups[region] = {
                    totalAmount: 0,
                    totalRequestAmount: 0,
                    currency,
                };
            }

            groups[region].totalAmount += row.amount || 0;
            groups[region].totalRequestAmount += row.requested_amount || 0;
            return groups;
        },
        {} as Record<
            string,
            {
                totalAmount: number;
                totalRequestAmount: number;
                currency: string;
            }
        >,
    );

    const stringToColor = (str: string) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = "#";
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff;
            color += ("00" + value.toString(16)).substr(-2);
        }
        return color;
    };

    // Hooks
    const { getUserName } = useOryAuth();

    const handlePrint = () => {
        setTrxDetails(data);
        setReceiptPrintOpen(true);
    };

    useEffect(() => {
        if (trxDetails && receiptPrintOpen) {
            setReceiptPrintOpen(false);
            const userName = getUserName();
            const printContent = ReactDOMServer.renderToString(
                <Print
                    items={selectedRows}
                    user={userName}
                    customer={
                        trxDetails.association
                            .getPaymentSession()
                            .getSessionInvoices()
                            .getData()[0].invoice.customer
                    }
                    setTrxDetails={setTrxDetails}
                />,
            );

            const newWindow = window.open(
                "",
                "PrintWindow",
                "width=800,height=600",
            );

            if (!newWindow) {
                return;
            }

            newWindow.document.write(
                printContent +
                    '<hr style="margin-bottom: 50px; margin-top: 50px; border-top: #ddd;" />' +
                    printContent,
            );

            // Trigger the print dialog and close the window
            newWindow.document.close();
            newWindow.print();
            newWindow.close();
        }
    }, [receiptPrintOpen, trxDetails, selectedRows]);

    const columns = [
        // {
        //     title: "#SL",
        //     dataIndex: "id",
        //     key: "sl",
        //     width: 70,
        //     align: "center" as const,
        //     render: (_: string, __: TransactionModel, index: number) => {
        //         return (
        //             <div>
        //                 {paginationData
        //                     ? (paginationData.current_page - 1) *
        //                           paginationData.per_page +
        //                       index +
        //                       1
        //                     : ""}
        //             </div>
        //         );
        //     },
        // },
        {
            title: "Transaction Info",
            dataIndex: "transaction_number",
            key: "transaction_number",
            render: (_: string, record: TransactionModel) => {
                return (
                    <div>
                        <Paragraph>
                            <Text>TN : </Text>
                            <Text strong copyable>
                                {record.getTransactionNumber()}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Source : </Text>
                            <Text strong>
                                {formatString(record.getTransactionSource())}
                            </Text>
                        </Paragraph>
                        <Space style={{ marginTop: 5, marginBottom: 5 }}>
                            <Text>Region : </Text>
                            <StatusTag
                                slug={record.getRegion().getCode()}
                                text={record.getRegion().getCode()}
                            />
                        </Space>
                        <Paragraph>
                            <Text>Create At : </Text>
                            <Tag color="purple">
                                {formatDateTime(record.getCreated())}
                            </Tag>
                        </Paragraph>
                        <Flex
                            gap={12}
                            align="center"
                            style={{ marginTop: 10, padding: 0 }}
                        >
                            <Button
                                type="link"
                                onClick={() => {
                                    handleQuickView(record.id.toString());
                                }}
                                style={{ padding: 0 }}
                            >
                                Quick View
                            </Button>

                            <Button
                                type="link"
                                onClick={() => handleShowDetail(record.id)}
                                // shape="round"
                                // icon={<EyeFilled />}
                                style={{ padding: 0 }}
                            >
                                View Details
                            </Button>
                        </Flex>
                    </div>
                );
            },
        },
        {
            title: "Gateway Info",
            dataIndex: "gateway",
            key: "gateway",
            width: 200,
            render: (_: string, record: TransactionModel) => {
                return (
                    <div>
                        <Paragraph>
                            <Text>Name : </Text>
                            <Text strong>{record.getGateway().getName()}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Type : </Text>
                            <Text strong>
                                {formatString(record.getGateway().getType())}
                            </Text>
                        </Paragraph>
                    </div>
                );
            },
        },
        {
            title: "Amount Details",
            dataIndex: "amount",
            key: "amount",
            render: (_: string, record: TransactionModel) => {
                return (
                    <div>
                        <Paragraph>
                            <Text>Amount : </Text>
                            <Text strong>
                                {record.getCurrency().getCode()}{" "}
                                {record.getAmount()}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Requested : </Text>
                            <Text strong>
                                {record.getCurrency().getCode()}{" "}
                                {record.getRequestAmount()}
                            </Text>
                        </Paragraph>
                    </div>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_: string, record: TransactionModel) => {
                const isStatusReject = record.getStatus() === "rejected";
                const hasRejectReason = record.getRejectReason();
                return (
                    <div style={{ maxWidth: "250px" }}>
                        <Paragraph style={{ marginTop: 5 }}>
                            <Tooltip
                                title={
                                    isStatusReject && hasRejectReason
                                        ? hasRejectReason
                                        : ""
                                }
                            >
                                <StatusTag
                                    slug={record.getStatus()}
                                    text={record.getStatus()}
                                />
                            </Tooltip>
                        </Paragraph>

                        {/* Display reject reason below if status is 'rejected' */}
                        {isStatusReject && hasRejectReason && (
                            <Paragraph
                                type="secondary"
                                style={{ marginTop: 5 }}
                            >
                                <strong> Reject Reason: </strong>{" "}
                                {hasRejectReason}
                            </Paragraph>
                        )}
                    </div>
                );
            },
        },
        {
            title: <Tooltip title="Payment Slips">Slip</Tooltip>,
            dataIndex: "id",
            key: "Payment_Slips",
            align: "center",
            render: (_: string, record: TransactionModel) => {
                const attachmentsData = record.categorizeAttachments();
                return checkActionPermission(
                    PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_VIEW,
                    <Flex gap={8} align="center" justify="center">
                        {attachmentsData.images.length > 0 && (
                            <Tooltip
                                title="View Payment Slip"
                                key={"view_details"}
                            >
                                <Button
                                    style={{
                                        padding: 0,
                                    }}
                                    onClick={() =>
                                        handleShowAttachments(attachmentsData)
                                    }
                                >
                                    <ImageWithPlaceholder
                                        preview={false}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            overflow: "hidden",
                                            objectFit: "contain",
                                        }}
                                        src={`${attachmentsData.images[0]}`}
                                    />
                                </Button>
                            </Tooltip>
                        )}

                        {attachmentsData.PDF.length > 0 &&
                            attachmentsData.PDF.map((url) => (
                                <DownloadPdfButton pdfUrl={url} />
                            ))}
                    </Flex>,
                    null,
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            width: 100,
            render: (_: string, record: TransactionModel) => {
                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_UPDATE,
                        label: "Status Update",
                        key: "update_status",
                        icon: <EditOutlined />,
                        onClick: () => showModal(record),
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button type="primary" icon={<HddFilled />} />
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Transactions"
                    style={{ marginTop: "10px" }}
                >
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <FiltersComponent
                                    handleProductFilter={handleProductFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={filterData}
                                    isFromProductReceived={true}
                                />
                            </Col>
                        </Row>
                    )}
                    <div style={{ marginBottom: 10, marginTop: 10 }}>
                        {hasSelected && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <Tag
                                        color="blue"
                                        style={{
                                            fontWeight: "bold",
                                            padding: 3,
                                            fontSize: 14,
                                            marginTop: 5,
                                        }}
                                    >
                                        Selected {selectedRowKeys.length} items
                                    </Tag>
                                    {Object.entries(groupedByRegion).map(
                                        ([
                                            region,
                                            {
                                                totalAmount,
                                                totalRequestAmount,
                                                currency,
                                            },
                                        ]) => (
                                            <div
                                                key={region}
                                                style={{ marginBottom: "10px" }}
                                            >
                                                <div>
                                                    <Text>
                                                        Region:{" "}
                                                        <StatusTag
                                                            text={region}
                                                            slug={region}
                                                        />
                                                    </Text>
                                                    <Tag
                                                        color="green"
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: 3,
                                                            fontSize: 14,
                                                            marginTop: 5,
                                                        }}
                                                    >
                                                        {currency}{" "}
                                                        {totalAmount.toFixed(2)}
                                                    </Tag>
                                                    <Tag
                                                        color="red"
                                                        style={{
                                                            fontWeight: "bold",
                                                            padding: 3,
                                                            fontSize: 14,
                                                            marginTop: 5,
                                                        }}
                                                    >
                                                        {currency}{" "}
                                                        {totalRequestAmount.toFixed(
                                                            2,
                                                        )}
                                                    </Tag>
                                                </div>

                                                {/* <Tag
                                                color="red"
                                                style={{
                                                    fontWeight: "bold",
                                                    padding: 3,
                                                    fontSize: 14,
                                                    marginTop: 5,
                                                }}
                                            >
                                                RA ({region}): {currency}{" "}
                                                {totalRequestAmount.toFixed(2)}
                                            </Tag> */}
                                            </div>
                                        ),
                                    )}
                                    {Object.entries(gatewayTotals).map(
                                        ([
                                            gateway,
                                            { totalAmount, currency },
                                        ]) => (
                                            <Tag
                                                key={gateway}
                                                color={stringToColor(gateway)}
                                                style={{
                                                    fontWeight: "bold",
                                                    padding: 3,
                                                    fontSize: 14,
                                                    marginTop: 5,
                                                }}
                                            >
                                                {gateway} - {currency}{" "}
                                                {totalAmount.toFixed(2)}
                                            </Tag>
                                        ),
                                    )}
                                </div>
                                <div>
                                    <Button onClick={handlePrint}>
                                        Print Selected
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>
                        <Table
                            rowSelection={{
                                type: "checkbox",
                                ...rowSelection,
                            }}
                            loading={isLoading}
                            rowKey="id"
                            bordered={true}
                            dataSource={TransactionsCollectionData?.data}
                            //@ts-ignore
                            columns={columns}
                            pagination={{
                                ...paginationConfig,
                                showTotal: (total) => (
                                    <div>
                                        Total{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                color: "green",
                                            }}
                                        >
                                            {total}
                                        </span>{" "}
                                        Items
                                    </div>
                                ),
                            }}
                            scroll={{ x: 1000 }}
                        />
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={`Update Status`}
                open={isShowCreateModal}
                footer={false}
                onCancel={() => {
                    setIsShowCreateModal(false);
                    setUpdatePayload(undefined);
                }}
                destroyOnClose={true}
                width="40%"
            >
                <UpdateStatus
                    setIsShowUpdateModal={setIsShowCreateModal}
                    data={updatePayload as TransactionModel}
                />
            </Modal>

            <Modal
                open={isModalVisible}
                title="Attachment Slip & Images"
                onCancel={() => {
                    setIsModalVisible(false);
                }}
                onOk={() => {
                    setIsModalVisible(false);
                }}
                destroyOnClose={true}
            >
                {selectedAttachments &&
                selectedAttachments.images.length > 0 ? (
                    <>
                        <Image
                            src={`${selectedAttachments.images[0]}`}
                            style={{ marginBottom: 10, maxWidth: "100%" }}
                        />
                        {selectedAttachments.images.length > 1 && (
                            <Image.PreviewGroup>
                                {selectedAttachments.images
                                    .slice(1)
                                    .map((image, index) => (
                                        <Image
                                            key={index}
                                            src={image}
                                            style={{
                                                marginBottom: 10,
                                                maxWidth: "100%",
                                            }}
                                            alt={`Attachment ${index + 1}`}
                                        />
                                    ))}
                            </Image.PreviewGroup>
                        )}
                    </>
                ) : (
                    <p>No images selected</p>
                )}
            </Modal>

            {setSelected && (
                <Modal
                    title=""
                    open={isModalQuickView}
                    onCancel={handleCloseModal}
                    footer={[
                        <Button key="close" onClick={handleCloseModal}>
                            Close
                        </Button>,
                    ]}
                    width={"40%"}
                    destroyOnClose={true}
                >
                    {/* Modal Content Based on Selected Record */}
                    <TransactionQuickViews id={setSelected} />
                </Modal>
            )}
        </>
    );
};

export default Transactions;
