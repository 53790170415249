import { ResponsePromise } from "@/lib/core/request/types/typings";
import BaseResource from "../public/baseResource";
import { IActionPerformPayload } from "@/types/buyActionCollection";
class BuyActionResource extends BaseResource {
    path = `/api/buy-take-action/admin/buy-action/v1/buy-actions`;
    getBuyActions(params?: string): ResponsePromise {
        const path = `${this.path}?${params ? `${params}` : ""}`;
        return this.client.request("GET", path, undefined, undefined, {});
    }

    performBuyActions(
        buyTakeAction_id: number,
        payload: IActionPerformPayload,
    ): ResponsePromise {
        const path = `${this.path}/${buyTakeAction_id}/perform`;
        return this.client.request("POST", path, payload, undefined, {});
    }

    refundBuyActions(
        buyTakeAction_id: number,
        payload: IActionPerformPayload,
    ): ResponsePromise {
        const path = `${this.path}/${buyTakeAction_id}/refund`;
        return this.client.request("POST", path, payload, undefined, {});
    }

    cancelBuyActions(buyTakeAction_id: number): ResponsePromise {
        const path = `${this.path}/${buyTakeAction_id}/cancel`;
        return this.client.request("PUT", path, undefined, {});
    }
}

export default BuyActionResource;
