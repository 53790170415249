import React from "react";
import { Form, Input, Button, Flex } from "antd";

interface ProductUrlFormProps {
    handleFinish: (values: any) => void;
    onGoToInventory: () => void;
}

const ProductUrlForm: React.FC<ProductUrlFormProps> = ({
    handleFinish,
    onGoToInventory,
}) => {
    const [form] = Form.useForm();

    const productUrl = Form.useWatch(["productUrl"], form);

    return (
        <Form layout="vertical" form={form} onFinish={handleFinish}>
            <Form.Item label="Product URL" name="productUrl">
                <Flex align="center" justify="end" gap={12}>
                    <Input
                        style={{ flex: 1 }}
                        placeholder="Please Paste your URL"
                    />
                    <Button
                        disabled={!productUrl}
                        type={
                            form.getFieldValue("productUrl")
                                ? "primary"
                                : "default"
                        }
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Flex>
            </Form.Item>

            <Flex justify="end" gap={24}>
                <Button htmlType="button" onClick={onGoToInventory}>
                    Go to inventory
                </Button>
            </Flex>
        </Form>
    );
};

export default ProductUrlForm;
