import { SingleChunkUpload } from "@/components/SingleChunkUpload";
import { Card, Form, Typography } from "antd";
import { useForm } from "antd/es/form/Form";
import { Link } from "react-router-dom";

const GetProductLink = () => {
    const [form] = useForm();
    const imageLink = Form.useWatch("image_link", form);
    return (
        <Form form={form}>
            <Card
                title="Get Image Link"
                style={{
                    marginTop: "30px",
                }}
            >
                <Form.Item
                    name={"image_link"}
                    rules={[{ required: true, message: "Image is required" }]}
                >
                    <SingleChunkUpload
                        form={form}
                        accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                        fieldName={"image_link"}
                    />
                </Form.Item>
                {imageLink && (
                    <Typography>
                        <Typography.Text>Link: </Typography.Text>
                        <Link target="_blank" to={imageLink}>
                            <Typography.Link copyable>
                                {imageLink}
                            </Typography.Link>
                        </Link>
                    </Typography>
                )}
            </Card>
        </Form>
    );
};

export default GetProductLink;
