import StatusTag from "@/components/StatusTag";
import { AgentCompanyModel } from "@/models/agentCompanyCollectionModel";
import { formatString } from "@/utils/helper";
import { Descriptions, DescriptionsProps, Divider } from "antd";

interface IProps {
    company: AgentCompanyModel;
}

export const ViewDetails = ({ company }: IProps) => {
    const items: DescriptionsProps["items"] = [
        {
            key: "1",
            label: "Primary Name",
            children: company.getPrimaryName(),
        },
        {
            key: "2",
            label: "Secondary Name",
            children: company.getSecondaryName(),
        },
        {
            key: "3",
            label: "Operation Status",
            children: (
                <StatusTag
                    slug={company.getOperationStatus()}
                    text={formatString(company.getOperationStatus())}
                />
            ),
        },
        {
            key: "4",
            label: "Verification Status",
            children: (
                <StatusTag
                    slug={company.getVerificationStatus()}
                    text={company.getVerificationStatus()}
                />
            ),
        },
        {
            key: "5",
            label: "Registered Country",
            children: company.getRegisteredCountryDetail()?.getName(),
        },
        {
            key: "6",
            label: "Company Type",
            children: company.getCompanyType(),
        },
        {
            key: "7",
            label: "Director Name",
            children: company.getDirectorName(),
        },
        {
            key: "8",
            label: "Designation",
            children: company.getDesignation(),
        },
        {
            key: "9",
            label: "Business Phone",
            children: company.getBusinessPhone(),
        },
        {
            key: "10",
            label: "Years of Experience",
            children: formatString(company.getYearsOfExperience()),
        },
        {
            key: "11",
            label: "Number of People in Team",
            children: formatString(company.getNumberOfPeopleInTeam()),
        },
        {
            key: "12",
            label: "Address",
            span: 2,
            children: `${company.getAddress()?.address_1}, ${company.getAddress()?.address_2}, ${company.getAddress()?.city}, ${company.getAddress()?.state}, ${company.getAddress()?.postal}, ${company.getAddress()?.country}`,
        },
    ];

    return (
        <>
            <Divider />
            <Descriptions layout="vertical" items={items} />
        </>
    );
};
