import { ShippingTrackingTypesEnums } from "@/enums/shippingEnums";
import {
    ICommonProductImage,
    ICommonProductImageCollection,
    ICommonRegion,
    ICommonUser,
    IImage,
    IImageCollection,
    ITrackingCollection,
    ITracking,
} from "@/types/shared";

export class CommonUserModel {
    id: number;
    name: string;
    email: string;
    phone?: string | null;
    gravatar?: string | null;
    shipping_mark?: string | null;
    type?: string | null;

    constructor(data: ICommonUser) {
        this.id = data.id;
        this.name = data.name;
        this.email = data.email;
        this.phone = data.phone;
        this.gravatar = data.gravatar;
        this.shipping_mark = data.shipping_mark;
        this.type = data.type;
    }

    getId = () => this.id;
    getName = () => this.name;
    getEmail = () => this.email;
    getPhone = () => this.phone;
    getGravatar = () => this.gravatar;
    getShippingMark = () => this.shipping_mark;
    getType = () => this.type;
}

export class CommonRegionModel {
    object: string;
    id: number;
    name: string;
    code: string;

    constructor(data: ICommonRegion) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
    }

    getId = () => this.id;
    getName = () => this.name;
    getCode = () => this.code;
}

// common product image
export class CommonProductImageModel {
    object: string;
    title: string | null;
    id: number;
    alt: string | null;
    src: string;
    display_order: number;

    constructor(data: ICommonProductImage) {
        this.object = data.object;
        this.title = data.title;
        this.id = data.id;
        this.alt = data.alt;
        this.src = data.src;
        this.display_order = data.display_order;
    }

    getTitle = () => this.title;
    getId = () => this.id;
    getAlt = () => this.alt;
    getSrc = () => this.src;
    getDisplayOrder = () => this.display_order;
}

export class CommonProductImageCollectionModel {
    object: string;
    data: CommonProductImageModel[];

    constructor(data: ICommonProductImageCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new CommonProductImageModel(item));
    }

    getData = () => this.data;
    getFirstImageSrc = () => {
        if (this.data.length) return this.data[0].getSrc();
        else return "";
    };
}

export class ImageModel {
    public readonly object: string;
    public readonly id: number;
    public readonly title: string;
    public readonly alt: string;
    public readonly src: string;
    public readonly display_order: number;
    constructor(data: IImage) {
        this.object = data.object;
        this.id = data.id;
        this.title = data.title;
        this.alt = data.alt;
        this.src = data.src;
        this.display_order = data.display_order;
    }

    getId() {
        return this.id;
    }
    getTitle() {
        return this.title;
    }
    getAlt() {
        return this.alt;
    }
    getSrc() {
        return this.src;
    }
    getDisplayOrder() {
        return this.display_order;
    }
}

export class ImageCollectionModel {
    public readonly object: string;
    public readonly data: ImageModel[];
    constructor(data: IImageCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ImageModel(item));
    }

    getData() {
        return this.data;
    }
    getFirstImage() {
        return this.data.length ? this.data[0] : null;
    }
    getImageSrcs() {
        return this.data.length ? this.data.map((image) => image.getSrc()) : [];
    }
}

export class TrackingModel {
    public readonly object: string;
    public readonly id: number;
    public readonly type: ShippingTrackingTypesEnums;
    public readonly tracking: string;
    constructor(data: ITracking) {
        this.object = data.object;
        this.id = data.id;
        this.type = data.type;
        this.tracking = data.tracking;
    }

    getId() {
        return this.id;
    }
    getType() {
        return this.type;
    }
    getTracking() {
        return this.tracking;
    }
}

export class TrackingCollectionModel {
    public readonly object: string;
    public readonly data: TrackingModel[];
    constructor(data: ITrackingCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new TrackingModel(item));
    }

    getData() {
        return this.data;
    }
    getGlobalTracking() {
        // Find the tracking information where type is "global-tracking"
        const globalTrackingData = this.data.find(
            (item) => item.type === "global-tracking",
        );

        if (globalTrackingData) {
            return globalTrackingData.getTracking();
        } else {
            return "not available";
        }
    }
    getAbroadTracking() {
        // Find the tracking information where type is "global-tracking"
        const abroadTrackingData = this.data.find(
            (item) => item.type === "abroad-courier-tracking",
        );

        if (abroadTrackingData) {
            return abroadTrackingData.getTracking();
        } else {
            return "not available";
        }
    }
    getTracking() {
        const globalTrackingData = this.data.find(
            (item) => item.type === "global-tracking",
        );
        const abroad_trackingData = this.data.find(
            (item) => item.type === "abroad-courier-tracking",
        );

        if (globalTrackingData) {
            return globalTrackingData.tracking;
        } else if (abroad_trackingData) {
            return abroad_trackingData.tracking;
        } else {
            return "not available";
        }
    }
}
