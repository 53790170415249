import { useEffect, createRef } from "react";
import { Button, Form, Input, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { UserModel } from "@/models/userCollectionModel";
import { useUpdateAdmin } from "@/lib/core-react/hooks/private/useAdminUser";
import { IUserStatus } from "@/types/userCollection";
import { showError } from "@/helpers/showError";
import { useGetRole } from "@/lib/core-react/hooks/private";
import { responseTypeQuery } from "@/filters/constant";
import QueryString from "qs";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

interface IProps {
    setIsShowResetPassModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedAdminUser: UserModel;
}

const ResetAdminUserPassword = ({
    setIsShowResetPassModal,
    selectedAdminUser,
}: IProps) => {
    const { updateAdmin, isLoading } = useUpdateAdmin();
    const { getRole } = useGetRole();

    useEffect(() => {
        getRole(QueryString.stringify(responseTypeQuery.minimal));
    }, []);

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        form.setFieldsValue({
            name: selectedAdminUser.name,
            is_active: selectedAdminUser.is_active === 1 ? "1" : "0",
            role_ids: selectedAdminUser?.roles?.data.map((role) =>
                role.id?.toString(),
            ),
        });
    }, [selectedAdminUser, form]);

    const onFinish = async (value: IUserStatus) => {
        try {
            await updateAdmin(value, Number(selectedAdminUser.id));
            setIsShowResetPassModal(false);
            notification["success"]({
                message: "Password Updated for Admin User successfully!",
            });
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Reset Admin Password"
            ref={formRef}
            initialValues={{
                name: selectedAdminUser.name,
                role_ids: selectedAdminUser?.roles?.data.map((role) =>
                    role.id?.toString(),
                ),
                is_active: selectedAdminUser.is_active.toString(),
            }}
        >
            <Form.Item name="name" hidden>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="role_ids" hidden>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="is_active" hidden>
                <Input type="hidden" />
            </Form.Item>
            <Form.Item
                rules={[
                    { required: true, message: "Password is required" },
                    {
                        min: 6,
                        message: "Password must be at least 6 characters",
                    },
                ]}
                label="Password"
                name="password"
            >
                <Input.Password
                    placeholder="Type password"
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                />
            </Form.Item>
            <Form.Item
                rules={[
                    { required: true, message: "Password is required" },
                    {
                        min: 6,
                        message: "Password must be at least 6 characters",
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error("Passwords do not match!"),
                            );
                        },
                    }),
                ]}
                label="Confirm Password"
                name="password_confirmation"
            >
                <Input.Password
                    placeholder="Retype password"
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                />
            </Form.Item>
            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ResetAdminUserPassword;
