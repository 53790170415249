import { Button, Form, Input, notification, Select, TreeSelect } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { ShippingCategoryStatusEnum } from "@/enums/shippingCategoryCollectionEnums";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { useAtom } from "jotai";
import {
    useCreateShippingCategory,
    useGetBaseShippingCategory,
} from "@/lib/core-react/hooks/private";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import { baseShippingCategoryAtom } from "@/lib/core-react/store/store";
import { BaseShippingCategoryModel } from "@/models/baseShippingCategory";
import { Forms } from "@/models/form";
import {
    ShippingCategoryModel,
    ShippingCategoryRequestModel,
} from "@/models/shippingCategory";
import React, { useEffect, createRef } from "react";

interface IProps {
    data: ShippingCategoryModel;
    setIsShowAddChildModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddChildren = ({ data, setIsShowAddChildModal }: IProps) => {
    const { createShippingCategory, isLoading } = useCreateShippingCategory();
    const { getBaseShippingCategory } = useGetBaseShippingCategory();
    const {
        allOptionsData: { regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
        getBaseShippingCategory("per_page=500");
    }, []);

    // region, base Shipping category

    const [
        {
            data: baseShippingCategoryDataAtom,
            isLoading: baseShippingCategoryLoading,
        },
    ] = useAtom(baseShippingCategoryAtom);

    const baseShippingCategoryTreeId =
        baseShippingCategoryDataAtom &&
        new BaseShippingCategoryModel(
            baseShippingCategoryDataAtom,
        ).getBaseShippingCategoryTreeIds();

    const onFinish = async (value: ShippingCategoryRequestModel) => {
        const payload = {
            name: value.name,
            status: value.status,
            slug: value.slug,
            region_id: value.region_id,
            base_shipping_category_id: value.base_shipping_category_id
                ? value.base_shipping_category_id
                : null,
            parent_id: data.id,
        };

        try {
            const res = await createShippingCategory(
                payload as ShippingCategoryRequestModel,
            );

            if (res) {
                notification["success"]({
                    message: res.message,
                });
                setIsShowAddChildModal(false);
            }
        } catch (error) {
            notification["error"]({
                message: getError(error),
            });
        }
    };
    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Create_Shipping_Category"
            ref={formRef}
            onFieldsChange={onValuesChange}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Shipping category name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Shipping category name" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Slug is required" }]}
                label="Slug"
                name="slug"
            >
                <Input
                    style={{ width: "100%" }}
                    readOnly
                    placeholder="Slug name"
                />
            </Form.Item>
            <Form.Item
                label="Select Base Shipping Category"
                name="base_shipping_category_id"
                rules={[
                    {
                        required: true,
                        message: "Base shipping category is required",
                    },
                ]}
            >
                <TreeSelect
                    showSearch
                    dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                    placeholder="Please select a base shipping category"
                    allowClear
                    loading={baseShippingCategoryLoading}
                    treeLine={{ showLeafIcon: true }}
                    treeData={baseShippingCategoryTreeId}
                    filterTreeNode={(searchValue, treeNode) => {
                        const title = treeNode.title;
                        return (
                            typeof title === "string" &&
                            title
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        );
                    }}
                />
            </Form.Item>
            <Form.Item
                rules={[{ required: true, message: "Region is required" }]}
                name="region_id"
                label="Region"
            >
                <Select
                    loading={regionOptionsData.isLoading}
                    options={regionOptionsData.options}
                    placeholder="Please select a region"
                />
            </Form.Item>
            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                name="status"
                label="Status"
            >
                <Select placeholder="Please select a status">
                    {renderOptionsFromEnum(ShippingCategoryStatusEnum)}
                </Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddChildren;
