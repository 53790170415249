import { UserOutlined } from "@ant-design/icons";
import { Button, Table, Typography, notification } from "antd";
import { useAtomValue } from "jotai";
import {
    useCreateBid,
    useUpdateBid,
} from "@/lib/core-react/hooks/private/useShipping";
import { useGetCategoryPrices } from "@/lib/core-react/hooks/private/useShippingAgent";
import { agentCategoryPriceReadCollectionAtom } from "@/lib/core-react/store/store";
import {
    AgentCategoryPriceReadCollectionModel,
    AgentCategoryPriceReadModel,
    AgentCategoryPriceSlotModel,
} from "@/models/agentCategoryPriceReadCollectionModel";
import React, { useEffect, useState } from "react";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import { IOpenBidPayload } from "@/types/shipmentProductCollection";

interface IProps {
    agentCategoryPriceRead: ICategoryPriceRequestParams;
    productId: number;
    bidId?: number;
    onCancel: () => void;
    mode: "create" | "update";
}
const AgentAssignWithBidManage = ({
    agentCategoryPriceRead,
    productId,
    onCancel,
    bidId,
    mode,
}: IProps) => {
    const { getCategoryPrices } = useGetCategoryPrices();
    const { data, isLoading: priceReadLoading } = useAtomValue(
        agentCategoryPriceReadCollectionAtom,
    );
    const agentCategoryPriceReadObj =
        data && new AgentCategoryPriceReadCollectionModel(data);

    const getData = async () => {
        await getCategoryPrices(agentCategoryPriceRead);
    };

    useEffect(() => {
        getData();
    }, [agentCategoryPriceRead]);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { createBid, isLoading } = useCreateBid();
    const { updateBid, isLoading: updateBidLoading } = useUpdateBid();

    const createBidHandler = async (
        payload: IOpenBidPayload,
        productId: number,
    ) => {
        try {
            const res = await createBid(payload, productId);
            notification["success"]({
                message: res.message,
            });
            onCancel();
        } catch (error: any) {
            notification["error"]({
                message: error.response.data.message,
            });
        }
    };
    const updateBidHandler = async (
        payload: IOpenBidPayload,
        bidId: number,
    ) => {
        try {
            const res = await updateBid(payload, bidId);
            notification["success"]({
                message: res.message,
            });
            onCancel();
        } catch (error: any) {
            notification["error"]({
                message: error.response.data.message,
            });
        }
    };

    const slotsColumns = [
        {
            title: "Min",
            dataIndex: "min_amount",
            key: "min_amount",
        },
        {
            title: "Max",
            dataIndex: "max_amount",
            key: "max_amount",
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
        },
    ];

    const columns = [
        {
            title: "Warehouse",
            dataIndex: "warehouse",
            key: "warehouse",
            width: 350,
            render: (_text: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text
                    ellipsis={{ tooltip: record.getAgentWarehouse().getName() }}
                >
                    {record.getAgentWarehouse().getName()}
                </Typography.Text>
            ),
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
            width: 100,
            render: (_: string, record: AgentCategoryPriceReadModel) =>
                record.getRate(),
        },
        {
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
            width: 100,
            render: (_: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text>
                    {record.getCommissionRate()}
                    {record.getCommissionRateType() === "percentage"
                        ? " (%)"
                        : " (Fixed)"}
                </Typography.Text>
            ),
        },
        {
            title: "Customer Cost",
            dataIndex: "customer_cost",
            key: "customer_cost",
            width: 150,
            align: "center" as const,
            render: (_: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text>
                    {record.getTotalRate()} /- {record.getUnitType()}
                </Typography.Text>
            ),
        },
        {
            title: "Slots",
            dataIndex: ["slots", "data"],
            key: "slots",
            align: "center" as const,
            width: 300,
            render: (
                _: AgentCategoryPriceSlotModel,
                record: AgentCategoryPriceReadModel,
            ) => {
                const slotsData = record.getSlots()?.getData();
                const shouldScroll = slotsData && slotsData.length > 2;
                return record.getSlots()?.getData().length ? (
                    <Table
                        columns={slotsColumns}
                        size="small"
                        dataSource={record.getSlots()?.getData()}
                        rowKey={(r) => r.getId()}
                        scroll={shouldScroll ? { y: 80 } : undefined}
                        pagination={false}
                        bordered={false}
                        className="meta_table"
                    />
                ) : null;
            },
        },
    ];

    return (
        <div>
            <div style={{ textAlign: "right" }}>
                <Button
                    loading={isLoading || updateBidLoading}
                    onClick={() => {
                        if (mode === "create") {
                            createBidHandler(
                                {
                                    agent_warehouse_ids: [
                                        ...selectedRowKeys.map((key) =>
                                            Number(key),
                                        ),
                                    ],
                                },
                                productId,
                            );
                        } else if (mode === "update") {
                            updateBidHandler(
                                {
                                    agent_warehouse_ids: [
                                        ...selectedRowKeys.map((key) =>
                                            Number(key),
                                        ),
                                    ],
                                },
                                bidId as number,
                            );
                        }
                    }}
                    disabled={selectedRowKeys.length === 0}
                    type="primary"
                    icon={<UserOutlined />}
                    size={"middle"}
                >
                    {mode === "create" ? "Open Bid" : "Attach Participant"}
                </Button>
            </div>
            {agentCategoryPriceReadObj && (
                <Table
                    dataSource={agentCategoryPriceReadObj.getData()}
                    columns={columns}
                    style={{ verticalAlign: "top" }}
                    rowKey={(r) => r.getId()}
                    loading={priceReadLoading}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (selectedRowKeys) =>
                            setSelectedRowKeys(selectedRowKeys),
                    }}
                />
            )}
        </div>
    );
};

export default AgentAssignWithBidManage;
