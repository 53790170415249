export enum LanguageEnum {
    LanguageCollection = "LanguageCollection",
    Language = "Language",
}
export enum LanguageDataStatus {
    active = "active",
    inactive = "inactive",
}

export enum LanguageCode {
    en = "English",
    bn = "Bangla",
    ar = "Arabic",
    hi = "Hindi",
    ca = "Catalan",
}
