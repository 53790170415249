import { inventoryProductsIgnoreKeys } from "@/consts/appConstants";

export const getUniqueArrayValues = (
    data: string[] | undefined,
    selectedName: string,
): string[] => {
    if (Array.isArray(data)) {
        return Array.from(new Set([...data, selectedName]));
    } else {
        return [selectedName];
    }
};

export const getFilterObjectKeys = (
    obj,
    ignoreKeys = inventoryProductsIgnoreKeys,
) => {
    return Object.keys(obj)
        .filter((key) => !ignoreKeys.includes(key))
        .reduce((filteredObj, key) => {
            filteredObj[key] = obj[key];
            return filteredObj;
        }, {}) as unknown as Record<string, any>;
};

export function allValuesFalsy(obj: any): boolean {
    return Object.values(obj).every((value) => {
        if (Array.isArray(value)) {
            // Check if it's an empty array (falsy)
            return value.length === 0;
        } else if (typeof value === "object" && value !== null) {
            // Recursively check nested objects
            return allValuesFalsy(value);
        } else {
            // Check if the value is falsy
            return !value;
        }
    });
}
