import {
    Form,
    Input,
    Radio,
    Button,
    Space,
    notification,
    Skeleton,
} from "antd";
import { IAdminProfileFormDataExtended } from "@/types/ory";
import {
    useGetAuthSettingsBrowser,
    usePostAuthSettingsBrowser,
} from "@/lib/core-react/hooks/private/useOry";
import { useEffect, useState } from "react";
import { SettingsFlow } from "@ory/client";
import { SingleChunkUpload } from "@/components/SingleChunkUpload";
import { getCsrfTokenValue } from "@/utils/helpers";
import { showError } from "@/helpers/showError";

const ProfileTab = () => {
    const [form] = Form.useForm();
    const { updateSettings } = usePostAuthSettingsBrowser();
    const [flow, setFlow] = useState<SettingsFlow | null>();
    const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);

    const {
        data: settingsFlow,
        isLoading: isSettingsLoading,
        refetch: settingsFlowRefetch,
    } = useGetAuthSettingsBrowser();

    useEffect(() => {
        if (settingsFlow) {
            setFlow(settingsFlow);
            form.setFieldsValue({
                name: settingsFlow.identity.traits.name,
                gender: settingsFlow.identity.traits.gender,
                avatar: settingsFlow.identity.traits.avatar,
            });
        }
    }, [settingsFlow]);

    const name: string | undefined = flow?.identity.traits.name;
    const email: string | undefined = flow?.identity.traits.email;
    const phone: string | undefined = flow?.identity.traits.phone;
    const gender: string | undefined = flow?.identity.traits.gender;
    const profilePic: string | undefined = flow?.identity.traits.avatar;

    const handleProfileUpdate = async (data: IAdminProfileFormDataExtended) => {
        setIsUpdateLoading(true);
        const { flowId, method, csrf_token, name, gender, avatar } = data;
        const payload = {
            csrf_token,
            method,
            traits: {
                name,
                gender,
                avatar,
                email,
                phone,
            },
        };
        try {
            const result = await updateSettings(flowId, payload);
            if (result) {
                settingsFlowRefetch();
                notification.success({
                    message: "Profile updated successfully",
                });
            }
        } catch (error) {
            showError(error);
        } finally {
            setIsUpdateLoading(false);
        }
    };

    const handleFormSubmit = (values: any) => {
        if (!settingsFlow) {
            notification.error({
                message: "Profile Update Error",
                description: "Please refresh the page and try again.",
            });

            return "";
        }

        const payload = {
            flowId: settingsFlow?.id,
            method: "profile",
            csrf_token: getCsrfTokenValue(settingsFlow?.ui.nodes) as string,
            ...values,
        };
        handleProfileUpdate(payload);
    };

    return (
        <div style={{ padding: "24px", maxWidth: "800px", margin: "0 auto" }}>
            {isSettingsLoading ? (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "20px",
                    }}
                >
                    <Skeleton.Input
                        style={{
                            width: "100%",
                            height: "25px",
                            marginBottom: "50px",
                        }}
                        active
                    />
                    <Skeleton.Button
                        style={{
                            height: "20px",
                            width: "150px",
                            marginBottom: "10px",
                        }}
                        active
                    />
                    <div style={{ marginLeft: "10px" }}>
                        <Skeleton.Button
                            style={{
                                height: "20px",
                                width: "60px",
                                marginRight: "10px",
                            }}
                            active
                        />
                        <Skeleton.Button
                            style={{ height: "20px", width: "60px" }}
                            active
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "24px",
                            marginTop: "20px",
                        }}
                    >
                        <Skeleton.Avatar size={64} active />
                    </div>
                    <Skeleton.Button
                        style={{ width: "120px", height: "40px" }}
                        active
                    />
                </div>
            ) : (
                <>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleFormSubmit}
                        initialValues={{
                            name: name,
                            gender: gender,
                            avatar: profilePic,
                        }}
                    >
                        {/* Name Input */}
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter your name!",
                                },
                            ]}
                        >
                            <Input placeholder="Enter your name" />
                        </Form.Item>

                        {/* Gender Selection */}
                        <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select your gender!",
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="male">Male</Radio>
                                <Radio value="female">Female</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {/* Profile Picture Upload */}
                        <Form.Item name="avatar" label="Profile Picture">
                            <Space direction="vertical" size="middle">
                                <SingleChunkUpload
                                    form={form}
                                    accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                                    fieldName="avatar"
                                />
                            </Space>
                        </Form.Item>

                        {/* Submit Button */}
                        <Form.Item>
                            <div style={{ textAlign: "right" }}>
                                <Button
                                    loading={isUpdateLoading}
                                    disabled={isUpdateLoading}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Save Changes
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </>
            )}
        </div>
    );
};

export default ProfileTab;
