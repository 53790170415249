import BaseResource from "../public/baseResource";
import { ResponsePromise } from "@/lib/core/request/types/typings";

class HsCodeResource extends BaseResource {
    path = `api/hs-code/admin/v1/hs-codes`;

    get(
        params?: string | number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default HsCodeResource;
