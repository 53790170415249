import {
    IAddressSchema,
    IAddressSchemaData,
    IAddressSchemaFieldsData,
    IAddressSchemaFieldsErrors,
    IAddressSchemaFieldsProperties,
    IAddressSchemaFieldsPropertyValue,
    IAddressSchemaFieldsRelation,
} from "@/types/addressSchema";

export class AddressSchemaFieldsDataModel {
    object: string;
    data: { [key: string]: string | { name: string } };

    constructor(data: IAddressSchemaFieldsData) {
        this.object = data.object;
        this.data = data.data;
    }
}
export class AddressSchemaFieldsErrorsModel {
    on_empty: string;

    constructor(data: IAddressSchemaFieldsErrors) {
        this.on_empty = data.on_empty;
    }
}

export class AddressSchemaFieldsPropertiesModel {
    is_editable: boolean;

    constructor(data: IAddressSchemaFieldsProperties) {
        this.is_editable = data.is_editable;
    }
}

export class AddressSchemaFieldsPropertyValueModel {
    type: string;
    place_holder: string;
    is_required: boolean;
    properties: AddressSchemaFieldsPropertiesModel;
    label: string;
    errors?: AddressSchemaFieldsErrorsModel;
    data_source?: string;
    resource?: string;
    data?: AddressSchemaFieldsDataModel;
    relation?: IAddressSchemaFieldsRelation;

    constructor(data: IAddressSchemaFieldsPropertyValue) {
        this.type = data.type;
        this.place_holder = data.place_holder;
        this.is_required = data.is_required;
        this.properties = new AddressSchemaFieldsPropertiesModel(
            data.properties,
        );
        this.label = data.label;
        this.errors = data.errors
            ? new AddressSchemaFieldsErrorsModel(data.errors)
            : undefined;
        this.data_source = data.data_source;
        this.resource = data.resource;
        this.data = data.data
            ? new AddressSchemaFieldsDataModel(data.data)
            : undefined;
        this.relation = data.relation;
    }
}

export class AddressSchemaWebFullModel {
    [key: string]: string[];
}
export class AddressSchemaMobileModel {
    [key: string]: string[];
}

export class AddressSchemaFieldsModel {
    [key: string]: AddressSchemaFieldsPropertyValueModel;
}

export class AddressSchemaDataModel {
    web_full: AddressSchemaWebFullModel;
    mobile: AddressSchemaMobileModel;
    fields: AddressSchemaFieldsModel;

    constructor(data: IAddressSchemaData) {
        this.web_full = data.web_full;
        this.mobile = data.mobile;
        this.fields = data.fields;
    }

    getWebFull = () => this.web_full;
    getMobile = () => this.mobile;
    getFields = () => this.fields;
}

export class AddressSchemaModel {
    object: string;
    data: AddressSchemaDataModel;

    constructor(data: IAddressSchema) {
        this.object = data.object;
        this.data = new AddressSchemaDataModel(data.data);
    }

    getData = () => this.data;
}
