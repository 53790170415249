import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import {
    IExchangeListPostRequest,
    IExchangeStoreUpdatePayload,
    IIndividualStoreExchangeUpdate,
    IRegionalExchangePreview,
    IStoreExchangePreview,
} from "@/types/exchangeListCollection";
import QueryString from "qs";

class ExchangeResource extends BaseResource {
    path = `/api/store/admin/exchange/v1/exchanges`;
    updatePath = `/api/store/admin/exchange/v1/update`;

    get(
        params: Record<string, any>,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}?${QueryString.stringify(params)}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
    getById(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    create(
        payload: IExchangeListPostRequest,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    copy(
        id: number,
        payload: { name: string },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
    delete(
        id: number,
        payload: Record<string, any> = {},
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("DELETE", path, payload, {}, customHeaders);
    }
    update(
        id: number,
        data: IExchangeStoreUpdatePayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, data, {}, customHeaders);
    }

    updateIndividualStoreExchange(
        data: IIndividualStoreExchangeUpdate,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.updatePath}`;
        return this.client.request("POST", path, data, {}, customHeaders);
    }
    defaultAndSync(
        data: IRegionalExchangePreview,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/store/admin/exchange/v1/update/default-and-sync`;
        return this.client.request("POST", path, data, {}, customHeaders);
    }

    preview(
        payload: IRegionalExchangePreview,
    ): ResponsePromise<IStoreExchangePreview> {
        const path = `/api/store/admin/exchange/v1/update/preview`;
        return this.client.request("POST", path, payload, {}, {});
    }
}

export default ExchangeResource;
