export enum AddonForEnum {
    BUY_AND_SHIP = "buy-and-ship",
    SHIP_FOR_ME = "ship-for-me",
    ALL = "all",
}

export enum DependsOnEnum {
    SINGLE_BUY_PRODUCT = "single-buy-product",
    SINGLE_BUY_ORDER = "single-buy-order",
    BUY_PRODUCT_EACH_VARIANT = "buy-product-each-variant",
    BUY_PRODUCT_EACH_QUANTITY = "buy-product-each-quantity",
    SINGLE_SHIPMENT_PRODUCT = "single-shipment-product",
    SINGLE_SHIPMENT_ORDER = "single-shipment-order",
    SHIPMENT_PRODUCT_PER_KG_WEIGHT = "shipment-product-per-kg-weight",
    SHIPMENT_PRODUCT_EACH_QUANTITY = "shipment-product-total-weight",
}
