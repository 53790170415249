import { ITranslationEntry } from "../lang";

export const DE: ITranslationEntry = {
    translations: {
        notifications: "Benachrichtigungen",
        markAllAsRead: "Alle als gelesen markieren",
        poweredBy: "von",
        settings: "Einstellungen",
        noNewNotification: "Hier gibt es noch nichts Neues zu sehen",
    },
    lang: "de",
};
