import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { ILanguageUpdateRequest } from "@/types/language";

class LanguageResource extends BaseResource {
    path = `/api/core/admin/language/v1/languages`;
    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    create(
        payload: ILanguageUpdateRequest,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    update(
        id: number,
        payload: ILanguageUpdateRequest,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }
}

export default LanguageResource;
