import { AcceptInventoryProductsQueryKeys } from "@/consts/appConstants";
import { FacetFieldModel } from "@/models/ProductSearchCollectionModel";

export interface IFacetCheckboxProps {
    facetFields: FacetFieldModel[] | undefined;
    payloadKey: AcceptInventoryProductsQueryKeys;
}

export enum PageOperationEnum {
    ADD_CAMPAIGN_PRODUCT = "add-campaign-product",
    VIEW_CAMPAIGN_PRODUCT = "view-campaign-product",
    AddBuyOrderProductVariant = "add-buy-order-product-variant",
    AddBuyOrderProduct = "add-buy-order-product",
    DeleteBuyOrderProduct = "delete-buy-order-product",
    PlaceBuyOrder = "place-buy-order",
}

export enum IsSelectablePageOperatorEnum {
    ADD_CAMPAIGN_PRODUCT = "add-campaign-product",
    VIEW_CAMPAIGN_PRODUCT = "view-campaign-product",
    AddBuyOrderProductVariant = "add-buy-order-product-variant",
    // AddBuyOrderProduct = "add-buy-order-product",
}
