import { ColumnsType } from "antd/es/table";
import { Typography, Tooltip, Tag, Button } from "antd";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { formatDateTime } from "@/utils/helpers";
import {
    IShippingCartExtended,
    IShippingCartItemPackage,
} from "@/types/shippingCartCollection";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import { ShippingCartItemPackageModel } from "@/models/shippingCartCollectionModel";

const { Text, Paragraph } = Typography;

export const getShippingCartColumns = (
    paginationData: any,
    setSelectedPackage?: (
        packages: ShippingCartItemPackageModel[] | undefined,
    ) => void,
): ColumnsType<IShippingCartExtended> => [
    {
        title: "#SL",
        dataIndex: "id",
        key: "sl",
        align: "center" as const,
        render: (_: unknown, __: IShippingCartExtended, index: number) => {
            return (
                <div>
                    {paginationData
                        ? (paginationData.current_page - 1) *
                              paginationData.per_page +
                          index +
                          1
                        : ""}
                </div>
            );
        },
    },
    {
        title: "User Info",
        dataIndex: "user",
        key: "user",
        render: (_, record: IShippingCartExtended) => (
            <>
                {record.user?.name && (
                    <Paragraph>
                        <Text>Name: </Text>
                        <Text strong copyable>
                            {record.user.name}
                        </Text>
                    </Paragraph>
                )}
                {record.user?.email && (
                    <Paragraph>
                        <Text>Email: </Text>
                        <Text strong copyable>
                            {record.user.email}
                        </Text>
                    </Paragraph>
                )}
                {record.user?.phone && (
                    <Paragraph>
                        <Text>Phone: </Text>
                        <Text strong copyable>
                            {record.user.phone}
                        </Text>
                    </Paragraph>
                )}
                {record.created_at && (
                    <Paragraph>
                        <Text>Created At: </Text>
                        <Tag color="purple">
                            {formatDateTime(record.created_at)}
                        </Tag>
                    </Paragraph>
                )}
            </>
        ),
    },
    {
        title: "Title",
        dataIndex: "title",
        key: "title",
        render: (title: string, record: IShippingCartExtended) => {
            return (
                <div style={{ display: "flex", gap: "16px" }}>
                    <ImageWithPlaceholder
                        style={{
                            width: 120,
                            maxHeight: 150,
                        }}
                        src={record.images[0]}
                    />
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                            width: 200,
                        }}
                    >
                        <Tooltip placement="topLeft" title={record.title}>
                            <Typography.Text
                                style={{
                                    width: 200,
                                }}
                                ellipsis
                            >
                                <a href={record.link}>{title}</a>
                            </Typography.Text>
                        </Tooltip>
                        <Paragraph>
                            <Text strong>Shipping Type:</Text>{" "}
                            <Tag
                                style={{
                                    textTransform: "capitalize",
                                    background: "#ff9100",
                                    color: "white",
                                    borderRadius: 10,
                                }}
                            >
                                {record.shipping_type}
                            </Tag>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Shipping Mode:</Text>{" "}
                            <Tag
                                style={{
                                    textTransform: "capitalize",
                                    background: "#ff9100",
                                    color: "white",
                                    borderRadius: 10,
                                }}
                            >
                                {record.shipping_mode}
                            </Tag>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Shipment Instruction:</Text>{" "}
                            <Text>{record.shipment_instruction || "None"}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Source:</Text>{" "}
                            <Text
                                style={{
                                    textTransform: "capitalize",
                                }}
                            >
                                {record.product_source}
                            </Text>
                        </Paragraph>
                    </div>
                </div>
            );
        },
    },
    {
        title: "Shipping Address",
        dataIndex: "shipping_address",
        key: "shipping_address",
        render: (address: Record<string, any>) => {
            const displayAddress = address?.display_address;

            if (!displayAddress) {
                return <div>No Address Provided</div>;
            }

            // Define the keys and their display labels for display address
            const displayKeys = {
                street_address: "Street Address",
                city: "City",
                state: "State",
                country: "Country",
                district: "District",
                address_1: "Address 1",
                address_2: "Address 2",
                postal_code: "Postal Code",
                first_name: "First Name",
                last_name: "Last Name",
                phone: "Phone",
                email: "Email",
            };

            // Divide the keys into two columns
            const keysArray = Object.entries(displayKeys);
            const halfLength = Math.ceil(keysArray.length / 2);
            const firstColumn = keysArray.slice(0, halfLength);
            const secondColumn = keysArray.slice(halfLength);

            return (
                <div
                    style={{
                        display: "flex",
                        gap: "20px",
                        flexWrap: "wrap",
                    }}
                >
                    {/* First Column */}
                    <div style={{ flex: 1 }}>
                        {firstColumn.map(([key, label]) => {
                            const value = displayAddress[key] ?? "N/A"; // Default to "N/A" if value is null or undefined
                            return (
                                <div key={key}>
                                    <strong>{label}:</strong> {value}
                                </div>
                            );
                        })}
                    </div>

                    {/* Second Column */}
                    <div style={{ flex: 1 }}>
                        {secondColumn.map(([key, label]) => {
                            const value = displayAddress[key] ?? "N/A"; // Default to "N/A" if value is null or undefined
                            return (
                                <div key={key}>
                                    <strong>{label}:</strong> {value}
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        },
    },
    ...(setSelectedPackage
        ? [
              {
                  title: "Packages",
                  dataIndex: ["packages", "data"],
                  key: "packages",
                  render: (packages: IShippingCartItemPackage[]) => {
                      return checkActionPermission(
                          ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
                          <Button onClick={() => setSelectedPackage(packages)}>
                              View Package Details
                          </Button>,
                          null,
                      );
                  },
              },
          ]
        : []),
];
