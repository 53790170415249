import {
    Button,
    Dropdown,
    Flex,
    Modal,
    Table,
    Typography,
    Alert,
    Popover,
    Space,
    Row,
    Col,
    notification,
} from "antd";
import type { TableColumnsType } from "antd";
import {
    BuyProductModel,
    BuyProductVariationsModel,
} from "../../../models/buyProductCollectionModel";
import { tw } from "@/consts/theme/tailwindTheme";
import { memo, useEffect, useState } from "react";
import {
    DeleteTwoTone,
    EditTwoTone,
    HddFilled,
    PlusOutlined,
    RightOutlined,
} from "@ant-design/icons";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { BuyOrderOperationEnum } from "@/enums/buyOrderCollectionEnums";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import { Link, useParams } from "react-router-dom";
import UpdateVariationForm from "./UpdateVariationForm";
import "./BuyProductVariationsTable.css";
import BuyProductCharges from "../BuyProducts/components/BuyProductCharges";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { getFulfillmentStatusColor } from "@/utils/helpers";
import { parseObjectToString } from "@/helpers/stringify";
import getPagePathname from "@/helpers/getPagePathname";
import {
    BUY_ORDER_DETAIL_PAGE,
    BUY_ORDER_LIST_PAGE,
    BUY_PRODUCT_DETAIL_PAGE,
    BUY_PRODUCT_LIST_PAGE,
} from "@/consts/pageRoute";
import { useCancelVariationsAndCharges } from "@/lib/core-react/hooks/private/usePurchase";
import { cancelVariationsAndChargesTypeEnum } from "@/types/buyProductCollection";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { showError } from "@/helpers/showError";
import useRefetch from "@/hooks/useRefetch";
import RefundForm from "./RefundForm";
import { StatusTag } from "@/components";
import { ADMIN_REFUND_PERMISSION_ENUM } from "@/consts/permission-enum/refund-enum";
import Separator from "@/components/Separator/Separator";
interface Props {
    buyProduct: BuyProductModel;
    isInsideModal?: boolean;
    isListPage: boolean;
    isBuyOrderListPage?: boolean;
    title?: string;
}

const TEXT_SIZE = "12px";

const BuyProductVariationsTable = ({ buyProduct, isListPage }: Props) => {
    const { Paragraph, Text } = Typography;
    const [variations, setVariations] = useState<BuyProductVariationsModel[]>(
        [],
    );
    const { refetchDetailApi } = useRefetch();
    const { id } = useParams();
    const [isExpand, setIsExpand] = useState(false);
    const { cancelVariationsCharges } = useCancelVariationsAndCharges();
    const [selectedRows, setSelectedRows] = useState<
        BuyProductVariationsModel[]
    >([]);
    const [isBulkRefundModalOpen, setIsBulkRefundModalOpen] = useState(false);
    // ---------------------------logic---------------------------------
    useEffect(() => {
        if (buyProduct.getProductVariations().length > 2) {
            if (!isExpand) {
                setVariations(buyProduct.getProductVariations().slice(0, 3));
            } else {
                setVariations(buyProduct.getProductVariations());
            }
        } else {
            setVariations(buyProduct.getProductVariations());
        }
    }, [isExpand, buyProduct]);

    const [variant, setVariant] = useState<
        BuyProductVariationsModel | undefined
    >(undefined);

    const handleVariant = async (
        action: "update" | "delete" | "close_modal",
        data: BuyProductVariationsModel | undefined,
    ) => {
        switch (action) {
            case "update":
                setVariant(data);
                break;
            case "close_modal":
                setVariant(undefined);
                break;

            default:
                break;
        }
    };

    const handleCancelVariant = async (
        buyProductId: number,
        variantId: number,
    ) => {
        Modal.confirm({
            title: "Are you sure you want to cancel this product?",
            content: "Select Ok, otherwise the process cannot success.",
            onOk: async () => {
                try {
                    await cancelVariationsCharges(buyProductId, {
                        type: cancelVariationsAndChargesTypeEnum.SPECIFIC_VARIATIONS,
                        variation_ids: [variantId],
                        charge_ids: [],
                    });
                    showSuccessAlert("Successfully Cancelled Variation");

                    refetchDetailApi(buyProductId);
                    return Promise.resolve();
                } catch (error) {
                    showError(error);
                    return Promise.reject();
                }
            },
            onCancel() {
                notification["info"]({
                    message: "Product cancellation was aborted.",
                });
            },
        });
    };

    // ----------------------------------ui-------------------------

    const onHeaderCellStyle = () => {
        return {
            style: {
                padding: tw.spacing[1],
            },
        };
    };

    const getActions = (variant: BuyProductVariationsModel) => {
        const items = [
            {
                key: "update",
                permission:
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
                label: "Update Variation",
                disabled: buyProduct.isNotEditable(),
                onClick: () => handleVariant("update", variant),
                icon: <EditTwoTone />,
            },
            {
                icon: <DeleteTwoTone />,
                key: "Cancel",
                permission:
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_DELETE,
                disabled: variant.getFulfillmentStatus() === "cancelled",
                label: "Cancel Variation",
                onClick: () =>
                    handleCancelVariant(buyProduct.getId(), variant.getId()),
            },
        ];

        return items?.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };
    const columns: TableColumnsType<BuyProductVariationsModel> = [
        {
            title: <Text style={{ fontSize: TEXT_SIZE }}>Meta</Text>,
            key: "meta",
            width: "250px", // Adjust based on your layout
            render: (_, variant) => (
                <div style={{ position: "relative", paddingRight: "50px" }}>
                    {/* Image and Properties */}
                    <Flex gap={4} align="flex-start">
                        <div style={{ marginTop: "10px" }}>
                            <ImageWithPlaceholder
                                style={{ width: 42, height: 42 }}
                                src={`${variant.getPropertyImageUrl()}`}
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            {variant.getProperties().map((singleProperty) => {
                                return (
                                    <div>
                                        <Typography key={singleProperty.id}>
                                            <Paragraph style={{ margin: 0 }}>
                                                <Text
                                                    style={{ paddingRight: 8 }}
                                                >
                                                    {singleProperty.getPropertyName()}
                                                    :{" "}
                                                </Text>

                                                <Text
                                                    strong
                                                    ellipsis={{
                                                        tooltip:
                                                            singleProperty.getPropertyValueName(),
                                                    }}
                                                    style={{
                                                        maxWidth: "120px",
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                            "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        fontSize: TEXT_SIZE,
                                                    }}
                                                >
                                                    {singleProperty.getPropertyValueName()}
                                                </Text>
                                            </Paragraph>
                                        </Typography>
                                        <Separator />

                                        <Typography key={singleProperty.id}>
                                            <Paragraph style={{ margin: 0 }}>
                                                <Text
                                                    style={{ paddingRight: 8 }}
                                                >
                                                    {singleProperty.getPropertyOriginalName()}
                                                    :{" "}
                                                </Text>

                                                <Text
                                                    strong
                                                    ellipsis={{
                                                        tooltip:
                                                            singleProperty.getPropertyValueOriginalName(),
                                                    }}
                                                    style={{
                                                        maxWidth: "100px",
                                                        display: "inline-block",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                            "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        fontSize: TEXT_SIZE,
                                                    }}
                                                >
                                                    {singleProperty.getPropertyValueOriginalName()}
                                                </Text>
                                            </Paragraph>
                                        </Typography>
                                    </div>
                                );
                            })}
                        </div>
                    </Flex>

                    {/* Fulfillment Status positioned in the top-right */}

                    <div
                        style={{
                            position: "absolute",
                            top: "-9px",
                            left: "-15px",
                            fontSize: "10px", // Small text size
                            padding: "2px 8px",
                        }}
                    >
                        <div
                            title={variant.getFulfillmentStatus()}
                            style={{
                                width: "14px",
                                height: "14px",
                                borderRadius: "50%",
                                background: getFulfillmentStatusColor(
                                    variant.getFulfillmentStatus(),
                                ),
                            }}
                        ></div>
                    </div>
                </div>
            ),
        },
        ...(!isListPage
            ? [
                  {
                      title: (
                          <Text style={{ fontSize: TEXT_SIZE }}>Status</Text>
                      ),
                      width: "70px",
                      key: "Status",
                      align: "center" as any,
                      onHeaderCell: onHeaderCellStyle,
                      render(_, variant: BuyProductVariationsModel) {
                          return (
                              <Typography>
                                  <Paragraph style={{ margin: tw.spacing[0] }}>
                                      <Text style={{ fontSize: TEXT_SIZE }}>
                                          <StatusTag
                                              slug={variant.getFulfillmentStatus()}
                                              text={variant.getFulfillmentStatus()}
                                          />
                                      </Text>
                                  </Paragraph>
                              </Typography>
                          );
                      },
                  },
              ]
            : []),
        {
            title: <Text style={{ fontSize: TEXT_SIZE }}>Quantity</Text>,
            width: "70px",
            key: "Quantity",
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render(_, variant: BuyProductVariationsModel) {
                return (
                    <Typography>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text style={{ fontSize: TEXT_SIZE }}>
                                {variant.getQuantity()}
                            </Text>
                        </Paragraph>
                    </Typography>
                );
            },
        },
        {
            title: <Text style={{ fontSize: TEXT_SIZE }}>Price</Text>,
            key: "unit_price",
            width: "300px",
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render(variant: BuyProductVariationsModel) {
                return (
                    <>
                        <Space direction="vertical" size="small">
                            <Row align="middle" gutter={4}>
                                <Col>
                                    <Popover content="Regional Price">
                                        <Text>R:</Text>
                                    </Popover>
                                </Col>
                                <Col>
                                    <Popover content="Regional Unit Price">
                                        <Text
                                            style={{ fontSize: TEXT_SIZE }}
                                            strong
                                        >
                                            {roundToTwoDecimal(
                                                variant.getUnitPrice(),
                                            )}
                                        </Text>
                                    </Popover>
                                </Col>
                                <Col>
                                    <Text
                                        style={{ fontSize: TEXT_SIZE }}
                                        strong
                                    >
                                        {buyProduct.getRegionCurrencyCode()}
                                    </Text>
                                </Col>
                                <Col>/</Col>
                                <Col>
                                    <Popover content="Regional Total Price">
                                        <Text
                                            style={{ fontSize: TEXT_SIZE }}
                                            strong
                                        >
                                            {roundToTwoDecimal(
                                                variant.getTotalPrice(),
                                            )}
                                        </Text>
                                    </Popover>
                                </Col>
                                <Col>
                                    <Text
                                        style={{ fontSize: TEXT_SIZE }}
                                        strong
                                    >
                                        {buyProduct.getRegionCurrencyCode()}
                                    </Text>
                                </Col>
                            </Row>

                            <Row align="middle" gutter={4}>
                                <Col>
                                    <Popover content="Original Price">
                                        <Text>O:</Text>
                                    </Popover>
                                </Col>
                                <Col>
                                    <Popover content="Original Total Unit Price">
                                        <Text
                                            style={{ fontSize: TEXT_SIZE }}
                                            strong
                                        >
                                            {roundToTwoDecimal(
                                                variant.getOriginalUnitPrice(),
                                            )}
                                        </Text>
                                    </Popover>
                                </Col>
                                <Col>
                                    <Text
                                        style={{ fontSize: TEXT_SIZE }}
                                        strong
                                    >
                                        {buyProduct.getOriginalCurrencyCode()}
                                    </Text>
                                </Col>
                                <Col>/</Col>
                                <Col>
                                    <Popover content="Original Total Price">
                                        <Text
                                            style={{ fontSize: TEXT_SIZE }}
                                            strong
                                        >
                                            {roundToTwoDecimal(
                                                variant.getOriginalTotalPrice(),
                                            )}
                                        </Text>
                                    </Popover>
                                </Col>
                                <Col>
                                    <Text
                                        style={{ fontSize: TEXT_SIZE }}
                                        strong
                                    >
                                        {buyProduct.getOriginalCurrencyCode()}
                                    </Text>
                                </Col>
                            </Row>
                        </Space>
                    </>
                );
            },
        },
        {
            width: "100px",
            title: <Text style={{ fontSize: TEXT_SIZE }}>A</Text>,
            key: "actions",
            align: "center",

            onHeaderCell: onHeaderCellStyle,
            hidden:
                buyProduct.isNotEditable() ||
                !checkActionPermission(
                    [
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_UPDATE,
                    ],
                    "checkPermission",
                    null,
                ) ||
                getPagePathname() === `${BUY_PRODUCT_LIST_PAGE}` ||
                getPagePathname() === `${BUY_ORDER_LIST_PAGE}`,

            render(_, variant: BuyProductVariationsModel) {
                return (
                    <>
                        <Dropdown
                            menu={{
                                items: getActions(variant),
                            }}
                        >
                            <Button type="primary" icon={<HddFilled />} />
                        </Dropdown>
                    </>
                );
            },
        },
    ];
    // if (!isListPage) {
    //     //  add a action column to the table
    //     columns.push({
    //         title: "Action",
    //         key: "action",
    //         align: "center",
    //         width: "100px",
    //         render: (_, variant: BuyProductVariationsModel) => {
    //             const isRefundDisabled =
    //                 variant.getFulfillmentStatus() === "refunded";
    //             return (
    //                 <>
    //                     <Button
    //                         type={isRefundDisabled ? "default" : "primary"}
    //                         onClick={() => setIsRefundModalOpen(true)}
    //                         disabled={isRefundDisabled}
    //                     >
    //                         {isRefundDisabled ? "Refunded" : "Refund"}
    //                     </Button>
    //                     <RefundForm
    //                         isOpen={isRefundModalOpen}
    //                         onClose={() => setIsRefundModalOpen(false)}
    //                         variationId={variant.getId()}
    //                         buyProductId={buyProduct.getId()}
    //                         // onSubmit={handleRefundSubmit}
    //                     />
    //                 </>
    //             );
    //         },
    //     });
    // }

    const addVariationsQuery = {
        productId: buyProduct.getProductId(),
        regionId: buyProduct.getRegion()?.getId(),
        region: buyProduct.getRegion()?.getCode(),
        buyProductId: buyProduct.getId(),
        userId: buyProduct.getUserId(),
        buyOrderId: buyProduct.getOrder()?.getId(),
        operation: BuyOrderOperationEnum.AddBuyOrderProductVariant,
    };
    return (
        <Flex vertical gap={20}>
            {/*------------------------ Variations ---------------------- */}
            <Table
                rowSelection={
                    isListPage
                        ? {
                              selectedRowKeys: selectedRows.map((x) =>
                                  x.getId(),
                              ),
                              onChange: (_, selectedRows) => {
                                  setSelectedRows(selectedRows);
                              },
                          }
                        : undefined
                }
                title={() => {
                    return getPagePathname() ===
                        `${BUY_PRODUCT_DETAIL_PAGE}/${id}` ||
                        getPagePathname() ===
                            `${BUY_ORDER_DETAIL_PAGE}/${id}` ? (
                        <Flex align="center" justify="space-between">
                            <Typography.Paragraph strong>
                                Variations
                            </Typography.Paragraph>

                            <Flex>
                                {checkActionPermission(
                                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VARIATION_ADD,
                                    <Button
                                        disabled={
                                            buyProduct.isNotEditable() ||
                                            buyProduct.getSourceType() ===
                                                "CustomOrder"
                                        }
                                        hidden={isListPage}
                                        type="link"
                                        icon={<PlusOutlined />}
                                    >
                                        <Link
                                            to={`/service/inventory/product-detail?${parseObjectToString(addVariationsQuery)}`}
                                        >
                                            Add
                                        </Link>
                                    </Button>,

                                    null,
                                )}

                                {buyProduct.getStatus() ===
                                    "delivered-to-customer" && (
                                    <>
                                        {checkActionPermission(
                                            ADMIN_REFUND_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_DISPUTE_CREATE,
                                            <Button
                                                onClick={() =>
                                                    setIsBulkRefundModalOpen(
                                                        true,
                                                    )
                                                }
                                                type="primary"
                                            >
                                                Create Refund
                                            </Button>,
                                            null,
                                        )}
                                    </>
                                )}
                            </Flex>
                        </Flex>
                    ) : (
                        <></>
                    );
                }}
                columns={columns}
                dataSource={variations}
                rowKey={(r) => r.getId()}
                //loading={isDeleteLoading}
                pagination={false}
                bordered
                size="small"
                rowHoverable={false}
            />

            <Flex>
                {buyProduct.getProductVariations().length > 3 ? (
                    <Button
                        style={{
                            border: tw.borderWidth[0],
                            marginTop: tw.spacing[2],
                        }}
                        onClick={() => setIsExpand(!isExpand)}
                    >
                        <RightOutlined
                            style={{
                                transform: `rotate(-${tw.rotate[isExpand ? 90 : 0]})`,
                                transition: tw.transitionProperty.transform,
                                transitionDuration: tw.transitionDelay[300],
                            }}
                        />
                        See {!isExpand ? "more" : "less"}
                    </Button>
                ) : (
                    <></>
                )}
            </Flex>

            {/*------------------------ charges ---------------------- */}
            {buyProduct.getCharges() && buyProduct.getCharges()?.length ? (
                <BuyProductCharges
                    isListPage={isListPage}
                    buyProductId={buyProduct.getId()}
                    originalCurrencyCode={buyProduct.getOriginalCurrencyCode()}
                    regionCurrencyCode={buyProduct.getRegionCurrencyCode()}
                    buyProductCharges={buyProduct.getCharges()}
                />
            ) : (
                <></>
            )}
            {/*------------------------ summary---------------------- */}

            {variations.length > 0 && (
                <Table
                    columns={[
                        {
                            title: (
                                <Text style={{ fontSize: TEXT_SIZE }} strong>
                                    Total Quantity
                                </Text>
                            ),
                            align: "center",
                            dataIndex: "totalQuantity",

                            key: "totalQuantity",
                            render(value) {
                                return (
                                    <Text
                                        style={{ fontSize: TEXT_SIZE }}
                                        strong
                                    >
                                        {value}
                                    </Text>
                                );
                            },
                        },
                        {
                            title: (
                                <Text style={{ fontSize: TEXT_SIZE }} strong>
                                    Total Regional
                                </Text>
                            ),
                            align: "center",
                            dataIndex: "totalReginal",
                            key: "totalReginal",
                            render(value) {
                                return (
                                    <Flex gap={2} justify="center">
                                        <Text
                                            style={{ fontSize: TEXT_SIZE }}
                                            strong
                                        >
                                            {value.toFixed(2)}
                                        </Text>
                                        <Text
                                            style={{ fontSize: TEXT_SIZE }}
                                            strong
                                        >
                                            {buyProduct.getRegionCurrencyCode()}
                                        </Text>
                                    </Flex>
                                );
                            },
                        },
                        {
                            title: (
                                <Text style={{ fontSize: TEXT_SIZE }} strong>
                                    Total Original
                                </Text>
                            ),
                            align: "center",
                            dataIndex: "totalOriginal",

                            key: "totalOriginal",
                            render(value) {
                                return (
                                    <Flex gap={2} justify="center">
                                        <Text
                                            style={{ fontSize: TEXT_SIZE }}
                                            strong
                                        >
                                            {value.toFixed(2)}
                                        </Text>
                                        <Text
                                            style={{ fontSize: TEXT_SIZE }}
                                            strong
                                        >
                                            {buyProduct.getOriginalCurrencyCode()}
                                        </Text>
                                    </Flex>
                                );
                            },
                        },
                    ]}
                    dataSource={[
                        {
                            id: 1,
                            totalQuantity:
                                buyProduct.getTotalQuantityCalculated(),
                            totalReginal:
                                buyProduct.getTotalRegionalPriceCalculated(),
                            totalOriginal:
                                buyProduct.getTotalOriginalPriceCalculated(),
                        },
                    ]}
                    rowKey={"id"}
                    pagination={false}
                    bordered
                    size="small"
                    rowHoverable={false}
                    scroll={{ x: 400 }}
                />
            )}
            <Modal
                centered
                title="Update Variation"
                open={!!variant}
                onCancel={() => setVariant(undefined)}
                destroyOnClose={false}
                footer={false}
            >
                {variant && buyProduct ? (
                    <UpdateVariationForm
                        handleVariant={handleVariant}
                        buyProductId={buyProduct.getId()}
                        currencyCode={
                            buyProduct.getOriginalCurrencyCode() || ""
                        }
                        variant={variant}
                    />
                ) : (
                    <Alert message="Variant not found" type="info" />
                )}
            </Modal>
            {
                <RefundForm
                    isOpen={isBulkRefundModalOpen}
                    onClose={() => setIsBulkRefundModalOpen(false)}
                    buyProductId={buyProduct.getId()}
                    isBulkRefund={true}
                    variations={// pass the variations to the refund form which are not refunded
                    buyProduct
                        .getProductVariations()
                        ?.filter(
                            (variation) =>
                                variation.getFulfillmentStatus() !== "refunded",
                        )}
                />
            }
        </Flex>
    );
};

export default memo(BuyProductVariationsTable);
