import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Button, Col, Drawer, Form, Row, Skeleton, Space, Input } from "antd";
import "./style.css";

import {
    useGetPermissionsByRole,
    useUpdateRole,
} from "@/lib/core-react/hooks/private/useRole";

import {
    PermissionCollectionModel,
    PermissionModel,
} from "@/models/permissionCollectionModel";
import { PermissionGroup } from "./PermissionGroup";
import {
    RoleModel,
    RoleUpdateRequestModel,
} from "@/models/roleCollectionModel";
import { v4 } from "uuid";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_ACL_PERMISSION_ENUM } from "@/consts/permission-enum/ACL-enum";
import { showError } from "@/helpers/showError";

interface IProps {
    show: boolean;
    onHide: () => void;
    allPermissions: PermissionCollectionModel;
    role: RoleModel;
}

export type RegionPermissions = {
    [region: string]: PermissionModel[];
};

// Define the group-level permissions
export type PermissionGroups = {
    [group: string]: RegionPermissions;
};

const PermissionsDrawer = ({ show, onHide, allPermissions, role }: IProps) => {
    const [form] = Form.useForm();
    const { getPermissionsByRole, isLoading } = useGetPermissionsByRole();
    const { updateRole, isLoading: updateLoading } = useUpdateRole();
    const [groupedPermissions, setGroupedPermissions] =
        useState<PermissionGroups>({});
    const [activeChildKey, setActiveChildKey] = useState<string[]>([]);

    const [_groupedPermissionIdsWithStatus, setGroupedPermissionIdsWithStatus] =
        useState<{
            [group: string]: {
                [region: string]: { id: number; isChecked: boolean }[];
            }[];
        }>({});

    const [selectedPermissionIds, setSelectedPermissionIds] = useState<
        number[]
    >([]);

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const permissionsResponse = await getPermissionsByRole(role.id);
                const permissions = allPermissions.data;
                const selectedIds =
                    permissionsResponse.selected_permission_ids || [];

                // Group the permissions by group_name and region_group
                const permissionStatusByIds: {
                    [group: string]: {
                        [region: string]: { id: number; isChecked: boolean }[];
                    }[];
                } = {};

                const grouped: PermissionGroups = {};
                permissions.forEach((permission) => {
                    const { group_name, region_group } = permission;
                    const region = region_group || "general"; // Default to "General" if no region_group

                    // Initialize the group if it doesn't exist
                    if (!grouped[group_name]) {
                        grouped[group_name] = {};
                    }

                    // Initialize the region if it doesn't exist
                    if (!grouped[group_name][region]) {
                        grouped[group_name][region] = [];
                    }

                    // Add the permission to the appropriate group and region
                    grouped[group_name][region].push(permission);
                });

                permissions.forEach((permission) => {
                    const { group_name, region_group, id } = permission;
                    const region = region_group || "general";

                    // Initialize group and region if they don't exist
                    // TODO->Wil be fixed type error as soon as possible
                    // @ts-ignore
                    if (!permissionStatusByIds[group_name])
                        permissionStatusByIds[group_name] = {};
                    if (!permissionStatusByIds[group_name][region])
                        permissionStatusByIds[group_name][region] = [];

                    // Add permission with its checked status based on selectedIds
                    permissionStatusByIds[group_name][region].push({
                        id,
                        isChecked: selectedIds.includes(id),
                    });
                });

                // Update the state with the grouped permissions and selected IDs
                setGroupedPermissions(grouped);
                setGroupedPermissionIdsWithStatus(permissionStatusByIds);
                setSelectedPermissionIds(selectedIds);
            } catch (error) {
                showError(error);
            }
        };

        fetchData();
    }, [role.id, allPermissions]);

    const updateFinalPermissions = useCallback(
        (group: string, regionGroup: string, data: number[]) => {
            setGroupedPermissionIdsWithStatus((prevGroupedPermissionIds) => {
                // Ensure the group exists; initialize if undefined
                const groupPermissions = prevGroupedPermissionIds[group] || {};

                // Ensure the region exists within the group; initialize if undefined
                const regionPermissions = groupPermissions[regionGroup] || [];

                // Update the region's permissions by mapping over existing data
                const latestRegionPermissions = regionPermissions.map(
                    (perm) => ({
                        ...perm,
                        isChecked: data.includes(perm.id),
                    }),
                );

                // Maintain the other regions and update only the target region
                const updatedGroupPermissions = {
                    ...groupPermissions,
                    [regionGroup]: latestRegionPermissions,
                };

                // Update the grouped permissions state immutably
                const updatedGroupedPermissions = {
                    ...prevGroupedPermissionIds,
                    [group]: updatedGroupPermissions,
                };

                // Collect all checked IDs across all groups and regions
                const checkedIds = Object.values(
                    updatedGroupedPermissions,
                ).flatMap((regions) =>
                    Object.values(regions).flatMap((permissions) =>
                        permissions
                            .filter((perm) => perm.isChecked)
                            .map((perm) => perm.id),
                    ),
                );

                // Update the selected permission IDs state
                setSelectedPermissionIds(checkedIds);

                return updatedGroupedPermissions;
            });
        },
        [],
    );

    const handleSubmit = async () => {
        const payload: RoleUpdateRequestModel = {
            name: role.name,
            label: role.label,
            permission_ids: selectedPermissionIds ? selectedPermissionIds : [],
        };

        await updateRole(role.id, payload);
        onHide();
    };

    const handleSearch = (term: string) => {
        setSearchTerm(term);
    };

    const filteredPermissions = useMemo(() => {
        return Object.entries(groupedPermissions).reduce(
            (result, [groupName, regions]) => {
                const groupMatch = groupName
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());

                const regionMatch = Object.values(regions).some((permissions) =>
                    permissions.some((permission) =>
                        permission.label
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase()),
                    ),
                );

                if (groupMatch || regionMatch) {
                    result.push([groupName, regions]);
                }

                return result;
            },
            [] as [string, RegionPermissions][],
        );
    }, [groupedPermissions, searchTerm]);

    const getRegionalSelectItems = (
        region: string,
        group: string,
    ): { every: boolean; some: boolean } => {
        const regions = filteredPermissions.find((x) => x[0] === group);

        const regionalPermissions = (regions && regions[1][region]) || [];

        const every = regionalPermissions.every((x) =>
            selectedPermissionIds.includes(x.id),
        );
        const some = regionalPermissions.some((x) =>
            selectedPermissionIds.includes(x.id),
        );

        return {
            some: every ? false : some,
            every,
        };
    };

    return (
        <Drawer
            title={`Update Permission`}
            width={500}
            onClose={onHide}
            open={show}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
            extra={
                <Space>
                    <Button onClick={onHide} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>

                    {checkActionPermission(
                        ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_MANAGE,
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                            loading={updateLoading}
                        >
                            Update
                        </Button>,
                        null,
                    )}
                </Space>
            }
        >
            <Row>
                <Col span={24}>
                    <Form form={form} layout="vertical">
                        <Skeleton loading={isLoading} active>
                            <Row>
                                <Col span={19}>
                                    <Input.Search
                                        placeholder="Search permissions"
                                        onChange={(e) =>
                                            handleSearch(e.target.value)
                                        }
                                        style={{ marginBottom: 16 }}
                                    />
                                </Col>
                            </Row>

                            {filteredPermissions &&
                                filteredPermissions.map(
                                    ([groupName, permissions]) => (
                                        <Row key={v4()}>
                                            <div key={groupName}>
                                                <PermissionGroup
                                                    setActiveChildKey={
                                                        setActiveChildKey
                                                    }
                                                    activeChildKey={
                                                        activeChildKey
                                                    }
                                                    permission={[
                                                        groupName,
                                                        permissions,
                                                    ]}
                                                    checkedPermission={
                                                        selectedPermissionIds
                                                    }
                                                    updateFinalPermissions={
                                                        updateFinalPermissions
                                                    }
                                                    getRegionalSelectItems={
                                                        getRegionalSelectItems
                                                    }
                                                />
                                            </div>
                                        </Row>
                                    ),
                                )}
                        </Skeleton>
                    </Form>
                </Col>
            </Row>
        </Drawer>
    );
};

export default React.memo(PermissionsDrawer);
