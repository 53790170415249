export enum FieldType {
    Array = "array",
    DATE_RANGE = "daterange",
    NUMBER_RANGE = "number_range",
    EXTERNAL_ARRAY = "external_array",
    SELECT = "select",
    MULTI_SELECT = "multiselect",
    PRICE_RANGE = "pricerange",
    DEFAULT = "default",
}

export enum ResourceDataType {
    EXTERNAL = "external",
}
