import { useState } from "react";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

type Props = {
    product: ProductDetailExtendedModel;
};

const ProductImageGrid = ({ product }: Props) => {
    const [showAll, setShowAll] = useState(false);
    const images = product.getGallery();

    // Control the number of images to display
    const visibleImages = showAll ? images : images.slice(0, 4);

    return (
        <div>
            {/* Image Grid */}
            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))", // Responsive columns
                    gap: "10px", // Space between images
                }}
            >
                {visibleImages.map((item) => (
                    <div
                        key={item.id}
                        style={{
                            border: "1px solid #f0f0f0", // Optional: Add a border for better visuals
                            borderRadius: "8px", // Optional: Add border radius for rounded corners
                            padding: "3px",
                        }}
                    >
                        <ImageWithPlaceholder
                            src={item.getUrl()}
                            alt={item.getTitle()}
                            style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "300px", // Constrain image height
                                objectFit: "cover", // Ensure images fit nicely
                            }}
                        />
                    </div>
                ))}
            </div>

            {/* Toggle Button */}
            {images.length > 4 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end", // Align button to the right
                        marginTop: "10px",
                    }}
                >
                    <Button
                        onClick={() => setShowAll(!showAll)}
                        style={{
                            padding: "8px 16px",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                        }}
                        type="link"
                    >
                        {showAll ? (
                            <>
                                See Less <UpOutlined />
                            </>
                        ) : (
                            <>
                                See More <DownOutlined />
                            </>
                        )}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default ProductImageGrid;
