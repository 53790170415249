import { Checkbox, Form, Typography, Space } from "antd";
import { Flex } from "antd";
import { FormInstance } from "antd/lib";
import FacetCheckboxFormTitle from "./FacetCheckboxFormTitle";
import { ReactNode } from "react";

interface IFacetField {
    name: string;
    count: number;
}

interface IProps {
    label: string;
    facetFields: IFacetField[] | undefined;
    isLoading?: boolean;
    form: FormInstance;
    onChange?: (e: any, data: IFacetField) => void;
    children?: ReactNode;
}

const FacetCheckboxForm = ({
    label,
    facetFields,
    form,
    onChange,
    children,
    isLoading = false,
}: IProps) => {
    return (
        <Space direction="vertical" size="small">
            <FacetCheckboxFormTitle label={label} isLoading={isLoading} />
            <Form form={form}>
                {children ? (
                    children
                ) : (
                    <Flex vertical gap={8}>
                        {facetFields &&
                            facetFields.map((data) => (
                                <Form.Item
                                    key={data.name}
                                    noStyle
                                    name={data.name}
                                    valuePropName="checked"
                                >
                                    <Checkbox
                                        id={data.name}
                                        onChange={(e) =>
                                            onChange && onChange(e, data)
                                        }
                                        checked={form.getFieldValue(data.name)}
                                    >
                                        <Flex gap={6} align="center">
                                            <Typography.Paragraph
                                                style={{
                                                    maxWidth: 300,
                                                    textTransform: "capitalize",
                                                }}
                                                ellipsis={{
                                                    tooltip: data.name,
                                                }}
                                            >
                                                {data.name} ({data.count})
                                            </Typography.Paragraph>
                                        </Flex>
                                    </Checkbox>
                                </Form.Item>
                            ))}
                    </Flex>
                )}
            </Form>
        </Space>
    );
};

export default FacetCheckboxForm;
