import { useAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import { getError } from "../utils/errors";
import {
    DeliveryRequestCollectionAtom,
    DeliveryRequestDetailsAtom,
} from "../../store/store";

export const useDeliveryRequestList = () => {
    const [deliveryRequest, setDeliveryRequest] = useAtom(
        DeliveryRequestCollectionAtom,
    );

    const { deliveryRequestService } = useService();

    const getDeliveryRequests = async (params: string = ""): Promise<void> => {
        setDeliveryRequest({
            ...deliveryRequest,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await deliveryRequestService.deliveryRequestResource.getList(
                    params,
                );
            setDeliveryRequest({
                ...setDeliveryRequest,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setDeliveryRequest({
                ...setDeliveryRequest,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };

    return { getDeliveryRequests } as const;
};

export const useDeliveryRequestDetails = () => {
    const [deliveryRequestDetails, setDeliveryRequestDetails] = useAtom(
        DeliveryRequestDetailsAtom,
    );

    const { deliveryRequestService } = useService();

    const getDeliveryRequestsDetails = async (
        deliveryRequestId: number,
    ): Promise<void> => {
        setDeliveryRequestDetails({
            ...deliveryRequestDetails,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await deliveryRequestService.deliveryRequestResource.details(
                    deliveryRequestId,
                );
            setDeliveryRequestDetails({
                ...deliveryRequestDetails,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
        } catch (error: any) {
            setDeliveryRequestDetails({
                ...deliveryRequestDetails,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };

    return { getDeliveryRequestsDetails } as const;
};
