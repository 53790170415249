import React, { createRef, useEffect, useState } from "react";
import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    FormInstance,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Table,
    Typography,
} from "antd";
import {
    BuyProductModel,
    BuyProductVariationsModel,
    SequenceModel,
    SequencesDataModel,
    VariantModel,
} from "@/models/buyProductCollectionModel";
import ProductDetails from "./ProductDetails";
import { TakeActionsEnums } from "@/enums/takeActionsEnums";
import { driverOptions, ISSUE_MESSAGE } from "./TakeActionsOptions";
import { useBuyProductTakeActions } from "@/lib/core-react/hooks/private/usePurchase";
import { Forms } from "@/models/form";
import { AddonServiceCollectionModel } from "@/models/addonServiceCollectionModel";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { useAddonServicesList } from "@/lib/core-react/hooks/private/useAddonService";
import { useAtom } from "jotai";
import { addonServiceCollectionAtom } from "@/lib/core-react/store/store";
import { useGetShippingCategory } from "@/lib/core-react/hooks/private";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";
import { MultiChunkUpload } from "@/components/MultiChunkUpload";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IShippingRateType, SequenceSlot } from "@/types/buyOrderCollection";
import ShippingUpdateModal from "@/pages/BuyAndShip/components/ShippingUpdateModal";
import { IModalData } from "@/helpers/getModalTital";
import { showError } from "@/helpers/showError";
import useRefetch from "@/hooks/useRefetch";

interface IProps {
    handleModal: (payload: IModalData) => void;
    selectedProduct: BuyProductModel;
}

interface InputValues {
    [key: number]: string;
}

const TakeActionsTable: React.FC<IProps> = ({
    handleModal,
    selectedProduct,
}) => {
    const { Text } = Typography;

    const [form] = Form.useForm();
    const formRef: React.Ref<FormInstance<any>> = createRef();

    const [selectedAction, setSelectedAction] = useState<TakeActionsEnums[]>(
        [],
    );
    const [selectedService, setSelectedService] = useState<any[]>([]);
    const [showPrices, setShowPrices] = useState(false);
    const [charge, setCharge] = useState<
        { action: TakeActionsEnums; value: number }[]
    >([]);
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [updateData, setUpdateData] = useState<{
        customer_note: string;
        adminNote: string;
        data: any;
    }>({
        customer_note: "",
        adminNote: "",
        data: [],
    });
    const [inputValues, setInputValues] = useState<InputValues>({});
    const [total, setTotal] = useState(0);
    const [shippingRates, setShippingRates] = useState<SequenceSlot[]>([]);
    const [filteredOptions, setFilteredOptions] = useState(driverOptions);

    const [{ data: addonServiceCollectionData, refetch }] = useAtom(
        addonServiceCollectionAtom,
    );

    const { getShippingCategory } = useGetShippingCategory();
    const { getAddonServices } = useAddonServicesList();
    const { filters, isFirstCall, isFetched } = useDataFilters();
    const variations = selectedProduct.getProductVariations();
    const { buyProductTakeAction, isLoading: takeActionSubmitLoading } =
        useBuyProductTakeActions();
    const { refetchListApi } = useRefetch();
    const handleAddRate = () => {
        const newRate = { min_amount: 0, max_amount: 0, rate: 0 };
        setShippingRates([...shippingRates, newRate]);
    };

    const handleRemoveRate = (index) => {
        const updatedRates = [...shippingRates];
        updatedRates.splice(index, 1);
        setShippingRates(updatedRates);
    };

    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(filters, getAddonServices);
        }
    }, [isFirstCall, filters, isFetched, refetch]);

    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(filters, getShippingCategory);
        }
    }, [isFirstCall, filters, isFetched, refetch]);

    const addonServiceCollectionModel =
        addonServiceCollectionData &&
        new AddonServiceCollectionModel(addonServiceCollectionData);

    // //  Shipping category
    // const ShippingCategoryData =
    //     shippingCategoryStoreData &&
    //     new ShippingCategoryCollectionModel(
    //         shippingCategoryStoreData,
    //     ).getShippingCategoryTreeIds();

    const options = addonServiceCollectionModel
        ?.getData()
        .map((service) => ({ id: service.id, value: service.name }));

    useEffect(() => {
        setInputValues((prevInputValues) => {
            return selectedProduct
                .getProductVariations()
                .reduce((acc: any, variation, index) => {
                    if (
                        selectedAction.includes(
                            TakeActionsEnums.PRICE_INCREASED,
                        ) ||
                        selectedAction.includes(
                            TakeActionsEnums.PRICE_DECREASED,
                        )
                    ) {
                        acc[index] = variation
                            .getOriginalUnitPrice()
                            .toString();
                    }
                    if (
                        selectedAction.includes(
                            TakeActionsEnums.QUANTITY_UPDATE,
                        )
                    ) {
                        acc[index] = variation.getQuantity().toString();
                    }

                    if (
                        selectedAction.includes(
                            TakeActionsEnums.PRODUCT_STOCK_OUT,
                        ) ||
                        selectedAction.includes(TakeActionsEnums.SKU_MISMATCHED)
                    ) {
                        acc[index] = 0;
                    }

                    return acc;
                }, prevInputValues);
        });
    }, [selectedAction, selectedProduct]);

    //hide error message after 5 sec
    useEffect(() => {
        if (isErrorVisible) {
            setTimeout(() => {
                setIsErrorVisible(false);
            }, 5000);
        }
    }, [isErrorVisible]);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const handleChange = (values: any) => {
        setSelectedAction(values);

        const actionsToShowPrices = [
            TakeActionsEnums.PRICE_INCREASED,
            TakeActionsEnums.PRICE_DECREASED,
            TakeActionsEnums.PRODUCT_STOCK_OUT,
            TakeActionsEnums.SKU_MISMATCHED,
        ];

        const getLocalizedMessage = (
            key: keyof typeof ISSUE_MESSAGE,
            region: string | undefined,
        ) => {
            const message = ISSUE_MESSAGE[key];
            return region === "BD" ? message.message_bd : message.message_en;
        };

        const REGION = selectedProduct.getRegion()?.code;

        const actionsWithMessages = {
            [TakeActionsEnums.ILLEGAL_ITEM]: getLocalizedMessage(
                "illegal_item",
                REGION,
            ),
            [TakeActionsEnums.PRICE_INCREASED]: getLocalizedMessage(
                "price_increased",
                REGION,
            ),
            [TakeActionsEnums.HIGH_TAX_POSSIBILITY]: getLocalizedMessage(
                "high_tax_possibility",
                REGION,
            ),
            [TakeActionsEnums.PRODUCT_STOCK_OUT]: getLocalizedMessage(
                "stock_out",
                REGION,
            ),
            [TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED]: getLocalizedMessage(
                "local_delivery_charges",
                REGION,
            ),
        };

        const customerNote = values
            .map((value: any) => actionsWithMessages[value] || "")
            .filter((msg: string) => msg.trim())
            .join(" ");

        setUpdateData((prev) => ({
            ...prev,
            customer_note: customerNote,
            adminNote: customerNote,
        }));

        const showPrices = values.some((value: any) =>
            actionsToShowPrices.includes(value),
        );
        setShowPrices(showPrices);

        const actionPairsToFilter = {
            [TakeActionsEnums.PRICE_INCREASED]: [
                TakeActionsEnums.PRICE_DECREASED,
                TakeActionsEnums.QUANTITY_UPDATE,
                TakeActionsEnums.SKU_MISMATCHED,
                TakeActionsEnums.PRODUCT_STOCK_OUT,
            ],
            [TakeActionsEnums.PRICE_DECREASED]: [
                TakeActionsEnums.PRICE_INCREASED,
                TakeActionsEnums.QUANTITY_UPDATE,
                TakeActionsEnums.SKU_MISMATCHED,
                TakeActionsEnums.PRODUCT_STOCK_OUT,
            ],
            [TakeActionsEnums.QUANTITY_UPDATE]: [
                TakeActionsEnums.PRICE_INCREASED,
                TakeActionsEnums.PRICE_DECREASED,
                TakeActionsEnums.SKU_MISMATCHED,
                TakeActionsEnums.PRODUCT_STOCK_OUT,
            ],
            [TakeActionsEnums.SKU_MISMATCHED]: [
                TakeActionsEnums.PRICE_INCREASED,
                TakeActionsEnums.PRICE_DECREASED,
                TakeActionsEnums.QUANTITY_UPDATE,
                TakeActionsEnums.PRODUCT_STOCK_OUT,
            ],
            [TakeActionsEnums.PRODUCT_STOCK_OUT]: [
                TakeActionsEnums.PRICE_INCREASED,
                TakeActionsEnums.PRICE_DECREASED,
                TakeActionsEnums.QUANTITY_UPDATE,
                TakeActionsEnums.SKU_MISMATCHED,
            ],
        };

        const filteredOptions = driverOptions.filter((option) => {
            return !values.some((value: any) => {
                return actionPairsToFilter[value]?.includes(option.value);
            });
        });

        setFilteredOptions(filteredOptions);
    };

    useEffect(() => {
        form.setFieldsValue({
            customer_note: updateData.customer_note,
            adminNote: updateData.adminNote,
        });
    }, [updateData, form]);

    const handleChangeServices = (values: any) => {
        const selectedIds = values.map((value: any) => {
            if (options) {
                const option = options.find(
                    (option: any) => option.value === value,
                );
                return option ? option.id : null;
            }
        });
        setSelectedService(selectedIds.filter((id: any) => id !== null));
    };

    // const handleChangeCategory = (option: string) => {
    //     setSelectedCategory(option);
    // };

    const [shippingRateChange, setShippingRateChange] =
        useState<IShippingRateType>({
            new_rate: 0,
            slots: [
                {
                    max_amount: 0,
                    min_amount: 0,
                    rate: 0,
                },
            ],
        });

    const handleFormChange = (
        _changedValues: any,
        allValues: IShippingRateType,
    ) => {
        setShippingRateChange(allValues);
    };

    const UpdateKeyValue = (key: string, value: string | null) => {
        const actionTypesMap: { [key: string]: string } = {
            customer_note: "customer_note",
            adminNote: "adminNote",
            "tax-added": "tax-added",
            "local-delivery-charge-added": "local-delivery-charge-added",
            "service-charge": "service-charge",
            "shipping-charge-increase": "shipping-charge-increase",
            "packing-charge": "packing-charge",
            "fx-mismatched": "fx-mismatched",
        };
        const actionType = actionTypesMap[key];
        if (actionType) {
            if (actionType === "customer_note" || actionType === "adminNote") {
                setUpdateData((prev) => ({
                    ...prev,
                    [actionType]: value,
                }));
            } else {
                findAndUpdateByAction(
                    actionType as TakeActionsEnums,
                    Number(value),
                );
            }
        }
    };

    const findAndUpdateByAction = (action: TakeActionsEnums, value: number) => {
        const index = charge.findIndex((field) => field.action === action);

        if (index === -1) {
            setCharge((prevFields) => [...prevFields, { action, value }]);
        } else {
            setCharge((prevFields) => {
                const newFields = [...prevFields];
                newFields[index] = { ...newFields[index], value };
                return newFields;
            });
        }
    };

    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    const updateObjectById = (array: any[], newData: any) => {
        const index = array.findIndex((obj) => obj.id === newData.id);
        if (index !== -1) {
            array[index] = { ...array[index], ...newData };
            setUpdateData((pre) => {
                return {
                    ...pre,
                    data: array,
                };
            });
        } else {
            setUpdateData((pre) => {
                return {
                    ...pre,
                    data: [...pre.data, newData],
                };
            });
        }
    };

    const handleUpdateDataPass = (
        data: BuyProductVariationsModel,
        totalUnit: number,
        updateFor: "quantity" | "price",
    ) => {
        const newData = { ...data };

        if (updateFor === "price") {
            newData.unit_price = totalUnit;
        } else if (updateFor === "quantity") {
            newData.quantity = totalUnit;
        }
        updateObjectById(updateData.data, newData);
    };

    const handleInputChange = (index: number, value: number | string) => {
        const variation = selectedProduct.getProductVariations()[index];
        const unitPrice = variation.getOriginalUnitPrice();

        const inputValue =
            typeof value === "string" ? parseFloat(value) : value;

        if (!isNaN(inputValue) && inputValue >= 0) {
            if (selectedAction.includes(TakeActionsEnums.PRICE_INCREASED)) {
                if (inputValue >= unitPrice) {
                    setInputValues((prevState) => ({
                        ...prevState,
                        [index]: String(inputValue),
                    }));
                }
            } else if (
                selectedAction.includes(TakeActionsEnums.PRICE_DECREASED)
            ) {
                if (inputValue <= unitPrice) {
                    setInputValues((prevState) => ({
                        ...prevState,
                        [index]: String(inputValue),
                    }));
                }
            } else if (
                selectedAction.includes(TakeActionsEnums.QUANTITY_UPDATE) &&
                !isNaN(inputValue)
            ) {
                setInputValues((prevState) => ({
                    ...prevState,
                    [index]: String(inputValue),
                }));
            }
        }
    };

    useEffect(() => {
        let totalAmount = 0;
        variations.forEach((variation, index) => {
            const updatedPrice =
                inputValues[index] !== undefined
                    ? inputValues[index]
                    : variation.getOriginalUnitPrice();
            totalAmount += Number(updatedPrice) * variation.getQuantity();
        });

        setTotal(totalAmount);
    }, [inputValues, variations]);

    const [selectedIds, setSelectedIds] = useState<number[]>([]);

    const onFinish = async (values) => {
        if (!selectedAction || selectedAction.length === 0) return;

        const sequences: SequenceModel[] = [];
        selectedAction.forEach((action) => {
            if (charge.map((charge) => charge.action).includes(action)) return;

            const chargeFx = charge.map((e) => e.value);

            let variants: VariantModel[] = [];

            switch (action) {
                case TakeActionsEnums.PRICE_INCREASED:
                    variants = updateData.data.map((item: any) => ({
                        variant_id: item.id,
                        new_original_unit_price: item.unit_price,
                    }));
                    break;

                case TakeActionsEnums.PRICE_DECREASED:
                    variants = updateData.data.map((item: any) => ({
                        variant_id: item.id,
                        new_original_unit_price: item.unit_price,
                    }));
                    break;

                case TakeActionsEnums.QUANTITY_UPDATE: {
                    // @ts-ignore
                    const _sequenceQuantity: SequenceModel = {
                        action_type: action,
                        admin_note: updateData.customer_note,
                        minimum_order_quantity: values.quantity
                            ? values.quantity
                            : selectedProduct.getTotalQuantity(),
                    };
                    break;
                }
                case TakeActionsEnums.PRODUCT_STOCK_OUT:
                    variants = selectedIds.map((item: any) => ({
                        variant_id: item,
                        new_quantity: 0,
                    }));
                    break;

                case TakeActionsEnums.SKU_MISMATCHED:
                    variants = selectedIds.map((item: any) => ({
                        variant_id: item,
                        new_quantity: 0,
                    }));
                    break;

                case TakeActionsEnums.SHIPPING_CHARGE_INCREASE: {
                    const shippingCharge: SequenceModel = {
                        action_type: action,
                        admin_note: updateData.customer_note,
                        new_rate: shippingRateChange.new_rate,
                        slots: shippingRateChange.slots || [],
                    };
                    sequences.push(shippingCharge);
                    break;
                }

                case TakeActionsEnums.SHIPPING_CHANGE: {
                    const shippingChange: SequenceModel = {
                        action_type: action,
                        admin_note: updateData.customer_note,
                        new_agent_category_price_read: values.shipping_change,
                    };
                    sequences.push(shippingChange);
                    break;
                }

                case TakeActionsEnums.SERVICE_CHARGE: {
                    const sequenceService: SequenceModel = {
                        action_type: action,
                        addon_ids: selectedService,
                        admin_note: updateData.customer_note,
                    };
                    sequences.push(sequenceService);
                    break;
                }

                case TakeActionsEnums.FX_MISMATCHED: {
                    const sequenceFx: SequenceModel = {
                        action_type: action,
                        new_fx: Number(chargeFx),
                        admin_note: updateData.customer_note,
                    };
                    sequences.push(sequenceFx);
                    break;
                }

                case TakeActionsEnums.PURCHASE_CONFIRMATION: {
                    const sequencePurchase: SequenceModel = {
                        action_type: action,
                        images: form.getFieldValue("images"),
                        admin_note: updateData.customer_note,
                    };
                    sequences.push(sequencePurchase);
                    break;
                }
                // case TakeActionsEnums.CATEGORY_CHANGE: {
                //     const sequenceCategoryChange: SequenceModel = {
                //         action_type: action,
                //         new_shipping_category_id: selectedCategory,
                //         admin_note: updateData.adminNote1,
                //     };
                //     sequences.push(sequenceCategoryChange);
                //     break;
                // }

                default: {
                    const sequence: SequenceModel = {
                        action_type: action,
                        admin_note: updateData.customer_note,
                    };
                    sequences.push(sequence);
                    break;
                }
            }

            if (variants.length > 0) {
                const sequence: SequenceModel = {
                    action_type: action,
                    variants,
                    admin_note: updateData.customer_note,
                };
                sequences.push(sequence);
            }
        });

        charge.forEach((charge) => {
            if (charge.action === TakeActionsEnums.FX_MISMATCHED) {
                const sequence: SequenceModel = {
                    action_type: charge.action,
                    new_fx: charge.value,
                    admin_note: updateData.customer_note,
                };
                sequences.push(sequence);
            } else {
                const sequence: SequenceModel = {
                    action_type: charge.action,
                    original_amount: charge.value,
                    admin_note: updateData.customer_note,
                };
                sequences.push(sequence);
            }
        });

        const latestSequences = sequences.filter((x) =>
            selectedAction.includes(x.action_type),
        );
        // Create payload
        const payload: SequencesDataModel = {
            sequences: latestSequences,
            admin_note: updateData.adminNote,
        };

        try {
            await buyProductTakeAction(selectedProduct.getId(), payload);
            setIsErrorVisible(false);
            form.resetFields();
            form.setFieldsValue({ take_actions: [] });
            setShowPrices(false);
            setSelectedAction([]);
            handleModal({
                action: false,
                data: null,
            });
            notification["success"]({
                message: "Successfully created",
            });
            await refetchListApi();
        } catch (error) {
            showError(error);
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys: any) => {
            setSelectedIds(selectedRowKeys);
        },
    };

    const shouldApplyRowSelection =
        selectedAction.includes(TakeActionsEnums.PRODUCT_STOCK_OUT) ||
        selectedAction.includes(TakeActionsEnums.SKU_MISMATCHED);

    return (
        <Row>
            <Col span={24}>
                <PageHeader
                    style={{ marginTop: "10px" }}
                    ghost={false}
                    title="Product Information"
                >
                    <Col span={24}>
                        <ProductDetails data={selectedProduct} />
                    </Col>
                    <Divider />
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        onFieldsChange={onValuesChange}
                        ref={formRef}
                    >
                        <Form.Item
                            name="take_actions"
                            label="Take Actions"
                            initialValue={selectedAction.map((x) => {
                                return {
                                    value: x,
                                    label: filteredOptions.find(
                                        (y) => y.value === x,
                                    )?.label,
                                };
                            })}
                            rules={[
                                {
                                    required: true,
                                    message: "Please select a issue!",
                                },
                            ]}
                        >
                            <Select
                                mode="tags"
                                style={{ width: "100%" }}
                                placeholder="Please select your actions"
                                onChange={handleChange}
                                options={filteredOptions}
                            />
                        </Form.Item>

                        {selectedAction
                            .filter(
                                (action) =>
                                    action === TakeActionsEnums.TAX_ADDED ||
                                    action ===
                                        TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED ||
                                    action ===
                                        TakeActionsEnums.PACKING_CHARGE ||
                                    action === TakeActionsEnums.FX_MISMATCHED,
                            )
                            .map((action, index) => (
                                <Form.Item
                                    key={index}
                                    name={`original_amount_${action}`}
                                    label={`Please add ${
                                        action === TakeActionsEnums.TAX_ADDED
                                            ? "tax"
                                            : action ===
                                                TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED
                                              ? "local delivery charge"
                                              : action ===
                                                  TakeActionsEnums.PACKING_CHARGE
                                                ? "Packing charge"
                                                : action ===
                                                    TakeActionsEnums.FX_MISMATCHED
                                                  ? " new fx"
                                                  : "charge"
                                    }`}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please add the amount!",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        style={{
                                            width: "100%",
                                            marginTop: "10px",
                                            marginBottom: "15px",
                                        }}
                                        min="1"
                                        key={index}
                                        type="text"
                                        placeholder={`Please add ${
                                            action ===
                                            TakeActionsEnums.TAX_ADDED
                                                ? "tax"
                                                : action ===
                                                    TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED
                                                  ? "local delivery charge"
                                                  : action ===
                                                      TakeActionsEnums.PACKING_CHARGE
                                                    ? "Packing charge"
                                                    : action ===
                                                        TakeActionsEnums.FX_MISMATCHED
                                                      ? "new fx"
                                                      : "charge"
                                        }`}
                                        onChange={(value: string | null) => {
                                            UpdateKeyValue(action, value);
                                        }}
                                    />
                                </Form.Item>
                            ))}

                        {showPrices && selectedProduct && (
                            <>
                                <Table
                                    dataSource={selectedProduct.getProductVariations()}
                                    pagination={false}
                                    rowSelection={
                                        shouldApplyRowSelection
                                            ? rowSelection
                                            : undefined
                                    }
                                    scroll={{ y: 240 }}
                                    rowKey={(r) => r.getId()}
                                    columns={[
                                        {
                                            title: "Product Meta",
                                            dataIndex: "product_variations",
                                            key: "product_variations",
                                            width: "35%",
                                            render: (
                                                _,
                                                record: BuyProductVariationsModel,
                                            ) => (
                                                <>
                                                    {record.properties.data.map(
                                                        (v, index) => (
                                                            <div key={index}>
                                                                {v.property_name ===
                                                                    "Color" && (
                                                                    <p>
                                                                        Color:{" "}
                                                                        {
                                                                            v.property_value_name
                                                                        }
                                                                    </p>
                                                                )}
                                                                {v.property_name ===
                                                                    "Size" && (
                                                                    <p>
                                                                        Size:{" "}
                                                                        {
                                                                            v.property_value_name
                                                                        }
                                                                    </p>
                                                                )}
                                                            </div>
                                                        ),
                                                    )}
                                                </>
                                            ),
                                        },
                                        // ...(selectedAction.includes(
                                        //     TakeActionsEnums.QUANTITY_UPDATE,
                                        // )
                                        //     ? [
                                        //           {
                                        //               title: "Old QTY",
                                        //               dataIndex:
                                        //                   "old_quantity",
                                        //               key: "old_quantity",
                                        //               render: (_, record) =>
                                        //                   record.getQuantity(),
                                        //           },
                                        //       ]
                                        //     : []),
                                        {
                                            title: "Qty",
                                            dataIndex: "qty",
                                            key: "qty",
                                            render: (_, record, index) => {
                                                const isDisabled =
                                                    selectedAction.includes(
                                                        TakeActionsEnums.PRODUCT_STOCK_OUT,
                                                    ) ||
                                                    selectedAction.includes(
                                                        TakeActionsEnums.SKU_MISMATCHED,
                                                    );

                                                if (
                                                    selectedAction.includes(
                                                        TakeActionsEnums.SKU_MISMATCHED,
                                                    )
                                                ) {
                                                    return (
                                                        <InputNumber
                                                            defaultValue={record?.getQuantity()}
                                                            min={1}
                                                            disabled={
                                                                isDisabled
                                                            }
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    handleInputChange(
                                                                        index,
                                                                        Number(
                                                                            e,
                                                                        ),
                                                                    );
                                                                    handleUpdateDataPass(
                                                                        record,
                                                                        Number(
                                                                            e,
                                                                        ),
                                                                        "quantity",
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    );
                                                } else if (
                                                    selectedAction.includes(
                                                        TakeActionsEnums.PRODUCT_STOCK_OUT,
                                                    )
                                                ) {
                                                    return (
                                                        <InputNumber
                                                            defaultValue={record?.getQuantity()}
                                                            min={1}
                                                            disabled={
                                                                isDisabled
                                                            }
                                                            onChange={(e) => {
                                                                if (e) {
                                                                    handleInputChange(
                                                                        index,
                                                                        Number(
                                                                            e,
                                                                        ),
                                                                    );
                                                                    handleUpdateDataPass(
                                                                        record,
                                                                        Number(
                                                                            e,
                                                                        ),
                                                                        "quantity",
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    );
                                                } else {
                                                    return (
                                                        <span>
                                                            {record?.getQuantity()}
                                                        </span>
                                                    );
                                                }
                                            },
                                        },

                                        ...(selectedAction.includes(
                                            TakeActionsEnums.PRICE_DECREASED,
                                        ) ||
                                        selectedAction.includes(
                                            TakeActionsEnums.PRICE_INCREASED,
                                        )
                                            ? [
                                                  {
                                                      title: "Old Price",
                                                      dataIndex: "unit_price",
                                                      key: "unit_price",
                                                      render: (
                                                          _,
                                                          record: BuyProductVariationsModel,
                                                      ) => (
                                                          <p>
                                                              {record.getOriginalUnitPrice()}
                                                              {`(${selectedProduct
                                                                  ?.getStore()
                                                                  ?.getDefaultCurrency()
                                                                  ?.getCode()})`}
                                                          </p>
                                                      ),
                                                  },
                                              ]
                                            : []),

                                        {
                                            title: "New Prices",
                                            dataIndex: "new_prices",
                                            key: "new_prices",
                                            render: (_, record, index) => {
                                                if (
                                                    selectedAction.includes(
                                                        TakeActionsEnums.PRICE_INCREASED,
                                                    )
                                                ) {
                                                    return (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <Text>
                                                                    {selectedProduct
                                                                        ?.getStore()
                                                                        ?.getDefaultCurrency()
                                                                        ?.getSymbol()}
                                                                </Text>
                                                                <InputNumber
                                                                    value={
                                                                        inputValues[
                                                                            index
                                                                        ] ||
                                                                        record.getOriginalUnitPrice()
                                                                    }
                                                                    min={1}
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        if (e) {
                                                                            handleInputChange(
                                                                                index,
                                                                                Number(
                                                                                    e,
                                                                                ),
                                                                            );
                                                                            handleUpdateDataPass(
                                                                                record,
                                                                                Number(
                                                                                    e,
                                                                                ),
                                                                                "price",
                                                                            );
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        marginLeft: 8,
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    );
                                                } else if (
                                                    selectedAction.includes(
                                                        TakeActionsEnums.PRICE_DECREASED,
                                                    )
                                                ) {
                                                    return (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                            >
                                                                <Text>
                                                                    {selectedProduct
                                                                        ?.getStore()
                                                                        ?.getDefaultCurrency()
                                                                        ?.getSymbol()}
                                                                </Text>
                                                                <InputNumber
                                                                    value={
                                                                        inputValues[
                                                                            index
                                                                        ] ||
                                                                        record.getOriginalUnitPrice()
                                                                    }
                                                                    min={1}
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        if (e) {
                                                                            handleInputChange(
                                                                                index,
                                                                                Number(
                                                                                    e,
                                                                                ),
                                                                            );
                                                                            handleUpdateDataPass(
                                                                                record,
                                                                                Number(
                                                                                    e,
                                                                                ),
                                                                                "price",
                                                                            );
                                                                        }
                                                                    }}
                                                                    style={{
                                                                        marginLeft: 8,
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <span>
                                                            {record?.getOriginalTotalPrice()}
                                                        </span>
                                                    );
                                                }
                                            },
                                        },
                                    ]}
                                    footer={() => (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <strong>Total:</strong>
                                            </div>
                                            {selectedAction.includes(
                                                TakeActionsEnums.PRICE_INCREASED,
                                            ) && (
                                                <div
                                                    style={{
                                                        marginRight: "86px",
                                                    }}
                                                >
                                                    {total.toFixed(2)}
                                                </div>
                                            )}
                                            {selectedAction.includes(
                                                TakeActionsEnums.PRICE_DECREASED,
                                            ) && (
                                                <div
                                                    style={{
                                                        marginRight: "86px",
                                                    }}
                                                >
                                                    {total.toFixed(2)}
                                                </div>
                                            )}
                                            {/* {selectedAction.includes(
                                                TakeActionsEnums.QUANTITY_UPDATE
                                                ) && (
                                                <div style={{ marginRight: "86px" }}>
                                                    {total.toFixed(2)}
                                                </div>
                                                )} */}
                                        </div>
                                    )}
                                />
                            </>
                        )}

                        {selectedAction.includes(
                            TakeActionsEnums.SHIPPING_CHARGE_INCREASE,
                        ) && (
                            <>
                                <Form
                                    name="rate-form"
                                    onValuesChange={handleFormChange}
                                >
                                    <Card title="Shipping Rate Change">
                                        <Form.Item
                                            label="New Rate"
                                            name="new_rate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Please enter a new rate",
                                                },
                                            ]}
                                            style={{ width: "100%" }}
                                        >
                                            <InputNumber
                                                style={{ width: "100%" }}
                                            />
                                        </Form.Item>

                                        {shippingRates.map((rate, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: 40,
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: "16px",
                                                    }}
                                                >
                                                    <Form.Item
                                                        label="Min"
                                                        name={[
                                                            "slots",
                                                            index,
                                                            "min_amount",
                                                        ]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Please enter min amount",
                                                            },
                                                        ]}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        initialValue={
                                                            rate.min_amount
                                                        }
                                                    >
                                                        <InputNumber
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: "16px",
                                                    }}
                                                >
                                                    <Form.Item
                                                        label="Max"
                                                        name={[
                                                            "slots",
                                                            index,
                                                            "max_amount",
                                                        ]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Please enter max amount",
                                                            },
                                                        ]}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        initialValue={
                                                            rate.max_amount
                                                        }
                                                    >
                                                        <InputNumber
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        marginRight: "16px",
                                                    }}
                                                >
                                                    <Form.Item
                                                        label="Rate"
                                                        name={[
                                                            "slots",
                                                            index,
                                                            "rate",
                                                        ]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    "Please enter rate",
                                                            },
                                                        ]}
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        initialValue={rate.rate}
                                                    >
                                                        <InputNumber
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                {shippingRates.length > 1 && (
                                                    <Button
                                                        type="link"
                                                        onClick={() =>
                                                            handleRemoveRate(
                                                                index,
                                                            )
                                                        }
                                                        icon={
                                                            <MinusCircleOutlined twoToneColor="#ffffff" />
                                                        }
                                                        style={{
                                                            marginLeft: "16px",
                                                            marginTop: 25,
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                        <Button
                                            type="dashed"
                                            onClick={handleAddRate}
                                            style={{ marginTop: "20px" }}
                                        >
                                            <PlusOutlined /> Add Rate Change
                                        </Button>
                                    </Card>
                                </Form>
                            </>
                        )}

                        {selectedAction.includes(
                            TakeActionsEnums.QUANTITY_UPDATE,
                        ) && (
                            <Form.Item
                                name="quantity"
                                label="Minimum Quantity Update"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        validator(_, value) {
                                            const minQuantity =
                                                selectedProduct.getTotalQuantity() +
                                                1;
                                            if (value >= minQuantity) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    `Quantity must be at least ${minQuantity}`,
                                                ),
                                            );
                                        },
                                    },
                                ]}
                            >
                                <InputNumber
                                    defaultValue={selectedProduct.getTotalQuantity()}
                                    min={selectedProduct.getTotalQuantity() + 1}
                                />
                            </Form.Item>
                        )}

                        {selectedAction.includes(
                            TakeActionsEnums.SHIPPING_CHANGE,
                        ) &&
                            selectedProduct.getMoveonShipping() && (
                                <Form.Item
                                    name="shipping_change"
                                    label="Shipping Change"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select Shipping!",
                                        },
                                    ]}
                                >
                                    <ShippingUpdateModal
                                        setIsModalVisible={() => {}}
                                        shipping={
                                            selectedProduct.getMoveonShipping()!
                                        }
                                        userId={selectedProduct?.getUserId()}
                                        region={{
                                            code: selectedProduct
                                                ?.getRegion()
                                                ?.getCode(),
                                            id: selectedProduct
                                                .getRegion()
                                                ?.getId(),
                                        }}
                                        buyProductId={selectedProduct.getId()}
                                        hideSubmitButton={true}
                                        onAgentCompanyPriceIdChange={(id) => {
                                            form.setFieldValue(
                                                "shipping_change",
                                                id,
                                            );
                                        }}
                                        handleModal={() => {}}
                                    />
                                </Form.Item>
                            )}

                        {selectedAction.includes(
                            TakeActionsEnums.SERVICE_CHARGE,
                        ) && (
                            <Form.Item
                                name="service_charge"
                                label="Services"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select services!",
                                    },
                                ]}
                            >
                                <Select
                                    mode="tags"
                                    style={{ width: "100%" }}
                                    placeholder="Please select your service...."
                                    onChange={handleChangeServices}
                                    options={options}
                                    optionLabelProp="value"
                                />
                            </Form.Item>
                        )}

                        {/* {selectedAction.includes(
                            TakeActionsEnums.CATEGORY_CHANGE,
                            ) && (
                                <Form.Item
                                    label="Select Shipping Category"
                                    name="shipping_category_id"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Shipping Category is required",
                                        },
                                    ]}
                                >
                                    <TreeSelect
                                        onChange={(id) =>
                                            handleChangeCategory(id)
                                        }
                                        showSearch
                                        dropdownStyle={{
                                            maxHeight: 400,
                                            overflow: "auto",
                                        }}
                                        placeholder="Please select a shipping category"
                                        allowClear
                                        loading={isLoading}
                                        treeLine={{ showLeafIcon: true }}
                                        treeData={ShippingCategoryData}
                                        filterTreeNode={(searchValue, treeNode) => {
                                        const title = treeNode.title;
                                        return typeof title === 'string' && title.toLowerCase().includes(searchValue.toLowerCase());
                                        }}
                                    />
                                </Form.Item>
                            )} */}

                        {selectedAction.includes(
                            TakeActionsEnums.PURCHASE_CONFIRMATION,
                        ) && (
                            <Form.Item
                                name="images"
                                label="Images"
                                rules={[
                                    {
                                        required: true,
                                        message: "Logo is required",
                                    },
                                ]}
                            >
                                <MultiChunkUpload
                                    form={form}
                                    accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                                    // uploadUrl={UPLOAD_URL}
                                    fieldName="images"
                                />
                            </Form.Item>
                        )}

                        <Form.Item
                            style={{ marginTop: "3%" }}
                            name="customer_note"
                            label="Message (Visible for customer)"
                            rules={[
                                {
                                    required: true,
                                    message: "Please write a message!",
                                },
                            ]}
                        >
                            <Input.TextArea
                                value={updateData.customer_note}
                                onChange={(e) => {
                                    UpdateKeyValue(
                                        "customer_note",
                                        e.target.value,
                                    );
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="adminNote"
                            label="Note: (Only visible for admin)"
                            rules={[
                                {
                                    required: true,
                                    message: "Please write a note!",
                                },
                            ]}
                        >
                            <Input.TextArea
                                value={updateData.adminNote}
                                onChange={(e) => {
                                    UpdateKeyValue("adminNote", e.target.value);
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={takeActionSubmitLoading}
                                loading={takeActionSubmitLoading}
                            >
                                Update
                            </Button>
                        </Form.Item>
                    </Form>
                </PageHeader>
            </Col>
        </Row>
    );
};

export default TakeActionsTable;
