import { Button, Flex, Modal, Spin, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd/lib";
import { StatusTag } from "@/components";
import { ShipmentProductPackageModel } from "@/models/shipmentProductPackageCollection";
import { Suspense, useState } from "react";
import { IModalActionsType } from "@/helpers/getModalTital";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import ShipmentProductPackagesQuickView from "./ShipmentProductPackagesQuickView";

interface Props {
    packages?: ShipmentProductPackageModel[];
}

const { Text } = Typography;

const BuyProductCartoonAndPackage: React.FC<Props> = (props) => {
    const { isMobile } = useWindowWidth();
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);
    const columns: ColumnsType<ShipmentProductPackageModel> = [
        {
            title: <Tooltip title="Weight">Weight</Tooltip>,
            dataIndex: "quantity",
            key: "quantity",
            render: (_target, record: ShipmentProductPackageModel) => (
                <Typography>
                    <Text>
                        {record.getWeight()}
                        <small>kg</small>
                    </Text>
                </Typography>
            ),
        },
        {
            title: "Contains",
            dataIndex: "contains",
            key: "contains",
            render: (text) => {
                const value = text;

                return (
                    <Flex wrap="wrap" style={{ textTransform: "capitalize" }}>
                        <Tag color="green">{value}</Tag>
                    </Flex>
                );
            },
        },
        // {
        //     title: "Trackings",
        //     key: "Trackings",

        //     render: (_, record) => (
        //         <>
        //             {record.getTrackings().length > 0
        //                 ? record.getTrackings().map((tracking) => {
        //                       return (
        //                           <>
        //                               <Paragraph>
        //                                   <Text copyable>
        //                                       {tracking.getTracking()}
        //                                   </Text>
        //                               </Paragraph>
        //                           </>
        //                       );
        //                   })
        //                 : "N/A"}
        //         </>
        //     ),
        // },
        {
            title: "Update status",
            dataIndex: "fulfillment_status",
            key: "fulfillment_status",
            render: (target) => (
                <Typography>
                    <StatusTag slug={target} text={target} />
                </Typography>
            ),
        },
    ];

    return (
        <div>
            <Table
                title={() => {
                    return (
                        <Flex align="center" justify="space-between">
                            <Flex align="center">
                                <Typography.Text strong>Carton</Typography.Text>

                                <Button
                                    onClick={() =>
                                        setModalOpenType(
                                            "quick_view_package_shipment_product",
                                        )
                                    }
                                    hidden={false}
                                    type="link"
                                >
                                    Quick View
                                </Button>
                            </Flex>
                        </Flex>
                    );
                }}
                bordered
                size="small"
                rowHoverable={false}
                pagination={false}
                columns={columns}
                dataSource={props.packages}
                rowKey="id"
                scroll={{ x: 400 }}
            />

            <Modal
                title="Carton"
                open={modalOpenType === "quick_view_package_shipment_product"}
                centered
                okText="Update"
                width={isMobile ? "100%" : 1100}
                footer={false}
                onCancel={() => setModalOpenType(false)}
            >
                <Suspense fallback={<Spin />}>
                    <ShipmentProductPackagesQuickView
                        packages={props.packages ?? []}
                    />
                </Suspense>
            </Modal>
        </div>
    );
};

export default BuyProductCartoonAndPackage;
