import React from "react";
import { Alert, Button, Flex, Typography } from "antd";
import { IModalData } from "@/helpers/getModalTital";
import ShippingSlotsTable from "@/pages/BuyAndShip/components/ShippingRateTable";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import ShippingText from "@/components/ShippingText";
import { StatusTag } from "@/components";
import { tw } from "@/consts/theme/tailwindTheme";

interface Props {
    buyProductModel: BuyProductModel | undefined;
    isDetailPage?: boolean;
    handleModal?: (payload: IModalData) => void;
}

const BuyProductShippingInfo: React.FC<Props> = ({
    buyProductModel,
    isDetailPage,
}) => {
    if (!buyProductModel?.getShipmentProduct()) {
        return <Alert message="Shipment product not found" />;
    }
    const customerRate = buyProductModel.getMoveonShipping()?.getRate()
        ? `  ${buyProductModel.getMoveonShipping()?.getRate()} ${buyProductModel.getRegionCurrencyCode()} / ${buyProductModel.getMoveonShipping()?.getUnitType()}`
        : "N/A";

    return (
        <>
            <Flex vertical gap={12}>
                <Flex vertical gap={2}>
                    {buyProductModel.getMoveonShipping()?.getContain() && (
                        <Typography.Paragraph>
                            <Typography.Text
                                style={{
                                    paddingRight: tw.spacing["0.5"],
                                }}
                            >
                                Contain :{" "}
                            </Typography.Text>
                            <StatusTag
                                color="blue"
                                slug={`${buyProductModel.getMoveonShipping()?.getContain()}`}
                                text={`${buyProductModel.getMoveonShipping()?.getContain()}`}
                            />
                        </Typography.Paragraph>
                    )}

                    <ShippingText
                        isDetailPage={isDetailPage}
                        label="Shipping Category"
                        value={buyProductModel
                            .getMoveonShipping()
                            ?.getShippingCategoryName()}
                    />

                    <ShippingText
                        label="Type"
                        value={buyProductModel.getShippingType()}
                    />

                    <ShippingText
                        label="Mode"
                        value={buyProductModel.getShippingMode()}
                    />

                    <ShippingText
                        label="Source"
                        value={buyProductModel
                            .getMoveonShipping()
                            ?.getShippingCountryName()}
                        flagUrl={buyProductModel
                            .getMoveonShipping()
                            ?.getShippingCountryFlag()}
                    />
                    <ShippingText
                        label="Destination"
                        value={buyProductModel.getDestinationWarehouseName()}
                        flagUrl={buyProductModel.getDestinationWarehouseFlag()}
                    />
                </Flex>
                {/* -------------------------Shipping Provider---------------------- */}

                <Flex vertical gap={2}>
                    <Typography.Text strong>Shipping Provider:</Typography.Text>

                    <ShippingText
                        label="Company: "
                        value={
                            buyProductModel
                                .getMoveonShipping()
                                ?.getShippingCompanyName() || "N/A"
                        }
                    />
                    <ShippingText
                        label="Warehouse: "
                        value={
                            buyProductModel
                                .getMoveonShipping()
                                ?.getAgentWarehouseName() || "N/A"
                        }
                    />
                    <ShippingText
                        label="Warehouse location"
                        value={
                            buyProductModel
                                .getMoveonShipping()
                                ?.getAgentWarehouseLocation() || "N/A"
                        }
                    />

                    <ShippingText
                        label="Warehouse Country"
                        flagUrl={buyProductModel
                            .getMoveonShipping()
                            ?.getAgentWarehouseCountryFlag()}
                        value={
                            buyProductModel
                                .getMoveonShipping()
                                ?.getAgentWarehouseCountryName() || "N/A"
                        }
                    />
                </Flex>

                {/* -------------------------Customer Rate---------------------- */}

                <Flex align="start" vertical gap={8}>
                    {buyProductModel.getMoveonShipping() ? (
                        <div style={{ margin: 0 }}>
                            <ShippingText label="Rate" value={customerRate} />
                        </div>
                    ) : (
                        <Typography.Paragraph>Rate : N/A</Typography.Paragraph>
                    )}

                    {buyProductModel.getMoveonShipping()?.getSlots().length ? (
                        <ShippingSlotsTable
                            slots={buyProductModel
                                .getMoveonShipping()
                                ?.getSlots()}
                            regionCurrencyCode={buyProductModel.getRegionCurrencyCode()}
                            unitType={buyProductModel
                                .getMoveonShipping()
                                ?.getUnitType()}
                        />
                    ) : (
                        <Button style={{ padding: 0 }} type="link">
                            Slots :
                            <Typography.Text type="danger">N/A</Typography.Text>
                        </Button>
                    )}
                </Flex>
            </Flex>
        </>
    );
};

export default BuyProductShippingInfo;
