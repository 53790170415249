import { useGetSearchProducts } from "@/lib/core-react/hooks/private/useProduct";
import { Checkbox, Flex, Typography, Form } from "antd";
import { useEffect } from "react";
import { getUniqueArrayValues } from "../../../helpers/getUniqueArrayValues";
import { IFacetCheckboxProps } from "../../../types";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import FacetCheckboxForm from "./FacetCheckboxForm";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { useForm } from "antd/es/form/Form";
import usePageQuery from "@/helpers/usePageQuery";
import { useAtom } from "jotai";
import { FacetFieldModel } from "@/models/ProductSearchCollectionModel";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { productSearchQueryAtom } from "@/lib/core-react/store/inventoryProductsAtoms";

const CountriesFacet = ({ facetFields, payloadKey }: IFacetCheckboxProps) => {
    const {
        allOptionsData: { countryOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.COUNTRY);
    }, []);

    const { isLoading } = useGetSearchProducts();
    const [form] = useForm();
    const { updateQuery } = usePageQuery();
    const [productSearchQuery, setProductSearchQuery] = useAtom(
        productSearchQueryAtom,
    );

    const { country_codes } = productSearchQuery;

    useEffect(() => {
        if (country_codes && Array.isArray(country_codes)) {
            setProductSearchQuery((prev) => ({ ...prev, country_codes }));
        }
    }, []);

    const handleSingleSelect = async (selected: FacetFieldModel) => {
        const updatedCountries = getUniqueArrayValues(
            country_codes,
            `${selected.name}`,
        );
        const payload = {};
        payload[payloadKey] = updatedCountries;
        updateQuery(payload);
        setProductSearchQuery((prev) => ({
            ...prev,
            country_codes: updatedCountries,
        }));
        form.setFieldValue(selected.name, true);
    };

    const handleSingleDeSelect = async (selected: FacetFieldModel) => {
        const updatedCountries = country_codes.filter(
            (code) => code !== selected.name,
        );
        const payload = {};
        payload[payloadKey] = updatedCountries;
        updateQuery(payload);
        setProductSearchQuery((prev) => ({
            ...prev,
            country_codes: updatedCountries,
        }));
        form.setFieldValue(selected.name, false);
    };

    const onChange = (e: CheckboxChangeEvent, selected: FacetFieldModel) => {
        if (e.target.checked) {
            handleSingleSelect(selected);
        } else {
            handleSingleDeSelect(selected);
        }
    };

    useEffect(() => {
        const initialFormValues = {};
        if (facetFields) {
            facetFields.forEach(({ name }) => {
                initialFormValues[name] = country_codes.includes(name);
            });
        }

        form.setFieldsValue(initialFormValues);
    }, [facetFields, country_codes]);

    return (
        <FacetCheckboxForm
            label={"Countries"}
            facetFields={facetFields}
            isLoading={isLoading}
            form={form}
        >
            <Flex vertical gap={8}>
                {facetFields &&
                    facetFields.map((data) => {
                        const country = countryOptionsData.options.find(
                            (item) => item.code === data.name,
                        );

                        return (
                            <Form.Item
                                key={data?.name}
                                noStyle
                                name={data?.name}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    id={`${data.name}`}
                                    checked={form.getFieldValue(data.name)}
                                    onChange={(e) => onChange(e, data)}
                                >
                                    <Flex gap={4} align="center">
                                        <div style={{ width: 20, height: 20 }}>
                                            <ImageWithPlaceholder
                                                preview={false}
                                                src={`${country?.flag}`}
                                                alt="country flag"
                                            />
                                        </div>
                                        <Typography.Paragraph>
                                            {data.name}
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            ({data?.count})
                                        </Typography.Paragraph>
                                    </Flex>
                                </Checkbox>
                            </Form.Item>
                        );
                    })}
            </Flex>
        </FacetCheckboxForm>
    );
};

export default CountriesFacet;
