import { SingleChunkUpload } from "@/components/SingleChunkUpload";
import { Form, FormInstance, Input, Switch } from "antd";
import { useState } from "react";
import { NamePath } from "antd/lib/form/interface";
interface IProps {
    form: FormInstance<any>;
    fieldName: NamePath;
    placeOrder?: string;
}
const DynamicImageInput = ({ form, fieldName, placeOrder }: IProps) => {
    const [showImageUrl, setShowImageUrl] = useState(true);
    return (
        <div>
            <Switch
                checked={showImageUrl}
                onChange={setShowImageUrl}
                checkedChildren="Add URL"
                unCheckedChildren="Upload"
                style={{
                    marginBottom: "10px",
                }}
            />

            <Form.Item
                name={fieldName}
                rules={[{ required: true, message: "Image is required" }]}
            >
                {showImageUrl ? (
                    <Input placeholder={placeOrder} />
                ) : (
                    <SingleChunkUpload
                        form={form}
                        accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                        fieldName={fieldName}
                    />
                )}
            </Form.Item>
        </div>
    );
};

export default DynamicImageInput;
