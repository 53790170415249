import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";
import { MinimalUsersCollectionModel } from "@/models/userCollectionModel";

export const useGetAdminsMinimal = () => {
    const { v1Service } = useService();
    const [adminOptionsData, setAdminOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getAdmins = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setAdminOptionsData((prev) => ({ ...prev, isLoading: true }));

            const response = await v1Service.v1Resource.getAdmins(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setAdminOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setAdminOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getAdmins, adminOptionsData } as const;
};

export const useGetOrderHandlerMinimal = () => {
    const { adminUserService } = useService();
    const [orderHandlerOptionsData, setOrderHandlerOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getOrderHandlers = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setOrderHandlerOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await adminUserService.adminUserResource.getOrderHandler(
                    params,
                );

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setOrderHandlerOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setOrderHandlerOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getOrderHandlers, orderHandlerOptionsData } as const;
};

export const useGetCustomersMinimal = () => {
    const { v1Service } = useService();
    const [customerOptionsData, setCustomerOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);
    const [usersResponse, setUserResponse] = useState<
        MinimalUsersCollectionModel | undefined
    >(undefined);

    const getCustomers = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setCustomerOptionsData((prev) => ({ ...prev, isLoading: true }));

            const response = await v1Service.v1Resource.getUsers(params);
            setUserResponse(new MinimalUsersCollectionModel(response));
            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setCustomerOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setCustomerOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getCustomers, customerOptionsData, usersResponse } as const;
};
