import { Form, Button, Select, Flex, Typography } from "antd";
import { useBuyProductCancel } from "@/lib/core-react/hooks/private/usePurchase";
import TextArea from "antd/es/input/TextArea";
import { cancelVariationsAndChargesTypeEnum } from "@/types/buyProductCollection";
import useRefetch from "@/hooks/useRefetch";
import { useParams } from "react-router-dom";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";

const options = [
    { value: "Out of Stock", label: "Out of Stock" },
    { value: "Customer Did Not Respond", label: "Customer Did Not Respond" },
    {
        value: "Customer Requested Cancellation",
        label: "Customer Requested Cancellation",
    },
    {
        value: "Incorrect Order Details Provided",
        label: "Incorrect Order Details Provided",
    },
    {
        value: "System Error During Processing",
        label: "System Error During Processing",
    },
    {
        value: "Order Placed in Error by Customer",
        label: "Order Placed in Error by Customer",
    },
    { value: "others", label: "Others" },
];
const OTHERS = "others";

interface Props {
    buyProductId: number;
    onCancel: () => void;
}
const CancelFormBuyProduct = ({ buyProductId, onCancel }: Props) => {
    const { cancelBuyProduct, isLoading } = useBuyProductCancel();
    const { refetchListApi, refetchDetailApi } = useRefetch();
    const { id } = useParams();
    const [form] = Form.useForm();
    const watch = Form.useWatch(["cancel_reason"], form);

    const onFinish = async (values) => {
        try {
            const reason =
                values.cancel_reason === OTHERS
                    ? values.others
                    : values.cancel_reason;

            await cancelBuyProduct(buyProductId, {
                type: cancelVariationsAndChargesTypeEnum.EVERYTHING,
                cancel_reason: reason,
            });
            showSuccessAlert("Successfully Cancel Product");
            onCancel();
            if (id) {
                refetchDetailApi(id);
            }
            refetchListApi();
        } catch (error) {
            showError(error, form);
        }
    };
    return (
        <>
            <Typography.Paragraph>Please note: </Typography.Paragraph>
            <Typography.Text type="danger" strong>
                If you cancel the product, you will not be able to revert the
                cancellation. The amount will be automatically refunded to your
                customer wallet.
            </Typography.Text>
            <Form
                style={{ marginTop: 12 }}
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    cancel_reason: options[0].value,
                }}
            >
                <Flex vertical>
                    <Form.Item
                        name="cancel_reason"
                        label="Reason for Cancel"
                        rules={[
                            {
                                required: true,
                                message: "Please Select Cancel Reason .",
                            },
                        ]}
                    >
                        <Select
                            allowClear={true}
                            showSearch
                            optionFilterProp="label"
                            options={options}
                        />
                    </Form.Item>
                    {watch === OTHERS && (
                        <Form.Item
                            name="others"
                            label="Write Cancel Reason"
                            rules={[
                                {
                                    required: true,
                                    message: "Please write Cancel Reason .",
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                    )}
                </Flex>
                <Flex justify="end">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                    >
                        Submit
                    </Button>
                </Flex>
            </Form>
        </>
    );
};

export default CancelFormBuyProduct;
