import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Select, Checkbox, Flex } from "antd";
import { useAtom } from "jotai";
import { addressSchemaAtom } from "@/lib/core-react/store/store";
import {
    useCreateAddress,
    useGetAddress,
} from "@/lib/core-react/hooks/private/useAddress";
import { useAddressSchemaRetrive } from "@/lib/core-react/hooks/private/useAddressSchema";
import { AddressSchemaModel } from "@/models/addressSchemaModel";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { ICreateAddressRequest } from "@/types/buyOrderPlace";
import { showError } from "@/helpers/showError";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import PhoneInput from "antd-phone-input";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { PhoneNumber } from "react-phone-hooks/types";
import { defaultQuery } from "@/consts/defaultQuery";

const AddressModal: React.FC<{
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setIsModalVisible }) => {
    const [form] = Form.useForm();
    const {
        allOptionsData: { countryOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { createAddress, isLoading } = useCreateAddress();
    const { getAddressSchema } = useAddressSchemaRetrive();
    const [, setIsErrorVisible] = useState(false);
    const { getAddress } = useGetAddress();

    const [
        {
            data: addressSchema,
            isLoading: addressSchemaLoading,
            error: addressSchemaError,
        },
    ] = useAtom(addressSchemaAtom);
    const [selectedCountryId, setSelectedCountryId] = useState<number | null>(
        null,
    );
    const [showAddressForm, setShowAddressForm] = useState(false);
    const [dynamicOptions, setDynamicOptions] = useState({});
    const [countryCode, setCountryCode] = useState("");
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | string>("");
    const params = new URLSearchParams(location.search);
    const userId = params.get("userId");
    const countryId = params.get("countryId");

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.COUNTRY);
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [selectedCountryId]);

    const addressSchemaData =
        addressSchema && new AddressSchemaModel(addressSchema);

    const webFull = addressSchemaData?.getData().getWebFull() || {};
    const fields = addressSchemaData?.getData().getFields() || {};

    const handleOnSubmitAddress = async (values) => {
        try {
            const {
                country,
                is_default_shipping,
                is_default_billing,
                ...rest
            } = values;
            const selectedCountryName =
                countryOptionsData.options?.find((x) => x.value === country)
                    ?.label || "";
            const payload: ICreateAddressRequest = {
                user_id: Number(userId),
                country_id: values.country,
                address: {
                    ...rest,
                    phone: `+${values.phone.countryCode}${values.phone.areaCode}${values.phone.phoneNumber}`,
                    country: selectedCountryName,
                },
                label_ids: [],
                is_default_shipping: values.is_default_shipping,
                is_default_billing: values.is_default_billing,
            };
            await createAddress(Number(userId), country, payload);
            showSuccessAlert("Create Address Successfully");
            getAddress({
                countries: [countryCode],
                user_id: Number(userId),
            });
            setIsModalVisible(false);
            form.resetFields();
        } catch (error) {
            showError(error, form);
            setIsErrorVisible(true);
        }
    };

    const handleAddressByCountry = async () => {
        try {
            const values = await form.validateFields();
            const countryId = values.countrySelect as number;
            setSelectedCountryId(countryId);
            const selectedCountryCode = countryOptionsData.options?.find(
                (x) => x.value === countryId,
            )?.code;
            if (selectedCountryCode) {
                await getAddressSchema(
                    selectedCountryCode,
                    defaultQuery.locale,
                );
                setShowAddressForm(true);
            }
            form.setFields([{ name: "country", value: countryId }]);
        } catch (_e) {
            form.setFields([
                {
                    name: "countrySelect",
                    errors: [`${addressSchemaError}`],
                },
            ]);
            setShowAddressForm(false);
        }
    };

    useEffect(() => {
        const country = countryOptionsData.options?.find(
            (x) => x.value === Number(countryId),
        );

        if (country && country.code) {
            form.setFields([
                {
                    name: "countrySelect",
                    value: country?.value,
                },
            ]);
            setCountryCode(country.code);
        }
    }, [countryId, countryOptionsData.isLoading, showAddressForm]);

    const handleParentChange = (parentKey: string, value: string) => {
        const parentField = fields[parentKey];

        if (parentField && parentField.relation) {
            const childKey = parentField.relation.relations.child;

            if (childKey && fields[childKey]) {
                const childOptions =
                    parentField.data?.data[value]?.[childKey] || [];

                setDynamicOptions((prev) => ({
                    ...prev,
                    [childKey]: childOptions.map((childValue) => {
                        const childData =
                            fields[childKey]?.data?.data[childValue];

                        if (typeof childData === "string") {
                            return {
                                label: childData,
                                value: childValue,
                            };
                        } else if (
                            typeof childData === "object" &&
                            childData !== null
                        ) {
                            return {
                                label: childData.name,
                                value: childValue,
                            };
                        }
                        return {
                            label: childValue,
                            value: childValue,
                        };
                    }),
                }));
                form.setFieldsValue({ [childKey]: null });
            }
        }
    };

    const renderField = (fieldKey, fieldSchema) => {
        const {
            type,
            place_holder,
            label,
            is_required,
            errors,
            data_source,
            data,
            properties,
            resource,
        } = fieldSchema;

        let fieldComponent;
        const rules = [
            {
                required: is_required,
                message: errors?.on_empty || `${label} is required`,
            },
        ];

        if (type === "string") {
            fieldComponent = (
                <Form.Item label={label} name={fieldKey} rules={rules}>
                    <Input
                        placeholder={place_holder}
                        disabled={!properties.is_editable}
                    />
                </Form.Item>
            );
        } else if (type === "textarea") {
            fieldComponent = (
                <Form.Item label={label} name={fieldKey} rules={rules}>
                    <Input.TextArea
                        placeholder={place_holder}
                        disabled={!properties.is_editable}
                    />
                </Form.Item>
            );
        } else if (type === "tel") {
            fieldComponent = (
                <Form.Item label={label} name={fieldKey} rules={rules}>
                    <PhoneInput
                        placeholder={place_holder}
                        disabled={!properties.is_editable}
                        value={phoneNumber}
                        country={`${countryCode.toLowerCase()}`}
                        enableArrow={true}
                        enableSearch={true}
                        searchNotFound="Country not found"
                        searchPlaceholder="Search country"
                        disableDropdown={false}
                        disableParentheses={true}
                        preferredCountries={[
                            "bd",
                            "ae",
                            "cn",
                            "us",
                            "in",
                            "uk",
                        ]}
                        // onInput={(event) => {
                        //   console.log("User input:", event.target.value);
                        // }}
                        onChange={(value) => {
                            setPhoneNumber(value);
                        }}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            );
        } else if (type === "select") {
            const options =
                dynamicOptions[fieldKey] ||
                (data_source === "on-premise"
                    ? Object.keys(data?.data || {}).map((x) => ({
                          label: data?.data[x]?.name,
                          value: x,
                      }))
                    : []);

            let externalOption: { label: string; value: string | number }[] =
                [];
            if (data_source === "external") {
                switch (resource) {
                    case "country":
                        externalOption = countryOptionsData.options;
                        break;
                    default:
                        externalOption = [];
                }

                fieldComponent = (
                    <Form.Item label={label} name={fieldKey} rules={rules}>
                        <Select
                            filterOption={(input, option) =>
                                (option?.label?.toString() || "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            onChange={(value) =>
                                handleParentChange(fieldKey, value)
                            }
                            showSearch={true}
                            placeholder={place_holder}
                            disabled={!properties.is_editable}
                            options={externalOption}
                        />
                    </Form.Item>
                );
            } else {
                fieldComponent = (
                    <Form.Item label={label} name={fieldKey} rules={rules}>
                        <Select
                            filterOption={(input, option) =>
                                (option?.label?.toString() || "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            onChange={(value) =>
                                handleParentChange(fieldKey, value)
                            }
                            showSearch={true}
                            placeholder={place_holder}
                            disabled={!properties.is_editable}
                            options={options}
                        />
                    </Form.Item>
                );
            }
        }

        return fieldComponent;
    };

    const renderLines = (lines) => {
        return lines.map((line) => (
            <Row gutter={16} key={line.join("-")}>
                {line.map((fieldKey) => (
                    <Col
                        span={
                            fieldKey === "phone" ||
                            fieldKey === "address_1" ||
                            fieldKey === "address_2"
                                ? 24
                                : 12
                        }
                        key={fieldKey}
                    >
                        {fields && renderField(fieldKey, fields[fieldKey])}
                    </Col>
                ))}
            </Row>
        ));
    };

    return (
        <>
            {!showAddressForm ? (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleAddressByCountry}
                >
                    <Form.Item
                        label="Select Country"
                        name="countrySelect"
                        rules={[
                            {
                                required: true,
                                message: "Please select a country",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select a country"
                            options={countryOptionsData.options}
                            onChange={(_, country) => {
                                // @ts-ignore
                                setCountryCode(country.code);
                            }}
                        />
                    </Form.Item>
                    <Flex justify="end">
                        <Button
                            htmlType="submit"
                            type="primary"
                            loading={addressSchemaLoading}
                            disabled={addressSchemaLoading}
                            icon={<RightOutlined />}
                        >
                            Next
                        </Button>
                    </Flex>
                </Form>
            ) : (
                <Form
                    form={form}
                    onFinish={handleOnSubmitAddress}
                    layout="vertical"
                >
                    {webFull &&
                        Object.keys(webFull).map((lineKey) =>
                            renderLines([webFull[lineKey]]),
                        )}
                    <Row>
                        <Col>
                            <Flex justify="space-between" gap={24}>
                                <Form.Item
                                    noStyle
                                    name="is_default_shipping"
                                    valuePropName="checked"
                                    initialValue={false}
                                >
                                    <Checkbox>
                                        Set as default shipping address
                                    </Checkbox>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    name="is_default_billing"
                                    valuePropName="checked"
                                    initialValue={false}
                                >
                                    <Checkbox>
                                        Set as default billing address
                                    </Checkbox>
                                </Form.Item>
                            </Flex>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: 24,
                        }}
                        justify="space-between"
                    >
                        <Col>
                            <Button
                                onClick={() => setShowAddressForm(false)}
                                icon={<LeftOutlined />}
                            >
                                Back
                            </Button>
                        </Col>
                        <Col>
                            <Form.Item noStyle>
                                <Button
                                    loading={isLoading}
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}
        </>
    );
};

export default AddressModal;
