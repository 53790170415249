import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import checkActionPermission from "@/components/Authorized/CheckPermissions";

const AuthenticatedRoute: React.FC<{
    permissions: string[];
    children?: ReactNode;
    requiredPermission?: boolean;
}> = ({ permissions = [], children, requiredPermission = true }) => {
    const hasPermission = checkActionPermission(permissions, true, false);

    if (!hasPermission && requiredPermission) {
        return <Navigate to="/403" />;
    } else {
        return <>{children}</>;
    }
};

export default AuthenticatedRoute;
