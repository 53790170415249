import { useEffect } from "react";
import { useLoginUserPermissionAndSetInLocalStorage } from "../../lib/core-react/hooks/private/usePermission";
import { showError } from "@/helpers/showError";

const PermissionHandler = () => {
    const { getCurrentUserPermissions } =
        useLoginUserPermissionAndSetInLocalStorage();
    useEffect(() => {
        getCurrentUserPermissions()
            .then(() => {})
            .catch((error) => {
                showError(error);
            });
    }, []);
    return null;
};

export default PermissionHandler;
