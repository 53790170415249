import { ITranslationEntry } from "../lang";

export const ZU: ITranslationEntry = {
    translations: {
        notifications: "Izaziso",
        markAllAsRead: "Maka konke njengokufundiwe",
        poweredBy: "Inikezwe amandla yi",
        settings: "Izilungiselelo",
        noNewNotification: "Akukho okusha ongakubona lapha okwamanje",
    },
    lang: "zu",
};
