import { ITranslationEntry } from "../lang";

export const KK: ITranslationEntry = {
    translations: {
        notifications: "Хабарландырулар",
        markAllAsRead: "барлығын оқылған деп белгілеңіз",
        poweredBy: "задвижвани от",
        settings: "көмегімен",
        noNewNotification:
            "Мұнда әлі көретін жаңа ештеңе жоқМұнда әлі көретін жаңа ештеңе жоқ",
    },
    lang: "kk",
};
