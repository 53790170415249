import { AgentCompanyCollectionEnums } from "@/enums/agentCompanyCollectionEnums";
import {
    AgentCompanyOperationStatusEnums,
    AgentCompanyVerificationStatusEnums,
} from "@/enums/shared";
import { ISelectOption } from "@/filters/Models/SelectOptionModel";
import {
    IAgentCompany,
    IAgentCompanyCollection,
    IPurchaseCommissionCollection,
    IRegisteredCountry,
} from "@/types/agentCompanyCollection";
import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import { PurchaseCommissionModel } from "./purchaseCommissionCollectionModel";

export class RegisteredCountryModel {
    id: number;
    code: string;
    name: string;
    status: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;

    constructor(dataItem: IRegisteredCountry) {
        this.id = dataItem.id;
        this.code = dataItem.code;
        this.name = dataItem.name;
        this.status = dataItem.status;
        this.created_at = dataItem.created_at;
        this.updated_at = dataItem.updated_at;
        this.deleted_at = dataItem.deleted_at;
    }

    getId() {
        return this.id;
    }
    getCode() {
        return this.code;
    }
    getName() {
        return this.name;
    }
}

export class AgentCompanyModel {
    object: AgentCompanyCollectionEnums.AgentCompany;
    id: number;
    primary_name: string;
    secondary_name: string;
    slug: string;
    operation_status: AgentCompanyOperationStatusEnums;
    verification_status: AgentCompanyVerificationStatusEnums;
    registered_country: number;
    company_type: string;
    director_name: string;
    designation: string;
    business_phone: string;
    years_of_experience: string;
    number_of_people_in_team: string;
    address: IFilterType | null;
    registered_country_detail: RegisteredCountryModel | null;
    purchase_commissions: PurchaseCommissionCollectionModel;

    constructor(dataItem: IAgentCompany) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.primary_name = dataItem.primary_name;
        this.secondary_name = dataItem.secondary_name;
        this.slug = dataItem.slug;
        this.operation_status = dataItem.operation_status;
        this.verification_status = dataItem.verification_status;
        this.registered_country = dataItem.registered_country;
        this.company_type = dataItem.company_type;
        this.director_name = dataItem.director_name;
        this.designation = dataItem.designation;
        this.business_phone = dataItem.business_phone;
        this.years_of_experience = dataItem.years_of_experience;
        this.number_of_people_in_team = dataItem.number_of_people_in_team;
        this.address = dataItem.address;
        this.registered_country_detail = dataItem.registered_country_detail
            ? new RegisteredCountryModel(dataItem.registered_country_detail)
            : null;
        this.purchase_commissions =
            dataItem.purchase_commissions &&
            new PurchaseCommissionCollectionModel(
                dataItem.purchase_commissions,
            );
    }

    getId() {
        return this.id;
    }
    getPrimaryName() {
        return this.primary_name;
    }
    getSecondaryName() {
        return this.secondary_name;
    }
    getSlug() {
        return this.slug;
    }
    getOperationStatus() {
        return this.operation_status;
    }
    getVerificationStatus() {
        return this.verification_status;
    }
    getRegisteredCountry() {
        return this.registered_country;
    }
    getRegisteredCountryDetail() {
        return this.registered_country_detail;
    }
    getCompanyType() {
        return this.company_type;
    }
    getDirectorName() {
        return this.director_name;
    }
    getDesignation() {
        return this.designation;
    }
    getBusinessPhone() {
        return this.business_phone;
    }
    getYearsOfExperience() {
        return this.years_of_experience;
    }
    getNumberOfPeopleInTeam() {
        return this.number_of_people_in_team;
    }
    getAddress() {
        if (this.address) {
            const keysToRemove = ["id", "flag", "code", "object"];

            const filteredAddress = Object.keys(this.address)
                .filter((key) => !keysToRemove.includes(key))
                .reduce((obj: IFilterType, key) => {
                    obj[key] = this.address?.[key];
                    return obj;
                }, {});

            return filteredAddress;
        } else return {};
    }

    getPurchaseCommissions = (): PurchaseCommissionModel[] => {
        return this.purchase_commissions.getCommissions();
    };
}

export class AgentCompanyCollectionModel {
    public readonly object: AgentCompanyCollectionEnums.AgentCompanyCollection;
    public readonly data: AgentCompanyModel[];
    public readonly filters: IFilterType;
    public readonly pagination: IPagination;

    constructor(data: IAgentCompanyCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new AgentCompanyModel(item));
        this.filters = data.filters;
        this.pagination = data.pagination;
    }
    getData() {
        return this.data;
    }
    getAgentCompanyById(id: number): AgentCompanyModel | undefined {
        return this.getData().find((item) => item.getId() === id);
    }
    getAgentCompanyIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.getData().forEach(
            (item) => (obj[item.getId()] = item.getPrimaryName()),
        );
        return obj;
    }
    getAgentCompanyOptions(): ISelectOption[] {
        return this.data && this.data.length > 0
            ? this.data.map((item) => {
                  return {
                      label: `${item.getPrimaryName()}`,
                      value: item.getId(),
                  };
              })
            : [];
    }

    getPagination() {
        return this.pagination;
    }
    getFilters() {
        return this.filters;
    }
}

export class PurchaseCommissionCollectionModel {
    private data: PurchaseCommissionModel[];

    constructor(payload: IPurchaseCommissionCollection) {
        this.data = payload.data.map((x) => new PurchaseCommissionModel(x));
    }

    getCommissions() {
        return this.data;
    }
}
