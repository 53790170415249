import {
    ActionSequenceModel,
    BuyActionShippingSlotModel,
} from "@/models/buyActionCollectionModel";
import { Table } from "antd";

interface IProps {
    data: ActionSequenceModel;
    currency: string;
}

const ShippingChargeIncrease = ({ data, currency }: IProps) => {
    const shippingRateSlots = data.getActionShippingRate()?.slots.data || [];

    const columns = [
        {
            title: "Min Qty",
            dataIndex: "min_amount",
            key: "min_amount",
            render: (text: number) => (
                <span
                    style={{
                        whiteSpace: "nowrap",
                        fontSize: "0.875rem",
                        color: "#4A5568",
                    }}
                >
                    {text}
                </span>
            ),
        },
        {
            title: "Max Qty",
            dataIndex: "max_amount",
            key: "max_amount",
            render: (text: number) => (
                <span
                    style={{
                        whiteSpace: "nowrap",
                        fontSize: "0.875rem",
                        color: "#4A5568",
                    }}
                >
                    {text}
                </span>
            ),
        },
        {
            title: "Shipping Rate",
            dataIndex: "rate",
            key: "rate",
            render: (text: number) => (
                <span
                    style={{
                        whiteSpace: "nowrap",
                        fontSize: "0.875rem",
                        color: "#4A5568",
                    }}
                >
                    {currency} {text}
                </span>
            ),
        },
    ];

    return (
        <>
            <div>
                <div
                    style={{
                        backgroundColor: "#D1FAE5",
                        marginBottom: "0.5rem",
                        marginTop: "0.5rem",
                        display: "flex",
                        alignItems: "center",
                        padding: "0.75rem",
                        borderRadius: "0.375rem",
                    }}
                >
                    <p style={{ fontWeight: 600, color: "#4A5568" }}>
                        Shipping Rate:{" "}
                    </p>
                    <p style={{ color: "#4A5568" }}>
                        {currency}
                        {data.getActionShippingRate()?.getRate()}
                    </p>
                </div>
                <div style={{ overflowX: "auto", borderRadius: "0.375rem" }}>
                    <Table
                        columns={columns}
                        dataSource={shippingRateSlots.map(
                            (slot: BuyActionShippingSlotModel) => ({
                                key: slot.id,
                                min_amount: slot.min_amount,
                                max_amount: slot.max_amount,
                                rate: slot.rate,
                            }),
                        )}
                        pagination={false}
                        style={{
                            minWidth: "100%",
                            border: "1px solid #E2E8F0",
                        }}
                        rowClassName="bg-white"
                    />
                </div>
            </div>
        </>
    );
};

export default ShippingChargeIncrease;
