import { AcceptInventoryProductsQueryKeys } from "@/consts/appConstants";
import {
    FacetFieldModel,
    IFacetField,
} from "@/models/ProductSearchCollectionModel";
import { useGetSearchProducts } from "@/lib/core-react/hooks/private/useProduct";
import { useForm } from "antd/es/form/Form";
import usePageQuery from "@/helpers/usePageQuery";
import { useEffect } from "react";
import { getUniqueArrayValues } from "../../../helpers/getUniqueArrayValues";
import { useAtom } from "jotai";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import FacetCheckboxForm from "./FacetCheckboxForm";
import { productSearchQueryAtom } from "@/lib/core-react/store/inventoryProductsAtoms";

interface IProps {
    facetFields: FacetFieldModel[] | undefined;
    payloadKey: AcceptInventoryProductsQueryKeys;
    label: string;
}

const SellersFacet = ({ facetFields, payloadKey, label }: IProps) => {
    const { isLoading } = useGetSearchProducts();
    const [form] = useForm();
    const { updateQuery } = usePageQuery();
    const [productSearchQuery, setProductSearchQuery] = useAtom(
        productSearchQueryAtom,
    );

    const { seller_names } = productSearchQuery;

    useEffect(() => {
        if (seller_names && Array.isArray(seller_names)) {
            setProductSearchQuery((prev) => ({ ...prev, seller_names }));
        }
    }, []);

    const handleSingleSelect = async (selected: FacetFieldModel) => {
        const updatedSellers = getUniqueArrayValues(
            seller_names,
            selected.name,
        );
        const payload = {};
        payload[payloadKey] = updatedSellers;
        updateQuery(payload);
        setProductSearchQuery((prev) => ({
            ...prev,
            seller_names: updatedSellers,
        }));
        form.setFieldValue(selected.name, true);
    };

    const handleSingleDeSelect = async (selected: IFacetField) => {
        const updatedSellers = seller_names.filter(
            (code) => code !== selected.name,
        );
        const payload = {};
        payload[payloadKey] = updatedSellers;
        updateQuery(payload);
        setProductSearchQuery((prev) => ({
            ...prev,
            seller_names: updatedSellers,
        }));
        form.setFieldValue(selected.name, false);
    };

    const onChange = (e: CheckboxChangeEvent, selected) => {
        if (e.target.checked) {
            handleSingleSelect(selected);
        } else {
            handleSingleDeSelect(selected);
        }
    };

    useEffect(() => {
        const initialFormValues = {};
        if (facetFields) {
            facetFields.forEach(({ name }) => {
                initialFormValues[name] = seller_names.includes(name);
            });
        }
        form.setFieldsValue(initialFormValues);
    }, [seller_names]);

    return (
        <FacetCheckboxForm
            label={label}
            facetFields={facetFields}
            isLoading={isLoading}
            form={form}
            onChange={onChange}
        />
    );
};
export default SellersFacet;
