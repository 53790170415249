import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useDeliveryRequestList } from "@/lib/core-react/hooks/private/useDeliveryRequest";
import { DeliveryRequestCollectionAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";
import {
    DeliveryRequestCollectionModel,
    DeliveryRequestCourierOptionModel,
    DeliveryRequestModel,
} from "@/models/deliveryRequestCollectionModel";
import { PaginationModel } from "@/models/pagination";
import { IFilterType } from "@/types/filters";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Row, Table, Tag, Typography } from "antd";
import { useAtom } from "jotai";
import { useEffect } from "react";
import DeliveryRequestInfo from "./component/deliveryRequestInfo";
import Paragraph from "antd/lib/typography/Paragraph";
import { Descriptions } from "antd/lib";
import { formatString } from "@/utils/helper";
import { StatusTag } from "@/components";
import { formatDateTime, truncateText } from "@/utils/helpers";
import BuyProductColumns from "./component/BuyProductColumns";
import CommonError from "@/components/Error/CommonError";
import React from "react";

const { Text } = Typography;

export const DeliveryRequest = () => {
    const { getDeliveryRequests } = useDeliveryRequestList();

    const [expandedRows, setExpandedRows] = React.useState<Set<number>>(
        new Set(),
    );

    const [
        {
            data: deliveryRequestData,
            isLoading: deliveryRequestLoading,
            refetch,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(DeliveryRequestCollectionAtom);

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getDeliveryRequests);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    const deliveryRequestRawData =
        deliveryRequestData &&
        new DeliveryRequestCollectionModel(deliveryRequestData);

    const filterData = deliveryRequestRawData?.filters;

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getDeliveryRequests,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        deliveryRequestRawData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getDeliveryRequests);
    };

    useEffect(() => {
        if (!isFetched && filterData) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, filterData]);

    const toggleRowExpansion = (id: number) => {
        setExpandedRows((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    };

    if (!deliveryRequestLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    const columns = [
        {
            title: "Basic Information",
            dataIndex: "request_number",
            key: "request_number",
            width: 100,
            render: (_request_number: number, record: DeliveryRequestModel) => {
                return (
                    <div>
                        <DeliveryRequestInfo deliveryRequest={record} />
                    </div>
                );
            },
        },
        {
            title: "User Information",
            dataIndex: "user_information",
            key: "user_information",
            width: 220, // Set a minimum width
            render: (
                _user_information: number,
                record: DeliveryRequestModel,
            ) => {
                return (
                    <>
                        <Paragraph>
                            <Text>Name : </Text>
                            <Text strong copyable>
                                {record.user?.name}
                            </Text>
                        </Paragraph>

                        {record.user?.email && (
                            <Paragraph>
                                <Text>Email : </Text>
                                <Text
                                    strong
                                    copyable={{
                                        text: record.user.email,
                                    }}
                                >
                                    {truncateText(record.user.email, 15)}
                                </Text>
                            </Paragraph>
                        )}
                    </>
                );
            },
        },
        {
            title: "Total Measurement",
            dataIndex: "measurement_information",
            key: "measurement_information",
            width: 150,
            render: (
                _measurement_information: number,
                record: DeliveryRequestModel,
            ) => {
                return (
                    <>
                        <div style={{ marginBottom: 5 }}>
                            <Paragraph>
                                <Text>Weight : </Text>
                                <Tag color="green-inverse">
                                    {record.total_weight}/kg
                                </Tag>
                            </Paragraph>
                        </div>

                        <Paragraph>
                            <Text>Quantity : </Text>
                            <Tag color="orange-inverse">
                                {record.total_quantity}
                            </Tag>
                        </Paragraph>

                        <Paragraph>
                            <Text>Height : </Text>
                            <Text strong>{record.total_height}</Text>
                        </Paragraph>

                        <Paragraph>
                            <Text>Width : </Text>
                            <Text strong>{record.total_width}</Text>
                        </Paragraph>

                        <Paragraph>
                            <Text>Length : </Text>
                            <Text strong>{record.total_length}</Text>
                        </Paragraph>
                    </>
                );
            },
        },
        {
            title: "Shipping Address",
            dataIndex: "shipping_address",
            key: "shipping_address",
            width: 250,
            render: (
                shipping_address: IFilterType,
                record: DeliveryRequestModel,
            ) => {
                const id = record.getId();
                const isExpanded = expandedRows.has(id);

                const capitalize = (str: string) =>
                    str
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase());

                if (
                    !shipping_address.display_address ||
                    typeof shipping_address.display_address !== "object"
                ) {
                    return null;
                }

                const keys = isExpanded
                    ? Object.keys(shipping_address.display_address)
                    : Object.keys(shipping_address.display_address).slice(0, 4);

                return (
                    <>
                        <Descriptions size="small" column={1} bordered={false}>
                            {keys.map((key) => (
                                <Descriptions.Item
                                    key={key}
                                    label={capitalize(key)}
                                >
                                    {shipping_address.display_address[key]}
                                </Descriptions.Item>
                            ))}
                        </Descriptions>
                        <Button
                            type="link"
                            onClick={() => toggleRowExpansion(id)}
                            style={{ padding: 0 }}
                        >
                            {isExpanded ? "Show Less" : "Show More"}
                        </Button>
                    </>
                );
            },
        },
        {
            title: "Courier Options",
            dataIndex: "courier_option",
            key: "courier_option",
            width: 150,
            render: (
                courier_option: DeliveryRequestCourierOptionModel,
                record: DeliveryRequestModel,
            ) => {
                return (
                    <div>
                        <Paragraph>
                            <Text>Name : </Text>
                            <Text strong>
                                {formatString(courier_option.name)}
                            </Text>
                        </Paragraph>

                        <Paragraph>
                            <Text>Flat amount : </Text>
                            <Text strong>
                                {courier_option.flat_amount}{" "}
                                {record.region?.currency?.code}
                            </Text>
                        </Paragraph>
                        <div style={{ marginTop: 5 }}>
                            <Paragraph>
                                <Text>Status : </Text>
                                <StatusTag
                                    slug={courier_option.availability_status}
                                    text={courier_option.availability_status}
                                />
                            </Paragraph>
                        </div>
                        {courier_option.note && (
                            <Paragraph>
                                <Text>Note : </Text>
                                <Text strong>{courier_option.note}</Text>
                            </Paragraph>
                        )}
                        <div style={{ marginTop: 5 }}>
                            <Text>Created At: </Text>
                            <Tag color="purple">
                                {formatDateTime(courier_option.created_at)}
                            </Tag>
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={"Delivery Request"}
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col style={{ marginBottom: "10px" }} span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Table
                            columns={columns}
                            dataSource={deliveryRequestRawData?.data}
                            size="middle"
                            pagination={{
                                ...paginationConfig,
                                showTotal: (total) => (
                                    <div>
                                        Total{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                color: "green",
                                            }}
                                        >
                                            {total}
                                        </span>{" "}
                                        Items
                                    </div>
                                ),
                            }}
                            expandable={{
                                expandedRowRender: ({
                                    enrollments,
                                    region,
                                }) => {
                                    return (
                                        <BuyProductColumns
                                            data={enrollments.data || []}
                                            region={region || undefined}
                                        />
                                    );
                                },
                                defaultExpandedRowKeys: ["0"],
                            }}
                            rowKey="id"
                            scroll={{ x: 1300 }}
                            loading={deliveryRequestLoading}
                        />
                    </PageHeader>
                </Col>
            </Row>
        </div>
    );
};
