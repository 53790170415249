import { ITranslationEntry } from "../lang";

export const BN: ITranslationEntry = {
    translations: {
        notifications: "নোটিফিকেশন",
        markAllAsRead: "সবগুলো পড়া হয়েছে",
        poweredBy: "পাওয়ার্ড বাই",
        settings: "সেটিংস",
        noNewNotification: "এখানে এখনো নতুন কিছু দেখার নেই",
    },
    lang: "bn",
};
