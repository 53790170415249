import { ITranslationEntry } from "../lang";

export const PA: ITranslationEntry = {
    translations: {
        notifications: "ਸੂਚਨਾਵਾਂ",
        markAllAsRead: " ਸਭ ਨੂੰ ਪੜ੍ਹਿਆ ਹੋਇਆ ਮਾਰਕ ਕਰੋ",
        poweredBy: "ਦੁਆਰਾ ਸੰਚਾਲਿਤ",
        settings: "ਸੈਟਿੰਗਾਂ",
        noNewNotification: "ਇੱਥੇ ਦੇਖਣ ਲਈ ਅਜੇ ਕੁਝ ਨਵਾਂ ਨਹੀਂ ਹੈ",
    },
    lang: "pa",
};
