import {
    DownloadOutlined,
    EyeFilled,
    PrinterOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";
import {
    Button,
    Col,
    Flex,
    Modal,
    Progress,
    Row,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import { useAtom } from "jotai";

import FiltersComponent from "@/components/FiltersComponent";
import { StatusEnum, UserTypeEnums } from "@/enums/harvestJobCollectionEnums";
import useDataFilters from "@/hooks/useDataFilters";
import { harvestJobCollectionAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";
import {
    HarvestBatchCollectionModel,
    HarvestBatchModel,
} from "@/models/harvestBatchCollectionModel";
import { PaginationModel } from "@/models/pagination";
import { useEffect, useState } from "react";
import { IFilterType } from "@/types/filters";
import ViewDetailsModal from "./components/viewDetails";
import { formatString, generateColor } from "@/utils/helper";
import { formatDateTime } from "@/utils/helpers";
import { useHarvestJobList } from "@/lib/core-react/hooks/private/useHarvestJob";
import CommonError from "@/components/Error/CommonError";
import CustomerDetailsViews from "../CustomerManage/User/components/CustomerDetailsViews";

const HarvestJobManage = () => {
    const { getHarvestJobList } = useHarvestJobList();
    const [{ data: harvestJobListData, isLoading, refetch, unAuthorized }] =
        useAtom(harvestJobCollectionAtom);
    const { Text } = Typography;
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

    const {
        filters,
        handleFilterChange,
        isFirstCall,
        isFetched,
        handelFilterClear,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest({}, getHarvestJobList);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    const HarvestJobCollectionData =
        harvestJobListData &&
        new HarvestBatchCollectionModel(harvestJobListData);

    const [selectedHarvest, setSelectedHarvest] = useState<
        HarvestBatchModel | undefined
    >(undefined);
    const paginationData = HarvestJobCollectionData?.getPagination();

    const filterData = HarvestJobCollectionData?.getFilters();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && HarvestJobCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        HarvestJobCollectionData?.getFilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getHarvestJobList,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        HarvestJobCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getHarvestJobList);
    };

    const getColorForStatus = (status: string): string => {
        switch (status) {
            case StatusEnum.CREATED:
                return "#2db7f5";
            case StatusEnum.PRE_PROCESSED:
                return "#bef5a6";
            case StatusEnum.PROCESSING:
                return "#87d068";
            case StatusEnum.COMPLETED:
                return "#87d068";
            case StatusEnum.CANCELLED:
                return "#CDB37B";
            case StatusEnum.FAILED:
                return "#a82e2e";
            default:
                return "#000";
        }
    };

    const getColorForType = (status: string) => {
        switch (status) {
            case UserTypeEnums.AGENT:
                return "#265073";
            case UserTypeEnums.ADMIN:
                return "#0E46A3";
            default:
                return "#000000";
        }
    };

    const handleShowDetail = (record: any) => {
        setSelectedHarvest(record);
        setIsShowCreateModal(record);
    };

    if (!isLoading && unAuthorized) {
        return <CommonError unAuthorized={unAuthorized} />;
    }

    const columns = [
        {
            title: "#SL",
            dataIndex: "sl",
            key: "sl",
            align: "center" as const,
            render: (_: string, __: HarvestBatchModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Text type="success">{record.getBatchNumber()}</Text>
                    </div>
                );
            },
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Text>{formatString(record.getType())}</Text>
                    </div>
                );
            },
        },
        {
            title: "User",
            dataIndex: "user",
            key: "user",
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Text>
                            <CustomerDetailsViews
                                name="Name"
                                selectedUser={record.getUser()}
                            />
                            <strong
                                style={{
                                    color: `${getColorForType(record?.getUser()?.getType() ?? "defaultType")}`,
                                }}
                            >
                                (
                                {formatString(
                                    record?.getUser()?.getType() ??
                                        "defaultType",
                                )}
                                )
                            </strong>
                        </Text>
                    </div>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Tag color={getColorForStatus(record.getStatus())}>
                            {record.getStatus()}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Progress",
            dataIndex: "progress",
            key: "progress",
            render: (_: string, record: HarvestBatchModel) => {
                const progress = record.getProgress()
                    ? record.getProgress()
                    : 0;
                const isProgressComplete = progress === 100;
                const downloadUrls = record
                    .getResult()
                    ?.getData()
                    ?.getDownloadUrls();
                const downloadLink =
                    downloadUrls && downloadUrls.length > 0
                        ? downloadUrls[0]
                        : undefined;

                const downloadUrl = downloadLink ? downloadLink : undefined;

                return (
                    <div>
                        <div>
                            {!isProgressComplete && (
                                <Flex gap="small">
                                    <Progress
                                        type="circle"
                                        percent={progress ? progress : 0}
                                        size={40}
                                    />
                                </Flex>
                            )}
                            {isProgressComplete &&
                                record.getResult()?.getActionValue() ===
                                    "print" &&
                                downloadUrl && (
                                    <Button
                                        type="dashed"
                                        icon={<PrinterOutlined />}
                                        onClick={() =>
                                            window.open(downloadUrl, "_blank")
                                        }
                                    >
                                        {record.getResult()?.getActionLabel()}
                                    </Button>
                                )}

                            {isProgressComplete &&
                                record.getResult()?.getActionValue() ===
                                    "download" && (
                                    <Button
                                        type="dashed"
                                        icon={<DownloadOutlined />}
                                    >
                                        <a
                                            href={downloadUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            download
                                        >
                                            {record
                                                .getResult()
                                                ?.getActionLabel()}
                                        </a>
                                    </Button>
                                )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Tag",
            dataIndex: "tags",
            key: "tags",
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        {record.getTags().data.map((i, index) => (
                            <Tag key={index} color={generateColor(i.getTag())}>
                                {i.getTag()}
                            </Tag>
                        ))}
                    </div>
                );
            },
        },
        {
            title: "Create At",
            dataIndex: "create_At",
            key: "create_At",
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <div>
                        <Tag color="purple">
                            {formatDateTime(record.getCreatedAt())}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "View Details",
            dataIndex: "tags",
            key: "tags",
            render: (_: string, record: HarvestBatchModel) => {
                return (
                    <Tooltip title="View Details">
                        <Button
                            onClick={() => handleShowDetail(record)}
                            shape="round"
                            icon={<EyeFilled />}
                        />
                    </Tooltip>
                );
            },
        },
    ];
    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="All Queue Jobs"
                    style={{ marginTop: "10px" }}
                    onBack={() => window.history.back()}
                >
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <FiltersComponent
                                    handleProductFilter={handleProductFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={filterData}
                                    isFromProductReceived={true}
                                />
                            </Col>
                        </Row>
                    )}
                    <div style={{ marginTop: "10px" }}>
                        <Table
                            loading={isLoading}
                            rowKey="id"
                            bordered={true}
                            dataSource={HarvestJobCollectionData?.getData()}
                            columns={columns}
                            pagination={{
                                ...paginationConfig,
                                showTotal: (total) => (
                                    <div>
                                        Total{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                color: "green",
                                            }}
                                        >
                                            {total}
                                        </span>{" "}
                                        Items
                                    </div>
                                ),
                            }}
                            scroll={{ x: 1170 }}
                        />
                    </div>
                </PageHeader>
            </div>
            {selectedHarvest && (
                <Modal
                    title={`View Details (${formatString(selectedHarvest.getType())})`}
                    open={isShowCreateModal}
                    onCancel={() => {
                        setIsShowCreateModal(false);
                        setSelectedHarvest(undefined);
                    }}
                    onOk={() => {
                        setIsShowCreateModal(false);
                        setSelectedHarvest(undefined);
                    }}
                    width="60vw"
                >
                    {selectedHarvest && (
                        <ViewDetailsModal product={selectedHarvest} />
                    )}
                </Modal>
            )}
        </>
    );
};

export default HarvestJobManage;
