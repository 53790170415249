import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select, notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { IAgentWarehouseUpdateCommissionFormData } from "@/types/agentWarehouseCollection";
import { AgentWarehouseRateTypeEnums } from "@/enums/shared";
import {
    AgentWarehouseModel,
    ShippingCommissionRateModel,
} from "@/models/agentWarehouseCollectionModel";

import { renderOptionsFromEnumReversed } from "@/components/Form/forms";
import { showError } from "@/helpers/showError";
import { useGetRegionMinimal } from "@/lib/core-react/hooks/private/minimal/useRegionMinimal";
import { useUpdateCommission } from "@/lib/core-react/hooks/private/useAgentWarehouse";

interface IProps {
    selectedAgentWarehouse: AgentWarehouseModel;
    setIsShowUpdateCommissionModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const UpdateCommission = ({
    selectedAgentWarehouse,
    setIsShowUpdateCommissionModal,
}: IProps) => {
    const { getRegion, regionOptionsData } = useGetRegionMinimal();
    const { updateCommission, isLoading } = useUpdateCommission();

    const [form] = Form.useForm();
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

    useEffect(() => {
        getRegion("minimal");

        // Populate form with existing commission data if available, or add an empty form item if none exists
        if (selectedAgentWarehouse) {
            const existingCommissions =
                selectedAgentWarehouse.getShippingCommissions()?.getData() ||
                [];

            const formattedCommissions =
                existingCommissions.length > 0
                    ? existingCommissions.map(
                          (commission: ShippingCommissionRateModel) => ({
                              region_id: commission.getRegion().id,
                              rate: commission.getRate(),
                              rate_type: commission.getRateType(),
                          }),
                      )
                    : [{}]; // Add an empty object to show the form fields

            form.setFieldsValue({ commissions: formattedCommissions });
        }
    }, [selectedAgentWarehouse, form, getRegion]);

    // Check if the last form item has all required fields filled
    const checkLastFormFilled = () => {
        const values = form.getFieldsValue();
        const lastItem = values.commissions?.[values.commissions.length - 1];

        const isFilled =
            lastItem?.region_id && lastItem?.rate && lastItem?.rate_type;
        setIsAddButtonDisabled(!isFilled); // Disable Add button if fields are not filled
    };

    const onFinish = async (values: {
        commissions: IAgentWarehouseUpdateCommissionFormData[];
    }) => {
        const payload = values.commissions.map((commission) => ({
            rate: commission.rate,
            rate_type: commission.rate_type,
            region_id: commission.region_id,
        }));

        try {
            await updateCommission(selectedAgentWarehouse.getId(), payload);
            notification.success({
                message: "Update Agent warehouse successfully",
            });
            // reset form and close modal
            setIsShowUpdateCommissionModal(false);
            form.resetFields();
        } catch (error: any) {
            showError(error);
        }
    };

    return (
        <div>
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                name="Edit_Agent_Warehouse"
                onFieldsChange={checkLastFormFilled}
            >
                <Form.List name="commissions">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(
                                ({ key, name, ...restField }, index) => (
                                    <div
                                        key={key + index}
                                        style={{
                                            border: "1px solid #e8e8e8",
                                            padding: "16px",
                                            marginBottom: "8px",
                                            position: "relative",
                                        }}
                                    >
                                        {fields.length > 1 && (
                                            <Button
                                                type="text"
                                                onClick={() => remove(name)}
                                                icon={<CloseOutlined />}
                                                style={{
                                                    position: "absolute",
                                                    top: "8px",
                                                    right: "8px",
                                                    color: "white",
                                                    background: "red",
                                                    zIndex: 1,
                                                    width: "24px",
                                                    height: "24px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        )}

                                        <Form.Item
                                            {...restField}
                                            name={[name, "region_id"]}
                                            label="Region"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Region is required",
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Select a region">
                                                {regionOptionsData?.options?.map(
                                                    (region) => (
                                                        <Select.Option
                                                            key={region.value}
                                                            value={region.value}
                                                        >
                                                            {region.label}
                                                        </Select.Option>
                                                    ),
                                                )}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            {...restField}
                                            name={[name, "rate"]}
                                            label="Rate"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Rate is required",
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter your rate" />
                                        </Form.Item>

                                        <Form.Item
                                            {...restField}
                                            name={[name, "rate_type"]}
                                            label="Rate Type"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Rate Type is required",
                                                },
                                            ]}
                                        >
                                            <Select placeholder="Select a rate type">
                                                {renderOptionsFromEnumReversed(
                                                    AgentWarehouseRateTypeEnums,
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                ),
                            )}
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                block
                                disabled={isAddButtonDisabled} // Disable add button until form is filled
                            >
                                Add Commission to another region
                            </Button>
                        </>
                    )}
                </Form.List>

                <Form.Item>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%", marginTop: "8px" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default UpdateCommission;
