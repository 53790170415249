import { useEffect, useState } from "react";
import { Button, Form, Input, Select, Skeleton } from "antd";

import { DestinationWarehouseModel } from "@/models/destinationWarehouseCollectionModel";
import {
    useCreateDestinationWarehouse,
    useGetDestinationWarehouse,
    useUpdateDestinationWarehouse,
} from "@/lib/core-react/hooks/private";
import { DestinationWarehouseStatusEnum } from "@/enums/destinationWarehouseCollectionEnums";
import { IDestinationWarehouseForm } from "@/types/destinationWarehouseCollection";
import { slugMaker, transformEnumToLabeledValue } from "@/utils/helpers";
import { showError } from "@/helpers/showError";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";

interface IProps {
    onHide: () => void;
    data?: DestinationWarehouseModel;
}

const DestinationWarehouseModification = ({ onHide, data }: IProps) => {
    const { getDestinationWarehouse } = useGetDestinationWarehouse();

    const { createWarehouse, isLoading } = useCreateDestinationWarehouse();

    const { updateWarehouse, isLoading: isLoadingUpdate } =
        useUpdateDestinationWarehouse();

    const [form] = Form.useForm<IDestinationWarehouseForm>();

    const {
        allOptionsData: { countryOptionsData, regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const [selectedRegionId, setSelectedRegionId] = useState<
        number | undefined
    >(undefined);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
    }, []);

    useEffect(() => {
        if (data?.getRegion()?.getId()) {
            setSelectedRegionId(data?.getRegion()?.getId());
        }
    }, [data]);

    useEffect(() => {
        if (selectedRegionId) {
            onFetchFilterApi(filterResourceEnum.COUNTRY, {
                region_id: selectedRegionId,
            });
        }
    }, [selectedRegionId]);

    useEffect(() => {
        if (countryOptionsData.options.length) {
            form.setFieldValue(
                "country_id",
                countryOptionsData.options[0].value,
            );
        }
    }, [countryOptionsData]);

    const onFinishUpdate = async (value: IDestinationWarehouseForm) => {
        try {
            value.slug = slugMaker(form.getFieldValue("name"));
            if (data) {
                await updateWarehouse(data.getId(), value);
                await getDestinationWarehouse();
            }
            onHide();
        } catch (error) {
            showError(error, form);
        }
    };

    const onFinishCreate = async (value: IDestinationWarehouseForm) => {
        try {
            value.slug = slugMaker(form.getFieldValue("name"));
            await createWarehouse(value);
            await getDestinationWarehouse();
            onHide();
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form
            onFinish={data ? onFinishUpdate : onFinishCreate}
            form={form}
            layout="vertical"
            name={
                data
                    ? "Update Destination Warehouse"
                    : "Create Destination Warehouse"
            }
            initialValues={{
                name: data?.getName(),
                slug: data?.getSlug(),
                status: data?.getStatus(),
            }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Name" />
            </Form.Item>

            {!data && (
                <>
                    <Form.Item
                        rules={[
                            { required: true, message: "region is required" },
                        ]}
                        name="region_id"
                        label="Region"
                    >
                        {regionOptionsData.isLoading ? (
                            <Skeleton.Input
                                style={{ width: "300px" }}
                                active
                                size="small"
                            />
                        ) : (
                            <Select
                                loading={regionOptionsData.isLoading}
                                placeholder="Please select a region"
                                onChange={(value) => {
                                    // console.log(value,"value")
                                    // form.setFieldValue("region_id",value)
                                    setSelectedRegionId(value);
                                }}
                                options={regionOptionsData.options}
                            />
                        )}
                    </Form.Item>

                    <Form.Item
                        rules={[
                            { required: true, message: "Country is required" },
                        ]}
                        name="country_id"
                        label="Country"
                    >
                        {countryOptionsData.isLoading ? (
                            <Skeleton.Input
                                style={{ width: "300px" }}
                                active
                                size="small"
                            />
                        ) : (
                            <Select
                                loading={countryOptionsData.isLoading}
                                placeholder="Please select a country"
                                onChange={(value) => {
                                    form.setFieldValue("country_id", value);
                                }}
                                options={countryOptionsData.options}
                                disabled={!selectedRegionId}
                            />
                        )}
                    </Form.Item>
                </>
            )}

            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                name="status"
                label="Status"
            >
                <Select
                    placeholder="Please select a status"
                    options={transformEnumToLabeledValue(
                        DestinationWarehouseStatusEnum,
                    )}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading || isLoadingUpdate}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default DestinationWarehouseModification;
