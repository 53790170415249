import { ResponsePromise } from "@/lib/core/request/types/typings";
import { BaseResource } from "../public";
import axios from "axios";
import { Config } from "../../request/types/request";
import { ICampaignStrapiSlugData } from "@/types/campaignStrip";

class StrapiResource extends BaseResource {
    base_url: string;

    constructor(config: Config) {
        super(config);
        this.base_url = config.baseUrl;
    }

    getSlugCampaign(params?: string): ResponsePromise<ICampaignStrapiSlugData> {
        let path = `${this.base_url}/api/campaigns`;
        if (params) {
            path = `${path}?${params}`;
        }

        return axios.get(path);
    }
}

export default StrapiResource;
