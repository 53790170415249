export function formatNumber(value: number): string {
    if (value < 1000) {
        return value.toString();
    } else if (value < 1000000) {
        return Math.ceil(value / 1000) + "K";
    } else if (value < 1000000000) {
        return Math.ceil(value / 1000000) + "M";
    } else if (value < 1000000000000) {
        return Math.ceil(value / 1000000000) + "B";
    } else {
        return Math.ceil(value / 1000000000000) + "T";
    }
}
