import {
    RegionDataStatus,
    RegionEnum,
    RegionStoreType,
} from "@/enums/regionCollectionEnums";
import { LanguageModel } from "./language";
import { IFilterType } from "@/types/filters";
import { IRegionCollection, IRegion } from "@/types/regionCollection";
import { CountryModel } from "./countryCollectionModel";
import { CurrencyModel } from "./currencyCollectionModel";
import { IPagination } from "@/types/pagination";

export class RegionModel {
    object: string;
    id: number;
    store_type: RegionStoreType;
    store: string;
    code: string;
    name: string;
    status: RegionDataStatus;
    created_at: string;
    currency?: CurrencyModel | undefined;
    countries?: CountryModel[];
    languages?: LanguageModel | undefined;

    constructor(dataItem: IRegion) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.store_type = dataItem.store_type;
        this.store = dataItem.store;
        this.code = dataItem.code;
        this.name = dataItem.name;
        this.status = dataItem.status;
        this.created_at = dataItem.created_at;
        this.currency =
            dataItem.currency && new CurrencyModel(dataItem.currency);
        this.countries =
            dataItem.countries &&
            dataItem.countries.data.map((item) => new CountryModel(item));

        this.languages = dataItem.languages
            ? new LanguageModel(dataItem.languages)
            : undefined;
    }

    getId = () => this.id;
    getStoreType = () => this.store_type;
    getStore = () => this.store;
    getCode = () => this.code;
    getName = () => this.name;
    getStatus = () => this.status;
    getCreatedAt = () => this.created_at;
    getCurrency = () => this.currency;
    getCurrencyCode = () => this.currency?.code;
    getCountries = () => this.countries;
    getLanguages = () => this.languages;
}

export class RegionCollectionModel {
    object: RegionEnum.RegionCollection;
    data: RegionModel[];
    pagination: IPagination;
    filters: IFilterType;
    constructor(data: IRegionCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new RegionModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getRegionIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.name));
        return obj;
    }
    getRegionCodesAsObject() {
        const obj: { [key: string]: string } = {};
        this.data.forEach((item) => (obj[item.code] = item.code));
        return obj;
    }

    getRegionLabeledValue() {
        return this.data.map((item) => ({
            label: item.getCode(),
            value: item.getCode(),
            country: item.getCountries(),
        }));
    }
    getData = () => this.data;
    getPagination = () => this.pagination;
    getFilters = () => this.filters;
}
