import { FlowError } from "@ory/client";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import ory from "../pkg/sdk";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";

const AuthError = () => {
    const [error, setError] = useState<FlowError | string>();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const location = useLocation();

    // Get ?id=... from the URL
    const id = searchParams.get("id");

    useEffect(() => {
        // If the router is not ready yet, or we already have an error, do nothing.
        if (!location || error) {
            return;
        }

        ory.getFlowError({ id: String(id) })
            .then(({ data }) => {
                setError(data);
            })
            .catch((err: AxiosError) => {
                switch (err.response?.status) {
                    case 404:
                        // The error id could not be found. Let's just redirect home!
                        return;
                    case 403:
                        // The error id could not be fetched due to e.g. a CSRF issue. Let's just redirect home!
                        return;
                    case 410:
                        // The error id expired. Let's just redirect home!
                        navigate("/");
                }

                return Promise.reject(err);
            });
    }, [id, navigate, location, error]);

    if (!error) {
        return null;
    }

    return (
        <>
            <div>
                <h1>An error occurred</h1>
                {/*<CodeBox code={JSON.stringify(error, null, 2)} />*/}
                <code>{JSON.stringify(error, null, 2)}</code>
            </div>
            <div>
                <Link to="/">Go back</Link>
            </div>
        </>
    );
};

export default AuthError;
