import { useState } from "react";
import { Button, Form } from "antd";
import MeasurementsPart from "../MeasurementsPart";
interface IProps {
    formSubmit: (values: any) => void;
    initialData: any;
    current: number;
    prev: () => void;
    isLoading: boolean;
}
const ExchangeAndMeasurementDetailsForm = ({
    formSubmit,
    initialData,
    current,
    prev,
    isLoading,
}: IProps) => {
    const [form] = Form.useForm();
    const [showMeasurements, setShowMeasurements] = useState(
        initialData.measurement ? true : false,
    );

    const onFinish = (values: any) => {
        const processedValues: any = {};
        if (showMeasurements) {
            processedValues.measurement = values.measurement;
        } else {
            processedValues.measurement = undefined;
        }
        formSubmit(processedValues);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialData}
        >
            {/* Measurements */}
            <MeasurementsPart
                setShowMeasurements={setShowMeasurements}
                showMeasurements={showMeasurements}
                form={form}
            />

            <Form.Item
                style={{
                    marginTop: "40px",
                }}
            >
                {current > 0 && (
                    <Button
                        style={{
                            margin: "0 8px",
                        }}
                        onClick={() => prev()}
                        loading={isLoading}
                    >
                        Previous
                    </Button>
                )}
                <Button type="primary" loading={isLoading} htmlType="submit">
                    Save and Continue
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ExchangeAndMeasurementDetailsForm;
