import { Table, Image } from "antd";

const VariationTable = ({ variations }) => {
    const columns = [
        {
            title: "Images",
            dataIndex: "image",
            key: "image",
            render: (image: string | undefined) => {
                return (
                    <>
                        <Image
                            width={40}
                            src={image}
                            placeholder={
                                <Image
                                    preview={false}
                                    src={image}
                                    width={200}
                                />
                            }
                        />
                    </>
                );
            },
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            align: "center" as const,
            render: (_: string, record: any) => {
                return <>{record.quantity}</>;
            },
        },
        {
            title: "Orginal Unit Price",
            dataIndex: "original_unit_price",
            key: "original_unit_price",
            align: "center" as const,
            render: (_: string, record: any) => {
                return <>{record.original_unit_price}</>;
            },
        },
        {
            title: "Orginal Total Price",
            dataIndex: "original_total_price",
            key: "original_total_price",
            align: "center" as const,
            render: (_: string, record: any) => {
                return <>{record.original_total_price}</>;
            },
        },
        {
            title: "Unit Price/BDT",
            dataIndex: "unit_price",
            key: "unit_price",
            align: "center" as const,
            render: (_: string, record: any) => {
                return <>{record.unit_price}</>;
            },
        },
        {
            title: "Total Price/BDT",
            dataIndex: "total_price",
            key: "total_price",
            align: "center" as const,
            render: (_: string, record: any) => {
                return <>{record.total_price}</>;
            },
        },
    ];

    const dataSource = variations.map((variation) => ({
        key: variation.id,
        image: variation.image,
        quantity: variation.quantity,
        original_unit_price: variation.original_unit_price,
        original_total_price: variation.original_total_price,
        unit_price: variation.unit_price,
        total_price: variation.total_price,
    }));

    return (
        <Table
            bordered
            rowKey={(r) => r.id}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
        />
    );
};

export default VariationTable;
