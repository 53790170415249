import { CampaignResource } from "../../resources/private";
import ProductApiService from "@/lib/core/apiServices/productApiService";
class CampaignService extends ProductApiService {
    public campaignResource: CampaignResource;

    constructor() {
        super();

        this.campaignResource = new CampaignResource(this.config);
    }
}

export default CampaignService;
