import { useEffect } from "react";
import { Button, DatePicker, Form, Input, Select, notification } from "antd";
import {
    useCampaignList,
    useCreateCampaign,
} from "@/lib/core-react/hooks/private/useCampaign";
import TextArea from "antd/lib/input/TextArea";
import { renderOptionsFromEnumUpdateCampaignsPublished } from "@/components/Form/forms";
import { PublishStatusEnum } from "@/enums/campaignCollectionEnums";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { showError } from "@/helpers/showError";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { ICreateCampaignPayload } from "@/types/campaignCollection";
import { IModalData } from "@/helpers/getModalTital";

dayjs.extend(utc);
interface IProps {
    handleModal: (modal_data: IModalData) => void;
}

const CreateCampaign = ({ handleModal }: IProps) => {
    const { getCampaign } = useCampaignList();

    const { createCampaign, isLoading } = useCreateCampaign();
    const {
        allOptionsData: {
            regionOptionsData,
            storeOptionsData,
            strapiSlugCampaignOptionData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
        onFetchFilterApi(filterResourceEnum.STORE);
        onFetchFilterApi(filterResourceEnum.STRAPI_SLUG_CAMPAIGN);
    }, []);

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value: ICreateCampaignPayload) => {
        try {
            const payload: ICreateCampaignPayload = {
                ...value,
                start_at: dayjs
                    .utc(value.start_at)
                    .format("YYYY-MM-DD HH:mm:ss"),
                finish_at: dayjs
                    .utc(value.finish_at)
                    .format("YYYY-MM-DD HH:mm:ss"),
            };
            await createCampaign(payload);

            notification["success"]({
                message: "Created Campaign successfully",
            });
            await getCampaign();
            handleModal({
                action: false,
                data: null,
            });
            form.resetFields();
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Create Campaign"
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Store is required",
                    },
                ]}
                name={["store_id"]}
                label="Store"
            >
                <Select
                    loading={storeOptionsData.isLoading}
                    options={storeOptionsData.options}
                    placeholder="Please select a store"
                />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Region is required" }]}
                name={["region_id"]}
                label="Region"
            >
                <Select
                    loading={regionOptionsData.isLoading}
                    placeholder="Please select a region"
                    options={regionOptionsData.options}
                />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Title is required" }]}
                label="Title"
                name="title"
            >
                <TextArea rows={4} placeholder="Please write your title" />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "slug id is required" },
                    {
                        required: true,
                    },
                ]}
                label="Slug"
                name="slug"
            >
                <Input placeholder="Please write your slug" />
            </Form.Item>

            <Form.Item
                label="Strapi Content Slug"
                name="strapi_content_slug"
                rules={[{ required: true, message: "Slug is required" }]}
            >
                <Select
                    options={strapiSlugCampaignOptionData.options}
                    loading={strapiSlugCampaignOptionData.isLoading}
                    placeholder="Please select your Strapi Content Slug"
                />
            </Form.Item>

            <Form.Item
                name="publishing_status"
                label="Select Publishing Status"
                rules={[
                    {
                        required: true,
                        message: "The publishing status field is required.",
                    },
                ]}
            >
                <Select placeholder="Please select a Publishing Status">
                    {renderOptionsFromEnumUpdateCampaignsPublished(
                        PublishStatusEnum,
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                label="Start At (in UTC +0)"
                name="start_at"
                initialValue={dayjs().utc()} // Ensure UTC is used here
                rules={[
                    { required: true, message: "Please input the start date!" },
                ]}
            >
                <DatePicker
                    showTime={{
                        defaultValue: dayjs("00:00:00", "HH:mm:ss").utc(),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Form.Item>

            <Form.Item
                label="Finish At (in UTC +0)"
                name="finish_at"
                rules={[
                    {
                        required: true,
                        message: "Please input the finish date!",
                    },
                ]}
            >
                <DatePicker
                    showTime={{
                        defaultValue: dayjs("00:00:00", "HH:mm:ss").utc(),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Form.Item>
            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateCampaign;
