import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import { InvoicePaymentRuleResource } from "@/lib/core/resources/private";

class InvoicePaymentRuleService extends CommerceApiService {
    public invoiceRuleResource: InvoicePaymentRuleResource;

    constructor() {
        super();

        this.invoiceRuleResource = new InvoicePaymentRuleResource(this.config);
    }
}

export default InvoicePaymentRuleService;
