import { useEffect, useState } from "react";
import { useAtom } from "jotai/index";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Card,
    Col,
    Result,
    Button,
    Row,
    Flex,
    Dropdown,
    Typography,
    Descriptions,
    Tag,
    Modal,
} from "antd";
import {
    useCancelDispute,
    useCompleteRefund,
    useGetDispute,
    useGetDisputePreview,
} from "@/lib/core-react/hooks/private/useDispute";
import {
    disputeDetailsAtom,
    disputePreviewAtom,
} from "@/lib/core-react/store/store";
import {
    DisputeDetailsCollectionModel,
    DisputePreviewModel,
} from "@/models/disputeDetailsCollectionModel";
import { useNavigate, useParams } from "react-router-dom";
import MandatesTable from "./MandatesTable";
import { useGetBuyProduct } from "@/lib/core-react/hooks/private";
import { buyProductAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import CustomerInfo from "@/components/shared/Order/CustomerInfo";
import OrderInfo from "@/pages/BuyAndShip/components/OrderInfo";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { BuyProductInfo } from "@/pages/BuyAndShip/components";
import { tw } from "@/consts/theme/tailwindTheme";
import VariationsTable from "./VariationsTable";
import { showError } from "@/helpers/showError";
import {
    CheckCircleOutlined,
    DownOutlined,
    HddFilled,
} from "@ant-design/icons";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_REFUND_PERMISSION_ENUM } from "@/consts/permission-enum/refund-enum";
import { FiTrash } from "react-icons/fi";
import DisputeSettlementPreviewModal from "./DisputeSettlementPreviewModal";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import DisputeTransactionsTable from "./DisputeTransactionsTable";
const { Text } = Typography;

const RefundDisputeDetails = () => {
    const navigate = useNavigate();
    const isMobile = useWindowWidth();
    const { id, buyProductId } = useParams();
    const parsedId: number = Number(id);
    const parsedBuyProductId: number = Number(buyProductId);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDisputeId, setSelectedDisputeId] = useState<number>();

    const { getDispute } = useGetDispute();
    const { getDisputePreview } = useGetDisputePreview();
    const { completeRefund } = useCompleteRefund();
    const { cancelDispute } = useCancelDispute();

    const [{ data: disputeDetailsData, isLoading, error }] =
        useAtom(disputeDetailsAtom);
    const [{ data: disputePreviewData }] = useAtom(disputePreviewAtom);
    const [{ data: buyProductDetailData, isLoading: buyProductLoading }] =
        useAtom(buyProductAtom);

    const uiActions = disputeDetailsData?.data?.ui_actions;

    const { getBuyProduct } = useGetBuyProduct();

    const disputeDetailsCollectionModelData =
        disputeDetailsData &&
        new DisputeDetailsCollectionModel(disputeDetailsData);
    const buyProduct =
        buyProductDetailData && new BuyProductModel(buyProductDetailData);
    const disputeDetails =
        disputeDetailsCollectionModelData &&
        disputeDetailsCollectionModelData.getData();

    const disputeItems =
        disputeDetails && disputeDetails?.getItems()?.getData();

    const mandates =
        disputeDetailsCollectionModelData &&
        disputeDetailsCollectionModelData?.getData()?.getMandates();
    const disputePreview =
        disputePreviewData && new DisputePreviewModel(disputePreviewData);

    // Update the handleCompleteRefund function
    const handleCompleteRefund = async (id: number) => {
        setSelectedDisputeId(id);
        setIsModalVisible(true);
    };

    const onConfirmCompleteRefund = async (id: number) => {
        try {
            await completeRefund(id);
            await getDispute(parsedId);
        } catch (error) {
            showError(error);
        }
    };

    const handleCancelDispute = async (id: number) => {
        const modal = Modal.confirm({
            title: "Are you sure you want to cancel this refund?",
            content: "Press Yes to confirm",
            okText: "Yes",
            cancelText: "No",
            onOk: async () => {
                try {
                    await cancelDispute(id);
                    await getDispute(parsedId);
                } catch (error) {
                    showError(error);
                }
            },
            onCancel: () => {
                modal.destroy();
            },
        });
    };

    useEffect(() => {
        getDispute(parsedId);
        getBuyProduct(parsedBuyProductId);
        getDisputePreview(parsedId);
    }, [parsedId]);

    if (error) {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={
                    <Button
                        onClick={() => window.history.back()}
                        type="primary"
                    >
                        Back Home
                    </Button>
                }
            />
        );
    }

    const cardStyle = {
        borderRadius: tw.borderRadius.lg,
        boxShadow: tw.boxShadow.DEFAULT,
    };

    const menuItems: ExtendedMenuItemType[] = [
        ...(uiActions?.can_complete
            ? [
                  {
                      permission:
                          ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_COMPLETE,
                      label: "Complete",
                      key: "complete",
                      icon: <CheckCircleOutlined />,
                      onClick: () => {
                          handleCompleteRefund(parsedId);
                      },
                  },
              ]
            : []),
        ...(uiActions?.can_cancel
            ? [
                  {
                      key: "cancel_refund",
                      icon: <FiTrash style={{ color: "red" }} />,
                      permission:
                          ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_VIEW,
                      label: (
                          <>
                              <Text type="danger">Cancel Refund</Text>
                          </>
                      ),
                      onClick: () => {
                          handleCancelDispute(parsedId);
                      },
                  },
              ]
            : []),
    ];

    return (
        <Card loading={isLoading}>
            <PageHeader
                ghost={false}
                onBack={() => navigate("/refund/disputes")}
                title={"Refund Details"}
                style={{ marginTop: "10px" }}
                extra={[
                    <Dropdown
                        menu={{
                            items: menuItems.filter((item) => item !== null),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>,
                ]}
            >
                {/* Buy product Start */}

                {buyProduct && !isLoading ? (
                    <>
                        <Flex
                            style={{ width: "100%" }}
                            justify="space-between"
                            gap={24}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    marginRight: isMobile ? 0 : 24,
                                }}
                            >
                                <Flex gap={24} vertical>
                                    <Card
                                        title="Product Info"
                                        style={{ ...cardStyle, width: "100%" }}
                                    >
                                        <BuyProductInfo
                                            isDetailPage={true}
                                            buyProduct={buyProduct}
                                        />
                                    </Card>
                                </Flex>
                            </div>
                            <div style={{ flex: 1 }}>
                                <Flex gap={24} vertical>
                                    {buyProduct &&
                                        buyProduct
                                            ?.getOrder()
                                            ?.getCustomer() && (
                                            <Card
                                                title="Customer"
                                                style={{
                                                    ...cardStyle,
                                                    width: "100%",
                                                }}
                                            >
                                                <CustomerInfo
                                                    user={
                                                        buyProduct
                                                            ?.getOrder()
                                                            ?.getCustomer() as any
                                                    }
                                                />
                                            </Card>
                                        )}
                                    <Card
                                        title="Order Info"
                                        style={{ ...cardStyle, width: "100%" }}
                                    >
                                        <OrderInfo
                                            oderNumber={buyProduct
                                                ?.getOrder()
                                                ?.getOrderNumber()}
                                            oderStatus={buyProduct
                                                ?.getOrder()
                                                ?.getOrderStatus()}
                                            oderType={buyProduct
                                                ?.getOrder()
                                                ?.getOrderType()}
                                            orderCreatedAt={buyProduct
                                                ?.getOrder()
                                                ?.getCreatedAt()}
                                            paymentStatus={buyProduct
                                                ?.getOrder()
                                                ?.getPaymentStatus()}
                                        />
                                    </Card>
                                </Flex>
                            </div>
                        </Flex>
                    </>
                ) : (
                    <></>
                )}

                {/* Buy product End */}

                {/* Attachments */}

                <div>
                    {disputeDetails && disputeDetails.getAttachments() && (
                        <Card
                            title="Attachments"
                            style={{ ...cardStyle, marginTop: 24 }}
                        >
                            <Row gutter={24}>
                                {disputeDetails
                                    .getAttachments()
                                    .getData()
                                    .map((item) => (
                                        <Col
                                            span={4}
                                            style={{ marginBottom: 12 }}
                                        >
                                            <Card
                                                hoverable
                                                style={{ width: "100%" }}
                                                cover={
                                                    <>
                                                        {item.getType() ===
                                                        "image" ? (
                                                            <ImageWithPlaceholder
                                                                alt="attachment"
                                                                src={item.getSrc()}
                                                                style={{
                                                                    width: "300px",
                                                                    height: "200px",
                                                                }}
                                                            />
                                                        ) : (
                                                            <video
                                                                src={item.getSrc()}
                                                                style={{
                                                                    width: "300px",
                                                                    height: "200px",
                                                                }}
                                                                controls
                                                            />
                                                        )}
                                                    </>
                                                }
                                            >
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        window.open(
                                                            item.getSrc(),
                                                        );
                                                    }}
                                                >
                                                    View
                                                </Button>
                                            </Card>
                                        </Col>
                                    ))}
                            </Row>
                        </Card>
                    )}
                    <Card
                        title="Dispute Information"
                        style={{ ...cardStyle, marginTop: 24 }}
                    >
                        <Descriptions column={1}>
                            <Descriptions.Item
                                label={<Text strong>Refund Reason</Text>}
                            >
                                {disputeDetails?.getReason()}
                            </Descriptions.Item>

                            <Descriptions.Item
                                label={<Text strong>Description</Text>}
                            >
                                {disputeDetails?.getDescription()}
                            </Descriptions.Item>

                            <Descriptions.Item
                                label={<Text strong>Received Item</Text>}
                            >
                                <Tag
                                    color={
                                        disputeDetails?.getReceivedItem()
                                            ? "green"
                                            : "red"
                                    }
                                >
                                    {disputeDetails?.getReceivedItem()
                                        ? "Yes"
                                        : "No"}
                                </Tag>
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </div>

                {/* Attachments End */}

                {/* Dispute details start */}
                <div style={{ marginTop: 24, paddingBottom: "24px" }}>
                    {disputeItems && buyProduct && disputePreview ? (
                        <>
                            <div
                                style={{
                                    marginTop: "16px",
                                    fontWeight: "bold",
                                    fontSize: "1rem",
                                }}
                            >
                                Dispute Details
                            </div>
                            <VariationsTable
                                buyProduct={buyProduct}
                                isInsideModal={false}
                                disputeItems={disputeItems}
                                disputePreview={disputePreview}
                                can_complete={uiActions?.can_complete}
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </div>

                {/* Dispute details end */}

                {/* Mandate list */}
                {!isLoading && mandates && mandates.length ? (
                    <div
                        style={{
                            marginTop: 8,
                            paddingBottom: 24,
                        }}
                    >
                        <Card loading={isLoading || buyProductLoading}>
                            <Row gutter={20}>
                                <Col>
                                    <MandatesTable
                                        mandates={mandates}
                                        currency={
                                            buyProduct
                                                ?.getRegion()
                                                ?.currency?.getCode() || ""
                                        }
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                ) : (
                    <></>
                )}
                {/* Mandate list end */}

                {/* Transactions */}
                {!isLoading &&
                disputeDetails?.getWalletAssociations() &&
                disputeDetails?.getWalletAssociations()?.getData() ? (
                    <div
                        style={{
                            marginTop: 24,
                            paddingBottom: "24px",
                        }}
                    >
                        <DisputeTransactionsTable
                            data={disputeDetails
                                ?.getWalletAssociations()
                                ?.getData()}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </PageHeader>
            {isModalVisible && (
                <DisputeSettlementPreviewModal
                    isVisible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    disputeId={selectedDisputeId}
                    onCompleteRefund={onConfirmCompleteRefund}
                    currency={buyProduct?.getRegion()?.currency?.getCode()}
                />
            )}
        </Card>
    );
};

export default RefundDisputeDetails;
