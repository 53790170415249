import {
    ICustomerTrackingEventAcl,
    ICustomerTrackingEventAclValue,
    IRoleTrackingEventAcl,
    IRoleTrackingEventAclValue,
    ITemplateConfiguration,
    ITemplateConfigurationValueCollection,
    ITemplateConfigurationValueItem,
    ITimelineTracking,
    ITracker,
    ITrackingEvent,
    ITrackingEventAclCollection,
    ITrackingEventCollection,
    ITrackingEventMention,
    ITrackingEventMentionCollection,
    ITrackingEventMentionUser,
} from "@/types/timelineTracking";

export class TemplateConfigurationValueItemModel {
    name: string;
    type: string;
    value: string;
    object: string;

    constructor(data: ITemplateConfigurationValueItem) {
        this.name = data.name;
        this.type = data.type;
        this.value = data.value;
        this.object = data.object;
    }

    getName = () => this.name;
    getType = () => this.type;
    getValue = () => this.value;
}

export class TemplateConfigurationValueCollectionModel {
    object: string;
    data: TemplateConfigurationValueItemModel[];

    constructor(data: ITemplateConfigurationValueCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (data) => new TemplateConfigurationValueItemModel(data),
        );
    }

    getData = () => this.data;
}

export class TemplateConfigurationModel {
    values: TemplateConfigurationValueCollectionModel;

    constructor(data: ITemplateConfiguration) {
        this.values = new TemplateConfigurationValueCollectionModel(
            data.values,
        );
    }

    getValues = () => this.values;
}

export class CustomerTrackingEventAclValue {
    object: string;
    id: number;
    name: string;
    phone: string | null;
    email: string;
    type: string;

    constructor(data: ICustomerTrackingEventAclValue) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.phone = data.phone;
        this.email = data.email;
        this.type = data.type;
    }

    getId = () => this.id;
    getName = () => this.name;
    getPhone = () => this.phone;
    getEmail = () => this.email;
    getType = () => this.type;
}

export class CustomerTrackingEventAclModel {
    object: string;
    id: number;
    acl_type: "user";
    acl_value: CustomerTrackingEventAclValue;

    constructor(data: ICustomerTrackingEventAcl) {
        this.object = data.object;
        this.id = data.id;
        this.acl_type = data.acl_type;
        this.acl_value = new CustomerTrackingEventAclValue(data.acl_value);
    }

    getId = () => this.id;
    getAclType = () => this.acl_type;
    getAclValue = () => this.acl_value;
}

export class RoleTrackingEventAclValue {
    object: string;
    id: number;
    name: string;
    label: string | null;

    constructor(data: IRoleTrackingEventAclValue) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.label = data.label;
    }

    getId = () => this.id;
    getName = () => this.name;
    getLabel = () => this.label;
}

export class RoleTrackingEventAclModel {
    object: string;
    id: number;
    acl_type: "role";
    acl_value: RoleTrackingEventAclValue;

    constructor(data: IRoleTrackingEventAcl) {
        this.object = data.object;
        this.id = data.id;
        this.acl_type = data.acl_type;
        this.acl_value = new RoleTrackingEventAclValue(data.acl_value);
    }

    getId = () => this.id;
    getAclType = () => this.acl_type;
    getAclValue = () => this.acl_value;
}

export class TrackingEventAclCollectionModel {
    object: string;
    data: (RoleTrackingEventAclModel | CustomerTrackingEventAclModel)[];

    constructor(data: ITrackingEventAclCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => {
            if (item.acl_type === "role") {
                return new RoleTrackingEventAclModel(
                    item as IRoleTrackingEventAcl,
                );
            } else if (item.acl_type === "user") {
                return new CustomerTrackingEventAclModel(
                    item as ICustomerTrackingEventAcl,
                );
            } else {
                throw new Error(`Invalid acl_type`);
            }
        });
    }

    getData = () => this.data;
    getUserData = () => this.data.filter((d) => d.getAclType() === "user");
    getRoleData = () => this.data.filter((d) => d.getAclType() === "role");
}

export class TrackingEventMentionUserModel {
    object: string;
    id: number;
    name: string;
    phone: string | null;
    email: string;
    type: string;

    constructor(data: ITrackingEventMentionUser) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.phone = data.phone;
        this.email = data.email;
        this.type = data.type;
    }

    getName = () => this.name;
    getId = () => this.id;
}

export class TrackingEventMentionModel {
    object: string;
    id: number;
    user: TrackingEventMentionUserModel;

    constructor(data: ITrackingEventMention) {
        this.object = data.object;
        this.id = data.id;
        this.user = new TrackingEventMentionUserModel(data.user);
    }

    getId = () => this.id;
    getUser = () => this.user;
}

export class TrackingEventMentionCollectionModel {
    object: string;
    data: TrackingEventMentionModel[];

    constructor(data: ITrackingEventMentionCollection) {
        this.object = data.object;
        this.data = data.data.map((d) => new TrackingEventMentionModel(d));
    }

    getData = () => this.data;
}

export class TrackingEventModel {
    object: string;
    id: number;
    label: null;
    message: string | null;
    template: string | null;
    template_configuration: TemplateConfigurationModel | null;
    display_order: number;
    children: TrackingEventModel[];
    acls: TrackingEventAclCollectionModel;
    mentions: TrackingEventMentionCollectionModel;
    created_at: string;

    constructor(data: ITrackingEvent) {
        this.object = data.object;
        this.id = data.id;
        this.label = data.label;
        this.message = data.message;
        this.template = data.template;
        this.template_configuration = data.template_configuration
            ? new TemplateConfigurationModel(data.template_configuration)
            : null;
        this.display_order = data.display_order;
        this.children = data.children.map((c) => new TrackingEventModel(c));
        this.acls = new TrackingEventAclCollectionModel(data.acls);
        this.mentions = new TrackingEventMentionCollectionModel(data.mentions);
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getLabel = () => this.label;
    getMessage = () => this.message;
    getTemplate = () => this.template;
    getTemplateConfiguration = () => this.template_configuration;
    getDisplayOrder = () => this.display_order;
    getChildren = () => this.children;
    getAcls = () => this.acls;
    getMentions = () => this.mentions;
    getCreatedAt = () => this.created_at;

    getCompletedTemplate = () => {
        const data = this.getTemplateConfiguration()?.getValues().getData();
        let result = this.getTemplate();
        if (data && result) {
            data.forEach(({ name, value }) => {
                const placeholder = `{{${name}}}`;
                result = result ? result.replace(placeholder, value) : "";
            });
        }

        return result;
    };
}

export class TrackingEventCollectionModel {
    object: string;
    data: TrackingEventModel[];

    constructor(data: ITrackingEventCollection) {
        this.object = data.object;
        this.data = data.data.map((d) => new TrackingEventModel(d));
    }

    getData = () => this.data;
}

export class TrackerModel {
    object: string;
    id: number;
    track_for: string;
    tracking_events: TrackingEventCollectionModel;

    constructor(data: ITracker) {
        this.object = data.object;
        this.id = data.id;
        this.track_for = data.track_for;
        this.tracking_events = new TrackingEventCollectionModel(
            data.tracking_events,
        );
    }

    getId = () => this.id;
    getTrackFor = () => this.track_for;
    getTrackingEvents = () => this.tracking_events;
}

export class TimelineTrackingModel {
    data: TrackerModel;
    constructor(data: ITimelineTracking) {
        this.data = new TrackerModel(data.data);
    }
    getData = () => this.data;
    getTrackingEventsData = () => this.data.tracking_events.data;
}
