import { useEffect } from "react";
import { Button, Form, Select } from "antd";

import { DestinationWarehouseMemberCollectionModel } from "@/models/destinationWarehouseCollectionModel";
import {
    useGetDestinationWarehouse,
    useGetDestinationWarehouseMembers,
    useGetRole,
} from "@/lib/core-react/hooks/private";
import { DestinationWarehouseMemberStatus } from "@/enums/destinationWarehouseCollectionEnums";
import { IDestinationWarehouseMemberForm } from "@/types/destinationWarehouseCollection";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import { useDestinationWarehouseMemberUpdate } from "@/lib/core-react/hooks/private";
import { useAtom } from "jotai";
import {
    destinationWarehouseMemberCollectionAtom,
    roleAtom,
} from "@/lib/core-react/store/store";
import { RoleCollectionModel } from "@/models/roleCollectionModel";
import { showError } from "@/helpers/showError";

interface IProps {
    onHide: () => void;
    destinationWarehouseId: number;
    destinationWarehouseMemberId: number;
}

export const DestinationWarehouseMemberModification = ({
    onHide,
    destinationWarehouseId,
    destinationWarehouseMemberId,
}: IProps) => {
    const { getDestinationWarehouseMembers } =
        useGetDestinationWarehouseMembers(destinationWarehouseId);
    const { getRole } = useGetRole();
    const { getDestinationWarehouse } = useGetDestinationWarehouse();

    const [{ data: memberCollectionData, isLoading: isLoadingMembers }] =
        useAtom(destinationWarehouseMemberCollectionAtom);
    const MemberCollectionData =
        memberCollectionData &&
        new DestinationWarehouseMemberCollectionModel(memberCollectionData);

    const [{ data: roleCollectionData, isLoading: isLoadingRole }] =
        useAtom(roleAtom);
    const RoleCollectionData =
        roleCollectionData && new RoleCollectionModel(roleCollectionData);

    const { updateMember, isLoading: isLoadingUpdate } =
        useDestinationWarehouseMemberUpdate();

    const [form] = Form.useForm<IDestinationWarehouseMemberForm>();

    useEffect(() => {
        form.resetFields();
    }, [destinationWarehouseId, destinationWarehouseMemberId]);

    useEffect(() => {
        getRole(`destination_warehouse_id=${destinationWarehouseId}`);
        getDestinationWarehouse("per_page=200");
    }, []);

    useEffect(() => {
        if (MemberCollectionData) {
            form.setFieldValue(
                "status",
                MemberCollectionData.getMemberById(
                    destinationWarehouseMemberId,
                )?.getStatus(),
            );
            form.setFieldValue(
                "role_ids",
                MemberCollectionData.getMemberById(destinationWarehouseMemberId)
                    ?.getRoles()
                    .getRoleIds(),
            );
        }
    }, [MemberCollectionData]);

    const onFinishUpdate = async (value: IDestinationWarehouseMemberForm) => {
        try {
            if (destinationWarehouseMemberId) {
                await updateMember(
                    destinationWarehouseId,
                    destinationWarehouseMemberId,
                    value,
                );
                onHide();
                await getDestinationWarehouseMembers("per_page=200");
            }
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form
            style={{ marginTop: "30px", padding: "0px 16px" }}
            onFinish={onFinishUpdate}
            form={form}
            layout="vertical"
            name="Update Destination Warehouse Member"
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Status is required",
                    },
                ]}
                label="Status"
                name="status"
            >
                <Select
                    placeholder="Please select a status"
                    options={transformEnumToLabeledValue(
                        DestinationWarehouseMemberStatus,
                    )}
                />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Role is required" }]}
                name="role_ids"
                label="Roles"
            >
                <Select
                    loading={isLoadingRole}
                    mode="multiple"
                    placeholder="Please select a role"
                    options={RoleCollectionData?.getRolesLabeledValue()}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoadingUpdate || isLoadingMembers}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};
