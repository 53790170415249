import { Flex, Table, Tag, Tooltip, Typography } from "antd";
import React from "react";
import { StatusTag } from "@/components";
import { formatDateTime } from "@/utils/helpers";
import { ColumnsType } from "antd/es/table";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { TransactionsModel } from "@/models/invoiceCollectionModel";

interface InvoiceTransactionProps {
    transaction: TransactionsModel[];
}

const { Text } = Typography;
const InvoiceSummaryTransaction: React.FC<InvoiceTransactionProps> = ({
    transaction,
}) => {
    const columns: ColumnsType<TransactionsModel> = [
        {
            title: "Transaction Number",
            dataIndex: "transactionNumber",
            key: "transactionNumber",
            render: (transactionNumber: string, record) => (
                <Flex vertical gap={6}>
                    <Text copyable>{transactionNumber}</Text>
                    <Tooltip
                        title={record.getDescription()} // Full name in tooltip
                    >
                        <Tag
                            color="blue-inverse"
                            style={{
                                maxWidth: "110px", // Set maximum width as needed
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                display: "inline-block",
                            }}
                        >
                            {record.getDescription()}
                        </Tag>
                    </Tooltip>
                </Flex>
            ),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render(_, record) {
                return (
                    <Text copyable>
                        {roundToTwoDecimal(record.getAmount())}
                    </Text>
                );
            },
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (_type: string, record) => (
                <StatusTag text={record.getType()} slug={record.getType()} />
            ),
        },

        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (_text: string, record) => (
                <Tag color="purple">
                    {formatDateTime(record.getCreatedAt())}
                </Tag>
            ),
        },
    ];

    return (
        <>
            <Table
                columns={columns}
                dataSource={transaction}
                pagination={false}
                rowKey={"id"}
                size="small"
                scroll={{ x: 800 }}
            />
        </>
    );
};

export default InvoiceSummaryTransaction;
