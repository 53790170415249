import {
    Button,
    Col,
    Flex,
    Form,
    FormInstance,
    InputNumber,
    notification,
    Row,
    Typography,
} from "antd";
import { useUpdateFx } from "@/lib/core-react/hooks/private/usePurchase";
import React, { useEffect, useState } from "react";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import { IUpdateFax } from "../../types.";
import { showError } from "@/helpers/showError";
import { IModalData } from "@/helpers/getModalTital";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import useRefetch from "@/hooks/useRefetch";

interface IProps {
    fxData: IUpdateFax;
    handleModal: (payload: IModalData) => void;
}

const UpdateFxForm = ({ fxData, handleModal }: IProps) => {
    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();
    const { updateFx, isLoading } = useUpdateFx();
    const [fxRate, setFxRate] = useState(fxData.fxRate);
    const { Text } = Typography;
    const [totalValue, setTotalValue] = useState<number | string | null>();
    const [commissionRate, setCommissionRate] = useState<number | null>(0);
    const { refetchListApi } = useRefetch();
    const onFinish = async (values: {
        fx_rate: number;
        commission_rate: number;
    }) => {
        const payload = {
            fx_rate: values.fx_rate,
            commission_rate: values.commission_rate || null,
        };

        try {
            await updateFx(fxData.product_id, payload);
            handleModal({
                data: null,
                action: false,
            });
            notification["success"]({
                message: "Update fx successfully",
            });
            refetchListApi();
        } catch (error) {
            showError(error, form);
        }
    };

    useEffect(() => {
        setTotalValue(fxData.totalFxAndCommission);
    }, [fxData]);

    useEffect(() => {
        form.resetFields();
    }, [form, fxData]);

    const handleFxRateChange = (value: number) => {
        setFxRate(value);
    };

    const handleCommissionRateChange = (value: number | null) => {
        setCommissionRate(value);
    };

    const calculateTotalValue = (_changedValues: any, allValues: any) => {
        const { fx_rate, commission_rate } = allValues;
        if (fx_rate && commission_rate && fxData.commission_type) {
            let total = 0;
            if (fxData.commission_type === CommissionRateTypeEnums.PERCENTAGE) {
                total = fx_rate + (fx_rate * commission_rate) / 100;
            } else {
                total = fx_rate + commission_rate;
            }
            setTotalValue(total.toFixed(2));
        }
    };

    return (
        <>
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                name="update_Fx"
                ref={formRef}
                initialValues={{
                    fx_rate: fxData.fxRate,
                    commission_rate: fxData.commission_rate,
                }}
                onValuesChange={calculateTotalValue}
            >
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Form.Item label="FX Rate" name="fx_rate">
                            <InputNumber
                                min={0}
                                value={fxRate}
                                onChange={(value) => {
                                    if (value !== null) {
                                        handleFxRateChange(value);
                                    }
                                }}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label="Commission Rate"
                            name="commission_rate"
                        >
                            <InputNumber
                                min={0}
                                value={commissionRate}
                                onChange={(value) => {
                                    if (value !== null) {
                                        handleCommissionRateChange(value);
                                    }
                                }}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {totalValue !== null && (
                    <Row gutter={16} style={{ marginBottom: "16px" }}>
                        <Col span={24} style={{ textAlign: "center" }}>
                            <Flex gap={2} justify="center">
                                <Text>Total : </Text>
                                <Text strong>
                                    {roundToTwoDecimal(Number(totalValue))}
                                </Text>
                                <Text strong>{fxData.currencyCode}</Text>
                            </Flex>
                        </Col>
                    </Row>
                )}
                <Form.Item noStyle>
                    <Flex justify="end">
                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        </>
    );
};

export default UpdateFxForm;
