import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import {
    IImpersonateSession,
    IImpersonateSessionCollection,
    IMinimalUsers,
    IMinimalUsersCollection,
    ImpersonateUserPool,
    IRole,
    IRoleCollection,
    IUser,
    IUserCollection,
} from "@/types/userCollection";
import { formatDateTime } from "@/utils/helpers";

export class RoleCollectionModel {
    public readonly object: string;
    public readonly data: RoleModel[];

    constructor(data: IRoleCollection) {
        this.object = data.object;
        this.data = data.data.map((role) => new RoleModel(role));
    }

    getAllRoleNames() {
        return this.data.map((role) => role.getName());
    }

    getAllRoleIds(): number[] {
        return this.data.map((role) => role.getId());
    }
}

export class RoleModel {
    public readonly object: string;
    public readonly id: number;
    public readonly name: string;
    public readonly label: string | null;
    public readonly access: string;

    constructor(data: IRole) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.label = data.label;
        this.access = data.access;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getLabel() {
        return this.label;
    }

    getAccess() {
        return this.access;
    }
}
export class UserModel {
    public readonly object: string;
    public readonly id: number;
    public readonly name: string;
    public readonly phone: string | null;
    public readonly email: string;
    public readonly emailVerifiedAt: string | null;
    public readonly type: string;
    public readonly is_active: number;
    public readonly roles?: RoleCollectionModel;
    public readonly created_at: string;
    public readonly is_drop_user?: boolean | undefined;
    public readonly shipping_mark?: string | undefined;

    constructor(data: IUser) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.phone = data.phone;
        this.email = data.email;
        this.emailVerifiedAt = data.emailVerifiedAt;
        this.type = data.type;
        this.is_active = data.is_active;
        this.roles = data.roles
            ? new RoleCollectionModel(data.roles)
            : undefined;
        this.created_at = data.created_at;
        this.is_drop_user = data.is_drop_user;
        this.shipping_mark = data.shipping_mark;
    }

    getAllRoleIds() {
        return this.id;
    }
    getId() {
        return this.id;
    }
    getName(): string {
        return this.name;
    }

    getShippingMark() {
        return this.shipping_mark;
    }

    getIsDropUser() {
        return this.is_drop_user;
    }

    getPhone(): string | null {
        return this.phone;
    }

    getEmail(): string {
        return this.email;
    }

    getEmailVerifiedAt(): string | null {
        return this.emailVerifiedAt;
    }

    getType(): string {
        return this.type;
    }

    getIsActive(): number {
        return this.is_active;
    }

    getCreatedAt(): string {
        return this.created_at;
    }
}

export class UserCollectionModel {
    public readonly object: string;
    public readonly data: UserModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: IUserCollection) {
        this.object = data.object;
        this.data = data.data.map((user) => new UserModel(user));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

    getOrderHandlerIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.name));
        return obj;
    }
}

export class MiniMulUsersModel {
    private readonly name: string;
    private readonly email: string;
    private readonly object: "UserMinimal";
    private readonly id: number;

    constructor(data: IMinimalUsers) {
        this.name = data.name;
        this.email = data.email;
        this.id = data.id;
        this.object = data.object;
    }

    getName() {
        return this.name;
    }
    getEmail() {
        return this.email;
    }

    getId() {
        return this.id;
    }
}

export class MinimalUsersCollectionModel {
    public readonly object: string;
    private readonly data: MiniMulUsersModel[];
    private readonly pagination: IPagination;
    private readonly filters: IFilterType;

    constructor(data: IMinimalUsersCollection) {
        this.object = data.object;
        this.data = data.data.map((user) => new MiniMulUsersModel(user));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

    getData() {
        return this.data;
    }

    getPagination() {
        return this.pagination;
    }
    getFilters() {
        return this.filters;
    }
}

class ImpersonateUserPoolModel {
    public object: string;
    private id: number;
    private user_type: string;
    private user: UserModel;
    private status: string;
    public created_at: string;

    constructor(data: ImpersonateUserPool) {
        this.object = data.object;
        this.id = data.id;
        this.user_type = data.user_type;
        this.user = new UserModel(data.user);
        this.status = data.status;
        this.created_at = data.created_at;
    }
    getId() {
        return this.id;
    }
    getUserType() {
        return this.user_type;
    }
    getUser() {
        return this.user;
    }

    getStatus() {
        return this.status;
    }
}

export class ImpersonateSessionModel {
    public object: string;
    private id: number;
    private pool: ImpersonateUserPoolModel;
    private impersonatee: UserModel;
    private impersonator: UserModel;
    private acquired_by: UserModel;
    private expired_at: string;
    private status: string;
    private created_at: string;

    constructor(data: IImpersonateSession) {
        this.object = data.object;
        this.id = data.id;
        this.pool = new ImpersonateUserPoolModel(data.pool);
        this.impersonatee = new UserModel(data.impersonatee);
        this.impersonator = new UserModel(data.impersonator);
        this.acquired_by = new UserModel(data.acquired_by);
        this.created_at = data.created_at;
        this.expired_at = data.expired_at;
        this.status = data.status;
    }
    getId() {
        return this.id;
    }
    getPool() {
        return this.pool;
    }
    getImpersonate() {
        return this.impersonatee;
    }
    getImpersonator() {
        return this.impersonator;
    }
    getAcquiredBy() {
        return this.acquired_by;
    }
    getStatus() {
        return this.status;
    }
    getExpireAt() {
        return formatDateTime(this.expired_at);
    }
    getCreatedAt() {
        return formatDateTime(this.created_at);
    }
}

export class ImpersonateSessionCollectionModel {
    public readonly object: "UserCollection";
    public readonly data: ImpersonateSessionModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: IImpersonateSessionCollection) {
        this.object = data.object;
        this.data = data.data.map((x) => new ImpersonateSessionModel(x));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
}
