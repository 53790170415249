import React, { useState } from "react";
import {
    Button,
    Checkbox,
    Col,
    notification,
    Row,
    Space,
    Table,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import type { TableColumnsType } from "antd";
import { tw } from "@/consts/theme/tailwindTheme";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { BuyProductModel } from "@/models/buyProductCollectionModel";

import {
    ApprovePurchasePayload,
    useApproveDispute,
} from "@/lib/core-react/hooks/private/useDispute";
import { useParams } from "react-router-dom";
import { showError } from "@/helpers/showError";
import {
    DisputeItemModel,
    DisputePreviewModel,
} from "@/models/disputeDetailsCollectionModel";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_REFUND_PERMISSION_ENUM } from "@/consts/permission-enum/refund-enum";
import { StatusTag } from "@/components";

interface Props {
    buyProduct: BuyProductModel;
    isInsideModal?: boolean;
    disputeItems: DisputeItemModel[];
    disputePreview: DisputePreviewModel;
    can_complete?: boolean;
}

const VariationsTable: React.FC<Props> = ({
    buyProduct,
    disputeItems,
    disputePreview,
    can_complete,
}) => {
    const { Paragraph, Text } = Typography;
    const { approveDispute, isLoading: isDisputeLoading } = useApproveDispute();
    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    const columnsTitleStyle = {
        fontSize: tw.fontSize.sm.fontSize,
        lineHeight: tw.fontSize.sm.lineHeight,
    };

    const onHeaderCellStyle = () => ({
        style: {
            padding: tw.spacing[1],
        },
    });

    const handleCheckboxChange = (itemId: number, checked: boolean) => {
        if (checked) {
            setSelectedItems([...selectedItems, itemId]);
        } else {
            setSelectedItems(selectedItems.filter((id) => id !== itemId));
        }
    };
    const { id: disputeId } = useParams();
    const parsedId: number = Number(disputeId);

    const handleApprove = async () => {
        // onApprove(selectedItems);
        try {
            const payload: ApprovePurchasePayload = {
                items: selectedItems.map((id) => ({ id })),
            };
            await approveDispute(parsedId, payload);
            notification.success({
                message: "Dispute approved successfully",
            });
        } catch (error) {
            showError(error);
        }
    };

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            const allItemIds = disputeItems.map((item) => item.getId());
            setSelectedItems(allItemIds);
        } else {
            setSelectedItems([]);
        }
    };

    const disputePreviewItems = disputePreview.getData().getItems();

    const columns: TableColumnsType<DisputeItemModel> = [
        {
            title: (
                <Checkbox
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    checked={selectedItems.length === disputeItems.length}
                    indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < disputeItems.length
                    }
                    disabled={
                        disputeItems.filter(
                            (item) => item.getStatus() === "approved",
                        ).length > 0 || can_complete
                    }
                />
            ),
            key: "select",
            width: 20,
            align: "center",
            render: (_, data) => {
                return (
                    <>
                        {data && (
                            <Checkbox
                                onChange={(e) =>
                                    handleCheckboxChange(
                                        data.getId(),
                                        e.target.checked,
                                    )
                                }
                                checked={selectedItems.includes(data.getId())}
                                disabled={data.getStatus() === "approved"}
                            />
                        )}
                    </>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>Meta</Text>,
            key: "meta",
            width: 200,
            render: (_, data) => {
                const variation = data.getBuyProductVariation();
                const properties = variation && variation.getProperties();
                const charge = data.getBuyProductCharge();
                return (
                    <>
                        {variation && properties && (
                            <Row key={1}>
                                <Col span={6}>
                                    <div
                                        style={{
                                            width: 42,
                                            height: 42,
                                            overflow: "hidden",
                                        }}
                                    >
                                        <ImageWithPlaceholder
                                            src={variation?.getImage()}
                                            alt={variation?.getImage()}
                                        />
                                    </div>
                                </Col>
                                <Col span={16}>
                                    <div>
                                        {properties &&
                                            properties.map((property) => (
                                                <Typography
                                                    key={property.getId()}
                                                >
                                                    <Paragraph
                                                        style={{
                                                            margin: tw
                                                                .spacing[0],
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                paddingRight:
                                                                    tw.spacing[
                                                                        "0.5"
                                                                    ],
                                                                fontSize:
                                                                    tw.fontSize
                                                                        .sm
                                                                        .fontSize,
                                                                lineHeight:
                                                                    tw.fontSize
                                                                        .sm
                                                                        .lineHeight,
                                                            }}
                                                        >
                                                            {property.getPropertyName()}
                                                            :
                                                        </Text>
                                                        <Text
                                                            strong
                                                            style={{
                                                                fontSize:
                                                                    tw.fontSize
                                                                        .sm
                                                                        .fontSize,
                                                                lineHeight:
                                                                    tw.fontSize
                                                                        .sm
                                                                        .lineHeight,
                                                            }}
                                                        >
                                                            {property.getPropertyValueName()}
                                                        </Text>
                                                    </Paragraph>
                                                </Typography>
                                            ))}
                                    </div>
                                </Col>
                            </Row>
                        )}
                        {charge && (
                            <Row key={2}>
                                <Col span={16}>
                                    <div>
                                        <Typography>
                                            <Space
                                                direction="vertical"
                                                size="small"
                                            >
                                                <StatusTag
                                                    slug={"Buy_Product_Charge"}
                                                    text={"Buy Product Charge"}
                                                    color={"warning"}
                                                />
                                                <Paragraph>
                                                    <Text>Charge Type: </Text>
                                                    <Text strong>
                                                        {charge.getChargeType()}
                                                    </Text>
                                                </Paragraph>
                                                <Paragraph>
                                                    <Text>Charged By: </Text>
                                                    <Text strong>
                                                        {charge.getChargedBy()}
                                                    </Text>
                                                </Paragraph>
                                                <Paragraph>
                                                    <Text>Total Amount:</Text>
                                                    <Text strong>
                                                        {Number(
                                                            charge.getTotalAmount(),
                                                        ).toFixed(2)}{" "}
                                                        {buyProduct.getRegionCurrencyCode()}
                                                    </Text>
                                                </Paragraph>
                                            </Space>
                                        </Typography>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>Info</Text>,
            key: "status",
            width: 70,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: (_, data) => {
                const status = data.getStatus();
                return (
                    <Space
                        direction="vertical"
                        size="small"
                        style={{ textAlign: "left" }}
                    >
                        <Paragraph>
                            <Text>Status: </Text>
                            <Tag
                                color={
                                    status === "approved"
                                        ? "success"
                                        : "warning"
                                }
                            >
                                {status}
                            </Tag>
                        </Paragraph>
                        <Paragraph>
                            <Text>Requested Amount: </Text>
                            <Text strong>
                                {data.getRequestedAmount().toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Requested Quantity: </Text>
                            <Text strong>{data.getRequestedQuantity()}</Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Approved Amount: </Text>
                            <Text strong>
                                {Number(data.getApprovedAmount()).toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                    </Space>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>Refund Quantity</Text>,
            key: "refundQuantity",
            width: 70,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: (_, data) => {
                const requestedQuantity = data.getRequestedQuantity();
                return (
                    <Typography>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>{requestedQuantity}</Text>
                        </Paragraph>
                    </Typography>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>Customer Cost</Text>,
            key: "customerCost",
            width: 100,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: (_, data) => {
                const previewItem =
                    disputePreviewItems &&
                    disputePreviewItems.find(
                        (previewItem) =>
                            previewItem.getItemId() === data.getId(),
                    );
                return (
                    <Space direction="vertical" size="small">
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>Unit: </Text>
                            <Text strong>
                                {previewItem
                                    ?.getCustomerPerItemCost()
                                    .toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>Total: </Text>
                            <Text strong>
                                {previewItem
                                    ?.getCustomerTotalItemCost()
                                    .toFixed(2)}{" "}
                                {buyProduct.getRegionCurrencyCode()}
                            </Text>
                        </Paragraph>
                    </Space>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>Purchase Agent Cost</Text>,
            key: "agentCost",
            width: 100,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: (_, data) => {
                const previewItem =
                    disputePreviewItems &&
                    disputePreviewItems.find(
                        (previewItem) =>
                            previewItem.getItemId() === data.getId(),
                    );
                const agentItemPerCost = previewItem?.getAgentPerItemCost();
                const agentItemTotalCost = previewItem?.getAgentTotalItemCost();
                const currencyCode = buyProduct.getRegionCurrencyCode();
                return (
                    <Space direction="vertical" size="small">
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>Unit: </Text>
                            <Text strong>
                                {agentItemPerCost?.toFixed(2)} {currencyCode}
                            </Text>
                        </Paragraph>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>Total: </Text>
                            <Text strong>
                                {agentItemTotalCost?.toFixed(2)} {currencyCode}
                            </Text>
                        </Paragraph>
                    </Space>
                );
            },
        },
        {
            title: <Text style={columnsTitleStyle}>MoveOn Profit</Text>,
            key: "moveonProfit",
            width: 100,
            align: "center",
            onHeaderCell: onHeaderCellStyle,
            render: (_, data) => {
                const previewItem =
                    disputePreviewItems &&
                    disputePreviewItems.find(
                        (previewItem) =>
                            previewItem.getItemId() === data.getId(),
                    );

                const moveonPerItemProfit =
                    previewItem?.getMoveonPerItemProfit();
                const moveonTotalItemProfit =
                    previewItem?.getMoveonTotalItemProfit();
                const currencyCode = buyProduct.getRegionCurrencyCode();

                return (
                    <Space direction="vertical" size="small">
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>Unit: </Text>
                            <Text strong>
                                {moveonPerItemProfit?.toFixed(2)} {currencyCode}
                            </Text>
                        </Paragraph>
                        <Paragraph style={{ margin: tw.spacing[0] }}>
                            <Text>Total: </Text>
                            <Text strong>
                                {moveonTotalItemProfit?.toFixed(2)}{" "}
                                {currencyCode}
                            </Text>
                        </Paragraph>
                    </Space>
                );
            },
        },
    ];

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: "4px",
                }}
            >
                {checkActionPermission(
                    [ADMIN_REFUND_PERMISSION_ENUM.ADMIN_DISPUTE_APPROVE],
                    <Tooltip
                        title={
                            can_complete
                                ? "Please complete the approved one first!"
                                : ""
                        }
                    >
                        <Button
                            onClick={handleApprove}
                            // Jodi dispute can_complete thake tahole button ta disable hobe bcz ekta dispute pending thakle onno gula approve kora jabe na
                            disabled={
                                selectedItems.length === 0 || can_complete
                            }
                            type={
                                selectedItems.length === 0
                                    ? "default"
                                    : "primary"
                            }
                            size="middle"
                            loading={isDisputeLoading}
                        >
                            Approve Selected Items
                        </Button>
                    </Tooltip>,
                    null,
                )}
            </div>
            <Table
                columns={columns}
                dataSource={disputeItems}
                rowKey="id"
                rowClassName={(record) =>
                    record.getStatus() === "approved" ? "approved-row" : ""
                }
                pagination={false}
                bordered
                size="small"
                style={{
                    width: "100%",
                    margin: 0,
                }}
                scroll={{ x: 720 }}
            />
        </div>
    );
};

export default VariationsTable;
