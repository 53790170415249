import React, { useEffect, useState } from "react";
import { InvoicePaymentRuleModel } from "@/models/invoicePaymentRoleModel";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { Button, Modal, Table } from "antd";
import { MiniMulUsersModel } from "@/models/userCollectionModel";
import { PaginationModel } from "@/models/pagination";
import { PageHeader } from "@ant-design/pro-layout";
import { PlusOutlined } from "@ant-design/icons";
import { FormInstance } from "antd/lib";
import { showError } from "@/helpers/showError";
import AddUserToInvoiceRules from "./AddUserToInvoiceRules";
import { useInvoicePaymentRule } from "@/lib/core-react/hooks/private/useInvoicePaymentRule";

interface InvoiceRuleUsersProps {
    setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedData: InvoicePaymentRuleModel | undefined;
}
const InvoiceRuleGroupUsers: React.FC<InvoiceRuleUsersProps> = ({
    selectedData,
}) => {
    const {
        allOptionsData: { customerOptionsData, usersResponse },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        if (selectedData) {
            onFetchFilterApi(filterResourceEnum.CUSTOMER, {
                invoice_payment_rule_group_id: selectedData.getId(),
            });
        }
    }, [selectedData]);

    const paginationData = usersResponse?.getPagination();

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        onFetchFilterApi(filterResourceEnum.CUSTOMER, {
            invoice_payment_rule_group_id: selectedData?.getId(),
            ...pageInfo,
        });
    };

    const [isShowAddUserModal, setIsShowAddUserModal] =
        useState<boolean>(false);
    const { addCustomerInvoiceRule, isAddCustomerLoading } =
        useInvoicePaymentRule();

    // add customer handler
    // add customer handler
    const addCustomerHandler = async (
        value: { user: number },
        form: FormInstance<any>,
    ) => {
        const payload = {
            user_id: value.user,
        };
        try {
            await addCustomerInvoiceRule(
                Number(selectedData?.getId()),
                payload,
            );
            setIsShowAddUserModal(false);
        } catch (error: any) {
            showError(error, form);
        }
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_: string, _record: MiniMulUsersModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "center" as const,
            width: "30%",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            align: "center" as const,
        },
    ];

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        usersResponse,
        handlePaginationChange,
    );

    return (
        <div className="responsive-table-container">
            <PageHeader
                ghost={false}
                title="Invoice payment rules"
                style={{ marginTop: "10px" }}
                extra={[
                    <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        key="1"
                        onClick={() => {
                            setIsShowAddUserModal(true);
                        }}
                    >
                        Add user
                    </Button>,
                ]}
            >
                <Table
                    loading={customerOptionsData.isLoading}
                    rowKey="id"
                    bordered={true}
                    dataSource={usersResponse ? usersResponse.getData() : []}
                    //@ts-ignore
                    columns={columns}
                    pagination={paginationConfig}
                />
            </PageHeader>
            {/* Add user modal */}
            {isShowAddUserModal && (
                <Modal
                    title={`Add user to ${selectedData?.getName()}`}
                    open={isShowAddUserModal}
                    onCancel={() => setIsShowAddUserModal(false)}
                    footer={null}
                >
                    <AddUserToInvoiceRules
                        addCustomerHandler={addCustomerHandler}
                        loading={isAddCustomerLoading}
                    />
                </Modal>
            )}
        </div>
    );
};
export default InvoiceRuleGroupUsers;
