import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { Button, Dropdown, Modal, Popconfirm, Table, Tag, message } from "antd";
import {
    CheckCircleOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";

import { addressCollectionAtom } from "@/lib/core-react/store/store";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import useDataFilters from "@/hooks/useDataFilters";

import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { DestinationWarehouseAddressModalType } from "@/types/destinationWarehouseCollection";

import {
    AddressCollectionModel,
    AddressModel,
} from "@/models/addressCollectionModel";
import {
    useAddressDelete,
    useGetAddressV2,
} from "@/lib/core-react/hooks/private/useAddress";
import { DestinationWarehouseModel } from "@/models/destinationWarehouseCollectionModel";
import { getKeyByValue } from "./utils";
import { DwAddressModification } from "./DwAddressModification";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADDRESS_PERMISSION_ENUM } from "@/consts/permission-enum/address-enum";
import { IDisplayAddress } from "@/types/buyOrderDetail";
import { PageHeader } from "@ant-design/pro-layout";
import FormattedAddress from "@/components/FormattedAddress";

interface IProps {
    destinationWarehouse: DestinationWarehouseModel;
}

export const DwAddressesTable = ({ destinationWarehouse }: IProps) => {
    const { deleteAddress, isLoading: isLoadingDelete } = useAddressDelete();
    const { getAddress } = useGetAddressV2();
    const [{ data: addressCollectionData, isLoading }] = useAtom(
        addressCollectionAtom,
    );
    const AddressCollectionData =
        addressCollectionData &&
        new AddressCollectionModel(addressCollectionData);

    const [modalOpen, setModalOpen] = useState<
        DestinationWarehouseAddressModalType | undefined
    >(undefined);

    const [
        selectedDestinationWarehouseAddress,
        setSelectedDestinationWarehouseAddress,
    ] = useState<AddressModel | undefined>(undefined);

    const { filters, handleFilterChange } = useDataFilters();

    // Api Call
    useEffect(() => {
        getAddress(
            `countries[0]=${destinationWarehouse.getCountry()?.getCode()}&destination_warehouse_id=${destinationWarehouse.getId()}`,
        );
    }, [destinationWarehouse]);

    // Pagination
    const paginationData = AddressCollectionData?.getPagination();

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAddress,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        AddressCollectionData,
        handlePaginationChange,
    );

    const handleDeleteAddress = async (id: number) => {
        try {
            await deleteAddress(id);
            await getAddress(
                `countries[0]=${destinationWarehouse.getCountry()?.getCode()}&destination_warehouse_id=${destinationWarehouse.getId()}`,
            );
            message.success("Address deleted.");
        } catch (error) {
            message.error(getError(error));
        }
    };

    const onCancelModal = () => {
        setModalOpen(undefined);
        setSelectedDestinationWarehouseAddress(undefined);
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "id",
            align: "center" as const,
            render: (_: string, __: AddressModel, index: number) => {
                return (
                    <>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </>
                );
            },
        },
        {
            title: "Address info",
            dataIndex: "address",
            key: "address",
            render: (_: string, record: AddressModel) => {
                const addressModel =
                    record.getDisplayAddress() || record.getAddress();
                return (
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 10,
                            width: 200,
                        }}
                    >
                        {addressModel && Object.keys(addressModel).length ? (
                            <div>
                                <FormattedAddress
                                    address={
                                        addressModel as unknown as IDisplayAddress
                                    }
                                />
                            </div>
                        ) : (
                            <Tag color="error">Address not found</Tag>
                        )}

                        {record.getIsDefaultBilling() ||
                        record.getIsDefaultShipping() ? (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                {record.getIsDefaultShipping()
                                    ? "Default Shipping Address"
                                    : record.getIsDefaultBilling()
                                      ? "Default Billing Address"
                                      : "Not default"}
                            </Tag>
                        ) : (
                            ""
                        )}
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            render: (_: string, record: AddressModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADDRESS_PERMISSION_ENUM.ADMIN_DW_ADDRESS_MANAGE,
                        key: "1",
                        label: (
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setSelectedDestinationWarehouseAddress(
                                        record,
                                    );
                                    setModalOpen(
                                        DestinationWarehouseAddressModalType.Update_Address,
                                    );
                                }}
                            >
                                <EditOutlined /> <span> Update</span>
                            </div>
                        ),
                    },
                ];

                if (!record.getIsDefaultShipping()) {
                    items.push({
                        permission:
                            ADDRESS_PERMISSION_ENUM.ADMIN_ADDRESS_MANAGE,
                        key: "2",
                        label: (
                            <Popconfirm
                                title="Delete this address"
                                description="Are you sure to delete this address?"
                                onConfirm={() =>
                                    handleDeleteAddress(record.getId())
                                }
                                onCancel={onCancelModal}
                                okButtonProps={{ loading: isLoadingDelete }}
                                okText="Yes"
                                okType="danger"
                                cancelText="No"
                            >
                                <span onClick={(e) => e.stopPropagation()}>
                                    <DeleteOutlined /> Delete
                                </span>
                            </Popconfirm>
                        ),
                        danger: true,
                    });
                }

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <PageHeader
                ghost={false}
                style={{ marginTop: "10px" }}
                extra={[
                    checkActionPermission(
                        ADDRESS_PERMISSION_ENUM.ADMIN_DW_ADDRESS_MANAGE,
                        <Button
                            key="1"
                            type="primary"
                            hidden={!!AddressCollectionData?.getData().length}
                            onClick={() => {
                                setModalOpen(
                                    DestinationWarehouseAddressModalType.Add_Address,
                                );
                            }}
                            icon={<PlusOutlined />}
                        >
                            Add address
                        </Button>,
                        null,
                    ),
                ]}
            >
                <div
                    className="modalTableWrapper"
                    style={{ minHeight: "41vh" }}
                >
                    <Table
                        columns={columns}
                        size="small"
                        bordered
                        loading={isLoading}
                        dataSource={AddressCollectionData?.getData()}
                        pagination={paginationConfig}
                        rowKey="id"
                    />
                </div>
            </PageHeader>
            <Modal
                title={modalOpen && getKeyByValue(modalOpen)}
                open={Boolean(modalOpen)}
                footer={null}
                onCancel={onCancelModal}
            >
                {modalOpen ===
                    DestinationWarehouseAddressModalType.Update_Address &&
                    selectedDestinationWarehouseAddress && (
                        <DwAddressModification
                            destinationWarehouse={destinationWarehouse}
                            data={selectedDestinationWarehouseAddress}
                            onHide={onCancelModal}
                        />
                    )}

                {modalOpen ===
                    DestinationWarehouseAddressModalType.Add_Address && (
                    <DwAddressModification
                        destinationWarehouse={destinationWarehouse}
                        onHide={onCancelModal}
                    />
                )}
            </Modal>
        </>
    );
};
