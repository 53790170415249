import {
    Button,
    Flex,
    Form,
    Input,
    Modal,
    Popover,
    Space,
    Tag,
    Typography,
} from "antd";
import { tw } from "@/consts/theme/tailwindTheme";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { EditOutlined } from "@ant-design/icons";
import ShippingSlotsTable from "./ShippingRateTable";
import { IModalData } from "@/helpers/getModalTital";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import { memo, useEffect, useState } from "react";
import { formatString } from "@/utils/helper";
import { useParams } from "react-router-dom";
import getPagePathname from "@/helpers/getPagePathname";
import { BUY_PRODUCT_DETAIL_PAGE } from "@/consts/pageRoute";
import { StatusTag } from "@/components";
import { RateTypeEnums } from "@/enums/shippingCoreEnums";
import ShippingText from "@/components/ShippingText";
import { showSuccessAlert } from "@/helpers/showSuccess";
import useRefetch from "@/hooks/useRefetch";
import { showError } from "@/helpers/showError";
import { useShipmentProductUpdateTrackingId } from "@/lib/core-react/hooks/private/useShipping";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { useSyncBuyProducts } from "@/lib/core-react/hooks/private/usePurchase";
const { Paragraph, Text } = Typography;
interface Props {
    buyProduct: BuyProductModel;
    handleModal: (payload: IModalData) => void;
}
const AgentInfo = ({ buyProduct, handleModal }: Props) => {
    const { id } = useParams();
    const isVisible = getPagePathname() === `${BUY_PRODUCT_DETAIL_PAGE}/${id}`;
    const { refetchListApi } = useRefetch();
    const { syncProducts } = useSyncBuyProducts();

    const [trackingModalOpen, setTrackingModalOpen] = useState<
        "update-tracking" | false
    >(false);

    const [form] = Form.useForm();

    const {
        updateTrackingId,
        isLoading: isLoadingUpdateShipmentProductTrackingId,
    } = useShipmentProductUpdateTrackingId();

    const handleUpdateTracking = async (trigger) => {
        try {
            if (
                buyProduct.getShipmentProduct() &&
                buyProduct.getTrackings().length
            ) {
                const trackingId = buyProduct
                    .getTrackings()
                    .find((t) => t.id)
                    ?.getId();

                await updateTrackingId(
                    buyProduct.getShipmentProduct()?.getId() as number,
                    trackingId as number,
                    {
                        ...trigger,
                    },
                );
                form.resetFields();
                showSuccessAlert("Update tracking successfully.");
                setTrackingModalOpen(false);
                await refetchListApi();
                await syncProducts({
                    ids: [buyProduct.getId()],
                });
            } else {
                throw new Error(
                    "Shipment product number or tracking id not found!",
                );
            }
        } catch (err) {
            showError(err);
        }
    };

    useEffect(() => {
        if (
            buyProduct.getShipmentProduct() &&
            buyProduct.getTrackings().length
        ) {
            const tracking = buyProduct
                ?.getTrackings()
                .find((t) => t.tracking)?.tracking;
            form.setFieldValue("tracking", tracking);
        }
    }, [buyProduct]);
    const moveonCommissionRate = buyProduct
        .getMoveonShipping()
        ?.getCommissionRate();

    return (
        <div>
            <Space direction="vertical">
                {buyProduct.getShipmentProduct()
                    ? buyProduct.getTrackings().map((tracking) => {
                          return (
                              <ShippingText
                                  label="Tracking"
                                  permissionCheck={
                                      ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE_TRACKING
                                  }
                                  isEditable={true}
                                  onActionClick={() => {
                                      setTrackingModalOpen("update-tracking");
                                  }}
                              >
                                  <Typography.Text strong>
                                      {tracking.getTracking()}
                                  </Typography.Text>
                              </ShippingText>
                          );
                      })
                    : ""}
                {!isVisible && (
                    <Paragraph>
                        <Text
                            style={{
                                paddingRight: tw.spacing["0.5"],
                            }}
                        >
                            Purchase by :{" "}
                        </Text>
                        {buyProduct.getPurchaseCompanyName() ? (
                            <Space>
                                <Text strong>
                                    {buyProduct.getPurchaseCompanyName()}
                                </Text>

                                {checkActionPermission(
                                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_APPROVE,
                                    <Button
                                        style={{ padding: 0 }}
                                        type="link"
                                        onClick={() =>
                                            handleModal({
                                                action: "approve_and_assign_agent",
                                                data: buyProduct,
                                            })
                                        }
                                    >
                                        <EditOutlined />
                                    </Button>,
                                    null,
                                )}
                            </Space>
                        ) : (
                            <Text strong>N/A</Text>
                        )}
                    </Paragraph>
                )}

                {isVisible && (
                    <Paragraph>
                        <Text
                            style={{
                                paddingRight: tw.spacing["0.5"],
                            }}
                        >
                            Purchase Commission :{" "}
                        </Text>
                        <Text strong>
                            {buyProduct.getProductAgentAssociation()
                                ? buyProduct
                                      .getProductAgentAssociation()
                                      ?.getCommissionRate()
                                : "N/A"}
                        </Text>
                    </Paragraph>
                )}
                <Flex justify="start" gap={4} align="baseline">
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Ship by :{" "}
                    </Text>

                    <Text strong>{buyProduct.getShipBy()}</Text>
                    {checkActionPermission(
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={() =>
                                handleModal({
                                    action: "update_agent_category_price_read",
                                    data: buyProduct,
                                })
                            }
                        >
                            <EditOutlined />
                        </Button>,
                        null,
                    )}
                </Flex>
                {buyProduct.getMoveonShipping()?.getContain() && (
                    <Paragraph>
                        <Text
                            style={{
                                paddingRight: tw.spacing["0.5"],
                            }}
                        >
                            Contain :{" "}
                        </Text>
                        <StatusTag
                            color="blue"
                            slug={`${buyProduct.getMoveonShipping()?.getContain()}`}
                            text={`${buyProduct.getMoveonShipping()?.getContain()}`}
                        />
                    </Paragraph>
                )}

                {isVisible && (
                    <Paragraph>
                        <Text
                            style={{
                                paddingRight: tw.spacing["0.5"],
                            }}
                        >
                            Source :{" "}
                        </Text>
                        <Text strong>{buyProduct.getSourceName()}</Text>
                    </Paragraph>
                )}
                <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Agent Warehouse :{" "}
                    </Text>
                    <Text strong>
                        {buyProduct.getMoveonShipping()?.getAgentWarehouse()
                            .name || "N/A"}
                    </Text>
                </Paragraph>

                <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Destination Warehouse :{" "}
                    </Text>
                    <Text strong>
                        {buyProduct.getDestinationWarehouseName()}
                    </Text>
                </Paragraph>
                <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Shipping :{" "}
                    </Text>
                    <Text strong>
                        {formatString(buyProduct.getShippingType())}/
                        {formatString(buyProduct.getShippingMode())}
                    </Text>
                </Paragraph>
                {/* <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Shipping Mode:
                    </Text>
                    <Text strong>{buyProduct.getShippingMode()}</Text>
                </Paragraph> */}
                <Paragraph>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Category :{" "}
                    </Text>
                    <Tag color="blue-inverse">
                        {buyProduct
                            .getMoveonShipping()
                            ?.getShippingCategoryName()}
                    </Tag>
                    {checkActionPermission(
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_SHIPMENT_UPDATE,
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            onClick={() =>
                                handleModal({
                                    action: "update_agent_category_price_read",
                                    data: buyProduct,
                                })
                            }
                        >
                            <EditOutlined />
                        </Button>,
                        null,
                    )}
                </Paragraph>

                <Flex gap={4}>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Moveon Commission :{" "}
                    </Text>
                    {moveonCommissionRate
                        ? `${parseFloat(moveonCommissionRate.toString())}${
                              buyProduct
                                  .getMoveonShipping()
                                  ?.getCommissionRateType() ===
                              RateTypeEnums.PERCENTAGE
                                  ? "%"
                                  : buyProduct.getRegionCurrencyCode()
                          }
                    `
                        : "N/A"}
                </Flex>

                <Flex gap={4}>
                    <Text
                        style={{
                            paddingRight: tw.spacing["0.5"],
                        }}
                    >
                        Rate :{" "}
                    </Text>
                    {buyProduct.getShippingRate() &&
                    buyProduct.getRegionCurrencyCode() &&
                    buyProduct.getShippingRateUnitType() ? (
                        <Tag color="success">
                            <Flex gap={2}>
                                <Text strong>
                                    {buyProduct.getShippingRate()}
                                </Text>
                                <Text strong>
                                    {buyProduct.getRegionCurrencyCode()}
                                </Text>
                                <Text strong>/</Text>
                                <Text strong>
                                    {buyProduct.getShippingRateUnitType()}
                                </Text>
                            </Flex>
                        </Tag>
                    ) : (
                        "N/A"
                    )}
                </Flex>

                <div>
                    <Popover
                        content={
                            <ShippingSlotsTable
                                slots={
                                    buyProduct
                                        .getShipmentProduct()
                                        ?.getCustomerAssociation()
                                        ?.getAssociationSlots() ||
                                    buyProduct?.getMoveonShipping()?.getSlots()
                                }
                                regionCurrencyCode={buyProduct.getRegionCurrencyCode()}
                                unitType={buyProduct.getShippingRateUnitType()}
                            />
                        }
                        trigger="click"
                        placement="bottom"
                    >
                        <Button
                            style={{
                                padding: 0,
                            }}
                            type="link"
                        >
                            View Shipping Slots
                        </Button>
                    </Popover>
                </div>
            </Space>

            <Modal
                title={`Update Tracking`}
                open={trackingModalOpen === "update-tracking"}
                centered
                onCancel={() => {
                    setTrackingModalOpen(false);
                }}
                footer={false}
                destroyOnClose={true}
            >
                <Form form={form} onFinish={handleUpdateTracking}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Tracking is required",
                            },
                        ]}
                        required
                        name="tracking"
                        label="Tracking"
                        labelCol={{ span: 24 }} // Full width label
                        wrapperCol={{ span: 24 }} // Full width input
                    >
                        <Input placeholder="type tracking" />
                    </Form.Item>
                    <Flex justify="end">
                        <Form.Item noStyle>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={
                                    isLoadingUpdateShipmentProductTrackingId
                                }
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Flex>
                </Form>
            </Modal>
        </div>
    );
};

export default memo(AgentInfo);
