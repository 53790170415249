import { Button, Form, FormInstance, Select } from "antd";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { ShippingCountryDataStatus } from "@/enums/shippingCountryCollectionEnums";
import { useUpdateShippingCountry } from "@/lib/core-react/hooks/private";
import React, { useEffect } from "react";
import { showError } from "@/helpers/showError";
import { ShippingCountryModel } from "@/models/shippingCountryCollectionModel";

interface IProps {
    data: ShippingCountryModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    onHandleRefetch: () => void;
}
const UpdateShippingCountry = ({
    data,
    setIsShowUpdateModal,
    onHandleRefetch,
}: IProps) => {
    const { updateShippingCountry, isLoading } = useUpdateShippingCountry();

    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [data, form]);

    const onFinish = async (value) => {
        const payload = {
            status: value.status,
        };

        try {
            await updateShippingCountry(data.id as number, payload);
            setIsShowUpdateModal(false);
            onHandleRefetch();
        } catch (_error) {
            showError(_error, form);
        }
    };
    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="update_shipping_country"
                    ref={formRef}
                    initialValues={{
                        status: data.status,
                    }}
                    style={{
                        margin: "0px 10px",
                        padding: "15px 10px 0px 10px",
                    }}
                >
                    <Form.Item required name="status" label="Status">
                        <Select placeholder="Please select a status">
                            {" "}
                            {renderOptionsFromEnum(ShippingCountryDataStatus)}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default UpdateShippingCountry;
