import { ITranslationEntry } from "../lang";

export const DA: ITranslationEntry = {
    translations: {
        notifications: "Meddelelser",
        markAllAsRead: "Markér alle som læst",
        poweredBy: "Drevet af",
        settings: "Indstillinger",
        noNewNotification: "Intet nyt at se her endnu",
    },
    lang: "da",
};
