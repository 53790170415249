import { UiNode, UiNodeTextAttributes, UiText } from "@ory/client";
import { Space, Typography } from "antd";

interface Props {
    node: UiNode;
    attributes: UiNodeTextAttributes;
}

const NUMBER = 1050015;

const Content = ({ attributes }: Props) => {
    if (attributes.text.id === NUMBER) {
        const secrets = (attributes.text.context as any).secrets.map(
            (text: UiText, k: number) => (
                <div
                    key={k}
                    data-testid={`node/text/${attributes.id}/lookup_secret`}
                >
                    <code>{text.id === 1050014 ? "Used" : text.text}</code>
                </div>
            ),
        );
        return (
            <div data-testid={`node/text/${attributes.id}/text`}>
                <div className="row">{secrets}</div>
            </div>
        );
    }
    return (
        <div data-testid={`node/text/${attributes.id}/text`}>
            <Space>
                <Typography.Text>{attributes.text.text}</Typography.Text>
            </Space>
        </div>
    );
};

export const NodeText = ({ node, attributes }: Props) => {
    return (
        <>
            <Typography.Text data-testid={`node/text/${attributes.id}/label`}>
                {node.meta?.label?.text}
            </Typography.Text>
            <Content node={node} attributes={attributes} />
        </>
    );
};
