import {
    Button,
    Form,
    Input,
    InputNumber,
    Select,
    Skeleton,
    Table,
    Tag,
    Typography,
    notification,
} from "antd";
import { FormInstance } from "antd/es/form/Form";
import React, { useEffect, useState, createRef } from "react";
import {
    convertEnumToSelectOptions,
    renderOptionsFromEnumUpdateStatusRejected,
} from "@/components/Form/forms";
import {
    TransactionModel,
    UpdateStatusModel,
} from "@/models/transactionCollectionModels";
import {
    useTransactionUpdateStatus,
    useUpdateStatus,
} from "@/lib/core-react/hooks/private/useTransactions";
import {
    TransactionsRejectEnums,
    TransactionsStatusEnums,
    TransactionsStatusEnumsForAutomatic,
} from "@/enums/transactionCollectionsEnums";
import { PaymentGatewayTypeEnums } from "@/enums/paymentGatewayCollectionEnums";
import Paragraph from "antd/lib/typography/Paragraph";
import StatusTag from "@/components/StatusTag/StatusTag";
import {
    ArrowRightOutlined,
    DownOutlined,
    UpOutlined,
} from "@ant-design/icons";
import { formatDateTime } from "@/utils/helpers";
import { showError } from "@/helpers/showError";

interface IProps {
    data: TransactionModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Text } = Typography;

const UpdateStatus = ({ data, setIsShowUpdateModal }: IProps) => {
    const { updateStatus, isLoading } = useUpdateStatus();
    const { getTransactionsUpdateStatus } = useTransactionUpdateStatus();
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [isTableVisible, setIsTableVisible] = useState(false);
    const [isOtherRejectReason, setIsOtherRejectReason] =
        useState<boolean>(false);
    const [authorizedAmount, setAuthorizedAmount] = useState<number | null>(
        data.getRequestAmount() || null,
    );

    const [transactionData, setTransactionData] = useState<TransactionModel[]>(
        [],
    );

    const [transactionsListLoading, setTransactionListLoading] =
        useState(false);

    useEffect(() => {
        const defaultStatus =
            data && typeof data.getStatus === "function"
                ? data.getStatus()
                : null;

        const isAutomaticPayment =
            data.getGateway().getType() ===
            PaymentGatewayTypeEnums.AUTOMATIC_PAYMENT;
        const initialStatus = isAutomaticPayment
            ? defaultStatus
            : defaultStatus === "authorized"
              ? "approved"
              : defaultStatus;

        const defaultAmount =
            data && typeof data.getAmount === "function"
                ? data.getAmount()
                : null;

        const initialValues: any = {};

        if (initialStatus !== null) {
            initialValues.status = initialStatus;
            setSelectedStatus(defaultStatus);
        }

        if (
            (defaultStatus === "approved" || defaultStatus === "authorized") &&
            defaultAmount !== null
        ) {
            initialValues.authorized_amount = defaultAmount;
        }

        form.setFieldsValue(initialValues);
    }, [data, form]);

    // useEffect(() => {
    //     form.resetFields({});
    // }, [data]);

    const onFinish = async (
        value: UpdateStatusModel & { authorized_amount?: number } & {
            reject_reason?: string;
        } & { other_reason?: string },
    ) => {
        const payload: any = {
            status: value.status,
        };

        if (
            value.status === "approved" &&
            value.authorized_amount !== undefined
        ) {
            payload.authorized_amount = value.authorized_amount;
        }
        if (value.status === "authorized") {
            payload.authorized_amount = value.authorized_amount;
        }

        if (value.status === "rejected") {
            payload.reject_reason =
                value.reject_reason === "others"
                    ? value.other_reason
                    : value.reject_reason;
        }
        try {
            await updateStatus(data.id as number, payload as UpdateStatusModel);
            notification["success"]({
                message: "Status Update successfully",
            });
            setIsShowUpdateModal(false);
        } catch (error: any) {
            showError(error, form);
        }
    };

    const statusOptions =
        data.getGateway().getType() ===
        PaymentGatewayTypeEnums.AUTOMATIC_PAYMENT
            ? convertEnumToSelectOptions(TransactionsStatusEnumsForAutomatic)
            : convertEnumToSelectOptions(TransactionsStatusEnums);
    const onAuthorizedAmountChange = (value: number | null) => {
        setAuthorizedAmount(value);
        setTransactionData([]);
        setIsTableVisible(false);
    };

    const onSeeItemsClick = async () => {
        if (!isTableVisible && authorizedAmount !== null) {
            try {
                setTransactionListLoading(true);

                const rawDatatrasaction = await getTransactionsUpdateStatus(
                    `requested_amount[0]=${authorizedAmount}&requested_amount[1]=${authorizedAmount}`,
                );

                setTransactionData(rawDatatrasaction.data);
                setIsTableVisible(true);
            } catch (_error) {
                notification["error"]({
                    message: "Failed to fetch transactions",
                });
            } finally {
                setTransactionListLoading(false);
            }
        } else {
            setIsTableVisible((prev) => !prev);
            if (isTableVisible) {
                setTransactionData([]);
            }
        }
    };

    const tableColumns = [
        {
            title: "Transaction Info",
            dataIndex: "transaction_number",
            key: "transaction_number",
            render: (_: string, record: TransactionModel) => (
                <>
                    <Paragraph>
                        <Text strong copyable>
                            {record.transaction_number}
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <StatusTag
                            slug={record.region.code}
                            text={record.region.code}
                        />
                    </Paragraph>
                    <Paragraph>
                        <Tag style={{ marginTop: 5 }} color="purple">
                            {formatDateTime(record.created_at)}
                        </Tag>
                    </Paragraph>
                    <Paragraph>
                        <Button
                            onClick={() => {
                                window.open(
                                    `/payment/transaction/${record.id}`,
                                    "_blank",
                                );
                            }}
                            type="link"
                            style={{ padding: 0 }}
                        >
                            View Detail <ArrowRightOutlined />
                        </Button>
                    </Paragraph>
                </>
            ),
        },
        {
            title: "Gateway Info",
            dataIndex: "gateway",
            key: "gateway",
            render: (_: string, record: TransactionModel) => (
                <>
                    <Paragraph>
                        <Text>Name: </Text>
                        <Text strong>{record.gateway.name}</Text>
                    </Paragraph>
                    <Paragraph>
                        <Text>Type: </Text>
                        <Text strong>{record.gateway.type}</Text>
                    </Paragraph>
                </>
            ),
        },
        {
            title: "Amount Details",
            dataIndex: "amount",
            key: "amount",
            render: (_: string, record: TransactionModel) => (
                <>
                    <Paragraph>
                        <Text>Amount: </Text>
                        <Text strong>
                            {record.currency.code} {record.amount}
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <Text>Requested: </Text>
                        <Text strong>
                            {record.currency.code} {record.requested_amount}
                        </Text>
                    </Paragraph>
                </>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (_: string, record: TransactionModel) => (
                <div>
                    {/* <Tooltip title={record.reject_reason}> */}
                    <StatusTag slug={record.status} text={record.status} />
                    {/* </Tooltip> */}
                </div>
            ),
        },
    ];

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update Status"
            ref={formRef}
            initialValues={{
                status: data.getStatus ? data.getStatus() : null,
                authorized_amount: data.getRequestAmount()
                    ? data.getRequestAmount()
                    : null,
            }}
            onFieldsChange={(changedFields) => {
                if (
                    changedFields.length > 0 &&
                    changedFields[0].name[0] === "status"
                ) {
                    const newStatus = changedFields[0].value;
                    setSelectedStatus(newStatus);
                    // if (newStatus !== "approved") {
                    //     form.setFieldsValue({ authorized_amount: undefined });
                    // }
                }

                if (
                    changedFields.length > 0 &&
                    changedFields[0].name[0] === "reject_reason"
                ) {
                    const newRejectReason = changedFields[0].value;
                    setIsOtherRejectReason(newRejectReason === "others");
                }
            }}
        >
            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                name="status"
                label="Status"
            >
                <Select
                    placeholder="Please select a status"
                    options={statusOptions}
                />
            </Form.Item>

            {selectedStatus === "rejected" && (
                <>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Reject reason is required",
                            },
                        ]}
                        name="reject_reason"
                        label="Reject Reason"
                    >
                        <Select placeholder="Please select a reject reason">
                            {renderOptionsFromEnumUpdateStatusRejected(
                                TransactionsRejectEnums,
                            )}
                        </Select>
                    </Form.Item>
                    {isOtherRejectReason && (
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Other reason is required",
                                },
                            ]}
                            name="other_reason"
                            label="Other Reason"
                        >
                            <Input placeholder="Please specify the reason" />
                        </Form.Item>
                    )}
                </>
            )}

            {(selectedStatus === "approved" ||
                selectedStatus === "authorized") && (
                <>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Authorized amount is required",
                            },
                            {
                                validator: (_, value) => {
                                    if (value > data.getRequestAmount()) {
                                        return Promise.reject(
                                            new Error(
                                                `Authorized amount cannot exceed ${data.getRequestAmount()}`,
                                            ),
                                        );
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                        name="authorized_amount"
                        label="Authorized Amount"
                    >
                        <InputNumber
                            style={{ width: "100%" }}
                            value={authorizedAmount}
                            onChange={onAuthorizedAmountChange}
                        />
                    </Form.Item>
                </>
            )}

            <Form.Item style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100px" }}
                >
                    Submit
                </Button>
            </Form.Item>

            {(selectedStatus === "approved" ||
                selectedStatus === "authorized") && (
                <Button
                    type="link"
                    icon={isTableVisible ? <UpOutlined /> : <DownOutlined />}
                    onClick={onSeeItemsClick}
                >
                    {transactionsListLoading
                        ? "Checking..."
                        : "Check Same Amount"}
                </Button>
            )}

            {isTableVisible &&
                (selectedStatus === "approved" ||
                    selectedStatus === "authorized") && (
                    <>
                        {transactionsListLoading ? (
                            <Skeleton active paragraph={{ rows: 5 }} />
                        ) : (
                            <Table
                                columns={tableColumns}
                                dataSource={transactionData || []}
                                pagination={false}
                                onRow={(record: TransactionModel) => {
                                    const isMatched =
                                        record.transaction_number ===
                                        data.getTransactionNumber();
                                    return {
                                        style: {
                                            backgroundColor: isMatched
                                                ? "#DBF2DC"
                                                : "#ffffff",
                                        },
                                    };
                                }}
                            />
                        )}
                    </>
                )}
        </Form>
    );
};

export default UpdateStatus;
