import { ITranslationEntry } from "../lang";

export const UK: ITranslationEntry = {
    translations: {
        notifications: "Сповіщення",
        markAllAsRead: "Позначити все як прочитані",
        poweredBy: "На підтримці",
        settings: "Налаштування",
        noNewNotification: "Тут ще немає нічого нового",
    },
    lang: "uk",
};
