import { ITranslationEntry } from "../lang";

export const SQ: ITranslationEntry = {
    translations: {
        notifications: "Njoftimet",
        markAllAsRead: "Shënoni të gjitha si të lexuara",
        poweredBy: "Mundësuar nga",
        settings: "Cilësimet",
        noNewNotification: "Nuk ka asgjë të re për të parë këtu ende",
    },
    lang: "sq",
};
