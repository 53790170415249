import { StatusTag } from "@/components";
import { DeliveryRequestModel } from "@/models/deliveryRequestCollectionModel";
import { formatDateTime } from "@/utils/helpers";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import { Tag } from "antd/lib";
import Paragraph from "antd/lib/typography/Paragraph";
import { Link } from "react-router-dom";

const { Text } = Typography;

interface IProps {
    deliveryRequest: DeliveryRequestModel;
    isDetailsShow?: boolean;
}

const deliveryRequestInfo: React.FC<IProps> = ({
    deliveryRequest,
    isDetailsShow,
}) => {
    return (
        <>
            {" "}
            <div>
                <Paragraph>
                    <Tooltip title="Request Number">
                        <Text>R/N : </Text>
                    </Tooltip>
                    <Text copyable strong>
                        {deliveryRequest.getRequestNumber()}
                    </Text>
                </Paragraph>
            </div>
            <div style={{ marginTop: 5 }}>
                <Paragraph style={{ marginTop: 5 }}>
                    <Text>Region : </Text>
                    <Text>
                        <StatusTag
                            slug={deliveryRequest.region?.code || ""}
                            text={deliveryRequest.region?.code || ""}
                        />
                    </Text>
                </Paragraph>
            </div>
            <div style={{ marginTop: 5 }}>
                <Paragraph>
                    <Text>Type : </Text>
                    <Text>
                        <StatusTag
                            slug={deliveryRequest.getType()}
                            text={deliveryRequest.getType()}
                        />
                    </Text>
                </Paragraph>
            </div>
            <div style={{ marginTop: 5 }}>
                <Paragraph>
                    <Text>Status : </Text>
                    <Text>
                        <StatusTag
                            slug={deliveryRequest.getStatus()}
                            text={deliveryRequest.getStatus()}
                        />
                    </Text>
                </Paragraph>
            </div>
            <div>
                <Paragraph>
                    <Tooltip title="Destination Warehouse">
                        <Text>D/W : </Text>
                    </Tooltip>
                    <Text strong color="blue" copyable>
                        {deliveryRequest.destination_warehouse.name}
                    </Text>
                </Paragraph>
            </div>
            <div style={{ marginBottom: 5 }}>
                <Paragraph>
                    <Tooltip title="Destination Country">
                        <Text>D/C : </Text>
                    </Tooltip>
                    <Text>
                        <StatusTag
                            slug={
                                deliveryRequest.destination_warehouse.country
                                    ?.code || ""
                            }
                            text={
                                deliveryRequest.destination_warehouse.country
                                    ?.code || ""
                            }
                        />
                    </Text>
                </Paragraph>
            </div>
            {deliveryRequest.getCourierNote() && (
                <Typography.Text
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                        background: "#bbf7d0",
                        borderRadius: "4px",
                        padding: 4,
                        marginTop: 5,
                    }}
                >
                    <Text>Customer Note: </Text>
                    <Text
                        strong
                        style={{
                            maxWidth: 250,
                            color: "#ef4444",
                            fontWeight: 800,
                            fontSize: 15,
                        }}
                        ellipsis={{
                            tooltip: deliveryRequest.getCourierNote(),
                        }}
                    >
                        {deliveryRequest.getCourierNote()}
                    </Text>
                </Typography.Text>
            )}
            {deliveryRequest.getNote() && (
                <Typography.Text
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                        background: "#bbf7d0",
                        borderRadius: "4px",
                        padding: 4,
                        marginTop: 5,
                    }}
                >
                    <Text>Note: </Text>
                    <Text
                        strong
                        style={{
                            maxWidth: 250,
                            color: "#ef4444",
                            fontWeight: 800,
                            fontSize: 15,
                        }}
                        ellipsis={{
                            tooltip: deliveryRequest.getNote(),
                        }}
                    >
                        {deliveryRequest.getNote()}
                    </Text>
                </Typography.Text>
            )}
            <div style={{ marginTop: 5 }}>
                <Paragraph>
                    <Text>Created At : </Text>
                    <Text>
                        <Tag color="purple">
                            {formatDateTime(deliveryRequest.getCreatedAt())}
                        </Tag>
                    </Text>
                </Paragraph>
            </div>
            {!isDetailsShow && (
                <div style={{ marginTop: 10 }}>
                    <Link
                        target="_blank"
                        to={`/delivery-request/details/${deliveryRequest.id}`}
                        style={{ padding: 0, marginTop: 5 }}
                    >
                        View Details <ArrowRightOutlined />
                    </Link>
                </div>
            )}
        </>
    );
};
export default deliveryRequestInfo;
