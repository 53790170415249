import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import DeliveryRequestResource from "../../resources/private/deliveryRequestResource";

class DeliveryRequestService extends CommerceApiService {
    public deliveryRequestResource: DeliveryRequestResource;

    constructor() {
        super();

        this.deliveryRequestResource = new DeliveryRequestResource(this.config);
    }
}

export default DeliveryRequestService;
