import { ITranslationEntry } from "../lang";

export const EL: ITranslationEntry = {
    translations: {
        notifications: "ειδοποιήσεις",
        markAllAsRead: "επισημάνετε όλα ως αναγνωσμένα",
        poweredBy: "τροφοδοτείται από",
        settings: "Ρυθμίσεις",
        noNewNotification: "Δεν υπάρχει τίποτα νέο να δείτε εδώ ακόμα",
    },
    lang: "el",
};
