import QueryString from "qs";
import { CreateAddressRequestModel } from "@/models/buyPlaceOrderModel";
import { IDestinationWarehouseAddressForm } from "@/types/destinationWarehouseCollection";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import BaseResource from "../public/baseResource";
class AddressResource extends BaseResource {
    path = `/api/address/admin/address/v1/addresses`;
    dwPath = `/api/address/admin/address/v1/dw-addresses`;

    get(
        params: Record<string, any>,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}?${QueryString.stringify(params)}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getAddress(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getAsFilters(
        user_id: number,
        country: string,
        params: Record<string, any>,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}?${QueryString.stringify(params)}?user_id=${user_id}/countries=${country}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    addAddress(
        user_id: number,
        country_id: string,
        payload: CreateAddressRequestModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}?user_id=${user_id}&countries=${country_id}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    addDwAddress(
        payload: IDestinationWarehouseAddressForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.dwPath}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updateDwAddress(
        address_id: number,
        payload: IDestinationWarehouseAddressForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.dwPath}/${address_id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    deleteAddress(
        address_id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${address_id}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }
}

export default AddressResource;
