import { ResponsePromise } from "@/lib/core/request/types/typings";
import BaseResource from "../public/baseResource";

class DeliveryRequestResource extends BaseResource {
    path = `/api/delivery/admin/request/v1`;
    getList(
        params: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}/requests`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    details(
        deliveryRequestId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/requests/${deliveryRequestId}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default DeliveryRequestResource;
