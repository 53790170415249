import { IHSCode, IHSCodeCollection } from "@/types/hsCodeCollection";
import { IPagination } from "@/types/pagination";

export class HSCodeModel {
    public readonly object: string;
    public readonly id: number;
    public readonly section: string;
    public readonly hs_code: string;
    public readonly description: string;
    public readonly src_parent: string;
    public readonly level: string;

    constructor(data: IHSCode) {
        this.object = data.object;
        this.id = data.id;
        this.description = data.description;
        this.section = data.section;
        this.level = data.level;
        this.hs_code = data.hs_code;
        this.src_parent = data.src_parent;
    }
}

export class HSCodeCollectionModel {
    public readonly object: string;
    public readonly data: HSCodeModel[];
    public readonly pagination?: IPagination;

    constructor(data: IHSCodeCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new HSCodeModel(item));
        this.pagination = data.pagination;
    }
    getData() {
        return this.data;
    }

    getAllHSCodeData(): { value: string; label: string }[] {
        return this.data.map((item) => ({
            value: item.hs_code,
            label: `${item.hs_code}, ${item.description}`,
        }));
    }
}
