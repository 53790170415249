import { InventoryCategoryEnum } from "@/enums/inventoryCategoryEnum";
import {
    IInventoryCategory,
    IInventoryCategoryCollection,
    IInventoryCategoryResponse,
} from "@/types/inventoryCategoryCollection";
import { PaginationModel } from "./paginationModel";
import { IFilterType } from "@/types/filters";
import { BaseShippingCategoryDataModel } from "./baseShippingCategory";

export class InventoryCategoryModel {
    object: InventoryCategoryEnum.InventoryCategory;
    id: string;
    parent_id: string | null;
    name: string;
    slug: string;
    children: InventoryCategoryCollectionModel;
    created_at?: string;
    base_shipping_category?: BaseShippingCategoryDataModel;

    constructor(data: IInventoryCategory) {
        this.object = InventoryCategoryEnum.InventoryCategory;
        this.id = data.id;
        this.parent_id = data.parent_id || null;
        this.name = data.name;
        this.slug = data.slug;
        this.children =
            data.children &&
            new InventoryCategoryCollectionModel(data.children);
        this.created_at = data.created_at;
        this.base_shipping_category =
            data.base_shipping_category &&
            new BaseShippingCategoryDataModel(data.base_shipping_category);
    }

    getParentId = () => this.parent_id;
    getName = () => this.name;
    getSlug = () => this.slug;
    getChildren = () => this.children;
    getCreatedAt = () => this.created_at;
    getId = () => this.id;
    getBaseShippingCategory = () => this.base_shipping_category;
}

export class InventoryCategoryCollectionModel {
    object: InventoryCategoryEnum.InventoryCategoryCollection;
    data: InventoryCategoryModel[];

    constructor(data: IInventoryCategoryCollection) {
        this.object = InventoryCategoryEnum.InventoryCategoryCollection;
        this.data =
            data?.data &&
            data.data.map((item) => new InventoryCategoryModel(item));
    }
    getData = () => this.data;
}

export class InventoryCategoryResponseModel {
    object: InventoryCategoryEnum.InventoryCategoryCollection;
    data: InventoryCategoryModel[];
    pagination: PaginationModel;
    filters: IFilterType;

    constructor(data: IInventoryCategoryResponse) {
        this.object = InventoryCategoryEnum.InventoryCategoryCollection;
        this.data =
            data?.data &&
            data.data.map((item) => new InventoryCategoryModel(item));
        this.pagination =
            data.pagination && new PaginationModel(data.pagination);
        this.filters = data.filters && data.filters;
    }
    getData = () => this.data;
    getPagination = () => this.pagination;
    getFilters = () => this.filters;
}
