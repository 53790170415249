import { Table } from "antd";
import {
    ActionSequenceModel,
    VariantMismatchModel,
} from "src/models/buyActionCollectionModel";
import PropertyVariation from "./ExtraComponent/PropertyVariation";

interface IProps {
    data: ActionSequenceModel;
    currency: string;
}

const PriceIncreaseIssues = ({ data, currency }: IProps) => {
    const columns = [
        {
            title: "Meta",
            dataIndex: "variation_mismatch",
            key: "variation_mismatch",
            render: (_: any, mismatch: VariantMismatchModel) =>
                PropertyVariation(mismatch),
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (_: any, mismatch: VariantMismatchModel) =>
                mismatch.getBuyProductVariation()?.getQuantity() || 0,
        },
        {
            title: "Old Price",
            dataIndex: "unit_price",
            key: "unit_price",
            render: (_: any, mismatch: VariantMismatchModel) => (
                <>
                    {currency}{" "}
                    {mismatch
                        .getBuyProductVariation()
                        ?.getOriginalUnitPrice()
                        .toFixed(2)}
                </>
            ),
        },
        {
            title: "New Price",
            dataIndex: "new_unit_price",
            key: "new_unit_price",
            render: (_: any, mismatch: VariantMismatchModel) => (
                <>
                    {currency} {mismatch.getNewOriginalUnitPrice()}
                </>
            ),
        },
        {
            title: "Amount",
            dataIndex: "total_price",
            key: "total_price",
            render: (_: any, mismatch: VariantMismatchModel) => {
                const buyProductVariation = mismatch.getBuyProductVariation();
                const totalPrice = mismatch?.getNewOriginalUnitPrice();
                const quantity = buyProductVariation?.getQuantity() || 1;
                const totalNewPrice = Number(totalPrice) * quantity;

                return (
                    <>
                        {currency} {totalNewPrice.toFixed(2)}{" "}
                        {/* Use totalNewPrice instead */}
                    </>
                );
            },
        },
    ];

    const dataSource: VariantMismatchModel[] =
        data.getVariantMismatches() || [];

    return (
        <div style={{ marginTop: "1rem" }}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey={(record) => record.getId()}
            />
        </div>
    );
};

export default PriceIncreaseIssues;
