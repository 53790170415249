import { ResponsePromise } from "@/lib/core/request/types/typings";

import {
    RolePostRequestModel,
    RoleUpdateRequestModel,
} from "@/models/roleCollectionModel";
import {
    IDestinationWarehouseRoleForm,
    IRoleCollection,
} from "@/types/roleCollection";
import {
    IImpersonateCreateResponse,
    IImpersonateSession,
    IImpersonateSessionCollection,
    IImpersonationSession,
} from "@/types/userCollection";
import BaseResource from "../public/baseResource";

class AclResource extends BaseResource {
    rolePath = `/api/acl/admin/role/v1/roles`;
    permissionPath = `/api/acl/admin/permission/v1/permissions`;
    impersonatePath = `/api/user/admin/impersonate/v1/sessions`;

    // api/acl/admin/role/v1/roles

    //Roles
    getRoles(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.rolePath}?expand=permissions&per_page=999`;
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    createRole(
        payload: RolePostRequestModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.rolePath}/`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updateRole(
        id: number,
        payload: RoleUpdateRequestModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.rolePath}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    getPermissionsByRole(
        role_id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<{
        message: string;
        selected_permission_ids: number[];
    }> {
        const path = `${this.rolePath}/${role_id}/permission`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    // Permissions
    getPermissions(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.permissionPath}/?per_page=999`;
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    createDestinationWarehouseRole(
        payload: IDestinationWarehouseRoleForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.rolePath}/`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    getLoginUserUserPermissions(
        params?: string,
    ): ResponsePromise<IRoleCollection> {
        let path = "api/acl/admin/role/v1/user/roles/permissions";
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path);
    }

    createImpersonateSession(
        payload: IImpersonationSession,
    ): ResponsePromise<IImpersonateCreateResponse> {
        const path = `${this.impersonatePath}`;
        return this.client.request("POST", path, payload, {}, {});
    }
    getImpersonateSessions(
        params?: string,
    ): ResponsePromise<IImpersonateSessionCollection> {
        let path = `${this.impersonatePath}`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path);
    }

    getImpersonateSessionsDetails(
        params?: string,
    ): ResponsePromise<IImpersonateSession> {
        let path = `${this.impersonatePath}/by-user`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path);
    }
}

export default AclResource;
