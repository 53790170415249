import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Image, Card, Row, Col, Skeleton } from "antd";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { PageHeader } from "@ant-design/pro-layout";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useService } from "@/lib/core-react";
import { InvoiceModel } from "@/models/invoiceCollectionModel";
import { getError } from "@/lib/core-react/hooks/utils/errors";

const PrintCustomerInvoice = () => {
    const navigate = useNavigate();
    const printRef = useRef<HTMLDivElement>(null);
    const { invoiceService } = useService();
    const { id: invoiceId } = useParams<{ id: string }>();
    const [invoiceDetails, setInvoiceDetails] = useState<{
        isLoading: boolean;
        error: any;
        data: InvoiceModel | undefined;
    }>({
        isLoading: true,
        error: null,
        data: undefined,
    });

    useEffect(() => {
        if (invoiceId) {
            getInvoiceDetailsById(invoiceId);
        }
    }, [invoiceId]);

    const getInvoiceDetailsById = async (
        id: number | string,
    ): Promise<void> => {
        setInvoiceDetails((pre) => ({
            ...pre,
            isLoading: true,
            error: null,
        }));

        try {
            const response =
                await invoiceService.invoiceResource.getCustomerInvoiceDetails(
                    id,
                );
            setInvoiceDetails((pre) => ({
                ...pre,
                data: new InvoiceModel(response.data),
                isLoading: false,
                error: null,
            }));
        } catch (error: any) {
            setInvoiceDetails((pre) => ({
                ...pre,
                isLoading: false,
                error: getError(error),
            }));
        }
    };
    const downloadPDF = async () => {
        const input = printRef.current;

        if (input) {
            // Ensure all images are loaded before capturing
            const images = input.getElementsByTagName("img");
            const loadPromises = Array.from(images).map(
                (img) =>
                    new Promise((resolve, reject) => {
                        if (img.complete) {
                            resolve(img);
                        } else {
                            img.onload = resolve;
                            img.onerror = reject;
                        }
                    }),
            );

            await Promise.all(loadPromises);

            // Ensure the document has rendered images before taking the snapshot
            setTimeout(async () => {
                const canvas = await html2canvas(input, { scale: 2 });
                const imgData = canvas.toDataURL();
                const mimeType = imgData
                    .split(",")[0]
                    .split(":")[1]
                    .split(";")[0];
                const pdf = new jsPDF("p", "mm", "a4");
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
                pdf.addImage(
                    imgData,
                    mimeType.toUpperCase().split("/")[1],
                    0,
                    0,
                    pdfWidth,
                    pdfHeight,
                );
                pdf.save(`invoice_${invoiceDetails?.data?.invoice_number}.pdf`);
            }, 1000); // Adjust timeout as necessary
        }
    };

    // Columns for the product variations table

    // const productColumns = [
    //   { title: 'Carton', dataIndex: 'cartonNumber', key: 'cartonNumber' },
    //   { title: 'CBM', dataIndex: 'cbm', key: 'cbm' },
    //   { title: 'Weight', dataIndex: 'weight', key: 'weight' },
    //   { title: 'Rate per Carton', dataIndex: 'unitPrice', key: 'unitPrice' }
    // ];

    // Columns for the transactions table
    // const transactionColumns = [
    //   { title: 'Date & Time', dataIndex: 'dateTime', key: 'dateTime' },
    //   { title: 'Transaction ID', dataIndex: 'transactionId', key: 'transactionId' },
    //   { title: 'Gateway', dataIndex: 'gateway', key: 'gateway' },
    //   { title: 'Amount', dataIndex: 'amount', key: 'amount' }
    // ];

    // Prepare data for the products table
    // const productData = invoiceDetails?.data?.items.map((item, index) => ({
    //   key: index,
    //   cartonNumber: `Carton ${index + 1}`,
    //   cbm: item.buy_product_variation.cbm,
    //   weight: `${item.buy_product_variation.quantity} KG`,
    //   unitPrice: `${item.buy_product_variation.unit_price} ${invoiceDetails?.data.region.currency.symbol}`
    // })) || [];

    // Prepare data for the transactions table
    // const transactionData = invoiceDetails?.data?.transactions.data.map((tx, index) => ({
    //   key: index,
    //   dateTime: new Date(tx.created_at).toLocaleString(),
    //   transactionId: tx.id,
    //   gateway: tx.gateway,
    //   amount: `${tx.amount} ${invoiceDetails?.data.region.currency.symbol}`
    // })) || [];

    if (invoiceDetails.isLoading) {
        return <Skeleton active paragraph={{ rows: 20 }} />;
    }
    return (
        <>
            <PageHeader
                onBack={() => navigate(-1)}
                title={`Invoice: ${invoiceDetails?.data?.invoice_number}`}
            />
            <div ref={printRef} id={"invoice"} style={{ padding: "10px" }}>
                <div className="invoice-header-brand-bg"> </div>
                <Card className="invoice-header invoice-card" bordered={false}>
                    <Row>
                        <Col span={18}>
                            <h1>MoveOn Technology Ltd.</h1>
                            <div className="header-content">
                                <p>
                                    <strong>Company Name:</strong>{" "}
                                    <span>Louis Vuitton</span>
                                </p>
                                <p>
                                    <strong>Address:</strong>{" "}
                                    <span>
                                        6391 Elgin St. Celina, Delaware 10299
                                    </span>
                                </p>
                                <p>
                                    <strong>State:</strong> <span>Sichuan</span>
                                </p>
                                <p>
                                    <strong>City:</strong> <span>Chengdu</span>
                                </p>
                                <p>
                                    <strong>Region:</strong> <span>China</span>
                                </p>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="header-details">
                                <p>
                                    <strong>Invoice no:</strong>{" "}
                                    <span>
                                        {invoiceDetails.data?.getInvoiceNumber()}
                                    </span>
                                </p>
                                <p>
                                    <strong>Date:</strong>{" "}
                                    <span>
                                        {invoiceDetails.data?.getCreatedAt()}
                                    </span>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Card>
                <Card className=" invoice-card" bordered={false}>
                    <Row>
                        <Col span={4}>
                            <Image
                                width={200}
                                src={
                                    invoiceDetails?.data?.buy_product
                                        ?.product_image
                                }
                                preview={false}
                                style={{ marginRight: 24 }}
                            />
                        </Col>
                        <Col span={2}></Col>
                        <Col span={10}>
                            <div className="header-content">
                                <p>
                                    <strong>Product Number:</strong>{" "}
                                    <span>Louis Vuitton</span>
                                </p>
                                <p>
                                    <strong>Quantity:</strong>{" "}
                                    <span>
                                        6391 Elgin St. Celina, Delaware 10299
                                    </span>
                                </p>
                                <p>
                                    <strong>CBM:</strong> <span>Sichuan</span>
                                </p>
                                <p>
                                    <strong>City:</strong> <span>Chengdu</span>
                                </p>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="header-details">
                                <p>
                                    <strong>Product Number:</strong>{" "}
                                    <span>Louis Vuitton</span>
                                </p>
                                <p>
                                    <strong>Quantity:</strong>{" "}
                                    <span>
                                        6391 Elgin St. Celina, Delaware 10299
                                    </span>
                                </p>
                                <p>
                                    <strong>CBM:</strong> <span>Sichuan</span>
                                </p>
                                <p>
                                    <strong>City:</strong> <span>Chengdu</span>
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <table id="shipment_product_items">
                            <tbody>
                                <tr className={"border-top-style"}>
                                    <td>01</td>
                                    <td className={"align-item-center"}>
                                        CBM: 02
                                    </td>
                                    <td className={"align-item-center"}>
                                        Weight : 2kg
                                    </td>
                                    <td className="price">
                                        {" "}
                                        Rate per carton : ¥427.00
                                    </td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td className={"align-item-center"}>
                                        CBM: 02
                                    </td>
                                    <td className={"align-item-center"}>
                                        Weight : 2kg
                                    </td>
                                    <td className="price">
                                        {" "}
                                        Rate per carton : ¥427.00
                                    </td>
                                </tr>
                                <tr>
                                    <td>01</td>
                                    <td className={"align-item-center"}>
                                        CBM: 02
                                    </td>
                                    <td className={"align-item-center"}>
                                        Weight : 2kg
                                    </td>
                                    <td className="price">
                                        {" "}
                                        Rate per carton : ¥427.00
                                    </td>
                                </tr>
                                <tr className="border-bottom-style">
                                    <td colSpan={3}></td>
                                    <td id="totalPrice" className="price ">
                                        Total : 627.00
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                </Card>
                <Card className={"invoice-card border-none"}>
                    <div className="transactions-container">
                        <div className="header">
                            <h2>Transactions</h2>
                            <div className="status">
                                <span>Status:</span>
                                <span className="status-settled">Settled</span>
                            </div>
                        </div>
                        <table id="transactions_table">
                            <thead>
                                <tr>
                                    <th>Date & Time</th>
                                    <th>Transaction ID</th>
                                    <th>Gateway</th>
                                    <th>Amount (CH)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>19 October, 2023, 4:41pm</td>
                                    <td>TX5746656decf</td>
                                    <td>Wallet</td>
                                    <td>¥5627.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>
            <div className="buttons action-button">
                <ReactToPrint
                    trigger={() => {
                        return (
                            <button>
                                <PrinterOutlined /> Print
                            </button>
                        );
                    }}
                    content={() => printRef.current}
                />
                <button onClick={downloadPDF}>
                    <DownloadOutlined /> Download as PDF
                </button>
            </div>
        </>
    );
};

export default PrintCustomerInvoice;
