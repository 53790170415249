import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { Config, RequestMethod, RequestOptions } from "./types/request";
import { getEnvironmentMode } from "../../../utils/helper";
import setAndGetGuestData from "@/helpers/setAndGetGuestData";

const defaultConfig = {
    maxRetries: 0,
    baseUrl: "",
};

export class Client {
    private axiosClient: AxiosInstance;
    private config: Config;

    constructor(config: Config) {
        /** @private @constant {AxiosInstance} */
        this.axiosClient = this.createClient({ ...defaultConfig, ...config });

        /** @private @constant {Config} */
        this.config = { ...defaultConfig, ...config };
        this.addInterceptors();
    }

    private addInterceptors() {
        // Response interceptor
        this.axiosClient.interceptors.response.use(
            (response: AxiosResponse) => {
                // Handle successful responses globally if needed
                return response;
            },
            (error: AxiosError) => {
                // Handle errors globally
                return Promise.reject(error); // Always reject to pass error down
            },
        );

        // You can also add a request interceptor if you want to manipulate requests
        this.axiosClient.interceptors.request.use(
            (config) => {
                // Modify request globally if needed
                return config;
            },
            (error: AxiosError) => {
                // Handle request errors globally
                return Promise.reject(error);
            },
        );
    }

    shouldRetryCondition(
        err: AxiosError,
        numRetries: number,
        maxRetries: number,
    ): boolean {
        // Obviously, if we have reached max. retries we stop
        if (numRetries >= maxRetries) {
            return false;
        }

        // If no response, we assume a connection error and retry
        if (!err.response) {
            return true;
        }

        // Retry on conflicts
        if (err.response.status === 409) {
            return true;
        }

        // All 5xx errors are retried
        // OBS: We are currently not retrying 500 requests, since our core needs proper error handling.
        //      At the moment, 500 will be returned on all errors, that are not of type BaseError.
        if (err.response.status > 500 && err.response.status <= 599) {
            return true;
        }

        return false;
    }

    // Stolen from https://github.com/stripe/stripe-node/blob/fd0a597064289b8c82f374f4747d634050739043/lib/utils.js#L282
    normalizeHeaders(obj: Record<string, any>): Record<string, any> {
        if (!(obj && typeof obj === "object")) {
            return obj;
        }

        return Object.keys(obj).reduce(
            (result, header) => {
                result[this.normalizeHeader(header)] = obj[header];
                return result;
            },
            {} as Record<string, any>,
        );
    }

    // Stolen from https://github.com/marten-de-vries/header-case-normalizer/blob/master/index.js#L36-L41
    normalizeHeader(header: string): string {
        return header
            .split("-")
            .map(
                (text) =>
                    text.charAt(0).toUpperCase() + text.substr(1).toLowerCase(),
            )
            .join("-");
    }

    // requiresAuthentication(path, method): boolean {
    //   return (
    //     path.startsWith("/customer") &&
    //     unAuthenticatedStorefrontEndpoints[path] !== method
    //   )
    // }

    /**
     * Creates all the initial headers.
     * We add the idempotency key, if the request is configured to retry.
     * @param {object} userHeaders user supplied headers
     * @param {Types.RequestMethod} method request method
     * @param {string} path request path
     * @param {object} customHeaders user supplied headers
     * @return {object}
     */
    setHeaders(
        userHeaders: RequestOptions,
        method: RequestMethod,
        _path: string,
        customHeaders: Record<string, any> = {},
    ): Record<string, any> {
        const defaultHeaders: Record<string, any> = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "X-GUEST-ID": setAndGetGuestData()?.guestId || "",
            "X-GUEST-SESSION": setAndGetGuestData()?.guestSession || "",
            "X-SESSION-TOKEN":
                getEnvironmentMode() === "development"
                    ? import.meta.env.VITE_ORY_TOKEN
                    : null,
        };

        // only add idempotency key, if we want to retry
        if (this.config.maxRetries > 0 && method === "POST") {
            defaultHeaders["Idempotency-Key"] = uuidv4();
        }

        return Object.assign(
            {},
            defaultHeaders,
            this.normalizeHeaders(userHeaders),
            customHeaders,
        );
    }

    /**
     * Creates the axios client used for requests
     * As part of the creation, we configure the retry conditions
     * and the exponential backoff approach.
     * @param {Config} config user supplied configurations
     * @return {AxiosInstance}
     */
    createClient(config: Config): AxiosInstance {
        // console.log(config);
        return axios.create({
            baseURL: config.baseUrl,
        });
    }

    /**
     * Axios request
     * @param method request method
     * @param path request path
     * @param payload request payload
     * @param options axios configuration
     * @param customHeaders custom request headers
     * @return
     */
    async request(
        method: RequestMethod,
        path: string,
        payload: Record<string, any> = {},
        options: RequestOptions = {},
        customHeaders: Record<string, any> = {},
    ): Promise<any> {
        const reqOpts = {
            method,
            withCredentials: true,
            url: path,
            json: true,
            headers: this.setHeaders(options, method, path, customHeaders),
        };

        // if (["POST", "DELETE"].includes(method)) {
        //   reqOpts["data"] = payload;
        // }
        reqOpts["data"] = payload;
        // e.g. data = { cart: { ... } }, response = { status, headers, ... }
        const { data, ...response } = await this.axiosClient(reqOpts);

        // console.log(response,"response",data)
        // e.g. would return an object like of this shape { cart, response }
        return { ...data, response };
    }
}
