import { useAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import {
    inventoryCategoryTreeAtom,
    inventoryCategoryTreeAtomByName,
    inventoryProductDetailAtom,
    inventoryProductSearchResultAtom,
} from "@/lib/core-react/store/store";
import { useState } from "react";
import { ProductSearchResultModel } from "@/models/ProductSearchCollectionModel";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { getErrorCode } from "@/helpers/getErrorCode";
import { IProductDetailParams } from "@/types/productDetailExtended";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";
import { InventoryBaseShippingCategoryMappingModel } from "@/models/inventoryBaseShippingCategoryMappingCollectionModel";
import { getError } from "../utils/errors";
import { IBaseShippingCategoryMappingForm } from "@/types/inventoryCategoryCollection";
import { notification } from "antd";
interface ErrorProps {
    code: number;
    message: string;
}

export const useGetInventoryCategoriesTree = () => {
    const [inventoryCategoryTreeResult, setInventoryCategoryTreeResult] =
        useAtom(inventoryCategoryTreeAtom);

    const { productService } = useService();
    const getCategoriesTree = async (params?: any): Promise<void> => {
        setInventoryCategoryTreeResult({
            ...inventoryCategoryTreeResult,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await productService.productResource.getCategoriesTree(params);
            setInventoryCategoryTreeResult({
                ...inventoryCategoryTreeResult,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setInventoryCategoryTreeResult({
                ...inventoryCategoryTreeResult,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return {
        getCategoriesTree,
    } as const;
};
export const useGetInventoryCategoriesTreeByName = () => {
    const [inventoryCategoryTreeResult, setInventoryCategoryTreeResult] =
        useAtom(inventoryCategoryTreeAtomByName);

    const { productService } = useService();
    const getCategoriesTreeByName = async ({
        name,
    }: {
        name: string;
    }): Promise<void> => {
        setInventoryCategoryTreeResult({
            ...inventoryCategoryTreeResult,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await productService.productResource.getCategoriesTreeByName(
                    name,
                );
            setInventoryCategoryTreeResult({
                ...inventoryCategoryTreeResult,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setInventoryCategoryTreeResult({
                ...inventoryCategoryTreeResult,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return {
        getCategoriesTreeByName,
    } as const;
};

export const useGetBaseShippingCategoryMappings = () => {
    const [baseShippingCategoryMappings, setBaseShippingCategoryMappings] =
        useState<InventoryBaseShippingCategoryMappingModel>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { productService } = useService();
    const getBaseShippingCategoryMappings = async (
        params: Record<string, any>,
    ): Promise<void> => {
        setIsLoading(true);
        setError(undefined);
        try {
            const response =
                await productService.productResource.getBaseShippingCategoryMappings(
                    params,
                );
            if (response) {
                const processData =
                    new InventoryBaseShippingCategoryMappingModel(response);
                setBaseShippingCategoryMappings(processData);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        getBaseShippingCategoryMappings,
        isLoading,
        data: baseShippingCategoryMappings,
        error,
    } as const;
};

export const useCreateBaseShippingCategoryMapping = () => {
    const [inventoryCategoryTreeResult, setInventoryCategoryTreeResult] =
        useAtom(inventoryCategoryTreeAtom);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { productService } = useService();
    const createBaseShippingCategoryMapping = async (
        payload: IBaseShippingCategoryMappingForm,
    ): Promise<void> => {
        setIsLoading(true);
        setError(undefined);
        try {
            await productService.productResource.createBaseShippingCategoryMapping(
                payload,
            );
            setInventoryCategoryTreeResult({
                ...inventoryCategoryTreeResult,
                refetch: true,
            });

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        createBaseShippingCategoryMapping,
        isLoading,
        error,
    } as const;
};

// detaching category
export const useDetachCategory = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);
    const [inventoryCategoryTreeResult, setInventoryCategoryTreeResult] =
        useAtom(inventoryCategoryTreeAtom);

    const { productService } = useService();
    const detachCategory = async (
        payload: IBaseShippingCategoryMappingForm,
    ): Promise<void> => {
        setIsLoading(true);
        setError(undefined);
        try {
            await productService.productResource.detachBaseShippingCategoryMapping(
                payload,
            );
            setInventoryCategoryTreeResult({
                ...inventoryCategoryTreeResult,
                refetch: true,
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        detachCategory,
        isLoading,
        error,
    } as const;
};

export const useSyncCategoryMapping = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);
    const [inventoryCategoryTreeResult, setInventoryCategoryTreeResult] =
        useAtom(inventoryCategoryTreeAtom);

    const { productService } = useService();
    const syncCategoryMapping = async (): Promise<void> => {
        setIsLoading(true);
        setError(undefined);
        try {
            await productService.productResource.syncCategoryMapping();
            notification["success"]({
                message: "Inventory category started syncing.",
            });
            setInventoryCategoryTreeResult({
                ...inventoryCategoryTreeResult,
                refetch: true,
            });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            notification["error"]({
                message: "Inventory category syncing failed.",
            });
            throw error;
        }
    };
    return {
        syncCategoryMapping,
        isLoading,
        error,
    } as const;
};

export const useGetSearchProducts = () => {
    const [productSearchResult, setProductSearchResult] = useAtom(
        inventoryProductSearchResultAtom,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { productService } = useService();
    const getProducts = async (
        params: { region: string; locale: string; [key: string]: any },
        payload?: Record<string, any>,
    ): Promise<void> => {
        setIsLoading(true);
        setError(undefined);

        try {
            const response =
                await productService.productResource.getProductList(
                    params,
                    payload,
                );
            const processData =
                response && new ProductSearchResultModel(response);
            if (processData) {
                setProductSearchResult(() => processData);
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        getProducts,
        isLoading,
        data: productSearchResult,
        error,
    } as const;
};

export const useGetProductByUrl = () => {
    const [productDetailResult, setProductDetailResult] = useAtom(
        inventoryProductDetailAtom,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { productService } = useService();
    const getProductDetailByUrl = async (
        params: IProductDetailParams,
    ): Promise<void> => {
        try {
            setIsLoading(true);
            setError(undefined);
            const response =
                await productService.productResource.getProductByUrl(params);
            if (response) {
                const processData =
                    response && new ProductDetailExtendedModel(response.data);
                setProductDetailResult(processData);
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        getProductDetailByUrl,
        isLoading,
        data: productDetailResult,
        error,
    } as const;
};

export const useGetProductById = () => {
    const [productDetailResult, setProductDetailResult] = useAtom(
        inventoryProductDetailAtom,
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { productService } = useService();
    const getProductDetailById = async (
        productId: string,
        params: Omit<IProductDetailParams, "url">,
    ): Promise<void | ProductDetailExtendedModel> => {
        try {
            setIsLoading(true);
            setError(undefined);
            const response =
                await productService.productResource.getProductById(
                    productId,
                    params,
                );
            if (response) {
                const processData = new ProductDetailExtendedModel(
                    response.data,
                );
                setProductDetailResult(processData);
                setIsLoading(false);
                return processData;
            }
        } catch (error) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return {
        getProductDetailById,
        isLoading,
        data: productDetailResult,
        error,
    } as const;
};

export const useGetProductDescription = () => {
    const { productService } = useService();

    const [data, setData] = useState<
        | {
              message: string;
              data: string;
          }
        | undefined
    >(undefined);

    const getProductDescription = async (
        params: Record<string, any>,
        productId: string,
    ): Promise<
        | {
              message: string;
              data: string;
          }
        | undefined
    > => {
        const response = await productService.productResource.getDescription({
            params,
            productId,
        });

        setData(response);
        return response;
    };
    return { getProductDescription, data } as const;
};
