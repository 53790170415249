import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Card,
    Form,
    Input,
    Select,
    Table,
    Typography,
    Dropdown,
    MenuProps,
    Skeleton,
    Row,
    Col,
    Tabs,
    Flex,
    notification,
    Alert,
    Modal,
} from "antd";
import { useEffect, useState } from "react";
import { BuyOrderOperationEnum } from "@/enums/buyOrderCollectionEnums";
import { useLocation, useNavigate } from "react-router-dom";
import {
    addressCollectionAtom,
    purchaseCartCollectionAtom,
} from "@/lib/core-react/store/store";
import { useAtom } from "jotai";
import VariationTable from "./BuyOrderPlace/expandedRowRender";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    HddFilled,
} from "@ant-design/icons";
import { IBuyOrderPlacePayload, ICartItem } from "@/types/buyOrderPlace";
import {
    useDeleteCartProduct,
    useGetCartList,
} from "@/lib/core-react/hooks/private/usePurchaseCart";
import TextArea from "antd/es/input/TextArea";
import {
    useAddressDelete,
    useGetAddress,
} from "@/lib/core-react/hooks/private/useAddress";
import { AddressCollectionModel } from "@/models/addressCollectionModel";
import Address from "./BuyOrderPlace/Adddress";
import { useBuyProductOrderPlace } from "@/lib/core-react/hooks/private/usePurchase";
import TabPane from "antd/es/tabs/TabPane";
import Link from "antd/lib/typography/Link";
import { UserSelect } from "@/components/CustomFormElement/UserSelect";
import { useDebounce } from "@/hooks/useDebounce";
import { responseTypeQuery } from "@/filters/constant";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { ColumnsType } from "antd/es/table";
import { BREAK_POINTS } from "@/consts/appConstants";
import useActionsProps from "@/helpers/useActionsProps";
import { IPurchaseCartExtended } from "@/types/PurchaseCartCollection";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import usePageQuery from "@/helpers/usePageQuery";
import isProductUrl from "@/helpers/isProductUrl";
import { parseObjectToString } from "@/helpers/stringify";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { defaultQuery } from "@/consts/defaultQuery";
import ShippingText from "@/components/ShippingText";
import { StatusTag } from "@/components";

const BuyOrderCreate = () => {
    const location = useLocation();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const { Text } = Typography;
    const {
        allOptionsData: {
            customerOptionsData,
            countryOptionsData,
            regionOptionsData,
            addonServiceOptionsData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { deleteCartProduct } = useDeleteCartProduct();
    const { getAddress } = useGetAddress();
    const { deleteAddress } = useAddressDelete();
    const { getCartList } = useGetCartList();
    const { buyProductOrderPlace, isLoading: isLoadingOrderPlace } =
        useBuyProductOrderPlace();
    const { getCurrentQuery, updateQuery, resetQuery } = usePageQuery();
    const params = new URLSearchParams(location.search);
    const user_id = params.get("userId");
    const regionCode = params.get("region");
    const countryId = params.get("countryId");
    const countryCode = params.get("countryCode");
    const regionId = params.get("regionId");
    const productUrl = params.get("productUrl");

    // State
    const [selectedAddressId, setSelectedAddressId] = useState<
        number | undefined
    >(undefined);

    const [selectedTab, setSelectedTab] = useState<
        "impersonate-cart" | "regular" | string
    >("impersonate-cart");

    const actionsProps = useActionsProps();
    const [isLoadingPlaceOrder, setIsLoadingPlaceOrder] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const [urlTerm, setUrlTerm] = useState("");
    const productUrlTerm = useDebounce(urlTerm, 100);

    const [
        {
            data: addressCollectionData,
            isLoading: isLoadingAddressData,
            error: errorAddress,
        },
    ] = useAtom(addressCollectionAtom);

    const [
        { data: purchaseCartCollectionData, isLoading: isPurchaseCartLoading },
    ] = useAtom(purchaseCartCollectionAtom);

    const [queryData, setQueryData] = useState<{
        userId: number | "" | null | undefined;
        region: string | null;
        countryCode: string | null;
        productUrl: string | null;
        regionId: number | "" | null | undefined;
        countryId: number | "" | null | undefined;
    }>({
        userId: user_id && Number(user_id),
        region: regionCode,
        countryCode: regionCode,
        productUrl: productUrl,
        regionId: regionId && Number(regionId),
        countryId: countryId && Number(countryId),
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<IPurchaseCartExtended[]>(
        [],
    );
    // ----------------------------Api Call----------------------------
    useEffect(() => {
        updateQuery({
            ...getCurrentQuery(),
            ...queryData,
        });
    }, [queryData]);

    useEffect(() => {
        form.setFieldValue("productUrl", productUrl);
    }, [productUrl]);

    useEffect(() => {
        if (queryData.userId && queryData.regionId) {
            getCartList({
                user_id: queryData.userId,
                region_id: queryData.regionId,
            });
        }
    }, [queryData.userId, queryData.regionId]);

    useEffect(() => {
        if (queryData.countryCode && queryData.userId) {
            getAddress({
                countries: [queryData.countryCode],
                user_id: queryData.userId,
            });
        }
    }, [queryData.region, queryData.userId, countryCode, user_id]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
        onFetchFilterApi(filterResourceEnum.ADDON_SERVICE_LIST);
    }, []);

    useEffect(() => {
        if (regionOptionsData.options.length) {
            const singleRegion = regionOptionsData.options.find(
                (x) => x.code === regionCode,
            );
            form.setFieldValue("region_id", singleRegion);
            if (singleRegion?.value) {
                setQueryData((prev) => ({
                    ...prev,
                    regionId: singleRegion.value as unknown as number,
                }));
            }
        }
    }, [regionOptionsData.isLoading]);

    useEffect(() => {
        if (customerOptionsData.options.length) {
            const singleUser = customerOptionsData.options.find(
                (x) => x.value === Number(queryData.userId),
            );
            form.setFieldValue("user_id", singleUser);
        }
    }, [customerOptionsData.isLoading]);
    useEffect(() => {
        if (countryOptionsData.options.length) {
            const singleCountry = countryOptionsData.options.find(
                (x) => x.value === Number(queryData.countryId),
            );
            if (singleCountry) {
                form.setFieldValue("country_id", singleCountry);
                setQueryData((prev) => ({
                    ...prev,
                    countryId: singleCountry.value as unknown as number,
                }));
            } else {
                form.setFieldValue("country_id", countryOptionsData.options[0]);
                setQueryData((prev) => ({
                    ...prev,
                    countryId: countryOptionsData.options[0]
                        .value as unknown as number,
                    countryCode: countryOptionsData.options[0]
                        .code as unknown as string,
                }));
            }
        }
    }, [countryOptionsData.isLoading]);

    useEffect(() => {
        if (queryData.regionId) {
            onFetchFilterApi(filterResourceEnum.COUNTRY, {
                region_id: queryData.regionId,
            });
        }
    }, [queryData.regionId]);

    useEffect(() => {
        form.setFieldValue("shipping_address_id", selectedAddressId);
    }, [selectedAddressId]);

    const validateQuery = (query: Record<string, any>) => {
        for (const [key, value] of Object.entries(query)) {
            if (!value) {
                throw new Error(`The value for '${key}' is required .`);
            }
        }
    };

    const handleEditCartItem = (record: IPurchaseCartExtended) => {
        try {
            const query = {
                productId: record.product_id,
                operation: BuyOrderOperationEnum.PurchaseCartItemAdd,
                userId: record.user.id,
                region: record.region.code,
                region_id: record.region.id,
            };

            validateQuery(query);

            navigate(
                `/service/inventory/product-detail?${parseObjectToString(query)}`,
                {
                    state: { from: location.pathname },
                },
            );
        } catch (error) {
            showError(error);
        }
    };

    const handleGoToInventory = () => {
        try {
            const query = {
                operation: BuyOrderOperationEnum.PurchaseCartItemAdd,
                userId: queryData.userId,
                region: queryData?.region,
                region_id: queryData?.regionId,
            };

            validateQuery(query);
            navigate(`/service/inventory?${parseObjectToString(query)}`, {
                state: { from: location.pathname },
            });
        } catch (error) {
            showError(error);
        }
    };
    const handleGoToInventoryProductDetailPage = (productUrl: string) => {
        try {
            if (!isProductUrl(productUrl)) {
                throw new Error("please paste valid product url");
            }
            const query = {
                productUrl: productUrl,
                operation: BuyOrderOperationEnum.PurchaseCartItemAdd,
                userId: queryData.userId,
                region: queryData?.region,
                region_id: queryData?.regionId,
            };

            validateQuery(query);

            navigate(
                `/service/inventory/product-detail?${parseObjectToString(query)}`,
                {
                    state: { from: location.pathname },
                },
            );
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        setQueryData((prev) => ({ ...prev, productUrl: productUrlTerm }));
        updateQuery({
            productUrl: productUrlTerm,
        });
    }, [productUrlTerm]);

    const handleDeleteCart = async (record: IPurchaseCartExtended) => {
        try {
            if (!queryData.region || !queryData.userId) {
                throw new Error("region or user id Not Found");
            }

            const payload = {
                user_id: record.user.id,
                region: record.region.code,
                ids: [record.purchaseCartItemId],
            };

            Modal.confirm({
                title: "Are you sure you want to delete the Cart Item?",
                icon: <ExclamationCircleOutlined />,
                content: "Select Ok, otherwise the process cannot success.",
                onOk: async () => {
                    try {
                        await deleteCartProduct(
                            record.region.code,
                            defaultQuery.locale,
                            payload,
                        );
                        showSuccessAlert("Successfully Deleted Cart item");

                        if (queryData.userId) {
                            await getCartList({
                                user_id: queryData.userId,
                                region_id: queryData.regionId,
                            });
                        }
                        Promise.resolve();
                    } catch (error) {
                        showError(error);
                        Promise.reject();
                    }
                },
                onCancel() {
                    notification["info"]({
                        message: "Product Reject was aborted.",
                    });
                },
            });
        } catch (error) {
            showError(error);
        }
    };

    const onFinish = (values: any, submitType: string) => {
        if (submitType === "productUrl") {
            if (values && isProductUrl(values.productUrl)) {
                handleGoToInventoryProductDetailPage(values.productUrl);
            } else {
                notification.error({ message: "please paste valid url" });
            }
        } else if (submitType === "create-custom-order") {
            const payload = {
                region_id: regionId,
                userId: user_id,
                region: regionCode,
                countryCode,
            };
            const validate = Object.values(payload).every((value) =>
                Boolean(value),
            );

            if (validate) {
                navigate(
                    `/purchase/buy-orders/create-custom-order?${parseObjectToString(payload)}`,
                    {
                        state: { from: location.pathname },
                    },
                );
            } else {
                notification.error({
                    message: " Some data as missing to go custom order page",
                });
            }
        } else {
            handleOrderPlace(values);
        }
    };

    const handleOrderPlace = async (values) => {
        try {
            setIsLoadingPlaceOrder(true);
            // const filteredRows = selectedRows.filter(
            //     (row) => row.cart_type === selectedTab,
            // );
            const filteredRows = selectedRows;

            const items: ICartItem[] = filteredRows.flatMap((item) => {
                return {
                    id: item.id,
                    variation_ids: item.variations.map((variant) => variant.id),
                };
            });

            if (filteredRows.length === 0) {
                throw new Error("user id and purchase cart id not found");
            }

            const payload: IBuyOrderPlacePayload = {
                user_id: filteredRows[0].user.id,
                cart: {
                    id: filteredRows[0].purchaseCartId,
                    items: items,
                },
                addon_service_ids: values.addon_service_ids ?? [],
                shipping_address_id: Number(selectedAddressId),
                instruction: values.note ?? "",
            };
            await buyProductOrderPlace(payload);
            showSuccessAlert("Successfully Buy Order Place");
            setSelectedRowKeys([]);
            setSelectedRows([]);
            navigate("/purchase/buy-orders");
            // FIXME : take time to get updated data when fetch instantly after place order that's why refetch after 5000 millisecond, threads created for the problem
            // const timeoutId = setTimeout(async () => {
            //     try {
            //         await getCartList({
            //             user_id: Number(filteredRows[0].user.id),
            //         });
            //         setIsLoadingPlaceOrder(false);
            //         clearTimeout(timeoutId);
            //     } catch (error) {
            //         showError(error);
            //         clearTimeout(timeoutId);
            //         setIsLoadingPlaceOrder(false);
            //     }
            // }, 3000);
            form.resetFields();
        } catch (error) {
            showError(error);
            setIsLoadingPlaceOrder(false);
        }
    };

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.CUSTOMER, {
            keyword: debouncedSearchTerm,
            ...responseTypeQuery.minimal,
        });
    }, [debouncedSearchTerm]);

    const handleSearch = (q) => {
        setSearchTerm(q);
    };

    const handleDeleteAddress = async (addressId: number) => {
        Modal.confirm({
            title: "Are you sure you want to delete this address?",
            icon: <ExclamationCircleOutlined />,
            content: "Select Ok, otherwise the process cannot success.",
            onOk: async () => {
                try {
                    await deleteAddress(addressId);
                    showSuccessAlert("Successfully Address Deleted");
                    if (queryData.countryCode && queryData.userId) {
                        await getAddress({
                            countries: [queryData.countryCode],
                            user_id: queryData.userId,
                        });
                    }
                    Promise.resolve();
                } catch (error) {
                    showError(error);
                    Promise.reject();
                }
            },
            onCancel() {
                notification["info"]({
                    message: "Product Reject was aborted.",
                });
            },
        });
    };
    // ------------------------data Model-------------------------

    const addressCollectionObj =
        addressCollectionData &&
        addressCollectionData.data &&
        new AddressCollectionModel(addressCollectionData);

    const isDisableButton =
        !queryData.region || !queryData.regionId || !queryData.userId;

    const isCustomOrderDisabled =
        !regionCode || !countryCode || !user_id || !regionId;
    // ------------------------ui-------------------------
    const columns: ColumnsType<IPurchaseCartExtended> = [
        {
            title: "Product Info",
            dataIndex: "product_image",
            key: "product_image",
            render: (_, record) => {
                return (
                    <Flex gap={12}>
                        <ImageWithPlaceholder
                            style={{
                                width: 120,
                            }}
                            src={record.product_image}
                        />
                        <Flex gap={2} vertical>
                            <Text>
                                <Link target={"_blank"} rel="noreferrer">
                                    {record.product_title}
                                </Link>
                            </Text>
                            <ShippingText label="Region">
                                <StatusTag
                                    slug={record.region.code}
                                    text={record.region.name}
                                />
                            </ShippingText>
                        </Flex>
                    </Flex>
                );
            },
        },

        {
            title: "Quantity",
            dataIndex: "total_quantity",
            key: "total_quantity",
            render: (total_quantity: any) => {
                return <Text>{total_quantity}</Text>;
            },
        },
        {
            title: "Original Price",
            dataIndex: "original_total",
            key: "original_total",
            render: (original_total: any) => {
                return <Text>{original_total}</Text>;
            },
        },
        {
            title: `Total Price`,
            dataIndex: "total",
            key: "total",
            render: (_, record) => {
                return (
                    <Text>
                        {record.total} {record.region.code}
                    </Text>
                );
            },
        },
        {
            ...actionsProps,
            render: (_: string, record) => {
                const items: MenuProps["items"] = [
                    {
                        label: "Edit",
                        key: record.product_id,
                        icon: <EditOutlined />,
                        onClick: () => handleEditCartItem(record),
                    },
                    {
                        label: "Delete",
                        key: record.id,
                        icon: <DeleteOutlined />,
                        onClick: () => handleDeleteCart(record),
                    },
                ];

                const menuProps = {
                    items,
                };

                return (
                    <Dropdown menu={menuProps} placement={"bottom"}>
                        <Button icon={<HddFilled />}></Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <div>
            <PageHeader
                style={{ marginTop: "10px" }}
                ghost={false}
                title={
                    <Flex align="center" gap={12}>
                        <Typography.Title level={3}>
                            Create Order
                        </Typography.Title>
                        <Button
                            htmlType="button"
                            type="dashed"
                            onClick={() => {
                                resetQuery();
                                window.location.reload();
                            }}
                        >
                            Reset Page
                        </Button>
                    </Flex>
                }
                onBack={() => navigate(-1)}
            >
                <Form
                    form={form}
                    onFinish={(values) => onFinish(values, "full")}
                    layout="vertical"
                    onKeyDown={(e) => {
                        if (e.key == "Enter") {
                            e.preventDefault();
                        }
                    }}
                >
                    <Flex vertical gap={24}>
                        <Card title="Required Information">
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                        label="Select User"
                                        name="user_id"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please select the user!",
                                            },
                                        ]}
                                        required
                                    >
                                        <UserSelect
                                            initialData={
                                                customerOptionsData.options as any[]
                                            }
                                            loading={
                                                customerOptionsData.isLoading
                                            }
                                            resource={"user_id"}
                                            onSearch={(value) => {
                                                handleSearch(value);
                                            }}
                                            allowClear={true}
                                            showSearch={true}
                                            placeholder={
                                                "Search users by email or name"
                                            }
                                            onChange={(value) => {
                                                form.setFieldValue(
                                                    "user_id",
                                                    value,
                                                );
                                                setQueryData((prev) => ({
                                                    ...prev,
                                                    userId: value,
                                                }));
                                            }}
                                            style={{ width: "100%" }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "region is required",
                                            },
                                        ]}
                                        name="region_id"
                                        label="Region"
                                    >
                                        {regionOptionsData.isLoading ? (
                                            <Skeleton.Input
                                                style={{ width: "300px" }}
                                                active
                                                size="small"
                                            />
                                        ) : (
                                            <Select
                                                loading={
                                                    regionOptionsData.isLoading
                                                }
                                                disabled={
                                                    queryData.userId
                                                        ? false
                                                        : true
                                                }
                                                placeholder="Please select a region"
                                                onChange={(value, region) => {
                                                    form.setFieldValue(
                                                        "region_id",
                                                        value,
                                                    );

                                                    setQueryData((prev) => ({
                                                        ...prev,
                                                        regionId: value,
                                                        region:
                                                            // @ts-ignore
                                                            region.code,
                                                    }));
                                                }}
                                                options={
                                                    regionOptionsData.options
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Country is required",
                                            },
                                        ]}
                                        name="country_id"
                                        label="Country"
                                    >
                                        {countryOptionsData.isLoading ? (
                                            <Skeleton.Input
                                                style={{ width: "300px" }}
                                                active
                                                size="small"
                                            />
                                        ) : (
                                            <Select
                                                loading={
                                                    countryOptionsData.isLoading
                                                }
                                                placeholder="Please select a country"
                                                onChange={(value, country) => {
                                                    form.setFieldValue(
                                                        "country_id",
                                                        value,
                                                    );

                                                    setQueryData((prev) => ({
                                                        ...prev,
                                                        countryId: value,
                                                        countryCode:
                                                            // @ts-ignore
                                                            country.code,
                                                    }));
                                                }}
                                                options={
                                                    countryOptionsData.options
                                                }
                                                disabled={!queryData.regionId}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item
                                        label="Product Link"
                                        name="productUrl"
                                    >
                                        <Input
                                            disabled={isDisableButton}
                                            onChange={(e) =>
                                                setUrlTerm(e.target.value)
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    const target =
                                                        e.target as HTMLInputElement;
                                                    handleGoToInventoryProductDetailPage(
                                                        target.value,
                                                    );
                                                }
                                            }}
                                            style={{ flex: 1 }}
                                            placeholder="Please Paste your URL"
                                            defaultValue={
                                                queryData.productUrl || ""
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                    <Form.Item label=" " name="productUrl">
                                        <Flex align="center" gap={34}>
                                            <Button
                                                disabled={
                                                    !productUrl ||
                                                    !queryData.productUrl ||
                                                    isDisableButton
                                                }
                                                type={
                                                    queryData.productUrl
                                                        ? "primary"
                                                        : "default"
                                                }
                                                onClick={() =>
                                                    form
                                                        .validateFields([
                                                            "productUrl",
                                                        ])
                                                        .then((values) =>
                                                            onFinish(
                                                                values,
                                                                "productUrl",
                                                            ),
                                                        )
                                                }
                                            >
                                                Submit
                                            </Button>

                                            <Button
                                                type={
                                                    !isDisableButton
                                                        ? "primary"
                                                        : "default"
                                                }
                                                htmlType="button"
                                                disabled={isDisableButton}
                                                onClick={() =>
                                                    handleGoToInventory()
                                                }
                                            >
                                                Go to inventory
                                            </Button>
                                            <Button
                                                disabled={isCustomOrderDisabled}
                                                type={"dashed"}
                                                onClick={() =>
                                                    form
                                                        .validateFields([
                                                            "region_id",
                                                            "user_id",
                                                            "countryId",
                                                        ])
                                                        .then((values) =>
                                                            onFinish(
                                                                values,
                                                                "create-custom-order",
                                                            ),
                                                        )
                                                }
                                            >
                                                Create Custom Order
                                            </Button>
                                        </Flex>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card
                            title={
                                <Flex align="center" justify="space-between">
                                    <Typography.Title level={4}>
                                        Cart items
                                    </Typography.Title>
                                </Flex>
                            }
                        >
                            <>
                                <Tabs
                                    defaultActiveKey={selectedTab}
                                    onChange={(value) => {
                                        setSelectedRows([]);
                                        setSelectedRowKeys([]);
                                        setSelectedTab(value);
                                    }}
                                >
                                    <TabPane
                                        tab="Admin Cart"
                                        key="impersonate-cart"
                                    >
                                        <Table
                                            rowKey={(r) => r.purchaseCartItemId}
                                            loading={
                                                isPurchaseCartLoading ||
                                                isLoadingOrderPlace ||
                                                isLoadingPlaceOrder
                                            }
                                            columns={columns}
                                            bordered
                                            rowHoverable={false}
                                            dataSource={purchaseCartCollectionData?.getAdminCartData()}
                                            pagination={false}
                                            scroll={{
                                                x: BREAK_POINTS.LG,
                                            }}
                                            rowSelection={{
                                                selectedRowKeys,
                                                onChange(
                                                    selectedRowKeys,
                                                    selectedRows,
                                                ) {
                                                    setSelectedRowKeys(
                                                        selectedRowKeys.map(
                                                            Number,
                                                        ),
                                                    );
                                                    setSelectedRows(
                                                        selectedRows,
                                                    );
                                                },
                                            }}
                                            expandable={{
                                                expandedRowRender: (record) => (
                                                    <VariationTable
                                                        variations={
                                                            record.variations
                                                        }
                                                    />
                                                ),
                                            }}
                                        />
                                    </TabPane>
                                    <TabPane tab="Customer Cart" key="regular">
                                        <Table
                                            rowKey={(r) => r.purchaseCartItemId}
                                            loading={
                                                isPurchaseCartLoading ||
                                                isLoadingOrderPlace ||
                                                isLoadingPlaceOrder
                                            }
                                            columns={columns}
                                            bordered
                                            rowHoverable={false}
                                            dataSource={purchaseCartCollectionData?.getCustomerCartData()}
                                            pagination={false}
                                            scroll={{
                                                x: BREAK_POINTS.LG,
                                            }}
                                            rowSelection={{
                                                selectedRowKeys,
                                                onChange(
                                                    selectedRowKeys,
                                                    selectedRows,
                                                ) {
                                                    setSelectedRowKeys(
                                                        selectedRowKeys.map(
                                                            Number,
                                                        ),
                                                    );
                                                    setSelectedRows(
                                                        selectedRows,
                                                    );
                                                },
                                            }}
                                            expandable={{
                                                expandedRowRender: (record) => (
                                                    <VariationTable
                                                        variations={
                                                            record.variations
                                                        }
                                                    />
                                                ),
                                            }}
                                        />
                                    </TabPane>
                                </Tabs>
                            </>
                        </Card>
                        <Card title={"Others details"}>
                            <Form.Item
                                name="shipping_address_id"
                                label="Shipping Address"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please Select Shipping Address",
                                    },
                                ]}
                            >
                                {isLoadingAddressData ? (
                                    LoaderSmall
                                ) : addressCollectionObj &&
                                  !isLoadingAddressData ? (
                                    <Address
                                        handleDeleteAddress={
                                            handleDeleteAddress
                                        }
                                        address={addressCollectionObj.getData()}
                                        selectedAddressId={selectedAddressId}
                                        setSelectedAddressId={
                                            setSelectedAddressId
                                        }
                                    />
                                ) : (
                                    <Alert
                                        type="error"
                                        message={`Please change region and country. ${errorAddress || ""}`}
                                    />
                                )}
                            </Form.Item>
                            <div style={{ marginTop: "20px" }}>
                                <Form.Item
                                    name="addon_service_ids"
                                    label="Value added services"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please Select services!",
                                        },
                                    ]}
                                >
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Please Select your service...."
                                        loading={
                                            addonServiceOptionsData.isLoading
                                        }
                                        options={
                                            addonServiceOptionsData.options
                                        }
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    name="note"
                                    label="Add Note"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please add your note!",
                                        },
                                    ]}
                                    style={{ marginTop: "10px" }}
                                >
                                    <TextArea
                                        rows={4}
                                        placeholder="Please add your note"
                                    />
                                </Form.Item>
                            </div>
                        </Card>
                    </Flex>

                    <Flex
                        justify="end"
                        style={{
                            marginTop: 24,
                            position: "fixed",
                            right: 32,
                            bottom: 32,
                        }}
                    >
                        <Form.Item noStyle>
                            <Button
                                loading={isLoadingOrderPlace}
                                disabled={selectedRows.length === 0}
                                type={
                                    selectedRows.length === 0
                                        ? "default"
                                        : "primary"
                                }
                                htmlType="submit"
                            >
                                Place Order
                            </Button>
                        </Form.Item>
                    </Flex>
                </Form>
            </PageHeader>
        </div>
    );
};

export default BuyOrderCreate;
