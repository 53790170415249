import { Table } from "antd";
import { ShippingSlot } from "@/types/buyProductCollection";
import { ColumnsType } from "antd/es/table";
import { Typography } from "antd/lib";
import { ShipmentProductAssociationSlotModel } from "@/models/shipmentProductCustomerAssociationModel";

interface Props {
    slots: ShippingSlot[] | ShipmentProductAssociationSlotModel[] | undefined;
    unitType: string | undefined;
    regionCurrencyCode: string | undefined;
}

const ShippingSlotsTable: React.FC<Props> = ({
    slots,
    unitType = "KG",
    regionCurrencyCode: currencyCode,
}) => {
    const { Text } = Typography;

    const columns: ColumnsType<
        ShippingSlot | ShipmentProductAssociationSlotModel
    > = [
        {
            align: "center",
            title: "From",
            dataIndex: ["min_amount"],
            key: "min_amount",
            render: (min_amount) => (
                <Typography>
                    <Text>{min_amount} </Text>
                </Typography>
            ),
        },
        {
            align: "center",
            title: "To",
            dataIndex: ["max_amount"],
            key: "max_amount",
            render: (max_amount) => (
                <Typography>
                    <Text>{max_amount} </Text>
                </Typography>
            ),
        },
        {
            align: "center",
            title: `Rate (Per ${unitType})`,
            dataIndex: ["rate"],
            key: "rate",
            render: (rate) => (
                <>
                    <Typography>
                        <Text>
                            {rate} {currencyCode}{" "}
                        </Text>
                    </Typography>
                </>
            ),
        },
    ];

    return (
        <Table
            title={() => <Typography.Text strong>Slots</Typography.Text>}
            size="small"
            bordered
            rowHoverable={false}
            pagination={false}
            style={{
                width: "100%",
            }}
            columns={columns}
            dataSource={slots}
            rowKey={(r) => r.id}
            locale={{
                emptyText:
                    !slots || slots.length === 0 ? (
                        <Text type="danger">No Slots available.</Text>
                    ) : (
                        "Loading data..."
                    ),
            }}
        />
    );
};

export default ShippingSlotsTable;
