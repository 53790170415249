import { IPagination } from "@/types/pagination";
import {
    StoreAvailabilityStatusEnums,
    StoreCollectionEnums,
} from "@/enums/storeCollectionEnums";
import { IStore, IStoreCollection } from "@/types/storeCollection";
import { IFilterType } from "@/types/filters";
import { formatDateTime } from "@/utils/helpers";
import { ISelectOption } from "@/filters/Models/SelectOptionModel";
import { CurrencyModel } from "./currencyCollectionModel";

export class StoreModel {
    readonly object: StoreCollectionEnums.Store;
    readonly id: number;
    readonly name: string;
    readonly identifier: string;
    readonly default_currency?: CurrencyModel;
    readonly availability_status: StoreAvailabilityStatusEnums;
    readonly created_at: string;

    constructor(dataItem: IStore) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.identifier = dataItem.identifier;
        this.availability_status = dataItem.availability_status;
        this.default_currency = dataItem.default_currency
            ? new CurrencyModel(dataItem.default_currency)
            : undefined;
        this.created_at = dataItem.created_at;
    }

    getId = () => this.id;
    getName = () => this.name;
    getIdentifier = () => this.identifier;
    getAvailabilityStatus = () => this.availability_status;
    getCreatedAt = () => formatDateTime(this.created_at);
    getDefaultCurrency = () => this.default_currency;
}

export class StoreCollectionModel {
    object: StoreCollectionEnums.StoreCollection;
    data: StoreModel[];
    pagination: IPagination;
    filters: IFilterType;

    constructor(data: IStoreCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new StoreModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getData = () => this.data;
    getFilters = () => this.filters;
    getPagination = () => this.pagination;
    getStoreById(id: number): StoreModel | undefined {
        return this.getData().find((item) => item.getId() === id);
    }
    getCurrencyCodeById(id: number) {
        return this.getData()
            .find((item) => item.getId() === id)
            ?.getDefaultCurrency()
            ?.getCode();
    }

    getStoreLabeledValue() {
        return this.data.map((item) => ({
            label: item.getName(),
            value: item.getId(),
        }));
    }

    getStoreIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.getData().forEach((item) => (obj[item.getId()] = item.getName()));
        return obj;
    }
    getSelectComponentSelectOptions(): ISelectOption[] {
        return this.getData() && this.getData().length > 0
            ? this.getData().map((store) => {
                  return {
                      label: store.getName(),
                      value: store.getId(),
                  };
              })
            : [];
    }
}
