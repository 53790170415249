import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import { BuyActionResource } from "../../resources/private";

class BuyTakeActionService extends CommerceApiService {
    public buyActionResource: BuyActionResource;
    constructor() {
        super();
        this.buyActionResource = new BuyActionResource(this.config);
    }
}

export default BuyTakeActionService;
