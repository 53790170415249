import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";

export const useAddonServicesListMinimal = () => {
    const { addonService } = useService();
    const [addonServiceOptionsData, setAddonServiceList] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getAddonServices = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setAddonServiceList((prev) => ({ ...prev, isLoading: true }));

            const response =
                await addonService.addonServiceResource.getList(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(
                          response.data,
                      ).getAddonServiceOptions("name")
                    : [];

            setAddonServiceList({
                isLoading: false,
                options,
            });
        } catch (_error) {
            setAddonServiceList({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getAddonServices, addonServiceOptionsData };
};
