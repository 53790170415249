import { ITranslationEntry } from "../lang";

export const ML: ITranslationEntry = {
    translations: {
        notifications: "അറിയിപ്പുകൾ",
        markAllAsRead: "എല്ലാം വായിച്ചതായി അടയാളപ്പെടുത്തുക",
        poweredBy: "പ്രായോജകർ",
        settings: "ക്രമീകരണങ്ങൾ",
        noNewNotification: "ഇവിടെ ഇതുവരെ പുതുതായി ഒന്നും കാണാനില്ല",
    },
    lang: "ml",
};
