import { useAtomValue } from "jotai";
import { inventoryProductSearchResultAtom } from "@/lib/core-react/store/store";
import CountriesFacet from "./components/CountriesFacet";
import SellersFacet from "./components/SellersFacet";
import CategoriesFacet from "./components/CategoriesFacet";
import ShopsFacet from "./components/ShopsFacet";
import PriceFacet from "./components/PriceMinimumRangeFacet";
import { Flex, Space, Spin } from "antd";
import RatingAverageRangeFacet from "./components/RatingAverageRangeFacet";

const ProductsSidebar = () => {
    const productList = useAtomValue(inventoryProductSearchResultAtom);

    if (!productList) {
        return (
            <Flex style={{ marginTop: 32 }} align="center" justify="center">
                <Spin />
            </Flex>
        );
    }
    return (
        <Space direction="vertical" size={"middle"}>
            {productList && productList.getFacet().getCountries() ? (
                <CountriesFacet
                    payloadKey="country_codes"
                    facetFields={productList.getFacet().getCountries()}
                />
            ) : (
                ""
            )}

            {productList && productList.getFacet()?.getCategories() ? (
                <CategoriesFacet
                    payloadKey="category_names"
                    facetFields={productList.getFacet().getCategories()}
                />
            ) : (
                ""
            )}
            {productList && productList.getFacet()?.getPriceMinimumRanges() ? (
                <PriceFacet
                    payloadKey="price"
                    facetFields={productList.getFacet().getPriceMinimumRanges()}
                />
            ) : (
                ""
            )}
            {productList && productList.getFacet()?.getRatingAverageRanges() ? (
                <RatingAverageRangeFacet
                    payloadKey="rating_avg"
                    facetFields={productList
                        .getFacet()
                        .getRatingAverageRanges()}
                />
            ) : (
                ""
            )}
            {/* {productList && productList.getFacet()?.getBrands() ? (
                <BrandsFacet
                    brandsField={productList?.getFacet().getBrands()}
                />
            ) : (
                ""
            )} */}
            {productList && productList.getFacet()?.getSellers() ? (
                <SellersFacet
                    label="Sellers"
                    payloadKey="seller_names"
                    facetFields={productList.getFacet().getSellers()}
                />
            ) : (
                ""
            )}
            {productList && productList.getFacet()?.getShops() ? (
                <ShopsFacet
                    label="Shops"
                    payloadKey="shops"
                    facetFields={productList.getFacet().getShops()}
                />
            ) : (
                ""
            )}
            {/* {productList && productList.getFacet()?.getProperties() ? (
                <PropertiesFacet
                    payloadKey="properties"
                    propertiesField={productList.getFacet().getProperties()}
                />
            ) : (
                ""
            )} */}
        </Space>
    );
};

export default ProductsSidebar;
