import { Button, Form, FormInstance, Modal, Select, notification } from "antd";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { useUpdateShippingCountry } from "@/lib/core-react/hooks/private/useShipping";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import React, { useEffect } from "react";

interface IProps {
    productIds: number[];
    isOpen: boolean;
    onCancel: (isMutate) => void;
    existingShippingCountryId: number | undefined;
    region_id: number | undefined;
}

export const ChangeShippingCountryModal = ({
    isOpen,
    onCancel,
    productIds,
    existingShippingCountryId,
    region_id,
}: IProps) => {
    const { updateShippingCountry, isLoading: updateShippingCountryLoading } =
        useUpdateShippingCountry();
    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();

    const {
        allOptionsData: { shippingCountryOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY, {
            region_id: region_id,
        });
    }, [region_id]);
    useEffect(() => {
        form.setFieldValue(
            "shipping_country_id",
            shippingCountryOptionsData.options.find(
                (opt) => opt.value === existingShippingCountryId,
            ),
        );
        return () => {
            form.resetFields();
        };
    }, [form, shippingCountryOptionsData.options]);

    const onOkHandler = async (data) => {
        const payload: {
            shipment_product_ids: number[];
            shipping_country_id: number;
        } = {
            shipment_product_ids: productIds,
            shipping_country_id: data.shipping_country_id,
        };

        try {
            const data = await updateShippingCountry(payload);
            notification["success"]({
                message: data.message,
            });
            onCancel(true);
        } catch (error) {
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return (
        <Modal
            title="Change Shipping Country"
            open={isOpen}
            destroyOnClose={true}
            confirmLoading={updateShippingCountryLoading}
            onCancel={onCancel}
            footer={false}
        >
            <Form
                onFinish={onOkHandler}
                form={form}
                layout="vertical"
                name="update_shipping_country"
                ref={formRef}
                initialValues={{
                    shippingCountry: existingShippingCountryId,
                }}
            >
                <Form.Item name="shipping_country_id" label="Shipping Country">
                    <Select
                        onChange={(value) => {
                            form.setFieldValue("shipping_country_id", value);
                        }}
                        loading={shippingCountryOptionsData.isLoading}
                        placeholder="Please select a shipping country"
                        options={shippingCountryOptionsData.options}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={updateShippingCountryLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
