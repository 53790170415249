import { CloseOutlined } from "@ant-design/icons";
import { Avatar, Button, Form, FormInstance, Image } from "antd";
import { useEffect, useState } from "react";

const ImagesPart = ({
    images,
    form,
}: {
    images: string[];
    form: FormInstance;
}) => {
    const [selectImages, setImages] = useState<string[]>(images);

    const handleRemoveImage = (url: string) => {
        setImages((prevImages) =>
            prevImages.filter((imageUrl) => imageUrl !== url),
        );
    };
    useEffect(() => {
        form.setFieldValue("previous_images", selectImages);
    }, [selectImages]);
    return (
        <Form.Item name={"previous_images"}>
            {selectImages.map((url: string, index: number) => (
                <div
                    key={index}
                    style={{
                        position: "relative",
                        display: "inline-block",
                        marginRight: 10,
                    }}
                >
                    <Avatar
                        style={{
                            marginBottom: 10,
                            objectFit: "contain",
                        }}
                        size={70}
                        shape="square"
                        src={<Image src={url} />}
                    />
                    <Button
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            backgroundColor: "rgb(0 0 0 / 10%)",
                            fontWeight: "bold",
                        }}
                        type="text"
                        danger
                        icon={<CloseOutlined />}
                        onClick={() => handleRemoveImage(url)}
                    ></Button>
                </div>
            ))}
        </Form.Item>
    );
};

export default ImagesPart;
