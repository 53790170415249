// Enum for admin permissions in the Core module
export enum ADMIN_CORE_PERMISSION_ENUM {
    // Country permissions
    ADMIN_COUNTRY_VIEW = "admin-country-view",
    ADMIN_COUNTRY_MANAGE = "admin-country-manage",

    // Language permissions
    ADMIN_LANGUAGE_VIEW = "admin-language-view",
    ADMIN_LANGUAGE_MANAGE = "admin-language-manage",

    // Region permissions
    ADMIN_REGION_VIEW = "admin-region-view",
    ADMIN_REGION_MANAGE = "admin-region-manage",

    // Currency permissions
    ADMIN_CURRENCY_VIEW = "admin-currency-view",
    ADMIN_CURRENCY_MANAGE = "admin-currency-manage",

    //OrderHandler Assign
    ASSIGN_ORDER_HANDLER = "assign-order-handler",
}

export const currencyManageRoutePermissions = [
    ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_VIEW,
    ADMIN_CORE_PERMISSION_ENUM.ADMIN_CURRENCY_MANAGE,
];
export const countryManageRoutePermissions = [
    ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_VIEW,
    ADMIN_CORE_PERMISSION_ENUM.ADMIN_COUNTRY_MANAGE,
];
export const languageManageRoutePermissions = [
    ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_VIEW,
    ADMIN_CORE_PERMISSION_ENUM.ADMIN_LANGUAGE_MANAGE,
];
export const regionManageRoutePermissions = [
    ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_VIEW,
    ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_MANAGE,
];
