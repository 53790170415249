import { Collapse } from "antd";
import { CollapseProps } from "antd/lib";

import NotAvailableTracking from "./NotAvailableTracking";
import { AddTrackingForm } from "./AddTrackingForm";
import { UpdateTrackingForm } from "./UpdateTrackingForm";
import { ShipmentProductPackageModel } from "@/models/shipmentProductPackageCollection";

interface Props {
    packages: ShipmentProductPackageModel[] | undefined;
    productId: number;
    onCloseModal: () => void;
    action: "view" | "add";
}
const Trackings = ({ packages, productId, onCloseModal, action }: Props) => {
    if (!packages) {
        return <></>;
    }

    const items: CollapseProps["items"] = packages.map((item, index) => {
        if (item.getTrackings().length > 0) {
            return {
                key: `${index}`,
                label: `Package No: ${item.getPackageNumber()} ${item.getCartonNumber() ? "| Carton Number:" + item.getCartonNumber() : ""}`,
                children: (
                    <>
                        {item.getTrackings().map((tracking) => {
                            return (
                                <UpdateTrackingForm
                                    key={tracking.id}
                                    action={action}
                                    onCloseModal={onCloseModal}
                                    productId={productId}
                                    trackingId={tracking.id}
                                    tracking={tracking}
                                />
                            );
                        })}
                    </>
                ),
            };
        } else {
            return {
                key: `${index}`,
                label: `Package No: ${item.getPackageNumber()} ${item.getCartonNumber() ? "| Carton Number:" + item.getCartonNumber() : ""}`,
                children: (
                    <>
                        <AddTrackingForm
                            action={action}
                            onCloseModal={onCloseModal}
                            productId={productId}
                            packageId={item.getId()}
                        />
                    </>
                ),
            };
        }
    });

    return (
        <>
            {packages.length > 0 ? (
                <Collapse
                    items={items}
                    defaultActiveKey={["0"]}
                    expandIconPosition="start"
                />
            ) : (
                <NotAvailableTracking text={"Package"} />
            )}
        </>
    );
};

export default Trackings;
