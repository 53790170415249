import { PageHeader } from "@ant-design/pro-layout";
import {
    Avatar,
    Button,
    Col,
    Dropdown,
    Form,
    Image,
    Modal,
    Row,
    Table,
    Tag,
    Typography,
} from "antd";
import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { paymentGatewayCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { MenuInfo } from "rc-menu/lib/interface";
import { PaginationModel } from "@/models/pagination";
import {
    useCreatePaymentGateway,
    useGetPaymentGateway,
    useUpdateUpdatePaymentGateway,
} from "@/lib/core-react/hooks/private/usePayment";
import {
    PaymentGatewayCollectionModel,
    PaymentGatewayModel,
} from "@/models/paymentGatewayCollectionModel";
import {
    DownOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import { IFilterType } from "@/types/filters";
import FiltersComponent from "@/components/FiltersComponent";
import { Logo } from "@/types/paymentGatewayCollection";
import { PaymentGatewayLogoTypeEnums } from "@/enums/paymentGatewayCollectionEnums";
import { PaymentGatewayModification } from "./components";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { StatusTag } from "../../../components";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { PAYMENT_GATEWAY_PERMISSION_ENUM } from "@/consts/permission-enum/payment-enum";
import CommonError from "@/components/Error/CommonError";
import { showError } from "@/helpers/showError";
import { IPaymentGatewayFormData } from "./../../../types/paymentGatewayCollection";

const PaymentGateway = () => {
    const { getPaymentGateway } = useGetPaymentGateway();
    const [
        {
            data: paymentGatewayCollectionData,
            isLoading,
            refetch,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(paymentGatewayCollectionAtom);
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest({}, getPaymentGateway);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);
    const { isMobile } = useWindowWidth();

    const PaymentGatewayCollectionData =
        paymentGatewayCollectionData &&
        new PaymentGatewayCollectionModel(paymentGatewayCollectionData);
    // Filter
    const filterData = PaymentGatewayCollectionData?.getFilters();
    // Pagination
    const paginationData = PaymentGatewayCollectionData?.getPagination();

    const [selectedPaymentGateway, setSelectedPaymentGateway] = useState<
        PaymentGatewayModel | undefined
    >(undefined);
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

    const handleMenuClick = (e: MenuInfo, record: PaymentGatewayModel) => {
        setSelectedPaymentGateway(record);

        if (e.key === "update") {
            setIsShowCreateModal(true);
        }
    };

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && PaymentGatewayCollectionData?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        PaymentGatewayCollectionData?.filters,
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getPaymentGateway,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        PaymentGatewayCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getPaymentGateway);
    };

    const { createPaymentGateway, isLoading: isLoadingCreate } =
        useCreatePaymentGateway();
    const { updatePaymentGateway, isLoading: isLoadingUpdate } =
        useUpdateUpdatePaymentGateway();

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        try {
            const logos: Logo[] = [];

            if (values.small_logo) {
                logos.push({
                    image_src: values.small_logo,
                    type: PaymentGatewayLogoTypeEnums.SMALL,
                });
            }

            if (values.medium_logo) {
                logos.push({
                    image_src: values.medium_logo,
                    type: PaymentGatewayLogoTypeEnums.MEDIUM,
                });
            }
            const payload: IPaymentGatewayFormData = {
                name: values.name,
                identifier: values.identifier,
                type: values.type,
                fee_type: values.fee_type,
                fee: values.fee.toFixed(2),
                status: values.status,
                logos,
                currencies: values.currencies,
                merchant_data:
                    values.merchant_data?.length > 0
                        ? values.merchant_data.reduce(
                              (acc, { key, value }) => {
                                  acc[key] = value;
                                  return acc;
                              },
                              {} as Record<string, string | number>,
                          )
                        : null,
            };

            if (selectedPaymentGateway) {
                await updatePaymentGateway(
                    selectedPaymentGateway.getId(),
                    payload,
                );
                setSelectedPaymentGateway(undefined);
            } else {
                await createPaymentGateway(payload);
            }
            setIsShowCreateModal(false);
        } catch (error) {
            showError(error, form);
        }
    };
    // ------------------------------ui------------------------

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }
    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_: string, __: PaymentGatewayModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Gateway info",
            dataIndex: "name",
            hidden: !isMobile,
            width: 300,
            key: "name",
            render: (_: string, record: PaymentGatewayModel) => {
                const logoCollection = record.getLogo();
                const logoUrl = logoCollection
                    ?.getData()
                    .map((logo) => logo.getSmall()?.src)
                    .find((url) => url);
                return (
                    <div style={{ display: "flex", gap: "16px" }}>
                        <Avatar
                            shape="circle"
                            size={70}
                            style={{ objectFit: "contain" }}
                            src={<Image src={logoUrl} />}
                        />
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <span style={{ fontWeight: "bold" }}>
                                    Name:{" "}
                                </span>

                                <Typography.Text strong>
                                    {record.getName()}
                                </Typography.Text>
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>
                                    Identifier:{" "}
                                </span>

                                <Tag color="green">
                                    {record
                                        .getIdentifier()
                                        .replaceAll("-", " ")}
                                </Tag>
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>
                                    Type:{" "}
                                </span>

                                <Tag color="green">
                                    {record.getType().replaceAll("-", " ")}
                                </Tag>
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>
                                    Fee:{" "}
                                </span>
                                {record.getFee() && record.getFeeType() ? (
                                    <Typography.Text>
                                        <Tag color="#54C21A">{`${record.getFee()} ${record.getFeeType() === "percentage" ? "%" : ""}`}</Tag>
                                    </Typography.Text>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>
                                    Status:{" "}
                                </span>

                                {record.getStatus() && (
                                    <StatusTag
                                        slug={record.getStatus()}
                                        text={record.getStatus()}
                                    />
                                )}
                            </div>
                            <div>
                                {record.getCurrencies() &&
                                    record.getCurrencies().getData() &&
                                    record
                                        .getCurrencies()
                                        .getData()
                                        .map((currency, index, array) => (
                                            <span key={currency.getId()}>
                                                {currency.getName()}{" "}
                                                {currency.getSymbol()
                                                    ? `(${currency.getSymbol()})`
                                                    : ""}
                                                {index < array.length - 1 &&
                                                    ", "}
                                            </span>
                                        ))}
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Gateway info",
            dataIndex: "gateway_info",
            key: "gateway_info",
            hidden: isMobile,
            render: (_: string, record: PaymentGatewayModel) => {
                const logoCollection = record.getLogo();
                const logoUrl = logoCollection
                    ?.getData()
                    .map((logo) => logo.getSmall()?.src)
                    .find((url) => url);

                return (
                    <div style={{ display: "flex", gap: "16px", width: 300 }}>
                        <Avatar
                            shape="circle"
                            size={70}
                            style={{ objectFit: "contain" }}
                            src={<Image src={logoUrl} />}
                        />
                        <div
                            style={{
                                display: "flex",
                                gap: "10px",
                                flexDirection: "column",
                            }}
                        >
                            <div>
                                <span style={{ fontWeight: "bold" }}>
                                    Name:{" "}
                                </span>

                                <Typography.Text strong>
                                    {record.getName()}
                                </Typography.Text>
                            </div>
                            <div>
                                <span style={{ fontWeight: "bold" }}>
                                    Identifier:{" "}
                                </span>

                                <Tag color="green">
                                    {record
                                        .getIdentifier()
                                        .replaceAll("-", " ")}
                                </Tag>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Merchant info",
            dataIndex: "merchant_data",
            key: "merchant_data",
            width: 300,
            render: (_: string, record: PaymentGatewayModel) => {
                const merchantData = record.getMerchantData();

                return (
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                            flexDirection: "column",
                        }}
                    >
                        {/* Render Merchant Data or N/A */}
                        {Object.keys(merchantData).length > 0 ? (
                            Object.entries(merchantData).map(
                                ([key, value], index) => (
                                    <div key={`${key}-${index}`}>
                                        <span style={{ fontWeight: "bold" }}>
                                            {key}:{" "}
                                        </span>
                                        <Typography.Text>
                                            {value}
                                        </Typography.Text>
                                    </div>
                                ),
                            )
                        ) : (
                            <Typography.Text>N/A</Typography.Text>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            hidden: isMobile,
            render: (_: string, record: PaymentGatewayModel) => {
                return (
                    <div>
                        <Tag color="green">
                            {record.getType().replaceAll("-", " ")}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Free",
            dataIndex: "fee",
            key: "fee",
            hidden: isMobile,
            render: (_: string, record: PaymentGatewayModel) => {
                return (
                    <div>
                        {record.getFee() && record.getFeeType() ? (
                            <Typography.Text>
                                <Tag color="#54C21A">{`${record.getFee()} ${record.getFeeType() === "percentage" ? "%" : ""}`}</Tag>
                            </Typography.Text>
                        ) : (
                            ""
                        )}
                    </div>
                );
            },
        },

        {
            title: "Currencies",
            dataIndex: "currencies",
            key: "currencies",
            hidden: isMobile,
            render: (_: string, record: PaymentGatewayModel) => {
                return (
                    <div>
                        {record.getCurrencies() &&
                            record.getCurrencies().getData() &&
                            record
                                .getCurrencies()
                                .getData()
                                .map((currency, index, array) => (
                                    <span key={currency.getId()}>
                                        {currency.getName()}{" "}
                                        {currency.getSymbol()
                                            ? `(${currency.getSymbol()})`
                                            : ""}
                                        {index < array.length - 1 && ", "}
                                    </span>
                                ))}
                    </div>
                );
            },
        },

        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            hidden: isMobile,
            render: (_: string, record: PaymentGatewayModel) => {
                return (
                    <div>
                        {record.getStatus() && (
                            <StatusTag
                                slug={record.getStatus()}
                                text={record.getStatus()}
                            />
                        )}
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            width: 100,
            render: (_: string, record: PaymentGatewayModel) => {
                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_MANAGE,
                        label: "Update",
                        key: "update",
                        icon: <EditOutlined />,
                        onClick: (e) => handleMenuClick(e, record),
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Payment Gateway"
                    style={{ marginTop: "10px" }}
                    extra={[
                        checkActionPermission(
                            PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_MANAGE,
                            <Button
                                key="1"
                                type="primary"
                                onClick={() => {
                                    setSelectedPaymentGateway(undefined);
                                    setIsShowCreateModal(true);
                                }}
                                icon={<PlusOutlined />}
                            >
                                Create a Payment Gateway
                            </Button>,
                            null,
                        ),
                    ]}
                    onBack={() => window.history.back()}
                >
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <FiltersComponent
                                    handleProductFilter={handleProductFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={filterData}
                                    isFromProductReceived={true}
                                />
                            </Col>
                        </Row>
                    )}
                    <div>
                        <Table
                            style={{ marginTop: 10 }}
                            loading={isLoading}
                            rowKey="id"
                            bordered={true}
                            dataSource={PaymentGatewayCollectionData?.data}
                            //@ts-ignore
                            columns={columns}
                            pagination={{
                                ...paginationConfig,
                                showTotal: (total) => (
                                    <div>
                                        Total{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                color: "green",
                                            }}
                                        >
                                            {total}
                                        </span>{" "}
                                        Items
                                    </div>
                                ),
                            }}
                            scroll={{ x: 600 }}
                        />
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={`${selectedPaymentGateway ? "Update" : "Create"} Payment Gateway`}
                open={isShowCreateModal}
                onCancel={() => {
                    setIsShowCreateModal(false);
                    setSelectedPaymentGateway(undefined);
                }}
                onOk={form.submit}
                okText={selectedPaymentGateway ? "Update" : "Create"}
                okButtonProps={{ loading: isLoadingCreate || isLoadingUpdate }}
                centered
                destroyOnClose={true}
            >
                <PaymentGatewayModification
                    data={selectedPaymentGateway}
                    form={form}
                    onFinish={onFinish}
                />
            </Modal>
        </>
    );
};

export default PaymentGateway;
