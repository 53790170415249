import {
    IDeliveryEnrollmentCollection,
    IDeliveryRequest,
    IDeliveryRequestCollection,
    IDeliveryRequestCourierOption,
    IDeliveryRequestUser,
    IShipmentProduct,
    IShippingAddressReference,
} from "@/types/deliveryRequestCollection";
import { IFilterType } from "@/types/filters";
import { DestinationWarehouseModel } from "./destinationWarehouseCollectionModel";
import {
    BuyOrderModel,
    BuyProductModel,
    CustomerInvoiceModel,
} from "./buyProductCollectionModel";
import { ShipmentProductPackageCollectionModel } from "./shipmentProductPackageCollection";
import {
    ShippingModeEnum,
    ShippingTypeEnum,
} from "@/enums/shipForMeContextEnums";
import { IShippingCategory } from "@/types/shippingCategory";
import { IShippingCountry } from "@/types/shippingCountryCollection";
import { ShipmentProductImageCollectionModel } from "./shipmentProductImageCollectionModel";
import { CurrencyModel } from "./currencyCollectionModel";
import { CountryModel } from "./countryCollectionModel";
import { PaginationModel } from "./paginationModel";
import { IRegion } from "@/types/regionCollection";

export class DeliveryRequestUserModel {
    public readonly object: string;
    public readonly id: number;
    public readonly name: string;
    public readonly email: string;

    constructor(data: IDeliveryRequestUser) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.email = data.email;
    }

    getName() {
        return this.name;
    }

    getEmail() {
        return this.email;
    }
}

export class DeliveryRequestCourierOptionModel {
    public readonly object: string;
    public readonly id: number;
    public readonly provider_id: string;
    public readonly price_type: string;
    public readonly name: string;
    public readonly availability_status: string;
    public readonly created_at: string;
    public readonly flat_amount: number;
    public readonly note: string;

    constructor(data: IDeliveryRequestCourierOption) {
        this.object = data.object;
        this.id = data.id;
        this.provider_id = data.provider_id;
        this.price_type = data.price_type;
        this.name = data.name;
        this.availability_status = data.availability_status;
        this.created_at = data.created_at;
        this.flat_amount = data.flat_amount;
        this.note = data.note;
    }

    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getProviderId(): string {
        return this.provider_id;
    }

    getPriceType(): string {
        return this.price_type;
    }

    getName(): string {
        return this.name;
    }

    getAvailabilityStatus(): string {
        return this.availability_status;
    }

    getCreatedAt(): string {
        return this.created_at;
    }

    getFlatAmount(): number {
        return this.flat_amount;
    }
}

export class ShipmentProductModel {
    public readonly object: string;
    public readonly id: number;
    public readonly product_number: string;
    public readonly images: ShipmentProductImageCollectionModel | null;
    public readonly link: string;
    public readonly title: string;
    public readonly reject_reason?: string | null;
    public readonly shipment_instruction?: string | null;
    public readonly currency?: CurrencyModel;
    public readonly shipping_country: IShippingCountry;
    public readonly destination_country: CountryModel;
    public readonly destination_warehouse: DestinationWarehouseModel;
    public readonly region: IRegion | null;
    public readonly shipping_category: IShippingCategory;
    public readonly shipping_mode: ShippingModeEnum;
    public readonly shipping_type: ShippingTypeEnum;
    public readonly status: string;
    public readonly status_label: string;
    public readonly packages: ShipmentProductPackageCollectionModel;
    public readonly customer_invoice?: CustomerInvoiceModel;
    public readonly order: BuyOrderModel;
    public readonly buy_product?: BuyProductModel;
    public readonly created_at: string;

    constructor(data: IShipmentProduct) {
        this.object = data.object;
        this.id = data.id;
        this.product_number = data.product_number;
        this.images = data.images
            ? new ShipmentProductImageCollectionModel(data.images)
            : null;
        this.link = data.link;
        this.title = data.title;
        this.reject_reason = data.reject_reason;
        this.shipment_instruction = data.shipment_instruction;
        this.currency = data.currency && new CurrencyModel(data.currency);
        this.shipping_country = data.shipping_country;
        this.destination_country =
            data.destination_country &&
            new CountryModel(data.destination_country);
        this.destination_warehouse =
            data.destination_warehouse &&
            new DestinationWarehouseModel(data.destination_warehouse);
        this.region = data.region;
        this.shipping_category = data.shipping_category;
        this.shipping_mode = data.shipping_mode;
        this.shipping_type = data.shipping_type;
        this.status = data.status;
        this.status_label = data.status_label;
        this.packages = new ShipmentProductPackageCollectionModel(
            data.packages,
        );
        this.customer_invoice = data.customer_invoice
            ? new CustomerInvoiceModel(data.customer_invoice)
            : undefined;
        this.order = new BuyOrderModel(data.order);
        this.buy_product =
            data.buy_product && new BuyProductModel(data.buy_product);
        this.created_at = data.created_at;
    }

    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getProductNumber(): string {
        return this.product_number;
    }

    getImages(): ShipmentProductImageCollectionModel | null {
        return this.images;
    }

    getLink(): string {
        return this.link;
    }

    getTitle(): string {
        return this.title;
    }
    getRejectReason() {
        return this.reject_reason;
    }

    getCurrency() {
        return this.currency;
    }

    getShippingCountry(): IShippingCountry {
        return this.shipping_country;
    }

    getDestinationCountry() {
        return this.destination_country;
    }

    getDestinationWarehouse() {
        return this.destination_warehouse;
    }

    getRegion(): IRegion | null {
        return this.region;
    }

    getShippingCategory(): IShippingCategory {
        return this.shipping_category;
    }

    getShippingMode(): ShippingModeEnum {
        return this.shipping_mode;
    }

    getShippingType(): ShippingTypeEnum {
        return this.shipping_type;
    }

    getStatus(): string {
        return this.status;
    }

    getStatusLabel(): string {
        return this.status_label;
    }

    getPackages(): ShipmentProductPackageCollectionModel {
        return this.packages;
    }

    getCustomerInvoice(): CustomerInvoiceModel | undefined {
        return this.customer_invoice;
    }

    getOrder(): BuyOrderModel {
        return this.order;
    }

    getBuyProduct() {
        return this.buy_product;
    }

    getShippingInstruction() {
        return this.shipment_instruction;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class DeliveryEnrollmentCollectionModel {
    public readonly object: string;
    public readonly data: ShipmentProductModel[];

    constructor(data: IDeliveryEnrollmentCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ShipmentProductModel(item));
    }

    getData() {
        return this.data;
    }
}

export class DeliveryRequestModel {
    public readonly object: string;
    public readonly id: number;
    public readonly request_number: string;
    public readonly type: string;
    public readonly status: string;
    public readonly note: string | null;
    public readonly courier_note: string | null;
    public readonly courier_price: number;
    public readonly total_weight: number;
    public readonly total_quantity: number;
    public readonly total_height: number;
    public readonly total_width: number;
    public readonly total_length: number;
    public readonly created_at: string;
    public readonly region: IRegion | null;
    public readonly destination_warehouse: DestinationWarehouseModel;
    public readonly shipping_address: IFilterType | null;
    public readonly shipping_address_reference: IShippingAddressReference | null;
    public readonly user: DeliveryRequestUserModel;
    public readonly courier_option: DeliveryRequestCourierOptionModel;
    public readonly enrollments: DeliveryEnrollmentCollectionModel;

    constructor(data: IDeliveryRequest) {
        this.object = data.object;
        this.id = data.id;
        this.request_number = data.request_number;
        this.type = data.type;
        this.status = data.status;
        this.note = data.note;
        this.courier_note = data.courier_note;
        this.courier_price = data.courier_price;
        this.total_weight = data.total_weight;
        this.total_quantity = data.total_quantity;
        this.total_height = data.total_height;
        this.total_width = data.total_width;
        this.total_length = data.total_length;
        this.created_at = data.created_at;
        this.region = data.region;
        this.destination_warehouse =
            data.destination_warehouse &&
            new DestinationWarehouseModel(data.destination_warehouse);
        this.shipping_address = data.shipping_address;
        this.shipping_address_reference = data.shipping_address_reference;
        this.user = new DeliveryRequestUserModel(data.user);
        this.courier_option = new DeliveryRequestCourierOptionModel(
            data.courier_option,
        );
        this.enrollments =
            data.enrollments &&
            new DeliveryEnrollmentCollectionModel(data.enrollments);
    }

    getId(): number {
        return this.id;
    }

    getRequestNumber(): string {
        return this.request_number;
    }

    getType(): string {
        return this.type;
    }

    getStatus(): string {
        return this.status;
    }

    getNote(): string | null {
        return this.note;
    }

    getCourierNote(): string | null {
        return this.courier_note;
    }

    getCourierPrice(): number {
        return this.courier_price;
    }

    getTotalWeight(): number {
        return this.total_weight;
    }

    getTotalQuantity(): number {
        return this.total_quantity;
    }

    getTotalHeight(): number {
        return this.total_height;
    }

    getTotalWidth(): number {
        return this.total_width;
    }

    getTotalLength(): number {
        return this.total_length;
    }

    getCreatedAt(): string {
        return this.created_at;
    }

    getShippingAddress(): IFilterType | null {
        return this.shipping_address;
    }

    getEnrollments() {
        return this.enrollments;
    }
}

export class DeliveryRequestCollectionModel {
    public readonly object: string;
    public readonly data: DeliveryRequestModel[];
    public readonly pagination: PaginationModel;
    public readonly filters: IFilterType;

    constructor(data: IDeliveryRequestCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new DeliveryRequestModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getObject(): string {
        return this.object;
    }

    getData() {
        return this.data;
    }

    getPagination() {
        return this.pagination;
    }

    getFilters() {
        return this.filters;
    }
}
