import {
    ServiceBaseUrl,
    ServiceNameEnum,
} from "@/lib/core/apiServices/servicBaseUrl";
import { SettingsFlow } from "@ory/client";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

const basePath = ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.AUTH_SERVICE);

const oryAxios = axios.create({
    baseURL: basePath,
    withCredentials: true,
});

export const useGetAuthSettingsBrowser = () => {
    const [data, setData] = useState<SettingsFlow | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const path = "/self-service/settings/browser";

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await oryAxios.get(path);
            setData(response.data);
        } catch (err: any) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, isLoading, error, refetch: fetchData };
};

export const usePostAuthSettingsBrowser = () => {
    const updateSettings = async (
        query: string,
        payload: Record<string, any>,
    ) => {
        const path = `/self-service/settings?flow=${query}`;
        // const response = await authService.auth.updateSettings(query, payload);
        const response = await oryAxios.post(path, payload);
        return response;
    };

    return { updateSettings };
};
