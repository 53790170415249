import { SettingsFlow, UpdateSettingsFlowBody } from "@ory/client";
import { ReactNode, useEffect, useState } from "react";
import { AxiosError } from "axios";

import { handleFlowError } from "../pkg/errors";
import { Flow, Messages, Methods, Values } from "../pkg";
import ory from "../pkg/sdk";
import {
    Link,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import { Button, Col, Row, Typography, Result } from "antd";
import logoImg from "/logo.svg";
interface Props {
    flow?: SettingsFlow;
    only?: Methods;
}

function SettingsCard({
    flow,
    only,
    children,
}: Props & { children: ReactNode }) {
    if (!flow) {
        return null;
    }

    const nodes = only
        ? flow.ui.nodes.filter(({ group }) => group === only)
        : flow.ui.nodes;

    if (nodes.length === 0) {
        return null;
    }

    return <div>{children}</div>;
}

const Settings = () => {
    const [flow, setFlow] = useState<SettingsFlow>();

    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const location = useLocation();

    const returnTo = searchParams.get("return_to");
    const flowId = searchParams.get("flow");

    useEffect(() => {
        if (!location || flow) {
            return;
        }

        if (flowId) {
            ory.getSettingsFlow({ id: String(flowId) })
                .then(({ data }) => {
                    setFlow(data);
                })
                .catch(handleFlowError(navigate, "settings", setFlow));
            return;
        }

        ory.createBrowserSettingsFlow({
            returnTo: String(returnTo || ""),
        })
            .then(({ data }) => {
                setFlow(data);
            })
            .catch(handleFlowError(navigate, "settings", setFlow));
    }, [flowId, navigate, location, returnTo, flow]);

    const onSubmit = async (values: Partial<Values>): Promise<void> => {
        navigate(`/auth/settings?flow=${flow?.id}`, { replace: true });

        ory.updateSettingsFlow({
            flow: String(flow?.id),
            updateSettingsFlowBody: values as UpdateSettingsFlowBody,
        })
            .then(({ data }) => {
                setFlow(data);
                if (data.continue_with) {
                    for (const item of data.continue_with) {
                        switch (item.action) {
                            case "show_verification_ui":
                                navigate(
                                    `/auth/verification?flow=${item.flow.id}`,
                                    { replace: true },
                                );
                                return;
                        }
                    }
                }

                if (data.return_to) {
                    window.location.href = data.return_to;
                    return;
                }
            })
            .catch(handleFlowError(navigate, "settings", setFlow))
            .catch(async (err: AxiosError) => {
                if (err.response && err.response.status === 400) {
                    setFlow(err.response.data as SettingsFlow);
                    return;
                }

                return Promise.reject(err);
            });
    };

    return (
        <>
            <Row
                justify="space-around"
                align="middle"
                style={{ background: "#fff", height: "100vh" }}
            >
                <Col xs={24} md={6} sm={8}>
                    <div
                        style={{
                            textAlign: "center",
                            padding: "30px",
                            marginTop: "50px",
                            marginBottom: "20px",
                        }}
                    >
                        <Link to={"/auth-admin/login"}>
                            <img src={logoImg} alt="logo" />
                        </Link>
                    </div>
                    <SettingsCard only="password" flow={flow}>
                        {flow?.state && flow?.state === "success" ? (
                            <>
                                <Result
                                    status="success"
                                    title="Congratulations!"
                                    subTitle="Your password has been successfully reset. You can now securely access your account using your
                      new password"
                                    extra={[
                                        <Button key={"1"} type={"link"}>
                                            <Link to="/" data-testid="cta-link">
                                                Back to home
                                            </Link>
                                        </Button>,
                                    ]}
                                />
                            </>
                        ) : (
                            <>
                                <div>
                                    <Typography.Title
                                        level={2}
                                        style={{
                                            margin: 0,
                                            textAlign: "center",
                                        }}
                                    >
                                        Reset Your Password
                                    </Typography.Title>
                                    <br />
                                    <Typography.Text
                                        style={{
                                            margin: 0,
                                            textAlign: "center",
                                        }}
                                    >
                                        Please choose a new password
                                    </Typography.Text>
                                </div>
                                <br />
                                <Messages messages={flow?.ui.messages} />
                                <Flow
                                    hideGlobalMessages
                                    onSubmit={onSubmit}
                                    only="password"
                                    flow={flow}
                                />
                            </>
                        )}
                    </SettingsCard>
                </Col>
            </Row>
        </>
    );
};

export default Settings;
