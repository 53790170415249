import { Form, Input, Radio, Select } from "antd";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import {
    LanguageCode,
    LanguageDataStatus,
} from "@/enums/languageCollectionEnums";

import { LanguageDataModel } from "@/models/language";
import { useEffect } from "react";
interface IProps {
    data?: LanguageDataModel;
    form: any;
    onFinish: (values: any) => Promise<void>;
    setRTLData: React.Dispatch<React.SetStateAction<boolean>>;
    rtlData: boolean;
}
const UpdateLanguage = ({
    data,
    form,
    onFinish,
    rtlData,
    setRTLData,
}: IProps) => {
    useEffect(() => {
        form.resetFields();
    }, [data, form]);

    const isRTLData = [true, false].map((item, index) => (
        <Radio key={index} value={String(item)}>
            {String(item)[0].toUpperCase() + String(item).slice(1)}
        </Radio>
    ));

    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Add Language"
                    initialValues={{
                        name: data?.name,
                        code: data?.code,
                        status: data?.status,
                        is_rtl: data?.is_rtl ? true : false,
                    }}
                >
                    <Form.Item
                        label="Code"
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: "Language code is required",
                            },
                        ]}
                    >
                        <Select
                            disabled={!!data}
                            placeholder="Please select a language code"
                        >
                            {renderOptionsFromEnum(LanguageCode)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Language name is required",
                            },
                        ]}
                        label="Name"
                        name="name"
                    >
                        <Input placeholder="Language name" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            { required: true, message: "Status is required" },
                        ]}
                        name="status"
                        label="Status"
                    >
                        <Select placeholder="Please select a status">
                            {" "}
                            {renderOptionsFromEnum(LanguageDataStatus)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            { required: true, message: "Is RTL is required" },
                        ]}
                        label="IS RTL?"
                    >
                        <Radio.Group
                            onChange={() => setRTLData((pre) => !pre)}
                            defaultValue={String(rtlData)}
                        >
                            {isRTLData}
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default UpdateLanguage;
