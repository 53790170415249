export const responseTypeQuery = {
    minimal: {
        response_type: "minimal",
    },
};

export const paginationQuery = {
    per_page: 500,
};

export const defaultMinimalData = {
    data: null,
    isLoading: true,
};

export const errorMessageMinimal = "Please response_type=minimal at query";
