import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import {
    ExpiredUnit,
    IImpersonationSession,
    UserType,
} from "../../types/userCollection";
import {
    useCreateImpersonateSession,
    useGetRole,
} from "../../lib/core-react/hooks/private/useRole";
import { UserSelect } from "../CustomFormElement/UserSelect";
import { filterResourceEnum } from "../../filters/enum/filterResourceEnum";
import { responseTypeQuery } from "../../filters/constant";
import { useDebounce } from "../../hooks/useDebounce";
import useFiltersApiData from "../../filters/hooks/useFiltersApiData";
import { downloadImpersonateCredentialsAsTextFile } from "../../utils/helper";
import { useAtom } from "jotai/index";
import {
    impersonateSessionsCreate,
    roleAtom,
} from "../../lib/core-react/store/store";
import { RoleCollectionModel } from "../../models/roleCollectionModel";
import { showError } from "../../helpers/showError";
import useOryAuth from "@/lib/core-react/utils/useOryAuth";
import ImpersonateResultView from "./impersonateResultView";

interface IProps {
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    selectedUser: {
        id: number;
    };
    userType: UserType;
    disableInputOptions?: boolean;
}

const ImpersonateForm = ({
    selectedUser,
    userType,
    disableInputOptions,
}: IProps) => {
    const formRef = useRef<FormInstance<any>>(null);
    const [form] = Form.useForm();
    const { createImpersonate, isLoading, response } =
        useCreateImpersonateSession();
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [isCreated, setIsCreated] = useState(false);
    const { getRole } = useGetRole();
    const {
        allOptionsData: { adminOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const [{ data: roleCollectionData, isLoading: isLoadingRole }] =
        useAtom(roleAtom);
    const [roleIds, setRoleIds] = useState<number[]>([]);

    const { getUserEmail } = useOryAuth();

    const email = getUserEmail();

    useEffect(() => {
        if (userType === "customer") {
            getRole(`is_customer=1&response_type=minimal`);
        }
    }, []);

    const [, setSessionData] = useAtom(impersonateSessionsCreate);
    const RoleCollectionData =
        roleCollectionData && new RoleCollectionModel(roleCollectionData);

    useEffect(() => {
        // Reset fields and state
        form.resetFields();
        setSessionData({
            data: undefined,
            isLoading: false,
            error: null,
        });
        setIsCreated(false);

        const initialValues: Partial<IImpersonationSession> = {
            user_type: userType,
            expired_unit: ExpiredUnit.Hour,
            expired_value: 1,
            intent: "",
        };

        if (userType === "customer") {
            const customerRole = RoleCollectionData?.data.find(
                (role) =>
                    role.name.toLowerCase() === "customer-role" ||
                    role.name.toLowerCase() === "customer role",
            );
            if (customerRole) {
                setRoleIds([customerRole.getId()]);
            }
        }

        form.setFieldsValue(initialValues);
    }, [userType, form, roleCollectionData]);

    const onFinish = async (values: IImpersonationSession) => {
        try {
            let payload: IImpersonationSession = {
                user_type: values.user_type || UserType.Customer,
                impersonatee_id: selectedUser.id,
                impersonator_id:
                    values.impersonator_id ?? adminOptionsData.options[0].value,
                expired_unit: values.expired_unit || ExpiredUnit.Day,
                expired_value: values.expired_value || 2,
                intent: values.intent || "",
            };
            if (userType === "customer") {
                payload = {
                    ...payload,
                    role_ids: values.role_ids || roleIds,
                };
            }
            const response = await createImpersonate(payload);
            if (response) {
                downloadImpersonateCredentialsAsTextFile(response);
            }
            setIsCreated(true);
        } catch (error: any) {
            showError(error, form);
        }
    };

    useEffect(() => {
        if (debouncedSearchTerm && !disableInputOptions) {
            onFetchFilterApi(filterResourceEnum.ADMIN, {
                keyword: debouncedSearchTerm,
                per_page: 500,
                ...responseTypeQuery.minimal,
            });
        } else if (email && disableInputOptions) {
            onFetchFilterApi(filterResourceEnum.ADMIN, {
                keyword: email,
                ...responseTypeQuery.minimal,
            });
        } else if (!disableInputOptions) {
            onFetchFilterApi(filterResourceEnum.ADMIN, {
                keyword: "",
                per_page: 500,
                ...responseTypeQuery.minimal,
            });
        }
    }, [debouncedSearchTerm, email]);

    const handleSearch = (q) => {
        setSearchTerm(q);
    };

    return (
        <>
            {isCreated && response ? (
                <ImpersonateResultView response={response} />
            ) : (
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Impersonation Session"
                    ref={formRef}
                    initialValues={{
                        user_type: userType,
                        expired_unit: ExpiredUnit.Hour,
                        intent: "",
                        expired_value: 1,
                    }}
                >
                    {!disableInputOptions && (
                        <>
                            <Form.Item
                                label="Impersonator user"
                                name="impersonator_id"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select the user!",
                                    },
                                ]}
                            >
                                <UserSelect
                                    initialData={
                                        adminOptionsData.options as any[]
                                    }
                                    loading={adminOptionsData.isLoading}
                                    resource={""}
                                    onSearch={handleSearch}
                                    allowClear
                                    showSearch
                                    placeholder="Search users by email or name"
                                    onChange={(value) =>
                                        form.setFieldValue(
                                            "impersonator_id",
                                            value,
                                        )
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="user_type"
                                label="User Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select a user type",
                                    },
                                ]}
                            >
                                <Input
                                    style={{ opacity: ".5" }}
                                    disabled={true}
                                    value={userType}
                                />
                            </Form.Item>

                            <Form.Item
                                name="expired_unit"
                                label="Expiration Unit"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please select an expiration unit",
                                    },
                                ]}
                            >
                                <Select placeholder="Select an expiration unit">
                                    {Object.entries(ExpiredUnit).map(
                                        ([key, value]) => (
                                            <Select.Option
                                                key={key}
                                                value={value}
                                            >
                                                {value.toUpperCase()}
                                            </Select.Option>
                                        ),
                                    )}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="expired_value"
                                label="Expiration Value"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please enter an expiration value",
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    placeholder="Enter expiration value"
                                />
                            </Form.Item>

                            {userType === "customer" &&
                                !disableInputOptions && (
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Role is required",
                                            },
                                        ]}
                                        name="role_ids"
                                        label="Roles"
                                    >
                                        <Select
                                            loading={isLoadingRole}
                                            mode="multiple"
                                            placeholder="Please select roles"
                                            options={RoleCollectionData?.getRolesLabeledValue()}
                                        />
                                    </Form.Item>
                                )}

                            <Form.Item name="intent" label="Note">
                                <Input placeholder="Note" />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item>
                        <Button
                            loading={isLoading}
                            disabled={isLoading}
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                        >
                            Create Session
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </>
    );
};

export default ImpersonateForm;
