import { ITranslationEntry } from "../lang";

export const KA: ITranslationEntry = {
    translations: {
        notifications: "ნოტიფიკაციები",
        markAllAsRead: "მონიშნე ყველა როგორც წაკითხული",
        poweredBy: "იკვებება ბყ",
        settings: "პარამეტრები",
        removeMessage: "შეტყობინების წაშლა",
        markAsRead: "წაკითხულად მონიშვნა",
        markAsUnRead: "წაუკითხავად მონიშვნა",
        noNewNotification: "აქ სანახავი ჯერ არაფერია ახალი",
    },
    lang: "ka",
};
