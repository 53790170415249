import {
    CampaignStatusEnum,
    PublishStatusEnum,
} from "@/enums/campaignCollectionEnums";
import {
    ICampaign,
    ICampaignCollection,
    ICreateCampaignPayload,
    IUpdateCampaignPostRequest,
    IStore,
} from "@/types/campaignCollection";
import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import { IRegion } from "@/types/regionCollection";
import { RegionModel } from "./regionCollectionModel";

export class StoreModel {
    public readonly object: string;
    public readonly id: number;
    public readonly identifier: string;
    public readonly created_at: string | null;

    constructor(data: IStore) {
        this.object = data.object;
        this.id = data.id;
        this.identifier = data.identifier;
        this.created_at = data.created_at;
    }

    getId() {
        return this.id;
    }
    getIdentifier() {
        return this.identifier;
    }
}

export class CampaignModel {
    object: "Campaign";
    readonly id: string;
    readonly title: string;
    readonly slug: string;
    readonly strapi_content_slug: string;
    readonly start_at: string;
    readonly finish_at: string;
    readonly publishing_status: PublishStatusEnum;
    readonly campaign_status: CampaignStatusEnum;
    readonly region: IRegion;
    readonly store: IStore;
    readonly created_at: string;

    constructor(data: ICampaign) {
        this.object = data.object;
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.strapi_content_slug = data.strapi_content_slug;
        this.start_at = data.start_at;
        this.finish_at = data.finish_at;
        this.publishing_status = data.publishing_status;
        this.campaign_status = data.campaign_status;
        this.region = data.region;
        this.store = data.store;
        this.created_at = data.created_at;
    }

    getId() {
        return this.id;
    }
    getTitle() {
        return this.title;
    }
    getSlug() {
        return this.slug;
    }
    getStripContentSlug() {
        return this.strapi_content_slug;
    }
    getPublishStatus() {
        return this.publishing_status;
    }
    getCampaignStatus() {
        return this.campaign_status;
    }
    getStartAt() {
        return this.start_at;
    }
    getFinishAt() {
        return this.finish_at;
    }
    getRegion() {
        return new RegionModel(this.region);
    }
    getStore() {
        return new StoreModel(this.store);
    }
}

export class CampaignCollectionModel {
    public readonly object: string;
    public readonly data: CampaignModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: ICampaignCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new CampaignModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

    getData() {
        return this.data;
    }

    getCampaignById(id: string): CampaignModel | undefined {
        return this.data.find((item) => item.getId() === id);
    }

    getPagination() {
        return this.pagination;
    }

    getFilter() {
        return this.filters;
    }
}

export class CreateCampaignPostRequestModel {
    public readonly region_id: number;
    public readonly store_id: number;
    public readonly title: string;
    public readonly slug: string;
    public readonly start_at: string;
    public readonly strip_content_slug: string;
    public readonly finish_at: string;
    public readonly publishing_status: string;

    constructor(reqData: ICreateCampaignPayload) {
        this.region_id = reqData.region_id;
        this.store_id = reqData.store_id;
        this.title = reqData.title;
        this.slug = reqData.slug;
        this.start_at = reqData.start_at;
        this.strip_content_slug = reqData.strapi_content_slug;
        this.finish_at = reqData.finish_at;
        this.publishing_status = reqData.publishing_status;
    }
}

export class UpdateCampaignPostRequestModel {
    public readonly region_id: number;
    public readonly title: string;
    public readonly slug: string;
    public readonly start_at: string;
    public readonly finish_at: string;
    public readonly strip_content_slug: string;
    public readonly publishing_status: string;

    constructor(reqData: IUpdateCampaignPostRequest) {
        this.region_id = reqData.region_id;
        this.title = reqData.title;
        this.slug = reqData.slug;
        this.start_at = reqData.start_at;
        this.strip_content_slug = reqData.strapi_content_slug;
        this.finish_at = reqData.finish_at;
        this.publishing_status = reqData.publishing_status;
    }
}
