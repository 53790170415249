import { CheckSquareOutlined } from "@ant-design/icons";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { useRef, useState } from "react";
import BulkApproveModal from "./component/bulkApproveModal";
import { showError } from "@/helpers/showError";
import { useBulkApprovePayoutRequests } from "@/lib/core-react/hooks/private/useWallet";
import { ADMIN_WALLET_PERMISSION_ENUM } from "@/consts/permission-enum/wallet-enum";
import { notification } from "antd";

export const useCustomerCashOutBulkActions = () => {
    const { bulkApprovePayoutRequests, isLoading } =
        useBulkApprovePayoutRequests();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const cashOutIdRef = useRef<number[]>([]);

    const handleBulkApprove = async (formData: { adminNote: string }) => {
        try {
            const payload = {
                ids: cashOutIdRef.current,
                admin_note: formData.adminNote || undefined,
            };
            await bulkApprovePayoutRequests(payload);
            notification["success"]({
                message: "Bulk Approved successfully.",
            });
            setIsModalVisible(false);
        } catch (error) {
            showError(error);
            setIsModalVisible(false);
        }
    };

    const getActions = (keys: React.Key[]) => {
        const numberKeys = keys.map((key) =>
            typeof key === "number" ? key : parseInt(key.toString(), 10),
        );

        cashOutIdRef.current = numberKeys;
        const items: ExtendedMenuItemType[] = [
            {
                permission:
                    ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_REQUEST_MANAGE,
                key: "bulk-approve",
                icon: <CheckSquareOutlined />,
                onClick: () => setIsModalVisible(true),
                label: "Bulk Approve",
            },
        ];

        return items.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };

    return {
        getActions,
        BulkApproveModal: (
            <BulkApproveModal
                visible={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                onSubmit={handleBulkApprove}
                isLoading={isLoading}
            />
        ),
    };
};
