import { ExtendedMenuItemType } from "@/types";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import {
    CalculatorFilled,
    CheckCircleTwoTone,
    EditOutlined,
    PayCircleOutlined,
} from "@ant-design/icons";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { IModalData } from "@/helpers/getModalTital";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { showError } from "@/helpers/showError";
import * as Sentry from "@sentry/browser";
import { RegionModel } from "@/models/buyActionCollectionModel";
import { message, Modal } from "antd";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import { IHarvestJobCreate } from "@/types/harvestBatchCollection";
import { HarvestActionEnums } from "@/enums/harvestJobCollectionEnums";
import { useHarvestJobCreate } from "@/lib/core-react/hooks/private/useHarvestJob";

interface IUseActionsCustomerInvoice {
    agentInvoiceIds?: number[];
    customerInvoiceIds?: number[];
    handleModal: (payload: IModalData) => void;
}
const recalculateKey = "recalculate";
const tokenKey = "tokenKey";

const useActionsInvoice = ({
    handleModal,
    agentInvoiceIds,
}: IUseActionsCustomerInvoice) => {
    const { recalculateInvoiceData, settleInvoice, createSession } =
        useInvoice();
    const { createHarvest } = useHarvestJobCreate();

    const handleRecalculateCustomer = async (buyProduct: BuyProductModel) => {
        try {
            if (!buyProduct.getCustomerInvoice()?.getId()) {
                throw new Error("No customer invoice id found");
            }
            message.loading({
                content: "Recalculating...",
                key: recalculateKey,
            });
            await recalculateInvoiceData(
                buyProduct.getCustomerInvoice()?.getId() as number,
                "customer",
            );
            message.destroy(recalculateKey);
            showSuccessAlert("Successfully Recalculated customer invoice ");
            handleModal({
                action: "refetch",
            });
        } catch (_error) {
            message.destroy(recalculateKey);
            // showError(error);
        }
    };
    const handleRecalculateAgent = async (buyProduct: BuyProductModel) => {
        try {
            if (!buyProduct.getAgentInvoice()?.getId()) {
                throw new Error("No customer invoice id found");
            }
            message.loading({
                content: "Recalculating...",
                key: recalculateKey,
            });
            await recalculateInvoiceData(
                buyProduct.getAgentInvoice()?.getId() as number,
                "agent",
            );
            message.destroy(recalculateKey);
            showSuccessAlert("Successfully Recalculated Agent invoice ");
            handleModal({
                action: "refetch",
            });
        } catch (_error) {
            message.destroy(recalculateKey);
            // showError(error);
        }
    };

    const harvestJobCreateHandler = async () => {
        const payload: IHarvestJobCreate<object, object> = {
            type: HarvestActionEnums.ADMIN_AGENT_INVOICE_SETTLE_HARVEST,
            context: {
                filters: {
                    invoice_ids: agentInvoiceIds,
                },
            },
            settings: {
                notification: {
                    email: true,
                    sms: true,
                },
            },
        };
        await createHarvest(payload);
    };

    const handleBulkSettle = async () => {
        try {
            if (!agentInvoiceIds) {
                throw new Error("selected invoice Not Found");
            }
            Modal.confirm({
                title: "Do you want to settle these invoice?",
                content: "Select Ok, otherwise the process cannot success.",
                async onOk() {
                    try {
                        if (agentInvoiceIds.length === 0) {
                            throw new Error("Please select invoice");
                        }

                        // await settleBulk({ ids: agentInvoiceIds });
                        await harvestJobCreateHandler();
                        showSuccessAlert(
                            "Successfully bulk agent invoice settle",
                        );
                        handleModal({
                            action: "refetch",
                        });
                    } catch (error) {
                        showError(error);
                    }
                },
                onCancel() {},
            });
        } catch (error) {
            showError(error);
        }
    };

    const handleSettleCustomer = async (
        customerInvoiceId: number | undefined,
    ) => {
        try {
            if (!customerInvoiceId) {
                throw new Error("Customer invoice Not Found");
            }

            Modal.confirm({
                title: "Do you want to settle this invoice?",
                content: "Select Ok, otherwise the process cannot success.",
                onOk: async () => {
                    try {
                        await settleInvoice(customerInvoiceId, "customer");

                        showSuccessAlert(
                            `Successfully Settled Customer invoice`,
                        );
                        handleModal({
                            action: "refetch",
                        });
                    } catch (_error) {
                        // showError(error);
                    }
                },
                onCancel() {},
            });
        } catch (error) {
            showError(error);
        }
    };
    const handleSettleAgent = async (agentInvoiceId: number | undefined) => {
        try {
            if (!agentInvoiceId) {
                throw new Error("agent invoice Not Found");
            }

            Modal.confirm({
                title: "Do you want to settle this invoice?",
                content: "Select Ok, otherwise the process cannot success.",
                async onOk() {
                    try {
                        await settleInvoice(agentInvoiceId, "agent");

                        showSuccessAlert(`Successfully Settled Agent invoice`);
                        handleModal({
                            action: "refetch",
                        });
                    } catch (_error) {
                        // showError(error);
                    }
                },
                onCancel() {},
            });
        } catch (error) {
            showError(error);
        }
    };

    const getActions = (
        buyProduct: BuyProductModel,
        shipmentProduct?: ShipmentProductModel,
    ) => {
        const shouldDisplaySettlementMenuItem = () => {
            return buyProduct.getCustomerInvoice()?.isOverPaid();
        };
        const shouldDisplayPayMenuItem = () => {
            return buyProduct.getCustomerInvoice()?.isDue();
        };

        const handlePayCustomerInvoice = async (
            region: RegionModel | undefined,
            invoice_ids: number[],
            user_id?: number,
        ) => {
            try {
                if (!region) {
                    throw new Error("No region provided to pay invoice");
                }
                if (!user_id) {
                    throw new Error("No user id provided to pay invoice");
                }

                message.loading({
                    content: "Loading Payment Modal...",
                    key: tokenKey,
                });
                const res = await createSession(user_id, invoice_ids);

                message.destroy(tokenKey);
                if (res && res.data.session_token) {
                    handleModal({
                        action: "pay-invoice",
                        data: {
                            region,
                            user_id,
                            token: res.data.session_token,
                        },
                    });
                } else {
                    throw new Error("No session token found");
                }
            } catch (error) {
                showError(error);
                Sentry.captureMessage(getErrorMessage(error));
            }
        };

        const isDisabled = () => {
            if (shipmentProduct) {
                return !shipmentProduct?.getAgentInvoice();
            } else {
                return !buyProduct.getAgentInvoice();
            }
        };

        const getRegion = () => {
            if (shipmentProduct) {
                return shipmentProduct.getRegion();
            } else {
                return buyProduct.getRegion();
            }
        };
        const getUserId = () => {
            if (shipmentProduct) {
                return shipmentProduct.getCustomerId();
            } else {
                return buyProduct.getCustomerInvoice()?.getCustomer()?.getId();
            }
        };
        const menuItems: ExtendedMenuItemType[] = [
            {
                label: "Settlement Customer",
                key: "customer-settlement",
                permission: ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_SETTLE,
                icon: <EditOutlined />,
                onClick: () => {
                    handleSettleCustomer(
                        buyProduct.getCustomerInvoice()?.getId(),
                    );
                },
                style: {
                    margin: "5px",
                    padding: "8px 16px",
                    display: shouldDisplaySettlementMenuItem()
                        ? "block"
                        : "none",
                },
            },
            {
                label: "Settlement Agent",
                key: "agent-settlement",
                permission:
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_SETTLE_AGENT_INVOICE,
                icon: <EditOutlined />,
                disabled: isDisabled(),
                onClick: () => {
                    handleSettleAgent(buyProduct.getAgentInvoice()?.getId());
                },
                style: {
                    margin: "5px",
                    padding: "8px 16px",
                    display: shouldDisplaySettlementMenuItem()
                        ? "block"
                        : "none",
                },
            },
            {
                label: "Pay Customer Invoice",
                key: "pay-customer-invoice",
                permission:
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                icon: <PayCircleOutlined />,
                onClick: async () => {
                    handlePayCustomerInvoice(
                        getRegion(),
                        [buyProduct.getCustomerInvoice()?.getId() as number],
                        getUserId(),
                    );
                },
                style: {
                    margin: "5px",
                    padding: "8px 16px",
                    display: shouldDisplayPayMenuItem() ? "block" : "none", // Only include Pay if due > 0
                },
            },

            {
                permission:
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_RECALCULATE,
                label: "Recalculate Customer",
                key: "re-calculate-customer",
                icon: <CalculatorFilled />,
                onClick: () => handleRecalculateCustomer(buyProduct),

                style: { margin: "5px", padding: "8px 16px" },
            },
            {
                permission:
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_RECALCULATE_AGENT_INVOICE,
                label: "Recalculate Agent",
                key: "re-calculate-agent",
                icon: <CalculatorFilled />,
                disabled: isDisabled(),
                onClick: () => handleRecalculateAgent(buyProduct),

                style: { margin: "5px", padding: "8px 16px" },
            },
        ];

        return menuItems.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };

    const getBulkActions = () => {
        const bulkItems: ExtendedMenuItemType[] = [
            {
                permission:
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_SETTLE_AGENT_INVOICE,
                icon: <CheckCircleTwoTone />,
                label: "Bulk Settle Agent",
                key: "bulk-settle",
                onClick: () => {
                    handleBulkSettle();
                },
            },
        ];

        return bulkItems.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };
    return { getActions, getBulkActions };
};

export default useActionsInvoice;
