import { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";

import { AgentVerificationModel } from "@/models/agentVerificationCollectionModel";
import { IAgentVerificationFormData } from "@/types/agentVerificationCollection";
import { AgentVerificationStatus } from "@/enums/agentCompanyCollectionEnums";

import { renderOptionsFromEnumReversed } from "@/components/Form/forms";

interface IProps {
    form: FormInstance<any>;
    selectedVerification: AgentVerificationModel;
    onFinish: (value: IAgentVerificationFormData) => Promise<void>;
}

export const UpdateVerificationStatus = ({
    form,
    selectedVerification,
    onFinish,
}: IProps) => {
    useEffect(() => {
        form.resetFields();
    }, [selectedVerification]);

    const selectedStatus = Form.useWatch("status", form);

    return (
        <>
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                name="Update Verification Status"
                initialValues={{
                    status: selectedVerification.getStatus(),
                    reject_reason: selectedVerification.getRejectReason(),
                }}
            >
                <Form.Item
                    rules={[{ required: true, message: "Status is required" }]}
                    name="status"
                    label="Status"
                >
                    <Select placeholder="Please select a status">
                        {renderOptionsFromEnumReversed(AgentVerificationStatus)}
                    </Select>
                </Form.Item>

                {selectedStatus === AgentVerificationStatus.REJECTED ? (
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Reject reason is required",
                            },
                        ]}
                        name="reject_reason"
                        label="Reject Reason"
                    >
                        <Input placeholder="Please specify the reason" />
                    </Form.Item>
                ) : (
                    ""
                )}
            </Form>
        </>
    );
};
