import { ShipmentTrackingModel } from "@/models/shipmentProductPackageCollection";
import { Form, FormInstance, Input } from "antd";
import { useEffect } from "react";
interface IProps {
    form: FormInstance<any>;
    onOk: () => void;
    tracking?: ShipmentTrackingModel | undefined;
}

export const TrackingModificationModal = ({ form, onOk, tracking }: IProps) => {
    useEffect(() => {
        form.resetFields();
    }, [tracking]);

    return (
        <Form
            form={form}
            name="form"
            onFinish={onOk}
            initialValues={{
                tracking: tracking?.getTracking(),
            }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Tracking is required",
                    },
                ]}
                required
                name="tracking"
                label="Tracking"
                labelCol={{ span: 24 }} // Full width label
                wrapperCol={{ span: 24 }} // Full width input
            >
                <Input placeholder="type tracking" />
            </Form.Item>
        </Form>
    );
};
