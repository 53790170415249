import { Button, Flex, Form, Input, notification, Typography } from "antd";
import { useShipmentProductAttachTrackingId } from "@/lib/core-react/hooks/private/useShipping";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import { ShipmentProductPackageTrackingModel } from "@/models/shipmentProductPackageTrackingCollectionModel";
import { useState } from "react";
import NotAvailableTracking from "./NotAvailableTracking";
import { PlusOutlined } from "@ant-design/icons";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";

interface ITrackingForm {
    tracking?: ShipmentProductPackageTrackingModel;
    trackingId?: number;
    packageId?: number;
    productId?: string | number;
    onCloseModal: () => void;
    action: "view" | "add";
}

export const AddTrackingForm = ({
    productId,
    tracking,
    packageId,
    onCloseModal,
    action,
}: ITrackingForm) => {
    const { isMobile } = useWindowWidth();
    const [form] = Form.useForm();

    const [isAdd, setIsAdd] = useState(false);

    const {
        attachTrackingId,
        isLoading: isLoadingAttachShipmentProductTrackingId,
    } = useShipmentProductAttachTrackingId();

    const handleAttachTracking = async () => {
        try {
            const trigger = await form.validateFields();
            if (trigger && productId && packageId) {
                await attachTrackingId(productId as number, [
                    {
                        ...trigger,
                        package_id: packageId,
                    },
                ]);

                notification["success"]({
                    message: "Add tracking successfully.",
                });

                onCloseModal();
            } else {
                notification["error"]({
                    message: "Shipment product id not found!",
                });
            }
        } catch (err) {
            notification["error"]({
                message: getError(err),
            });
        }
    };

    return (
        <>
            {isAdd ? (
                <Form form={form} name="form" onFinish={handleAttachTracking}>
                    <Flex
                        vertical={isMobile ? true : false}
                        align={isMobile ? "start" : "center"}
                        gap={4}
                    >
                        <>
                            <Typography.Text
                                strong
                                style={{
                                    textTransform: "capitalize",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Tracking
                            </Typography.Text>
                            <Flex
                                gap={4}
                                align="center"
                                style={{ width: "100%" }}
                            >
                                <Form.Item
                                    style={{
                                        width: "100%",
                                        padding: 0,
                                        margin: 0,
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Tracking is required",
                                        },
                                    ]}
                                    initialValue={
                                        tracking ? tracking.getTracking() : ""
                                    }
                                    required
                                    name={`tracking`}
                                >
                                    <Input placeholder="Type tracking" />
                                </Form.Item>
                                <Button
                                    disabled={
                                        isLoadingAttachShipmentProductTrackingId
                                    }
                                    loading={
                                        isLoadingAttachShipmentProductTrackingId
                                    }
                                    type="default"
                                    htmlType="submit"
                                >
                                    Add
                                </Button>
                            </Flex>
                        </>
                    </Flex>
                </Form>
            ) : (
                <Flex align="center" justify="start">
                    <NotAvailableTracking text={"Tracking"} />

                    {action === "add" ? (
                        <Button
                            hidden={
                                !checkActionPermission(
                                    [
                                        ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_MANAGE_PACKAGE_TRACKING,
                                    ],
                                    "checkActionPermission",
                                    null,
                                )
                            }
                            type="link"
                            onClick={() => setIsAdd(true)}
                        >
                            <PlusOutlined /> Add
                        </Button>
                    ) : (
                        <></>
                    )}
                </Flex>
            )}
        </>
    );
};
