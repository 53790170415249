import { Card, Typography, Divider, Flex, Alert, Tag } from "antd";

const { Text } = Typography;
import { ShipmentProductDeliveryEnrollmentModel } from "@/models/shipmentProductCollectionModel";
import { StatusTag } from "@/components";
import ShippingText from "../../../components/ShippingText";

interface Props {
    deliveryEnrollment: ShipmentProductDeliveryEnrollmentModel[] | undefined;
}

export const DeliveryRequest = ({ deliveryEnrollment }: Props) => {
    if (!deliveryEnrollment || deliveryEnrollment.length === 0) {
        return (
            <Alert
                style={{
                    marginTop: 20,
                }}
                message="Delivery Request Not Available"
            />
        );
    }

    return (
        <div style={{ marginTop: 12 }}>
            {deliveryEnrollment.map(({ delivery_request }, index) => (
                <Card
                    key={delivery_request.getId()}
                    styles={{
                        body: {
                            padding: 8,
                        },
                    }}
                >
                    <Text strong>{`Request #${index + 1}`}</Text>
                    <Divider
                        style={{
                            margin: "4px 0px 4px 0px",
                        }}
                    />
                    <Flex vertical gap={8}>
                        <ShippingText label="Request Number">
                            <Text strong copyable>
                                {delivery_request.getRequestNumber()}
                            </Text>
                        </ShippingText>
                        <ShippingText label="Status">
                            <StatusTag
                                slug={delivery_request.getStatus()}
                                text={delivery_request.getStatus()}
                            />
                        </ShippingText>

                        <ShippingText label="Courier">
                            <Tag color="volcano">
                                {delivery_request.getCourierOption().getName()}
                            </Tag>
                        </ShippingText>

                        <ShippingText label="Total Weight">
                            <Text strong>
                                {delivery_request.getTotalWeight()}
                            </Text>
                        </ShippingText>
                        <ShippingText label="Total Quantity">
                            <Text strong>
                                {delivery_request.getTotalQuantity()} Piece
                            </Text>
                        </ShippingText>
                        <ShippingText label="Dimension (W, H, L)">
                            <Text strong>
                                {delivery_request.getDimension()}{" "}
                            </Text>
                        </ShippingText>

                        <Flex vertical>
                            <Typography.Text strong>
                                Shipping Address:
                            </Typography.Text>
                            <Flex vertical gap={4}>
                                {delivery_request?.getShippingAddress()
                                    .display_address &&
                                    Object.entries(
                                        delivery_request?.getShippingAddress()
                                            .display_address || {},
                                    ).map(([key, value]) => {
                                        return (
                                            <ShippingText
                                                key={key}
                                                label={key.replaceAll("_", " ")}
                                            >
                                                <Text strong>{value}</Text>
                                            </ShippingText>
                                        );
                                    })}
                            </Flex>
                        </Flex>
                    </Flex>

                    {index < deliveryEnrollment.length - 1 && <Divider />}
                </Card>
            ))}
        </div>
    );
};
