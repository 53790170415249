import { tw } from "@/consts/theme/tailwindTheme";
import { Image } from "antd";
import React, { forwardRef, useState, useEffect } from "react";

export const handleImageError = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
    placeholderUrl: string,
) => {
    event.currentTarget.src = placeholderUrl;
};

export const handleImageLoad = (
    _event: React.SyntheticEvent<HTMLImageElement, Event>,
    onLoadCallback?: () => void,
) => {
    if (onLoadCallback) {
        onLoadCallback();
    }
};

type ImageWithPlaceholderProps = {
    src: string | undefined;
    placeholderSrc?: string;
    alt?: string;
    preview?: boolean;
    className?: string;
    onLoadCallback?: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

const ImageWithPlaceholder = forwardRef<
    HTMLDivElement,
    ImageWithPlaceholderProps
>(
    (
        {
            src,
            placeholderSrc = "/assets/placeholderImageSquare.svg",
            alt,
            className,
            onLoadCallback,
            style,
            preview = true,
        },
        _ref,
    ) => {
        const [imageSrc, setImageSrc] = useState<string | undefined>(
            placeholderSrc,
        );

        useEffect(() => {
            setImageSrc(src);
        }, [src]);

        return (
            <div
                style={{
                    ...style,
                    overflow: "hidden",
                    borderRadius: tw.borderRadius.DEFAULT,
                }}
            >
                <Image
                    style={{
                        borderRadius: tw.borderRadius.DEFAULT,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                    preview={preview}
                    fallback="/assets/placeholderImageSquare.svg"
                    src={`${imageSrc}`}
                    alt={alt}
                    placeholder={true}
                    className={className}
                    onLoad={(e) => {
                        handleImageLoad(
                            e as unknown as React.SyntheticEvent<
                                HTMLImageElement,
                                Event
                            >,
                            onLoadCallback,
                        );
                    }}
                    onError={(e) => {
                        handleImageError(
                            e as unknown as React.SyntheticEvent<
                                HTMLImageElement,
                                Event
                            >,
                            placeholderSrc,
                        );
                        setImageSrc(placeholderSrc);
                    }}
                />
            </div>
        );
    },
);

ImageWithPlaceholder.displayName = "ImageWithPlaceholder";

export default ImageWithPlaceholder;
