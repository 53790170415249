import { ITranslationEntry } from "../lang";

export const MS: ITranslationEntry = {
    translations: {
        notifications: "Pemberitahuan",
        markAllAsRead: "Tandakan semua sebagai telah dibaca",
        poweredBy: "Dikuasai oleh",
        settings: "Tetapan",
        noNewNotification: "Belum ada yang baru untuk dilihat di sini",
    },
    lang: "ms",
};
