import { ITranslationEntry } from "../lang";

export const CA: ITranslationEntry = {
    translations: {
        notifications: "Notificacions",
        markAllAsRead: "marcar tot com a llegit",
        poweredBy: "Amb la tecnologia de",
        settings: "Configuració",
        noNewNotification: "Encara no hi ha res nou per veure aquí",
    },
    lang: "ca",
};
