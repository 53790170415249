export enum DESTINATION_PERMISSION_ENUM {
    ADMIN_DESTINATION_WAREHOUSE_VIEW = "admin-destination-warehouse-view",
    ADMIN_DESTINATION_WAREHOUSE_MANAGE = "admin-destination-warehouse-manage",

    ADMIN_DESTINATION_WAREHOUSE_MEMBER_VIEW = "admin-destination-warehouse-member-view",
    ADMIN_DESTINATION_WAREHOUSE_MEMBER_MANAGE = "admin-destination-warehouse-member-manage",

    ADMIN_DW_MEMBER_INVITATION_VIEW = "admin-dw-member-invitation-view",
    ADMIN_DW_MEMBER_INVITATION_MANAGE = "admin-dw-member-invitation-manage",
}

export const destinationManageRoutePermissions = [
    DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_VIEW,
    DESTINATION_PERMISSION_ENUM.ADMIN_DESTINATION_WAREHOUSE_MANAGE,
];
