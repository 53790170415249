import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { UserSelect } from "@/components/CustomFormElement/UserSelect";
import { renderOptionsFromEnumReversed } from "@/components/Form/forms";
import { MultiChunkUpload } from "@/components/MultiChunkUpload";
import { UnitTypeEnums } from "@/enums/shippingCoreEnums";
import { ShippingModeEnums, ShippingTypeEnums } from "@/enums/shippingEnums";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { useDebounce } from "@/hooks/useDebounce";
import { useRfqCreate } from "@/lib/core-react/hooks/private/useRfq";
import { rfqRequestListAtom } from "@/lib/core-react/store/store";
import { RFQRequestAddPayload } from "@/types/rfqRequestCreate";
import {
    Button,
    Col,
    DatePicker,
    Flex,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    TreeSelect,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useSetAtom } from "jotai";
import moment from "moment";
import { useEffect, useState } from "react";

interface Props {
    handleSuccess: () => void;
}

const LOCALE = "en";

const CreateRFQForm: React.FC<Props> = ({ handleSuccess }) => {
    const [form] = Form.useForm();
    const {
        allOptionsData: {
            shippingCategoryOptionsData,
            shippingCountryOptionsData,
            regionOptionsData,
            destinationWarehouseOptionsData,
            agentCompanyOptionsData,
            customerOptionsData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { createRFQ, isLoading } = useRfqCreate();
    const [regionId, setRegionId] = useState<number | null>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [description, setDescription] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const setRFQRequestList = useSetAtom(rfqRequestListAtom);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.SHIPPING_CATEGORY);
        onFetchFilterApi(filterResourceEnum.REGION);
        onFetchFilterApi(filterResourceEnum.DESTINATION_WAREHOUSE);
        onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY);
        onFetchFilterApi(filterResourceEnum.AGENT_COMPANY);
        onFetchFilterApi(filterResourceEnum.CUSTOMER);
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;
        if (value.length > 1000) {
            setDescription(value.substring(0, 1000)); // Trim to 1000 characters
        } else {
            setDescription(value);
        }
    };

    const onFinish = async (values: RFQRequestAddPayload) => {
        //@ts-ignore
        const time = moment(String(values.valid_until.$d)).format(
            "YYYY-MM-DD HH:mm:ss",
        );
        try {
            await createRFQ({
                payload: { ...values, locale: LOCALE, valid_until: time },
            });

            showSuccessAlert("Successfully RFQ Created.");
            form.resetFields();
            handleSuccess();
            setRFQRequestList((prev) => ({ ...prev, refetch: true }));
        } catch (error) {
            showError(error, form);
        }
    };

    const onFinishFailed = (_errorInfo: any) => {
        notification.error({
            message: "Please fill-up the required fields. ",
        });
    };

    useEffect(() => {
        if (regionId) {
            onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY, {
                region_id: regionId,
            });
        }
    }, [regionId]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.CUSTOMER, {
            keyword: debouncedSearchTerm,
        });
    }, [debouncedSearchTerm]);

    const handleSearch = (q) => {
        setSearchTerm(q);
    };
    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={14}>
                            <Form.Item
                                label="Customer "
                                name="user_id"
                                style={{ width: "100%" }}
                                rules={[
                                    {
                                        required: true,
                                        message: "customer is required",
                                    },
                                ]}
                            >
                                <UserSelect
                                    initialData={
                                        customerOptionsData.options as any[]
                                    }
                                    loading={customerOptionsData.isLoading}
                                    resource={""}
                                    onSearch={(value) => {
                                        handleSearch(value);
                                    }}
                                    allowClear={true}
                                    showSearch={true}
                                    placeholder={
                                        "Search users by email or name"
                                    }
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={9}>
                            <Flex gap={4}>
                                <Form.Item
                                    label="Region "
                                    name="region"
                                    style={{ width: "100%" }}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Region is required",
                                        },
                                    ]}
                                >
                                    <Select
                                        loading={regionOptionsData.isLoading}
                                        placeholder="Please select a region"
                                        options={regionOptionsData.options.map(
                                            (opt) => {
                                                return {
                                                    ...opt,
                                                    value: opt.code,
                                                    code: opt.value,
                                                };
                                            },
                                        )}
                                        onChange={(_value, option: any) => {
                                            setRegionId(option.code);
                                        }}
                                    />
                                </Form.Item>
                            </Flex>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Product Name"
                        name="product_name"
                        rules={[
                            {
                                required: true,
                                message: "Product name is required",
                            },
                        ]}
                    >
                        <Input placeholder="Write Product Name" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Product Link" name="product_link">
                        <Input type="url" placeholder="give product url" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Flex gap={4}>
                        <Form.Item
                            label="Purchase Quantity"
                            name="purchase_quantity"
                            style={{ width: "100%" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Purchase quantity is required",
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder="Purchase Quantity"
                                min={1}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Unit Type"
                            name="purchase_unit_type"
                            rules={[
                                {
                                    required: true,
                                    message: "Purchase unit type is required",
                                },
                            ]}
                        >
                            <Select
                                placeholder="unit type"
                                style={{ minWidth: 200 }}
                            >
                                {renderOptionsFromEnumReversed(UnitTypeEnums)}
                            </Select>
                        </Form.Item>
                    </Flex>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Product Description"
                        name="product_description"
                        rules={[
                            {
                                required: true,
                                message: "Product description is required",
                            },
                            {
                                validator: (_, value) =>
                                    value && value.length > 1000
                                        ? Promise.reject(
                                              "Product description must be at most 1000 characters.",
                                          )
                                        : Promise.resolve(),
                            },
                        ]}
                    >
                        <TextArea
                            placeholder="Write Product Description"
                            rows={4}
                            maxLength={10000}
                            value={description}
                            onChange={handleChange}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Images"
                        name="images"
                        rules={[
                            {
                                required: true,
                                message: "At least one image is required",
                            },
                        ]}
                    >
                        <MultiChunkUpload fieldName={"images"} form={form} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Row gutter={4}>
                        <Col span={24} lg={8}>
                            <Form.Item
                                label="Shipping Category"
                                name="shipping_category_id"
                                rules={[
                                    {
                                        required: false,
                                        message:
                                            "Shipping Category is required",
                                    },
                                ]}
                            >
                                <TreeSelect
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            "shipping_category_id",
                                            value,
                                        );
                                    }}
                                    showSearch
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: "auto",
                                    }}
                                    placeholder="Please select a shipping category"
                                    allowClear
                                    loading={
                                        shippingCategoryOptionsData.isLoading
                                    }
                                    treeLine={{ showLeafIcon: true }}
                                    treeData={
                                        shippingCategoryOptionsData.options
                                    }
                                    filterTreeNode={(searchValue, treeNode) => {
                                        const title = treeNode.title;
                                        return (
                                            typeof title === "string" &&
                                            title
                                                .toLowerCase()
                                                .includes(
                                                    searchValue.toLowerCase(),
                                                )
                                        );
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} lg={8}>
                            <Form.Item
                                name={"shipping_country_id"}
                                label="Shipping Country"
                                required
                                rules={[
                                    {
                                        required: true,
                                        message: "Shipping Country is required",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                                (
                                                    optionB?.label ?? ""
                                                ).toLowerCase(),
                                            )
                                    }
                                    loading={
                                        shippingCountryOptionsData.isLoading
                                    }
                                    placeholder="Select Shipping Country"
                                    options={shippingCountryOptionsData.options}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} lg={4}>
                            <Form.Item
                                label="Shipping Mode"
                                name="shipping_mode"
                                rules={[
                                    {
                                        required: true,
                                        message: "Shipping mode is required",
                                    },
                                ]}
                            >
                                <Select placeholder="Select Shipping Mode">
                                    {Object.values(ShippingModeEnums).map(
                                        (mode) => (
                                            <Select.Option
                                                key={mode}
                                                value={mode}
                                            >
                                                {mode.toUpperCase()}
                                            </Select.Option>
                                        ),
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={4}>
                            <Form.Item
                                label="Shipping Type"
                                name="shipping_type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Shipping type is required",
                                    },
                                ]}
                            >
                                <Select placeholder="Select Shipping Type">
                                    {Object.values(ShippingTypeEnums).map(
                                        (type) => (
                                            <Select.Option
                                                key={type}
                                                value={type}
                                            >
                                                {type.toUpperCase()}
                                            </Select.Option>
                                        ),
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                label="Destination Warehouse"
                                name="destination_warehouse_id"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Destination warehouse ID is required",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                                (
                                                    optionB?.label ?? ""
                                                ).toLowerCase(),
                                            )
                                    }
                                    loading={
                                        destinationWarehouseOptionsData.isLoading
                                    }
                                    placeholder="select destination Warehouse"
                                    options={
                                        destinationWarehouseOptionsData.options
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Source Agent Company"
                                name="source_agent_company_ids"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "At least one agent company is required",
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                                (
                                                    optionB?.label ?? ""
                                                ).toLowerCase(),
                                            )
                                    }
                                    style={{ width: "100%" }}
                                    placeholder="Please select a company"
                                    loading={agentCompanyOptionsData.isLoading}
                                    options={agentCompanyOptionsData.options}
                                    notFoundContent={
                                        agentCompanyOptionsData.isLoading
                                            ? LoaderSmall
                                            : null
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Form.Item
                        style={{ width: "100%" }}
                        name="valid_until"
                        label="Valid Until"
                        rules={[
                            {
                                required: true,
                                message: "Please provide valid until date ",
                            },
                        ]}
                    >
                        <DatePicker
                            style={{ width: "100%" }}
                            showTime
                            format={"DD/MM/YYYY"}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Flex justify="end">
                <Form.Item noStyle>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Flex>
        </Form>
    );
};

export default CreateRFQForm;
