import React, { useEffect } from "react";
import {
    Button,
    Form,
    InputNumber,
    notification,
    Radio,
    Select,
    Tooltip,
} from "antd";

import { useAddPrice } from "@/lib/core-react/hooks/private/useAddonService";
import { renderOptionsFromEnumAddonDependsOnEnum } from "@/components/Form/forms";
import {
    AddonServiceModel,
    AddonServicePricesModel,
    Price,
} from "@/models/addonServiceCollectionModel";
import { DependsOnEnum } from "@/enums/addonServicesCollectionEnums";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { showError } from "@/helpers/showError";

interface IProps {
    setIsShowAddPriceModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedAddPrice: AddonServiceModel | AddonServicePricesModel;
}

const AddPrice = ({ setIsShowAddPriceModal, selectedAddPrice }: IProps) => {
    const { addPrice, isLoading } = useAddPrice();
    const {
        allOptionsData: { regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    let id;

    if (selectedAddPrice instanceof AddonServicePricesModel) {
        id = selectedAddPrice.getAddonServiceId();
    } else {
        id = selectedAddPrice.getId();
    }
    const onFinish = async (value: Price) => {
        try {
            await addPrice(Number(id), value);
            setIsShowAddPriceModal(false);
            form.resetFields();
            notification.success({
                message: "Successfully Added Price",
            });
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form
            name="serviceForm"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
                slots: [{ min_amount: 1, max_amount: 1, price: 1 }],
                price: 1,
            }}
        >
            <Form.Item
                rules={[{ required: true, message: "Region is required" }]}
                name="region_id"
                label="Region"
            >
                <Select
                    onClick={() => onFetchFilterApi(filterResourceEnum.REGION)}
                    allowClear
                    placeholder="Please select a region"
                    onClear={() => onFetchFilterApi(filterResourceEnum.REGION)}
                    options={regionOptionsData.options}
                    loading={regionOptionsData.isLoading}
                    notFoundContent={
                        regionOptionsData.isLoading ? LoaderSmall : null
                    }
                />
            </Form.Item>

            <Form.Item
                label="Depends On"
                name="depends_on"
                rules={[{ required: true, message: "Depends On is required" }]}
            >
                <Select placeholder="Please select a Depends On">
                    {renderOptionsFromEnumAddonDependsOnEnum(DependsOnEnum)}
                </Select>
            </Form.Item>

            <Form.Item
                name="is_primary"
                label="Is primary"
                rules={[{ required: true, message: "Primary is required" }]}
            >
                <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: "Please enter price" }]}
            >
                <InputNumber />
            </Form.Item>

            <Form.List name="slots">
                {(slotFields, { add: addSlot, remove: removeSlot }) => (
                    <div>
                        {slotFields.map((slotField, index) => (
                            <div
                                key={slotField.key}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                }}
                            >
                                <div style={{ marginRight: "16px" }}>
                                    <Form.Item
                                        label="Min"
                                        name={[slotField.name, "min_amount"]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please enter min amount",
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                </div>
                                <div style={{ marginRight: "16px" }}>
                                    <Form.Item
                                        label="Max"
                                        name={[slotField.name, "max_amount"]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Please enter max amount",
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                </div>
                                <div style={{ marginRight: "16px" }}>
                                    <Form.Item
                                        label="Price"
                                        name={[slotField.name, "price"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter price",
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                </div>
                                {index > 0 && (
                                    <MinusCircleOutlined
                                        size={24}
                                        onClick={() =>
                                            removeSlot(slotField.name)
                                        }
                                    />
                                )}
                            </div>
                        ))}
                        <Form.Item>
                            <Tooltip title="Add Slot">
                                <Button
                                    size="small"
                                    style={{ width: "100px" }}
                                    onClick={() => addSlot()}
                                    type="dashed"
                                    icon={<PlusOutlined />}
                                />
                            </Tooltip>
                        </Form.Item>
                    </div>
                )}
            </Form.List>
            <Form.Item
                style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <Button
                    loading={isLoading}
                    disabled={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddPrice;
