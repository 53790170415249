import { CSSProperties, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import {
    Button,
    Card,
    Col,
    Dropdown,
    Flex,
    Form,
    Modal,
    Row,
    Tooltip,
    notification,
} from "antd";
import { PageHeader } from "@ant-design/pro-layout";

import {
    useApprovedShippingProductForProduct,
    useGetShipmentProduct,
    useRejectedShippingProduct,
    useShipmentProductAttachPackageImage,
} from "@/lib/core-react/hooks/private/useShipping";
import { shipmentProductAtom } from "@/lib/core-react/store/store";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";

import useDataFilters from "@/hooks/useDataFilters";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";

import OrderHandlerInfo from "@/pages/BuyAndShip/components/OrderHandlerInfo";
import CustomerInfo from "@/components/shared/Order/CustomerInfo";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import useShipmentProductsActions from "../useActions";
import SubTitleShipmentProductDetails from "./SubTitleShipmentProductDetails";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import OrderInfo from "@/pages/BuyAndShip/components/OrderInfo";
import { BuyProductInfo } from "@/pages/BuyAndShip/components";
import { HddFilled, LoadingOutlined } from "@ant-design/icons";
import { Image } from "antd";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import BuyProductCharges from "@/pages/BuyAndShip/BuyProducts/components/BuyProductCharges";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { TRACKING_PERMISSION_ENUM } from "@/consts/permission-enum/tracking-enum";
import { BidList, SelectWarehouseModal } from "./components";
import { shipmentProductTimelineTrackingAtom } from "@/lib/core-react/store/shipmentOrder/shipmentOrderAtom";
import {
    useGetRole,
    useShipmentProductGetTimelineTracking,
} from "@/lib/core-react/hooks/private";
import { TimelineTrackingModel } from "@/models/timelineTrackingModel";
import { TimelineTracking } from "../../newComponents/TimelineTracking";
import LoaderSpin from "@/components/LoaderSpin";
import ShipmentProductInfo from "../../newComponents/ShipmentProductInfo";
import ShipmentProductPackages from "../../newComponents/ShipmentProductPackages";
import ShipmentProductShippingInfo from "../../newComponents/ShipmentProductShippingInfo";
import { DeliveryRequest } from "../../newComponents/DeliveryRequest";
import { BidTable } from "../../newComponents/BidTable";
import { ShippingInfoChangeModals } from "../../newComponents/ShippingInfoChangeModals";
import {
    IShipmentProductAttachImagesPayload,
    RejectPayload,
} from "@/types/shipmentProductCollection";
import { showError } from "@/helpers/showError";
import { MultiChunkUpload } from "@/components/MultiChunkUpload";
import Invoice from "@/pages/BuyAndShip/components/Invoice";
import useRefetch from "@/hooks/useRefetch";
import {
    ExpiredUnit,
    IImpersonateSessionData,
    IImpersonationSession,
    UserType,
} from "@/types/userCollection";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";
import RejectProductModal from "../../components/handleRejectModal";
import CommonError from "@/components/Error/CommonError";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { RegionModel } from "@/models/regionCollectionModel";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import { useCreateImpersonateSession } from "@/lib/core-react/hooks/private/useRole";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { responseTypeQuery } from "@/filters/constant";
import { RoleModel } from "@/models/roleCollectionModel";
import { downloadImpersonateCredentialsAsTextFile } from "@/utils/helper";
import ImpersonateResultView from "@/components/ImpersonateSessionCreate/impersonateResultView";
import ImpersonateIcon from "@/assets/ImpersonateIcon";
import useOryAuth from "@/lib/core-react/utils/useOryAuth";
import { ICustomerUser } from "@/types/customerUserCollection";

export const ShipmentProductDetails = () => {
    const [form] = Form.useForm(); // pay now form
    const params = useParams();
    const { productId } = params;
    const { isMobile } = useWindowWidth();

    const { createImpersonate, isLoading: impersonateLoading } =
        useCreateImpersonateSession();
    const { getRole } = useGetRole();

    const [showAgentAssignModal, setShowAgentAssignModal] =
        useState<boolean>(false);
    const [shouldFetchImpersonateData, setShouldFetchImpersonateData] =
        useState(false);
    const [loadingImpersonate, setLoadingImpersonate] = useState<{
        [key: number]: boolean;
    }>({});
    const [categoryPriceDetailsInfo, setCategoryPriceDetailsInfo] = useState<
        ICategoryPriceRequestParams | undefined
    >(undefined);
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedCustomerUser, setSelectedCustomerUser] =
        useState<ICustomerUser>();
    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
    }>();

    const { paySession, isLoading: isLoadingPaySession } = useInvoice();

    // Bid list

    const { approvedShippingProduct } = useApprovedShippingProductForProduct();
    const { getShipmentProduct } = useGetShipmentProduct();
    const { getTimelineTracking } = useShipmentProductGetTimelineTracking();
    const [
        { data: timelineTrackingData, isLoading: isLoadingTimelineTracking },
    ] = useAtom(shipmentProductTimelineTrackingAtom);

    const [, setOpenBidModal] = useState<boolean>(false);
    const [selectedBidProduct, setSelectedBidProduct] = useState<
        ShipmentProductModel | undefined
    >(undefined);

    const [selectedShipmentProduct, setSelectedShipmentProduct] = useState<
        ShipmentProductModel | undefined
    >(undefined);
    const [_selectedPackageIds, setSelectedPackageIds] = useState<number[]>([]);
    const [impersonateData, setImpersonateData] =
        useState<IImpersonateSessionData>();

    const {
        attachImage: attachShipmentProductPackageImage,
        isLoading: isLoadingAttachShipmentProductPackageImage,
    } = useShipmentProductAttachPackageImage();
    const { refetchDetailApi } = useRefetch();
    const handleTracking = (productId: number) => {
        getTimelineTracking(productId);
    };

    const [
        {
            data: shipmentProductModel,
            refetch,
            isLoading: isProductDataLoading,
            error,
            code,
        },
    ] = useAtom(shipmentProductAtom);

    const {
        allOptionsData: { adminOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { getUserEmail } = useOryAuth();

    const email = getUserEmail();

    const { rejectedShippingProduct, isLoading: isRejectedLoading } =
        useRejectedShippingProduct();

    const showBidModalHandler = (product: ShipmentProductModel) => {
        setSelectedBidProduct(product);
        setOpenBidModal(true);
    };

    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);

    const handleModal = async (payload: IModalData) => {
        setModalOpenType(payload.action);
        switch (payload.action) {
            case "bid_list":
                showBidModalHandler(payload.data);
                break;
            case "approve_and_assign_agent":
                agentAssignHandler();
                break;
            case "reject_shipment_product":
                setSelectedShipmentProduct(payload.data);
                break;
            case "approved_shipment_product":
                handleApproveProduct(payload.data);
                break;
            case "pay-invoice":
                setSelectedPayInvoiceData(payload.data);
                break;
            default:
                break;
        }
    };

    const createImpersonateCredential = async () => {
        if (!shipmentProductModel) {
            return;
        }

        if (adminOptionsData.options.length === 0) {
            showError("Admin data not available");
            return;
        }

        const shipmentProductId = shipmentProductModel.getId();
        const user = shipmentProductModel.user;
        setLoadingImpersonate((prev) => ({
            ...prev,
            [shipmentProductId]: true,
        }));
        setSelectedCustomerUser(user);
        let roleIds: number[] = [];
        if (user.type === UserType.Customer) {
            const roleCollectionData = await getRole(
                `is_customer=1&response_type=minimal`,
            );

            if (roleCollectionData) {
                const customerRole = roleCollectionData?.find(
                    (role: RoleModel) =>
                        role.name.toLowerCase() === "customer-role" ||
                        role.name.toLowerCase() === "customer role",
                );
                roleIds = [customerRole.id];
            }
        }
        try {
            const payload: IImpersonationSession = {
                user_type: UserType.Customer,
                impersonatee_id: user.id,
                impersonator_id: adminOptionsData.options[0].value as number,
                expired_unit: ExpiredUnit.Hour,
                expired_value: 1,
                intent: "",
                role_ids: roleIds,
            };

            const response = await createImpersonate(payload);
            setImpersonateData(response);

            if (response) {
                downloadImpersonateCredentialsAsTextFile(response);
                setIsShowModal(true);
            }
        } catch (error: any) {
            showError(error);
        } finally {
            setLoadingImpersonate((prev) => ({
                ...prev,
                [shipmentProductId]: false,
            })); // Reset loading for this invoice
        }
    };

    useEffect(() => {
        if (shouldFetchImpersonateData && adminOptionsData.options.length > 0) {
            createImpersonateCredential();
            setShouldFetchImpersonateData(false);
        }
    }, [adminOptionsData.options.length, shouldFetchImpersonateData]);

    const handleAttachCartonImage = async () => {
        try {
            if (shipmentProductModel) {
                const images = form.getFieldValue("images") as string[];

                const payload: IShipmentProductAttachImagesPayload = {
                    images: images,
                    shipment_product_ids: [shipmentProductModel.getId()],
                    packages: shipmentProductModel.getPackages().map((pkg) => ({
                        id: pkg.getId(),
                        images: [...images],
                    })),
                };

                await attachShipmentProductPackageImage(payload);
                notification["success"]({
                    message: "Attached carton images successfully.",
                });

                setSelectedPackageIds([]);
                handleModal({
                    action: false,
                });
            } else {
                throw new Error("Shipment product id not found");
            }
        } catch (err) {
            showError(err, form);
        }
    };

    const onCancel = () => {
        handleModal({
            action: false,
            data: null,
        });
    };

    const handleRejectProduct = async (value: { reject_reason: string }) => {
        try {
            const payload: RejectPayload = {
                reject_reason: value.reject_reason,
            };
            if (!selectedShipmentProduct) {
                throw new Error("shipment Product id not found");
            }
            // Pass the full payload, not just payload.reject_reason
            await rejectedShippingProduct(
                Number(selectedShipmentProduct.getById()),
                payload,
            );
            notification.success({
                message: "Successfully rejected shipment product.",
            });
            setModalOpenType(false);
            await refetchDetailApi(selectedShipmentProduct.getById());
        } catch (error) {
            showError(error);
        }
    };

    const agentAssignHandler = () => {
        if (shipmentProductModel) {
            const categoryDetails: ICategoryPriceRequestParams = {
                region: shipmentProductModel.getRegionCode(),
                region_id: shipmentProductModel.getRegionId(),
                shipping_category_id:
                    shipmentProductModel.getShippingCategoryId(),
                destination_warehouse_id:
                    shipmentProductModel.getDestinationWarehouseId(),
                shipping_type: shipmentProductModel.getShippingType() as any,
                shipping_mode: shipmentProductModel.getShippingMode() as any,
                shipping_country_id:
                    shipmentProductModel.getShippingCountryId(),
                destination_country_id:
                    shipmentProductModel.getDestinationCountryId(),
            };
            setCategoryPriceDetailsInfo(categoryDetails);
            setShowAgentAssignModal(true);
        }
    };

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, wallet_id, ...restValues } = values;
                if (
                    typeof wallet_id === "undefined" &&
                    typeof gateway_id === "undefined"
                ) {
                    return notification.error({
                        message: "Please select a gateway or wallet",
                    });
                }

                const key = gateway_id ? "gateway_id" : "wallet_id";
                const id = gateway_id || wallet_id;

                const payload = {
                    amount,
                    [key]: id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                if (id) {
                    await refetchDetailApi(id);
                }
                setSelectedPayInvoiceData(undefined);
                onCancel();
                notification.success({
                    message: "Payment initiated successfully",
                });
            } catch (error) {
                showError(error, form);
            }
        }
    };

    const { isFirstCall, isFetched } = useDataFilters();

    useEffect(() => {
        if (!isFetched && isFirstCall && productId) {
            getShipmentProduct(parseInt(productId));
        }
    }, [isFirstCall, productId, isFetched]);

    useEffect(() => {
        if (refetch && productId) {
            getShipmentProduct(parseInt(productId));
        }
    }, [refetch]);

    const { getActions } = useShipmentProductsActions({ handleModal });

    const handleApproveProduct = (shipmentProduct: ShipmentProductModel) => {
        const modal = Modal.confirm({
            title: "Confirm Approval",
            content: "Are you sure you want to approve this product?",
            okText: "Ok",
            cancelText: "No",
            onOk: async () => {
                try {
                    await approvedShippingProduct(shipmentProduct.getId());
                    notification["success"]({
                        message: "Product Approved",
                        description:
                            "The product has been successfully approved.",
                    });
                    modal.destroy();
                    await refetchDetailApi(shipmentProduct.getId());
                } catch (error) {
                    showError(error);
                }
            },
        });
    };

    // ------------------------------------ui-----------------------------

    if (isProductDataLoading) {
        return <LoaderSpin />;
    }
    if (error) {
        return <CommonError code={code} message={error} />;
    }

    const colStyle: CSSProperties = {
        padding: isMobile ? 0 : 16,
    };

    return (
        <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title={
                shipmentProductModel
                    ? `#${shipmentProductModel?.getOrder().getId()}`
                    : ""
            }
            subTitle={
                <SubTitleShipmentProductDetails
                    shipmentProductModel={shipmentProductModel}
                />
            }
            onBack={() => window.history.back()}
        >
            <Flex
                justify="end"
                style={{
                    marginRight: 16,
                }}
            >
                <Dropdown
                    menu={{
                        items: shipmentProductModel
                            ? getActions(shipmentProductModel).filter(
                                  (x) => x.isVisible !== true,
                              )
                            : [],
                    }}
                >
                    <Button type="primary" icon={<HddFilled />}>
                        Actions
                    </Button>
                </Dropdown>
            </Flex>

            {shipmentProductModel && (
                <Row
                    gutter={24}
                    style={{
                        width: "100%",
                        margin: isMobile ? 0 : undefined,
                    }}
                >
                    <Col style={colStyle} xs={24} lg={12}>
                        <Flex vertical gap={24}>
                            <Card title="Product Info">
                                <ShipmentProductInfo
                                    handleModal={handleModal}
                                    isDetailPage={true}
                                    shipmentProductModel={shipmentProductModel}
                                />
                            </Card>

                            <Card
                                styles={{
                                    body: {
                                        padding: 0,
                                    },
                                }}
                            >
                                {shipmentProductModel.getPackages() && (
                                    <ShipmentProductPackages
                                        shipmentNumber=""
                                        customerId={shipmentProductModel.getCustomerId()}
                                        productSource={shipmentProductModel.getProductSource()}
                                        productUrl={
                                            shipmentProductModel.getLink() || ""
                                        }
                                        regionCode={shipmentProductModel.getRegionCode()}
                                        shipmentProductId={shipmentProductModel.getId()}
                                        packages={shipmentProductModel.getPackages()}
                                        unitType="KG"
                                    />
                                )}
                            </Card>

                            <Card title={"Shipping"}>
                                <ShipmentProductShippingInfo
                                    isDetailPage={true}
                                    shipmentProductModel={shipmentProductModel}
                                    handleModal={handleModal}
                                />
                            </Card>

                            <Card
                                styles={{
                                    body: {
                                        paddingTop: 0,
                                    },
                                }}
                                title="Invoice"
                            >
                                <Invoice
                                    customerInvoiceId={shipmentProductModel.getCustomerInvoiceId()}
                                    agentInvoiceId={shipmentProductModel.getAgentInvoiceId()}
                                />
                            </Card>
                            <Card
                                styles={{
                                    body: {
                                        padding: 0,
                                    },
                                }}
                            >
                                <BidTable
                                    bids={shipmentProductModel.getBids()}
                                />
                            </Card>
                            <Card
                                styles={{
                                    body: {
                                        paddingTop: 0,
                                    },
                                }}
                                title="Delivery Request"
                            >
                                <DeliveryRequest
                                    deliveryEnrollment={shipmentProductModel.getDeliveryEnrollments()}
                                />
                            </Card>
                        </Flex>
                    </Col>
                    <Col style={colStyle} xs={24} lg={12}>
                        <Flex vertical gap={24}>
                            {shipmentProductModel?.getOrder().order_handler && (
                                <Card title="Order Handler">
                                    <OrderHandlerInfo
                                        orderHandler={
                                            shipmentProductModel?.getOrder()
                                                .order_handler
                                        }
                                    />
                                </Card>
                            )}

                            {shipmentProductModel.order.customer && (
                                <Card
                                    title="Customer"
                                    extra={
                                        <div>
                                            {checkActionPermission(
                                                ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_IMPERSONATE_CREATE,
                                                <Tooltip title="Click to impersonate this customer">
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => {
                                                            setShouldFetchImpersonateData(
                                                                true,
                                                            );
                                                            setLoadingImpersonate(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    [shipmentProductModel.getId()]:
                                                                        true,
                                                                }),
                                                            );
                                                            setSelectedCustomerUser(
                                                                shipmentProductModel.user,
                                                            );
                                                            onFetchFilterApi(
                                                                filterResourceEnum.ADMIN,
                                                                {
                                                                    keyword:
                                                                        email,
                                                                    ...responseTypeQuery.minimal,
                                                                },
                                                            );
                                                        }}
                                                        loading={
                                                            loadingImpersonate[
                                                                shipmentProductModel.getId()
                                                            ]
                                                        }
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            justifyContent:
                                                                "center",
                                                            border: "1px solid #FD384F",
                                                            borderRadius: "50%",
                                                            backgroundColor:
                                                                "#FFF8F9",
                                                        }}
                                                        icon={
                                                            impersonateLoading ? (
                                                                <LoadingOutlined
                                                                    style={{
                                                                        fontSize: 18,
                                                                        color: "#FD384F",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <ImpersonateIcon
                                                                    width={25}
                                                                    height={15}
                                                                    style={{
                                                                        marginTop:
                                                                            -5,
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    />
                                                </Tooltip>,
                                                null,
                                            )}
                                        </div>
                                    }
                                >
                                    <CustomerInfo
                                        user={shipmentProductModel
                                            .getOrder()
                                            .getCustomer()}
                                    />
                                </Card>
                            )}

                            {shipmentProductModel.getOrder() && (
                                <Card title="Order Info">
                                    <OrderInfo
                                        oderNumber={shipmentProductModel
                                            .getOrder()
                                            .getOrderNumber()}
                                        oderStatus={shipmentProductModel
                                            .getOrder()
                                            .getOrderStatus()}
                                        oderType={shipmentProductModel
                                            .getOrder()
                                            .getOrderType()}
                                        orderCreatedAt={shipmentProductModel
                                            .getOrder()
                                            .getCreatedAt()}
                                        paymentStatus={shipmentProductModel
                                            .getOrder()
                                            .getPaymentStatus()}
                                    />
                                </Card>
                            )}

                            {shipmentProductModel?.getBuyProduct() && (
                                <Card title="Buy Product Info">
                                    <BuyProductInfo
                                        isDetailPage={false}
                                        buyProduct={shipmentProductModel.getBuyProduct()}
                                    />
                                </Card>
                            )}

                            {shipmentProductModel.getImages() && (
                                <Card title="Images">
                                    <Image.PreviewGroup>
                                        <Row gutter={[12, 12]} wrap={true}>
                                            {shipmentProductModel
                                                .getImages()
                                                .map((image) => {
                                                    return (
                                                        <Col
                                                            key={image.id}
                                                            span={2}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: 50,
                                                                    height: 50,
                                                                    overflow:
                                                                        "hidden",
                                                                }}
                                                            >
                                                                <ImageWithPlaceholder
                                                                    src={`${image.src}`}
                                                                    style={{
                                                                        objectFit:
                                                                            "cover",
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                        </Row>
                                    </Image.PreviewGroup>
                                </Card>
                            )}

                            {shipmentProductModel &&
                                shipmentProductModel.getBuyProduct() && (
                                    <Card title="Charges">
                                        <BuyProductCharges
                                            isListPage={false}
                                            buyProductId={shipmentProductModel
                                                .getBuyProduct()
                                                ?.getId()}
                                            originalCurrencyCode={shipmentProductModel
                                                .getRegion()
                                                ?.getCode()}
                                            buyProductCharges={shipmentProductModel.getCharges()}
                                        />
                                    </Card>
                                )}

                            {checkActionPermission(
                                [
                                    TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_TIMELINE_VIEW,
                                ],
                                <TimelineTracking
                                    TimelineTrackingData={
                                        timelineTrackingData &&
                                        new TimelineTrackingModel(
                                            timelineTrackingData,
                                        )
                                    }
                                    productId={shipmentProductModel.getId()}
                                    productNumber={shipmentProductModel.getProductNumber()}
                                    isLoading={isLoadingTimelineTracking}
                                    handleTracking={handleTracking}
                                    title="Timeline"
                                />,
                                null,
                            )}
                        </Flex>
                    </Col>
                </Row>
            )}

            <ShippingInfoChangeModals
                modalOpenType={modalOpenType}
                onCancel={onCancel}
                selectedProduct={shipmentProductModel}
                selectedProductIds={
                    shipmentProductModel ? [shipmentProductModel.getId()] : []
                }
            />

            {categoryPriceDetailsInfo && shipmentProductModel && (
                <SelectWarehouseModal
                    categoryPriceDetails={categoryPriceDetailsInfo}
                    selectedProductIds={[shipmentProductModel.getId()]}
                    selectedProducts={[shipmentProductModel]}
                    onCancel={() => {
                        setCategoryPriceDetailsInfo(undefined);
                        setShowAgentAssignModal(false);
                    }}
                    open={showAgentAssignModal}
                />
            )}
            <Modal
                open={Boolean(selectedBidProduct)}
                onCancel={() => setSelectedBidProduct(undefined)}
                destroyOnClose={true}
                footer={null}
                width={"90%"}
                title="Bid List"
            >
                {selectedBidProduct && (
                    <BidList shipmentProductModel={selectedBidProduct} />
                )}
            </Modal>

            <Modal
                title="Attach Carton Image"
                open={modalOpenType === "attach_images_shipment_product"}
                centered
                destroyOnClose={true}
                okText="Attach"
                onOk={handleAttachCartonImage}
                onCancel={onCancel}
                okButtonProps={{
                    loading: isLoadingAttachShipmentProductPackageImage,
                }}
            >
                <MultiChunkUpload fieldName="images" form={form} />
            </Modal>
            {isShowModal && impersonateData && (
                <Modal
                    title={
                        <ImpersonateCreateModalTitle
                            name={selectedCustomerUser?.name || ""}
                            email={selectedCustomerUser?.email || ""}
                            title="Impersonate Customer"
                        />
                    }
                    open={isShowModal}
                    onCancel={() => setIsShowModal(false)}
                    footer={null}
                    width={600}
                >
                    <ImpersonateResultView response={impersonateData} />
                </Modal>
            )}
            <Modal
                width={"40%"}
                open={modalOpenType === "reject_shipment_product"}
                onCancel={onCancel}
                footer={null}
                destroyOnClose={true}
                title={"Confirm Reject"}
            >
                {selectedShipmentProduct && (
                    <RejectProductModal
                        onClose={onCancel}
                        handleOk={handleRejectProduct}
                        loading={isRejectedLoading}
                    />
                )}
            </Modal>

            <Modal
                width={"50%"}
                title="Pay Customer Invoice"
                open={
                    modalOpenType === "pay-invoice" &&
                    Boolean(selectedPayInvoiceData)
                }
                onCancel={() => {
                    handleModal({
                        action: false,
                        data: null,
                    });
                }}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoadingPaySession }}
                centered
                destroyOnClose={true}
            >
                {selectedPayInvoiceData && (
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                )}
            </Modal>
        </PageHeader>
    );
};
