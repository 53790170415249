import { useEffect, useRef, useState } from "react";
import { useGetBaseShippingCategory } from "@/lib/core-react/hooks/private";
import React from "react";
import { Button, Form, notification, TreeSelect } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { baseShippingCategoryAtom } from "@/lib/core-react/store/store";
import { useAtom } from "jotai";
import { ApiHelperModel } from "@/models/apiHelper";
import useDataFilters from "@/hooks/useDataFilters";
import { IBaseShippingCategoryMappingForm } from "@/types/inventoryCategoryCollection";
import { useCreateBaseShippingCategoryMapping } from "@/lib/core-react/hooks/private/useProduct";
import { showError } from "@/helpers/showError";
import {
    IInventoryCategoryTreeNode,
    transformToBaseShippingCategoryTreeData,
} from "../utils";
import { debounce } from "lodash";

interface IProps {
    setIsShowCategoryMappingModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    selectedCategory?: IInventoryCategoryTreeNode;
    isBulk?: boolean;
    selectedRows?: React.Key[];
    setSelectedRows?: React.Dispatch<React.SetStateAction<React.Key[]>>;
    setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const AttachCategory = ({
    setIsShowCategoryMappingModal,
    selectedCategory,
    isBulk,
    selectedRows,
    setSelectedRows,
    setShouldRefetch,
}: IProps) => {
    const [{ data: baseShippingData, isLoading, refetch }] = useAtom(
        baseShippingCategoryAtom,
    );
    const [baseShippingCategoryName, setBaseShippingCategoryName] =
        useState("");

    const { getBaseShippingCategory } = useGetBaseShippingCategory();
    const { createBaseShippingCategoryMapping, isLoading: isMappingLoading } =
        useCreateBaseShippingCategoryMapping();

    const { filters, isFirstCall, isFetched } = useDataFilters();

    const debouncedBaseShippingSearch = useRef(
        debounce((value) => {
            setBaseShippingCategoryName(value);
        }, 500), // 500ms delay
    ).current;

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(
                {
                    name: baseShippingCategoryName,
                    per_page: 100,
                },
                getBaseShippingCategory,
            );
        }
    }, [isFirstCall, filters, isFetched, refetch, baseShippingCategoryName]);

    const formRef = useRef<FormInstance>(null);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value) => {
        try {
            if (!isBulk && selectedCategory) {
                const payload: IBaseShippingCategoryMappingForm = {
                    base_shipping_category_id: value.base_shipping_category_id,
                    inventory_category_ids: [selectedCategory.id],
                };
                await createBaseShippingCategoryMapping(payload);
                setIsShowCategoryMappingModal(false);
                form.resetFields();
                notification["success"]({
                    message:
                        "Base Shipping Category Mapping Created Successfully",
                });
                setShouldRefetch(true);
            }
            if (isBulk && selectedRows) {
                const payload: IBaseShippingCategoryMappingForm = {
                    base_shipping_category_id: value.base_shipping_category_id,
                    inventory_category_ids: [...selectedRows] as string[],
                };
                await createBaseShippingCategoryMapping(payload);
                setIsShowCategoryMappingModal(false);
                form.resetFields();
                if (setSelectedRows) {
                    setSelectedRows([]);
                }
                notification["success"]({
                    message:
                        "Base Shipping Category Mapping Created Successfully",
                });
                setShouldRefetch(true);
            }
        } catch (error) {
            showError(error, form);
        }
    };

    const onValuesChange = (changedValues: any) => {
        form.setFieldsValue(changedValues);
    };

    // Transform the data for the TreeSelect component
    const treeBaseShippingCategory = baseShippingData?.data
        ? transformToBaseShippingCategoryTreeData(baseShippingData.data)
        : [];

    return (
        <div>
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                name="base_shipping_category_mapping_form"
                ref={formRef}
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Select a Base Shipping Category",
                        },
                    ]}
                    name="base_shipping_category_id"
                    label="Select Base Shipping Category"
                >
                    <TreeSelect
                        placeholder="Please select a category"
                        treeData={treeBaseShippingCategory}
                        loading={isLoading}
                        allowClear
                        showSearch
                        treeDefaultExpandedKeys={["1"]}
                        // filterTreeNode={(input, option) => {
                        //     if (!option.title) return false;
                        //     return `${option.title}`
                        //         .toLowerCase()
                        //         .includes(input.toLowerCase());
                        // }}
                        onSearch={(value) => {
                            debouncedBaseShippingSearch(value);
                        }}
                        filterTreeNode={false}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={isMappingLoading || isLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AttachCategory;
