import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { IDiscountFormData } from "@/types/discountCollection";

class DiscountResource extends BaseResource {
    discountsPath = `/api/discount/admin/discount/v1/discounts`;
    discountGroupPath = `/api/discount/admin/discount-group/v1/discount-groups`;
    discountRuleSchemaPath = `/api/discount/admin/discount-rule/v1/discounts-rule-schema`;
    discountUsageHistoryPath = `/api/discount/admin/discount-usage-history/v1/discount-usage-histories`;

    // Discounts
    getDiscounts(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.discountsPath}/?expand=discountRules,region,discountGroup`;
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    getDiscount(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.discountsPath}/${id}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    createDiscount(
        payload: IDiscountFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.discountsPath}/`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updateDiscount(
        id: number,
        payload: IDiscountFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.discountsPath}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    deleteDiscount(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.discountsPath}/${id}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }

    // Discount group
    getDiscountGroups(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.discountGroupPath}`;
        if (params) {
            path = `${path}?${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    // Discount rule
    getDiscountRuleSchema(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.discountRuleSchemaPath}`;
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    // Discount usage history
    getDiscountUsageHistories(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.discountUsageHistoryPath}/?expand=region,user,discount,invoice`;
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default DiscountResource;
