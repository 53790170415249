import { CalculatorOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link } from "react-router-dom";

const Homepage = () => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "0px 20px",
            }}
        >
            <h1>Welcome To Homepage</h1>

            <Button
                type="primary"
                size="middle"
                icon={<CalculatorOutlined />}
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Link
                    to="/service/tools/cost-calculator"
                    style={{
                        color: "inherit",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    Go To Cost Calculator
                </Link>
            </Button>
        </div>
    );
};

export default Homepage;
