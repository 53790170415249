import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";

import { CustomerInvoiceModel } from "@/models/buyProductCollectionModel";
import { EyeOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Flex, message, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface InvoiceDetailsProps {
    invoice: CustomerInvoiceModel | undefined | null;
    requestFor: "customer" | "agent";
    label: string;
    linkPath: string;
}

import * as Sentry from "@sentry/browser";
import { showError } from "@/helpers/showError";

const downloadInvoiceKey = "downloadInvoiceKey";
const InvoiceInfoTitle: React.FC<InvoiceDetailsProps> = ({
    invoice,
    requestFor,
    label,
    linkPath,
}) => {
    const { handledownloadData } = useInvoice();
    const [isDownloadingInvoice, setIsDownloadingInvoice] = useState(false);

    const handleDownloadInvoice = async (
        invoiceId: number,
        requestFor: "customer" | "agent",
    ) => {
        try {
            setIsDownloadingInvoice(true);
            if (!invoiceId) {
                throw new Error("No customer invoice id found");
            }

            message.loading({
                content: "Generating Invoice PDF...",
                key: downloadInvoiceKey,
            });

            const response = await handledownloadData(invoiceId, requestFor);
            message.destroy(downloadInvoiceKey);
            setIsDownloadingInvoice(false);
            if (!response?.data?.download_url) {
                throw new Error("Download URL not found in response");
            }

            const downloadUrl = response.data.download_url;
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = "";
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            setIsDownloadingInvoice(false);
            message.destroy(downloadInvoiceKey);
            showError(error);
            Sentry.captureException(error);
        }
    };

    return (
        <Flex justify="space-between">
            {checkActionPermission(
                [
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
                ],

                <>
                    {invoice ? (
                        <Link
                            to={`${linkPath}`}
                            target="_blank"
                            style={{
                                display: "flex",
                                gap: "4px",
                                alignItems: "center",
                            }}
                        >
                            <Typography.Link>{label}</Typography.Link>

                            <EyeOutlined />
                        </Link>
                    ) : (
                        <Typography.Text>{label}</Typography.Text>
                    )}
                </>,
                null,
            )}

            {checkActionPermission(
                [
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
                ],

                <>
                    {invoice && (
                        <Button
                            loading={isDownloadingInvoice}
                            type="dashed"
                            onClick={() =>
                                handleDownloadInvoice(
                                    invoice.getId() as number,
                                    requestFor,
                                )
                            }
                        >
                            <PrinterOutlined width={24} height={24} />
                        </Button>
                    )}
                </>,
                null,
            )}
        </Flex>
    );
};

export default InvoiceInfoTitle;
