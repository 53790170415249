import usePageQuery from "@/helpers/usePageQuery";
import { Result, Button } from "antd";
import { ExceptionStatusType } from "antd/es/result";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
    const { getCurrentQuery } = usePageQuery();
    const navigate = useNavigate();
    const errorData = getCurrentQuery();

    return (
        <Result
            status={errorData.code as ExceptionStatusType}
            title={errorData.message as ExceptionStatusType}
            extra={
                <Button onClick={() => navigate(-2)} type="primary">
                    Go Back
                </Button>
            }
        />
    );
};
export default ErrorPage;
