export enum DestinationWarehouseEnum {
    DestinationWarehouseCollection = "DestinationWarehouseCollection",
    DestinationWarehouse = "DestinationWarehouse",
}

export enum DestinationWarehouseStatusEnum {
    Active = "active",
    Inactive = "inactive",
}

export enum DestinationWarehouseMemberStatus {
    Active = "active",
    Inactive = "inactive",
}
