import { useService } from "../../contexts";
import { useState } from "react";
import { useAtom } from "jotai";
import { regionCountry } from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import { ICountryUpdateRequest } from "@/types/country";

export const useCreateCountry = () => {
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [regionCountryData, setRegionCountryData] = useAtom(regionCountry);
    const createCountry = async (payload: ICountryUpdateRequest) => {
        setIsLoading(true);
        try {
            const response = await coreService.countryResource.create(payload);
            setIsLoading(false);
            setRegionCountryData({ ...regionCountryData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { createCountry, isLoading, isError };
};

export const useUpdateCountry = () => {
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [regionCountryData, setRegionCountryData] = useAtom(regionCountry);
    const updateCountry = async (
        id: number,
        payload: ICountryUpdateRequest,
    ) => {
        setIsLoading(true);
        try {
            const response = await coreService.countryResource.update(
                id,
                payload,
            );
            setIsLoading(false);
            setRegionCountryData({ ...regionCountryData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateCountry, isLoading, isError };
};

export const useGetCountry = () => {
    const [country, setCountry] = useAtom(regionCountry);
    const { coreService } = useService();
    const getCountry = async (params?: string): Promise<void> => {
        setCountry({ ...country, isLoading: true, error: null });
        try {
            const response = await coreService.countryResource.get(params);
            setCountry({
                ...country,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setCountry({
                ...country,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return { getCountry } as const;
};
