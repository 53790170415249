import { InputNumber, Row, Col, Typography } from "antd";

const { Text } = Typography;

const NumberRangeInput = ({ name, handleChange, fieldValue }) => {
    const min = 0;
    const max = 1000000;

    const currentMin = fieldValue ? fieldValue[0] : undefined;
    const currentMax = fieldValue ? fieldValue[1] : undefined;

    const onMinChange = (value: number | null) => {
        if (value !== null) {
            const newRange = [value, currentMax];
            handleChange({ [name]: newRange });
        }
    };

    const onMaxChange = (value: number | null) => {
        if (value !== null) {
            const newRange = [currentMin, value];
            handleChange({ [name]: newRange });
        }
    };

    return (
        <div>
            <Row gutter={16} align="middle" justify="center">
                <Col span={12}>
                    <Text strong>Min:</Text>
                    <InputNumber
                        min={min}
                        max={max}
                        value={currentMin}
                        onChange={onMinChange}
                        placeholder="Min"
                        style={{ marginLeft: 8 }}
                    />
                </Col>
                <Col span={12}>
                    <Text strong>Max:</Text>
                    <InputNumber
                        min={min}
                        max={max}
                        value={currentMax}
                        onChange={onMaxChange}
                        placeholder="Max"
                        style={{ marginLeft: 8 }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default NumberRangeInput;
