import { VariantMismatchModel } from "@/models/buyActionCollectionModel";
import { Typography } from "antd";

const PropertyVariation = (mismatch: VariantMismatchModel) => {
    const { Text } = Typography;
    const productImage = mismatch
        .getBuyProductVariation()
        ?.getProperties()
        .getData()
        .find(
            (item) =>
                item.getPropertyValueImage() !== null ||
                item.getPropertyValueThumb() !== null,
        );

    const properties =
        mismatch.getBuyProductVariation()?.getProperties().getData() || [];

    return (
        <div style={{ display: "flex", gap: "8px" }}>
            <div>
                <img
                    src={
                        productImage?.getPropertyValueImage() ||
                        productImage?.getPropertyValueThumb() ||
                        ""
                    }
                    alt="Image"
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "4px",
                    }}
                />
            </div>
            <div>
                {properties.map((property, idx) => (
                    <div
                        key={property.getId() + idx}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: 4,
                        }}
                    >
                        <Text
                            style={{
                                color: property.getPropertyName()
                                    ? "#28a745"
                                    : "#bbb",
                            }}
                        >
                            {property.getPropertyName() ||
                                property.getPropertyOriginalName()}
                            :
                        </Text>
                        <Text>
                            {property.getPropertyValueName() ||
                                property.getPropertyValueTitle() ||
                                ""}
                        </Text>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default PropertyVariation;
