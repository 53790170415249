import { UiNodeAnchorAttributes } from "@ory/client";
import { Button } from "antd";

interface Props {
    attributes: UiNodeAnchorAttributes;
}

export const NodeAnchor = ({ attributes }: Props) => {
    return (
        <Button
            data-testid={`node/anchor/${attributes.id}`}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                window.location.href = attributes.href;
            }}
        >
            {attributes.title.text}
        </Button>
    );
};
