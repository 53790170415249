import React, { useState, useEffect } from "react";
import { Card, Switch, Collapse, List, Typography, Spin } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./draggable.css";

const { Panel } = Collapse;
const { Text } = Typography;

export interface FilterItem {
    key: string;
    label: string;
    visible: boolean;
    serial?: number;
}

interface FilterSettingsProps {
    heading: string;
    storageKey: string;
    initialFilters: FilterItem[];
    onFiltersChange?: (filters: FilterItem[]) => void;
    className?: string;
    defaultCollapsed?: boolean;
}

const FilterSettings: React.FC<FilterSettingsProps> = ({
    heading,
    storageKey,
    initialFilters,
    onFiltersChange,
    className = "",
    defaultCollapsed = false,
}) => {
    const [filters, setFilters] = useState<FilterItem[]>([]);
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);
    const [dragOverIndex, setDragOverIndex] = useState<number | null>(null);
    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => {
        try {
            const savedFilters = localStorage.getItem(storageKey);
            if (savedFilters && JSON.parse(savedFilters).length > 0) {
                const parsedFilters = JSON.parse(savedFilters);

                // Add missing keys from initialFilters to parsedFilters
                const updatedFilters = (initialFilters || []).map(
                    (filter, index) => {
                        const existingFilter = parsedFilters.find(
                            (savedFilter) => savedFilter.key === filter.key,
                        );
                        return (
                            existingFilter || { ...filter, serial: index + 1 }
                        ); // Use existing or initialize new
                    },
                );

                // Ensure all filters have serial numbers
                const filtersWithSerials = updatedFilters.map(
                    (filter, index) => ({
                        ...filter,
                        serial: index + 1,
                    }),
                );

                setFilters(filtersWithSerials);
                localStorage.setItem(
                    storageKey,
                    JSON.stringify(filtersWithSerials),
                );
            } else {
                // Initialize filters if localStorage is empty or invalid
                const initializedFilters = (initialFilters || []).map(
                    (filter, index) => ({
                        ...filter,
                        serial: index + 1,
                    }),
                );
                setFilters(initializedFilters);
                localStorage.setItem(
                    storageKey,
                    JSON.stringify(initializedFilters),
                );
            }
        } catch (_error) {
            // Fallback to initialFilters if an error occurs
            const initializedFilters = (initialFilters || []).map(
                (filter, index) => ({
                    ...filter,
                    serial: index + 1,
                }),
            );
            setFilters(initializedFilters);
            localStorage.setItem(
                storageKey,
                JSON.stringify(initializedFilters),
            );
        }
        setIsInitialized(true);
    }, [storageKey, initialFilters]);

    useEffect(() => {
        if (isInitialized && filters.length > 0) {
            try {
                localStorage.setItem(storageKey, JSON.stringify(filters));
                onFiltersChange?.(filters);
            } catch (error) {
                console.error("Error saving filters:", error);
            }
        }
    }, [filters, storageKey, onFiltersChange, isInitialized]);

    const moveItem = (fromIndex: number, toIndex: number) => {
        const updatedFilters = [...filters];
        const [removed] = updatedFilters.splice(fromIndex, 1);
        updatedFilters.splice(toIndex, 0, removed);

        return updatedFilters.map((filter, index) => ({
            ...filter,
            serial: index + 1,
        }));
    };

    const handleDragStart = (e: React.DragEvent, index: number) => {
        e.dataTransfer.effectAllowed = "move";
        setDraggedIndex(index);
        // Add visual feedback
        const target = e.target as HTMLElement;
        const card = target.closest(".filter-card");
        if (card) {
            card.classList.add("dragging");
        }
    };

    const handleDragOver = (e: React.DragEvent, index: number) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
        setDragOverIndex(index);
    };

    const handleDrop = (e: React.DragEvent, index: number) => {
        e.preventDefault();
        if (draggedIndex !== null) {
            setFilters(moveItem(draggedIndex, index));
            // Remove visual feedback
            const cards = document.querySelectorAll(".filter-card");
            cards.forEach((card) => card.classList.remove("dragging"));
        }
        setDraggedIndex(null);
        setDragOverIndex(null);
    };

    const handleDragEnd = () => {
        // Remove visual feedback
        const cards = document.querySelectorAll(".filter-card");
        cards.forEach((card) => card.classList.remove("dragging"));
        setDraggedIndex(null);
        setDragOverIndex(null);
    };

    const toggleVisibility = (key: string) => {
        const updatedFilters = filters.map((filter) =>
            filter.key === key
                ? { ...filter, visible: !filter.visible }
                : filter,
        );
        setFilters(updatedFilters);
    };

    const header = (
        <div className="filter-header">
            <span>{heading}</span>
            <Text type="secondary">
                {filters.filter((f) => f.visible).length} of {filters.length}{" "}
                visible
            </Text>
        </div>
    );

    if (!isInitialized) {
        return (
            <div className="filter-loading">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className={`filter-settings ${className}`}>
            <Collapse defaultActiveKey={defaultCollapsed ? [] : ["1"]}>
                <Panel header={header} key="1">
                    <List
                        dataSource={filters}
                        renderItem={(filter, index) => (
                            <Card
                                size="small"
                                className={`filter-card ${dragOverIndex === index ? "dragging" : ""}`}
                                draggable
                                onDragStart={(e) => handleDragStart(e, index)}
                                onDragOver={(e) => handleDragOver(e, index)}
                                onDrop={(e) => handleDrop(e, index)}
                                onDragEnd={handleDragEnd}
                            >
                                <div className="filter-card-content">
                                    <MenuOutlined className="drag-handle" />
                                    <div className="filter-info">
                                        <div className="filter-details">
                                            <Text strong>{filter.label}</Text>
                                            <Text type="secondary">
                                                Serial: {filter.serial}
                                            </Text>
                                        </div>
                                        <Switch
                                            checked={filter.visible}
                                            onChange={() =>
                                                toggleVisibility(filter.key)
                                            }
                                        />
                                    </div>
                                </div>
                            </Card>
                        )}
                    />
                </Panel>
            </Collapse>
        </div>
    );
};

export default FilterSettings;
