import { Button, Form, Input, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import React, { useEffect, createRef } from "react";
import { storeExchangeAtom } from "@/lib/core-react/store/store";
import { useUpdateExchangeGroup } from "@/lib/core-react/hooks/private/useExchangeGroup";
import { useGetExchange } from "@/lib/core-react/hooks/private/useExchange";
import { ExchangeGroupModel } from "@/models/exchangeGroupCollectionModel";
import { ExchangeListCollectionModel } from "@/models/exchangeListCollectionModel";
import { IExchangeGroupPostRequest } from "@/types/exchangeGroupCollection";
import { showError } from "@/helpers/showError";

interface IProps {
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedExchangeGroup: ExchangeGroupModel;
}

const UpdateExchangeGroup = ({
    setIsShowUpdateModal,
    selectedExchangeGroup,
}: IProps) => {
    const { getExchange } = useGetExchange();
    const [{ data: exchangeDataAtom, isLoading: exchangeLoading }] =
        useAtom(storeExchangeAtom);

    const { updateExchangeGroup, isLoading } = useUpdateExchangeGroup();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [selectedExchangeGroup, form]);

    useEffect(() => {
        getExchange({ per_page: 200 });
    }, []);

    const exchangeListCollectionData =
        exchangeDataAtom && new ExchangeListCollectionModel(exchangeDataAtom);

    const onFinish = async (value: IExchangeGroupPostRequest) => {
        const payload = {
            name: value.name,
            exchange_list_id: value.exchange_list_id,
        };
        try {
            const res = await updateExchangeGroup(
                selectedExchangeGroup.id,
                payload as IExchangeGroupPostRequest,
            );
            if (res) {
                setIsShowUpdateModal(false);
                notification.success({
                    message: res.message,
                });
            }
        } catch (e: any) {
            showError(e, form);
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update-Exchange-Group"
            ref={formRef}
            initialValues={{
                name: selectedExchangeGroup.getName(),
                exchange_list_id: selectedExchangeGroup
                    .getExchangeList()
                    .getId(),
            }}
            style={{ padding: "15px 10px  0px 10px" }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Exchange group name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Exchange group name" />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "Exchange list is required" },
                ]}
                name="exchange_list_id"
                label="Exchange List"
            >
                <Select
                    loading={exchangeLoading}
                    placeholder="Please select an exchange list"
                    options={exchangeListCollectionData?.getSelectComponentSelectOptions()}
                />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateExchangeGroup;
