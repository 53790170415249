import { IPagination } from "@/types/pagination";

export class PaginationModel {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
    constructor(data: IPagination) {
        this.current_page = data.current_page;
        this.from = data.from;
        this.last_page = data.last_page;
        this.per_page = data.per_page;
        this.to = data.to;
        this.total = data.total;
    }

    getCurrentPage() {
        return this.current_page;
    }
    getFrom() {
        return this.from;
    }
    getLastPage() {
        return this.last_page;
    }
    getPerPage() {
        return this.per_page;
    }
    getTo() {
        return this.to;
    }
    getTotal() {
        return this.total;
    }
}
