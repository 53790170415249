import { Button, Form, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import React, { useEffect, createRef } from "react";
import { useAddExchangeGroupCustomer } from "@/lib/core-react/hooks/private/useExchangeGroup";

import { IExchangeGroupUserRequest } from "@/types/exchangeGroupCollection";
import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import { showError } from "@/helpers/showError";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";

interface IProps {
    setIsShowAddCustomerModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCustomerUser: CustomerUserModel;
}

const AddExchangeGroupCustomer = ({
    setIsShowAddCustomerModal,
    selectedCustomerUser,
}: IProps) => {
    const {
        allOptionsData: { exchangeGroupOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { addExchangeGroupCustomer, isLoading } =
        useAddExchangeGroupCustomer();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [selectedCustomerUser, form]);

    const onFinish = async (value: IExchangeGroupUserRequest) => {
        const payload = {
            user_id: selectedCustomerUser.getId(),
            exchange_group_id: Number(value.exchange_group_id),
        };

        try {
            const res = await addExchangeGroupCustomer(
                payload as IExchangeGroupUserRequest,
            );
            if (res) {
                setIsShowAddCustomerModal(false);
                notification.success({
                    message: res.message,
                });
            }
        } catch (error: any) {
            showError(error, form);
        }
    };
    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Add-Exchange-Group-Customer"
            ref={formRef}
        >
            <div style={{ margin: "36px 0px" }}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Exchange group is required",
                        },
                    ]}
                    name="exchange_group_id"
                    label="Exchange Group"
                >
                    <Select
                        allowClear
                        onFocus={() =>
                            onFetchFilterApi(filterResourceEnum.EXCHANGE_GROUP)
                        }
                        onClear={() =>
                            onFetchFilterApi(filterResourceEnum.EXCHANGE_GROUP)
                        }
                        loading={exchangeGroupOptionsData.isLoading}
                        placeholder="Please select an exchange group"
                    >
                        {exchangeGroupOptionsData.isLoading
                            ? LoaderSmall
                            : exchangeGroupOptionsData.options.map((data) => (
                                  <Select.Option
                                      key={`${data.label}-${data.value}`}
                                      value={data.value}
                                  >
                                      {data.label}
                                  </Select.Option>
                              ))}
                    </Select>
                </Form.Item>
            </div>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddExchangeGroupCustomer;
