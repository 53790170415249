import { ITranslationEntry } from "../lang";

export const FA: ITranslationEntry = {
    translations: {
        notifications: "اطلاعیه",
        markAllAsRead: "همه را به عنوان خوانده شده علامت بزن",
        poweredBy: "پشتیبانی شده توسط",
        settings: "تنظيمات",
        noNewNotification: "هنوز چیز جدیدی برای دیدن اینجا وجود ندارد",
    },
    lang: "fa",
};
