import { Config } from "../request/types/request";
import { ServiceBaseUrl, ServiceNameEnum } from "./servicBaseUrl";
class CmsApiService {
    public config: Config;

    constructor() {
        this.config = {
            baseUrl: ServiceBaseUrl.getServiceBaseUrl(
                ServiceNameEnum.CMS_STRAPI_SERVICE,
            ),
            apiKey: "",
            maxRetries: 0,
            publishableApiKey: "",
        };
    }
}

export default CmsApiService;
