export const StatusEnum = {
    CREATED: "created",
    PRE_PROCESSED: "pre-processed",
    PROCESSING: "processing",
    COMPLETED: "completed",
    CANCELLED: "cancelled",
    FAILED: "failed",
};

export const UserTypeEnums = {
    AGENT: "agent",
    ADMIN: "admin",
    DW_ADMIN: "dw-admin",
};

export enum HarvestActionEnums {
    CUSTOMER_INVOICE = "customer-invoice",
    ADMIN_BUY_PRODUCT_EXPORT_HARVEST = "admin-buy-product-export-harvest",
    SHIPMENT_BUY_PRODUCT_EXPORT_HARVEST = "admin-shipment-product-export-harvest",
    MINIMAL_CUSTOMER_INVOICE = "minimal-customer-invoice",
    ADMIN_AGENT_INVOICE_SETTLE_HARVEST = "admin-agent-invoice-settle-harvest",
}
