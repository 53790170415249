import { Card, Table, Tag, Typography, Image } from "antd";
import React from "react";
import { StatusTag } from "@/components";
import { InvoiceModel } from "@/models/invoiceCollectionModel";
import { formatString } from "@/utils/helper";

interface InvoiceItemProps {
    invoice: InvoiceModel;
}

const { Text } = Typography;

const InvoiceItem: React.FC<InvoiceItemProps> = ({ invoice }) => {
    const currency = invoice.getRegion()?.currency?.code;
    const data = invoice
        .getItems()
        ?.data.map((item, index) => {
            if (item.buy_product_variation) {
                const variation = item.buy_product_variation;
                return {
                    key: index,
                    type: "buy_product_variation",
                    id: variation?.id,
                    image: variation?.image,
                    quantity: variation?.quantity,
                    unitPrice:
                        currency + " " + variation?.unit_price.toFixed(2),
                    totalPrice:
                        currency + " " + variation?.total_price.toFixed(2),
                    originalUnitPrice:
                        currency +
                        " " +
                        variation?.original_unit_price.toFixed(2),
                    originalTotalPrice:
                        currency +
                        " " +
                        variation?.original_total_price.toFixed(2),
                    properties: variation?.properties?.data.map((prop) => (
                        <div
                            key={prop.property_name}
                        >{`${prop.property_name}: ${prop.property_value_name}`}</div>
                    )),
                    totalAmount: currency + " " + item.total_amount.toFixed(2),
                    initialAmount:
                        currency + " " + item.total_amount.toFixed(2),
                    refundableAmount:
                        currency + " " + item.refundable_amount.toFixed(2),
                    status: item.status,
                    createdAt: item.created_at,
                };
            } else if (item.shipment_product_package) {
                const shipmentPackage = item.shipment_product_package;
                return {
                    key: index,
                    type: "shipment_product_package",
                    id: shipmentPackage?.id,
                    packageNumber: shipmentPackage?.package_number,
                    quantity: shipmentPackage?.quantity,
                    weight: shipmentPackage?.weight,
                    length: shipmentPackage?.length,
                    width: shipmentPackage?.width,
                    contains: shipmentPackage?.contains,
                    fulfillmentStatus: shipmentPackage?.fulfillment_status,
                    totalAmount: currency + item.total_amount.toFixed(2),
                    refundableAmount:
                        currency + item.refundable_amount.toFixed(2),
                    status: item.status,
                    createdAt: item.created_at,
                    variations: shipmentPackage?.variations,
                };
            } else if (item.buy_product_charge) {
                const buyProductCharge = item.buy_product_charge;
                return {
                    key: index,
                    type: "buy_product_charge",
                    id: buyProductCharge?.id,
                    chargeType: buyProductCharge?.charge_type,
                    commissionRate: `${buyProductCharge?.commission_rate}%`,
                    fxRate: buyProductCharge?.fx_rate,
                    originalAmount:
                        currency + buyProductCharge?.original_amount.toFixed(2),
                    totalAmount:
                        currency + buyProductCharge?.total_amount.toFixed(2),
                    refundableAmount:
                        currency + item.refundable_amount.toFixed(2),
                    status: item.status,
                    createdAt: item.created_at,
                };
            }
            // else if (item.getShippingCharge()) {
            //     const shippingCharge = item.getShippingCharge();
            //     return {
            //         key: index,
            //         type: "shipment_product_charge",
            //         id: productCharge.id,
            //         chargeType: productCharge.charge_type,
            //         commissionRate: `${productCharge.commission_rate}%`,
            //         fxRate: productCharge.fx_rate,
            //         originalAmount: productCharge.original_amount,
            //         totalAmount: productCharge.total_amount,
            //         refundableAmount: item.getRefundableAmount(),
            //         status: item.getStatus(),
            //         createdAt: item.getCreatedAt(),
            //     };
            // }
            return null;
        })
        .filter((item): item is NonNullable<typeof item> => item !== null);

    // Group data by item type
    const productVariationItems = data?.filter(
        (item) => item.type === "buy_product_variation",
    );
    const productChargeItems = data?.filter(
        (item) => item.type === "buy_product_charge",
    );
    const shipmentPackageItems = data?.filter(
        (item) => item.type === "shipment_product_package",
    );
    //const shipmentChargeItems = data?.filter(item => item.type === "shipment_product_charge");

    // Define columns for each table type
    const variationColumns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (image: string) =>
                image ? (
                    <img
                        src={image}
                        alt="Product"
                        style={{ width: 50, height: 50 }}
                    />
                ) : null,
        },
        {
            title: "Properties",
            dataIndex: "properties",
            key: "properties",
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        },
        {
            title: "Prices",
            dataIndex: "prices",
            render: (_prices: number, record: any) => (
                <>
                    <Text>
                        Unit: <strong> {record.unitPrice}</strong>
                    </Text>
                    <br />
                    <Text>
                        Total: <strong> {record.totalPrice}</strong>
                    </Text>
                </>
            ),
        },
        {
            title: "Original Prices",
            dataIndex: "originalPrices",
            render: (_originalPrices: string, record: any) => (
                <>
                    <Text>
                        Unit: <strong>{record.originalUnitPrice}</strong>
                    </Text>
                    <br />
                    <Text>
                        Total: <strong>{record.originalTotalPrice}</strong>
                    </Text>
                </>
            ),
        },
        {
            title: "Amounts",
            dataIndex: "amounts",
            render: (_amounts: number, record: any) => (
                <>
                    <Text>
                        Initial: <strong>{record.initialAmount}</strong>
                    </Text>
                    <br />
                    <Text>
                        Refundable: <strong>{record.refundableAmount}</strong>
                    </Text>
                </>
            ),
        },
        {
            title: "Fulfillment Status",
            dataIndex: "status",
            key: "status",
            render: (status: string) => (
                <StatusTag text={status} slug={status} />
            ),
        },
    ];

    const chargeColumns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Charge Type",
            dataIndex: "chargeType",
            key: "chargeType",
        },
        {
            title: "FX Rate",
            dataIndex: "fxRate",
            key: "fxRate",
        },
        {
            title: "Commission Rate",
            dataIndex: "commissionRate",
            key: "commissionRate",
        },
        {
            title: "Amounts",
            dataIndex: "amounts",
            render: (_amounts: number, record: any) => (
                <>
                    <Text>
                        Original: <strong>{record.originalAmount}</strong>
                    </Text>
                    <Text>
                        Initial: <strong>{record.initialAmount}</strong>
                    </Text>
                    <br />
                    <Text>
                        Refundable: <strong>{record.refundableAmount}</strong>
                    </Text>
                </>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: string) => (
                <StatusTag text={status} slug={status} />
            ),
        },
    ];

    const packageColumns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Package Number",
            dataIndex: "packageNumber",
            key: "packageNumber",
        },
        {
            title: "Carton Properties",
            dataIndex: "details",
            key: "details",
            render: (_: string, record) => (
                <>
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                        }}
                    >
                        {/* First line */}
                        <div>
                            <Text strong>Quantity: </Text>
                            <Text>{record.quantity}</Text>
                        </div>
                        <div>
                            <Text strong>Weight: </Text>
                            <Text>{record.weight}</Text>
                        </div>
                        <div>
                            <Text strong>Length: </Text>
                            <Text>{record.length}</Text>
                        </div>
                    </div>

                    {/* Second line */}
                    <div
                        style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                            marginTop: "8px",
                        }}
                    >
                        <div>
                            <Text strong>Width: </Text>
                            <Text>{record.width}</Text>
                        </div>
                        <div>
                            <Text strong>Contains: </Text>
                            <Text>{record.contains}</Text>
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: "Fulfillment Status",
            dataIndex: "fulfillmentStatus",
            key: "fulfillmentStatus",
            render: (fulfillmentStatus: string) => (
                <Tag color="green">{formatString(fulfillmentStatus)}</Tag>
            ),
        },
        {
            title: "Total Amount",
            dataIndex: "totalAmount",
            key: "totalAmount",
        },
        // {
        //     title: "Refundable Amount",
        //     dataIndex: "refundableAmount",
        //     key: "refundableAmount",
        // },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: string) => (
                <StatusTag text={status} slug={status} />
            ),
        },
    ];

    const expandedRowRender = (record: any) => {
        // Columns for the inner table (shipmentPackageItems)
        const itemColumns = [
            {
                title: "Image",
                dataIndex: "image",
                key: "image",
                render: (_, record) => (
                    <ul>
                        {record.properties.data.map((property: any) => (
                            <li key={property.id}>
                                <Image
                                    width={50}
                                    src={property?.property_value_image}
                                    alt="Product Image"
                                />
                            </li>
                        ))}
                    </ul>
                ),
            },
            {
                title: "Variant Quantity",
                dataIndex: "quantity",
                key: "quantity",
            },
            {
                title: "Properties",
                key: "properties",
                render: (_, record) => (
                    <ul>
                        {record.properties.data.map((property: any) => (
                            <li key={property.id}>
                                {`${property.property_name}: ${property.property_value_name}`}
                            </li>
                        ))}
                    </ul>
                ),
            },
        ];

        return (
            <Table
                columns={itemColumns}
                dataSource={record.variations.data}
                pagination={false}
                rowKey="id" // Replace with appropriate key
            />
        );
    };

    return (
        <Card title="Invoice Items" style={{ margin: 10 }}>
            {/* Buy Product Variation Table */}
            {productVariationItems && productVariationItems.length > 0 && (
                <Card title="Buy Product Variations">
                    <Table
                        style={{ marginTop: 10 }}
                        dataSource={productVariationItems}
                        columns={variationColumns}
                        pagination={false}
                        scroll={{ x: 1250 }}
                    />
                </Card>
            )}

            {/* Buy Product Charge Table */}
            {productChargeItems && productChargeItems.length > 0 && (
                <Card title="Buy Product Charges">
                    <Table
                        style={{ marginTop: 10 }}
                        dataSource={productChargeItems}
                        columns={chargeColumns}
                        pagination={false}
                        scroll={{ x: 1250 }}
                    />
                </Card>
            )}

            {/* Shipment Product Package Table */}
            {shipmentPackageItems && shipmentPackageItems.length > 0 && (
                <Card title="Carton">
                    <Table
                        style={{ marginTop: 10 }}
                        dataSource={shipmentPackageItems}
                        columns={packageColumns}
                        pagination={false}
                        scroll={{ x: 1250 }}
                        expandable={{
                            expandedRowRender,
                        }}
                    />
                </Card>
            )}

            {/* Shipment Product Charge Table */}
            {/* {shipmentChargeItems && shipmentChargeItems.length > 0 && (
                <Card title="Shipment Product Charges">
                    <Table dataSource={shipmentChargeItems} columns={chargeColumns} pagination={false} />
                </Card>
            )} */}
        </Card>
    );
};

export default InvoiceItem;
