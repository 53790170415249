import { Col, Row, Space, Typography } from "antd";
import { BuyProductModel } from "@/models/buyProductCollectionModel";

const ProductDetails = ({ data }: { data: BuyProductModel }) => {
    const { Text } = Typography;
    return (
        <Row gutter={16}>
            <Col span={5}>
                <img
                    src={data?.getProductImage()}
                    alt="item"
                    style={{ width: "100%" }}
                />
            </Col>
            <Col span={19}>
                <Space direction="vertical" size={2}>
                    <span>
                        <Text strong>ID: </Text> {data?.getProductNumber()}
                    </span>
                    <span>
                        <Text strong>Title: </Text>{" "}
                        <a
                            href={data?.getProductLink()}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {data?.getProductTitle()}
                        </a>
                    </span>
                </Space>
            </Col>
        </Row>
    );
};
export default ProductDetails;
