import { Button, Col, Flex, Form, Input, notification, Row } from "antd";
import { useEffect } from "react";
import { showError } from "@/helpers/showError";
import useRefetch from "@/hooks/useRefetch";
import { useUpdateVendorOrderId } from "@/lib/core-react/hooks/private/usePurchase";
import { BuyProductModel } from "@/models/buyProductCollectionModel";

interface IProps {
    buyProduct: BuyProductModel;
    onClose: () => void;
}

const VendorOrderIdUpdateAndAddForm = ({ buyProduct, onClose }: IProps) => {
    const buyProductId = buyProduct.getId();
    const vendorOrderId =
        buyProduct?.getProductAgentAssociation()?.vendor_order_id || "";
    const [form] = Form.useForm();
    const { refetchListApi } = useRefetch();
    const { updateVendorOrderId, isLoading } = useUpdateVendorOrderId();

    useEffect(() => {
        if (!vendorOrderId) {
            form.resetFields();
        }
    }, [vendorOrderId, form]);

    const onFinish = async (values: { vendor_order_id: string }) => {
        const payload = {
            vendor_order_id: values.vendor_order_id,
        };

        try {
            await updateVendorOrderId(buyProductId, payload);
            notification.success({
                message: "Vendor Order ID updated successfully",
            });
            refetchListApi();
            onClose();
        } catch (error) {
            showError(error, form);
            onClose();
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="update_vendor_order_id"
            initialValues={{
                vendor_order_id: vendorOrderId, // Set initial value here
            }}
        >
            <Row gutter={16}>
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Vendor Order ID"
                        name="vendor_order_id"
                        rules={[
                            {
                                required: true,
                                message: "Please input a valid Vendor Order ID",
                            },
                        ]}
                    >
                        <Input placeholder="Enter Vendor Order ID" allowClear />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item noStyle>
                <Flex justify="end">
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Flex>
            </Form.Item>
        </Form>
    );
};

export default VendorOrderIdUpdateAndAddForm;
