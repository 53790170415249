import { ITranslationEntry } from "../lang";

export const AZ: ITranslationEntry = {
    translations: {
        notifications: "Bildirişlər",
        markAllAsRead: "Hamısını oxunmuş olaraq işarələ",
        poweredBy: "tərəfindən təchiz edilmişdir",
        settings: "Parametrlər",
        noNewNotification: "Hələ burada görmək üçün yeni heç nə yoxdur",
    },
    lang: "az",
};
