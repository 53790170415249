import { Button, Form, Input, notification, Select } from "antd";
import { useDestinationWarehouseInvitationSend } from "@/lib/core-react/hooks/private/useDestinationWarehouse";
import { useEffect } from "react";
import { IDestinationWarehouseMemberInvitationForm } from "@/types/destinationWarehouseCollection";
import { showError } from "@/helpers/showError";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";

interface IProps {
    destinationWarehouseId: number;
    onHide: () => void;
}

export const SendInvitation = ({ destinationWarehouseId, onHide }: IProps) => {
    const { sendInvitation, isLoading: isLoadingSend } =
        useDestinationWarehouseInvitationSend();
    const [form] = Form.useForm<IDestinationWarehouseMemberInvitationForm>();
    const {
        allOptionsData: { roleOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        form.resetFields();
    }, [destinationWarehouseId]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.AGENT_ROLE, {
            destination_warehouse_id: destinationWarehouseId,
        });
    }, [destinationWarehouseId]);

    const onFinishInvite = async (
        value: IDestinationWarehouseMemberInvitationForm,
    ) => {
        try {
            if (destinationWarehouseId) {
                await sendInvitation(destinationWarehouseId, value);
            }
            notification.success({ message: "Invitation successfully sent" });
            onHide();
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <div
            style={{ marginTop: "30px", padding: "0px 16px" }}
            className="form-container-animation"
        >
            <Form
                onFinish={onFinishInvite}
                form={form}
                layout="vertical"
                name="Send Invite"
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "User email is required",
                        },
                    ]}
                    label="User email"
                    name="user_email"
                >
                    <Input placeholder="Enter user email" />
                </Form.Item>

                <Form.Item label="Roles">
                    <Form.Item
                        rules={[
                            { required: true, message: "Role is required" },
                        ]}
                        name="role_ids"
                        noStyle
                    >
                        <Select
                            style={{ width: "100%" }}
                            loading={roleOptionsData.isLoading}
                            mode="multiple"
                            placeholder="Please select roles"
                            options={roleOptionsData.options}
                        />
                    </Form.Item>
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={isLoadingSend}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
