import { PageHeader } from "@ant-design/pro-layout";
import { Button, Form, Modal, Skeleton } from "antd";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import {
    useCreatedPayoutGateway,
    useGetPayoutGateways,
    useUpdatePayoutGateway,
} from "@/lib/core-react/hooks/private/useWallet";
import { payoutGateWayCollectionAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";
import { MenuInfo } from "rc-menu/lib/interface";
import {
    PayoutGatewayCollectionModel,
    PayoutGatewayModel,
} from "@/models/payoutGatewayCollectionModel";
import { useEffect, useState } from "react";
import { PayoutGatewayModification } from "./components/PayoutGatewayModification";
import { PaymentGatewayLogoTypeEnums } from "@/enums/paymentGatewayCollectionEnums";
import {
    IPayoutGateWayFormRequest,
    Logo,
} from "@/types/payoutGatewayCollection";
import PayoutGatewayMasterTable from "./payoutGatewaysMasterTable";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import CommonError from "@/components/Error/CommonError";
import { PlusOutlined } from "@ant-design/icons";
import { showError } from "@/helpers/showError";
import { ADMIN_WALLET_PERMISSION_ENUM } from "@/consts/permission-enum/wallet-enum";

const PayoutGateways = () => {
    const { getPayoutGateWay } = useGetPayoutGateways();
    const { createdPayoutGateway, isLoading: isLoadingCreate } =
        useCreatedPayoutGateway();
    const { updatePayoutGateway, isLoading: isLoadingUpdate } =
        useUpdatePayoutGateway();

    const [form] = Form.useForm();

    const [
        {
            data: payoutGatewayCollectionData,
            refetch,
            isLoading,
            unAuthorized,
            error,
            code,
        },
    ] = useAtom(payoutGateWayCollectionAtom);

    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

    const [selectedPayoutGateway, setSelectedPayoutGateway] = useState<
        PayoutGatewayModel | undefined
    >(undefined);

    const {
        filters,
        isFirstCall,
        isFetched,
        handleFilterChange,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest({}, getPayoutGateWay);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    const PayoutGatewayCollectionData =
        payoutGatewayCollectionData &&
        new PayoutGatewayCollectionModel(payoutGatewayCollectionData);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getPayoutGateWay,
        );
    };

    const handleMenuClick = (e: MenuInfo, record: PayoutGatewayModel) => {
        setSelectedPayoutGateway(record);
        if (e.key === "update") {
            setIsShowCreateModal(true);
        }
    };

    const onFinish = async (values) => {
        try {
            const logos: Logo[] = [];

            if (values.small_logo) {
                logos.push({
                    image_src: values.small_logo,
                    type: PaymentGatewayLogoTypeEnums.SMALL,
                });
            }

            if (values.medium_logo) {
                logos.push({
                    image_src: values.medium_logo,
                    type: PaymentGatewayLogoTypeEnums.MEDIUM,
                });
            }

            const payload: IPayoutGateWayFormRequest = {
                name: values.name,
                slug: values.slug,
                type: values.type,
                country_id: values.country,
                logos,
            };

            if (selectedPayoutGateway) {
                await updatePayoutGateway(
                    selectedPayoutGateway.getId(),
                    payload,
                );
                setSelectedPayoutGateway(undefined);
            } else {
                await createdPayoutGateway(payload);
            }
            setIsShowCreateModal(false);
        } catch (error) {
            showError(error, form);
        }
    };

    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <div>
            <PageHeader
                ghost={false}
                title="Payout Gateway"
                style={{ marginTop: "10px" }}
                onBack={() => window.history.back()}
                extra={[
                    checkActionPermission(
                        ADMIN_WALLET_PERMISSION_ENUM.ADMIN_PAYOUT_GATEWAY_MANAGE,
                        <Button
                            key="1"
                            type="primary"
                            onClick={() => setIsShowCreateModal(true)}
                            icon={<PlusOutlined />}
                        >
                            Create Cashout Gateway
                        </Button>,
                        null,
                    ),
                ]}
            >
                {PayoutGatewayCollectionData ? (
                    <PayoutGatewayMasterTable
                        isLoading={isLoading}
                        payoutGatewayCollectionData={
                            PayoutGatewayCollectionData
                        }
                        handlePaginationChange={handlePaginationChange}
                        handleMenuClick={handleMenuClick}
                    />
                ) : (
                    <Skeleton />
                )}
            </PageHeader>
            <Modal
                title={`${selectedPayoutGateway ? "Update" : "Create"} Payout Gateway`}
                open={isShowCreateModal}
                onCancel={() => {
                    setIsShowCreateModal(false);
                    setSelectedPayoutGateway(undefined);
                }}
                onOk={form.submit}
                okText={selectedPayoutGateway ? "Update" : "Create"}
                okButtonProps={{ loading: isLoadingCreate || isLoadingUpdate }}
                centered
            >
                <PayoutGatewayModification
                    data={selectedPayoutGateway}
                    form={form}
                    onFinish={onFinish}
                />
            </Modal>
        </div>
    );
};

export default PayoutGateways;
