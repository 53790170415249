import { ITranslationEntry } from "../lang";

export const UZ: ITranslationEntry = {
    translations: {
        notifications: "Bildirishnomalar",
        markAllAsRead: `Hammasini o'qing deb belgilang`,
        poweredBy: `Tomonidan qo'llab-quvvatlanadi`,
        settings: "Sozlamalar",
        noNewNotification: `Bu yerda hali ko'rish uchun yangi narsa yo'q`,
    },
    lang: "uz",
};
