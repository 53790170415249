import { Checkbox, Flex, Space, Typography } from "antd";
import { tw } from "@/consts/theme/tailwindTheme";
import { IRatingRangeData } from "@/types/productSearchResult";
import { AcceptInventoryProductsQueryKeys } from "@/consts/appConstants";
import { useEffect, useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useForm } from "antd/es/form/Form";
import { useAtom } from "jotai";
import usePageQuery from "@/helpers/usePageQuery";
import FacetCheckboxFormTitle from "./FacetCheckboxFormTitle";
import { productSearchQueryAtom } from "@/lib/core-react/store/inventoryProductsAtoms";

const PriceMinimumRangeFacet = ({
    facetFields,
}: {
    payloadKey: AcceptInventoryProductsQueryKeys;
    facetFields: IRatingRangeData[] | undefined;
}) => {
    const [form] = useForm();
    const { getCurrentQuery } = usePageQuery();
    const { updateQuery } = usePageQuery();

    const [facetFieldsData, setFacetFieldsData] =
        useState<IRatingRangeData[]>();

    const [productSearchQuery, setProductSearchQuery] = useAtom(
        productSearchQueryAtom,
    );

    const { price } = productSearchQuery;

    useEffect(() => {
        if ((price && price.aggregated.max) || price.aggregated.min) {
            setProductSearchQuery((prev) => ({ ...prev, price }));
        }
    }, []);

    useEffect(() => {
        if (facetFields) {
            setFacetFieldsData(facetFields);
        }
    }, [facetFields]);

    const currentQuery = getCurrentQuery();
    useEffect(() => {
        if (!currentQuery.price) {
            form.resetFields();
        }
    }, [currentQuery]);

    const onChange = (
        _isChecked: CheckboxChangeEvent,
        selected: IRatingRangeData,
    ) => {
        if (facetFieldsData) {
            const updateData = facetFieldsData.map((item) => {
                if (item.name === selected.name) {
                    form.setFieldValue(item.name, true);
                    return { ...item, isChecked: true };
                } else {
                    form.resetFields([item.name]);
                    return { ...item, isChecked: false };
                }
            });

            const selectedPrice = updateData.find(
                (item) => item.isChecked === true,
            );

            if (selectedPrice) {
                const price = {
                    aggregated: {
                        min: selectedPrice.from,
                        max: selectedPrice.to,
                    },
                };

                setProductSearchQuery((prev) => ({ ...prev, price }));
                updateQuery({ price });
            }
        }
    };

    return (
        <Space direction="vertical" size={"small"}>
            <FacetCheckboxFormTitle label="Price" />
            <Flex vertical gap={tw.spacing[2]}>
                {facetFields &&
                    facetFields.map((data) => {
                        return (
                            <label
                                style={{ display: "block" }}
                                htmlFor={`${data?.name}`}
                            >
                                <>
                                    <Flex gap={6} align="center">
                                        <Checkbox
                                            id={`${data?.name}`}
                                            checked={form.getFieldValue(
                                                data.name,
                                            )}
                                            onChange={(e) => onChange(e, data)}
                                        />
                                        <Typography.Paragraph>
                                            {data.name}
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            ({data.count})
                                        </Typography.Paragraph>
                                    </Flex>
                                </>
                            </label>
                        );
                    })}
            </Flex>
        </Space>
    );
};
export default PriceMinimumRangeFacet;
