export enum ShippingModeEnums {
    CARGO = "cargo",
    INTERNATIONAL = "international",
    P2P = "p2p",
}

export enum ShippingTypeEnums {
    BY_AIR = "air",
    BY_SHIP = "ship",
    BY_ROAD = "road",
}

export enum UnitTypeEnums {
    PIECE = "piece",
    KG = "kg",
    CBM = "cbm",
}

export enum RateTypeEnums {
    PERCENTAGE = "percentage",
    FIXED = "fixed",
}

export enum OperationStatusEnums {
    OPEN_FOR_EVERYONE = "open-for-everyone",
    OPEN_FOR_ADMIN = "open-for-admin",
    CLOSED_OPERATION = "closed-operation",
    BLOCKED_OPERATION = "blocked-operation",
}

export enum StatusEnums {
    PENDING = "pending",
    ON_HOLD = "on-hold",
    APPROVED = "approved",
    REJECTED = "rejected",
}
