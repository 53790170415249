import CategoryPriceResource from "@/lib/core/resources/private/categoryPriceResource";
import {
    AgentCompanyResource,
    AgentWarehouseResource,
} from "../../resources/private";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";

class ShippingAgentService extends CommerceApiService {
    public agentCompanyResource: AgentCompanyResource;
    public agentWarehouseResource: AgentWarehouseResource;
    public categoryPriceResource: CategoryPriceResource;

    constructor() {
        super();

        this.agentCompanyResource = new AgentCompanyResource(this.config);
        this.agentWarehouseResource = new AgentWarehouseResource(this.config);
        this.categoryPriceResource = new CategoryPriceResource(this.config);
    }
}

export default ShippingAgentService;
