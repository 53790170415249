import type { MentionOptions } from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import tippy, { type Instance as TippyInstance } from "tippy.js";
import SuggestionList, { type SuggestionListRef } from "./SuggestionList";
import { ICustomerUser } from "@/types/customerUserCollection";
import { AdminUserService } from "@/lib/core/services/private";

export type MentionSuggestion = {
    id: string;
    mentionLabel: string;
};

/**
 * Workaround for the current typing incompatibility between Tippy.js and Tiptap
 * Suggestion utility.
 *
 * @see https://github.com/ueberdosis/tiptap/issues/2795#issuecomment-1160623792
 *
 * Adopted from
 * https://github.com/Doist/typist/blob/a1726a6be089e3e1452def641dfcfc622ac3e942/stories/typist-editor/constants/suggestions.ts#L169-L186
 */
const DOM_RECT_FALLBACK: DOMRect = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
    toJSON() {
        return {};
    },
};

export const mentionSuggestionOptions: MentionOptions["suggestion"] = {
    items: async ({ query }): Promise<MentionSuggestion[]> => {
        const adminUserService = new AdminUserService();
        try {
            const response = await adminUserService.adminUserResource.getList(
                `per_page=5&keyword=${query.toLowerCase()}`,
            );

            const mentionSuggestions = response.data
                .map((user: ICustomerUser) => ({
                    mentionLabel: user.name,
                    id: user.id.toString(),
                }))
                .filter((item: MentionSuggestion) =>
                    item.mentionLabel
                        .toLowerCase()
                        .startsWith(query.toLowerCase()),
                )
                .slice(0, 5);

            return Promise.resolve(mentionSuggestions);
        } catch (_) {
            return Promise.resolve([]);
        }
    },
    render: () => {
        let component: ReactRenderer<SuggestionListRef> | undefined;
        let popup: TippyInstance | undefined;

        return {
            onStart: (props) => {
                component = new ReactRenderer(SuggestionList, {
                    props,
                    editor: props.editor,
                });

                // Customize the popup using Tippy.js
                popup = tippy("body", {
                    getReferenceClientRect: () =>
                        props.clientRect?.() ?? DOM_RECT_FALLBACK,
                    appendTo: () => document.body,
                    content: component.element,
                    showOnCreate: true,
                    allowHTML: true,
                    interactive: true,
                    trigger: "manual",
                    placement: "bottom-start",
                    theme: "light-border",
                    popperOptions: {
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 5],
                                },
                            },
                        ],
                    },
                })[0];

                // Apply custom styles directly
                const element = component?.element as HTMLElement;

                if (element) {
                    element.style.padding = "8px";
                    element.style.backgroundColor = "#ffffff";
                    element.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
                    element.style.borderRadius = "4px";
                    element.style.cursor = "pointer"; // Pointer cursor for better UX
                }
            },

            onUpdate(props) {
                component?.updateProps(props);

                popup?.setProps({
                    getReferenceClientRect: () =>
                        props.clientRect?.() ?? DOM_RECT_FALLBACK,
                });
            },

            onKeyDown(props) {
                if (props.event.key === "Escape") {
                    popup?.hide();
                    return true;
                }

                if (!component?.ref) {
                    return false;
                }

                return component.ref.onKeyDown(props);
            },

            onExit() {
                popup?.destroy();
                component?.destroy();
                popup = undefined;
                component = undefined;
            },
        };
    },
};
