export const buyProductFilters = [
    { key: "keyword", label: "Keyword", visible: true },
    {
        key: "product_status",
        label: "Product Status (Current)",
        visible: true,
    },
    { key: "tracking_number", label: "Tracking Number", visible: true },
    {
        key: "tracking_number_match",
        label: "Tracking Number Match",
        visible: true,
    },
    { key: "tracking_presence", label: "Tracking Presence", visible: true },
    { key: "user_id", label: "Customer", visible: true },
    { key: "order_handler_id", label: "Order Handler", visible: true },
    {
        key: "customer_invoice_status",
        label: "Payment Status",
        visible: true,
    },
    {
        key: "agent_invoice_status",
        label: "Agent Invoice Status",
        visible: true,
    },
    { key: "store_id", label: "Store", visible: true },
    { key: "order_number", label: "Order Number", visible: true },
    { key: "product_number", label: "Product Number", visible: true },
    { key: "created_at", label: "Created At", visible: true },
    { key: "region_id", label: "Region", visible: true },
    { key: "agent_company_id", label: "Agent Company", visible: true },
    { key: "source_country_id", label: "Source Country", visible: true },
    {
        key: "base_shipping_category_id",
        label: "Shipping Category",
        visible: true,
    },
    { key: "status_log_status", label: "Status Log", visible: true },
    {
        key: "status_log_created_at",
        label: "Status Log Created At",
        visible: true,
    },
    { key: "sort_by", label: "Sort By", visible: true },
];
export const buyOrderFilters = [
    { key: "keyword", label: "Keyword", visible: true },
    {
        key: "product_status",
        label: "Product Status (Current)",
        visible: true,
    },
    { key: "order_number", label: "Order Number", visible: true },
    { key: "tracking_number", label: "Tracking Number", visible: true },
    {
        key: "tracking_number_match",
        label: "Tracking Number Match",
        visible: true,
    },
    { key: "tracking_presence", label: "Tracking Presence", visible: true },
    { key: "user_id", label: "Customer", visible: true },
    { key: "order_handler_id", label: "Order Handler", visible: true },
    {
        key: "customer_invoice_status",
        label: "Payment Status",
        visible: true,
    },
    {
        key: "agent_invoice_status",
        label: "Agent Invoice Status",
        visible: true,
    },
    { key: "product_number", label: "Product Number", visible: true },
    { key: "store_id", label: "Store", visible: true },
    { key: "created_at", label: "Created At", visible: true },
    {
        key: "destination_warehouse_id",
        label: "Destination Warehouse",
        visible: true,
    },
    { key: "region_id", label: "Region", visible: true },
    { key: "agent_company_id", label: "Agent Company", visible: true },
    { key: "source_country_id", label: "Source Country", visible: true },
    {
        key: "base_shipping_category_id",
        label: "Shipping Category",
        visible: true,
    },
    { key: "seller_id", label: "Seller Id", visible: true },
    { key: "product_link", label: "Product Link", visible: true },
    { key: "status_log_status", label: "Status Log", visible: true },
    {
        key: "status_log_created_at",
        label: "Status Log Created At",
        visible: true,
    },
    { key: "sort_by", label: "Sort By", visible: true },
];

export const shipmentProductFilter = [
    { key: "keyword", label: "Keyword", visible: true },
    {
        key: "product_status",
        label: "Product Status (Current)",
        visible: true,
    },
    { key: "tracking_number", label: "Tracking Number", visible: true },
    {
        key: "tracking_number_match",
        label: "Tracking Number Match",
        visible: true,
    },
    { key: "tracking_presence", label: "Tracking Presence", visible: true },
    { key: "user_id", label: "Customer", visible: true },
    { key: "order_handler_id", label: "Order Handler", visible: true },
    {
        key: "customer_invoice_status",
        label: "Payment Status",
        visible: true,
    },
    {
        key: "agent_invoice_status",
        label: "Agent Invoice Status",
        visible: true,
    },
    { key: "order_number", label: "Order Number", visible: true },
    { key: "product_number", label: "Product Number", visible: true },
    {
        key: "buy_product_number",
        label: "Buy Product Number",
        visible: true,
    },
    {
        key: "destination_warehouse_id",
        label: "Destination Warehouse",
        visible: true,
    },
    { key: "created_at", label: "Created At", visible: true },
    { key: "agent_company_id", label: "Agent Company", visible: true },
    { key: "agent_warehouse_id", label: "Agent Warehouse", visible: true },
    { key: "region_id", label: "Region", visible: true },
    { key: "source_country_id", label: "Source Country", visible: true },
    {
        key: "base_shipping_category_id",
        label: "Shipping Category",
        visible: true,
    },
    {
        key: "warehouse_shipment_id",
        label: "Warehouse Shipment",
        visible: true,
    },
    { key: "shipping_type", label: "Shipping Type", visible: true },
    { key: "shipping_mode", label: "Shipping Mode", visible: true },
    { key: "total_weight", label: "Total Weight", visible: true },
    { key: "total_quantity", label: "Total Quantity", visible: true },
    {
        key: "invoice_total",
        label: "Customer Invoice Total",
        visible: true,
    },
    {
        key: "agent_invoice_total",
        label: "Agent Invoice Total",
        visible: true,
    },
    { key: "status_log_status", label: "Status Log", visible: true },
    {
        key: "status_log_created_at",
        label: "Status Log Created At",
        visible: true,
    },
];

export const shipmentOrderFilters = [
    { key: "keyword", label: "Keyword", visible: true },
    {
        key: "product_status",
        label: "Product status (Current)",
        visible: true,
    },
    { key: "order_number", label: "Order Number", visible: true },
    { key: "tracking_number", label: "Tracking Number", visible: true },
    {
        key: "tracking_number_match",
        label: "Tracking Number Match",
        visible: true,
    },
    { key: "tracking_presence", label: "Tracking presence", visible: true },
    { key: "user_id", label: "Customer", visible: true },
    { key: "order_handler_id", label: "Order Handler", visible: true },
    {
        key: "customer_invoice_status",
        label: "Payment status",
        visible: true,
    },
    {
        key: "agent_invoice_status",
        label: "Agent invoice status",
        visible: true,
    },
    { key: "product_number", label: "Product Number", visible: true },
    {
        key: "buy_product_number",
        label: "Buy Product Number",
        visible: true,
    },
    {
        key: "destination_warehouse_id",
        label: "Destination warehouse",
        visible: true,
    },
    { key: "agent_company_id", label: "Agent Company", visible: true },
    { key: "agent_warehouse_id", label: "Agent warehouse", visible: true },
    { key: "region_id", label: "Region", visible: true },
    { key: "created_at", label: "Created At", visible: true },
    { key: "source_country_id", label: "Source Country", visible: true },
    {
        key: "shipping_category_id",
        label: "Shipping Category",
        visible: true,
    },
    { key: "shipping_type", label: "Shipping Type", visible: true },
    { key: "shipping_mode", label: "Shipping Mode", visible: true },
    { key: "total_weight", label: "Total weight", visible: true },
    { key: "total_quantity", label: "Total quantity", visible: true },
    {
        key: "invoice_total",
        label: "Customer invoice total",
        visible: true,
    },
    {
        key: "agent_invoice_total",
        label: "Agent invoice total",
        visible: true,
    },
    { key: "status_log_status", label: "Status Log", visible: true },
    {
        key: "status_log_created_at",
        label: "Status Log Created At",
        visible: true,
    },
];
