import { IPagination } from "@/types/pagination";
import {
    WishlistCollectionEnums,
    WishlistItemCollectionEnums,
} from "@/enums/wishlistCollectionEnums";
import {
    IContext,
    IMeasurement,
    IProductGalleryCollection,
    IProductPrice,
    ISeller,
    IWishlist,
    IWishlistCollection,
    IWishlistExtended,
    IWishlistItem,
    IWishlistItemCollection,
} from "@/types/wishlistCollection";
import { IFilterType } from "@/types/filters";
import { IRegionCollection } from "@/types/regionCollection";
import { ICustomer } from "@/types/customerCollection";

export class WishlistItemModel {
    public readonly object: WishlistItemCollectionEnums.WishlistItem;
    public readonly id: number;
    public readonly product_id: string;
    public readonly product_title: string;
    public readonly product_link: string;
    public readonly product_image: string;
    public readonly product_ratings_avg: number;
    public readonly product_seller: ISeller;
    public readonly product_price: IProductPrice;
    public readonly product_dimension: IMeasurement;
    public readonly product_gallery: IProductGalleryCollection;

    constructor(dataItem: IWishlistItem) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.product_id = dataItem.product_id;
        this.product_title = dataItem.product_title;
        this.product_link = dataItem.product_link;
        this.product_image = dataItem.product_image;
        this.product_ratings_avg = dataItem.product_ratings_avg;
        this.product_seller = dataItem.product_seller;
        this.product_price = dataItem.product_price;
        this.product_dimension = dataItem.product_dimension;
        this.product_gallery = dataItem.product_gallery;
    }

    getTitle() {
        return this.product_title;
    }
    getProductLink() {
        return this.product_link;
    }
    getProductImage() {
        return this.product_image;
    }
    getProductAverageRating() {
        return this.product_ratings_avg;
    }
    getSellerName() {
        return this.product_seller.name;
    }
    getSellerLink() {
        return this.product_seller.link;
    }
    getProductMaxDiscount() {
        return this.product_price.discount.max;
    }
    getProductMinDiscount() {
        return this.product_price.discount.min;
    }
    getProductMinOriginal() {
        return this.product_price.original.min;
    }
    getProductMaxOriginal() {
        return this.product_price.original.max;
    }
    getProductWidth() {
        return this.product_dimension.width;
    }
    getProductHeight() {
        return this.product_dimension.height;
    }
    getProductWeight() {
        return this.product_dimension.unit_weight;
    }
}

export class WishlistItemCollectionModel {
    public readonly object: WishlistItemCollectionEnums.WishlistItemCollection;
    public readonly data: WishlistItemModel[];

    constructor(data: IWishlistItemCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new WishlistItemModel(item));
    }
    getWishlistItemById(id: number): WishlistItemModel | undefined {
        return this.data.find((item) => item.id === id);
    }
}

export class WishlistModel {
    public readonly object: WishlistCollectionEnums.Wishlist;
    public readonly id: number;
    public readonly name: string;
    public readonly is_default: number;
    public readonly guest_id: string | null;
    public readonly context: IContext;
    public readonly customer: ICustomer | null;
    public readonly region: IRegionCollection;
    public readonly wishlist_items: WishlistItemCollectionModel;

    constructor(dataItem: IWishlist) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.is_default = dataItem.is_default;
        this.guest_id = dataItem.guest_id;
        this.context = dataItem.context;
        this.customer = dataItem.customer;
        this.region = dataItem.region;
        this.wishlist_items = new WishlistItemCollectionModel(
            dataItem.wishlist_items,
        );
    }

    getCustomer() {
        return this.customer;
    }
}

export class WishlistCollectionModel {
    public readonly object: WishlistCollectionEnums.WishlistCollection;
    public readonly data: WishlistModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: IWishlistCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new WishlistModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getWishlistById(id: number): WishlistModel | undefined {
        return this.data.find((item) => item.id === id);
    }

    getData() {
        return this.data;
    }

    getProcessData(): IWishlistExtended[] {
        return this.getData().flatMap((purchaseCart) =>
            purchaseCart.wishlist_items.data.map((cart) => ({
                ...cart,
                customer: purchaseCart.customer,
                region: purchaseCart.region,
                guest_id: purchaseCart.guest_id,
                purchaseCartId: purchaseCart.id,
                purchaseCartItemId: cart.id,
            })),
        ) as unknown as IWishlistExtended[];
    }
}
