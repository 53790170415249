export enum AgentCompanyCollectionEnums {
    AgentCompanyCollection = "AgentCompanyCollection",
    AgentCompany = "AgentCompany",
}

export enum AgentVerificationCollectionEnums {
    AgentVerificationCollection = "AgentVerificationCollection",
    AgentVerification = "AgentVerification",
}

export enum AgentCompanyVerificationEnum {
    AgentAttachmentCollection = "AgentAttachmentCollection",
    AgentAttachment = "AgentAttachment",
}
export enum AgentVerificationStatus {
    PENDING = "pending",
    PROCESSING = "processing",
    APPROVED = "approved",
    REJECTED = "rejected",
}
export enum AgentCompanyVerificationStatus {
    PENDING = "pending",
    PROCESSING = "processing",
    ON_HOLD = "on-hold",
    APPROVED = "approved",
    REJECTED = "rejected",
}
export enum AgentCompanyDataSlug {
    ECourier = "ecourier",
    SteadFast = "steadfast",
    PathaoCourier = "pathao-courier",
}
export enum AgentCompanyOperationStatus {
    OPEN_FOR_EVERYONE = "open-for-everyone",
    OPEN_FOR_ADMIN = "open-for-admin",
    CLOSED_OPERATION = "closed-operation",
    BLOCKED_OPERATION = "blocked-operation",
}
export enum AgentCompanyShippingType {
    Air = "air",
    Sea = "sea",
    Road = "road",
}
export enum AgentCompanyVerificationType {
    NID_FRONT = "nid-front",
    NID_BACK = "nid-back",
    BUSINESS_LICENCE = "business-license",
    PASSPORT = "passport",
}

// OLD

export enum AgentCompanyEnum {
    AgentCompanyCollection = "AgentCompanyCollection",
    AgentCompany = "AgentCompany",
    AgentVerificationCollection = "AgentVerificationCollection",
    AgentVerification = "AgentVerification",
}
