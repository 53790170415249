import { ITranslationEntry } from "../lang";

export const HU: ITranslationEntry = {
    translations: {
        notifications: "értesítéseket",
        markAllAsRead: "összes megjelölése olvasottként",
        poweredBy: "támogatta",
        settings: "beállítások",
        noNewNotification: "Itt még semmi újat nem látni",
    },
    lang: "hu",
};
