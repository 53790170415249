import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import { IActionsProps } from "@/helpers/getModalTital";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { RegionModel } from "@/models/regionCollectionModel";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import { ExtendedMenuItemType } from "@/types";
import {
    BorderOuterOutlined,
    CameraTwoTone,
    CheckCircleTwoTone,
    DeleteTwoTone,
    DownloadOutlined,
    HomeTwoTone,
    PayCircleOutlined,
    RadiusBottomleftOutlined,
    RadiusUpleftOutlined,
    UnorderedListOutlined,
    VerticalLeftOutlined,
    DollarTwoTone,
    FastForwardOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import { agentRateChangeAllowedStatuses } from "@/pages/Shipping/ShipmentProducts/ShipmentProductsTable.tsx";
import { useShippingStart } from "@/lib/core-react/hooks/private/useShipping";
import { showError } from "@/helpers/showError";

export const useShipmentProductsBulkActions = ({
    handleModal,
}: IActionsProps) => {
    const { createSession } = useInvoice();

    const getActions = (selectedRows: ShipmentProductModel[]) => {
        const validSelectedRows = selectedRows.filter((row) => {
            const transactionSummary = row
                .getCustomerInvoice()
                ?.getTransactionSummary();
            const due = transactionSummary?.due;

            return typeof due === "number" && due > 0;
        });

        const uniqueRegions = Array.from(
            new Set(
                validSelectedRows
                    .map((row) => row.getRegion())
                    .filter(
                        (region) => region !== undefined && region !== null,
                    ),
            ),
        );

        const uniqueRegionIds = Array.from(
            new Set(
                validSelectedRows
                    .map((row) => row.getRegion()?.getId())
                    .filter(
                        (region) => region !== undefined && region !== null,
                    ),
            ),
        );

        const uniqueCustomerIds = Array.from(
            new Set(
                validSelectedRows
                    .map((row) => row.getCustomerId())
                    .filter(
                        (customer) =>
                            customer !== null && customer !== undefined,
                    ),
            ),
        );

        const uniqueInvoiceIds = Array.from(
            new Set(
                validSelectedRows
                    .filter((product) => {
                        const transactionSummary = product
                            .getCustomerInvoice()
                            ?.getTransactionSummary();
                        const due = transactionSummary?.due;
                        return typeof due === "number" && due > 0;
                    })
                    .map((row) => row.getCustomerInvoiceId())
                    .filter((id) => id !== undefined && id !== null),
            ),
        );

        const handlePayCustomerInvoice = async () => {
            if (uniqueCustomerIds.length > 1) {
                return notification.error({
                    message: "Error",
                    description:
                        "Selected rows must belong to a single customer",
                });
            }
            if (uniqueRegionIds.length > 1) {
                return notification.error({
                    message: "Error",
                    description: "Selected rows must belong to a single region",
                });
            }

            if (uniqueInvoiceIds.length === 0) {
                return notification.error({
                    message: "Error",
                    description: "No invoices have dues",
                });
            }

            const res = await createSession(
                uniqueCustomerIds[0],
                uniqueInvoiceIds,
            );

            if (res) {
                handleModal({
                    action: "pay-invoice",
                    data: {
                        region: uniqueRegions[0],
                        user_id: uniqueCustomerIds[0],
                        token: res.data.session_token,
                        buyProduct: selectedRows.map((e) => e.getBuyProduct()),
                    },
                });
            }
        };

        const items: ExtendedMenuItemType[] = [
            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                ],
                icon: <CheckCircleTwoTone />,
                label: "Assign Agent",
                key: "approve-and-assign-agent",
                onClick: () => {
                    handleModal({
                        action: "approve_and_assign_agent",
                    });
                },
            },

            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_APPROVE_SHIPMENT_PRODUCT,
                ],
                icon: <CheckCircleTwoTone />,
                label: "Approve product",
                key: "approve-bulk-products",
                onClick: () => {
                    handleModal({
                        action: "approved_bulk_shipment_product",
                    });
                },
            },

            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ATTACH_IMAGES,
                ],
                icon: <CameraTwoTone />,
                label: "Attach Images",
                key: "attach-bulk-images",
                onClick: () => {
                    handleModal({
                        action: "attach_images_shipment_product",
                    });
                },
            },

            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_COUNTRY,
                ],
                icon: <VerticalLeftOutlined />,
                label: "Change source country",
                key: "change-source-country",

                onClick: () => {
                    handleModal({
                        action: "change_shipping_country",
                    });
                },
            },
            {
                permission:
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_DESTINATION_WAREHOUSE,
                icon: <HomeTwoTone />,
                label: "Change destination warehouse",
                key: "change-destination-warehouse",
                onClick: () => {
                    handleModal({
                        action: "change_destination_warehouse",
                    });
                },
            },
            {
                permission:
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_CATEGORY,
                icon: <BorderOuterOutlined />,
                label: "Change shipping category",
                key: "change-shipping-category",
                onClick: () => {
                    handleModal({
                        action: "change_shipping_category",
                    });
                },
            },
            {
                permission:
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_TYPE,
                icon: <RadiusBottomleftOutlined />,
                label: "Change shipping type",
                key: "change-shipping-type",
                onClick: () => {
                    handleModal({
                        action: "change_shipping_type",
                    });
                },
            },
            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_PRODUCT_CHANGE_RATE,
                ],
                icon: <DollarTwoTone />,
                label: "Change Agent Rate",
                key: "bulk_shipment_product_change_rate",
                onClick: () => {
                    handleModal({
                        action: "bulk_shipment_product_change_rate",
                    });
                },
            },
            {
                permission:
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_CHANGE_SHIPPING_MODE,
                icon: <RadiusUpleftOutlined />,
                label: "Change shipping mode",
                key: "change-shipping-mode",
                onClick: () => {
                    handleModal({
                        action: "change_shipping_mode",
                    });
                },
            },
            ...(validSelectedRows.length
                ? [
                      {
                          permission:
                              ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                          label: "Pay Customer Invoice",
                          key: "pay-invoice",
                          icon: <PayCircleOutlined />,
                          onClick: () => handlePayCustomerInvoice(),
                      },
                  ]
                : []),
        ];

        return items.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };

    return { getActions };
};

const useShipmentProductsActions = ({ handleModal }: IActionsProps) => {
    const {
        handledownloadData,
        createSession,
        isLoading: isLoadingSession,
    } = useInvoice();
    const { shippingStart } = useShippingStart();

    const handlePayCustomerInvoice = async (
        region: RegionModel,
        user_id: number,
        invoice_ids: number[],
    ) => {
        const res = await createSession(user_id, invoice_ids);

        if (res) {
            handleModal({
                action: "pay-invoice",
                data: { region, user_id, token: res.data.session_token },
            });
        }
    };

    const handleDownloadInvoice = async (invoiceId: number) => {
        try {
            const response = await handledownloadData(invoiceId, "customer");

            if (response?.data?.download_url) {
                const downloadUrl = response.data.download_url;

                const link = document.createElement("a");
                link.href = downloadUrl;
                link.download = "";
                link.target = "_blank";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                console.error("Download URL not found in response");
            }
        } catch (error) {
            console.error("Error downloading invoice:", error);
        }
    };

    const handleShippingStart = async (shipmentProductId: number) => {
        try {
            if (!shipmentProductId) {
                throw new Error("Shipment Product ID is missing.");
            }
            await shippingStart(shipmentProductId);
            notification["success"]({
                message: "Shipping Start successfully",
            });
        } catch (error) {
            showError(error);
        }
    };
    const getActions = (shipmentProductModel: ShipmentProductModel) => {
        const invoiceId = shipmentProductModel.getCustomerInvoice()?.getId();
        const userId = shipmentProductModel.getCustomerId();
        const region = shipmentProductModel.getRegion();

        const items: ExtendedMenuItemType[] = [
            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                ],
                icon: <CheckCircleTwoTone />,
                label: "Assign Agent",
                key: "approve-and-assign-agent",
                onClick: () => {
                    handleModal({
                        action: "approve_and_assign_agent",
                        data: shipmentProductModel,
                    });
                },
            },

            {
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ATTACH_IMAGES,
                ],
                icon: <CameraTwoTone />,
                label: "Attach image",
                key: "attach_images_shipment_product",
                onClick: () => {
                    handleModal({
                        action: "attach_images_shipment_product",
                        data: shipmentProductModel,
                    });
                },
            },
        ];

        if (shipmentProductModel.isCanShippingStart()) {
            items.push({
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_START_SHIPPING,
                ],
                icon: <FastForwardOutlined color=" #1677ff" />,
                label: "Shipping Start",
                key: "shipping-start",
                onClick: () => {
                    const shipmentProductId = shipmentProductModel?.id;
                    if (shipmentProductId) {
                        handleShippingStart(shipmentProductId);
                    }
                },
            });
        }

        if (shipmentProductModel.isCanApproved()) {
            items.push({
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_APPROVE_SHIPMENT_PRODUCT,
                ],
                icon: <CheckCircleTwoTone />,
                label: "Approve product",
                key: "approve-products",
                onClick: () => {
                    handleModal({
                        action: "approved_shipment_product",
                        data: shipmentProductModel,
                    });
                },
            });
        }

        if (shipmentProductModel.isCanRejected()) {
            items.push({
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_REJECT_SHIPMENT_PRODUCT,
                ],
                icon: <DeleteTwoTone />,
                label: "Reject products",
                key: "reject-product",
                onClick: () => {
                    handleModal({
                        action: "reject_shipment_product",
                        data: shipmentProductModel,
                    });
                },
            });
        }

        if (shipmentProductModel.getCustomerInvoice()) {
            items.push({
                permission:
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_RECALCULATE,
                label: "Download Invoice",
                key: "download-invoice",
                icon: <DownloadOutlined />,
                onClick: () => handleDownloadInvoice(Number(invoiceId)),
            });
            items.push({
                permission:
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                label: isLoadingSession ? "Wait..." : "Pay Customer Invoice",
                key: "pay-invoice",
                icon: <PayCircleOutlined />,
                disabled: invoiceId === undefined,
                onClick: () =>
                    userId &&
                    handlePayCustomerInvoice(region, userId, [
                        Number(invoiceId),
                    ]),
            });
        }

        if (shipmentProductModel.getBids().length > 0) {
            items.push({
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_MANAGE_BID,
                ],
                icon: <UnorderedListOutlined />,
                label: "Bid list",
                key: "bid-list",
                onClick: () => {
                    handleModal({
                        action: "bid_list",
                        data: shipmentProductModel,
                    });
                },
            });
        }

        if (
            agentRateChangeAllowedStatuses.includes(
                shipmentProductModel.getStatus(),
            )
        ) {
            items.push({
                permission: [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_PRODUCT_CHANGE_RATE,
                ],
                icon: <DollarTwoTone />,
                label: "Change Agent Rate",
                key: "shipment_product_change_rate",
                onClick: () => {
                    handleModal({
                        action: "shipment_product_change_rate",
                        data: shipmentProductModel,
                    });
                },
            });
        }

        return items.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };

    return { getActions };
};

export default useShipmentProductsActions;
