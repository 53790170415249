import {
    IAgentInvoice,
    IAgentInvoiceCollection,
    IAgentInvoiceDetails,
    IBuyProduct,
    IDestinationWarehouse,
    IInvoice,
    IInvoiceAgentCompany,
    IInvoiceCollection,
    IInvoiceItem,
    IInvoiceItemCollection,
    IInvoiceTransaction,
    IInvoiceTransactionCollection,
    InvoiceTransactionDetails,
    IOrder,
    IShipmentProduct,
    IShipmentProductImage,
    IShipmentProductImageCollection,
    IShipmentProductShippingAddress,
    OrderTypeEnum,
} from "@/types/InvoiceCollection";
import { IPagination } from "@/types/pagination";
import { IFilterType } from "@/types/filters";
import { formatDateTime } from "@/utils/helpers";
import { StoreModel } from "./storeCollectionModel";
import {
    IBuyProductCharge,
    IBuyProductVariation,
} from "@/types/buyProductCollection";
import { ProductSellerModel } from "./buyOrderCollectionModel";
import { IShipmentProductPackage } from "@/types/shipmentProductCollection";
import { ShippingCountryModel } from "./shippingCountryCollectionModel";
import { RegionModel } from "./regionCollectionModel";
import { SessionInvoiceTransactionSummaryModel } from "./paymentSessionModel";
import { CustomerUserModel } from "./customerUserCollectionModel";

export class InvoiceItemModel {
    private item: IInvoiceItem;
    constructor(item: IInvoiceItem) {
        this.item = item;
    }

    getTotalAmount(): number {
        return this.item.total_amount;
    }

    getInitialAmount(): number {
        return this.item.initial_amount;
    }

    getRefundableAmount(): number {
        return this.item.refundable_amount;
    }

    getStatus() {
        return this.item.status;
    }
    getCreatedAt() {
        return formatDateTime(this.item.created_at);
    }

    getProductVariation(): IBuyProductVariation | undefined {
        return this.item.buy_product_variation;
    }

    getShipmentProductPackage(): IShipmentProductPackage | undefined {
        return this.item.shipment_product_package;
    }

    getBuyProductCharge(): IBuyProductCharge | undefined {
        return this.item.buy_product_charge;
    }

    getProductImageUrl(): string | undefined {
        return this.item.buy_product_variation?.image;
    }
}

export class ShipmentProductImageModel {
    public readonly object: string;
    public readonly id: number;
    public readonly title: string;
    public readonly alt: string;
    public readonly src: string;
    public readonly display_order: number;

    constructor(data: IShipmentProductImage) {
        this.object = data.object;
        this.id = data.id;
        this.title = data.title;
        this.alt = data.alt;
        this.src = data.src;
        this.display_order = data.display_order;
    }

    getById() {
        return this.id;
    }

    getTitle() {
        return this.title;
    }

    getAlt() {
        return this.alt;
    }

    getSrc() {
        return this.src;
    }

    getDisplayOrder() {
        return this.display_order;
    }
}

export class DestinationWarehouseModel {
    public readonly object: string;
    public readonly id: number;
    public readonly slug: string;
    public readonly name: string;
    public readonly status: string;
    public readonly created_at: string;

    constructor(data: IDestinationWarehouse) {
        this.object = data.object;
        this.id = data.id;
        this.slug = data.slug;
        this.name = data.name;
        this.status = data.status;
        this.created_at = data.created_at;
    }

    getId(): number {
        return this.id;
    }

    getSlug(): string {
        return this.slug;
    }

    getName(): string {
        return this.name;
    }

    getStatus(): string {
        return this.status;
    }

    getCreatedAt(): string {
        return this.created_at;
    }
}

export class InvoiceShipmentProductModel {
    public readonly object: string;
    public readonly id: number;
    public readonly product_number: string;
    public readonly title: string;
    public readonly images: IShipmentProductImageCollection;
    public readonly shipping_address: IShipmentProductShippingAddress;
    public readonly shipment_instruction: string | null;
    public readonly shipping_mode: string;
    public readonly shipping_type: string;
    public readonly status: string;
    public readonly status_label: string;
    public readonly link: string;
    public readonly shipping_country?: ShippingCountryModel;
    public readonly destination_warehouse?: DestinationWarehouseModel;

    constructor(data: IShipmentProduct) {
        this.object = data.object;
        this.id = data.id;
        this.product_number = data.product_number;
        this.title = data.title;
        this.images = data.images;
        this.shipping_address = data.shipping_address;
        this.shipment_instruction = data.shipment_instruction;
        this.shipping_mode = data.shipping_mode;
        this.shipping_type = data.shipping_type;
        this.status = data.status;
        this.status_label = data.status_label;
        this.link = data.link;
        this.shipping_country = data.shipping_country
            ? new ShippingCountryModel(data.shipping_country)
            : undefined;
        this.destination_warehouse = data.destination_warehouse
            ? new DestinationWarehouseModel(data.destination_warehouse)
            : undefined;
    }

    getById() {
        return this.id;
    }

    getProductNumber() {
        return this.product_number;
    }

    getLink() {
        return this.link;
    }

    getShippingCountry() {
        return this.shipping_country;
    }

    getTitle() {
        return this.title;
    }

    getDestinationWarehouse() {
        return this.destination_warehouse;
    }

    getImages() {
        return this.images;
    }

    getShippingAddress() {
        return this.shipping_address;
    }

    getShippingInstrucation() {
        return this.shipment_instruction;
    }

    getShippingMode() {
        return this.shipping_mode;
    }

    getShippingType() {
        return this.shipping_type;
    }

    getStatus() {
        return this.status;
    }
    getStatusLabel() {
        return this.status_label;
    }

    getFullName() {
        return `${this.shipping_address.first_name}  ${this.shipping_address.last_name}`;
    }
}

export class InvoiceBuyProductModel {
    public readonly object: string;
    public readonly id: number;
    public readonly product_number: string;
    public readonly product_id: string;
    public readonly product_vid: string;
    public readonly cart_item_id: null | string;
    public readonly product_title: string;
    public readonly product_image: string;
    public readonly product_link: string;
    public readonly product_seller?: ProductSellerModel;
    public readonly shipping_provider: string;
    public readonly total_quantity: number;
    public readonly status: string;
    public readonly status_label: string;
    public readonly source_type: string;
    public readonly source_id: number;
    public readonly created_at: string;
    public readonly store?: StoreModel;

    constructor(data: IBuyProduct) {
        this.object = data.object;
        this.id = data.id;
        this.product_number = data.product_number;
        this.product_id = data.product_id;
        this.product_vid = data.product_vid;
        this.cart_item_id = data.cart_item_id;
        this.product_title = data.product_title;
        this.product_image = data.product_image;
        this.product_link = data.product_link;
        this.shipping_provider = data.shipping_provider;
        this.total_quantity = data.total_quantity;
        this.status = data.status;
        this.status_label = data.status_label;
        this.source_type = data.source_type;
        this.source_id = data.source_id;
        this.store = data.store ? new StoreModel(data.store) : undefined;
        this.created_at = data.created_at;
        this.product_seller = data.product_seller
            ? new ProductSellerModel(data.product_seller)
            : undefined;
    }

    getProductID() {
        return this.product_id;
    }
    getId() {
        return this.id;
    }

    getProductNumber() {
        return this.product_number;
    }

    getStore() {
        return this.store;
    }

    getProductVID() {
        return this.product_vid;
    }

    getCartItemID() {
        return this.cart_item_id;
    }

    getProductTitle() {
        return this.product_title;
    }

    getProductImage() {
        return this.product_image;
    }

    getProductLink() {
        return this.product_link;
    }

    getShippingProvider() {
        return this.shipping_provider;
    }

    getTotalQuantity() {
        return this.total_quantity;
    }

    getStatus() {
        return this.status;
    }

    getStatusLabel() {
        return this.status_label;
    }

    getSourceType() {
        return this.source_type;
    }

    getSourceID() {
        return this.source_id;
    }

    getCreatedAt() {
        return formatDateTime(this.created_at);
    }
}

export class OrderModel {
    public readonly object: string;
    public readonly id: number;
    public readonly order_number: string;
    public readonly order_tag: string | null;
    public readonly order_type: OrderTypeEnum;
    public readonly order_status: string;
    public readonly payment_status: string;
    // public readonly user_id: number;
    // public readonly order_handler: number;
    public readonly created_at: string;

    constructor(data: IOrder) {
        this.object = data.object;
        this.id = data.id;
        this.order_number = data.order_number;
        // this.user_id = data.user_id;
        // this.order_handler = data.order_handler;
        this.order_tag = data.order_tag;
        this.order_type = data.order_type;
        this.order_status = data.order_status;
        this.payment_status = data.payment_status;
        this.created_at = data.created_at;
    }

    getId() {
        return this.id;
    }

    getOrderNumber() {
        return this.order_number;
    }
    getOrderTag() {
        return this.order_tag;
    }

    getType() {
        return this.order_type;
    }

    getStatus() {
        return this.order_status;
    }

    getCreatedAt() {
        return formatDateTime(this.created_at);
    }
}

export class InvoiceModel {
    readonly object: "Invoice";
    readonly id: number;
    readonly invoice_number: string;
    readonly customer: CustomerUserModel;
    readonly region: RegionModel;
    readonly order: IOrder;
    readonly status: InvoiceStatus;
    readonly total_amount: number;
    readonly initial_amount: number;
    readonly refundable_amount: number;
    readonly transaction_summary: SessionInvoiceTransactionSummaryModel;
    readonly buy_product?: InvoiceBuyProductModel;
    readonly items?: IInvoiceItemCollection;
    readonly shipment_product?: InvoiceShipmentProductModel;
    readonly transactions?: IInvoiceTransactionCollection;
    readonly created_at: string;

    constructor(data: IInvoice) {
        this.object = data.object;
        this.id = data.id;
        this.invoice_number = data.invoice_number;
        this.status = data.status;
        this.total_amount = data.total_amount;
        this.initial_amount = data.initial_amount;
        this.refundable_amount = data.refundable_amount;
        this.transaction_summary = new SessionInvoiceTransactionSummaryModel(
            data.transaction_summary,
        );
        this.buy_product = data.buy_product
            ? new InvoiceBuyProductModel(data.buy_product)
            : undefined;
        this.customer = data.customer && new CustomerUserModel(data.customer);
        this.region = new RegionModel(data.region);
        this.order = data.order;
        this.items = data.items;
        this.created_at = data.created_at;
        this.transactions = data.transactions;
        this.shipment_product = data.shipment_product
            ? new InvoiceShipmentProductModel(data.shipment_product)
            : undefined;
    }

    getId() {
        return this.id;
    }

    getInvoiceNumber() {
        return this.invoice_number;
    }
    getItems() {
        return this.items;
    }

    getCustomer() {
        return this.customer;
    }

    getShipmentProduct() {
        return this.shipment_product;
    }

    getRegion() {
        return this.region;
    }
    getCurrencyCode(): string | undefined {
        return this.region.currency?.code;
    }

    getOrder() {
        return this.order;
    }
    getStatus() {
        return this.status;
    }

    getTotalAmount() {
        return this.total_amount;
    }

    getInitialAmount() {
        return this.initial_amount;
    }

    getRefundableAmount() {
        return this.refundable_amount;
    }

    getTransactionSummery() {
        return this.transaction_summary;
    }

    getBuyProduct() {
        return this.buy_product;
    }

    getTransactions() {
        return this.transactions?.data;
    }
    isBuyProductVariationPresentInItems(): boolean {
        return this.items
            ? this.items.data.some((item) => item.buy_product_variation)
            : false;
    }

    getCreatedAt() {
        return formatDateTime(this.created_at);
    }
}

export class AgentInvoiceDetailsModel extends InvoiceModel {
    public readonly agent_company: InvoiceAgentCompanyModel;
    constructor(data: IAgentInvoiceDetails) {
        super(data);
        this.agent_company = new InvoiceAgentCompanyModel(data.agent_company);
    }
    getAgentCompany() {
        return this.agent_company;
    }
}
export class InvoiceAgentCompanyModel {
    private data: IInvoiceAgentCompany;

    constructor(data: IInvoiceAgentCompany) {
        this.data = data;
    }

    // Method to get company primary name
    getPrimaryName(): string {
        return this.data.primary_name;
    }

    // Method to get company secondary name
    getSecondaryName(): string {
        return this.data.secondary_name;
    }

    // Method to get company slug
    getSlug(): string {
        return this.data.slug;
    }

    // Method to get company operation status
    getOperationStatus(): string {
        return this.data.operation_status;
    }

    // Method to get company verification status
    getVerificationStatus(): string {
        return this.data.verification_status;
    }

    // Method to get company type
    getCompanyType(): string {
        return this.data.company_type;
    }

    // Method to get director name
    getDirectorName(): string {
        return this.data.director_name;
    }

    // Method to get designation
    getDesignation(): string {
        return this.data.designation;
    }

    // Method to get business phone
    getBusinessPhone(): string {
        return this.data.business_phone;
    }

    // Method to get years of experience
    getYearsOfExperience(): string {
        return this.data.years_of_experience;
    }

    // Method to get number of people in team
    getNumberOfPeopleInTeam(): string {
        return this.data.number_of_people_in_team;
    }

    // Method to get address
    getAddress() {
        return this.data.address;
    }

    // Method to get registered country detail
    getRegisteredCountryDetail() {
        return {
            ...this.data.registered_country_detail,
            created_at: formatDateTime(
                this.data.registered_country_detail.created_at,
            ),
        };
    }
}

export class InvoiceCollectionCommonModel {
    public readonly object: string;
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: {
        object: string;
        pagination: IPagination;
        filters: IFilterType;
    }) {
        this.object = data.object;
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getPagination() {
        return this.pagination;
    }
    getFilter() {
        return this.filters;
    }
}
export class InvoiceCollectionModel extends InvoiceCollectionCommonModel {
    public readonly data: InvoiceModel[];
    constructor(data: IInvoiceCollection) {
        super(data);
        this.data = data.data.map((item: IInvoice) => new InvoiceModel(item));
    }
    getData() {
        return this.data;
    }
}
export enum InvoiceStatus {
    UNPAID = "unpaid",
    PARTIAL_PAID = "partial-paid",
    PAID = "paid",
    SETTLED = "settled",
    UNSETTLED = "unsettled",
}

export class TransactionsModel {
    private data: IInvoiceTransaction;

    constructor(data: IInvoiceTransaction) {
        this.data = data;
    }

    getId() {
        return this.data.id;
    }
    getTransactionNumber() {
        return this.data.transaction_number;
    }
    getAmount() {
        return this.data.amount;
    }
    getDescription() {
        return this.data.description;
    }
    getType() {
        return this.data.type;
    }

    getAssociation() {
        return this.data.associations;
    }

    getCreatedAt() {
        return formatDateTime(this.data.created_at);
    }
    private getAssociations() {
        return this.data.associations?.data[0];
    }
    getAssociationsAmount() {
        return this.getAssociations()?.amount;
    }
    getAssociationsWallet() {
        return this.getAssociations()?.wallet;
    }
}

export class TransactionDetailsModel extends TransactionsModel {
    private transaction: InvoiceTransactionDetails;
    constructor(transaction: InvoiceTransactionDetails) {
        super(transaction);
        this.transaction = transaction;
    }

    getInvoiceNumber() {
        return this.transaction.invoice.invoice_number;
    }
    getInvoiceStatus() {
        return this.transaction.invoice.status;
    }
    getTransactionSummary() {
        return this.transaction.invoice.transaction_summary;
    }
    getTotalInvoiceAmount() {
        return this.transaction.invoice.total_amount;
    }
}

export class AgentInvoiceModel {
    private data: IAgentInvoice;
    constructor(data: IAgentInvoice) {
        this.data = data;
    }

    getStatus() {
        return this.data.status;
    }
    getId() {
        return this.data.id;
    }
    getInvoiceNumber() {
        return this.data.invoice_number;
    }

    getTransactionSummary() {
        return this.data.transaction_summary;
    }

    getOrder() {
        return this.data.order;
    }

    getAgentCompany() {
        return this.data.agent_company;
    }

    getBuyProduct() {
        return this.data.buy_product;
    }

    getShipmentProduct() {
        return this.data.shipment_product;
    }

    getRegion() {
        return this.data.region;
    }
    getTotalAmount() {
        return this.data.total_amount;
    }
    getInitialAmount() {
        return this.data.initial_amount;
    }
    getRefundableAmount() {
        return this.data.refundable_amount;
    }

    getTransaction() {
        return this.data.transaction_summary;
    }
    getCreatedAt() {
        return formatDateTime(this.data.created_at);
    }
}

export class AgentInvoiceCollectionModel extends InvoiceCollectionCommonModel {
    private readonly invoices: AgentInvoiceModel[];
    constructor(data: IAgentInvoiceCollection) {
        super(data);
        this.invoices = data.data.map((item) => new AgentInvoiceModel(item));
    }
    getData() {
        return this.invoices;
    }
}
