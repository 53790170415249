import { useState } from "react";
import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import {
    storeExchangeAtom,
    storeExchangePreviewAtom,
    storeSingleExchangeAtom,
} from "@/lib/core-react/store/store";
import {
    IExchangeListPostRequest,
    IExchangeStoreUpdatePayload,
    IIndividualStoreExchangeUpdate,
    IRegionalExchangePreview,
} from "@/types/exchangeListCollection";
import { StoreExchangePreviewModel } from "@/models/storeExchangePreviewModel";
import { notification } from "antd";

export const useGetExchange = () => {
    const [exchange, setExchange] = useAtom(storeExchangeAtom);
    const { storeService } = useService();
    const getExchange = async (params: Record<string, any>): Promise<void> => {
        setExchange({ ...exchange, isLoading: true, error: null });
        try {
            const response = await storeService.exchangeResource.get(params);
            setExchange({
                ...exchange,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setExchange({
                ...exchange,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error?.response?.status === 403,
            });
            throw error;
        }
    };
    return { getExchange } as const;
};
export const useGetExchangeById = () => {
    const [exchange, setExchange] = useAtom(storeSingleExchangeAtom);
    const { storeService } = useService();
    const getExchangeById = async (id: number): Promise<void> => {
        setExchange({ ...exchange, isLoading: true, error: null });
        try {
            const res = await storeService.exchangeResource.getById(id);
            setExchange({
                ...exchange,
                isLoading: false,
                data: res,
                refetch: false,
            });
        } catch (error: any) {
            setExchange({
                ...exchange,
                isLoading: false,
                refetch: false,
                code: error?.response?.status,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
            });
            throw error;
        }
    };
    return { getExchangeById } as const;
};

export const useUpdateExchange = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const updateExchange = async (
        id: number,
        payload: IExchangeStoreUpdatePayload,
    ) => {
        setIsLoading(true);
        try {
            const res = await storeService.exchangeResource.update(id, payload);
            if (res) {
                setIsLoading(false);
                return res;
            }
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { updateExchange, isLoading };
};
export const useIndividualStoreExchange = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const update = async (payload: IIndividualStoreExchangeUpdate) => {
        setIsLoading(true);
        try {
            const res =
                await storeService.exchangeResource.updateIndividualStoreExchange(
                    payload,
                );
            setIsLoading(false);
            return res;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { update, isLoading };
};

// Update and Sync with rest
export const useUpdateAndSyncWithRest = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSyncLoading, setIsSyncLoading] = useState<boolean>(false);
    const [previewExchangeData, setPreviewExchangeData] = useAtom(
        storeExchangePreviewAtom,
    );

    const preview = async (payload: IRegionalExchangePreview) => {
        setIsLoading(true);
        setPreviewExchangeData({ ...previewExchangeData, isLoading: true });
        try {
            const res = await storeService.exchangeResource.preview(payload);
            setPreviewExchangeData({
                ...previewExchangeData,
                data: new StoreExchangePreviewModel({
                    ...res,
                    payload: payload,
                }),
                isLoading: false,
            });
            setIsLoading(false);
            return res;
        } catch (error: any) {
            setPreviewExchangeData({
                ...previewExchangeData,
                error: getError(error),
                isLoading: false,
            });
            setIsLoading(false);
            throw error;
        }
    };

    const defaultAndSync = async (payload: IRegionalExchangePreview) => {
        try {
            setIsSyncLoading(true);
            const res =
                await storeService.exchangeResource.defaultAndSync(payload);
            setIsSyncLoading(false);

            notification.success({
                message: res.message,
            });
            return res;
        } catch (error: any) {
            setIsSyncLoading(false);
            throw error;
        }
    };

    return { preview, defaultAndSync, isLoading, isSyncLoading };
};

export const useCreateExchange = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeData, setExchangeData] = useAtom(storeExchangeAtom);
    const createExchange = async (payload: IExchangeListPostRequest) => {
        setIsLoading(true);
        try {
            const response =
                await storeService.exchangeResource.create(payload);
            setIsLoading(false);
            setExchangeData({ ...exchangeData, refetch: true });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { createExchange, isLoading };
};

export const useCopyExchange = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeData, setExchangeData] = useAtom(storeExchangeAtom);

    const copyExchange = async (id: number, payload: { name: string }) => {
        setIsLoading(true);
        try {
            const response = await storeService.exchangeResource.copy(
                id,
                payload,
            );
            setIsLoading(false);
            setExchangeData({ ...exchangeData, refetch: true });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { copyExchange, isLoading };
};
export const useDeleteExchange = () => {
    const { storeService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [exchangeData, setExchangeData] = useAtom(storeExchangeAtom);

    const deleteExchange = async (id: number) => {
        setIsLoading(true);
        try {
            const response = await storeService.exchangeResource.delete(id);
            setIsLoading(false);
            setExchangeData({ ...exchangeData, refetch: true });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { deleteExchange, isLoading };
};
