import { useAtom, useSetAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import { useState } from "react";
import { CartItemAddPayload } from "@/types/buyOrderPlace";
import { getError } from "../utils/errors";
import {
    buyOrderPlaceAtom,
    purchaseCartCollectionAtom,
} from "@/lib/core-react/store/store";
import { PurchaseCartCollectionModel } from "@/models/PurchaseCartCollectionModel";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { getErrorCode } from "@/helpers/getErrorCode";
import { IDeleteCartItemPayload } from "@/types/PurchaseCartCollection";
export const useGetCartList = () => {
    const setPurchaseCartCollectionAtomData = useSetAtom(
        purchaseCartCollectionAtom,
    );

    const { purchaseCartService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError] = useState<string>("");
    const getCartList = async (params?: {
        user_id?: number;
        [key: string]: any;
    }) => {
        setPurchaseCartCollectionAtomData({ isLoading: true, error: null });
        try {
            const response =
                await purchaseCartService.purchaseCartResource.get(params);
            const processData =
                response && new PurchaseCartCollectionModel(response);

            setPurchaseCartCollectionAtomData({
                refetch: true,
                isLoading: false,
                data: processData,
            });
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setPurchaseCartCollectionAtomData({
                isLoading: false,
                refetch: false,
                error: getErrorMessage(error),
                code: getErrorCode(error),
            });
        }
    };

    return { getCartList, isLoading, isError };
};

export const usePlaceOrderCartProduct = () => {
    const [buyOrderPlaceAtomData, setByOrderPlaceAtomData] =
        useAtom(buyOrderPlaceAtom);

    const { purchaseCartService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const cartProduct = async (
        region: string,
        locale: string,
        payload: CartItemAddPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await purchaseCartService.purchaseCartResource.cartProduct(
                    region,
                    locale,
                    payload,
                );
            setIsLoading(false);
            setByOrderPlaceAtomData({
                ...buyOrderPlaceAtomData,
                refetch: true,
            });
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { cartProduct, isLoading, isError };
};

export const useDeleteCartProduct = () => {
    const { purchaseCartService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const deleteCartProduct = async (
        region: string,
        locale: string,
        payload: IDeleteCartItemPayload,
    ) => {
        setIsLoading(true);
        try {
            await purchaseCartService.purchaseCartResource.cartDeleteProduct(
                region,
                locale,
                payload,
            );
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };

    return { deleteCartProduct, isLoading };
};
