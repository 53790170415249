import {
    Alert,
    Card,
    Col,
    Empty,
    Form,
    Modal,
    Row,
    Space,
    Timeline,
    Typography,
    message,
} from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

import {
    TimelineTrackingModel,
    TrackingEventModel,
} from "@/models/timelineTrackingModel";
import { formatDateTime } from "@/utils/helpers";
import {
    useProductCreateComment,
    useProductDeleteComment,
    useProductUpdateComment,
    useProductReplyComment,
} from "@/lib/core-react/hooks/private";

import {
    handleCreateComment,
    handleReplyComment,
    handleUpdateComment,
    SelectLabelValue,
} from "@/components/TrackingTimeline/utils";
import { CommentEditor, RenderComment } from "@/components/TrackingTimeline";
import { tw } from "@/consts/theme/tailwindTheme";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { showError } from "@/helpers/showError";
import { useEffect, useState } from "react";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";

interface Props {
    modalWidth?: number | string;
    isInsideModal?: boolean;
    productNumber?: string;
    title: string;
    isLoading: boolean;
    productId: number;
    TimelineTrackingData: TimelineTrackingModel | undefined;
    handleTracking: (productId) => void;
}

const { Text } = Typography;

export const TimelineTracking = ({
    title,
    isLoading,
    modalWidth,
    productId,
    TimelineTrackingData,
    isInsideModal = false,
    handleTracking,
}: Props) => {
    const [form] = Form.useForm();
    const visibility = Form.useWatch("visibility", form);
    const role_ids = Form.useWatch("role_ids", form);
    const { isMobile } = useWindowWidth();

    const [_isErrorVisible, setIsErrorVisible] = useState(false);
    const [_customError, setCustomError] = useState<string | null>(null);

    const { createComment, isLoading: isLoadingCreateComment } =
        useProductCreateComment();
    const { updateComment, isLoading: isLoadingUpdateComment } =
        useProductUpdateComment();
    const { deleteComment, isLoading: isLoadingDeleteComment } =
        useProductDeleteComment();
    const { replyComment, isLoading: isLoadingReplyComment } =
        useProductReplyComment();

    const [commentContent, setCommentContent] = useState<string>("");
    const [specificUserIds, setSpecificUserIds] = useState<SelectLabelValue[]>(
        [],
    );
    const [roleIds, setRoleIds] = useState<SelectLabelValue[]>([]);
    const [selectedCommentIdToUpdate, setSelectedCommentIdToUpdate] =
        useState<number>();
    const [selectedCommentIdToReply, setSelectedCommentIdToReply] =
        useState<number>();

    useEffect(() => handleTracking(productId), []);

    const handleCreateCommentCall = async () => {
        try {
            await handleCreateComment({
                specificUserIds,
                setSpecificUserIds,
                commentContent,
                TimelineTrackingData,
                createComment,
                form,
                setCommentContent,
                message,
                setIsErrorVisible,
                setCustomError,
                visibility,
                role_ids,
                productId,
            });

            form.resetFields();
            handleTracking(productId);
        } catch (error) {
            showError(error, form);
        }
    };

    const handleUpdateCommentCall = async () => {
        try {
            await handleUpdateComment({
                specificUserIds,
                setSpecificUserIds,
                commentContent,
                TimelineTrackingData,
                selectedCommentIdToUpdate,
                updateComment,
                form,
                setCommentContent,
                setSelectedCommentIdToUpdate,
                message,
                setIsErrorVisible,
                setCustomError,
                visibility,
                role_ids,
                productId,
            });
            handleTracking(productId);
        } catch (error) {
            showError(error, form);
        }
    };

    const handleDeleteCommentCall = async (data: TrackingEventModel) => {
        try {
            await deleteComment(data.getId(), productId);
            message.success("Comment deleted successfully");
            handleTracking(productId);
        } catch (error) {
            showError(error, form);
        }
    };

    const handleReplyCommentCall = async () => {
        try {
            await handleReplyComment({
                commentContent,
                setCommentContent,
                selectedCommentIdToReply,
                setSelectedCommentIdToReply,
                replyComment,
                form,
                message,
                setIsErrorVisible,
                setCustomError,
                productId,
            });
        } catch (error) {
            showError(error);
        }
    };

    return (
        <>
            <div>
                <Row gutter={[24, 24]}>
                    <Col span={isMobile ? 24 : isInsideModal ? 8 : 24}>
                        <Card
                            type="inner"
                            title={title}
                            bordered
                            style={{
                                width: modalWidth ?? "100%",
                                borderRadius: tw.borderRadius.lg,
                                boxShadow: tw.boxShadow.DEFAULT,
                            }}
                        >
                            <CommentEditor
                                mode="create"
                                form={form}
                                roleIds={roleIds}
                                specificUserIds={specificUserIds}
                                setSpecificUserIds={setSpecificUserIds}
                                content={commentContent}
                                setContent={setCommentContent}
                                onSubmit={handleCreateCommentCall}
                                loading={isLoadingCreateComment}
                            />
                        </Card>
                    </Col>
                    <Col span={isMobile ? 24 : isInsideModal ? 16 : 24}>
                        <div
                            style={{
                                marginTop: isInsideModal ? 0 : 24,
                                border: tw.border.DEFAULT,
                                borderRadius: tw.borderRadius.lg,
                                paddingTop: tw.spacing[4],
                                paddingLeft: tw.spacing[4],
                                paddingRight: tw.spacing[4],
                                paddingBottom: tw.spacing[0],
                                minHeight: 418,
                            }}
                        >
                            {isLoading ? (
                                LoaderSmall
                            ) : TimelineTrackingData &&
                              TimelineTrackingData.getTrackingEventsData()
                                  .length > 0 ? (
                                <Timeline
                                    mode="left"
                                    items={TimelineTrackingData.getTrackingEventsData().map(
                                        (data) => ({
                                            dot:
                                                data.getTemplate() &&
                                                !data.getMessage() ? (
                                                    <ClockCircleOutlined
                                                        style={{
                                                            fontSize: "16px",
                                                        }}
                                                    />
                                                ) : undefined,
                                            label:
                                                data.getTemplate() &&
                                                data.getMessage() ? (
                                                    <Space direction="vertical">
                                                        <Text type="secondary">
                                                            {formatDateTime(
                                                                data.getCreatedAt(),
                                                            )}
                                                        </Text>
                                                        <Text strong>
                                                            {data.getCompletedTemplate()}
                                                        </Text>
                                                    </Space>
                                                ) : (
                                                    <Text type="secondary">
                                                        {formatDateTime(
                                                            data.getCreatedAt(),
                                                        )}
                                                    </Text>
                                                ),
                                            key: data.getId(),
                                            children:
                                                data.getTemplate() &&
                                                !data.getMessage() ? (
                                                    <Text strong>
                                                        {data.getCompletedTemplate()}
                                                    </Text>
                                                ) : data.getMessage() ? (
                                                    <RenderComment
                                                        data={data}
                                                        handleDeleteComment={
                                                            handleDeleteCommentCall
                                                        }
                                                        isLoadingDeleteComment={
                                                            isLoadingDeleteComment
                                                        }
                                                        setCommentContent={
                                                            setCommentContent
                                                        }
                                                        setSpecifiedUserIds={
                                                            setSpecificUserIds
                                                        }
                                                        setRoleIds={setRoleIds}
                                                        setSelectedCommentIdToUpdate={
                                                            setSelectedCommentIdToUpdate
                                                        }
                                                        setSelectedCommentIdToReply={
                                                            setSelectedCommentIdToReply
                                                        }
                                                    />
                                                ) : (
                                                    ""
                                                ),
                                        }),
                                    )}
                                />
                            ) : (
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{ height: 60, marginTop: 32 }}
                                    description={
                                        <Alert
                                            message="      No timeline has been created yet. Please
                                        create timeline by comment."
                                            type="info"
                                        />
                                    }
                                ></Empty>
                            )}
                        </div>
                    </Col>
                </Row>

                {/* Comment edit modal */}
                {selectedCommentIdToUpdate && (
                    <Modal
                        title="Update Comment"
                        centered
                        open={Boolean(selectedCommentIdToUpdate)}
                        onCancel={() => {
                            setCommentContent("");
                            setSelectedCommentIdToUpdate(undefined);
                        }}
                        footer={null}
                    >
                        <CommentEditor
                            mode="update"
                            form={form}
                            roleIds={roleIds}
                            specificUserIds={specificUserIds}
                            setSpecificUserIds={setSpecificUserIds}
                            content={commentContent}
                            setContent={setCommentContent}
                            onSubmit={handleUpdateCommentCall}
                            loading={isLoadingUpdateComment}
                            onCancel={() =>
                                setSelectedCommentIdToUpdate(undefined)
                            }
                        />
                    </Modal>
                )}

                {/* Comment reply modal */}
                {selectedCommentIdToReply && (
                    <Modal
                        title="Leave a reply"
                        centered
                        open={Boolean(selectedCommentIdToReply)}
                        onCancel={() => {
                            setCommentContent("");
                            setSelectedCommentIdToReply(undefined);
                        }}
                        footer={null}
                    >
                        <CommentEditor
                            mode="reply"
                            form={form}
                            roleIds={roleIds}
                            specificUserIds={specificUserIds}
                            setSpecificUserIds={setSpecificUserIds}
                            content={commentContent}
                            setContent={setCommentContent}
                            onSubmit={handleReplyCommentCall}
                            loading={isLoadingReplyComment}
                            onCancel={() =>
                                setSelectedCommentIdToReply(undefined)
                            }
                        />
                    </Modal>
                )}
            </div>
        </>
    );
};
