import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { IStoreFormData, IStoreUpdateFormData } from "@/types/storeCollection";

class StoreResource extends BaseResource {
    path = `/api/store/admin/store/v1/stores`;

    sync(customHeaders: Record<string, any> = {}): ResponsePromise {
        const path = `${this.path}/sync-to-other-service`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    create(
        payload: IStoreFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    update(
        id: number,
        payload: IStoreUpdateFormData,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    delete(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, undefined, {}, customHeaders);
    }
}

export default StoreResource;
