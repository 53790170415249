import React from "react";
import { Image } from "antd";
import StarIconFull from "/assets/star/star-icon-full.svg";
import StarIconHalf from "/assets/star/star-icon-half.svg";
import StarIconEmpty from "/assets/star/star-icon-empty.svg";

type RatingProps = {
    rating: number;
    width: number;
};

const Ratings: React.FC<RatingProps> = ({ rating, width }) => {
    const fullStars = Math.floor(rating);
    const decimal = parseFloat((rating - fullStars).toFixed(2));

    const stars: React.ReactNode[] = [];

    // Add full stars
    for (let i = 0; i < fullStars; i++) {
        stars.push(
            <Image
                key={`full${i}`}
                src={StarIconFull}
                width={width}
                alt="rating"
                preview={false}
            />,
        );
    }

    // Add half star if needed
    if (decimal >= 0.1 && decimal <= 0.99) {
        stars.push(
            <Image
                key={`half${decimal}`}
                src={StarIconHalf}
                width={width}
                alt="rating"
                preview={false}
            />,
        );
    }

    // Add empty stars for the remaining
    for (let i = stars.length; i < 5; i++) {
        stars.push(
            <Image
                key={`empty${i}`}
                src={StarIconEmpty}
                width={width}
                alt="rating"
                preview={false}
            />,
        );
    }

    return <div style={{ display: "flex", gap: "4px" }}>{stars}</div>;
};

export default Ratings;
