import { NodeInputProps } from "./helpers";
import TextInput from "../custom-components/TextInput";
import { Typography } from "antd";

export function NodeInputDefault(props: NodeInputProps) {
    const { node, attributes, value = "", setValue, disabled } = props;

    const onClick = () => {
        if (attributes.onclick) {
            const run = new Function(attributes.onclick);
            run();
        }
    };

    return (
        <TextInput
            title={node.meta.label?.text}
            onClick={onClick}
            onChange={(e) => {
                setValue(e.target.value);
            }}
            type={attributes.type}
            name={attributes.name}
            value={value}
            disabled={attributes.disabled || disabled}
            state={
                node.messages.find(({ type }) => type === "error")
                    ? "error"
                    : undefined
            }
            subtitle={
                <>
                    <br />
                    {node.messages.map(({ text, id }, k) => (
                        <Typography.Text
                            type={
                                node.messages.find(
                                    ({ type }) => type === "error",
                                )
                                    ? "danger"
                                    : undefined
                            }
                            key={`${id}-${k}`}
                            data-testid={`ui/message/${id}`}
                        >
                            {text}
                        </Typography.Text>
                    ))}
                </>
            }
        />
    );
}
