import { useAtom } from "jotai";
import { paymentGatewayCollectionAtom } from "@/lib/core-react/store/store";
import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import { useState } from "react";
import { IPaymentGatewayFormData } from "@/types/paymentGatewayCollection";
import { notification } from "antd";

export const useGetPaymentGateway = () => {
    const [paymentGateways, setPaymentGateways] = useAtom(
        paymentGatewayCollectionAtom,
    );

    const { gatewayService } = useService();

    const getPaymentGateway = async (params?: string): Promise<void> => {
        setPaymentGateways({
            ...paymentGateways,
            isLoading: true,
            error: null,
        });
        try {
            const response = await gatewayService.gatewayResource.get(params);
            setPaymentGateways({
                ...paymentGateways,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPaymentGateways({
                ...paymentGateways,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            throw error;
        }
    };

    return { getPaymentGateway } as const;
};

export const useCreatePaymentGateway = () => {
    const { gatewayService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [paymentGateways, setPaymentGateways] = useAtom(
        paymentGatewayCollectionAtom,
    );

    const createPaymentGateway = async (payload: IPaymentGatewayFormData) => {
        setIsLoading(true);
        try {
            const response =
                await gatewayService.gatewayResource.create(payload);
            setIsLoading(false);
            setPaymentGateways({ ...paymentGateways, refetch: true });
            notification.success({ message: "Gateway create successfully" });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createPaymentGateway, isLoading, isError };
};

export const useUpdateUpdatePaymentGateway = () => {
    const { gatewayService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [paymentGateways, setPaymentGateways] = useAtom(
        paymentGatewayCollectionAtom,
    );

    const updatePaymentGateway = async (
        id: number,
        payload: IPaymentGatewayFormData,
    ) => {
        setIsLoading(true);
        try {
            const response = await gatewayService.gatewayResource.update(
                id,
                payload,
            );
            setIsLoading(false);
            setPaymentGateways({ ...paymentGateways, refetch: true });
            notification.success({ message: response.message });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updatePaymentGateway, isLoading, isError };
};
