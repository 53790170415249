import { IFilterType } from "@/types/filters";
import { PaginationModel } from "./paginationModel";
import { BuyProductCommissionRateTypeEnum } from "@/enums/purchaseCommissionCollectionEnum";
import {
    IPurchaseCommission,
    IPurchaseCommissionCollection,
    IPurchaseCommissionStore,
} from "@/types/purchaseCommissionCollection";

export class PurchaseCommissionStoreModel {
    object: string;
    id: number;
    name: string;
    identifier: string;
    availability_status: string;

    constructor(data: IPurchaseCommissionStore) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.identifier = data.identifier;
        this.availability_status = data.availability_status;
    }

    getId = () => this.id;
    getName = () => this.name;
    getIdentifier = () => this.identifier;
    getAvailabilityStatus = () => this.availability_status;
}

export class PurchaseCommissionModel {
    object: string;
    id: number;
    commission_rate: string;
    commission_rate_type: BuyProductCommissionRateTypeEnum;
    created_at: string | null;
    //extended
    store?: PurchaseCommissionStoreModel;

    constructor(dataItem: IPurchaseCommission) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.commission_rate = dataItem.commission_rate;
        this.commission_rate_type = dataItem.commission_rate_type;
        this.created_at = dataItem.created_at;
        this.store =
            dataItem.store && new PurchaseCommissionStoreModel(dataItem.store);
    }

    getId = () => this.id;
    getCommissionRate = () => this.commission_rate;
    getCommissionRateType = () => this.commission_rate_type;
    getCreatedAt = () => this.created_at;
    getStore = () => this.store;

    getStoreAndCommission() {
        const storeName = this.getStore()?.getName();
        const commission = this.getCommissionRate();
        const commissionType =
            this.getCommissionRateType() ===
            BuyProductCommissionRateTypeEnum.PERCENTAGE
                ? "%"
                : "";
        return `${storeName} (${commission}${commissionType})`;
    }
}

export class PurchaseCommissionCollectionModel {
    object: string;
    data: PurchaseCommissionModel[];
    pagination: PaginationModel;
    filters: IFilterType;
    constructor(data: IPurchaseCommissionCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new PurchaseCommissionModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData = () => this.data;
    getPagination = () => this.pagination;
    getFilters = () => this.filters;
}
