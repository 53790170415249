import { PageHeader } from "@ant-design/pro-layout";
import {
    Radio,
    Button,
    Card,
    Col,
    List,
    Modal,
    Row,
    Skeleton,
    Typography,
    Table,
    Tag,
    Dropdown,
    message,
    Tabs,
    Badge,
    notification,
} from "antd";

import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { Link, useNavigate } from "react-router-dom";

import useDataFilters from "@/hooks/useDataFilters";
import { GetApiHelperModel } from "@/models/apiHelper";
import {
    storeExchangeAtom,
    storeExchangePreviewAtom,
} from "@/lib/core-react/store/store";
import {
    useDeleteExchange,
    useGetExchange,
    useUpdateAndSyncWithRest,
} from "@/lib/core-react/hooks/private/useExchange";
import {
    ExchangeListCollectionModel,
    ExchangeListModel,
    RegionalExchangeModel,
    StoreExchangeModel,
} from "@/models/exchangeListCollectionModel";
import { IFilterType } from "@/types/filters";
import CopyExchange from "./components/CopyExchange";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import { CommissionRateTypeEnums } from "../../../enums/exchangeListCollectionEnums";
import {
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import UpdateExchange from "./components/UpdateExchange";
import UpdateAndSync from "./components/UpdateAndSync";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_STORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-store-enum";
import CommonError from "@/components/Error/CommonError";
import { getError } from "@/lib/core-react/hooks/utils/errors";

const { confirm } = Modal;
const { Text } = Typography;
type ModalForType =
    | "exchange-update"
    | "exchange-copy"
    | "update-and-sync-default";

const StoreExchange = () => {
    const { getExchange } = useGetExchange();
    const navigate = useNavigate();
    const { deleteExchange } = useDeleteExchange();
    const [
        {
            data: exchangeStoreData,
            isLoading,
            refetch,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(storeExchangeAtom);
    const { defaultAndSync, isSyncLoading } = useUpdateAndSyncWithRest();
    const [previewExchangeData, setPreviewExchangeData] = useAtom(
        storeExchangePreviewAtom,
    );

    const [selectedExchangeStore, setSelectedExchangeStore] = useState<
        StoreExchangeModel | undefined
    >(undefined);
    const [selectedRegionData, setSelectedRegionData] = useState<
        RegionalExchangeModel | undefined
    >(undefined);
    const [modalFor, setModalFor] = useState<ModalForType>("exchange-update");
    const ExchangeListCollectionData =
        exchangeStoreData && new ExchangeListCollectionModel(exchangeStoreData);

    const { isFirstCall, isFetched, initializeAvailableFilter } =
        useDataFilters();
    const [selectedExchange, setSelectedExchange] = useState<
        ExchangeListModel | undefined
    >(undefined);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const { isMobile } = useWindowWidth();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            GetApiHelperModel.makeGetRequest({}, getExchange);
        }
    }, [isFirstCall, isFetched, refetch]);

    // Filter
    const filterData = ExchangeListCollectionData?.getFilters();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && ExchangeListCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        ExchangeListCollectionData?.getFilters(),
    ]);

    useEffect(() => {
        if (selectedExchange) {
            setSelectedRegionData(
                selectedExchange.getRegionalExchanges().getData()[0],
            );
        }
    }, [selectedExchange]);

    useEffect(() => {
        if (exchangeStoreData) {
            const collections = new ExchangeListCollectionModel(
                exchangeStoreData,
            );
            setSelectedExchange(collections.getData()[0]);
        }
    }, [exchangeStoreData]);

    const onChangeExchange = (data: ExchangeListModel) => {
        setSelectedExchange(data);
    };

    const getModelTitle = (modelFor: ModalForType) => {
        switch (modelFor) {
            case "exchange-update":
                return `Update Exchange (${selectedExchangeStore?.getStore().name})`;
            case "exchange-copy":
                return "Copy Exchange";
            case "update-and-sync-default":
                return previewExchangeData?.data ? (
                    <Tag color="cyan">Preview changes </Tag>
                ) : (
                    `Update  (${selectedExchangeStore?.getStore().name})`
                );
            default:
                return "";
        }
    };

    const handleConfirmDefaultSyncAndUpdated = async () => {
        const payload = previewExchangeData?.data?.getPayload();
        if (payload) {
            try {
                await defaultAndSync(payload);
                await getExchange({});
                setPreviewExchangeData((pre) => ({ ...pre, data: undefined }));
                setIsShowModal(false);
            } catch (error) {
                notification.error({
                    message: getError(error),
                });
            }
        }
    };

    const getModalContent = () => {
        if (
            modalFor === "exchange-update" &&
            selectedExchangeStore &&
            selectedExchange
        ) {
            return (
                <UpdateExchange
                    selectedExchange={selectedExchange}
                    setIsShowUpdateModal={setIsShowModal}
                    selectedStore={selectedExchangeStore}
                />
            );
        } else if (modalFor === "exchange-copy" && selectedExchange) {
            return (
                <CopyExchange
                    selectedExchange={selectedExchange}
                    setIsShowCopyModal={setIsShowModal}
                />
            );
        } else if (
            modalFor === "update-and-sync-default" &&
            selectedExchangeStore &&
            selectedExchange &&
            selectedRegionData
        ) {
            return (
                <UpdateAndSync
                    selectedExchange={selectedExchange}
                    setIsShowUpdateModal={setIsShowModal}
                    selectedStore={selectedExchangeStore}
                    selectedRegion={selectedRegionData}
                />
            );
        } else {
            return <div>Please select a proper action </div>;
        }
    };

    const exchangeColumns = [
        {
            title: "ID",
            dataIndex: "id",
            width: 70,
            key: "exchange-id",
            render: (_: string, record: StoreExchangeModel) => {
                return <div key={record.getId()}>{record.getId()}</div>;
            },
        },
        {
            title: "Store",
            dataIndex: "store",
            key: "exchange-store",
            render: (_: string, record: StoreExchangeModel) => {
                return (
                    <div key={record.getId()}>
                        {record.getStore().getName()}
                    </div>
                );
            },
        },
        {
            title: "FX Rate",
            dataIndex: "fx_rate",
            hidden: isMobile,
            key: "exchange-fx-rate",
            render: (_: string, record: StoreExchangeModel) => {
                return <div key={record.getId()}>{record.getFxRate()}</div>;
            },
        },
        {
            title: "Commission Rate",
            dataIndex: "commission_rate",
            hidden: isMobile,
            key: "exchange-commission-rate",
            render: (_: string, record: StoreExchangeModel) => {
                return (
                    <div key={record.getId()}>
                        {record.getCommissionRate()}{" "}
                        {record.getCommissionRateType() ===
                        CommissionRateTypeEnums.PERCENTAGE
                            ? "%"
                            : ""}{" "}
                    </div>
                );
            },
        },
        {
            title: "Total",
            dataIndex: "commission_rate_type",
            hidden: isMobile,
            key: "exchange-commission-rate-total",
            render: (_: string, record: StoreExchangeModel) => {
                return (
                    <Tag key={record.getId()} color="green">
                        {record.getTotal()}
                    </Tag>
                );
            },
        },
        {
            title: "Exchange Rates",
            hidden: !isMobile,
            key: "exchange-rates",
            render: (_: string, record: StoreExchangeModel) => {
                return (
                    <div
                        key={record.getId()}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            minWidth: "150px",
                        }}
                    >
                        <div style={{ marginBottom: "4px" }}>
                            <Text strong style={{ marginRight: "5px" }}>
                                FX Rate:
                            </Text>
                            <Text>{record.getFxRate()}</Text>
                        </div>
                        <div style={{ marginBottom: "4px" }}>
                            <Text strong style={{ marginRight: "5px" }}>
                                Commission:
                            </Text>
                            <Text>
                                {record.getCommissionRate()}{" "}
                                {record.getCommissionRateType() ===
                                CommissionRateTypeEnums.PERCENTAGE
                                    ? "%"
                                    : ""}
                            </Text>
                        </div>
                        <div>
                            <Text strong style={{ marginRight: "5px" }}>
                                Total:
                            </Text>
                            <Tag color="green">{record.getTotal()}</Tag>
                        </div>
                    </div>
                );
            },
        },

        {
            title: "Minimum Order Amount",
            dataIndex: ["options", "minimum_order_amount"],
            key: "commission-rate-rate",
            render: (_: string, record: StoreExchangeModel) => {
                return (
                    <div key={record.getId()}>
                        {record.getMinimumOrderAmount()}
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            hidden: !checkActionPermission(
                [ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_MANAGE],
                "checkPermission",
                null,
            ),
            fixed: isMobile ? "right" : undefined,
            align: "center" as const,
            width: 100,
            render: (_: string, record: StoreExchangeModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_MANAGE,
                        label: "Update",
                        key: "update",
                        icon: <EditOutlined />,
                        onClick: async () => {
                            setSelectedExchangeStore(record);
                            setIsShowModal(true);
                            setModalFor("exchange-update");
                        },
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                ];

                if (selectedExchange?.getIsDefault()) {
                    items.push({
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_MANAGE,
                        label: "Update and Sync with rest",
                        key: "update-rate",
                        icon: <EditOutlined />,
                        onClick: async () => {
                            setSelectedExchangeStore(record);
                            setIsShowModal(true);
                            setModalFor("update-and-sync-default");
                        },
                        style: { margin: "5px", padding: "8px 16px" },
                    });
                }

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    // Delete exchange

    const deleteExchangeListHandler = () => {
        confirm({
            title: "Do you want to delete this item ?",
            icon: <ExclamationCircleOutlined />,
            content: "Once deleted, the item cannot be recovered.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            async onOk() {
                if (selectedExchange) {
                    try {
                        const res = await deleteExchange(
                            selectedExchange.getId(),
                        );
                        if (res) {
                            message.success(res.message);
                        }
                    } catch (e: any) {
                        const errorMessage = e?.response?.data?.message
                            ? e?.response?.data?.message
                            : e.message;
                        message.error(errorMessage);
                    }
                }
            },
            onCancel() {},
        });
    };

    // Error handle
    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    const renderExchangeItem = (item: ExchangeListModel) => {
        const isDefault = item.getIsDefault();

        const renderExchangeRegions = (regions) => {
            return regions.getData().map((exchange, index, allValue) => (
                <small key={exchange.getRegion().name}>
                    {exchange.getRegion().name}
                    {index < allValue.length - 1 ? ", " : ""}
                </small>
            ));
        };

        const renderButtons = (item) => (
            <div>
                {!isDefault &&
                    checkActionPermission(
                        ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_MANAGE,
                        <>
                            <Button
                                shape="default"
                                type="text"
                                icon={<EditOutlined />}
                                onClick={() =>
                                    navigate(
                                        `/store/store-fx/update/${item.getId()}`,
                                    )
                                }
                                style={{ marginRight: "10px" }}
                            />
                            <Button
                                shape="default"
                                type="text"
                                onClick={deleteExchangeListHandler}
                                icon={<DeleteOutlined />}
                                style={{ color: "red" }}
                            />
                        </>,
                        null,
                    )}
            </div>
        );

        return (
            <List.Item
                style={{
                    padding: "15px 0px",
                    display: "flex",
                    alignItems: "center",
                    background: "white",
                    boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 0px",
                    border: "1px solid rgb(239 234 234)",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    cursor: "pointer",
                }}
                key={item.getId()}
                onClick={() => onChangeExchange(item)}
            >
                <Skeleton title={false} loading={isLoading} active>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {/* Radio Button */}
                        <Radio
                            checked={
                                selectedExchange &&
                                selectedExchange.getId() === item.getId()
                            }
                            onChange={() => onChangeExchange(item)}
                            style={{ marginLeft: "16px" }}
                        />

                        <div style={{ textAlign: "left", flex: 1 }}>
                            {/* Display Name */}
                            <div
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                                {item.getName()}
                            </div>
                            <div>
                                {renderExchangeRegions(
                                    item.getRegionalExchanges(),
                                )}
                            </div>
                        </div>

                        {/* Edit and Delete buttons */}
                        {renderButtons(item)}
                    </div>
                </Skeleton>
            </List.Item>
        );
    };

    return (
        <>
            <PageHeader
                ghost={false}
                title="Exchange List"
                style={{ marginTop: "10px" }}
                extra={[
                    checkActionPermission(
                        ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_MANAGE,
                        <Button
                            onClick={() => {
                                setIsShowModal(true);
                                setModalFor("exchange-copy");
                            }}
                            key="copy-commission-for-another-region"
                            type="default"
                            icon={<CopyOutlined />}
                        >
                            Create a Copy
                        </Button>,

                        null,
                    ),
                ]}
                onBack={() => window.history.back()}
            >
                <Row gutter={[16, 16]}>
                    <Col md={24} lg={7}>
                        <Card
                            extra={
                                <div>
                                    {checkActionPermission(
                                        ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_MANAGE,
                                        <Link
                                            key={1}
                                            to="/store/store-fx/create"
                                        >
                                            <Button
                                                style={{ marginLeft: "10px" }}
                                                type="text"
                                                key="create-exchange-button"
                                                icon={<PlusOutlined />}
                                            >
                                                Add
                                            </Button>
                                        </Link>,
                                        <></>,
                                    )}
                                </div>
                            }
                            style={{ margin: "10px 0px", height: "98%" }}
                            title="Exchange list"
                        >
                            <List
                                loading={isLoading}
                                itemLayout="vertical"
                                dataSource={ExchangeListCollectionData?.getData()}
                                renderItem={(item) =>
                                    item.getIsDefault() ? (
                                        <Badge.Ribbon text="Default">
                                            {renderExchangeItem(item)}
                                        </Badge.Ribbon>
                                    ) : (
                                        renderExchangeItem(item)
                                    )
                                }
                            />
                        </Card>
                    </Col>

                    {selectedExchange && (
                        <Col md={24} lg={16}>
                            <Card style={{ margin: "10px 0px" }} title="Region">
                                <Col style={{ marginLeft: "16px" }}>
                                    <Tabs
                                        defaultActiveKey={String(
                                            selectedExchange
                                                .getRegionalExchanges()
                                                .getData()[0]
                                                ?.getId(),
                                        )}
                                        onChange={(key) => {
                                            const region = selectedExchange
                                                .getRegionalExchanges()
                                                .getData()
                                                .find(
                                                    (region) =>
                                                        region.getId() ===
                                                        Number(key),
                                                );
                                            setSelectedRegionData(region);
                                        }}
                                    >
                                        {selectedExchange
                                            .getRegionalExchanges()
                                            .getData()
                                            .map((region) => (
                                                <Tabs.TabPane
                                                    tab={
                                                        region.getRegion().name
                                                    }
                                                    key={region.getId()}
                                                ></Tabs.TabPane>
                                            ))}
                                    </Tabs>
                                    {selectedExchange && selectedRegionData && (
                                        <Table
                                            rowKey="id"
                                            bordered
                                            loading={isLoading}
                                            dataSource={selectedRegionData
                                                .getStoreExchanges()
                                                .getData()}
                                            //@ts-ignore
                                            columns={exchangeColumns}
                                            scroll={{ x: 1000 }}
                                            pagination={{ pageSize: 20 }}
                                        />
                                    )}
                                </Col>
                            </Card>
                        </Col>
                    )}
                </Row>
            </PageHeader>

            {selectedExchange && (
                <Modal
                    title={getModelTitle(modalFor)}
                    open={isShowModal}
                    destroyOnClose={true}
                    footer={
                        previewExchangeData?.data
                            ? [
                                  <Button
                                      key="back"
                                      onClick={() =>
                                          setPreviewExchangeData((pre) => ({
                                              ...pre,
                                              data: undefined,
                                          }))
                                      }
                                  >
                                      Back
                                  </Button>,
                                  <Button
                                      loading={isSyncLoading}
                                      disabled={isSyncLoading}
                                      onClick={
                                          handleConfirmDefaultSyncAndUpdated
                                      }
                                      key="sync"
                                      type="primary"
                                  >
                                      Confirm
                                  </Button>,
                              ]
                            : false
                    }
                    onCancel={() => setIsShowModal(false)}
                    width={previewExchangeData?.data ? "50vw" : undefined}
                >
                    {getModalContent()}
                </Modal>
            )}
        </>
    );
};

export default StoreExchange;
