import React from "react";
import { CURRENT } from "./renderAuthorize";
import { getIsCurrentUserSuperAdmin } from "../../utils/helper";
import { RegionCode } from "@/enums/regionCollectionEnums";

export type IAuthorityType =
    | undefined
    | string
    | string[]
    | ((currentAuthority: string | string[]) => IAuthorityType);

/**
 * General permission check method
 * Common check permissions method
 * @param {Permission judgment} authority
 * @param {Your permission | Your permission description} currentAuthority
 * @param {Passing components} target
 * @param {no pass components | no pass components} Exception
 */
const checkPermissions = <T, K>(
    authority: IAuthorityType,
    currentAuthority: string | string[],
    target: T,
    Exception: K,
): T | K | React.ReactNode => {
    // No judgment permission. View all by default
    // Retirement authority, return target;
    if (!authority) {
        return authority;
    }
    // Array processing
    if (Array.isArray(authority)) {
        if (Array.isArray(currentAuthority)) {
            if (currentAuthority.some((item) => authority.includes(item))) {
                return target;
            }
        } else if (authority.includes(currentAuthority)) {
            return target;
        }
        return Exception;
    }

    // Deal with string
    if (typeof authority === "string") {
        if (Array.isArray(currentAuthority)) {
            if (currentAuthority.some((item) => authority === item)) {
                return target;
            }
        } else if (authority === currentAuthority) {
            return target;
        }
        return Exception;
    }

    //Deal with function
    if (typeof authority === "function") {
        const bool = authority(currentAuthority);
        if (bool) {
            return target;
        }
        return Exception;
    }
    throw new Error("unsupported parameters");
};

export { checkPermissions };

function checkActionPermission<T, K>(
    authority: IAuthorityType,
    target: T,
    Exception: K,
): T | K | React.ReactNode {
    if (getIsCurrentUserSuperAdmin()) {
        return target;
    } else {
        const general = checkPermissions<T, K>(
            authority,
            CURRENT,
            target,
            Exception,
        );

        if (general) {
            return target;
        }

        for (const region of Object.values(RegionCode)) {
            if (
                checkPermissions<T, K>(
                    getRegionalAuthority(authority, region),
                    CURRENT,
                    target,
                    Exception,
                )
            ) {
                return target;
            }
        }
        return Exception;
    }
}

const getRegionalAuthority = (authority: IAuthorityType, region: string) => {
    if (Array.isArray(authority)) {
        // eslint-disable-next-line prefer-const
        let processed = [];
        // eslint-disable-next-line prefer-const
        for (let _authority of authority) {
            // @ts-ignore
            processed.push(`${region}:${_authority}`);
        }
        return processed;
    }

    if (typeof authority === "string") {
        return `${region}:${authority}`;
    }
};

export default checkActionPermission;
