import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Table,
    Button,
    Row,
    Col,
    Space,
    Modal,
    Typography,
    Flex,
    notification,
    Form,
} from "antd";
import {
    ArrowRightOutlined,
    DiffOutlined,
    EditOutlined,
    PartitionOutlined,
    SafetyCertificateOutlined,
    TeamOutlined,
    WalletOutlined,
} from "@ant-design/icons";

import { useGetAgentCompany } from "@/lib/core-react/hooks/private";
import { agentCompanyCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import {
    AgentCompanyCollectionModel,
    AgentCompanyModel,
} from "@/models/agentCompanyCollectionModel";
import { PaginationModel } from "@/models/pagination";
import { IFilterType } from "@/types/filters";

import FiltersComponent from "@/components/FiltersComponent";
import {
    MembersTable,
    PurchaseCommissionTable,
    ShippingPointsTable,
    VerificationsTable,
} from "./components";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_AGENT_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { ADMIN_WALLET_PERMISSION_ENUM } from "@/consts/permission-enum/wallet-enum";
import { StatusTag } from "@/components";
import { ViewDetails } from "./components/viewDetails";
import { formatString } from "@/utils/helper";
import CommonError from "@/components/Error/CommonError";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import ModificationAgentCompany from "./components/ModificationAgentCompany";
import { useUpdateAgentCompany } from "@/lib/core-react/hooks/private/useAgentCompany";
import { showError } from "@/helpers/showError";
import { IAgentCompanyUpdate } from "@/types/agentCompanyCollection";

const AgentCompany = () => {
    const { getAgentCompanies } = useGetAgentCompany();
    const { updateAgentCompany, isLoading: updateAgentCompanyloading } =
        useUpdateAgentCompany();
    const [
        {
            data: agentCompanyCollectionData,
            isLoading,
            unAuthorized,
            refetch,
            error,
        },
    ] = useAtom(agentCompanyCollectionAtom);

    const { isMobile } = useWindowWidth();

    const navigate = useNavigate();

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        handelSubFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    const [form] = Form.useForm();

    const handleClick = (id: number) => {
        navigate(`/agent/company/${id}/wallet`);
    };

    const AgentCompanyCollectionData =
        agentCompanyCollectionData &&
        new AgentCompanyCollectionModel(agentCompanyCollectionData);

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // Filter
    const filterData = AgentCompanyCollectionData?.getFilters();
    // Pagination
    const paginationData = AgentCompanyCollectionData?.getPagination();

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && AgentCompanyCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        AgentCompanyCollectionData?.getFilters(),
    ]);

    const [selectedCompany, setSelectedCompany] = useState<
        AgentCompanyModel | undefined
    >(undefined);

    const [modalOpen, setModalOpen] = useState<
        | "members"
        | "shippingPoints"
        | "verifications"
        | "commission"
        | "viewDetails"
        | "agentCompanyEdit"
        | undefined
    >(undefined);

    const handleEdit = (record: AgentCompanyModel) => {
        setSelectedCompany(record);
        setModalOpen("agentCompanyEdit");
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAgentCompanies,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        AgentCompanyCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
    };

    const onSubmit = async (values: IAgentCompanyUpdate) => {
        try {
            await updateAgentCompany(
                values,
                selectedCompany?.getId() as number,
            );
            setModalOpen(undefined);
            notification["success"]({
                message: "Successfully Update Agent Company",
            });
        } catch (error) {
            showError(error, form);
        }
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            width: 70,
            align: "center" as const,
            render: (_: string, __: AgentCompanyModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Company info",
            dataIndex: "primary_name",
            key: "primary_name",
            render: (_: string, record: AgentCompanyModel) => {
                return (
                    <div style={{ position: "relative", padding: "16px" }}>
                        {checkActionPermission(
                            ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_MANAGE,

                            <EditOutlined
                                onClick={() => handleEdit(record)}
                                style={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                    fontSize: "16px",
                                    color: "#1890ff",
                                    cursor: "pointer",
                                }}
                            />,
                            null,
                        )}

                        {/* Company Info Content */}
                        <Space direction="vertical">
                            <Space>
                                <Typography.Text strong>
                                    Company Name:{" "}
                                </Typography.Text>
                                <Typography.Text copyable>
                                    {record.getPrimaryName()}
                                </Typography.Text>
                            </Space>
                            <Space>
                                <Typography.Text strong>
                                    Visible Name:{" "}
                                </Typography.Text>
                                <Typography.Text>
                                    {record.getSecondaryName()}
                                </Typography.Text>
                            </Space>
                            <Space>
                                <Typography.Text strong>
                                    Registered country:{" "}
                                </Typography.Text>
                                {record.getRegisteredCountryDetail() &&
                                record
                                    .getRegisteredCountryDetail()
                                    ?.getName() ? (
                                    <Typography.Text
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {record
                                            .getRegisteredCountryDetail()
                                            ?.getName()}
                                    </Typography.Text>
                                ) : (
                                    ""
                                )}
                            </Space>
                            <Space>
                                <Typography.Text strong>Type: </Typography.Text>
                                <Typography.Text
                                    style={{ textTransform: "capitalize" }}
                                >
                                    {record.getCompanyType()
                                        ? record
                                              .getCompanyType()
                                              .replaceAll("-", " ")
                                        : ""}
                                </Typography.Text>
                            </Space>
                            <Space>
                                <a
                                    onClick={() => {
                                        setSelectedCompany(record);
                                        setModalOpen("viewDetails");
                                    }}
                                >
                                    View Details <ArrowRightOutlined />
                                </a>
                            </Space>
                        </Space>
                    </div>
                );
            },
        },

        {
            title: "Verification Status",
            dataIndex: "status",
            key: "status",
            render: (_: string, record: AgentCompanyModel) => {
                return (
                    <>
                        <StatusTag
                            slug={record.getVerificationStatus()}
                            text={record.getVerificationStatus()}
                        />
                    </>
                );
            },
        },
        {
            title: "Operations Status",
            dataIndex: "status",
            key: "status",
            render: (_: string, record: AgentCompanyModel) => {
                return (
                    <>
                        <StatusTag
                            slug={record.getOperationStatus()}
                            text={formatString(record.getOperationStatus())}
                        />
                    </>
                );
            },
        },
        {
            title: "Additional",
            dataIndex: "verifications",
            key: "verifications",
            align: "center" as const,
            render: (_: string, record: AgentCompanyModel) => {
                return (
                    <Flex gap="small" vertical>
                        {checkActionPermission(
                            ADMIN_WALLET_PERMISSION_ENUM.ADMIN_VIEW_AGENT_WALLET,
                            <Button
                                onClick={() => handleClick(record.getId())}
                                icon={<WalletOutlined />}
                            >
                                Wallet Balance
                            </Button>,
                            null,
                        )}

                        {checkActionPermission(
                            [
                                ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_VERIFICATION_ADMIN_VIEW,
                                ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_VERIFICATION_ADMIN_UPDATE,
                            ],
                            <Button
                                onClick={() => {
                                    setSelectedCompany(record);
                                    setModalOpen("verifications");
                                }}
                                icon={<SafetyCertificateOutlined />}
                            >
                                Verifications
                            </Button>,
                            null,
                        )}

                        {checkActionPermission(
                            ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_MEMBER_ADMIN_VIEW,
                            <Button
                                onClick={() => {
                                    setSelectedCompany(record);
                                    setModalOpen("members");
                                }}
                                icon={<TeamOutlined />}
                            >
                                Members
                            </Button>,
                            null,
                        )}

                        {checkActionPermission(
                            ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_ADMIN_VIEW,

                            <Button
                                onClick={() => {
                                    setSelectedCompany(record);
                                    setModalOpen("shippingPoints");
                                }}
                                icon={<PartitionOutlined />}
                            >
                                Shipping Points
                            </Button>,
                            null,
                        )}

                        {checkActionPermission(
                            [
                                ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_COMMISSION_VIEW,
                                ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_PURCHASE_COMMISSION_MANAGE,
                            ],

                            <Button
                                onClick={() => {
                                    setSelectedCompany(record);
                                    setModalOpen("commission");
                                }}
                                icon={<DiffOutlined />}
                            >
                                Purchase Commissions
                            </Button>,
                            null,
                        )}
                    </Flex>
                );
            },
        },
    ];
    if (unAuthorized || error) {
        return <CommonError unAuthorized={unAuthorized} message={error} />;
    }
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        style={{ marginTop: "10px" }}
                        ghost={false}
                        title="Agent Companies"
                        onBack={() => window.history.back()}
                    >
                        <FiltersComponent
                            handleProductFilter={handleProductFilter}
                            handleFilterChange={handleFilterChange}
                            handelFilterClear={handelFilterClear}
                            isFetched={isFetched}
                            filters={filters}
                            filtersData={filterData}
                            isFromProductReceived={true}
                        />
                        <div style={{ marginTop: 10 }}>
                            <Table
                                //@ts-ignore
                                columns={columns}
                                dataSource={AgentCompanyCollectionData?.getData()}
                                bordered
                                loading={isLoading}
                                rowKey="id"
                                pagination={{
                                    ...paginationConfig,
                                    showTotal: (total) => (
                                        <div>
                                            Total{" "}
                                            <span
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "18px",
                                                    color: "green",
                                                }}
                                            >
                                                {total}
                                            </span>{" "}
                                            Items
                                        </div>
                                    ),
                                }}
                                scroll={{ x: 1170 }}
                            />
                        </div>
                    </PageHeader>
                </Col>
            </Row>

            <Modal
                open={Boolean(selectedCompany) && Boolean(modalOpen)}
                destroyOnClose={true}
                onCancel={() => {
                    handelSubFilterClear();
                    setSelectedCompany(undefined);
                    setModalOpen(undefined);
                }}
                width={
                    isMobile
                        ? "90vw"
                        : modalOpen === "viewDetails"
                          ? "50vw"
                          : modalOpen === "agentCompanyEdit"
                            ? "50vw"
                            : "70vw"
                }
                footer={null}
                title={
                    modalOpen === "members"
                        ? `Members (${selectedCompany?.getPrimaryName()})`
                        : modalOpen === "viewDetails"
                          ? `View Details (${selectedCompany?.getPrimaryName()})`
                          : modalOpen === "agentCompanyEdit"
                            ? `Agent Company (${selectedCompany?.getPrimaryName()})`
                            : modalOpen === "shippingPoints"
                              ? `Shipping Points (${selectedCompany?.getPrimaryName()})`
                              : modalOpen === "commission"
                                ? `Commissions (${selectedCompany?.getPrimaryName()})`
                                : `Verifications (${selectedCompany?.getPrimaryName()})`
                }
            >
                {modalOpen === "members" && selectedCompany && (
                    <MembersTable data={selectedCompany} />
                )}
                {modalOpen === "shippingPoints" && selectedCompany && (
                    <ShippingPointsTable data={selectedCompany} />
                )}
                {modalOpen === "verifications" && selectedCompany && (
                    <VerificationsTable data={selectedCompany} />
                )}
                {modalOpen === "commission" && selectedCompany && (
                    <PurchaseCommissionTable data={selectedCompany} />
                )}
                {modalOpen === "viewDetails" && selectedCompany && (
                    <ViewDetails company={selectedCompany} />
                )}
                {modalOpen === "agentCompanyEdit" && selectedCompany && (
                    <ModificationAgentCompany
                        onSubmit={onSubmit}
                        company={selectedCompany}
                        updateAgentCompanyloading={updateAgentCompanyloading}
                        form={form}
                    />
                )}
            </Modal>
        </>
    );
};

export default AgentCompany;
