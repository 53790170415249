import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Card, Col, Modal, Row, Button } from "antd";
import { useEffect, useState, Dispatch, SetStateAction, FC } from "react";
import AddressModal from "./AddressModal";
import { AddressModel } from "@/models/addressCollectionModel";
import { IDisplayAddress } from "@/types/buyOrderDetail";
import FormattedAddress from "@/components/FormattedAddress";
interface IAddressProps {
    address: AddressModel[];
    selectedAddressId: number | undefined;
    setSelectedAddressId: Dispatch<SetStateAction<number | undefined>>;
    handleDeleteAddress: (id) => void;
}
const Address: FC<IAddressProps> = ({
    address,
    selectedAddressId,
    setSelectedAddressId,
    handleDeleteAddress,
}) => {
    const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
    const [visibleCount, setVisibleCount] = useState(5); // Initial count of visible addresses

    useEffect(() => {
        if (address.length > 0 && !selectedAddressId) {
            // @ts-ignore
            setSelectedAddressId(address[0].getId());
        }
    }, [address, selectedAddressId, setSelectedAddressId]);

    const handleCardClick = (id) => {
        setSelectedAddressId(id);
    };

    const showModal = () => {
        setIsModalVisibleAddress(true);
    };

    const handleCancel = () => {
        setIsModalVisibleAddress(false);
    };

    const handleShowMore = () => {
        setVisibleCount(address.length); // Show all addresses
    };

    const handleShowLess = () => {
        setVisibleCount(5); // Show only 5 addresses
    };

    return (
        <>
            <div className="site-card-wrapper">
                <Row gutter={[16, 16]}>
                    {address.slice(0, visibleCount).map((addr, index) => {
                        return (
                            <Col xs={24} sm={24} md={12} lg={8} key={index}>
                                <Card
                                    onClick={() => handleCardClick(addr.id)}
                                    style={{
                                        cursor: "pointer",
                                        border:
                                            selectedAddressId === addr.id
                                                ? "2px solid #7ABA78"
                                                : "1px solid #e0e0e0",
                                        marginBottom: "0",
                                        minHeight: "160px",
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        textAlign: "left",
                                        boxShadow:
                                            "0 2px 8px rgba(0, 0, 0, 0.1)",
                                        borderRadius: "8px",
                                        position: "relative",
                                    }}
                                >
                                    <h4
                                        style={{
                                            fontSize: "14px",
                                            marginBottom: "8px",
                                            marginTop: "-5px",
                                        }}
                                    >{`Address ${index + 1}`}</h4>

                                    {/* {formatAddress(addr.getDisplayAddress() as unknown as IDisplayAddress)} */}

                                    <FormattedAddress
                                        address={
                                            addr.getDisplayAddress() as unknown as IDisplayAddress
                                        }
                                    />

                                    <div
                                        style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "10px",
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        {/*<EditOutlined*/}
                                        {/* disabled={true}*/}
                                        {/*  style={{*/}
                                        {/*    fontSize: '16px',*/}
                                        {/*    color: '#1890ff',*/}
                                        {/*    cursor: 'pointer',*/}
                                        {/*    opacity:.3*/}
                                        {/*  }}*/}
                                        {/*  onClick={(e) => {*/}
                                        {/*    e.stopPropagation();*/}
                                        {/*    // onEditAddress(addr.id);*/}
                                        {/*  }}*/}
                                        {/*/>*/}
                                        <DeleteOutlined
                                            style={{
                                                fontSize: "16px",
                                                color: "#ff4d4f",
                                                cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteAddress(addr.id);
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        );
                    })}
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Card
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100px",
                                cursor: "pointer",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: "8px",
                                padding: "0",
                                textAlign: "center",
                            }}
                            onClick={showModal}
                        >
                            <PlusOutlined
                                style={{
                                    fontSize: "24px",
                                    color: "#1890ff",
                                    lineHeight: "100px",
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
                {address.length > 5 && (
                    <div style={{ textAlign: "center", marginTop: "16px" }}>
                        {visibleCount < address.length ? (
                            <Button type="link" onClick={handleShowMore}>
                                Show More
                            </Button>
                        ) : (
                            <Button type="link" onClick={handleShowLess}>
                                Show Less
                            </Button>
                        )}
                    </div>
                )}
            </div>

            <Modal
                title="Add New Address"
                open={isModalVisibleAddress}
                onCancel={handleCancel}
                footer={null}
                destroyOnClose={true}
            >
                <AddressModal setIsModalVisible={setIsModalVisibleAddress} />
            </Modal>
        </>
    );
};

export default Address;
