import { IPropertyValue } from "..";

export const generateStringFromArray = (array: IPropertyValue[]) => {
    return array
        .map(
            ({ propertyId, propertyValueId }) =>
                `${propertyId}-${propertyValueId}`,
        )
        .join(";");
};
