import { useState } from "react";
import { useService } from "../../contexts";

import { useAtom } from "jotai";
import { regionLanguage } from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import { ILanguageUpdateRequest } from "@/types/language";

export const useCreateLanguage = () => {
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [regionLanguageData, setRegionLanguageData] = useAtom(regionLanguage);

    const createLanguage = async (payload: ILanguageUpdateRequest) => {
        setIsLoading(true);
        try {
            const response = await coreService.LanguageResource.create(payload);
            setIsLoading(false);
            setRegionLanguageData({ ...regionLanguageData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { createLanguage, isLoading, isError };
};

export const useUpdateLanguage = () => {
    const { coreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [regionLanguageData, setRegionLanguageData] = useAtom(regionLanguage);

    const updateLanguage = async (
        id: number,
        payload: ILanguageUpdateRequest,
    ) => {
        setIsLoading(true);
        try {
            const response = await coreService.LanguageResource.update(
                id,
                payload,
            );
            setIsLoading(false);
            setRegionLanguageData({ ...regionLanguageData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateLanguage, isLoading, isError };
};

export const useGetLanguage = () => {
    const [language, setLanguage] = useAtom(regionLanguage);
    const { coreService } = useService();
    const getLanguage = async (params?: string): Promise<void> => {
        setLanguage({ ...language, isLoading: true, error: null });
        try {
            const response = await coreService.LanguageResource.get(params);
            setLanguage({
                ...language,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setLanguage({
                ...language,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return { getLanguage } as const;
};
