import {
    BaseShippingCategoryResource,
    ShippingCategoryResource,
    ShippingCountryResource,
} from "../../resources/private";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
class ShippingCoreService extends CommerceApiService {
    public baseShippingCategoryResource: BaseShippingCategoryResource;
    public shippingCategoryResource: ShippingCategoryResource;
    public shippingCountryResource: ShippingCountryResource;

    constructor() {
        super();
        this.baseShippingCategoryResource = new BaseShippingCategoryResource(
            this.config,
        );
        this.shippingCategoryResource = new ShippingCategoryResource(
            this.config,
        );
        this.shippingCountryResource = new ShippingCountryResource(this.config);
    }
}

export default ShippingCoreService;
