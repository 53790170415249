import { ITranslationEntry } from "../lang";

export const LV: ITranslationEntry = {
    translations: {
        notifications: "Paziņojumi",
        markAllAsRead: "Atzīmēt visus kā lasītus",
        poweredBy: "Izstrādāts",
        settings: "Iestatījumi",
        noNewNotification: "Šeit vēl nav nekā jauna, ko redzēt",
    },
    lang: "lv",
};
