export const getErrorMessage = (error: any): string => {
    let msg = "";

    // Check for message in response data
    if (error?.response?.data) {
        // Handle when error response is an array of messages
        if (Array.isArray(error.response.data.message)) {
            if (Array.isArray(error.response.data.message[0])) {
                msg =
                    error.response.data.message[0][0]?.message ||
                    "Unknown error occurred";
            } else {
                msg =
                    error.response.data.message[0]?.message ||
                    "Unknown error occurred";
            }
        }
        // Handle when message is a string or object
        else if (typeof error.response.data.message === "string") {
            msg = error.response.data.message;
        } else if (error.response.data.message?.message) {
            msg = error.response.data.message.message;
        }
    }

    // If no message found, fall back to generic error message
    if (!msg) {
        msg = error?.message || "Something went wrong, Please try again.";
    }

    return msg;
};
