import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { useState } from "react";
import { agentWarehouseCollectionAtom } from "@/lib/core-react/store/store";
import {
    IAgentWarehouseFormData,
    IAgentWarehouseUpdateCommissionFormData,
} from "@/types/agentWarehouseCollection";

export const useGetAgentWarehouse = () => {
    const [agentWarehouses, setAgentWarehouses] = useAtom(
        agentWarehouseCollectionAtom,
    );
    const { shippingAgentService } = useService();
    const getAgentWarehouses = async (params?: string): Promise<void> => {
        setAgentWarehouses({
            ...agentWarehouses,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingAgentService.agentWarehouseResource.get(params);
            setAgentWarehouses({
                ...agentWarehouses,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setAgentWarehouses({
                ...agentWarehouses,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getAgentWarehouses } as const;
};

export const useUpdateAgentWarehouse = () => {
    const { shippingAgentService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [agentWarehouseData, setAgentWarehouseData] = useAtom(
        agentWarehouseCollectionAtom,
    );

    const updateAgentWarehouse = async (
        agent_company_id: number,
        warehouse_id: number,
        payload: IAgentWarehouseFormData,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingAgentService.agentWarehouseResource.update(
                    agent_company_id,
                    warehouse_id,
                    payload,
                );
            setIsLoading(false);
            setAgentWarehouseData({ ...agentWarehouseData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateAgentWarehouse, isLoading, isError };
};

export const useUpdateCommission = () => {
    const { shippingAgentService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [agentWarehouseData, setAgentWarehouseData] = useAtom(
        agentWarehouseCollectionAtom,
    );

    const updateCommission = async (
        warehouse_id: number,
        payload: IAgentWarehouseUpdateCommissionFormData[],
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingAgentService.agentWarehouseResource.updateCommission(
                    warehouse_id,
                    payload,
                );
            setIsLoading(false);
            setAgentWarehouseData({ ...agentWarehouseData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateCommission, isLoading, isError };
};
