import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";

export const useGetLanguageMinimal = () => {
    const { coreService } = useService();
    const [languageOptionsData, setLanguageOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getLanguage = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setLanguageOptionsData((prev) => ({ ...prev, isLoading: true }));

            const response = await coreService.LanguageResource.get(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setLanguageOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setLanguageOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getLanguage, languageOptionsData } as const;
};
