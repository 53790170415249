import { ITranslationEntry } from "../lang";

export const FI: ITranslationEntry = {
    translations: {
        notifications: "Ilmoitukset",
        markAllAsRead: "Merkkaa luetuksi",
        poweredBy: "Ilmoitukset mahdollistaa",
        settings: "Asetukset",
        noNewNotification: "Täällä ei ole vielä mitään uutta nähtävää",
    },
    lang: "fi",
};
