import { Card } from "antd";
interface IProps {
    title: string;
    children: JSX.Element;
    errorMessage: string;
}
const CreateOrderStepContent = ({ title, children, errorMessage }: IProps) => {
    return (
        <Card
            title={title}
            style={{
                marginTop: 16,
            }}
        >
            {children}

            <p
                style={{
                    marginTop: "10px",
                    color: "red",
                    textAlign: "center",
                }}
            >
                {errorMessage}
            </p>
        </Card>
    );
};

export default CreateOrderStepContent;
