import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";

export const useGetAgentWarehouseMinimal = () => {
    const { shippingAgentService } = useService();
    const [agentWarehouseOptionsData, setAgentWarehouseOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getAgentWarehouses = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }

            setAgentWarehouseOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await shippingAgentService.agentWarehouseResource.get(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setAgentWarehouseOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setAgentWarehouseOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getAgentWarehouses, agentWarehouseOptionsData };
};
