import { ITranslationEntry } from "../lang";

export const ES: ITranslationEntry = {
    translations: {
        notifications: "Notificaciones",
        markAllAsRead: "Marcar todo como leído",
        poweredBy: "Con tecnología de",
        settings: "Configuración",
        removeMessage: "Eliminar mensaje",
        markAsRead: "Marcar como leído",
        markAsUnRead: "Marcar como no leído",
        noNewNotification: "Nada nuevo por aquí",
    },
    lang: "es",
};
