import { Space, Typography, Rate, Button, Tooltip } from "antd";
import { IWishlistExtended, IProductGallery } from "@/types/wishlistCollection";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import Link from "antd/es/typography/Link";
import { StatusTag } from "@/components";
import { ColumnsType } from "antd/es/table";
import { ICustomer } from "@/types/customerCollection";

const { Text, Paragraph } = Typography;

const WishlistTableColumns = (
    paginationData: any,
    openGalleryModal?: (images: IProductGallery[] | undefined) => void,
): ColumnsType<IWishlistExtended> => {
    return [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center",
            width: 70,
            render: (_: string, _record: IWishlistExtended, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Customer",
            dataIndex: "customer",
            key: "customer",
            render: (_customer: ICustomer, record: IWishlistExtended) => {
                return (
                    <>
                        {record.customer ? (
                            <Space direction="vertical">
                                <Space
                                    direction="horizontal"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <Typography.Text strong>
                                        Name:
                                    </Typography.Text>
                                    <Typography.Text copyable>
                                        {record.customer?.name}
                                    </Typography.Text>
                                </Space>
                                <Space
                                    direction="horizontal"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    <Typography.Text strong>
                                        Email:
                                    </Typography.Text>
                                    <Typography.Text copyable>
                                        {record.customer?.email}
                                    </Typography.Text>
                                </Space>
                                {record.customer.phone && (
                                    <Space
                                        direction="horizontal"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        <Typography.Text strong>
                                            Phone:
                                        </Typography.Text>
                                        <Typography.Text>
                                            {record.customer.phone}
                                        </Typography.Text>
                                    </Space>
                                )}
                            </Space>
                        ) : (
                            ""
                        )}
                    </>
                );
            },
        },
        {
            title: "Product Info",
            dataIndex: "product_image",
            key: "product_image",
            render: (_: any, record: IWishlistExtended) => {
                return (
                    <Space direction="horizontal" size={12}>
                        <ImageWithPlaceholder
                            style={{
                                width: 120,
                            }}
                            src={record.product_image}
                        />
                        <Space direction="vertical" size={2}>
                            <Text>
                                <Tooltip
                                    title={
                                        record.product_title.length > 30
                                            ? record.product_title
                                            : ""
                                    }
                                    placement="top"
                                >
                                    <Link target="_blank" rel="noreferrer">
                                        {record.product_title.length > 30
                                            ? `${record.product_title.slice(0, 30)}...`
                                            : record.product_title}
                                    </Link>
                                </Tooltip>
                            </Text>
                            <Paragraph>
                                <Text>Region: </Text>
                                <StatusTag
                                    slug={record.region.code}
                                    text={record.region.code}
                                />
                            </Paragraph>
                            <Paragraph>
                                <Text>Seller: </Text>
                                <Text>{record.product_seller.name}</Text>
                            </Paragraph>
                            {record.product_ratings_avg > 0 && (
                                <Paragraph>
                                    <Rate
                                        style={{
                                            fontSize: 16,
                                            padding: 0,
                                            margin: 0,
                                        }}
                                        defaultValue={
                                            record.product_ratings_avg
                                        }
                                        disabled
                                    />
                                </Paragraph>
                            )}
                            {openGalleryModal && (
                                <Paragraph>
                                    <Button
                                        onClick={() =>
                                            openGalleryModal(
                                                record.product_gallery.data,
                                            )
                                        }
                                        style={{ padding: 0 }}
                                        type="link"
                                    >
                                        View Gallery
                                    </Button>
                                </Paragraph>
                            )}
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: "Price Ranges",
            key: "price_ranges",
            render: (_: any, record: IWishlistExtended) => (
                <Space direction="vertical">
                    <Space>
                        <Text strong>Original Price: </Text>
                        <Text>
                            {record.product_price?.original?.min != null &&
                            record.product_price?.original?.max != null
                                ? `${record.product_price.original.min.toFixed(2)} - ${record.product_price.original.max.toFixed(2)}`
                                : "N/A"}
                        </Text>
                    </Space>
                    <Space>
                        <Text strong>Discount Price: </Text>
                        <Text>
                            {record.product_price?.discount?.min != null &&
                            record.product_price?.discount?.max != null
                                ? `${record.product_price.discount.min.toFixed(2)} - ${record.product_price.discount.max.toFixed(2)}`
                                : "N/A"}
                        </Text>
                    </Space>
                </Space>
            ),
        },
    ];
};

export default WishlistTableColumns;
