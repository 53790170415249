import { renderOptionsFromEnumReversed } from "@/components/Form/forms";
import { MultiChunkUpload } from "@/components/MultiChunkUpload";
import { UnitTypeEnums } from "@/enums/shippingCoreEnums";
import { ShippingModeEnums, ShippingTypeEnums } from "@/enums/shippingEnums";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { useRfqUpdate } from "@/lib/core-react/hooks/private/useRfq";
import { rfqRequestListAtom } from "@/lib/core-react/store/store";
import { RfqRequestModel } from "@/models/rfqRequestCollectionModel";
import { RFQRequestUpdatePayload } from "@/types/rfqRequestCreate";
import {
    Button,
    Col,
    Flex,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    TreeSelect,
} from "antd";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import ImagesPart from "./ImagesPart";

interface Props {
    requestData: RfqRequestModel;
    handleSuccess: () => void;
}

const UpdateRFQForm: React.FC<Props> = ({ handleSuccess, requestData }) => {
    const [form] = Form.useForm();
    const {
        allOptionsData: {
            shippingCategoryOptionsData,
            shippingCountryOptionsData,
            destinationWarehouseOptionsData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { updateRFQ, isLoading } = useRfqUpdate();

    const setRFQRequestList = useSetAtom(rfqRequestListAtom);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.SHIPPING_CATEGORY);
        onFetchFilterApi(filterResourceEnum.DESTINATION_WAREHOUSE);
        onFetchFilterApi(filterResourceEnum.SHIPPING_COUNTRY, {
            region_id: requestData.getRegionId(),
        });
    }, []);

    const onFinish = async (values: RFQRequestUpdatePayload) => {
        try {
            const { previous_images, ...data } = values;
            await updateRFQ({
                requestId: requestData.getId(),
                payload: {
                    ...data,
                    images: [...data.images, ...(previous_images as string[])],
                },
            });

            showSuccessAlert("Successfully RFQ Created.");
            form.resetFields();
            handleSuccess();
            setRFQRequestList((prev) => ({ ...prev, refetch: true }));
        } catch (error) {
            showError(error);
        }
    };

    const onFinishFailed = (_errorInfo: any) => {
        notification.error({
            message: "Please fill-up the required fields. ",
        });
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
                product_name: requestData.getProductName(),
                product_link: requestData.getProductLink(),
                purchase_quantity: requestData.getPurchaseQuantity(),
                purchase_unit_type: requestData.getPurchaseUnitType(),
                product_description: requestData.getProductDescription(),
                shipping_mode: requestData.getShippingMode(),
                shipping_type: requestData.getShippingType(),
                shipping_category_id: requestData.getShippingCategoryId(),
                shipping_country_id: requestData.getShippingCountryId(),
                destination_warehouse_id:
                    requestData.getDestinationWarehouseId(),
                images: [],
            }}
        >
            <Row>
                <Col span={24}>
                    <Form.Item
                        label="Product Name"
                        name="product_name"
                        rules={[
                            {
                                required: true,
                                message: "Product name is required",
                            },
                        ]}
                    >
                        <Input placeholder="Write Product Name" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Product Link" name="product_link">
                        <Input type="url" placeholder="give product url" />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Flex gap={4}>
                        <Form.Item
                            label="Purchase Quantity"
                            name="purchase_quantity"
                            style={{ width: "100%" }}
                            rules={[
                                {
                                    required: true,
                                    message: "Purchase quantity is required",
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder="Purchase Quantity"
                                min={1}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Unit Type"
                            name="purchase_unit_type"
                            rules={[
                                {
                                    required: true,
                                    message: "Purchase unit type is required",
                                },
                            ]}
                        >
                            <Select
                                placeholder="unit type"
                                style={{ minWidth: 200 }}
                            >
                                {renderOptionsFromEnumReversed(UnitTypeEnums)}
                            </Select>
                        </Form.Item>
                    </Flex>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label="Product Description"
                        name="product_description"
                        rules={[
                            {
                                required: true,
                                message: "Product description is required",
                            },
                        ]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item label="Images" name="images">
                        <ImagesPart
                            form={form}
                            images={requestData.getImageUrls()}
                        />
                        <MultiChunkUpload fieldName={"images"} form={form} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Row gutter={4}>
                        <Col span={24} lg={8}>
                            <Form.Item
                                label="Shipping Category"
                                name="shipping_category_id"
                                rules={[
                                    {
                                        required: false,
                                        message:
                                            "Shipping Category is required",
                                    },
                                ]}
                            >
                                <TreeSelect
                                    onChange={(value) => {
                                        form.setFieldValue(
                                            "shipping_category_id",
                                            value,
                                        );
                                    }}
                                    showSearch
                                    dropdownStyle={{
                                        maxHeight: 400,
                                        overflow: "auto",
                                    }}
                                    placeholder="Please select a shipping category"
                                    allowClear
                                    loading={
                                        shippingCategoryOptionsData.isLoading
                                    }
                                    treeLine={{ showLeafIcon: true }}
                                    treeData={
                                        shippingCategoryOptionsData.options
                                    }
                                    filterTreeNode={(searchValue, treeNode) => {
                                        const title = treeNode.title;
                                        return (
                                            typeof title === "string" &&
                                            title
                                                .toLowerCase()
                                                .includes(
                                                    searchValue.toLowerCase(),
                                                )
                                        );
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} lg={8}>
                            <Form.Item
                                name={"shipping_country_id"}
                                label="Shipping Country"
                                required
                            >
                                <Select
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                                (
                                                    optionB?.label ?? ""
                                                ).toLowerCase(),
                                            )
                                    }
                                    loading={
                                        shippingCountryOptionsData.isLoading
                                    }
                                    placeholder="Select Shipping Country"
                                    options={shippingCountryOptionsData.options}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} lg={4}>
                            <Form.Item
                                label="Shipping Mode"
                                name="shipping_mode"
                                rules={[
                                    {
                                        required: true,
                                        message: "Shipping mode is required",
                                    },
                                ]}
                            >
                                <Select placeholder="Select Shipping Mode">
                                    {Object.values(ShippingModeEnums).map(
                                        (mode) => (
                                            <Select.Option
                                                key={mode}
                                                value={mode}
                                            >
                                                {mode.toUpperCase()}
                                            </Select.Option>
                                        ),
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={4}>
                            <Form.Item
                                label="Shipping Type"
                                name="shipping_type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Shipping type is required",
                                    },
                                ]}
                            >
                                <Select placeholder="Select Shipping Type">
                                    {Object.values(ShippingTypeEnums).map(
                                        (type) => (
                                            <Select.Option
                                                key={type}
                                                value={type}
                                            >
                                                {type.toUpperCase()}
                                            </Select.Option>
                                        ),
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col span={24}>
                    <Row gutter={8}>
                        <Col span={12}>
                            <Form.Item
                                label="Destination Warehouse"
                                name="destination_warehouse_id"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Destination warehouse ID is required",
                                    },
                                ]}
                            >
                                <Select
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="label"
                                    filterSort={(optionA, optionB) =>
                                        (optionA?.label ?? "")
                                            .toLowerCase()
                                            .localeCompare(
                                                (
                                                    optionB?.label ?? ""
                                                ).toLowerCase(),
                                            )
                                    }
                                    loading={
                                        destinationWarehouseOptionsData.isLoading
                                    }
                                    placeholder="select destination Warehouse"
                                    options={
                                        destinationWarehouseOptionsData.options
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Flex justify="end">
                <Form.Item noStyle>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Flex>
        </Form>
    );
};

export default UpdateRFQForm;
