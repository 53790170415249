import { Table } from "antd";
import { IBuyOrderQuantityFormData } from "@/types/buyOrderDetail";
import { IPropertyValueMap } from "..";
import { useAtomValue } from "jotai";
import { currentCurrencyAtom } from "@/lib/core-react/store/store";

interface IProps {
    bordered?: boolean;
    pagination?: boolean;
    title?: string;
    quantity: IBuyOrderQuantityFormData[];
    mappedSkuProperties: IPropertyValueMap;
}

const SelectedItems = ({
    bordered = true,
    pagination = false,
    title,
    quantity,
    mappedSkuProperties,
}: IProps) => {
    const { data: currentCurrencyModel } = useAtomValue(currentCurrencyAtom);

    return (
        <Table
            size="small"
            bordered={bordered}
            rowHoverable={false}
            caption={title}
            dataSource={quantity.map((q, index) => ({
                key: index,
                properties: Object.values(mappedSkuProperties)
                    .find((v) => v.getId() === q.sku_id)
                    ?.getPropertyAssociations()
                    .getCustomizedDataToAddShipmentProductCarton()
                    .map((item) => item.property_value_name)
                    .join(", "),
                quantity: q.quantity,
            }))}
            footer={() => (
                <p style={{ textAlign: "center" }}>
                    Total cost = {currentCurrencyModel?.getCurrencySymbol()}{" "}
                    {quantity
                        .reduce(
                            (acc, curr) =>
                                acc + curr.quantity * curr.original_unit_price,
                            0,
                        )
                        .toFixed(2)}
                </p>
            )}
            pagination={pagination ? { pageSize: 3 } : false}
        >
            <Table.Column title="Properties" dataIndex="properties" />
            <Table.Column title="Quantity" dataIndex="quantity" />
        </Table>
    );
};

export default SelectedItems;
