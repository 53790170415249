import { useAtom } from "jotai";
import { supportInquiryList } from "../../store/store";
import { useService } from "../../contexts/ServiceProvider";
import { getError } from "../utils/errors";

export const useSupportInquiry = () => {
    const [supportInquiry, setSupportInquiry] = useAtom(supportInquiryList);
    const { genericService } = useService();
    const getSupportInquiry = async (params?: string): Promise<void> => {
        try {
            const response = await genericService.genericResource.get(params);
            setSupportInquiry({
                ...supportInquiry,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setSupportInquiry({
                ...supportInquiry,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error.response.status,
                unAuthorized: error.response.status === 403,
            });
            throw error;
        }
    };

    return { getSupportInquiry } as const;
};
