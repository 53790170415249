import { IBuyPlaceOrderCartShippingFormData } from "@/types/buyOrderPlace";
import { Button, Flex, Image, Modal, notification, Typography } from "antd";
import React from "react";
import shippingMethodIcon from "/assets/shipping_method_icon.gif";
import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import { ShippingMethod } from "@/pages/ProductManage/ProductDetail/ProductVariations/components";
const { Text } = Typography;
interface IProps {
    placeBuyOrderProductStep2FormData:
        | IBuyPlaceOrderCartShippingFormData
        | undefined;
    setIsShippingMethodModalOpen: (
        value: React.SetStateAction<boolean>,
    ) => void;
    isShippingMethodModalOpen: boolean;
    setPlaceBuyOrderProductStep2FormData: React.Dispatch<
        React.SetStateAction<IBuyPlaceOrderCartShippingFormData | undefined>
    >;
    shippingInfoErrorMessage: string | null;
}
const SelectShippingMethod = ({
    placeBuyOrderProductStep2FormData,
    setIsShippingMethodModalOpen,
    isShippingMethodModalOpen,
    setPlaceBuyOrderProductStep2FormData,
    shippingInfoErrorMessage,
}: IProps) => {
    return (
        <>
            <div>
                <Button
                    size="large"
                    style={{
                        padding: "0 30px",
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor:
                            placeBuyOrderProductStep2FormData &&
                            Object.values(
                                placeBuyOrderProductStep2FormData,
                            ).every(Boolean)
                                ? "#3676ff54"
                                : "#05d05e3b",
                        border: 0,
                    }}
                    onClick={() => {
                        setIsShippingMethodModalOpen(true);
                    }}
                >
                    <Flex align="center" gap={8}>
                        <Image
                            preview={false}
                            src={shippingMethodIcon}
                            alt=""
                            width={45}
                        />
                        {placeBuyOrderProductStep2FormData &&
                        Object.values(placeBuyOrderProductStep2FormData).every(
                            Boolean,
                        ) ? (
                            <>
                                <Flex gap={8} align="center">
                                    <Text
                                        style={{
                                            fontSize: "16px",
                                        }}
                                    >
                                        {placeBuyOrderProductStep2FormData.selectedCategoryPrice
                                            .getAgentWarehouse()
                                            .getCompanyName()}
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "14px",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        <Text strong>
                                            {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getMinShippingTime()}
                                            -
                                            {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getMaxShippingTime()}{" "}
                                            {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getShippingTimeUnit()}
                                        </Text>
                                    </Text>
                                    <Text
                                        style={{
                                            fontSize: "14px",
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getShippingMode()}{" "}
                                        by{" "}
                                        {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getShippingType()}{" "}
                                    </Text>

                                    <Text strong>
                                        {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getRate()}
                                        /
                                        {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getUnitType()}
                                    </Text>
                                    <Text
                                        strong
                                        style={{
                                            textTransform: "capitalize",
                                        }}
                                    >
                                        {
                                            placeBuyOrderProductStep2FormData
                                                .selectedCategoryPrice.contain
                                        }
                                    </Text>

                                    <EditOutlined />
                                </Flex>
                            </>
                        ) : (
                            <Typography.Text strong>
                                Select Shipping Method <ArrowRightOutlined />
                            </Typography.Text>
                        )}
                    </Flex>
                </Button>
                <span
                    style={{
                        color: "red",
                    }}
                >
                    {shippingInfoErrorMessage}
                </span>
            </div>
            <Modal
                width={""}
                centered
                title={
                    <Typography.Title level={4}>
                        Shipping Methods
                    </Typography.Title>
                }
                open={isShippingMethodModalOpen}
                onCancel={() => setIsShippingMethodModalOpen(false)}
                onOk={() => {
                    if (
                        placeBuyOrderProductStep2FormData &&
                        Object.values(placeBuyOrderProductStep2FormData).every(
                            Boolean,
                        )
                    ) {
                        setIsShippingMethodModalOpen(false);
                    } else {
                        notification.error({
                            message:
                                "Please Select Product Category, Shipping Country, Shipping Partner",
                        });
                    }
                }}
                okText={"Submit"}
                cancelText="Cancel"
            >
                <ShippingMethod
                    placeBuyOrderProductStep2FormData={
                        placeBuyOrderProductStep2FormData
                    }
                    setPlaceBuyOrderProductStep2FormData={
                        setPlaceBuyOrderProductStep2FormData
                    }
                />
            </Modal>
        </>
    );
};

export default SelectShippingMethod;
