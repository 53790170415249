import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import { DestinationWarehouseResource } from "../../resources/private";

class DestinationService extends CommerceApiService {
    public destinationWarehouseResource: DestinationWarehouseResource;

    constructor() {
        super();

        this.destinationWarehouseResource = new DestinationWarehouseResource(
            this.config,
        );
    }
}

export default DestinationService;
