import { useEffect } from "react";
import { Form, InputNumber, Select } from "antd";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import { PurchaseCommissionModel } from "@/models/purchaseCommissionCollectionModel";
import { BuyProductCommissionRateTypeEnum } from "@/enums/purchaseCommissionCollectionEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";

interface IProps {
    selectedCommission: PurchaseCommissionModel;
    form: any;
    onFinish: (values: any) => Promise<void>;
}

export const PurchaseCommissionUpdate = ({
    selectedCommission,
    form,
    onFinish,
}: IProps) => {
    const {
        allOptionsData: { storeOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        form.resetFields();
    }, [selectedCommission, form]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.STORE);
    }, []);

    const initialValues = {
        store_id: selectedCommission?.store?.id,
        commission_rate: selectedCommission.commission_rate,
        commission_rate_type: selectedCommission.commission_rate_type,
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update Commission"
            initialValues={initialValues}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Store is required",
                    },
                ]}
                name="store_id"
                label="Store"
            >
                <Select
                    allowClear
                    placeholder="Please select a store"
                    onClear={() => onFetchFilterApi(filterResourceEnum.STORE)}
                    options={storeOptionsData.options}
                    loading={storeOptionsData.isLoading}
                    notFoundContent={
                        storeOptionsData.isLoading ? LoaderSmall : null
                    }
                />
            </Form.Item>

            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Commission rate is required",
                    },
                ]}
                required
                name="commission_rate"
                label="Commission Rate"
            >
                <InputNumber
                    placeholder="0"
                    min={1}
                    style={{ width: "100%" }}
                />
            </Form.Item>
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Rate type is required",
                    },
                ]}
                required
                name="commission_rate_type"
                label="Rate Type"
            >
                <Select
                    placeholder="Please select a rate type"
                    options={transformEnumToLabeledValue(
                        BuyProductCommissionRateTypeEnum,
                    )}
                />
            </Form.Item>
        </Form>
    );
};
