import React, { useEffect, useState } from "react";
import { Button, Input, notification, Popconfirm } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { AgentCompanyModel } from "@/models/agentCompanyCollectionModel";
import {
    useApproveBuyProduct,
    useBulkApproveBuyProduct,
} from "@/lib/core-react/hooks/private/usePurchase";
import { BulkApprovedPayload } from "@/types/buyOrderCollection";
import { IModalData } from "@/helpers/getModalTital";
import { showError } from "@/helpers/showError";
import useDataFilters from "@/hooks/useDataFilters";
import useRefetch from "@/hooks/useRefetch";

interface IProps {
    record: AgentCompanyModel;
    productId?: number;
    selectedRowKeys: React.Key[];
    handleModal: (payload: IModalData) => void;
    commissionValue: string | undefined;
    buttonText: string;
    assignWithCommission?: boolean;
}

const AgentCommissionSubmit = ({
    record,
    productId,
    selectedRowKeys,
    handleModal,
    commissionValue,
    buttonText,
    assignWithCommission = false,
}: IProps) => {
    const [open, setOpen] = useState(false);
    const [inputError, setInputError] = useState(false);
    const [value, setValue] = useState("");
    const [isAssigned, setIsAssigned] = useState(false);
    const { refetchListApi } = useRefetch();
    const { handelSubFilterClear } = useDataFilters();

    const { approveBuyProduct, isLoading: isApproveLoading } =
        useApproveBuyProduct();
    useEffect(() => {
        setIsAssigned(false);
    }, []);

    const { bulkApproveBuyProduct, isLoading: isBulkApproveLoading } =
        useBulkApproveBuyProduct();

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        const inputValue = (e.target as HTMLInputElement).value;

        setValue(inputValue);
        if (inputValue && Number(inputValue) >= 0) {
            setInputError(false);
        }
    };
    const handleSubmit = async () => {
        if (assignWithCommission && (!value || Number(value) < 0)) {
            setInputError(true);
            return;
        }
        const payload = {
            commission_rate: assignWithCommission
                ? Number(value)
                : Number(commissionValue),
            agent_company_id: record.getId(),
        };

        let message = "";

        try {
            if (selectedRowKeys && selectedRowKeys.length > 0) {
                const bulkPayload: BulkApprovedPayload = {
                    ...payload,
                    product_ids: selectedRowKeys.map((key) => Number(key)),
                };
                await bulkApproveBuyProduct(bulkPayload);
                message = "Bulk approve and assign successfully";
            } else {
                if (productId) await approveBuyProduct(productId, payload);
                message = "Assign successfully";
            }

            setOpen(false);
            setValue("");
            setIsAssigned(true);
            handleModal({
                data: null,
                action: false,
            });

            notification["success"]({
                message,
            });

            handelSubFilterClear();

            refetchListApi();
        } catch (error: any) {
            showError(error);
        }
    };

    const showPopconfirm = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false);
        setInputError(false);
    };

    return (
        <Popconfirm
            placement="top"
            title={
                <div>
                    <p>Commission:</p>
                    <Input
                        required={true}
                        onInput={(e) => handleChange(e)}
                        type={"number"}
                        min={0}
                        suffix="%"
                        style={{ WebkitAppearance: "none" }}
                    />
                    {inputError && (
                        <div style={{ color: "red" }}>
                            Please enter a valid commission rate
                        </div>
                    )}
                </div>
            }
            open={open}
            okButtonProps={{
                loading: isApproveLoading || isBulkApproveLoading,
            }}
            onConfirm={async () => await handleSubmit()}
            okText={"Submit"}
            cancelText={"Cancel"}
            onCancel={handleCancel}
            icon={""}
        >
            <Button
                loading={isApproveLoading || isBulkApproveLoading}
                icon={<CheckCircleOutlined />}
                onClick={assignWithCommission ? showPopconfirm : handleSubmit}
                style={{
                    backgroundColor: isAssigned ? "green" : "",
                    color: isAssigned ? "white" : "",
                }}
                disabled={isAssigned}
            >
                {buttonText}
            </Button>
        </Popconfirm>
    );
};

export default AgentCommissionSubmit;
