/* eslint-disable @typescript-eslint/no-unused-expressions */
import { AcceptInventoryProductsQueryKeys } from "@/consts/appConstants";
import {
    FacetFieldModel,
    IFacetField,
} from "@/models/ProductSearchCollectionModel";
import { useGetSearchProducts } from "@/lib/core-react/hooks/private/useProduct";
import usePageQuery from "@/helpers/usePageQuery";
import { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useAtom } from "jotai";
import { getUniqueArrayValues } from "../../../helpers/getUniqueArrayValues";
import FacetCheckboxForm from "./FacetCheckboxForm";
import { productSearchQueryAtom } from "@/lib/core-react/store/inventoryProductsAtoms";

interface IProps {
    facetFields: FacetFieldModel[] | undefined;
    payloadKey: AcceptInventoryProductsQueryKeys;
    label: string;
}

const ShopsFacet = ({ facetFields, payloadKey, label }: IProps) => {
    const { isLoading } = useGetSearchProducts();
    const [form] = useForm();
    const { updateQuery } = usePageQuery();
    const [productSearchQuery, setProductSearchQuery] = useAtom(
        productSearchQueryAtom,
    );

    const { shops } = productSearchQuery;

    useEffect(() => {
        if (shops && Array.isArray(shops)) {
            setProductSearchQuery((prev) => ({ ...prev, shops }));
        }
    }, []);

    const handleSingleSelect = async (selected: IFacetField) => {
        const updatedShops = getUniqueArrayValues(shops, selected.name);
        const payload = {};
        payload[payloadKey] = updatedShops;
        updateQuery(payload);
        setProductSearchQuery((prev) => ({ ...prev, shops: updatedShops }));
        form.setFieldValue(selected.name, true);
    };

    const handleSingleDeSelect = async (selected: IFacetField) => {
        const updatedShops = shops.filter((code) => code !== selected.name);
        const payload = {};
        payload[payloadKey] = updatedShops;
        updateQuery(payload);
        setProductSearchQuery((prev) => ({ ...prev, shops: updatedShops }));
        form.setFieldValue(selected.name, false);
    };

    const onChange = (e: CheckboxChangeEvent, selected: IFacetField) => {
        if (e.target.checked) {
            handleSingleSelect(selected);
        } else {
            handleSingleDeSelect(selected);
        }
    };

    useEffect(() => {
        const initialFormValues = {};
        facetFields &&
            facetFields.forEach(({ name }) => {
                initialFormValues[name] = shops.includes(name);
            });

        form.setFieldsValue(initialFormValues);
    }, [facetFields]);

    return (
        <FacetCheckboxForm
            label={label}
            facetFields={facetFields}
            isLoading={isLoading}
            form={form}
            onChange={onChange}
        />
    );
};
export default ShopsFacet;
