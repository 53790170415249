import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    HddFilled,
    PlusOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Modal, Table, Tag, Typography, Space } from "antd";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";

import { addonServiceCollectionAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { useEffect, useState } from "react";
import { IFilterType } from "@/types/filters";
import CreateService from "./components/CreateServices";
import {
    useAddonServicesList,
    useDeletePrice,
} from "@/lib/core-react/hooks/private/useAddonService";
import {
    AddonServiceCollectionModel,
    AddonServiceModel,
    AddonServicePriceSlotsModel,
    AddonServicePricesModel,
} from "@/models/addonServiceCollectionModel";
import FiltersComponent from "@/components/FiltersComponent";
import UpdateService from "./components/UpdateService";

import AddPrice from "./components/PriceSlot/addPrice";
import DeletePrice from "./components/PriceSlot/delete";
import UpdatePrice from "./components/PriceSlot/edit";
import { PageHeader } from "@ant-design/pro-layout/es/components/PageHeader";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { formatDateTime } from "@/utils/helpers";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_ADDON_SERVICE_PERMISSION_ENUM } from "@/consts/permission-enum/addon-service-enum";
const AddonServices = () => {
    const { getAddonServices } = useAddonServicesList();
    const [{ data: addonServiceCollection, isLoading, refetch }] = useAtom(
        addonServiceCollectionAtom,
    );
    const { isMobile } = useWindowWidth();
    const [modalAction, setModalAction] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const { deletePrice } = useDeletePrice();

    const showModal = (action: any) => {
        setModalAction(action);
        setModalVisible(true);
    };

    const handleOk = () => {
        setModalVisible(false);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const { Text } = Typography;

    const {
        filters,
        handleFilterChange,
        isFirstCall,
        isFetched,
        handelFilterClear,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest({}, getAddonServices);
        }
    }, [isFirstCall, isFetched, refetch, refetchFromFilter]);

    const AddonServiceCollectionData =
        addonServiceCollection &&
        new AddonServiceCollectionModel(addonServiceCollection);

    const [selectedAddonService, setSelectedAddonService] = useState<
        AddonServiceModel | undefined
    >(undefined);

    const [selectedPrice, setSelectedPrice] = useState<
        AddonServicePricesModel | undefined
    >(undefined);

    const paginationData = AddonServiceCollectionData?.getPagination();

    const filterData = AddonServiceCollectionData?.getFilters();

    const handleMenuClick = (e, record: AddonServiceModel) => {
        setSelectedAddonService(record);

        if (e.key === "update_service") {
            showModal("update_service");
        }
    };

    const handleEdit = (priceSlot: any) => {
        setSelectedPrice(priceSlot);
        showModal("update_price");
    };

    const handleAdd = (priceSlot: any) => {
        setSelectedPrice(priceSlot);
        showModal("add_price");
    };

    const handleDelete = async (priceSlot: any) => {
        Modal.confirm({
            title: "Are you sure you want to delete this item?",
            icon: <ExclamationCircleOutlined />,
            content: "Once deleted, the data cannot be recovered.",
            okText: "Yes, delete it",
            okType: "danger",
            cancelText: "No",
            async onOk() {
                await deletePrice(
                    priceSlot.getAddonServiceId(),
                    priceSlot.getId(),
                );
            },
            onCancel() {},
        });
    };

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && AddonServiceCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,

        AddonServiceCollectionData?.getFilters(),
    ]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAddonServices,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        AddonServiceCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getAddonServices);
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_: string, __: AddonServiceModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            hidden: isMobile,
            key: "name",
            align: "center" as const,
            render: (_: string, record: AddonServiceModel) => {
                return (
                    <div>
                        <Text>{record.getName()}</Text>
                    </div>
                );
            },
        },
        {
            title: "Addon Status",
            hidden: !isMobile,
            width: 250,
            key: "exchange-rates",
            render: (_: string, record: AddonServiceModel) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            minWidth: "150px",
                        }}
                    >
                        <div>
                            Name:
                            <Text>{record.getName()}</Text>
                        </div>
                        <div style={{ marginBottom: "4px" }}>
                            <Text strong style={{ marginRight: "5px" }}>
                                Addon For:
                            </Text>
                            <Tag color="cyan">{record.getAddonFor()}</Tag>
                        </div>
                        <div style={{ marginBottom: "4px" }}>
                            <Text strong style={{ marginRight: "5px" }}>
                                Identifier:
                            </Text>
                            <Tag color="processing">
                                {record.getIdentifier()}
                            </Tag>
                        </div>
                        <div>
                            <Text strong style={{ marginRight: "5px" }}>
                                Status:
                            </Text>
                            <Tag
                                color={
                                    record.getStatus() === "active"
                                        ? "#87d068"
                                        : "#f50"
                                }
                            >
                                {record.getStatus()}
                            </Tag>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Addon For",
            dataIndex: "addon_for",
            hidden: isMobile,
            key: "addon_for",
            align: "center" as const,
            render: (_: string, record: AddonServiceModel) => {
                return (
                    <div>
                        <Tag color="cyan">{record.getAddonFor()}</Tag>
                    </div>
                );
            },
        },
        {
            title: "Identifier",
            dataIndex: "identifier",
            key: "identifier",
            hidden: isMobile,
            align: "center" as const,
            render: (_: string, record: AddonServiceModel) => {
                return (
                    <div>
                        <Tag color="processing">{record.getIdentifier()}</Tag>
                    </div>
                );
            },
        },
        {
            title: "Descriptions",
            dataIndex: "descriptions",
            key: "descriptions",
            width: 200,
            align: "center" as const,
            render: (_: string, record: AddonServiceModel) => {
                return (
                    <div>
                        <Text type="secondary">{record.getDescription()}</Text>
                    </div>
                );
            },
        },
        {
            title: "Prices",
            dataIndex: "prices",
            key: "prices",
            align: "center" as const,
            hidden: !checkActionPermission(
                ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_MANAGE,
                "checkActionPermission",
                null,
            ),
            render: (_: string, record: AddonServiceModel) => {
                const pricesData = record.prices.data;
                if (pricesData && pricesData.length > 0) {
                    return (
                        <Table<AddonServicePricesModel>
                            dataSource={pricesData}
                            columns={[
                                {
                                    title: "Price",
                                    dataIndex: "price",
                                    key: "price",
                                },
                                {
                                    title: "Depend on",
                                    dataIndex: "depends_on",
                                    key: "depends_on",
                                    width: 200,
                                },
                                {
                                    title: "Price Slots",
                                    dataIndex: "price_slots",
                                    key: "price_slots",
                                    align: "center" as const,
                                    render: (
                                        priceSlots: AddonServicePricesModel["price_slots"],
                                    ) => (
                                        <Table<AddonServicePriceSlotsModel>
                                            dataSource={priceSlots.getData()}
                                            columns={[
                                                {
                                                    title: "Min",
                                                    dataIndex: "min_amount",
                                                    key: "min_amount",
                                                },
                                                {
                                                    title: "Max",
                                                    dataIndex: "max_amount",
                                                    key: "max_amount",
                                                },
                                                {
                                                    title: "Price",
                                                    dataIndex: "price",
                                                    key: "price",
                                                },
                                            ]}
                                            pagination={false}
                                        />
                                    ),
                                },
                                {
                                    title: "Actions",
                                    dataIndex: "actions",
                                    key: "actions",
                                    render: (
                                        _,
                                        priceSlot: AddonServicePricesModel,
                                    ) => (
                                        <Space>
                                            {checkActionPermission(
                                                ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_MANAGE,

                                                <Button
                                                    type="dashed"
                                                    onClick={() =>
                                                        handleAdd(priceSlot)
                                                    }
                                                    icon={<PlusOutlined />}
                                                ></Button>,
                                                null,
                                            )}

                                            {checkActionPermission(
                                                ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_MANAGE,
                                                <Button
                                                    onClick={() =>
                                                        handleEdit(priceSlot)
                                                    }
                                                    icon={<EditOutlined />}
                                                    type="default"
                                                ></Button>,
                                                null,
                                            )}

                                            {checkActionPermission(
                                                ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_MANAGE,
                                                <Button
                                                    danger
                                                    onClick={() => {
                                                        handleDelete(priceSlot);
                                                    }}
                                                    icon={<DeleteOutlined />}
                                                ></Button>,
                                                null,
                                            )}
                                        </Space>
                                    ),
                                },
                            ]}
                            pagination={false}
                        />
                    );
                } else {
                    return checkActionPermission(
                        ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_MANAGE,
                        <Button
                            type="dashed"
                            onClick={() => handleAdd(record)}
                            icon={<PlusOutlined />}
                        >
                            Add Price
                        </Button>,
                        null,
                    );
                }
            },
        },

        {
            title: "Status",
            dataIndex: "status",
            hidden: isMobile,
            key: "status",
            align: "center" as const,
            render: (_: string, record: AddonServiceModel) => {
                return (
                    <div>
                        <Tag
                            color={
                                record.getStatus() === "active"
                                    ? "#87d068"
                                    : "#f50"
                            }
                        >
                            {record.getStatus()}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            hidden: isMobile,
            key: "created_at",
            align: "center" as const,
            render: (_: string, record: AddonServiceModel) => {
                return (
                    <div>
                        <Tag color="purple">
                            {formatDateTime(record.getCreatedAt())}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            fixed: isMobile ? "right" : undefined,
            width: 100,
            hidden: !checkActionPermission(
                ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_MANAGE,
                "checkActionPermission",
                null,
            ),
            render: (_: string, record: AddonServiceModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_MANAGE,
                        label: "Update Service",
                        key: "update_service",
                        icon: <EditOutlined />,
                        onClick: async (e) => handleMenuClick(e, record),
                        style: { margin: "5px", padding: "8px 16px" },
                    },
                    // {
                    //   label: isRecalculating ? "Wait...." : "Re-calculate",
                    //   key: "re-calculate",
                    //   icon: <CalculatorFilled />,
                    //   onClick: handleRecalculate,
                    //   disabled: isRecalculating,
                    //   style: { margin: "5px", padding: "8px 16px" },
                    // },
                ];

                if (isMobile) {
                    items.push({
                        permission:
                            ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_VIEW,
                        label: (
                            <Tag color="purple">
                                {formatDateTime(record.getCreatedAt())}
                            </Tag>
                        ),
                        key: "created_at",
                    });
                }

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="All Addons"
                    style={{ marginTop: "10px" }}
                    extra={[
                        checkActionPermission(
                            ADMIN_ADDON_SERVICE_PERMISSION_ENUM.ADMIN_ADDON_SERVICE_MANAGE,
                            <Button
                                onClick={() => {
                                    showModal("create_service");
                                }}
                                icon={<PlusOutlined />}
                                type="dashed"
                            >
                                Create a Addons
                            </Button>,
                            null,
                        ),
                    ]}
                >
                    <FiltersComponent
                        handleProductFilter={handleProductFilter}
                        handleFilterChange={handleFilterChange}
                        handelFilterClear={handelFilterClear}
                        isFetched={isFetched}
                        filters={filters}
                        filtersData={filterData}
                        isFromProductReceived={true}
                    />

                    <div style={{ marginTop: 10 }}>
                        <Table
                            loading={isLoading}
                            rowKey="id"
                            bordered={true}
                            dataSource={AddonServiceCollectionData?.getData()}
                            //@ts-ignore
                            columns={columns}
                            pagination={paginationConfig}
                            scroll={{ x: 1237 }}
                        />
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={
                    modalAction === "create_service"
                        ? "Create Service"
                        : modalAction === "update_service"
                          ? "Update Service"
                          : modalAction === "add_price"
                            ? "Add Price"
                            : modalAction === "update_price"
                              ? "Update Price"
                              : "Delete Price"
                }
                open={modalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                {modalAction === "create_service" && (
                    <CreateService setIsShowCreateModal={setModalVisible} />
                )}
                {modalAction === "update_service" && selectedAddonService && (
                    <UpdateService
                        selectedAddonService={selectedAddonService}
                        setIsShowUpdateModal={setModalVisible}
                    />
                )}
                {modalAction === "add_price" && selectedPrice && (
                    <AddPrice
                        selectedAddPrice={selectedPrice}
                        setIsShowAddPriceModal={setModalVisible}
                    />
                )}
                {modalAction === "update_price" && selectedPrice && (
                    <UpdatePrice
                        selectedUpdatePrice={selectedPrice}
                        setIsShowUpdatePriceModal={setModalVisible}
                    />
                )}
                {modalAction === "delete_price" && selectedPrice && (
                    <DeletePrice
                        setIsShowDeleteModal={setModalVisible}
                        isShowDeleteId={selectedPrice}
                    />
                )}
            </Modal>
        </>
    );
};

export default AddonServices;
