import React from "react";
import { Space, Tag } from "antd";
import {
    SyncOutlined,
    CheckCircleOutlined,
    ClockCircleOutlined,
} from "@ant-design/icons";
import { formatStatus } from "@/helpers/formatStatus";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import { formatDateTime } from "@/utils/helpers";
import { buyProductStatusEnum } from "@/enums/buyProductEnum";

const BuyProductDetailTitle: React.FC<{
    buyProduct: BuyProductModel | undefined;
}> = ({ buyProduct }) => {
    if (!buyProduct) return null;

    return (
        <Space style={{ textTransform: "lowercase" }}>
            <Tag
                icon={
                    buyProduct.getStatus() ===
                    buyProductStatusEnum.BUY_PENDING ? (
                        <SyncOutlined spin />
                    ) : (
                        <CheckCircleOutlined />
                    )
                }
                color={
                    buyProduct.getStatus() === buyProductStatusEnum.BUY_PENDING
                        ? "processing"
                        : buyProduct.getStatus() ===
                            buyProductStatusEnum.BUY_REJECT
                          ? "red"
                          : "success"
                }
            >
                {formatStatus(buyProduct.getStatus())}
            </Tag>

            <Tag
                icon={
                    buyProduct.getOrder()?.getPaymentStatus() ===
                        "invoice-unpaid" ||
                    buyProduct.getOrder()?.getPaymentStatus() === "pending" ? (
                        <SyncOutlined spin />
                    ) : (
                        <CheckCircleOutlined />
                    )
                }
                color={
                    buyProduct.getOrder()?.getPaymentStatus() ===
                        "invoice-unpaid" ||
                    buyProduct.getOrder()?.getPaymentStatus() === "pending"
                        ? "processing"
                        : "success"
                }
            >
                invoice{" "}
                {buyProduct.getOrder()?.getPaymentStatus().replaceAll("-", " ")}
            </Tag>

            <div>
                <ClockCircleOutlined />{" "}
                {formatDateTime(buyProduct.getCreatedAt())}
            </div>
        </Space>
    );
};

export default BuyProductDetailTitle;
