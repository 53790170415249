import React from "react";
import { Card, Col, Row, Descriptions, Typography } from "antd";
import {
    InvoiceAgentCompanyModel,
    InvoiceModel,
} from "@/models/invoiceCollectionModel";
import { StatusTag } from "@/components";
import { formatString } from "@/utils/helper";
const { Text } = Typography;

interface DetailsCardProps {
    invoice: InvoiceModel;
    detailsFor: "customer" | "agent";
    agentCompany?: InvoiceAgentCompanyModel;
}

const BasicDetailsCard: React.FC<DetailsCardProps> = ({
    invoice,
    detailsFor,
    agentCompany,
}) => {
    const order = invoice.getOrder();
    const transactionSummary = invoice.getTransactionSummery();
    const region = invoice.getRegion();
    const currency = region.currency?.code;
    const totalAmount = invoice.getTotalAmount();
    const titleStyle = { fontWeight: "bold", fontSize: "1em" };

    return (
        <Card style={{ margin: "10px" }} title="Basic Details" bordered={false}>
            <Row gutter={[16, 16]}>
                {/* Invoice Details */}
                {detailsFor === "customer" && (
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Descriptions
                            title={<span style={titleStyle}>Invoice</span>}
                            bordered
                            column={1}
                        >
                            <Descriptions.Item label="Invoice Number">
                                <Text copyable>
                                    {invoice.getInvoiceNumber()}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Status">
                                <StatusTag
                                    slug={invoice.getStatus()}
                                    text={invoice.getStatus()}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Region">
                                <StatusTag
                                    slug={region.code}
                                    text={region.code}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Total">
                                {currency} {totalAmount.toFixed(2)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Paid">
                                {currency}{" "}
                                {transactionSummary.paid?.toFixed(2) || 0}
                            </Descriptions.Item>
                            <Descriptions.Item label="Due">
                                {currency}{" "}
                                {transactionSummary.due?.toFixed(2) || 0}
                            </Descriptions.Item>
                            <Descriptions.Item label="Overpaid">
                                {currency}{" "}
                                {transactionSummary.overpaid?.toFixed(2) || 0}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                )}

                {detailsFor === "agent" && (
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Descriptions
                            title={<span style={titleStyle}>Invoice</span>}
                            bordered
                            column={1}
                        >
                            <Descriptions.Item label="Invoice Number">
                                <Text copyable>
                                    {invoice.getInvoiceNumber()}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Status">
                                <StatusTag
                                    slug={invoice.getStatus()}
                                    text={invoice.getStatus()}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Region">
                                <StatusTag
                                    slug={region.code}
                                    text={region.code}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label="Total">
                                {currency} {totalAmount.toFixed(2)}
                            </Descriptions.Item>
                            <Descriptions.Item label="Settled">
                                {currency}{" "}
                                {transactionSummary.paid?.toFixed(2) || 0}
                            </Descriptions.Item>
                            <Descriptions.Item label="Due">
                                {currency}{" "}
                                {transactionSummary.due?.toFixed(2) || 0}
                            </Descriptions.Item>
                            <Descriptions.Item label="Over Settled">
                                {currency}{" "}
                                {transactionSummary.overpaid?.toFixed(2) || 0}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                )}

                {/* Customer Details */}
                {detailsFor === "agent" && (
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Descriptions
                            title={
                                <span style={titleStyle}>Agent Company</span>
                            }
                            bordered
                            column={1}
                        >
                            <Descriptions.Item label="Name">
                                {agentCompany?.getPrimaryName()}
                            </Descriptions.Item>
                            <Descriptions.Item label="Status">
                                <StatusTag
                                    slug={
                                        agentCompany?.getVerificationStatus() ||
                                        ""
                                    }
                                    text={
                                        agentCompany?.getVerificationStatus() ||
                                        ""
                                    }
                                />
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                )}

                {/* Customer Details */}
                {detailsFor === "customer" && (
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <Descriptions
                            title={<span style={titleStyle}>Customer</span>}
                            bordered
                            column={1}
                        >
                            <Descriptions.Item label="Name">
                                {invoice.getCustomer()?.getName()}
                            </Descriptions.Item>
                            <Descriptions.Item label="Email">
                                <Text copyable={true}>
                                    {invoice.getCustomer()?.getEmail()}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Phone">
                                <Text copyable={true}>
                                    {invoice.getCustomer()?.getPhone()}
                                </Text>
                            </Descriptions.Item>
                            <Descriptions.Item label="Shipping Mark">
                                <Text copyable={true}>
                                    {invoice.getCustomer()?.getShippingMark()}
                                </Text>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                )}

                {/* Order Details */}
                <Col xs={24} sm={24} md={12} lg={8}>
                    <Descriptions
                        title={<span style={titleStyle}>Order</span>}
                        bordered
                        column={1}
                    >
                        <Descriptions.Item label="Order Number">
                            <Text copyable={true}>{order.order_number}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">
                            <StatusTag
                                slug={order.order_type}
                                text={formatString(order.order_type)}
                            />
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="Order Handler Name">
              {order.getHandlerName()}
            </Descriptions.Item>
            <Descriptions.Item label="Order Handler Email">
              <Text copyable={true}>{order.getHandlerEmail()}</Text>
            </Descriptions.Item> */}
                    </Descriptions>
                </Col>
            </Row>
        </Card>
    );
};

export default BasicDetailsCard;
