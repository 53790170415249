import { useAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import { addonServiceCollectionAtom } from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import { useState } from "react";
import {
    CreateServicesPostRequestModel,
    Price,
    UpdateServicesPutRequestModel,
} from "@/models/addonServiceCollectionModel";

export const useAddonServicesList = () => {
    const [addons, setAddon] = useAtom(addonServiceCollectionAtom);

    const { addonService } = useService();

    const getAddonServices = async (params: string = ""): Promise<void> => {
        setAddon({ ...addons, isLoading: true, error: null });
        try {
            const response =
                await addonService.addonServiceResource.getList(params);
            setAddon({
                ...addons,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setAddon({
                ...addons,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
        }
    };

    return { getAddonServices } as const;
};

export const useCreateService = () => {
    const { addonService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [addons, setAddon] = useAtom(addonServiceCollectionAtom);

    const createService = async (payload: CreateServicesPostRequestModel) => {
        setIsLoading(true);
        try {
            const response =
                await addonService.addonServiceResource.CreateService(payload);

            setIsLoading(false);
            setAddon({ ...addons, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createService, isLoading, isError };
};

export const useUpdateService = () => {
    const { addonService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [addons, setAddon] = useAtom(addonServiceCollectionAtom);

    const updateService = async (
        id: number,
        payload: UpdateServicesPutRequestModel,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await addonService.addonServiceResource.updateService(
                    id,
                    payload,
                );

            setIsLoading(false);
            setAddon({ ...addons, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updateService, isLoading, isError };
};

export const useAddPrice = () => {
    const { addonService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [addons, setAddon] = useAtom(addonServiceCollectionAtom);

    const addPrice = async (id: number, payload: Price) => {
        setIsLoading(true);
        try {
            const response = await addonService.addonServiceResource.addPrice(
                id,
                payload,
            );

            setIsLoading(false);
            setAddon({ ...addons, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { addPrice, isLoading, isError };
};

export const useUpdatePrice = () => {
    const { addonService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [addons, setAddon] = useAtom(addonServiceCollectionAtom);

    const updatePrice = async (id: number, priceid: number, payload: Price) => {
        setIsLoading(true);
        try {
            const response =
                await addonService.addonServiceResource.updatePrice(
                    id,
                    priceid,
                    payload,
                );

            setIsLoading(false);
            setAddon({ ...addons, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { updatePrice, isLoading, isError };
};

export const useDeletePrice = () => {
    const { addonService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [addons, setAddon] = useAtom(addonServiceCollectionAtom);

    const deletePrice = async (id: number, priceid: number) => {
        setIsLoading(true);
        try {
            const response =
                await addonService.addonServiceResource.deletePrice(
                    id,
                    priceid,
                );

            setIsLoading(false);
            setAddon({ ...addons, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
        }
    };

    return { deletePrice, isLoading, isError };
};
