import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
    Button,
    Col,
    Dropdown,
    Modal,
    Row,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import { useGetAgentCompanyMembers } from "@/lib/core-react/hooks/private/useAgentCompany";
import { memberCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { AgentCompanyModel } from "@/models/agentCompanyCollectionModel";
import { ApiHelperModel } from "@/models/apiHelper";
import {
    MemberCollectionModel,
    MemberModel,
    MemberUserModel,
} from "@/models/memberCollectionModel";
import { PaginationModel } from "@/models/pagination";
import DateTimeFormatter from "@/components/DateTime/DateTimeFormatter";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import FiltersComponent from "@/components/FiltersComponent";
import { IFilterType } from "@/types/filters";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { DownOutlined, HddFilled } from "@ant-design/icons";
import { FiLogIn } from "react-icons/fi";
import { ADMIN_USER_PERMISSION_ENUM } from "@/consts/permission-enum/admin-user-enum";
import ImpersonateForm from "@/components/ImpersonateSessionCreate/impersonate";
import { UserType } from "@/types/userCollection";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";
import CommonError from "@/components/Error/CommonError";

interface IProps {
    data: AgentCompanyModel;
}

export const MembersTable = ({ data }: IProps) => {
    const { getAgentCompanyMembers } = useGetAgentCompanyMembers(data.id);
    const [
        { data: memberCollectionData, isLoading, refetch, error, unAuthorized },
    ] = useAtom(memberCollectionAtom);
    const {
        filters,
        refetch: refetchFromFilter,
        initializeAvailableFilter,
        handleFilterChange,
        isFirstCall,
        isFetched,
        handelFilterClear,
    } = useDataFilters(true);
    const [isShowImpersonateModal, setIsShowImpersonateModal] =
        useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<
        MemberUserModel | undefined
    >(undefined);

    const { isMobile } = useWindowWidth();

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter ||
            data.id
        ) {
            ApiHelperModel.makeGetRequest(filters, getAgentCompanyMembers);
        }
    }, [data.id, filters, refetchFromFilter]);

    const MemberCollectionData =
        memberCollectionData && new MemberCollectionModel(memberCollectionData);

    const filtersData = MemberCollectionData?.getFilters();

    useEffect(() => {
        if (!isFetched && filtersData) {
            initializeAvailableFilter(filtersData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter]);

    // Pagination
    const paginationData = MemberCollectionData?.pagination;

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAgentCompanyMembers,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        MemberCollectionData,
        handlePaginationChange,
    );

    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getAgentCompanyMembers);
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "id",
            align: "center" as const,
            width: 70,
            render: (_: string, __: MemberModel, index: number) => {
                return (
                    <>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </>
                );
            },
        },
        {
            title: "User info",
            dataIndex: "user",
            key: "user",
            hidden: isMobile,
            render: (_: string, record: MemberModel) => {
                return (
                    <Space direction="vertical">
                        <Space>
                            <Typography.Text strong>Name: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getName()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>Phone: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getPhone()}
                            </Typography.Text>
                        </Space>
                        <Space></Space>
                        <Space>
                            <Typography.Text strong>Email: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getEmail()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Shipping mark:{" "}
                            </Typography.Text>{" "}
                            <Typography.Text>
                                {record.getUser().getShippingMark()}
                            </Typography.Text>
                        </Space>
                    </Space>
                );
            },
        },
        {
            title: "User info",
            dataIndex: "user",
            hidden: !isMobile,
            key: "user",
            render: (_: string, record: MemberModel) => {
                return (
                    <Space direction="vertical">
                        <Space>
                            <Typography.Text strong>Name: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getName()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>Phone: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getPhone()}
                            </Typography.Text>
                        </Space>
                        <Space></Space>
                        <Space>
                            <Typography.Text strong>Email: </Typography.Text>
                            <Typography.Text>
                                {record.getUser().getEmail()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            <Typography.Text strong>
                                Shipping mark:{" "}
                            </Typography.Text>{" "}
                            <Typography.Text>
                                {record.getUser().getShippingMark()}
                            </Typography.Text>
                        </Space>
                        <Space>
                            {record.getRoles() ? (
                                <div
                                    style={{
                                        textTransform: "capitalize",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 10,
                                        width: 200,
                                    }}
                                >
                                    {record.getRoles().map((role) => (
                                        <Tag key={role.id} color="green">
                                            {role.name}
                                        </Tag>
                                    ))}
                                </div>
                            ) : (
                                ""
                            )}
                            ;
                        </Space>
                        <>
                            {record.getStatus() ? (
                                <Tag
                                    style={{ textTransform: "capitalize" }}
                                    color="green"
                                >
                                    {record.getStatus()}
                                </Tag>
                            ) : (
                                ""
                            )}
                        </>
                        <>
                            {record.getMemberSince() ? (
                                <DateTimeFormatter
                                    date={record.getMemberSince()}
                                />
                            ) : (
                                ""
                            )}
                        </>
                    </Space>
                );
            },
        },
        {
            title: "Roles",
            dataIndex: "roles",
            hidden: isMobile,
            key: "roles",
            render: (_: string, record: MemberModel) => {
                return record.getRoles() ? (
                    <div
                        style={{
                            textTransform: "capitalize",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 10,
                            width: 200,
                        }}
                    >
                        {record.getRoles().map((role) => (
                            <Tag key={role.id} color="green">
                                {role.name}
                            </Tag>
                        ))}
                    </div>
                ) : (
                    ""
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            align: "center" as const,
            hidden: isMobile,
            key: "status",
            render: (_: string, record: MemberModel) => {
                return record.getStatus() ? (
                    <Tag style={{ textTransform: "capitalize" }} color="green">
                        {record.getStatus()}
                    </Tag>
                ) : (
                    ""
                );
            },
        },
        {
            title: "Member since",
            dataIndex: "user",
            align: "center" as const,
            key: "user",
            hidden: isMobile,
            render: (_: string, record: MemberModel) => {
                return record.getMemberSince() ? (
                    <DateTimeFormatter date={record.getMemberSince()} />
                ) : (
                    ""
                );
            },
        },
        checkActionPermission(
            ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
            {
                title: "Actions",
                key: "actions",
                fixed: isMobile ? "right" : undefined,
                width: isMobile ? 80 : 150,
                ellipsis: true,
                render: (_: string, record: MemberModel) => {
                    const items: ExtendedMenuItemType[] = [
                        {
                            icon: <FiLogIn />,
                            label: "Create Impersonate",
                            key: "login",
                            permission:
                                ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
                            onClick: async () => {
                                setIsShowImpersonateModal(true);
                                setSelectedUser(record.getUser());
                            },
                        },
                    ];
                    return (
                        <Dropdown
                            menu={{
                                items: items.filter((x) =>
                                    checkActionPermission(
                                        x.permission,
                                        x,
                                        null,
                                    ),
                                ),
                            }}
                        >
                            <Button icon={<HddFilled />}>
                                {isMobile ? (
                                    <DownOutlined />
                                ) : (
                                    <>
                                        Actions <DownOutlined />{" "}
                                    </>
                                )}
                            </Button>
                        </Dropdown>
                    );
                },
            },
            <></>,
        ),
    ];

    if (unAuthorized || error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                hidePageHeader={true}
            />
        );
    }

    return (
        <>
            {filtersData && Object.keys(filtersData).length > 0 && (
                <Row>
                    <Col span={24}>
                        <FiltersComponent
                            handleProductFilter={handleFilter}
                            handleFilterChange={handleFilterChange}
                            handelFilterClear={handelFilterClear}
                            isFetched={isFetched}
                            filters={filters}
                            filtersData={filtersData}
                            isFromProductReceived={true}
                            isSubFilter={true}
                        />
                    </Col>
                </Row>
            )}

            <Table
                //@ts-ignore
                columns={columns}
                size="small"
                bordered
                loading={isLoading}
                dataSource={MemberCollectionData?.data}
                pagination={paginationConfig}
                rowKey="id"
            />

            {isShowImpersonateModal && selectedUser && (
                <Modal
                    title={
                        <ImpersonateCreateModalTitle
                            name={selectedUser.getName() || ""}
                            email={selectedUser.getEmail() || ""}
                            title=""
                        />
                    }
                    open={isShowImpersonateModal}
                    destroyOnClose={true}
                    footer={false}
                    onCancel={() => setIsShowImpersonateModal(false)}
                >
                    <ImpersonateForm
                        userType={UserType.Agent}
                        selectedUser={{ id: selectedUser.getId() }}
                        setIsModalVisible={setIsShowImpersonateModal}
                    />
                </Modal>
            )}
        </>
    );
};
