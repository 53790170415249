import { Alert, Card, Col, Flex, Row, Space } from "antd";
import { Typography } from "antd/lib";
import { ShipmentProductPackageModel } from "@/models/shipmentProductPackageCollection";
import { tw } from "@/consts/theme/tailwindTheme";
import { StatusTag } from "@/components";

interface Props {
    packages: ShipmentProductPackageModel[];
}

const { Text } = Typography;

const ShipmentProductPackagesQuickView: React.FC<Props> = ({ packages }) => {
    // const trackingColumns: ColumnsType<ShipmentTrackingModel> = [
    //     {
    //         key: "id",
    //         title: "Type",
    //         dataIndex: "type",
    //         render: (target) => (
    //             <>
    //                 <Typography>
    //                     <Text>{formatString(target)} </Text>
    //                 </Typography>
    //             </>
    //         ),
    //     },
    //     {
    //         key: "id",
    //         align: "center",
    //         title: "Tracking",
    //         render: (_, record) => (
    //             <Typography>
    //                 <Text copyable>{record.getTracking()} </Text>
    //             </Typography>
    //         ),
    //     },
    //     {
    //         key: "id",
    //         align: "center",
    //         title: "Actions",
    //         width: 200,
    //         render: () => (
    //             <Dropdown
    //                 menu={{
    //                     items: [
    //                         {
    //                             type: "group",
    //                             label: "Add",
    //                         },
    //                         {
    //                             type: "group",
    //                             label: "Delete",
    //                         },
    //                     ],
    //                 }}
    //             >
    //                 <Button type="primary" icon={<HddFilled />} />
    //             </Dropdown>
    //         ),
    //     },
    // ];
    // const variationColumns: ColumnsType<ShipmentProductPackageVariationModel> =
    //     [
    //         {
    //             key: "id",
    //             title: "Meta",
    //             render: (_, variant) => (
    //                 <>
    //                     <Flex key={variant.getId()} gap={8}>
    //                         <div
    //                             style={{
    //                                 width: 42,
    //                                 height: 42,
    //                                 overflow: "hidden",
    //                             }}
    //                         >
    //                             <ImageWithPlaceholder
    //                                 src={`${variant.getFirstPropertyImageUrl()}`}
    //                             />
    //                         </div>

    //                         <div>
    //                             {variant
    //                                 .getProperties()
    //                                 .map((singleProperty) => {
    //                                     return (
    //                                         <Flex
    //                                             gap={4}
    //                                             align="center"
    //                                             key={singleProperty.id}
    //                                         >
    //                                             <Text>
    //                                                 {
    //                                                     singleProperty.property_name
    //                                                 }
    //                                                 :
    //                                             </Text>

    //                                             <Text
    //                                                 strong
    //                                                 style={{
    //                                                     fontSize:
    //                                                         tw.fontSize.sm
    //                                                             .fontSize,
    //                                                     lineHeight:
    //                                                         tw.fontSize.sm
    //                                                             .lineHeight,
    //                                                 }}
    //                                             >
    //                                                 {singleProperty.property_name.toLowerCase() ==
    //                                                 "size"
    //                                                     ? singleProperty.property_value_name.toUpperCase()
    //                                                     : singleProperty.property_value_name}
    //                                             </Text>
    //                                         </Flex>
    //                                     );
    //                                 })}
    //                         </div>
    //                     </Flex>
    //                 </>
    //             ),
    //         },
    //         {
    //             key: "id",
    //             align: "center",
    //             title: "Quantity",
    //             dataIndex: "quantity",
    //             render: (target) => (
    //                 <Typography>
    //                     <Text>{target} </Text>
    //                 </Typography>
    //             ),
    //         },
    //     ];

    return (
        <Flex gap={24} vertical>
            {packages.length ? (
                <Flex vertical gap={24}>
                    {packages.map((pkg, index) => {
                        return (
                            <Card
                                style={{
                                    border: tw.border.DEFAULT,
                                }}
                                title={`Package-${index + 1}`}
                            >
                                <Flex vertical gap={24}>
                                    <Row>
                                        <Col span={12}>
                                            <Flex gap={24} vertical>
                                                <Space
                                                    direction="vertical"
                                                    size={"small"}
                                                >
                                                    <Typography>
                                                        <Text>
                                                            {" "}
                                                            Package Number:{" "}
                                                        </Text>
                                                        <Text copyable strong>
                                                            {pkg.getPackageNumber()}
                                                        </Text>
                                                    </Typography>
                                                    <Typography>
                                                        <Text> Width: </Text>
                                                        <Text strong>
                                                            {pkg.getWidth()}
                                                        </Text>
                                                        <Text strong>KG</Text>
                                                    </Typography>
                                                    <Typography>
                                                        <Text> Height: </Text>
                                                        <Text strong>
                                                            {pkg.getHeight()}
                                                        </Text>
                                                        <Text strong>KG</Text>
                                                    </Typography>
                                                    <Typography>
                                                        <Text>Length: </Text>
                                                        <Text strong>
                                                            {pkg.getLength()}
                                                        </Text>
                                                        <Text strong>KG</Text>
                                                    </Typography>
                                                </Space>
                                                <Space
                                                    direction="vertical"
                                                    size={"small"}
                                                >
                                                    <Typography>
                                                        <Text> Status: </Text>
                                                        <Text strong>
                                                            <StatusTag
                                                                text={pkg.getFulfillmentStatus()}
                                                                slug={pkg.getFulfillmentStatus()}
                                                            />
                                                        </Text>
                                                    </Typography>

                                                    <Typography>
                                                        <Text>
                                                            Carton number:
                                                        </Text>
                                                        <Text strong>
                                                            {pkg.getCartonNumber()
                                                                ? pkg.getCartonNumber()
                                                                : "N/A"}
                                                        </Text>
                                                    </Typography>
                                                </Space>
                                            </Flex>
                                        </Col>
                                        <Col span={12}>
                                            <Space>
                                                <Space
                                                    direction="vertical"
                                                    size={"small"}
                                                >
                                                    <Typography>
                                                        <Text>
                                                            {" "}
                                                            Product Price:{" "}
                                                        </Text>
                                                        <Text strong>
                                                            {pkg.getProductPrice()}
                                                            {pkg
                                                                .getProductPriceCurrency()
                                                                ?.getCode()}
                                                        </Text>
                                                    </Typography>

                                                    <Typography>
                                                        <Text> Quantity: </Text>
                                                        <Text strong>
                                                            {pkg.getQuantity()}
                                                        </Text>
                                                        <Text strong>KG</Text>
                                                    </Typography>
                                                    <Typography>
                                                        <Text> Width: </Text>
                                                        <Text strong>
                                                            {pkg.getWidth()}
                                                        </Text>
                                                        <Text strong>KG</Text>
                                                    </Typography>
                                                    <Typography>
                                                        <Text>CBM: </Text>
                                                        <Text strong>
                                                            {pkg.getCbm()}
                                                        </Text>
                                                        <Text strong>KG</Text>
                                                    </Typography>
                                                </Space>
                                                <Space
                                                    direction="vertical"
                                                    size={"small"}
                                                >
                                                    <Typography>
                                                        <Text> Contains: </Text>
                                                        <Text strong>
                                                            {pkg.getContains()}
                                                        </Text>
                                                    </Typography>
                                                    {pkg.getHsCode() && (
                                                        <Typography>
                                                            <Text>
                                                                HS Code:
                                                            </Text>
                                                            <Text strong>
                                                                {pkg.getHsCode()}
                                                            </Text>
                                                        </Typography>
                                                    )}
                                                </Space>
                                            </Space>
                                        </Col>
                                    </Row>

                                    {/* <Table
                                        bordered
                                        size="small"
                                        rowHoverable={false}
                                        pagination={false}
                                        columns={trackingColumns}
                                        locale={{
                                            emptyText:
                                                !pkg.getTrackings() ||
                                                pkg.getTrackings().length ===
                                                    0 ? (
                                                    <Text type="danger">
                                                        No Tracking available.
                                                    </Text>
                                                ) : (
                                                    "Loading data..."
                                                ),
                                        }}
                                        dataSource={pkg.getTrackings()}
                                        rowKey={"id"}
                                        scroll={{ x: 600 }}
                                    /> */}
                                    {/* <Table
                                        title={() => (
                                            <Typography.Text strong>
                                                Description
                                            </Typography.Text>
                                        )}
                                        bordered
                                        size="small"
                                        rowHoverable={false}
                                        pagination={false}
                                        columns={variationColumns}
                                        dataSource={pkg.getVariations()}
                                        rowKey={"id"}
                                        scroll={{ x: 600 }}
                                    /> */}
                                </Flex>
                            </Card>
                        );
                    })}
                </Flex>
            ) : (
                <Alert message={"Carton Not Found"} />
            )}

            {/* <Flex>
                {packagesData.length > 2 ? (
                    <Button
                        style={{
                            border: tw.borderWidth[0],
                            marginTop: tw.spacing[2],
                        }}
                        onClick={() => setIsExpand(!isExpand)}
                        type="primary"
                    >
                        <RightOutlined
                            style={{
                                transform: `rotate(-${tw.rotate[isExpand ? 90 : 0]})`,
                                transition: tw.transitionProperty.transform,
                                transitionDuration: tw.transitionDelay[300],
                            }}
                        />
                        See {!isExpand ? "more" : "less"}
                    </Button>
                ) : (
                    <></>
                )}
            </Flex> */}
        </Flex>
    );
};

export default ShipmentProductPackagesQuickView;
