import AdminUserResource from "@/lib/core/resources/private/adminUserResource";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";

class AdminUserService extends CommerceApiService {
    public adminUserResource: AdminUserResource;

    constructor() {
        super();

        this.adminUserResource = new AdminUserResource(this.config);
    }
}
export default AdminUserService;
