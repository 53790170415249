import { Card, Table, Tag, Typography } from "antd";
import React from "react";
import { InvoiceModel } from "@/models/invoiceCollectionModel";
import { StatusTag } from "@/components";
import TransactionsAssociationTable from "./TransactionsItemsTable";
import { formatDateTime } from "@/utils/helpers";

interface InvoiceTransactionProps {
    invoice: InvoiceModel;
    transactionFor: "customer" | "agent";
}

const InvoiceTransaction: React.FC<InvoiceTransactionProps> = ({ invoice }) => {
    const { Text } = Typography;
    const currency = invoice.getRegion().currency?.code;
    const userId = invoice.getCustomer()?.getId();
    const data =
        invoice.getTransactions()?.map((item, index) => {
            const amount =
                typeof item.amount === "number"
                    ? item.amount.toFixed(2)
                    : "0.00";

            return {
                key: index,
                transactionNumber: item.transaction_number,
                amount: currency + amount,
                type: item.type,
                // availableBalance:
                //     item.associations.available_balance
                //         ? currency + item.getAssociationsWallet()?.available_balance
                //         : "",
                // reservedBalance: item.getAssociationsWallet()?.reserved_balance
                //         ? currency + item.getAssociationsWallet()?.reserved_balance
                //         : "",
                // pendingBalance: item.getAssociationsWallet()?.pending_balance
                //         ? currency + item.getAssociationsWallet()?.pending_balance
                //         : "",
                createdAt: item.created_at,
                associations: item.associations,
                id: item.id,
                description: item.description,
            };
        }) || [];

    // const hasAvailableBalance = data.some(item => item.availableBalance);
    // const hasReservedBalance = data.some(item => item.reservedBalance);
    // const hasPendingBalance = data.some(item => item.pendingBalance);

    const columns = [
        {
            title: "Transaction ID",
            dataIndex: "id",
            key: "id", // Make sure the key matches the dataIndex
        },
        {
            title: "Transaction Number",
            dataIndex: "transactionNumber",
            key: "transactionNumber",
            render: (transactionNumber: string) => (
                <Text copyable>{transactionNumber}</Text>
            ),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type: string) => <StatusTag text={type} slug={type} />,
        },
        {
            title: "Descriptions",
            dataIndex: "description",
            key: "description",
            render: (description: string) => <Text>{description}</Text>,
        },
        // hasAvailableBalance && {
        //     title: "Available Balance",
        //     dataIndex: "availableBalance",
        //     key: "availableBalance",
        // },
        // hasReservedBalance && {
        //     title: "Reserved Balance",
        //     dataIndex: "reservedBalance",
        //     key: "reservedBalance",
        // },
        // hasPendingBalance && {
        //     title: "Pending Balance",
        //     dataIndex: "pendingBalance",
        //     key: "pendingBalance",
        // },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text: string) => (
                <Tag color="purple">{formatDateTime(text)}</Tag>
            ),
        },
    ].filter(Boolean) as Array<object>;

    const expandedRowRender = (record: any) => {
        return (
            <TransactionsAssociationTable
                data={record?.associations?.data || []}
                currency={currency || ""}
                userId={userId ? userId : null}
            />
        );
    };

    return (
        <>
            <Card style={{ margin: "10px" }} title="Invoice Transaction">
                <Table
                    columns={columns}
                    expandable={{
                        expandedRowRender,
                        defaultExpandedRowKeys: ["0"],
                    }}
                    dataSource={data}
                    rowKey="transactionNumber"
                    pagination={false}
                    scroll={{ x: 1237 }}
                />
            </Card>
        </>
    );
};

export default InvoiceTransaction;
