import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import AddonServiceResource from "@/lib/core/resources/private/addonServiceResource";

class AddonService extends CommerceApiService {
    public addonServiceResource: AddonServiceResource;

    constructor() {
        super();
        this.addonServiceResource = new AddonServiceResource(this.config);
    }
}
export default AddonService;
