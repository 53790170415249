import React from "react";

import { IMessage, ButtonTypeEnum } from "@novu/shared";
import { IUserPreferenceSettings } from "@novu/client";

import { INotificationCenterContext, ITab } from "../shared/interfaces";

export const NotificationCenterContext =
    React.createContext<INotificationCenterContext>({
        onUrlChange: (_url: string) => {},
        onNotificationClick: (_notification: IMessage) => {},
        onUnseenCountChanged: (_unseenCount: number) => {},
        onActionClick: (
            _identifier: string,
            _type: ButtonTypeEnum,
            _message: IMessage,
        ) => {},
        onTabClick: (_tab: ITab) => {},
        preferenceFilter: (_userPreference: IUserPreferenceSettings) => false,
        isLoading: true,
        header: null,
        footer: null,
        emptyState: null,
        listItem: null,
        actionsResultBlock: null,
        tabs: [],
        showUserPreferences: true,
        allowedNotificationActions: true,
    } as any);
