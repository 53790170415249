import { ISelectOption } from "@/filters/Models/SelectOptionModel";
import { ICampaign, ICampaignAttributes, IMeta } from "@/types/campaignStrip";

export class CampaignStripeModel {
    id: number;
    attributes: ICampaignAttributes;

    constructor(payload: ICampaign) {
        this.id = payload.id;
        this.attributes = payload.attributes;
    }

    getTitle() {
        return this.attributes.title;
    }

    getSlug() {
        return this.attributes.slug;
    }

    getPrimaryColor() {
        return this.attributes.primaryColor;
    }

    getSecondaryColor() {
        return this.attributes.secondaryColor;
    }

    getBgPrimaryColor() {
        return this.attributes.bgPrimaryColor;
    }

    getBgSecondaryColor() {
        return this.attributes.bgSecondaryColor;
    }

    getCreatedAt() {
        return this.attributes.createdAt;
    }

    getUpdatedAt() {
        return this.attributes.updatedAt;
    }

    getPublishedAt() {
        return this.attributes.publishedAt;
    }
}

export class CampaignDataModel {
    data: CampaignStripeModel[];
    meta: IMeta;

    constructor(payload) {
        this.data = payload.data.map((item) => new CampaignStripeModel(item));
        this.meta = payload.meta;
    }

    getCampaigns() {
        return this.data;
    }
    getOptions(): ISelectOption[] {
        return this.data && this.data.length > 0
            ? this.data.map((item) => {
                  return {
                      label: item.attributes.title,
                      value: item.attributes.slug,
                  };
              })
            : [];
    }

    getMeta() {
        return this.meta;
    }

    getPage() {
        return this.meta.pagination.page;
    }

    getPageSize() {
        return this.meta.pagination.pageSize;
    }

    getPageCount() {
        return this.meta.pagination.pageCount;
    }

    getTotal() {
        return this.meta.pagination.total;
    }
}
