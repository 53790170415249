import { atom, createStore } from "jotai";
import { DestinationWarehouseCollectionModel } from "@/models/destinationWarehouse";
import { LanguageModel } from "@/models/language";
import { IFilterType } from "@/types/filters";
import { AuctionRuleModel } from "@/models/auctionRule";
import { WishlistCollectionModel } from "@/models/wishlistCollectionModel";
import { CustomerUserCollectionModel } from "@/models/customerUserCollectionModel";
import { RoleCollectionModel } from "@/models/roleCollectionModel";
import { PermissionCollectionModel } from "@/models/permissionCollectionModel";
import { ShipmentProductBidCollectionModel } from "@/models/shipmentProductBidCollectionModel";
import { ShippingCartCollectionModel } from "@/models/shippingCartCollectionModel";
import { AddressCollectionModel } from "@/models/addressCollectionModel";
import { ICommissionCollection } from "@/types/commissionCollection";
import {
    IExchangeList,
    IExchangeListCollection,
} from "@/types/exchangeListCollection";
import { IExchangeGroupCollection } from "@/types/exchangeGroupCollection";
import { ICustomerUserCollection } from "@/types/customerUserCollection";
import { IAgentCompanyCollection } from "@/types/agentCompanyCollection";
import { IMemberCollection } from "@/types/memberCollection";
import { IAgentCompanyShippingPointCollection } from "@/types/agentCompanyShippingPointCollection";
import { IAgentWarehouseCollection } from "@/types/agentWarehouseCollection";
import { IShipmentProductCollection } from "@/types/shipmentProductCollection";
import { WalletOverviewModel } from "@/models/walletOverviewCollectionModel";
import { AddonServiceCollectionModel } from "@/models/addonServiceCollectionModel";
import { BuyActionCollectionModel } from "@/models/buyActionCollectionModel";
import { ICampaignCollection } from "@/types/campaignCollection";
import { IDiscountRulesSchema } from "@/types/discountRulesSchema";
import { IShippingCountryCollection } from "@/types/shippingCountryCollection";
import { IDiscountUsageHistoryCollection } from "@/types/discountUsageHistoryCollection";
import { IPayoutRequestCollection } from "@/types/payoutRequestCollection";
import { IDiscount, IDiscountCollection } from "@/types/discountCollection";
import { IDisputeCollection } from "@/types/disputeCollection";
import {
    DisputePreview,
    IDisputeDetailsCollection,
} from "@/types/disputeDetailsCollection";
import { IAgentVerificationCollection } from "@/types/agentVerificationCollection";
import {
    IDestinationWarehouseCollection,
    IDestinationWarehouseMemberCollection,
    IDestinationWarehouseMembershipInvitationCollection,
} from "@/types/destinationWarehouseCollection";
import { IRfqRequestCollection } from "@/types/rfqRequestCollection";
import { HarvestBatchCollectionModel } from "@/models/harvestBatchCollectionModel";
import { ITransaction } from "@/types/transactionDetail";
import { IBuyOrderPlace } from "@/types/buyOrderPlace";
import { IAddressSchema } from "@/types/addressSchema";
import { IPurchaseCommissionCollection } from "@/types/purchaseCommissionCollection";
import { ICourierGatewayCollection } from "@/types/courierGatewayCollection";
import { ICountryCollection } from "@/types/countryCollection";
import { IStoreCollection } from "@/types/storeCollection";

import { IWalletTransactionCollection } from "../../../types/walletTransactionCollection";
import { PayoutGatewayCollectionModel } from "@/models/payoutGatewayCollectionModel";
import { CampaignDataModel } from "@/models/campaignStripModel";
import {
    ImpersonateSessionCollectionModel,
    ImpersonateSessionModel,
    UserCollectionModel,
} from "@/models/userCollectionModel";
import { InvoicePaymentRuleResponseModel } from "../../../models/invoicePaymentRoleModel";
import { IShippingCategoryCollection } from "../../../types/shippingCategory";
import { IBulkUserCollection } from "../../../types/bulkUserCollection.d";
import { HSCodeCollectionModel } from "@/models/hsCodeCollectionModel";
import { IImpersonateCreateResponse } from "../../../types/userCollection";
import { createDataAtom } from "./jotaiUtility";
import { IMessage } from "@novu/shared";
import {
    IAgentInvoiceCollection,
    IInvoice,
    IInvoiceCollection,
} from "@/types/InvoiceCollection";
import { StoreExchangePreviewModel } from "@/models/storeExchangePreviewModel";
import { ProductSearchResultModel } from "@/models/ProductSearchCollectionModel";
import { IBaseShippingCategory } from "@/types/baseShippingCategory";
import {
    ShipmentProductCollectionModel,
    ShipmentProductModel,
} from "@/models/shipmentProductCollectionModel";
import { ICurrencyCollection } from "@/types/currencyCollection";
import { ShipmentProductPackageCollectionModel } from "@/models/shipmentProductPackageCollection";
import { IRegionCollection } from "@/types/regionCollection";
import { IAgentCategoryPriceReadCollection } from "@/types/agentCategoryPriceReadCollection";
import { CurrentCurrencyModel } from "@/models/currentCurrencyModel";
import { IPaymentGatewayCollection } from "@/types/paymentGatewayCollection";
import { PurchaseCartCollectionModel } from "@/models/PurchaseCartCollectionModel";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";
import { CampaignProductCollectionModel } from "@/models/campaignProductCollectionModel";
import { IPaymentSessionResponse } from "@/types/paymentSession";
import { SupportInquiryCollectionModel } from "@/models/supportInquiryCollectionModel";
import { ITransactionCollection } from "@/types/transactionCollection";
import { IUnlistedPackageCollection } from "@/types/unlistedPackageCollection";
import {
    IDeliveryRequest,
    IDeliveryRequestCollection,
} from "@/types/deliveryRequestCollection";
import { IWarehouseShipmentCollection } from "@/types/warehouseShipmentCollection";
import { InventoryCategoryResponseModel } from "@/models/inventoryCollectionModel";

const rootStore = createStore();

// Agent Manage ->
export const agentCompanyCollectionAtom =
    createDataAtom<IAgentCompanyCollection>();

export const warehouseShipmentCollectionAtom =
    createDataAtom<IWarehouseShipmentCollection>();

export const memberCollectionAtom = createDataAtom<IMemberCollection>();
export const agentVerificationCollectionAtom =
    createDataAtom<IAgentVerificationCollection>();

export const agentCompanyShippingPointCollectionAtom =
    createDataAtom<IAgentCompanyShippingPointCollection>();

export const agentWarehouseCollectionAtom =
    createDataAtom<IAgentWarehouseCollection>();

// Region Country store
export const regionCountry = createDataAtom<ICountryCollection>();

// Region Currency store
export const regionCurrency = createDataAtom<ICurrencyCollection>();

// Region Currency store
export const hsCodeCollection = createDataAtom<HSCodeCollectionModel>();

// Region Language store
export const regionLanguage = createDataAtom<LanguageModel>();

// Region store
export const regionRegion = createDataAtom<IRegionCollection>();
export const currentCurrencyAtom = createDataAtom<CurrentCurrencyModel>();

// Courier courier gateway store
export const courierGatewayCollectionAtom =
    createDataAtom<ICourierGatewayCollection>();

// Destination warehouse
export const destinationWarehouse =
    createDataAtom<DestinationWarehouseCollectionModel>(); // old version
export const destinationWarehouseCollectionAtom =
    createDataAtom<IDestinationWarehouseCollection>();
export const destinationWarehouseMemberCollectionAtom =
    createDataAtom<IDestinationWarehouseMemberCollection>();
export const destinationWarehouseInvitationCollectionAtom =
    createDataAtom<IDestinationWarehouseMembershipInvitationCollection>();

// Shipping Country
export const shippingCountry = createDataAtom<IShippingCountryCollection>();
export const shippingCountryCollectionAtom =
    createDataAtom<IShippingCountryCollection>();

// BaseShipping Category store
export const baseShippingCategoryAtom = createDataAtom<IBaseShippingCategory>();

// Inventory Category store
export const inventoryCategoryTreeAtom =
    createDataAtom<InventoryCategoryResponseModel>();
// Inventory Category store
export const inventoryCategoryTreeAtomByName =
    createDataAtom<InventoryCategoryResponseModel>();

// Shipping Category store
export const shippingCategoryAtom =
    createDataAtom<IShippingCategoryCollection>();

// Auction Rule store
export const auctionRuleAtom = createDataAtom<AuctionRuleModel>();

// Bulk admin store
export const bulkUserAdmin = createDataAtom<IBulkUserCollection>();

// Bulk customer store
export const bulkUserCustomer = createDataAtom<IBulkUserCollection>();

// Shipping Commission store
export const shippingCommissionAtom = createDataAtom<ICommissionCollection>();

// Store exchange List
export const storeExchangeAtom = createDataAtom<IExchangeListCollection>();
// Store exchange List
export const storeExchangePreviewAtom =
    createDataAtom<StoreExchangePreviewModel>();

// Single Store exchange
export const storeSingleExchangeAtom = createDataAtom<IExchangeList>();

// Store exchange group
export const exchangeGroupCollectionAtom =
    createDataAtom<IExchangeGroupCollection>();

export const exchangeGroupCustomerUserCollectionAtom =
    createDataAtom<ICustomerUserCollection>();

export const storeStoreAtom = createDataAtom<IStoreCollection>();

// Query store
export const queryStringAtom = atom<IFilterType>({});

// Customer Manage
export const customerUserCollectionAtom =
    createDataAtom<CustomerUserCollectionModel>();
export const wishlistAtom = createDataAtom<WishlistCollectionModel>();
export const shippingCartAtom = createDataAtom<ShippingCartCollectionModel>();

// Role Permission
export const roleAtom = createDataAtom<RoleCollectionModel>();
export const impersonateSessions =
    createDataAtom<ImpersonateSessionCollectionModel>();
export const impersonateSessionsDetails =
    createDataAtom<ImpersonateSessionModel>();
export const impersonateSessionsCreate =
    createDataAtom<IImpersonateCreateResponse>();

export const permissionAtom = createDataAtom<PermissionCollectionModel>();
export const loginUserPermission = createDataAtom<RoleCollectionModel>();

// Admin User
export const adminUserCollection = createDataAtom<UserCollectionModel>();

//Shipment Products
export const shipmentProductsAtom =
    createDataAtom<IShipmentProductCollection>();
export const shipmentProductsInvoiceAtom =
    createDataAtom<ShipmentProductCollectionModel>();
export const unlistedProductsAtom =
    createDataAtom<IUnlistedPackageCollection>();
export const shipmentProductAtom = createDataAtom<ShipmentProductModel>();
export const shipmentProductPackageAtom =
    createDataAtom<ShipmentProductPackageCollectionModel>();

//Agent Category Price Read Collection
export const agentCategoryPriceReadCollectionAtom =
    createDataAtom<IAgentCategoryPriceReadCollection>();

//Bids
export const bidCollectionAtom =
    createDataAtom<ShipmentProductBidCollectionModel>();

//Address
export const addressCollectionAtom = createDataAtom<AddressCollectionModel>();

// Payment Manage
export const paymentGatewayCollectionAtom =
    createDataAtom<IPaymentGatewayCollection>();

export const payoutGateWayCollectionAtom =
    createDataAtom<PayoutGatewayCollectionModel>();

// Transactions Manage

export const transactionCollectionAtom =
    createDataAtom<ITransactionCollection>();
export const TransactionCollectionAtom =
    createDataAtom<IWalletTransactionCollection>();

export const transactionDetailAtom = createDataAtom<ITransaction>();

export const walletOverviewCollectionAtom =
    createDataAtom<WalletOverviewModel>();
export const masterWalletOverviewCollectionAtom =
    createDataAtom<WalletOverviewModel>();
export const agentWalletOverviewCollectionAtom =
    createDataAtom<WalletOverviewModel>();
export const agentPayoutRequestCollectionAtom =
    createDataAtom<IPayoutRequestCollection>();
export const customerPayoutRequestCollectionAtom =
    createDataAtom<IPayoutRequestCollection>();

//Invoice
export const invoiceCollectionAtom = createDataAtom<IInvoiceCollection>();
export const agentInvoiceCollectionAtom =
    createDataAtom<IAgentInvoiceCollection>();
export const customerInvoiceDetailsAtom = createDataAtom<IInvoice>();

export const agentInvoiceDetailsAtom = createDataAtom<IInvoice>();

export const paymentSessionResponseAtom =
    createDataAtom<IPaymentSessionResponse>();

// invoice payment rule

export const invoicePaymentRuleCollectionAtom =
    createDataAtom<InvoicePaymentRuleResponseModel>();

//Product List
export const inventoryProductSearchResultAtom = atom<
    ProductSearchResultModel | undefined
>(undefined);
export const inventoryProductDetailAtom = atom<
    ProductDetailExtendedModel | undefined
>(undefined);

//Product List
export const addonServiceCollectionAtom =
    createDataAtom<AddonServiceCollectionModel>();

//Product List
export const addressSchemaAtom = createDataAtom<IAddressSchema>();

//Take Actions List
export const buyActionCollectionAtom =
    createDataAtom<BuyActionCollectionModel>();
//Campaign List
export const campaignCollectionAtom = createDataAtom<ICampaignCollection>();

//Campaign Strip
export const campaignStripAtom = createDataAtom<CampaignDataModel>();
//Campaign
export const campaignProductCollectionAtom = atom<
    CampaignProductCollectionModel | undefined
>(undefined);
// dispute
export const disputeCollectionAtom = createDataAtom<IDisputeCollection>();
export const disputeDetailsAtom = createDataAtom<IDisputeDetailsCollection>();
export const disputePreviewAtom = createDataAtom<DisputePreview>();
// RFQ
export const rfqRequestListAtom = createDataAtom<IRfqRequestCollection>();

// HarvestJob
export const harvestJobCollectionAtom =
    createDataAtom<HarvestBatchCollectionModel>();

// Discount
export const discountCollectionAtom = createDataAtom<IDiscountCollection>();
export const discountAtom = createDataAtom<IDiscount>();
export const discountRulesSchemaAtom = createDataAtom<IDiscountRulesSchema>();
export const discountUsageHistoryCollectionAtom =
    createDataAtom<IDiscountUsageHistoryCollection>();

// PurchaseCommission
export const purchaseCommissionCollectionAtom =
    createDataAtom<IPurchaseCommissionCollection>();

export const selectedNotificationAtom = atom<IMessage[]>([]);

// BuyOrderPlace
export const buyOrderPlaceAtom = createDataAtom<IBuyOrderPlace>();
export const purchaseCartCollectionAtom =
    createDataAtom<PurchaseCartCollectionModel>();

// Support Inquiry
export const supportInquiryList =
    createDataAtom<SupportInquiryCollectionModel>();

//Product List
export const DeliveryRequestCollectionAtom =
    createDataAtom<IDeliveryRequestCollection>();
export const DeliveryRequestDetailsAtom = createDataAtom<IDeliveryRequest>();

export default rootStore;
