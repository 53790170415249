import React from "react";
import { Typography, Tooltip, Tag, Table } from "antd";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { StatusTag } from "@/components";
import { formatDateTime } from "@/utils/helpers";
import PackageColumns from "./PackageColumns";
import { IShipmentProductPackageCollection } from "@/types/shipmentProductCollection";
import { ShipmentProductModel } from "@/models/deliveryRequestCollectionModel";
import { CurrencyModel } from "@/models/currencyCollectionModel";
import { truncateTitle } from "@/utils/helper";
import { IRegion } from "@/types/regionCollection";

const { Paragraph, Text } = Typography;

const BuyProductColumns: React.FC<{
    data: ShipmentProductModel[] | undefined;
    region: IRegion | undefined;
}> = ({ data, region }) => {
    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "id",
            render: (_: any, __: ShipmentProductModel, index: number) => {
                return <>{index + 1}</>;
            },
        },
        {
            title: "Product Info",
            dataIndex: "product_number",
            key: "product_number",
            width: 200,
            render: (_product_number: number, record: ShipmentProductModel) => {
                const buyProduct = record.getBuyProduct();
                const shipmentProduct =
                    record.getOrder().getOrderType() === "only-ship";

                const productImage = record.getImages()?.data[0].src;

                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "16px",
                            padding: "16px",
                        }}
                    >
                        {buyProduct && (
                            <>
                                {/* Left Side: Image */}
                                <div style={{ flexShrink: 0 }}>
                                    {buyProduct.getProductImage() ? (
                                        <ImageWithPlaceholder
                                            style={{
                                                width: 150,
                                                height: 150,
                                            }}
                                            src={buyProduct.getProductImage()}
                                        />
                                    ) : (
                                        <Text type="secondary">
                                            No Image Available
                                        </Text>
                                    )}
                                </div>
                                {/* Right Side: Product Details */}
                                <div style={{ flexGrow: 1 }}>
                                    <Paragraph>
                                        <Text>Title : </Text>
                                        <Tooltip
                                            title={buyProduct.getProductTitle()}
                                        >
                                            <Typography.Link
                                                href={buyProduct.getProductLink()}
                                                target="_blank"
                                                strong
                                            >
                                                {truncateTitle(
                                                    buyProduct.getProductTitle(),
                                                    20,
                                                )}
                                            </Typography.Link>
                                        </Tooltip>
                                    </Paragraph>
                                    <Paragraph>
                                        <Tooltip title="Product Number">
                                            <Text>P/N : </Text>
                                        </Tooltip>
                                        <Text
                                            strong
                                            copyable
                                            style={{
                                                color: "#1890ff",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    `/purchase/buy-products?product_number=${buyProduct.getProductNumber()}`,
                                                    "_blank",
                                                );
                                            }}
                                        >
                                            {buyProduct.getProductNumber()}
                                        </Text>
                                    </Paragraph>
                                    <Paragraph>
                                        <Tooltip title="Order Number">
                                            <Text>O/N : </Text>
                                        </Tooltip>
                                        <Text
                                            strong
                                            copyable
                                            style={{ color: "#1890ff" }}
                                        >
                                            {record
                                                .getOrder()
                                                ?.getOrderNumber()}
                                        </Text>
                                    </Paragraph>
                                    <Paragraph>
                                        <Text>Quantity : </Text>
                                        <Text strong>
                                            {buyProduct.getTotalQuantity()}
                                        </Text>
                                    </Paragraph>
                                    <Paragraph>
                                        <Text>Status : </Text>
                                        <StatusTag
                                            slug={buyProduct.getStatus()}
                                            text={buyProduct.getStatus()}
                                        />
                                    </Paragraph>
                                    <Paragraph>
                                        <Text>Shipping Provider : </Text>
                                        <Text strong>
                                            {buyProduct.getShippingProvider()}
                                        </Text>
                                    </Paragraph>
                                    <Paragraph>
                                        <Tag color="purple">
                                            {formatDateTime(
                                                record.buy_product?.getCreatedAt(),
                                            )}
                                        </Tag>
                                    </Paragraph>
                                </div>
                            </>
                        )}

                        {shipmentProduct && (
                            <>
                                <div style={{ flexShrink: 0 }}>
                                    {productImage ? (
                                        <ImageWithPlaceholder
                                            style={{
                                                width: 150,
                                                height: 150,
                                            }}
                                            src={productImage}
                                        />
                                    ) : (
                                        <Text type="secondary">
                                            No Image Available
                                        </Text>
                                    )}
                                </div>

                                <div>
                                    <Paragraph>
                                        <Text>Title : </Text>
                                        <Tooltip title={record.getTitle()}>
                                            <Typography.Link
                                                target="_blank"
                                                strong
                                            >
                                                {truncateTitle(
                                                    record.getTitle(),
                                                    20,
                                                )}
                                            </Typography.Link>
                                        </Tooltip>
                                    </Paragraph>

                                    <Paragraph>
                                        <Tooltip title="Product Number">
                                            <Text>P/N : </Text>
                                        </Tooltip>
                                        <Text
                                            strong
                                            copyable
                                            style={{
                                                color: "#1890ff",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    `/shipping/shipment-products/edit/${record.getId()}`,
                                                    "_blank",
                                                );
                                            }}
                                        >
                                            {record.getProductNumber()}
                                        </Text>
                                    </Paragraph>
                                    {record.getRejectReason() && (
                                        <Paragraph>
                                            <Text>Reject Reason : </Text>
                                            <Text strong>
                                                {record.getRejectReason()}
                                            </Text>
                                        </Paragraph>
                                    )}
                                    <Paragraph>
                                        <Tag color="purple">
                                            {formatDateTime(
                                                record.getCreatedAt(),
                                            )}
                                        </Tag>
                                    </Paragraph>
                                </div>
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Package Information",
            dataIndex: "packages",
            key: "packages",
            width: 600,
            render: (
                packages: IShipmentProductPackageCollection,
                record: ShipmentProductModel,
            ) => {
                const currency = record.getCurrency() as
                    | CurrencyModel
                    | undefined;

                return (
                    <PackageColumns
                        dataSource={packages.data || []}
                        currency={currency}
                    />
                );
            },
        },
        {
            title: "Invoice Summary",
            dataIndex: "invoice_summary",
            key: "invoice_summary",
            render: (_, record: ShipmentProductModel) => {
                const buyProduct = record.getBuyProduct();
                const isShipmentOnly =
                    record.getOrder().getOrderType() === "only-ship";

                const getTransactionSummary = (invoice: any) => ({
                    due: invoice?.getTransactionSummary()?.due || 0,
                    overpaid: invoice?.getTransactionSummary()?.overpaid || 0,
                    paid: invoice?.getTransactionSummary()?.paid || 0,
                });

                const renderInvoiceDetails = (
                    invoice: any,
                    currency: string,
                ) => {
                    const { due, overpaid, paid } =
                        getTransactionSummary(invoice);

                    return (
                        <>
                            <Paragraph>
                                <Tooltip title="Invoice Number">
                                    <Text>I/N : </Text>
                                </Tooltip>
                                <Text strong copyable>
                                    {invoice?.getInvoiceNumber()}
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Tooltip title="Invoice Status">
                                    <Text>Status : </Text>
                                </Tooltip>
                                <StatusTag
                                    slug={invoice?.getStatus() || ""}
                                    text={invoice?.getStatus() || ""}
                                />
                            </Paragraph>
                            <Paragraph>
                                <Text>Paid : </Text>
                                <Text strong>
                                    {paid.toFixed(2)} {currency}
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Over Paid : </Text>
                                <Text strong>
                                    {overpaid.toFixed(2)} {currency}
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <Text>Due : </Text>
                                <Text strong>
                                    {due.toFixed(2)} {currency}
                                </Text>
                            </Paragraph>
                        </>
                    );
                };

                const currency = region?.currency?.code || "";

                return (
                    <>
                        {buyProduct &&
                            renderInvoiceDetails(
                                buyProduct.getCustomerInvoice(),
                                currency,
                            )}
                        {isShipmentOnly &&
                            renderInvoiceDetails(
                                record.getCustomerInvoice(),
                                currency,
                            )}
                    </>
                );
            },
        },
        {
            title: "Shipping Information",
            dataIndex: "shipping_information",
            key: "shipping_information",
            render: (_, record: ShipmentProductModel) => (
                <>
                    <Paragraph>
                        <Tooltip title="Shipping Category">
                            <Text>S/C : </Text>
                        </Tooltip>
                        <Text strong copyable>
                            {record.getShippingCategory().name}
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <Tooltip title="Shipping Type">
                            <Text>S/T : </Text>
                        </Tooltip>
                        <Text strong copyable>
                            {record.getShippingType()}
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <Tooltip title="Shipping Mode">
                            <Text>S/M : </Text>
                        </Tooltip>
                        <Text strong copyable>
                            {record.getShippingMode()}
                        </Text>
                    </Paragraph>
                    {record.getShippingInstruction() && (
                        <Paragraph>
                            <Tooltip title="Shipping Instruction">
                                <Text>S/I : </Text>
                            </Tooltip>
                            <Text strong>
                                {record.getShippingInstruction()}
                            </Text>
                        </Paragraph>
                    )}
                </>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={false}
            scroll={{ x: 1300 }}
        />
    );
};

export default BuyProductColumns;
