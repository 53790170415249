import { Descriptions, Divider, Flex, Progress, Tag } from "antd";
import { generateColor } from "@/utils/helper";
import { StatusEnum } from "@/enums/harvestJobCollectionEnums";

const ViewDetailsModal = ({ product }) => {
    const getColorForStatus = (status: string): string => {
        switch (status) {
            case StatusEnum.CREATED:
                return "#2db7f5";
            case StatusEnum.PRE_PROCESSED:
                return "#bef5a6";
            case StatusEnum.PROCESSING:
                return "#87d068";
            case StatusEnum.COMPLETED:
                return "#87d068";
            case StatusEnum.CANCELLED:
                return "#CDB37B";
            case StatusEnum.FAILED:
                return "#a82e2e";
            default:
                return "#000";
        }
    };

    const progress = product.getProgress() ? product.getProgress() : 0;
    const isProgressComplete = progress === 100;
    return (
        <>
            <Divider />
            <Descriptions>
                <Descriptions.Item label="Batch Number">
                    {product.batch_number}
                </Descriptions.Item>

                <Descriptions.Item label="User Name">
                    {product.user.name}
                </Descriptions.Item>

                <Descriptions.Item label="Status">
                    <Tag color={getColorForStatus(product.getStatus())}>
                        {product.getStatus()}
                    </Tag>
                </Descriptions.Item>

                <Descriptions.Item label="Job Count">
                    {product.job_count}
                </Descriptions.Item>

                <Descriptions.Item label="Advancement Count">
                    {product.advancement_count}
                </Descriptions.Item>

                {!isProgressComplete && (
                    <Descriptions.Item label="Progress">
                        <Flex gap="small">
                            <Progress
                                type="circle"
                                percent={progress ? progress : 0}
                                size={40}
                            />
                        </Flex>
                    </Descriptions.Item>
                )}

                <Descriptions.Item label="Created At">
                    {new Date(product.created_at).toLocaleString()}
                </Descriptions.Item>

                <Descriptions.Item label="Completed At">
                    <Tag color="purple">
                        {product.getCompletedAt()
                            ? new Date(
                                  product.getCompletedAt(),
                              ).toLocaleString()
                            : "N/A"}
                    </Tag>
                </Descriptions.Item>

                {product.getFailedAt() && (
                    <Descriptions.Item label="Failed At">
                        <Tag color="red">
                            {product.getFailedAt()
                                ? product.getFailedAt()
                                : "N/A"}
                        </Tag>
                    </Descriptions.Item>
                )}
                <Descriptions.Item label="Tags">
                    {product.tags.data.map((tag, index) => (
                        <Tag key={index} color={generateColor(tag.tag)}>
                            {tag.tag}
                        </Tag>
                    ))}
                </Descriptions.Item>
            </Descriptions>
        </>
    );
};

export default ViewDetailsModal;
