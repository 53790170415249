import React, { useEffect } from "react";
import {
    Button,
    Form,
    Input,
    InputNumber,
    notification,
    Radio,
    Select,
    Tooltip,
    Typography,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useCreateService } from "@/lib/core-react/hooks/private/useAddonService";
import {
    renderOptionsFromEnumAddonDependsOnEnum,
    renderOptionsFromEnumAddonForEnum,
} from "@/components/Form/forms";
import { CreateServicesPostRequestModel } from "@/models/addonServiceCollectionModel";
import {
    AddonForEnum,
    DependsOnEnum,
} from "@/enums/addonServicesCollectionEnums";
import {
    DeleteOutlined,
    MinusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { SingleChunkUpload } from "@/components/SingleChunkUpload";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { showError } from "@/helpers/showError";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateService = ({ setIsShowCreateModal }: IProps) => {
    const { createService, isLoading } = useCreateService();
    const {
        allOptionsData: { regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { Title } = Typography;
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
    }, []);

    const onFinish = async (value: CreateServicesPostRequestModel) => {
        try {
            await createService(value);
            notification.success({
                message: "Created Successfully",
            });
            setIsShowCreateModal(false);
            form.resetFields();
        } catch (e) {
            showError(e, form);
        }
    };

    return (
        <Form
            name="serviceForm"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
                prices: [
                    {
                        region_id: undefined,
                        depends_on: undefined,
                        is_primary: false,
                        price: 0,
                        slots: [{ min_amount: 1, max_amount: 1, price: 0 }],
                    },
                ],
            }}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: "Name is required" }]}
            >
                <Input placeholder="Enter name" />
            </Form.Item>

            <Form.Item
                label="Identifier"
                name="identifier"
                rules={[{ required: true, message: "Identifier  is required" }]}
            >
                <Input placeholder="Enter identifier" />
            </Form.Item>

            <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: "Description is required" }]}
            >
                <TextArea placeholder="Enter Description" />
            </Form.Item>

            <Form.Item
                label="Addon For"
                name="addon_for"
                rules={[{ required: true, message: "Addon for is required" }]}
            >
                <Select placeholder="Please select a Publishing Status">
                    {renderOptionsFromEnumAddonForEnum(AddonForEnum)}
                </Select>
            </Form.Item>

            <Form.Item name="logo" label="Images">
                <SingleChunkUpload
                    form={form}
                    accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                    fieldName="logo"
                />
            </Form.Item>

            <div
                style={{
                    border: "1px solid #d9d9d9",
                    borderRadius: "4px",
                    padding: "10px",
                }}
            >
                <Form.List name="prices">
                    {(fields, { add, remove }) => (
                        <div>
                            {fields.map((field, index) => (
                                <div key={field.key}>
                                    <Title type="success" level={4}>
                                        Price {index + 1}
                                    </Title>

                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: "Region is required",
                                            },
                                        ]}
                                        name={[field.name, "region_id"]}
                                        label="Region"
                                    >
                                        <Select
                                            loading={
                                                regionOptionsData.isLoading
                                            }
                                            options={regionOptionsData.options}
                                            placeholder="Please select a region"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Depends On"
                                        name={[field.name, "depends_on"]}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    "Depends On is required",
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Please select a Publishing Status">
                                            {renderOptionsFromEnumAddonDependsOnEnum(
                                                DependsOnEnum,
                                            )}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name={[field.name, "is_primary"]}
                                        label="Is primary"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Primary is required",
                                            },
                                        ]}
                                    >
                                        <Radio.Group>
                                            <Radio value={true}>Yes</Radio>
                                            <Radio value={false}>No</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        label="Price"
                                        name={[field.name, "price"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter price",
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </Form.Item>
                                    <div
                                        style={{
                                            border: "1px solid #d9d9d9",
                                            borderRadius: "4px",
                                            padding: "16px",
                                        }}
                                    >
                                        <Form.List
                                            name={[field.name, "slots"]}
                                            key={index}
                                        >
                                            {(
                                                slotFields,
                                                {
                                                    add: addSlot,
                                                    remove: removeSlot,
                                                },
                                            ) => (
                                                <div>
                                                    {slotFields.map(
                                                        (
                                                            slotField,
                                                            slotsInx,
                                                        ) => (
                                                            <div
                                                                key={
                                                                    slotField.key
                                                                }
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "center",
                                                                    marginBottom:
                                                                        "16px",
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        marginRight:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    <Form.Item
                                                                        label="Min"
                                                                        name={[
                                                                            slotField.name,
                                                                            "min_amount",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    true,
                                                                                message:
                                                                                    "Please enter min amount",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumber />
                                                                    </Form.Item>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        marginRight:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    <Form.Item
                                                                        label="Max"
                                                                        name={[
                                                                            slotField.name,
                                                                            "max_amount",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    true,
                                                                                message:
                                                                                    "Please enter max amount",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumber />
                                                                    </Form.Item>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        marginRight:
                                                                            "16px",
                                                                    }}
                                                                >
                                                                    <Form.Item
                                                                        label="Price"
                                                                        name={[
                                                                            slotField.name,
                                                                            "price",
                                                                        ]}
                                                                        rules={[
                                                                            {
                                                                                required:
                                                                                    true,
                                                                                message:
                                                                                    "Please enter price",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <InputNumber />
                                                                    </Form.Item>
                                                                </div>
                                                                {slotsInx >
                                                                    0 && (
                                                                    <MinusCircleOutlined
                                                                        size={
                                                                            24
                                                                        }
                                                                        onClick={() =>
                                                                            removeSlot(
                                                                                slotField.name,
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        ),
                                                    )}
                                                    <Form.Item>
                                                        <Tooltip title="Add Slot">
                                                            <Button
                                                                onClick={() =>
                                                                    addSlot()
                                                                }
                                                                style={{
                                                                    display:
                                                                        "block",
                                                                }}
                                                                type="dashed"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                            >
                                                                Add Slot
                                                            </Button>
                                                        </Tooltip>
                                                    </Form.Item>
                                                </div>
                                            )}
                                        </Form.List>
                                    </div>

                                    <div>
                                        <Button
                                            type="default"
                                            color={"danger"}
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={() => remove(field.name)}
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            Remove Price
                                        </Button>
                                    </div>
                                </div>
                            ))}
                            <Form.Item style={{ marginTop: "10px" }}>
                                <Button
                                    icon={<PlusOutlined />}
                                    type="dashed"
                                    onClick={() => add()}
                                >
                                    Add Price
                                </Button>
                            </Form.Item>
                        </div>
                    )}
                </Form.List>
            </div>
            <Form.Item
                style={{
                    textAlign: "center",
                    width: "100%",
                    marginTop: "10px",
                }}
            >
                <Button
                    loading={isLoading}
                    disabled={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateService;
