import { useEffect } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Col, Row, Table, Tag, Typography } from "antd";
import { useAtom } from "jotai";
import FiltersComponent from "@/components/FiltersComponent";
import { supportInquiryList } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { IFilterType } from "@/types/filters";
import CommonError from "@/components/Error/CommonError";
import { useSupportInquiry } from "@/lib/core-react/hooks/private/useGeneric";
import {
    SupportInquiryCollectionModel,
    SupportInquiryModel,
} from "@/models/supportInquiryCollectionModel";
import Paragraph from "antd/lib/typography/Paragraph";
import { formatDateTime } from "@/utils/helpers";
import { PaginationModel } from "@/models/pagination";

const SupportInquiry = () => {
    const { getSupportInquiry } = useSupportInquiry();
    const [
        {
            data: supportInquiryData,
            isLoading,
            refetch,
            code,
            error,
            unAuthorized,
        },
    ] = useAtom(supportInquiryList);

    const { Text } = Typography;

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    const supportInquiry =
        supportInquiryData &&
        new SupportInquiryCollectionModel(supportInquiryData);

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getSupportInquiry);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    const filterData = supportInquiryData?.filters;
    const paginationData = supportInquiryData?.pagination;

    useEffect(() => {
        if (!isFetched && supportInquiry?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, supportInquiry?.filters]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getSupportInquiry,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        supportInquiry,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getSupportInquiry);
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_: string, __: SupportInquiryModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Customer Info",
            dataIndex: "name",
            key: "name",
            render: (_name, record: SupportInquiryModel) => {
                return (
                    <div>
                        <Paragraph>
                            <Text>Name : </Text>
                            <Text strong copyable>
                                {record.name}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Email : </Text>
                            <Text strong copyable>
                                {record.email}
                            </Text>
                        </Paragraph>
                        <Paragraph>
                            <Text>Phone : </Text>
                            <Text strong copyable>
                                {record.phone}
                            </Text>
                        </Paragraph>
                    </div>
                );
            },
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
            render: (message: string) => {
                return (
                    <div>
                        <Paragraph>
                            <Text>{message}</Text>
                        </Paragraph>
                    </div>
                );
            },
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            render: (reason: string) => {
                return (
                    <div>
                        <Paragraph>
                            <Text>{reason}</Text>
                        </Paragraph>
                    </div>
                );
            },
        },
        {
            title: "Created At",
            dataIndex: "created_at",
            key: "created_at",
            render: (created_at: string) => {
                return (
                    <div>
                        <Paragraph>
                            <Tag color="purple">
                                {formatDateTime(created_at)}
                            </Tag>
                        </Paragraph>
                    </div>
                );
            },
        },
    ];

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={"Talk to Expert"}
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col style={{ marginBottom: "10px" }} span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}

                        <Table
                            //@ts-ignore
                            columns={columns}
                            dataSource={supportInquiry?.data}
                            loading={isLoading}
                            size="middle"
                            pagination={paginationConfig}
                            rowKey="id"
                        />
                    </PageHeader>
                </Col>
            </Row>
        </>
    );
};

export default SupportInquiry;
