import { CartItemAddPayload } from "@/types/buyOrderPlace";
import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { IDeleteCartItemPayload } from "@/types/PurchaseCartCollection";

class PurchaseCartResource extends BaseResource {
    get(params?: { user_id?: number; [key: string]: any }): ResponsePromise {
        const query = params ? this.addQuery(params) : "";
        const path = `/api/purchase-cart/admin/v1/carts${query}`;

        return this.client.request("GET", path);
    }

    cartProduct(
        region: string,
        locale: string,
        payload: CartItemAddPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase-cart/admin/v1/cart-items?locale=${locale}&region=${region}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    cartDeleteProduct(
        region: string,
        locale: string,
        payload: IDeleteCartItemPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/purchase-cart/admin/v1/cart-items/destroy?locale=${locale}&region=${region}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
}

export default PurchaseCartResource;
