export enum ADMIN_ACL_PERMISSION_ENUM {
    ADMIN_ROLE_VIEW = "admin-role-view",
    ADMIN_ROLE_MANAGE = "admin-role-manage",
}

const manageAdminAclPermissions = [
    ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW,
    ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_MANAGE,
];
export default manageAdminAclPermissions;
