import { ITranslationEntry } from "../lang";

export const TA: ITranslationEntry = {
    translations: {
        notifications: "அறிவிப்புகள்",
        markAllAsRead: "அனைத்தையும் வாசிக்கப்பட்டதாக அடையாளமிடு",
        poweredBy: "மூலம் இயக்கப்படுகிறது",
        settings: "செட்டிங்ஸ்",
        noNewNotification: "இதுவரை இங்கு பார்க்க புதிதாக எதுவும் இல்லை",
    },
    lang: "ta",
};
