import { useEffect, createRef } from "react";
import { Button, Form, Input, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useUpdateCustomerUser } from "@/lib/core-react/hooks/private/useCustomerUser";

import { Forms } from "@/models/form";
import {
    CustomerUserModel,
    CustomerUserUpdateRequestModel,
} from "@/models/customerUserCollectionModel";
import { showError } from "@/helpers/showError";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

interface IProps {
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCustomerUser: CustomerUserModel;
}

const PasswordResetCustomerUser = ({
    setIsShowUpdateModal,
    selectedCustomerUser,
}: IProps) => {
    const { updateCustomerUser, isLoading } = useUpdateCustomerUser();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value: CustomerUserUpdateRequestModel) => {
        try {
            await updateCustomerUser(selectedCustomerUser.id, value);
            setIsShowUpdateModal(false);
            notification["success"]({
                message: "Customer user password updated successfully!",
            });
        } catch (error) {
            showError(error, form);
        }
    };

    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="User Password Reset"
            onFieldsChange={onValuesChange}
            ref={formRef}
            initialValues={{
                name: selectedCustomerUser.name,
                is_active: selectedCustomerUser.getIsActive().toString(),
            }}
        >
            <Form.Item name="name" hidden>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "Password is required" },
                    {
                        min: 6,
                        message: "Password must be at least 6 characters",
                    },
                ]}
                label="Password"
                name="password"
            >
                <Input.Password
                    placeholder="Type password"
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                />
            </Form.Item>
            <Form.Item
                rules={[
                    { required: true, message: "Password is required" },
                    {
                        min: 6,
                        message: "Password must be at least 6 characters",
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(
                                new Error("Passwords do not match!"),
                            );
                        },
                    }),
                ]}
                label="Confirm Password"
                name="password_confirmation"
            >
                <Input.Password
                    placeholder="Retype password"
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                />
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default PasswordResetCustomerUser;
