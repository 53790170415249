import axios from "axios";

export const isUnauthorized = (error: unknown): boolean => {
    // Handle Axios errors
    if (axios.isAxiosError(error) && error.response) {
        return error.response.status === 403;
    }

    // Handle Fetch API errors
    if (error instanceof Response) {
        return error.status === 403;
    }

    return false; // Return false for other or unexpected errors
};
