import { ITranslationEntry } from "../lang";

export const EN: ITranslationEntry = {
    translations: {
        notifications: "Notifications",
        markAllAsRead: "Mark all as read",
        poweredBy: "Powered By",
        settings: "Settings",
        removeMessage: "Remove Message",
        markAsRead: "Mark as Read",
        markAsUnRead: "Mark as Unread",
        noNewNotification: "Nothing new to see here yet",
    },
    lang: "en",
};
