import { IsSelectablePageOperatorEnum, PageOperationEnum } from "../types";

export const getMatchedOperator = (
    operation?: string | null | undefined,
): PageOperationEnum | string => {
    if (!operation) {
        return "";
    }
    const match = Object.values(PageOperationEnum).find(
        (enumValue) => enumValue === operation,
    );
    return match || "";
};

export const isSelectable = (): boolean => {
    const operationAction = new URLSearchParams(location.search).get(
        "operation",
    );

    const match = Object.values(IsSelectablePageOperatorEnum).find(
        (enumValue) => enumValue === operationAction,
    );

    return match ? true : false;
};
