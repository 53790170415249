import { Flex, Tag, Typography } from "antd";
import { StatusTag } from "@/components";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { memo } from "react";
import { IModalData } from "@/helpers/getModalTital";
import { UnlistedPackageModel } from "@/models/unlistedPackageCollectionModel";

interface Props {
    unlistedPackageModel: UnlistedPackageModel;
    handleModal: (payload: IModalData) => void;
}

const { Text } = Typography;

const UnlistedPackageInfo = ({ unlistedPackageModel }: Props) => {
    const imageUrl = unlistedPackageModel.getImageUrls()[0];
    const productNumber = unlistedPackageModel.getPackageNumber();
    const status = unlistedPackageModel.getStatus();
    const totalQuantity = unlistedPackageModel.getQuantity();
    const totalWeight = unlistedPackageModel.getWeight();
    const fulfillmentStatus = unlistedPackageModel.getFulfillmentStatus();
    const contains = unlistedPackageModel.getContains();

    return (
        <Flex gap={8} vertical>
            <Flex gap={12}>
                <ImageWithPlaceholder
                    style={{
                        width: 120,
                        height: 120,
                    }}
                    src={`${imageUrl}`}
                />
                <Flex vertical gap={8}>
                    <Typography.Text>
                        <Text>PN: </Text>
                        <Text strong copyable>
                            {productNumber}
                        </Text>
                    </Typography.Text>

                    <Typography.Text>
                        <Text>Status: </Text>
                        <StatusTag slug={status} text={status} />
                    </Typography.Text>

                    <Typography.Text>
                        <Text> Total Quantity: </Text>
                        <Tag>{totalQuantity}</Tag>
                    </Typography.Text>

                    <Typography.Text>
                        <Text>Total weight: </Text>
                        <Tag color="geekblue-inverse">{totalWeight} KG</Tag>
                    </Typography.Text>
                    <Typography.Text>
                        <Text>Fulfillment Status: </Text>
                        <StatusTag
                            slug={fulfillmentStatus}
                            text={fulfillmentStatus}
                        />
                    </Typography.Text>
                    <Typography.Text>
                        <Text>Contains: </Text>
                        <StatusTag slug={contains} text={contains} />
                    </Typography.Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default memo(UnlistedPackageInfo);
