import { IFilterType } from "@/types/filters";
import { PaginationModel } from "./paginationModel";
import {
    ICampaignProductCollection,
    ICampaignProduct,
} from "@/types/campaignProductCollection";
import { ProductDetailExtendedModel } from "./productDetailExtendedModel";

export class CampaignProductModel {
    object: string;
    id: string;
    product: ProductDetailExtendedModel;
    constructor(data: ICampaignProduct) {
        this.object = data.object;
        this.id = data.id;
        this.product = new ProductDetailExtendedModel(data.product);
    }
    getId = () => this.id;
    getProduct = () => this.product;
}

export class CampaignProductCollectionModel {
    object: string;
    data: CampaignProductModel[];
    pagination: PaginationModel;
    filters: IFilterType;

    constructor(data: ICampaignProductCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new CampaignProductModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData() {
        return this.data;
    }

    getCampaignProductById(id: string): CampaignProductModel | undefined {
        return this.data.find((item) => item.getId() === id);
    }

    getPagination() {
        return this.pagination;
    }

    getFilters() {
        return this.filters;
    }
}
