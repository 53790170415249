export interface IMinimalData<T = any> {
    data: T;
    isLoading: boolean;
}
export interface ISelectOption extends Record<string, any> {
    label: string;
    value: string | number;
    code?: string;
    flag?: string;
}

interface ICurrencyMinimal {
    object: string;
    id: number;
    code: string;
    symbol: string;
    symbol_native: string;
    name: string;
    status: string;
}
export class SelectOptionModel<
    T extends {
        addon_for?: string;
        id: number | string;
        name?: string;
        email?: string;
        code?: string;
        label?: string;
        flag?: string;
        default_currency?: null | ICurrencyMinimal;
    },
> {
    private payload: T[];
    constructor(payload: T[]) {
        this.payload = payload;
    }

    public getOptions(): ISelectOption[] {
        const options =
            this.payload.length > 0
                ? this.payload.map((item) => {
                      if (item.code && item.code !== undefined) {
                          return {
                              label: item.name,
                              value: item.id,
                              code: item.code,
                          };
                      } else {
                          return {
                              label: item.email
                                  ? `${item.email} ( ${item.name} )`
                                  : item.label || item.name || item.id,
                              value: item.id,
                              code: "",
                              default_currency_code: item.default_currency
                                  ? item.default_currency.code
                                  : "",
                          };
                      }
                  })
                : [];

        return options as ISelectOption[];
    }
    public getOptionsByLabelKey(labelKey: keyof T): ISelectOption[] {
        const options =
            this.payload.length > 0
                ? this.payload.map((item) => {
                      return {
                          label: String(item[labelKey] || item.id),
                          value: item.id,
                      };
                  })
                : [];

        return options;
    }
    public getCountryOptions(labelKey: keyof T): ISelectOption[] {
        const options =
            this.payload.length > 0
                ? this.payload.map((item) => {
                      return {
                          label: String(item[labelKey] || item.id),
                          value: item.id,
                          flag: item?.flag,
                          code: item?.code,
                      };
                  })
                : [];

        return options;
    }
    public getAddonServiceOptions(labelKey: keyof T): ISelectOption[] {
        const options =
            this.payload.length > 0
                ? this.payload
                      .filter((service) => service.addon_for === "buy-and-ship")
                      .map((item) => {
                          return {
                              label: String(item[labelKey] || item.id),
                              value: item.id,
                          };
                      })
                : [];

        return options;
    }
}
