import {
    CloseCircleTwoTone,
    DownOutlined,
    EditTwoTone,
    HddFilled,
} from "@ant-design/icons";
import {
    Button,
    Dropdown,
    Flex,
    Modal,
    Popconfirm,
    Table,
    TableColumnsType,
    Tag,
} from "antd";
import { useAtom, useAtomValue } from "jotai";

import {
    useAbandonBid,
    useGetAllBids,
} from "@/lib/core-react/hooks/private/useShipping";
import {
    bidCollectionAtom,
    shipmentProductsAtom,
} from "@/lib/core-react/store/store";
import { useEffect, useState } from "react";
import AgentAssignWithBidManage from "@/pages/Shipping/components/AgentAssignWithBidManage";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import ParticipantsTable from "@/pages/Shipping/ShipmentProducts/components/ParticipantsTable";
import { ExtendedMenuItemType } from "@/types";
import { ROLE_PERMISSION_TEST_ENUM } from "@/consts/parmissionEnum";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { ShipmentProductBidModel } from "@/models/shipmentProductBidCollectionModel";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { showError } from "@/helpers/showError";
import { Typography } from "antd/lib";
const { Text } = Typography;

interface IProps {
    shipmentProductModel: ShipmentProductModel;
}

export const BidList = ({ shipmentProductModel }: IProps) => {
    const { isMobile } = useWindowWidth();
    const { data: shipmentProductCollection } =
        useAtomValue(shipmentProductsAtom);
    const { getAllBids } = useGetAllBids();
    const { abandonBid, isLoading: isLoadingAbandon } = useAbandonBid();
    const [{ data: bidCollectionData, isLoading }] = useAtom(bidCollectionAtom);

    const [categoryPriceDetailsInfo, setCategoryPriceDetailsInfo] = useState<
        ICategoryPriceRequestParams | undefined
    >(undefined);

    const [selectedBidId, setSelectedBidId] = useState<number | undefined>(
        undefined,
    );
    const [openUpdateBidModal, setOpenUpdateBidModal] =
        useState<boolean>(false);

    useEffect(() => {
        getAllBids(shipmentProductModel.getId());
    }, [shipmentProductModel.getId()]);
    const bidAbandonOkHandler = async (id: number) => {
        try {
            const res = await abandonBid(id);
            showSuccessAlert(res.message);
            getAllBids(shipmentProductModel.getId());
        } catch (error: any) {
            showError(error);
        }
    };

    const showUpdateBidModalHandler = (bidId: number) => {
        setSelectedBidId(bidId);
        setOpenUpdateBidModal(true);
        if (shipmentProductCollection) {
            const categoryDetails: ICategoryPriceRequestParams = {
                region: shipmentProductModel.getRegionCode(),
                region_id: shipmentProductModel.getRegionId(),
                shipping_category_id:
                    shipmentProductModel.getShippingCategoryId(),
                destination_warehouse_id:
                    shipmentProductModel.getDestinationWarehouseId(),
                shipping_type: shipmentProductModel.getShippingType() as any,
                shipping_mode: shipmentProductModel.getShippingMode() as any,
                shipping_country_id:
                    shipmentProductModel.getShippingCountryId(),
                destination_country_id:
                    shipmentProductModel.getDestinationCountryId(),
            };
            setCategoryPriceDetailsInfo(categoryDetails);
        }
    };

    // -------------------------------ui-------------------------------

    const columns: TableColumnsType<ShipmentProductBidModel> = [
        {
            title: "Bid ID",
            key: "id",
            render: (_: string, record) => <>{record.getId()}</>,
        },
        {
            title: "Bid Status",
            key: "status",
            render: (_: string, record) => (
                <Tag
                    color={`${record.status === "abandoned" ? "red" : "green-inverse"}`}
                >
                    {record.status}
                </Tag>
            ),
        },
        {
            title: "Open For",
            key: "open_for",
            render: (_: string, record) => <Tag>{record.open_for}</Tag>,
        },
        {
            title: "Winner Warehouse",
            key: "winner_warehouse",
            render: (_: string, record) => (
                <>
                    {record.getWinnerWarehouse() ? (
                        <Flex vertical>
                            <div>
                                <Text strong>Name: </Text>
                                <Text>
                                    {record
                                        .getWinnerWarehouse()
                                        ?.getAliasName()}
                                </Text>
                            </div>
                            <div>
                                <Text strong>Operation Status: </Text>
                                <Text>
                                    <Tag>
                                        {record
                                            .getWinnerWarehouse()
                                            ?.getOperationStatus()}
                                    </Tag>
                                </Text>
                            </div>
                            <div>
                                <Text strong>Contact Person: </Text>
                                <Text>
                                    {record
                                        .getWinnerWarehouse()
                                        ?.getContactPerson()}
                                </Text>
                            </div>
                            <div>
                                <Text strong>Contact Number: </Text>
                                <Text>
                                    {record
                                        .getWinnerWarehouse()
                                        ?.getContactNumber()}
                                </Text>
                            </div>
                        </Flex>
                    ) : (
                        <Tag color="red">No Winner Found!</Tag>
                    )}
                </>
            ),
        },

        {
            title: "Action",
            key: "action",
            render: (_: string, record) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
                        disabled: !record.ui_action.getCanAttachParticipants(),
                        icon: <EditTwoTone />,
                        label: "Attach more participants",
                        key: "update",
                        onClick: () =>
                            showUpdateBidModalHandler(Number(record.getId())),
                    },
                    {
                        permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
                        disabled: !record.ui_action.getCanAbandonBid(),
                        icon: <CloseCircleTwoTone />,
                        label: (
                            <Popconfirm
                                disabled={!record.ui_action.getCanAbandonBid()}
                                title="Abandon Bid"
                                description="Are you sure you want to abandon this bid?"
                                onConfirm={() =>
                                    bidAbandonOkHandler(Number(record.getId()))
                                }
                                okButtonProps={{
                                    loading: isLoadingAbandon,
                                }}
                                okText="Yes"
                                okType="danger"
                                cancelText="No"
                            >
                                <span onClick={(e) => e.stopPropagation()}>
                                    Abandon
                                </span>
                            </Popconfirm>
                        ),
                        key: "abandon",
                        danger: true,
                    },
                ];

                return (
                    <Dropdown menu={{ items }}>
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        const keys =
            bidCollectionData?.getData()?.map((item) => item.getId()) || [];
        setExpandedRowKeys(keys);
    }, [bidCollectionData]);

    return (
        <>
            <Table
                style={{ verticalAlign: "top" }}
                dataSource={bidCollectionData?.getData()}
                rowKey={(r) => r.getId()}
                columns={columns}
                loading={isLoading}
                expandable={{
                    expandedRowRender: (record) => (
                        <ParticipantsTable
                            bidId={Number(record.getId())}
                            bid={record}
                            winner_warehouse_id={record
                                .getWinnerWarehouse()
                                ?.getId()}
                            shipmentProductId={shipmentProductModel.getId()}
                        />
                    ),
                    expandedRowKeys, // Control expanded state here
                    onExpand: (expanded, record) => {
                        setExpandedRowKeys((prev) =>
                            expanded
                                ? [...prev, record.getId()]
                                : prev.filter((key) => key !== record.getId()),
                        );
                    },
                }}
            />

            <Modal
                title="Warehouse List"
                open={openUpdateBidModal}
                destroyOnClose={true}
                width="1200px"
                footer={false}
                onCancel={() => setOpenUpdateBidModal(false)}
            >
                {categoryPriceDetailsInfo && (
                    <AgentAssignWithBidManage
                        mode="update"
                        bidId={selectedBidId}
                        onCancel={() => setOpenUpdateBidModal(false)}
                        productId={shipmentProductModel.getId()}
                        agentCategoryPriceRead={categoryPriceDetailsInfo}
                    />
                )}
            </Modal>
        </>
    );
};
