import { ITranslationEntry } from "../lang";

export const EU: ITranslationEntry = {
    translations: {
        notifications: "Notificacionespenak",
        markAllAsRead: "Markatu guztiak irakurrita bezala",
        poweredBy: "Bultzatuta",
        settings: "Ezarpenak",
        noNewNotification: "Oraindik ez dago ezer berririk hemen ikusteko",
    },
    lang: "eu",
};
