import { Rate, Typography, Space, Tag, Popover, Flex, Tooltip } from "antd";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";
import ShippingText from "@/components/ShippingText";
import { originalLinkToMoveOnLinkConversion } from "@/utils/helpers";

const { Text, Link, Title } = Typography;

interface IProps {
    product: ProductDetailExtendedModel;
}

const ProductHeader = ({ product }: IProps) => {
    const globalUrl = originalLinkToMoveOnLinkConversion(
        product.getId(),
        product.getTitle(),
    );

    return (
        <>
            <Space direction="vertical" style={{ width: "100%" }}>
                <Tooltip title={product.getTitleOriginal()}>
                    <Title level={5}>{product.getTitle()}</Title>
                </Tooltip>
                <Space size="middle" align="center">
                    {product.getShippingCategory() ? (
                        <Tag color="green">
                            {product.getShippingCategory()?.getName()}
                        </Tag>
                    ) : (
                        <></>
                    )}
                    <Rate
                        style={{ fontSize: 16 }}
                        defaultValue={product.getRatingsAverage()}
                        disabled
                    />
                    <Text>{`${product.getRatingsCount() || 0} reviews`}</Text>
                    <Text>
                        Sales: <Text strong>{product.getSales()}</Text>
                    </Text>
                    <Text>
                        In Stock: <Text strong>{product.getStock()}</Text>
                    </Text>
                    <Flex gap={3}>
                        Weight:{" "}
                        {product?.getMeasurement() ? (
                            <>
                                <Flex gap={2} justify="end">
                                    {product
                                        .getMeasurement()
                                        ?.getGrossWeight() ? (
                                        <Tag color="blue">
                                            G:{" "}
                                            {product
                                                .getMeasurement()
                                                ?.getGrossWeight()}
                                        </Tag>
                                    ) : (
                                        ""
                                    )}
                                    {product
                                        .getMeasurement()
                                        ?.getWeightUnit() ? (
                                        <Tag color="orange">
                                            U:{" "}
                                            {product
                                                .getMeasurement()
                                                ?.getWeightUnit()}
                                        </Tag>
                                    ) : (
                                        ""
                                    )}
                                </Flex>
                            </>
                        ) : (
                            <Tag>N/A</Tag>
                        )}
                    </Flex>
                </Space>
                <Flex vertical gap={8}>
                    {/* <ShippingText label="Discount Price">
                    <Tag color="magenta-inverse">
                        {product.getPrice().getBasePriceTargetCurrency()}{" "}
                        {product.getPrice().getDiscountPrice()}

                    </Tag>
                </ShippingText> */}
                    <ShippingText label="Source Price">
                        <Tag color="blue-inverse">
                            {product.getStore()?.getCurrencyCode()}{" "}
                            {product.getPrice().getOriginalRawPrice()}
                        </Tag>
                    </ShippingText>
                    <Text copyable={{ text: globalUrl }}>
                        {" "}
                        <a target="_blank" href={globalUrl}>
                            MoveOn Global link
                        </a>
                    </Text>

                    {product.getVendorLink() && (
                        <Popover
                            content={
                                <Text copyable>{product.getVendorLink()}</Text>
                            }
                        >
                            <Link
                                href={`${product.getVendorLink()}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Original Product Link
                            </Link>
                        </Popover>
                    )}
                </Flex>
            </Space>
        </>
    );
};

export default ProductHeader;
