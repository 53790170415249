export enum CountryCollectionEnums {
    CountryCollection = "CountryCollection",
    Country = "Country",
}
export enum CountryEnum {
    CountryCollection = "CountryCollection",
    Country = "Country",
}
export enum CountryDataStatus {
    active = "active",
    inactive = "inactive",
}

export enum CountryCode {
    AE = "UAE",
    BD = "Bangladesh",
    CN = "China",
    GB = "UK",
    IN = "India",
    US = "USA",
}
