import { StatusTag } from "@/components";
import {
    InvoiceBuyProductModel,
    InvoiceModel,
    InvoiceShipmentProductModel,
} from "@/models/invoiceCollectionModel";
import { Card, Image, Typography } from "antd";

interface InvoiceItemProps {
    invoice: InvoiceModel;
}

const BuyAndShipmentProducts: React.FC<InvoiceItemProps> = ({ invoice }) => {
    const buyProduct = invoice.getBuyProduct();
    const shipmentProduct = invoice.getShipmentProduct();
    const { Text, Link } = Typography;

    const renderBuyProductDetails = (product: InvoiceBuyProductModel) => {
        const status = product?.getStatusLabel();
        const storeIdentifier = product?.getStore()?.getIdentifier();
        const storeName = product?.getStore()?.getName();
        const productLink = product?.getProductLink();
        const sourceType = product?.getSourceType();

        return (
            <>
                <div style={{ display: "flex" }}>
                    {/* Product Image */}
                    <div>
                        <Image
                            width={100}
                            src={product?.getProductImage()}
                            alt="Product Image"
                        />
                    </div>

                    <div style={{ marginLeft: 20 }}>
                        {/* Product Number */}
                        <div>
                            <Text strong>Product Number: </Text>
                            <Link
                                href={`/purchase/buy-products/detail/${product?.getId()}`}
                            >
                                <Text copyable>
                                    {product?.getProductNumber()}
                                </Text>
                            </Link>
                        </div>

                        {/* Region */}
                        <div style={{ marginTop: 5 }}>
                            <Text strong>Region: </Text>
                            <StatusTag
                                slug={invoice.getRegion().code}
                                text={invoice.getRegion().code}
                            />
                        </div>

                        {/* Status */}
                        <div style={{ marginTop: 5 }}>
                            <Text strong>Status: </Text>
                            {status && (
                                <StatusTag slug={status} text={status} />
                            )}
                        </div>

                        {/* Store */}
                        <div style={{ marginTop: 5 }}>
                            <Text strong>Store: </Text>
                            {storeIdentifier && storeName && (
                                <StatusTag
                                    slug={storeIdentifier}
                                    text={storeName}
                                />
                            )}
                        </div>

                        {/* Seller */}
                        <div style={{ marginTop: 5 }}>
                            <Text strong>Seller: </Text>
                            <Text>
                                {product?.product_seller?.getSellerName()}
                            </Text>
                        </div>
                    </div>
                </div>

                {/* Additional Information */}
                <div style={{ marginTop: 20 }}>
                    <div>
                        <Text strong>Title: </Text>
                        <Text>{product?.getProductTitle()}</Text>
                    </div>

                    {/* Clickable Product URL */}
                    <div>
                        <Text strong>Product Url: </Text>
                        <Link href={productLink || "#"}>
                            <Text style={{ color: "red" }}>{productLink}</Text>
                        </Link>
                    </div>

                    {/* Source Type */}
                    <div>
                        <Text strong>Source: </Text>
                        <Text>
                            {sourceType === "RFQ"
                                ? "RFQ"
                                : sourceType === "PurchaseCartItem"
                                  ? "Cart"
                                  : "Unknown"}
                        </Text>
                    </div>
                </div>
            </>
        );
    };

    const renderShipmentProductDetails = (
        product: InvoiceShipmentProductModel,
    ) => {
        const statusLabel = product?.getStatusLabel();
        const status = product?.getStatus();
        const productLink = product?.getLink();
        const shippingCountry = product?.getShippingCountry();
        const destinationWarehouse = product?.getDestinationWarehouse(); // Assuming this method exists for warehouse info

        return (
            <>
                <div style={{ display: "flex" }}>
                    {/* Product Image */}
                    <div>
                        <Image
                            width={100}
                            src={product?.getImages().data[0].src}
                            alt="Product Image"
                        />
                    </div>

                    <div style={{ marginLeft: 20 }}>
                        {/* Product Number */}
                        <div>
                            <Text strong>Product Number: </Text>
                            <Link
                                href={`/purchase/buy-products/detail/${product?.getById()}`}
                            >
                                <Text copyable>
                                    {product?.getProductNumber()}
                                </Text>
                            </Link>
                        </div>

                        {/* Region */}
                        <div style={{ marginTop: 5 }}>
                            <Text strong>Region: </Text>
                            <StatusTag
                                slug={invoice.getRegion().code}
                                text={invoice.getRegion().code}
                            />
                        </div>

                        {/* Status */}
                        <div style={{ marginTop: 5 }}>
                            <Text strong>Status: </Text>
                            {status && statusLabel && (
                                <StatusTag slug={status} text={statusLabel} />
                            )}
                        </div>

                        {/* Shipping Country */}
                        <div style={{ marginTop: 5 }}>
                            <Text strong>Shipping Country: </Text>
                            <Text>
                                {shippingCountry?.country?.code}{" "}
                                <Image
                                    width={20}
                                    src={shippingCountry?.country?.flag}
                                    alt="Flag"
                                />
                            </Text>
                        </div>

                        {/* Destination Warehouse */}
                        <div style={{ marginTop: 5 }}>
                            <Text strong>Destination: </Text>
                            <Text>{destinationWarehouse?.name}</Text>
                        </div>
                    </div>
                </div>

                {/* Additional Information */}
                <div style={{ marginTop: 20 }}>
                    <div>
                        <Text strong>Title: </Text>
                        <Text>{product?.getTitle()}</Text>
                    </div>

                    {/* Clickable Product URL */}
                    <div>
                        <Text strong>Product Url: </Text>
                        <Link href={productLink || "#"}>
                            <Text style={{ color: "red" }}>{productLink}</Text>
                        </Link>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            {buyProduct && (
                <Card title={"Buy Product"} style={{ margin: "10px" }}>
                    {renderBuyProductDetails(buyProduct)}
                </Card>
            )}
            {shipmentProduct && (
                <Card title={"Shipment Product"} style={{ margin: "10px" }}>
                    {renderShipmentProductDetails(shipmentProduct)}
                </Card>
            )}
        </>
    );
};

export default BuyAndShipmentProducts;
