import { ITranslationEntry } from "../lang";

export const KM: ITranslationEntry = {
    translations: {
        notifications: "សារជូនដំណឹង",
        markAllAsRead: "បានអានទាំងអស់",
        poweredBy: "ដំណើរការដោយ",
        settings: "ការកំណត់",
        noNewNotification: "មិនមានអ្វីថ្មីដើម្បីមើលនៅទីនេះនៅឡើយទេ",
    },
    lang: "km",
};
