import { ITranslationEntry } from "../lang";

export const SI: ITranslationEntry = {
    translations: {
        notifications: "දැනුම්දීම්",
        markAllAsRead: "සියල්ල කියවූ ලෙස ලකුණු කරන්න",
        poweredBy: "බලගැන්වීම",
        settings: "සැකසුම්",
        noNewNotification: "මෙතන තවම බලන්න අලුත් දෙයක් නෑ",
    },
    lang: "si",
};
