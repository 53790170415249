import { ITranslationEntry } from "../lang";

export const TE: ITranslationEntry = {
    translations: {
        notifications: "నోటిఫికేషన్‌లు",
        markAllAsRead: "అన్నీచదివినట్లుగా సూచించు",
        poweredBy: "ద్వారా ఆధారితం",
        settings: "సెట్టింగ్‌లు",
        noNewNotification: "ఇంకా ఇక్కడ చూడడానికి కొత్తగా ఏమీ లేదు",
    },
    lang: "te",
};
