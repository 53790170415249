import { IOrderHandler } from "@/types/buyProductCollection";
import { Alert, Space, Typography } from "antd";
const { Text, Paragraph } = Typography;

interface Props {
    orderHandler: IOrderHandler | undefined;
}

const OrderHandlerInfo = ({ orderHandler }: Props) => {
    if (!orderHandler) {
        return <Alert message="Order Handler Not Available" />;
    }

    return (
        <Space direction="vertical" size={"small"}>
            {orderHandler.name ? (
                <Paragraph>
                    <Text>Order Handler Name: </Text>
                    <Text strong>{orderHandler.name}</Text>
                </Paragraph>
            ) : (
                ""
            )}
            {orderHandler.email ? (
                <Paragraph>
                    <Text>Order Handler Email: </Text>
                    <Text copyable>
                        <a href={`mailto:${orderHandler.email}`}>
                            {orderHandler.email}
                        </a>
                    </Text>
                </Paragraph>
            ) : (
                ""
            )}

            {orderHandler.phone ? (
                <Paragraph>
                    <Text>Order Handler Phone: </Text>
                    <Text copyable>
                        <a href={`tel:${orderHandler.phone}`}>
                            {orderHandler.phone}
                        </a>
                    </Text>
                </Paragraph>
            ) : (
                ""
            )}
        </Space>
    );
};

export default OrderHandlerInfo;
