export const permissionTooltipContent = {
    "admin-rfq-manage":
        "This permission allows users to manage the Request for Quotation (RFQ) process. With this permission  users can view the list of all received RFQs and can take two key actions: approve and reject.",
    "admin-purchase-commission-manage":
        "This permission allows users to view the list of purchase commissions and take key actions such as adding, updating, and deleting commission entries.",
    "admin-rfq-view":
        "This permission grants users read-only access to the RFQ (Request for Quotation) list. With this permission, users can view all RFQs but do not have the ability to take any actions",
    "admin-purchase-commission-view":
        "This permission provides users with read-only access to the list of purchase commissions.",
    "payment-gateway-manage":
        "This permission allows users to create, update, and view payment gateways in the system. With this permission, users can add new gateways and modify existing gateway details.",
    "payment-gateway-view":
        "This permission grants users read-only access to the list of payment gateways within the system",
    "admin-user-manage":
        "This permission grants users the ability to create and update admin user accounts within the system. With this permission, users can add new admin users and modify existing users' details, ensuring they have the necessary access and roles. This permission is crucial for maintaining the admin team's structure and ensuring that the right users have appropriate administrative rights.",
    "admin-user-view":
        "This permission provides users with read-only access to the list of admin users. With this permission, users can view details about admin accounts but cannot make any changes or updates.",
    "admin-customer-user-manage":
        "This permission allows users to create new customer and update existing customer details",
    "admin-customer-user-view":
        "This permission grants users read-only access to the customer list. With this permission, users can view customer details but are not able to make any changes or updates.",
    "admin-session-manage":
        "This permission allows a user to manage login sessions across devices. With it, users can view their active sessions and log out of other devices remotely. This is especially useful for maintaining account security, ensuring users can terminate unwanted or forgotten sessions on any device they are logged into",
    "admin-login-session-view":
        "This permission grants users read-only access. With this permission, an admin can view all active login sessions of a particular user. The admin can see which devices the user is logged into, along with details like login times and IP addresses",
    "admin-disable-all-session":
        "This permission allows the admin to forcibly disable or log out all active sessions for a user.",
    "admin-disable-session":
        "This permission allows admins to selectively disable or log out a specific session of a user.",
    "admin-payout-gateway-view":
        "This permission grants users read-only access to the payout gateway configurations within the system. With this permission, users can view details about all available payout gateways.",
    "admin-payout-gateway-manage":
        "This permission allows users to manage payout gateways in the system. With it, users can add new payout gateways and update existing ones",
    "admin-payout-request-view":
        "This permission grants users read-only access to view all payout requests. With this permission, users can see the details of submitted payout requests but cannot take any action to modify or process them.",
    "admin-payout-request-manage":
        "This permission allows users to manage payout requests, including taking actions such as approving, processing, and rejecting payout requests",
    "admin-wallet-view":
        "This permission grants users read-only access to view wallet balances and transactions",
    "admin-view-agent-wallet":
        "This permission grants users read-only access to view agent company wallet balances and transactions.",
    "admin-store-manage":
        "This permission allows the admin to manage all aspects of stores within the system, including adding new stores, updating store information, and deleting stores when necessary.",
    "admin-store-view":
        "This permission grants the admin read-only access to view all stores listed in the system.",
    "admin-exchange-group-manage":
        "This permission enables the admin to manage exchange groups, including adding new groups, updating group details, and deleting groups that are no longer needed.",
    "admin-exchange-group-view":
        "This permission grants the admin the ability to view exchange groups without making any modifications.",
    "admin-destination-warehouse-manage":
        "This permission allows the admin to manage destination warehouses, including adding new warehouses, updating their information, and deleting them from the system.",
    "admin-destination-warehouse-view":
        "This permission grants the admin the ability to view all destination warehouses in the system.",
    "admin-dw-member-invitation-manage":
        "This permission enables the admin to manage membership invitations to destination warehouses, including actions such as adding new invitations, resending existing ones, and deleting outdated or unaccepted invitations.",
    "admin-addon-service-view":
        "This permission grants the admin read-only access to view all addon services in the system.",
    "admin-dw-member-invitation-view":
        "This permission grants the admin read-only access to view all destination warehouse membership invitations.",
    "admin-destination-warehouse-member-view":
        "This permission allows the admin to view all members of the destination warehouses within the system.",
    "admin-destination-warehouse-member-manage":
        "This permission grants the admin the ability to manage members of destination warehouses, including updating member details and removing members from the warehouse.",
    "admin-addon-service-manage":
        "This permission allows the admin to manage addon services within the system, including adding new services, updating service information, and deleting services when needed.",
    "admin-address-manage":
        "This permission allows the admin to manage all addresses within the system, including adding new addresses, updating existing ones, and deleting those that are no longer needed.",
    "admin-address-view":
        "This permission grants the admin read-only access to view the list of all addresses in the system.",
    "admin-buy-action-view":
        "This permission allows the admin to view the list of all buy actions in the system, without the ability to make any changes.",
    "admin-buy-action-cancel":
        "This permission allows the admin to cancel ongoing buy actions, preventing further steps in the buying process.",
    "admin-buy-action-perform":
        "This permission enables the admin to perform buy actions, facilitating the completion of purchase operations.",
    "admin-refund-add":
        "This permission allows the admin to add new refund requests to the system, ensuring that refunds can be processed for buyers.",
    "admin-buy-action-add":
        "This permission grants the admin the ability to add new buy actions to the system, starting new purchase processes.",
    "destination-admin-courier-option-manage":
        "This permission allows the admin to manage courier options, including adding new couriers, updating courier information, and deleting outdated options.",
    "destination-admin-courier-option-view":
        "This permission grants the admin read-only access to view courier options within the system.",
    "destination-admin-courier-price-manage":
        "This permission enables the admin to manage courier prices, including setting, updating, and deleting courier price information for different services.",
    "destination-admin-courier-price-view":
        "This permission grants the admin the ability to view courier prices within the system without making any changes.",
    "destination-admin-delivery-request-view":
        "This permission allows the admin to view delivery requests, providing read-only access to the details of all active and past requests.",
    "destination-admin-delivery-request-create":
        "This permission allows the admin to create new delivery requests, enabling shipment initiation for products.",
    "destination-admin-delivery-request-status-update":
        "This permission grants the admin the ability to update the status of delivery requests, ensuring real-time tracking and progress updates.",
    "destination-admin-delivery-request-update":
        "This permission enables the admin to make updates to existing delivery requests, modifying details as needed.",
    "destination-admin-delivery-request-add-shipment-product":
        "This permission allows the admin to add shipment products to existing delivery requests, ensuring products are assigned correctly for delivery.",
    "admin-discount-view":
        "This permission allows users to view a list of all discounts within the system. Users with this permission can see basic details of each discount, such as name, start date, end date, limitation type, coupon code, and status.",
    "admin-discount-manage":
        "This permission allows users to create, update, and delete discounts within the system. Users can add new discounts, modify existing ones, and remove discounts when they are no longer valid.",
    "admin-discount-group-manage":
        "This permission allows users to manage discount groups. It includes the ability to create groups and add or remove users from discount groups.",
    "admin-discount-usage-history-view":
        "This permission provides access to the view of discount usage history. Users can see minimal information such as the date and ID of each usage.",
    "admin-harvest-batch-view":
        "This permission allows users to view all harvest job batches in the system. Users can see details such as batch number, type, and status.",
    "admin-harvest-batch-create":
        "This permission allows users to create new harvest job batches in the system.",
    "admin-harvest-batch-cancel":
        "This permission allows users to cancel existing harvest job batches.",
    "admin-payment-transaction-view":
        "This permission allows users to view payment transactions. Users can access transaction details such as transaction source, gateway information, currency, and status.",
    "admin-payment-transaction-update":
        "This permission allows users to update the status of payment transactions within the system.",
    "admin-invoice-view":
        "This permission grants users access to view the list of invoices and detailed information about each invoice, including invoice number and status.",
    "admin-invoice-settle":
        "This permission allows users to settle invoices within the system.",
    "admin-invoice-recalculate":
        "This permission allows users to recalculate an invoice, updating amounts as needed.",
    "admin-invoice-payment-rule-group-manage":
        "This permission allows users to manage invoice payment rule groups, including adding or updating groups.",
    "admin-dispute-view":
        "This permission allows users to view the list of disputes related to refunds. Users can see minimal information such as dispute number, intent, and status.",
    "admin-dispute-approve":
        "This permission allows users to approve disputes related to refund requests.",
    "admin-shipment-product-dispute-assign":
        "This permission allows users to assign shipment products to a dispute case.",
    "admin-rfq-quote-manage":
        "This permission allows users to manage quotes related to RFQ requests, including adding and updating quotes.",
    "admin-role-view":
        "This permission allows users to view a list of roles within the system, including their labels and associated access levels.",
    "admin-role-manage":
        "This permission allows users to manage roles, including creating, updating, and assigning permissions to roles.",
    "admin-user-session-manage":
        "This permission allows users to manage login sessions of other users, including disabling or ending active sessions.",
    "admin-base-shipping-category-view":
        "This permission allows users to view base shipping categories, including their names, slugs, and status.",
    "admin-base-shipping-category-manage":
        "This permission allows users to manage base shipping categories, including creating, updating, or deleting categories.",
    "admin-shipping-category-view":
        "This permission allows users to view shipping categories, including their names and status.",
    "admin-shipping-category-manage":
        "This permission allows users to manage shipping categories, including adding, updating, or deleting categories.",
    "admin-shipping-country-view":
        "This permission allows users to view shipping countries and their status.",
    "admin-shipping-country-manage":
        "This permission allows users to manage shipping countries, including adding, updating, or deleting them.",
    "admin-buy-product-add":
        "This permission allows users to add new buy products to the system. Users can initiate new purchase requests with details such as order number, order tag, type, and status.",
    "admin-buy-product-view":
        "This permission grants users the ability to view the list of buy products. Users can see details of each product such as order number, type, and status.",
    "admin-buy-product-approve":
        "This permission allows users to approve multiple buy product requests. Users can review and mark buy products as approved in bulk or individually.",
    "admin-buy-product-reject":
        "This permission allows users to reject buy product requests. Users can mark one or more buy product requests as rejected.",
    "admin-buy-product-variation-add":
        "This permission allows users to add product variations to an existing buy product order, ensuring different variations are correctly associated with the product.",
    "admin-buy-product-variation-update":
        "This permission allows users to update variations for existing buy product orders. Users can modify variations to reflect changes or corrections.",
    "admin-buy-product-variation-delete":
        "This permission allows users to delete variations from existing buy product orders. Users can remove product variations that are no longer needed.",
    "admin-buy-product-fx-set":
        "This permission allows users to set the current foreign exchange (FX) rate for a buy product, ensuring the accurate calculation of cost based on the current currency rates.",
    "admin-buy-product-fx-update":
        "This permission allows users to update the FX (foreign exchange) rate for an existing buy product, ensuring that currency rates reflect the latest changes.",
    "admin-buy-product-shipment-update":
        "This permission allows users to update shipment details for a buy product, ensuring that shipping information, including carrier and expected delivery, is accurate.",
    "admin-change-shipping-agent-category-price":
        "This permission allows users to update the shipping price for a product's shipment, ensuring that the correct price is applied based on the selected shipping agent and category.",
    "admin-buy-product-raw-view":
        "This permission grants users the ability to view the raw data of buy products, providing detailed information such as product attributes and raw purchase details.",
    "admin-buy-product-approve-multiple":
        "This permission allows users to bulk approve multiple buy product orders at once, streamlining the process for high-volume approvals.",
    "admin-buy-product-reject-multiple":
        "This permission allows users to bulk reject multiple buy product orders at once, facilitating quick handling of disapproved orders.",
    "admin-buy-order-view":
        "This permission allows the admin to view detailed information about buy orders, including order number, type, and status.",
    "admin-agent-company-manage":
        "This permission allows the admin to manage agent company details, such as updating warehouses and syncing data with external services.",
    "admin-agent-company-view":
        "This permission allows the admin to view agent company details, including their operation status and associated warehouses.",
    "admin-country-view":
        "This permission allows the admin to view the list of countries, including country name, code, and status.",
    "admin-country-manage":
        "This permission allows the admin to manage country details, including adding, updating, and syncing country information with other services.",
    "admin-language-view":
        "This permission allows the admin to view the list of languages, including language name, code, and status.",
    "admin-language-manage":
        "This permission allows the admin to manage languages in the system, including adding new languages and updating existing ones.",
    "admin-region-view":
        "This permission allows the admin to view the list of regions, including region name, code, and status.",
    "admin-region-manage":
        "This permission allows the admin to manage region details, including adding, updating, and syncing information across other services.",
    "admin-currency-view":
        "This permission allows the admin to view the list of currencies, including currency code, name, and status.",
    "admin-currency-manage":
        "This permission allows the admin to manage currency details, including adding, updating, and syncing currency data with other services.",
    "admin-view-shipment-product":
        "This permission allows the admin to view shipment products and shipment orders in the system.",
    "admin-place-order":
        "This permission allows the admin to place shipment orders for products.",
    "admin-attach-images":
        "This permission allows the admin to bulk attach images to shipment products.",
    "admin-change-shipping-country":
        "This permission allows the admin to change the shipping country for multiple shipment products.",
    "admin-change-destination-warehouse":
        "This permission allows the admin to change the destination warehouse for multiple shipment products.",
    "admin-change-shipping-category":
        "This permission allows the admin to change the shipping category for multiple shipment products.",
    "admin-change-shipping-mode":
        "This permission allows the admin to change the shipping mode for multiple shipment products.",
    "admin-change-shipping-type":
        "This permission allows the admin to change the shipping type for multiple shipment products.",
    "admin-assign-default":
        "This permission allows the admin to assign the default agent to multiple shipment products.",
    "admin-assign-with-price":
        "This permission allows the admin to assign an agent with price for multiple shipment products.",
    "admin-approve-shipment-product":
        "This permission allows the admin to approve shipment products in the system.",
    "admin-reject-shipment-product":
        "This permission allows the admin to reject shipment products in the system.",
    "admin-attach-tracking":
        "This permission allows the admin to attach tracking information to shipment products.",
    "admin-update-tracking":
        "This permission allows the admin to update existing tracking information for shipment products.",
    "admin-package-view":
        "This permission allows the admin to view the packages associated with a shipment product.",
    "admin-package-add":
        "This permission allows the admin to add new packages to shipment products.",
    "admin-package-update":
        "This permission allows the admin to update existing packages associated with shipment products.",
    "admin-package-delete":
        "This permission allows the admin to delete packages associated with shipment products.",
    "admin-shipment-bid-add":
        "This permission allows the admin to add bids for shipment products.",
    "admin-shipment-bid-view":
        "This permission allows the admin to view bids placed on shipment products.",
    "admin-shipment-bid-attach-participant":
        "This permission allows the admin to attach participants to shipment bids.",
    "admin-shipment-bid-abandon":
        "This permission allows the admin to abandon a bid for shipment products.",
    "admin-shipment-bid-choose-winner":
        "This permission allows the admin to select a winning participant from the shipment bid offers.",
    "admin-shipment-bid-offer-view":
        "This permission allows the admin to view the offers associated with shipment bids.",
    "admin-unlisted-package-view":
        "This permission allows the admin to view unlisted packages, including details like package and carton numbers.",
    "admin-unlisted-package-add":
        "This permission allows the admin to add unlisted packages to the system.",
    "admin-unlisted-package-update":
        "This permission allows the admin to update details of unlisted packages in the system.",
    "admin-shipment-product-charge-add":
        "This permission allows the admin to add charges associated with shipment products.",
    "admin-shipment-product-charge-update":
        "This permission allows the admin to update charges associated with shipment products.",
    "admin-shipment-product-charge-delete":
        "This permission allows the admin to delete charges associated with shipment products.",
    "admin-recalculate-agent-invoice":
        "This permission allows the admin to recalculate agent invoices.",
    "admin-invoice-payment-rule-group-view":
        "This permission allows the admin to view invoice payment rule groups.",
    "admin-dispute-complete":
        "This permission allows the admin to mark disputes as complete.",
    "admin-auction-rule-view":
        "This permission allows the admin to view auction rules, including their name, region, and status.",
    "admin-auction-rule-manage":
        "This permission allows the admin to manage auction rules, including adding, updating, and deleting entries.",
    "admin-tracking-event-add":
        "This permission allows the admin to add tracking events, such as comments on tracking timelines.",
    "admin-tracking-event-update":
        "This permission allows the admin to update tracking events, such as comments.",
    "admin-tracking-timeline-view":
        "This permission allows the admin to view the tracking timeline for products, buy orders, or delivery requests.",
};
