import React, { CSSProperties, ReactNode } from "react";
import { Button, Flex, Typography } from "antd";

import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { EditOutlined } from "@ant-design/icons";

interface ShippingDetailProps {
    label: string;
    value?: string | undefined;
    flagUrl?: string;
    onActionClick?: (e?: any) => void;
    isEditable?: boolean;
    permissionCheck?: string[] | string;
    isDetailPage?: boolean;
    children?: ReactNode;
}

const ShippingText: React.FC<ShippingDetailProps> = ({
    label,
    value,
    flagUrl,
    permissionCheck,
    onActionClick,
    children,
    isEditable = true,
}) => {
    const buttonStyle: CSSProperties = {
        padding: 0,
    };
    return value || children ? (
        <>
            <Flex align="center" gap={2} style={{ width: "100%" }}>
                <Typography.Text
                    style={{
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                    }}
                >
                    {label}:
                </Typography.Text>

                <Flex align="baseline" gap={2}>
                    {flagUrl && (
                        <div style={{ width: 18, height: 18 }}>
                            <ImageWithPlaceholder
                                preview={false}
                                src={flagUrl}
                                alt=""
                            />
                        </div>
                    )}

                    <Flex align="center" flex={"1"}>
                        {children ? (
                            children
                        ) : (
                            <Typography.Text
                                strong
                                ellipsis={{
                                    tooltip: value,
                                }}
                                style={{
                                    textTransform: "capitalize",
                                    maxWidth: 200,
                                }}
                            >
                                {value}
                            </Typography.Text>
                        )}

                        {permissionCheck && isEditable ? (
                            checkActionPermission(
                                permissionCheck,
                                <Button
                                    icon={<EditOutlined />}
                                    style={buttonStyle}
                                    type="link"
                                    onClick={(e) =>
                                        onActionClick && onActionClick(e)
                                    }
                                ></Button>,
                                null,
                            )
                        ) : (
                            <></>
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </>
    ) : (
        <></>
    );
};

export default ShippingText;
