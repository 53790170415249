import React, { useEffect, createRef } from "react";
import { Button, Form, Input, Checkbox } from "antd";
import { FormInstance } from "antd/es/form/Form";

import { useRole, useUpdateRole } from "@/lib/core-react/hooks/private/useRole";
import { Forms } from "@/models/form";
import {
    RoleModel,
    RolePostRequestModel,
    RoleUpdateRequestModel,
} from "@/models/roleCollectionModel";
import { showError } from "@/helpers/showError";

interface IProps {
    onHide: () => void;
    role?: RoleModel;
}

const RoleModification = ({ onHide, role }: IProps) => {
    const { createRole, isLoading } = useRole();
    const { updateRole, isLoading: isLoadingUpdate } = useUpdateRole();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinishUpdate = async (value: RoleUpdateRequestModel) => {
        const payload: RoleUpdateRequestModel = {
            name: value.name,
            label: value.label,
            is_customer: Boolean(value.is_customer),
            is_order_handler: Boolean(value.is_order_handler),
            permission_ids: role
                ? role.permissions?.data?.map((permission) => permission.id)
                : [],
        };
        try {
            if (role) {
                await updateRole(role.id, payload);
            }
            onHide();
        } catch (error) {
            showError(error, form);
        }
    };

    const onFinishCreate = async (value: RolePostRequestModel) => {
        const payload: RolePostRequestModel = {
            name: value.name,
            label: value.label,
            is_customer: Boolean(value.is_customer),
            is_order_handler: Boolean(value.is_order_handler),
        };
        try {
            await createRole(payload);
            onHide();
        } catch (error: any) {
            showError(error, form);
        }
    };

    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    return (
        <Form
            onFinish={role ? onFinishUpdate : onFinishCreate}
            form={form}
            layout="vertical"
            name={role ? "Update Role" : "Create Role"}
            onFieldsChange={onValuesChange}
            ref={formRef}
            initialValues={{
                name: role?.name,
                label: role?.label,
                is_customer: role?.is_customer,
                is_order_handler: role?.is_order_handler,
            }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Name" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Label is required" }]}
                label="Label"
                name="label"
            >
                <Input placeholder="Label" />
            </Form.Item>

            <Form.Item valuePropName="checked" name="is_customer">
                <Checkbox>Is Customer</Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" name="is_order_handler">
                <Checkbox>Is Order Handler</Checkbox>
            </Form.Item>
            <Form.Item>
                <Button
                    loading={isLoading || isLoadingUpdate}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default RoleModification;
