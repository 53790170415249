import { useEffect, useRef, useState } from "react";
import { useGetBaseShippingCategory } from "@/lib/core-react/hooks/private";
import React from "react";
import { Button, Form, notification, TreeSelect } from "antd";
import { FormInstance } from "antd/es/form/Form";
import {
    baseShippingCategoryAtom,
    inventoryCategoryTreeAtomByName,
} from "@/lib/core-react/store/store";
import { useAtom } from "jotai";
import { ApiHelperModel } from "@/models/apiHelper";
import { InventoryCategoryResponseModel } from "@/models/inventoryCollectionModel";
import useDataFilters from "@/hooks/useDataFilters";
import { IBaseShippingCategoryMappingForm } from "@/types/inventoryCategoryCollection";
import {
    useCreateBaseShippingCategoryMapping,
    useGetInventoryCategoriesTreeByName,
} from "@/lib/core-react/hooks/private/useProduct";
import { showError } from "@/helpers/showError";
import {
    transformToBaseShippingTreeData,
    TransformToInventoryCategoryTreeData,
} from "../utils";
import { debounce } from "lodash";

interface IProps {
    setIsShowCategoryMappingModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const CreateBaseShippingCategory = ({
    setIsShowCategoryMappingModal,
}: IProps) => {
    const [baseShippingCategoryName, setBaseShippingCategoryName] =
        useState("");
    const [inventoryCategoryName, setInventoryCategoryName] = useState("");

    const { getBaseShippingCategory } = useGetBaseShippingCategory();
    const { getCategoriesTreeByName } = useGetInventoryCategoriesTreeByName();

    const { createBaseShippingCategoryMapping, isLoading: isMappingLoading } =
        useCreateBaseShippingCategoryMapping();

    const { isFirstCall, isFetched } = useDataFilters();

    const [
        {
            data: baseShippingData,
            isLoading: isBaseCategoryShippingLoading,
            refetch,
        },
    ] = useAtom(baseShippingCategoryAtom);

    const [
        {
            data: inventoryCategoryResponseData,
            isLoading: isInventoryCategoryLoading,
        },
    ] = useAtom(inventoryCategoryTreeAtomByName);

    // Debounced search for Base Shipping Category
    const debouncedBaseShippingSearch = useRef(
        debounce((value) => {
            setBaseShippingCategoryName(value);
        }, 500), // 500ms delay
    ).current;

    // Debounced search for Inventory Category
    const debouncedInventoryCategorySearch = useRef(
        debounce((value) => {
            setInventoryCategoryName(value);
        }, 500),
    ).current;

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(
                {
                    name: baseShippingCategoryName,
                    per_page: 100,
                },
                getBaseShippingCategory,
            );
        }
    }, [isFirstCall, isFetched, refetch, baseShippingCategoryName]);

    // API Call
    useEffect(() => {
        if (isFirstCall || inventoryCategoryName) {
            ApiHelperModel.makeGetRequest({}, () =>
                getCategoriesTreeByName({ name: inventoryCategoryName }),
            );
        }
    }, [inventoryCategoryName, isFirstCall]);

    const InventoryCategoryTree =
        inventoryCategoryResponseData &&
        new InventoryCategoryResponseModel(inventoryCategoryResponseData);

    const formRef = useRef<FormInstance>(null);
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value) => {
        const payload: IBaseShippingCategoryMappingForm = {
            base_shipping_category_id: value.base_shipping_category_id,
            inventory_category_ids: value.inventory_category_ids,
        };
        try {
            await createBaseShippingCategoryMapping(payload);
            setIsShowCategoryMappingModal(false);
            form.resetFields();
            notification["success"]({
                message: "Base Shipping Category Mapping Created Successfully",
            });
        } catch (error) {
            showError(error, form);
        }
    };

    const onValuesChange = (changedValues: any) => {
        form.setFieldsValue(changedValues);
    };

    // Transform the data for the TreeSelect component
    const treeBaseShippingCategory = baseShippingData?.data
        ? transformToBaseShippingTreeData(baseShippingData.data)
        : [];

    const inventoryCategoryData = InventoryCategoryTree
        ? TransformToInventoryCategoryTreeData(InventoryCategoryTree.data)
        : [];

    return (
        <div>
            <Form
                onFinish={onFinish}
                form={form}
                layout="vertical"
                name="base_shipping_category_mapping_form"
                ref={formRef}
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Select a Base Shipping Category",
                        },
                    ]}
                    name="base_shipping_category_id"
                    label="Select Base Shipping Category"
                >
                    <TreeSelect
                        placeholder="Please select a category"
                        treeData={treeBaseShippingCategory}
                        loading={isBaseCategoryShippingLoading}
                        allowClear
                        showSearch
                        treeDefaultExpandedKeys={["1"]}
                        onSearch={(value) => {
                            debouncedBaseShippingSearch(value);
                        }}
                        filterTreeNode={false}
                    />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Select Inventory Category",
                        },
                    ]}
                    name="inventory_category_ids"
                    label="Select Inventory Category"
                >
                    <TreeSelect
                        placeholder="Please select a category"
                        treeData={inventoryCategoryData}
                        loading={isInventoryCategoryLoading}
                        allowClear
                        showSearch
                        treeDefaultExpandedKeys={["1"]}
                        treeCheckable
                        treeLine
                        onSearch={(value) => {
                            debouncedInventoryCategorySearch(value);
                        }}
                        filterTreeNode={false}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={
                            isMappingLoading ||
                            isBaseCategoryShippingLoading ||
                            isInventoryCategoryLoading
                        }
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CreateBaseShippingCategory;
