import {
    CurrencyDataStatus,
    CurrencyEnum,
} from "@/enums/currencyCollectionEnums";
import { ICurrency, ICurrencyCollection } from "@/types/currencyCollection";
import { IPagination } from "@/types/pagination";

export class CurrencyModel {
    object: CurrencyEnum.Currency;
    id: number;
    name: string;
    code: string;
    symbol: string;
    symbol_native: string;
    status: CurrencyDataStatus;
    created_at: string;

    constructor(data: ICurrency) {
        this.object = data.object;
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.symbol = data.symbol;
        this.symbol_native = data.symbol_native;
        this.status = data.status;
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getName = () => this.name;
    getCode = () => this.code;
    getSymbol = () => this.symbol;
    getSymbolNative = () => this.symbol_native;
}

export class CurrencyCollectionModel {
    object: string;
    data: CurrencyModel[];
    pagination: IPagination;

    constructor(data: ICurrencyCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new CurrencyModel(item));
        this.pagination = data.pagination;
    }

    getData = () => this.data;
    getPagination = () => this.pagination;

    getCurrencyForBDT = (regionCurrencyCode: string) => {
        return this.data.find((item) => item.getCode() === regionCurrencyCode);
    };
    getDataByCurrencyCode = (regionCurrencyCode: string) => {
        return this.data.find((item) => item.getCode() === regionCurrencyCode);
    };
    getCurrencyOptions() {
        return this.data && this.data.length > 0
            ? this.data.map((item) => ({
                  label: item.getCode(),
                  value: item.getId(),
              }))
            : [];
    }
    getCurrencyLabeledValue() {
        return this.data.map((item) => ({
            label: item.getCode(),
            value: item.getCode(),
        }));
    }

    getCurrencyLabeledValueV2() {
        return this.data.map((item) => ({
            label: item.getCode(),
            value: item.getId(),
        }));
    }
}
