import { ShipmentProductPackageModel } from "@/models/shipmentProductPackageCollection";
import { Space, Typography } from "antd";

interface IProps {
    productPackage: ShipmentProductPackageModel;
}

export const CartonVariationsModal = ({ productPackage }: IProps) => {
    return (
        <>
            {productPackage.getVariations().length ? (
                <Space
                    size="large"
                    direction="horizontal"
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                    }}
                >
                    <Typography.Text
                        strong
                        style={{ textTransform: "capitalize" }}
                    >
                        {productPackage
                            .getVariations()[0]
                            .getProperties()
                            .map((d) => d.property_name)
                            .join(", ")}
                    </Typography.Text>
                    <Typography.Text strong>Quantity</Typography.Text>
                </Space>
            ) : (
                ""
            )}

            <Space
                size="large"
                direction="vertical"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                }}
            >
                {productPackage.getVariations().length
                    ? productPackage
                          .getVariations()
                          .map((variation, variationIndex) => {
                              return (
                                  <div
                                      key={variation.getId()}
                                      style={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          paddingRight: "10px",
                                      }}
                                  >
                                      <Typography.Text>
                                          {variationIndex + 1}
                                          {". "}
                                          {variation
                                              .getProperties()
                                              .map(
                                                  (prop) =>
                                                      prop.property_value_name,
                                              )
                                              .join(", ")}
                                      </Typography.Text>
                                      <Typography.Text>
                                          {variation.getQuantity()}
                                      </Typography.Text>
                                  </div>
                              );
                          })
                    : "Variation not found"}
            </Space>
        </>
    );
};
