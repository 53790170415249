import { ITranslationEntry } from "../lang";

export const KN: ITranslationEntry = {
    translations: {
        notifications: "ಅಧಿಸೂಚನೆಗಳು",
        markAllAsRead: "ಎಲ್ಲವನ್ನು ಓದಲಾಗಿದೆ ಎಂದು ಗುರುತಿಸಿ",
        poweredBy: "ಮೂಲಕ ನಡೆಸಲ್ಪಡುತ್ತಿದೆ",
        settings: "ಸೆಟ್ಟಿಂಗ್ಸ್",
        noNewNotification: "ಹೊಸ ಅಧಿಸೂಚನೆ ಇಲ್ಲ",
    },
    lang: "kn",
};
