import { BuyProductPackageModel } from "@/models/buyProductCollectionModel";
import { Descriptions } from "antd";

export const BuyProductPackage = ({
    buyProductPackage,
}: {
    buyProductPackage: BuyProductPackageModel;
}) => {
    return (
        <>
            <Descriptions
                layout="vertical"
                bordered
                column={2}
                size="middle"
                labelStyle={{ fontWeight: 600 }}
            >
                {/* <Descriptions.Item label="ID">
                    <Typography.Text copyable>{buyProductPackage.id}</Typography.Text>
                </Descriptions.Item>
                <Descriptions.Item label="Buy Product ID">
                    <Typography.Text copyable>
                        {buyProductPackage.buy_product_id}
                    </Typography.Text>
                </Descriptions.Item> */}
                <Descriptions.Item label="Weight">
                    {`${buyProductPackage.weight} ${buyProductPackage.weight_unit}`}
                </Descriptions.Item>
                <Descriptions.Item label="Dimensions (L × W × H)">
                    {`${buyProductPackage.length} ${buyProductPackage.length_unit} × ${buyProductPackage.width} ${buyProductPackage.width_unit} × ${buyProductPackage.height} ${buyProductPackage.height_unit}`}
                </Descriptions.Item>
            </Descriptions>
        </>
    );
};
