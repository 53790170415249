import { IFilterType } from "@/types/filters";
import {
    PaymentGatewayCollectionEnums,
    PaymentGatewayFeeTypeEnums,
    PaymentGatewayIdentifierEnums,
    PaymentGatewayTypeEnums,
} from "@/enums/paymentGatewayCollectionEnums";
import {
    IPaymentGatewayCollection,
    IPaymentGateway,
    IPaymentGatewayFormSchema,
    IPaymentGatewayLogo,
    IGatewayCurrencyCollection,
    IGatewayCurrency,
    IPaymentGatewayLogoCollection,
    IPaymentGatewayLogoImage,
    IMerchantData,
} from "@/types/paymentGatewayCollection";
import { PaginationModel } from "./paginationModel";

export class GatewayCurrencyModel {
    object: string;
    id: number;
    code: string;
    symbol: string;
    sybol_native: string;
    name: string;
    status: string;

    constructor(dataItem: IGatewayCurrency) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.code = dataItem.code;
        this.sybol_native = dataItem.sybol_native;
        this.symbol = dataItem.symbol;
        this.status = dataItem.status;
    }

    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getCode() {
        return this.code;
    }
    getSymbolNative() {
        return this.sybol_native;
    }
    getStatus() {
        return this.status;
    }
    getSymbol() {
        return this.symbol;
    }
}

export class GatewayCurrencyCollectionModel {
    object: string;
    data: GatewayCurrencyModel[];

    constructor(data: IGatewayCurrencyCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new GatewayCurrencyModel(item));
    }
    getData() {
        return this.data;
    }
    getDataIds = () => this.data.map((d) => d.getId());
}

export class PaymentGatewayLogoImageModel {
    src: string;
    width: number | null;
    height: number | null;

    constructor(data: IPaymentGatewayLogoImage) {
        this.src = data.src;
        this.width = data.width;
        this.height = data.height;
    }
}

export class PaymentGatewayLogoModel {
    object: string;
    small?: PaymentGatewayLogoImageModel;
    medium?: PaymentGatewayLogoImageModel;
    large?: PaymentGatewayLogoImageModel;

    constructor(data: IPaymentGatewayLogo) {
        this.object = data.object;
        if (data.small)
            this.small = new PaymentGatewayLogoImageModel(data.small);
        if (data.medium)
            this.medium = new PaymentGatewayLogoImageModel(data.medium);
        if (data.large)
            this.large = new PaymentGatewayLogoImageModel(data.large);
    }

    getSmall(): PaymentGatewayLogoImageModel | undefined {
        return this.small;
    }
    getMedium(): PaymentGatewayLogoImageModel | undefined {
        return this.medium;
    }
}

export class PaymentGatewayLogoCollection {
    object: string;
    data: PaymentGatewayLogoModel[];

    constructor(data: IPaymentGatewayLogoCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new PaymentGatewayLogoModel(item));
    }

    getData() {
        return this.data;
    }
}

export class PaymentGatewayModel {
    object: PaymentGatewayCollectionEnums.PaymentGateway;
    id: number;
    identifier: PaymentGatewayIdentifierEnums;
    name: string;
    type: PaymentGatewayTypeEnums;
    fee: number;
    fee_type: PaymentGatewayFeeTypeEnums;
    merchant_data: IMerchantData | null;
    form_schema: {
        en: IPaymentGatewayFormSchema;
    } | null;
    logos: PaymentGatewayLogoCollection | null;
    currencies: GatewayCurrencyCollectionModel;
    status: string;

    constructor(dataItem: IPaymentGateway) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.identifier = dataItem.identifier;
        this.name = dataItem.name;
        this.type = dataItem.type;
        this.fee = dataItem.fee;
        this.fee_type = dataItem.fee_type;
        this.merchant_data = dataItem.merchant_data;
        this.form_schema = dataItem.form_schema;
        this.logos = dataItem.logos
            ? new PaymentGatewayLogoCollection(dataItem.logos)
            : null;
        this.currencies = new GatewayCurrencyCollectionModel(
            dataItem.currencies,
        );
        this.status = dataItem.status;
    }

    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getIdentifier() {
        return this.identifier;
    }
    getType() {
        return this.type;
    }
    getFee() {
        return this.fee;
    }
    getStatus = () => this.status;
    getFeeType() {
        return this.fee_type;
    }
    getMerchantData() {
        return this.merchant_data ? this.merchant_data : {};
    }
    getFormSchema() {
        return this.form_schema?.en;
    }
    getLogo() {
        return this.logos;
    }
    getCurrencies() {
        return this.currencies;
    }
}

export class PaymentGatewayCollectionModel {
    object: PaymentGatewayCollectionEnums.PaymentGatewayCollection;
    data: PaymentGatewayModel[];
    pagination: PaginationModel;
    filters: IFilterType;

    constructor(data: IPaymentGatewayCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new PaymentGatewayModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }
    getData() {
        return this.data;
    }
    getMobileMerchantPaymentData = () => {
        return this.data.filter(
            (item) =>
                item.getType() === PaymentGatewayTypeEnums.MOBILE_MERCHANT,
        );
    };
    getAutomaticPaymentData = () => {
        return this.data.filter(
            (item) =>
                item.getType() === PaymentGatewayTypeEnums.AUTOMATIC_PAYMENT,
        );
    };
    getBankDepositData = () => {
        return this.data.filter(
            (item) => item.getType() === PaymentGatewayTypeEnums.BANK_DEPOSIT,
        );
    };
    getFilters = () => this.filters;
    getPagination = () => this.pagination;

    getPaymentGatewayById(id: number): PaymentGatewayModel | undefined {
        return this.data.find((item) => item.id === id);
    }
    getPaymentGatewayIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.name));
        return obj;
    }
}
