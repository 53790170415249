import { InputHTMLAttributes, ReactNode } from "react";

import { Link, useLocation, useSearchParams } from "react-router-dom";
import { LogoutLink } from "../hooks";
import { Button, Checkbox, Input, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
    subtitle?: ReactNode;
    helper?: ReactNode;
    state?: string | undefined;
}

const TextInput = ({
    className,
    title,
    subtitle,
    disabled,
    type = "text",
    onChange,
    value,
    ...props
}: TextInputProps) => {
    const [searchParams] = useSearchParams();
    const { size, ...restProps } = props;

    const location = useLocation();
    const pathName = location.pathname;
    const isLoginPath = pathName.includes("/login");

    const refresh = searchParams.get("refresh");
    const aal = searchParams.get("aal");

    // let state = props.state
    // if (disabled) {
    //   state = "disabled"
    // }

    const onLogout = LogoutLink([aal, refresh]);

    if (type === "password") {
        return (
            <>
                {/*<input*/}
                {/*  onChange={onChange}*/}
                {/*  value={value}*/}
                {/*  type={showPass ? "text" : "password"}*/}
                {/*  disabled={disabled}*/}
                {/*  {...props}*/}
                {/*/>*/}
                <Input.Password
                    placeholder={title}
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    {...restProps}
                />
                {subtitle && <div>{subtitle}</div>}

                {isLoginPath ? (
                    aal || refresh ? (
                        <div>
                            <Button
                                data-testid="logout-link"
                                onClick={onLogout}
                                type={"primary"}
                            >
                                Log out
                            </Button>
                        </div>
                    ) : (
                        <Space
                            direction={"horizontal"}
                            style={{
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                        >
                            <Checkbox id="remember">Remember me</Checkbox>
                            <Button
                                type={"link"}
                                style={{ paddingRight: "0px" }}
                            >
                                <Link to="/auth/recovery">
                                    Forgot password?
                                </Link>
                            </Button>
                        </Space>
                    )
                ) : null}
            </>
        );
    } else {
        return (
            <>
                <Input
                    placeholder={title}
                    onChange={onChange}
                    value={value}
                    type={type}
                    disabled={disabled}
                    {...restProps}
                />
                {subtitle && <div>{subtitle}</div>}
            </>
        );
    }
};

export default TextInput;
