import React, { useEffect } from "react";
import { Button, Form, FormInstance, Modal, Select, notification } from "antd";
import { useUpdateDestinationWarehouse } from "@/lib/core-react/hooks/private/useShipping";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";

interface IProps {
    country_id: number | undefined;
    productIds: number[];
    isOpen: boolean;
    onCancel: (isMutate?: boolean) => void;
    existingWarehouseId: number | undefined;
}

export const ChangeDestinationWarehouseModal = ({
    country_id,
    isOpen,
    onCancel,
    productIds,
    existingWarehouseId,
}: IProps) => {
    const { updateDestinationWarehouse, isLoading: updateWarehouseLoading } =
        useUpdateDestinationWarehouse();
    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();

    const {
        allOptionsData: { destinationWarehouseOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.DESTINATION_WAREHOUSE, {
            country_id: country_id,
        });
    }, []);

    useEffect(() => {
        form.setFieldValue(
            "destinationWarehouse",
            destinationWarehouseOptionsData.options.find(
                (opt) => opt.value === existingWarehouseId,
            ),
        );
        return () => {
            form.resetFields();
        };
    }, [form, destinationWarehouseOptionsData.options]);

    // Ok handler
    const onOkHandler = async (data: { destinationWarehouse: number }) => {
        const payload: {
            shipment_product_ids: number[];
            destination_warehouse_id: number;
        } = {
            shipment_product_ids: productIds,
            destination_warehouse_id: data.destinationWarehouse,
        };

        try {
            const data = await updateDestinationWarehouse(payload);
            notification["success"]({
                message: data.message,
            });
            onCancel(true);
        } catch (error) {
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return (
        <Modal
            title="Change Destination Warehouse"
            open={isOpen}
            destroyOnClose={true}
            confirmLoading={updateWarehouseLoading}
            onCancel={() => onCancel(false)}
            footer={false}
        >
            <Form
                onFinish={onOkHandler}
                form={form}
                layout="vertical"
                name="update_warehouse"
                ref={formRef}
            >
                <Form.Item
                    name="destinationWarehouse"
                    label="Destination Warehouse"
                >
                    <Select
                        loading={destinationWarehouseOptionsData.isLoading}
                        onChange={(value) => {
                            form.setFieldValue("destinationWarehouse", value);
                        }}
                        placeholder="Please select a destination warehouse"
                        options={destinationWarehouseOptionsData.options}
                    />
                </Form.Item>
                <Form.Item>
                    <Button
                        loading={updateWarehouseLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
