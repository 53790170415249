import React, { useEffect } from "react";
import { Button, Form, FormInstance, Input, Select, notification } from "antd";

import { useUpdateAgentWarehouse } from "@/lib/core-react/hooks/private";
import { IAgentWarehouseFormData } from "@/types/agentWarehouseCollection";
import {
    AgentWarehouseOperationStatusEnums,
    AgentWarehouseVerificationStatusEnums,
} from "@/enums/shared";
import { AgentWarehouseModel } from "@/models/agentWarehouseCollectionModel";

import { renderOptionsFromEnumReversed } from "@/components/Form/forms";
import { showError } from "@/helpers/showError";

interface IProps {
    selectedAgentWarehouse: AgentWarehouseModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateAgentWarehouse = ({
    selectedAgentWarehouse,
    setIsShowUpdateModal,
}: IProps) => {
    const { updateAgentWarehouse, isLoading } = useUpdateAgentWarehouse();

    const formRef: React.Ref<FormInstance<any>> = React.createRef();

    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [selectedAgentWarehouse, form]);

    const onFinish = async (value: IAgentWarehouseFormData) => {
        const payload = {
            country_id: value.country_id,
            alias_name: value.alias_name,
            shipping_mark: value.shipping_mark,
            operation_status: value.operation_status,
            verification_status: value.verification_status,
        };

        try {
            await updateAgentWarehouse(
                selectedAgentWarehouse.getAgentCompany().getId(),
                selectedAgentWarehouse.getId(),
                payload as IAgentWarehouseFormData,
            );
            notification["success"]({
                message: "Update Agent warehouse successfully",
            });
            setIsShowUpdateModal(false);
        } catch (error: any) {
            showError(error);
        }
    };

    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Edit_Agent_Warehouse"
                    ref={formRef}
                    initialValues={{
                        alias_name: selectedAgentWarehouse.getAliasName(),
                        shipping_mark: selectedAgentWarehouse.getShippingMark(),
                        operation_status:
                            selectedAgentWarehouse.getOperationStatus(),
                        verification_status:
                            selectedAgentWarehouse.getVerificationStatus(),
                    }}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Agent warehouse name is required",
                            },
                        ]}
                        label="Visible Name"
                        name="alias_name"
                    >
                        <Input placeholder="Agent warehouse visible name" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Shipping mark is required",
                            },
                        ]}
                        label="Shipping Mark"
                        name="shipping_mark"
                    >
                        <Input placeholder="Shipping mark" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Operation status is required",
                            },
                        ]}
                        name="operation_status"
                        label="Operation Status"
                    >
                        <Select
                            value={selectedAgentWarehouse.getOperationStatus()}
                            placeholder="Please select a operation status"
                        >
                            {renderOptionsFromEnumReversed(
                                AgentWarehouseOperationStatusEnums,
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Verification Status is required",
                            },
                        ]}
                        name="verification_status"
                        label="Verification Status"
                    >
                        <Select placeholder="Please select a Verification status">
                            {renderOptionsFromEnumReversed(
                                AgentWarehouseVerificationStatusEnums,
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default UpdateAgentWarehouse;
