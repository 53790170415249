import {
    IPreviewExchangeData,
    IPreviewExchangeList,
    IPreviewStoreExchange,
    IRegionalExchangePreview,
    IStoreExchangePreview,
} from "@/types/exchangeListCollection";

export class PreviewExchangeListModel {
    private object: string;
    private id: number;
    private name: string;
    private is_default: number;

    constructor(data: IPreviewExchangeList) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.is_default = data.is_default;
    }

    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getIsDefault(): number {
        return this.is_default;
    }
}

export class PreviewStoreExchangeModel {
    private object: string;
    private id: number;
    private fx_rate: number;
    private commission_rate: number;
    private commission_rate_type: string;

    constructor(data: IPreviewStoreExchange) {
        this.object = data.object;
        this.id = data.id;
        this.fx_rate = data.fx_rate;
        this.commission_rate = data.commission_rate;
        this.commission_rate_type = data.commission_rate_type;
    }

    getObject(): string {
        return this.object;
    }

    getId(): number {
        return this.id;
    }

    getFxRate(): number {
        return this.fx_rate;
    }

    getCommissionRate(): number {
        return this.commission_rate;
    }

    getCommissionRateType(): string {
        return this.commission_rate_type;
    }
}

export class PreviewExchangeDataModel {
    private exchange_list: PreviewExchangeListModel;
    private store_exchange: PreviewStoreExchangeModel;
    private new_fx_rate: number;
    private new_commission_rate: number;

    constructor(data: IPreviewExchangeData) {
        this.exchange_list = new PreviewExchangeListModel(data.exchange_list);
        this.store_exchange = new PreviewStoreExchangeModel(
            data.store_exchange,
        );
        this.new_fx_rate = data.new_fx_rate;
        this.new_commission_rate = data.new_commission_rate;
    }

    getExchangeList(): PreviewExchangeListModel {
        return this.exchange_list;
    }

    getStoreExchange(): PreviewStoreExchangeModel {
        return this.store_exchange;
    }

    getNewFxRate(): number {
        return this.new_fx_rate;
    }

    getNewCommissionRate(): number {
        return this.new_commission_rate;
    }
}

export class StoreExchangePreviewModel {
    private message: string;
    private defaultExchange: PreviewExchangeDataModel;
    private non_default: PreviewExchangeDataModel[];
    private payload: IRegionalExchangePreview | undefined;

    constructor(data: IStoreExchangePreview) {
        this.message = data.message;
        this.defaultExchange = new PreviewExchangeDataModel(data.data.default);
        this.non_default = data.data.non_default.map(
            (item) => new PreviewExchangeDataModel(item),
        );
        this.payload = data.payload;
    }

    getMessage(): string {
        return this.message;
    }

    getDefaultExchange(): PreviewExchangeDataModel {
        return this.defaultExchange;
    }

    getNonDefaultExchanges(): PreviewExchangeDataModel[] {
        return this.non_default;
    }
    getPayload(): IRegionalExchangePreview | undefined {
        return this.payload;
    }
}
