import React, { useEffect } from "react";
import {
    Button,
    Col,
    Form,
    FormInstance,
    InputNumber,
    Row,
    message,
} from "antd";
import { useAddCampaignProducts } from "@/lib/core-react/hooks/private/useCampaign";
import { IAddCampaignProductsPostRequest } from "@/types/campaignCollection";
import { useNavigate } from "react-router-dom";
import { IModalData } from "@/helpers/getModalTital";
import { showError } from "@/helpers/showError";
import { defaultQuery } from "@/consts/defaultQuery";

interface IProps {
    selectedProducts: string[];
    region: string;
    campaignId: string;
    handleModal: (payload: IModalData) => void;
}

const AddProductToCampaign = ({
    selectedProducts,
    region,
    campaignId,
    handleModal,
}: IProps) => {
    const navigate = useNavigate();
    const { addCampaignProducts, isLoading } = useAddCampaignProducts();

    const formRef: React.Ref<FormInstance<any>> = React.createRef();

    const [form] = Form.useForm();

    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [campaignId, form]);

    const onFinish = async (value: IAddCampaignProductsPostRequest) => {
        try {
            if (selectedProducts.length === 0) {
                throw new Error("Please select at least one product");
            }

            const payload = {
                product_ids: selectedProducts,
                configuration: {
                    price_hike: value.configuration.price_hike,
                    hike_type: value.configuration.hike_type,
                    discount: value.configuration.discount,
                    discount_type: value.configuration.discount_type,
                },
            };
            await addCampaignProducts(
                campaignId,
                defaultQuery.locale,
                region,
                payload as IAddCampaignProductsPostRequest,
            );

            message.success("Product added successfully");
            handleModal({
                action: "add_to_campaign",
                data: null,
            });
            navigate("/campaigns");
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Add_Campaign_Products"
                    ref={formRef}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Price hike is required",
                                    },
                                ]}
                                label="Price Hike"
                                name={["configuration", "price_hike"]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                    placeholder="Enter price hike"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                rules={[
                                    {
                                        required: true,
                                        message: "Discount is required",
                                    },
                                ]}
                                label="Discount"
                                name={["configuration", "discount"]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                    placeholder="Enter discount"
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item noStyle>
                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                        >
                            Add to campaign
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default AddProductToCampaign;
