import { Button, Form, Select, Spin, Tag, Tooltip } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useAtom } from "jotai";
import React, { useEffect, useState, createRef } from "react";
import { invoicePaymentRuleCollectionAtom } from "@/lib/core-react/store/store";
import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import { useInvoicePaymentRule } from "@/lib/core-react/hooks/private/useInvoicePaymentRule";
import { ApiHelperModel } from "@/models/apiHelper";
import { InvoicePaymentRuleModel } from "@/models/invoicePaymentRoleModel";
import { InfoCircleOutlined } from "@ant-design/icons";
import { responseTypeQuery } from "@/filters/constant";
import { showError } from "@/helpers/showError";
interface IProps {
    setIsShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCustomerUser: CustomerUserModel;
}

const AddUserInvoicePaymentGroup: React.FC<IProps> = ({
    setIsShowModal,
    selectedCustomerUser,
}) => {
    const {
        getInvoicePaymentRules,
        addCustomerInvoiceRule,
        isAddCustomerLoading,
    } = useInvoicePaymentRule();
    const [{ data: invoicePaymentRoleCollectionData, isLoading }] = useAtom(
        invoicePaymentRuleCollectionAtom,
    );
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    const [invoicePaymentRules, setInvoicePaymentRules] = useState<
        InvoicePaymentRuleModel[]
    >([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loadingPrevious, setLoadingPrevious] = useState(false);
    const [hasMoreNext, setHasMoreNext] = useState(true);
    const [hasMorePrevious, setHasMorePrevious] = useState(false);
    const paginationData = invoicePaymentRoleCollectionData?.getPagination();

    useEffect(() => {
        form.resetFields();
    }, [selectedCustomerUser, form]);

    useEffect(() => {
        if (selectedCustomerUser) {
            fetchInvoicePaymentRules(1, "initial");
        }
    }, [selectedCustomerUser]);

    useEffect(() => {
        if (invoicePaymentRoleCollectionData) {
            setInvoicePaymentRules((pre) => [
                ...pre,
                ...invoicePaymentRoleCollectionData.getData(),
            ]);
        }
    }, [invoicePaymentRoleCollectionData]);

    const fetchInvoicePaymentRules = async (
        page: number,
        direction: "next" | "previous" | "initial",
    ) => {
        try {
            await ApiHelperModel.makeGetRequest(
                { page, ...responseTypeQuery.minimal },
                getInvoicePaymentRules,
            );

            const fetchedData =
                invoicePaymentRoleCollectionData?.getData() || [];
            if (fetchedData.length > 0 && paginationData) {
                if (direction === "next") {
                    setHasMoreNext(page < paginationData.last_page);
                } else if (direction === "previous") {
                    setHasMorePrevious(page > 1);
                } else {
                    setHasMoreNext(page < paginationData.last_page);
                    setHasMorePrevious(page > 1);
                }
                setCurrentPage(page);
            } else {
                if (direction === "next") {
                    setHasMoreNext(false);
                } else if (direction === "previous") {
                    setHasMorePrevious(false);
                }
            }
        } catch (error) {
            showError(error, form);
        }
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const top = e.currentTarget.scrollTop === 0;
        const bottom =
            e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
            e.currentTarget.clientHeight;

        if (top && !loadingPrevious && hasMorePrevious) {
            setLoadingPrevious(true);
            fetchInvoicePaymentRules(currentPage - 1, "previous").finally(() =>
                setLoadingPrevious(false),
            );
        }

        if (bottom && !loadingMore && hasMoreNext) {
            setLoadingMore(true);
            fetchInvoicePaymentRules(currentPage + 1, "next").finally(() =>
                setLoadingMore(false),
            );
        }
    };

    const onFinish = async (value: {
        invoice_payment_rule_group_id: number;
    }) => {
        const payload = {
            user_id: selectedCustomerUser.getId(),
        };
        try {
            await addCustomerInvoiceRule(
                value.invoice_payment_rule_group_id,
                payload,
            );
            setIsShowModal(false);
        } catch (error) {
            showError(error, form);
        }
    };

    const removeDuplicates = (
        data: InvoicePaymentRuleModel[],
    ): InvoicePaymentRuleModel[] => {
        const uniqueMap = new Map();
        data.forEach((item) => uniqueMap.set(item.getId(), item));
        return Array.from(uniqueMap.values());
    };

    const renderLabelWithTooltip = (rule: InvoicePaymentRuleModel) => {
        const content = (
            <div style={{ padding: "10px", width: "400" }}>
                <p style={{ margin: "10px 0", fontSize: "12px" }}>
                    <strong>Wallet Payment:</strong>
                    <span style={{ float: "right" }}>
                        {rule.getWalletPaymentPercentage()}%
                        {rule.isWalletEnabled() ? (
                            <Tag
                                color="green"
                                style={{ marginLeft: 8, fontSize: 9 }}
                            >
                                Active
                            </Tag>
                        ) : (
                            <Tag color="volcano" style={{ marginLeft: 8 }}>
                                Inactive
                            </Tag>
                        )}
                    </span>
                </p>
                <p style={{ margin: "10px 0", fontSize: "12px" }}>
                    <strong>Automatic Gateway Payment:</strong>
                    <span style={{ float: "right" }}>
                        {rule.getAutomaticGatewayPaymentPercentage()}%
                        {rule.isAutomaticGatewayEnabled() ? (
                            <Tag
                                color="green"
                                style={{ marginLeft: 8, fontSize: 9 }}
                            >
                                Active
                            </Tag>
                        ) : (
                            <Tag color="volcano" style={{ marginLeft: 8 }}>
                                Inactive
                            </Tag>
                        )}
                    </span>
                </p>
                <p style={{ margin: "10px 0", fontSize: "12px" }}>
                    <strong>Bank Deposit Payment:</strong>
                    <span style={{ float: "right" }}>
                        {rule.getBankDepositPaymentPercentage()}%
                        {rule.isBankDepositEnabled() ? (
                            <Tag
                                color="green"
                                style={{ marginLeft: 8, fontSize: 9 }}
                            >
                                Active
                            </Tag>
                        ) : (
                            <Tag color="volcano" style={{ marginLeft: 8 }}>
                                Inactive
                            </Tag>
                        )}
                    </span>
                </p>
                <p style={{ margin: "10px 0", fontSize: "12px" }}>
                    <strong>Mobile Merchant Payment:</strong>
                    <span style={{ float: "right" }}>
                        {rule.getMobileMerchantPaymentPercentage()}%
                        {rule.isMobileMerchantEnabled() ? (
                            <Tag
                                color="green"
                                style={{ marginLeft: 8, fontSize: 9 }}
                            >
                                Active
                            </Tag>
                        ) : (
                            <Tag color="volcano" style={{ marginLeft: 8 }}>
                                Inactive
                            </Tag>
                        )}
                    </span>
                </p>
            </div>
        );

        return (
            <>
                <span>{rule.getName()}</span>
                {rule.isDefault() && <Tag color="green">Default</Tag>}
                <Tooltip title={content} placement="right">
                    <InfoCircleOutlined
                        style={{ marginLeft: 8, color: "green" }}
                    />
                </Tooltip>
            </>
        );
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Add-User-Invoice-Payment-Group"
            ref={formRef}
        >
            <div style={{ margin: "36px 0px" }}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: "Exchange group is required",
                        },
                    ]}
                    name="invoice_payment_rule_group_id"
                    label="Invoice payment rule group"
                >
                    <Select
                        loading={isLoading}
                        placeholder="Please select an Invoice payment rule group"
                        onPopupScroll={handleScroll}
                        notFoundContent={
                            loadingMore || loadingPrevious ? (
                                <Spin size="small" />
                            ) : null
                        }
                        options={removeDuplicates(invoicePaymentRules).map(
                            (x) => ({
                                value: x.getId(),
                                label: renderLabelWithTooltip(x),
                            }),
                        )}
                    />
                </Form.Item>
            </div>

            <Form.Item>
                <Button
                    loading={isAddCustomerLoading}
                    disabled={isAddCustomerLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddUserInvoicePaymentGroup;
