import { showError } from "@/helpers/showError";
import { ArrowDownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { HTMLAttributeAnchorTarget } from "react";

interface DownloadPdfButtonProps {
    pdfUrl: string;
    fileName?: string;
    buttonLabel?: string;
    buttonStyle?: React.CSSProperties;
    fetchFromApi?: boolean;
    target?: HTMLAttributeAnchorTarget;
}

export const DownloadPdfButton: React.FC<DownloadPdfButtonProps> = ({
    pdfUrl,
    fileName = "download.pdf",
    buttonLabel = "",
    buttonStyle,
    fetchFromApi = false,
    target = "_blank",
}) => {
    const handleDownload = async () => {
        if (fetchFromApi && pdfUrl) {
            // Fetch from API
            try {
                const response = await fetch(pdfUrl);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.target = target;
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                showError(error);
            }
        } else if (pdfUrl) {
            // Direct URL
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.target = target;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <Button
            style={buttonStyle}
            onClick={handleDownload}
            shape="round"
            icon={<ArrowDownOutlined />}
        >
            {buttonLabel}
        </Button>
    );
};
