import { ITranslationEntry } from "../lang";

export const BS: ITranslationEntry = {
    translations: {
        notifications: "Obavještenja",
        markAllAsRead: "Označi sve kao pročitano",
        poweredBy: "Pokreće",
        settings: "Postavke",
        noNewNotification: "Ovdje još nema ništa novo za vidjeti",
    },
    lang: "bs",
};
