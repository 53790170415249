import { Checkbox } from "antd";
import { NodeInputProps } from "./helpers";

export function NodeInputCheckbox({
    node,
    attributes,
    setValue,
    disabled,
}: NodeInputProps) {
    // Render a checkbox.s

    return (
        <>
            {node.messages.map(({ text }) => {
                return (
                    <Checkbox
                        key={text as unknown as string}
                        name={attributes.name}
                        defaultChecked={attributes.value}
                        onChange={(e) => setValue(e.target.checked)}
                        disabled={attributes.disabled || disabled}
                        // label={getNodeLabel(node)}
                        // state={
                        //   node.messages.find(({ type }) => type === "error")
                        //     ? "error"
                        //     : undefined
                        // }
                        // subtitle={node.messages.map(({ text }) => text).join("\n")}
                    >
                        {text}
                    </Checkbox>
                );
            })}
        </>
    );
}
