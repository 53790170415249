import React, { useState } from "react";
import { Form, Input } from "antd";

interface IProps {
    maxLength?: number;
}

const DescriptionField = ({ maxLength = 300 }: IProps) => {
    const [description, setDescription] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length <= maxLength) {
            setDescription(e.target.value);
        }
    };

    return (
        <Form.Item
            name="description"
            label="Description"
            rules={[
                { required: true, message: "Please enter description" },
                {
                    validator: (_, value) =>
                        value?.length > maxLength
                            ? Promise.reject(
                                  new Error(
                                      `Description must be within ${maxLength} characters`,
                                  ),
                              )
                            : Promise.resolve(),
                },
            ]}
        >
            <div>
                <Input.TextArea
                    rows={4}
                    value={description}
                    onChange={handleChange}
                    maxLength={maxLength}
                    showCount={false}
                    placeholder="Please  write description"
                />
                <div style={{ textAlign: "left", marginTop: "4px" }}>
                    {`${description.length}/${maxLength}`}
                </div>
            </div>
        </Form.Item>
    );
};

export default DescriptionField;
