// Enum for admin permissions in the RFQ module
export enum ADMIN_RFQ_PERMISSION_ENUM {
    ADMIN_RFQ_VIEW = "admin-rfq-view",
    ADMIN_RFQ_MANAGE = "admin-rfq-manage",
    ADMIN_RFQ_ADD = "admin-rfq-add",
    ADMIN_RFQ_APPROVE = "admin-rfq-approve",
    ADMIN_RFQ_REJECT = "admin-rfq-reject",
}

export const manageRfqRoutePermissions = [
    ...Object.values(ADMIN_RFQ_PERMISSION_ENUM),
];
