import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal } from "@/filters/utils";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { ShippingCategoryCollectionModel } from "@/models/shippingCategory";

export const useGetShippingCategoryMinimal = () => {
    const { shippingCoreService } = useService();
    const [shippingCategoryOptionsData, setShippingCategoryOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getShippingCategory = async (params: string): Promise<void> => {
        try {
            setShippingCategoryOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await shippingCoreService.shippingCategoryResource.get(params);

            const shippingCategoryCollectionData =
                response &&
                new ShippingCategoryCollectionModel(
                    response,
                ).getShippingCategoryTreeIds();

            setShippingCategoryOptionsData({
                isLoading: false,
                options: shippingCategoryCollectionData || [],
            });
        } catch (_) {
            setShippingCategoryOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getShippingCategory, shippingCategoryOptionsData };
};
