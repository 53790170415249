import React from "react";
import { Button, notification, Space, Typography } from "antd";

import { IModalData } from "@/helpers/getModalTital";
import {
    useApproveBuyProduct,
    useGetBuyProduct,
} from "@/lib/core-react/hooks/private";
import { showError } from "@/helpers/showError";
import { useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { buyProductCollectionAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";

const { Text } = Typography;

interface IProps {
    productId?: number;
    handleModal: (payload: IModalData) => void;
}

const ApproveRfqProductModal: React.FC<IProps> = ({
    handleModal,
    productId,
}) => {
    const { approveBuyProduct, isLoading: isApproveLoading } =
        useApproveBuyProduct();
    const { getBuyProduct } = useGetBuyProduct();
    const { id } = useParams();
    const [, setBuyProductCollection] = useAtom(buyProductCollectionAtom);

    const handleSubmit = async () => {
        // As this is an RFQ product, we don't need to pass values for commission_rate and agent_company_id in the payload instead we will pass null values.
        const payload = {
            commission_rate: null,
            agent_company_id: null,
        };

        let message = "";

        try {
            if (productId) {
                await approveBuyProduct(productId, payload);
                message = "Assign successfully";
            }
            handleModal({
                data: null,
                action: false,
            });

            notification["success"]({
                message,
            });

            if (
                window.location.pathname ===
                `/purchase/buy-products/detail/${id}`
            ) {
                getBuyProduct(Number(id));
            } else {
                setBuyProductCollection((pre) => ({ ...pre, refetch: true }));
            }
        } catch (error: any) {
            showError(error);
        }
    };

    return (
        <>
            <Space
                direction="vertical"
                style={{ width: "100%", textAlign: "left", padding: "16px" }}
            >
                <Text strong style={{ fontSize: "16px" }}>
                    Approve RFQ Product
                </Text>
                <Text>Are you sure you want to approve this RFQ product?</Text>
                <Space
                    style={{ width: "100%", marginTop: "16px" }}
                    direction="horizontal"
                >
                    <Button
                        onClick={() =>
                            handleModal({ data: null, action: false })
                        }
                        style={{ width: "100px" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={isApproveLoading}
                        style={{ width: "150px" }}
                    >
                        Approve
                    </Button>
                </Space>
            </Space>
        </>
    );
};

export default ApproveRfqProductModal;
