import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import {
    IPurchaseCartExtended,
    IPurchaseCartItemVariation,
} from "@/types/PurchaseCartCollection";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Table, Tooltip } from "antd";
import { useState } from "react";

const VariationsColumn = ({
    variations,
    record,
}: {
    variations: IPurchaseCartItemVariation[];
    record: IPurchaseCartExtended;
}) => {
    const [expanded, setExpanded] = useState(false);
    const { currency } = record; // Get the currency from record

    const currencyformat = currency?.code ? currency.code : "";

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const displayedVariations = expanded ? variations : variations.slice(0, 2);

    const totalPrice = variations.reduce(
        (sum, variation) => sum + variation.total_price,
        0,
    );
    const totalQuantity = variations.reduce(
        (sum, variation) => sum + variation.quantity,
        0,
    );

    const columns = [
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            render: (value: string) => (
                <ImageWithPlaceholder
                    style={{ width: 50, height: 50 }}
                    src={value}
                />
            ),
        },
        {
            title: "Qty",
            dataIndex: "quantity",
            key: "quantity",
            render: (value: number) => value,
        },
        {
            title: (
                <Tooltip title="Unit Price">
                    <span>Unit Price</span>
                </Tooltip>
            ),
            key: "price",
            render: (_: unknown, record: IPurchaseCartItemVariation) => {
                const { unit_price } = record;
                return `${currencyformat} ${unit_price.toFixed(2)} `;
            },
        },
        {
            title: "Total Price",
            dataIndex: "total_price",
            key: "total_price",
            render: (value: number) => `${currencyformat} ${value.toFixed(2)} `,
        },
    ];

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Table
                dataSource={displayedVariations}
                pagination={false}
                rowKey="id"
                size="small"
                columns={columns}
                summary={() => (
                    <>
                        <div
                            style={{
                                marginTop: "8px",
                                height: "24px",
                            }}
                        >
                            {variations.length > 2 && (
                                <Button
                                    type="link"
                                    onClick={handleToggleExpand}
                                    style={{ padding: 0 }}
                                >
                                    {expanded ? (
                                        <>
                                            See Less <UpOutlined />
                                        </>
                                    ) : (
                                        <>
                                            See More <DownOutlined />
                                        </>
                                    )}
                                </Button>
                            )}
                        </div>
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={1}>
                                <strong>Total</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                                <strong>{totalQuantity}</strong>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1}></Table.Summary.Cell>
                            <Table.Summary.Cell index={2} colSpan={2}>
                                <strong>
                                    {currencyformat} {totalPrice.toFixed(2)}{" "}
                                </strong>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </>
                )}
            />
        </div>
    );
};

export default VariationsColumn;
