import { atom } from "jotai";
import { IDataAtom } from "@/types/store";

export function createDataAtom<T>() {
    return atom<IDataAtom<T>>({
        isLoading: false,
        data: undefined,
        error: null,
        refetch: false,
        code: null,
        unAuthorized: false,
    });
}
