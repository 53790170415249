import { SessionInvoiceModel } from "@/models/paymentSessionModel";
import { Flex, Typography } from "antd";

const ShippingSummeryProduct = ({
    data,
    index,
}: {
    data: SessionInvoiceModel;
    index: number;
}) => {
    const product = data.getProductDetails();

    return (
        <Flex vertical>
            {product.title && (
                <Typography.Text>
                    <strong>{index}.</strong> {product.title}
                </Typography.Text>
            )}
            {product.quantity ? (
                <Typography.Text>
                    {!product.title ? <strong>{index}.</strong> : ""} Quantity:{" "}
                    <strong>{product.quantity}</strong>
                </Typography.Text>
            ) : (
                ""
            )}
            {product.weight ? (
                <Typography.Text>
                    {!product.title && !product.quantity ? (
                        <strong>{index}.</strong>
                    ) : (
                        ""
                    )}{" "}
                    Weight:{" "}
                    <strong>{product.weight.toLocaleString()} kg</strong>
                </Typography.Text>
            ) : (
                ""
            )}
        </Flex>
    );
};

export default ShippingSummeryProduct;
