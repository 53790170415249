import { ITranslationEntry } from "../lang";

export const TH: ITranslationEntry = {
    translations: {
        notifications: "การแจ้งเตือน",
        markAllAsRead: "ทำเครื่องหมายทั้งหมดว่าอ่านแล้ว",
        poweredBy: "ขับเคลื่อนโดย",
        settings: "การตั้งค่า",
        noNewNotification: "ยังไม่มีอะไรใหม่ให้ดูที่นี่",
    },
    lang: "th",
};
