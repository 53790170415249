import { Col, Row, Typography, Skeleton } from "antd";
import {
    BuyActionCollectionModel,
    BuyActionModel,
} from "@/models/buyActionCollectionModel";
import ActionNeededIssuesType from "./ActionNeededIssuesType";
import { PageHeader } from "@ant-design/pro-layout";
import { useEffect, useState } from "react";
import { useTakeActionList } from "@/lib/core-react/hooks/private/useBuyAction";
import { buyActionCollectionAtom } from "@/lib/core-react/store/store";
import { useAtom } from "jotai";

interface IProps {
    handleClose?: () => void;
    selectedAction?: BuyActionModel;
    isLoading?: boolean;
    buyProductId?: number;
}

const ActionViewDetailsModal: React.FC<IProps> = ({
    selectedAction,
    buyProductId,
}) => {
    const { getAllTakeActions } = useTakeActionList();
    const [actions, setActions] = useState<BuyActionModel[]>(
        selectedAction ? [selectedAction] : [],
    );

    const { Text } = Typography;

    useEffect(() => {
        if (buyProductId) {
            const params = `buy_product_id=${buyProductId}`;
            getAllTakeActions(params);
        }
    }, [buyProductId]);

    const [{ data: buyActionCollectionData, isLoading }] = useAtom(
        buyActionCollectionAtom,
    );

    useEffect(() => {
        if (buyActionCollectionData && !selectedAction) {
            const buyActiondata = new BuyActionCollectionModel(
                buyActionCollectionData,
            );
            const actionModel = buyActiondata.data.map(
                (x) => new BuyActionModel(x),
            );
            setActions(actionModel);
        }
    }, [buyActionCollectionData, selectedAction]);

    return (
        <Row>
            <Col span={24}>
                <PageHeader ghost={false}>
                    {/* <Title level={3}>
                        Action Needed for #
                        {!isLoading && actions[0]?.getBuyProduct().getProductNumber()}
                    </Title> */}

                    <Col span={24} style={{ marginTop: 10 }}>
                        {isLoading
                            ? Array.from({ length: 8 }).map((_, index) => (
                                  <Skeleton
                                      key={index}
                                      active
                                      paragraph={{ rows: 1 }}
                                  />
                              ))
                            : actions.map((action) => (
                                  <div
                                      key={action.getId()}
                                      style={{
                                          marginTop: 10,
                                          marginBottom: 10,
                                      }}
                                  >
                                      <Col span={24}>
                                          {action.getSequences().map((item) => (
                                              <ActionNeededIssuesType
                                                  key={item.getId()}
                                                  data={item}
                                                  buyActionData={action}
                                              />
                                          ))}
                                      </Col>

                                      {action.getAdminNote() && (
                                          <Col
                                              style={{
                                                  marginTop: 30,
                                                  padding: "10px 15px",
                                                  borderRadius: 5,
                                                  backgroundColor: "#FFDBE1",
                                              }}
                                          >
                                              <Text strong>Admin Note:</Text>{" "}
                                              <Text>
                                                  {action.getAdminNote()}
                                              </Text>
                                          </Col>
                                      )}

                                      {action.getAgentNote() && (
                                          <Col
                                              style={{
                                                  marginTop: 30,
                                                  padding: "10px 15px",
                                                  borderRadius: 5,
                                                  backgroundColor: "#FFBDC7",
                                              }}
                                          >
                                              <Text strong>Agent Note:</Text>{" "}
                                              <Text>
                                                  {action.getAgentNote()}
                                              </Text>
                                          </Col>
                                      )}

                                      {action.getCustomerNote() && (
                                          <Col
                                              style={{
                                                  marginTop: 30,
                                                  padding: "10px 15px",
                                                  borderRadius: 5,
                                                  backgroundColor: "#E6FFE6",
                                              }}
                                          >
                                              <Text strong>Customer Note:</Text>{" "}
                                              <Text>
                                                  {action.getCustomerNote()}
                                              </Text>
                                          </Col>
                                      )}
                                  </div>
                              ))}
                    </Col>
                </PageHeader>
            </Col>
        </Row>
    );
};

export default ActionViewDetailsModal;
