import { BaseResource } from "../public";
import {
    IDefaultAssignAgentPayload,
    IDefaultWithPriceAssignAgentPayload,
    IOpenBidPayload,
    IShipmentProductAddPackagePayload,
    IShipmentProductAttachImagesPayload,
    IShipmentProductPackageCollection,
    RejectPayload,
} from "@/types/shipmentProductCollection";
import { ShippingModesEnums, ShippingTypeEnums } from "@/enums/shippingEnums";
import { TrackingTypesEnums } from "@/enums/shipForMeContextEnums";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { IQuery } from "../../request/types/request";
import { IShipmentProductBidCollection } from "@/types/shipmentProductBidCollection";
import {
    IBulkShippingRateUpdatePayload,
    IShippingRateUpdatePayload,
} from "@/pages/Shipping/ShipmentProducts/components/ShipmentProductChangeShippingRate.tsx";

class ShippingResource extends BaseResource {
    shipmentOrdersPath = `/api/shipping/admin/shipment-product/v1/shipment-orders`;
    shipmentProductsPath = `/api/shipping/admin/shipment-product/v1/shipment-products`;
    unlistedProductsPath = `/api/shipping/admin/unlisted-package/v1/unlisted-packages`;
    approvedShippingProductPath = `/api/shipping/admin/shipment-product/v1/shipment-products`;
    rejectShippingProductPath = `/api/shipping/admin/shipment-product/v1/shipment-products`;
    changeAgentRateShippingProductPath = `/api/shipping/admin/shipment-product/v1/shipment-products`;
    openBidPath = `/api/shipping/admin/bid/v1`;
    updateBidPath = `/api/shipping/admin/bid/v1/bids`;
    abandonBidPath = `/api/shipping/admin/bid/v1/bids`;
    winnerWarehousePath = `/api/shipping/admin/bid/v1/bids`;
    changeShippingCountryPath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-shipping-country`;
    changeDestinationWarehousePath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-destination-warehouse`;
    changeShippingCategoryPath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-shipping-category`;
    changeShippingModePath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-shipping-mode`;

    changeShippingTypePath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/change-shipping-type`;
    defaultAgentAssignPath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/assign-agent-default`;
    defaultWithPriceAgentAssignPath = `/api/shipping/admin/shipment-product/v1/shipment-products/bulk/assign-agent-with-price`;
    attachTrackingIdPath = `/api/shipping/admin/shipment-product/v1/shipment-products`;
    addProductImageToProductPath = `/api/shipping/admin/shipment-product/v1/shipment-products/attach-images`;
    getShipmentOrders(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentOrdersPath}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
    getShipmentProducts(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
    getUnlistedProducts(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.unlistedProductsPath}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
    getShipmentProduct(
        productId: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}/${productId}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
    approvedShipmentProduct(
        productId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.approvedShippingProductPath}/${productId}/approve`;
        return this.client.request("PUT", path, undefined, {}, customHeaders);
    }
    rejectShipmentProduct(
        payload: RejectPayload,
        productId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.rejectShippingProductPath}/${productId}/reject`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }
    changeAgentRateShipmentProduct(
        payload: IShippingRateUpdatePayload,
        productId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.changeAgentRateShippingProductPath}/${productId}/change-rate`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    bulkChangeAgentRateShipmentProduct(
        payload: IBulkShippingRateUpdatePayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.changeAgentRateShippingProductPath}/bulk/change-rate`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    agentDefaultAssign(
        payload: IDefaultAssignAgentPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "POST",
            `${this.defaultAgentAssignPath}`,
            payload,
            {},
            customHeaders,
        );
    }
    agentDefaultWithPriceAssign(
        payload: IDefaultWithPriceAssignAgentPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "POST",
            `${this.defaultWithPriceAgentAssignPath}`,
            payload,
            {},
            customHeaders,
        );
    }
    createBid(
        payload: IOpenBidPayload,
        productId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "POST",
            `${this.openBidPath}/${productId}/bids`,
            payload,
            {},
            customHeaders,
        );
    }
    updateBid(
        payload: IOpenBidPayload,
        bidId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.updateBidPath}/${bidId}/attach`,
            payload,
            {},
            customHeaders,
        );
    }
    abandonBid(
        bidId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.abandonBidPath}/${bidId}/abandon`,
            {},
            {},
            customHeaders,
        );
    }
    winnerWarehouse(
        bidId: number,
        payload: { agent_warehouse_id: number },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.winnerWarehousePath}/${bidId}/winner`,
            payload,
            {},
            customHeaders,
        );
    }
    getAllBids(
        productId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise<IShipmentProductBidCollection> {
        return this.client.request(
            "GET",
            `${this.openBidPath}/${productId}/bids`,
            {},
            {},
            customHeaders,
        );
    }
    // Update Shipping Country
    updateShippingCountry(
        payload: {
            shipment_product_ids: number[];
            shipping_country_id: number;
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.changeShippingCountryPath}`,
            payload,
            {},
            customHeaders,
        );
    }
    // Update Destination warehouse
    updateDestinationWarehouse(
        payload: {
            shipment_product_ids: number[];
            destination_warehouse_id: number;
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.changeDestinationWarehousePath}`,
            payload,
            {},
            customHeaders,
        );
    }
    // Update Shipping category
    updateShippingCategory(
        payload: {
            shipment_product_ids: number[];
            shipping_category_id: string;
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.changeShippingCategoryPath}`,
            payload,
            {},
            customHeaders,
        );
    }
    // Update Shipping mode
    updateShippingMode(
        payload: {
            shipment_product_ids: number[];
            shipping_mode: ShippingModesEnums;
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.changeShippingModePath}`,
            payload,
            {},
            customHeaders,
        );
    }
    // Update Shipping type
    updateShippingType(
        payload: {
            shipment_product_ids: number[];
            shipping_type: ShippingTypeEnums;
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.changeShippingTypePath}`,
            payload,
            {},
            customHeaders,
        );
    }
    // Add tracking id
    attachTrackingId(
        shipmentProductId: number,
        // payload: {
        //     tracking: string;
        //     package_id: number;
        // }[],
        payload: any,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.attachTrackingIdPath}/${shipmentProductId}/tracking`,
            payload,
            {},
            customHeaders,
        );
    }

    // Update tracking id
    updateTrackingId(
        shipmentProductId: number,
        trackingId: number,
        payload: {
            tracking: string;
            type: TrackingTypesEnums;
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.attachTrackingIdPath}/${shipmentProductId}/tracking/${trackingId}`,
            payload,
            {},
            customHeaders,
        );
    }
    // Add Image to product
    addProductImage(
        payload: { shipment_product_ids: number[]; images: string[] },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "POST",
            `${this.addProductImageToProductPath}`,
            payload,
            {},
            customHeaders,
        );
    }

    getPackages(
        productId: number,
        query: IQuery,
    ): ResponsePromise<IShipmentProductPackageCollection> {
        const path = `${this.shipmentProductsPath}/${productId}/packages${this.addQuery(query)}`;
        return this.client.request("GET", path, {}, {}, {});
    }
    addPackage(
        shipmentProductId: number,
        payload: IShipmentProductAddPackagePayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}/${shipmentProductId}/packages`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
    putUpdatePackage(
        shipmentProductId: number,
        packageId: number,
        payload: IShipmentProductAddPackagePayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}/${shipmentProductId}/packages/${packageId}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    deletePackage(
        shipmentProductId: number,
        packageId: number,
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}/${shipmentProductId}/packages/${packageId}`;
        return this.client.request("DELETE", path);
    }

    deleteProduct(
        shipmentProductId: number,
        packageNumber: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}/${shipmentProductId}/packages/${packageNumber}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }

    attachImage(
        payload: IShipmentProductAttachImagesPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}/bulk/attach-images`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    sync(
        payload: {
            ids: number[];
        },
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}/sync-to-elastic`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    shippingStart(
        shipmentProductId: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "PUT",
            `${this.shipmentProductsPath}/${shipmentProductId}/start-shipping`,
            {},
            customHeaders,
        );
    }
    forInvoice(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.shipmentProductsPath}/for-invoice${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, {}, customHeaders);
    }
}

export default ShippingResource;
