import { Form, Button, message, notification } from "antd";
import { useDeletePrice } from "@/lib/core-react/hooks/private/useAddonService";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import { AddonServicePricesModel } from "@/models/addonServiceCollectionModel";

interface IProps {
    setIsShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    isShowDeleteId: AddonServicePricesModel;
}

const DeletePrice = ({ setIsShowDeleteModal, isShowDeleteId }: IProps) => {
    const { deletePrice, isLoading } = useDeletePrice();

    const onConfirmDelete = async () => {
        try {
            await deletePrice(
                isShowDeleteId.getAddonServiceId(),
                isShowDeleteId.getId(),
            );
            setIsShowDeleteModal(false);
            message.success({
                content: "Price deleted successfully.",
                key: "deletePrice",
                duration: 2,
            });
        } catch (error) {
            notification.error({
                message: getError(error),
            });
        }
    };

    return (
        <>
            <Form layout="vertical">
                <Form.Item>
                    <h3>Are you sure you want to delete this Price?</h3>
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        loading={isLoading}
                        onClick={onConfirmDelete}
                    >
                        Yes
                    </Button>{" "}
                    <Button
                        disabled={isLoading}
                        onClick={() => setIsShowDeleteModal(false)}
                    >
                        No
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};

export default DeletePrice;
