import React, { ReactElement } from "react";
import { Empty, Result } from "antd";
import type { IAuthorityType } from "./CheckPermissions";
import checkActionPermission from "./CheckPermissions";
import type AuthorizedRoute from "./AuthorizedRoute";
import { useLocation } from "react-router-dom";
import { getAuthorityFromRouter } from "../../utils/helper";
import { RouteObject } from "react-router";

export interface RouteType {
    path?: string;
    index?: boolean;
    children?: RouteType[];
    caseSensitive?: boolean;
    id?: string;
    element?: React.ReactNode | null;
    hydrateFallbackElement?: React.ReactNode | null;
    errorElement?: React.ReactNode | null;
    Component?: React.ComponentType | null;
    HydrateFallback?: React.ComponentType | null;
    ErrorBoundary?: React.ComponentType | null;
    handle?: RouteObject["handle"];
}

type AuthorizedProps = {
    children: ReactElement;
    authority: IAuthorityType;
    noMatch?: React.ReactNode;
    routes: RouteType[];
};

type IAuthorizedType = React.FunctionComponent<AuthorizedProps> & {
    checkActionPermission: typeof checkActionPermission;
    AuthorizedRoute: typeof AuthorizedRoute;
};

const Authorized: React.FunctionComponent<AuthorizedProps> = ({
    routes,
    children,
    authority,
    noMatch = (
        <Result
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
        />
    ),
}) => {
    const childrenRender: React.ReactNode =
        typeof children === "undefined" ? null : children;
    const dom = checkActionPermission(authority, childrenRender, noMatch);
    const location = useLocation();
    const authorized =
        getAuthorityFromRouter(routes, location.pathname || "/") || null;

    return <>{authorized ? dom : <Empty />}</>;
};

export default Authorized as IAuthorizedType;
