import { useEffect, useMemo, useState } from "react";

import {
    ProductDetailExtendedModel,
    SkuModelExtended,
} from "@/models/productDetailExtendedModel";
import {
    IProductVariationExtended,
    IPropertyAssociationExtended,
} from "@/types/productDetailExtended";
import { FirstProperties, LastProperty } from "./components";
import { Col, Row } from "antd";
import { BuyOrderOperationEnum } from "@/enums/buyOrderCollectionEnums";
import { InventoryProductVariationTable } from "./InventoryProductVariationTable";
import { ZeroVariant } from "./components/ZeroVariant";

export interface IPropertyValue {
    propertyId: string;
    propertyValueId: string;
}

export interface IPropertyValueExtended {
    propertyId: string;
    propertyValueId: string;
    propertyName: string;
}

export interface IPropertyValueMap {
    [propertyIdValue: string]: SkuModelExtended;
}

export function mapSkusByProperties(
    variations: IProductVariationExtended,
): IPropertyValueMap {
    const skus = variations.skus.data;
    const properties = variations.properties.data;

    const mappedItems: IPropertyValueMap = {};

    skus.forEach((sku) => {
        const propertyAssociations = sku.property_associations.data;
        const key = getPropertyKey(propertyAssociations);
        const processedPropertyAssociationsData: IPropertyAssociationExtended[] =
            [];
        propertyAssociations.forEach((item) => {
            const foundProperty = properties.find((prop) => {
                return prop.id === item.property_id;
            });
            const object = item.object;
            const id = item.id;
            const property_id = foundProperty?.id || "";
            const property_name = foundProperty?.name;
            const foundPropertyValue = foundProperty?.values.data.find(
                (value) => value.id === item.property_value_id,
            );
            const property_value_id = foundPropertyValue?.id || "";
            const property_value_name = foundPropertyValue?.name;
            const property_value_title = foundPropertyValue?.title;
            const property_value_color = foundPropertyValue?.color;
            const property_value_image = foundPropertyValue?.image;
            const property_value_thumb = foundPropertyValue?.thumb;
            processedPropertyAssociationsData.push({
                object,
                id,
                property_id,
                property_name,
                property_value_id,
                property_value_name,
                property_value_title,
                property_value_color,
                property_value_image,
                property_value_thumb,
            });
        });

        sku.property_associations.data = processedPropertyAssociationsData;

        const itemModel = new SkuModelExtended(sku);
        mappedItems[key] = itemModel;
    });

    return mappedItems;
}

function getPropertyKey(
    propertyAssociations: IPropertyAssociationExtended[],
): string {
    return propertyAssociations
        .map(
            (association) =>
                `${association.property_id}-${association.property_value_id}`,
        )
        .join(";");
}

interface IProps {
    product: ProductDetailExtendedModel;
}

const ProductVariations = ({ product }: IProps) => {
    const variations = product.getVariation();
    const operation = new URLSearchParams(location.search).get("operation");
    const [selectedVariants, setSelectedVariants] =
        useState<IPropertyValue[]>();
    const mappedSkuProperties = useMemo(
        () => mapSkusByProperties(variations),
        [variations.getSkus().getData()],
    );
    useEffect(() => {
        // Function to select the first item from each property
        const selectFirstItemFromEachProperty = () => {
            const selectedVariantsCopy: IPropertyValue[] = [];
            variations
                .getProperties()
                .getData()
                .forEach((prop, index) => {
                    // ignore last property
                    if (
                        index <
                        variations.getProperties().getData().length - 1
                    ) {
                        if (
                            prop.getValues() &&
                            prop.getValues().getData().length > 0
                        ) {
                            const firstValue = prop.getValues().getData()[0];
                            selectedVariantsCopy.push({
                                propertyId: prop.getId(),
                                propertyValueId: firstValue.getId(),
                            });
                        }
                    }
                });
            setSelectedVariants(selectedVariantsCopy);
        };

        // Call the function to select the first item from each property
        selectFirstItemFromEachProperty();
    }, [variations]);

    if (operation === BuyOrderOperationEnum.AddBuyOrderProductVariant) {
        return (
            <InventoryProductVariationTable
                mappedSkuProperties={mappedSkuProperties}
            />
        );
    }

    if (product.getVariation().getSkus().getData().length === 1) {
        return <ZeroVariant product={product} />;
    }

    return (
        <Row gutter={[16, 16]}>
            {variations
                .getProperties()
                .getData()
                .map((prop, index) => {
                    if (
                        index <
                            variations.getProperties().getData().length - 1 &&
                        Array.isArray(selectedVariants)
                    ) {
                        return (
                            <Col sm={24} lg={18} key={prop.getId()}>
                                <FirstProperties
                                    selectedVariants={selectedVariants}
                                    setSelectedVariants={setSelectedVariants}
                                    property={prop}
                                />
                            </Col>
                        );
                    } else if (Array.isArray(selectedVariants)) {
                        return (
                            <Col sm={24} lg={24} key={prop.getId()}>
                                <LastProperty
                                    selectedVariants={selectedVariants}
                                    property={prop}
                                    mappedSkuProperties={mappedSkuProperties}
                                />
                            </Col>
                        );
                    } else {
                        return <></>;
                    }
                })}
        </Row>
    );
};
export default ProductVariations;
