import { useService } from "@/lib/core-react/contexts";
import { invoicePaymentRuleCollectionAtom } from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import { useAtom } from "jotai";
import { Modal, notification } from "antd";
import { InvoicePaymentRuleResponseModel } from "@/models/invoicePaymentRoleModel";
import { IInvoicePaymentRuleBaseData } from "@/types/InvoiceRoleCollection";
import { useState } from "react";
const { confirm } = Modal;

interface UseInvoiceRule {
    getInvoicePaymentRules: (params?: string) => Promise<void>;
    createPaymentRules: (payload: IInvoicePaymentRuleBaseData) => Promise<void>;
    isCreateLoading: boolean;
    isUpdateLoading: boolean;
    updatePaymentRules: (
        id: number,
        payload: IInvoicePaymentRuleBaseData,
    ) => Promise<void>;
    deleteInvoicePaymentRules: (id: number) => Promise<void>;
    addCustomerInvoiceRule: (
        invoiceRuleGroupId: number,
        payload: { user_id: number },
    ) => Promise<void>;
    isAddCustomerLoading: boolean;
}

export const useInvoicePaymentRule = (): UseInvoiceRule => {
    const [, setInvoicePaymentCollection] = useAtom(
        invoicePaymentRuleCollectionAtom,
    );
    const [isCreateLoading, setIsCreateLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const [isAddCustomerLoading, setIsAddCustomerLoading] = useState(false);

    const { invoicePaymentService } = useService();

    const getInvoicePaymentRules = async (params?: string): Promise<void> => {
        setInvoicePaymentCollection((prev) => ({
            ...prev,
            isLoading: true,
            error: null,
        }));
        try {
            const response =
                await invoicePaymentService.invoiceRuleResource.getInvoicePaymentRules(
                    params,
                );
            if (response) {
                const responseModel = new InvoicePaymentRuleResponseModel(
                    response,
                );
                setInvoicePaymentCollection((prev) => ({
                    ...prev,
                    isLoading: false,
                    data: responseModel,
                    error: null,
                }));
            }
        } catch (error: any) {
            setInvoicePaymentCollection((prev) => ({
                ...prev,
                isLoading: false,
                error: getError(error),
                unAuthorized: error.response.status === 403,
                code: error.response.status,
            }));
        }
    };

    // Create

    const createPaymentRules = async (
        payload: IInvoicePaymentRuleBaseData,
    ): Promise<void> => {
        setInvoicePaymentCollection((pre) => ({ ...pre, refetch: false }));
        setIsCreateLoading(true);
        try {
            const response =
                await invoicePaymentService.invoiceRuleResource.create(payload);
            if (response) {
                setInvoicePaymentCollection((pre) => ({
                    ...pre,
                    refetch: true,
                }));
            }
            notification["success"]({
                message: "Create successfully",
            });
            setIsCreateLoading(false);
        } catch (error: any) {
            setIsCreateLoading(false);
            throw error;
        }
    };

    const updatePaymentRules = async (
        id: number,
        payload: IInvoicePaymentRuleBaseData,
    ): Promise<void> => {
        setInvoicePaymentCollection((pre) => ({ ...pre, refetch: false }));
        setIsUpdateLoading(true);
        try {
            const response =
                await invoicePaymentService.invoiceRuleResource.update(
                    id,
                    payload,
                );

            if (response) {
                setInvoicePaymentCollection((pre) => ({
                    ...pre,
                    refetch: true,
                }));
            }
            notification["success"]({
                message: "Update successfully",
            });
            setIsUpdateLoading(false);
        } catch (error: any) {
            setIsUpdateLoading(false);
            notification["error"]({
                message: getError(error),
            });
            throw error;
        }
    };

    const deleteInvoicePaymentRules = async (id: number) => {
        setInvoicePaymentCollection((prev) => ({ ...prev, refetch: false }));

        confirm({
            title: "Do you want to Delete?",
            content: "Select Ok, otherwise the process cannot success.",
            async onOk() {
                try {
                    await invoicePaymentService.invoiceRuleResource.delete(id);
                    notification["success"]({
                        message: "Delete successfully",
                    });
                    setInvoicePaymentCollection((prev) => ({
                        ...prev,
                        refetch: true,
                    }));
                } catch (error) {
                    notification["error"]({
                        message: getError(error),
                    });
                    return;
                }
            },
            onCancel() {},
        });
    };
    const addCustomerInvoiceRule = async (
        invoiceRuleGroupId: number,
        payload: { user_id: number },
    ): Promise<void> => {
        setIsAddCustomerLoading(true);
        try {
            const res =
                await invoicePaymentService.invoiceRuleResource.attachCustomer(
                    invoiceRuleGroupId,
                    payload,
                );
            notification["success"]({
                message: res.message,
            });
            setIsAddCustomerLoading(false);
        } catch (error: any) {
            setIsAddCustomerLoading(false);
            throw error;
        }
    };

    return {
        isAddCustomerLoading,
        getInvoicePaymentRules,
        createPaymentRules,
        addCustomerInvoiceRule,
        deleteInvoicePaymentRules,
        isCreateLoading,
        isUpdateLoading,
        updatePaymentRules,
    } as const;
};
