import { ITranslationEntry } from "../lang";

export const ZH: ITranslationEntry = {
    translations: {
        notifications: "通知",
        markAllAsRead: "将所有标记为已读",
        poweredBy: "技术支持",
        settings: "设置",
        noNewNotification: "这里还没有什么新东西可看",
    },
    lang: "zh",
};
