import { ITranslationEntry } from "../lang";

export const SD: ITranslationEntry = {
    translations: {
        notifications: "نوٽيفڪيشن",
        markAllAsRead: "سڀني کي پڙهيل طور نشان لڳايو",
        poweredBy: "پاران تيار ڪيل",
        settings: "سيٽنگون",
        noNewNotification: "هتي ڏسڻ لاءِ اڃا ڪجهه نئون ناهي",
    },
    lang: "sd",
};
