import { ITranslationEntry } from "../lang";

export const SV: ITranslationEntry = {
    translations: {
        notifications: "Meddelanden",
        markAllAsRead: "Markera alla som lästa",
        poweredBy: "Använder",
        settings: "Inställningar",
        removeMessage: "Ta bort meddelande",
        markAsRead: "Markera som läst",
        markAsUnRead: "Markera som oläst",
        noNewNotification: "Inget nytt att se här ännu",
    },
    lang: "sv",
};
