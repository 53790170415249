import {
    DownOutlined,
    EditOutlined,
    FileAddOutlined,
    HddFilled,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Dropdown, Input, Modal, Row, Table, Tabs } from "antd";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import { useGetShippingCategory } from "@/lib/core-react/hooks/private";
import { shippingCategoryAtom } from "@/lib/core-react/store/store";
import { PaginationModel } from "@/models/pagination";
import {
    ShippingCategoryModel,
    ShippingCategoryCollectionModel,
} from "@/models/shippingCategory";
import { useState, useEffect } from "react";
import CreateShippingCategory from "./components/CreateShippingCategory";
import UpdateShippingCategory from "./components/UpdateShippingCategory";
import AddChildren from "./components/AddChildren";
import { ApiHelperModel } from "@/models/apiHelper";
import { StatusTag } from "../../../components";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_SHIPPING_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import {
    useDeleteShippingCategory,
    useSyncShippingCategoryResource,
} from "../../../lib/core-react/hooks/private/useShippingCategory";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { checkNestedChildrenAndMakeItNull } from "@/utils/helper";
import CommonError from "@/components/Error/CommonError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import useRefetch from "@/hooks/useRefetch";
import { showError } from "@/helpers/showError";
const { Column } = Table;
const { confirm } = Modal;

const ShippingCategory = () => {
    const {
        allOptionsData: { regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { getShippingCategory } = useGetShippingCategory();
    const { syncShippingCategory, isLoading: isLoadingSync } =
        useSyncShippingCategoryResource();
    const { isMobile } = useWindowWidth();
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [filteredData, setFilteredData] = useState<ShippingCategoryModel[]>(
        [],
    );
    const { deleteShippingCategory } = useDeleteShippingCategory();

    const { refetchListApi } = useRefetch();
    const [
        {
            data: shippingCategoryStoreData,
            isLoading,
            refetch,
            error,
            code,
            unAuthorized,
        },
    ] = useAtom(shippingCategoryAtom);
    const [selectedRegion, setSelectedRegion] = useState<string | undefined>(
        undefined,
    );
    // const [refetchNow, setRefetchNow] = useState(false);

    const { filters, handleFilterChange, isFirstCall, isFetched } =
        useDataFilters();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
    }, []);

    useEffect(() => {
        if (regionOptionsData.options.length > 0) {
            const firstRegionId = regionOptionsData.options[0].value.toString();
            setSelectedRegion(firstRegionId);
            handleFilterChange({ region_id: firstRegionId });
        }
    }, [regionOptionsData]);

    useEffect(() => {
        if (selectedRegion) {
            ApiHelperModel.makeGetRequest(
                {
                    ...filters,
                    region_id: selectedRegion,
                },
                getShippingCategory,
            );
        }
        // refetchNow
    }, [selectedRegion]);
    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            if (filters && selectedRegion) {
                ApiHelperModel.makeGetRequest(filters, getShippingCategory);
            }
        }
    }, [isFirstCall, filters, isFetched, refetch]);

    //  Shipping category
    const ShippingCategoryData =
        shippingCategoryStoreData &&
        new ShippingCategoryCollectionModel(shippingCategoryStoreData);

    // Add Child state
    const [isShowAddChildModal, setIsShowAddChildModal] =
        useState<boolean>(false);
    const [addChildPayload, setAddChildPayload] = useState<
        ShippingCategoryModel | undefined
    >(undefined);

    const showAddChildModal = (record: ShippingCategoryModel) => {
        setAddChildPayload(record);
        setIsShowAddChildModal(true);
    };

    // Create state
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

    // Update modal state
    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
    const [updatePayload, setUpdatePayload] = useState<
        ShippingCategoryModel | undefined
    >(undefined);

    // Show update modal with data
    const showUpdateModal = (record: ShippingCategoryModel) => {
        setUpdatePayload(record);
        setIsShowUpdateModal(true);
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getShippingCategory,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        shippingCategoryStoreData,
        handlePaginationChange,
    );

    useEffect(() => {
        if (ShippingCategoryData) {
            if (searchTerm) {
                const filtered = ShippingCategoryData.search(searchTerm);
                setFilteredData(filtered);
            } else {
                setFilteredData(ShippingCategoryData.data);
            }
        }
    }, [searchTerm, shippingCategoryStoreData]);

    const _showDeleteConfirm = (id: string) => {
        confirm({
            title: "Are you sure you want to delete this category?",
            icon: <ExclamationCircleOutlined />,
            content: "Select Ok, otherwise the process cannot success.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: async () => {
                await deleteShippingCategory(id);
            },
        });
    };

    const handleSync = async () => {
        try {
            await syncShippingCategory();
            showSuccessAlert("Shipping Categories synced successfully");
            await refetchListApi();
        } catch (error) {
            showError(error);
        }
    };

    const onChangeRegion = (key: string) => {
        setSelectedRegion(key);
        handleFilterChange({ region_id: key });
    };

    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    // Shipping Category-> Regional catagories
    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Regional Categories"
                    style={{ marginTop: "10px" }}
                    onBack={() => window.history.back()}
                    extra={[
                        <>
                            <Button
                                style={{ padding: 0 }}
                                type="link"
                                htmlType="button"
                                icon={<SyncOutlined spin={isLoadingSync} />}
                                onClick={() => handleSync()}
                            >
                                Sync
                            </Button>
                            {checkActionPermission(
                                ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_MANAGE,
                                <Button
                                    key="1"
                                    icon={<PlusOutlined />}
                                    type="primary"
                                    onClick={() => setIsShowCreateModal(true)}
                                >
                                    Create regional category
                                </Button>,

                                null,
                            )}
                        </>,
                    ]}
                >
                    <div>
                        <Tabs
                            type="card"
                            size="large"
                            activeKey={selectedRegion}
                            tabPosition="top"
                            animated
                            centered
                            onChange={onChangeRegion}
                            items={regionOptionsData.options.map((region) => {
                                return {
                                    label: region.label,
                                    key: region.value.toString(),
                                    children: (
                                        <>
                                            <div>
                                                <Row>
                                                    <Col
                                                        xs={24}
                                                        sm={24}
                                                        md={5}
                                                        lg={5}
                                                    >
                                                        <Input.Search
                                                            placeholder="Search by name or slug"
                                                            value={searchTerm}
                                                            onChange={(e) =>
                                                                setSearchTerm(
                                                                    e.target
                                                                        .value,
                                                                )
                                                            }
                                                            onSearch={(value) =>
                                                                setSearchTerm(
                                                                    value,
                                                                )
                                                            }
                                                            allowClear
                                                        />
                                                    </Col>
                                                </Row>
                                                <Table
                                                    bordered
                                                    style={{
                                                        marginTop: "15px",
                                                    }}
                                                    loading={isLoading}
                                                    rowKey={(r) => r.getId()}
                                                    pagination={
                                                        paginationConfig
                                                    }
                                                    dataSource={
                                                        checkNestedChildrenAndMakeItNull(
                                                            filteredData,
                                                        ) as ShippingCategoryModel[]
                                                    }
                                                    scroll={{ x: 1000 }}
                                                >
                                                    <Column
                                                        title="Name"
                                                        dataIndex="name"
                                                        key="name"
                                                    />

                                                    <Column
                                                        title="Slug"
                                                        dataIndex="slug"
                                                        key="slug"
                                                    />

                                                    <Column
                                                        title="Status"
                                                        dataIndex="status"
                                                        key="status"
                                                        render={(
                                                            _,
                                                            record: ShippingCategoryModel,
                                                        ) => (
                                                            <StatusTag
                                                                text={
                                                                    record.status
                                                                }
                                                                slug={
                                                                    record.status
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    <Column
                                                        title="Action"
                                                        key="action"
                                                        fixed={
                                                            isMobile
                                                                ? "right"
                                                                : undefined
                                                        }
                                                        align="center"
                                                        width={100}
                                                        hidden={
                                                            !checkActionPermission(
                                                                ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_MANAGE,
                                                                "checkPermission",
                                                                null,
                                                            )
                                                        }
                                                        render={(
                                                            _,
                                                            record: ShippingCategoryModel,
                                                        ) => {
                                                            const items: ExtendedMenuItemType[] =
                                                                [
                                                                    {
                                                                        permission:
                                                                            ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_MANAGE,
                                                                        key: "1",
                                                                        label: (
                                                                            <div
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    showUpdateModal(
                                                                                        record,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <EditOutlined />{" "}
                                                                                <span>
                                                                                    {" "}
                                                                                    Update
                                                                                </span>
                                                                            </div>
                                                                        ),
                                                                    },
                                                                    {
                                                                        permission:
                                                                            ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_MANAGE,
                                                                        key: "2",
                                                                        label: (
                                                                            <div
                                                                                style={{
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() =>
                                                                                    showAddChildModal(
                                                                                        record,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <FileAddOutlined />{" "}
                                                                                <span>
                                                                                    {" "}
                                                                                    Add
                                                                                    Child
                                                                                </span>
                                                                            </div>
                                                                        ),
                                                                    },
                                                                ];

                                                            return (
                                                                <Dropdown
                                                                    menu={{
                                                                        items: items.filter(
                                                                            (
                                                                                x,
                                                                            ) =>
                                                                                checkActionPermission(
                                                                                    x.permission,
                                                                                    x,
                                                                                    null,
                                                                                ),
                                                                        ),
                                                                    }}
                                                                >
                                                                    <Button
                                                                        icon={
                                                                            <HddFilled />
                                                                        }
                                                                    >
                                                                        {isMobile ? (
                                                                            <DownOutlined />
                                                                        ) : (
                                                                            <>
                                                                                Actions{" "}
                                                                                <DownOutlined />{" "}
                                                                            </>
                                                                        )}
                                                                    </Button>
                                                                </Dropdown>
                                                            );
                                                        }}
                                                    />
                                                </Table>
                                            </div>
                                        </>
                                    ),
                                };
                            })}
                        />
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={`Create regional  category`}
                open={isShowCreateModal}
                footer={false}
                onCancel={() => setIsShowCreateModal(false)}
            >
                <CreateShippingCategory
                    setIsShowCreateModal={setIsShowCreateModal}
                />
            </Modal>

            <Modal
                title={`Update regional category`}
                open={isShowUpdateModal}
                footer={false}
                onCancel={() => setIsShowUpdateModal(false)}
            >
                <UpdateShippingCategory
                    setIsShowUpdateModal={setIsShowUpdateModal}
                    data={updatePayload as ShippingCategoryModel}
                />
            </Modal>

            <Modal
                title={`Add Children`}
                open={isShowAddChildModal}
                footer={false}
                onCancel={() => setIsShowAddChildModal(false)}
            >
                <AddChildren
                    setIsShowAddChildModal={setIsShowAddChildModal}
                    data={addChildPayload as ShippingCategoryModel}
                />
            </Modal>
        </>
    );
};

export default ShippingCategory;
