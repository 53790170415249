import { ITranslationEntry } from "../lang";

export const NB: ITranslationEntry = {
    translations: {
        notifications: "Varsler",
        markAllAsRead: "Marker alle som lest",
        poweredBy: "Drevet av",
        settings: "Innstillinger",
        removeMessage: "Fjern melding",
        markAsRead: "Merk som lest",
        markAsUnRead: "Merk som ulest",
        noNewNotification: "Ikke noe nytt å se her ennå",
    },
    lang: "nb",
};
