import { BrowserRouter } from "react-router-dom";
import AppProviders from "./context";
import { ServiceProvider } from "./lib/core-react";
import rootStore from "./lib/core-react/store/store";
import { Provider as JotaiProvider } from "jotai";
import AllRoutes from "./routes/Routes";
import PermissionHandler from "./components/PermissionHandler";

const App = () => {
    return (
        <>
            <BrowserRouter>
                <ServiceProvider>
                    <JotaiProvider store={rootStore}>
                        <PermissionHandler />
                        <AppProviders>
                            <AllRoutes />
                        </AppProviders>
                    </JotaiProvider>
                </ServiceProvider>
            </BrowserRouter>
        </>
    );
};

export default App;
