import { Alert, Col, Row, Skeleton } from "antd";
import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import { useWalletTransactions } from "@/lib/core-react/hooks/private/useWallet";
import { TransactionCollectionAtom } from "@/lib/core-react/store/store";
import { TransactionCollectionModel } from "@/models/walletTransactionCollectionModel";
import WalletMasterTable from "@/pages/WalletManage/WalletMasterTable";
import { useEffect } from "react";
import { IFilterType } from "@/types/filters";
import CommonError from "@/components/Error/CommonError";

interface IWalletTableData {
    isLoading: boolean;
    walletId: number;
    currencyCode?: string; // Added currencyCode as a prop
}

const WalletTable = ({
    isLoading,
    walletId,
    currencyCode,
}: IWalletTableData) => {
    const [
        {
            data: TransactionCollectionData,
            error: isError,
            isLoading: transactionLoading,
            unAuthorized,
            error,
            code,
        },
    ] = useAtom(TransactionCollectionAtom);

    const { getWalletTransctions } = useWalletTransactions();

    const {
        filters,
        handleFilterChange,
        isFetched,
        handelFilterClear,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    useEffect(() => {
        if (walletId || refetchFromFilter || currencyCode) {
            getWalletTransctions(walletId);
        }
    }, [walletId, refetchFromFilter, currencyCode]);

    const transactionsCollectionData =
        TransactionCollectionData &&
        new TransactionCollectionModel(TransactionCollectionData);

    const filterData = transactionsCollectionData?.getFilters();

    useEffect(() => {
        if (!isFetched && transactionsCollectionData?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        transactionsCollectionData?.getFilters(),
    ]);

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const walletIdValue = walletId;
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        getWalletTransctions(walletIdValue, pageInfo);
    };

    const handleProductFilter = () =>
        filters && getWalletTransctions(walletId, filters);

    if (unAuthorized || error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            {filters && Object.keys(filters).length > 0 && (
                <Row>
                    <Col span={24}>
                        <FiltersComponent
                            handleProductFilter={handleProductFilter}
                            handleFilterChange={handleFilterChange}
                            handelFilterClear={handelFilterClear}
                            isFetched={isFetched}
                            filters={filters}
                            filtersData={filterData}
                            isFromProductReceived={true}
                        />
                    </Col>
                </Row>
            )}
            <div style={{ marginTop: 10 }}>
                {isLoading ? (
                    <Skeleton active />
                ) : isError ? (
                    <Alert message={isError} type="error" />
                ) : transactionsCollectionData ? (
                    <WalletMasterTable
                        isLoading={transactionLoading}
                        transactionsCollectionData={transactionsCollectionData}
                        handlePaginationChange={handlePaginationChange}
                        currencyCode={currencyCode || ""}
                    />
                ) : (
                    <Skeleton />
                )}
            </div>
        </>
    );
};

export default WalletTable;
