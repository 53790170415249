import { ITranslationEntry } from "../lang";

export const SA: ITranslationEntry = {
    translations: {
        notifications: "अधिसूचनाः",
        markAllAsRead: "सर्वं पठितम् इति सूचनीयम्",
        poweredBy: "द्वारा संचालितः",
        settings: "संस्थापनम्",
        noNewNotification: "अत्र अद्यापि किमपि नूतनं द्रष्टुं न शक्यते",
    },
    lang: "sa",
};
