import { PaymentSessionModel } from "@/models/paymentSessionModel";
import ShippingSummeryProduct from "./ShippingSummaryProduct";
import { Divider, Flex, Typography } from "antd";
import { RegionModel } from "@/models/regionCollectionModel";
import { BuyProductModel } from "@/models/buyProductCollectionModel";

interface IProps {
    sessionData: PaymentSessionModel;
    region: RegionModel;
    buyProduct: BuyProductModel[];
    isCheckShipping?: boolean;
}

const ShippingSummary = ({
    sessionData,
    region,
    buyProduct,
    isCheckShipping,
}: IProps) => {
    const items = sessionData.getSessionInvoices().getData();

    const buyingPrice = buyProduct
        .map((product) => product?.getTotalRegionalPriceCalculated() || 0)
        .reduce((total, price) => total + price, 0);

    const shippingPrice = buyProduct
        .map(
            (product) =>
                (product?.getCustomerInvoice()?.getTotalAmount() || 0) -
                (product?.getTotalRegionalPriceCalculated() || 0),
        )
        .reduce((total, price) => total + price, 0);

    const quantity = buyProduct
        .map((product) => product?.getTotalQuantityCalculated() || 0)
        .reduce((total, quantities) => total + quantities, 0);

    const totalDue = buyProduct
        .map((product) => product?.getDue() || 0)
        .reduce((total, due) => total + due, 0);

    return (
        <Flex vertical gap={6}>
            <Typography.Text strong>Summary</Typography.Text>
            {
                <Flex vertical gap={2}>
                    {!isCheckShipping && (
                        <>
                            <div>
                                Total Quantity:{" "}
                                <Typography.Text strong>
                                    {quantity}
                                </Typography.Text>
                            </div>
                            <div>
                                Buying Price:{" "}
                                <Typography.Text strong>
                                    {region.getCurrency()?.getSymbolNative()}
                                    {buyingPrice.toFixed(2)}
                                </Typography.Text>
                            </div>
                        </>
                    )}

                    <div>
                        Shipping Price:{" "}
                        <Typography.Text strong>
                            {region.getCurrency()?.getSymbolNative()}
                            {shippingPrice.toFixed(2)}
                        </Typography.Text>
                    </div>
                    <div>
                        Total Due:{" "}
                        <Typography.Text strong>
                            {region.getCurrency()?.getSymbolNative()}
                            {totalDue.toFixed(2)}
                        </Typography.Text>
                    </div>
                </Flex>
            }

            <Divider />
            <Flex
                vertical
                style={{ maxHeight: "384px", overflowY: "auto" }}
                gap={6}
            >
                {items.map((item, index) => {
                    return (
                        <ShippingSummeryProduct
                            data={item.getInvoice()}
                            index={index + 1}
                            key={index}
                        />
                    );
                })}
            </Flex>
        </Flex>
    );
};

export default ShippingSummary;
