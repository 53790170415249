import { useState } from "react";
import { useAtom } from "jotai";

import { getError } from "../utils/errors";
import { useService } from "@/lib/core-react/contexts";
import {
    campaignCollectionAtom,
    campaignProductCollectionAtom,
} from "@/lib/core-react/store/store";
import {
    IAddCampaignProductsPostRequest,
    ICreateCampaignPayload,
    IDeleteCampaignProducts,
} from "@/types/campaignCollection";
import { IAddCampaignProductRawPayload } from "@/types/campaignPayload";
import { CampaignProductCollectionModel } from "@/models/campaignProductCollectionModel";
import { getErrorCode } from "@/helpers/getErrorCode";
import { getErrorMessage } from "@/helpers/getErrorMessages";

interface ErrorProps {
    code: number;
    message: string;
}
export const useCampaignList = () => {
    const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

    const { campaignService } = useService();

    const getCampaign = async (params?: string): Promise<void> => {
        setCampaign({ ...campaign, isLoading: true, error: null });
        try {
            const response =
                await campaignService.campaignResource.getCampaign(params);
            setCampaign({
                ...campaign,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setCampaign({
                ...campaign,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };

    return { getCampaign } as const;
};

export const useCreateCampaign = () => {
    const { campaignService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);

    const createCampaign = async (payload: ICreateCampaignPayload) => {
        setIsLoading(true);
        try {
            const response =
                await campaignService.campaignResource.createCampaign(payload);
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createCampaign, isLoading, isError };
};

export const useUpdateCampaign = () => {
    const { campaignService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

    const updateCampaign = async (
        id: string,
        payload: ICreateCampaignPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await campaignService.campaignResource.updateCampaign(
                    id,
                    payload,
                );
            setIsLoading(false);
            setCampaign({ ...campaign, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateCampaign, isLoading, isError };
};

export const useDeleteCampaign = () => {
    const { campaignService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

    const deleteCampaign = async (id: string) => {
        setIsLoading(true);
        try {
            const response = await campaignService.campaignResource.delete(id);
            setIsLoading(false);
            setCampaign({ ...campaign, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { deleteCampaign, isLoading, isError };
};

export const usePauseCampaign = () => {
    const { campaignService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

    const getPauseCampaign = async (id: string) => {
        setIsLoading(true);
        try {
            const response =
                await campaignService.campaignResource.updatePause(id);
            setIsLoading(false);
            setCampaign({ ...campaign, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { getPauseCampaign, isLoading, isError };
};

export const useResumeCampaign = () => {
    const { campaignService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [campaign, setCampaign] = useAtom(campaignCollectionAtom);

    const getResumeCampaign = async (id: string) => {
        setIsLoading(true);
        try {
            const response =
                await campaignService.campaignResource.updateResume(id);
            setIsLoading(false);
            setCampaign({ ...campaign, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { getResumeCampaign, isLoading, isError };
};

export const useGetCampaignProductList = () => {
    const [campaignProducts, setCampaignProducts] = useAtom(
        campaignProductCollectionAtom,
    );
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const { campaignService } = useService();

    const getCampaignProducts = async (
        id: string,
        params?: Record<string, any>,
    ): Promise<void> => {
        try {
            setIsLoading(true);
            setError(undefined);
            const response =
                await campaignService.campaignResource.getCampaignProducts(
                    id,
                    params,
                );

            if (response) {
                const processData = new CampaignProductCollectionModel(
                    response,
                );
                setCampaignProducts(processData);
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };

    return {
        getCampaignProducts,
        data: campaignProducts,
        isLoading,
        error,
    } as const;
};

export const useAddCampaignProducts = () => {
    const { campaignService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);

    const addCampaignProducts = async (
        id: string,
        locale: string,
        region: string,
        payload: IAddCampaignProductsPostRequest,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await campaignService.campaignResource.addCampaignProducts(
                    id,
                    locale,
                    region,
                    payload,
                );
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { addCampaignProducts, isLoading, isError };
};

export const useAddRawCampaignProduct = () => {
    const { campaignService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);

    const addRawCampaignProduct = async (
        campaignId: string,
        locale: string,
        region: string,
        payload: IAddCampaignProductRawPayload,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await campaignService.campaignResource.postAddCampaignProductRaw(
                    campaignId,
                    locale,
                    region,
                    payload,
                );
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { addRawCampaignProduct, isLoading, isError };
};

export const useDeleteCampaignProducts = () => {
    const { campaignService } = useService();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ErrorProps | undefined>(undefined);

    const deleteCampaignProducts = async (
        id: string,
        payload: IDeleteCampaignProducts,
    ) => {
        try {
            setIsLoading(true);
            const response =
                await campaignService.campaignResource.deleteCampaignProducts(
                    id,
                    payload,
                );
            setIsLoading(false);
            return response.data;
        } catch (error: any) {
            setIsLoading(false);
            setError({
                code: getErrorCode(error),
                message: getErrorMessage(error),
            });
            throw error;
        }
    };
    return { deleteCampaignProducts, error, isLoading };
};
