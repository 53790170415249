import { Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import logoImg from "/logo.svg";
import menuItems, { MenuItem } from "@/consts/menu-items";
import { SUPER_ADMIN } from "@/consts/storage";
import { Authorized, getIsCurrentUserSuperAdmin } from "@/utils/helper";
import { tw } from "@/consts/theme/tailwindTheme";

const { SubMenu } = Menu;
const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
    overflow: "auto",
    height: "100vh",
    position: "fixed",
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    zIndex: 999,
    scrollbarWidth: "none",
    scrollbarColor: "unset",
    backgroundColor: "white",
};

const Sidebar = (props: { isMenuCollapsed: boolean }) => {
    const { isMenuCollapsed } = props;
    const location = useLocation();
    const superAdmin = getIsCurrentUserSuperAdmin();
    const isSuperAdmin = superAdmin === SUPER_ADMIN;

    // Generate a unique key based on the menu item path or title
    const generateUniqueKey = (item: MenuItem, prefix: string): string => {
        const identifier = item.path || item.title;
        return `${prefix}-${identifier.toLowerCase().replace(/\s+/g, "-")}`;
    };

    const getMenuKeys = (path: string) => {
        const keys = { selectedKey: "", openKey: "" };

        menuItems.forEach((menuItem, idx) => {
            const menuKey = generateUniqueKey(menuItem, `menu-${idx}`);

            if (menuItem.subMenus) {
                menuItem.subMenus.forEach((subMenuItem, subIdx) => {
                    if (subMenuItem.path === path) {
                        keys.selectedKey = `${menuKey}-sub-${subIdx}`;
                        keys.openKey = menuKey;
                    }
                });
            } else if (menuItem.path === path) {
                keys.selectedKey = menuKey;
            }
        });
        return keys;
    };

    const { selectedKey, openKey } = getMenuKeys(location.pathname);

    const renderSubMenu = (
        menuItem: MenuItem,
        idx: number,
        parentKey?: string,
    ) => {
        const currentKey = parentKey
            ? `${parentKey}-sub-${idx}`
            : generateUniqueKey(menuItem, `menu-${idx}`);

        if (
            menuItem.requirePermission &&
            !isSuperAdmin &&
            !Authorized.checkActionPermission(
                menuItem.permission,
                menuItem,
                null,
            )
        ) {
            return null;
        }

        if (menuItem.subMenus && menuItem.subMenus.length > 0) {
            const filteredSubMenus = menuItem.subMenus.filter((subMenuItem) => {
                return (
                    !subMenuItem.requirePermission ||
                    isSuperAdmin ||
                    Authorized.checkActionPermission(
                        subMenuItem.permission,
                        subMenuItem,
                        null,
                    )
                );
            });

            if (filteredSubMenus.length > 0) {
                return (
                    <SubMenu
                        key={currentKey}
                        icon={menuItem.icon}
                        title={menuItem.title}
                        style={{ marginBottom: "0px", width: 245 }}
                    >
                        {filteredSubMenus.map((subMenuItem, subIdx) =>
                            renderSubMenu(subMenuItem, subIdx, currentKey),
                        )}
                    </SubMenu>
                );
            }
            return null;
        }

        return (
            <Menu.Item key={currentKey} icon={menuItem.icon}>
                {menuItem.path ? (
                    <Link to={menuItem.path}>{menuItem.title}</Link>
                ) : (
                    menuItem.title
                )}
            </Menu.Item>
        );
    };

    return (
        <Sider
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={isMenuCollapsed}
            style={siderStyle}
            width={250}
        >
            <div
                className="logo"
                style={{
                    padding: "16px 16px 6px 16px",
                    borderBottom: tw.border.DEFAULT,
                    borderRight: tw.border.DEFAULT,
                }}
            >
                <Link to="/">
                    <img src={logoImg} alt="logo" />
                </Link>
            </div>
            <Menu
                mode="inline"
                style={{ fontWeight: "700" }}
                selectedKeys={[selectedKey]}
                defaultOpenKeys={[openKey]}
            >
                {menuItems
                    .filter((item) => {
                        if (!item.requirePermission) return true;
                        return (
                            isSuperAdmin ||
                            Authorized.checkActionPermission(
                                item.permission,
                                item,
                                null,
                            )
                        );
                    })
                    .map((menuItem, idx) => renderSubMenu(menuItem, idx))}
            </Menu>
        </Sider>
    );
};

export default Sidebar;
