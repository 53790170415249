import qs from "qs";
import { IFilterType } from "@/types/filters";
export class ApiHelperModel {
    static makeGetRequest = async (
        filters: IFilterType | null,
        getRequest: (data: string) => Promise<void>,
    ): Promise<void> => {
        if (filters) {
            await getRequest(qs.stringify({ ...filters }));
        }
    };
}
export class GetApiHelperModel {
    static makeGetRequest = async (
        params: Record<string, any>,
        getRequest: (data: Record<string, any>) => Promise<void>,
    ): Promise<void> => {
        await getRequest(params);
    };
}
