import React, { useEffect, useState } from "react";
import {
    Button,
    Dropdown,
    Flex,
    Modal,
    Space,
    Spin,
    Table,
    Tag,
    Typography,
    notification,
} from "antd";
import {
    CheckCircleOutlined,
    DownOutlined,
    HddFilled,
} from "@ant-design/icons";
import { useAtomValue } from "jotai";

import { agentCategoryPriceReadCollectionAtom } from "@/lib/core-react/store/store";
import { useGetCategoryPrices } from "@/lib/core-react/hooks/private/useShippingAgent";
import {
    useApprovedShippingProductForProduct,
    useCreateBid,
    useDefaultAgentAssign,
} from "@/lib/core-react/hooks/private/useShipping";
import {
    AgentCategoryPriceReadCollectionModel,
    AgentCategoryPriceReadModel,
    AgentCategoryPriceSlotModel,
} from "@/models/agentCategoryPriceReadCollectionModel";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import {
    IDefaultAssignAgentPayload,
    IOpenBidPayload,
} from "@/types/shipmentProductCollection";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import { ExtendedMenuItemType } from "@/types";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import useDataFilters from "@/hooks/useDataFilters";
import { IFilterType } from "@/types/filters";
import FiltersComponent from "@/components/FiltersComponent";
import { AgentAssignWithPriceModal } from "./AgentAssignWithPriceModal";
import useActionsProps from "@/helpers/useActionsProps";
import { formatString } from "@/utils/helper";

interface IProps {
    open: boolean;
    selectedProductIds: number[];
    onCancel: () => void;
    categoryPriceDetails: ICategoryPriceRequestParams;
    selectedProducts: ShipmentProductModel[];
}
export const SelectWarehouseModal = ({
    open,
    onCancel,
    categoryPriceDetails,
    selectedProductIds,
    selectedProducts,
}: IProps) => {
    const { approvedShippingProduct } = useApprovedShippingProductForProduct();

    const {
        filters,
        handleFilterChange,
        isFetched,
        initializeAvailableFilter,
        handelSubFilterClear,
    } = useDataFilters(true);

    const { Text } = Typography;

    const actionsProps = useActionsProps();
    const [isAssigning, setIsAssigning] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<
        AgentCategoryPriceReadModel[]
    >([]);
    const { defaultAgentAssign, isLoading: isLoadingDefaultAssign } =
        useDefaultAgentAssign();
    const { createBid, isLoading: isLoadingBids } = useCreateBid();

    const { getCategoryPrices } = useGetCategoryPrices();
    const { data, isLoading } = useAtomValue(
        agentCategoryPriceReadCollectionAtom,
    );

    const getData = async () => {
        await getCategoryPrices(categoryPriceDetails);
    };

    useEffect(() => {
        getData();
    }, [categoryPriceDetails]);

    const agentCategoryPriceRead =
        data && new AgentCategoryPriceReadCollectionModel(data);

    const filterData = agentCategoryPriceRead?.getFilters();
    const okHandler = () => {};

    const [priceReadId, setPriceReadId] = useState<number | undefined>(
        undefined,
    );
    const [showAgentAssignModal, setShowAgentAssignModal] =
        useState<boolean>(false);

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && agentCategoryPriceRead?.getFilters()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        agentCategoryPriceRead?.getFilters(),
    ]);

    // Filter Handler
    const handleProductFilter = async () => {
        const merged = getMergeFilterItems();
        await getCategoryPrices(merged);
    };

    const defaultAgentAssignHandler = async (priceReadId: number) => {
        try {
            // first we approve all
            setIsAssigning(true);
            await Promise.all(
                selectedProducts
                    .filter(
                        (product) =>
                            product.getStatus() === "shipment-pending" ||
                            product.getStatus() === "shipment-rejected",
                    )
                    .map(async (product) => {
                        await approvedShippingProduct(product.getId());
                    }),
            );

            const payload: IDefaultAssignAgentPayload = {
                agent_category_price_read_id: priceReadId,
                shipment_product_ids: selectedProductIds,
            };
            // now assign agent
            await defaultAgentAssign(payload);
            notification["success"]({
                message: "Agent Assign successfully",
            });
            setIsSuccess(true);
            onCancel();
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
        } finally {
            setIsAssigning(false);
        }
    };

    const createBidHandler = async (
        payload: IOpenBidPayload,
        productId: number,
    ) => {
        try {
            if (
                selectedProducts[0].getStatus() === "shipment-pending" ||
                selectedProducts[0].getStatus() === "shipment-rejected"
            ) {
                await approvedShippingProduct(selectedProducts[0].getId());
            }
            await createBid(payload, productId);
            notification["success"]({
                message: "Bid opened successfully",
            });
            onCancel();
        } catch (error: any) {
            if (error?.response?.data?.message) {
                notification["error"]({
                    message: error.response.data.message,
                });
            }
        }
    };

    // Bid is possible only when there is one product selected which has no previous record of bid opening and a warehouse selection is also required
    const isBidDisabled =
        selectedRowKeys.length === 0 ||
        selectedProductIds.length > 1 ||
        selectedProducts[0].getBids().length >= 1;

    const mainItems: ExtendedMenuItemType[] = [
        {
            permission:
                ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_REJECT_SHIPMENT_PRODUCT,
            label:
                selectedProducts[0].getStatus() === "shipment-pending" ||
                selectedProducts[0].getStatus() === "shipment-rejected"
                    ? "Approve & Open Bid"
                    : "Open Bid",
            key: "open_bid",
            onClick: () =>
                createBidHandler(
                    {
                        agent_warehouse_ids: [
                            ...selectedRows.map((row) =>
                                row.getAgentWarehouse().getId(),
                            ),
                        ],
                    },
                    Number(selectedProductIds[0]),
                ),
            disabled: isBidDisabled,
            title:
                selectedProductIds.length > 1
                    ? "Bid is not possible when multiple product is selected"
                    : selectedRowKeys.length === 0
                      ? "Please select warehouse to open bid"
                      : "",
        },
    ];

    const slotsColumns = [
        {
            title: "Min",
            dataIndex: "min_amount",
            key: "min_amount",
        },
        {
            title: "Max",
            dataIndex: "max_amount",
            key: "max_amount",
        },
        {
            title: "Rate",
            dataIndex: "unitType",
            key: "unitType",
            render: (unitType: string, record: AgentCategoryPriceSlotModel) => (
                <span>
                    {record.getRate()}
                    <small>/-{unitType}</small>
                </span>
            ),
        },
    ];

    const columns = [
        {
            title: "Warehouse",
            dataIndex: "warehouse",
            key: "warehouse",
            width: 350,
            render: (_text: string, record: AgentCategoryPriceReadModel) => (
                <>
                    <Typography.Paragraph
                        strong
                        ellipsis={{
                            tooltip: `${record.getAgentWarehouse().getCompanyName()}}`,
                        }}
                        style={{
                            maxWidth: 300,
                            fontSize: 16,
                        }}
                    >
                        {record.getAgentWarehouse().getCompanyName()}
                    </Typography.Paragraph>
                    <Typography.Text
                        ellipsis={{
                            tooltip: `${record.getAgentWarehouse().getName()}}`,
                        }}
                        style={{
                            maxWidth: 300,
                        }}
                    >
                        {record.getAgentWarehouse().getName()}
                    </Typography.Text>
                    <Typography>
                        <Text>Contains: </Text>
                        <Tag color="green">{formatString(record.contain)}</Tag>
                    </Typography>
                </>
            ),
        },
        {
            title: "Rate",
            dataIndex: "rate",
            key: "rate",
            width: 100,
            render: (_: string, record: AgentCategoryPriceReadModel) =>
                record.getRate(),
        },
        {
            title: "Commission",
            dataIndex: "commission",
            key: "commission",
            width: 100,
            render: (_: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text>
                    {record.getCommissionRate()}
                    {record.getCommissionRateType() === "percentage"
                        ? " (%)"
                        : " (Fixed)"}
                </Typography.Text>
            ),
        },
        {
            title: "Customer Cost",
            dataIndex: "customer_cost",
            key: "customer_cost",
            width: 150,
            align: "center" as const,
            render: (_: string, record: AgentCategoryPriceReadModel) => (
                <Typography.Text>
                    {record.getTotalRate()} /- {record.getUnitType()}
                </Typography.Text>
            ),
        },
        {
            title: "Slots",
            dataIndex: ["slots", "data"],
            key: "slots",
            align: "center" as const,
            width: 300,
            render: (
                _: AgentCategoryPriceSlotModel,
                record: AgentCategoryPriceReadModel,
            ) => {
                const unitType = record.getUnitType();
                return (
                    <Table
                        columns={slotsColumns}
                        size="small"
                        dataSource={record
                            .getSlots()
                            ?.getData()
                            .map((slot) => ({
                                ...slot,
                                unitType,
                            }))}
                        rowKey={(r) => r.getId()}
                        pagination={false}
                        bordered={false}
                        className="meta_table"
                    />
                );
            },
        },
        {
            ...actionsProps,
            render: (_: string, record: AgentCategoryPriceReadModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                        label: "Assign Agent",
                        icon: isAssigning ? (
                            <Spin />
                        ) : isSuccess ? (
                            <CheckCircleOutlined />
                        ) : null,
                        onClick: () => {
                            defaultAgentAssignHandler(record.getId());
                        },
                        key: "1",
                        disabled: isLoadingDefaultAssign,
                    },
                    {
                        disabled: isLoadingBids,
                        permission:
                            ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                        label: "Assign agent with price",
                        key: "2",
                        onClick: () => {
                            setShowAgentAssignModal(true);
                            setPriceReadId(record.getId());
                        },
                    },
                ];
                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}></Button>
                    </Dropdown>
                );
            },
        },
    ];

    const getMergeFilterItems = () => {
        const merged = { ...categoryPriceDetails };
        for (const key in filters) {
            if (key === "page" || key === "per_page") {
                continue;
            }

            if (filters[key] !== undefined) {
                merged[key] = filters[key];
            }
        }
        return merged;
    };

    return (
        <>
            <Modal
                destroyOnClose={true}
                width="100%"
                open={open}
                onOk={okHandler}
                onCancel={() => {
                    onCancel();
                    handelSubFilterClear();
                }}
                footer={false}
                title={
                    <Space
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <Typography.Text>Select Warehouse</Typography.Text>
                    </Space>
                }
            >
                <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelSubFilterClear}
                    isFetched={isFetched}
                    filters={getMergeFilterItems()}
                    filtersData={filterData}
                    isFromProductReceived={true}
                    isSubFilter={true}
                />

                <Flex
                    justify="end"
                    style={{
                        marginTop: 24,
                    }}
                >
                    {selectedProductIds.length === 1 &&
                        (selectedProducts[0].getStatus() ===
                            "shipment-pending" ||
                            selectedProducts[0].getStatus() ===
                                "shipment-rejected" ||
                            selectedProducts[0].getStatus() ===
                                "shipment-approved") && (
                            <Dropdown
                                menu={{
                                    items: mainItems.filter((x) =>
                                        checkActionPermission(
                                            x.permission,
                                            x,
                                            null,
                                        ),
                                    ),
                                }}
                            >
                                <Button icon={<HddFilled />}>
                                    Actions
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        )}
                </Flex>

                <Table
                    bordered
                    rowHoverable={false}
                    style={{ marginTop: 10, verticalAlign: "top" }}
                    size="small"
                    dataSource={agentCategoryPriceRead?.getData()}
                    rowKey={(r) => r.getId()}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedRowKeys(selectedRowKeys);
                            setSelectedRows(selectedRows);
                        },
                    }}
                    columns={columns}
                    loading={isLoading}
                    scroll={{ x: 950 }}
                ></Table>
            </Modal>

            {priceReadId && agentCategoryPriceRead && (
                <AgentAssignWithPriceModal
                    agentCategoryPriceRead={categoryPriceDetails}
                    onCancel={() => {
                        setShowAgentAssignModal(false);
                        onCancel();
                        handelSubFilterClear();
                    }}
                    open={showAgentAssignModal}
                    priceReadId={priceReadId}
                    selectedProducts={selectedProducts}
                    selectedProductIds={selectedProductIds}
                />
            )}
        </>
    );
};
