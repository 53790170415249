import { getNodeLabel } from "../typeGuards";

import { NodeInputProps } from "./helpers";
import { Button } from "antd";

export function NodeInputSubmit({
    node,
    attributes,
    disabled,
}: NodeInputProps) {
    return (
        <>
            <Button
                type="primary"
                htmlType="submit"
                name={attributes.name}
                value={attributes.value || ""}
                disabled={attributes.disabled || disabled}
                style={{ width: "100%" }}
            >
                {getNodeLabel(node)}
            </Button>
        </>
    );
}
