import { ITranslationEntry } from "../lang";

export const HE: ITranslationEntry = {
    translations: {
        notifications: "התראות",
        markAllAsRead: "סמן הכל כנקרא",
        poweredBy: "נתמך על ידי",
        settings: "הגדרות",
        noNewNotification: "אין שום דבר חדש לראות כאן עדיין",
    },
    lang: "he",
};
