import { Button, Form, InputNumber, Row, Col, Typography, Table } from "antd";
import React, { useEffect } from "react";
import {
    ExchangeListModel,
    RegionalExchangeModel,
    StoreExchangeModel,
} from "@/models/exchangeListCollectionModel";
import { useUpdateAndSyncWithRest } from "@/lib/core-react/hooks/private/useExchange";
import { storeExchangePreviewAtom } from "@/lib/core-react/store/store";
import { useAtom } from "jotai";
import { showError } from "@/helpers/showError";
import { PreviewExchangeDataModel } from "@/models/storeExchangePreviewModel";
import { IRegionalExchangePreview } from "@/types/exchangeListCollection";

const { Title } = Typography;

interface IProps {
    selectedExchange: ExchangeListModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedStore: StoreExchangeModel;
    selectedRegion: RegionalExchangeModel;
}

const UpdateAndSync = ({
    selectedExchange,
    setIsShowUpdateModal,
    selectedStore,
    selectedRegion,
}: IProps) => {
    const [form] = Form.useForm();
    const { preview } = useUpdateAndSyncWithRest();
    const [previewExchangeData] = useAtom(storeExchangePreviewAtom);

    useEffect(() => {
        form.resetFields();
    }, [form, selectedExchange, selectedStore, selectedRegion]);

    const onFinish = async (values: any) => {
        const { fx_rate, commission_rate } = values;
        try {
            const data: IRegionalExchangePreview = {
                region_id: selectedRegion.getRegion().id,
                store_id: selectedStore.getStore().getId(),
                fx_rate,
                commission_rate,
            };
            await preview(data);
            // if (res) {
            //     setIsShowUpdateModal(false);
            //     notification["success"]({
            //         message: res.message,
            //     });
            //     // getExchange({});
            // }
        } catch (error) {
            showError(error, form);
        }
    };

    const columns = [
        {
            title: "Exchange Name",
            dataIndex: "name",
            key: "name",
            align: "center" as const,
        },
        {
            title: "FX Rate",
            dataIndex: "fx_rate",
            key: "fx_rate",
            align: "left" as const,
            render: (_text: any, record: any) => (
                <div>
                    <div>Old: {record.old_fx_rate}</div>
                    <div>New: {record.fx_rate}</div>
                </div>
            ),
        },
        {
            title: "Commission Rate",
            dataIndex: "commission_rate",
            key: "commission_rate",
            align: "left" as const,
            render: (_text: any, record: any) => (
                <div>
                    <div>Old: {record.old_commission_rate}</div>
                    <div>New: {record.commission_rate}</div>
                </div>
            ),
        },
        {
            title: "Commission Type",
            dataIndex: "commission_rate_type",
            key: "commission_rate_type",
            align: "center" as const,
        },
    ];

    const getExchangeData = (
        exchangeData: PreviewExchangeDataModel | undefined,
    ) => ({
        key: exchangeData?.getExchangeList().getId(),
        name: exchangeData?.getExchangeList().getName(),
        fx_rate: exchangeData?.getNewFxRate().toFixed(3),
        commission_rate: exchangeData?.getNewCommissionRate().toFixed(3),
        commission_rate_type: exchangeData
            ?.getStoreExchange()
            .getCommissionRateType(),
        old_fx_rate: exchangeData?.getStoreExchange().getFxRate().toFixed(3),
        old_commission_rate: exchangeData
            ?.getStoreExchange()
            .getCommissionRate()
            .toFixed(3),
    });

    const defaultExchangeData = [
        getExchangeData(previewExchangeData.data?.getDefaultExchange()),
    ];
    const nonDefaultExchangeData = previewExchangeData.data
        ?.getNonDefaultExchanges()
        .map(getExchangeData);

    return (
        <>
            {previewExchangeData.data ? (
                <>
                    <div style={{ marginBottom: 20 }}>
                        <Title level={5}>Default exchange</Title>
                        <Table
                            columns={columns}
                            dataSource={defaultExchangeData}
                            pagination={false}
                            bordered
                            size="small"
                        />
                    </div>

                    <div style={{ marginBottom: 20 }}>
                        <Title level={5}>Non default exchanges</Title>
                        <Table
                            columns={columns}
                            dataSource={nonDefaultExchangeData}
                            pagination={false}
                            bordered
                            size="small"
                        />
                    </div>
                </>
            ) : (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        fx_rate: selectedStore.getFxRate(),
                        commission_rate: selectedStore.getCommissionRate(),
                        commission_rate_type:
                            selectedStore.getCommissionRateType(),
                        minimum_order_amount:
                            selectedStore.getMinimumOrderAmount(),
                    }}
                    style={{ margin: "25px 0px 0px 0px" }}
                >
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="fx_rate"
                                label="FX Rate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the FX rate!",
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item
                                name="commission_rate"
                                label="Commission Rate"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Please input the commission rate!",
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                style={{ minWidth: "100px" }}
                                onClick={() => setIsShowUpdateModal(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                loading={previewExchangeData.isLoading}
                                style={{
                                    marginLeft: "10px",
                                    minWidth: "100px",
                                }}
                                type="primary"
                                htmlType="submit"
                            >
                                Update
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            )}
        </>
    );
};

export default UpdateAndSync;
