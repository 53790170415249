import { ITranslationEntry } from "../lang";

export const GL: ITranslationEntry = {
    translations: {
        notifications: "Notificacións",
        markAllAsRead: "Marcar todo coma lido",
        poweredBy: "Coa tecnoloxía de",
        settings: "Axustes",
        noNewNotification: "Aínda non hai nada novo que ver aquí",
    },
    lang: "gl",
};
