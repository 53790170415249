import { IPagination } from "@/types/pagination";
import {
    IRole,
    IRoleCollection,
    IRolePostRequest,
    IRoleUpdateRequest,
} from "@/types/roleCollection";
import { RoleEnum } from "@/enums/roleCollectionEnums";
import { PermissionCollectionModel } from "./permissionCollectionModel";

export class RoleModel {
    public readonly object: RoleEnum.Role;
    public readonly id: number;
    public readonly name: string;
    public readonly label: string;
    public readonly is_customer?: boolean;
    public readonly is_order_handler?: boolean;
    public readonly permissions: PermissionCollectionModel;

    constructor(dataItem: IRole) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.name = dataItem.name;
        this.label = dataItem.label;
        this.label = dataItem.label;
        this.is_order_handler = dataItem.is_order_handler;
        this.is_customer = dataItem.is_customer;
        this.permissions = dataItem.permissions;
    }

    getId = () => this.id;
    getName = () => this.name;
    getLabel = () => this.label;
    getIsOrderHandler = () => this.is_order_handler;
    getIsCustomer = () => this.is_customer;
    getPermissions = () => this.permissions;
}

export class RoleCollectionModel {
    public readonly object: RoleEnum.RoleCollection;
    public readonly data: RoleModel[];
    public readonly pagination: IPagination | undefined;

    constructor(data: IRoleCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new RoleModel(item));
        this.pagination = data.pagination;
    }

    getData = () => this.data;

    getRoleIdsAsObjectName() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.name] = item.name));
        return obj;
    }

    getRoleIdsAsObject() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.name));
        return obj;
    }

    getRolesLabeledValue = () => {
        return this.getData().map((item) => ({
            label: item.getLabel(),
            value: item.getId(),
        }));
    };
}

//Post Request
export class RolePostRequestModel {
    public readonly name: string;
    public readonly label: string;
    public readonly is_customer?: boolean;
    public readonly is_order_handler?: boolean;

    constructor(reqData: IRolePostRequest) {
        this.name = reqData.name;
        this.label = reqData.label;
        this.is_customer = reqData.is_customer;
        this.is_order_handler = reqData.is_order_handler;
    }
}

//update Request
export class RoleUpdateRequestModel {
    public readonly name: string;
    public readonly label: string;
    public readonly is_customer?: boolean;
    public readonly is_order_handler?: boolean;
    public readonly permission_ids: number[];

    constructor(reqData: IRoleUpdateRequest) {
        this.name = reqData.name;
        this.label = reqData.label;
        this.is_customer = reqData.is_customer;
        this.is_order_handler = reqData.is_order_handler;
        this.permission_ids = reqData.permission_ids;
    }
}
