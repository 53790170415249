import React, { useEffect, createRef } from "react";
import { Button, Form, Input, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { UserModel } from "@/models/userCollectionModel";
import { useUpdateAdmin } from "@/lib/core-react/hooks/private/useAdminUser";
import { IUserStatus } from "@/types/userCollection";
import { Option } from "antd/es/mentions";
import { useAtom } from "jotai";
import { roleAtom } from "@/lib/core-react/store/store";
import { RoleCollectionModel } from "@/models/roleCollectionModel";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { showError } from "@/helpers/showError";
import { useGetRole } from "@/lib/core-react/hooks/private";
import { responseTypeQuery } from "@/filters/constant";
import QueryString from "qs";

interface IProps {
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedAdminUser: UserModel;
}

const UpdateAdminUser = ({
    setIsShowUpdateModal,
    selectedAdminUser,
}: IProps) => {
    const { updateAdmin, isLoading } = useUpdateAdmin();
    const { getRole } = useGetRole();
    const [{ data: roleData }] = useAtom(roleAtom);
    const RoleData = roleData && new RoleCollectionModel(roleData);

    useEffect(() => {
        getRole(QueryString.stringify(responseTypeQuery.minimal));
    }, []);

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        form.setFieldsValue({
            name: selectedAdminUser.name,
            is_active: selectedAdminUser.is_active === 1 ? "1" : "0",
            role_ids: selectedAdminUser?.roles?.data.map((role) =>
                role.id?.toString(),
            ),
        });
    }, [selectedAdminUser, form]);

    const onFinish = async (value: IUserStatus) => {
        try {
            await updateAdmin(value, Number(selectedAdminUser.id));
            setIsShowUpdateModal(false);
            notification["success"]({
                message: "Updated Admin User successfully",
            });
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update Admin User"
            ref={formRef}
            initialValues={{
                name: selectedAdminUser.name,
                role_ids: selectedAdminUser?.roles?.data.map((role) =>
                    role.id?.toString(),
                ),
                is_active: selectedAdminUser.is_active.toString(),
            }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "User name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="User name" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Role is required" }]}
                name="role_ids"
                label="Role"
            >
                <Select
                    mode="multiple"
                    loading={isLoading}
                    placeholder="Please select the Roles"
                >
                    {RoleData?.getRoleIdsAsObjectName() &&
                        renderOptionsFromEnum(RoleData?.getRoleIdsAsObject())}
                </Select>
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                label="Status"
                name="is_active"
            >
                <Select placeholder="Select status">
                    <Option value="1">Active</Option>
                    <Option value="0">Inactive</Option>
                </Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateAdminUser;
