import { GatewayResource } from "../../resources/private";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
class GatewayService extends CommerceApiService {
    public gatewayResource: GatewayResource;
    constructor() {
        super();

        this.gatewayResource = new GatewayResource(this.config);
    }
}

export default GatewayService;
