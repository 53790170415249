import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import QueryString from "qs";

class CategoryPriceResource extends BaseResource {
    categoryPricesPath = `/api/shipping-agent/admin/category-price/v1/category-prices`;

    getCategoryPrice(
        params: ICategoryPriceRequestParams,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        return this.client.request(
            "GET",
            `${this.categoryPricesPath}?${QueryString.stringify(params)}`,
            {},
            {},
            customHeaders,
        );
    }
}

export default CategoryPriceResource;
