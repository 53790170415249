import { IModalActionsType } from "@/helpers/getModalTital";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import {
    ChangeDestinationWarehouseModal,
    ChangeShippingCategoryModal,
    ChangeShippingCountryModal,
    ChangeShippingModeModal,
    ChangeShippingTypeModal,
} from "../ShipmentProducts";

interface Props {
    modalOpenType: IModalActionsType;
    selectedProduct: ShipmentProductModel | undefined;
    selectedProductIds: number[];
    onCancel: (isMutate?: boolean) => void;
}

export const ShippingInfoChangeModals = ({
    modalOpenType,
    onCancel,
    selectedProduct,
    selectedProductIds,
}: Props) => {
    switch (modalOpenType) {
        case "change_shipping_country":
            return (
                <ChangeShippingCountryModal
                    existingShippingCountryId={selectedProduct?.getShippingCountryId()}
                    isOpen={true}
                    productIds={selectedProductIds}
                    onCancel={onCancel}
                    region_id={selectedProduct?.getRegion().getId()}
                />
            );

        case "change_destination_warehouse":
            return (
                <ChangeDestinationWarehouseModal
                    country_id={selectedProduct?.getDestinationCountryId()}
                    existingWarehouseId={selectedProduct?.getDestinationWarehouseId()}
                    isOpen={true}
                    productIds={selectedProductIds}
                    onCancel={onCancel}
                />
            );

        case "change_shipping_category":
            return (
                <ChangeShippingCategoryModal
                    shipping_category_id={selectedProduct?.getShippingCategoryId()}
                    isOpen={true}
                    productIds={selectedProductIds}
                    onCancel={onCancel}
                    region_id={selectedProduct?.getRegion().getId()}
                />
            );

        case "change_shipping_mode":
            return (
                <ChangeShippingModeModal
                    existingMode={selectedProduct?.getShippingMode()}
                    isOpen={true}
                    productIds={selectedProductIds}
                    onCancel={onCancel}
                />
            );

        case "change_shipping_type":
            return (
                <ChangeShippingTypeModal
                    existingType={selectedProduct?.getShippingType()}
                    isOpen={true}
                    productIds={selectedProductIds}
                    onCancel={onCancel}
                />
            );

        default:
            return <></>;
    }
};
