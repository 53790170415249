import { ITranslationEntry } from "../lang";

export const HR: ITranslationEntry = {
    translations: {
        notifications: "Obavijesti",
        markAllAsRead: "Označi sve kao pročitano",
        poweredBy: "Uz podršku",
        settings: "Postavke",
        noNewNotification: "Ovdje još nema ništa novo za vidjeti",
    },
    lang: "hr",
};
