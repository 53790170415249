import { IFilterType } from "@/types/filters";
import QueryString, { IStringifyOptions, IParseOptions } from "qs";

// 1. Function to parse query string to object, with options
export const parseStringToObject = (
    queryString: string,
    options?: IParseOptions,
) => {
    return QueryString.parse(queryString, options);
};

// 2. Function to convert object to query string, with options
export const parseObjectToString = (
    object: Record<string, any> | IFilterType,
    options?: IStringifyOptions,
): string => {
    return QueryString.stringify(object, options);
};
