import { ITranslationEntry } from "../lang";

export const HY: ITranslationEntry = {
    translations: {
        notifications: "ծանուցումներ",
        markAllAsRead: "նշել բոլորը որպես կարդացված",
        poweredBy: "աջակցվում է",
        settings: "կարգավորումներ",
        noNewNotification: "Դեռևս այստեղ տեսնելու նոր բան չկա",
    },
    lang: "hy",
};
