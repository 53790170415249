// Enum for admin permissions in the Shipping-Agent module
export enum ADMIN_SHIPPING_AGENT_PERMISSION_ENUM {
    // Agent Company permissions
    AGENT_COMPANY_ADMIN_VIEW = "admin-agent-company-view",
    AGENT_MEMBER_ADMIN_VIEW = "agent-member-admin-view",
    AGENT_COMPANY_MANAGE = "admin-agent-company-manage",

    AGENT_WAREHOUSE_ADMIN_MANAGE = "agent-warehouse-admin-manage",
    AGENT_WAREHOUSE_ADMIN_VIEW = "agent-warehouse-admin-view",

    // Agent Verification permissions
    AGENT_VERIFICATION_ADMIN_VIEW = "agent-verification-admin-view",
    AGENT_VERIFICATION_ADMIN_UPDATE = "agent-verification-admin-update",
}

// Enum for admin permissions in the Shipping module
export enum ADMIN_SHIPPING_PERMISSION_ENUM {
    // Shipment Product permissions
    ADMIN_CHANGE_SHIPPING_COUNTRY = "admin-change-shipping-country",
    ADMIN_CHANGE_DESTINATION_WAREHOUSE = "admin-change-destination-warehouse",
    ADMIN_CHANGE_SHIPPING_CATEGORY = "admin-change-shipping-category",
    ADMIN_CHANGE_SHIPPING_MODE = "admin-change-shipping-mode",
    ADMIN_CHANGE_SHIPPING_TYPE = "admin-change-shipping-type",

    ADMIN_ASSIGN_DEFAULT = "admin-assign-agent",
    ADMIN_START_SHIPPING = "admin-start-shipping",

    ADMIN_APPROVE_SHIPMENT_PRODUCT = "admin-approve-shipment-product",
    ADMIN_REJECT_SHIPMENT_PRODUCT = "admin-reject-shipment-product",

    ADMIN_VIEW_SHIPMENT_PRODUCT = "admin-view-shipment-product",
    ADMIN_PLACE_ORDER = "admin-place-order",
    ADMIN_ATTACH_IMAGES = "admin-attach-images",

    ADMIN_UPDATE_TRACKING = "admin-update-tracking",

    ADMIN_PACKAGE_ADD = "admin-package-add",
    ADMIN_PACKAGE_UPDATE = "admin-package-update",
    ADMIN_PACKAGE_DELETE = "admin-package-delete",
    ADMIN_SHIPMENT_PRODUCT_CHANGE_RATE = "admin-shipment-product-change-rate",

    // Shipment Product Tracking permissions
    ADMIN_MANAGE_PACKAGE_TRACKING = "admin-manage-package-tracking",

    // Shipment Product Package permissions
    ADMIN_MANAGE_PACKAGE = "admin-manage-package",
    ADMIN_SHIPMENT_MANAGE_BID = "admin-shipment-manage-bid",

    // Shipment Product Unlisted Package permissions
    ADMIN_UNLISTED_PACKAGE_VIEW = "admin-unlisted-package-view",

    ADMIN_SHIPMENT_IMPERSONATE_CREATE = "admin-shipment-impersonate-create",
    ADMIN_SHIPMENT_PRODUCT_INVOICE_SUMMERY_VIEW = "admin-view-shipment-product-invoice-summary-view",
}

// Enum for admin permissions in the Shipping-Core module
export enum ADMIN_SHIPPING_CORE_PERMISSION_ENUM {
    // Base-shipping-category permissions
    ADMIN_BASE_SHIPPING_CATEGORY_VIEW = "admin-base-shipping-category-view",
    ADMIN_BASE_SHIPPING_CATEGORY_MANAGE = "admin-base-shipping-category-manage",

    // Shipping-category permissions
    ADMIN_SHIPPING_CATEGORY_VIEW = "admin-shipping-category-view",
    ADMIN_SHIPPING_CATEGORY_MANAGE = "admin-shipping-category-manage",

    // Shipping-country permissions
    ADMIN_SHIPPING_COUNTRY_VIEW = "admin-shipping-country-view",
    ADMIN_SHIPPING_COUNTRY_MANAGE = "admin-shipping-country-manage",
}

export const agentCompanyManageRoutePermissions = [
    ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_COMPANY_ADMIN_VIEW,
];
export const agentWarehouseManageRoutePermissions = [
    ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_MANAGE,
    ADMIN_SHIPPING_AGENT_PERMISSION_ENUM.AGENT_WAREHOUSE_ADMIN_VIEW,
];
export const shippingCountryManageRoutePermissions = [
    ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_VIEW,
    ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_MANAGE,
];
export const shippingCategoryManageRoutePermissions = [
    ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_VIEW,
    ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_CATEGORY_MANAGE,
];
export const shippingBaseShippingCategoryManageRoutePermissions = [
    ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_VIEW,
    ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_BASE_SHIPPING_CATEGORY_MANAGE,
];
export const shippingCommissionManageRoutePermissions = [];
// shipment product and shipment order same permission
export const shippingShipmentProductManageRoutePermissions = [
    ...Object.values(ADMIN_SHIPPING_PERMISSION_ENUM),
];
export const unlistedProductManageRoutePermissions = [
    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_UNLISTED_PACKAGE_VIEW,
];
