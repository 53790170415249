import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { useUpdateRfqReject } from "@/lib/core-react/hooks/private/useRfq";
import { RfqRequestModel } from "@/models/rfqRequestCollectionModel";
import { Form, Select, Button, Flex } from "antd";
import { rejectReasonOptions } from "../data/rejectReasonOptions";

interface Props {
    rfqRequestData: RfqRequestModel;
    onSuccess: () => void;
}
const RejectRFQForm = ({ rfqRequestData, onSuccess }: Props) => {
    const { updateRequestsReject, isLoading: isRejectLoading } =
        useUpdateRfqReject();

    const [form] = Form.useForm();

    const handleReject = async (inputData) => {
        try {
            const payload = {
                reject_reason: inputData?.reject_reason,
            };

            await updateRequestsReject({
                requestId: `${rfqRequestData.getId()}`,
                payload,
            });

            showSuccessAlert("Successfully RFQ Request Rejected");
            form.resetFields();
            onSuccess();
        } catch (error) {
            showError(error);
        }
    };

    return (
        <Form form={form} onFinish={handleReject} layout="vertical">
            <Form.Item
                label="Reject Reason"
                name="reject_reason"
                style={{ color: "red" }}
                rules={[
                    {
                        required: true,
                        message: "Please select al least one option",
                    },
                ]}
            >
                <Select
                    placeholder="Please select reason"
                    options={rejectReasonOptions}
                />
            </Form.Item>
            <Flex justify="end">
                <Button
                    loading={isRejectLoading}
                    type="primary"
                    htmlType="submit"
                >
                    Reject
                </Button>
            </Flex>
        </Form>
    );
};

export default RejectRFQForm;
