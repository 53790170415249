import { ResponsePromise } from "@/lib/core/request/types/typings";
import BaseResource from "./baseResource";

class FileUploadResource extends BaseResource {
    fileUpload(payload: FormData): ResponsePromise {
        const path = `/api/storage/generic/asset/v1/assets`;
        return this.client.request("POST", path, payload, undefined, {
            "Content-Type": `multipart/form-data`,
        });
    }
}

export default FileUploadResource;
