import { ServiceNameEnum } from "./serviceNameEnum";
export class ServiceBaseUrl {
    private static env = import.meta.env;
    private static PRODUCT_SERVICE_HOST = this.env.VITE_PRODUCT_SERVICE_HOST;

    private static COMMERCE_SERVICE_HOST = this.env.VITE_COMMERCE_SERVICE_HOST;

    private static CART_SERVICE_HOST = this.env.VITE_CART_SERVICE_HOST;

    private static AUTH_SERVICE_HOST = this.env.VITE_AUTH_SERVICE_HOST;

    private static LIVE_STREAMS_SERVICE_HOST =
        this.env.VITE_LIVE_STREAMS_SERVICE_HOST;

    private static CMS_STRIP_SERVICE_HOST =
        this.env.VITE_CMS_STRAPI_SERVICE_HOST;

    public static getServiceBaseUrl(serviceType: ServiceNameEnum): string {
        switch (serviceType) {
            case ServiceNameEnum.COMMERCE_SERVICE:
                return `${this.COMMERCE_SERVICE_HOST}`;

            case ServiceNameEnum.PRODUCT_SERVICE:
                return `${this.PRODUCT_SERVICE_HOST}`;

            case ServiceNameEnum.CART_SERVICE:
                return `${this.CART_SERVICE_HOST}`;

            case ServiceNameEnum.AUTH_SERVICE:
                return `${this.AUTH_SERVICE_HOST}`;

            case ServiceNameEnum.LIVE_STREAMS_SERVICE:
                return `${this.LIVE_STREAMS_SERVICE_HOST}`;

            case ServiceNameEnum.CMS_STRAPI_SERVICE:
                return `${this.CMS_STRIP_SERVICE_HOST}`;

            default:
                return "";
        }
    }
}
