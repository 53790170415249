import React from "react";
import { Form, Input, Button } from "antd";

const { TextArea } = Input;

interface RejectProductModalProps {
    onClose: () => void;
    handleOk: (value: { reject_reason: string }) => Promise<void>;
    loading?: boolean;
}

const RejectProductModal: React.FC<RejectProductModalProps> = ({
    onClose,
    handleOk,
    loading,
}) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} layout="vertical" onFinish={handleOk}>
            <Form.Item
                name="reject_reason"
                label="Reason for Rejection"
                rules={[
                    {
                        required: true,
                        message: "Please enter a reason for rejection.",
                    },
                ]}
            >
                <TextArea rows={4} placeholder="Enter reason here..." />
            </Form.Item>
            <Form.Item>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "8px",
                    }}
                >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </div>
            </Form.Item>
        </Form>
    );
};

export default RejectProductModal;
