import { ITranslationEntry } from "../lang";

export const NE: ITranslationEntry = {
    translations: {
        notifications: "सूचनाहरू",
        markAllAsRead: "सबै पढेको रूपमा चिन्ह लगाउनुहोस्",
        poweredBy: "द्वारा संचालित",
        settings: "सेटिङहरू",
        noNewNotification: "यहाँ हेर्न को लागी केहि नयाँ छैन",
    },
    lang: "ne",
};
