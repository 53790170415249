import { ITranslationEntry } from "../lang";

export const KU: ITranslationEntry = {
    translations: {
        notifications: "ئاگادارکردنەوەکان",
        markAllAsRead: "هەموویان وەک خوێندراوێک نیشانە بکە",
        poweredBy: "بەهێزکراوە لەلایەن",
        settings: "دانانەوە",
        noNewNotification: "هێشتا هیچ شتێکی نوێ لێرە نابینرێت",
    },
    lang: "ku",
};
