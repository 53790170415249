import {
    DeleteTwoTone,
    MinusCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Card,
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Space,
    Tag,
} from "antd";
import { Store } from "antd/es/form/interface";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { renderSelectOptionsFromEnum } from "@/components/Form/forms";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { showError } from "@/helpers/showError";
import {
    useCreateExchange,
    useGetExchange,
    useUpdateExchange,
} from "@/lib/core-react/hooks/private/useExchange";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    IExchangeCreateUpdateFormValue,
    IExchangeListPostRequest,
    IExchangeStoreUpdatePayload,
} from "@/types/exchangeListCollection";
import { v4 as uuidv4 } from "uuid";
import { getStoreCommission } from "@/utils/helper";
interface IProps {
    initialValues?: Store | undefined;
    title: string;
    action: "update" | "create";
    exchangeId?: number;
}
const CreateUpdateExchange = ({
    initialValues,
    title,
    action,
    exchangeId,
}: IProps) => {
    const {
        allOptionsData: { regionOptionsData, storeOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { getExchange } = useGetExchange();
    const navigate = useNavigate();

    const { createExchange, isLoading } = useCreateExchange();
    const { isLoading: updateExchangeLoading, updateExchange } =
        useUpdateExchange();
    const [form] = Form.useForm();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
    }, [form]);

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.STORE);
    }, []);

    const onFinish = async (value: IExchangeCreateUpdateFormValue) => {
        if (action === "create") {
            const payload: IExchangeListPostRequest = {
                name: value.name,
                is_default: value.is_default ?? false,
                regional_exchanges: value.regional_exchanges.map((rx) => {
                    return {
                        region_id: rx.region_id,
                        store_exchanges: rx.store_exchanges.map((sx) => {
                            return {
                                fx_rate: sx.fx_rate,
                                commission_rate: sx.commission_rate,
                                commission_rate_type: sx.commission_rate_type,
                                options: sx.options,
                                store_id: sx.store_id,
                            };
                        }),
                    };
                }),
            };

            try {
                const response = await createExchange(payload);
                if (response) {
                    notification.success({
                        message: response.message,
                    });
                    await getExchange({});
                    navigate("/store/store-fx");
                }
            } catch (e: any) {
                showError(e, form);
            }
        } else if (action === "update" && exchangeId) {
            try {
                const updatePayload: IExchangeStoreUpdatePayload = {
                    name: value.name,
                    regional_exchanges: value?.regional_exchanges.map(
                        (item) => {
                            return {
                                regional_exchange_id:
                                    item.regional_exchange_id as number,
                                store_exchanges: item?.store_exchanges.map(
                                    (ex) => {
                                        return {
                                            commission_rate: ex.commission_rate,
                                            commission_rate_type:
                                                ex.commission_rate_type,
                                            fx_rate: ex.fx_rate,
                                            options: ex.options,
                                            store_exchange_id:
                                                ex.store_exchange_id as number,
                                        };
                                    },
                                ),
                            };
                        },
                    ),
                };
                const res = await updateExchange(exchangeId, updatePayload);
                if (res) {
                    notification.success({
                        message: res.message,
                    });
                    await getExchange({});
                    navigate("/store/store-fx");
                }
            } catch (error: any) {
                showError(error, form);
            }
        }
    };

    return (
        <PageHeader
            style={{ marginTop: "10px" }}
            ghost={false}
            title={title}
            onBack={() => window.history.back()}
        >
            <Card
                title={`${action === "create" ? "Create" : "Update"}`}
                style={{ margin: "10px 0px" }}
            >
                <Form
                    layout="vertical"
                    form={form}
                    name="create-exchange-form"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={initialValues}
                >
                    {/* Exchange Name */}
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Exchange name is required",
                            },
                        ]}
                        label="Name"
                        name="name"
                    >
                        <Input placeholder="Exchange name" />
                    </Form.Item>

                    {/* Regional Exchanges */}
                    <Form.List name="regional_exchanges">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Card
                                        key={field.key}
                                        size="small"
                                        title={`Region #${index + 1}`}
                                        extra={
                                            index > 0 && (
                                                <MinusCircleOutlined
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                    style={{ color: "red" }}
                                                />
                                            )
                                        }
                                        style={{
                                            marginBottom: 16,
                                            border: "4px solid #DFFFED",
                                        }}
                                    >
                                        <Space
                                            key={uuidv4()}
                                            direction="vertical"
                                            size="middle"
                                            style={{
                                                display: "flex",
                                                margin: "0 10px",
                                            }}
                                        >
                                            <Row
                                                justify="space-between"
                                                style={{
                                                    padding: 5,
                                                }}
                                            >
                                                {/*<Typography.Text mark>Region #{index + 1}</Typography.Text>*/}
                                                {index > 0 && (
                                                    <DeleteTwoTone
                                                        twoToneColor="hotpink"
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                    />
                                                )}
                                            </Row>
                                            {/* Region */}
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Region is required",
                                                    },
                                                ]}
                                                name={[field.name, "region_id"]}
                                                label="Region"
                                            >
                                                <Select
                                                    allowClear
                                                    disabled={
                                                        action === "update"
                                                    }
                                                    loading={
                                                        regionOptionsData.isLoading
                                                    }
                                                    placeholder="Please select a region"
                                                    options={
                                                        regionOptionsData.options
                                                    }
                                                    notFoundContent={
                                                        regionOptionsData.isLoading
                                                            ? LoaderSmall
                                                            : null
                                                    }
                                                />
                                            </Form.Item>

                                            {/* Store Exchanges */}
                                            <Form.List
                                                name={[
                                                    field.name,
                                                    "store_exchanges",
                                                ]}
                                            >
                                                {(fields, { add, remove }) => (
                                                    <>
                                                        {fields.map(
                                                            (
                                                                field,
                                                                storeIndex,
                                                            ) => (
                                                                <Card
                                                                    key={
                                                                        field.key
                                                                    }
                                                                    size="small"
                                                                    title={`Store #${storeIndex + 1}`}
                                                                    extra={
                                                                        storeIndex >
                                                                            0 && (
                                                                            <MinusCircleOutlined
                                                                                onClick={() =>
                                                                                    remove(
                                                                                        field.name,
                                                                                    )
                                                                                }
                                                                                style={{
                                                                                    color: "red",
                                                                                }}
                                                                            />
                                                                        )
                                                                    }
                                                                    style={{
                                                                        marginBottom: 16,
                                                                        border: "2px solid #DFFFED",
                                                                    }}
                                                                >
                                                                    <Space
                                                                        key={uuidv4()}
                                                                        direction="vertical"
                                                                        size="middle"
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            margin: "0 10px",
                                                                        }}
                                                                    >
                                                                        <Row
                                                                            justify="space-between"
                                                                            style={{
                                                                                padding: 5,
                                                                            }}
                                                                        >
                                                                            {storeIndex >
                                                                                0 && (
                                                                                <DeleteTwoTone
                                                                                    twoToneColor="hotpink"
                                                                                    onClick={() =>
                                                                                        remove(
                                                                                            field.name,
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Row>
                                                                        {/* Store */}

                                                                        <Row
                                                                            gutter={
                                                                                16
                                                                            }
                                                                        >
                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "Store is required",
                                                                                        },
                                                                                    ]}
                                                                                    name={[
                                                                                        field.name,
                                                                                        "store_id",
                                                                                    ]}
                                                                                    label="Store"
                                                                                >
                                                                                    {/* <Select
                                                                                        loading={
                                                                                            storeLoading
                                                                                        }
                                                                                        placeholder="Please select a store"
                                                                                        options={storeData?.getSelectComponentSelectOptions()}
                                                                                    /> */}

                                                                                    <Select
                                                                                        allowClear
                                                                                        loading={
                                                                                            storeOptionsData.isLoading
                                                                                        }
                                                                                        placeholder="Please select a region"
                                                                                        options={
                                                                                            storeOptionsData.options
                                                                                        }
                                                                                        notFoundContent={
                                                                                            storeOptionsData.isLoading
                                                                                                ? LoaderSmall
                                                                                                : null
                                                                                        }
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "FX rate is required",
                                                                                        },
                                                                                    ]}
                                                                                    label="FX Rate"
                                                                                    name={[
                                                                                        field.name,
                                                                                        "fx_rate",
                                                                                    ]}
                                                                                >
                                                                                    <InputNumber
                                                                                        placeholder="FX Rate"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "Commission rate is required",
                                                                                        },
                                                                                    ]}
                                                                                    label="Commission Rate"
                                                                                    name={[
                                                                                        field.name,
                                                                                        "commission_rate",
                                                                                    ]}
                                                                                >
                                                                                    <InputNumber
                                                                                        placeholder="Commission Rate"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "Commission rate type is required",
                                                                                        },
                                                                                    ]}
                                                                                    label="Commission Rate Type"
                                                                                    name={[
                                                                                        field.name,
                                                                                        "commission_rate_type",
                                                                                    ]}
                                                                                >
                                                                                    <Select
                                                                                        placeholder="Please select a commission rate type"
                                                                                        options={renderSelectOptionsFromEnum(
                                                                                            CommissionRateTypeEnums,
                                                                                        )}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>

                                                                            <Col
                                                                                xs={
                                                                                    24
                                                                                }
                                                                                sm={
                                                                                    24
                                                                                }
                                                                                md={
                                                                                    12
                                                                                }
                                                                                lg={
                                                                                    8
                                                                                }
                                                                            >
                                                                                <Form.Item
                                                                                    rules={[
                                                                                        {
                                                                                            required:
                                                                                                true,
                                                                                            message:
                                                                                                "Minimum order amount is required",
                                                                                        },
                                                                                    ]}
                                                                                    label="Minimum Order Amount"
                                                                                    name={[
                                                                                        field.name,
                                                                                        "options",
                                                                                        "minimum_order_amount",
                                                                                    ]}
                                                                                >
                                                                                    <InputNumber
                                                                                        placeholder="Minimum Order Amount"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                </Form.Item>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Form.Item
                                                                                shouldUpdate
                                                                            >
                                                                                {() => {
                                                                                    const storeValues =
                                                                                        form.getFieldValue(
                                                                                            [
                                                                                                "regional_exchanges",
                                                                                                index,
                                                                                                "store_exchanges",
                                                                                                storeIndex,
                                                                                            ],
                                                                                        );

                                                                                    const fx =
                                                                                        storeValues?.fx_rate;
                                                                                    const commission =
                                                                                        storeValues?.commission_rate;
                                                                                    const commissionType =
                                                                                        storeValues?.commission_rate_type;

                                                                                    if (
                                                                                        fx &&
                                                                                        commission &&
                                                                                        commissionType
                                                                                    ) {
                                                                                        const total =
                                                                                            getStoreCommission(
                                                                                                fx,
                                                                                                commission,
                                                                                                commissionType,
                                                                                            );
                                                                                        return (
                                                                                            <div>
                                                                                                <Tag color="green">
                                                                                                    {
                                                                                                        total
                                                                                                    }{" "}
                                                                                                </Tag>
                                                                                            </div>
                                                                                        );
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                }}
                                                                            </Form.Item>
                                                                        </Row>
                                                                    </Space>
                                                                </Card>
                                                            ),
                                                        )}
                                                        <Form.Item>
                                                            <Button
                                                                style={{
                                                                    width: "150px",
                                                                }}
                                                                type="dashed"
                                                                onClick={() =>
                                                                    add()
                                                                }
                                                                block
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                            >
                                                                Add store
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Space>
                                    </Card>
                                ))}
                                {action !== "update" && (
                                    <Form.Item>
                                        <Button
                                            style={{ width: "150px" }}
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                        >
                                            Add region
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.List>
                    <Form.Item name="is_default" valuePropName="checked">
                        <Checkbox>Set as Default</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isLoading || updateExchangeLoading}
                            >
                                {action === "update" ? "Update" : "Submit"}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        </PageHeader>
    );
};

export default CreateUpdateExchange;
