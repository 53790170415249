import React, { useEffect } from "react";
import { Button, Form, FormInstance, Modal, Select, notification } from "antd";

import { ShippingModesEnums } from "@/enums/shippingEnums";
import { useUpdateShippingMode } from "@/lib/core-react/hooks/private/useShipping";

import { transformEnumToLabeledValue } from "@/utils/helpers";
import { getError } from "@/lib/core-react/hooks/utils/errors";

interface IProps {
    productIds: number[];
    isOpen: boolean;
    onCancel: (isMutate?: boolean) => void;
    existingMode: ShippingModesEnums | undefined;
}

export const ChangeShippingModeModal = ({
    isOpen,
    onCancel,
    productIds,
    existingMode,
}: IProps) => {
    const { updateShippingMode, isLoading } = useUpdateShippingMode();
    const formRef: React.Ref<FormInstance<any>> = React.createRef();
    const [form] = Form.useForm();
    useEffect(() => {
        return () => {
            form.resetFields();
        };
    }, [form]);

    // Ok handler
    const onOkHandler = async (data: { shipping_mode: ShippingModesEnums }) => {
        const payload: {
            shipment_product_ids: number[];
            shipping_mode: ShippingModesEnums;
        } = {
            shipment_product_ids: productIds,
            shipping_mode: data.shipping_mode,
        };

        try {
            const data = await updateShippingMode(payload);
            notification["success"]({
                message: data.message,
            });
            onCancel();
        } catch (error) {
            notification["error"]({
                message: getError(error),
            });
        }
    };

    return (
        <Modal
            title="Change Shipping Mode"
            open={isOpen}
            destroyOnClose={true}
            confirmLoading={isLoading}
            onCancel={() => onCancel()}
            footer={false}
        >
            <Form
                onFinish={onOkHandler}
                form={form}
                layout="vertical"
                name="shipping_mode"
                ref={formRef}
                initialValues={{
                    shipping_mode: existingMode,
                }}
            >
                <Form.Item name="shipping_mode" label="Shipping Mode">
                    <Select
                        loading={isLoading}
                        placeholder="Please select a shipping mode"
                        options={transformEnumToLabeledValue(
                            ShippingModesEnums,
                        )}
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
