export enum DisputeCollectionEnum {
    DiscountRuleCollection = "DisputeCollectionEnum",
    Dispute = "Dispute",
}

export enum DisputeRegionEnum {
    Region = "Region",
    Currency = "Currency",
}

export enum DisputeUserEnum {
    User = "User",
}

export enum DisputeBuyProductEnum {
    BuyProduct = "BuyProduct",
}

export enum DisputeMandatesEnum {
    DisputeMandateCollection = "DisputeMandateCollection",
    DisputeMandate = "DisputeMandate",
}

export enum DisputeItemCollectionEnum {
    DisputeItemCollection = "DisputeItemCollection",
    DisputeItem = "DisputeItem",
    BuyProductVariation = "BuyProductVariation",
    BuyProductVariationPropertiesCollection = "BuyProductVariationPropertiesCollection",
    BuyProductVariationProperty = "BuyProductVariationProperty",
    DisputeAttachment = "DisputeAttachment",
    DisputeAttachmentCollection = "DisputeAttachmentCollection",
}

export enum DisputeItemStatusEnum {
    PENDING = "pending",
    APPROVED = "approved",
    REJECTED = "rejected",
}

export enum DisputeMandateStatusEnum {
    ASSIGNED = "assigned",
    AGREED = "agreed",
}

export enum DisputeStatusEnum {
    PENDING = "pending",
    APPROVED = "approved",
    PROCESSING = "processing",
    COMPLETED = "completed",
}
