import axios from "axios";

export const getErrorCode = (error: unknown): number => {
    // Handle Axios errors
    if (axios.isAxiosError(error) && error.response) {
        return error.response.status || 500; // Use 500 as fallback
    }

    // Handle Fetch API errors (Response objects)
    if (error instanceof Response) {
        return error.status || 500; // Use 500 as fallback
    }

    // Handle generic JavaScript errors or unexpected cases
    return 500; // Default to 500 for all other cases
};
