import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import GenericResource from "../../resources/private/genericResource";

class GenericService extends CommerceApiService {
    public genericResource: GenericResource;
    constructor() {
        super();

        this.genericResource = new GenericResource(this.config);
    }
}

export default GenericService;
