import { useLocation } from "react-router-dom";
import { useGetProductDescription } from "@/lib/core-react/hooks/private/useProduct";
import { useEffect } from "react";
import { Divider } from "antd";
import { StyledProductDetailItemTitle } from "../ProductView/index.styled";
import { defaultQuery } from "@/consts/defaultQuery";

export function ProductDescription({ productId }: { productId: string }) {
    const location = useLocation();
    const region =
        new URLSearchParams(location.search).get("region") ||
        defaultQuery.region;

    const { getProductDescription, data } = useGetProductDescription();

    useEffect(() => {
        if (productId) {
            getProductDescription(
                {
                    apply_exchange: 1,
                    region,
                    locale: defaultQuery.locale,
                },
                productId,
            );
        }
    }, []);

    return (
        <>
            {data ? (
                <div>
                    <Divider />
                    <StyledProductDetailItemTitle>
                        Description
                    </StyledProductDetailItemTitle>
                    <Divider />

                    <div
                        style={{ width: "100%", overflow: "auto" }}
                        dangerouslySetInnerHTML={{ __html: data.data }}
                    />
                </div>
            ) : (
                ""
            )}
        </>
    );
}
