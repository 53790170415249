import { useEffect } from "react";
import { useAtom } from "jotai";
import { Space, Table, Tag, Typography } from "antd";

import { useGetAgentCompanyShippingPoints } from "@/lib/core-react/hooks/private/useAgentCompany";
import { agentCompanyShippingPointCollectionAtom } from "@/lib/core-react/store/store";
import useDataFilters from "@/hooks/useDataFilters";

import { AgentCompanyModel } from "@/models/agentCompanyCollectionModel";
import {
    AgentCompanyShippingPointCollectionModel,
    AgentCompanyShippingPointModel,
} from "@/models/agentCompanyShippingPointCollectionModel";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import CommonError from "@/components/Error/CommonError";

interface IProps {
    data: AgentCompanyModel;
}

export const ShippingPointsTable = ({ data }: IProps) => {
    const { getAgentCompanyShippingPoints } = useGetAgentCompanyShippingPoints(
        data.id,
    );
    const [
        {
            data: agentCompanyShippingPointsCollectionData,
            isLoading,
            refetch,
            error,
            unAuthorized,
        },
    ] = useAtom(agentCompanyShippingPointCollectionAtom);
    const { filters, handleFilterChange, isFirstCall, isFetched } =
        useDataFilters();

    const { isMobile } = useWindowWidth();

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest({}, getAgentCompanyShippingPoints);
        }
    }, [data, filters]);

    const AgentCompanyShippingPoints =
        agentCompanyShippingPointsCollectionData &&
        new AgentCompanyShippingPointCollectionModel(
            agentCompanyShippingPointsCollectionData,
        );

    // Pagination
    const paginationData = AgentCompanyShippingPoints?.pagination;

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAgentCompanyShippingPoints,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        AgentCompanyShippingPoints,
        handlePaginationChange,
    );

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (
                _: string,
                __: AgentCompanyShippingPointModel,
                index: number,
            ) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Shipping Point Details",
            dataIndex: "source_country",
            key: "source_country",
            hidden: !isMobile,
            render: (_: string, record: AgentCompanyShippingPointModel) => {
                return (
                    <>
                        <Space>
                            From:{" "}
                            <img
                                src={record.getSourceCountry().getFlag()}
                                style={{ width: 18, height: 18 }}
                                alt=""
                            />
                            {record.getSourceCountry().getName()}
                        </Space>
                        <br />
                        <Space>
                            To:{" "}
                            <img
                                src={record.getDestinationCountry().getFlag()}
                                style={{ width: 18, height: 18 }}
                                alt=""
                            />
                            {record.getDestinationCountry().getName()}
                        </Space>
                        <Space>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "15px",
                                    flexDirection: "column",
                                }}
                            >
                                {record.getRoutes().map((route, index) => {
                                    return (
                                        <Space key={index + route.type}>
                                            Route:{" "}
                                            <Typography.Text
                                                strong
                                                keyboard
                                                style={{
                                                    textTransform: "capitalize",
                                                }}
                                            >
                                                By {route.getType()}:
                                            </Typography.Text>
                                            {route
                                                .getModes()
                                                .map((mode, index) => (
                                                    <Tag
                                                        key={index + mode}
                                                        color="green"
                                                        style={{
                                                            textTransform:
                                                                "capitalize",
                                                        }}
                                                    >
                                                        {mode}
                                                    </Tag>
                                                ))}
                                        </Space>
                                    );
                                })}
                            </div>
                        </Space>
                    </>
                );
            },
        },
        {
            title: "From",
            dataIndex: "source_country",
            key: "source_country",
            hidden: isMobile,
            render: (_: string, record: AgentCompanyShippingPointModel) => {
                return (
                    <Space align="center">
                        <img
                            src={record.getSourceCountry().getFlag()}
                            style={{ width: 18, height: 18 }}
                            alt=""
                        />
                        {record.getSourceCountry().getName()}
                    </Space>
                );
            },
        },
        {
            title: "To",
            dataIndex: "destination_country",
            key: "destination_country",
            hidden: isMobile,
            render: (_: string, record: AgentCompanyShippingPointModel) => {
                return (
                    <Space align="center">
                        <img
                            src={record.getDestinationCountry().getFlag()}
                            style={{ width: 18, height: 18 }}
                            alt=""
                        />
                        {record.getDestinationCountry().getName()}
                    </Space>
                );
            },
        },
        {
            title: "Routes",
            dataIndex: "routes",
            hidden: isMobile,
            key: "user",
            render: (_: string, record: AgentCompanyShippingPointModel) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            gap: "15px",
                            flexDirection: "column",
                        }}
                    >
                        {record.getRoutes().map((route, index) => {
                            return (
                                <Space key={index + route.type}>
                                    <Typography.Text
                                        strong
                                        keyboard
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        By {route.getType()}:
                                    </Typography.Text>
                                    {route.getModes().map((mode, index) => (
                                        <Tag
                                            key={index + mode}
                                            color="green"
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                        >
                                            {mode}
                                        </Tag>
                                    ))}
                                </Space>
                            );
                        })}
                    </div>
                );
            },
        },
    ];
    if (unAuthorized || error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                hidePageHeader={true}
            />
        );
    }
    return (
        <Table
            bordered
            size="small"
            loading={isLoading}
            columns={columns}
            dataSource={AgentCompanyShippingPoints?.data}
            pagination={paginationConfig}
            rowKey="id"
        />
    );
};
