import { Button, Form, Input, notification, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import React, { useEffect, createRef } from "react";
import { useCreateExchangeGroup } from "@/lib/core-react/hooks/private/useExchangeGroup";
import { IExchangeGroupPostRequest } from "@/types/exchangeGroupCollection";
import { showError } from "@/helpers/showError";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateExchangeGroup = ({ setIsShowCreateModal }: IProps) => {
    const {
        allOptionsData: { exchangeListOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { createExchangeGroup, isLoading } = useCreateExchangeGroup();
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
        onFetchFilterApi(filterResourceEnum.EXCHANGE_LIST);
    }, [form]);

    const onFinish = async (value: IExchangeGroupPostRequest) => {
        const payload = {
            name: value.name,
            exchange_list_id: value.exchange_list_id,
        };

        try {
            const res = await createExchangeGroup(
                payload as IExchangeGroupPostRequest,
            );
            if (res) {
                notification["success"]({
                    message: res.message,
                });
                setIsShowCreateModal(false);
            }
        } catch (e: any) {
            showError(e, form);
        }
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Create Exchange Group"
            ref={formRef}
            style={{ padding: "15px 10px  0px 10px" }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Exchange group name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Exchange group name" />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "Exchange list is required" },
                ]}
                name="exchange_list_id"
                label="Exchange List"
            >
                <Select
                    loading={exchangeListOptionsData.isLoading}
                    placeholder="Please select a exchange list"
                    options={exchangeListOptionsData.options}
                ></Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default CreateExchangeGroup;
