import { IFilterType } from "@/types/filters";
import {
    HarvestBatchCollection,
    IContext,
    IContextFilters,
    IHarvestBatch,
    IHarvestBatchTag,
    IHarvestBatchTagCollection,
    INotificationSettings,
    IResult,
    IResultData,
    ISettings,
} from "@/types/harvestBatchCollection";
import { IPagination } from "@/types/pagination";
import { CustomerUserModel } from "./customerUserCollectionModel";

export class NotificationSettingsModel {
    public readonly sms: boolean;
    public readonly email: boolean;

    constructor(data: INotificationSettings) {
        this.sms = data?.sms;
        this.email = data?.email;
    }

    getSms(): boolean {
        return this.sms;
    }

    getEmail(): boolean {
        return this.email;
    }
}

export class SettingsModel {
    public readonly notification: NotificationSettingsModel;

    constructor(data: ISettings) {
        this.notification = new NotificationSettingsModel(data?.notification);
    }

    getNotification(): NotificationSettingsModel {
        return this.notification;
    }
}

export class ContextFiltersModel {
    public readonly delivery_request_id: number;
    public readonly destination_warehouse_id: number;

    constructor(data: IContextFilters) {
        this.delivery_request_id = data?.delivery_request_id;
        this.destination_warehouse_id = data?.destination_warehouse_id;
    }

    getDeliveryRequestId(): number {
        return this.delivery_request_id;
    }

    getDestinationWarehouseId(): number {
        return this.destination_warehouse_id;
    }
}

export class ContextModel {
    public readonly filters: ContextFiltersModel;

    constructor(data: IContext) {
        this.filters = new ContextFiltersModel(data.filters);
    }

    getFilters(): ContextFiltersModel {
        return this.filters;
    }
}

export class ResultDataModel {
    public readonly download_urls: string[];

    constructor(data: IResultData) {
        this.download_urls = data?.download_urls || [];
    }

    getDownloadUrls(): string[] {
        return this.download_urls;
    }
}

export class ResultModel {
    public readonly data: ResultDataModel;
    public readonly message: string;
    public readonly actionLabel: string;
    public readonly actionValue: string;

    constructor(data: IResult) {
        this.data = new ResultDataModel(data.data);
        this.message = data.message;
        this.actionLabel = data.actionLabel;
        this.actionValue = data.actionValue;
    }

    getData(): ResultDataModel {
        return this.data;
    }

    getMessage(): string {
        return this.message;
    }

    getActionLabel(): string {
        return this.actionLabel;
    }

    getActionValue(): string {
        return this.actionValue;
    }
}

export class HarvestBatchTagModel {
    public readonly object: string;
    public readonly id: number;
    public readonly tag: string;

    constructor(data: IHarvestBatchTag) {
        this.object = data.object;
        this.id = data.id;
        this.tag = data.tag;
    }

    getId(): number {
        return this.id;
    }

    getTag(): string {
        return this.tag;
    }
}

export class HarvestBatchTagCollectionModel {
    public readonly object: string;
    public readonly data: HarvestBatchTagModel[];

    constructor(data: IHarvestBatchTagCollection) {
        this.object = data.object;
        this.data = data.data.map((tag) => new HarvestBatchTagModel(tag));
    }

    getData(): HarvestBatchTagModel[] {
        return this.data;
    }
}

export class HarvestBatchModel {
    public readonly object: string;
    public readonly id: number;
    public readonly batch_number: string;
    public readonly user: CustomerUserModel;
    public readonly type: string;
    public readonly status: string;
    public readonly job_count: number;
    public readonly advancement_count: number;
    public readonly progress: number | null;
    public readonly settings: SettingsModel;
    public readonly context: ContextModel;
    public readonly result: ResultModel | null;
    public readonly tags: HarvestBatchTagCollectionModel;
    public readonly created_at: string;
    public readonly errors: string | null;
    public readonly pre_processed_at: string | null;
    public readonly completed_at: string | null;
    public readonly cancelled_at: string | null;
    public readonly failed_at: string | null;

    constructor(data: IHarvestBatch) {
        this.object = data.object;
        this.id = data.id;
        this.batch_number = data.batch_number;
        this.user = new CustomerUserModel(data.user);
        this.type = data.type;
        this.status = data.status;
        this.job_count = Number(data.job_count);
        this.advancement_count = Number(data.advancement_count);
        this.progress = data.progress ? Number(data.progress) : null;
        this.settings = new SettingsModel(data.settings);
        this.context = new ContextModel(data.context);
        this.result = data.result ? new ResultModel(data.result) : null;
        this.tags = new HarvestBatchTagCollectionModel(data.tags);
        this.created_at = data.created_at;
        this.errors = data.errors ?? null;
        this.pre_processed_at = data.pre_processed_at ?? null;
        this.completed_at = data.completed_at ?? null;
        this.cancelled_at = data.cancelled_at ?? null;
        this.failed_at = data.failed_at ?? null;
    }

    getId(): number {
        return this.id;
    }

    getBatchNumber(): string {
        return this.batch_number;
    }

    getUser(): CustomerUserModel {
        return this.user;
    }

    getType(): string {
        return this.type;
    }

    getStatus(): string {
        return this.status;
    }

    getJobCount(): number {
        return this.job_count;
    }

    getAdvancementCount(): number {
        return this.advancement_count;
    }

    getProgress() {
        return this.progress;
    }

    getSettings(): SettingsModel {
        return this.settings;
    }

    getContext(): ContextModel {
        return this.context;
    }

    getResult(): ResultModel | null {
        return this.result;
    }

    getTags(): HarvestBatchTagCollectionModel {
        return this.tags;
    }

    getCreatedAt(): string {
        return this.created_at;
    }

    getErrors(): string | null {
        return this.errors;
    }

    getPreProcessedAt(): string | null {
        return this.pre_processed_at;
    }

    getCompletedAt(): string | null {
        return this.completed_at;
    }

    getCancelledAt(): string | null {
        return this.cancelled_at;
    }

    getFailedAt(): string | null {
        return this.failed_at;
    }
}

export class HarvestBatchCollectionModel {
    public readonly object: string;
    public readonly data: HarvestBatchModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: HarvestBatchCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new HarvestBatchModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

    getData() {
        return this.data;
    }

    getPagination() {
        return this.pagination;
    }

    getFilters() {
        return this.filters;
    }
}
