import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";

import {
    impersonateSessions,
    impersonateSessionsCreate,
    impersonateSessionsDetails,
    roleAtom,
} from "@/lib/core-react/store/store";
import {
    RolePostRequestModel,
    RoleUpdateRequestModel,
} from "@/models/roleCollectionModel";
import { IDestinationWarehouseRoleForm } from "@/types/roleCollection";
import { notification } from "antd";
import {
    IImpersonateSessionData,
    IImpersonationSession,
} from "@/types/userCollection";
import {
    ImpersonateSessionCollectionModel,
    ImpersonateSessionModel,
} from "@/models/userCollectionModel";

export const useRole = () => {
    const { aclService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [roleData, setRoleData] = useAtom(roleAtom);

    const createRole = async (payload: RolePostRequestModel) => {
        setIsLoading(true);
        try {
            const response = await aclService.aclResource.createRole(payload);
            setIsLoading(false);
            setRoleData({ ...roleData, refetch: true });
            notification["success"]({
                message: response.message || "Create successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createRole, isLoading, isError };
};

export const useUpdateRole = () => {
    const { aclService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");
    const [roleData, setRoleData] = useAtom(roleAtom);

    const updateRole = async (id: number, payload: RoleUpdateRequestModel) => {
        setIsLoading(true);
        try {
            const response = await aclService.aclResource.updateRole(
                id,
                payload,
            );
            setIsLoading(false);
            setRoleData({ ...roleData, refetch: true });

            notification["success"]({
                message: response.message || "Update successfully",
            });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateRole, isLoading, isError };
};

export const useGetRole = () => {
    const [role, setRole] = useAtom(roleAtom);
    const { aclService } = useService();
    const getRole = async (params?: string) => {
        setRole({ ...role, isLoading: true, error: null });
        try {
            const response = await aclService.aclResource.getRoles(params);
            setRole({
                ...role,
                isLoading: false,
                data: response,
                refetch: false,
            });
            return response.data;
        } catch (error: any) {
            setRole({
                ...role,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return { getRole } as const;
};

export const useGetPermissionsByRole = () => {
    const { aclService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);

    const getPermissionsByRole = async (role_id: number) => {
        setIsLoading(true);
        try {
            const response =
                await aclService.aclResource.getPermissionsByRole(role_id);
            setIsLoading(false);
            return response;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { getPermissionsByRole, isLoading, isError };
};

export const useDestinationWarehouseRoleCreate = () => {
    const { aclService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [roleData, setRoleData] = useAtom(roleAtom);

    const createDestinationWarehouseRole = async (
        payload: IDestinationWarehouseRoleForm,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await aclService.aclResource.createDestinationWarehouseRole(
                    payload,
                );
            setIsLoading(false);
            setRoleData({ ...roleData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createDestinationWarehouseRole, isLoading, isError };
};
export const useCreateImpersonateSession = () => {
    const { aclService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [response, setResponse] = useState<IImpersonateSessionData>();
    const [, setSessionData] = useAtom(impersonateSessionsCreate);

    const createImpersonate = async (payload: IImpersonationSession) => {
        setIsLoading(true);
        setSessionData((pre) => ({ ...pre, isLoading: true, data: undefined }));
        try {
            const response =
                await aclService.aclResource.createImpersonateSession(payload);
            setIsLoading(false);
            setResponse(response.data);
            setSessionData((pre) => ({
                ...pre,
                isLoading: false,
                data: response,
            }));
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            setSessionData((pre) => ({
                ...pre,
                isLoading: false,
                data: undefined,
                error: error,
            }));
            throw error;
        }
    };

    return { createImpersonate, isLoading, isError, response };
};
export const useGetImpersonateSessions = () => {
    const [sessions, setSessions] = useAtom(impersonateSessions);
    const { aclService } = useService();

    const getSessions = async (params?: string): Promise<void> => {
        setSessions({ ...sessions, isLoading: true, error: null });
        try {
            const response =
                await aclService.aclResource.getImpersonateSessions(params);
            setSessions({
                ...sessions,
                isLoading: false,
                data: new ImpersonateSessionCollectionModel(response),
                refetch: false,
            });
        } catch (error: any) {
            setSessions({
                ...sessions,
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return { getSessions } as const;
};

export const useGetImpersonateSessionsDetail = () => {
    const [sessions, setSessions] = useAtom(impersonateSessionsDetails);
    const { aclService } = useService();

    const getSessionDetail = async (params?: string): Promise<void> => {
        setSessions({ ...sessions, isLoading: true, error: null });
        try {
            const response =
                await aclService.aclResource.getImpersonateSessionsDetails(
                    params,
                );
            setSessions({
                ...sessions,
                isLoading: false,
                data: new ImpersonateSessionModel(response),
                refetch: false,
            });
        } catch (error: any) {
            setSessions({
                ...sessions,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getSessionDetail } as const;
};
