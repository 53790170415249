import { useEffect, useMemo, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { useAtomValue, useSetAtom } from "jotai";
import useDataFilters from "@/hooks/useDataFilters";
import { PaginationModel } from "@/models/pagination";
import { ApiHelperModel } from "@/models/apiHelper";
import { IFilterType } from "@/types/filters";

import FiltersComponent from "@/components/FiltersComponent";
import { HarvestActionEnums } from "@/enums/harvestJobCollectionEnums";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd/lib";
import { BREAK_POINTS } from "@/consts/appConstants";
import {
    Alert,
    Button,
    Card,
    Dropdown,
    Flex,
    Form,
    Modal,
    notification,
    Tag,
    Tooltip,
    Typography,
} from "antd";
import ShippingText from "@/components/ShippingText";
import { StatusTag } from "@/components";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { useGetShipmentProductsForInvoice } from "@/lib/core-react/hooks/private/useShipping";
import ShipmentProductInfo from "../../newComponents/ShipmentProductInfo";
import { shipmentProductsInvoiceAtom } from "@/lib/core-react/store/store";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";

import InvoiceInfoShipmentProduct from "./InvoiceInfoShipmentProduct";
import InvoiceSummaryTransaction from "@/pages/Shipping/ShipmentProducts/InvoiceSummary/InvoiceSummaryTransaction";
import useActionsProps from "@/helpers/useActionsProps";
import useActionsInvoice from "@/pages/Shipping/ShipmentProducts/InvoiceSummary/useActionsInvoice";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import { RegionModel } from "@/models/regionCollectionModel";
import { HddFilled } from "@ant-design/icons";
import { showError } from "@/helpers/showError";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import CommonError from "@/components/Error/CommonError";

const InvoiceSummaryShipmentProducts = () => {
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();
    const {
        data: shipmentProductInvoiceCollection,
        isLoading,
        refetch,
        error,
        code,
    } = useAtomValue(shipmentProductsInvoiceAtom);
    const setShipmentProductsInvoice = useSetAtom(shipmentProductsInvoiceAtom);
    const { isMobile } = useWindowWidth();
    const actionsProps = useActionsProps();

    const { getShipmentProductsInvoice } = useGetShipmentProductsForInvoice();

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<ShipmentProductModel[]>(
        [],
    );
    const { paySession, isLoading: isLoadingInvoice } = useInvoice();

    const [form] = Form.useForm();

    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
    }>();
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);

    const handleModal = async (payload: IModalData) => {
        try {
            setModalOpenType(payload.action);
            switch (payload.action) {
                case "pay-invoice":
                    setSelectedPayInvoiceData(payload.data);
                    break;
                case "refetch":
                    setShipmentProductsInvoice((prev) => ({
                        ...prev,
                        refetch: true,
                    }));
                    break;
                default:
                    break;
            }
        } catch (error) {
            showError(error);
        }
    };

    const onCancel = () => {
        setModalOpenType(false);
        setSelectedPayInvoiceData(undefined);
    };

    const agentInvoiceIds = useMemo(() => {
        return selectedRows.reduce<number[]>((acc, row) => {
            const id = row.getAgentInvoiceId();
            if (id !== undefined) {
                acc.push(id);
            }
            return acc;
        }, []);
    }, [selectedRows]);

    const { getActions, getBulkActions } = useActionsInvoice({
        handleModal,
        agentInvoiceIds,
    });

    const onClearBulkSelectedState = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
    };

    const onChangeRow = (
        selectedRowKeys: React.Key[],
        rows: ShipmentProductModel[],
    ) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(rows);
    };

    //----------------------------- Api Call-----------------------------
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getShipmentProductsInvoice);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    const filterData = shipmentProductInvoiceCollection?.filters;

    useEffect(() => {
        if (!isFetched && filterData) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, filterData]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getShipmentProductsInvoice,
        );
        onClearBulkSelectedState();
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        shipmentProductInvoiceCollection,
        handlePaginationChange,
    );

    // Filter Handler
    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getShipmentProductsInvoice);
        onClearBulkSelectedState();
    };

    //-------------------------- Logic---------------------------------

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, wallet_id, ...restValues } = values;
                if (
                    typeof wallet_id === "undefined" &&
                    typeof gateway_id === "undefined"
                ) {
                    notification.error({
                        message: "Please select a gateway or wallet",
                    });
                    return;
                }

                const key = gateway_id ? "gateway_id" : "wallet_id";
                const id = gateway_id || wallet_id;

                const payload = {
                    amount,
                    [key]: id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                ApiHelperModel.makeGetRequest(
                    filters,
                    getShipmentProductsInvoice,
                );
                setSelectedPayInvoiceData(undefined);
                setSelectedRowKeys([]);
                setSelectedRows([]);
                showSuccessAlert("Payment initiated successfully");
                onCancel();
            } catch (error) {
                showError(error, form);
            }
        }
    };

    // --------------------------------ui------------------------------

    const columns: ColumnsType<ShipmentProductModel> = useMemo(() => {
        return [
            {
                title: "Product Info",
                key: "product_info",

                onCell: () => ({
                    style: { verticalAlign: "top" },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <ShipmentProductInfo
                                isDetailPage={false}
                                handleModal={() => {}}
                                shipmentProductModel={record}
                            />
                        </>
                    );
                },
            },
            {
                title: "Invoice",
                key: "invoice",
                onCell: () => ({
                    style: { verticalAlign: "top" },
                }),
                render: (_, shipmentProduct) => {
                    return (
                        <>
                            <InvoiceInfoShipmentProduct
                                shipmentProduct={shipmentProduct}
                            />
                        </>
                    );
                },
            },

            {
                title: "Profit and Price",
                key: "profit-and-price",
                align: "start",
                width: 220,
                onCell: () => ({
                    style: { verticalAlign: "top" },
                }),
                render: (_, shipmentProduct) => {
                    return (
                        <>
                            <Flex vertical gap={12} style={{ marginTop: 12 }}>
                                {shipmentProduct.getProfit() > 0 ? (
                                    <ShippingText label="Profit">
                                        <StatusTag
                                            color="green-inverse"
                                            slug=""
                                            text={`${roundToTwoDecimal(
                                                shipmentProduct.getProfit(),
                                            )} ${shipmentProduct.getRegionCurrencyCode()}`}
                                        />
                                    </ShippingText>
                                ) : shipmentProduct.getProfit() === 0 ? (
                                    <></>
                                ) : (
                                    <ShippingText label="Loss">
                                        <StatusTag
                                            color="red-inverse"
                                            slug=""
                                            text={`${roundToTwoDecimal(
                                                shipmentProduct.getProfit(),
                                            )} ${shipmentProduct.getRegionCurrencyCode()}`}
                                        />
                                    </ShippingText>
                                )}
                                {shipmentProduct.getBuyingPrice() ? (
                                    <ShippingText label="Buying Price">
                                        <StatusTag
                                            color="orange"
                                            slug=""
                                            text={`${roundToTwoDecimal(
                                                shipmentProduct.getBuyingPrice(),
                                            )} ${shipmentProduct.getRegionCurrencyCode()}`}
                                        />
                                    </ShippingText>
                                ) : (
                                    <></>
                                )}
                                {shipmentProduct.getShippingPrice() ? (
                                    <ShippingText label="Shipping Price">
                                        <StatusTag
                                            color="blue"
                                            slug=""
                                            text={`${roundToTwoDecimal(
                                                shipmentProduct.getShippingPrice(),
                                            )} ${shipmentProduct.getRegionCurrencyCode()}`}
                                        />
                                    </ShippingText>
                                ) : (
                                    <></>
                                )}

                                {shipmentProduct.getShippingCompanyName() ? (
                                    <ShippingText label="Ship By">
                                        <Typography.Text strong>
                                            <Tooltip
                                                title={shipmentProduct.getShippingCompanyName()} // Full name in tooltip
                                            >
                                                <Tag
                                                    color="blue-inverse"
                                                    style={{
                                                        maxWidth: "110px", // Set maximum width as needed
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                            "ellipsis",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {shipmentProduct.getShippingCompanyName()}{" "}
                                                </Tag>
                                            </Tooltip>
                                        </Typography.Text>
                                    </ShippingText>
                                ) : (
                                    <></>
                                )}
                            </Flex>
                        </>
                    );
                },
            },
            {
                title: "Transaction",
                key: "transaction",
                align: "start",

                onCell: () => ({
                    style: { verticalAlign: "top" },
                }),
                render: (_, shipmentProduct) => {
                    if (
                        !shipmentProduct
                            .getBuyProduct()
                            ?.getAgentInvoice()
                            ?.hasTransactions() &&
                        !shipmentProduct.getAgentInvoice()?.hasTransactions()
                    ) {
                        return (
                            <Alert
                                message="Transaction Not Available"
                                type="warning"
                                showIcon
                            />
                        );
                    }

                    return (
                        <>
                            <Flex vertical gap={12}>
                                {shipmentProduct.getBuyProduct() &&
                                shipmentProduct
                                    .getBuyProduct()
                                    ?.getCustomerInvoice()
                                    ?.hasTransactions() ? (
                                    <Card title="Customer Transaction">
                                        <InvoiceSummaryTransaction
                                            transaction={
                                                shipmentProduct
                                                    .getAgentInvoice()
                                                    ?.getTransactions() || []
                                            }
                                        />
                                    </Card>
                                ) : (
                                    <>
                                        <Alert
                                            message="Customer Transaction Not Available"
                                            type="warning"
                                            showIcon
                                        />
                                    </>
                                )}

                                {shipmentProduct
                                    .getAgentInvoice()
                                    ?.hasTransactions() ? (
                                    <Card title="Agent Transaction">
                                        <InvoiceSummaryTransaction
                                            transaction={
                                                shipmentProduct
                                                    .getAgentInvoice()
                                                    ?.getTransactions() || []
                                            }
                                        />
                                    </Card>
                                ) : (
                                    <>
                                        <Alert
                                            message="Agent Transaction Not Available"
                                            type="warning"
                                            showIcon
                                        />
                                    </>
                                )}
                            </Flex>
                        </>
                    );
                },
            },
            {
                ...actionsProps,

                onCell: () => ({ style: { padding: 2.5 } }),
                render: (_, record) => {
                    if (!record.getBuyProduct()) {
                        return <></>;
                    }
                    return (
                        <Dropdown
                            menu={{
                                items: getActions(
                                    record.getBuyProduct() as BuyProductModel,
                                    record,
                                ),
                            }}
                        >
                            <Button type="primary" icon={<HddFilled />} />
                        </Dropdown>
                    );
                },
            },
        ];
    }, []);

    if (error) {
        return <CommonError message={error} code={code} />;
    }
    return (
        <PageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Shipment Products Invoice Summary"
            style={{ marginTop: "10px" }}
        >
            <FiltersComponent
                isLoading={isLoading}
                handleProductFilter={handleFilter}
                handleFilterChange={handleFilterChange}
                handelFilterClear={() => {
                    handelFilterClear();
                    onClearBulkSelectedState();
                }}
                isFetched={isFetched}
                filters={filters}
                filtersData={filterData}
                isFromProductReceived={true}
                harvestKey={
                    HarvestActionEnums.SHIPMENT_BUY_PRODUCT_EXPORT_HARVEST
                }
            />
            <Flex gap={8} align="center">
                <Dropdown
                    menu={{
                        items: getBulkActions(),
                    }}
                    disabled={selectedRowKeys.length === 0}
                >
                    <Button disabled={selectedRowKeys.length === 0}>
                        Bulk Actions
                    </Button>
                </Dropdown>
                {selectedRows.length > 0 ? (
                    <Typography.Text>
                        Selected : {selectedRows.length}
                    </Typography.Text>
                ) : (
                    <></>
                )}
            </Flex>

            <Table
                bordered
                style={{
                    backgroundColor: "white",
                    marginTop: 16,
                    width: "100%",
                }}
                rowHoverable={false}
                rowSelection={{
                    type: "checkbox",
                    selectedRowKeys,
                    columnWidth: 60,
                    fixed: true,
                    onChange: onChangeRow,

                    getCheckboxProps: (record) => ({
                        disabled: !record.getAgentInvoice(),
                    }),
                }}
                loading={isLoading}
                dataSource={shipmentProductInvoiceCollection?.getData()}
                columns={columns}
                rowKey={(r) => r.getId()}
                pagination={{
                    ...paginationConfig,
                    showTotal: (total) => (
                        <div>
                            Total{" "}
                            <span
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "18px",
                                    color: "green",
                                }}
                            >
                                {total}
                            </span>{" "}
                            Items
                        </div>
                    ),
                }}
                scroll={{ x: BREAK_POINTS.XL }}
            />

            {selectedPayInvoiceData && (
                <Modal
                    width={isMobile ? "100%" : "60%"}
                    title="Pay Customer Invoice"
                    open={modalOpenType === "pay-invoice"}
                    onCancel={onCancel}
                    onOk={form.submit}
                    okText="Submit"
                    okButtonProps={{ loading: isLoadingInvoice }}
                    centered
                    destroyOnClose={true}
                >
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                </Modal>
            )}
        </PageHeader>
    );
};

export default InvoiceSummaryShipmentProducts;
