import { atom } from "jotai";

export interface IProductSearchQuery {
    shops: string[];
    seller_names: string[];
    category_names: string[];
    country_codes: string[];
    brands: string[];
    rating_avg: {
        min: number | null;
        max: number | null;
    };

    price: {
        aggregated: {
            min: number | null;
            max: number | null;
        };
    };
    image_url: string;
    provider: "inventory";
}

const DEFAULT_COUNTRY_CODE = "CN"; //FIXME make sure what country code give for default data

export const initialProductSearchQuery: IProductSearchQuery = {
    shops: [],
    seller_names: [],
    category_names: [],
    country_codes: [DEFAULT_COUNTRY_CODE],
    brands: [],
    rating_avg: {
        min: null,
        max: null,
    },
    price: {
        aggregated: {
            min: null,
            max: null,
        },
    },
    image_url: "",
    provider: "inventory",
};

export const productSearchQueryAtom = atom<IProductSearchQuery>(
    initialProductSearchQuery,
);
