import { Button, Form } from "antd";
import { FormInstance } from "antd/lib";
import { UserSelect } from "@/components/CustomFormElement/UserSelect";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { useDebounce } from "@/hooks/useDebounce";

import React, { createRef, useEffect, useState } from "react";
interface IProps {
    addCustomerHandler: (
        value: {
            user: number;
        },
        form: FormInstance<any>,
    ) => Promise<void>;
    loading: boolean;
}
const AddUserToInvoiceRules = ({ addCustomerHandler, loading }: IProps) => {
    const [form] = Form.useForm();
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const {
        allOptionsData: { customerOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.CUSTOMER, {
            per_page: 100,
            keyword: debouncedSearchTerm,
        });
    }, []);

    useEffect(() => {
        if (debouncedSearchTerm) {
            onFetchFilterApi(filterResourceEnum.CUSTOMER, {
                per_page: 100,
                keyword: debouncedSearchTerm,
            });
        } else {
            onFetchFilterApi(filterResourceEnum.CUSTOMER, {
                per_page: 100,
                keyword: debouncedSearchTerm,
            });
        }
    }, [debouncedSearchTerm]);

    const handleSearch = (q: any) => {
        setSearchTerm(q);
    };

    return (
        <div>
            <Form
                form={form}
                onFinish={(value: { user: number }) =>
                    addCustomerHandler(value, form)
                }
                layout="vertical"
                name="User-Form"
                ref={formRef}
            >
                <Form.Item
                    label="Select User"
                    name="user"
                    rules={[
                        {
                            required: true,
                            message: "Please select the user!",
                        },
                    ]}
                    required
                >
                    <UserSelect
                        initialData={customerOptionsData.options as any[]}
                        loading={customerOptionsData.isLoading}
                        resource={""}
                        onSearch={async (value) => {
                            handleSearch(value);
                        }}
                        allowClear={true}
                        showSearch={true}
                        placeholder={"Search users by email or name"}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                <Form.Item>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Button
                            loading={loading}
                            type="primary"
                            htmlType="submit"
                        >
                            Add customer
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default AddUserToInvoiceRules;
