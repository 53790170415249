import { useAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import {
    transactionCollectionAtom,
    transactionDetailAtom,
} from "@/lib/core-react/store/store";
import { getError } from "../utils/errors";
import { IUpdateStatus } from "@/types/transactionCollection";
import { useState } from "react";

export const useTransactions = () => {
    const [transaction, setTransaction] = useAtom(transactionCollectionAtom);

    const { paymentService } = useService();

    const getTransactions = async (params?: string): Promise<void> => {
        setTransaction({ ...transaction, isLoading: true, error: null });
        try {
            const response =
                await paymentService.transactionResource.get(params);
            setTransaction({
                ...transaction,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setTransaction({
                ...transaction,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };

    return { getTransactions: getTransactions } as const;
};

export const useTransactionUpdateStatus = () => {
    const { paymentService } = useService();
    const [loading, setLoading] = useState(false);

    const getTransactionsUpdateStatus = async (
        params?: string,
    ): Promise<any> => {
        try {
            setLoading(true);
            const response =
                await paymentService.transactionResource.get(params);
            return response;
        } catch (error: any) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return { getTransactionsUpdateStatus, loading } as const;
};

export const useTransactionsDetail = () => {
    const [transaction, setTransaction] = useAtom(transactionDetailAtom);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { paymentService } = useService();
    const [isError, setError] = useState<string>("");

    const getTransactionsDetail = async (
        transaction_id: string,
    ): Promise<void> => {
        setTransaction({ ...transaction, isLoading: true, error: null });
        setIsLoading(true);
        try {
            const response = await paymentService.transactionResource.getDetail(
                Number(transaction_id),
            );
            setTransaction({
                ...transaction,
                isLoading: false,
                data: response.data,
                refetch: false,
            });
            setIsLoading(false);
        } catch (error: any) {
            setTransaction({
                ...transaction,
                isLoading: false,
                refetch: false,
                error: getError(error),
                code: error?.response?.status,
                unAuthorized: error.response?.status === 403,
            });
            setIsLoading(false);
            setError(getError(error));
            throw error;
        }
    };

    return { getTransactionsDetail, isLoading, isError } as const;
};

export const useUpdateStatus = () => {
    const [, setTransaction] = useAtom(transactionCollectionAtom);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string>("");

    const { paymentService } = useService();

    const updateStatus = async (id: number, payload: IUpdateStatus) => {
        setIsLoading(true);
        try {
            const response =
                await paymentService.transactionResource.updateStatus(
                    id,
                    payload,
                );
            setIsLoading(false);
            setTransaction((pre) => ({ ...pre, refetch: true }));
            return response.data;
        } catch (error) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };
    return { updateStatus, isLoading, isError };
};
