import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_RFQ_PERMISSION_ENUM } from "@/consts/permission-enum/RFQ-enum";
import { IModalData } from "@/helpers/getModalTital";
import { RfqRequestModel } from "@/models/rfqRequestCollectionModel";
import { ExtendedMenuItemType } from "@/types";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
interface IActionsProps {
    handleModal: (payload: IModalData) => void;
}
const useActions = ({ handleModal }: IActionsProps) => {
    const getActions = (rfqRequest: RfqRequestModel) => {
        const items: ExtendedMenuItemType[] = [
            {
                permission: ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                icon: <EditOutlined />,
                key: "approve",
                isVisible: !["closed", "approved"].includes(
                    rfqRequest.getStatus(),
                ),
                label: "Approve",
                onClick: () => {
                    handleModal({
                        action: "rfq-approve",
                        data: rfqRequest,
                    });
                },
            },
            {
                permission: ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                icon: <DeleteOutlined />,
                key: "reject",
                isVisible: rfqRequest.getStatus() !== "closed",
                label: "Reject",
                onClick: () => {
                    handleModal({
                        action: "rfq-reject",
                        data: rfqRequest,
                    });
                },
            },
            {
                permission: ADMIN_RFQ_PERMISSION_ENUM.ADMIN_RFQ_MANAGE,
                icon: <EditOutlined />,
                key: "update",
                isVisible: rfqRequest.isEditable(),
                label: "Full Edit",
                onClick: () => {
                    handleModal({
                        action: "rfq-update",
                        data: rfqRequest,
                    });
                },
            },
        ];

        const filterItemsVisible = items.filter((item2) => item2.isVisible);

        return filterItemsVisible.filter((x) =>
            checkActionPermission(x.permission, x, null),
        );
    };

    return { getActions };
};

export default useActions;
