import React from "react";

const Separator = ({ label = "original" }: { label?: string }) => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.125rem",
                }}
            >
                <div
                    style={{
                        height: "1px",
                        width: "100%",
                        borderRadius: "9999px",
                        backgroundColor: "gray",
                    }}
                ></div>
                <div style={{ fontSize: "10px" }}>{label}</div>
                <div
                    style={{
                        height: "1px",
                        width: "100%",
                        borderRadius: "9999px",
                        backgroundColor: "gray",
                    }}
                ></div>
            </div>
        </>
    );
};

export default Separator;
