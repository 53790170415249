const rejectReasonOptions = [
    {
        label: "Non-Compliance with Requirements",
        value: "Non-Compliance with Requirements : The submitted quotation did not meet the specific requirements outlined in the RFQ, such as technical specifications, deliverables, or deadlines",
    },
    {
        label: "Incomplete Documentation",
        value: "Incomplete Documentation: The submission was missing key documentation, like a cover letter, detailed cost breakdown, or references, leading to incomplete evaluation.",
    },
    {
        label: "Pricing Issues",
        value: "Pricing Issues: The proposed costs were either significantly higher than budgeted or lacked a clear and reasonable breakdown of expenses.",
    },
    {
        label: "Lack of Relevant Experience",
        value: "Lack of Relevant Experience: The vendor's prior experience and case studies did not demonstrate sufficient expertise or capability in the specific area outlined in the RFQ.",
    },
    {
        label: "Late Submission",
        value: "The quotation was received after the specified deadline, disqualifying it from consideration in the evaluation process.",
    },
    {
        label: "others",
        value: "others",
    },
];

export { rejectReasonOptions };
