import BaseResource from "../public/baseResource";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import {
    IAddCampaignProductsPostRequest,
    ICreateCampaignPayload,
    IDeleteCampaignProducts,
} from "@/types/campaignCollection";
import QueryString from "qs";
import { IAddCampaignProductRawPayload } from "@/types/campaignPayload";

class CampaignResource extends BaseResource {
    path = `/api/campaign/admin/campaign/v1/campaigns`;

    getCampaign(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}?expand=region,store`;
        if (params) {
            path = `${this.path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    createCampaign(
        payload: ICreateCampaignPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    updateCampaign(
        id: string,
        payload: ICreateCampaignPayload,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    updatePause(
        id: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}/pause`;
        return this.client.request("PUT", path, {}, undefined, customHeaders);
    }

    updateResume(
        id: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}/resume`;
        return this.client.request("PUT", path, {}, undefined, customHeaders);
    }

    delete(
        id: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request(
            "DELETE",
            path,
            {},
            undefined,
            customHeaders,
        );
    }

    // Campaign product list
    async getCampaignProducts(
        id: string,
        params?: Record<string, any>,
    ): Promise<any> {
        let path = `${this.path}/${id}/products`;
        if (params) {
            path = `${this.path}/${id}/products?${QueryString.stringify(params)}`;
        }
        return this.client.request("GET", path, undefined, undefined, {});
    }

    deleteCampaignProducts(
        id: string,
        payload: IDeleteCampaignProducts,
    ): ResponsePromise {
        const path = `${this.path}/${id}/products`;
        return this.client.request("DELETE", path, payload, {}, {});
    }

    // Add product to campaign
    async addCampaignProducts(
        id: string,
        locale: string,
        region: string,
        payload: IAddCampaignProductsPostRequest,
    ): Promise<any> {
        const path = `${this.path}/${id}/products-by-id?locale=${locale}&region=${region}`;
        return this.client.request("POST", path, payload, {});
    }

    async postAddCampaignProductRaw(
        campaignId: string,
        locale: string,
        region: string,
        payload: IAddCampaignProductRawPayload,
    ): Promise<any> {
        const path = `${this.path}/${campaignId}/products-raw?locale=${locale}&region=${region}`;
        return this.client.request("POST", path, payload, {});
    }
}

export default CampaignResource;
