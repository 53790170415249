import {
    DestinationWarehouseMemberStatus,
    DestinationWarehouseStatusEnum,
} from "@/enums/destinationWarehouseCollectionEnums";
import { IFilterType } from "./filters";
import { IPagination } from "./pagination";
import { ICommonUser } from "./shared";
import { ICountry } from "./countryCollection";
import { IRegion } from "./regionCollection";
import { IShippingAddress } from "./shipmentProductCollection";

export enum DestinationWarehouseModalType {
    Create_Destination_Warehouse = "createDestinationWarehouse",
    Update_Destination_Warehouse = "updateDestinationWarehouse",
    Add_Role = "AddRole",
    Send_Invite = "SendInvite",
    Invitations = "invitations",
    Members = "members",
    Add_Address = "AddAddress",
    Addresses = "addresses",
}

export enum DestinationWarehouseMemberModalType {
    Update_Member = "update",
}

export enum DestinationWarehouseAddressModalType {
    Update_Address = "updateAddress",
    Add_Address = "AddAddress",
}

export interface IDestinationWarehouseMembershipInvitation {
    object: string;
    id: number;
    user_email: string;
    roles: IDestinationWarehouseRoleCollection;
    status: string;
    expires_at: string;
}

export interface IDestinationWarehouseMembershipInvitationCollection {
    object: string;
    data: IDestinationWarehouseMembershipInvitation[];
    pagination: IPagination;
}

export interface IDestinationWarehouseRole {
    object: string;
    id: number;
    name: string;
    label: string;
}

export interface IDestinationWarehouseRoleCollection {
    object: string;
    data: IDestinationWarehouseRole[];
}
export interface IDestinationWarehouseMember {
    object: string;
    id: number;
    user: ICommonUser;
    status: string;
    member_since: string;
    roles: IDestinationWarehouseRoleCollection;
}

export interface IDestinationWarehouseMemberCollection {
    object: string;
    data: IDestinationWarehouseMember[];
    pagination: IPagination;
}

export interface IDestinationWarehouse {
    object: string;
    id: number;
    slug: string;
    name: string;
    status: string;
    country?: ICountry;
    region?: IRegion;
    created_at: string;
    default_shipping_address?: IShippingAddress;
}

export interface IDestinationWarehouseCollection {
    object: string;
    data: IDestinationWarehouse[];
    pagination?: IPagination;
    filters?: IFilterType;
}

// Form
export interface IDestinationWarehouseForm {
    name: string;
    slug: string;
    status: DestinationWarehouseStatusEnum;
    country_id: string;
    region_id: number;
}

export interface IDestinationWarehouseMemberForm {
    status: DestinationWarehouseMemberStatus;
    role_ids: number[];
}

export interface IDestinationWarehouseMemberInvitationForm {
    user_email: string;
    role_ids: number[];
}

export interface IDestinationWarehouseAddressForm {
    destination_warehouse_id: number;
    address: Record<string, string>;
}
