import { Row, Col, Button } from "antd";
import OriginalProductLink from "./FindOriginalLink";
import FilterSettings from "./BuyProductSettings";
import { Card } from "antd";
import { FilterStorageKeys } from "@/consts/AppEnums";
import { PageHeader } from "@ant-design/pro-layout";
import { useNavigate } from "react-router-dom";
import {
    buyOrderFilters,
    buyProductFilters,
    shipmentOrderFilters,
    shipmentProductFilter,
} from "./filtersOptions";
import GetProductLink from "./GetProductLink";
import { LinkOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const Tools = () => {
    const navigate = useNavigate();

    return (
        <PageHeader
            onBack={() => navigate(-1)}
            title={"Find Original Product Link"}
        >
            <Row style={{ marginTop: "20px" }} gutter={24}>
                {/* First Column */}
                <Col xs={24} sm={22} md={10} lg={12}>
                    <Card>
                        <OriginalProductLink />
                    </Card>

                    <GetProductLink />

                    <Card style={{ marginTop: "24px" }} title="Cost Calculator">
                        <Link to="/service/tools/cost-calculator">
                            <Button type="link">
                                Go to Cost Calculator <LinkOutlined />
                            </Button>
                        </Link>
                    </Card>
                </Col>
                <Col xs={22} sm={22} md={10} lg={10}>
                    <Card
                        title="Filter settings"
                        style={{
                            borderRadius: "8px",
                            overflow: "hidden",
                            marginLeft: "10px",
                        }}
                    >
                        <FilterSettings
                            heading="Buy product filters"
                            storageKey={FilterStorageKeys.BuyProducts}
                            initialFilters={buyProductFilters}
                            defaultCollapsed={true}
                        />

                        <FilterSettings
                            heading="Buy order filters"
                            storageKey={FilterStorageKeys.BuyOrders}
                            initialFilters={buyOrderFilters}
                            defaultCollapsed={true}
                        />

                        <FilterSettings
                            heading="Shipment product filters"
                            storageKey={
                                FilterStorageKeys.ShipmentProductFilters
                            }
                            initialFilters={shipmentProductFilter}
                            defaultCollapsed={true}
                        />

                        <FilterSettings
                            heading="Shipment order filters"
                            storageKey={FilterStorageKeys.ShipmentOrderFilters}
                            initialFilters={shipmentOrderFilters}
                            defaultCollapsed={true}
                        />
                    </Card>
                </Col>
            </Row>
        </PageHeader>
    );
};

export default Tools;
