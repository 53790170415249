export class SharedModel {
    static slugMaker(text: string | undefined): string {
        if (text) {
            return text
                .trim()
                .replace(/\s+/g, " ")
                .split(" ")
                .join("-")
                .toLowerCase();
        }
        return "";
    }
}
