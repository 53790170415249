import styled from "@emotion/styled";

export function SeenAllBlock() {
    return <Wrapper>Yay! You have seen it all</Wrapper>;
}

const Wrapper = styled.div`
    text-align: center;
    margin: 10px 0;
    font-size: 10px;
    color: #b1b1b1;
`;
