import { Col, Form, Input, Row, Select } from "antd";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import { SingleChunkUpload } from "@/components/SingleChunkUpload";
import {
    PayoutGatewaySlugEnum,
    PayoutGatewayTypeEnum,
} from "@/enums/payoutGatewayEnums";
import { PayoutGatewayModel } from "@/models/payoutGatewayCollectionModel";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { useEffect } from "react";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";

interface IProps {
    data?: PayoutGatewayModel;
    form: any;
    onFinish: (values: any) => Promise<void>;
}

export const PayoutGatewayModification = ({ data, form, onFinish }: IProps) => {
    const {
        allOptionsData: { countryOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.COUNTRY);
    }, []);

    useEffect(() => {
        if (data) {
            form.setFieldValue(
                "country_id",
                countryOptionsData.options?.find(
                    (opt) => opt.value === data.getCountry()?.getId(),
                ),
            );
        }

        return () => {
            form.resetFields();
        };
    }, [data, countryOptionsData.isLoading, form]);
    const SmallLogoUrl =
        data
            ?.getLogo()
            ?.getData()
            .map((logo) => logo.getSmall()?.url)
            .find((url) => url) || "";
    const MediumlogoUrl =
        data
            ?.getLogo()
            ?.getData()
            .map((logo) => logo.getMedium()?.url)
            .find((url) => url) || "";
    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Add Payout Gateway"
            initialValues={{
                name: data?.getName(),
                slug: data?.getSlug(),
                country: data?.getCountry()?.getId(),
                type: data?.getType(),
                small_logo: SmallLogoUrl,
                medium_logo: MediumlogoUrl,
            }}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Payout gateway name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="Payout gateway name" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Slug is required" }]}
                name="slug"
                label="Slug"
            >
                <Select
                    placeholder="Please select a slug"
                    options={transformEnumToLabeledValue(PayoutGatewaySlugEnum)}
                />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Country is required" }]}
                name="country"
                label="Country"
            >
                <Select
                    loading={countryOptionsData.isLoading}
                    placeholder="Please select a country"
                    onChange={(value) => {
                        form.setFieldValue("country_id", value);
                    }}
                    options={countryOptionsData.options}
                />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Type is required" }]}
                name="type"
                label="Type"
            >
                <Select
                    placeholder="Please select a type"
                    options={transformEnumToLabeledValue(PayoutGatewayTypeEnum)}
                />
            </Form.Item>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="small_logo"
                        label="Small Size Logo"
                        rules={[
                            { required: true, message: "Logo is required" },
                        ]}
                    >
                        <SingleChunkUpload
                            form={form}
                            accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                            fieldName="small_logo"
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="medium_logo"
                        label="Medium Size Logo"
                        rules={[
                            { required: true, message: "Logo is required" },
                        ]}
                    >
                        <SingleChunkUpload
                            form={form}
                            accept="image/jpeg,image/png,image/jpg,image/gif,image/webp"
                            fieldName="medium_logo"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
