import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";
import { IAgentCompany } from "@/types/agentCompanyCollection";

export const useGetAgentCompanyMinimal = () => {
    const { shippingAgentService } = useService();
    const [agentCompanyOptionsData, setAgentCompanyOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getAgentCompanies = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setAgentCompanyOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await shippingAgentService.agentCompanyResource.get(params);

            const options =
                response && response.data
                    ? new SelectOptionModel<IAgentCompany>(
                          response.data,
                      ).getOptionsByLabelKey("primary_name")
                    : [];

            setAgentCompanyOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setAgentCompanyOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getAgentCompanies, agentCompanyOptionsData } as const;
};
