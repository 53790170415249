import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Result, Row, Skeleton } from "antd";
import { useAtom } from "jotai";
import { agentWalletOverviewCollectionAtom } from "@/lib/core-react/store/store";
import { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useAgentWalletOverviewBalance } from "@/lib/core-react/hooks/private/useWallet";
import { WalletOverviewModel } from "@/models/walletOverviewCollectionModel";
import { useNavigate, useParams } from "react-router-dom";
import WalletCard from "../WalletCard";
import WalletTable from "./components/WalletTable";

const WalletOverviewForAgentCompany = () => {
    const { getAgentWalletOverviewBalance, isError, isLoading } =
        useAgentWalletOverviewBalance();
    const { id } = useParams();
    const navigate = useNavigate();

    const [{ data: walletOverviewData }, setWalletOverviewBalance] = useAtom(
        agentWalletOverviewCollectionAtom,
    );
    const [walletId, setWalletId] = useState<number | undefined>(undefined);
    const [currencyCode, setCurrencyCode] = useState<string | undefined>(
        undefined,
    );
    const latestWalletIdRef = useRef(walletId);

    // Effect to fetch wallet overview balance when component mounts or when `id` changes
    useEffect(() => {
        if (id) {
            setWalletOverviewBalance((prev) => ({ ...prev, data: undefined }));
            setWalletId(undefined); // Reset walletId when id changes
            getAgentWalletOverviewBalance(Number(id));
        }
    }, [id]);

    // Memoized function to update wallet ID based on fetched wallet data
    const updateWalletId = useCallback(() => {
        if (walletOverviewData?.available?.data[0]?.id) {
            setWalletId(walletOverviewData.available.data[0].id);
            latestWalletIdRef.current = walletOverviewData.available.data[0].id;
            setCurrencyCode(walletOverviewData.available.data[0].currency.code);
        }
    }, [walletOverviewData]);

    useEffect(() => {
        if (
            walletOverviewData &&
            latestWalletIdRef.current !==
                walletOverviewData?.available?.data[0]?.id
        ) {
            updateWalletId();
        }
    }, [walletOverviewData, updateWalletId]);

    const walletOverviewCollectionData = useMemo(
        () => walletOverviewData && new WalletOverviewModel(walletOverviewData),
        [walletOverviewData],
    );

    const currencyData = useMemo(
        () => walletOverviewCollectionData?.getByCurrency(),
        [walletOverviewCollectionData],
    );

    // Memoize the currencies list to prevent unnecessary re-renders
    const currencies = useMemo(
        () =>
            Object.entries(currencyData || {}).map(([currencyCode, data]) => ({
                code: currencyCode,
                totalAvailableBalance: data.totalAvailableBalance,
                totalReservedBalance: data.totalReservedBalance,
                totalPendingBalance: data.totalPendingBalance,
                symbol: data.symbol,
                walletId: data.walletIds[0],
            })),
        [currencyData],
    );

    // Handle wallet balance card click
    const handleWalletBalanceClick = (id: number, code: string) => {
        setWalletId(id);
        latestWalletIdRef.current = id; // Update the ref
        setCurrencyCode(code);
    };

    // Handle back navigation
    const handleBack = () => {
        setWalletOverviewBalance((prev) => ({ ...prev, data: undefined }));
        setWalletId(undefined);
        navigate("/agent/companies");
    };

    // Render error state if any
    if (!isLoading && isError) {
        return (
            <Result
                subTitle={isError}
                extra={
                    <Button onClick={handleBack} type="primary">
                        Back Home
                    </Button>
                }
            />
        );
    }

    return (
        <PageHeader
            ghost={false}
            title="Agent Wallet"
            style={{ marginTop: "10px" }}
            onBack={handleBack}
        >
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Row>
                    <WalletCard
                        key={walletId}
                        walletId={walletId}
                        handleWalletBalanceClick={handleWalletBalanceClick}
                        item={currencies}
                    />
                </Row>
            )}
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Row>
                    <Col span={24}>
                        {walletId && (
                            <WalletTable
                                key={walletId}
                                walletId={walletId}
                                currencyCode={currencyCode}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </PageHeader>
    );
};

export default WalletOverviewForAgentCompany;
