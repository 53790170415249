export enum PAYMENT_GATEWAY_PERMISSION_ENUM {
    PAYMENT_GATEWAY_VIEW = "payment-gateway-view",
    PAYMENT_GATEWAY_MANAGE = "payment-gateway-manage",
    ADMIN_PAYMENT_TRANSACTION_VIEW = "admin-payment-transaction-view",
    ADMIN_PAYMENT_TRANSACTION_UPDATE = "admin-payment-transaction-update",
}

export const paymentGateWayManageRoutePermissions = [
    PAYMENT_GATEWAY_PERMISSION_ENUM.PAYMENT_GATEWAY_VIEW,
];
export const gatewayTransactionsRoutePermissions = [
    PAYMENT_GATEWAY_PERMISSION_ENUM.ADMIN_PAYMENT_TRANSACTION_VIEW,
];
