export enum RegionEnum {
    RegionCollection = "RegionCollection",
    Region = "Region",
}
export enum RegionDataStatus {
    active = "active",
    inactive = "inactive",
}
export enum RegionStoreType {
    global = "global",
    segregated = "segregated",
}
export enum RegionCode {
    BD = "BD",
    EU = "EU",
    IN = "IN",
    AE = "AE",
    US = "US",
}
