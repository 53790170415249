import { Button, Flex, Space, Tag, Typography } from "antd";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import { Link, useParams } from "react-router-dom";
import { StatusTag } from "@/components";
import ShippingText from "../../../components/ShippingText";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { memo } from "react";
import { IModalData } from "@/helpers/getModalTital";
import { EyeFilled, SyncOutlined } from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";
import { Tooltip } from "antd/lib";
import { useSyncShipmentProducts } from "@/lib/core-react/hooks/private/useShipping";
import useRefetch from "@/hooks/useRefetch";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { showError } from "@/helpers/showError";

interface Props {
    shipmentProductModel: ShipmentProductModel;
    isDetailPage: boolean;
    handleModal: (payload: IModalData) => void;
}

const { Text } = Typography;

const ShipmentProductInfo = ({
    shipmentProductModel,
    isDetailPage,
    handleModal,
}: Props) => {
    const rejectReason = shipmentProductModel?.getRejectReason();
    const { id } = useParams();
    const { syncShipmentProducts, isLoading: isLoadingSync } =
        useSyncShipmentProducts();
    const { refetchListApi, refetchDetailApi } = useRefetch();

    const handleSync = async () => {
        try {
            if (!shipmentProductModel) {
                throw new Error("Product Id Not found");
            }
            await syncShipmentProducts({
                ids: [shipmentProductModel.getId()],
            });

            showSuccessAlert(
                `Successfully Sync ${shipmentProductModel.getProductNumber()}`,
            );
            await refetchListApi();
            if (id) {
                await refetchDetailApi(id);
            }
        } catch (error) {
            showError(error);
        }
    };

    return (
        <Flex gap={8} vertical>
            <Flex gap={12}>
                <ImageWithPlaceholder
                    style={{
                        width: 120,
                        height: 120,
                    }}
                    src={`${shipmentProductModel.getFirstImageSrc()}`}
                />
                <Flex vertical gap={4}>
                    <Typography.Text>
                        <Text>PN: </Text>
                        <Text strong copyable>
                            {shipmentProductModel.getProductNumber()}
                        </Text>
                    </Typography.Text>
                    {shipmentProductModel.getBuyProduct() && (
                        <Typography.Text>
                            <Text>PB: </Text>
                            <Text strong copyable>
                                {shipmentProductModel
                                    .getBuyProduct()
                                    ?.getProductNumber()}
                            </Text>
                        </Typography.Text>
                    )}

                    <Typography.Text>
                        <Text>Region: </Text>
                        <StatusTag
                            minWidth={48}
                            slug={shipmentProductModel.getRegionCode()}
                            text={shipmentProductModel.getRegionCode()}
                        />
                    </Typography.Text>

                    <Typography.Text>
                        <Text>Status: </Text>
                        <StatusTag
                            slug={shipmentProductModel.getStatus()}
                            text={shipmentProductModel.getStatusLabel()}
                        />
                    </Typography.Text>

                    {rejectReason && (
                        <Paragraph>
                            <Text>Reject Reason: </Text>

                            <Tooltip
                                title={
                                    rejectReason.length > 10 ? rejectReason : ""
                                }
                                placement="top"
                            >
                                <Text
                                    style={{
                                        textTransform: "capitalize",
                                        color: "red",
                                    }}
                                >
                                    {rejectReason.length > 10
                                        ? `${rejectReason.slice(0, 20)}...`
                                        : rejectReason}
                                </Text>
                            </Tooltip>
                        </Paragraph>
                    )}
                    <Typography.Text>
                        <Text> Product source: </Text>
                        <Text strong>
                            {shipmentProductModel
                                .getProductSource()
                                .toUpperCase()}
                        </Text>
                    </Typography.Text>
                    <Typography.Text>
                        <Text>Order Type: </Text>
                        <StatusTag
                            slug={shipmentProductModel
                                .getOrder()
                                .getOrderType()}
                            text={shipmentProductModel
                                .getOrder()
                                .getOrderType()}
                        />
                    </Typography.Text>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 4,
                            }}
                        >
                            <Text>Total Bids: </Text>
                            <Tag color="blue">
                                {shipmentProductModel.getBids().length}
                            </Tag>
                        </div>
                        {shipmentProductModel.getBids().length > 0 &&
                            checkActionPermission(
                                [
                                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_MANAGE_BID,
                                ],
                                <Button
                                    type="link"
                                    htmlType="button"
                                    onClick={() =>
                                        handleModal({
                                            action: "bid_list",
                                            data: shipmentProductModel,
                                        })
                                    }
                                    style={{
                                        padding: 0,
                                        display: "inline-block",
                                        textAlign: "start",
                                        cursor: "pointer",
                                    }}
                                >
                                    <EyeFilled size={100} />
                                </Button>,
                                null,
                            )}
                    </div>

                    <Typography.Text>
                        <Text> Total Quantity: </Text>
                        <Tag>{shipmentProductModel.getTotalQuantity()}</Tag>
                    </Typography.Text>

                    <Typography.Text>
                        <Text>Total weight: </Text>
                        <Tag color="geekblue-inverse">
                            {shipmentProductModel.getTotalWeight()} KG
                        </Tag>
                    </Typography.Text>
                </Flex>
            </Flex>

            <Flex gap={1} vertical>
                <Text
                    strong
                    ellipsis={{ tooltip: shipmentProductModel.getTitle() }}
                    style={{
                        maxWidth: isDetailPage ? "100%" : 350,
                    }}
                >
                    <Link
                        target="_blank"
                        to={`${shipmentProductModel.getLink() || ""}`}
                    >
                        {shipmentProductModel.getTitle()}
                    </Link>
                </Text>

                <ShippingText
                    label="Shipping Country"
                    value={shipmentProductModel?.getShippingCountryName()}
                    flagUrl={shipmentProductModel.getShippingCountryFlag()}
                />
                <ShippingText
                    label="Destination Warehouse"
                    value={shipmentProductModel?.getDestinationWarehouseName()}
                    flagUrl={shipmentProductModel.getDestinationWarehouseFlag()}
                />
                <Space size={"large"}>
                    {!isDetailPage &&
                        checkActionPermission(
                            [
                                ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_VIEW_SHIPMENT_PRODUCT,
                            ],
                            <Link
                                target="_blank"
                                to={`/shipping/shipment-products/edit/${shipmentProductModel.getId()}`}
                            >
                                View Details
                            </Link>,
                            null,
                        )}
                    <Button
                        style={{ padding: 0 }}
                        type="link"
                        htmlType="button"
                        onClick={handleSync}
                    >
                        <SyncOutlined spin={isLoadingSync} /> Sync
                    </Button>

                    {/* {checkActionPermission(
                            [
                                TRACKING_PERMISSION_ENUM.ADMIN_TRACKING_TIMELINE_VIEW,
                            ],

                            <div
                                onClick={() => {
                                    handleModal({
                                        action: "tracking",
                                        data: shipmentProductModel,
                                    });
                                }}
                                style={{
                                    whiteSpace: "nowrap",
                                    color: "#1677ff",
                                    cursor: "pointer",
                                }}
                            >
                                <Badge
                                    offset={[16, 0]}
                                    showZero
                                    count={shipmentProductModel.getTrackingsCount()}
                                    color="#1677ff"
                                >
                                    <span
                                        style={{
                                            color: "#1677ff",
                                        }}
                                    >
                                        View All Tracking
                                    </span>
                                </Badge>
                                {}
                            </div>,

                            null,
                        )} */}
                </Space>
            </Flex>
        </Flex>
    );
};

export default memo(ShipmentProductInfo);
