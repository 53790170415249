import { useAtom } from "jotai";
import { hsCodeCollection } from "@/lib/core-react/store/store";
import { useService } from "@/lib/core-react/contexts";
import { getError } from "../utils/errors";

export const useHsCodeList = () => {
    const [hsCode, setHsCode] = useAtom(hsCodeCollection);

    const { hsCodeService } = useService();

    const getHsCodeList = async (params?: string | number): Promise<void> => {
        setHsCode({ ...hsCode, isLoading: true, error: null });
        try {
            const response = await hsCodeService.hsCodeResource.get(params);
            setHsCode({
                ...hsCode,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setHsCode({
                ...hsCode,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };

    return { getHsCodeList } as const;
};
