import { createDataAtom } from "../jotaiUtility";
import { ITimelineTracking } from "@/types/timelineTracking";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import {
    BuyOrderCollectionModel,
    BuyOrderWithProductModel,
} from "@/models/buyOrderCollectionModel";

export const buyOrderCollectionAtom = createDataAtom<BuyOrderCollectionModel>();

export const buyProductTimelineTrackingAtom =
    createDataAtom<ITimelineTracking>();

export const buyProductDetailAtom = createDataAtom<BuyProductModel>();

export const buyOrderDetailAtom = createDataAtom<BuyOrderWithProductModel>();
