import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import { AclResource } from "../../resources/private";

class AclService extends CommerceApiService {
    public aclResource: AclResource;

    constructor() {
        super();

        this.aclResource = new AclResource(this.config);
    }
}

export default AclService;
