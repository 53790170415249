import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import qs from "qs";
import { IFilterType } from "@/types/filters";

const useDataFilters = (isSubFilter = false) => {
    const [filters, setFilters] = useState<IFilterType | null>(null);
    const [refetch, setRefetch] = useState(false);
    const [isFirstCall, setIsFirstCall] = useState<boolean>(false);
    const [isFetched, setIsFetched] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    const initializeFilter = () => {
        const params = qs.parse(location.search.substring(1));
        if (isSubFilter) {
            setFilters((params.subfilter as IFilterType) || {});
        } else {
            const mainParams = { ...params };
            delete mainParams.subfilter;
            setFilters(mainParams as IFilterType);
        }
        setIsFirstCall(true);
        setRefetch(false);
    };

    useEffect(() => {
        if (!isFirstCall) {
            initializeFilter();
        }
    }, [isFirstCall, location.search]);

    const initializeAvailableFilter = (data: IFilterType) => {
        const params = qs.parse(location.search.substring(1));
        const initialFilter: IFilterType = {};

        Object.keys(data).forEach((key) => {
            if (isSubFilter) {
                initialFilter[key] =
                    (params.subfilter &&
                        (params.subfilter as IFilterType)[key]) ||
                    undefined;
            } else {
                initialFilter[key] = params[key] || undefined;
            }
        });

        setFilters(initialFilter);
        setIsFetched(true);
        setRefetch(false);
    };

    const updateQueryParams = (items: IFilterType) => {
        const currentParams = qs.parse(location.search.substring(1));
        let updatedParams: IFilterType;

        if (isSubFilter) {
            updatedParams = {
                ...currentParams,
                subfilter: {
                    ...((currentParams.subfilter as IFilterType) || {}),
                    ...items,
                },
            };
        } else {
            const { subfilter, ...mainParams } = currentParams;
            updatedParams = { ...mainParams, ...items, subfilter };
        }

        const params = qs.stringify(updatedParams, { encodeValuesOnly: true });
        navigate({
            search: `?${params}`,
        });
    };

    const handleFilterChange = (fields: IFilterType) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            ...fields,
        }));
        updateQueryParams(fields);
        setRefetch(false);
    };
    /**
     * Clear filter state and filter params.
     */
    const handelFilterClear = () => {
        navigate({
            search: "",
        });
        const newFilter: IFilterType = {};
        Object.keys(filters as IFilterType).forEach((el) => {
            newFilter[el] = undefined;
        });
        setFilters(newFilter);
        setRefetch(true);
    };

    const handelSubFilterClear = () => {
        const currentParams = qs.parse(location.search.substring(1));
        const { subfilter, ...mainParams } = currentParams;
        const params = qs.stringify(mainParams, { encodeValuesOnly: true });
        navigate({
            search: `?${params}`,
        });
        const newFilter: IFilterType = {};
        Object.keys(filters as IFilterType).forEach((el) => {
            newFilter[el] = undefined;
        });
        setFilters(newFilter);
    };

    return {
        filters,
        isFirstCall,
        handleFilterChange,
        handelFilterClear,
        handelSubFilterClear,
        isFetched,
        setIsFetched,
        initializeFilter,
        initializeAvailableFilter,
        refetch,
    };
};

export default useDataFilters;
