import React, { useEffect } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Space, Table, Tag, Typography } from "antd";
import FiltersComponent from "@/components/FiltersComponent";
import { useGetAgentCompany } from "@/lib/core-react/hooks/private";
import { useAtom } from "jotai";
import { agentCompanyCollectionAtom } from "@/lib/core-react/store/store";
import {
    AgentCompanyCollectionModel,
    AgentCompanyModel,
} from "@/models/agentCompanyCollectionModel";
import { PaginationModel } from "@/models/pagination";
import AgentCommissionSubmit from "./AgentCommissionSubmit";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import { ApiHelperModel } from "@/models/apiHelper";
import { IModalData } from "@/helpers/getModalTital";
import useDataFilters from "@/hooks/useDataFilters";
import { IFilterType } from "@/types/filters";
import { StatusTag } from "@/components";
import { ColumnsType } from "antd/lib/table";

const { Text } = Typography;

interface IProps {
    productId?: number;
    productNumber?: string;
    storeId?: number | number[];
    selectedRowKeys: React.Key[];
    handleModal: (payload: IModalData) => void;
    isBulk: boolean;
}

const BuyProductAgentAssignModal: React.FC<IProps> = ({
    handleModal,
    productId,
    storeId,
    selectedRowKeys,
    isBulk,
}) => {
    const { getAgentCompanies } = useGetAgentCompany();
    const [{ data: agentCompanyCollectionData, isLoading, refetch }] = useAtom(
        agentCompanyCollectionAtom,
    );
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters(true);

    const AgentCompanyCollectionData =
        agentCompanyCollectionData &&
        new AgentCompanyCollectionModel(agentCompanyCollectionData);

    const filterData = AgentCompanyCollectionData?.getFilters();

    useEffect(() => {
        if (!isFetched && filterData) {
            initializeAvailableFilter({ ...filterData } as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, filterData]);

    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            if (filters) {
                ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
            }
        }
        if (productId) {
            ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
        }
    }, [
        isFirstCall,
        filters,
        isFetched,
        refetch,
        refetchFromFilter,
        productId,
    ]);

    useEffect(() => {
        if (Array.isArray(storeId)) {
            handleFilterChange({ purchase_store_ids: storeId });
        } else {
            handleFilterChange({ purchase_store_ids: [storeId] });
        }
    }, [storeId]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getAgentCompanies,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        AgentCompanyCollectionData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        if (filters) {
            ApiHelperModel.makeGetRequest(filters, getAgentCompanies);
        }
    };

    const CompanyInfoColumn: React.FC<{ record: AgentCompanyModel }> = ({
        record,
    }) => {
        const { width } = useWindowWidth();

        const renderRow = (key: string, value: string) => {
            if (width < 400) {
                return (
                    <Space direction="vertical">
                        <Text strong>{key}</Text>

                        {key === "Verification Status: " ? (
                            <StatusTag text={value} slug={value} />
                        ) : (
                            <Text>{value}</Text>
                        )}
                    </Space>
                );
            }
            return (
                <Space>
                    <Text strong>{key}</Text>
                    {key === "Verification Status: " ? (
                        <StatusTag text={value} slug={value} />
                    ) : (
                        <Text>{value}</Text>
                    )}
                </Space>
            );
        };

        return (
            <Space direction="vertical">
                {renderRow("Business name: ", record.getPrimaryName())}
                {renderRow(
                    "Verification Status: ",
                    record.getVerificationStatus(),
                )}
            </Space>
        );
    };

    const columns: ColumnsType<AgentCompanyModel> = [
        {
            title: "Id",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            render: (id: string) => {
                return <div>{id}</div>;
            },
        },
        {
            title: "Company info",
            dataIndex: "primary_name",
            key: "primary_name",
            render: (_: string, record: AgentCompanyModel) => (
                <CompanyInfoColumn record={record} />
            ),
        },

        {
            title: "Supported Stores (Default commission)",
            dataIndex: "supported_stores",
            key: "supported_stores",
            render: (_: string, record: AgentCompanyModel) => {
                const purchaseCommissions = record.getPurchaseCommissions();
                const formattedCommissions = purchaseCommissions.map(
                    (x, index) => {
                        return (
                            <Tag key={index}>{x.getStoreAndCommission()}</Tag>
                        );
                    },
                );

                // Split the array into chunks of 3 for line break
                const chunkSize = 3;
                const chunkedCommissions: JSX.Element[] = [];
                for (
                    let i = 0;
                    i < formattedCommissions.length;
                    i += chunkSize
                ) {
                    const chunk = (
                        <div key={i} style={{ marginBottom: "5px" }}>
                            {formattedCommissions.slice(i, i + chunkSize)}
                        </div>
                    );
                    chunkedCommissions.push(chunk);
                }

                return <>{chunkedCommissions}</>;
            },
        },
        {
            title: "Actions",
            key: "actions",
            align: "center",
            width: 200,
            render: (_: string, record: AgentCompanyModel) => {
                const id = Array.isArray(storeId) ? storeId[0] : storeId;
                const commission = record
                    .getPurchaseCommissions()
                    .find((x) => x.getStore()?.getId() === id);
                const commissionValue = commission?.getCommissionRate();
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                        }}
                    >
                        <AgentCommissionSubmit
                            selectedRowKeys={selectedRowKeys}
                            handleModal={handleModal}
                            record={record}
                            productId={productId}
                            commissionValue={commissionValue}
                            buttonText="Assign Agent"
                        />
                        <AgentCommissionSubmit
                            selectedRowKeys={selectedRowKeys}
                            handleModal={handleModal}
                            record={record}
                            productId={productId}
                            commissionValue={commissionValue}
                            buttonText="Assign Agent With Commission"
                            assignWithCommission={true}
                        />
                    </div>
                );
            },
        },
    ];

    const title = isBulk
        ? `Approve ${selectedRowKeys.length} products`
        : `Approve Product`;

    return (
        <PageHeader
            ghost={false}
            title={title}
            style={{
                padding: 0,
            }}
        >
            <FiltersComponent
                handleProductFilter={handleProductFilter}
                handleFilterChange={handleFilterChange}
                handelFilterClear={handelFilterClear}
                isFetched={isFetched}
                filters={filters}
                filtersData={filterData}
                isFromProductReceived={true}
                isSubFilter={true}
            />
            <Table
                bordered
                columns={columns}
                dataSource={AgentCompanyCollectionData?.getData()}
                size="small"
                loading={isLoading}
                pagination={paginationConfig}
                rowKey={(r) => r.getId()}
                scroll={{ x: 400 }}
            />
        </PageHeader>
    );
};

export default BuyProductAgentAssignModal;
