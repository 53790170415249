import { ITranslationEntry } from "../lang";

export const NL: ITranslationEntry = {
    translations: {
        notifications: "Notificaties",
        markAllAsRead: "Markeer alles als gelezen",
        poweredBy: "Aangedreven door",
        settings: "Instellingen",
        noNewNotification: "Hier is nog niets nieuws te zien",
    },
    lang: "nl",
};
