import {
    IInvoicePaymentRoleCollection,
    IInvoicePaymentRule,
} from "@/types/InvoiceRoleCollection";
import { IPagination } from "@/types/pagination";
import { formatDateTime } from "@/utils/helpers";

export class InvoicePaymentRuleModel {
    private readonly data: IInvoicePaymentRule;
    constructor(data: IInvoicePaymentRule) {
        this.data = data;
    }
    public getId(): number {
        return this.data.id;
    }
    public getName(): string {
        return this.data.name;
    }
    public isDefault(): boolean {
        return !!this.data.is_default;
    }
    public isWalletEnabled(): boolean {
        return !!this.data.is_wallet_enabled;
    }

    public getWalletPaymentPercentage(): number {
        return this.data.wallet_payment_percentage;
    }

    public isAutomaticGatewayEnabled(): boolean {
        return !!this.data.is_automatic_gateway_enabled;
    }

    public getAutomaticGatewayPaymentPercentage(): number {
        return this.data.automatic_gateway_payment_percentage;
    }

    public isBankDepositEnabled(): boolean {
        return !!this.data.is_bank_deposit_enabled;
    }

    public getBankDepositPaymentPercentage(): number {
        return this.data.bank_deposit_payment_percentage;
    }

    public isMobileMerchantEnabled(): boolean {
        return !!this.data.is_mobile_merchant_enabled;
    }

    public getMobileMerchantPaymentPercentage(): number {
        return this.data.mobile_merchant_payment_percentage;
    }

    public getCreatedAt(): string {
        return formatDateTime(this.data.created_at);
    }
}

export class InvoicePaymentRuleResponseModel {
    public readonly roles: InvoicePaymentRuleModel[];

    private readonly pagination: IPagination;
    constructor(data: IInvoicePaymentRoleCollection) {
        this.pagination = data.pagination;
        this.roles = data.data.map((x) => new InvoicePaymentRuleModel(x));
    }

    // Get a role by ID
    public getData(): InvoicePaymentRuleModel[] {
        const defaultIndex = this.roles.findIndex((role) => role.isDefault());
        if (defaultIndex > 0) {
            const defaultRole = this.roles.splice(defaultIndex, 1)[0];
            this.roles.unshift(defaultRole);
        }
        return this.roles;
    }

    public getPagination() {
        return this.pagination;
    }
}
