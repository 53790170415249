import { useEffect } from "react";
import {
    Button,
    Form,
    InputNumber,
    Row,
    Select,
    Space,
    Typography,
} from "antd";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import { PurchaseCommissionModel } from "@/models/purchaseCommissionCollectionModel";
import { DeleteTwoTone, PlusSquareTwoTone } from "@ant-design/icons";
import { BuyProductCommissionRateTypeEnum } from "@/enums/purchaseCommissionCollectionEnum";
import { AgentCompanyModel } from "@/models/agentCompanyCollectionModel";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";

interface IProps {
    data?: PurchaseCommissionModel;
    company: AgentCompanyModel;
    form: any;
    onFinish: (values: any) => Promise<void>;
}

export const PurchaseCommissionModification = ({
    data,
    form,
    onFinish,
}: IProps) => {
    const {
        allOptionsData: { storeOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        form.resetFields();
    }, [data, form]);

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Add Commission"
            initialValues={{
                store_commissions: [{ commission_rate: "" }],
            }}
        >
            <Form.List name="store_commissions">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Space
                                key={key}
                                direction="vertical"
                                size="middle"
                                style={{
                                    display: "flex",
                                    margin: "0 10px",
                                }}
                            >
                                <Row
                                    justify="space-between"
                                    style={{
                                        padding: 5,
                                        borderLeft: "2px solid hotpink",
                                    }}
                                >
                                    <Typography.Text mark>
                                        Commission #{index + 1}
                                    </Typography.Text>

                                    {index > 0 && (
                                        <DeleteTwoTone
                                            twoToneColor="hotpink"
                                            onClick={() => remove(name)}
                                        />
                                    )}
                                </Row>

                                <Form.Item
                                    {...restField}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Store is required",
                                        },
                                    ]}
                                    name={[name, "store_id"]}
                                    label="Store"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Please select a store"
                                        onClick={() =>
                                            onFetchFilterApi(
                                                filterResourceEnum.STORE,
                                            )
                                        }
                                        onClear={() =>
                                            onFetchFilterApi(
                                                filterResourceEnum.STORE,
                                            )
                                        }
                                        options={storeOptionsData.options}
                                        loading={storeOptionsData.isLoading}
                                        notFoundContent={
                                            storeOptionsData.isLoading
                                                ? LoaderSmall
                                                : null
                                        }
                                    />
                                </Form.Item>

                                <Form.Item
                                    {...restField}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                "Commission rate is required",
                                        },
                                    ]}
                                    required
                                    name={[name, "commission_rate"]}
                                    label="Commission Rate"
                                >
                                    <InputNumber
                                        placeholder="0"
                                        min={1}
                                        style={{ width: "100%" }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Rate type is required",
                                        },
                                    ]}
                                    required
                                    name={[name, "commission_rate_type"]}
                                    label="Rate Type"
                                >
                                    <Select
                                        placeholder="Please select a rate type"
                                        options={transformEnumToLabeledValue(
                                            BuyProductCommissionRateTypeEnum,
                                        )}
                                    />
                                </Form.Item>
                            </Space>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    form.validateFields()
                                        .then(() => add())
                                        .catch((_) => {});
                                }}
                                block
                                icon={<PlusSquareTwoTone />}
                            >
                                Add more commission
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
};
