import { useMemo } from "react";
import { Row, Col, Flex, Card, Button, Collapse, Badge } from "antd";
import { CollapseProps } from "antd/lib";

import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { BuyOrderWithProductModel } from "@/models/buyOrderCollectionModel";

import ShippingText from "@/components/ShippingText";
import { StatusTag } from "@/components";

interface Props {
    selectedRows: BuyOrderWithProductModel[];
    onClear: () => void;
}

const BuyOrderTableSummary = ({ selectedRows, onClear }: Props) => {
    const initialSummary = {
        totalItem: 0,
        storeData: {} as Record<
            string,
            {
                storeName: string;
                itemCount: number;
                totalOriginalAmount: number;
                totalRegionalAmount: number;
                totalPaid: number;
                totalDue: number;
                originalCurrencyCode: string;
                regionalCurrencyCode: string;
            }
        >,
        statusData: {} as Record<string, number>,
        regionData: {} as Record<
            string,
            {
                regionCode: string;
                totalOriginalAmount: number;
                totalRegionalAmount: number;
                totalPaid: number;
                totalDue: number;
                originalCurrencyCode: string;
                regionalCurrencyCode: string;
                itemCount: number;
            }
        >,
    };

    const tableSummary = useMemo(() => {
        const summary = selectedRows.reduce((acc, curr) => {
            acc.totalItem += 1;

            curr.getProducts().forEach((product) => {
                // Store-wise aggregation for amount, paid, and due
                const storeName = product.store?.name || "Unknown";
                const regionalCurrencyCode =
                    product.getRegionCurrencyCode() || "Unknown";
                const originalCurrencyCode =
                    product.getOriginalCurrencyCode() || "Unknown";

                if (!acc.storeData[storeName]) {
                    acc.storeData[storeName] = {
                        storeName,
                        itemCount: 1,
                        totalOriginalAmount:
                            product.getTotalOriginalPriceCalculated(),
                        totalRegionalAmount:
                            product.getTotalRegionalPriceCalculated(),
                        totalPaid: product.getPaid(),
                        totalDue: product.getDue(),
                        regionalCurrencyCode,
                        originalCurrencyCode,
                    };
                } else {
                    acc.storeData[storeName].itemCount += 1;
                    acc.storeData[storeName].totalOriginalAmount +=
                        product.getTotalOriginalPriceCalculated();
                    acc.storeData[storeName].totalRegionalAmount +=
                        product.getTotalRegionalPriceCalculated();
                    acc.storeData[storeName].totalPaid += product.getPaid();
                    acc.storeData[storeName].totalDue += product.getDue();
                }

                // Region-wise aggregation for amount, paid, and due
                const regionCode = product.getRegion()?.getCode() || "Unknown";
                if (!acc.regionData[regionCode]) {
                    acc.regionData[regionCode] = {
                        regionCode,
                        totalOriginalAmount:
                            product.getTotalOriginalPriceCalculated(),
                        totalRegionalAmount:
                            product.getTotalRegionalPriceCalculated(),
                        totalPaid: product.getPaid(),
                        totalDue: product.getDue(),
                        originalCurrencyCode:
                            product.getOriginalCurrencyCode() || "",
                        regionalCurrencyCode:
                            product.getRegionCurrencyCode() || "",
                        itemCount: 1,
                    };
                } else {
                    acc.regionData[regionCode].totalOriginalAmount +=
                        product.getTotalOriginalPriceCalculated();
                    acc.regionData[regionCode].totalRegionalAmount +=
                        product.getTotalRegionalPriceCalculated();
                    acc.regionData[regionCode].totalPaid += product.getPaid();
                    acc.regionData[regionCode].totalDue += product.getDue();
                    acc.regionData[regionCode].itemCount += 1;
                }

                // Status-wise aggregation
                const status = product.getStatus() || "Unknown";
                if (!acc.statusData[status]) {
                    acc.statusData[status] = 1;
                } else {
                    acc.statusData[status] += 1;
                }
            });

            return acc;
        }, initialSummary);

        return { ...summary, totalSelected: selectedRows.length };
    }, [selectedRows]);

    const renderData = (
        label: string,
        valueKey: string,
        slug: string,
        type: "region" | "store",
    ) => {
        const data =
            type === "region"
                ? tableSummary.regionData
                : tableSummary.storeData;
        const name = type === "region" ? "regionCode" : "storeName";
        return (
            <Col span={8}>
                <ShippingText label={label}>
                    <Flex gap="middle" wrap="wrap">
                        {Object.values(data).map((item) => (
                            <Badge
                                text
                                overflowCount={1000}
                                count={
                                    slug !== "paid" && slug !== "due"
                                        ? item.itemCount
                                        : ""
                                }
                                key={item[name]}
                                color="green"
                            >
                                <StatusTag
                                    slug={slug}
                                    text={`${item[name]}: ${roundToTwoDecimal(item[valueKey])} ${item.regionalCurrencyCode} ${valueKey === "totalRegionalAmount" ? `(${roundToTwoDecimal(item.totalOriginalAmount)} ${item.originalCurrencyCode})` : ""}`}
                                />
                            </Badge>
                        ))}
                    </Flex>
                </ShippingText>
            </Col>
        );
    };
    const items: CollapseProps["items"] = [
        {
            key: "region",
            label: "Region-wise Overview",
            children: (
                <Row gutter={[16, 16]} align="middle" wrap>
                    {renderData(
                        "Amount",
                        "totalRegionalAmount",
                        "credit",
                        "region",
                    )}
                    {renderData("Paid", "totalPaid", "paid", "region")}
                    {renderData("Due", "totalDue", "due", "region")}
                </Row>
            ),
        },
        {
            key: "store",
            label: "Store-wise Overview",
            children: (
                <Row gutter={[16, 16]} align="middle" wrap>
                    {renderData(
                        "Amount",
                        "totalRegionalAmount",
                        "credit",
                        "store",
                    )}
                    {renderData("Paid", "totalPaid", "paid", "store")}
                    {renderData("Due", "totalDue", "due", "store")}
                </Row>
            ),
        },
        {
            key: "status",
            label: "Status-wise Overview",
            children: (
                <Row gutter={[16, 16]} align="middle" wrap>
                    <Col span={24}>
                        <ShippingText label="Status">
                            <Flex gap="middle" wrap="wrap">
                                {Object.entries(tableSummary.statusData).map(
                                    ([status, count]) => (
                                        <Badge
                                            overflowCount={1000}
                                            key={status}
                                            count={count}
                                            color="green"
                                        >
                                            <StatusTag
                                                slug={status}
                                                text={status}
                                            />
                                        </Badge>
                                    ),
                                )}
                            </Flex>
                        </ShippingText>
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <Card
            bordered={false}
            type="inner"
            title={`Selected Items: ${tableSummary.totalSelected}`}
            extra={
                <Button danger onClick={onClear}>
                    Clear
                </Button>
            }
        >
            <Collapse
                size="middle"
                accordion
                ghost
                items={items}
                defaultActiveKey={["region"]}
            />
        </Card>
    );
};

export default BuyOrderTableSummary;
