import { responseTypeQuery } from "@/filters/constant";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { ISelectOption } from "@/filters/Models/SelectOptionModel";

export const getSelectedOption = (
    value,
    options: ISelectOption[],
): ISelectOption | undefined => {
    const option =
        options.length > 0
            ? options.find((option) => Number(option.value) === Number(value))
            : undefined;
    return option;
};

export const getMatchedResource = (
    resource: string | null,
): filterResourceEnum | undefined => {
    if (!resource) {
        return undefined;
    }
    const match = Object.values(filterResourceEnum).find(
        (enumValue) => enumValue === resource,
    );
    return match;
};

export const isMinimalQuery = (params: string | undefined) => {
    return params
        ? params.includes(responseTypeQuery.minimal.response_type)
        : false;
};

export const responseSuccess = {
    isLoading: false,
    error: null,
};
export const responseError = {
    isLoading: false,
    refetch: false,
};

export const defaultDataMinimal = {
    options: [],
    isLoading: false,
};
