import { useState } from "react";
import { useAtom } from "jotai";

import { useService } from "../../contexts";
import { getError } from "../utils/errors";
import {
    loginUserPermission,
    permissionAtom,
    roleAtom,
} from "@/lib/core-react/store/store";
import {
    RoleCollectionModel,
    RolePostRequestModel,
} from "@/models/roleCollectionModel";
import {
    removeSuperAdminKey,
    setCurrentUserPermissionsInLocalStorage,
    setCurrentUserSuperAdmin,
} from "@/utils/helper";
import { SUPER_ADMIN } from "@/consts/storage";

export const usePermission = () => {
    const { aclService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setError] = useState<string | null>(null);
    const [roleData, setRoleData] = useAtom(roleAtom);

    const createRole = async (payload: RolePostRequestModel) => {
        setIsLoading(true);
        try {
            const response = await aclService.aclResource.createRole(payload);
            setIsLoading(false);
            setRoleData({ ...roleData, refetch: true });
            return response.data;
        } catch (error: any) {
            setError(getError(error));
            setIsLoading(false);
            throw error;
        }
    };

    return { createRole, isLoading, isError };
};

// export const useUpdateRole = () => {
//   const { aclService } = useService();
//   const [isLoading, setIsLoading] = useState<boolean>(false);
//   const [isError, setError] = useState<string>("");
//   const [roleData, setRoleData] = useAtom(roleAtom);

//   const updateRole = async (id: number, payload: RoleUpdateRequestModel) => {
//     setIsLoading(true);
//     try {
//       const response = await aclService.aclResource.updateRole(id, payload);
//       setIsLoading(false);
//       setRoleData({ ...roleData, refetch: true });
//       return response.data;
//     } catch (error: any) {
//       setError(getError(error));
//       setIsLoading(false);
//       throw error;
//     }
//   };
//   return { updateRole, isLoading, isError };
// };

export const useGetPermission = () => {
    const [permission, setPermission] = useAtom(permissionAtom);
    const { aclService } = useService();
    const getPermission = async (params?: string): Promise<void> => {
        setPermission({ ...permission, isLoading: true, error: null });
        try {
            const response =
                await aclService.aclResource.getPermissions(params);
            setPermission({
                ...permission,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setPermission({
                ...permission,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getPermission } as const;
};

export const useLoginUserPermissionAndSetInLocalStorage = () => {
    const [, setLoginUserPermission] = useAtom(loginUserPermission); // No need to read permission atom
    const { aclService } = useService();

    const getCurrentUserPermissions = async (
        params?: string,
    ): Promise<void> => {
        setLoginUserPermission((prev) => ({
            ...prev,
            isLoading: true,
            error: null,
        }));

        try {
            const response =
                await aclService.aclResource.getLoginUserUserPermissions(
                    params,
                );

            const permissionNames = response.data
                .map((user) =>
                    user.permissions.data.map((permission) => permission.name),
                )
                .flat();

            if (permissionNames && Array.isArray(permissionNames)) {
                setCurrentUserPermissionsInLocalStorage(permissionNames);
            }
            if (
                response.data &&
                response.data.find((x) => x.name === SUPER_ADMIN)
            ) {
                setCurrentUserSuperAdmin();
            } else if (
                response.data &&
                !response.data.find((x) => x.name === SUPER_ADMIN)
            ) {
                removeSuperAdminKey();
            }

            setLoginUserPermission((prev) => ({
                ...prev,
                isLoading: false,
                data: new RoleCollectionModel(response),
                refetch: false,
            }));
        } catch (error: any) {
            setLoginUserPermission((prev) => ({
                ...prev,
                isLoading: false,
                refetch: false,
                error: getError(error),
            }));
            throw error;
        }
    };

    return { getCurrentUserPermissions } as const;
};
