import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import {
    ISupportInquiry,
    ISupportInquiryCollection,
} from "@/types/supportInquiryCollection";

export class SupportInquiryModel {
    public readonly object: string;
    public readonly id: number;
    public readonly name: string;
    public readonly email: string;
    public readonly phone: string;
    public readonly reason: string;
    public readonly message: string;
    public readonly created_at: string;

    constructor(data: ISupportInquiry) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.email = data.email;
        this.phone = data.phone;
        this.reason = data.reason;
        this.message = data.message;
        this.created_at = data.created_at;
    }

    getId = () => this.id;
    getName = () => this.name;
    getEmail = () => this.email;
    getPhone = () => this.phone;
    getReason = () => this.reason;
    getMessage = () => this.message;
    getCreatedAt = () => this.created_at;
}

export class SupportInquiryCollectionModel {
    public readonly object: string;
    public readonly data: SupportInquiryModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: ISupportInquiryCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new SupportInquiryModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
}
