import { errorMessageMinimal } from "@/filters/constant";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { useService } from "@/lib/core-react/contexts";
import QueryString from "qs";
import { useState } from "react";

export const useGetExchangeGroupMinimal = () => {
    const { storeService } = useService();
    const [exchangeGroupOptionsData, setExchangeGroupOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getExchangeGroup = async (
        params: Record<string, any>,
    ): Promise<void> => {
        try {
            if (!isMinimalQuery(QueryString.stringify(params))) {
                throw new Error(errorMessageMinimal);
            }
            setExchangeGroupOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await storeService.exchangeGroupResource.get(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setExchangeGroupOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setExchangeGroupOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getExchangeGroup, exchangeGroupOptionsData } as const;
};
