import { ITranslationEntry } from "../lang";

export const PT: ITranslationEntry = {
    translations: {
        notifications: "Notificações",
        markAllAsRead: "Marcar tudo como lido",
        poweredBy: "Desenvolvido por",
        settings: "Configurações",
        noNewNotification: "Nada de novo para ver aqui ainda",
    },
    lang: "pt",
};
