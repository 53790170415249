import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import useRefetch from "@/hooks/useRefetch";
import { useUpdateVariations } from "@/lib/core-react/hooks/private";
import { BuyProductVariationsModel } from "@/models/buyProductCollectionModel";
import { Alert, Button, Col, Flex, Form, InputNumber, Row } from "antd";
import { useEffect } from "react";

interface Props {
    variant: BuyProductVariationsModel;
    currencyCode: string;
    buyProductId: number;
    handleVariant: (
        action: "update" | "delete" | "close_modal",
        data: BuyProductVariationsModel | undefined,
    ) => void;
}

const UpdateVariationForm = ({
    variant,
    currencyCode,
    buyProductId,
    handleVariant,
}: Props) => {
    const [form] = Form.useForm();
    const { updateVariations, isLoading: isUpdateLoading } =
        useUpdateVariations();
    const { refetchDetailApi } = useRefetch();
    const onFinish = async (values) => {
        if (!variant) return;
        try {
            await updateVariations(buyProductId, {
                variations: [
                    {
                        variation_id: variant.getId(),
                        original_unit_price: values.original_unit_price,
                        quantity: values.quantity,
                        sku_id: variant.getSkuId(),
                    },
                ],
            });
            showSuccessAlert("Successfully Variation Updated");
            handleVariant("close_modal", undefined);
            await refetchDetailApi(buyProductId);
        } catch (error) {
            showError(error, form);
        }
    };

    useEffect(() => {
        if (variant) {
            form.setFieldValue(
                "original_unit_price",
                roundToTwoDecimal(variant.getOriginalUnitPrice()),
            );
            form.setFieldValue(
                "quantity",
                roundToTwoDecimal(variant.getQuantity()),
            );
        }
    }, [variant]);
    return (
        <Form form={form} layout="vertical" onFinish={onFinish}>
            {!variant ? (
                <Alert message="Variant not found" type="info" />
            ) : (
                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            name="original_unit_price"
                            label={`Original Price (${currencyCode})`}
                            rules={[
                                {
                                    required: true,
                                    message: "Original price is required",
                                },
                                {
                                    validator: (_, value) =>
                                        value > 0
                                            ? Promise.resolve()
                                            : Promise.reject(
                                                  new Error(
                                                      "Price must be greater than 0",
                                                  ),
                                              ),
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} min={0.01} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="quantity"
                            label="Quantity"
                            rules={[
                                {
                                    required: true,
                                    message: "Quantity is required",
                                },
                                {
                                    type: "number",
                                    min: 1,
                                    message: "Quantity must be greater than 0",
                                },
                            ]}
                        >
                            <InputNumber style={{ width: "100%" }} min={1} />
                        </Form.Item>
                    </Col>
                </Row>
            )}

            <Form.Item noStyle>
                <Flex gap={20} justify="end">
                    <Button
                        color="danger"
                        type="dashed"
                        htmlType="button"
                        onClick={() => handleVariant("close_modal", undefined)}
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={isUpdateLoading}
                        disabled={isUpdateLoading}
                        type="primary"
                        htmlType="submit"
                    >
                        Update
                    </Button>
                </Flex>
            </Form.Item>
        </Form>
    );
};

export default UpdateVariationForm;
