import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Table } from "antd";
import { FormInstance } from "antd/lib";
interface IProps {
    form: FormInstance<any>;
    initialData: any;
}

const VariantWithoutPropertiesPart = ({ form, initialData }: IProps) => {
    const defaultCurrencyCode = initialData?.default_currency_code;
    const columns = (remove: (index: number | number[]) => void) => [
        {
            title: "Image",
            dataIndex: "image",
            render: (_, record) => (
                <Form.Item name={[record.name, "image"]}>
                    <Input placeholder="Image URL" />
                </Form.Item>
            ),
        },
        {
            title: "Quantity",
            dataIndex: "quantity",
            render: (_, record) => (
                <Form.Item
                    name={[record.name, "quantity"]}
                    rules={[
                        {
                            required: true,
                            message: "Quantity required",
                        },
                        {
                            type: "number",
                            min: 1,
                            message: "Quantity must be at least 1",
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Quantity"
                    />
                </Form.Item>
            ),
        },
        {
            title: `Original Unit Price ${defaultCurrencyCode ? `- (${defaultCurrencyCode})` : ""}`,
            dataIndex: "original_unit_price",
            render: (_, record) => (
                <Form.Item
                    name={[record.name, "original_unit_price"]}
                    rules={[
                        {
                            required: true,
                            message: "Unit Price required",
                        },
                        {
                            validator: (_, value) => {
                                if (value > 0 || value === undefined) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(
                                        "Unit Price must be greater than 0",
                                    ),
                                );
                            },
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: "100%" }}
                        placeholder="Unit Price"
                    />
                </Form.Item>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_, __, index) => (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                    }}
                >
                    <MinusCircleOutlined
                        onClick={() => remove(index)} // Correctly reference the index for removal
                        style={{
                            color: "red",
                            cursor: "pointer",
                            marginTop: "-20px",
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <Form.List name="var_without_property_info">
            {(fields, { add, remove }) => {
                return (
                    <>
                        {fields.length === 0 && (
                            <Button
                                type="dashed"
                                disabled={fields.length > 0}
                                onClick={() => {
                                    form.validateFields().then(() => {
                                        add();
                                    });
                                }}
                                block
                                style={{
                                    marginBottom: "10px",
                                    width: "20%",
                                }}
                                icon={<PlusOutlined />}
                            >
                                Add Variation (Without Properties)
                            </Button>
                        )}

                        <Table
                            dataSource={fields}
                            columns={columns(remove)}
                            rowKey="name"
                            pagination={false}
                        />
                    </>
                );
            }}
        </Form.List>
    );
};

export default VariantWithoutPropertiesPart;
