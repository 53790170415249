import { Alert, Typography, Tag, Flex } from "antd";
import { memo } from "react";
import { StatusTag } from "@/components";

import { InvoiceModel } from "@/models/invoiceCollectionModel";
import ShippingText from "@/components/ShippingText";
import { CSSProperties } from "styled-components";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";

interface IInvoiceDetailsProps {
    InvoiceModel: InvoiceModel | undefined;
}

const { Text } = Typography;

const InvoiceInfo: React.FC<IInvoiceDetailsProps> = ({ InvoiceModel }) => {
    if (!InvoiceModel) {
        return (
            <Alert
                style={{ width: 500 }}
                message="Invoice Not Available"
                type="info"
            />
        );
    }
    const textStyle: CSSProperties = {
        paddingRight: 4,
        fontWeight: "bold",
    };
    return (
        <Flex gap={8} vertical>
            <ShippingText label="Invoice Number">
                <Typography.Text copyable>
                    {InvoiceModel.getInvoiceNumber()}
                </Typography.Text>
            </ShippingText>
            <ShippingText label="Status">
                <StatusTag
                    slug={InvoiceModel.getStatus()}
                    text={`${InvoiceModel.getStatus()}`}
                />{" "}
            </ShippingText>
            <ShippingText label="Total">
                <Tag style={textStyle} color="processing">
                    <Text strong>
                        {roundToTwoDecimal(InvoiceModel.getTotalAmount())}{" "}
                        {InvoiceModel.getCurrencyCode()}
                    </Text>
                </Tag>
            </ShippingText>
            <ShippingText label="Paid">
                <Tag style={textStyle} color="cyan">
                    <Text strong>
                        {roundToTwoDecimal(
                            InvoiceModel.transaction_summary.paid,
                        )}{" "}
                        {InvoiceModel.getCurrencyCode()}
                    </Text>
                </Tag>
            </ShippingText>
            <ShippingText label="Due">
                <Tag style={textStyle} color="red">
                    <Text strong>
                        {roundToTwoDecimal(
                            InvoiceModel.transaction_summary.due,
                        )}{" "}
                        {InvoiceModel.getCurrencyCode()}
                    </Text>
                </Tag>
            </ShippingText>
            <ShippingText label="Overpaid">
                <Tag style={textStyle} color="warning">
                    <Text strong>
                        {roundToTwoDecimal(
                            InvoiceModel.transaction_summary.overpaid,
                        )}{" "}
                        {InvoiceModel.getCurrencyCode()}
                    </Text>
                </Tag>
            </ShippingText>
        </Flex>
    );
};

export default memo(InvoiceInfo);
