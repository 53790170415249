import { IPagination } from "@/types/pagination";
import {
    IShippingCountry,
    IShippingCountryCollection,
    IShippingCountryPostRequest,
    IShippingCountryResponse,
    IShippingCountryUpdateRequest,
} from "@/types/shippingCountryCollection";
import { CountryModel } from "./countryCollectionModel";
import {
    ShippingCountryDataStatus,
    ShippingCountryEnum,
} from "@/enums/shippingCountryCollectionEnums";
import { RegionModel } from "./regionCollectionModel";
import { IFilterType } from "@/types/filters";

export class ShippingCountryModel {
    public readonly object: ShippingCountryEnum.ShippingCountry;
    public readonly id: number;
    public readonly country: CountryModel | undefined;
    public readonly region: RegionModel | undefined;
    public readonly status: ShippingCountryDataStatus;

    constructor(dataItem: IShippingCountry) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.country = dataItem.country
            ? new CountryModel(dataItem.country)
            : undefined;
        this.region = dataItem.region
            ? new RegionModel(dataItem.region)
            : undefined;
        this.status = dataItem.status;
    }
    public getObject(): ShippingCountryEnum.ShippingCountry {
        return this.object;
    }

    public getId(): number {
        return this.id;
    }

    public getCountry(): CountryModel | undefined {
        return this.country;
    }

    public getRegion(): RegionModel | undefined {
        return this.region;
    }
    public getStatus(): ShippingCountryDataStatus {
        return this.status;
    }
}

//Post Request
export class ShippingCountryPostRequestModel {
    public readonly region_id: number;
    public readonly country_id: number;
    public readonly status: ShippingCountryDataStatus;

    constructor(reqData: IShippingCountryPostRequest) {
        this.region_id = reqData.region_id;
        this.country_id = reqData.country_id;
        this.status = reqData.status;
    }
}

//Update Request
export class ShippingCountryUpdateRequestModel {
    public readonly status: ShippingCountryDataStatus;

    constructor(reqData: IShippingCountryUpdateRequest) {
        this.status = reqData.status;
    }
}

// Response
export class ShippingCountryResponseModel {
    public readonly message: string;
    public readonly data: ShippingCountryModel;

    constructor(resData: IShippingCountryResponse) {
        this.message = resData.message;
        this.data = new ShippingCountryModel(resData.data);
    }
}

export class ShippingCountryCollectionModel {
    public readonly object: string;
    public readonly data: ShippingCountryModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: IShippingCountryCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new ShippingCountryModel(item));
        this.pagination = data.pagination;
        this.filters = data.filters;
    }

    getFilters = () => this.filters;
    getData = () => this.data;
    GetCountries() {
        return this.data;
    }
    GetAllCountries() {
        return this.data.map((country) => {
            return {
                label: country.country?.name,
                value: country.id,
            };
        });
    }
    getLabeledShippingCountries = () => {
        return this.data && this.data.length > 0
            ? this.data.map((data) => {
                  return {
                      label: data.getCountry()?.getName(),
                      value: data.getId(),
                  };
              })
            : [];
    };
    getLabeledCountries = () => {
        return this.data.map((data) => {
            return {
                label: data.getCountry()?.getName(),
                value: data.getId(),
                code: data.getCountry()?.getCode(),
            };
        });
    };

    getShippingCountryIdsAsObject(): { [key: number]: string } {
        const obj: { [key: number]: string } = {};
        this.getData().forEach((item) => {
            if (item.country && item.country.name) {
                obj[item.getId()] = item.country.name;
            }
        });

        return obj;
    }
}
