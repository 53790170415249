import { Spin } from "antd";

const LoaderSmall = (
    <div
        style={{
            textAlign: "center",
            padding: "10px",
        }}
    >
        <Spin size="small" />
    </div>
);

export default LoaderSmall;
