import { Form, InputNumber, Row, Col, Switch, Card } from "antd";
import { FormInstance } from "antd/lib";

// const { Option } = Select;

// Example enums for CommissionRateTypeEnum
// const commissionRateTypes = ["percentage", "fixed"]; // Replace with your actual enum values
interface IProps {
    showExchange: boolean;
    setShowExchange: React.Dispatch<React.SetStateAction<boolean>>;
    form: FormInstance<any>;
    default_currency_code: string;
}
const ExchangePart = ({
    showExchange,
    setShowExchange,
    form,
    default_currency_code,
}: IProps) => {
    return (
        <>
            <Card
                title={
                    <Col
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        <h3
                            style={{
                                margin: 0,
                            }}
                        >
                            Exchange
                        </h3>
                        <Switch
                            checked={showExchange}
                            onChange={() => {
                                setShowExchange((pre) => !pre);
                                form.setFieldsValue({
                                    exchange: undefined,
                                });
                            }}
                        />
                    </Col>
                }
            >
                {showExchange ? (
                    <Row gutter={16} style={{ marginBottom: 16 }}>
                        {/* FX Rate */}
                        <Col span={8}>
                            <Form.Item
                                name={["exchange", "fx_rate"]}
                                label={`FX Rate ${default_currency_code ? `- (${default_currency_code})` : ""}`}
                                rules={[
                                    {
                                        required: true,
                                        message: "FX Rate is required",
                                    },
                                    {
                                        type: "number",
                                        min: 0.000001,
                                        message: "FX Rate must be > 0",
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    placeholder="Enter FX Rate"
                                />
                            </Form.Item>
                        </Col>

                        {/* Commission Rate */}
                        {/* <Col span={8}>
                            <Form.Item
                                name={["exchange", "commission_rate"]}
                                label="Commission Rate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Commission Rate is required",
                                    },
                                    {
                                        type: "number",
                                        min: 0,
                                        message: "Commission Rate must be >= 0",
                                    },
                                ]}
                            >
                                <InputNumber
                                    style={{ width: "100%" }}
                                    placeholder="Enter Commission Rate"
                                />
                            </Form.Item>
                        </Col> */}

                        {/* Commission Rate Type */}
                        {/* <Col span={8}>
                            <Form.Item
                                name={["exchange", "commission_rate_type"]}
                                label="Commission Rate Type"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Commission Rate Type is required",
                                    },
                                    {
                                        type: "string",
                                        message: "Invalid Commission Rate Type",
                                    },
                                ]}
                            >
                                <Select placeholder="Select Commission Rate Type">
                                    {commissionRateTypes.map((type) => (
                                        <Option key={type} value={type}>
                                            {type.charAt(0).toUpperCase() +
                                                type.slice(1)}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}
                    </Row>
                ) : (
                    <div
                        style={{
                            textAlign: "center",
                            padding: "10px 0",
                        }}
                    >
                        Enable Exchange to see details
                    </div>
                )}
            </Card>
        </>
    );
};

export default ExchangePart;
