import {
    CurrencyDataStatus,
    CurrencyEnum,
} from "@/enums/currencyCollectionEnums";
import {
    ICurrencyCollection,
    ICurrencyUpdateRequest,
} from "@/types/currencyCollection";

import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import { CurrencyModel } from "./currencyCollectionModel";

export class CurrencyCollectionModel {
    public readonly object: CurrencyEnum.CurrencyCollection;
    public readonly data: CurrencyModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(dataItem: ICurrencyCollection) {
        this.object = dataItem.object;
        this.data = dataItem.data.map((item) => new CurrencyModel(item));
        this.pagination = dataItem.pagination;
        this.filters = dataItem.filters;
    }

    getData() {
        return this.data;
    }

    getCurrencyCode() {
        const obj: { [key: string]: string } = {};
        this.data.forEach((item) => (obj[item.code] = item.code));
        return obj;
    }
    getCurrencyId() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.name));
        return obj;
    }
}

//Update Request
export class CurrencyCreateUpdateRequestModel {
    public readonly code: string;
    public readonly name: string;
    public readonly symbol: string;
    public readonly symbol_native: string;
    public readonly status: CurrencyDataStatus;
    constructor(reqData: ICurrencyUpdateRequest) {
        this.name = reqData.name;
        this.code = reqData.code;
        this.symbol = reqData.symbol;
        this.symbol_native = reqData.symbol_native;
        this.status = reqData.status;
    }
}
