export const getCustomerRateCalc = (
    rateType: "fixed" | "percentage",
    agentRate: number,
    commission: number,
) => {
    if (rateType === "fixed") {
        return agentRate + commission;
    }

    if (rateType === "percentage") {
        const calculatedRate = (agentRate * commission) / 100;
        return agentRate + calculatedRate;
    }

    return 0;
};

export const getCommissionRate = (
    customerRate: number,
    agentRate: number,
    rateType: "fixed" | "percentage",
) => {
    if (!customerRate || !agentRate) {
        return 0;
    }

    if (rateType === "fixed") {
        return customerRate - agentRate;
    }
    if (rateType === "percentage") {
        const calculatedRate = (100 * (customerRate - agentRate)) / agentRate;
        return calculatedRate;
    }
};
