import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface IProps {
    requestContext?: string;
}

const OrderSource = ({ requestContext }: IProps) => {
    const parsedContext = requestContext ? JSON.parse(requestContext) : null;

    const os = parsedContext?.os;
    const platform = parsedContext?.platform;
    const device = parsedContext?.device;

    return parsedContext ? (
        <div style={{ textTransform: "capitalize" }}>
            <span>Source :</span> <strong>{os || "N/A"}</strong>{" "}
            {platform ? `(${platform})` : ""}
            <Tooltip title={device || "No more information available"}>
                <InfoCircleOutlined
                    style={{
                        marginLeft: 8,
                        color: "#1890ff",
                        cursor: "pointer",
                    }}
                />
            </Tooltip>
        </div>
    ) : null;
};

export default OrderSource;
