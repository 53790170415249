// Enum for admin permissions in the Invoice module
export enum ADMIN_INVOICE_PERMISSION_ENUM {
    // Invoice permissions
    ADMIN_INVOICE_VIEW = "admin-invoice-view",
    ADMIN_INVOICE_SETTLE = "admin-invoice-settle",
    ADMIN_INVOICE_RECALCULATE = "admin-invoice-recalculate",

    // Agent Invoice permissions
    ADMIN_VIEW_AGENT_INVOICE = "admin-view-agent-invoice",
    ADMIN_SETTLE_AGENT_INVOICE = "admin-settle-agent-invoice",
    ADMIN_RECALCULATE_AGENT_INVOICE = "admin-recalculate-agent-invoice",

    // Invoice payment rule group permissions
    ADMIN_INVOICE_PAYMENT_RULE_GROUP_VIEW = "admin-invoice-payment-rule-group-view",
    ADMIN_INVOICE_PAYMENT_RULE_GROUP_MANAGE = "admin-invoice-payment-rule-group-manage",

    // Admin Pay customer Invoice
    ADMIN_PAY_CUSTOMER_INVOICE = "admin-pay-customer-invoice",
}

export const customerInvoiceViewRoutePermissions = [
    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_VIEW,
    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_SETTLE,
    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_RECALCULATE,
];
export const agentInvoiceViewRoutePermissions = [
    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_VIEW_AGENT_INVOICE,
    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_SETTLE_AGENT_INVOICE,
    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_RECALCULATE_AGENT_INVOICE,
];
export const agentInvoiceRuleGroupViewRoutePermissions = [
    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_VIEW,
];
