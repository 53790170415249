import {
    DisputeCollectionEnum,
    DisputeItemCollectionEnum,
    DisputeMandatesEnum,
} from "@/enums/disputeCollectionEnum";

import { IDisputeUser, IMandateUiActions } from "@/types/disputeCollection";
import {
    DisputeAttachment,
    DisputeAttachmentCollection,
    DisputeData,
    DisputePreview,
    DisputePreviewItem,
    IDisputeBuyProductVariationProperty,
    IDisputeDetails,
    IDisputeDetailsCollection,
    IDisputeItem,
    IDisputeItemCollection,
    IDisputeTransaction,
    IDisputeWalletAssociations,
    IDisputeWithTransaction,
} from "@/types/disputeDetailsCollection";
import { BuyProductChargeModel } from "./buyActionCollectionModel";
import {
    BuyProductModel,
    BuyProductVariationsModel,
} from "./buyProductCollectionModel";
import { AgentCompanyModel } from "./agentCompanyCollectionModel";
import {
    ShipmentProductChargeModel,
    ShipmentProductPackageModel,
} from "./shipmentProductPackageCollection";
import { RegionModel } from "./regionCollectionModel";

export class DisputeBuyProductVariationPropertiesModel {
    _object: DisputeItemCollectionEnum.BuyProductVariationProperty;
    _id: number;
    _property_id: string;
    _property_name: string;
    _property_original_name: string;
    _property_value_id: string;
    _property_value_name: string;
    _property_value_color: string;
    _property_value_title: string;
    _property_value_thumb: string;
    _property_value_image: string;
    constructor(data: IDisputeBuyProductVariationProperty) {
        this._object = data?.object;
        this._id = data?.id;
        this._property_id = data?.property_id;
        this._property_name = data?.property_name;
        this._property_original_name = data?.property_original_name;
        this._property_value_color = data?.property_value_color;
        this._property_value_name = data?.property_value_name;
        this._property_value_id = data?.property_value_id;
        this._property_value_title = data?.property_value_title;
        this._property_value_thumb = data?.property_value_thumb;
        this._property_value_image = data?.property_value_image;
    }

    getId = () => this._id;
    getPropertyId = () => this._property_id;
    getPropertyName = () => this._property_name;
    getPropertyOriginalName = () => this._property_original_name;
    getPropertyValueId = () => this._property_value_id;
    getPropertyValueName = () => this._property_value_name;
    getPropertyValueColor = () => this._property_value_color;
    getPropertyValueTitle = () => this._property_value_title;
    getPropertyValueThumb = () => this._property_value_thumb;
    getPropertyValueImage = () => this._property_value_image;
}

export class DisputeItemModel {
    object: DisputeItemCollectionEnum.DisputeItem;
    id: number;
    dispute_id: number;
    requested_amount: number;
    requested_quantity: number;
    approved_amount: number | null;
    approved_percentage: number | null;
    status: string;
    buy_product_variation?: BuyProductVariationsModel;
    buy_product_charge?: BuyProductChargeModel;
    shipment_product_package?: ShipmentProductPackageModel;
    shipment_product_charge?: ShipmentProductChargeModel;
    constructor(payload: IDisputeItem) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.dispute_id = payload?.dispute_id;
        this.requested_amount = payload?.requested_amount;
        this.requested_quantity = payload?.requested_quantity;
        this.approved_amount = payload?.approved_amount;
        this.approved_percentage = payload?.approved_percentage;
        this.status = payload?.status;
        this.buy_product_variation =
            payload?.buy_product_variation &&
            new BuyProductVariationsModel(payload?.buy_product_variation);
        this.buy_product_charge =
            payload?.buy_product_charge &&
            new BuyProductChargeModel(payload.buy_product_charge);
        this.shipment_product_charge =
            payload?.shipment_product_charge &&
            new ShipmentProductChargeModel(payload.shipment_product_charge);
    }
    getId = () => this.id;
    getDisputeId = () => this.dispute_id;
    getRequestedAmount = () => this.requested_amount;
    getRequestedQuantity = () => this.requested_quantity;
    getApprovedAmount = () => this.approved_amount;
    getApprovedPercentage = () => this.approved_percentage;
    getStatus = () => this.status;
    getBuyProductVariation = () => this.buy_product_variation;
    getBuyProductCharge = () => this.buy_product_charge;
}

export class DisputeMandatesModel {
    _object: DisputeMandatesEnum.DisputeMandate;
    _id: number;
    _mandate_to: string;
    _status: string;
    agent_company: AgentCompanyModel;
    total_assigned: number;
    total_agreed: number;
    customer_total_cost: number;
    moveon_total_profit: number;
    moveon_profit_percentage: number;
    ui_actions: IMandateUiActions;
    constructor(data) {
        this._object = data?.object;
        this._id = data?.id;
        this._mandate_to = data?.mandate_to;
        this._status = data?.status;
        this.agent_company =
            data?.agent_company && new AgentCompanyModel(data.agent_company);
        this.total_assigned = data?.total_assigned;
        this.total_agreed = data?.total_agreed;
        this.customer_total_cost = data?.customer_total_cost;
        this.moveon_total_profit = data?.moveon_total_profit;
        this.moveon_profit_percentage = data?.moveon_profit_percentage;
        this.ui_actions = data?.ui_actions;
    }
    getId = () => this._id;
    getMandateTo = () => this._mandate_to;
    getAgentCompany = () => this.agent_company;
    getStatus = () => this._status;
    getTotalAssigned = () => this.total_assigned;
    getTotalAgreed = () => this.total_agreed;
    getCustomerTotalCost = () => this.customer_total_cost;
    getMoveonTotalProfit = () => this.moveon_total_profit;
    getMoveonProfitPercentage = () => this.moveon_profit_percentage;
    getUiActions = () => this.ui_actions;
}

class DisputeUserModel {
    _object: string;
    _id: number;
    _name: string;
    _email: string;

    constructor(data: IDisputeUser) {
        this._object = data?.object;
        this._id = data?.id;
        this._name = data?.name;
        this._email = data?.email;
    }

    getUserName = () => this._name;
    getUserEmail = () => this._email;
}

export class DisputeAttachmentModel {
    object: DisputeItemCollectionEnum.DisputeAttachment;
    id: number;
    type: "image" | "video";
    src: string;
    constructor(data: DisputeAttachment) {
        this.object = data?.object;
        this.id = data?.id;
        this.type = data?.type;
        this.src = data?.src;
    }
    getId = () => this.id;
    getType = () => this.type;
    getSrc = () => this.src;
}

export class DisputeAttachmentCollectionModel {
    object: DisputeItemCollectionEnum.DisputeAttachmentCollection;
    data: DisputeAttachmentModel[];
    constructor(data: DisputeAttachmentCollection) {
        this.object = data?.object;
        this.data = data?.data.map((item) => new DisputeAttachmentModel(item));
    }
    getData = () => this.data;
}

export class DisputeItemCollectionModel {
    object: DisputeItemCollectionEnum.DisputeItemCollection;
    data: DisputeItemModel[];

    constructor(data: IDisputeItemCollection) {
        this.object = data?.object;
        this.data = data?.data.map((item) => new DisputeItemModel(item));
    }
    getData = () => this.data;
}

export class DisputeTransactionModel {
    object: string;
    id: number;
    transaction_number: string;
    amount: number;
    description: string;
    type: "debit" | "credit";
    source: string;
    available_on: string | null;
    released_on: string | null;
    status: string;
    created_at: string;
    wallet: any;
    constructor(data: IDisputeTransaction) {
        this.object = data?.object;
        this.id = data?.id;
        this.transaction_number = data?.transaction_number;
        this.amount = data?.amount;
        this.description = data?.description;
        this.type = data?.type;
        this.source = data?.source;
        this.available_on = data?.available_on;
        this.released_on = data?.released_on;
        this.status = data?.status;
        this.created_at = data?.created_at;
        this.wallet = data?.wallet;
    }
    getId = () => this.id;
    getTransactionNumber = () => this.transaction_number;
    getAmount = () => this.amount;
    getDescription = () => this.description;
    getType = () => this.type;
    getSource = () => this.source;
    getAvailableOn = () => this.available_on;
    getReleasedOn = () => this.released_on;
    getStatus = () => this.status;
    getCreatedAt = () => this.created_at;
    getWallet = () => this.wallet;
}

export class DisputeWithTransactionModel {
    object: string;
    id: number;
    transaction: DisputeTransactionModel;
    type: string;
    constructor(data: IDisputeWithTransaction) {
        this.object = data?.object;
        this.id = data?.id;
        this.transaction =
            data?.transaction && new DisputeTransactionModel(data?.transaction);
        this.type = data?.type;
    }
    getId = () => this.id;
    getTransaction = () => this.transaction;
    getType = () => this.type;
}

export class DisputeWalletAssociationsModel {
    object: string;
    data: DisputeWithTransactionModel[];
    constructor(data: IDisputeWalletAssociations) {
        this.object = data?.object;
        this.data = data?.data.map(
            (item) => new DisputeWithTransactionModel(item),
        );
    }
    getData = () => this.data;
}

export class DisputeDetailsModel {
    object: DisputeCollectionEnum.Dispute;
    id: number;
    created_at: string;
    dispute_number: string;
    attachments: DisputeAttachmentCollectionModel;
    reason: string;
    description: string;
    received_item: boolean;
    intent: string;
    status: string;
    region: RegionModel;
    user: DisputeUserModel;
    buy_product: BuyProductModel;
    items: DisputeItemCollectionModel;
    mandates: DisputeMandatesModel[];
    wallet_associations: DisputeWalletAssociationsModel;
    constructor(dataItem: IDisputeDetails) {
        this.object = dataItem?.object;
        this.id = dataItem?.id;
        this.dispute_number = dataItem?.dispute_number;
        this.attachments =
            dataItem?.attachments &&
            new DisputeAttachmentCollectionModel(dataItem.attachments);
        this.intent = dataItem?.intent;
        this.status = dataItem?.status;
        this.status = dataItem?.status;
        this.region = dataItem?.region && new RegionModel(dataItem.region);
        this.user = new DisputeUserModel(dataItem?.user);
        this.created_at = dataItem?.created_at;
        this.buy_product =
            dataItem?.buy_product && new BuyProductModel(dataItem?.buy_product);
        this.items =
            dataItem?.items && new DisputeItemCollectionModel(dataItem?.items);
        this.reason = dataItem?.reason && dataItem?.reason;
        this.description = dataItem?.description && dataItem?.description;
        this.received_item = dataItem?.received_item && dataItem?.received_item;
        this.wallet_associations =
            dataItem?.wallet_associations &&
            new DisputeWalletAssociationsModel(dataItem.wallet_associations);

        this.mandates =
            dataItem?.mandates &&
            dataItem?.mandates?.data?.map(
                (item) => item && new DisputeMandatesModel(item),
            );
    }

    getId = () => this.id;
    getStatus = () => this.status;
    getCreatedAt = () => this.created_at;
    getRegion = () => this.region;
    getBuyProduct = () => this.buy_product;
    getItems = () => this.items;
    getUser = () => this.user;
    getMandates = () => this.mandates || [];
    getAttachments = () => this.attachments;
    getDisputeNumber = () => this.dispute_number;
    getIntent = () => this.intent;
    getReason = () => this.reason;
    getDescription = () => this.description;
    getReceivedItem = () => this.received_item;
    getWalletAssociations = () => this.wallet_associations;
}

export class DisputeDetailsCollectionModel {
    public data: DisputeDetailsModel;

    constructor(data: IDisputeDetailsCollection) {
        this.data = data?.data && new DisputeDetailsModel(data?.data);
    }

    getData() {
        return this.data;
    }
}

export class DisputePreviewModel {
    message: string;
    data: DisputeDataModel;

    constructor(dataItem: DisputePreview) {
        this.message = dataItem.message;
        this.data = new DisputeDataModel(dataItem.data);
    }

    getMessage = () => this.message;
    getData = () => this.data;
}

export class DisputeDataModel {
    agent_total_cost: number;
    customer_total_cost: number;
    moveon_total_profit: number;
    moveon_profit_percentage: number;
    items: DisputePreviewItemModel[];

    constructor(dataItem: DisputeData) {
        this.agent_total_cost = dataItem.agent_total_cost;
        this.customer_total_cost = dataItem.customer_total_cost;
        this.moveon_total_profit = dataItem.moveon_total_profit;
        this.moveon_profit_percentage = dataItem.moveon_profit_percentage;
        this.items =
            dataItem?.items &&
            dataItem.items.map((item) => new DisputePreviewItemModel(item));
    }

    getAgentTotalCost = () => this.agent_total_cost;
    getCustomerTotalCost = () => this.customer_total_cost;
    getMoveonTotalProfit = () => this.moveon_total_profit;
    getMoveonProfitPercentage = () => this.moveon_profit_percentage;
    getItems = () => this.items;
}

export class DisputePreviewItemModel {
    item_id: number;
    agent_per_item_cost: number;
    agent_total_item_cost: number;
    customer_per_item_cost: number;
    customer_total_item_cost: number;
    moveon_per_item_profit: number;
    moveon_total_item_profit: number;
    moveon_profit_percentage: number;

    constructor(dataItem: DisputePreviewItem) {
        this.item_id = dataItem.item_id;
        this.agent_per_item_cost = dataItem.agent_per_item_cost;
        this.agent_total_item_cost = dataItem.agent_total_item_cost;
        this.customer_per_item_cost = dataItem.customer_per_item_cost;
        this.customer_total_item_cost = dataItem.customer_total_item_cost;
        this.moveon_per_item_profit = dataItem.moveon_per_item_profit;
        this.moveon_total_item_profit = dataItem.moveon_total_item_profit;
        this.moveon_profit_percentage = dataItem.moveon_profit_percentage;
    }

    getItemId = () => this.item_id;
    getAgentPerItemCost = () => this.agent_per_item_cost;
    getAgentTotalItemCost = () => this.agent_total_item_cost;
    getCustomerPerItemCost = () => this.customer_per_item_cost;
    getCustomerTotalItemCost = () => this.customer_total_item_cost;
    getMoveonPerItemProfit = () => this.moveon_per_item_profit;
    getMoveonTotalItemProfit = () => this.moveon_total_item_profit;
    getMoveonProfitPercentage = () => this.moveon_profit_percentage;
}
