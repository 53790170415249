import { TakeActionsEnums } from "@/enums/takeActionsEnums";

export const ISSUE_MESSAGE = {
    illegal_item: {
        label: "Illegal item",
        message_en:
            "Your product is illegal under Bangladesh customs law, so it cannot be purchased.",
        message_bd:
            "আপনার পণ্যটি বাংলাদেশ কাস্টমস আইনে অবৈধ পণ্য, এ জন্য পণ্যটি ক্রয় করা সম্ভব নয়।",
    },
    price_increased: {
        label: "Price Increased",
        message_en:
            "The price of this product has increased. Do you want to purchase it?",
        message_bd:
            "আপনার এই প্রোডাক্ট এর মূল্য বৃদ্ধি পেয়েছে। আপনি কি প্রোডাক্টটি কিনতে চান?",
    },
    price_increased_and_ldc: {
        label: "Price Increased and LDC",
        message_en:
            "The price of this product and the China local delivery charge have slightly increased. Do you want to purchase it?",
        message_bd:
            "আপনার এই প্রোডাক্ট এর মূল্য এবং চায়না লোকাল ডেলিভারি চার্জ কিছুটা যুক্ত হয়েছে। আপনি কি প্রোডাক্টটি কিনতে চান?",
    },
    high_tax_possibility: {
        label: "High Tax Possibility",
        message_en:
            "There is a possibility of high tax for your product at Bangladesh Customs Office. Do you want to accept it with high tax?",
        message_bd:
            "আপনার পণ্যটির জন্য বাংলাদেশ কাস্টমস অফিসে উচ্চ ট্যাক্স এর সম্ভাবনা আছে। আপনি কি উচ্চ ট্যাক্স দিয়ে পণ্যটি নিতে চান?",
    },
    stock_out: {
        label: "Stock Out",
        message_en:
            "This product is out of stock with the seller. You can purchase your preferred product from another seller.",
        message_bd:
            "আপনার পণ্যটি উক্ত সেলার এর কাছে স্টক শেষ হয়েছে। আপনি অন্য সেলার থেকে পছন্দের পণ্যটি কিনতে পারেন।",
    },
    local_delivery_charges: {
        label: "Local Delivery Charges",
        message_en:
            "The China local delivery charge for this product has slightly increased. Do you want to purchase it?",
        message_bd:
            "আপনার এই প্রোডাক্ট এর চায়না লোকাল ডেলিভারি চার্জ কিছুটা যুক্ত হয়েছে। আপনি কি প্রোডাক্টটি কিনতে চান?",
    },
    others: {
        label: "Others",
        message_en: "Other reasons, please check the item and try again.",
        message_bd:
            "অন্যান্য কারণ, দয়া করে পণ্যটি পরীক্ষা করুন এবং আবার চেষ্টা করুন।",
    },
};

const REGION = "BD";

export const driverOptions = [
    { value: TakeActionsEnums.PRICE_INCREASED, label: "Price Increased" },
    { value: TakeActionsEnums.PRICE_DECREASED, label: "Price Decreased" },
    {
        value: TakeActionsEnums.QUANTITY_UPDATE,
        label: "Minimum Quantity Update",
    },
    { value: TakeActionsEnums.SKU_MISMATCHED, label: "Sku Mismatched" },
    { value: TakeActionsEnums.PRODUCT_STOCK_OUT, label: "Product Stock Out" },
    {
        value: TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED,
        label: "Local Delivery charge",
    },
    {
        value: TakeActionsEnums.GENERAL_TERMS_AND_CONDITIONS,
        label: "General Terms and Conditions",
    },
    { value: TakeActionsEnums.TAX_ADDED, label: "Tax Added" },
    { value: TakeActionsEnums.NO_RISK_PRODUCT, label: "No Risk Products" },
    {
        value: TakeActionsEnums.SHIPPING_CHANGE,
        label: "Shipping Change",
    },
    {
        value: TakeActionsEnums.SHIPPING_CHARGE_INCREASE,
        label: "Shipping Charge Increase",
    },
    {
        value: TakeActionsEnums.PURCHASE_CONFIRMATION,
        label: "Purchase Confirmation",
    },
    {
        value: TakeActionsEnums.HIGH_TAX_POSSIBILITY,
        label: "High Tax Possibility",
    },
    { value: TakeActionsEnums.PACKING_CHARGE, label: "Packing Charge" },
    { value: TakeActionsEnums.SERVICE_CHARGE, label: "Services Charge" },
    { value: TakeActionsEnums.ILLEGAL_ITEM, label: "Illegal Item" },
    { value: TakeActionsEnums.FX_MISMATCHED, label: "Fx Mismatched" },
]
    // Map through the options and attach the corresponding ISSUE_MESSAGE
    .map((option) => {
        let messageKey = "";

        if (
            option.value === TakeActionsEnums.PRICE_INCREASED ||
            option.value === TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED
        ) {
            messageKey = "price_increased_and_ldc";
        }

        // Match ISSUE_MESSAGE keys based on TakeActionsEnums values
        switch (option.value) {
            case TakeActionsEnums.ILLEGAL_ITEM:
                messageKey = "illegal_item";
                break;
            case TakeActionsEnums.PRICE_INCREASED:
                messageKey = "price_increased";
                break;
            case TakeActionsEnums.HIGH_TAX_POSSIBILITY:
                messageKey = "high_tax_possibility";
                break;
            case TakeActionsEnums.PRODUCT_STOCK_OUT:
                messageKey = "stock_out";
                break;
            case TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED:
                messageKey = "local_delivery_charges";
                break;
            default:
                messageKey = "others";
                break;
        }

        // Define the English and Bangla messages
        const englishMessage = ISSUE_MESSAGE[messageKey]?.message_en || "";
        const banglaMessage = ISSUE_MESSAGE[messageKey]?.message_bd || "";

        return {
            ...option,
            message: REGION === "BD" ? banglaMessage : englishMessage,
        };
    }) as {
    value: TakeActionsEnums;
    label: string;
    message: string;
}[];
