import {
    IShipmentProductAgentAssociation,
    IShipmentProductAgentCompany,
    IShipmentProductAgentCompanyRegisteredCountryDetail,
    IShipmentProductAgentWarehouse,
} from "@/types/shipmentProductAgentAssociation";
import { CommonUserModel } from "./shared";
import { ShippingCategoryModel } from "./shippingCategory";
import { ShipmentProductAssociationSlotModel } from "./shipmentProductCustomerAssociationModel";

export class ShipmentProductAgentWarehouseModel {
    object: string;
    id: number;
    name: string;
    shipping_mark: string;
    operation_status: string;
    verification_status: string;
    contact_person: string;
    contact_number: string;
    instruction: string;

    constructor(data: IShipmentProductAgentWarehouse) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.shipping_mark = data.shipping_mark;
        this.operation_status = data.operation_status;
        this.verification_status = data.verification_status;
        this.contact_person = data.verification_status;
        this.contact_number = data.contact_number;
        this.instruction = data.instruction;
    }

    getId = () => this.id;
    getName = () => this.name;
    getShippingMark = () => this.shipping_mark;
    getOperationStatus = () => this.operation_status;
    getVerificationStatus = () => this.verification_status;
    getContactPerson = () => this.contact_person;
    getContactNumber = () => this.contact_number;
    getInstruction = () => this.instruction;
}

export class ShipmentProductAgentCompanyRegisteredCountryDetailModel {
    id: number;
    name: string;
    code: string;

    constructor(data: IShipmentProductAgentCompanyRegisteredCountryDetail) {
        this.id = data.id;
        this.name = data.name;
        this.code = data.code;
    }

    getId = () => this.id;
    getName = () => this.name;
    getCode = () => this.code;
}

export class ShipmentProductAgentCompanyModel {
    object: string;
    id: number;
    primary_name: string;
    secondary_name: string;
    slug: string;
    operation_status: string;
    verification_status: string;
    registered_country: number;
    registered_country_detail: ShipmentProductAgentCompanyRegisteredCountryDetailModel;
    company_type: string;
    director_name: string;
    designation: string;
    business_phone: string;
    years_of_experience: string;
    number_of_people_in_team: string;
    address: { [key: string]: string };
    user: CommonUserModel;

    constructor(data: IShipmentProductAgentCompany) {
        this.object = data.object;
        this.id = data.id;
        this.primary_name = data.primary_name;
        this.secondary_name = data.secondary_name;
        this.slug = data.slug;
        this.operation_status = data.operation_status;
        this.verification_status = data.verification_status;
        this.user = new CommonUserModel(data.user);
        this.address = data.address;
        this.registered_country = data.registered_country;
        this.registered_country_detail =
            new ShipmentProductAgentCompanyRegisteredCountryDetailModel(
                data.registered_country_detail,
            );
        this.company_type = data.company_type;
        this.director_name = data.director_name;
        this.designation = data.designation;
        this.business_phone = data.business_phone;
        this.years_of_experience = data.years_of_experience;
        this.number_of_people_in_team = data.number_of_people_in_team;
    }

    getId = () => this.id;
    getPrimaryName = () => this.primary_name;
    getSecondaryName = () => this.secondary_name;
    getSlug = () => this.slug;
    getOperationStatus = () => this.operation_status;
    getVerificationStatus = () => this.verification_status;
    getUser = () => this.user;
    getAddress = () => this.address;
    getRegisteredCountry = () => this.registered_country;
    getRegisteredCountryDetail = () => this.registered_country_detail;
    getCompanyType = () => this.company_type;
    getDirectorName = () => this.director_name;
    getDesignation = () => this.designation;
    getBusinessPhone = () => this.business_phone;
    getYearsOfExperience = () => this.years_of_experience;
    getNumberOfPeopleInTeam = () => this.number_of_people_in_team;
}
export class ShipmentProductAgentAssociationModel {
    object: string;
    id: number;
    shipping_type: string;
    shipping_mode: string;
    unit_type: string;
    association_type: string;
    rate: number;
    weight: null | number;
    piece: null | number;
    cbm: null | number;
    agent_company: ShipmentProductAgentCompanyModel;
    shipping_category: ShippingCategoryModel;
    warehouse: ShipmentProductAgentWarehouseModel;
    association_slots: ShipmentProductAssociationSlotModel[];

    constructor(data: IShipmentProductAgentAssociation) {
        this.object = data.object;
        this.id = data.id;
        this.shipping_type = data.shipping_type;
        this.shipping_mode = data.shipping_mode;
        this.unit_type = data.unit_type;
        this.association_type = data.association_type;
        this.rate = data.rate;
        this.weight = data.weight;
        this.piece = data.piece;
        this.cbm = data.cbm;
        this.agent_company = new ShipmentProductAgentCompanyModel(
            data.agent_company,
        );
        this.shipping_category =
            data.shipping_category &&
            new ShippingCategoryModel(data.shipping_category);
        this.warehouse = new ShipmentProductAgentWarehouseModel(data.warehouse);
        this.association_slots = data.association_slots.data.map(
            (slot) => new ShipmentProductAssociationSlotModel(slot),
        );
    }

    getId = () => this.id;
    getShippingType = () => this.shipping_type;
    getShippingMode = () => this.shipping_mode;
    getUnitType = () => this.unit_type;
    getAssociationType = () => this.association_type;
    getRate = () => this.rate;
    getWeight = () => this.weight;
    getPiece = () => this.piece;
    getCbm = () => this.cbm;
    getAgentCompany = () => this.agent_company;
    getShippingCategory = () => this.shipping_category;
    getWarehouse = () => this.warehouse;
    getAssociationSlots = () => this.association_slots;
}
