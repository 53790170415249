import { FilterStorageKeys } from "@/consts/AppEnums";
import { IFilterType } from "@/types/filters";

export function getVisibleFilters(
    storageKey: FilterStorageKeys,
    originalFilters: IFilterType,
): IFilterType {
    // Get saved filters from local storage
    const savedFiltersString = localStorage.getItem(storageKey);

    if (!savedFiltersString) {
        return originalFilters;
    }

    // Parse the saved filters
    const savedFilters = JSON.parse(savedFiltersString);

    // Filter by visibility and sort by serial number
    const visibleSortedFilters = savedFilters
        .filter((filter) => filter.visible) // Only get visible filters
        .sort((a, b) => a.serial - b.serial); // Sort by serial

    // Create a new object with the matching original filters for the visible filters
    const result = {};
    visibleSortedFilters.forEach((filterSetting) => {
        const originalFilter = originalFilters[filterSetting.key];
        if (originalFilter) {
            result[filterSetting.key] = originalFilter; // Add the corresponding filter from originalFilters
        }
    });

    return result;
}
