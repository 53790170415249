import React, { useEffect, createRef } from "react";
import { Button, DatePicker, Form, Input, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";

import { Forms } from "@/models/form";

import { CampaignModel } from "@/models/campaignCollectionModel";
import {
    useCampaignList,
    useUpdateCampaign,
} from "@/lib/core-react/hooks/private/useCampaign";
import TextArea from "antd/lib/input/TextArea";
import { renderOptionsFromEnumUpdateCampaignsPublished } from "@/components/Form/forms";
import { PublishStatusEnum } from "@/enums/campaignCollectionEnums";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { getSelectedOption } from "@/filters/utils";
import { showError } from "@/helpers/showError";
import { IModalData } from "@/helpers/getModalTital";
import { ICreateCampaignPayload } from "@/types/campaignCollection";

dayjs.extend(utc);
interface IProps {
    handleModal: (modal_data: IModalData) => void;
    selectedCampaign: CampaignModel;
}

const UpdateCampaign = ({ handleModal, selectedCampaign }: IProps) => {
    const { updateCampaign, isLoading } = useUpdateCampaign();
    const { getCampaign } = useCampaignList();

    const {
        allOptionsData: { regionOptionsData, strapiSlugCampaignOptionData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
        onFetchFilterApi(filterResourceEnum.STRAPI_SLUG_CAMPAIGN);
        form.resetFields();
    }, [form]);

    useEffect(() => {
        form.setFieldsValue({
            region_id: getSelectedOption(
                selectedCampaign.getRegion().getId(),
                regionOptionsData.options,
            ),
            title: selectedCampaign.getTitle(),
            slug: selectedCampaign.getSlug(),
            strapi_content_slug: selectedCampaign.getStripContentSlug(),
            start_at: dayjs(selectedCampaign.getStartAt()),
            finish_at: dayjs(selectedCampaign.getFinishAt()),
            publishing_status: selectedCampaign.getPublishStatus(),
        });
    }, [selectedCampaign, form, regionOptionsData.isLoading]);

    const onFinish = async (value) => {
        try {
            const payload: ICreateCampaignPayload = {
                ...value,
                region_id: value.region_id.value || value.region_id,
                start_at: dayjs
                    .utc(value.start_at)
                    .format("YYYY-MM-DD HH:mm:ss"),
                finish_at: dayjs
                    .utc(value.finish_at)
                    .format("YYYY-MM-DD HH:mm:ss"),
            };
            await updateCampaign(selectedCampaign.getId(), payload);

            await getCampaign();
            handleModal({
                action: false,
                data: null,
            });
            notification["success"]({
                message: "Updated Campaign successfully",
            });
            form.resetFields();
        } catch (error) {
            showError(error, form);
        }
    };
    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };
    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Create Campaign"
            onFieldsChange={onValuesChange}
            ref={formRef}
        >
            <Form.Item
                rules={[{ required: true, message: "Region is required" }]}
                name={["region_id"]}
                label="Region"
            >
                <Select
                    loading={regionOptionsData.isLoading}
                    options={regionOptionsData.options}
                    placeholder="Please select a region"
                />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Title is required" }]}
                label="Title"
                name="title"
            >
                <TextArea rows={4} placeholder="Please write your title" />
            </Form.Item>

            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "slug id is required",
                    },
                ]}
                label="Slug"
                name="slug"
            >
                <Input placeholder="Create the Slug" />
            </Form.Item>

            <Form.Item
                label="Strapi Content Slug"
                name="strapi_content_slug"
                rules={[{ required: true, message: "Slug is required" }]}
            >
                <Select
                    options={strapiSlugCampaignOptionData.options}
                    loading={strapiSlugCampaignOptionData.isLoading}
                    placeholder="Please select your Strapi Content Slug"
                />
            </Form.Item>
            <Form.Item
                label="Start At (in UTC +0)"
                name="start_at"
                initialValue={dayjs().utc()} // Ensure UTC is used here
                rules={[
                    { required: true, message: "Please input the start date!" },
                ]}
            >
                <DatePicker
                    showTime={{
                        defaultValue: dayjs("00:00:00", "HH:mm:ss").utc(),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Form.Item>

            <Form.Item
                label="Finish At (in UTC +0)"
                name="finish_at"
                rules={[
                    {
                        required: true,
                        message: "Please input the finish date!",
                    },
                ]}
            >
                <DatePicker
                    showTime={{
                        defaultValue: dayjs("00:00:00", "HH:mm:ss").utc(),
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                />
            </Form.Item>
            <Form.Item
                name="publishing_status"
                label="Select Publishing Status"
                rules={[{ required: true }]}
            >
                <Select placeholder="Please select a Slug">
                    {renderOptionsFromEnumUpdateCampaignsPublished(
                        PublishStatusEnum,
                    )}
                </Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateCampaign;
