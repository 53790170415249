import { useAtom } from "jotai";
import { useService } from "@/lib/core-react/contexts";
import { shippingCategoryAtom } from "@/lib/core-react/store/store";
import { ShippingCategoryRequestModel } from "@/models/shippingCategory";
import { useState } from "react";
import { getError } from "../utils/errors";
import { notification } from "antd";

export const useCreateShippingCategory = () => {
    const { shippingCoreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shippingCategoryData, setShippingCategoryData] =
        useAtom(shippingCategoryAtom);

    const createShippingCategory = async (
        payload: ShippingCategoryRequestModel,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingCoreService.shippingCategoryResource.create(
                    payload,
                );
            setIsLoading(false);
            setShippingCategoryData({ ...shippingCategoryData, refetch: true });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { createShippingCategory, isLoading };
};

export const useUpdateShippingCategory = () => {
    const { shippingCoreService } = useService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [shippingCategoryData, setShippingCategoryData] =
        useAtom(shippingCategoryAtom);

    const updateShippingCategory = async (
        id: string,
        payload: ShippingCategoryRequestModel,
    ) => {
        setIsLoading(true);
        try {
            const response =
                await shippingCoreService.shippingCategoryResource.update(
                    id,
                    payload,
                );
            setIsLoading(false);
            setShippingCategoryData({ ...shippingCategoryData, refetch: true });
            return response;
        } catch (error: any) {
            setIsLoading(false);
            throw error;
        }
    };

    return { updateShippingCategory, isLoading };
};

export const useGetShippingCategory = () => {
    const [shippingCategoryData, setShippingCategoryData] =
        useAtom(shippingCategoryAtom);
    const { shippingCoreService } = useService();

    const getShippingCategory = async (params?: string) => {
        setShippingCategoryData({
            ...shippingCategoryData,
            isLoading: true,
            error: null,
        });
        try {
            const response =
                await shippingCoreService.shippingCategoryResource.get(params);
            setShippingCategoryData({
                ...shippingCategoryData,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setShippingCategoryData({
                isLoading: false,
                refetch: false,
                error: getError(error),
                unAuthorized: error?.response?.status === 403,
                code: error?.response?.status,
            });
            throw error;
        }
    };
    return { getShippingCategory } as const;
};

export const useDeleteShippingCategory = () => {
    const { shippingCoreService } = useService(); // Assuming you have a service hook
    const [shippingCategoryData, setShippingCategoryData] =
        useAtom(shippingCategoryAtom); // Fetching atom state

    const deleteShippingCategory = async (id: string) => {
        try {
            const response =
                await shippingCoreService.shippingCategoryResource.delete(id); // assuming delete method exists
            setShippingCategoryData({ ...shippingCategoryData, refetch: true });
            notification.success({
                message: "Delete Successfully",
            });
            return response;
        } catch (error: any) {
            notification.error({
                message: getError(error),
            });
            throw error;
        }
    };
    return { deleteShippingCategory } as const;
};

export const useSyncShippingCategoryResource = () => {
    const { shippingCoreService } = useService();
    const [isLoading, setIsLoading] = useState(false);
    const syncShippingCategory = async () => {
        try {
            setIsLoading(true);
            const response =
                await shippingCoreService.shippingCategoryResource.sync();

            setIsLoading(false);
            return response;
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    };
    return { syncShippingCategory, isLoading } as const;
};
