import { Alert, Card, Flex } from "antd";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import InvoiceInfo from "@/pages/Shipping/ShipmentProducts/InvoiceSummary/InvoiceInfo";
import InvoiceInfoTitle from "@/pages/Shipping/ShipmentProducts/InvoiceSummary/InvoiceInfoTitle";

const InvoiceInfoBuyProduct = ({
    buyProduct,
}: {
    buyProduct: BuyProductModel;
}) => {
    if (!buyProduct.getCustomerInvoice() && !buyProduct.getAgentInvoice()) {
        return (
            <Alert message="Invoice Not Available" type="warning" showIcon />
        );
    }

    const customerInvoice = buyProduct.getCustomerInvoice();
    return (
        <>
            <Flex vertical gap={12}>
                <Card
                    styles={{
                        body: {
                            padding: 8,
                        },

                        header: {
                            padding: 8,
                        },
                    }}
                    title={
                        <InvoiceInfoTitle
                            label="Customer Invoice"
                            requestFor="customer"
                            invoice={customerInvoice}
                            linkPath={`/invoice/customer-invoice/details/${customerInvoice?.getId()}`}
                        />
                    }
                >
                    {buyProduct.getCustomerInvoice() ? (
                        <InvoiceInfo
                            regionCurrencyCode={
                                buyProduct.getRegionCurrencyCode() || ""
                            }
                            invoice={buyProduct.getCustomerInvoice()}
                        />
                    ) : (
                        <Alert
                            message="Not Available"
                            type="warning"
                            showIcon
                        />
                    )}
                </Card>

                <Card
                    title={
                        <InvoiceInfoTitle
                            label="Agent Invoice"
                            requestFor="agent"
                            invoice={buyProduct.getAgentInvoice()}
                            linkPath={`/invoice/agent-invoice/details/${buyProduct.getAgentInvoice()?.getId()}`}
                        />
                    }
                >
                    {buyProduct.getAgentInvoice() ? (
                        <InvoiceInfo
                            regionCurrencyCode={
                                buyProduct.getRegionCurrencyCode() || ""
                            }
                            invoice={buyProduct.getAgentInvoice()}
                            linkPath={`/invoice/agent-invoice/details/${buyProduct
                                .getAgentInvoice()
                                ?.getId()}`}
                        />
                    ) : (
                        <>
                            <Alert
                                message="Not Available"
                                type="warning"
                                showIcon
                            />
                        </>
                    )}
                </Card>
            </Flex>
        </>
    );
};

export default InvoiceInfoBuyProduct;
