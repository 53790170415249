import { ITranslationEntry } from "../lang";

export const GA: ITranslationEntry = {
    translations: {
        notifications: "Fógraí",
        markAllAsRead: "Marcáil go léir mar a léitear",
        poweredBy: "Cumhachtaithe ag",
        settings: "Socruithe",
        noNewNotification: "Níl aon rud nua le feiceáil anseo fós",
    },
    lang: "ga",
};
