export enum InventoryCategoryEnum {
    InventoryCategory = "InventoryCategory",
    InventoryCategoryCollection = "InventoryCategoryCollection",
}

export enum InventoryBaseShippingCategoryMappingCollectionEnum {
    BaseShippingCategory = "BaseShippingCategory",
    InventoryBaseShippingCategoryMappingCollection = "InventoryBaseShippingCategoryMappingCollection",
    InventoryBaseShippingCategoryMapping = "InventoryBaseShippingCategoryMapping",
}
