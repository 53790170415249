import React from "react";
import { Modal, Button, Input, Form } from "antd";
import { showError } from "@/helpers/showError";

interface BulkApproveModalProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: (formData: { adminNote: string }) => void;
    isLoading: boolean;
}

const BulkApproveModal: React.FC<BulkApproveModalProps> = ({
    visible,
    onClose,
    onSubmit,
    isLoading,
}) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                onSubmit(values);
                form.resetFields();
            })
            .catch((info) => {
                showError(info, form);
            });
    };

    return (
        <Modal
            title="Bulk Approve Payout Requests"
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={isLoading}
                    onClick={handleOk}
                >
                    Approve
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="adminNote"
                    label="Optional Note for Approval"
                    rules={[
                        {
                            required: false,
                            message: "Please enter a note if needed",
                        },
                    ]}
                >
                    <Input.TextArea rows={4} placeholder="Enter your note" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default BulkApproveModal;
