import {
    Card,
    Col,
    Divider,
    Form,
    FormInstance,
    InputNumber,
    Row,
    Select,
    Spin,
} from "antd";

import { transformEnumToLabeledValue } from "@/utils/helpers";
import {
    ContainsEnum,
    DimensionUnitEnum,
    WeightUnitEnum,
} from "@/enums/shipForMeContextEnums";
import { useGetCurrency } from "@/lib/core-react/hooks/private";
import { useAtom } from "jotai";
import { hsCodeCollection, regionCurrency } from "@/lib/core-react/store/store";
import { useEffect, useState } from "react";
import { CurrencyCollectionModel } from "@/models/currencyCollectionModel";
import { IBuyOrderQuantityFormData } from "@/types/buyOrderDetail";

import { IPropertyValueMap } from "..";
import SelectedItems from "./SelectedItems";
import { useHsCodeList } from "@/lib/core-react/hooks/private/useHsCode";
import { HSCodeCollectionModel } from "@/models/hsCodeCollectionModel";
import { useDebounce } from "@/hooks/useDebounce";

interface IProps {
    form: FormInstance<any>;
    onOk: () => void;
    quantity: IBuyOrderQuantityFormData[];
    mappedSkuProperties: IPropertyValueMap;
}

interface HsCodeOption {
    label: string;
    value: string;
}

export const AddShipmentProductCartonModel = ({
    form,
    onOk,
    quantity,
    mappedSkuProperties,
}: IProps) => {
    const { getCurrency } = useGetCurrency();
    const { getHsCodeList } = useHsCodeList();
    const [hsCodeOptions, setHsCodeOptions] = useState<HsCodeOption[]>([]);
    useEffect(() => {
        form.resetFields();
        getCurrency("per_page=200");
        getHsCodeList().then(() => {
            if (hsCodeData) {
                setHsCodeOptions(
                    hsCodeData.getAllHSCodeData().map((item) => ({
                        label: `${item.label}, ${item.value}`,
                        value: item.value,
                    })) || [],
                );
            }
        });
    }, []);

    const [searchTerm, setSearchTerm] = useState("");
    const [searchType, setSearchType] = useState<"hs_code" | "description">(
        "hs_code",
    );

    const debouncedSearchTerm = useDebounce(searchTerm, 300);

    useEffect(() => {
        if (debouncedSearchTerm) {
            const queryParam =
                searchType === "hs_code"
                    ? `hs_code=${debouncedSearchTerm}`
                    : `description=${debouncedSearchTerm}`;
            getHsCodeList(queryParam);
        } else {
            getHsCodeList();
        }
        if (hsCodeData) {
            setHsCodeOptions(
                hsCodeData.getAllHSCodeData().map((item) => ({
                    label: item.label,
                    value: item.value,
                })) || [],
            );
        }
    }, [debouncedSearchTerm]);

    const handleHsCodeSearch = (value: string) => {
        setSearchTerm(value);
        setSearchType(isNaN(Number(value)) ? "description" : "hs_code");
    };

    const [{ data: currencyDataAtom, isLoading: isLoadingCurrency }] =
        useAtom(regionCurrency);
    const currencyCollectionData =
        currencyDataAtom && new CurrencyCollectionModel(currencyDataAtom);

    const [{ data: hsCodeCollectionData, isLoading: isHsCodeLoading }] =
        useAtom(hsCodeCollection);
    const hsCodeData =
        hsCodeCollectionData && new HSCodeCollectionModel(hsCodeCollectionData);

    return (
        <Card>
            <Form
                form={form}
                name="form"
                onFinish={onOk}
                initialValues={{
                    weight: {
                        unit: WeightUnitEnum.KG,
                    },
                    length: {
                        unit: DimensionUnitEnum.CM,
                    },
                    width: {
                        unit: DimensionUnitEnum.CM,
                    },
                    height: {
                        unit: DimensionUnitEnum.CM,
                    },
                    price: {
                        amount: quantity.reduce(
                            (acc, cur) => acc + cur.original_unit_price,
                            0,
                        ),
                        currency: "BDT",
                    },
                    quantity: quantity.reduce(
                        (acc, cur) => acc + cur.quantity,
                        0,
                    ),
                }}
            >
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <SelectedItems
                            title="Selected Items"
                            bordered={false}
                            pagination={true}
                            quantity={quantity}
                            mappedSkuProperties={mappedSkuProperties}
                        />
                    </Col>
                    <Divider />
                    <Col span={12}>
                        <Form.Item
                            name="contains"
                            label="Contains"
                            rules={[
                                {
                                    required: true,
                                    message: "Contains is required",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select a contains"
                                options={transformEnumToLabeledValue(
                                    ContainsEnum,
                                )}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            name={["weight", "amount"]}
                            label="Weight"
                            rules={[
                                {
                                    required: true,
                                    message: "Weight is required",
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="0"
                                addonAfter={
                                    <Form.Item
                                        name={["weight", "unit"]}
                                        noStyle
                                    >
                                        <Select
                                            style={{ width: 70 }}
                                            options={transformEnumToLabeledValue(
                                                WeightUnitEnum,
                                            )}
                                        />
                                    </Form.Item>
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={["length", "amount"]}
                            label="Length"
                            rules={[
                                {
                                    required: true,
                                    message: "Length is required",
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="0"
                                addonAfter={
                                    <Form.Item
                                        name={["length", "unit"]}
                                        noStyle
                                    >
                                        <Select
                                            style={{ width: 70 }}
                                            options={transformEnumToLabeledValue(
                                                DimensionUnitEnum,
                                            )}
                                        />
                                    </Form.Item>
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={["width", "amount"]}
                            label="Width"
                            rules={[
                                {
                                    required: true,
                                    message: "Width is required",
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="0"
                                addonAfter={
                                    <Form.Item name={["width", "unit"]} noStyle>
                                        <Select
                                            style={{ width: 70 }}
                                            options={transformEnumToLabeledValue(
                                                DimensionUnitEnum,
                                            )}
                                        />
                                    </Form.Item>
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={["height", "amount"]}
                            label="Height"
                            rules={[
                                {
                                    required: true,
                                    message: "Height is required",
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="0"
                                addonAfter={
                                    <Form.Item
                                        name={["height", "unit"]}
                                        noStyle
                                    >
                                        <Select
                                            style={{ width: 70 }}
                                            options={transformEnumToLabeledValue(
                                                DimensionUnitEnum,
                                            )}
                                        />
                                    </Form.Item>
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={["price", "amount"]}
                            label="Price"
                            rules={[
                                {
                                    required: true,
                                    message: "Price is required",
                                },
                            ]}
                        >
                            <InputNumber
                                placeholder="0"
                                addonAfter={
                                    <Form.Item
                                        name={["price", "currency"]}
                                        noStyle
                                    >
                                        <Select
                                            loading={isLoadingCurrency}
                                            style={{ width: 70 }}
                                            options={currencyCollectionData?.getCurrencyLabeledValue()}
                                        />
                                    </Form.Item>
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={"quantity"}
                            label="Quantity"
                            rules={[
                                {
                                    required: true,
                                    message: "Quantity is required",
                                },
                            ]}
                        >
                            <InputNumber
                                disabled={true}
                                placeholder="0"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Divider />
                    <Col span={24}>
                        <Form.Item name={"hs_code"} label="Hs Code">
                            <Select
                                notFoundContent={
                                    isHsCodeLoading ? (
                                        <Spin size="small" />
                                    ) : (
                                        "No Data"
                                    )
                                }
                                loading={isHsCodeLoading}
                                placeholder="Please select HS Code"
                                showSearch={true}
                                options={hsCodeOptions}
                                onSearch={handleHsCodeSearch}
                                filterOption={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};
