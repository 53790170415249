import { InventoryCategoryModel } from "@/models/inventoryCollectionModel";
import { BaseShippingCategoryDataModel } from "@/models/baseShippingCategory";
import { IBaseShippingCategoryData } from "@/types/baseShippingCategory";
import { defaultQuery } from "@/consts/defaultQuery";
import { notification } from "antd";
import { getErrorMessage } from "@/helpers/getErrorMessages";
import { IProductDetailParams } from "@/types/productDetailExtended";
import { ProductDetailExtendedModel } from "@/models/productDetailExtendedModel";

export interface IInventoryCategoryTreeNode {
    id: string;
    name: string;
    slug: string;
    attachedBaseShippingCategory?: BaseShippingCategoryDataModel;
    children?: IInventoryCategoryTreeNode[];
}

export interface IBaseShippingCategoryTreeNode {
    title: string;
    value: string;
    children?: IBaseShippingCategoryTreeNode[];
}

export const transformToInventoryCategoryTreeData = (
    categories: InventoryCategoryModel[],
): IInventoryCategoryTreeNode[] => {
    if (!categories) return [];
    return categories.map((category) => ({
        id: category.getId(),
        name: category.getName(),
        slug: category.getSlug(),
        attachedBaseShippingCategory: category.getBaseShippingCategory(),
        children:
            category.getChildren() &&
            category.getChildren().getData().length > 0
                ? transformToInventoryCategoryTreeData(
                      category.getChildren().getData(),
                  )
                : undefined,
    }));
};

export const transformToBaseShippingCategoryTreeData = (
    categories: IBaseShippingCategoryData[],
): IBaseShippingCategoryTreeNode[] => {
    if (!categories) return [];
    return categories.map((category) => ({
        title: category.name,
        value: category.id,
        children: category?.children
            ? transformToBaseShippingCategoryTreeData(category.children)
            : undefined,
    }));
};

// should remove

export const TransformToInventoryCategoryTreeData = (
    categories: InventoryCategoryModel[],
): any[] => {
    if (!categories) return [];
    return categories.map((category) => {
        const isAttached = category?.getBaseShippingCategory()?.name
            ? true
            : false;
        return {
            title: isAttached
                ? `${category.getName()} - (${category?.getBaseShippingCategory()?.name})`
                : category.getName(),
            disabled: isAttached,
            value: category.getId(),
            children: category.getChildren()
                ? TransformToInventoryCategoryTreeData(
                      category.getChildren().getData(),
                  )
                : undefined,
        };
    });
};

export const transformToBaseShippingTreeData = (
    categories: IBaseShippingCategoryData[],
): any[] => {
    if (!categories) return [];
    return categories.map((category) => ({
        title: category.name,
        value: category.id,
        children: category?.children
            ? transformToBaseShippingTreeData(category.children)
            : undefined,
    }));
};

export function generateMoveOnProductURL(slug: string, productId: string) {
    const region = "BD";
    const locale = "en";
    const productCode = productId.slice(0, 14); // First 14 characters
    const productHash = productId.slice(14); // Remaining characters

    return `https://moveon.global/${region}_${locale}/products/${productHash}-${slug}/${productCode}`;
}

const MATCH = {
    PRODUCTS: "products",
};

interface IHandleGetVendorProductLinkProps {
    submittedUrl: string;
    getProductDetailById: (
        productId: string,
        params: Omit<IProductDetailParams, "url">,
    ) => Promise<void | ProductDetailExtendedModel>;
}

export const handleGetVendorProductLink = async ({
    submittedUrl,
    getProductDetailById,
}: IHandleGetVendorProductLinkProps) => {
    try {
        if (submittedUrl) {
            const regex = /^[A-Z0-9]{13}/;
            const match = submittedUrl.match(regex);
            const trimUrl = submittedUrl.trim().split("/").filter(Boolean);
            const lastPart = match
                ? match[0]
                : trimUrl
                      .filter(Boolean)
                      .map((slug, index) => {
                          if (slug === MATCH.PRODUCTS) {
                              return trimUrl[index + 1].split("-")[0];
                          }
                      })
                      .filter(Boolean)
                      .join("");

            if (!lastPart) {
                throw new Error(
                    "Please Provide a Valid Moveon Product Detail URL",
                );
            }

            const firstPart = trimUrl[trimUrl.length - 1];
            const productId = `${firstPart}${lastPart}`;

            await getProductDetailById(productId, {
                region: defaultQuery.region,
                locale: defaultQuery.locale,
                apply_exchange: 1,
            }).then((response) => {
                if (response) {
                    const url = response?.getVendorLink();
                    window.open(url, "_blank");
                }
            });
        }
    } catch (error) {
        notification.error({
            message: getErrorMessage(error),
        });
    }
};
