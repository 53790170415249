import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    DeleteOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";

import {
    Button,
    Divider,
    Dropdown,
    Modal,
    Switch,
    Table,
    Tag,
    Typography,
} from "antd";
import { useAtom } from "jotai";
import { formatDateTime } from "@/utils/helpers";
import { CampaignOperationEnum } from "@/enums/campaignCollectionEnums";
import useDataFilters from "@/hooks/useDataFilters";
import {
    useCampaignList,
    usePauseCampaign,
    useResumeCampaign,
} from "@/lib/core-react/hooks/private/useCampaign";
import { campaignCollectionAtom } from "@/lib/core-react/store/store";
import { ApiHelperModel } from "@/models/apiHelper";
import {
    CampaignCollectionModel,
    CampaignModel,
} from "@/models/campaignCollectionModel";
import { PaginationModel } from "@/models/pagination";
import { IFilterType } from "@/types/filters";

import CreateCampaign from "./components/CreateCampaign";
import UpdateCampaign from "./components/UpdateCampaign";
import DeleteCampaign from "./components/DeleteCampaign";
import { PageHeader } from "@ant-design/pro-layout";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import FiltersComponent from "@/components/FiltersComponent";
import { StatusTag } from "@/components";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ROLE_PERMISSION_TEST_ENUM } from "@/consts/parmissionEnum";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-core-enum";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import CommonError from "@/components/Error/CommonError";
import {
    defaultModalData,
    getModalTitle,
    IModalData,
} from "@/helpers/getModalTital";
import { ColumnsType } from "antd/es/table";
import useActionsProps from "@/helpers/useActionsProps";

const CampaignsAll = () => {
    const navigate = useNavigate();
    const { getCampaign } = useCampaignList();
    const { getPauseCampaign } = usePauseCampaign();
    const { getResumeCampaign } = useResumeCampaign();
    const actionsProps = useActionsProps();
    const { width } = useWindowWidth();
    const [
        { data: campaignsCollection, isLoading, refetch, error, unAuthorized },
    ] = useAtom(campaignCollectionAtom);

    const [modalData, setModalData] = useState<IModalData>(defaultModalData);

    const handleModal = (modal_data: IModalData) => {
        setModalData(modal_data);
    };

    const { Text } = Typography;
    const { isMobile } = useWindowWidth();

    const {
        filters,
        handleFilterChange,
        isFirstCall,
        handelFilterClear,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // Api Call
    useEffect(() => {
        if ((!isFetched && isFirstCall) || refetch || refetchFromFilter) {
            ApiHelperModel.makeGetRequest({}, getCampaign);
        }
    }, [refetchFromFilter, isFetched, isFirstCall, refetch]);

    const handleSwitchChange = (checked: boolean, record: CampaignModel) => {
        if (checked) {
            getResumeCampaign(record.id);
        } else {
            getPauseCampaign(record.id);
        }
    };

    const CampaignCollectionData =
        campaignsCollection && new CampaignCollectionModel(campaignsCollection);

    const [selectedCampaign, setSelectedCampaign] = useState<
        CampaignModel | undefined
    >(undefined);

    const paginationData = CampaignCollectionData?.getPagination();

    const filterData = CampaignCollectionData?.getFilter();
    const handleMenuClick = (
        action: "add" | "update" | "view_products" | "delete",
        campaign: CampaignModel,
    ) => {
        setSelectedCampaign(campaign);
        switch (action) {
            case "update":
                handleModal({ action: "update_campaign" });
                break;
            case "delete":
                handleModal({ action: "delete_campaign" });
                break;
            case "add":
                navigate(
                    `/service/inventory?operation=${CampaignOperationEnum.AddCampaignProduct}&region=${campaign
                        .getRegion()
                        .getCode()}&campaignId=${campaign.getId()}`,
                );
                break;
            case "view_products":
                navigate(
                    `/service/inventory?operation=${CampaignOperationEnum.ViewCampaignProduct}&region=${campaign.getRegion().getCode()}&campaignId=${campaign.getId()}`,
                );
                break;
            default:
                break;
        }
    };

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && CampaignCollectionData?.getFilter()) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        CampaignCollectionData?.getFilter(),
    ]);

    const handleProductFilter = () => {
        if (filters) {
            ApiHelperModel.makeGetRequest(filters, getCampaign);
        }
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getCampaign,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        CampaignCollectionData,
        handlePaginationChange,
    );

    // ------------------------------ui------------------------
    if (unAuthorized || error) {
        return <CommonError unAuthorized={unAuthorized} message={error} />;
    }

    const columns: ColumnsType<CampaignModel> = [
        {
            title: "#SL",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_, __, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            hidden: isMobile,
            align: "center" as const,
            render: (_: string, record: CampaignModel) => {
                return (
                    <div>
                        <Text>{record.getTitle()}</Text>
                    </div>
                );
            },
        },
        {
            title: "Campaign Details",
            dataIndex: "title",
            hidden: !isMobile,
            key: "title",
            render: (_: string, record: CampaignModel) => {
                return (
                    <>
                        <div style={{ marginBottom: 10 }}>
                            <Text>{record.getTitle()}</Text>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            Region:{" "}
                            <Tag color="geekblue">
                                {record.getRegion().getCode()}
                            </Tag>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            Slug: <Tag color="cyan">{record.getSlug()}</Tag>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                            Status:{" "}
                            <StatusTag
                                text={record.getPublishStatus()}
                                slug={record.getPublishStatus()}
                            />
                        </div>
                        <div>
                            Campaign Status:{" "}
                            <StatusTag
                                text={record.getCampaignStatus()}
                                slug={record.getCampaignStatus()}
                            />
                        </div>
                    </>
                );
            },
        },
        {
            title: "Slug",
            dataIndex: "slug",
            hidden: isMobile,
            key: "slug",
            align: "center" as const,
            render: (_: string, record: CampaignModel) => {
                return (
                    <div>
                        <Tag color="cyan">{record.getSlug()}</Tag>
                    </div>
                );
            },
        },
        {
            title: "Publishing Status",
            dataIndex: "publishing_status",
            key: "publishing_status",
            hidden: isMobile,
            align: "center" as const,
            render: (_: string, record: CampaignModel) => {
                return (
                    <div>
                        <StatusTag
                            text={record.getPublishStatus()}
                            slug={record.getPublishStatus()}
                        />
                    </div>
                );
            },
        },
        {
            title: "Campaign Status",
            dataIndex: "campaign_status",
            hidden: isMobile,
            key: "campaign_status",
            align: "center" as const,
            render: (_: string, record: CampaignModel) => {
                return (
                    <div>
                        <StatusTag
                            text={record.getCampaignStatus()}
                            slug={record.getCampaignStatus()}
                        />
                    </div>
                );
            },
        },
        {
            title: "Region",
            dataIndex: "region",
            key: "region",
            hidden: isMobile,
            align: "center" as const,
            render: (_: string, record: CampaignModel) => {
                return (
                    <div>
                        <Tag color="geekblue">
                            {record.getRegion().getCode()}
                        </Tag>
                    </div>
                );
            },
        },
        {
            title: "Start - Finish (In UTC +0)",
            dataIndex: "start_at",
            key: "start_at",
            align: "center" as const,
            render: (_: string, record: CampaignModel) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <div>
                            Start:{" "}
                            <Tag color="purple">
                                {formatDateTime(record.getStartAt())}
                            </Tag>
                        </div>
                        <Divider style={{ margin: "8px 0" }} />
                        <div>
                            Finish:{" "}
                            <Tag color="purple">
                                {formatDateTime(record.getFinishAt())}
                            </Tag>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            hidden: isMobile,
            align: "center" as const,
            render: (_: string, record: CampaignModel) => (
                <>
                    {checkActionPermission(
                        ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_MANAGE,
                        <Switch
                            checkedChildren="Resume"
                            unCheckedChildren="Pause"
                            defaultChecked={
                                record.getCampaignStatus() === "running"
                            }
                            onChange={(checked) =>
                                handleSwitchChange(checked, record)
                            }
                        />,
                        null,
                    )}
                </>
            ),
        },
        {
            ...actionsProps,
            key: "id",
            render: (_: string, record: CampaignModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
                        icon: <EditOutlined />,
                        label: "Update",
                        key: "update",
                        onClick: () => handleMenuClick("update", record),
                    },
                    {
                        permission: ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,
                        icon: <DeleteOutlined />,
                        label: "Delete",
                        key: "delete",
                        onClick: () => handleMenuClick("delete", record),
                    },
                    {
                        permission:
                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
                        icon: <PlusOutlined />,
                        label: "Add Product",
                        key: CampaignOperationEnum.AddCampaignProduct,
                        onClick: () => handleMenuClick("add", record),
                    },
                    {
                        permission:
                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
                        icon: <SearchOutlined />,
                        label: "View Products",
                        key: CampaignOperationEnum.ViewCampaignProduct,
                        onClick: () => handleMenuClick("view_products", record),
                    },
                ];

                if (isMobile) {
                    items.push({
                        permission:
                            ADMIN_CORE_PERMISSION_ENUM.ADMIN_REGION_MANAGE,
                        label: (
                            <Switch
                                checkedChildren="Resume"
                                unCheckedChildren="Pause"
                                defaultChecked={
                                    record.getCampaignStatus() === "running"
                                }
                                onChange={(checked) =>
                                    handleSwitchChange(checked, record)
                                }
                            />
                        ),
                        key: "status",
                    });
                }

                return (
                    <>
                        <Dropdown
                            menu={{
                                items: items.filter((x) =>
                                    checkActionPermission(
                                        x.permission,
                                        x,
                                        null,
                                    ),
                                ),
                            }}
                        >
                            {width > 1440 ? (
                                <Button>
                                    Actions <HddFilled />
                                </Button>
                            ) : (
                                <Button type="primary" icon={<HddFilled />} />
                            )}
                        </Dropdown>
                    </>
                );
            },
        },
    ];

    return (
        <>
            <PageHeader
                ghost={false}
                title="All Campaigns"
                extra={[
                    checkActionPermission(
                        ROLE_PERMISSION_TEST_ENUM.ADMIN_ROLE_INDEX,

                        <Button
                            key={"1"}
                            onClick={() => {
                                handleModal({
                                    action: "create_campaign",
                                });
                            }}
                            icon={<PlusOutlined />}
                            type="dashed"
                        >
                            Create a Campaign
                        </Button>,
                        null,
                    ),
                ]}
                onBack={() => window.history.back()}
            >
                <FiltersComponent
                    handleProductFilter={handleProductFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={filterData}
                    isFromProductReceived={true}
                />

                <Table
                    style={{ marginTop: 10 }}
                    loading={isLoading}
                    rowKey="id"
                    bordered={true}
                    rowHoverable={false}
                    dataSource={CampaignCollectionData?.getData()}
                    columns={columns}
                    pagination={paginationConfig}
                    scroll={{ x: 1000 }}
                />
            </PageHeader>

            <Modal
                title={getModalTitle(modalData.action)}
                open={!!modalData.action}
                footer={false}
                onCancel={() => handleModal({ action: false })}
            >
                {modalData.action === "create_campaign" && (
                    <CreateCampaign handleModal={handleModal} />
                )}

                {modalData.action === "update_campaign" && selectedCampaign && (
                    <UpdateCampaign
                        selectedCampaign={selectedCampaign}
                        handleModal={handleModal}
                    />
                )}
                {modalData.action === "delete_campaign" && selectedCampaign && (
                    <DeleteCampaign
                        selectedCampaign={selectedCampaign}
                        handleModal={handleModal}
                    />
                )}
            </Modal>
        </>
    );
};

export default CampaignsAll;
