import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";

export const useGetShippingCountryMinimal = () => {
    const { shippingCoreService } = useService();
    const [shippingCountryOptionsData, setShippingCountryOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getShippingCountry = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }

            setShippingCountryOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await shippingCoreService.shippingCountryResource.get(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setShippingCountryOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setShippingCountryOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getShippingCountry, shippingCountryOptionsData };
};
