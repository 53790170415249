import { StatusTag } from "@/components";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";
import { Space, Tag, Typography } from "antd";
const { Paragraph, Text } = Typography;

interface Props {
    // totalQuantity: number;
    totalItems: number;
    totalAmount: number;
    totalPaid: number;
    totalDue: number;
    totalRefundableAmount: number;
    currencyCode: string | undefined;
}
const FinancialDetails = ({
    totalAmount,
    totalDue,
    totalPaid,
    // totalQuantity,
    currencyCode,
    totalRefundableAmount,
    totalItems,
}: Props) => {
    return (
        <Space direction="vertical" size={"small"}>
            <Paragraph>
                <Text>Total Items: </Text>
                <Tag>{totalItems}</Tag>
            </Paragraph>
            {/* <Paragraph>
                <Text>Total Quantity: </Text>
                <Text strong>{totalQuantity}</Text>
            </Paragraph> */}

            {totalAmount > 0 ? (
                <Paragraph>
                    <Text>Total</Text>
                    <Text strong> Amount: </Text>
                    <StatusTag
                        color="cyan-inverse"
                        slug=""
                        text={`${roundToTwoDecimal(totalAmount)} ${currencyCode}`}
                    />
                </Paragraph>
            ) : (
                <></>
            )}

            {totalPaid > 0 && (
                <Paragraph>
                    Total
                    <Text strong> Paid: </Text>
                    <StatusTag
                        slug="paid"
                        text={`${roundToTwoDecimal(totalPaid)} ${currencyCode}`}
                    />
                </Paragraph>
            )}
            {totalDue > 0 && (
                <Paragraph>
                    <Text>Total</Text>
                    <Text strong> Due: </Text>
                    <StatusTag
                        slug="due"
                        text={`${roundToTwoDecimal(totalDue)} ${currencyCode}`}
                    />
                </Paragraph>
            )}

            {totalRefundableAmount > 0 ? (
                <Paragraph>
                    <Text>Total</Text>
                    <Text strong> Refund: </Text>
                    <StatusTag
                        color="red"
                        slug=""
                        text={`${roundToTwoDecimal(totalRefundableAmount)} ${currencyCode}`}
                    />
                </Paragraph>
            ) : (
                <></>
            )}
        </Space>
    );
};

export default FinancialDetails;
