/* eslint-disable no-useless-escape */

const isProductUrl = (text: string) => {
    const regexUrlCheck: RegExp =
        /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%\-\/]))?/;

    return regexUrlCheck.test(text.trim());
};

export default isProductUrl;
