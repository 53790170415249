import { ITranslationEntry } from "../lang";

export const TR: ITranslationEntry = {
    translations: {
        notifications: "Bildirimler",
        markAllAsRead: "Hepsini okundu olarak işaretle",
        poweredBy: "Tarafından desteklenmektedir",
        settings: "Ayarlar",
        noNewNotification: "Burada henüz görülecek yeni bir şey yok",
    },
    lang: "tr",
};
