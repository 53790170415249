import { IFacetField } from "@/models/ProductSearchCollectionModel";
import { useGetSearchProducts } from "@/lib/core-react/hooks/private/useProduct";
import { useEffect } from "react";
import { getUniqueArrayValues } from "../../../helpers/getUniqueArrayValues";
import { IFacetCheckboxProps } from "../../../types";
import { useForm } from "antd/es/form/Form";
import usePageQuery from "@/helpers/usePageQuery";
import { useAtom } from "jotai";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import FacetCheckboxForm from "./FacetCheckboxForm";
import { productSearchQueryAtom } from "@/lib/core-react/store/inventoryProductsAtoms";

const CategoriesFacet = ({ facetFields, payloadKey }: IFacetCheckboxProps) => {
    const { isLoading } = useGetSearchProducts();
    const [form] = useForm();
    const { updateQuery } = usePageQuery();
    const [productSearchQuery, setProductSearchQuery] = useAtom(
        productSearchQueryAtom,
    );

    const { category_names } = productSearchQuery;

    useEffect(() => {
        if (category_names && Array.isArray(category_names)) {
            setProductSearchQuery((prev) => ({ ...prev, category_names }));
        }
    }, []);

    // useEffect(() => {
    //     const payload = {
    //         ...rest,
    //     };
    //     payload[payloadKey] = facetFieldsData;

    //     getProducts(defaultQuery, payload);
    // }, [facetFieldsData]);

    const handleSingleSelect = async (selected: IFacetField) => {
        const updatedShops = getUniqueArrayValues(
            category_names,
            selected.name,
        );
        const payload = {};
        payload[payloadKey] = updatedShops;
        updateQuery(payload);
        setProductSearchQuery((prev) => ({
            ...prev,
            category_names: updatedShops,
        }));
        form.setFieldValue(selected.name, true);
    };

    const handleSingleDeSelect = async (selected: IFacetField) => {
        const updatedCategories = category_names.filter(
            (code) => code !== selected.name,
        );
        const payload = {};
        payload[payloadKey] = updatedCategories;
        updateQuery(payload);
        setProductSearchQuery((prev) => ({
            ...prev,
            category_names: updatedCategories,
        }));
        form.setFieldValue(selected.name, false);
    };

    const onChange = (e: CheckboxChangeEvent, selected: IFacetField) => {
        if (e.target.checked) {
            handleSingleSelect(selected);
        } else {
            handleSingleDeSelect(selected);
        }
    };

    useEffect(() => {
        const initialFormValues = {};
        if (facetFields) {
            facetFields.forEach(({ name }) => {
                initialFormValues[name] = category_names.includes(name);
            });
        }

        form.setFieldsValue(initialFormValues);
    }, [facetFields]);

    return (
        <FacetCheckboxForm
            label={"Categories"}
            facetFields={facetFields}
            isLoading={isLoading}
            form={form}
            onChange={onChange}
        />
    );
};
export default CategoriesFacet;
