import { ITranslationEntry } from "../lang";

export const ID: ITranslationEntry = {
    translations: {
        notifications: "Notifikasi",
        markAllAsRead: "Tandai semua sudah dibaca",
        poweredBy: "Dipersembahkan Oleh",
        settings: "Pengaturan",
        noNewNotification: "Belum ada yang baru untuk dilihat di sini",
    },
    lang: "id",
};
