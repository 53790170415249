import { Col, Row } from "antd";
import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import { useAgentWalletTransactions } from "@/lib/core-react/hooks/private/useWallet";
import { TransactionCollectionAtom } from "@/lib/core-react/store/store";
import { TransactionCollectionModel } from "@/models/walletTransactionCollectionModel";
import WalletMasterTable from "@/pages/WalletManage/WalletMasterTable";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IFilterType } from "@/types/filters";
import qs from "qs";
import CommonError from "@/components/Error/CommonError";

interface IWalletTableData {
    walletId: number;
    currencyCode?: string;
}

const WalletTable = ({ walletId, currencyCode }: IWalletTableData) => {
    const [
        {
            data: TransactionCollectionData,
            isLoading: transactionLoading,
            unAuthorized,
            error,
            code,
        },
    ] = useAtom(TransactionCollectionAtom);

    const { id } = useParams();
    const { getAgentWalletTransctions } = useAgentWalletTransactions();

    const {
        filters,
        handleFilterChange,
        isFetched,
        handelFilterClear,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    useEffect(() => {
        if (id && walletId) {
            getAgentWalletTransctions(Number(id), walletId);
        }
    }, [walletId]);

    const transactionsCollectionData =
        TransactionCollectionData &&
        new TransactionCollectionModel(TransactionCollectionData);

    const filterData = transactionsCollectionData?.getFilters();

    useEffect(() => {
        if (
            (!isFetched && transactionsCollectionData?.getFilters()) ||
            refetchFromFilter
        ) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        transactionsCollectionData,
        filterData,
    ]);
    useEffect(() => {
        if (refetchFromFilter) {
            getAgentWalletTransctions(Number(id), walletId);
        }
    }, [refetchFromFilter]);
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const walletIdValue = walletId;
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        getAgentWalletTransctions(
            Number(id),
            walletIdValue,
            qs.stringify({ ...filters, ...pageInfo }),
        );
    };

    const handleProductFilter = () => {
        getAgentWalletTransctions(
            Number(id),
            walletId,
            qs.stringify({ ...filters }),
        );
    };

    if (unAuthorized || error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            {filters && Object.keys(filters).length > 0 && (
                <Row>
                    <Col span={24}>
                        <FiltersComponent
                            handleProductFilter={handleProductFilter}
                            handleFilterChange={handleFilterChange}
                            handelFilterClear={handelFilterClear}
                            isFetched={isFetched}
                            filters={filters}
                            filtersData={filterData}
                            isFromProductReceived={true}
                        />
                    </Col>
                </Row>
            )}
            <div style={{ marginTop: 10 }}>
                <WalletMasterTable
                    isLoading={transactionLoading}
                    transactionsCollectionData={transactionsCollectionData}
                    handlePaginationChange={handlePaginationChange}
                    currencyCode={currencyCode || ""}
                />
            </div>
        </>
    );
};

export default WalletTable;
