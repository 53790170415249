// Enum for admin permissions in the BuyTakeAction module
export enum ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM {
    ADMIN_BUY_ACTION_VIEW = "admin-buy-action-view",
    ADMIN_BUY_ACTION_CANCEL = "admin-buy-action-cancel",
    ADMIN_BUY_ACTION_PERFORM = "admin-buy-action-perform",
    ADMIN_REFUND_ADD = "admin-refund-add",
    ADMIN_BUY_ACTION_ADD = "admin-buy-action-add",
}

export const buyProductTakeActionRoutePermissions = [
    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_VIEW,
    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_PERFORM,
    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_BUY_ACTION_CANCEL,
    ADMIN_BUY_TAKE_ACTION_PERMISSION_ENUM.ADMIN_REFUND_ADD,
];
