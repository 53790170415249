import {
    DestinationWarehouseStatusEnum,
    DestinationWarehouseEnum,
} from "@/enums/destinationWarehouseCollectionEnums";

import { IFilterType } from "@/types/filters";
import { IPagination } from "@/types/pagination";
import { CountryModel } from "./countryCollectionModel";
import { IDestinationWarehouse } from "@/types/destinationWarehouseCollection";
import {
    DestinationWarehouseCollection,
    IDestinationWarehousePostRequest,
    IDestinationWarehouseRequest,
    IDestinationWarehouseResponse,
} from "@/types/destinationWarehouse";

export class DestinationWarehouseModel {
    readonly object: "DestinationWarehouse";
    public readonly id: number;
    public readonly slug: string;
    public readonly name: string;
    public readonly status: DestinationWarehouseStatusEnum;
    public readonly country: CountryModel;

    constructor(dataItem: IDestinationWarehouse) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.slug = dataItem.slug;
        this.name = dataItem.name;
        this.status = dataItem.status;
        this.country = new CountryModel(dataItem.country);
    }

    public getId(): number {
        return this.id;
    }

    public getSlug(): string {
        return this.slug;
    }

    public getName(): string {
        return this.name;
    }
    public getFlag(): string {
        return this.country.flag;
    }

    public getStatus(): DestinationWarehouseStatusEnum {
        return this.status;
    }
    public getCountry(): CountryModel {
        return this.country;
    }
}

export class DestinationWarehouseCollectionModel {
    public readonly object: DestinationWarehouseEnum.DestinationWarehouseCollection;
    public readonly data: DestinationWarehouseModel[];
    public readonly pagination: IPagination;
    public readonly filters: IFilterType;

    constructor(data: DestinationWarehouseCollection) {
        this.object = data.object;
        this.data = data.data.map(
            (item) => new DestinationWarehouseModel(item),
        );
        this.pagination = data.pagination;
        this.filters = data.filters;
    }
    getData() {
        return this.data.map((warehouse) => {
            return {
                value: warehouse.id,
                label: warehouse.name,
            };
        });
    }
}

//Update Request
export class DestinationWarehouseRequestModel {
    public readonly slug: string;
    public readonly name: string;
    public readonly status: DestinationWarehouseStatusEnum;
    public readonly region_id: string;
    public readonly country_id: string;

    constructor(reqData: IDestinationWarehouseRequest) {
        this.slug = reqData.slug;
        this.name = reqData.name;
        this.status = reqData.status;
        this.region_id = reqData.region_id;
        this.country_id = reqData.country_id;
    }
}
//Post Request
export class DestinationWarehousePostRequestModel {
    public readonly slug: string;
    public readonly name: string;
    public readonly status: DestinationWarehouseStatusEnum;
    public readonly region: string;
    public readonly country: string;

    public readonly region_id: string;
    public readonly country_id: string;

    constructor(reqData: IDestinationWarehousePostRequest) {
        this.slug = reqData.slug;
        this.name = reqData.name;
        this.status = reqData.status;
        this.region = reqData.region;
        this.country = reqData.country;
        this.region_id = reqData.region_id;
        this.country_id = reqData.country_id;
    }
}

// Response
export class DestinationWarehouseResponseModel {
    public readonly message: string;
    public readonly data: DestinationWarehouseModel;

    constructor(resData: IDestinationWarehouseResponse) {
        this.message = resData.message;
        this.data = new DestinationWarehouseModel(resData.data);
    }
}
