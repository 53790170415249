import { Modal, notification } from "antd";
import { IInventoryCategoryTreeNode } from "./utils";
import { showError } from "@/helpers/showError";
import { IBaseShippingCategoryMappingForm } from "@/types/inventoryCategoryCollection";

interface IHandleDetachProps {
    actionType: "single" | "bulk";
    selectedCategory?: IInventoryCategoryTreeNode;
    detachCategory: (
        payload: IBaseShippingCategoryMappingForm,
    ) => Promise<void>;
    setSelectedRows: (value: React.SetStateAction<React.Key[]>) => void;
    selectedRows: React.Key[];
    bulkOriginatorId: string;
    setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IHandleBulkAttachProps {
    dataSource: IInventoryCategoryTreeNode[];
    selectedRows: React.Key[];
    setIsBulkAttachAction: React.Dispatch<React.SetStateAction<boolean>>;
    setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IHandleBulkDetachProps {
    selectedRows: React.Key[];
    dataSource: IInventoryCategoryTreeNode[];
    setBulkOriginatorId: React.Dispatch<React.SetStateAction<string | null>>;
    detachCategory: (
        payload: IBaseShippingCategoryMappingForm,
    ) => Promise<void>;
    setSelectedRows: (value: React.SetStateAction<React.Key[]>) => void;
    bulkOriginatorId: string | null;
    setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const handleDetach = async ({
    actionType,
    selectedCategory,
    detachCategory,
    setSelectedRows,
    selectedRows,
    bulkOriginatorId,
    setShouldRefetch,
}: IHandleDetachProps) => {
    const modal = Modal.confirm({
        title: "Are you sure you want to detach the category?",
        content: "Press Yes to confirm",
        okText: "Yes",
        cancelText: "No",
        onOk: async () => {
            try {
                if (actionType === "single") {
                    if (!selectedCategory) return;
                    if (
                        !selectedCategory ||
                        !selectedCategory.attachedBaseShippingCategory
                    )
                        return;
                    const payload = {
                        base_shipping_category_id:
                            selectedCategory?.attachedBaseShippingCategory
                                ?.originator_id,
                        inventory_category_ids: [selectedCategory?.id],
                    };
                    await detachCategory(payload);
                    notification["success"]({
                        message: "Category Detached Successfully",
                    });
                    setShouldRefetch(true);
                    modal.destroy();
                    setSelectedRows([]);
                } else if (actionType === "bulk") {
                    if (!bulkOriginatorId) return;
                    const payload = {
                        base_shipping_category_id: bulkOriginatorId,
                        inventory_category_ids: selectedRows as string[],
                    };
                    await detachCategory(payload);
                    notification["success"]({
                        message: "Categories Detached Successfully",
                    });
                    setSelectedRows([]);
                    modal.destroy();
                }
            } catch (error) {
                showError(error);
            }
        },
        onCancel: () => {
            modal.destroy();
        },
    });
};

const handleBulkDetach = ({
    selectedRows,
    dataSource,
    setBulkOriginatorId,
    detachCategory,
    setSelectedRows,
    setShouldRefetch,
}: IHandleBulkDetachProps) => {
    // first we need to check if all selected rows are attached
    const isAllAttached = selectedRows.every((row) => {
        const category =
            dataSource && dataSource.find((data) => data.id === row.toString());
        return category?.attachedBaseShippingCategory ? true : false;
    });
    // also check the all selected rows originator_id is same
    const originatorId =
        dataSource &&
        dataSource.find((data) => data.id === selectedRows[0].toString())
            ?.attachedBaseShippingCategory?.originator_id;

    const isSameOriginator =
        originatorId &&
        selectedRows.every((row) => {
            const category = dataSource.find(
                (data) => data.id === row.toString(),
            );
            return (
                category?.attachedBaseShippingCategory?.originator_id ===
                originatorId
            );
        });

    if (!isAllAttached) {
        notification["info"]({
            message: "Please select only attached categories",
        });
        return;
    }

    if (!isSameOriginator) {
        notification["info"]({
            message: "Please select categories with same base shipping",
        });
        return;
    }

    setBulkOriginatorId(originatorId);
    handleDetach({
        actionType: "bulk",
        detachCategory,
        setSelectedRows,
        selectedRows,
        bulkOriginatorId: originatorId,
        setShouldRefetch,
    });
};

const handleBulkAttach = ({
    dataSource,
    selectedRows,
    setIsBulkAttachAction,
}: IHandleBulkAttachProps) => {
    if (!dataSource || dataSource.length === 0) {
        notification["error"]({
            message: "Data is not loaded yet. Please try again later.",
        });
        return;
    }

    const isAllAttached = selectedRows.every((row) => {
        const category = dataSource.find(
            (data) => data.id.toString() === row.toString(),
        );
        return !!category?.attachedBaseShippingCategory;
    });

    if (isAllAttached) {
        notification["info"]({
            message: "Please select only unattached categories",
        });
        return;
    }

    setIsBulkAttachAction(true);
};

export const ActionHandler = {
    handleDetach,
    handleBulkDetach,
    handleBulkAttach,
};

// const handleDetach2 = async (actionType: string) => {
//     const modal = Modal.confirm({
//         title: "Are you sure you want to detach the category?",
//         content: "Press Yes to confirm",
//         okText: "Yes",
//         cancelText: "No",
//         onOk: async () => {
//             try {
//                 if (actionType === "single") {
//                     if (!selectedCategory) return;
//                     if (
//                         !selectedCategory ||
//                         !selectedCategory.attachedBaseShippingCategory
//                     )
//                         return;
//                     const payload = {
//                         base_shipping_category_id:
//                             selectedCategory?.attachedBaseShippingCategory
//                                 ?.originator_id,
//                         inventory_category_ids: [selectedCategory?.id],
//                     };
//                     await detachCategory(payload);
//                     notification["success"]({
//                         message: "Category Detached Successfully",
//                     });
//                     modal.destroy();
//                     setSelectedRows([]);
//                 } else if (actionType === "bulk") {
//                     if (!bulkOriginatorId) return;
//                     const payload = {
//                         base_shipping_category_id: bulkOriginatorId,
//                         inventory_category_ids: selectedRows as string[],
//                     };
//                     await detachCategory(payload);
//                     notification["success"]({
//                         message: "Categories Detached Successfully",
//                     });
//                     setSelectedRows([]);
//                     modal.destroy();
//                 }
//             } catch (error) {
//                 showError(error);
//             }
//         },
//         onCancel: () => {
//             modal.destroy();
//         },
//     });
// };

// const handleBulkDetach = () => {
//     // first we need to check if all selected rows are attached
//     const isAllAttached = selectedRows.every((row) => {
//         const category =
//             dataSource &&
//             dataSource.find((data) => data.id === row.toString());
//         return category?.attachedBaseShippingCategory ? true : false;
//     });
//     // also check the all selected rows originator_id is same
//     const originatorId =
//         dataSource &&
//         dataSource.find((data) => data.id === selectedRows[0].toString())
//             ?.attachedBaseShippingCategory?.originator_id;

//     const isSameOriginator =
//         originatorId &&
//         selectedRows.every((row) => {
//             const category = dataSource.find(
//                 (data) => data.id === row.toString(),
//             );
//             return (
//                 category?.attachedBaseShippingCategory?.originator_id ===
//                 originatorId
//             );
//         });

//     if (!isAllAttached) {
//         notification["info"]({
//             message: "Please select only attached categories",
//         });
//         return;
//     }

//     if (!isSameOriginator) {
//         notification["info"]({
//             message: "Please select categories with same base shipping",
//         });
//         return;
//     }

//     setBulkOriginatorId(originatorId);
//     handleDetach({
//         actionType: "bulk",
//         detachCategory,
//         setSelectedRows,
//         selectedRows,
//         bulkOriginatorId: originatorId,
//     });
// };

// const handleBulkAttach = () => {
//   if (!dataSource || dataSource.length === 0) {
//       notification["error"]({
//           message: "Data is not loaded yet. Please try again later.",
//       });
//       return;
//   }

//   const isAllAttached = selectedRows.every((row) => {
//       const category = dataSource.find(
//           (data) => data.id.toString() === row.toString(),
//       );
//       return !!category?.attachedBaseShippingCategory;
//   });

//   if (isAllAttached) {
//       notification["info"]({
//           message: "Please select only unattached categories",
//       });
//       return;
//   }

//   setIsBulkAction(true);
// };
