const StoreIcon = ({ size = 14, color = "currentColor", ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            {...props}
        >
            <path
                d="M12.991 2.67776H1.00977V12.9328H12.991V2.67776Z"
                fill="#E9EAEB"
            />
            <path
                d="M12.9909 2.67776H11.4697V12.9328H12.9909V2.67776Z"
                fill="#B9BBC1"
            />
            <path
                d="M13.0327 2.6777H0.967773V0.994256C0.967773 0.859752 1.07679 0.750732 1.2113 0.750732H12.7892C12.9237 0.750732 13.0327 0.859752 13.0327 0.994256V2.6777Z"
                fill="#FDC74F"
            />
            <path
                d="M12.7893 0.750732H11.5117V2.01803H13.0329V0.994256C13.0329 0.859779 12.9238 0.750732 12.7893 0.750732Z"
                fill="#FCA629"
            />
            <path
                d="M1.0092 5.7304C0.457785 5.7304 0.0107422 5.28338 0.0107422 4.73197V3.91855H2.00763V4.73197C2.00766 5.28338 1.56061 5.7304 1.0092 5.7304Z"
                fill="#E60D28"
            />
            <path
                d="M3.00627 5.7304C2.45486 5.7304 2.00781 5.28338 2.00781 4.73197V3.91855H4.0047V4.73197C4.00473 5.28338 3.55768 5.7304 3.00627 5.7304Z"
                fill="#F2F2F2"
            />
            <path
                d="M5.00334 5.7304C4.45193 5.7304 4.00488 5.28338 4.00488 4.73197V3.91855H6.00177V4.73197C6.0018 5.28338 5.55475 5.7304 5.00334 5.7304Z"
                fill="#E60D28"
            />
            <path
                d="M7.00041 5.7304C6.449 5.7304 6.00195 5.28338 6.00195 4.73197V3.91855H7.99884V4.73197C7.99884 5.28338 7.55182 5.7304 7.00041 5.7304Z"
                fill="#F2F2F2"
            />
            <path
                d="M8.9965 5.7304C8.44509 5.7304 7.99805 5.28338 7.99805 4.73197V3.91855H9.99493V4.73197C9.99496 5.28338 9.54792 5.7304 8.9965 5.7304Z"
                fill="#E60D28"
            />
            <path
                d="M10.9936 5.7304C10.4422 5.7304 9.99512 5.28338 9.99512 4.73197V3.91855H11.992V4.73197C11.992 5.28338 11.545 5.7304 10.9936 5.7304Z"
                fill="#F2F2F2"
            />
            <path
                d="M12.9906 5.7304C12.4392 5.7304 11.9922 5.28338 11.9922 4.73197V3.91855H13.9891V4.73197C13.9891 5.28338 13.5421 5.7304 12.9906 5.7304Z"
                fill="#E60D28"
            />
            <path
                d="M2.00763 3.91854H0.0107422L0.966379 2.01804H2.69024L2.00763 3.91854Z"
                fill="#FC502A"
            />
            <path
                d="M4.0047 3.91854H2.00781L2.69042 2.01804H4.41425L4.0047 3.91854Z"
                fill="white"
            />
            <path
                d="M6.00177 3.91854H4.00488L4.41444 2.01804H6.1383L6.00177 3.91854Z"
                fill="#FC502A"
            />
            <path
                d="M7.99884 3.91854H6.00195L6.13848 2.01804H7.86234L7.99884 3.91854Z"
                fill="white"
            />
            <path
                d="M9.99569 3.91854H7.9988L7.8623 2.01804H9.58616L9.99569 3.91854Z"
                fill="#FC502A"
            />
            <path
                d="M11.9924 3.91854H9.99546L9.58594 2.01804H11.3098L11.9924 3.91854Z"
                fill="white"
            />
            <path
                d="M13.989 3.91854H11.9922L11.3096 2.01804H13.0334L13.989 3.91854Z"
                fill="#FC502A"
            />
            <path
                d="M13.544 11.9958H0.456012C0.204176 11.9958 0 12.1999 0 12.4518V13.12C0 13.1914 0.0578867 13.2493 0.129254 13.2493H13.8707C13.9421 13.2493 14 13.1914 14 13.12V12.4518C14 12.1999 13.7958 11.9958 13.544 11.9958Z"
                fill="#8B8996"
            />
            <path
                d="M13.5444 11.9958H12.4746V13.2493H13.8712C13.9426 13.2493 14.0004 13.1914 14.0004 13.12V12.4518C14.0004 12.1999 13.7962 11.9958 13.5444 11.9958Z"
                fill="#706F7F"
            />
            <path
                d="M11.8102 10.6555H5.66021C5.55986 10.6555 5.47852 10.5742 5.47852 10.4738V7.08972C5.47852 6.98937 5.55986 6.90802 5.66021 6.90802H11.8102C11.9105 6.90802 11.9919 6.98937 11.9919 7.08972V10.4738C11.9919 10.5742 11.9105 10.6555 11.8102 10.6555Z"
                fill="#00C6C8"
            />
            <path
                d="M11.8109 6.90802H10.4668V10.6555H11.8109C11.9113 10.6555 11.9926 10.5742 11.9926 10.4738V7.08972C11.9926 6.98937 11.9113 6.90802 11.8109 6.90802Z"
                fill="#0FB0C0"
            />
            <path
                d="M4.61548 11.9958H1.80957V7.08972C1.80957 6.98937 1.89092 6.90802 1.99127 6.90802H4.43378C4.53413 6.90802 4.61548 6.98937 4.61548 7.08972V11.9958Z"
                fill="#00C6C8"
            />
            <path
                d="M4.433 6.90802H3.08887V11.9958H4.61468V7.08972C4.6147 6.98937 4.53336 6.90802 4.433 6.90802Z"
                fill="#0FB0C0"
            />
            <path
                d="M3.83137 9.78182C3.94805 9.78182 4.04263 9.68724 4.04263 9.57056V9.19029C4.04263 9.07362 3.94805 8.97903 3.83137 8.97903C3.7147 8.97903 3.62012 9.07362 3.62012 9.19029V9.57056C3.62009 9.68724 3.7147 9.78182 3.83137 9.78182Z"
                fill="white"
            />
            <defs>
                <clipPath id="clip0_12302_17314">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default StoreIcon;
