import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import {
    CustomerUserPostRequestModel,
    CustomerUserUpdateRequestModel,
} from "@/models/customerUserCollectionModel";
import {
    IOrderHandlerAssignSpecificCustomer,
    IOrderHandlerUnassignSpecificCustomer,
} from "@/types/customerUserCollection";

class CustomerUserResource extends BaseResource {
    path = `/api/user/admin/customer-user/v1/customer-users`;

    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.path}?expand=orderHandler`;
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    create(payload: CustomerUserPostRequestModel): ResponsePromise {
        const path = `${this.path}/`;
        return this.client.request("POST", path, payload, {});
    }

    update(
        id: number,
        payload: CustomerUserUpdateRequestModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    orderHandlerAssignSpecificCustomer(
        payload: IOrderHandlerAssignSpecificCustomer,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/core/admin/order-handler/v1/order-handlers/assign-to-customer`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    orderHandlerUnAssignAssignSpecificCustomer(
        payload: IOrderHandlerUnassignSpecificCustomer,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `/api/core/admin/order-handler/v1/order-handlers/unassign-from-customer`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
}

export default CustomerUserResource;
