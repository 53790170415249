import {
    IBuyOrderWithProducts,
    IBuyOrderCollection,
    ISequence,
    SequenceSlot,
    IVariant,
    ISequencesData,
} from "@/types/buyOrderCollection";
import { IPagination } from "@/types/pagination";
import { IFilterType } from "@/types/filters";
import {
    BuyProductVariationCollection,
    IActionType,
    IBuyAction,
    IBuyActionCollection,
    IBuyOrder,
    IBuyProductCustomerAssociation,
    IBuyProductPackage,
    IBuyProductVariation,
    IBuyProductVariationPropertiesCollection,
    IBuyProductVariationProperty,
    ICustomerInvoice,
    IOrderHandler,
    IRegisteredCountryDetail,
    IShippingSlot,
    IShippingSlotCollection,
} from "@/types/buyProductCollection";

import { TakeActionsEnums } from "@/enums/takeActionsEnums";
import {
    IBuyOrderCurrency,
    IBuyProductRegion,
    IOrderFilters,
    IOrderFiltersPropertyValue,
    IOrderFiltersPropertyValueOptions,
    IProductSeller,
} from "@/types/buyOrderDetail";
import { IAgentWarehouse } from "@/types/agentWarehouseCollection";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import { IInvoice, ITransactionSummary } from "@/types/InvoiceCollection";
import { BuyProductModel } from "./buyProductCollectionModel";
import { CustomerUserModel } from "./customerUserCollectionModel";

export class BuyOrderCollectionModel {
    object: "BuyOrderCollection";
    data: IBuyOrderWithProducts[];
    filters: IFilterType;
    pagination: IPagination;

    constructor(payload: IBuyOrderCollection) {
        this.object = payload.object;
        this.data = payload.data;
        this.filters = payload.filters;
        this.pagination = payload.pagination;
    }

    getData(): BuyOrderWithProductModel[] {
        return this.data.map(
            (buyOrder) => new BuyOrderWithProductModel(buyOrder),
        );
    }

    getFilters() {
        return this.filters;
    }

    getPagination() {
        return new PaginationCompressedModel(this.pagination);
    }

    static getStatusLabel(value: string) {
        const statusInfo = [
            { label: "Pending", value: "shipment-pending", color: "warning" },
            { label: "Approved", value: "shipment-approved", color: "success" },
            { label: "Rejected", value: "shipment-rejected", color: "error" },
            {
                label: "Assigned agent",
                value: "shipment-assigned-agent",
                color: "processing",
            },
            {
                label: "Shipping started",
                value: "shipment-shipping-started",
                color: "processing",
            },
            {
                label: "Shipping not arrived",
                value: "shipment-not-arrived",
                color: "error",
            },
            {
                label: "Shipping cancelled",
                value: "shipment-shipping-cancelled",
                color: "error",
            },
            {
                label: "Arrived at warehouse",
                value: "shipment-arrived-at-warehouse",
                color: "processing",
            },
            {
                label: "Handover to travel agent",
                value: "shipment-handover-to-travel-agent",
                color: "processing",
            },
            {
                label: "Handover to MoveOn",
                value: "shipment-handover-to-moveon",
                color: "processing",
            },
            {
                label: "Preparing for transport",
                value: "shipment-preparing-for-transport",
                color: "processing",
            },
            {
                label: "Handover to ship",
                value: "shipment-handover-to-ship",
                color: "processing",
            },
            {
                label: "Handover to airline",
                value: "shipment-handover-to-airline",
                color: "processing",
            },
            {
                label: "Arrived at destination port",
                value: "shipment-arrived-at-destination-port",
                color: "processing",
            },
            {
                label: "Arrived at destination airport",
                value: "shipment-arrived-at-destination-airport",
                color: "processing",
            },
            {
                label: "Customs released",
                value: "shipment-customs-released",
                color: "processing",
            },
            {
                label: "Received by MoveOn",
                value: "shipment-received-by-moveon",
                color: "success",
            },
            {
                label: "Delivery requested",
                value: "shipment-delivery-requested",
                color: "success",
            },
            {
                label: "Delivery processing",
                value: "shipment-delivery-processing",
                color: "success",
            },
            {
                label: "Prepared for delivery",
                value: "shipment-prepared-for-delivery",
                color: "success",
            },
            {
                label: "Handover to courier",
                value: "shipment-handover-to-courier",
                color: "success",
            },
            {
                label: "Delivered to customer",
                value: "shipment-delivered-to-customer",
                color: "success",
            },
            {
                label: "Delivery failed",
                value: "shipment-delivery-failed",
                color: "success",
            },
            {
                label: "Delivery returned",
                value: "shipment-delivery-returned",
                color: "success",
            },
        ];
        return statusInfo.find((item) => item.value === value);
    }
}

export class BuyOrderModel {
    object: "BuyOrder";
    id: number;
    order_number: string;
    order_tag?: string | null;
    order_type: string;
    order_status: string;
    payment_status: string;
    note?: string | null;
    meta: any[];
    order_handler?: IOrderHandler;
    customer?: CustomerUserModel;
    created_at: string;
    type: string;
    status: string;
    oauth_client_id: string;
    addresses: { [key: string]: string }[];

    constructor(payload: IBuyOrder) {
        this.object = payload.object;
        this.id = payload.id;
        this.order_number = payload.order_number;
        this.order_tag = payload.order_tag;
        this.order_type = payload.order_type;
        this.order_status = payload.order_status;
        this.payment_status = payload.payment_status;
        this.note = payload.note;
        this.meta = payload.meta;
        this.order_handler = payload.order_handler;
        this.customer =
            payload.customer && new CustomerUserModel(payload.customer);
        this.created_at = payload.created_at;
        this.type = payload.type;
        this.status = payload.status;
        this.oauth_client_id = payload.oauth_client_id;
        this.addresses = payload.addresses;
    }

    getId() {
        return this.id;
    }
    getCreatedAt() {
        return this.created_at;
    }

    getOrderNumber(): string {
        return this.order_number;
    }
    getPaymentStatus() {
        return this.payment_status;
    }
    getOrderStatus() {
        return this.order_status;
    }
    getOrderType() {
        return this.order_type;
    }

    getCustomer() {
        return this.customer;
    }

    getOrderHandler() {
        return this.order_handler && new OrderHandlerModel(this.order_handler);
    }
}
export class BuyOrderWithProductModel extends BuyOrderModel {
    products: BuyProductModel[];
    constructor(payload: IBuyOrderWithProducts) {
        super(payload);
        this.products = payload.products.data.map(
            (item) => new BuyProductModel(item),
        );
    }

    getProducts() {
        return this.products;
    }
    hasProductWithDueInvoice = () => {
        return this.products.some((product) => {
            const transactionSummary = product
                .getCustomerInvoice()
                ?.getTransactionSummary();
            const due = transactionSummary?.due;
            return typeof due === "number" && due > 0;
        });
    };
    getInvoicesWithDue = () =>
        this.getProducts() && this.getProducts().length > 0
            ? this.getProducts().map((product) => product.getCustomerInvoiceId)
            : undefined;

    getFirstProductRegion = () =>
        this.getProducts() && this.getProducts().length > 0
            ? this.getProducts()[0].getRegion()
            : undefined;
    getFirstProductRegionCode = () =>
        this.getProducts() && this.getProducts().length > 0
            ? this.getProducts()[0].getRegion()?.getCode()
            : "";
    getFirstProductRegionId = () =>
        this.getProducts() && this.getProducts().length > 0
            ? this.getProducts()[0].getRegion()?.getId()
            : "";

    getTotalDue(): number {
        return this.getProducts().reduce((acc, curr) => {
            if (
                curr.customer_invoice &&
                curr.customer_invoice.transaction_summary
            ) {
                return acc + curr.customer_invoice.transaction_summary.due;
            } else {
                return 0;
            }
        }, 0);
    }
    getTotalPaid(): number {
        return this.getProducts().reduce((acc, curr) => {
            if (
                curr.customer_invoice &&
                curr.customer_invoice.transaction_summary.paid
            ) {
                return acc + curr.customer_invoice.transaction_summary.paid;
            } else {
                return 0;
            }
        }, 0);
    }
    getTotalAmount(): number {
        return this.getProducts().reduce((acc, curr) => {
            if (curr.customer_invoice && curr.customer_invoice?.total_amount) {
                return acc + curr.customer_invoice.total_amount;
            } else {
                return 0;
            }
        }, 0);
    }

    getTotalRefundableAmount(): number {
        return this.getProducts().reduce((acc, curr) => {
            if (
                curr.customer_invoice &&
                curr.customer_invoice?.refundable_amount
            ) {
                return acc + curr.customer_invoice.refundable_amount;
            } else {
                return 0;
            }
        }, 0);
    }
    getTotalQuantity(): number {
        return this.getProducts().reduce((acc, curr) => {
            if (curr.total_quantity) {
                return acc + curr.total_quantity;
            } else {
                return 0;
            }
        }, 0);
    }
    getCurrencyCode() {
        const product = this.getProducts().find(
            (item) => item?.region?.currency?.code,
        );
        if (product) {
            return product?.region?.currency?.code;
        }
        return "";
    }
}

export class CustomerInvoiceModel {
    object: string;
    id: number;
    invoice_number: string;
    status: string;
    total_amount: number;
    initial_amount: number;
    refundable_amount: number;
    transaction_summary: ITransactionSummary;
    created_at: string;

    constructor(payload: ICustomerInvoice) {
        this.object = payload.object;
        this.id = payload.id;
        this.invoice_number = payload.invoice_number;
        this.status = payload.status;
        this.total_amount = payload.total_amount;
        this.initial_amount = payload.initial_amount;
        this.refundable_amount = payload.refundable_amount;
        this.transaction_summary = payload.transaction_summary;
        this.created_at = payload.created_at;
    }

    getId() {
        return this.id;
    }

    getInvoiceNumber() {
        return this.invoice_number;
    }

    getStatus() {
        return this.status;
    }

    getTotalAmount() {
        return this.total_amount;
    }

    getInitialAmount() {
        return this.initial_amount;
    }

    getRefundableAmount() {
        return this.refundable_amount;
    }

    getTransactionSummary() {
        return this.transaction_summary;
    }

    getCreatedAt() {
        return this.created_at;
    }

    calculatePaidPercentage(): number {
        return this.total_amount
            ? (this.transaction_summary.paid / this.total_amount) * 100
            : 0;
    }
}

export class RegisteredCountryDetailModel {
    id: number;
    code: string;
    name: string;
    status: string;
    created_at: string;

    constructor(payload: IRegisteredCountryDetail) {
        this.id = payload.id;
        this.code = payload.code;
        this.name = payload.name;
        this.status = payload.status;
        this.created_at = payload.created_at;
    }
}

export class ActionTypeModel {
    label: string;
    value: string;

    constructor(payload: IActionType) {
        this.label = payload.label;
        this.value = payload.value;
    }
}

export class BuyActionModel {
    object: string;
    id: number;
    action_number: string;
    buy_product_id: number;
    status: string;
    admin_note: string | null;
    customer_note: string | null;
    agent_note: string | null;
    action_type: ActionTypeModel[];
    created_at: string;

    constructor(payload: IBuyAction) {
        this.object = payload.object;
        this.id = payload.id;
        this.action_number = payload.action_number;
        this.buy_product_id = payload.buy_product_id;
        this.status = payload.status;
        this.admin_note = payload.admin_note;
        this.customer_note = payload.customer_note;
        this.agent_note = payload.agent_note;
        this.action_type = payload.action_type.map(
            (item) => new ActionTypeModel(item),
        );
        this.created_at = payload.created_at;
    }

    getObject() {
        return this.object;
    }

    getId() {
        return this.id;
    }

    getActionNumber() {
        return this.action_number;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getStatus() {
        return this.status;
    }

    getAdminNote() {
        return this.admin_note;
    }

    getCustomerNote() {
        return this.customer_note;
    }

    getAgentNote() {
        return this.agent_note;
    }

    getActionType() {
        return this.action_type;
    }

    getCreatedAt() {
        return this.created_at;
    }
}

export class BuyActionCollectionModel {
    object: string;
    data: BuyActionModel[];

    constructor(payload: IBuyActionCollection) {
        this.object = payload.object;
        this.data = payload.data.map((item) => new BuyActionModel(item));
    }

    getObject() {
        return this.object;
    }

    getData() {
        return this.data;
    }
}

export class InvoiceModel {
    object: string;
    id: number;
    invoice_number: string;
    total_amount: number;
    status: string;

    constructor(payload: IInvoice) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.invoice_number = payload?.invoice_number;
        this.total_amount = payload?.total_amount;
        this.status = payload?.status;
    }

    getId() {
        return this.id;
    }

    getInvoiceNumber() {
        return this.invoice_number;
    }

    getTotal() {
        return this.total_amount;
    }

    getStatus() {
        return this.status;
    }
}

export class OrderModel {
    id: number;
    order_number: string;
    order_handler?: OrderHandlerModel;
    type: string;
    status: string;
    payment_status: string;
    customer?: CustomerUserModel;
    created_at: string;
    oauth_client_id: null | string;
    addresses: { [key: string]: string }[];
    meta: any[];

    constructor(data: IBuyOrder) {
        this.id = data.id;
        this.order_number = data.order_number;
        this.order_handler = data.order_handler
            ? new OrderHandlerModel(data.order_handler)
            : undefined;
        this.customer = data.customer && new CustomerUserModel(data.customer);
        this.type = data.type;
        this.status = data.status;
        this.payment_status = data.payment_status;
        this.created_at = data.created_at;
        this.oauth_client_id = data.oauth_client_id;
        this.addresses = data.addresses;
        this.meta = data.meta;
    }
    getId() {
        return this.id;
    }

    getOrderNumber() {
        return this.order_number;
    }

    getCustomer() {
        return this.customer;
    }

    getOrderHandler() {
        return this.order_handler;
    }
}

export class OrderHandlerModel {
    object: string;
    id: number;
    name: string;
    phone?: string | null;
    email: string;
    email_verified_at?: string;
    type: string;
    is_drop_user?: boolean;
    order_handler?: string;
    billing_addr_id?: string;
    shipping_addr_id?: string;
    agent_company_id?: string;
    shipping_mark?: string;
    is_active: number;
    created_at: string;
    updated_at?: string;
    lock_version?: string;
    etag?: string;
    gravatar?: string;

    constructor(payload: IOrderHandler) {
        this.object = payload.object;
        this.id = payload.id;
        this.name = payload.name;
        this.phone = payload.phone;
        this.email = payload.email;
        this.email_verified_at = payload.email_verified_at;
        this.type = payload.type;
        this.is_drop_user = payload.is_drop_user;
        this.order_handler = payload.order_handler;
        this.billing_addr_id = payload.billing_addr_id;
        this.shipping_addr_id = payload.shipping_addr_id;
        this.agent_company_id = payload.agent_company_id;
        this.shipping_mark = payload.shipping_mark;
        this.is_active = payload.is_active;
        this.created_at = payload.created_at;
        this.updated_at = payload.updated_at;
        this.lock_version = payload.lock_version;
        this.etag = payload.etag;
        this.gravatar = payload.gravatar;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getPhone() {
        return this.phone;
    }

    getEmail() {
        return this.email;
    }

    getEmailVerifiedAt() {
        return this.email_verified_at;
    }

    getType() {
        return this.type;
    }

    getIsDropUser() {
        return this.is_drop_user;
    }

    getOrderHandler() {
        return this.order_handler;
    }

    getBillingAddressId() {
        return this.billing_addr_id;
    }

    getShippingAddressId() {
        return this.shipping_addr_id;
    }

    getAgentCompanyId() {
        return this.agent_company_id;
    }

    getShippingMark() {
        return this.shipping_mark;
    }

    getIsActive() {
        return this.is_active;
    }

    getCreatedAt() {
        return this.created_at;
    }

    getUpdatedAt() {
        return this.updated_at;
    }

    getLockVersion() {
        return this.lock_version;
    }

    getEtag() {
        return this.etag;
    }

    getGravatar() {
        return this.gravatar;
    }
}

export class BuyProductRegionModel {
    public object: string;
    public id: number;
    public name: string;
    public code: string;
    public store_type: string;
    public store: string;
    public currency?: BuyOrderCurrencyModel;

    constructor(region: IBuyProductRegion) {
        this.object = region.object;
        this.id = region.id;
        this.name = region.name;
        this.code = region.code;
        this.store_type = region.store_type;
        this.store = region.store;
        this.currency = region.currency
            ? new BuyOrderCurrencyModel(region.currency)
            : undefined;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    getCode() {
        return this.code;
    }

    getStoreType() {
        return this.store_type;
    }

    getStore() {
        return this.store;
    }

    getCurrency() {
        return this.currency;
    }
}

export class BuyOrderCurrencyModel {
    object: string;
    id: number;
    code: string;
    name: string;
    symbol: string;
    symbol_native: string;

    constructor(data: IBuyOrderCurrency) {
        this.object = data?.object;
        this.id = data?.id;
        this.code = data?.code;
        this.symbol = data?.symbol;
        this.symbol_native = data?.symbol_native;
        this.name = data?.name;
    }

    getId() {
        return this.id;
    }

    getCode() {
        return this.code;
    }

    getSymbol() {
        return this.symbol;
    }

    getSymbolNative() {
        return this.symbol_native;
    }

    getName() {
        return this.name;
    }
}

export class ProductSellerModel {
    object: string;
    id: number;
    seller_id: string;
    seller_name: string;

    constructor(payload: IProductSeller) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.seller_id = payload?.seller_id;
        this.seller_name = payload?.seller_name;
    }

    getId() {
        return this.id;
    }

    getSellerId() {
        return this.seller_id;
    }

    getSellerName() {
        return this.seller_name;
    }
}

export class BuyProductCustomerAssociationModel {
    object: string;
    id: number;
    buy_product_id: number;
    include_tax: number;
    sub_total: number;
    tax_total: number;
    total: number;
    original_sub_total: number;
    original_total: number;
    discount_total: number;
    fx_rate: number;
    commission_rate: number;
    commission_rate_type: string;

    constructor(payload: IBuyProductCustomerAssociation) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.buy_product_id = payload?.buy_product_id;
        this.include_tax = payload?.include_tax;
        this.sub_total = payload?.sub_total;
        this.tax_total = payload?.tax_total;
        this.total = payload?.total;
        this.original_sub_total = payload?.original_sub_total;
        this.original_total = payload?.original_total;
        this.discount_total = payload?.discount_total;
        this.fx_rate = payload?.fx_rate;
        this.commission_rate = payload?.commission_rate;
        this.commission_rate_type = payload?.commission_rate_type;
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getIncludeTax() {
        return this.include_tax;
    }

    getSubTotal() {
        return this.sub_total;
    }

    getTaxTotal() {
        return this.tax_total;
    }

    getTotal() {
        return this.total;
    }

    getDiscountTotal() {
        return this.discount_total;
    }

    getFxRate() {
        return this.fx_rate;
    }

    getCommissionRate() {
        return this.commission_rate;
    }

    getCommissionRateType() {
        return this.commission_rate_type;
    }

    getTotalCommissionAndFx = (): string => {
        let total: number = 0;

        if (this.commission_rate_type === CommissionRateTypeEnums.PERCENTAGE) {
            total = this.fx_rate + (this.fx_rate * this.commission_rate) / 100;
        } else if (
            this.commission_rate_type === CommissionRateTypeEnums.FIXED
        ) {
            total = this.fx_rate + this.commission_rate;
        }
        return total.toFixed(2);
    };
}

export class BuyProductPackageModel {
    object: string;
    id: number;
    buy_product_id: number;
    weight: number;
    weight_unit: string;
    length: number;
    length_unit: string;
    width: number;
    width_unit: string;
    height: number;
    height_unit: string;

    constructor(payload: IBuyProductPackage) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.buy_product_id = payload?.buy_product_id;
        this.weight = payload?.weight;
        this.weight_unit = payload?.weight_unit;
        this.length = payload?.length;
        this.length_unit = payload?.length_unit;
        this.width = payload?.width;
        this.width_unit = payload?.width_unit;
        this.height = payload?.height;
        this.height_unit = payload?.height_unit;
    }

    getId() {
        return this.object;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getWeight() {
        return this.weight;
    }

    getWeightUnit() {
        return this.weight_unit;
    }

    getLength() {
        return this.length;
    }

    getLengthUnit() {
        return this.length_unit;
    }

    getWidth() {
        return this.width;
    }

    getHeight() {
        return this.height;
    }

    getHeightUnit() {
        return this.height_unit;
    }
}

export class AgentWarehouseModel {
    object: string;
    id: number;
    name: string;

    constructor(payload: IAgentWarehouse) {
        this.object = payload.object;
        this.id = payload.id;
        this.name = payload.name;
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }
}

export class ShippingSlotCollectionModel {
    object: string;
    data: ShippingSlotModel[];

    constructor(payload: IShippingSlotCollection) {
        this.object = payload?.object;
        this.data = payload?.data?.map(
            (singleData) => new ShippingSlotModel(singleData),
        );
    }
}

export class ShippingSlotModel {
    object: string;
    id: number;
    min_amount: number;
    max_amount: number;
    rate: number;

    constructor(payload: IShippingSlot) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.min_amount = payload?.min_amount;
        this.max_amount = payload?.max_amount;
        this.rate = payload?.rate;
    }

    getId() {
        return this.id;
    }

    getMinAmount() {
        return this.min_amount;
    }

    getMaxAmount() {
        return this.max_amount;
    }

    getRate() {
        return this.rate;
    }
}

export class BuyProductVariationCollectionModel {
    object: string;
    data: BuyProductVariationModel[];

    constructor(payload: BuyProductVariationCollection) {
        this.object = payload?.object;
        this.data = payload?.data?.map(
            (buyProductVariation) =>
                new BuyProductVariationModel(buyProductVariation),
        );
    }

    getData() {
        return this.data;
    }
}

export class BuyProductVariationModel {
    object: string;
    id: number;
    buy_product_id: number;
    cart_item_variation_id?: number;
    sku_id: string;
    image: string;
    quantity: number;
    unit_price: number;
    total_price: number;
    original_unit_price: number;
    original_total_price: number;
    properties: BuyProductVariationPropertiesCollectionModel;

    constructor(payload: IBuyProductVariation) {
        this.object = payload.object;
        this.id = payload.id;
        this.buy_product_id = payload.buy_product_id;
        this.sku_id = payload.sku_id;
        this.image = payload.image;
        this.quantity = payload.quantity;
        this.unit_price = payload.unit_price;
        this.original_unit_price = payload.original_unit_price;
        this.original_total_price = payload.original_total_price;
        this.total_price = payload.total_price;
        this.properties = new BuyProductVariationPropertiesCollectionModel(
            payload?.properties,
        );
    }

    getId() {
        return this.id;
    }

    getBuyProductId() {
        return this.buy_product_id;
    }

    getCartItemVariationId() {
        return this.cart_item_variation_id;
    }

    getSkuId() {
        return this.sku_id;
    }

    getImage() {
        return this.image;
    }

    getQuantity() {
        return this.quantity;
    }

    getUnitPrice() {
        return this.unit_price;
    }

    getTotalPrice() {
        return this.total_price;
    }

    getOriginalUnitPrice() {
        return this.original_unit_price;
    }

    getOriginalTotalPrice() {
        return this.original_total_price;
    }

    getProperties() {
        return this.properties;
    }
}

export class BuyProductVariationPropertiesCollectionModel {
    object: string;
    data: BuyProductVariationPropertyModel[];

    constructor(payload: IBuyProductVariationPropertiesCollection) {
        this.object = payload?.object;
        this.data = payload?.data?.map(
            (buyProductVariationProperty) =>
                new BuyProductVariationPropertyModel(
                    buyProductVariationProperty,
                ),
        );
    }

    getData() {
        return this.data;
    }
}

export class BuyProductVariationPropertyModel {
    object: string;
    id: number;
    property_id: string;
    property_name: string;
    property_original_name: string;
    property_value_id: string;
    property_value_name: string;
    property_value_color?: string | null;
    property_value_title: string;
    property_value_image: string;
    property_value_thumb: string;

    constructor(payload: IBuyProductVariationProperty) {
        this.object = payload?.object;
        this.id = payload?.id;
        this.property_id = payload?.property_id;
        this.property_name = payload?.property_name;
        this.property_original_name = payload?.property_original_name;
        this.property_value_id = payload?.property_value_id;
        this.property_value_name = payload?.property_value_name;
        this.property_value_color = payload?.property_value_color;
        this.property_value_title = payload?.property_value_title;
        this.property_value_image = payload?.property_value_image;
        this.property_value_thumb = payload?.property_value_thumb;
    }

    getId() {
        return this.id;
    }

    getPropertyId() {
        return this.property_id;
    }

    getPropertyName() {
        return this.property_name;
    }

    getPropertyOriginalName() {
        return this.property_original_name;
    }

    getPropertyValueId() {
        return this.property_value_id;
    }

    getPropertyValueName() {
        return this.property_value_name;
    }

    getPropertyValueColor() {
        return this.property_value_color;
    }

    getPropertyValueTitle() {
        return this.property_value_title;
    }

    getPropertyValueImage() {
        return this.property_value_image;
    }

    getPropertyValueThumb() {
        return this.property_value_thumb;
    }
}

export class OrderFiltersPropertyValueOptionsModel {
    public readonly label: string;
    public readonly value: string;
    public readonly group?: string;

    constructor(data: IOrderFiltersPropertyValueOptions) {
        this.label = data.label;
        this.value = data.value;
        this.group = data?.group;
    }
}

export class OrderFiltersPropertyValueModel {
    public readonly type: string;
    public readonly placeholder: string;
    public readonly label: string;
    public readonly values?: OrderFiltersPropertyValueOptionsModel[];
    public readonly data?: string;
    public readonly resource?: string;

    constructor(data: IOrderFiltersPropertyValue) {
        this.type = data.type;
        this.placeholder = data.placeholder;
        this.label = data.label;
        this.values = data?.values
            ? data.values.map(
                  (item) => new OrderFiltersPropertyValueOptionsModel(item),
              )
            : undefined;
        this.data = data?.data;
        this.resource = data?.resource;
    }
}

export class OrderFiltersModel {
    public readonly keyword: OrderFiltersPropertyValueModel;
    public readonly product_status: OrderFiltersPropertyValueModel;
    public readonly payment_status: OrderFiltersPropertyValueModel;
    public readonly shipping_country: OrderFiltersPropertyValueModel;
    public readonly order_amount: OrderFiltersPropertyValueModel;
    // public readonly group: OrderFiltersPropertyValueModel;
    public readonly seller_id: OrderFiltersPropertyValueModel;

    constructor(data: IOrderFilters) {
        this.keyword = new OrderFiltersPropertyValueModel(data.keyword);
        this.product_status = new OrderFiltersPropertyValueModel(
            data.product_status,
        );
        this.payment_status = new OrderFiltersPropertyValueModel(
            data.payment_status,
        );
        this.shipping_country = new OrderFiltersPropertyValueModel(
            data.shipping_country,
        );
        this.order_amount = new OrderFiltersPropertyValueModel(
            data.order_amount,
        );
        // this.group = new OrderFiltersPropertyValueModel(data.group);
        this.seller_id = new OrderFiltersPropertyValueModel(data.seller_id);
    }
}

export class PaginationCompressedModel {
    public current_page: number;
    public from: number;
    public last_page: number;
    public per_page: number;
    public to: number;
    public total: number;

    constructor(data: IPagination) {
        this.current_page = data.current_page;
        this.from = data.from;
        this.last_page = data.last_page;
        this.per_page = data.per_page;
        this.to = data.to;
        this.total = data.total;
    }

    getCurrentPage() {
        return this.current_page;
    }

    getFrom() {
        return this.from;
    }

    getLastPage() {
        return this.last_page;
    }

    getPerPage() {
        return this.per_page;
    }

    getTo() {
        return this.to;
    }

    getTotalData() {
        return this.total;
    }
}

export class VariantModel {
    variant_id: number;
    new_original_unit_price?: number;
    new_quantity?: number;
    suggested_sku_id?: string;

    constructor(data: IVariant) {
        this.variant_id = data.variant_id;
        this.new_original_unit_price = data.new_original_unit_price;
        this.new_quantity = data.new_quantity;
        this.suggested_sku_id = data.suggested_sku_id;
    }
}

export class SequenceModel {
    action_type: TakeActionsEnums;
    variants?: VariantModel[];
    addon_ids?: any[];
    images?: any[];
    new_shipping_category_id?: string;
    original_amount?: number;
    new_fx?: number;
    minimum_order_quantity?: number;
    new_rate?: number;
    new_agent_category_price_read?: number;
    slots?: SequenceSlot[];
    admin_note: string;

    constructor(data: ISequence) {
        this.action_type = data.action_type;
        this.variants = data.variants?.map(
            (variant) => new VariantModel(variant),
        );
        this.addon_ids = data.addon_ids;
        this.images = data.images;
        this.new_shipping_category_id = data.new_shipping_category_id;
        this.minimum_order_quantity = data?.minimum_order_quantity;
        this.original_amount = data.original_amount;
        this.new_fx = data.new_fx;
        this.new_rate = data.new_rate;
        this.slots = data.slots;
        this.admin_note = data.admin_note;
    }
}

export class SequencesDataModel {
    sequences: SequenceModel[];
    admin_note: string;

    constructor(data: ISequencesData) {
        this.sequences = data.sequences.map(
            (sequence) => new SequenceModel(sequence),
        );
        this.admin_note = data.admin_note;
    }
}
