import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InvoiceModel } from "@/models/invoiceCollectionModel";
import { Button, Result, Skeleton } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import BasicDetailsCard from "../component/basicDetailsCard";
import InvoiceTransaction from "../component/invoiceTransaction";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import { useService } from "@/lib/core-react";
import InvoiceItem from "../component/invoiceItem";
import BuyAndShipmentProducts from "../component/BuyAndShipmentProducts";
const Details = () => {
    const navigate = useNavigate();
    const { invoiceService } = useService();
    const { id: invoiceId } = useParams<{ id: string }>();
    const [invoiceDetails, setInvoiceDetails] = useState<{
        isLoading: boolean;
        error: any;
        data: InvoiceModel | undefined;
    }>({
        isLoading: true,
        error: null,
        data: undefined,
    });

    useEffect(() => {
        if (invoiceId) {
            getInvoiceDetailsById(invoiceId);
        }
    }, [invoiceId]);

    const getInvoiceDetailsById = async (
        id: number | string,
    ): Promise<void> => {
        setInvoiceDetails((pre) => ({
            ...pre,
            isLoading: true,
            error: null,
        }));

        try {
            const response =
                await invoiceService.invoiceResource.getCustomerInvoiceDetails(
                    id,
                );
            setInvoiceDetails((pre) => ({
                ...pre,
                data: new InvoiceModel(response.data),
                isLoading: false,
                error: null,
            }));
        } catch (error: any) {
            setInvoiceDetails((pre) => ({
                ...pre,
                isLoading: false,
                error: getError(error),
            }));
        }
    };

    if (invoiceDetails.isLoading) {
        return <Skeleton active paragraph={{ rows: 20 }} />;
    }

    if (invoiceDetails.error) {
        return (
            <Result
                status="500"
                title="Please wait"
                subTitle={invoiceDetails.error || "Something went wrong."}
                extra={
                    <Button type="primary" onClick={() => navigate(-1)}>
                        Back Home
                    </Button>
                }
            />
        );
    }

    if (!invoiceDetails.data) {
        return (
            <Result
                status="404"
                title="No Invoice Found"
                subTitle="The invoice data might be missing or incorrect."
                extra={
                    <Button type="primary" onClick={() => navigate(-1)}>
                        Back Home
                    </Button>
                }
            />
        );
    }

    return (
        <div>
            <PageHeader
                onBack={() => navigate(-1)}
                title={`Invoice: ${invoiceDetails.data.getInvoiceNumber()}`}
            />
            <BasicDetailsCard
                detailsFor={"customer"}
                invoice={invoiceDetails.data}
            />
            <BuyAndShipmentProducts invoice={invoiceDetails.data} />
            <InvoiceItem invoice={invoiceDetails.data} />
            <InvoiceTransaction
                transactionFor={"customer"}
                invoice={invoiceDetails.data}
            />
        </div>
    );
};

export default Details;
