import { ITranslationEntry } from "../lang";

export const RO: ITranslationEntry = {
    translations: {
        notifications: "Notificări",
        markAllAsRead: "Marcheaza totul ca fiind citit",
        poweredBy: "Cu sprijinul",
        settings: "Setări",
        noNewNotification: "Nimic nou de văzut aici încă",
    },
    lang: "ro",
};
