import { Alert, Form, Modal, notification } from "antd";
import React, { useEffect } from "react";
import Address from "../../BuyOrderPlace/Adddress";
import {
    useAddressDelete,
    useGetAddress,
} from "@/lib/core-react/hooks/private/useAddress";
import { useSearchParams } from "react-router-dom";
import { addressCollectionAtom } from "@/lib/core-react/store/store";
import { useAtom } from "jotai";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import { AddressCollectionModel } from "@/models/addressCollectionModel";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { showError } from "@/helpers/showError";

interface IProps {
    selectedAddressId: number | undefined;
    setSelectedAddressId: React.Dispatch<
        React.SetStateAction<number | undefined>
    >;
    shippingAddressErrorMessage: string | null;
}
const ShippingAddressSelect = ({
    selectedAddressId,
    setSelectedAddressId,
    shippingAddressErrorMessage,
}: IProps) => {
    const [
        {
            data: addressCollectionData,
            isLoading: isLoadingAddressData,
            error: errorAddress,
        },
    ] = useAtom(addressCollectionAtom);

    const { getAddress } = useGetAddress();
    const { deleteAddress } = useAddressDelete();

    const [params] = useSearchParams();
    const countryCode = params.get("countryCode");
    const userId = params.get("userId");
    useEffect(() => {
        if (countryCode && userId) {
            getAddress({
                countries: [countryCode],
                user_id: userId,
            });
        }
    }, [countryCode, userId]);

    const addressCollectionObj =
        addressCollectionData &&
        addressCollectionData.data &&
        new AddressCollectionModel(addressCollectionData);
    const handleDeleteAddress = async (addressId: number) => {
        Modal.confirm({
            title: "Are you sure you want to delete this address?",
            icon: <ExclamationCircleOutlined />,
            content: "Select Ok, otherwise the process cannot success.",
            onOk: async () => {
                try {
                    await deleteAddress(addressId);
                    showSuccessAlert("Successfully Address Deleted");
                    if (countryCode && userId) {
                        await getAddress({
                            countries: [countryCode],
                            user_id: userId,
                        });
                    }
                    Promise.resolve();
                    setSelectedAddressId(undefined);
                } catch (error) {
                    showError(error);
                    Promise.reject();
                }
            },
            onCancel() {
                notification["info"]({
                    message: "Product Reject was aborted.",
                });
            },
        });
    };

    return (
        <>
            <Form.Item>
                <p>
                    <span
                        style={{
                            color: "#ff4d4f",
                            fontFamily: "SimSun,sans-serif",
                        }}
                    >
                        *
                    </span>{" "}
                    Shipping Address
                </p>
                {isLoadingAddressData ? (
                    LoaderSmall
                ) : addressCollectionObj && !isLoadingAddressData ? (
                    <Address
                        handleDeleteAddress={handleDeleteAddress}
                        address={addressCollectionObj.getData()}
                        selectedAddressId={selectedAddressId}
                        setSelectedAddressId={setSelectedAddressId}
                    />
                ) : (
                    <Alert
                        type="error"
                        message={`Please change region and country. ${errorAddress || ""}`}
                    />
                )}
            </Form.Item>

            <span style={{ color: "red" }}>{shippingAddressErrorMessage}</span>
        </>
    );
};

export default ShippingAddressSelect;
