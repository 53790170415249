import { useEffect, createRef } from "react";
import { useCreateBaseShippingCategory } from "@/lib/core-react/hooks/private";
import { BaseShippingCategoryRequestModel } from "@/models/baseShippingCategory";
import React from "react";
import { Button, Form, Input, notification, Select } from "antd";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { BaseShippingCategoryDataStatus } from "@/enums/baseShippingCategoryCollectionEnums";
import { FormInstance } from "antd/es/form/Form";
import { Forms } from "@/models/form";
import { showError } from "@/helpers/showError";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateBaseShippingCategory = ({ setIsShowCreateModal }: IProps) => {
    const { createBaseShippingCategory, isLoading } =
        useCreateBaseShippingCategory();

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value: BaseShippingCategoryRequestModel) => {
        const payload = {
            name: value.name,
            status: value.status,
            slug: value.slug,
        };

        try {
            const res = await createBaseShippingCategory(
                payload as BaseShippingCategoryRequestModel,
            );
            if (res) {
                notification["success"]({
                    message: res.message,
                });
                setIsShowCreateModal(false);
            }
        } catch (error) {
            showError(error, form);
        }
    };
    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Create_Base_Shipping_Category"
                    ref={formRef}
                    onFieldsChange={onValuesChange}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message:
                                    "Base shipping category name is required",
                            },
                        ]}
                        label="Name"
                        name="name"
                    >
                        <Input placeholder="Base shipping category name" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            { required: true, message: "Slug is required" },
                        ]}
                        label="Slug"
                        name="slug"
                        style={{ opacity: 0.6 }}
                    >
                        <Input disabled={true} readOnly placeholder="Slug" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            { required: true, message: "Status is required" },
                        ]}
                        name="status"
                        label="Status"
                    >
                        <Select placeholder="Please select a Status">
                            {renderOptionsFromEnum(
                                BaseShippingCategoryDataStatus,
                            )}
                        </Select>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={isLoading}
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default CreateBaseShippingCategory;
