import { Select } from "antd";
import {
    AddonForEnum,
    DependsOnEnum,
} from "@/enums/addonServicesCollectionEnums";
import {
    CampaignStatusEnum,
    PublishStatusEnum,
} from "@/enums/campaignCollectionEnums";
import { LanguageCode } from "@/enums/languageCollectionEnums";
import {
    TransactionsRejectEnums,
    TransactionsStatusEnums,
} from "@/enums/transactionCollectionsEnums";
import { formatString } from "@/utils/helper";

interface IProps {
    [key: string | number]: any;
}

export const renderOptionsFromEnum = (enumData: IProps): JSX.Element[] => {
    return Object.entries(enumData).map((item) => {
        return (
            <Select.Option value={item[0]} key={item[0]}>
                {item[1]}
            </Select.Option>
        );
    });
};

export const renderOptionsFromEnum2 = (enumData: IProps): JSX.Element[] => {
    return Object.entries(enumData).map((item) => {
        return (
            <Select.Option value={item[1]} key={item[0]}>
                {item[1]}
            </Select.Option>
        );
    });
};

export const renderOptionsFromEnumForCode = (
    enumData: LanguageCode,
): JSX.Element[] => {
    return Object.entries(enumData).map(([key, value]) => (
        <Select.Option value={value} key={key}>
            {value}
        </Select.Option>
    ));
};

export const renderOptionsFromEnumUpdateStatus = (
    enumData: typeof TransactionsStatusEnums,
): JSX.Element[] => {
    return Object.entries(enumData).map(([key, value]) => (
        <Select.Option value={value} key={key}>
            {value}
        </Select.Option>
    ));
};

export const renderOptionsFromEnumUpdateStatusRejected = (
    enumData: typeof TransactionsRejectEnums,
): JSX.Element[] => {
    return Object.entries(enumData).map(([key, value]) => (
        <Select.Option value={value} key={key}>
            {value}
        </Select.Option>
    ));
};

export const renderOptionsFromEnumUpdateCampaignsSlug = (
    enumData: typeof CampaignStatusEnum,
): JSX.Element[] => {
    return Object.entries(enumData).map(([key, value]) => (
        <Select.Option value={value} key={key}>
            {value}
        </Select.Option>
    ));
};

export const renderOptionsFromEnumUpdateCampaignsPublished = (
    enumData: typeof PublishStatusEnum,
): JSX.Element[] => {
    return Object.entries(enumData).map(([key, value]) => (
        <Select.Option value={value} key={key}>
            {value}
        </Select.Option>
    ));
};

export const renderOptionsFromEnumAddonForEnum = (
    enumData: typeof AddonForEnum,
): JSX.Element[] => {
    return Object.entries(enumData).map(([key, value]) => (
        <Select.Option key={key} value={value}>
            {value
                .replace(/-/g, " ")
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
        </Select.Option>
    ));
};

export const renderOptionsFromEnumAddonDependsOnEnum = (
    enumData: typeof DependsOnEnum,
): JSX.Element[] => {
    return Object.entries(enumData).map(([key, value]) => (
        <Select.Option value={value} key={key}>
            {value
                .replace(/-/g, " ")
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
        </Select.Option>
    ));
};

export const renderOptionsFromEnumReversed = (
    enumData: IProps,
): JSX.Element[] => {
    return Object.entries(enumData).map((item) => (
        <Select.Option value={item[1]} key={item[1]}>
            {formatString(item[0])}
        </Select.Option>
    ));
};
export const renderSelectOptionsFromEnum = (enumData: IProps) => {
    return Object.entries(enumData).map((item) => {
        return {
            label: item[0],
            value: item[1],
        };
    });
};

export const convertEnumToSelectOptions = (enumObj: object) => {
    return Object.keys(enumObj).map((key) => ({
        label: key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase()),
        value: enumObj[key as keyof typeof enumObj],
    }));
};
