import React from "react";
import { Table, Tag, Typography } from "antd";
import { DisputeWithTransactionModel } from "@/models/disputeDetailsCollectionModel";
import { StatusTag } from "@/components";
import { formatDateTime } from "@/utils/helpers";
const { Text } = Typography;

const columns = [
    {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (id: number) => <Text strong># {id}</Text>,
    },
    {
        title: "Transaction Number",
        dataIndex: ["transaction", "transaction_number"],
        key: "transaction_number",
        render: (transaction_number: string) => (
            <Text strong copyable>
                {transaction_number}
            </Text>
        ),
    },
    {
        title: "Amount",
        dataIndex: ["transaction", "amount"],
        key: "amount",
        render: (amount: number) => <Text>{amount.toFixed(2)} BDT</Text>,
    },
    {
        title: "Transaction Type",
        dataIndex: ["transaction", "type"],
        key: "transaction_type",
        render: (type: string) => (
            <StatusTag
                slug={type}
                text={type}
                color={type === "debit" ? "red" : "green"}
            />
        ),
    },
    {
        title: "Description",
        dataIndex: ["transaction", "description"],
        key: "description",
        width: 250,
    },
    {
        title: "Status",
        dataIndex: ["transaction", "status"],
        key: "status",
        render: (status: string) => (
            <StatusTag
                slug={status}
                text={status}
                color={status === "available" ? "green" : "red"}
            />
        ),
    },
    {
        title: "Created At",
        dataIndex: ["transaction", "created_at"],
        key: "created_at",
        render: (createdAt: string) => (
            <Tag color="purple">{formatDateTime(createdAt)}</Tag>
        ),
    },
];

interface IProps {
    data: DisputeWithTransactionModel[];
}

const DisputeTransactionsTable: React.FC<IProps> = ({ data }) => {
    return (
        <Table
            title={() => <Text strong>Dispute Transactions</Text>}
            rowKey="id"
            dataSource={data}
            columns={columns}
            bordered
            pagination={false}
        />
    );
};

export default DisputeTransactionsTable;
