import {
    BASE_SHIPPING_CATEGORIES,
    BUY_CREATE_CUSTOM_ORDER_PAGE,
    BUY_ORDER_DETAIL_PAGE,
    BUY_ORDER_LIST_PAGE,
    BUY_PRODUCT_DETAIL_PAGE,
    BUY_PRODUCT_LIST_PAGE,
    SHIPMENT_ORDERS_PAGE,
    SHIPMENT_PRODUCTS_DETAIL_PAGE,
    SHIPMENT_PRODUCTS_PAGE,
    SHIPPING_CATEGORIES,
    SHIPPING_COUNTRY,
} from "@/consts/pageRoute";
import getPagePathname from "@/helpers/getPagePathname";
import {
    useGetBuyOrdersDetail,
    useGetBuyProduct,
} from "@/lib/core-react/hooks/private";
import { useGetShipmentProduct } from "@/lib/core-react/hooks/private/useShipping";
import { buyOrderCollectionAtom } from "@/lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import { buyProductCollectionAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";
import { shipmentOrderCollectionAtom } from "@/lib/core-react/store/shipmentOrder/shipmentOrderAtom";
import {
    baseShippingCategoryAtom,
    shipmentProductsAtom,
    shippingCategoryAtom,
    shippingCountry,
} from "@/lib/core-react/store/store";
import { useSetAtom } from "jotai";

const useRefetch = () => {
    const { getBuyProduct } = useGetBuyProduct();
    const { getBuyOrdersDetail } = useGetBuyOrdersDetail();
    const { getShipmentProduct } = useGetShipmentProduct();

    const setBuyProductCollection = useSetAtom(buyProductCollectionAtom);
    const setBuyOrderCollection = useSetAtom(buyOrderCollectionAtom);
    const setShipmentProductsData = useSetAtom(shipmentProductsAtom);
    const setShipmentOrderCollection = useSetAtom(shipmentOrderCollectionAtom);
    const baseShippingCategories = useSetAtom(baseShippingCategoryAtom);
    const shippingCategories = useSetAtom(shippingCategoryAtom);
    const shippingCountryData = useSetAtom(shippingCountry);
    const pathname = getPagePathname();

    const refetchDetailApi = async (id: string | number) => {
        if (id && pathname === `${BUY_PRODUCT_DETAIL_PAGE}/${id}`) {
            await getBuyProduct(Number(id));
        } else if (id && pathname === `${BUY_ORDER_DETAIL_PAGE}/${id}`) {
            await getBuyOrdersDetail(id.toString());
        } else if (
            id &&
            pathname === `${SHIPMENT_PRODUCTS_DETAIL_PAGE}/${id}`
        ) {
            await getShipmentProduct(Number(id));
        }
    };

    const refetchListApi = async () => {
        if (pathname === BUY_PRODUCT_LIST_PAGE) {
            setBuyProductCollection((prev) => ({ ...prev, refetch: true }));
        } else if (
            pathname === BUY_ORDER_LIST_PAGE ||
            pathname === BUY_CREATE_CUSTOM_ORDER_PAGE
        ) {
            setBuyOrderCollection((prev) => ({ ...prev, refetch: true }));
        } else if (pathname === SHIPMENT_PRODUCTS_PAGE) {
            setShipmentProductsData((prev) => ({ ...prev, refetch: true }));
        } else if (pathname === SHIPMENT_ORDERS_PAGE) {
            setShipmentOrderCollection((prev) => ({ ...prev, refetch: true }));
        } else if (pathname === BASE_SHIPPING_CATEGORIES) {
            baseShippingCategories((prev) => ({ ...prev, refetch: true }));
        } else if (pathname === SHIPPING_CATEGORIES) {
            shippingCategories((prev) => ({ ...prev, refetch: true }));
        } else if (pathname === SHIPPING_COUNTRY) {
            shippingCountryData((prev) => ({ ...prev, refetch: true }));
        }
    };

    return { refetchDetailApi, refetchListApi };
};

export default useRefetch;
