import { PageHeader } from "@ant-design/pro-layout";
import { Flex, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import CreateOrderSteps from "./components/CreateOrderSteps/CreateOrderSteps";
const BuyOrderCustomOrderCreate = () => {
    const navigate = useNavigate();

    return (
        <div>
            <PageHeader
                style={{ marginTop: "10px" }}
                ghost={false}
                title={
                    <Flex align="center" gap={12}>
                        <Typography.Title level={3}>
                            Create Custom Order
                        </Typography.Title>
                    </Flex>
                }
                onBack={() => navigate(-1)}
            >
                <CreateOrderSteps />
            </PageHeader>
        </div>
    );
};

export default BuyOrderCustomOrderCreate;
