import {
    Button,
    Form,
    InputNumber,
    Row,
    Col,
    Select,
    Typography,
    notification,
} from "antd";
import React, { useEffect, useState } from "react";
import {
    ExchangeListModel,
    StoreExchangeModel,
} from "@/models/exchangeListCollectionModel";
import { CommissionRateTypeEnums } from "@/enums/exchangeListCollectionEnums";
import {
    useGetExchange,
    useIndividualStoreExchange,
} from "@/lib/core-react/hooks/private/useExchange";
import { showError } from "@/helpers/showError";
import { IIndividualStoreExchangeUpdate } from "@/types/exchangeListCollection";

interface IProps {
    selectedExchange: ExchangeListModel;
    setIsShowUpdateModal: React.Dispatch<React.SetStateAction<boolean>>;
    selectedStore: StoreExchangeModel;
}

const { Text } = Typography;

const UpdateExchange = ({
    selectedExchange,
    setIsShowUpdateModal,
    selectedStore,
}: IProps) => {
    const [form] = Form.useForm();
    const { getExchange } = useGetExchange();
    const { update, isLoading } = useIndividualStoreExchange();
    const [totalValue, setTotalValue] = useState<number | string | null>();

    useEffect(() => {
        form.resetFields();
    }, [form, selectedExchange, selectedStore]);

    useEffect(() => {
        setTotalValue(selectedStore.getTotal());
    }, [selectedStore]);

    const calculateTotalValue = (_changedValues: any, allValues: any) => {
        const { fx_rate, commission_rate, commission_rate_type } = allValues;
        if (fx_rate && commission_rate && commission_rate_type) {
            let total = 0;
            if (commission_rate_type === CommissionRateTypeEnums.PERCENTAGE) {
                total = fx_rate + (fx_rate * commission_rate) / 100;
            } else {
                total = fx_rate + commission_rate;
            }
            setTotalValue(total.toFixed(2));
        }
    };

    const onFinish = async (values: any) => {
        const {
            fx_rate,
            commission_rate,
            commission_rate_type,
            minimum_order_amount,
        } = values;
        try {
            const data: IIndividualStoreExchangeUpdate = {
                id: selectedStore.getId(),
                fx_rate,
                commission_rate,
                commission_rate_type,
                options: {
                    minimum_order_amount,
                },
            };
            const res = await update(data);
            if (res) {
                notification["success"]({
                    message: res.message,
                });
                setIsShowUpdateModal(false);
                await getExchange({});
            }
        } catch (error) {
            showError(error, form);
        }
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    fx_rate: selectedStore.getFxRate(),
                    commission_rate: selectedStore.getCommissionRate(),
                    commission_rate_type: selectedStore.getCommissionRateType(),
                    minimum_order_amount: selectedStore.getMinimumOrderAmount(),
                }}
                onValuesChange={calculateTotalValue}
                style={{ margin: "25px 0px 0px 0px" }}
            >
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="fx_rate"
                            label="FX Rate"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input the FX rate!",
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="commission_rate"
                            label="Commission Rate"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please input the commission rate!",
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="commission_rate_type"
                            label="Commission Rate Type"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please select the commission rate type!",
                                },
                            ]}
                        >
                            <Select>
                                <Select.Option
                                    value={CommissionRateTypeEnums.PERCENTAGE}
                                >
                                    Percentage
                                </Select.Option>
                                <Select.Option
                                    value={CommissionRateTypeEnums.FIXED}
                                >
                                    Fixed
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name="minimum_order_amount"
                            label="Minimum Order Amount"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        "Please input the minimum order amount!",
                                },
                            ]}
                        >
                            <InputNumber min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>

                {totalValue !== null && (
                    <Row gutter={16} style={{ marginBottom: "16px" }}>
                        <Col span={24} style={{ textAlign: "center" }}>
                            <Text strong>Total Value: {totalValue}</Text>
                        </Col>
                    </Row>
                )}

                <Form.Item>
                    <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            style={{ minWidth: "100px" }}
                            onClick={() => setIsShowUpdateModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={isLoading}
                            style={{ marginLeft: "10px", minWidth: "100px" }}
                            type="primary"
                            htmlType="submit"
                        >
                            Update
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </>
    );
};

export default UpdateExchange;
