import React, { createContext, useEffect, useState } from "react";
import {
    FrontendApi,
    Configuration,
    Session,
    Identity,
    SessionAuthenticationMethod,
} from "@ory/client";
import { AxiosError } from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import {
    ServiceBaseUrl,
    ServiceNameEnum,
} from "@/lib/core/apiServices/servicBaseUrl";
import { getEnvironmentMode } from "@/utils/helper";

export interface IAuthInfo {
    authError: AxiosError | null;
    logoutUrl?: string;
    session: Session | undefined;
    isAuthLoading: boolean;
    isLoggedIn: boolean;
    getUserName: () => string | undefined;
    getUserEmail: () => string | undefined;
    getUserPhone: () => string | undefined;
    getUserPhoto: () => string | undefined;
    getUserGender: () => string | undefined;
    getUserDateOfBirth: () => string | undefined;
    getUserCountry: () => string | undefined;
    getNotificationSubscriberId: () => string | undefined;
    getIsImpersonateIdentity: () => boolean;
    getIsEmailVerified: (identity?: Identity) => boolean;
    getAuthenticationMethod: () =>
        | undefined
        | "link_recovery"
        | "code_recovery"
        | "password"
        | "code"
        | "totp"
        | "oidc"
        | "webauthn"
        | "lookup_secret"
        | "v0.6_legacy_session";
}
export interface IMetaDataPublicOry {
    is_impersonate_identity: boolean;
    notification_subscriber_id: string;
}
const localConfig = {
    basePath: ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.AUTH_SERVICE),
    baseOptions: {
        withCredentials: true,
    },
};

const ory = new FrontendApi(new Configuration(localConfig));

export const OryAuthContext = createContext<IAuthInfo>({} as IAuthInfo);

const OryAuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [session, setSession] = useState<Session | undefined>();

    const [logoutUrl, setLogoutUrl] = useState<string | undefined>();
    const [authError, setAuthError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const isModeProduction = getEnvironmentMode() !== "development";

    const isAuthLoading = !session && !authError;
    const isLoggedIn = !isAuthLoading && !!session;
    const getUserName = () => {
        return session?.identity?.traits.name;
    };
    const getUserEmail = () => {
        return session?.identity?.traits.email;
    };
    const getUserPhone = () => {
        return session?.identity?.traits.phone;
    };
    const getUserPhoto = () => {
        return session?.identity?.traits.avatar;
    };

    const getUserDateOfBirth = () => {
        return session?.identity?.traits.date_of_birth;
    };
    const getUserGender = () => {
        return session?.identity?.traits.gender;
    };

    const getUserCountry = () => {
        return session?.identity?.traits.country;
    };

    const getNotificationSubscriberId = () => {
        return session?.identity?.metadata_public
            ? (session?.identity?.metadata_public as IMetaDataPublicOry)
                  .notification_subscriber_id
            : undefined;
    };

    const getIsImpersonateIdentity = () => {
        return session?.identity?.metadata_public
            ? (session?.identity?.metadata_public as IMetaDataPublicOry)
                  .is_impersonate_identity
            : false;
    };

    const getAuthenticationMethod = () => {
        if (!session) {
            return undefined;
        }
        return (
            session.authentication_methods as SessionAuthenticationMethod[]
        )[0].method;
    };
    const getIsEmailVerified = (identity?: Identity) => {
        const email = identity?.traits.email;
        const emailStatus = identity?.verifiable_addresses?.find(
            (address) => address.value === email,
        );
        return !!emailStatus?.verified;
    };

    useEffect(() => {
        ory.toSession()
            .then(({ data }) => {
                setSession(data);
                ory.createBrowserLogoutFlow().then(({ data }) => {
                    setLogoutUrl(data.logout_url);
                });
            })
            .catch((error) => {
                setAuthError(error);
                setSession(undefined);
                if (isModeProduction) navigate("/auth/login");
            });
    }, [location]);

    const authInfo: IAuthInfo = {
        authError,
        logoutUrl,
        session,
        isAuthLoading,
        isLoggedIn,
        getUserName,
        getUserEmail,
        getUserPhone,
        getUserPhoto,
        getUserDateOfBirth,
        getUserGender,
        getUserCountry,
        getNotificationSubscriberId,
        getIsEmailVerified,
        getAuthenticationMethod,
        getIsImpersonateIdentity,
    };

    return (
        <OryAuthContext.Provider value={authInfo}>
            {children}
        </OryAuthContext.Provider>
    );
};

export default OryAuthProvider;
