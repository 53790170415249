import { notification, Steps } from "antd";
import { useState } from "react";
import CreateOrderStepContent from "./CreateOrderStepContent";
import ProductDetailsForm from "../DetailsForm/ProductDetailsForm";
import VariationDetailsForm from "../DetailsForm/VariationDetailsForm";
import ExchangeAndMeasurementDetailsForm from "../DetailsForm/ExchangeAndMeasurementDetailsForm";
import ShippingDetailsForm from "../DetailsForm/ShippingDetailsForm";
import { IBuyPlaceOrderCartShippingFormData } from "@/types/buyOrderPlace";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCreateCustomOrder } from "@/lib/core-react/hooks/private/usePurchase";
import { prepareError } from "@/helpers/prepareError";
import useRefetch from "@/hooks/useRefetch";
const { Step } = Steps;

interface IProperty {
    property_id?: string;
    property_name: string;
    property_original_name?: string;
    property_value_id?: string;
    property_value_name: string;
    property_value_title?: string;
}

export interface IVariant {
    sku_id?: string;
    sku_original_id?: string;
    image?: string | null;
    quantity: number;
    original_unit_price: number;
    properties: IProperty[];
}

interface IExchange {
    fx_rate: number;
}

interface IMeasurementUnit {
    value: number;
    unit: string;
}

interface IMeasurement {
    weight: IMeasurementUnit;
    length?: IMeasurementUnit;
    width?: IMeasurementUnit;
    height?: IMeasurementUnit;
}

export interface IShipping {
    provider: string;
    agent_category_price_read_id: number;
    shipping_category_id: string;
    shipping_address_id: number;
}

export interface ICustomOrderPayload {
    seller_id?: string;
    seller_name?: string;
    user_id: number;
    store_id: number;
    region_id: number;
    product_id?: string;
    product_title: string;
    product_image: string;
    product_link: string;
    variants: IVariant[];
    exchange?: IExchange;
    measurement?: IMeasurement;
    shipping: IShipping;
    addon_service_ids?: number[];
    instruction?: string;
}

export interface IPropertyValues {
    name: string;
    values: IProperty[];
}

const CreateOrderSteps = () => {
    const [current, setCurrent] = useState(0);
    const [formData, setFormData] = useState({});
    const navigate = useNavigate();
    const [shippingInfoData, setShippingInfoData] =
        useState<IBuyPlaceOrderCartShippingFormData>();

    const [errorMessage, setErrorMessage] = useState("");

    const { createCustomOrder, isLoading } = useCreateCustomOrder();
    const { refetchListApi } = useRefetch();
    const [searchParams] = useSearchParams();

    const regionId = searchParams.get("region_id");
    const userId = searchParams.get("userId");

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const handleFormSubmit = async (values: Partial<ICustomOrderPayload>) => {
        const newFormData = { ...formData, ...values };
        if (current === steps.length - 1) {
            setFormData(newFormData);

            const payload: ICustomOrderPayload = {
                // Product Details
                product_title: newFormData.product_title as string,
                product_link: newFormData.product_link as string,
                product_image: newFormData.product_image as string,
                store_id: newFormData.store_id as number,

                // Variations Details
                variants: newFormData.variants as IVariant[],

                // Shipping Details
                shipping: newFormData.shipping as IShipping,

                region_id: Number(regionId),
                user_id: Number(userId),
            };
            // Product Details
            if (newFormData.instruction) {
                payload.instruction = newFormData.instruction as string;
            }
            const addonServiceExist =
                Array.isArray(newFormData.addon_service_ids) &&
                newFormData.addon_service_ids.length > 0;
            if (addonServiceExist) {
                payload.addon_service_ids = newFormData.addon_service_ids;
            }

            // Measurement and Exchanges Details
            // Measurement Details
            if (
                newFormData.measurement &&
                Object.values(newFormData.measurement).length > 0
            ) {
                const processedMeasurements: IMeasurement = {} as IMeasurement;
                // Weight
                processedMeasurements.weight = newFormData?.measurement?.weight;

                // Height
                const heightValue = newFormData?.measurement?.height?.value;
                const heightUnit = newFormData?.measurement?.height?.unit;
                if (heightValue && heightUnit)
                    processedMeasurements.height = {
                        unit: heightUnit,
                        value: heightValue,
                    };

                // Width
                const widthValue = newFormData?.measurement?.width?.value;
                const widthUnit = newFormData?.measurement?.width?.unit;
                if (widthValue && widthUnit) {
                    processedMeasurements.width = {
                        unit: widthUnit,
                        value: widthValue,
                    };
                }
                // Length
                const lengthValue = newFormData?.measurement?.length?.value;
                const lengthUnit = newFormData?.measurement?.length?.unit;
                if (lengthValue && lengthUnit) {
                    processedMeasurements.length = {
                        unit: lengthUnit,
                        value: lengthValue,
                    };
                }

                payload.measurement = processedMeasurements;
            }

            // Exchange Details
            if (
                newFormData.exchange &&
                Object.values(newFormData.exchange).length > 0
            ) {
                payload.exchange = newFormData.exchange as IExchange;
            }

            // Preprocess property

            // Remove set empty string image to null
            const updatedVariations = payload.variants.map((item) => {
                return {
                    ...item,
                    image: item.image ? item.image : null,
                };
            });
            payload.variants = updatedVariations;

            try {
                const res = await createCustomOrder(payload);
                notification.success({
                    message: res.message,
                });
                setErrorMessage("");
                await refetchListApi();
                navigate("/purchase/buy-orders");
            } catch (error) {
                const errorData = prepareError(error);
                notification.error({
                    message: errorData.message,
                });
                setErrorMessage(errorData.message);
            }
        } else {
            setFormData(newFormData);
            next();
        }
    };

    const steps = [
        {
            title: "Product Details",
            content: (
                <ProductDetailsForm
                    formSubmit={handleFormSubmit}
                    initialData={formData}
                    current={current}
                    prev={prev}
                    isLoading={isLoading}
                />
            ),
        },
        {
            title: "Variations Details",
            content: (
                <VariationDetailsForm
                    formSubmit={handleFormSubmit}
                    initialData={formData}
                    current={current}
                    prev={prev}
                    isLoading={isLoading}
                />
            ),
        },
        {
            title: "Measurement Details",
            content: (
                <ExchangeAndMeasurementDetailsForm
                    formSubmit={handleFormSubmit}
                    initialData={formData}
                    current={current}
                    prev={prev}
                    isLoading={isLoading}
                />
            ),
        },
        {
            title: "Shipping Details",
            content: (
                <ShippingDetailsForm
                    formSubmit={handleFormSubmit}
                    initialData={formData}
                    current={current}
                    prev={prev}
                    setShippingInfoData={setShippingInfoData}
                    shippingInfoData={shippingInfoData}
                    isLoading={isLoading}
                />
            ),
        },
    ];
    return (
        <div>
            <Steps
                onChange={(current) => setCurrent(current)}
                current={current}
            >
                {steps.map((item) => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>
            <CreateOrderStepContent
                errorMessage={errorMessage}
                title={steps[current].title}
            >
                {steps[current].content}
            </CreateOrderStepContent>
        </div>
    );
};

export default CreateOrderSteps;
