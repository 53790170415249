import { ITranslationEntry } from "../lang";

export const AS: ITranslationEntry = {
    translations: {
        notifications: "জাননীসমূহ",
        markAllAsRead: "সকলো পঢ়া হিচাপে চিহ্নিত কৰক",
        poweredBy: "দ্বাৰা সমৰ্থিত",
        settings: "ছেটিংছ",
        noNewNotification: "ইয়াত এতিয়াও নতুন একো দেখা নাই",
    },
    lang: "as",
};
