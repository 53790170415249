import {
    FileAddOutlined,
    LinkOutlined,
    PlusOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Flex,
    Modal,
    Row,
    Table,
    Drawer,
    Card,
    Spin,
    Skeleton,
    Empty,
} from "antd";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom, useAtomValue } from "jotai";
import {
    inventoryCategoryTreeAtom,
    inventoryProductSearchResultAtom,
} from "@/lib/core-react/store/store";
import { PaginationModel } from "@/models/pagination";
import { useState, useEffect } from "react";
import { ApiHelperModel } from "@/models/apiHelper";
import CommonError from "@/components/Error/CommonError";
import {
    useDetachCategory,
    useGetInventoryCategoriesTree,
    useGetProductById,
    useGetSearchProducts,
    useSyncCategoryMapping,
} from "@/lib/core-react/hooks/private/useProduct";
import { InventoryCategoryResponseModel } from "@/models/inventoryCollectionModel";
import CategoryMapping from "./components/CategoryMapping";
import FiltersComponent from "@/components/FiltersComponent";
import { IFilterType } from "@/types/filters";
import AttachCategory from "./components/AttachCategory";
import { StatusTag } from "@/components";
import {
    generateMoveOnProductURL,
    handleGetVendorProductLink,
    IInventoryCategoryTreeNode,
    transformToInventoryCategoryTreeData,
} from "./utils";
import { ActionHandler } from "./actions-handler";
import { ProductSearchResultModel } from "@/models/ProductSearchCollectionModel";
import Meta from "antd/es/card/Meta";
import { Link } from "react-router-dom";

const { Column } = Table;

const InventoryCategory = () => {
    const { getCategoriesTree } = useGetInventoryCategoriesTree();
    const { detachCategory } = useDetachCategory();
    const { getProducts, isLoading: isGetSearchProductLoading } =
        useGetSearchProducts();
    const { syncCategoryMapping, isLoading: isSyncing } =
        useSyncCategoryMapping();

    const [isShowCreateModal, setIsShowCategoryMappingModal] =
        useState<boolean>(false);
    const [isShowAttachModal, setIsShowAttachModal] = useState<boolean>(false);
    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
    const [isBulkAttachAction, setIsBulkAttachAction] =
        useState<boolean>(false);
    const [bulkOriginatorId, setBulkOriginatorId] = useState<string | null>(
        null,
    );
    const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

    const [selectedCategory, setSelectedCategory] = useState<
        IInventoryCategoryTreeNode | undefined
    >(undefined);

    const [
        {
            data: inventoryCategoryData,
            isLoading: isInventoryCategoryLoading,
            refetch: isInventoryCategoryRefetch,
            error: inventoryCategoryError,
            unAuthorized: inventoryCategoryUnAuthorized,
            code: inventoryCategoryCode,
        },
    ] = useAtom(inventoryCategoryTreeAtom);

    const productSearchResultModel = useAtomValue(
        inventoryProductSearchResultAtom,
    );

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
    } = useDataFilters();

    const attachedFilter = filters?.is_attached;

    const { getProductDetailById } = useGetProductById();

    const InventoryCategoryTree =
        inventoryCategoryData &&
        new InventoryCategoryResponseModel(inventoryCategoryData);
    const filterData =
        InventoryCategoryTree && InventoryCategoryTree.getFilters();

    const InventoryProductSearchData =
        productSearchResultModel &&
        new ProductSearchResultModel(productSearchResultModel);
    // API Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            isInventoryCategoryRefetch
        ) {
            ApiHelperModel.makeGetRequest(filters, getCategoriesTree);
        }
    }, [isFirstCall, filters, isFetched]);

    // if refetch is true then  we need to refetch the data
    useEffect(() => {
        if (filters) {
            ApiHelperModel.makeGetRequest(filters, getCategoriesTree);
        }
    }, [filters]);

    // if shouldRefetch is true then  we need to refetch the data
    useEffect(() => {
        if (shouldRefetch) {
            ApiHelperModel.makeGetRequest(filters, getCategoriesTree);
            setShouldRefetch(false);
        }
    }, [shouldRefetch]);

    // Getting all available filters
    useEffect(() => {
        if (!isFetched && filterData) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, filterData]);

    // call getProducts API
    useEffect(() => {
        if (selectedCategory && isDrawerVisible) {
            getProducts(
                // TODO: Should be dynamic. Have to consult with backend team. will be added later. (region and locale)
                { region: "BD", locale: "en" },
                {
                    category_id: selectedCategory.id,
                    provider: "inventory",
                },
            );
        }
    }, [selectedCategory, isDrawerVisible]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getCategoriesTree,
        );
    };

    // Filter Handler
    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getCategoriesTree);
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        InventoryCategoryTree,
        handlePaginationChange,
    );

    if (inventoryCategoryError) {
        return (
            <CommonError
                unAuthorized={inventoryCategoryUnAuthorized}
                message={inventoryCategoryError}
                code={inventoryCategoryCode}
            />
        );
    }

    const dataSource =
        InventoryCategoryTree &&
        transformToInventoryCategoryTreeData(InventoryCategoryTree.getData());

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Global Categories"
                    style={{ marginTop: "10px" }}
                    extra={[
                        <Button
                            style={{ padding: 0 }}
                            type="link"
                            htmlType="button"
                            icon={<SyncOutlined />}
                            key="sync"
                            loading={isSyncing}
                            onClick={() => {
                                syncCategoryMapping();
                            }}
                        >
                            Sync
                        </Button>,

                        <Button
                            icon={<PlusOutlined />}
                            type="primary"
                            key="1"
                            onClick={() => setIsShowCategoryMappingModal(true)}
                        >
                            Create Category Mapping
                        </Button>,
                    ]}
                >
                    {filters && Object.keys(filters).length > 0 && (
                        <Row>
                            <Col span={24}>
                                <FiltersComponent
                                    handleProductFilter={handleFilter}
                                    handleFilterChange={handleFilterChange}
                                    handelFilterClear={handelFilterClear}
                                    isFetched={isFetched}
                                    filters={filters}
                                    filtersData={filterData}
                                />
                            </Col>
                        </Row>
                    )}
                    <Flex
                        vertical
                        gap="middle"
                        style={{
                            marginTop: 32,
                        }}
                    >
                        <div>
                            <Flex gap={10}>
                                <Button
                                    onClick={() => {
                                        if (!dataSource) return;
                                        ActionHandler.handleBulkAttach({
                                            dataSource,
                                            selectedRows,
                                            setIsBulkAttachAction,
                                            setShouldRefetch,
                                        });
                                    }}
                                    disabled={selectedRows.length === 0}
                                    hidden={attachedFilter === "attached"}
                                >
                                    <FileAddOutlined /> Bulk Attach
                                </Button>
                                <Button
                                    onClick={() => {
                                        if (!dataSource) return;
                                        ActionHandler.handleBulkDetach({
                                            selectedRows,
                                            dataSource,
                                            setBulkOriginatorId,
                                            detachCategory,
                                            setSelectedRows,
                                            bulkOriginatorId,
                                            setShouldRefetch,
                                        });
                                    }}
                                    disabled={selectedRows.length === 0}
                                    hidden={attachedFilter === "unattached"}
                                >
                                    <FileAddOutlined /> Bulk Detach
                                </Button>
                            </Flex>
                        </div>
                    </Flex>
                    <Table
                        style={{ marginTop: "15px" }}
                        bordered
                        loading={isInventoryCategoryLoading}
                        rowKey={(record) => record.id}
                        pagination={paginationConfig}
                        dataSource={dataSource}
                        scroll={{ x: 1000 }}
                        sticky
                        rowSelection={{
                            type: "checkbox",
                            columnWidth: 50,
                            selectedRowKeys: selectedRows,
                            onChange: (selectedRowKeys) => {
                                setSelectedRows(selectedRowKeys);
                            },
                        }}
                    >
                        <Column
                            title="Name"
                            dataIndex="name"
                            key="name"
                            width={200}
                            render={(_, data: IInventoryCategoryTreeNode) => {
                                return (
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            setIsDrawerVisible(true);
                                            setSelectedCategory(data);
                                        }}
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            marginBottom: "-4px",
                                        }}
                                    >
                                        {data.name}
                                    </Button>
                                );
                            }}
                        />

                        <Column
                            title="Base Shipping Category"
                            dataIndex="attachedBaseShippingCategory"
                            key="attachedBaseShippingCategory"
                            width={200}
                            render={(attachedBaseShippingCategory) => {
                                if (attachedBaseShippingCategory) {
                                    return (
                                        <p>
                                            {attachedBaseShippingCategory.name}
                                        </p>
                                    );
                                } else {
                                    return (
                                        <StatusTag
                                            slug="not-attached"
                                            color="red"
                                            text="Not Attached"
                                        />
                                    );
                                }
                            }}
                        />
                        <Column
                            title="Action"
                            key="action"
                            fixed="right"
                            align="center"
                            width={100}
                            render={(_, data: IInventoryCategoryTreeNode) => {
                                const isAttached =
                                    data.attachedBaseShippingCategory;

                                return (
                                    <Button
                                        type={
                                            isAttached ? "default" : "primary"
                                        }
                                        danger={isAttached ? true : false}
                                        icon={<FileAddOutlined />}
                                        onClick={() => {
                                            if (isAttached) {
                                                setSelectedCategory(data);
                                                ActionHandler.handleDetach({
                                                    actionType: "single",
                                                    selectedCategory: data,
                                                    detachCategory,
                                                    setSelectedRows,
                                                    selectedRows,
                                                    bulkOriginatorId: "",
                                                    setShouldRefetch,
                                                });
                                            } else {
                                                setSelectedCategory(data);
                                                setIsShowAttachModal(true);
                                            }
                                        }}
                                    >
                                        {isAttached ? "Detach" : "Attach"}
                                    </Button>
                                );
                            }}
                        />
                    </Table>
                </PageHeader>
            </div>
            <Modal
                title={`Create Category Mapping`}
                open={isShowCreateModal}
                footer={false}
                onCancel={() => setIsShowCategoryMappingModal(false)}
                width={1000}
            >
                <CategoryMapping
                    setIsShowCategoryMappingModal={
                        setIsShowCategoryMappingModal
                    }
                />
            </Modal>
            {selectedCategory && isShowAttachModal && (
                <Modal
                    title={"Attach Category"}
                    open={isShowAttachModal}
                    footer={false}
                    onCancel={() => setIsShowAttachModal(false)}
                    width={600}
                    destroyOnClose
                >
                    <AttachCategory
                        setIsShowCategoryMappingModal={setIsShowAttachModal}
                        selectedCategory={selectedCategory}
                        setShouldRefetch={setShouldRefetch}
                    />
                </Modal>
            )}
            {isBulkAttachAction && (
                <Modal
                    title={"Bulk Action"}
                    open={isBulkAttachAction}
                    footer={false}
                    onCancel={() => setIsBulkAttachAction(false)}
                    width={600}
                    destroyOnClose
                >
                    <AttachCategory
                        setIsShowCategoryMappingModal={setIsBulkAttachAction}
                        selectedRows={selectedRows}
                        isBulk={isBulkAttachAction}
                        setSelectedRows={setSelectedRows}
                        setShouldRefetch={setShouldRefetch}
                    />
                </Modal>
            )}
            <Drawer
                size="large"
                title="Suggested Products"
                open={isDrawerVisible}
                onClose={() => setIsDrawerVisible(false)}
            >
                {isGetSearchProductLoading ? (
                    <>
                        <Spin
                            spinning={isGetSearchProductLoading}
                            tip="Loading Suggested Products..."
                            size="small"
                        >
                            Loading
                        </Spin>
                        <Skeleton title />
                    </>
                ) : (
                    <Row gutter={[16, 16]}>
                        {InventoryProductSearchData &&
                        InventoryProductSearchData.getData().length > 0 ? (
                            InventoryProductSearchData.getData().map(
                                (product) => (
                                    <Col key={product.id} lg={8}>
                                        <Card
                                            style={{ width: "100%" }}
                                            cover={
                                                <img
                                                    alt="example"
                                                    src={product.image}
                                                    style={{ height: "200px" }}
                                                />
                                            }
                                            actions={[
                                                <Link
                                                    to={generateMoveOnProductURL(
                                                        product.slug,
                                                        product.id,
                                                    )}
                                                    target="_blank"
                                                >
                                                    <LinkOutlined key="MoveOn" />{" "}
                                                    MoveOn
                                                </Link>,
                                                <Button
                                                    type="link"
                                                    onClick={() => {
                                                        const moveOnUrl =
                                                            generateMoveOnProductURL(
                                                                product.slug,
                                                                product.id,
                                                            );
                                                        handleGetVendorProductLink(
                                                            {
                                                                submittedUrl:
                                                                    moveOnUrl,
                                                                getProductDetailById,
                                                            },
                                                        );
                                                    }}
                                                >
                                                    <LinkOutlined key="Store" />{" "}
                                                    Store
                                                </Button>,
                                            ]}
                                        >
                                            <Meta title={product.getTitle()} />
                                        </Card>
                                    </Col>
                                ),
                            )
                        ) : (
                            <Col span={24}>
                                <Empty />
                            </Col>
                        )}
                    </Row>
                )}
            </Drawer>
        </>
    );
};

export default InventoryCategory;
