import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Button,
    Col,
    Dropdown,
    MenuProps,
    Modal,
    notification,
    Row,
    Table,
    Tag,
    Typography,
} from "antd";
import { useAtom } from "jotai";
import {
    ControlOutlined,
    DeleteFilled,
    DownOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    FileOutlined,
    HddFilled,
    PlusOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
    WalletOutlined,
} from "@ant-design/icons";

import FiltersComponent from "@/components/FiltersComponent";
import CreateCustomerUser from "./components/CreateCustomerUser";
import UpdateCustomerUser from "./components/UpdateCustomerUser";

import {
    useGetCustomerUser,
    useOrderHandlerAssignSpecificCustomer,
    useOrderHandlerUnassignSpecificCustomer,
} from "@/lib/core-react/hooks/private/useCustomerUser";
import { customerUserCollectionAtom } from "@/lib/core-react/store/store";

import useDataFilters from "@/hooks/useDataFilters";

import {
    CustomerUserCollectionModel,
    CustomerUserModel,
} from "@/models/customerUserCollectionModel";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { IFilterType } from "@/types/filters";
import AddExchangeGroupCustomer from "./components/AddExchangeGroupCustomer";
import { v4 } from "uuid";
import { useNavigate } from "react-router-dom";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import AddUserInvoicePaymentGroup from "./components/AddUserInvoicePaymentGroup";
import { ExtendedMenuItemType } from "@/types";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_USER_PERMISSION_ENUM } from "@/consts/permission-enum/admin-user-enum";
import { ADMIN_STORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-store-enum";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import StatusTag from "@/components/StatusTag";
import { ADMIN_WALLET_PERMISSION_ENUM } from "../../../consts/permission-enum/wallet-enum";
import { FiLogIn } from "react-icons/fi";
import ImpersonateForm from "../../../components/ImpersonateSessionCreate/impersonate";
import { UserType } from "../../../types/userCollection";
import ImpersonateCreateModalTitle from "../../../components/ImpersonateFormModalTitle";
import CommonError from "@/components/Error/CommonError";
import { ADMIN_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-core-enum";
import OrderHandlerAssign from "@/pages/BuyAndShip/BuyOrders/components/orderHandlerAssignModal";
import { showError } from "@/helpers/showError";
import {
    IOrderHandlerAssignSpecificCustomer,
    IOrderHandlerUnassignSpecificCustomer,
} from "@/types/customerUserCollection";
import Paragraph from "antd/es/typography/Paragraph";
import PasswordResetCustomerUser from "@/pages/CustomerManage/User/components/PasswordResetCustomerUser.tsx";
import CustomerDetailsViews from "./components/CustomerDetailsViews";

type ModalForType =
    | "create"
    | "update"
    | "password-reset"
    | "agent-wallet"
    | "attach-user"
    | "add-to-group"
    | "add-to-invoice-payment-group"
    | "create-impersonate"
    | "add"
    | "edit"
    | "customer_details";

const { confirm } = Modal;

const CustomerUser = () => {
    const { getCustomerUser } = useGetCustomerUser();
    const { orderHandlerAssignSpecificCustomer, isLoading: AssignLoading } =
        useOrderHandlerAssignSpecificCustomer();
    const { orderHandlerUnassignSpecificCustomer } =
        useOrderHandlerUnassignSpecificCustomer();
    const [
        { data: customersData, isLoading, refetch, code, error, unAuthorized },
    ] = useAtom(customerUserCollectionAtom);
    const { Text } = Typography;

    const [selectedCustomerUser, setSelectedCustomerUser] = useState<
        CustomerUserModel | undefined
    >(undefined);
    const [modalFor, setModalFor] = useState<ModalForType | undefined>(
        undefined,
    );
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const handleOrderHandlerAction = (action: "add" | "edit") => {
        setModalFor(action);
        setIsShowModal(true);
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const { isMobile } = useWindowWidth();
    const navigate = useNavigate();

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    const CustomerUserData =
        customersData && new CustomerUserCollectionModel(customersData);
    // Api Call
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getCustomerUser);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    const filterData = CustomerUserData?.filters;
    const paginationData = CustomerUserData?.pagination;

    useEffect(() => {
        if (!isFetched && CustomerUserData?.filters) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, CustomerUserData?.filters]);

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getCustomerUser,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        CustomerUserData,
        handlePaginationChange,
    );

    // Filter Handler
    const handleProductFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getCustomerUser);
    };

    const onSubmitHandlerAssign = async (values: {
        order_handler_id: string;
    }) => {
        const payload: IOrderHandlerAssignSpecificCustomer = {
            customer_ids:
                selectedRowKeys.length > 0
                    ? selectedRowKeys.map((key) => Number(key))
                    : [Number(selectedCustomerUser?.getId())],
            order_handler_id: Number(values.order_handler_id),
        };

        try {
            await orderHandlerAssignSpecificCustomer(payload);
            setIsShowModal(false);
            setSelectedRowKeys([]);
            notification["success"]({
                message: "Update Assigned Order Handler",
            });
        } catch (error) {
            showError(error);
        }
    };

    const onSubmitHandlerUnAssign = async () => {
        const payload: IOrderHandlerUnassignSpecificCustomer = {
            customer_ids:
                selectedRowKeys.length > 0
                    ? selectedRowKeys.map((key) => Number(key))
                    : [Number(selectedCustomerUser?.getId())],
        };

        try {
            await orderHandlerUnassignSpecificCustomer(payload);
            setIsShowModal(false);
            setSelectedRowKeys([]);
            notification["success"]({
                message: "Remove Order Handler",
            });
        } catch (error) {
            showError(error);
        }
    };

    const showUnassignConfirmation = () => {
        confirm({
            title: "Are you sure you want to unassign the order handler?",
            icon: <ExclamationCircleOutlined />,
            content: "This action cannot be undone.",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: onSubmitHandlerUnAssign,
            onCancel() {},
        });
    };

    const AssignBulkOrderHandler = () => {
        handleOrderHandlerAction("add");
        setIsShowModal(true);
    };

    const handleNameClick = (customer: CustomerUserModel) => {
        setSelectedCustomerUser(customer);
        setModalFor("customer_details");
        setIsShowModal(true);
    };

    const handleMenuClick: MenuProps["onClick"] = (e) => {
        if (e.key === "update") {
            setIsShowModal(true);
            setModalFor("update");
        } else if (e.key === "password-reset") {
            setIsShowModal(true);
            setModalFor("password-reset");
        } else if (e.key === "add-to-group") {
            setIsShowModal(true);
            setModalFor("add-to-group");
        } else if (e.key === "add-to-invoice-payment-group") {
            setIsShowModal(true);
            setModalFor("add-to-invoice-payment-group");
        } else if (e.key === "user-wallet") {
            navigate(`/customer/user/${selectedCustomerUser?.getId()}/wallet`);
        } else if (e.key === "create_impersonate") {
            setModalFor("create-impersonate");
            setIsShowModal(true);
        }
    };
    const getModalTitle = (modelFor: ModalForType) => {
        switch (modelFor) {
            case "create":
                return `Create`;
            case "update":
                return `Update ${selectedCustomerUser?.getName()}`;
            case "password-reset":
                return `Password Reset for ${selectedCustomerUser?.getName()}`;
            case "add-to-group":
                return `Add Exchange Group Customer`;
            case "add-to-invoice-payment-group":
                return `Add Invoice payment Rule ( ${selectedCustomerUser?.getName()} )`;
            case "customer_details":
                return `Customer ( ${selectedCustomerUser?.getName()} )`;
            case "create-impersonate":
                return (
                    <ImpersonateCreateModalTitle
                        name={selectedCustomerUser?.getName() || ""}
                        email={selectedCustomerUser?.getEmail() || ""}
                        title=""
                    />
                );
            default:
                return "";
        }
    };

    const getModalContent = () => {
        if (modalFor === "create") {
            return <CreateCustomerUser setIsShowCreateModal={setIsShowModal} />;
        } else if (modalFor === "update" && selectedCustomerUser) {
            return (
                <UpdateCustomerUser
                    selectedCustomerUser={selectedCustomerUser}
                    setIsShowUpdateModal={setIsShowModal}
                />
            );
        } else if (modalFor === "password-reset" && selectedCustomerUser) {
            return (
                <PasswordResetCustomerUser
                    selectedCustomerUser={selectedCustomerUser}
                    setIsShowUpdateModal={setIsShowModal}
                />
            );
        } else if (modalFor === "add-to-group" && selectedCustomerUser) {
            return (
                <AddExchangeGroupCustomer
                    setIsShowAddCustomerModal={setIsShowModal}
                    selectedCustomerUser={selectedCustomerUser}
                />
            );
        } else if (
            modalFor === "add-to-invoice-payment-group" &&
            selectedCustomerUser
        ) {
            return (
                <AddUserInvoicePaymentGroup
                    setIsShowModal={setIsShowModal}
                    selectedCustomerUser={selectedCustomerUser}
                />
            );
        } else if (modalFor === "create-impersonate" && selectedCustomerUser) {
            return (
                <ImpersonateForm
                    userType={UserType.Customer}
                    selectedUser={{ id: selectedCustomerUser.getId() }}
                    setIsModalVisible={setIsShowModal}
                />
            );
        } else if (modalFor === "customer_details" && selectedCustomerUser) {
            return (
                <CustomerDetailsViews
                    name="Name"
                    selectedUser={selectedCustomerUser}
                />
            );
        } else {
            return <div>Please select a proper action </div>;
        }
    };

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            align: "center" as const,
            width: 70,
            render: (_: string, __: CustomerUserModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Customer Details",
            dataIndex: "name",
            hidden: !isMobile,
            key: "name",
            render: (_text: string, record: CustomerUserModel) => {
                return (
                    <div>
                        <div>
                            Name:{" "}
                            <Text
                                copyable
                                onClick={() => handleNameClick(record)}
                            >
                                {record.getName()}
                            </Text>
                        </div>
                        <div>
                            Email: <Text>{record.getEmail()}</Text>
                        </div>
                        <div>
                            Shipping Mark:{" "}
                            <Tag color="green">{record.getShippingMark()}</Tag>
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Customer Details",
            dataIndex: "name",
            hidden: isMobile,
            key: "name",
            render: (_text: string, record: CustomerUserModel) => {
                return (
                    <>
                        <CustomerDetailsViews
                            name="Name"
                            selectedUser={record}
                        />
                        {record.getEmail() && (
                            <Paragraph>
                                <Text>Email : </Text>
                                <Text strong copyable>
                                    {record.getEmail()}
                                </Text>
                            </Paragraph>
                        )}

                        {record.getPhone() && (
                            <Paragraph>
                                <Text>Phone : </Text>
                                <Text strong copyable>
                                    {record.getPhone()}
                                </Text>
                            </Paragraph>
                        )}
                    </>
                );
            },
        },
        {
            title: "Order Handler",
            dataIndex: "order_handler",
            key: "order_handler",
            render: (order_handler, record: CustomerUserModel) => {
                if (!order_handler) {
                    return (
                        <>
                            {checkActionPermission(
                                ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
                                <Button
                                    type="dashed"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        handleOrderHandlerAction("add");
                                        setSelectedCustomerUser(record);
                                        setSelectedRowKeys([]);
                                    }}
                                >
                                    Add Order Handler
                                </Button>,
                                null,
                            )}
                        </>
                    );
                }
                return (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div>
                                <Paragraph>
                                    <Text>Name: </Text>
                                    <Text strong copyable>
                                        {order_handler?.name}
                                    </Text>
                                </Paragraph>
                                <Paragraph>
                                    <Text>Email: </Text>
                                    <Text strong copyable>
                                        {order_handler?.email}
                                    </Text>
                                </Paragraph>
                                {order_handler?.phone && (
                                    <Paragraph>
                                        <Text>Phone: </Text>
                                        <Text strong copyable>
                                            {order_handler?.phone}
                                        </Text>
                                    </Paragraph>
                                )}
                            </div>
                            {checkActionPermission(
                                ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
                                <Button
                                    type="link"
                                    onClick={() => {
                                        handleOrderHandlerAction("edit");
                                        setSelectedCustomerUser(record);
                                        setSelectedRowKeys([]);
                                    }}
                                >
                                    <EditOutlined />
                                </Button>,
                                null,
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: "Drop User",
            dataIndex: "is_drop_user",
            hidden: isMobile,
            key: "is_drop_user",
            render: (is_drop_user: boolean) => {
                const status = is_drop_user === true ? "Active" : "Inactive";
                return (
                    <>
                        <StatusTag
                            text={status.toLowerCase()}
                            slug={status.toLowerCase()}
                        />
                    </>
                );
            },
        },
        {
            title: "Active",
            dataIndex: "is_active",
            hidden: isMobile,
            key: "is_active",
            render: (is_active: number) => {
                const status = is_active === 1 ? "Active" : "Inactive";
                return (
                    <>
                        <StatusTag
                            text={status.toLowerCase()}
                            slug={status.toLowerCase()}
                        />
                    </>
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "actions",
            fixed: isMobile ? "right" : undefined,
            hidden: !checkActionPermission(
                [
                    ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_MANAGE,
                    ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_MANAGE,
                    ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
                    ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_MANAGE,
                    ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
                ],
                "checkPermission",
                null,
            ),
            width: 100,
            render: (_: string, record: CustomerUserModel) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_MANAGE,
                        icon: <UsergroupAddOutlined />,
                        label: "Update",
                        key: "update",
                    },
                    {
                        permission:
                            ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_MANAGE,
                        icon: <UsergroupAddOutlined />,
                        label: "Password Reset",
                        key: "password-reset",
                    },
                    {
                        permission:
                            ADMIN_STORE_PERMISSION_ENUM.ADMIN_EXCHANGE_GROUP_MANAGE,
                        icon: <TeamOutlined />,
                        label: "Add to exchange group",
                        key: "add-to-group",
                    },
                    {
                        permission:
                            ADMIN_WALLET_PERMISSION_ENUM.ADMIN_WALLET_VIEW,
                        icon: <WalletOutlined />,
                        label: "User Wallet",
                        key: "user-wallet",
                    },
                    {
                        permission:
                            ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_INVOICE_PAYMENT_RULE_GROUP_MANAGE,
                        icon: <FileOutlined />,
                        label: "Add to invoice payment group",
                        key: "add-to-invoice-payment-group",
                    },
                    {
                        icon: <FiLogIn />,
                        label: "Create Impersonate",
                        key: "create_impersonate",
                        permission:
                            ADMIN_USER_PERMISSION_ENUM.ADMIN_IMPERSONATE_SESSION_CREATE,
                    },
                    ...(record.order_handler
                        ? [
                              {
                                  icon: <DeleteFilled />,
                                  label: "Remove Order Handler",
                                  key: "remove-order-handler",
                                  permission:
                                      ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
                                  onClick: () => showUnassignConfirmation(),
                              },
                          ]
                        : []),
                ];

                const menuProps = {
                    items: items.filter((x) =>
                        checkActionPermission(x.permission, x, null),
                    ),
                    onClick: handleMenuClick,
                };

                return (
                    <Dropdown
                        menu={menuProps}
                        onOpenChange={() => setSelectedCustomerUser(record)}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    const bulkItems: ExtendedMenuItemType[] = [
        {
            permission: ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
            key: "add",
            icon: <ControlOutlined />,
            onClick: () => AssignBulkOrderHandler(),
            label: "Order Handler Assign",
        },
        {
            permission: ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
            key: "remove-order-assign",
            icon: <ControlOutlined />,
            onClick: () => showUnassignConfirmation(),
            label: "Remove Handler Assign",
        },
    ];

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={"Customers"}
                        extra={[
                            checkActionPermission(
                                ADMIN_USER_PERMISSION_ENUM.ADMIN_CUSTOMER_USER_MANAGE,
                                <Button
                                    key={v4()}
                                    onClick={() => {
                                        setIsShowModal(true);
                                        setModalFor("create");
                                    }}
                                    icon={<PlusOutlined />}
                                    type="primary"
                                >
                                    Create Customer
                                </Button>,
                                null,
                            ),
                        ]}
                        onBack={() => window.history.back()}
                    >
                        {filters && Object.keys(filters).length > 0 && (
                            <Row>
                                <Col style={{ marginBottom: "10px" }} span={24}>
                                    <FiltersComponent
                                        handleProductFilter={
                                            handleProductFilter
                                        }
                                        handleFilterChange={handleFilterChange}
                                        handelFilterClear={handelFilterClear}
                                        isFetched={isFetched}
                                        filters={filters}
                                        filtersData={filterData}
                                        isFromProductReceived={true}
                                    />
                                </Col>
                            </Row>
                        )}

                        <div style={{ margin: "20px 0", textAlign: "right" }}>
                            <Dropdown
                                menu={{
                                    items: bulkItems.filter((x) =>
                                        checkActionPermission(
                                            x.permission,
                                            x,
                                            null,
                                        ),
                                    ),
                                }}
                                disabled={selectedRowKeys.length === 0}
                            >
                                <Button icon={<HddFilled />}>
                                    Bulk Actions
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>

                        <Table
                            //@ts-ignore
                            columns={columns}
                            setSelectedRowKeys={setSelectedRowKeys}
                            selectedRowKeys={selectedRowKeys}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: (keys) => {
                                    setSelectedRowKeys(keys);
                                },
                            }}
                            dataSource={CustomerUserData?.data}
                            loading={isLoading}
                            size="middle"
                            pagination={paginationConfig}
                            rowKey="id"
                            scroll={{ x: 1020 }}
                        />
                    </PageHeader>
                </Col>
            </Row>
            {modalFor && isShowModal && (
                <Modal
                    title={getModalTitle(modalFor)}
                    open={isShowModal}
                    footer={false}
                    onCancel={() => setIsShowModal(false)}
                    destroyOnClose={true}
                    width={modalFor === "customer_details" ? "60%" : undefined}
                >
                    {getModalContent()}
                </Modal>
            )}

            {(modalFor === "add" || modalFor === "edit") && isShowModal && (
                <OrderHandlerAssign
                    isModalOpen={isShowModal}
                    handleCloseModal={() => setIsShowModal(false)}
                    handleSelectChange={onSubmitHandlerAssign}
                    AssignLoading={AssignLoading}
                    orderHandlerId={
                        modalFor === "edit"
                            ? selectedCustomerUser?.getOrderHandler()?.id
                            : undefined
                    }
                />
            )}
        </>
    );
};

export default CustomerUser;
