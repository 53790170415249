import { ITranslationEntry } from "../lang";

export const MR: ITranslationEntry = {
    translations: {
        notifications: "अधिसूचना",
        markAllAsRead: "सर्व वाचलेले म्हणून चिन्हांकित करा",
        poweredBy: "द्वारे समर्थित",
        settings: "सेटिंग्ज",
        noNewNotification: "इथे अजून काही नवीन पाहण्यासारखे नाही",
    },
    lang: "mr",
};
