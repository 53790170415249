import { useState, useEffect } from "react";

export const useWindowWidth = () => {
    // Initialize state with the current window width
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    useEffect(() => {
        const handleResize = () => {
            const currentWidth = window.innerWidth;
            setWidth(currentWidth);
            setIsMobile(currentWidth < 1440); //? why   less than 1500 is mobile. we are using 1440 less for mobile
        };

        // Add the resize event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Return the current window width and isMobile flag
    return { width, isMobile };
};

export default useWindowWidth;
