import { Form, Input, Select } from "antd";
import { CountryCode, CountryDataStatus } from "@/enums/countryCollectionEnums";
import { useEffect } from "react";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { CountryModel } from "@/models/countryCollectionModel";
interface IProps {
    data?: CountryModel;
    form: any;
    onFinish: (values: any) => Promise<void>;
}

const CountryModifications = ({ data, form, onFinish }: IProps) => {
    useEffect(() => {
        form.resetFields();
    }, [data, form]);

    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Add country"
                    initialValues={{
                        name: data?.name,
                        code: data?.code,
                        status: data?.status,
                    }}
                >
                    <Form.Item
                        label="Code"
                        name="code"
                        rules={[
                            {
                                required: true,
                                message: "Country code is required",
                            },
                        ]}
                    >
                        <Select
                            disabled={!!data}
                            placeholder="Please select a country code"
                        >
                            {renderOptionsFromEnum(CountryCode)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Country name is required",
                            },
                        ]}
                        label="Name"
                        name="name"
                    >
                        <Input placeholder="Country name" />
                    </Form.Item>

                    <Form.Item required name="status" label="Status">
                        <Select placeholder="Please select a status">
                            {renderOptionsFromEnum(CountryDataStatus)}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default CountryModifications;
