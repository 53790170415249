import { IFilterType } from "./filters";
import { IPagination } from "./pagination";

export interface IUser {
    object: string;
    id: number;
    name: string;
    phone: string | null;
    email: string;
    emailVerifiedAt: string | null;
    type: string;
    is_active: number;
    roles?: IRoleCollection;
    created_at: string;
    is_drop_user?: boolean | undefined;
    shipping_mark?: string | undefined;
    original_id?: number;
}

export interface IRoleCollection {
    object: string;
    data: IRole[];
}

export interface IRole {
    object: string;
    id: number;
    name: string;
    label: string | null;
    access: string;
}

export interface IUserCollection {
    object: string;
    data: IUser[];
    pagination: IPagination;
    filters: IFilterType;
}

export interface IUserCredentials {
    name: string;
    password: string;
    email: string;
    role_ids: number[];
}

export interface IUserStatus {
    name: string;
    is_active: number;
    role_ids: number[];
    password?: string;
    password_confirmation?: string;
}

export enum ExpiredUnit {
    Hour = "hour",
    Day = "days",
}

export enum UserType {
    Admin = "admin",
    Agent = "agent",
    DwAdmin = "dw-admin",
    Customer = "customer",
}
export interface IImpersonationSession {
    user_type: UserType;
    impersonatee_id: number;
    impersonator_id: number;
    expired_unit: ExpiredUnit;
    expired_value: number;
    intent?: string;
    role_ids?: number[];
}

export interface IImpersonateCreateResponse {
    message: string;
    data: IImpersonateSessionData;
}

export interface IImpersonateSessionData {
    session: IImpersonateSession;
    email: string;
    password: string;
}

export interface IImpersonateSession {
    object: string;
    id: number;
    pool: ImpersonateUserPool;
    impersonatee: IUser;
    impersonator: IUser;
    acquired_by: IUser;
    expired_at: string;
    status: string;
    created_at: string;
}

export interface ImpersonateUserPool {
    object: string;
    id: number;
    user_type: string;
    user: IUser;
    status: string;
    created_at: string;
}

export interface IImpersonateSessionCollection {
    object: "UserCollection";
    data: IImpersonateSession[];
    pagination: IPagination;
    filters: IFilterType;
}

export interface IImpersonateUserPool {
    object: string;
    id: number;
    user_type: string;
    user: IUser;
    status: string;
    created_at: string;
}

export interface IMinimalUsers {
    object: "UserMinimal";
    email: string;
    id: number;
    name: string;
}

export interface IMinimalUsersCollection {
    object: string;
    data: IMinimalUsers[];
    pagination: IPagination;
    filters: IFilterType;
}
