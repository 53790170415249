import { useService } from "../../contexts";
import { useAtom } from "jotai";
import { getError } from "../utils/errors";
import { wishlistAtom } from "@/lib/core-react/store/store";

export const useGetWishlist = () => {
    const [wishlist, setWishlist] = useAtom(wishlistAtom);
    const { wishlistService } = useService();

    const getWishlist = async (params?: string): Promise<void> => {
        setWishlist({ ...wishlist, isLoading: true, error: null });
        try {
            const response = await wishlistService.wishlistResource.get(params);
            setWishlist({
                ...wishlist,
                isLoading: false,
                data: response,
                refetch: false,
            });
        } catch (error: any) {
            setWishlist({
                ...wishlist,
                isLoading: false,
                refetch: false,
                error: getError(error),
            });
            throw error;
        }
    };
    return { getWishlist } as const;
};
