import { ITranslationEntry } from "../lang";

export const IG: ITranslationEntry = {
    translations: {
        notifications: "Ngosi",
        markAllAsRead: "kaa ihe niile ka agụlaha",
        poweredBy: "kwadoro site na",
        settings: "Mwube gasị",
        noNewNotification: "Ọnweghị ihe ọhụrụ ịhụ ebe a",
    },
    lang: "ig",
};
