import { CountryDataStatus } from "@/enums/countryCollectionEnums";
import {
    ICountry,
    ICountryCollection,
    ICountryUpdateRequest,
} from "@/types/countryCollection";
import { IFilterType } from "@/types/filters";
import { PaginationModel } from "./paginationModel";
import { SelectProps } from "antd";

export class CountryModel {
    object: string;
    id: number;
    code: string;
    name: string;
    flag: string;
    status?: string;
    created_at?: string;

    constructor(dataItem: ICountry) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.code = dataItem.code;
        this.name = dataItem.name;
        this.flag = dataItem.flag;
        this.status = dataItem.status;
        this.created_at = dataItem.created_at;
    }
    getId = () => this.id;
    getCode = () => this.code;
    getName = () => this.name;
    getFlag = () => this.flag;
    getStatus = () => this.status;
}

export class CountryCollectionModel {
    object: string;
    data: CountryModel[];
    pagination: PaginationModel;
    filters: IFilterType;

    constructor(data: ICountryCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new CountryModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData = () => this.data;
    getPagination = () => this.pagination;
    getFilters = () => this.filters;

    getRegionCountryOptions(): SelectProps["options"] {
        if (this.data && this.data.length > 0) {
            return this.data.map((item) => ({
                label: item.getName(),
                value: item.getId(),
            }));
        }

        return [];
    }

    getCountryCode() {
        const obj: { [key: string]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.code));
        return obj;
    }
    getCountryId() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.name));
        return obj;
    }

    getCountryId2() {
        const obj: { [key: number]: string } = {};
        this.data.forEach((item) => (obj[item.id] = item.code));
        return obj;
    }

    getCountryById(id: number): CountryModel | undefined {
        return this.data.find((item) => item.id === id);
    }

    getCountryLabeledValue() {
        return this.data.map((item) => ({
            label: item.getName(),
            value: item.getId(),
        }));
    }
    getCountryLabeledLabel() {
        return this.data.map((item) => ({
            label: item.getName(),
            value: item.getName(),
        }));
    }

    getCountryLabeledValueAndId() {
        return this.data.map((item) => ({
            label: item.getName(),
            value: item.getCode(),
            id: item.getId(),
        }));
    }
}

//update Request
export class CountryUpdateRequestModel {
    name: string;
    status: CountryDataStatus;

    constructor(reqData: ICountryUpdateRequest) {
        this.name = reqData.name;
        this.status = reqData.status;
    }
}
