import { ITranslationEntry } from "../lang";

export const VI: ITranslationEntry = {
    translations: {
        notifications: "Thông báo",
        markAllAsRead: "đánh dấu tất cả như đã đọc",
        poweredBy: "Được cung cấp bởi",
        settings: "Cài đặt",
        noNewNotification: "Chưa có gì mới để xem ở đây",
    },
    lang: "vi",
};
