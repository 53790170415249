import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { ShippingCategoryRequestModel } from "@/models/shippingCategory";

class ShippingCategoryResource extends BaseResource {
    path = `/api/shipping-core/admin/shipping-category/v1/shipping-categories`;

    create(
        payload: ShippingCategoryRequestModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}?expand=children.children${params ? `&${params}` : ""}`;

        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    update(
        id: string,
        payload: ShippingCategoryRequestModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }
    delete(id: string): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("DELETE", path, {}, {}, {});
    }

    sync(customHeaders: Record<string, any> = {}): ResponsePromise {
        const path = `${this.path}/sync-to-other-service`;
        return this.client.request("GET", path, {}, customHeaders);
    }
}

export default ShippingCategoryResource;
