import { ITranslationEntry } from "../lang";

export const JA: ITranslationEntry = {
    translations: {
        notifications: "通知",
        markAllAsRead: "すべて既読にする",
        poweredBy: "搭載",
        settings: "設定",
        noNewNotification: "ここにはまだ新しいものはありません",
    },
    lang: "ja",
};
