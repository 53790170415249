import { ITranslationEntry } from "../lang";

export const LO: ITranslationEntry = {
    translations: {
        notifications: "ການແຈ້ງເຕືອນ",
        markAllAsRead: "ໝາຍທັງໝົດວ່າອ່ານແລ້ວ",
        poweredBy: "ສະ​ຫນັບ​ສະ​ຫນູນ​ໂດຍ",
        settings: "ການຕັ້ງຄ່າ",
        noNewNotification: "ບໍ່ມີຫຍັງໃໝ່ທີ່ຈະເຫັນຢູ່ທີ່ນີ້ເທື່ອ",
    },
    lang: "lo",
};
