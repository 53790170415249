import "./styles.scss";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import classNames from "classnames";
import {
    useEditor,
    EditorContent,
    Editor,
    mergeAttributes,
    BubbleMenu,
} from "@tiptap/react";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Bold from "@tiptap/extension-bold";
import Underline from "@tiptap/extension-underline";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Code from "@tiptap/extension-code";
import History from "@tiptap/extension-history";
import Mention from "@tiptap/extension-mention";
import Link from "@tiptap/extension-link";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Highlight from "@tiptap/extension-highlight";
import Blockquote from "@tiptap/extension-blockquote";
// Custom
import * as Icons from "./Icons";
import { mentionSuggestionOptions } from "./mentionSuggestionOptions";
import { LinkModal } from "./LinkModal";
import { Button, ColorPicker, Space } from "antd";

interface IProps {
    content: string;
    setContent: Dispatch<SetStateAction<string>>;
}

const RichTextEditor = ({ content, setContent }: IProps) => {
    const editor = useEditor({
        extensions: [
            Document,
            History,
            Paragraph,
            Text,
            TextStyle,
            Color,
            Bold,
            Underline,
            Italic,
            Strike,
            Code,
            // Heading,
            Highlight,
            Blockquote,
            Link.configure({
                protocols: ["mailto"],
                openOnClick: false,
                autolink: true,
                validate: (href) => /^https?:\/\//.test(href),
            }).extend({
                inclusive: false,
            }),
            Mention.configure({
                HTMLAttributes: {
                    class: "mention",
                },
                suggestion: mentionSuggestionOptions,
                renderHTML({ options, node }) {
                    return [
                        "span",
                        mergeAttributes(
                            {
                                "data-type": "mention",
                                "data-id": node.attrs.id ?? node.attrs.label,
                                "data-label": node.attrs.label ?? node.attrs.id,
                                class: "mention-list",
                            },
                            options.HTMLAttributes,
                        ),
                        `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`,
                    ];
                },
            }),
            // Image,
            // Dropcursor,
        ],
        content,
        onUpdate({ editor }) {
            setContent(editor.getHTML());
        },
    }) as Editor;

    // Link
    const [modalIsOpen, setIsOpen] = useState(false);
    const [url, setUrl] = useState<string>("");

    const openLinkModal = useCallback(() => {
        setUrl(editor.getAttributes("link").href);
        setIsOpen(true);
    }, [editor]);

    const closeLinkModal = useCallback(() => {
        setIsOpen(false);
        setUrl("");
    }, []);

    const saveLink = useCallback(() => {
        if (url) {
            editor
                .chain()
                .focus()
                .extendMarkRange("link")
                .setLink({ href: url, target: "_blank" })
                .run();
        } else {
            editor.chain().focus().extendMarkRange("link").unsetLink().run();
        }
        closeLinkModal();
    }, [editor, url, closeLinkModal]);

    const removeLink = useCallback(() => {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        closeLinkModal();
    }, [editor, closeLinkModal]);

    const toggleBold = useCallback(() => {
        editor.chain().focus().toggleBold().run();
    }, [editor]);

    const toggleUnderline = useCallback(() => {
        editor.chain().focus().toggleUnderline().run();
    }, [editor]);

    const toggleItalic = useCallback(() => {
        editor.chain().focus().toggleItalic().run();
    }, [editor]);

    const toggleStrike = useCallback(() => {
        editor.chain().focus().toggleStrike().run();
    }, [editor]);

    const toggleCode = useCallback(() => {
        editor.chain().focus().toggleCode().run();
    }, [editor]);

    // const toggleParagraph = useCallback(() => {
    //   editor.chain().focus().setParagraph().run();
    // }, [editor]);

    // const toggleH1 = useCallback(() => {
    //   editor.chain().focus().toggleHeading({ level: 1 }).run();
    // }, [editor]);
    // const toggleH2 = useCallback(() => {
    //   editor.chain().focus().toggleHeading({ level: 2 }).run();
    // }, [editor]);
    // const toggleH3 = useCallback(() => {
    //   editor.chain().focus().toggleHeading({ level: 3 }).run();
    // }, [editor]);
    // const toggleH4 = useCallback(() => {
    //   editor.chain().focus().toggleHeading({ level: 4 }).run();
    // }, [editor]);
    // const toggleH5 = useCallback(() => {
    //   editor.chain().focus().toggleHeading({ level: 5 }).run();
    // }, [editor]);

    const toggleHighlight = useCallback(() => {
        editor.chain().focus().toggleHighlight().run();
    }, [editor]);

    const toggleBlockQuote = useCallback(() => {
        editor.chain().focus().toggleBlockquote().run();
    }, [editor]);

    if (!editor) {
        return null;
    }

    return (
        <div className="editor">
            <div className="menu">
                <ColorPicker
                    size="small"
                    onChange={(color) => {
                        editor
                            .chain()
                            .focus()
                            .setColor(color.toHexString())
                            .run();
                    }}
                    value={editor.getAttributes("textStyle").color}
                    defaultValue={"red"}
                    data-testid="setColor"
                />
                <button
                    onClick={() => editor.chain().focus().unsetColor().run()}
                    data-testid="unsetColor"
                >
                    <Icons.ResetColor />
                </button>
                <button
                    className={classNames("menu-button", {
                        "is-active": editor.isActive("bold"),
                    })}
                    onClick={toggleBold}
                >
                    <Icons.Bold />
                </button>
                <button
                    className={classNames("menu-button", {
                        "is-active": editor.isActive("underline"),
                    })}
                    onClick={toggleUnderline}
                >
                    <Icons.Underline />
                </button>
                <button
                    className={classNames("menu-button", {
                        "is-active": editor.isActive("italic"),
                    })}
                    onClick={toggleItalic}
                >
                    <Icons.Italic />
                </button>
                <button
                    className={classNames("menu-button", {
                        "is-active": editor.isActive("strike"),
                    })}
                    onClick={toggleStrike}
                >
                    <Icons.Strikethrough />
                </button>
                <button
                    className={classNames("menu-button", {
                        "is-active": editor.isActive("code"),
                    })}
                    onClick={toggleCode}
                >
                    <Icons.Code />
                </button>
                <button
                    className={classNames("menu-button", {
                        "is-active": editor.isActive("link"),
                    })}
                    onClick={openLinkModal}
                >
                    <Icons.Link />
                </button>
                {/* <button
          onClick={toggleParagraph}
          className={editor.isActive("paragraph") ? "is-active" : ""}
        >
          <Icons.Paragraph />
        </button> */}
                {/* <button
          onClick={toggleH1}
          className={
            editor.isActive("heading", { level: 1 }) ? "is-active" : ""
          }
        >
          <Icons.H1 />
        </button>
        <button
          onClick={toggleH2}
          className={
            editor.isActive("heading", { level: 2 }) ? "is-active" : ""
          }
        >
          <Icons.H2 />
        </button>
        <button
          onClick={toggleH3}
          className={
            editor.isActive("heading", { level: 3 }) ? "is-active" : ""
          }
        >
          <Icons.H3 />
        </button>
        <button
          onClick={toggleH4}
          className={
            editor.isActive("heading", { level: 4 }) ? "is-active" : ""
          }
        >
          <Icons.H4 />
        </button>
        <button
          onClick={toggleH5}
          className={
            editor.isActive("heading", { level: 5 }) ? "is-active" : ""
          }
        >
          <Icons.H5 />
        </button> */}

                <button
                    onClick={toggleHighlight}
                    className={editor.isActive("highlight") ? "is-active" : ""}
                >
                    <Icons.Highlight />
                </button>

                <button
                    onClick={toggleBlockQuote}
                    className={editor.isActive("blockquote") ? "is-active" : ""}
                >
                    <Icons.BlockQuote />
                </button>
            </div>

            <BubbleMenu
                tippyOptions={{ duration: 150 }}
                editor={editor}
                shouldShow={({ editor, from, to }) => {
                    // only show the bubble menu for links.
                    return from === to && editor.isActive("link");
                }}
            >
                <Space>
                    <Button onClick={openLinkModal}>Edit</Button>
                    <Button danger onClick={removeLink}>
                        Remove
                    </Button>
                </Space>
            </BubbleMenu>

            <EditorContent editor={editor} />

            <LinkModal
                url={url}
                open={modalIsOpen}
                onCancel={closeLinkModal}
                onChangeUrl={setUrl}
                onSaveLink={saveLink}
                onRemoveLink={removeLink}
            />
        </div>
    );
};

export default RichTextEditor;
