export function formatStatus(
    input: string,
    isUpperCase: boolean = true,
): string {
    if (!input) {
        return "N/A";
    }

    const formatted = input
        .toLowerCase() // Ensure the input is in lowercase
        .replace(/-/g, " ") // Replace hyphens with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word

    // Return the formatted string based on the isUpperCase flag
    return isUpperCase ? formatted.toUpperCase() : formatted;
}
