import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import {
    IDestinationWarehouseForm,
    IDestinationWarehouseMemberForm,
    IDestinationWarehouseMemberInvitationForm,
} from "@/types/destinationWarehouseCollection";

class DestinationWarehouseResource extends BaseResource {
    path = `/api/destination/admin/warehouse/v1/warehouses`;
    membershipPath = `/api/destination/admin/membership/v1`;

    create(
        payload: IDestinationWarehouseForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }
    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}?expand=country,region${params ? `&${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    update(
        id: number,
        payload: IDestinationWarehouseForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    /**
     * @description Gets member list,
     * accepts fields and expands as params
     */
    getMembers(
        destination_warehouse_id: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.membershipPath}/${destination_warehouse_id}/members?expand=roles&expand=user`;
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    /**
     * @description deletes member,
     * accepts destination_warehouse_id and id
     */
    deleteMember(
        destination_warehouse_id: number,
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.membershipPath}/${destination_warehouse_id}/members/${id}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }

    /**
     * @description updates member,
     * accepts destination_warehouse_id, id and a payload
     */
    updateMember(
        destination_warehouse_id: number,
        id: number,
        payload: IDestinationWarehouseMemberForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.membershipPath}/${destination_warehouse_id}/members/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    /**
     * @description Gets invitation list,
     * accepts fields and expands as params
     */
    getInvitations(
        destination_warehouse_id: number,
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        let path = `${this.membershipPath}/invitation/${destination_warehouse_id}/invitations?expand=roles`;
        if (params) {
            path = `${path}&${params}`;
        }
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    /**
     * @description deletes invitation,
     * accepts destination_warehouse_id and id
     */
    deleteInvitation(
        destination_warehouse_id: number,
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.membershipPath}/invitation/${destination_warehouse_id}/invitations/${id}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }

    /**
     * @description send invitation,
     * accepts destination_warehouse_id and a payload
     */
    sendInvitation(
        destination_warehouse_id: number,
        payload: IDestinationWarehouseMemberInvitationForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.membershipPath}/invitation/${destination_warehouse_id}/invitations`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    /**
     * @description resend invitation,
     * accepts destination_warehouse_id and a payload
     */
    resendInvitation(
        destination_warehouse_id: number,
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.membershipPath}/invitation/${destination_warehouse_id}/invitations/${id}`;
        return this.client.request("POST", path, undefined, {}, customHeaders);
    }
}

export default DestinationWarehouseResource;
