import CmsApiService from "../../apiServices/cmsApiService";
import StrapiResource from "../../resources/private/stripiResource";

class StrapiService extends CmsApiService {
    public strapiResource: StrapiResource;

    constructor() {
        super();

        this.strapiResource = new StrapiResource(this.config);
    }
}
export default StrapiService;
