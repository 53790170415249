import React, { useEffect, useState } from "react";
import { Button, Form, notification } from "antd";
import { IBuyPlaceOrderCartShippingFormData } from "@/types/buyOrderPlace";
import ShippingAddressSelect from "../ShippingAddressSelect";
import SelectShippingMethod from "../SelectShippingMethod";
import { IShipping } from "../CreateOrderSteps/CreateOrderSteps";
interface IProps {
    formSubmit: (values: any) => void;
    initialData: any;
    current: number;
    prev: () => void;
    shippingInfoData: IBuyPlaceOrderCartShippingFormData | undefined;
    setShippingInfoData: React.Dispatch<
        React.SetStateAction<IBuyPlaceOrderCartShippingFormData | undefined>
    >;
    isLoading: boolean;
}
const ShippingDetailsForm = ({
    formSubmit,
    initialData,
    current,
    prev,
    shippingInfoData,
    setShippingInfoData,
    isLoading,
}: IProps) => {
    const [form] = Form.useForm();
    const [shippingInfoErrorMessage, setShippingInfoErrorMessage] = useState<
        null | string
    >(null);
    const [isShippingMethodModalOpen, setIsShippingMethodModalOpen] =
        useState(false);
    const [selectedAddressId, setSelectedAddressId] = useState<
        number | undefined
    >(undefined);
    const [shippingAddressErrorMessage, setShippingAddressErrorMessage] =
        useState<null | string>(null);

    useEffect(() => {
        const isShippingInfoHave = shippingInfoData
            ? Object.values(shippingInfoData).every(Boolean)
            : false;
        if (isShippingInfoHave) {
            setShippingInfoErrorMessage(null);
        }
    }, [shippingInfoData]);

    useEffect(() => {
        if (selectedAddressId) {
            setShippingAddressErrorMessage(null);
        }
    }, [shippingAddressErrorMessage]);

    const onFinish = () => {
        const isShippingInfoHave = shippingInfoData
            ? Object.values(shippingInfoData).every(Boolean)
            : false;
        if (!isShippingInfoHave) {
            setShippingInfoErrorMessage("Please select shipping info");

            return;
        }
        if (!selectedAddressId) {
            setShippingAddressErrorMessage("Please select shipping address");

            return;
        }
        setShippingInfoErrorMessage(null);
        setShippingAddressErrorMessage(null);

        const payload: IShipping = {
            provider: shippingInfoData?.provider as string,
            agent_category_price_read_id:
                shippingInfoData?.agent_category_price_read_id as number,
            shipping_category_id:
                shippingInfoData?.shipping_category_id as string,
            shipping_address_id: selectedAddressId as number,
        };

        const validate = Object.values(payload).every(Boolean);
        if (validate) {
            formSubmit({
                shipping: { ...payload },
            });
        } else {
            notification.error({
                message: "Form validation failed",
            });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialData}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <SelectShippingMethod
                    shippingInfoErrorMessage={shippingInfoErrorMessage}
                    placeBuyOrderProductStep2FormData={shippingInfoData}
                    setIsShippingMethodModalOpen={setIsShippingMethodModalOpen}
                    isShippingMethodModalOpen={isShippingMethodModalOpen}
                    setPlaceBuyOrderProductStep2FormData={setShippingInfoData}
                />
            </div>
            {/* Shipping Address Select */}
            <div
                style={{
                    marginTop: "30px",
                }}
            >
                <ShippingAddressSelect
                    selectedAddressId={selectedAddressId}
                    setSelectedAddressId={setSelectedAddressId}
                    shippingAddressErrorMessage={shippingAddressErrorMessage}
                />
            </div>
            <Form.Item>
                {current > 0 && (
                    <Button
                        style={{
                            margin: "0 8px",
                        }}
                        onClick={() => prev()}
                        loading={isLoading}
                    >
                        Previous
                    </Button>
                )}
                <Button loading={isLoading} type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ShippingDetailsForm;
