import StatusTag from "@/components/StatusTag";
import { AgentWarehouseModel } from "@/models/agentWarehouseCollectionModel";
import { formatString } from "@/utils/helper";
import { Descriptions, DescriptionsProps, Divider } from "antd";

interface IProps {
    warehouse: AgentWarehouseModel;
}

export const ViewDetails = ({ warehouse }: IProps) => {
    const agentCompany = warehouse.getAgentCompany();
    const warehouseAddress = warehouse.getWareHouseAddress();

    const agentCompanyAddress = warehouse.getAgentCompany().getAddress();

    // Always show other details and conditionally show warehouse address or "No address available"
    const warehouseItems: DescriptionsProps["items"] = [
        {
            key: "2",
            label: "Warehouse Name",
            children: warehouse.getName(),
        },
        {
            key: "3",
            label: "Shipping Mark",
            children: warehouse.getShippingMark(),
        },
        {
            key: "4",
            label: "Operation Status",
            children: (
                <StatusTag
                    slug={warehouse.getOperationStatus()}
                    text={formatString(warehouse.getOperationStatus())}
                />
            ),
        },
        {
            key: "5",
            label: "Verification Status",
            children: (
                <StatusTag
                    slug={warehouse.getVerificationStatus()}
                    text={warehouse.getVerificationStatus()}
                />
            ),
        },
        {
            key: "6",
            label: "Contact Person",
            children: warehouse.getContactPerson(),
        },
        {
            key: "7",
            label: "Contact Number",
            children: warehouse.getContactNumber(),
        },
        {
            key: "8",
            label: "Country",
            children: (
                <>
                    <img
                        src={warehouse.getCountry()?.getFlag()}
                        alt={warehouse.getCountry()?.getName()}
                        style={{ width: "15px", marginRight: "8px" }}
                    />
                    {warehouse.getCountry()?.getName()}
                </>
            ),
        },
        {
            key: "9",
            label: "Instructions",
            children: warehouse.getInstruction(),
        },
        {
            key: "1",
            label: "Warehouse Address",
            children: warehouseAddress
                ? `${warehouseAddress?.display_address?.street_address}, ${warehouseAddress?.display_address?.state}, ${warehouseAddress?.display_address?.city}, ${warehouseAddress?.display_address?.country}`
                : "No address available",
        },
    ];

    // Agent Company details
    const companyItems: DescriptionsProps["items"] = [
        {
            key: "1",
            label: "Primary Name",
            children: agentCompany?.getPrimaryName(),
        },
        {
            key: "2",
            label: "Secondary Name",
            children: agentCompany?.getSecondaryName(),
        },
        {
            key: "3",
            label: "Operation Status",
            children: (
                <StatusTag
                    slug={agentCompany?.getOperationStatus()}
                    text={formatString(agentCompany?.getOperationStatus())}
                />
            ),
        },
        {
            key: "4",
            label: "Verification Status",
            children: (
                <StatusTag
                    slug={agentCompany?.getVerificationStatus()}
                    text={agentCompany?.getVerificationStatus()}
                />
            ),
        },
        {
            key: "6",
            label: "Company Type",
            children: agentCompany?.getCompanyType(),
        },
        {
            key: "7",
            label: "Director Name",
            children: agentCompany?.getDirectorName(),
        },
        {
            key: "8",
            label: "Designation",
            children: agentCompany?.getDesignation(),
        },
        {
            key: "9",
            label: "Business Phone",
            children: agentCompany?.getBusinessPhone(),
        },
        {
            key: "10",
            label: "Years of Experience",
            children: formatString(agentCompany?.getYearsOfExperience()),
        },
        {
            key: "11",
            label: "Number of People in Team",
            children: formatString(agentCompany?.getNumberOfPeopleInTeam()),
        },
        {
            key: "12",
            label: "Address",
            span: 2,
            children: agentCompanyAddress
                ? `${agentCompanyAddress?.address_1}, ${agentCompanyAddress?.address_2}, ${agentCompanyAddress?.city}, ${agentCompanyAddress?.state}, ${agentCompanyAddress?.postal}, ${agentCompanyAddress?.country}`
                : "No address Available",
        },
    ];

    return (
        <>
            <Divider />
            {/* Warehouse Details Section */}
            <Descriptions
                title="Warehouse Details"
                layout="vertical"
                items={warehouseItems}
            />
            <Divider />
            {/* Agent Company Details Section */}
            <Descriptions
                title="Agent Company Details"
                layout="vertical"
                items={companyItems}
            />
        </>
    );
};
