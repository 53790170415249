import React, { CSSProperties, Suspense, useEffect, useState } from "react";
import {
    Button,
    Dropdown,
    Modal,
    Table,
    notification,
    Typography,
    TablePaginationConfig,
    Flex,
    Form,
    Spin,
    Tooltip,
} from "antd";
import { HddFilled, LoadingOutlined, SyncOutlined } from "@ant-design/icons";

import {
    useApprovedShippingProductForProduct,
    useRejectedShippingProduct,
    useShipmentProductAttachPackageImage,
    useSyncShipmentProducts,
} from "@/lib/core-react/hooks/private/useShipping";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import {
    BidList,
    SelectWarehouseModal,
} from "@/pages/Shipping/ShipmentProducts";
import useActionsProps from "@/helpers/useActionsProps";
import ShipmentProductInfo from "../newComponents/ShipmentProductInfo";
import { tw } from "@/consts/theme/tailwindTheme";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import useShipmentProductsActions, {
    useShipmentProductsBulkActions,
} from "./useActions";
import ShipmentProductPackages from "../newComponents/ShipmentProductPackages";
import ShipmentProductShippingInfo from "../newComponents/ShipmentProductShippingInfo";
import { ColumnsType } from "antd/es/table";
import { showError } from "@/helpers/showError";
import { ShippingInfoChangeModals } from "../newComponents/ShippingInfoChangeModals";
import { MultiChunkUpload } from "@/components/MultiChunkUpload";
import {
    IShipmentProductAttachImagesPayload,
    RejectPayload,
} from "@/types/shipmentProductCollection";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_SHIPPING_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import useRefetch from "@/hooks/useRefetch";
import ShipmentProductTableSummary from "./components/ShipmentProductTableSummary";
import RejectProductModal from "../components/handleRejectModal";
import {
    ExpiredUnit,
    IImpersonateSessionData,
    IImpersonationSession,
    UserType,
} from "@/types/userCollection";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import { RegionModel } from "@/models/regionCollectionModel";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import ShipmentProductChangeShippingRate from "@/pages/Shipping/ShipmentProducts/components/ShipmentProductChangeShippingRate.tsx";
import ShipmentProductChangeCustomerRate from "@/pages/Shipping/ShipmentProducts/components/ShipmentProductChangeCustomerRate.tsx";
import { RoleModel } from "@/models/roleCollectionModel";
import {
    useCreateImpersonateSession,
    useGetRole,
} from "@/lib/core-react/hooks/private/useRole";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { responseTypeQuery } from "@/filters/constant";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import ImpersonateResultView from "@/components/ImpersonateSessionCreate/impersonateResultView";
import { downloadImpersonateCredentialsAsTextFile } from "@/utils/helper";
import useOryAuth from "@/lib/core-react/utils/useOryAuth";
import ImpersonateIcon from "@/assets/ImpersonateIcon";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import { BuyProductModel } from "@/models/buyProductCollectionModel";
import InvoiceInfoShipmentProduct from "./InvoiceSummary/InvoiceInfoShipmentProduct";
import CustomerInfo from "@/components/shared/Order/CustomerInfo";

export type modalActionType =
    | "carton"
    | "reject"
    | "assignAgent"
    | "bidList"
    | "tracking"
    | "notMatch"
    | false;

interface IProps {
    data: ShipmentProductModel[] | undefined;
    paginationConfig?: false | TablePaginationConfig;
    isLoading?: boolean;
    isOrderPage: boolean;
    selectedRowKeys: number[];
    setSelectedRowKeys: React.Dispatch<React.SetStateAction<number[]>>;
    selectedRows: ShipmentProductModel[];
    setSelectedRows: React.Dispatch<
        React.SetStateAction<ShipmentProductModel[]>
    >;
    onClearBulkSelectedState: () => void;
}
const { Text } = Typography;

export const agentRateChangeAllowedStatuses = [
    "shipment-assigned-agent",
    "shipment-shipping-started",
    "shipment-not-arrived",
    "shipment-arrived-at-warehouse",
    "shipment-handover-to-travel-agent",
    "shipment-handover-to-moveon",
    "shipment-preparing-for-transport",
    "shipment-handover-to-ship",
    "shipment-handover-to-airline",
    "shipment-arrived-at-destination-port",
    "shipment-arrived-at-destination-airport",
    "shipment-customs-released",
    "shipment-received-by-moveon",
];

const ShipmentProductsTable = ({
    data,
    isLoading,
    paginationConfig,
    isOrderPage,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    onClearBulkSelectedState,
}: IProps) => {
    const [form] = Form.useForm();
    const { paySession, isLoading: isLoadingPaySession } = useInvoice();
    const actionsProps = useActionsProps();
    const { approvedShippingProduct } = useApprovedShippingProductForProduct();
    const { syncShipmentProducts, isLoading: isLoadingSync } =
        useSyncShipmentProducts();

    const { rejectedShippingProduct, isLoading: isRejectedLoading } =
        useRejectedShippingProduct();
    const {
        attachImage: attachShipmentProductPackageImage,
        isLoading: isLoadingAttachShipmentProductPackageImage,
    } = useShipmentProductAttachPackageImage();
    const { createImpersonate } = useCreateImpersonateSession();
    const { getRole } = useGetRole();
    const { refetchListApi } = useRefetch();
    const [selectedProduct, setSelectedProduct] = useState<
        ShipmentProductModel | undefined
    >(undefined);
    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
        buyProduct: BuyProductModel[] | undefined;
    }>();
    const [modalOpen, setModalOpen] = useState<modalActionType>(false);
    const [selectedCustomerUser, setSelectedCustomerUser] =
        useState<CustomerUserModel>();
    const [shouldFetchImpersonateData, setShouldFetchImpersonateData] =
        useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);
    const [loadingImpersonate, setLoadingImpersonate] = useState<
        Record<number, boolean>
    >({});
    const [categoryPriceDetailsInfo, setCategoryPriceDetailsInfo] = useState<
        ICategoryPriceRequestParams | undefined
    >(undefined);
    const [impersonateData, setImpersonateData] =
        useState<IImpersonateSessionData>();

    const {
        allOptionsData: { adminOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    const { session } = useOryAuth();

    const email = session?.identity?.traits.email;

    useEffect(() => {
        if (shouldFetchImpersonateData && adminOptionsData.options.length > 0) {
            createImpersonateCredential();
            setShouldFetchImpersonateData(false);
        }
    }, [adminOptionsData.options.length, shouldFetchImpersonateData]);

    const handleModal = async (payload: IModalData) => {
        setModalOpenType(payload.action);
        setSelectedProduct(payload.data);
        switch (payload.action) {
            case "approve_and_assign_agent":
                agentAssignHandler(payload.data);
                break;
            case "approved_shipment_product":
                if (payload.data) {
                    handleSingleApprove(payload.data);
                }
                break;
            case "reject_shipment_product":
                if (payload.data) {
                    setSelectedProduct(payload.data.id);
                }
                break;
            case "pay-invoice":
                setSelectedPayInvoiceData(payload.data);
                break;
            case "approved_bulk_shipment_product":
                await handleBulkApprove();
                break;

            default:
                break;
        }
    };

    const onCancel = () => {
        handleModal({
            action: false,
            data: null,
        });
    };

    const onClearSingleSelectedState = () => {
        setSelectedProduct(undefined);
        setModalOpen(false);
        onCancel();
        setModalOpenType(false);
        setCategoryPriceDetailsInfo(undefined);
        setSelectedPayInvoiceData(undefined);
    };

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, ...restValues } = values;

                const payload = {
                    amount,
                    gateway_id,
                    gateway_data: { ...restValues },
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                notification.success({
                    message: "Payment initiated successfully",
                });
                onClearSingleSelectedState();
                refetchListApi();
            } catch (error) {
                showError(error, form);
            }
        }
    };

    const createImpersonateCredential = async () => {
        if (!selectedProduct) {
            return;
        }

        if (adminOptionsData.options.length === 0) {
            showError("Admin data not available");
            return;
        }

        const shipmentProductId = selectedProduct.getId();
        const user = selectedProduct.user;
        setLoadingImpersonate((prev) => ({
            ...prev,
            [shipmentProductId]: true,
        }));
        setSelectedCustomerUser(user);
        let roleIds: number[] = [];
        if (user.type === UserType.Customer) {
            const roleCollectionData = await getRole(
                `is_customer=1&response_type=minimal`,
            );

            if (roleCollectionData) {
                const customerRole = roleCollectionData?.find(
                    (role: RoleModel) =>
                        role.name.toLowerCase() === "customer-role" ||
                        role.name.toLowerCase() === "customer role",
                );
                roleIds = [customerRole.id];
            }
        }
        try {
            const payload: IImpersonationSession = {
                user_type: UserType.Customer,
                impersonatee_id: user.id,
                impersonator_id: adminOptionsData.options[0].value as number,
                expired_unit: ExpiredUnit.Hour,
                expired_value: 1,
                intent: "",
                role_ids: roleIds,
            };

            const response = await createImpersonate(payload);
            setImpersonateData(response);

            if (response) {
                downloadImpersonateCredentialsAsTextFile(response);
                setIsShowModal(true);
            }
        } catch (error: any) {
            showError(error);
        } finally {
            setLoadingImpersonate((prev) => ({
                ...prev,
                [shipmentProductId]: false,
            })); // Reset loading for this invoice
        }
    };

    const agentAssignHandler = (shipmentProduct?: ShipmentProductModel) => {
        const firstRow = shipmentProduct ? shipmentProduct : selectedRows[0];

        const categoryDetails: ICategoryPriceRequestParams = {
            region: firstRow.getRegionCode(),
            region_id: firstRow.getRegionId(),
            shipping_category_id: firstRow.getShippingCategoryId(),
            destination_warehouse_id: firstRow.getDestinationWarehouseId(),
            shipping_type: firstRow.getShippingType() as any,
            shipping_mode: firstRow.getShippingMode() as any,
            shipping_country_id: firstRow.getShippingCountryId(),
            destination_country_id: firstRow.getDestinationCountryId(),
        };
        setCategoryPriceDetailsInfo(categoryDetails);
        setModalOpen("assignAgent");
    };

    const handleSingleRejectProduct = async (value: {
        reject_reason: string;
    }) => {
        const payload: RejectPayload = {
            reject_reason: value.reject_reason,
        };

        try {
            // Pass the full payload, not just payload.reject_reason
            await rejectedShippingProduct(Number(selectedProduct), payload);
            notification.success({
                message: "Successfully rejected shipment product.",
            });
            onClearSingleSelectedState();
            await refetchListApi();
        } catch (error) {
            showError(error);
        }
    };

    const handleBulkApprove = async () => {
        const productsToApprove = selectedRows.filter(
            (product) =>
                product.getStatus() === "shipment-pending" ||
                product.getStatus() === "shipment-rejected",
        );

        if (productsToApprove.length === 0) {
            notification["info"]({
                message: "No Products to Approve",
                description:
                    "All selected products are already approved or not eligible for approval.",
            });
            return;
        }

        const modal = Modal.confirm({
            title: "Confirm Bulk Approval",
            content: `Are you sure you want to approve these ${productsToApprove.length} products?`,
            okText: "Ok",
            cancelText: "No",
            onOk: async () => {
                const successCount = [] as ShipmentProductModel[];
                const failedCount = [] as ShipmentProductModel[];

                await Promise.all(
                    productsToApprove.map(async (product) => {
                        try {
                            await approvedShippingProduct(product.getId());
                            successCount.push(product);
                        } catch (_error) {
                            failedCount.push(product);
                        }
                    }),
                );

                notification["success"]({
                    message: "Bulk Approval Success",
                    description:
                        "All eligible products have been successfully approved.",
                });
                onClearBulkSelectedState();
                modal.destroy();
                await refetchListApi();
            },
            onCancel: () => {
                notification["info"]({
                    message: "Bulk Approval Cancelled",
                    description: "The bulk approval action was cancelled.",
                });
            },
        });
    };

    const handleSingleApprove = (shipmentProduct: ShipmentProductModel) => {
        const modal = Modal.confirm({
            title: "Confirm Approval",
            content: "Are you sure you want to approve this product?",
            okText: "Ok",
            cancelText: "No",
            onOk: async () => {
                try {
                    await approvedShippingProduct(shipmentProduct.getId());
                    notification["success"]({
                        message: "Product Approved",
                        description:
                            "The product has been successfully approved.",
                    });
                    modal.destroy();
                    onClearSingleSelectedState();
                    await refetchListApi();
                } catch (error) {
                    showError(error);
                }
            },
        });
    };

    const handleAttachCartonImage = async () => {
        try {
            const images = form.getFieldValue("images") as string[];
            if (selectedProduct) {
                const payload: IShipmentProductAttachImagesPayload = {
                    images: images,
                    shipment_product_ids: [selectedProduct.getId()],
                    packages: selectedProduct.getPackages().map((pkg) => ({
                        id: pkg.getId(),
                        images: [...images],
                    })),
                };

                await attachShipmentProductPackageImage(payload);
                notification["success"]({
                    message: "Attached carton images successfully.",
                });
                handleModal({
                    action: false,
                });
                onClearSingleSelectedState();
                await refetchListApi();
            } else if (selectedRows.length > 0) {
                const packages = selectedRows
                    .flatMap((row) => row.getPackages())
                    .map((pkg) => ({
                        id: pkg.getId(),
                        images: [
                            ...pkg.getImages().map((img) => img.src),
                            ...images,
                        ],
                    }));
                const payload: IShipmentProductAttachImagesPayload = {
                    images: images,
                    shipment_product_ids: selectedRows.map((row) => row.id),
                    packages: packages,
                };

                await attachShipmentProductPackageImage(payload);
                notification["success"]({
                    message: "Attached carton images successfully.",
                });
                handleModal({
                    action: false,
                });
                await refetchListApi();
            } else {
                throw new Error("No product selected");
            }
        } catch (err) {
            showError(err, form);
        }
    };

    const handleSync = async () => {
        try {
            if (selectedRows.length > 0) {
                const ids = selectedRows.map((row) => row.getId());
                await syncShipmentProducts({
                    ids,
                });
                setSelectedRowKeys([]);
                setSelectedRows([]);
                showSuccessAlert(
                    `Shipment product synced to elastic successfully.`,
                );
                await refetchListApi();
            } else {
                throw new Error("Please Select Rows");
            }
        } catch (error) {
            showError(error);
        }
    };

    // ---------------------------------------UI----------------------------------
    const { getActions: getBulkActions } = useShipmentProductsBulkActions({
        handleModal,
    });
    const { getActions } = useShipmentProductsActions({ handleModal });

    const onHeaderCellStyle: CSSProperties = {
        backgroundColor: isOrderPage ? "white" : "",
    };
    const columns: ColumnsType<ShipmentProductModel> = [
        {
            title: "Product Info",
            dataIndex: "product-details",
            key: "product-details",
            width: 400,
            onHeaderCell: () => {
                return {
                    style: onHeaderCellStyle,
                };
            },
            onCell: () => ({
                style: { verticalAlign: "top", alignItems: "center" },
            }),
            render: (_: string, shipmentProduct) => (
                <>
                    <ShipmentProductInfo
                        handleModal={handleModal}
                        isDetailPage={false}
                        shipmentProductModel={shipmentProduct}
                    />
                </>
            ),
        },
        {
            title: "Cartons",
            key: "cartons",
            width: 400,
            onHeaderCell: () => ({
                style: onHeaderCellStyle,
            }),
            onCell: () => ({
                style: { verticalAlign: "top", alignItems: "center" },
            }),
            render: (_: string, shipmentProduct) => (
                <ShipmentProductPackages
                    isDetailPage={false}
                    shipmentNumber=""
                    customerId={shipmentProduct.getCustomerId()}
                    productSource={shipmentProduct.getProductSource()}
                    productUrl={shipmentProduct.getLink() || ""}
                    regionCode={shipmentProduct.getRegionCode()}
                    shipmentProductId={shipmentProduct.getId()}
                    packages={shipmentProduct.getPackages()}
                    unitType="KG"
                />
            ),
        },
        {
            title: "Shipping & Rate",
            dataIndex: "shipping",
            key: "shipping",
            width: 350,
            onHeaderCell: () => ({
                style: onHeaderCellStyle,
            }),
            onCell: () => ({ style: { verticalAlign: "top" } }),
            render: (_: string, shipmentProduct) => (
                <>
                    <ShipmentProductShippingInfo
                        isDetailPage={false}
                        shipmentProductModel={shipmentProduct}
                        handleModal={handleModal}
                    />
                </>
            ),
        },
        {
            title: "Customer",
            dataIndex: "Customer",
            key: "Customer",
            width: 350,
            onHeaderCell: () => ({
                style: onHeaderCellStyle,
            }),
            onCell: () => ({ style: { verticalAlign: "top" } }),
            render: (_: string, shipmentProduct) => {
                const shipmentProductId = shipmentProduct?.getId();
                const customer = shipmentProduct.user;
                const shippingAgentName = shipmentProduct
                    ?.getAgentAssociation()
                    ?.getAgentCompany()
                    ?.getPrimaryName();

                const orderHandler = shipmentProduct
                    .getOrder()
                    .getOrderHandler();

                return (
                    <div style={{ position: "relative" }}>
                        {/* Top-right corner Impersonate Button */}
                        <div style={{ position: "absolute", top: 0, right: 0 }}>
                            {checkActionPermission(
                                ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_IMPERSONATE_CREATE,
                                <Tooltip title="Click to impersonate this customer">
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            setSelectedProduct(shipmentProduct);
                                            setShouldFetchImpersonateData(true);
                                            setLoadingImpersonate((prev) => ({
                                                ...prev,
                                                [shipmentProductId]: true,
                                            }));
                                            setSelectedCustomerUser(customer);
                                            onFetchFilterApi(
                                                filterResourceEnum.ADMIN,
                                                {
                                                    keyword: email,
                                                    ...responseTypeQuery.minimal,
                                                },
                                            );
                                        }}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            border: "1px solid #FD384F",
                                            borderRadius: "50%",
                                            backgroundColor: "#FFF8F9",
                                        }}
                                        icon={
                                            loadingImpersonate[
                                                shipmentProductId
                                            ] ? (
                                                <LoadingOutlined />
                                            ) : (
                                                <ImpersonateIcon
                                                    width={25}
                                                    height={15}
                                                    style={{ marginTop: -5 }}
                                                />
                                            )
                                        }
                                    />
                                </Tooltip>,
                                null,
                            )}
                        </div>

                        {/* Left content */}
                        <div>
                            <CustomerInfo user={customer} />

                            {orderHandler && (
                                <div style={{ margin: 0 }}>
                                    <p style={{ marginBottom: 0 }}>
                                        <strong> Order Handler Name: </strong>{" "}
                                        <span>{orderHandler?.getName()}</span>{" "}
                                    </p>
                                    <p
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                    >
                                        <strong> Order Handler Email: </strong>{" "}
                                        <span>{orderHandler?.getEmail()}</span>{" "}
                                    </p>
                                </div>
                            )}

                            {shippingAgentName && (
                                <Text>
                                    <Text>Shipping Agent: </Text>
                                    <Text
                                        strong
                                        style={{ maxWidth: 300 }}
                                        ellipsis={{
                                            tooltip: shippingAgentName,
                                        }}
                                    >
                                        {shippingAgentName}
                                    </Text>
                                </Text>
                            )}
                        </div>

                        <div style={{ marginTop: 4 }}>
                            <InvoiceInfoShipmentProduct
                                shipmentProduct={shipmentProduct}
                            />
                        </div>
                    </div>
                );
            },
        },
        {
            ...actionsProps,
            fixed: "right",
            onHeaderCell: () => ({
                style: onHeaderCellStyle,
            }),
            hidden: !checkActionPermission(
                [
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ASSIGN_DEFAULT,
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_APPROVE_SHIPMENT_PRODUCT,
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_REJECT_SHIPMENT_PRODUCT,
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_ATTACH_IMAGES,
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_MANAGE_BID,
                    ADMIN_SHIPPING_PERMISSION_ENUM.ADMIN_SHIPMENT_PRODUCT_CHANGE_RATE,
                ],
                "checkActionPermission",
                null,
            ),
            render: (_: string, shipmentProduct) => (
                <Dropdown
                    menu={{
                        items: getActions(shipmentProduct).filter((x) =>
                            checkActionPermission(x.permission, x, null),
                        ),
                    }}
                >
                    <Button type="primary" icon={<HddFilled />} />
                </Dropdown>
            ),
        },
    ];

    return (
        <div>
            {!isOrderPage && (
                <Flex
                    vertical
                    gap="middle"
                    style={{
                        marginTop: 32,
                    }}
                >
                    {selectedRows.length > 0 && (
                        <ShipmentProductTableSummary
                            selectedRows={selectedRows}
                            onClear={() => {
                                onClearSingleSelectedState();
                                onClearBulkSelectedState();
                            }}
                        />
                    )}
                    <div style={{ gap: 10 }}>
                        <Dropdown
                            menu={{
                                items: getBulkActions(selectedRows),
                            }}
                            disabled={selectedRowKeys.length === 0}
                        >
                            <Button
                                disabled={selectedRowKeys.length === 0}
                                style={{
                                    border: tw.border.none,
                                    boxShadow: tw.boxShadow.DEFAULT,
                                }}
                            >
                                Bulk Actions <HddFilled />
                            </Button>
                        </Dropdown>

                        <Button
                            style={{ padding: 0, marginLeft: 10 }}
                            type="link"
                            htmlType="button"
                            disabled={selectedRowKeys.length === 0}
                            icon={<SyncOutlined spin={isLoadingSync} />}
                            onClick={() => handleSync()}
                        >
                            Sync
                        </Button>
                    </div>
                </Flex>
            )}

            <div>
                <Table
                    loading={isLoading}
                    dataSource={data}
                    columns={columns}
                    rowHoverable={false}
                    rowKey={(r) => r.id}
                    scroll={{ x: 1600 }} // Changed to accommodate total column widths
                    style={{
                        backgroundColor: "white",
                        boxShadow: tw.boxShadow.md,
                        marginTop: 24,
                        width: "100%", // Ensure table takes full container width
                    }}
                    bordered
                    pagination={
                        isOrderPage
                            ? false
                            : {
                                  ...paginationConfig,
                                  showTotal: (total) => (
                                      <div>
                                          Total{" "}
                                          <span
                                              style={{
                                                  fontWeight: "bold",
                                                  fontSize: "18px",
                                                  color: "green",
                                              }}
                                          >
                                              {total}
                                          </span>{" "}
                                          Items
                                      </div>
                                  ),
                              }
                    }
                    rowSelection={
                        isOrderPage
                            ? undefined
                            : {
                                  selectedRowKeys: selectedRowKeys,
                                  type: "checkbox",
                                  onChange: (selectedRowKeys, selectedRows) => {
                                      const productIds =
                                          selectedRowKeys.map(Number);
                                      setSelectedRowKeys(productIds);
                                      setSelectedRows(selectedRows);
                                  },
                              }
                    }
                />
            </div>

            {categoryPriceDetailsInfo && (
                <SelectWarehouseModal
                    categoryPriceDetails={categoryPriceDetailsInfo}
                    selectedProductIds={
                        selectedProduct
                            ? [selectedProduct.getId()]
                            : selectedRowKeys
                    }
                    selectedProducts={
                        selectedProduct ? [selectedProduct] : selectedRows
                    }
                    onCancel={
                        selectedProduct
                            ? onClearSingleSelectedState
                            : onClearBulkSelectedState
                    }
                    open={modalOpen === "assignAgent"}
                />
            )}

            <Modal
                width={"90%"}
                open={modalOpenType === "bid_list"}
                onCancel={onClearSingleSelectedState}
                footer={null}
                destroyOnClose={true}
                title={"Bid Lists"}
            >
                {selectedProduct && (
                    <BidList shipmentProductModel={selectedProduct} />
                )}
            </Modal>

            <Modal
                width={"40%"}
                open={modalOpenType === "reject_shipment_product"}
                onCancel={onClearSingleSelectedState}
                footer={null}
                destroyOnClose={true}
                title={"Confirm Reject"}
            >
                {selectedProduct && (
                    <RejectProductModal
                        onClose={onClearSingleSelectedState}
                        handleOk={handleSingleRejectProduct}
                        loading={isRejectedLoading}
                    />
                )}
            </Modal>

            <ShippingInfoChangeModals
                modalOpenType={modalOpenType}
                onCancel={onCancel}
                selectedProduct={undefined}
                selectedProductIds={selectedRowKeys}
            />

            <Modal
                title="Attach Image"
                open={modalOpenType === "attach_images_shipment_product"}
                centered
                destroyOnClose={true}
                okText="Attach"
                onOk={handleAttachCartonImage}
                onCancel={onCancel}
                okButtonProps={{
                    loading: isLoadingAttachShipmentProductPackageImage,
                }}
            >
                <MultiChunkUpload fieldName="images" form={form} />
            </Modal>
            <Modal
                title="Agent Shipping Rate Update"
                open={
                    modalOpenType === "shipment_product_change_rate" ||
                    modalOpenType === "bulk_shipment_product_change_rate"
                }
                destroyOnClose={true}
                onCancel={onCancel}
                footer={false}
            >
                {(modalOpenType === "shipment_product_change_rate" &&
                    selectedProduct) ||
                (modalOpenType === "bulk_shipment_product_change_rate" &&
                    selectedRows.length > 0) ? (
                    <Suspense fallback={<Spin />}>
                        <ShipmentProductChangeShippingRate
                            handleModal={handleModal}
                            selectedProduct={
                                modalOpenType === "shipment_product_change_rate"
                                    ? selectedProduct
                                    : undefined
                            }
                            selectedRows={
                                modalOpenType ===
                                "bulk_shipment_product_change_rate"
                                    ? selectedRows
                                    : undefined
                            }
                            isBulk={
                                modalOpenType ===
                                "bulk_shipment_product_change_rate"
                            }
                        />
                    </Suspense>
                ) : null}
            </Modal>
            <Modal
                title="Customer Rate Update"
                open={modalOpenType === "shipment_product_change_customer_rate"}
                destroyOnClose={true}
                onCancel={onCancel}
                footer={false}
            >
                {selectedProduct &&
                    modalOpenType ===
                        "shipment_product_change_customer_rate" && (
                        <Suspense fallback={<Spin />}>
                            <ShipmentProductChangeCustomerRate
                                handleModal={handleModal}
                                selectedProduct={selectedProduct}
                            />
                        </Suspense>
                    )}
            </Modal>

            {selectedCustomerUser && impersonateData && (
                <Modal
                    title={
                        <ImpersonateCreateModalTitle
                            name={selectedCustomerUser?.name || ""}
                            email={selectedCustomerUser?.email || ""}
                            title="Impersonate Customer"
                        />
                    }
                    visible={isShowModal}
                    onCancel={() => setIsShowModal(false)}
                    footer={null}
                    width={600}
                >
                    <ImpersonateResultView response={impersonateData} />
                </Modal>
            )}

            <Modal
                width={"60%"}
                title="Pay Customer Invoice"
                open={Boolean(selectedPayInvoiceData)}
                onCancel={() => setSelectedPayInvoiceData(undefined)}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoadingPaySession }}
                centered
                destroyOnClose={true}
            >
                {selectedPayInvoiceData && (
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                        isCheckShipping={false}
                    />
                )}
            </Modal>
        </div>
    );
};

export default ShipmentProductsTable;
//
