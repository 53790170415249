import { StatusTag } from "@/components";
import { formatDateTime } from "@/utils/helpers";
import { Space, Typography } from "antd";
const { Text, Paragraph } = Typography;

interface Props {
    oderNumber: string | undefined;
    oderType: string | undefined;
    oderStatus: string | undefined;
    orderCreatedAt: string | undefined;
    paymentStatus: string | undefined;
    orderTag?: string | null;
    note?: string | null;
}

const OrderInfo = ({
    orderTag,
    paymentStatus,
    oderType,
    orderCreatedAt,
    oderNumber,
    oderStatus,
    note,
}: Props) => {
    return (
        <Space direction="vertical" size={"small"}>
            <Paragraph>
                <Text>Order No: </Text>
                <Text strong copyable>
                    {oderNumber}
                </Text>
            </Paragraph>

            {oderStatus && (
                <Paragraph>
                    <Text> Order Status: </Text>
                    <StatusTag slug={oderStatus} text={oderStatus} />
                </Paragraph>
            )}
            {paymentStatus && (
                <Paragraph>
                    <Text> Payment Status: </Text>
                    <StatusTag slug={paymentStatus} text={paymentStatus} />
                </Paragraph>
            )}

            {oderType && (
                <Paragraph>
                    <Text>Type: </Text>
                    <StatusTag slug={oderType} text={oderType} />
                </Paragraph>
            )}

            <Paragraph>
                <Text>Created At: </Text>
                <Text strong>{formatDateTime(orderCreatedAt)}</Text>
            </Paragraph>

            {orderTag && (
                <Paragraph>
                    <Text> Order Tag: </Text>
                    <Text strong> {orderTag} </Text>
                </Paragraph>
            )}
            {note && (
                <Paragraph>
                    <Text>Note: </Text>
                    <Text strong>{note}</Text>
                </Paragraph>
            )}
        </Space>
    );
};

export default OrderInfo;
