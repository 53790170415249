import { ITranslationEntry } from "../lang";

export const TL: ITranslationEntry = {
    translations: {
        notifications: "Notipikasyon",
        markAllAsRead: "Markahan ang lahat bilang nabasa",
        poweredBy: "Pinagagana ng",
        settings: "Settings",
        noNewNotification: "Wala pang bagong makikita dito",
    },
    lang: "tl",
};
