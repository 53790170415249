import { IFilterType } from "@/types/filters";

import {
    IWarehouseShipment,
    IWarehouseShipmentCollection,
} from "src/types/warehouseShipmentCollection";
import { PaginationModel } from "./paginationModel";

export class WarehouseShipmentModel {
    object: string;
    id: string;
    shipment_number: string;
    waybill_number: string;
    estimated_fly_time: string;
    created_at: string;

    constructor(data: IWarehouseShipment) {
        this.object = data.object;
        this.id = data.id;
        this.shipment_number = data.shipment_number;
        this.waybill_number = data.waybill_number;
        this.estimated_fly_time = data.estimated_fly_time;
        this.created_at = data.created_at;
    }

    getId() {
        return this.id;
    }
    getShipmentNumber() {
        return this.shipment_number;
    }
    getWaybillNumber() {
        return this.waybill_number;
    }
    getEstimatedFlyTime() {
        return this.estimated_fly_time;
    }
    getCreatedAt = () => this.created_at;
}

export class WarehouseShipmentCollectionModel {
    object: string;
    data: WarehouseShipmentModel[];
    pagination: PaginationModel;
    filters: IFilterType;
    constructor(data: IWarehouseShipmentCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new WarehouseShipmentModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData() {
        return this.data;
    }
    getPagination() {
        return this.pagination;
    }
    getFilters = () => this.filters;
}
