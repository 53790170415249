const LogoIcon = ({ size = 0, color = "currentColor", ...props }) => {
    return (
        <svg
            width={size}
            height={size}
            fill={color}
            {...props}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_19222_29306)">
                <path
                    d="M249.965 449.93C360.403 449.93 449.93 360.403 449.93 249.965C449.93 139.527 360.403 50 249.965 50C139.527 50 50 139.527 50 249.965C50 360.403 139.527 449.93 249.965 449.93Z"
                    fill="#00893C"
                />
                <path
                    d="M249.965 246.618L132.91 362.623V264.669C132.91 258.957 134.041 253.302 136.239 248.03C138.437 242.758 141.657 237.974 145.714 233.954L241.778 138.729C242.457 138.072 243.314 137.628 244.242 137.453C245.171 137.278 246.13 137.379 247.002 137.744C247.873 138.109 248.619 138.722 249.145 139.506C249.672 140.291 249.957 141.212 249.965 142.157V246.618Z"
                    fill="white"
                />
                <path
                    d="M354 259.911L249.959 362.623V246.618L358.828 138.729C359.507 138.072 360.363 137.628 361.292 137.453C362.22 137.278 363.18 137.379 364.051 137.744C364.923 138.109 365.668 138.722 366.195 139.506C366.721 140.291 367.006 141.212 367.014 142.157V228.286C367.061 234.159 365.934 239.981 363.699 245.412C361.464 250.843 358.167 255.773 354 259.911Z"
                    fill="white"
                />
                <path
                    d="M318.534 357.856H354.007C355.437 357.875 356.855 357.607 358.18 357.069C359.504 356.531 360.707 355.733 361.718 354.722C362.729 353.711 363.527 352.508 364.065 351.184C364.603 349.859 364.871 348.441 364.852 347.011V315.316L318.534 357.856Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_19222_29306">
                    <rect
                        width="400"
                        height="400"
                        fill="white"
                        transform="translate(50 50)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default LogoIcon;
