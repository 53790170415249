import { useEffect } from "react";
import { useAtom } from "jotai";
import { storeSingleExchangeAtom } from "@/lib/core-react/store/store";
import { useGetExchangeById } from "@/lib/core-react/hooks/private/useExchange";
import { ExchangeListModel } from "@/models/exchangeListCollectionModel";
import { Store } from "antd/es/form/interface";
import CreateUpdateExchange from "../components/CreateUpdateExchange";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import CommonError from "@/components/Error/CommonError";

const UpdateStoreExchange = () => {
    const { getExchangeById } = useGetExchangeById();
    const [
        {
            data: storeSingleExchangeDataAtom,
            isLoading: storeSingleExchangeLoading,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(storeSingleExchangeAtom);
    const { id: listId } = useParams();

    const getExchangeList = async () => {
        getExchangeById(Number(listId));
    };

    useEffect(() => {
        if (listId && !isNaN(Number(listId))) {
            getExchangeList();
        }
    }, [listId]);

    const singleExchangeModel = storeSingleExchangeDataAtom
        ? new ExchangeListModel(storeSingleExchangeDataAtom)
        : undefined;

    if (storeSingleExchangeLoading) {
        return (
            <div style={{ padding: "6px 16px" }}>
                <Skeleton active />
            </div>
        );
    }

    // Error handling
    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    if (singleExchangeModel) {
        const data: Store = {};
        // Is default
        data["is_default"] = singleExchangeModel.getIsDefault() ? true : false;

        // name
        data["name"] = singleExchangeModel.getName();

        // regional_exchanges
        const regionalExchanges = singleExchangeModel
            .getRegionalExchanges()
            .getData()
            .map((item) => {
                return {
                    region_id: item.getRegion().getId(),
                    regional_exchange_id: item.getId(),
                    store_exchanges: item
                        .getStoreExchanges()
                        .getData()
                        .map((ex) => {
                            return {
                                commission_rate: ex.getCommissionRate(),
                                commission_rate_type:
                                    ex.getCommissionRateType(),
                                fx_rate: ex.getFxRate(),
                                store_id: ex.getStore().getId(),
                                options: ex.getOptions(),
                                store_exchange_id: ex.getId(),
                            };
                        }),
                };
            });
        data["regional_exchanges"] = regionalExchanges;
        return (
            <CreateUpdateExchange
                title="Update an Exchange List"
                initialValues={data}
                action="update"
                exchangeId={singleExchangeModel.getId()}
            />
        );
    }

    return null;
};

export default UpdateStoreExchange;
