import { IPagination } from "@/types/pagination";
import {
    IShipmentProductImage,
    IShipmentProductImageCollection,
} from "@/types/shipmentProductImageCollection";

export class ShipmentProductImageModel {
    object: string;
    title: string;
    id: number;
    alt: string;
    src: string;
    display_order: number;

    constructor(data: IShipmentProductImage) {
        this.object = data?.object;
        this.title = data?.title;
        this.id = data?.id;
        this.alt = data?.alt;
        this.src = data?.src;
        this.display_order = data?.display_order;
    }
}

export class ShipmentProductImageCollectionModel {
    object: string;
    data: ShipmentProductImageModel[];
    pagination?: IPagination;

    constructor(data: IShipmentProductImageCollection) {
        this.object = data?.object;
        this.pagination = data?.pagination;
        this.data = data?.data?.map(
            (item) => new ShipmentProductImageModel(item),
        );
    }
}
