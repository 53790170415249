import { PageHeader } from "@ant-design/pro-layout";
import { Col, Row, Skeleton } from "antd";
import { useAtom } from "jotai";
import { walletOverviewCollectionAtom } from "@/lib/core-react/store/store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useUserWalletOverviewBalance } from "@/lib/core-react/hooks/private/useWallet";
import { WalletOverviewModel } from "@/models/walletOverviewCollectionModel";
import { useNavigate, useParams } from "react-router-dom";
import WalletCard from "../WalletCard";
import WalletTable from "./components/WalletTable";
import CommonError from "@/components/Error/CommonError";

//     const { getUserWalletOverviewBalance } = useUserWalletOverviewBalance();
const WalletOverviewForUser = () => {
    const { getUserWalletOverviewBalance } = useUserWalletOverviewBalance();
    const { id } = useParams();
    const navigate = useNavigate();

    const [
        { data: walletOverviewData, isLoading, error, code, unAuthorized },
        setWalletOverviewBalance,
    ] = useAtom(walletOverviewCollectionAtom);
    const [walletId, setWalletId] = useState<number | undefined>(undefined);
    const [currencyCode, setCurrencyCode] = useState<string | undefined>(
        undefined,
    );
    const latestWalletIdRef = useRef(walletId);

    // Effect to fetch wallet overview balance when component mounts or when `id` changes
    useEffect(() => {
        if (id) {
            setWalletOverviewBalance((prev) => ({ ...prev, data: undefined }));
            setWalletId(undefined); // Reset walletId when id changes
            getUserWalletOverviewBalance(Number(id));
        }
    }, [id]);

    // Memoized function to update wallet ID based on fetched wallet data
    const updateWalletId = useCallback(() => {
        if (walletOverviewData?.available?.data[0]?.id) {
            setWalletId(walletOverviewData.available.data[0].id);
            latestWalletIdRef.current = walletOverviewData.available.data[0].id; // Update the ref
            setCurrencyCode(walletOverviewData.available.data[0].currency.code);
        }
    }, [walletOverviewData]);

    useEffect(() => {
        if (
            walletOverviewData &&
            latestWalletIdRef.current !==
                walletOverviewData?.available?.data[0]?.id
        ) {
            updateWalletId();
        }
    }, [walletOverviewData, updateWalletId]);

    const walletOverviewCollectionData = useMemo(
        () => walletOverviewData && new WalletOverviewModel(walletOverviewData),
        [walletOverviewData],
    );

    const currencyData = useMemo(
        () => walletOverviewCollectionData?.getByCurrency(),
        [walletOverviewCollectionData],
    );

    // Memoize the currencies list to prevent unnecessary re-renders
    const currencies = useMemo(
        () =>
            Object.entries(currencyData || {}).map(([currencyCode, data]) => ({
                code: currencyCode,
                totalAvailableBalance: data.totalAvailableBalance,
                totalReservedBalance: data.totalReservedBalance,
                totalPendingBalance: data.totalPendingBalance,
                symbol: data.symbol,
                walletId: data.walletIds[0],
            })),
        [currencyData],
    );

    // Handle wallet balance card click
    const handleWalletBalanceClick = (id: number) => {
        setWalletId(id);
        latestWalletIdRef.current = id; // Update the ref
    };

    // Handle back navigation
    const handleBack = () => {
        setWalletOverviewBalance((prev) => ({ ...prev, data: undefined }));
        setWalletId(undefined);
        navigate("/customer/users");
    };

    // if (!isLoading && error) {
    //     return (
    //         <>
    //             <Result
    //                 status="404"
    //                 title="404"
    //                 subTitle={"Page not found"}
    //                 extra={
    //                     <Button
    //                         onClick={handleBack}
    //                         type="primary"
    //                     >
    //                         Back Home
    //                     </Button>
    //                 }
    //             />
    //         </>
    //     );
    // }

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <PageHeader
            ghost={false}
            title="User Wallet"
            style={{ marginTop: "10px" }}
            onBack={() => {
                handleBack();
                setWalletId(undefined);
            }}
        >
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Row>
                    <WalletCard
                        walletId={walletId}
                        handleWalletBalanceClick={handleWalletBalanceClick}
                        key={walletId}
                        item={currencies}
                    />
                </Row>
            )}
            {isLoading ? (
                <Skeleton active />
            ) : (
                <Row>
                    <Col span={24}>
                        {walletId && (
                            <WalletTable
                                key={walletId}
                                isLoading={false}
                                walletId={walletId}
                                currencyCode={currencyCode}
                            />
                        )}
                    </Col>
                </Row>
            )}
        </PageHeader>
    );
};

export default WalletOverviewForUser;
