import React from "react";
import { Table, Typography, Tooltip, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { IShipmentProductPackage } from "@/types/shipmentProductCollection";
import { CurrencyModel } from "@/models/currencyCollectionModel";

const { Paragraph, Text } = Typography;

const PackageColumns: React.FC<{
    dataSource: IShipmentProductPackage[];
    currency: CurrencyModel | undefined;
}> = ({ dataSource, currency }) => {
    const columns: ColumnsType<any> = [
        {
            title: "Information",
            dataIndex: "package_number",
            key: "package_number",
            render: (
                _package_number: number,
                record: IShipmentProductPackage,
            ) => (
                <>
                    <Paragraph>
                        <Tooltip title="Package Number">
                            <Text>P/N : </Text>
                        </Tooltip>
                        <Text strong copyable>
                            {record.package_number}
                        </Text>
                    </Paragraph>
                    {record.carton_number && (
                        <Paragraph>
                            <Tooltip title="Cart Number">
                                <Text>C/N : </Text>
                            </Tooltip>
                            <Text strong copyable>
                                {record.carton_number}
                            </Text>
                        </Paragraph>
                    )}
                    <Paragraph>
                        <Text>Product Price : </Text>
                        <Text strong>
                            {record.product_price} {currency?.code}
                        </Text>
                    </Paragraph>
                    <Paragraph>
                        <Text>Quantity : </Text>
                        <Text strong>{record.quantity}</Text>
                    </Paragraph>
                    <Paragraph>
                        <Text>Weight : </Text>
                        <Text strong>{record.weight}/kg</Text>
                    </Paragraph>
                    <Paragraph>
                        <Text>CBM : </Text>
                        <Text strong>{record.cbm}</Text>
                    </Paragraph>

                    {record.hs_code && (
                        <div style={{ marginBottom: 5 }}>
                            <Paragraph>
                                <Text>Hs Code : </Text>
                                <Tag color="blue">{record.hs_code}</Tag>
                            </Paragraph>
                        </div>
                    )}

                    {record.contains && (
                        <Paragraph>
                            <Text>Contain : </Text>
                            <Tag color="Green">{record.contains}</Tag>
                        </Paragraph>
                    )}

                    {record.contains_2 && (
                        <Paragraph>
                            <Text>Contain 2: </Text>
                            <Tag color="Green">{record.contains_2}</Tag>
                        </Paragraph>
                    )}
                    <div style={{ marginTop: 5 }}>
                        <Paragraph>
                            <Text>Status: </Text>
                            <Tag color="blue">{record.fulfillment_status}</Tag>
                        </Paragraph>
                    </div>
                </>
            ),
        },
        {
            title: "Dimensions (L×W×H)",
            key: "dimensions",
            render: (_, record) =>
                `${record.length} × ${record.width} × ${record.height} cm`,
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            rowKey="id"
            pagination={false}
            style={{ marginTop: "16px" }}
            scroll={{ y: 300 }}
        />
    );
};

export default PackageColumns;
