import { Button, Card, Form, notification, Switch } from "antd";
import VariantWithPropertiesPart from "../VariantPart/VariantWithPropertiesPart";
import VariantWithOutPropertiesPart from "../VariantPart/VariantWithOutPropertiesPart";
import { useState } from "react";
interface IProps {
    formSubmit: (values: any) => void;
    initialData: any;
    current: number;
    prev: () => void;
    isLoading: boolean;
}
const VariationDetailsForm = ({
    formSubmit,
    initialData,
    current,
    prev,
    isLoading,
}: IProps) => {
    const [form] = Form.useForm();

    // Variation with Properties
    const variationWithProperties = initialData?.var_with_property_info;
    const variationWithPropertiesVariationsData = initialData?.variants;

    const isVariationsWithPropertiesFound =
        Array.isArray(variationWithProperties) &&
        variationWithProperties.length > 0 &&
        Array.isArray(variationWithPropertiesVariationsData) &&
        variationWithPropertiesVariationsData.length > 0;

    // Variation with Properties
    const variationWithoutProperties = initialData?.var_without_property_info;
    const isVariationWithoutProperties =
        Array.isArray(variationWithoutProperties) &&
        variationWithoutProperties.length > 0;

    const [showWithProperties, setShowWithProperties] = useState(
        isVariationsWithPropertiesFound && isVariationWithoutProperties
            ? true
            : isVariationsWithPropertiesFound
              ? true
              : isVariationWithoutProperties
                ? false
                : true,
    );

    const onFinish = (value) => {
        // Variation with Properties
        const variationWithProperties = value?.var_with_property_info;
        const variationWithPropertiesVariationsData = value?.variants;

        const isVariationsWithPropertiesFound =
            Array.isArray(variationWithProperties) &&
            variationWithProperties.length > 0 &&
            Array.isArray(variationWithPropertiesVariationsData) &&
            variationWithPropertiesVariationsData.length > 0;

        // Variation with Properties
        const variationWithoutProperties = value?.var_without_property_info;
        const isVariationWithoutProperties =
            Array.isArray(variationWithoutProperties) &&
            variationWithoutProperties.length > 0;

        //  If Both are not found
        if (isVariationsWithPropertiesFound && isVariationWithoutProperties) {
            notification.error({
                message: "No variants selected",
            });
        }
        // If Variation with Properties Found
        else if (isVariationsWithPropertiesFound) {
            const variantsArray = [...variationWithPropertiesVariationsData];
            const data = {
                variants: [...variantsArray],
                var_with_property_info: variationWithProperties,
                var_without_property_info: undefined,
            };
            formSubmit(data);
        } else if (isVariationWithoutProperties) {
            const updatedVariations = variationWithoutProperties.map((item) => {
                return {
                    ...item,
                    properties: [],
                };
            });
            const data = {
                variants: [...updatedVariations],
                var_without_property_info: updatedVariations,
                var_with_property_info: undefined,
            };
            formSubmit(data);
        } else {
            notification.error({
                message: "No variants selected",
            });
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialData}
        >
            <Card
                title={
                    <div
                        style={{
                            display: "flex",
                            gap: 20,
                            alignItems: "center",
                        }}
                    >
                        <Switch
                            checked={showWithProperties}
                            onChange={() => {
                                setShowWithProperties((pre) => !pre);
                                form.setFieldsValue({
                                    variants: [],
                                    var_with_property_info: undefined,
                                    var_without_property_info: undefined,
                                });
                            }}
                            checkedChildren="With Properties"
                            unCheckedChildren="Without Properties"
                        />
                    </div>
                }
            >
                {showWithProperties ? (
                    <VariantWithPropertiesPart
                        initialData={initialData}
                        form={form}
                    />
                ) : (
                    <VariantWithOutPropertiesPart
                        initialData={initialData}
                        form={form}
                    />
                )}
            </Card>

            <Form.Item
                style={{
                    marginTop: "40px",
                }}
            >
                {current > 0 && (
                    <Button
                        style={{
                            margin: "0 8px",
                        }}
                        onClick={() => prev()}
                        loading={isLoading}
                    >
                        Previous
                    </Button>
                )}
                <Button loading={isLoading} type="primary" htmlType="submit">
                    Save and Continue
                </Button>
            </Form.Item>
        </Form>
    );
};

export default VariationDetailsForm;
