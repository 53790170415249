import React from "react";
import { Tag } from "antd";
import { useAtom } from "jotai/index";
import { impersonateSessionsCreate } from "../../lib/core-react/store/store";

interface ImpersonateFormModalTitleProps {
    name: string;
    email: string;
    title?: string;
}
const ImpersonateCreateModalTitle: React.FC<ImpersonateFormModalTitleProps> = (
    props,
) => {
    const [sessionData] = useAtom(impersonateSessionsCreate);

    return (
        <div>
            <p style={{ marginBottom: 5 }}>
                {" "}
                {sessionData?.data
                    ? sessionData.data?.message
                    : props.title
                      ? props.title
                      : "Create Impersonate"}{" "}
            </p>
            <Tag color="cyan">
                {props.name} <small>({props.email})</small>
            </Tag>
        </div>
    );
};

export default ImpersonateCreateModalTitle;
