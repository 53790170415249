import BaseResource from "../public/baseResource";
import { ResponsePromise } from "@/lib/core/request/types/typings";

class GenericResource extends BaseResource {
    path = `/api/generic/admin/support/v1`;

    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/support-inquiries${
            params ? `?${params}` : ""
        }`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default GenericResource;
