import { Card, Checkbox, Col, Flex, Popover, Row, Tag, Typography } from "antd";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { tw } from "@/consts/theme/tailwindTheme";
import { Link } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import Ratings from "./SearchBox/ratings";
import usePageQuery from "@/helpers/usePageQuery";
import { parseObjectToString } from "@/helpers/stringify";
import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";

const { Meta } = Card;
const { Text, Paragraph } = Typography;

interface Props {
    isChecked?: boolean;
    onSelect: (id: CheckboxChangeEvent, productId: string) => void;
    selectable?: boolean;
    imageUrl: string;
    title: string;
    ratings: number;
    ratingCount: string;
    sales: string;
    originalPrice: number;
    discountPrice: number;
    currencyCode?: string;
    currencySymbol: string;
    productId: string;
    isSelectable: boolean;
    grossWeight?: string;
    UnitWeight?: string;
    categoryName?: string;
    shippingCategoryName?: string;
}

const ProductCard = ({
    imageUrl,
    title,
    ratings,
    ratingCount,
    sales,
    discountPrice,
    originalPrice,
    currencySymbol,
    productId,
    onSelect,
    isSelectable,
    isChecked,
    UnitWeight,
    grossWeight,
    shippingCategoryName = "",
}: Props) => {
    const { getCurrentQuery } = usePageQuery();
    const { per_page, page, ...rest } = getCurrentQuery();
    return (
        <>
            <Card
                style={{
                    boxShadow: tw.boxShadow.md,
                    width: "100%",
                    maxWidth: 300,
                }}
                cover={
                    <ImageWithPlaceholder
                        style={{
                            height: 200,
                            objectFit: "cover",
                            objectPosition: "center",
                        }}
                        src={imageUrl}
                        alt="product image"
                    />
                }
                actions={[
                    <Link
                        to={`product-detail?productId=${productId}&${parseObjectToString(rest)}`}
                        style={{
                            width: "100%",
                            height: "100%",
                        }}
                        target="_blank"
                    >
                        View Details
                    </Link>,
                ]}
            >
                <label
                    htmlFor={productId}
                    style={{
                        cursor: isSelectable ? "grab" : "initial",
                    }}
                >
                    <Meta
                        title={
                            <>
                                <Popover content={title}>
                                    {title || <div>-</div>}
                                </Popover>
                            </>
                        }
                        description={
                            <>
                                {shippingCategoryName ? (
                                    <Tag color="green">
                                        {shippingCategoryName}
                                    </Tag>
                                ) : (
                                    <></>
                                )}

                                <Row align={"middle"} justify={"space-between"}>
                                    <Col>
                                        <Flex gap={3}>
                                            <Ratings
                                                rating={ratings}
                                                width={12}
                                            />
                                            <Text>{ratings}</Text>
                                            <Text>({ratingCount})</Text>
                                        </Flex>
                                    </Col>
                                    <Col>
                                        <Paragraph>
                                            <Text>{sales} Sold</Text>
                                        </Paragraph>
                                    </Col>
                                </Row>

                                <Row align={"middle"} justify={"space-between"}>
                                    <Col>
                                        <Typography>
                                            <Paragraph strong>
                                                <Text
                                                    style={{
                                                        paddingRight:
                                                            tw.spacing["0.5"],
                                                        fontSize: tw.spacing[5],
                                                    }}
                                                >
                                                    {currencySymbol}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: tw.spacing[5],
                                                    }}
                                                >
                                                    {roundToTwoDecimal(
                                                        discountPrice,
                                                    )}
                                                </Text>
                                            </Paragraph>

                                            <Paragraph>
                                                <Text
                                                    style={{
                                                        paddingRight:
                                                            tw.spacing["0.5"],
                                                        lineHeight: 0,
                                                        color: tw.colors
                                                            .gray[900],
                                                    }}
                                                >
                                                    original:
                                                </Text>

                                                <Text
                                                    strong
                                                    style={{
                                                        paddingRight:
                                                            tw.spacing["0.5"],
                                                        lineHeight: 0,
                                                        color: tw.colors
                                                            .gray[900],
                                                    }}
                                                >
                                                    {currencySymbol}
                                                </Text>
                                                <Text
                                                    strong
                                                    style={{
                                                        lineHeight: 0,
                                                        color: tw.colors
                                                            .gray[900],
                                                    }}
                                                >
                                                    {roundToTwoDecimal(
                                                        originalPrice,
                                                    )}
                                                </Text>
                                            </Paragraph>
                                        </Typography>
                                    </Col>
                                    <Col>
                                        <>
                                            <Flex
                                                vertical
                                                gap={4}
                                                justify="end"
                                            >
                                                {grossWeight ? (
                                                    <Tag color="blue">
                                                        G: {grossWeight}
                                                    </Tag>
                                                ) : (
                                                    ""
                                                )}
                                                {grossWeight ? (
                                                    <Tag color="orange">
                                                        U: {UnitWeight}
                                                    </Tag>
                                                ) : (
                                                    ""
                                                )}
                                            </Flex>
                                        </>
                                    </Col>
                                </Row>
                            </>
                        }
                    />
                </label>

                {isSelectable && (
                    <label
                        htmlFor={productId}
                        style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: 100,
                            height: 100,
                            paddingTop: 10,
                            paddingRight: 14,
                            display: "flex",
                            flex: tw.flex.auto,
                            alignItems: "start",
                            justifyContent: "end",
                            cursor: "grab",
                            background:
                                "radial-gradient(circle 100px at 80% 20%, rgba(2,0,36,0.1881127450980392) 0%, rgba(180,180,180,0) 60%)",
                        }}
                    >
                        <Checkbox
                            checked={isChecked}
                            id={productId}
                            onChange={(e) => onSelect(e, productId)}
                        />
                    </label>
                )}
            </Card>
        </>
    );
};

export default ProductCard;
