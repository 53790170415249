import React from "react";

export function Mobile(props: React.ComponentPropsWithoutRef<"svg">) {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M15 22.5H15.01M8.75 2.5H21.25C22.6307 2.5 23.75 3.61929 23.75 5V25C23.75 26.3807 22.6307 27.5 21.25 27.5H8.75C7.36929 27.5 6.25 26.3807 6.25 25V5C6.25 3.61929 7.36929 2.5 8.75 2.5Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
