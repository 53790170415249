import { Table } from "antd";
import {
    AgentCategoryPriceReadCollectionModel,
    AgentCategoryPriceReadModel,
} from "@/models/agentCategoryPriceReadCollectionModel";
import { PaginationModel } from "@/models/pagination";
import useDataFilters from "@/hooks/useDataFilters";
import { ApiHelperModel } from "@/models/apiHelper";
import { ICategoryPriceRequestParams } from "@/types/agentCategoryPriceReadCollection";
import FiltersComponent from "@/components/FiltersComponent";
import { useEffect } from "react";
import { IFilterType } from "@/types/filters";

interface ICategoryPriceReadTableProps {
    data: AgentCategoryPriceReadCollectionModel | undefined;
    isLoading: boolean;
    getCategoryPrices: (
        params: ICategoryPriceRequestParams | undefined,
    ) => Promise<void>;
    categoryPriceReadParams: ICategoryPriceRequestParams | undefined;
}

const columns = [
    {
        title: "Shipping Company Details",
        dataIndex: "company_details",
        key: "company_details",
        render: (_: any, record: AgentCategoryPriceReadModel) => (
            <div>
                <div>{record.getAgentWarehouse().getCompanyName()}</div>
                <div>{record.getAgentWarehouse().getName()}</div>
            </div>
        ),
    },
    {
        title: "Shipping Mode",
        dataIndex: "shipping_mode",
        key: "shipping_mode",
        render: (_: any, record: AgentCategoryPriceReadModel) => (
            <span style={{ textTransform: "capitalize" }}>
                {record.getShippingMode()}
            </span>
        ),
    },
    {
        title: "Shipping Type",
        dataIndex: "shipping_type",
        key: "shipping_type",
        render: (_: any, record: AgentCategoryPriceReadModel) => (
            <span style={{ textTransform: "capitalize" }}>
                {record.getShippingType()}
            </span>
        ),
    },
    {
        title: "Expected Time",
        dataIndex: "shipping_time",
        key: "shipping_time",
        render: (_: any, record: AgentCategoryPriceReadModel) =>
            `${record.getMinShippingTime()}-${record.getMaxShippingTime()} ${record.getShippingTimeUnit()}`,
    },
    {
        title: "Amount",
        dataIndex: "amount",
        fixed: "right" as const,
        key: "amount",
        render: (_: any, record: AgentCategoryPriceReadModel) =>
            `${Number(record.getTotalRate()).toFixed(2)} /- ${record.getUnitType()}`,
    },
];

const CategoryPriceReadTable = ({
    data,
    isLoading,
    getCategoryPrices,
    categoryPriceReadParams,
}: ICategoryPriceReadTableProps) => {
    const agentCategoryPriceReadData = data && data.getData();
    const filtersData = data && data.getFilters();

    // Filter out only the required filters
    const requiredFilters = filtersData && {
        shipping_mode: filtersData.shipping_mode,
        shipping_type: filtersData.shipping_type,
        unit_type: filtersData.unit_type,
        contains: filtersData.contains,
    };

    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFetched,
        initializeAvailableFilter,
    } = useDataFilters();

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            () => getCategoryPrices(categoryPriceReadParams),
        );
    };

    // Filter Handler
    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, () =>
            getCategoryPrices(categoryPriceReadParams),
        );
    };

    const paginationConfig = PaginationModel.getPaginationConfig(
        data,
        handlePaginationChange,
    );

    useEffect(() => {
        if (!isFetched && requiredFilters) {
            initializeAvailableFilter(requiredFilters as IFilterType);
        }
    }, [isFetched, initializeAvailableFilter, requiredFilters]);

    return (
        <div>
            <Table
                title={() => (
                    <FiltersComponent
                        isLoading={isLoading}
                        handleProductFilter={handleFilter}
                        handleFilterChange={handleFilterChange}
                        handelFilterClear={handelFilterClear}
                        isFetched={isFetched}
                        filters={filters}
                        filtersData={requiredFilters}
                    />
                )}
                dataSource={agentCategoryPriceReadData}
                columns={columns}
                rowKey="id"
                loading={isLoading}
                scroll={{ x: 1000 }}
                pagination={paginationConfig}
            />
        </div>
    );
};

export default CategoryPriceReadTable;
