import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
    Avatar,
    Button,
    Col,
    Form,
    InputNumber,
    Modal,
    Row,
    Table,
    Typography,
    Badge,
    notification,
    Flex,
    Image,
    Alert,
} from "antd";
import { useAtomValue } from "jotai";

import {
    ProductDetailExtendedModel,
    SkuModelExtended,
} from "@/models/productDetailExtendedModel";

import { BuyOrderOperationEnum } from "@/enums/buyOrderCollectionEnums";
import {
    IAddBuyOrderAddProductFormData,
    IBuyOrderQuantityFormData,
} from "@/types/buyOrderDetail";

import {
    currentCurrencyAtom,
    inventoryProductDetailAtom,
} from "@/lib/core-react/store/store";

import {
    CartItemAddPayload,
    IBuyPlaceOrderCartShippingFormData,
    IPlaceBuyOrderProductStepFormData,
} from "@/types/buyOrderPlace";
import { usePlaceOrderCartProduct } from "@/lib/core-react/hooks/private/usePurchaseCart";
import TextArea from "antd/es/input/TextArea";
import { showError } from "@/helpers/showError";
import { showSuccessAlert } from "@/helpers/showSuccess";
import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { ColumnsType } from "antd/es/table";
import { defaultQuery } from "@/consts/defaultQuery";
import { parseObjectToString } from "@/helpers/stringify";
import usePageQuery from "@/helpers/usePageQuery";
import { ShippingMethod } from "./PlaceBuyOrderProductShippingMethodModal";
import { ArrowRightOutlined, EditOutlined } from "@ant-design/icons";
import shippingMethodIcon from "/assets/shipping_method_icon.gif";
import { useBuyOrderAddProduct } from "@/lib/core-react/hooks/private";

const { Text } = Typography;

interface IProps {
    product: ProductDetailExtendedModel;
}
export const ZeroVariant = ({ product }: IProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { getCurrentQuery } = usePageQuery();
    const productDetail = useAtomValue(inventoryProductDetailAtom);
    const { data: currentCurrencyModel } = useAtomValue(currentCurrencyAtom);

    const buyOrderId = new URLSearchParams(location.search).get("buyOrderId");
    const operation = new URLSearchParams(location.search).get("operation");
    const userId = new URLSearchParams(location.search).get("userId");
    const region = new URLSearchParams(location.search).get("region") || "";
    const from = new URLSearchParams(location.search).get("from");
    const variations = product.getVariation();

    const { cartProduct, isLoading } = usePlaceOrderCartProduct();
    const { addProduct: addProductBuyOrder, isLoading: isLoadingAddProduct } =
        useBuyOrderAddProduct();

    const [quantity, setQuantity] = useState<IBuyOrderQuantityFormData[]>([]);

    const [isShippingMethodModalOpen, setIsShippingMethodModalOpen] =
        useState(false);
    // to place new buy order
    const [
        placeBuyOrderProductStep2FormData,
        setPlaceBuyOrderProductStep2FormData,
    ] = useState<IBuyPlaceOrderCartShippingFormData>();

    const [cartNote, setCartNote] = useState<string | "">();
    const handleDecrement = (sku_id: string, original_unit_price: number) => {
        if (!quantity) {
            setQuantity([{ sku_id, quantity: 0, original_unit_price }]);
            return;
        }

        const updatedQuantity = quantity
            .map((item) =>
                item.sku_id === sku_id
                    ? { ...item, quantity: Math.max(item.quantity - 1, 0) }
                    : item,
            )
            .filter((item) => item.quantity > 0);
        setQuantity(updatedQuantity);
    };

    const handleIncrement = (
        sku_id: string,
        original_unit_price: number,
        value?: number | null,
    ) => {
        if (!quantity) {
            setQuantity([
                { sku_id, quantity: value ? value : 1, original_unit_price },
            ]);
            return;
        }

        const existingItem = quantity.find((item) => item.sku_id === sku_id);

        if (existingItem) {
            const updatedQuantity = quantity.map((item) =>
                item.sku_id === sku_id
                    ? { ...item, quantity: value ? value : item.quantity + 1 }
                    : item,
            );
            setQuantity(updatedQuantity);
        } else {
            setQuantity([
                ...quantity,
                { sku_id, quantity: value ? value : 1, original_unit_price },
            ]);
        }
    };

    const handleCartItem = async () => {
        try {
            // Validate quantity and prepare payload
            if (!quantity || !quantity.length) {
                throw new Error("Please add Quantity");
            }

            const productPayload: IPlaceBuyOrderProductStepFormData[] =
                quantity.map((item) => ({
                    sku_id: item.sku_id,
                    quantity: item.quantity,
                }));

            // Ensure all necessary data is available before proceeding
            if (!placeBuyOrderProductStep2FormData) {
                throw new Error(
                    "Please Product Category and Shipping Country is Required  ",
                );
            }

            if (!productDetail?.getId()) {
                throw new Error("Product ID not found");
            }

            // Prepare final payload
            const payload: CartItemAddPayload = {
                user_id: Number(userId),
                region,
                locale: defaultQuery.locale,
                product_id: productDetail.id,
                shipping: placeBuyOrderProductStep2FormData,
                variations: productPayload,
                note: cartNote,
            };

            // Make the API call to add product to cart
            await cartProduct(region, defaultQuery.locale, payload);

            // Show success alert and navigate to the next page
            showSuccessAlert("Product Added in Cart successfully");
            navigate(
                `/purchase/buy-orders/create?${parseObjectToString(getCurrentQuery())}`,
            );
        } catch (error: any) {
            showError(error);
        }
    };

    const handleBuyOrderAddProduct = async () => {
        try {
            // Validate quantity and prepare payload
            if (!quantity || !quantity.length) {
                throw new Error("Please add Quantity");
            }

            // Ensure all necessary data is available before proceeding
            if (!placeBuyOrderProductStep2FormData) {
                throw new Error(
                    "Product Category and Shipping Country is Required.Select Shipping Method  ",
                );
            }

            if (!productDetail?.getId()) {
                throw new Error("Product ID not found");
            }
            if (!buyOrderId) {
                throw new Error("Buy Order ID not found");
            }

            const productPayload: IPlaceBuyOrderProductStepFormData[] =
                quantity.map((item) => ({
                    sku_id: item.sku_id,
                    quantity: item.quantity,
                }));

            const payload: IAddBuyOrderAddProductFormData = {
                variations: productPayload,
                product_id: productDetail.getId(),
                shipping: {
                    agent_category_price_read_id:
                        placeBuyOrderProductStep2FormData.agent_category_price_read_id,
                    provider: placeBuyOrderProductStep2FormData.provider,
                    shipping_category_id:
                        placeBuyOrderProductStep2FormData.shipping_category_id,
                },
            };

            // Call the addProductBuyOrder API
            await addProductBuyOrder(
                buyOrderId,
                region,
                defaultQuery.locale,
                payload,
            );
            if (from) {
                navigate(`${from}`);
            } else {
                navigate(-1);
            }

            showSuccessAlert("Product added successfully to the buy order!");
        } catch (error) {
            showError(error);
        }
    };

    const handleAddButton = async () => {
        switch (operation) {
            case BuyOrderOperationEnum.PurchaseCartItemAdd:
                await handleCartItem();
                break;
            case BuyOrderOperationEnum.AddBuyOrderProduct:
                await handleBuyOrderAddProduct();
                break;
            default:
                break;
        }
    };

    const isDisableFloatingActionButton = () => {
        return (
            quantity.length === 0 ||
            isLoading ||
            !placeBuyOrderProductStep2FormData ||
            !placeBuyOrderProductStep2FormData?.agent_category_price_read_id
        );
    };
    const floatingActionButtonType = () => {
        return isDisableFloatingActionButton() ? "default" : "primary";
    };

    const getButtonText = () => {
        switch (operation) {
            case BuyOrderOperationEnum.AddBuyOrderProduct:
                return "Add Product";
            case BuyOrderOperationEnum.PurchaseCartItemAdd:
                return "Add to cart";
            default:
                return "";
        }
    };

    const columns: ColumnsType<SkuModelExtended> = [
        {
            title: "Image",
            dataIndex: "image",
            key: "image",
            align: "center",
            render: (_: string) => (
                <Avatar
                    src={<ImageWithPlaceholder src={product.getImage()} />}
                    size={50}
                    shape="square"
                />
            ),
        },
        {
            title: "Price",
            dataIndex: "color",
            key: "color",
            render: (_: string, record: SkuModelExtended) => {
                return (
                    <div>
                        {record?.getPrice()?.getDiscount() ? (
                            <>
                                {currentCurrencyModel?.getCurrencySymbol()}{" "}
                                {record?.getPrice()?.getDiscount()?.toFixed(2)}{" "}
                                (
                                <Typography.Text type="secondary">
                                    {record
                                        ?.getPrice()
                                        ?.getDiscountRaw()
                                        .toFixed(2)}{" "}
                                    {record?.getPrice()?.getCurrency()}
                                </Typography.Text>
                                )
                            </>
                        ) : (
                            <>
                                {currentCurrencyModel?.getCurrencySymbol()}{" "}
                                {record?.getPrice()?.getOriginal().toFixed(2)} (
                                <Typography.Text type="secondary">
                                    {record
                                        ?.getPrice()
                                        ?.getOriginalRaw()
                                        .toFixed(2)}{" "}
                                    {record?.getPrice()?.getCurrency()}
                                </Typography.Text>
                                )
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Stock",
            dataIndex: "title",
            key: "title",
            render: (_: string, record: SkuModelExtended) => {
                return <div>{record.getStock() || 0}</div>;
            },
        },
        {
            title: "Quantity",
            dataIndex: "thumb",
            key: "thumb",
            align: "center" as const,
            render: (_: string, record: SkuModelExtended) => {
                const sku_id = record.getId();
                const original_unit_price = record.getPrice().getOriginal();

                return (
                    <Row gutter={[4, 4]}>
                        <Col sm={8}>
                            <Button
                                onClick={() =>
                                    handleDecrement(sku_id, original_unit_price)
                                }
                            >
                                -
                            </Button>
                        </Col>
                        <Col sm={8}>
                            <InputNumber
                                style={{ width: "100%" }}
                                min={0}
                                defaultValue={0}
                                onChange={(value) =>
                                    handleIncrement(
                                        sku_id,
                                        original_unit_price,
                                        value,
                                    )
                                }
                                value={
                                    quantity.find(
                                        (item) => item.sku_id === sku_id,
                                    )?.quantity || 0
                                }
                            />
                        </Col>
                        <Col sm={8}>
                            <Button
                                onClick={() =>
                                    handleIncrement(sku_id, original_unit_price)
                                }
                            >
                                +
                            </Button>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    if (
        operation !== BuyOrderOperationEnum.PurchaseCartItemAdd &&
        operation !== BuyOrderOperationEnum.AddBuyOrderProduct
    )
        return (
            <Alert
                type="error"
                description="This is a zero variant product. The variant already exists in the order. Go back and update it."
            />
        );

    return (
        <>
            <Row gutter={16}>
                <Col sm={24} lg={18}>
                    <Flex vertical gap={24}>
                        <Table
                            size="small"
                            bordered
                            columns={columns}
                            dataSource={variations.getSkus().getData()}
                            rowKey="id"
                            pagination={false}
                        />
                        <>
                            <Button
                                size="large"
                                style={{
                                    padding: 0,
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor:
                                        placeBuyOrderProductStep2FormData &&
                                        Object.values(
                                            placeBuyOrderProductStep2FormData,
                                        ).every(Boolean)
                                            ? "#3676ff54"
                                            : "#05d05e3b",
                                    border: 0,
                                }}
                                onClick={() => {
                                    setIsShippingMethodModalOpen(true);
                                }}
                            >
                                <Flex align="center" gap={8}>
                                    <Image
                                        preview={false}
                                        src={shippingMethodIcon}
                                        alt=""
                                        width={45}
                                    />
                                    {placeBuyOrderProductStep2FormData &&
                                    Object.values(
                                        placeBuyOrderProductStep2FormData,
                                    ).every(Boolean) ? (
                                        <>
                                            <Flex gap={8} align="center">
                                                <Text
                                                    style={{
                                                        fontSize: "16px",
                                                    }}
                                                >
                                                    {placeBuyOrderProductStep2FormData.selectedCategoryPrice
                                                        .getAgentWarehouse()
                                                        .getCompanyName()}
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: "14px",
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    <Text strong>
                                                        {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getMinShippingTime()}
                                                        -
                                                        {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getMaxShippingTime()}{" "}
                                                        {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getShippingTimeUnit()}
                                                    </Text>
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontSize: "14px",
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getShippingMode()}{" "}
                                                    by{" "}
                                                    {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getShippingType()}{" "}
                                                </Text>

                                                <Text strong>
                                                    {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getRate()}
                                                    /
                                                    {placeBuyOrderProductStep2FormData.selectedCategoryPrice.getUnitType()}
                                                </Text>
                                                <Text
                                                    strong
                                                    style={{
                                                        textTransform:
                                                            "capitalize",
                                                    }}
                                                >
                                                    {
                                                        placeBuyOrderProductStep2FormData
                                                            .selectedCategoryPrice
                                                            .contain
                                                    }
                                                </Text>

                                                <EditOutlined />
                                            </Flex>
                                        </>
                                    ) : (
                                        <Typography.Text strong>
                                            Select Shipping Method{" "}
                                            <ArrowRightOutlined />
                                        </Typography.Text>
                                    )}
                                </Flex>
                            </Button>

                            <Form>
                                <Form.Item
                                    label="Add note"
                                    name="description"
                                    validateStatus={""}
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    style={{ marginBottom: 0 }}
                                >
                                    <TextArea
                                        onChange={(e) =>
                                            setCartNote(e.target.value)
                                        }
                                        rows={4}
                                        placeholder="Please add your note"
                                        required={true}
                                    />
                                </Form.Item>
                            </Form>
                        </>
                    </Flex>
                </Col>
            </Row>

            <Modal
                width={""}
                centered
                title={
                    <Typography.Title level={4}>
                        Shipping Methods
                    </Typography.Title>
                }
                open={isShippingMethodModalOpen}
                onCancel={() => setIsShippingMethodModalOpen(false)}
                onOk={() => {
                    if (
                        placeBuyOrderProductStep2FormData &&
                        Object.values(placeBuyOrderProductStep2FormData).every(
                            Boolean,
                        )
                    ) {
                        showSuccessAlert("Successfully Added Shipping Method");
                        setIsShippingMethodModalOpen(false);
                    } else {
                        notification.error({
                            message:
                                "Please Select Product Category, Shipping Country, Shipping Partner",
                        });
                    }
                }}
                okText={"Submit"}
                cancelText="Cancel"
                destroyOnClose={true}
            >
                <ShippingMethod
                    placeBuyOrderProductStep2FormData={
                        placeBuyOrderProductStep2FormData
                    }
                    setPlaceBuyOrderProductStep2FormData={
                        setPlaceBuyOrderProductStep2FormData
                    }
                />
            </Modal>

            <div
                style={{
                    position: "fixed",
                    bottom: "20px",
                    right: "16px",
                    zIndex: 99999,
                }}
            >
                <Badge
                    count={
                        quantity.length > 0
                            ? quantity.reduce(
                                  (acc, curr) => acc + curr.quantity,
                                  0,
                              )
                            : ""
                    }
                >
                    <Button
                        type={floatingActionButtonType()}
                        onClick={handleAddButton}
                        disabled={isDisableFloatingActionButton()}
                        loading={isLoading || isLoadingAddProduct}
                        style={{ width: "150px" }}
                    >
                        {getButtonText()}
                    </Button>
                </Badge>
            </div>
        </>
    );
};
