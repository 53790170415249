import { ITranslationEntry } from "../lang";

export const OR: ITranslationEntry = {
    translations: {
        notifications: "ବିଜ୍ଞପ୍ତି",
        markAllAsRead: "ସମସ୍ତଙ୍କୁ ପଢ଼ିଥିବା ପରି ଚିହ୍ନିତ କର",
        poweredBy: "ଦ୍ୱାରା ପରିଚାଳିତ",
        settings: "ସେଟିଂ",
        noNewNotification: "ଏପର୍ଯ୍ୟନ୍ତ ଦେଖିବାକୁ କିଛି ନୂଆ ନୁହେଁ |",
    },
    lang: "or",
};
