import { Button, Popconfirm, Table, Tag, notification, Typography } from "antd";
import {
    useGetAllBids,
    useWinnerWarehouse,
} from "@/lib/core-react/hooks/private/useShipping";
import {
    ShipmentProductBidModel,
    ShipmentProductBidParticipantModel,
} from "@/models/shipmentProductBidCollectionModel";
const { Column } = Table;
const { Text } = Typography;
interface IProps {
    bid: ShipmentProductBidModel;
    bidId: number;
    winner_warehouse_id?: number;
    shipmentProductId: number;
}
const ParticipantsTable = ({
    bid,
    bidId,
    winner_warehouse_id,
    shipmentProductId,
}: IProps) => {
    const { winnerWarehouse } = useWinnerWarehouse();
    const { getAllBids } = useGetAllBids();

    const winnerHandler = async (warehouseId: number) => {
        try {
            const res = await winnerWarehouse(bidId, {
                agent_warehouse_id: warehouseId,
            });
            notification["success"]({
                message: res.message,
            });
            getAllBids(shipmentProductId);
        } catch (error: any) {
            notification["error"]({
                message: error.response.data.message,
            });
        }
    };
    return (
        <>
            <Table
                dataSource={bid.participants}
                pagination={false}
                rowKey={(r) => r.getId()}
            >
                <Column
                    title="Warehouse Details"
                    dataIndex="product-details"
                    key="product-details"
                    className="vertical-align-top"
                    render={(
                        _: string,
                        record: ShipmentProductBidParticipantModel,
                    ) => (
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <div style={{ display: "flex", gap: "8px" }}>
                                <b>Name:</b>{" "}
                                <span>
                                    {record.getAgentWarehouse().getName()}
                                </span>
                            </div>
                            <div style={{ display: "flex", gap: "8px" }}>
                                <span>
                                    {record.getAgentWarehouse().getId() ===
                                        winner_warehouse_id && (
                                        <Tag color="green-inverse">
                                            Bid Winner
                                        </Tag>
                                    )}
                                </span>
                            </div>
                            <div>
                                {record.getOffers()?.length ? (
                                    <>
                                        <b>Offers:</b>
                                        <ul>
                                            {record
                                                .getOffers()
                                                ?.map((offer, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <Text strong>
                                                                {index + 1}.{" "}
                                                            </Text>
                                                            <Text strong>
                                                                Unit type :{" "}
                                                            </Text>
                                                            <Text>
                                                                {offer
                                                                    .getUnitType()
                                                                    .toUpperCase()}
                                                            </Text>
                                                            {" | "}
                                                            <Text strong>
                                                                Rate :{" "}
                                                            </Text>
                                                            <Text>
                                                                {offer.getRate()}
                                                            </Text>
                                                        </li>
                                                    );
                                                })}
                                        </ul>
                                    </>
                                ) : (
                                    <b>No Offers</b>
                                )}
                            </div>
                        </div>
                    )}
                />
                <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    className="vertical-align-top"
                    render={(
                        _: string,
                        record: ShipmentProductBidParticipantModel,
                    ) => (
                        <div style={{ textTransform: "capitalize" }}>
                            <Tag color="#87d068">
                                {record.getParticipantStatus()}
                            </Tag>
                        </div>
                    )}
                />
                <Column
                    title="Actions"
                    dataIndex="actions"
                    key="actions"
                    className="vertical-align-top"
                    render={(
                        _: string,
                        record: ShipmentProductBidParticipantModel,
                    ) => (
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() =>
                                winnerHandler(
                                    Number(record.getAgentWarehouse().getId()),
                                )
                            }
                        >
                            <Button
                                title={
                                    record.getParticipantStatus() !==
                                    "interested"
                                        ? "Participant is not interested"
                                        : !bid.ui_action.getCanChooseBidWinner()
                                          ? "Ui action is not allowing to set winner"
                                          : ""
                                }
                                disabled={
                                    record.getParticipantStatus() !==
                                        "interested" ||
                                    !bid.ui_action.getCanChooseBidWinner()
                                }
                            >
                                Set Winner
                            </Button>
                        </Popconfirm>
                    )}
                />
            </Table>
        </>
    );
};

export default ParticipantsTable;
