export enum buyProductStatusEnum {
    BUY_PENDING = "buy-pending",
    BUY_APPROVED = "buy-approved",
    BUY_PROCESSING = "buy-processing",
    BUY_REJECT = "buy-reject",
    BUY_PURCHASED = "buy-purchased",
    BUY_NOT_PURCHASED = "buy-not-purchased",
    BUY_HANDOVER_TO_SHIPPING = "buy-handover-to-shipping",
    BUY_REFUND_INITIATED = "buy-refund-initiated",
    BUY_REFUND_PROCESSING = "buy-refund-processing",
    BUY_REFUNDED = "buy-refunded",
    BUY_CANCELLED = "buy-cancelled",
    SHIPMENT_ASSIGNED_AGENT = "shipment-assigned-agent",
    SHIPMENT_NOT_ARRIVED = "shipment-not-arrived",
    SHIPMENT_SHIPPING_CANCELLED = "shipment-shipping-cancelled",
    SHIPMENT_ARRIVED_AT_WAREHOUSE = "shipment-arrived-at-warehouse",
    SHIPMENT_PREPARING_FOR_TRANSPORT = "shipment-preparing-for-transport",
    SHIPMENT_HANDOVER_TO_SHIP = "shipment-handover-to-ship",
    SHIPMENT_HANDOVER_TO_AIRLINE = "shipment-handover-to-airline",
    SHIPMENT_ARRIVED_AT_DESTINATION_PORT = "shipment-arrived-at-destination-port",
    SHIPMENT_ARRIVED_AT_DESTINATION_AIRPORT = "shipment-arrived-at-destination-airport",
    SHIPMENT_CUSTOMS_RELEASED = "shipment-customs-released",
    SHIPMENT_HANDOVER_TO_MOVEON = "shipment-handover-to-moveon",
    SHIPMENT_RECEIVED_BY_MOVEON = "shipment-received-by-moveon",
    DELIVERY_PENDING = "delivery-pending",
    DELIVERY_PROCESSING = "delivery-processing",
    DELIVERY_READY = "delivery-ready",
    DELIVERY_SHIPPED = "delivery-shipped",
    DELIVERY_FAILED = "delivery-failed",
    DELIVERY_RETURNED = "delivery-returned",
    DELIVERED_TO_CUSTOMER = "delivered-to-customer",
}
