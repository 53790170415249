import { Form, Input, Select } from "antd";
import { useEffect } from "react";
import {
    CurrencyCode,
    CurrencyDataStatus,
} from "@/enums/currencyCollectionEnums";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { CurrencyModel } from "@/models/currencyCollectionModel";

interface IProps {
    data?: CurrencyModel;
    form: any;
    onFinish: (values: any) => Promise<void>;
}

const CurrencyModifications = ({ data, form, onFinish }: IProps) => {
    useEffect(() => {
        form.resetFields();
    }, [data, form]);
    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Add Currency"
                    initialValues={{
                        name: data?.name,
                        code: data?.code,
                        status: data?.status,
                        symbol: data?.symbol,
                        symbol_native: data?.symbol_native,
                    }}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Currency code is required",
                            },
                        ]}
                        label="Code"
                        name="code"
                    >
                        <Select
                            disabled={!!data}
                            placeholder="Please select a currency code"
                        >
                            {renderOptionsFromEnum(CurrencyCode)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Currency name is required",
                            },
                        ]}
                        label="Currency name"
                        name="name"
                    >
                        <Input placeholder="Currency name" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            { required: true, message: "Symbol is required" },
                        ]}
                        label="Symbol"
                        name="symbol"
                    >
                        <Input placeholder="Symbol" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Symbol native is required",
                            },
                        ]}
                        label="Symbol native"
                        name="symbol_native"
                    >
                        <Input placeholder="Symbol native" />
                    </Form.Item>

                    <Form.Item required name="status" label="Status">
                        <Select placeholder="Please select a status">
                            {renderOptionsFromEnum(CurrencyDataStatus)}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default CurrencyModifications;
