import { ITranslationEntry } from "../lang";

export const BH: ITranslationEntry = {
    translations: {
        notifications: "सूचनाएं",
        markAllAsRead: "सभी को पढ़ा हुआ चिन्हित करें",
        poweredBy: "द्वारा संचालित",
        settings: "सेटिंग्स",
        noNewNotification: "यहां अभी कुछ नया देखने को नहीं मिला है",
    },
    lang: "bh",
};
