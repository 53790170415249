import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";
import { IWarehouseShipment } from "@/types/warehouseShipmentCollection";

export const useGetWarehouseShipmentMinimal = () => {
    const { shippingAgentService } = useService();
    const [warehouseShipmentOptionsData, setWarehouseShipmentOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getWarehouseShipment = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }

            setWarehouseShipmentOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await shippingAgentService.agentCompanyResource.getWarehouseShipment(
                    params,
                );

            const options =
                response && response.data
                    ? new SelectOptionModel<IWarehouseShipment>(
                          response.data,
                      ).getOptionsByLabelKey("shipment_number")
                    : [];

            setWarehouseShipmentOptionsData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setWarehouseShipmentOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getWarehouseShipment, warehouseShipmentOptionsData } as const;
};
