import React from "react";
import { Table, Typography } from "antd";
import { ShipmentProductBidModel } from "@/models/shipmentProductBidCollectionModel";
import { ColumnsType } from "antd/es/table";

const { Text } = Typography;

interface BidTableProps {
    bids: ShipmentProductBidModel[];
}

export const BidTable: React.FC<BidTableProps> = ({ bids }) => {
    const columns: ColumnsType<ShipmentProductBidModel> = [
        {
            title: "Status",
            align: "center",
            dataIndex: "status",
            key: "id",
            render: (status: string) => <Text>{status}</Text>,
        },
        {
            title: "Open For",
            align: "center",
            dataIndex: "open_for",
            key: "id",
            render: (open_for: string) => <Text>{open_for}</Text>,
        },
        {
            title: "Winner Warehouse",
            align: "center",
            key: "id",
            render: (_: any, bid: ShipmentProductBidModel) => (
                <Text>
                    {bid.getWinnerWarehouse()?.getAgentWarehouse().getName() ||
                        "N/A"}
                </Text>
            ),
        },
    ];

    return (
        <Table
            size="small"
            title={() => <Typography.Text strong>Bids</Typography.Text>}
            dataSource={bids}
            columns={columns}
            bordered
            rowHoverable={false}
            pagination={false}
            rowKey={(record) => record.getId()}
        />
    );
};
