import { Table } from "antd";
import {
    ActionSequenceModel,
    VariantMismatchModel,
} from "src/models/buyActionCollectionModel";
import PropertyVariation from "./ExtraComponent/PropertyVariation";

interface IProps {
    data: ActionSequenceModel;
    currency: string;
}

const StockOutMismatchedTable = ({ data, currency }: IProps) => {
    const columns = [
        {
            title: "Meta",
            dataIndex: "variation_mismatch",
            key: "variation_mismatch",
            render: (_: any, mismatch: VariantMismatchModel) =>
                PropertyVariation(mismatch),
        },
        {
            title: "Old Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (_: any, mismatch: VariantMismatchModel) =>
                mismatch.getBuyProductVariation()?.getQuantity() || 0,
        },
        {
            title: "New Quantity",
            dataIndex: "quantity",
            key: "new_quantity",
            render: (_: any, mismatch: VariantMismatchModel) => (
                <span
                    style={{
                        color:
                            mismatch.getNewQuantity() === 0
                                ? "#DC2626"
                                : "inherit",
                    }}
                >
                    {(mismatch?.getNewQuantity() || 0) === 0 ? "Stock out" : ""}
                </span>
            ),
        },
        {
            title: "Amount",
            dataIndex: "total_price",
            key: "total_price",
            render: (_: any, mismatch: VariantMismatchModel) => {
                const buyProductVariation = mismatch.getBuyProductVariation();

                return (
                    <>
                        {currency}{" "}
                        {buyProductVariation
                            ?.getOriginalTotalPrice()
                            .toFixed(2)}{" "}
                        {/* Use totalNewPrice instead */}
                    </>
                );
            },
        },
    ];

    const dataSource: VariantMismatchModel[] =
        data.getVariantMismatches() || [];

    return (
        <div style={{ marginTop: "1rem" }}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                rowKey={(record) => record.getId()}
            />
        </div>
    );
};

export default StockOutMismatchedTable;
