import { Form, FormInstance, Input, Select, Skeleton } from "antd";
import { renderOptionsFromEnum } from "@/components/Form/forms";

import {
    RegionCode,
    RegionDataStatus,
    RegionStoreType,
} from "@/enums/regionCollectionEnums";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { useEffect } from "react";
import { RegionModel } from "@/models/regionCollectionModel";

interface IProps {
    data?: RegionModel;
    form: FormInstance;
    onFinish: (values: any) => Promise<void>;
}

const RegionModifications = ({ data, form, onFinish }: IProps) => {
    const {
        allOptionsData: {
            currencyOptionsData,
            countryOptionsData,
            languageOptionsData,
        },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.CURRENCY);
        onFetchFilterApi(filterResourceEnum.COUNTRY);
        onFetchFilterApi(filterResourceEnum.LANGUAGE);
    }, []);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                name: data.name,
                code: data.code,
                status: data.status,
                store: data.store,
                store_type: data.store_type,
                country_ids: data?.countries?.map((x) => x.id),
                currency_id: data.currency?.id,
                language_ids: data.languages?.data.map((x) => x.id),
            });
        }
    }, [data, form]);

    return (
        <>
            <div>
                <Form
                    onFinish={onFinish}
                    form={form}
                    layout="vertical"
                    name="Add region"
                    initialValues={{
                        name: data?.name,
                        code: data?.code,
                        status: data?.status,
                        store: data?.store,
                        store_type: data?.store_type,
                        country_ids: data?.countries?.map((x) => x.id),
                        currency_id: data?.currency?.id,
                        language_ids: data?.languages?.data.map((x) => x.id),
                    }}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Region name is required",
                            },
                        ]}
                        label="Name"
                        name="name"
                    >
                        <Input placeholder="Region name" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Region code is required",
                            },
                        ]}
                        label="Code"
                        name="code"
                    >
                        <Select
                            placeholder="Please select a region code"
                            disabled={!!data}
                        >
                            {renderOptionsFromEnum(RegionCode)}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Store name is required",
                            },
                        ]}
                        label="Store"
                        name="store"
                    >
                        <Input placeholder="Store name" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Store type is required",
                            },
                        ]}
                        name="store_type"
                        label="Store Type"
                    >
                        <Select placeholder="Please select a Store Type">
                            {renderOptionsFromEnum(RegionStoreType)}
                        </Select>
                    </Form.Item>

                    {/* Currency Id */}
                    <Form.Item
                        rules={[
                            { required: true, message: "Currency is required" },
                        ]}
                        name="currency_id"
                        label="Currency"
                    >
                        {currencyOptionsData.isLoading ? (
                            <Skeleton.Input
                                active
                                size="default"
                                style={{ width: "100%" }}
                            />
                        ) : (
                            <Select
                                loading={currencyOptionsData.isLoading}
                                placeholder="Please select a currency"
                                options={currencyOptionsData.options}
                            />
                        )}
                    </Form.Item>

                    {/* Country ID */}
                    <div>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Country is required",
                                },
                            ]}
                            name="country_ids"
                            label="Country"
                        >
                            {countryOptionsData.isLoading ? (
                                <Skeleton.Input
                                    active
                                    size="default"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                <Select
                                    mode="multiple"
                                    loading={countryOptionsData.isLoading}
                                    placeholder="Please select countries"
                                    options={countryOptionsData?.options}
                                />
                            )}
                        </Form.Item>
                    </div>
                    {/* Language */}
                    <div>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: "Language is required",
                                },
                            ]}
                            name="language_ids"
                            label="Language"
                        >
                            {languageOptionsData.isLoading ? (
                                <Skeleton.Input
                                    active
                                    size="default"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                <Select
                                    mode="multiple"
                                    loading={languageOptionsData.isLoading}
                                    placeholder="Please select languages"
                                    options={languageOptionsData.options}
                                />
                            )}
                        </Form.Item>
                    </div>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "Status is required",
                            },
                        ]}
                        name="status"
                        label="Status"
                    >
                        <Select placeholder="Please select a status">
                            {renderOptionsFromEnum(RegionDataStatus)}
                        </Select>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default RegionModifications;
