import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Dropdown,
    Space,
    Table,
    Button,
    MenuProps,
    Tag,
    Typography,
    notification,
    Modal,
    Flex,
    Form,
} from "antd";

import {
    useGetBuyOrders,
    useOrderHandlerAssign,
} from "../../../lib/core-react/hooks/private/usePurchase";
import { buyOrderCollectionAtom } from "../../../lib/core-react/store/buyOrderAtoms/buyOrderAtoms";
import useDataFilters from "../../../hooks/useDataFilters";
import { ApiHelperModel } from "../../../models/apiHelper";
import { IFilterType } from "../../../types/filters";
import FiltersComponent from "../../../components/FiltersComponent";
import {
    ArrowRightOutlined,
    ControlOutlined,
    DownloadOutlined,
    EditOutlined,
    HddFilled,
    PayCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { PaginationModel } from "@/models/pagination";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_PURCHASE_PERMISSION_ENUM } from "@/consts/permission-enum/purchase-enum";
import CommonError from "../../../components/Error/CommonError";
import { BuyOrderWithProductModel } from "@/models/buyOrderCollectionModel";
import { ColumnsType } from "antd/es/table";
import { tw } from "@/consts/theme/tailwindTheme";
import BuyProductsTable from "../BuyProducts/BuyProductsTable";
import CustomerInfo from "../../../components/shared/Order/CustomerInfo";
import useActionsProps from "@/helpers/useActionsProps";
import FinancialDetails from "../BuyProducts/components/FinacialDetails";
import { BREAK_POINTS } from "@/consts/appConstants";
import OrderHandlerAssign from "./components/orderHandlerAssignModal";
import { IOrderHandlerRequest } from "@/types/buyOrderCollection";
import { showError } from "@/helpers/showError";
import useRefetch from "@/hooks/useRefetch";
import { ADMIN_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-core-enum";
import { UserType } from "@/types/userCollection";
import ImpersonateCreateModalTitle from "@/components/ImpersonateFormModalTitle";
import ImpersonateForm from "@/components/ImpersonateSessionCreate/impersonate";
import BuyOrderTableSummary from "./components/BuyOrderTableSummary";
import { IHarvestJobCreate } from "@/types/harvestBatchCollection";
import { HarvestActionEnums } from "@/enums/harvestJobCollectionEnums";
import { useHarvestJobCreate } from "@/lib/core-react/hooks/private/useHarvestJob";
import { ADMIN_HARVEST_BATCH_PERMISSION_ENUM } from "@/consts/permission-enum/harvest-enum";
import { BuyOrderOperationEnum } from "@/enums/buyOrderCollectionEnums";
import { parseObjectToString } from "@/helpers/stringify";
import getPagePathname from "@/helpers/getPagePathname";
import { formatDateTime } from "@/utils/helpers";
import isProductUrl from "@/helpers/isProductUrl";
import ProductUrlForm from "../components/ProductUrlForm";
import { IPaymentSessionPayPayload } from "@/types/paymentSession";
import { PayCustomerInvoiceModal } from "@/components/PayCustomerInvoice";
import { useInvoice } from "@/lib/core-react/hooks/private/useInvoice";
import { RegionModel } from "@/models/regionCollectionModel";
import { ADMIN_INVOICE_PERMISSION_ENUM } from "@/consts/permission-enum/invoice-enum";
import { CustomerUserModel } from "@/models/customerUserCollectionModel";
import { ICustomerUser } from "@/types/customerUserCollection";
import { getVisibleFilters } from "@/helpers/getfiltersBySettings";
import { FilterStorageKeys } from "@/consts/AppEnums";
import OrderSource from "@/components/shared/Order/OrderSource";
const { Text, Paragraph } = Typography;

const BuyOrders = () => {
    const [form] = Form.useForm(); // pay customer invoice form
    const {
        paySession,
        createSession,
        isLoading: isLoadingSession,
    } = useInvoice();
    const navigate = useNavigate();
    const [selectedOrderId, setSelectedOrderId] = useState<string>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [selectedRows, setSelectedRows] = useState<
        BuyOrderWithProductModel[]
    >([]);

    const [selectedProduct, setSelectedProduct] = useState<
        BuyOrderWithProductModel | undefined
    >(undefined);
    const [isAssignOrderHandlerModalOpen, setIsAssignOrderHandlerModalOpen] =
        useState(false);
    const [orderHandlerId, setOrderHandlerId] = useState<number | null>();
    const [isShowModal, setIsShowModal] = useState(false);
    const [modalOpenType, setModalOpenType] = useState<
        "add_buy_product" | false
    >(false);
    const [selectedCustomerUser, setSelectedCustomerUser] =
        useState<ICustomerUser>();
    const { createHarvest } = useHarvestJobCreate();

    const onClearBulkSelectedState = () => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        onClearSingleSelectedState();
    };
    const onClearSingleSelectedState = () => {
        setSelectedOrderId("");
        setSelectedProduct(undefined);
        setOrderHandlerId(null);
        setIsShowModal(false);
        setModalOpenType(false);
        setSelectedPayInvoiceData(undefined);
        setIsAssignOrderHandlerModalOpen(false);
    };

    const { isMobile, width } = useWindowWidth();

    const actionsProps = useActionsProps();
    const [selectedPayInvoiceData, setSelectedPayInvoiceData] = useState<{
        region: RegionModel;
        user_id: number;
        token: string;
    }>();

    const {
        data: buyOrderCollectionModel,
        isLoading,
        refetch,
        unAuthorized,
        error,
        code,
    } = useAtomValue(buyOrderCollectionAtom);

    const { getBuyOrders } = useGetBuyOrders();
    const { orderHandlerAssign, isLoading: AssignLoading } =
        useOrderHandlerAssign();
    const { refetchListApi } = useRefetch();
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFirstCall,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();

    // --------------------------------- API Call -> ------------------------------ //
    useEffect(() => {
        if (
            (filters && !isFetched && isFirstCall) ||
            refetch ||
            refetchFromFilter
        ) {
            ApiHelperModel.makeGetRequest(filters, getBuyOrders);
        }
    }, [isFirstCall, filters, isFetched, refetch, refetchFromFilter]);

    // ---------------------- Getting all available filters -> -------------------- //

    useEffect(() => {
        if (
            !isFetched &&
            buyOrderCollectionModel &&
            buyOrderCollectionModel?.getFilters()
        ) {
            initializeAvailableFilter(
                buyOrderCollectionModel.getFilters() as IFilterType,
            );
        }
    }, [isFetched, initializeAvailableFilter]);

    // ------------------------------ Event Handlers -> ---------------------------- //

    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getBuyOrders,
        );
        onClearBulkSelectedState();
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        buyOrderCollectionModel,
        handlePaginationChange,
    );

    // Filter Handler
    const handleFilter = () => {
        ApiHelperModel.makeGetRequest(filters, getBuyOrders);
        onClearBulkSelectedState();
    };

    const handleMenuClick: MenuProps["onClick"] = (e) => {
        if (e.key === "edit-order") {
            const url = `/purchase/buy-orders/edit/${selectedOrderId}`;
            window.open(url, "_blank");
        }
    };

    const onSubmitHandlerAssign = async (values: {
        order_handler_id: string;
    }) => {
        const payload: IOrderHandlerRequest = {
            order_ids: selectedOrderId
                ? [Number(selectedOrderId)]
                : selectedRowKeys.map((key) => Number(key)),
            order_handler_id: Number(values.order_handler_id),
        };

        try {
            await orderHandlerAssign(payload);
            refetchListApi();

            if (orderHandlerId) {
                notification["success"]({
                    message: "Order Handler Updated Successfully",
                });
            } else {
                notification["success"]({
                    message: "Order Handler Assigned Successfully",
                });
            }

            if (selectedOrderId) onClearSingleSelectedState();
            else onClearBulkSelectedState();
        } catch (error) {
            showError(error);
        }
    };

    const harvestJobCreateHandler = async (
        record: BuyOrderWithProductModel,
        isMinimalInvoice = false,
    ) => {
        const payload: IHarvestJobCreate<object, object> = {
            type: isMinimalInvoice
                ? HarvestActionEnums.MINIMAL_CUSTOMER_INVOICE
                : HarvestActionEnums.CUSTOMER_INVOICE,
            context: {
                filters: {
                    order_ids: [record.getId()],
                },
            },
            settings: {
                notification: {
                    email: false,
                    sms: false,
                },
            },
        };
        await createHarvest(payload);
        notification.success({ message: "Create Successfully" });
    };

    const harvestJobCreateHandlerBulk = async (
        selectedOrders: BuyOrderWithProductModel[],
        isMinimalInvoice = false,
    ) => {
        const firstEmail = selectedOrders[0].getCustomer()?.getEmail();

        const isSameUserOrders = selectedOrders.every(
            (item) => item.getCustomer()?.getEmail() === firstEmail,
        );

        if (isSameUserOrders) {
            const payload: IHarvestJobCreate<object, object> = {
                type: isMinimalInvoice
                    ? HarvestActionEnums.MINIMAL_CUSTOMER_INVOICE
                    : HarvestActionEnums.CUSTOMER_INVOICE,
                context: {
                    filters: {
                        order_ids: selectedOrders.map((order) => order.getId()),
                    },
                },
                settings: {
                    notification: {
                        email: false,
                        sms: false,
                    },
                },
            };
            await createHarvest(payload);
            notification.success({ message: "Create Successfully" });
            onClearBulkSelectedState();
        } else {
            notification.error({
                message: "Please select orders for the same customer.",
            });
        }
    };

    const handlePayCustomerInvoice = async (
        rows: BuyOrderWithProductModel[],
    ) => {
        const validRows = rows.filter((row) => {
            const products = row.getProducts();

            // Ensure there are products in the row and at least one satisfies the condition
            return products.some((product) => {
                const transactionSummary = product
                    .getCustomerInvoice()
                    ?.getTransactionSummary();
                const due = transactionSummary?.due;
                return typeof due === "number" && due > 0;
            });
        });

        // Extract unique user IDs and regions
        const uniqueCustomerIds = Array.from(
            new Set(validRows.map((row) => row.getCustomer()?.id)),
        );
        const uniqueRegions = Array.from(
            new Set(
                validRows
                    .map((row) => row.getFirstProductRegion())
                    .filter(
                        (region) => region !== undefined && region !== null,
                    ),
            ),
        );

        const uniqueRegionIds = Array.from(
            new Set(
                validRows
                    .map((row) => row.getFirstProductRegion()?.getId())
                    .filter(
                        (region) => region !== undefined && region !== null,
                    ),
            ),
        );
        const uniqueInvoiceIds = [
            ...new Set(
                validRows
                    .flatMap((row) =>
                        row
                            .getProducts()
                            .filter((product) => {
                                const transactionSummary = product
                                    .getCustomerInvoice()
                                    ?.getTransactionSummary();
                                const due = transactionSummary?.due;
                                return typeof due === "number" && due > 0;
                            })
                            .map((product) => product.getCustomerInvoiceId()),
                    )
                    .filter((id) => id != null),
            ),
        ];

        if (uniqueCustomerIds.length > 1) {
            return notification.error({
                message: "Error",
                description: "Selected rows must belong to a single customer",
            });
        }
        if (uniqueRegionIds.length > 1) {
            return notification.error({
                message: "Error",
                description: "Selected rows must belong to a single region",
            });
        }

        if (uniqueInvoiceIds.length === 0) {
            return notification.error({
                message: "Error",
                description: "No invoices have dues",
            });
        }

        const res = await createSession(
            Number(uniqueCustomerIds[0]),
            uniqueInvoiceIds,
        );

        if (res) {
            setSelectedPayInvoiceData({
                region: uniqueRegions[0],
                user_id: Number(uniqueCustomerIds[0]),
                token: res.data.session_token,
            });
        }
    };

    const onFinishCustomerPay = async (values: IPaymentSessionPayPayload) => {
        if (selectedPayInvoiceData) {
            try {
                const { amount, gateway_id, wallet_id, ...restValues } = values;
                if (
                    typeof wallet_id === "undefined" &&
                    typeof gateway_id === "undefined"
                ) {
                    return notification.error({
                        message: "Please select a gateway or wallet",
                    });
                }

                const key = gateway_id ? "gateway_id" : "wallet_id";
                const id = gateway_id || wallet_id;

                const payload = {
                    amount,
                    [key]: id,
                    ...(Object.keys(restValues).length > 0 && {
                        gateway_data: { ...restValues },
                    }),
                };

                await paySession(
                    selectedPayInvoiceData.user_id,
                    selectedPayInvoiceData.token,
                    payload,
                );
                await refetchListApi();

                if (selectedOrderId) onClearSingleSelectedState();
                else onClearBulkSelectedState();

                notification.success({
                    message: "Payment initiated successfully",
                });
            } catch (error) {
                showError(error, form);
            }
        }
    };

    const handleGoToInventory = () => {
        try {
            if (!selectedProduct) {
                throw new Error("Product Not Found");
            }
            const queryData = {
                buyOrderId: selectedProduct.getId(),
                region: selectedProduct.getFirstProductRegionCode(),
                region_id: selectedProduct.getFirstProductRegionId(),
                operation: BuyOrderOperationEnum.AddBuyOrderProduct,
                from: getPagePathname(),
            };
            navigate(`/service/inventory?${parseObjectToString(queryData)}`);
        } catch (error) {
            showError(error);
        }
    };

    const handleGoToInventoryFinish = (values) => {
        try {
            if (!selectedProduct) {
                throw new Error("Product Not Found");
            }

            if (
                values &&
                values.productUrl &&
                !isProductUrl(values.productUrl)
            ) {
                throw new Error("Please Paste Product Url");
            }

            const queryData = {
                productUrl: values.productUrl,
                buyOrderId: selectedProduct.getId(),
                region: selectedProduct.getFirstProductRegionCode(),
                region_id: selectedProduct.getFirstProductRegionId(),
                operation: BuyOrderOperationEnum.AddBuyOrderProduct,
                from: getPagePathname(),
            };
            navigate(
                `/service/inventory/product-detail?${parseObjectToString(queryData)}`,
            );
        } catch (error) {
            showError(error);
        }
    };

    // ------------------------------------- Ui ------------------------------------ //

    if (unAuthorized || error) {
        return (
            <CommonError
                code={code}
                message={error}
                unAuthorized={unAuthorized}
            />
        );
    }

    const onModalOpen = (customer: CustomerUserModel) => {
        setSelectedCustomerUser(customer);
        setIsShowModal(true);
    };

    const columns: ColumnsType<BuyOrderWithProductModel> = [
        {
            title: "Order",
            key: "Order",
            render: (_, record) => (
                <Space direction="vertical" size={"small"}>
                    <Paragraph>
                        <Text>Order.N : </Text>
                        <Text strong copyable>
                            {record.order_number}
                        </Text>
                    </Paragraph>

                    <Paragraph>
                        <Text>Created At : </Text>
                        <Tag color="purple">
                            {formatDateTime(record.getCreatedAt())}
                        </Tag>
                    </Paragraph>
                    <Paragraph>
                        <OrderSource
                            requestContext={record
                                .getProducts()[0]
                                .getRequestContext()}
                        />
                    </Paragraph>
                </Space>
            ),
        },
        {
            title: "Financial Details",
            key: "Financial_Details",
            render: (_text, record) => (
                <FinancialDetails
                    totalItems={record.getProducts().length}
                    currencyCode={record.getCurrencyCode()}
                    totalRefundableAmount={record.getTotalRefundableAmount()}
                    totalAmount={record.getTotalAmount()}
                    totalDue={record.getTotalDue()}
                    totalPaid={record.getTotalPaid()}
                    // totalQuantity={record.getTotalQuantity()}
                />
            ),
        },
        {
            title: "Customer",
            key: "Customer",
            render: (_text, record) => {
                const customer = record?.customer;
                return (
                    <div>
                        {/* Render the customer info */}
                        <CustomerInfo user={record.customer} />
                        <br />
                        <Space style={{ marginTop: 5 }}>
                            {/* Render the impersonate button */}
                            {
                                !checkActionPermission(
                                    [
                                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_IMPERSONATE_CREATE,
                                    ],
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            if (customer) onModalOpen(customer);
                                        }}
                                    >
                                        Customer Impersonate
                                        <ArrowRightOutlined />
                                    </Button>,
                                    null,
                                )
                            }
                        </Space>
                    </div>
                );
            },
        },

        {
            title: "Order Handler",
            key: "order_handler",
            hidden: width < BREAK_POINTS.XL,
            render: (_text, record) => {
                return (
                    <>
                        <Space direction="vertical" size={"small"}>
                            <Paragraph>
                                <Text>Name: </Text>
                                <Text strong>
                                    {record?.order_handler?.name || "N/A"}
                                </Text>
                            </Paragraph>

                            <Paragraph>
                                <Text>Email: </Text>
                                {record.order_handler?.email ? (
                                    <Text>
                                        <a
                                            href={`mailto:${record.order_handler?.email}`}
                                        >
                                            {record.order_handler?.email}
                                        </a>
                                    </Text>
                                ) : (
                                    <Text strong>N/A</Text>
                                )}
                            </Paragraph>
                        </Space>
                    </>
                );
            },
        },
        {
            ...actionsProps,
            key: "actions",
            hidden: !checkActionPermission(
                [
                    ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
                    ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
                ],
                "checkActionPermission",
                null,
            ),
            render: (_: string, record) => {
                const orderHandlerId = record.getOrderHandler()?.getId();
                const menuItems: ExtendedMenuItemType[] = [
                    {
                        permission:
                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
                        label: "View Details",
                        key: "edit-order",
                        icon: <EditOutlined />,
                        onClick: handleMenuClick,
                    },
                    {
                        permission:
                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
                        label: "Add Product",
                        key: "add-product",
                        icon: <PlusOutlined />,
                        onClick: () => {
                            setModalOpenType("add_buy_product");
                            setSelectedProduct(record);
                        },
                    },

                    {
                        permission:
                            ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
                        label: orderHandlerId
                            ? "Update Order Handler"
                            : "Assign Order Handler",
                        key: "order-handler-assign",
                        icon: <ControlOutlined />,
                        onClick: () => {
                            setSelectedOrderId(record.id.toString());
                            setOrderHandlerId(Number(orderHandlerId));
                            setIsAssignOrderHandlerModalOpen(true);
                        },
                    },
                    {
                        permission:
                            ADMIN_HARVEST_BATCH_PERMISSION_ENUM.ADMIN_HARVEST_BATCH_CREATE,
                        key: "download-invoice",
                        icon: <DownloadOutlined />,
                        onClick: () => {
                            harvestJobCreateHandler(record);
                        },
                        label: "Download Invoice",
                    },
                    {
                        permission:
                            ADMIN_HARVEST_BATCH_PERMISSION_ENUM.ADMIN_HARVEST_BATCH_CREATE,
                        key: "download-minimal-invoice",
                        icon: <DownloadOutlined />,
                        onClick: () => {
                            harvestJobCreateHandler(record, true);
                        },
                        label: "Download Invoice Summary",
                    },
                    ...(record.hasProductWithDueInvoice()
                        ? [
                              {
                                  permission:
                                      ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                                  label: "Pay Customer Invoice",
                                  key: "pay-invoice",
                                  icon: <PayCircleOutlined />,
                                  onClick: () => {
                                      setSelectedOrderId(record.id.toString());
                                      handlePayCustomerInvoice([record]);
                                  },
                              },
                          ]
                        : []),
                ];

                if (isMobile) {
                    menuItems.push({
                        permission:
                            ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_VIEW,
                        label: <Tag color={"purple"}>{record.created_at}</Tag>,
                        key: "createAt",
                        disabled: true,
                    });
                }

                return (
                    <Dropdown
                        menu={{
                            items: menuItems.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                        onOpenChange={() => {
                            setSelectedOrderId(record.id.toString());
                        }}
                    >
                        <Button type="primary" icon={<HddFilled />} />
                    </Dropdown>
                );
            },
        },
    ];

    const bulkItems: ExtendedMenuItemType[] = [
        {
            permission: ADMIN_CORE_PERMISSION_ENUM.ASSIGN_ORDER_HANDLER,
            key: "Order-Handler",
            icon: <ControlOutlined />,
            onClick: () => {
                setSelectedOrderId(undefined);
                setIsAssignOrderHandlerModalOpen(true);
            },
            label: "Order Handler Assign",
        },
        {
            permission:
                ADMIN_HARVEST_BATCH_PERMISSION_ENUM.ADMIN_HARVEST_BATCH_CREATE,
            key: "download-invoice",
            icon: <DownloadOutlined />,
            onClick: () => {
                harvestJobCreateHandlerBulk(selectedRows);
            },
            label: "Download Invoice",
        },
        {
            permission:
                ADMIN_HARVEST_BATCH_PERMISSION_ENUM.ADMIN_HARVEST_BATCH_CREATE,
            key: "download-minimal-invoice",
            icon: <DownloadOutlined />,
            onClick: () => {
                harvestJobCreateHandlerBulk(selectedRows, true);
            },
            label: "Download Invoice Summary",
        },
        ...(selectedRows.some((row) => row.hasProductWithDueInvoice())
            ? [
                  {
                      permission:
                          ADMIN_INVOICE_PERMISSION_ENUM.ADMIN_PAY_CUSTOMER_INVOICE,
                      label: isLoadingSession
                          ? "Wait..."
                          : "Pay Customer Invoice",
                      key: "pay-invoice",
                      icon: <PayCircleOutlined />,
                      onClick: () => {
                          setSelectedOrderId(undefined);
                          handlePayCustomerInvoice(selectedRows);
                      },
                  },
              ]
            : []),
    ];
    return (
        <div>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={"Purchase Orders"}
                style={{ marginTop: "10px" }}
                extra={[
                    checkActionPermission(
                        ADMIN_PURCHASE_PERMISSION_ENUM.ADMIN_BUY_PRODUCT_ADD,
                        <Link
                            key="create-order"
                            to="/purchase/buy-orders/create"
                        >
                            <Button icon={<PlusOutlined />} type="primary">
                                Create Order
                            </Button>
                        </Link>,
                        null,
                    ),
                ]}
            >
                <FiltersComponent
                    handleProductFilter={handleFilter}
                    handleFilterChange={handleFilterChange}
                    handelFilterClear={handelFilterClear}
                    isFetched={isFetched}
                    filters={filters}
                    filtersData={
                        buyOrderCollectionModel?.getFilters() &&
                        getVisibleFilters(
                            FilterStorageKeys.BuyOrders,
                            buyOrderCollectionModel?.getFilters(),
                        )
                    }
                    isFromProductReceived={true}
                    isLoading={isLoading}
                />

                <Flex
                    vertical
                    gap="middle"
                    style={{
                        marginTop: 32,
                    }}
                >
                    {selectedRows.length > 0 && (
                        <BuyOrderTableSummary
                            selectedRows={selectedRows}
                            onClear={onClearBulkSelectedState}
                        />
                    )}
                    <div>
                        <Dropdown
                            menu={{
                                items: bulkItems.filter((x) =>
                                    checkActionPermission(
                                        x.permission,
                                        x,
                                        null,
                                    ),
                                ),
                            }}
                            disabled={selectedRowKeys.length === 0}
                        >
                            <Button
                                disabled={selectedRowKeys.length === 0}
                                style={{
                                    border: tw.border.none,
                                    boxShadow: tw.boxShadow.DEFAULT,
                                }}
                            >
                                Bulk Actions <HddFilled />
                            </Button>
                        </Dropdown>
                    </div>
                </Flex>

                <div style={{ marginTop: "15px" }}>
                    <Table
                        loading={isLoading}
                        columns={columns}
                        dataSource={buyOrderCollectionModel?.getData()}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => {
                                setSelectedRowKeys(selectedRowKeys);
                                setSelectedRows(selectedRows);
                            },
                        }}
                        expandable={{
                            expandedRowRender: ({ products }) => {
                                return (
                                    <div style={{ backgroundColor: "white" }}>
                                        <BuyProductsTable
                                            isOrderPage={true}
                                            buyProductList={products}
                                        />
                                    </div>
                                );
                            },
                            defaultExpandedRowKeys: ["0"],
                        }}
                        pagination={{
                            ...paginationConfig,
                            showTotal: (total) => (
                                <div>
                                    Total{" "}
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                            color: "green",
                                        }}
                                    >
                                        {total}
                                    </span>{" "}
                                    Items
                                </div>
                            ),
                        }}
                        scroll={{ x: 1000 }}
                        bordered
                        style={{
                            backgroundColor: tw.colors.white,
                            marginTop: tw.spacing[4],
                            verticalAlign: "top",
                        }}
                        rowHoverable={false}
                        rowKey={(r) => r.getId()}
                    />
                </div>

                {isAssignOrderHandlerModalOpen && (
                    <OrderHandlerAssign
                        isModalOpen={isAssignOrderHandlerModalOpen}
                        handleCloseModal={() => {
                            setIsAssignOrderHandlerModalOpen(false);
                            setSelectedOrderId(undefined);
                            setOrderHandlerId(undefined);
                        }}
                        handleSelectChange={onSubmitHandlerAssign}
                        AssignLoading={AssignLoading}
                        orderHandlerId={orderHandlerId || undefined}
                    />
                )}
                {selectedCustomerUser && (
                    <Modal
                        title={
                            <ImpersonateCreateModalTitle
                                name={selectedCustomerUser?.name || ""}
                                email={selectedCustomerUser?.email || ""}
                                title="Impersonate Customer"
                            />
                        }
                        open={isShowModal}
                        onCancel={() => setIsShowModal(false)}
                        footer={null}
                        width={600}
                    >
                        <ImpersonateForm
                            userType={UserType.Customer}
                            selectedUser={{
                                id: Number(selectedCustomerUser.id),
                            }}
                            setIsModalVisible={setIsShowModal}
                            disableInputOptions={true}
                        />
                    </Modal>
                )}
                <Modal
                    title={"Add Product"}
                    open={modalOpenType === "add_buy_product"}
                    onCancel={() => setModalOpenType(false)}
                    footer={null}
                    width={600}
                    destroyOnClose={true}
                >
                    <ProductUrlForm
                        handleFinish={handleGoToInventoryFinish}
                        onGoToInventory={handleGoToInventory}
                    />
                </Modal>
            </PageHeader>

            <Modal
                width={"50%"}
                title="Pay Customer Invoice"
                open={Boolean(selectedPayInvoiceData)}
                onCancel={() => {
                    setSelectedPayInvoiceData(undefined);
                }}
                onOk={form.submit}
                okText="Submit"
                okButtonProps={{ loading: isLoadingSession }}
                centered
                destroyOnClose={true}
            >
                {selectedPayInvoiceData && (
                    <PayCustomerInvoiceModal
                        form={form}
                        data={selectedPayInvoiceData}
                        onFinish={onFinishCustomerPay}
                    />
                )}
            </Modal>
        </div>
    );
};

export default BuyOrders;
