import { useMemo } from "react";
import { Row, Col, Flex, Card, Button, Badge, Collapse } from "antd";

import { roundToTwoDecimal } from "@/helpers/roundToTwoDecimal";

import ShippingText from "@/components/ShippingText";
import { StatusTag } from "@/components";
import { CollapseProps } from "antd/lib";
import { ShipmentOrderModel } from "@/models/shipmentOrderCollectionModel";

interface Props {
    selectedRows: ShipmentOrderModel[];
    onClear: () => void;
}

const ShipmentOrderTableSummary = ({ selectedRows, onClear }: Props) => {
    const initialSummary = {
        totalItem: 0,
        storeData: {} as Record<
            string,
            { storeName: string; itemCount: number }
        >,
        statusData: {} as Record<string, number>,
        regionInvoiceData: {} as Record<
            string,
            {
                regionCode: string;
                totalAmount: number;
                totalPaid: number;
                totalDue: number;
                currencyCode: string;
                itemCount: number;
            }
        >,
        regionData: {} as Record<
            string,
            {
                regionCode: string;
                totalWeight: number;
                totalCbm: number;
                itemCount: number;
            }
        >,
        warehouseData: {} as Record<
            string,
            {
                warehouseName: string;
                totalWeight: number;
                totalCbm: number;
                itemCount: number;
            }
        >,
        shippingCountryData: {} as Record<
            string,
            {
                shippingCountryName: string;
                totalWeight: number;
                totalCbm: number;
                itemCount: number;
            }
        >,
    };

    const tableSummary = useMemo(() => {
        const summary = selectedRows.reduce((acc, curr) => {
            acc.totalItem += 1;

            // Process each product in the order
            curr.getProducts().forEach((product) => {
                const regionCode = product.getRegion()?.getCode() || "Unknown";

                // Status-wise aggregation
                const status = product.getStatus() || "Unknown";
                if (!acc.statusData[status]) {
                    acc.statusData[status] = 1;
                } else {
                    acc.statusData[status] += 1;
                }

                // Region-wise invoice aggregation for amount, paid, and due
                if (!acc.regionInvoiceData[regionCode]) {
                    acc.regionInvoiceData[regionCode] = {
                        regionCode,
                        totalAmount:
                            product.getCustomerInvoice()?.getTotalAmount() || 0,
                        totalPaid:
                            product
                                .getCustomerInvoice()
                                ?.getTransactionSummary()
                                .getPaid() || 0,
                        totalDue:
                            product
                                .getCustomerInvoice()
                                ?.getTransactionSummary()
                                .getDue() || 0,
                        currencyCode: product.getCurrency().getCode() || "",
                        itemCount: product.getCustomerInvoice() ? 1 : 0,
                    };
                } else {
                    acc.regionInvoiceData[regionCode].totalAmount +=
                        product.getCustomerInvoice()?.getTotalAmount() || 0;
                    acc.regionInvoiceData[regionCode].totalPaid +=
                        product
                            .getCustomerInvoice()
                            ?.getTransactionSummary()
                            .getPaid() || 0;
                    acc.regionInvoiceData[regionCode].totalDue +=
                        product
                            .getCustomerInvoice()
                            ?.getTransactionSummary()
                            .getDue() || 0;
                    acc.regionInvoiceData[regionCode].itemCount +=
                        product.getCustomerInvoice() ? 1 : 0;
                }

                // Region-wise aggregation
                if (!acc.regionData[regionCode]) {
                    acc.regionData[regionCode] = {
                        regionCode,
                        totalWeight: product.getTotalWeight(),
                        totalCbm: product.getAgentAssociation()?.getCbm() || 0,
                        itemCount: 1,
                    };
                } else {
                    acc.regionData[regionCode].totalWeight +=
                        product.getTotalWeight();
                    acc.regionData[regionCode].totalCbm +=
                        product.getAgentAssociation()?.getCbm() || 0;
                    acc.regionData[regionCode].itemCount += 1;
                }

                // Warehouse-wise aggregation
                const warehouseName =
                    product.getDestinationWarehouseName() || "Unknown";
                if (!acc.warehouseData[warehouseName]) {
                    acc.warehouseData[warehouseName] = {
                        warehouseName,
                        totalWeight: product.getTotalWeight(),
                        totalCbm: product.getAgentAssociation()?.getCbm() || 0,
                        itemCount: 1,
                    };
                } else {
                    acc.warehouseData[warehouseName].totalWeight +=
                        product.getTotalWeight();
                    acc.warehouseData[warehouseName].totalCbm +=
                        product.getAgentAssociation()?.getCbm() || 0;
                    acc.warehouseData[warehouseName].itemCount += 1;
                }

                // ShippingCountry-wise aggregation
                const shippingCountryName =
                    product.getShippingCountryName() || "Unknown";
                if (!acc.shippingCountryData[shippingCountryName]) {
                    acc.shippingCountryData[shippingCountryName] = {
                        shippingCountryName,
                        totalWeight: product.getTotalWeight(),
                        totalCbm: product.getAgentAssociation()?.getCbm() || 0,
                        itemCount: 1,
                    };
                } else {
                    acc.shippingCountryData[shippingCountryName].totalWeight +=
                        product.getTotalWeight();
                    acc.shippingCountryData[shippingCountryName].totalCbm +=
                        product.getAgentAssociation()?.getCbm() || 0;
                    acc.shippingCountryData[shippingCountryName].itemCount += 1;
                }
            });

            return acc;
        }, initialSummary);

        return { ...summary, totalSelected: selectedRows.length };
    }, [selectedRows]);

    const renderData = (
        label: string,
        valueKey: string,
        slug: string,
        type: "region" | "warehouse" | "shippingCountry",
    ) => {
        const data =
            type === "region"
                ? tableSummary.regionData
                : type === "warehouse"
                  ? tableSummary.warehouseData
                  : tableSummary.shippingCountryData;
        const name =
            type === "region"
                ? "regionCode"
                : type === "warehouse"
                  ? "warehouseName"
                  : "shippingCountryName";

        return (
            <Col span={8}>
                <ShippingText label={label}>
                    <Flex gap="middle" wrap="wrap">
                        {Object.values(data).map((item) => (
                            <Badge
                                text
                                overflowCount={1000}
                                count={item.itemCount}
                                key={item[name]}
                                color="green"
                            >
                                <StatusTag
                                    slug={slug}
                                    text={`${item[name]}: ${roundToTwoDecimal(item[valueKey])} ${valueKey === "totalWeight" ? `Kg` : ""}`}
                                />
                            </Badge>
                        ))}
                    </Flex>
                </ShippingText>
            </Col>
        );
    };

    const renderRegionInvoiceData = (
        label: string,
        valueKey: string,
        slug: string,
    ) => {
        return (
            <Col span={8}>
                <ShippingText label={label}>
                    <Flex gap="middle" wrap="wrap">
                        {Object.values(tableSummary.regionInvoiceData).map(
                            (item) => (
                                <Badge
                                    text
                                    overflowCount={1000}
                                    count={item.itemCount}
                                    key={item.regionCode}
                                    color="green"
                                >
                                    <StatusTag
                                        slug={slug}
                                        text={`${item.regionCode}: ${roundToTwoDecimal(item[valueKey])} ${item.currencyCode}`}
                                    />
                                </Badge>
                            ),
                        )}
                    </Flex>
                </ShippingText>
            </Col>
        );
    };

    const items: CollapseProps["items"] = [
        {
            key: "regionInvoice",
            label: "Invoice Overview",
            children: (
                <Row gutter={[16, 16]} align="middle" wrap>
                    {renderRegionInvoiceData("Amount", "totalAmount", "credit")}
                    {renderRegionInvoiceData("Paid", "totalPaid", "paid")}
                    {renderRegionInvoiceData("Due", "totalDue", "due")}
                </Row>
            ),
        },
        {
            key: "region",
            label: "Region-wise Overview",
            children: (
                <Row gutter={[16, 16]} align="middle" wrap>
                    {renderData("Weight", "totalWeight", "credit", "region")}
                    {renderData("Quantity", "totalCbm", "paid", "region")}
                </Row>
            ),
        },
        {
            key: "warehouse",
            label: "Warehouse-wise Overview",
            children: (
                <Row gutter={[16, 16]} align="middle" wrap>
                    {renderData("Weight", "totalWeight", "credit", "warehouse")}
                    {renderData("Quantity", "totalCbm", "paid", "warehouse")}
                </Row>
            ),
        },
        {
            key: "shippingCountry",
            label: "ShippingCountry-wise Overview",
            children: (
                <Row gutter={[16, 16]} align="middle" wrap>
                    {renderData(
                        "Weight",
                        "totalWeight",
                        "credit",
                        "shippingCountry",
                    )}
                    {renderData(
                        "Quantity",
                        "totalCbm",
                        "paid",
                        "shippingCountry",
                    )}
                </Row>
            ),
        },
        {
            key: "status",
            label: "Status-wise Overview",
            children: (
                <Row gutter={[16, 16]} align="middle" wrap>
                    <Col span={24}>
                        <ShippingText label="Status">
                            <Flex gap="middle" wrap="wrap">
                                {Object.entries(tableSummary.statusData).map(
                                    ([status, count]) => (
                                        <Badge
                                            overflowCount={1000}
                                            key={status}
                                            count={count}
                                            color="green"
                                        >
                                            <StatusTag
                                                slug={status}
                                                text={status}
                                            />
                                        </Badge>
                                    ),
                                )}
                            </Flex>
                        </ShippingText>
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <Card
            bordered={false}
            type="inner"
            title={`Selected Items: ${tableSummary.totalSelected}`}
            extra={
                <Button danger onClick={onClear}>
                    Clear
                </Button>
            }
        >
            <Collapse
                size="middle"
                accordion
                ghost
                items={items}
                defaultActiveKey={["regionInvoice"]}
            />
        </Card>
    );
};

export default ShipmentOrderTableSummary;
