import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";

import { useGetSearchProducts } from "@/lib/core-react/hooks/private/useProduct";
import {
    useDeleteCampaignProducts,
    useGetCampaignProductList,
} from "@/lib/core-react/hooks/private/useCampaign";
import ProductsSidebar from "./ProductsSidebar";
import { useAtom, useAtomValue } from "jotai";
import {
    campaignProductCollectionAtom,
    currentCurrencyAtom,
    inventoryProductSearchResultAtom,
} from "@/lib/core-react/store/store";
import { PageHeader } from "@ant-design/pro-layout";
import {
    Alert,
    Button,
    Checkbox,
    Col,
    Drawer,
    Empty,
    Flex,
    Modal,
    notification,
    Pagination,
    Row,
    Space,
    Typography,
} from "antd";
import {
    DeleteOutlined,
    FilterOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { PaginationProps } from "antd/lib";
import CommonError from "@/components/Error/CommonError";
import ProductCard from "../newComponents/ProductCard";
import LoaderSmall from "@/components/CustomFormElement/LoaderSmall";
import useToggleState from "@/hooks/useToggleState";
import usePageQuery from "@/helpers/usePageQuery";
import {
    allValuesFalsy,
    getFilterObjectKeys,
} from "../helpers/getUniqueArrayValues";
import { useGetCurrencyByRegionCode } from "@/lib/core-react/hooks/private/useRegion";
import { defaultQuery } from "@/consts/defaultQuery";
import {
    initialProductSearchQuery,
    productSearchQueryAtom,
} from "@/lib/core-react/store/inventoryProductsAtoms";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { IModalActionsType, IModalData } from "@/helpers/getModalTital";
import AddProductToCampaign from "../newComponents/AddToCampaign";
import { PageOperationEnum } from "../types";
import { isSelectable } from "../helpers/isSelectale";
import { IDeleteCampaignProducts } from "@/types/campaignCollection";
import { showError } from "@/helpers/showError";
import { tw } from "@/consts/theme/tailwindTheme";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import SearchBox from "../newComponents/SearchBox/SearchBox";
import { showSuccessAlert } from "@/helpers/showSuccess";

const InventoryProducts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const parsedQuery = qs.parse(location.search, { ignoreQueryPrefix: true });
    const operation = new URLSearchParams(location.search).get("operation");
    const region =
        new URLSearchParams(location.search).get("region") ||
        defaultQuery.region;

    const campaignId = new URLSearchParams(location.search).get("campaignId");
    const { isMobile } = useWindowWidth();
    const { page, ...rest } = parsedQuery;
    const { close, state, toggle } = useToggleState();

    const [productSearchQuery, setProductSearchQuery] = useAtom(
        productSearchQueryAtom,
    );
    const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
    const [productIds, setProductIds] = useState<string[]>([]);

    const productSearchResultModel = useAtomValue(
        inventoryProductSearchResultAtom,
    );
    const { data: currentCurrencyModel } = useAtomValue(currentCurrencyAtom);

    const campaignData = useAtomValue(campaignProductCollectionAtom);

    const products =
        operation == PageOperationEnum.VIEW_CAMPAIGN_PRODUCT
            ? campaignData?.getData()
            : productSearchResultModel?.getData();

    const { getCurrentQuery, resetQuery, updateQuery } = usePageQuery();
    const query = getFilterObjectKeys(getCurrentQuery());
    const {
        getProducts,
        error: errorInventoryProducts,
        isLoading: isLoadingInventoryProducts,
    } = useGetSearchProducts();
    const {
        getCampaignProducts,
        error: errorCampaignProducts,
        isLoading: isLoadingCampaignProducts,
    } = useGetCampaignProductList();

    const { getCurrencies } = useGetCurrencyByRegionCode();
    const { deleteCampaignProducts } = useDeleteCampaignProducts();

    const productDefaultParams = {
        ...defaultQuery,
        region: region,
        locale: defaultQuery.locale,
    };

    // ----------------------------api call------------------------
    const handleReset = () => {
        resetQuery();
        setProductSearchQuery(() => initialProductSearchQuery);
    };

    useEffect(() => {
        updateQuery({ ...productDefaultParams });
        getCurrencies({ region: region });
    }, []);

    useEffect(() => {
        setProductSearchQuery((prev) => ({ ...prev, ...query }));
    }, []);

    useEffect(() => {
        if (operation === PageOperationEnum.VIEW_CAMPAIGN_PRODUCT) {
            if (campaignId) {
                getCampaignProducts(campaignId);
            }
        } else {
            // Fetch products based on search query or default params
            if (allValuesFalsy(productSearchQuery)) {
                getProducts(productDefaultParams);
            } else {
                getProducts(
                    productDefaultParams,
                    getFilterObjectKeys(productSearchQuery),
                );
            }
        }
    }, [productSearchQuery, operation, campaignId]);

    // ------------------------------logic------------------------------

    const handleOnSearch = (values: {
        image_url: string;
        country_codes: string[];
        keyword: string;
    }) => {
        setProductIds([]);
        setProductSearchQuery((prev) => ({
            ...prev,
            image_url: values.image_url,
            keyword: values.keyword,
            country_codes: values.country_codes,
        }));
    };
    const handleResetOnSearch = () => {
        setProductIds([]);
        getProducts(defaultQuery, {
            country_codes: initialProductSearchQuery.country_codes,
        });
        setCurrentPage(1);
        updateQuery({ ...productDefaultParams, page: 1 });
    };

    const handleSingleSelect = (e, productId) => {
        const isChecked = e.target.checked;

        if (isChecked) {
            setProductIds((prev) => {
                const uniqueIds = new Set([...prev, productId]);
                return Array.from(uniqueIds);
            });
        } else {
            const updatedIds = productIds.filter((id) => id !== productId);
            setProductIds(() => updatedIds);
        }
    };

    const handleAllSelect = (e: CheckboxChangeEvent) => {
        const isChecked = e.target.checked;

        if (productSearchResultModel && isChecked) {
            const ids = productSearchResultModel.getAllProductIds();

            setProductIds((prev) => {
                const uniqueIds = new Set([...prev, ...ids]);
                return Array.from(uniqueIds);
            });
        } else {
            setProductIds(() => []);
        }
    };

    const handleDeleteCampaignProduct = async () => {
        try {
            if (productIds.length === 0) {
                throw new Error("Please select at least one product");
            }
            Modal.confirm({
                title: "Are you want to delete these products from campaign?",
                content: "Select Ok, otherwise the process cannot success.",
                onOk: async () => {
                    try {
                        const payload: IDeleteCampaignProducts = {
                            ids: productIds,
                        };
                        await deleteCampaignProducts(
                            campaignId as string,
                            payload,
                        );
                        showSuccessAlert(
                            "Successfully Campaign Product Deleted",
                        );
                        setProductIds([]);
                        if (campaignId) {
                            getCampaignProducts(campaignId);
                        }

                        return Promise.resolve();
                    } catch (error) {
                        showError(error);
                        return Promise.reject(error);
                    }
                },
                onCancel() {
                    notification["info"]({
                        message: "Product Reject was aborted.",
                    });
                },
            });
        } catch (error) {
            showError(error);
        }
    };

    const onChangePage: PaginationProps["onChange"] = async (
        page,
        _per_page,
    ) => {
        setCurrentPage(page);
        setProductIds(() => []);
        if (
            operation === PageOperationEnum.VIEW_CAMPAIGN_PRODUCT &&
            campaignId
        ) {
            await getCampaignProducts(campaignId, {
                page,
                ...rest,
            });
        } else {
            await getProducts(
                {
                    ...productDefaultParams,
                    page,
                },
                getFilterObjectKeys(productSearchQuery),
            );
            updateQuery({ ...productDefaultParams, page });
        }
    };

    const [modalOpenType, setModalOpenType] =
        useState<IModalActionsType>(false);

    const handleModal = async (payload: IModalData) => {
        setModalOpenType(payload.action);
        switch (payload.action) {
            case "delete_product_from_campaign":
                await handleDeleteCampaignProduct();
                break;

            default:
                break;
        }
    };

    // ------------------------------ui------------------------------

    if (errorInventoryProducts || errorCampaignProducts) {
        return (
            <CommonError
                message={
                    errorInventoryProducts?.message ||
                    errorCampaignProducts?.message
                }
                code={
                    errorInventoryProducts?.code || errorCampaignProducts?.code
                }
            />
        );
    }

    const getPageHeaderTitle = (
        operator: string | undefined | null,
    ): string => {
        switch (operator) {
            case PageOperationEnum.ADD_CAMPAIGN_PRODUCT:
                return "Add Products To Campaign";
            case PageOperationEnum.VIEW_CAMPAIGN_PRODUCT:
                return "Campaign Product List";
            case PageOperationEnum.AddBuyOrderProduct:
            case PageOperationEnum.PlaceBuyOrder:
                return "Select a Product";
            default:
                return "Product List";
        }
    };

    return (
        <div>
            <PageHeader
                style={{
                    marginBottom: 0,
                    marginTop: 0,
                }}
                onBack={() => navigate(-1)}
                extra={
                    operation !== PageOperationEnum.VIEW_CAMPAIGN_PRODUCT && (
                        <Flex justify="end">
                            <Button
                                icon={<FilterOutlined />}
                                type="primary"
                                onClick={toggle}
                            >
                                Filter
                            </Button>
                        </Flex>
                    )
                }
                title={getPageHeaderTitle(operation)}
            >
                {operation !== PageOperationEnum.VIEW_CAMPAIGN_PRODUCT && (
                    <Flex
                        justify="center"
                        style={{
                            marginBottom: 20,
                        }}
                    >
                        <SearchBox
                            isLoading={isLoadingInventoryProducts}
                            handleResetOnSearch={handleResetOnSearch}
                            handleOnSearch={handleOnSearch}
                        />
                    </Flex>
                )}

                {isSelectable() && (
                    <Flex
                        align="center"
                        justify="start"
                        style={{
                            marginBottom: 32,
                        }}
                    >
                        <Checkbox
                            disabled={isLoadingInventoryProducts}
                            checked={
                                productIds.length ===
                                productSearchResultModel
                                    ?.getPagination()
                                    .getPerPage()
                            }
                            style={{
                                minWidth: 120,
                            }}
                            onChange={handleAllSelect}
                        >
                            <Space>
                                <Typography.Text>
                                    {" "}
                                    {productIds.length ===
                                    productSearchResultModel
                                        ?.getPagination()
                                        .getPerPage()
                                        ? "Deselect"
                                        : "Select"}{" "}
                                    All
                                </Typography.Text>
                                {productIds.length > 0 && (
                                    <Typography.Text>
                                        {productIds.length}
                                    </Typography.Text>
                                )}
                            </Space>
                        </Checkbox>

                        {operation ===
                            PageOperationEnum.ADD_CAMPAIGN_PRODUCT && (
                            <Button
                                disabled={productIds.length === 0}
                                type="primary"
                                htmlType="button"
                                icon={<PlusOutlined />}
                                onClick={() =>
                                    handleModal({
                                        action: "add_product_to_campaign",
                                    })
                                }
                            >
                                Add
                            </Button>
                        )}
                        {operation ===
                            PageOperationEnum.VIEW_CAMPAIGN_PRODUCT && (
                            <Button
                                disabled={productIds.length === 0}
                                type="dashed"
                                htmlType="button"
                                icon={<DeleteOutlined />}
                                onClick={() =>
                                    handleModal({
                                        action: "delete_product_from_campaign",
                                    })
                                }
                            >
                                Delete
                            </Button>
                        )}
                    </Flex>
                )}

                <Row>
                    <Col flex="1 1 260px">
                        <div
                            style={{
                                minHeight: "65vh",
                                overflow: "hidden",
                            }}
                        >
                            {isLoadingInventoryProducts ||
                            isLoadingCampaignProducts ? (
                                <div
                                    style={{
                                        marginTop: 150,
                                    }}
                                >
                                    {LoaderSmall}
                                </div>
                            ) : products && products.length > 0 ? (
                                <div
                                    style={{
                                        display:
                                            products.length <= 4
                                                ? "flex"
                                                : "grid",
                                        flexDirection: isMobile
                                            ? "column"
                                            : "row",
                                        flexWrap: "wrap",
                                        flex: tw.flex[1],
                                        justifyContent: "center",
                                        alignContent: "center",
                                        gridTemplateColumns:
                                            "repeat(auto-fill, minmax(260px, 1fr))",
                                        gap: "20px",
                                        width: "100%",
                                        paddingBottom: 32,
                                    }}
                                >
                                    {products &&
                                        products.map((item) => {
                                            const product =
                                                item.object ===
                                                "CampaignProduct"
                                                    ? item.getProduct()
                                                    : item;
                                            return (
                                                <ProductCard
                                                    key={product.getId()}
                                                    isSelectable={isSelectable()}
                                                    isChecked={productIds.includes(
                                                        product.getId(),
                                                    )}
                                                    onSelect={
                                                        handleSingleSelect
                                                    }
                                                    productId={product.getId()}
                                                    currencySymbol={
                                                        currentCurrencyModel?.getCurrencySymbol() ||
                                                        ""
                                                    }
                                                    discountPrice={product
                                                        .getPrice()
                                                        .getDiscountPrice()}
                                                    originalPrice={product
                                                        .getPrice()
                                                        .getOriginalPrice()}
                                                    ratings={product.getRatingsAverage()}
                                                    ratingCount={product.getRatingsCount()}
                                                    title={product.getTitle()}
                                                    sales={product.getSales()}
                                                    imageUrl={product.getImage()}
                                                    shippingCategoryName={`${product?.getShippingCategory() ? product?.getShippingCategory().getName() : ""}`}
                                                    grossWeight={`${
                                                        product?.getMeasurement()
                                                            ? product
                                                                  .getMeasurement()
                                                                  ?.getGrossWeight()
                                                            : ""
                                                    }`}
                                                    UnitWeight={`${
                                                        product?.getMeasurement()
                                                            ? product
                                                                  .getMeasurement()
                                                                  ?.getWeightUnit()
                                                            : ""
                                                    }`}
                                                />
                                            );
                                        })}
                                </div>
                            ) : (
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{ height: 100, marginTop: 32 }}
                                    description={
                                        <Alert
                                            message="Products Not Found"
                                            type="info"
                                        />
                                    }
                                />
                            )}
                        </div>
                    </Col>

                    <Drawer
                        title={
                            <Flex align="center" justify="space-between">
                                <Typography.Paragraph>
                                    Filter Products
                                </Typography.Paragraph>
                                <Button
                                    onClick={handleReset}
                                    type="dashed"
                                    htmlType="button"
                                >
                                    Clear Filter
                                </Button>
                            </Flex>
                        }
                        onClose={close}
                        open={state}
                    >
                        <ProductsSidebar />
                    </Drawer>
                </Row>

                <Pagination
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                    onChange={onChangePage}
                    showSizeChanger={false}
                    current={currentPage}
                    pageSize={productSearchResultModel
                        ?.getPagination()
                        .getPerPage()}
                    total={
                        operation === PageOperationEnum.VIEW_CAMPAIGN_PRODUCT
                            ? campaignData?.getPagination().getTotal()
                            : productSearchResultModel
                                  ?.getPagination()
                                  .getTotal()
                    }
                />
            </PageHeader>

            {campaignId && region && (
                <Modal
                    title="Add Configuration"
                    open={modalOpenType === "add_product_to_campaign"}
                    onCancel={() => {
                        handleModal({ action: false });
                    }}
                    footer={null}
                >
                    <AddProductToCampaign
                        handleModal={handleModal}
                        region={region}
                        campaignId={campaignId}
                        selectedProducts={productIds}
                    />
                </Modal>
            )}
        </div>
    );
};

export default InventoryProducts;
