export enum PaymentGatewayCollectionEnums {
    PaymentGatewayCollection = "PaymentGatewayCollection",
    PaymentGateway = "PaymentGateway",
}

export enum PaymentGatewayStatusEnums {
    ACTIVE = "active",
    INACTIVE = "inactive",
}

export enum PaymentGatewayFeeTypeEnums {
    FIXED = "fixed",
    PERCENTAGE = "percentage",
}

export enum PaymentGatewayIdentifierEnums {
    BKASH = "bkash",
    SSL_COMMERZ = "ssl-commerz",
    TELR = "telr",
    STRIPE = "stripe",
    NAGAD = "nagad",
    UPAY_MERCHANT = "upay-merchant",
    ROCKET_MERCHANT = "rocket-merchant",
    BRAC_BANK = "brac-bank",
    CITY_BANK = "city-bank",
    DBBL_BANK = "dbbl-bank",
    CASH_PAYMENT = "cash-payment",
    MASHREQ = "mashreq",
}

export enum PaymentGatewayLogoTypeEnums {
    SMALL = "small",
    MEDIUM = "medium",
}

export enum PaymentGatewayTypeEnums {
    // AUTOMATED = 'automated',
    // MOBILE_MERCHANT = 'mobile-merchant',
    // BANK_DEPOSIT = 'bank-deposit'

    AUTOMATIC_PAYMENT = "automatic-payment",
    BANK_DEPOSIT = "bank-deposit",
    WALLET = "wallet",
    MOBILE_MERCHANT = "mobile-merchant",
}
