import { ShipmentProductModel } from "@/models/shipmentProductCollectionModel";
import { formatDateTime } from "@/utils/helpers";
import {
    CheckCircleOutlined,
    ClockCircleOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { Space, Tag } from "antd";
import { memo } from "react";
const SubTitleShipmentProductDetails = ({
    shipmentProductModel,
}: {
    shipmentProductModel: ShipmentProductModel | undefined;
}) => {
    return (
        <Space style={{ textTransform: "lowercase" }}>
            <Tag
                icon={
                    shipmentProductModel?.getOrder().getPaymentStatus() ===
                        "invoice-unpaid" ||
                    shipmentProductModel?.getOrder().getPaymentStatus() ===
                        "pending" ? (
                        <SyncOutlined spin />
                    ) : (
                        <CheckCircleOutlined />
                    )
                }
                color={
                    shipmentProductModel?.getOrder().getPaymentStatus() ===
                        "invoice-unpaid" ||
                    shipmentProductModel?.getOrder().getPaymentStatus() ===
                        "pending"
                        ? "processing"
                        : "success"
                }
            >
                invoice{" "}
                {shipmentProductModel
                    ?.getOrder()
                    .getPaymentStatus()
                    .replaceAll("-", " ")}
            </Tag>
            <Tag
                icon={
                    shipmentProductModel?.getOrder().getPaymentStatus() ===
                    "pending" ? (
                        <SyncOutlined spin />
                    ) : (
                        <CheckCircleOutlined />
                    )
                }
                color={
                    shipmentProductModel?.getOrder().getPaymentStatus() ===
                    "pending"
                        ? "processing"
                        : "success"
                }
            >
                order {shipmentProductModel?.getOrder().getPaymentStatus()}
            </Tag>
            <Tag
                icon={
                    shipmentProductModel?.getStatus() === "shipment-pending" ? (
                        <SyncOutlined spin />
                    ) : (
                        <CheckCircleOutlined />
                    )
                }
                color={
                    shipmentProductModel?.getStatus() === "shipment-pending"
                        ? "processing"
                        : "success"
                }
            >
                shipment {shipmentProductModel?.getStatusLabel()}
            </Tag>

            <Space size="small">
                <ClockCircleOutlined />
                {formatDateTime(shipmentProductModel?.getOrder().created_at)}
            </Space>
        </Space>
    );
};

export default memo(SubTitleShipmentProductDetails);
