import {
    ICurrency,
    ICurrencyCollection,
    ICurrentCurrency,
} from "@/types/currencyCollection";
import { ILanguageCollection } from "@/types/languageCollection";

export class CurrentCurrencyModel {
    object: "Region";
    id: number;
    name: string;
    code: string;
    store_type: string; // "global";
    store: string;
    currency: ICurrency;
    countries?: ICurrencyCollection;
    languages?: ILanguageCollection;

    constructor(data: ICurrentCurrency) {
        this.object = data.object;
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.store_type = data.store_type;
        this.store = data.store;
        this.currency = data.currency;
    }

    getId = () => this.id;
    getName = () => this.name;
    getCode = () => this.code;
    getCurrencySymbol = () => this.currency.symbol;
    getCurrencyCode = () => this.currency.code;
}
