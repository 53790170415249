import { ITranslationEntry } from "../lang";

export const SM: ITranslationEntry = {
    translations: {
        notifications: "faasilasilaga",
        markAllAsRead: "faailoga Mea uma e pei ona Faitau",
        poweredBy: "fa amalosia ",
        settings: "faatulagaga",
        noNewNotification: "E leai se mea fou e va'ai iinei",
    },
    lang: "sm",
};
