import { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Dropdown, Row, Table, Col, Button, Tooltip, Modal, Tag } from "antd";
import {
    DownOutlined,
    ClusterOutlined,
    HddFilled,
    EditOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { useAtom } from "jotai";

import { permissionAtom, roleAtom } from "@/lib/core-react/store/store";
import { useGetPermission } from "@/lib/core-react/hooks/private/usePermission";
import { useGetRole } from "@/lib/core-react/hooks/private/useRole";
import useDataFilters from "@/hooks/useDataFilters";
import PermissionsDrawer from "./components/PermissionsDrawer";
import RoleModification from "./components/RoleModification";
import { RoleCollectionModel, RoleModel } from "@/models/roleCollectionModel";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import { PermissionCollectionModel } from "@/models/permissionCollectionModel";
import { v4 } from "uuid";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ADMIN_ACL_PERMISSION_ENUM } from "@/consts/permission-enum/ACL-enum";
import { ExtendedMenuItemType } from "@/types";
import CommonError from "@/components/Error/CommonError";

const Role = () => {
    const { getRole } = useGetRole();
    const [{ data: roleData, isLoading, refetch, error, unAuthorized, code }] =
        useAtom(roleAtom);
    const { isMobile } = useWindowWidth();
    const { getPermission } = useGetPermission();
    const [{ data: permissionData }] = useAtom(permissionAtom);

    const { filters, handleFilterChange, isFirstCall, isFetched } =
        useDataFilters();

    const RoleData = roleData && new RoleCollectionModel(roleData);
    const PermissionData =
        permissionData && new PermissionCollectionModel(permissionData);

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(filters, getRole);
            ApiHelperModel.makeGetRequest({}, getPermission);
        }
    }, [isFirstCall, filters, isFetched, refetch]);

    // Pagination
    const paginationData = RoleData?.pagination;

    const [selectedRole, setSelectedRole] = useState<RoleModel | undefined>(
        undefined,
    );
    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);
    const [isShowPermissionModal, setIsShowPermissionModal] =
        useState<boolean>(false);

    const handleMenuClick = (key: string, record: RoleModel) => {
        setSelectedRole(record);
        if (key === "update") {
            setIsShowUpdateModal(true);
        }
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getRole,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        RoleData,
        handlePaginationChange,
    );

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "sl",
            width: 70,
            align: "center" as const,
            render: (_: string, __: RoleModel, index: number) => {
                return (
                    <div>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </div>
                );
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text: string) => {
                return (
                    <>
                        <Row>{text}</Row>
                    </>
                );
            },
        },
        {
            title: "Is Order Handler",
            dataIndex: "is_order_handler",
            key: "is_order_handler",
            render: (is_order_handler: string) => {
                return (
                    <>
                        <Tag color={is_order_handler ? "green" : "yellow"}>
                            {is_order_handler ? "Yes" : "No"}
                        </Tag>
                    </>
                );
            },
        },
        {
            title: "Is Customer",
            dataIndex: "is_customer",
            key: "is_customer",
            render: (is_customer: string) => {
                return (
                    <>
                        <Tag color={is_customer ? "green" : "yellow"}>
                            {is_customer ? "Yes" : "No"}
                        </Tag>
                    </>
                );
            },
        },
        {
            title: "Permissions",
            dataIndex: "permissions",
            key: "label",
            width: 70,
            hidden:
                isMobile ||
                !checkActionPermission(
                    [
                        ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_MANAGE,
                        ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW,
                    ],
                    "hasPermission",
                    null,
                ),
            align: "center" as const,
            render: (_: PermissionCollectionModel, record: RoleModel) => (
                <>
                    {checkActionPermission(
                        [
                            ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_MANAGE,
                            ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW,
                        ],

                        <Tooltip title="View Permissions">
                            <Button
                                onClick={() => {
                                    setSelectedRole(record);
                                    setIsShowPermissionModal(true);
                                }}
                                type="text"
                                icon={<ClusterOutlined />}
                            />
                        </Tooltip>,
                        null,
                    )}
                </>
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            fixed: isMobile ? "right" : undefined,
            width: isMobile ? 100 : undefined,
            align: "center" as const,
            hidden: !checkActionPermission(
                [
                    ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_MANAGE,
                    ...(isMobile
                        ? [ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW]
                        : []),
                ],
                "permissionCheck",
                null,
            ),
            render: (_, record) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        key: "1",
                        permission: ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_MANAGE,
                        label: (
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                    handleMenuClick("update", record)
                                }
                            >
                                <EditOutlined /> <span> Update</span>
                            </div>
                        ),
                    },
                ];

                if (isMobile) {
                    items.push({
                        label: "Permission",
                        permission: [
                            ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_MANAGE,
                            ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_VIEW,
                        ],
                        onClick: () => {
                            setSelectedRole(record);
                            setIsShowPermissionModal(true);
                        },
                        key: "details",
                        icon: <ClusterOutlined />,
                    });
                }

                const menuItems = items.filter((x) =>
                    checkActionPermission(x.permission, x, null),
                );
                if (menuItems.length) {
                    return (
                        <Dropdown
                            menu={{
                                items: menuItems,
                            }}
                        >
                            <Button icon={<HddFilled />}>
                                {isMobile ? (
                                    <DownOutlined />
                                ) : (
                                    <>
                                        Actions <DownOutlined />
                                    </>
                                )}
                            </Button>
                        </Dropdown>
                    );
                } else {
                    return null;
                }
            },
        },
    ];

    const onHide = () => {
        setIsShowPermissionModal(false);
        setIsShowUpdateModal(false);
        setIsShowCreateModal(false);
        setSelectedRole(undefined);
    };

    if (!isLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={"Roles"}
                        extra={[
                            checkActionPermission(
                                ADMIN_ACL_PERMISSION_ENUM.ADMIN_ROLE_MANAGE,
                                <Button
                                    key={v4()}
                                    onClick={() => {
                                        setIsShowCreateModal(true);
                                    }}
                                    icon={<PlusOutlined />}
                                    type="primary"
                                >
                                    Create new role
                                </Button>,
                                null,
                            ),
                        ]}
                        onBack={() => window.history.back()}
                    >
                        <Table
                            //@ts-ignore
                            columns={columns}
                            dataSource={RoleData?.data}
                            loading={isLoading}
                            style={{ width: "100%" }}
                            size="middle"
                            pagination={{
                                ...paginationConfig,
                                showTotal: (total) => (
                                    <div>
                                        Total{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                color: "green",
                                            }}
                                        >
                                            {total}
                                        </span>{" "}
                                        Items
                                    </div>
                                ),
                            }}
                            rowKey="id"
                            scroll={{ x: 500 }}
                        />
                    </PageHeader>
                </Col>
            </Row>

            <Modal
                title={`Create Role`}
                open={isShowCreateModal}
                footer={false}
                onCancel={() => setIsShowCreateModal(false)}
            >
                <RoleModification onHide={onHide} />
            </Modal>

            {selectedRole && (
                <Modal
                    title={`Update Role`}
                    open={isShowUpdateModal}
                    footer={false}
                    onCancel={onHide}
                >
                    <RoleModification onHide={onHide} role={selectedRole} />
                </Modal>
            )}

            {selectedRole && PermissionData && (
                <PermissionsDrawer
                    show={isShowPermissionModal}
                    onHide={onHide}
                    allPermissions={PermissionData}
                    role={selectedRole}
                />
            )}
        </>
    );
};

export default Role;
