import React, { useEffect, useState, createRef } from "react";
import { Alert, Button, Form, Input, notification, Select } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { useCreateCustomerUser } from "@/lib/core-react/hooks/private/useCustomerUser";
import { Forms } from "@/models/form";
import { CustomerUserPostRequestModel } from "@/models/customerUserCollectionModel";
import PhoneInput from "antd-phone-input";
import { Option } from "antd/es/mentions";
import { showError } from "@/helpers/showError";

interface IProps {
    setIsShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateCustomerUser = ({ setIsShowCreateModal }: IProps) => {
    const { createCustomerUser, isLoading, isError } = useCreateCustomerUser();
    const [isErrorVisible, setIsErrorVisible] = useState(false);

    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        form.resetFields();
    }, [form]);

    const onFinish = async (value) => {
        const formattedPhone = `+${value.phone.countryCode}${value.phone.areaCode}${value.phone.phoneNumber}`;
        const payload = new CustomerUserPostRequestModel({
            name: value.name,
            email: value.email,
            phone: formattedPhone,
            password: value.password,
            password_confirmation: value.password_confirmation,
            is_active: true,
        });

        try {
            const res = await createCustomerUser(payload);
            setIsShowCreateModal(false);
            notification["success"]({
                message: res.message,
            });
            form.resetFields();
        } catch (error: any) {
            showError(error, form);
        }
    };

    const validator = (_, { valid }) => {
        if (valid()) return Promise.resolve();
        return Promise.reject("Invalid phone number");
    };

    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Add User"
            onFieldsChange={onValuesChange}
            ref={formRef}
        >
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "User name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="User name" />
            </Form.Item>

            <Form.Item
                rules={[
                    { required: true, message: "Email is required" },
                    {
                        type: "email",
                        message: "Please enter a valid email address",
                    },
                ]}
                label="Email"
                name="email"
            >
                <Input placeholder="Email" type="email" autoComplete="email" />
            </Form.Item>

            <Form.Item rules={[{ validator }]} label="Phone" name="phone">
                <PhoneInput enableSearch />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Password is required" }]}
                label="Password"
                name="password"
            >
                <Input.Password placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item
                rules={[
                    {
                        required: true,
                        message: "Password Confirmation is required",
                    },
                ]}
                label="Password Confirmation"
                name="password_confirmation"
            >
                <Input.Password placeholder="Password" type="password" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Status is required" }]}
                label="Status"
                name="is_active"
            >
                <Select placeholder="Select status">
                    <Option value="1">Active</Option>
                    <Option value="0">Inactive</Option>
                </Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>

            {isErrorVisible && (
                <Alert
                    style={{ marginTop: "7px" }}
                    message={isError}
                    closable
                    afterClose={() => setIsErrorVisible(false)}
                    type="error"
                    showIcon
                />
            )}
        </Form>
    );
};

export default CreateCustomerUser;
