//Operations

export enum ShipmentProductOperationEnum {
    AddShipmentProductPackage = "add-shipment-product-package",
}

export enum ShipmentProductCollectionEnums {
    ShipmentProductCollection = "ShipmentProductCollection",
    ShipmentProduct = "ShipmentProduct",
}

export enum ShipmentProductMeasurementPackagesWeightUnitEnum {
    KG = "kg",
    LB = "lb",
}

export enum ShipmentProductMeasurementPackagesLengthUnitEnum {
    CM = "cm",
    In = "in",
}

export enum ShipmentProductMeasurementPackagesWidthUnitEnum {
    CM = "cm",
    In = "in",
}

export enum ShipmentProductMeasurementPackagesHeightUnitEnum {
    CM = "cm",
    In = "in",
}

export enum ShipmentProductMeasurementPackagesCbmUnitEnum {
    CM = "cm",
    In = "in",
}

export enum ShipmentProductCommonUnitEnum {
    CM = "cm",
    IN = "in",
}
