import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { ICourierGatewayForm } from "@/types/courierGatewayCollection";

class CourierGatewayResource extends BaseResource {
    path = `/api/courier/admin/courier-gateway/v1/courier-gateways`;

    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}?expand=country${params ? `&${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    create(
        payload: ICourierGatewayForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    update(
        id: number,
        payload: ICourierGatewayForm,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }

    delete(
        id: number,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request(
            "DELETE",
            path,
            undefined,
            {},
            customHeaders,
        );
    }
}

export default CourierGatewayResource;
