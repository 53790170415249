import {
    Alert,
    Button,
    Col,
    Flex,
    Form,
    InputNumber,
    Row,
    Typography,
} from "antd";
import { BuyProductModel } from "@/models/buyProductCollectionModel";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
    IShippingRateType,
    IShippingRateUpdatePayload,
} from "@/types/buyOrderCollection";
import {
    useBuyProductsShippingPriceUpdate,
    useGetBuyOrders,
    useGetBuyOrdersDetail,
    useGetBuyProduct,
} from "@/lib/core-react/hooks/private/usePurchase";
import { IModalData } from "@/helpers/getModalTital";
import { showError } from "@/helpers/showError";
import { useAtom } from "jotai";
import { buyProductCollectionAtom } from "@/lib/core-react/store/buyProducts/buyProductsAtoms";
import { useParams } from "react-router-dom";
import {
    BUY_ORDER_DETAIL_PAGE,
    BUY_ORDER_LIST_PAGE,
    BUY_PRODUCT_DETAIL_PAGE,
    BUY_PRODUCT_LIST_PAGE,
} from "@/consts/pageRoute";
import { showSuccessAlert } from "@/helpers/showSuccess";
import getPagePathname from "@/helpers/getPagePathname";
import usePageQuery from "@/helpers/usePageQuery";
import { parseObjectToString } from "@/helpers/stringify";

interface IProps {
    handleModal: (payload: IModalData) => void;
    selectedProduct: BuyProductModel;
}

const UpdateShippingPrice: React.FC<IProps> = ({
    selectedProduct,
    handleModal,
}) => {
    const [form] = Form.useForm();
    const { buyProductShippingPriceUpdate, isLoading } =
        useBuyProductsShippingPriceUpdate();
    const { getBuyProduct } = useGetBuyProduct();
    const { getBuyOrders } = useGetBuyOrders();
    const { getBuyOrdersDetail } = useGetBuyOrdersDetail();
    const { getCurrentQuery } = usePageQuery();
    const { id } = useParams();

    const [, setBuyProductCollection] = useAtom(buyProductCollectionAtom);
    const onFinish = async (values: IShippingRateType) => {
        const payload: IShippingRateUpdatePayload = {
            rate: values.new_rate,
            slots:
                values.slots?.map((slot) => ({
                    min_amount: slot.min_amount,
                    max_amount: slot.max_amount,
                    rate: slot.rate,
                })) || [],
        };

        try {
            await buyProductShippingPriceUpdate(
                selectedProduct.getId(),
                payload,
            );
            showSuccessAlert("successfully Updated Shipping Price");
            handleModal({
                action: false,
                data: null,
            });

            if (getPagePathname() === `${BUY_PRODUCT_DETAIL_PAGE}/${id}`) {
                getBuyProduct(Number(id));
            } else if (getPagePathname() === `${BUY_PRODUCT_LIST_PAGE}`) {
                setBuyProductCollection((pre) => ({
                    ...pre,
                    refetch: true,
                    isLoading: false,
                }));
            } else if (getPagePathname() === `${BUY_ORDER_LIST_PAGE}`) {
                getBuyOrders(parseObjectToString(getCurrentQuery()));
            }
            if (id && getPagePathname() === `${BUY_ORDER_DETAIL_PAGE}/${id}`) {
                getBuyOrdersDetail(id);
            }
        } catch (error: any) {
            showError(error, form);
        }
    };

    return (
        <Row>
            <Col span={24}>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    initialValues={{
                        new_rate:
                            selectedProduct.getMoveonShipping()?.getRate() || 0,
                        slots: selectedProduct.getMoveonShipping()?.getSlots()
                            .length
                            ? selectedProduct
                                  .getMoveonShipping()
                                  ?.getSlots()
                                  .map((rate) => ({
                                      min_amount: rate.min_amount,
                                      max_amount: rate.max_amount,
                                      rate: rate.rate,
                                  }))
                            : [
                                  {
                                      min_amount: 0,
                                      max_amount: 0,
                                      rate: 0,
                                  },
                              ],
                    }}
                >
                    <Form.Item
                        label="New Rate"
                        name="new_rate"
                        rules={[
                            {
                                required: true,
                                message: "Please enter a new rate",
                            },
                        ]}
                    >
                        <InputNumber style={{ width: "100%" }} />
                    </Form.Item>

                    <Form.List name="slots">
                        {(fields, { add, remove }) => (
                            <div>
                                <div>
                                    {fields.length > 0 ? (
                                        fields.map(
                                            (
                                                { key, name, ...restField },
                                                index,
                                            ) => (
                                                <div>
                                                    <Typography.Paragraph
                                                        strong
                                                    >
                                                        Slot No: {index + 1}
                                                    </Typography.Paragraph>
                                                    <Flex
                                                        gap={12}
                                                        align="center"
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            label="Min"
                                                            name={[
                                                                name,
                                                                "min_amount",
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                    message:
                                                                        "min amount required",
                                                                },
                                                                {
                                                                    validator: (
                                                                        _,
                                                                        value,
                                                                    ) => {
                                                                        if (
                                                                            value >
                                                                            0
                                                                        ) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                "Amount must be greater than 0",
                                                                            ),
                                                                        );
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Max"
                                                            name={[
                                                                name,
                                                                "max_amount",
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                    message:
                                                                        "max amount required",
                                                                },
                                                                {
                                                                    validator: (
                                                                        _,
                                                                        value,
                                                                    ) => {
                                                                        if (
                                                                            value >
                                                                            0
                                                                        ) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                "Amount must be greater than 0",
                                                                            ),
                                                                        );
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label="Rate"
                                                            name={[
                                                                name,
                                                                "rate",
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                    message:
                                                                        "rate is required",
                                                                },
                                                                {
                                                                    validator: (
                                                                        _,
                                                                        value,
                                                                    ) => {
                                                                        if (
                                                                            value >
                                                                            0
                                                                        ) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(
                                                                            new Error(
                                                                                "Amount must be greater than 0",
                                                                            ),
                                                                        );
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                            />
                                                        </Form.Item>

                                                        {fields.length > 1 && (
                                                            <Button
                                                                type="link"
                                                                onClick={() =>
                                                                    remove(name)
                                                                }
                                                                icon={
                                                                    <MinusCircleOutlined />
                                                                }
                                                                style={{
                                                                    marginLeft:
                                                                        "16px",
                                                                    marginTop: 8,
                                                                }}
                                                            />
                                                        )}
                                                    </Flex>
                                                </div>
                                            ),
                                        )
                                    ) : (
                                        <Alert
                                            message="Add Rate slots field is hide.  Please click Add Rate"
                                            type="info"
                                        />
                                    )}
                                </div>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ marginTop: "20px" }}
                                >
                                    <PlusOutlined /> Add Slots
                                </Button>
                            </div>
                        )}
                    </Form.List>
                    <Form.Item noStyle>
                        <Flex justify="end">
                            <Button
                                loading={isLoading}
                                disabled={isLoading}
                                type="primary"
                                htmlType="submit"
                            >
                                Update
                            </Button>
                        </Flex>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default UpdateShippingPrice;
