import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState, useCallback } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";

export const useGetRegionMinimal = () => {
    const { coreService } = useService();
    const [regionOptionsData, setRegionOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getRegion = useCallback(
        async (params: string): Promise<void> => {
            try {
                if (!isMinimalQuery(params)) {
                    throw new Error(errorMessageMinimal);
                }
                setRegionOptionsData((prev) => ({ ...prev, isLoading: true }));

                const response = await coreService.RegionResource.get(params);

                const options =
                    response && response.data
                        ? new SelectOptionModel(response.data).getOptions()
                        : [];

                setRegionOptionsData({
                    isLoading: false,
                    options,
                });
            } catch (_) {
                setRegionOptionsData({
                    isLoading: false,
                    options: [],
                });
            }
        },
        [coreService],
    );

    return { getRegion, regionOptionsData } as const;
};
