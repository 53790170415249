import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal } from "@/filters/utils";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { BaseShippingCategoryModel } from "@/models/baseShippingCategory";

export const useGetBaseShippingCategoryMinimal = () => {
    const { shippingCoreService } = useService();
    const [
        baseShippingCategoryOptionsData,
        setBaseShippingCategoryOptionsData,
    ] = useState<IResponseOptions>(defaultDataMinimal);

    const getBaseShippingCategory = async (params: string): Promise<void> => {
        try {
            setBaseShippingCategoryOptionsData((prev) => ({
                ...prev,
                isLoading: true,
            }));

            const response =
                await shippingCoreService.baseShippingCategoryResource.get(
                    params,
                );
            const baseShippingCategoryTreeIds =
                response &&
                new BaseShippingCategoryModel(
                    response,
                ).getBaseShippingCategoryTreeIds();

            setBaseShippingCategoryOptionsData({
                isLoading: false,
                options: baseShippingCategoryTreeIds || [],
            });
        } catch (_) {
            setBaseShippingCategoryOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getBaseShippingCategory, baseShippingCategoryOptionsData };
};
