import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";
import QueryString from "qs";

export const useGetExchangeMinimal = () => {
    const { storeService } = useService();
    const [exchangeListOptionsData, setExchangeData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getExchange = async (params: Record<string, any>): Promise<void> => {
        try {
            if (!isMinimalQuery(QueryString.stringify(params))) {
                throw new Error(errorMessageMinimal);
            }
            setExchangeData((prev) => ({ ...prev, isLoading: true }));

            const response = await storeService.exchangeResource.get(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setExchangeData({
                isLoading: false,
                options,
            });
        } catch (_) {
            setExchangeData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getExchange, exchangeListOptionsData } as const;
};
