import {
    CountryResource,
    CurrencyResource,
    LanguageResource,
    RegionResource,
} from "@/lib/core/resources/private";
import CommerceApiService from "@/lib/core/apiServices/commerceApiService";
import BaseError from "@/lib/core/request/error";
import { ProductsResource } from "@/lib/core/resources/public";

class CoreService extends CommerceApiService {
    public errors: BaseError;
    public products: ProductsResource;
    public countryResource: CountryResource;
    public CurrencyResource: CurrencyResource;
    public LanguageResource: LanguageResource;
    public RegionResource: RegionResource;

    constructor() {
        super();
        this.errors = new BaseError();
        this.products = new ProductsResource(this.config);
        this.countryResource = new CountryResource(this.config);
        this.CurrencyResource = new CurrencyResource(this.config);
        this.LanguageResource = new LanguageResource(this.config);
        this.RegionResource = new RegionResource(this.config);
    }
}

export default CoreService;
