import React, { useEffect, createRef } from "react";
import { Button, Form, Input, Select, notification } from "antd";
import { FormInstance } from "antd/es/form/Form";
import { Forms } from "@/models/form";
import { UserModel } from "@/models/userCollectionModel";
import { useUpdateAdmin } from "@/lib/core-react/hooks/private/useAdminUser";
import { IUserStatus } from "@/types/userCollection";
import { useAtom } from "jotai";
import { roleAtom } from "@/lib/core-react/store/store";
import { RoleCollectionModel } from "@/models/roleCollectionModel";
import { renderOptionsFromEnum } from "@/components/Form/forms";
import { showError } from "@/helpers/showError";
import { useGetRole } from "@/lib/core-react/hooks/private";
import QueryString from "qs";
import { responseTypeQuery } from "@/filters/constant";

interface IProps {
    setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRoles: UserModel;
}

const UpdateAdminUserRoleOnly = ({
    setIsModalVisible,
    selectedRoles,
}: IProps) => {
    const { updateAdmin, isLoading } = useUpdateAdmin();
    const [{ data: roleData }] = useAtom(roleAtom);
    const { getRole } = useGetRole();
    const RoleData = roleData && new RoleCollectionModel(roleData);
    const formRef: React.Ref<FormInstance<any>> = createRef();
    const [form] = Form.useForm();

    useEffect(() => {
        getRole(QueryString.stringify(responseTypeQuery.minimal));
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [form]);

    useEffect(() => {
        form.setFieldsValue({
            name: selectedRoles.name,
            is_active: selectedRoles.is_active === 1 ? "1" : "0",
            role_ids: selectedRoles?.roles?.data.map((role) =>
                role.id?.toString(),
            ),
        });
    }, [selectedRoles, form]);

    const onFinish = async (values: IUserStatus) => {
        try {
            form.setFieldsValue({
                name: selectedRoles.name,
                is_active: selectedRoles.is_active === 1 ? "1" : "0",
            });
            await updateAdmin(values, Number(selectedRoles.id));

            setIsModalVisible(false);
            notification["success"]({
                message: "Updated Admin User successfully",
            });
        } catch (error) {
            showError(error, form);
        }
    };

    const onValuesChange = (changedValues: any) => {
        Forms.formValueChange(form, changedValues);
    };

    return (
        <Form
            onFinish={onFinish}
            form={form}
            layout="vertical"
            name="Update Admin User"
            onFieldsChange={onValuesChange}
            ref={formRef}
            initialValues={{
                name: selectedRoles.name,
                role_ids: selectedRoles?.roles?.data.map((role) =>
                    role.id?.toString(),
                ),
            }}
        >
            <Form.Item
                hidden
                rules={[
                    {
                        required: true,
                        message: "User name is required",
                    },
                ]}
                label="Name"
                name="name"
            >
                <Input placeholder="User name" />
            </Form.Item>

            <Form.Item
                rules={[{ required: true, message: "Role is required" }]}
                name="role_ids"
                label="Role"
            >
                <Select
                    mode="multiple"
                    loading={isLoading}
                    placeholder="Please select the Roles"
                >
                    {RoleData?.getRoleIdsAsObjectName() &&
                        renderOptionsFromEnum(RoleData?.getRoleIdsAsObject())}
                </Select>
            </Form.Item>

            <Form.Item>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default UpdateAdminUserRoleOnly;
