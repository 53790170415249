import { ITranslationEntry } from "../lang";

export const IT: ITranslationEntry = {
    translations: {
        notifications: "Notifiche",
        markAllAsRead: "Segna tutti come letti",
        poweredBy: "Offerto da",
        settings: "Impostazioni",
        noNewNotification: "Niente di nuovo da vedere qui ancora",
    },
    lang: "it",
};
