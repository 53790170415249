import {
    IUnlistedPackage,
    IUnlistedPackageAgentWarehouse,
    IUnlistedPackageCollection,
    IUnlistedPackageDestinationWarehouse,
    IUnlistedPackageUiAction,
    IUnlistedPackageUiActionChangeableStatus,
} from "src/types/unlistedPackageCollection";
import { CountryModel } from "./countryCollectionModel";
import { ContainsEnum } from "@/enums/shipForMeContextEnums";
import { IFilterType } from "@/types/filters";
import { WarehouseShipmentModel } from "./warehouseShipmentCollectionModel";
import { ImageCollectionModel, TrackingCollectionModel } from "./shared";
import { PaginationModel } from "./paginationModel";
import { UnlistedPackageCollectionEnum } from "@/enums/unlistedPackageCollectionEnums";
import { ShippingCategoryModel } from "./shippingCategory";
import { SelectOption } from "@/types/shared";

export class UnlistedPackageAgentWarehouseModel {
    object: string;
    id: number;
    name: string;
    shipping_mark: string;
    contact_person: string | null;
    contact_number: string | null;
    instruction: string | null;

    constructor(data: IUnlistedPackageAgentWarehouse) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
        this.shipping_mark = data.shipping_mark;
        this.contact_person = data.contact_person;
        this.contact_number = data.contact_number;
        this.instruction = data.name;
    }

    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getShippingMark() {
        return this.shipping_mark;
    }
    getContactPerson() {
        return this.contact_person ? this.contact_person : "";
    }
    getContactNumber() {
        return this.contact_number ? this.contact_number : "";
    }
    getInstruction() {
        return this.instruction ? this.instruction : "";
    }
}

export class UnlistedPackageDestinationWarehouseModel {
    object: string;
    id: number;
    name: string;

    constructor(data: IUnlistedPackageDestinationWarehouse) {
        this.object = data.object;
        this.id = data.id;
        this.name = data.name;
    }

    getId = () => this.id;
    getName = () => this.name;
}

export class UnlistedPackageUiActionChangeableStatusModel {
    backward: null | SelectOption<string>;
    forward: null | SelectOption<string>;

    constructor(data: IUnlistedPackageUiActionChangeableStatus) {
        this.backward = data.backward;
        this.forward = data.forward;
    }

    getBackward = () => this.backward;
    getForward = () => this.forward;
}

export class UnlistedPackageUiActionModel {
    object: string;
    changeable_statuses: UnlistedPackageUiActionChangeableStatusModel[];

    constructor(data: IUnlistedPackageUiAction) {
        this.object = data.object;
        this.changeable_statuses = data.changeable_statuses.map(
            (data) => new UnlistedPackageUiActionChangeableStatusModel(data),
        );
    }

    getChangeableStatuses = () => this.changeable_statuses;
    getHasChangeableStatuses = () =>
        this.changeable_statuses.some(
            (data) => data.getBackward() || data.getForward(),
        );
    getForwardStatus = (): SelectOption<string> | null => {
        for (const status of this.changeable_statuses) {
            if (status.getForward()) {
                return status.getForward(); // Return the forward status if available
            }
        }
        return null; // Return null if no forward status is found
    };
}

export class UnlistedPackageModel {
    object: UnlistedPackageCollectionEnum.UnlistedPackage;
    id: number;
    package_number: string;
    product_title: string | null;
    product_description: string | null;
    product_price: string | null;
    product_price_currency: string | null;
    quantity: number;
    weight: number;
    length: number | null;
    width: number | null;
    height: number | null;
    cbm: number | null;
    contains: ContainsEnum;
    contains_2: ContainsEnum | null;
    hs_code: string | null;
    carton_number: string;
    rate: number | null;
    estimated_bill: number | null;
    estimated_profit: number | null;
    status: string;
    status_label: string;
    fulfillment_status: string;
    agent_warehouse: UnlistedPackageAgentWarehouseModel;
    destination_warehouse: UnlistedPackageDestinationWarehouseModel;
    shipping_category: ShippingCategoryModel | null;
    country: CountryModel | null;
    shipment_product: null;
    trackings: TrackingCollectionModel;
    images: ImageCollectionModel;
    warehouse_shipment: WarehouseShipmentModel | null;
    ui_action: UnlistedPackageUiActionModel;

    constructor(dataItem: IUnlistedPackage) {
        this.object = dataItem.object;
        this.id = dataItem.id;
        this.package_number = dataItem.package_number;
        this.product_title = dataItem.product_title;
        this.product_description = dataItem.product_description;
        this.product_price = dataItem.product_price;
        this.product_price_currency = dataItem.product_price_currency;
        this.quantity = dataItem.quantity;
        this.weight = dataItem.weight;
        this.length = dataItem.length;
        this.width = dataItem.width;
        this.height = dataItem.height;
        this.cbm = dataItem.cbm;
        this.contains = dataItem.contains;
        this.contains_2 = dataItem.contains_2;
        this.hs_code = dataItem.hs_code;
        this.carton_number = dataItem.carton_number;
        this.rate = dataItem.rate;
        this.estimated_bill = dataItem.estimated_bill;
        this.estimated_profit = dataItem.estimated_profit;
        this.status = dataItem.status;
        this.status_label = dataItem.status_label;
        this.fulfillment_status = dataItem.fulfillment_status;
        this.agent_warehouse =
            dataItem.agent_warehouse &&
            new UnlistedPackageAgentWarehouseModel(dataItem.agent_warehouse);
        this.destination_warehouse =
            dataItem.destination_warehouse &&
            new UnlistedPackageDestinationWarehouseModel(
                dataItem.destination_warehouse,
            );
        this.shipping_category =
            dataItem.shipping_category &&
            new ShippingCategoryModel(dataItem.shipping_category);
        this.country = dataItem.country && new CountryModel(dataItem.country);
        this.shipment_product = dataItem.shipment_product;
        this.trackings =
            dataItem.trackings &&
            new TrackingCollectionModel(dataItem.trackings);
        this.images = new ImageCollectionModel(dataItem.images);
        this.warehouse_shipment =
            dataItem.warehouse_shipment &&
            new WarehouseShipmentModel(dataItem.warehouse_shipment);
        this.ui_action =
            dataItem.ui_action &&
            new UnlistedPackageUiActionModel(dataItem.ui_action);
    }
    getId() {
        return this.id;
    }
    getProductTitle() {
        return this.product_title ? this.product_title : "";
    }
    getProductDescription() {
        return this.product_description ? this.product_description : "";
    }
    getPackageNumber() {
        return this.package_number;
    }
    getProductPrice() {
        return this.product_price;
    }
    getProductPriceCurrency() {
        return this.product_price_currency;
    }
    getQuantity() {
        return this.quantity;
    }
    getWeight() {
        return this.weight;
    }
    getLength() {
        return this.length;
    }
    getHeight() {
        return this.height;
    }
    getCbm() {
        return this.cbm;
    }
    getWidth() {
        return this.width;
    }
    getContains() {
        return this.contains;
    }
    getHsCode() {
        return this.hs_code;
    }
    getCartonNumber() {
        return this.carton_number;
    }
    getStatus() {
        return this.status;
    }
    getFulfillmentStatus() {
        return this.fulfillment_status;
    }
    geyShipmentProduct() {
        return this.shipment_product;
    }
    getTrackings() {
        return this.trackings;
    }
    getImages() {
        return this.images;
    }
    getImageUrls() {
        return this.images.data.map((item) => item.src);
    }
    getAgentWarehouse() {
        return this.agent_warehouse;
    }
    getDestinationWarehouse = () => this.destination_warehouse;
    getShippingCategory() {
        return this.shipping_category;
    }
    getCountry() {
        return this.country;
    }
    getTracking() {
        return this.trackings;
    }
    getEstimatedBill() {
        return this.estimated_bill;
    }
    getEstimatedProfit() {
        return this.estimated_profit;
    }
    getRate() {
        return this.rate;
    }
    getWarehouseShipment() {
        return this.warehouse_shipment;
    }
    getUiAction = () => this.ui_action;
    getStatusLabel = () => this.status_label;
}

export class UnlistedPackageCollectionModel {
    public readonly object: UnlistedPackageCollectionEnum.UnlistedPackageCollection;
    public readonly data: UnlistedPackageModel[];
    public readonly pagination: PaginationModel;
    public readonly filters: IFilterType;
    constructor(data: IUnlistedPackageCollection) {
        this.object = data.object;
        this.data = data.data.map((item) => new UnlistedPackageModel(item));
        this.pagination = new PaginationModel(data.pagination);
        this.filters = data.filters;
    }

    getData() {
        return this.data;
    }

    getPagination() {
        return this.pagination;
    }

    getFilters() {
        return this.filters;
    }

    getUnlistedPackageById(id: number): UnlistedPackageModel | undefined {
        return this.data.find((item) => item.id === id);
    }
}
