import QueryString from "qs";
import { Config } from "@/lib/core/request/types/request";
import { Client } from "@/lib/core/request/request";
import KeyManager from "@/lib/core/request/keyManager";

export default class BaseResource {
    public client: Client;
    private keyManager: KeyManager;
    // some
    constructor(config: Config) {
        this.client = new Client(config);
        this.keyManager = new KeyManager();
    }
    /**
     * Set a PublishableApiKey that will be sent with each request
     * to define the scope of available resources.
     *
     * @param key - PublishableApiKey identifier
     */
    setPublishableKey(key: string) {
        this.keyManager.registerPublishableApiKey(key);
    }

    protected addQuery(query: Record<string, any>) {
        return QueryString.stringify(query, { addQueryPrefix: true });
    }
}
