import {
    Button,
    Space,
    Tooltip,
    Typography,
    Table,
    message,
    Modal,
    Skeleton,
    notification,
    Tag,
} from "antd";
import {
    ExclamationCircleOutlined,
    MobileOutlined,
    TabletOutlined,
    LaptopOutlined,
    LogoutOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
    ServiceBaseUrl,
    ServiceNameEnum,
} from "@/lib/core/apiServices/servicBaseUrl";
import { Configuration, FrontendApi, Session } from "@ory/client";
import { useEffect, useState } from "react";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";

const { Title, Text } = Typography;
const { confirm } = Modal;

const basePath = ServiceBaseUrl.getServiceBaseUrl(ServiceNameEnum.AUTH_SERVICE);

const ory = new FrontendApi(
    new Configuration({
        basePath,
        baseOptions: {
            withCredentials: true,
        },
    }),
);

const ITEMS_PER_PAGE = 50;

const SecurityTab = () => {
    const { isMobile } = useWindowWidth();
    const [sessions, setSessions] = useState<Session[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const getDeviceIcon = (userAgent: string) => {
        if (userAgent.includes("Mobile") || userAgent.includes("Android")) {
            return <MobileOutlined style={{ fontSize: "24px" }} />;
        } else if (userAgent.includes("iPad")) {
            return <TabletOutlined style={{ fontSize: "24px" }} />;
        } else {
            return <LaptopOutlined style={{ fontSize: "24px" }} />;
        }
    };

    useEffect(() => {
        fetchSessions(1);
    }, []);

    const fetchSessions = (page: number) => {
        setIsLoading(true);
        ory.listMySessions({ page, perPage: ITEMS_PER_PAGE })
            .then((res) => {
                setSessions(res.data);
                setIsLoading(false);
            })
            .catch((_err) => {
                setIsLoading(false);
            });
    };

    const handleRemove = (sessionId: string) => {
        confirm({
            title: "Are you sure you want to logout this session?",
            icon: <ExclamationCircleOutlined />,
            content: "Select Ok, otherwise the process cannot success.",
            onOk() {
                return ory
                    .disableMySession({ id: sessionId })
                    .then(() => {
                        message.success("Session removed successfully");
                        notification.success({
                            message: "Session logout successfully",
                        });
                        fetchSessions(1);
                    })
                    .catch((_) => {
                        notification.error({
                            message: "Failed to logout this session",
                        });
                    });
            },
        });
    };

    const handleLogoutFromOthers = () => {
        confirm({
            title: "Are you sure you want to logout from all other devices?",
            icon: <ExclamationCircleOutlined />,
            content:
                "This action will log you out from all devices except this one.",
            onOk() {
                return ory
                    .disableMyOtherSessions()
                    .then(() => {
                        notification.success({
                            message:
                                "Logged out from other devices successfully",
                        });
                        fetchSessions(1);
                    })
                    .catch((_) => {
                        notification.error({
                            message: "Failed to logout from other devices",
                        });
                    });
            },
        });
    };

    const shortenDeviceName = (userAgent: string) => {
        const devicePatterns = [
            { regex: /iPhone/i, name: "iPhone" },
            { regex: /iPad/i, name: "iPad" },
            { regex: /Windows/i, name: "Windows" },
            { regex: /Windows NT/i, name: "Windows" },
            { regex: /Android/i, name: "Android" },
            { regex: /Macintosh/i, name: "Mac" },
            { regex: /Linux/i, name: "Linux" },
        ];

        const browserPatterns = [
            { regex: /Chrome/i, name: "Chrome" },
            { regex: /Firefox/i, name: "Firefox" },
            { regex: /Safari/i, name: "Safari" },
            { regex: /Edge/i, name: "Edge" },
            { regex: /Opera|OPR/i, name: "Opera" },
        ];

        let device = "Unknown Device";
        let browser = "Unknown Browser";

        for (const pattern of devicePatterns) {
            if (pattern.regex.test(userAgent)) {
                device = pattern.name;
                break;
            }
        }

        for (const pattern of browserPatterns) {
            if (pattern.regex.test(userAgent)) {
                browser = pattern.name;
                break;
            }
        }

        return `${device} - ${browser}`;
    };

    const columns = [
        {
            title: "Device",
            dataIndex: "device",
            key: "device",
            render: (_, session) => (
                <Space style={{ minWidth: "300px" }} direction="vertical">
                    <Space>
                        {/* Device icon based on user agent */}
                        {getDeviceIcon(session.devices[0]?.user_agent || "")}
                        <Text>
                            {shortenDeviceName(
                                session.devices[0]?.user_agent || "",
                            )}
                        </Text>
                    </Space>
                    <Text type="secondary">
                        IP: {session.devices[0]?.ip_address}
                    </Text>
                    <Text type="secondary">
                        Location: {session.devices[0]?.location}
                    </Text>
                </Space>
            ),
        },
        {
            title: "Logged in at",
            dataIndex: "authenticated_at",
            key: "authenticated_at",
            render: (authenticated_at) => (
                <Tag bordered={false} color="green">
                    {moment(authenticated_at).format("MMM D, YYYY HH:mm")}
                </Tag>
            ),
        },
        {
            title: "Auto logged out at",
            dataIndex: "expires_at",
            key: "expires_at",
            render: (expires_at) => (
                <Tag bordered={false} color="warning">
                    {moment(expires_at).format("MMM D, YYYY HH:mm")}
                </Tag>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (_, session) => (
                <Tooltip title="Logout">
                    <Button
                        type="text"
                        danger
                        icon={<LogoutOutlined />}
                        onClick={() => handleRemove(session.id)}
                    >
                        Logout
                    </Button>
                </Tooltip>
            ),
        },
    ];

    return (
        <div style={{ padding: "24px", margin: "0 auto" }}>
            <>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "15px",
                    }}
                >
                    <Title level={4}>Active sessions</Title>
                    {sessions.length > 1 && (
                        <Button
                            color="danger"
                            danger
                            onClick={handleLogoutFromOthers}
                            icon={<LogoutOutlined />}
                        >
                            Logout from all
                        </Button>
                    )}
                </div>

                {/* Show Skeleton while loading */}
                {isLoading ? (
                    <Skeleton active paragraph={{ rows: 4 }} />
                ) : (
                    <Table
                        dataSource={sessions}
                        columns={columns}
                        rowKey={(record) => record.id}
                        pagination={false}
                        scroll={{ x: isMobile ? 400 : undefined }}
                    />
                )}
            </>
        </div>
    );
};

export default SecurityTab;
