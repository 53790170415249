import React, { useEffect } from "react";
import { Descriptions, Skeleton, Typography } from "antd";
import { useTransactionsDetail } from "@/lib/core-react/hooks/private/useTransactions";
import { transactionDetailAtom } from "@/lib/core-react/store/store";
import { useAtom } from "jotai";
import StatusTag from "@/components/StatusTag";
import { formatString } from "@/utils/helper";
import { TransactionModelDetails } from "@/models/transactionDetailModels";

const { Text } = Typography;

interface DetailsCardProps {
    id: string;
}

const TransactionQuickViews: React.FC<DetailsCardProps> = ({ id }) => {
    const { getTransactionsDetail, isLoading } = useTransactionsDetail();

    useEffect(() => {
        if (id) {
            getTransactionsDetail(id);
        }
    }, [id]);

    const [{ data: transactionDetail }] = useAtom(transactionDetailAtom);

    const data =
        transactionDetail && new TransactionModelDetails(transactionDetail);

    const invoice = data?.association
        .getPaymentSession()
        .getSessionInvoices()
        .getData()[0];

    const order = invoice?.getOrder();
    const titleStyle = { fontWeight: "bold", fontSize: "1em" };

    if (isLoading) {
        return <Skeleton active paragraph={{ rows: 10 }} />;
    }

    return (
        <div style={{ marginBottom: 15 }}>
            <Descriptions
                title={<span style={titleStyle}>Customer</span>}
                bordered
                column={1}
            >
                <Descriptions.Item label="Name">
                    {invoice?.getCustomer()?.name}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                    <Text copyable={true}>
                        {invoice?.getCustomer()?.email
                            ? invoice.getCustomer()?.email
                            : "N/A"}
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                    <Text copyable={true}>
                        {invoice?.getCustomer()?.phone
                            ? invoice.getCustomer()?.phone
                            : "N/A"}
                    </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Shipping Mark">
                    <Text copyable={true}>
                        {invoice?.getCustomer()?.shipping_mark}
                    </Text>
                </Descriptions.Item>
            </Descriptions>

            <Descriptions
                title={<span style={titleStyle}>Order</span>}
                bordered
                column={1}
            >
                <Descriptions.Item label="Order Number">
                    <Text copyable={true}>{order?.order_number}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                    <StatusTag
                        slug={order?.order_type || ""}
                        text={formatString(order?.order_type)}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Order Status">
                    <StatusTag
                        slug={order?.order_status || ""}
                        text={formatString(order?.order_status)}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="Payment Status">
                    <StatusTag
                        slug={order?.payment_status || ""}
                        text={formatString(order?.payment_status)}
                    />
                </Descriptions.Item>
            </Descriptions>
            <Descriptions
                title={<span style={titleStyle}>Order Handler Details</span>}
                bordered
                column={1}
                style={{ marginTop: 10 }}
            >
                <Descriptions.Item label="Name">
                    <Text copyable={true}>{order?.order_handler?.name}</Text>
                </Descriptions.Item>
                {order?.order_handler?.email && (
                    <Descriptions.Item label="Email">
                        <Text copyable={true}>
                            {order.order_handler?.email}
                        </Text>
                    </Descriptions.Item>
                )}

                {order?.order_handler?.phone && (
                    <Descriptions.Item label="Phone">
                        <Text copyable={true}>
                            {order.order_handler?.phone}
                        </Text>
                    </Descriptions.Item>
                )}
            </Descriptions>
        </div>
    );
};

export default TransactionQuickViews;
