import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";

class WishlistResource extends BaseResource {
    path = `/api/wishlist/admin/wishlist/v1/wishlists`;

    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }
}

export default WishlistResource;
