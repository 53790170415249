import React, { ReactNode } from "react";
import { Card, Col, Row, Descriptions, Typography } from "antd";

import { StatusTag } from "@/components";
import { PaymentSessionInvoiceModel } from "@/models/transactionDetailModels";
import { formatString } from "@/utils/helper";
const { Text } = Typography;

interface DetailsCardProps {
    invoice: PaymentSessionInvoiceModel;
    currency: string;
    region: string;
    title: ReactNode;
}

const BasicDetailsCard: React.FC<DetailsCardProps> = ({ invoice, title }) => {
    const order = invoice.getOrder();
    const titleStyle = { fontWeight: "bold", fontSize: "1em" };

    return (
        <Card style={{ marginBottom: 15 }} title={title} bordered={false}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={8}>
                    <Descriptions
                        title={<span style={titleStyle}>Customer</span>}
                        bordered
                        column={1}
                    >
                        <Descriptions.Item label="Name">
                            <Text copyable={true}>
                                {invoice.getCustomer()?.name
                                    ? invoice.getCustomer()?.name
                                    : "N/A"}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">
                            <Text copyable={true}>
                                {invoice.getCustomer()?.email
                                    ? invoice.getCustomer()?.email
                                    : "N/A"}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Phone">
                            <Text copyable={true}>
                                {invoice.getCustomer()?.phone
                                    ? invoice.getCustomer()?.phone
                                    : "N/A"}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Shipping Mark">
                            <Text copyable={true}>
                                {invoice.getCustomer()?.shipping_mark}
                            </Text>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>

                {/* Order Details */}
                <Col xs={24} sm={24} md={12} lg={8}>
                    <Descriptions
                        title={<span style={titleStyle}>Order</span>}
                        bordered
                        column={1}
                    >
                        <Descriptions.Item label="Order Number">
                            <Text
                                strong
                                copyable={true}
                                style={{ color: "#1890ff", cursor: "pointer" }}
                                onClick={() => {
                                    window.open(
                                        `/purchase/buy-products?order_number=${order.order_number}`,
                                        "_blank",
                                    );
                                }}
                            >
                                {order.order_number}
                            </Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">
                            <StatusTag
                                slug={order.order_type}
                                text={formatString(order.order_type)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Order Status">
                            <StatusTag
                                slug={order.order_status}
                                text={formatString(order.order_status)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item label="Payment Status">
                            <StatusTag
                                slug={order.payment_status}
                                text={formatString(order.payment_status)}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8}>
                    <Descriptions
                        title={
                            <span style={titleStyle}>
                                Order Handler Details
                            </span>
                        }
                        bordered
                        column={1}
                        style={{ marginTop: 10 }}
                    >
                        <Descriptions.Item label="Name">
                            <Text copyable={true}>
                                {order.order_handler?.name}
                            </Text>
                        </Descriptions.Item>
                        {order.order_handler?.email && (
                            <Descriptions.Item label="Email">
                                <Text copyable={true}>
                                    {order.order_handler?.email}
                                </Text>
                            </Descriptions.Item>
                        )}

                        {order.order_handler?.phone && (
                            <Descriptions.Item label="Phone">
                                <Text copyable={true}>
                                    {order.order_handler?.phone}
                                </Text>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Col>
            </Row>
        </Card>
    );
};

export default BasicDetailsCard;
