import { ITranslationEntry } from "../lang";

export const AR: ITranslationEntry = {
    translations: {
        notifications: "إشعارات",
        markAllAsRead: "اشر عليها بانها قرات",
        poweredBy: "مشغل بواسطة",
        settings: "الإعدادات",
        noNewNotification: "لا يوجد شيء جديد تراه هنا حتى الآن",
    },
    lang: "ar",
};
