import { useService } from "@/lib/core-react/contexts";
import { defaultDataMinimal, isMinimalQuery } from "@/filters/utils";
import { SelectOptionModel } from "@/filters/Models/SelectOptionModel";
import { useState } from "react";
import { IResponseOptions } from "@/filters/enum/responseDataMinimal";
import { errorMessageMinimal } from "@/filters/constant";

export const useGetStoreMinimal = () => {
    const { storeService } = useService();
    const [storeOptionsData, setStoreOptionsData] =
        useState<IResponseOptions>(defaultDataMinimal);

    const getStore = async (params: string): Promise<void> => {
        try {
            if (!isMinimalQuery(params)) {
                throw new Error(errorMessageMinimal);
            }
            setStoreOptionsData((prev) => ({ ...prev, isLoading: true }));

            const response = await storeService.storeResource.get(params);

            const options =
                response && response.data
                    ? new SelectOptionModel(response.data).getOptions()
                    : [];

            setStoreOptionsData({
                isLoading: false,
                options,
            });
        } catch (_error) {
            setStoreOptionsData({
                isLoading: false,
                options: [],
            });
        }
    };

    return { getStore, storeOptionsData };
};
