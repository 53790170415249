import {
    DownOutlined,
    EditOutlined,
    HddFilled,
    PlusOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Col, Dropdown, Modal, Row, Table, Tabs } from "antd";
import FiltersComponent from "@/components/FiltersComponent";
import useDataFilters from "@/hooks/useDataFilters";
import { useAtom } from "jotai";
import { useGetShippingCountry } from "@/lib/core-react/hooks/private";
import { shippingCountry } from "@/lib/core-react/store/store";
import { PaginationModel } from "@/models/pagination";
import { StatusTag } from "../../../components";
import { useEffect, useState } from "react";
import { IFilterType } from "@/types/filters";
import CreateShippingCountry from "./components/CreateShippingCountry";
import UpdateShippingCountry from "./components/UpdateShippingCountry";
import { ApiHelperModel } from "@/models/apiHelper";
import useWindowWidth from "../../../lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { ADMIN_SHIPPING_CORE_PERMISSION_ENUM } from "@/consts/permission-enum/admin-shipping-enum";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import CommonError from "@/components/Error/CommonError";
import {
    ShippingCountryCollectionModel,
    ShippingCountryModel,
} from "@/models/shippingCountryCollectionModel";
import { useSyncShippingCountryResource } from "@/lib/core-react/hooks/private/useShippingCountry";
import { showSuccessAlert } from "@/helpers/showSuccess";
import { showError } from "@/helpers/showError";
import useRefetch from "@/hooks/useRefetch";
const { Column } = Table;

const ShippingCountry = () => {
    const {
        allOptionsData: { regionOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();
    const { getShippingCountry } = useGetShippingCountry();
    const { syncShippingCountry, isLoading: isLoadingSync } =
        useSyncShippingCountryResource();
    const { isMobile } = useWindowWidth();
    const [selectedRegion, setSelectedRegion] = useState<string | undefined>(
        undefined,
    );
    const [
        {
            data: shippingCountryStoreData,
            isLoading,
            error,
            code,
            unAuthorized,
        },
    ] = useAtom(shippingCountry);
    const [refetchNow, setRefetchNow] = useState(false);
    const { refetchListApi } = useRefetch();
    const {
        filters,
        handleFilterChange,
        handelFilterClear,
        isFetched,
        initializeAvailableFilter,
        refetch: refetchFromFilter,
    } = useDataFilters();
    // Shipping Country
    const ShippingCountryData =
        shippingCountryStoreData &&
        new ShippingCountryCollectionModel(shippingCountryStoreData);

    // Filter

    const filterData = ShippingCountryData?.filters;

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.REGION);
    }, []);

    useEffect(() => {
        if (regionOptionsData.options.length > 0) {
            const firstRegionId = regionOptionsData.options[0].value.toString();
            setSelectedRegion(firstRegionId);
            handleFilterChange({ region_id: firstRegionId });
        }
    }, [regionOptionsData]);

    useEffect(() => {
        if (selectedRegion) {
            ApiHelperModel.makeGetRequest(
                {
                    ...filters,
                    region_id: selectedRegion,
                },
                getShippingCountry,
            );
        }
    }, [selectedRegion, refetchNow]);

    let filterWithoutRegin: IFilterType | undefined = undefined;
    if (filterData) {
        const { region_id: _, ...rest } = filterData as IFilterType;
        filterWithoutRegin = rest;
    }

    // Getting all available filters
    useEffect(() => {
        if ((!isFetched && ShippingCountryData?.filters) || refetchFromFilter) {
            initializeAvailableFilter(filterData as IFilterType);
        }
    }, [
        isFetched,
        initializeAvailableFilter,
        ShippingCountryData?.filters,
        refetchFromFilter,
    ]);

    // Create modal state
    const [isShowCreateModal, setIsShowCreateModal] = useState<boolean>(false);

    // Update modal state
    const [isShowUpdateModal, setIsShowUpdateModal] = useState<boolean>(false);
    const [updatePayload, setUpdatePayload] = useState<
        ShippingCountryModel | undefined
    >(undefined);

    const onChangeRegion = (key: string) => {
        setSelectedRegion(key);
        handleFilterChange({ region_id: key });
    };

    // Show update modal with data
    const showUpdateModal = (record: any) => {
        setUpdatePayload(record);
        setIsShowUpdateModal(true);
    };

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getShippingCountry,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        ShippingCountryData,
        handlePaginationChange,
    );

    // Filter Handling
    const handleFilter = () => {
        if (filters) {
            ApiHelperModel.makeGetRequest(filters, getShippingCountry);
        }
    };

    const onHandleRefetch = () => {
        setRefetchNow((pre) => !pre);
    };

    const handleSync = async () => {
        try {
            await syncShippingCountry();
            showSuccessAlert("Shipping Countries synced successfully");
            await refetchListApi();
        } catch (error) {
            showError(error);
        }
    };

    if (error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
                hidePageHeader={false}
            />
        );
    }

    return (
        <>
            <div>
                <PageHeader
                    ghost={false}
                    title="Shipping Country"
                    style={{ marginTop: "10px" }}
                    extra={[
                        <>
                            <Button
                                style={{ padding: 0 }}
                                type="link"
                                htmlType="button"
                                icon={<SyncOutlined spin={isLoadingSync} />}
                                onClick={() => handleSync()}
                            >
                                Sync
                            </Button>

                            {checkActionPermission(
                                ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_MANAGE,
                                <Button
                                    icon={<PlusOutlined />}
                                    type="primary"
                                    onClick={() => setIsShowCreateModal(true)}
                                >
                                    Create Shipping Country
                                </Button>,
                                null,
                            )}
                        </>,
                    ]}
                    onBack={() => window.history.back()}
                >
                    <div>
                        <Tabs
                            type="card"
                            size="large"
                            activeKey={selectedRegion}
                            tabPosition="top"
                            animated
                            centered
                            onChange={onChangeRegion}
                            items={regionOptionsData.options.map((region) => {
                                return {
                                    label: region.label,
                                    key: region.value.toString(),
                                    children: (
                                        <>
                                            {filters &&
                                                Object.keys(filters).length >
                                                    0 && (
                                                    <Row>
                                                        <Col span={24}>
                                                            <FiltersComponent
                                                                handleProductFilter={
                                                                    handleFilter
                                                                }
                                                                handleFilterChange={
                                                                    handleFilterChange
                                                                }
                                                                handelFilterClear={
                                                                    handelFilterClear
                                                                }
                                                                isFetched={
                                                                    isFetched
                                                                }
                                                                filters={
                                                                    filters
                                                                }
                                                                filtersData={
                                                                    filterWithoutRegin
                                                                }
                                                                isFromProductReceived={
                                                                    true
                                                                }
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}

                                            <Table
                                                bordered
                                                style={{ marginTop: "15px" }}
                                                loading={isLoading}
                                                rowKey={(r) => r.getId()}
                                                scroll={{ x: 1000 }}
                                                pagination={{
                                                    ...paginationConfig,
                                                    showTotal: (total) => (
                                                        <div>
                                                            Total{" "}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        "bold",
                                                                    fontSize:
                                                                        "18px",
                                                                    color: "green",
                                                                }}
                                                            >
                                                                {total}
                                                            </span>{" "}
                                                            Items
                                                        </div>
                                                    ),
                                                }}
                                                dataSource={
                                                    ShippingCountryData?.data
                                                }
                                            >
                                                <Column
                                                    width={70}
                                                    title="ID"
                                                    dataIndex="id"
                                                    key="id"
                                                />

                                                <Column
                                                    title="Country"
                                                    dataIndex="country_name"
                                                    key="country_name"
                                                    render={(
                                                        _,
                                                        record: ShippingCountryModel,
                                                    ) => (
                                                        <span>
                                                            {record.country &&
                                                                record.country
                                                                    .name}
                                                        </span>
                                                    )}
                                                />
                                                <Column
                                                    title="Status"
                                                    dataIndex="status"
                                                    key="status"
                                                    render={(
                                                        _,
                                                        record: ShippingCountryModel,
                                                    ) => (
                                                        <StatusTag
                                                            text={record.status}
                                                            slug={record.status}
                                                        />
                                                    )}
                                                />

                                                <Column
                                                    title="Action"
                                                    key="action"
                                                    fixed={
                                                        isMobile
                                                            ? "right"
                                                            : undefined
                                                    }
                                                    align="center"
                                                    width={100}
                                                    render={(_, record) => {
                                                        const items: ExtendedMenuItemType[] =
                                                            [
                                                                {
                                                                    permission:
                                                                        ADMIN_SHIPPING_CORE_PERMISSION_ENUM.ADMIN_SHIPPING_COUNTRY_MANAGE,
                                                                    key: "1",
                                                                    label: (
                                                                        <div
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() =>
                                                                                showUpdateModal(
                                                                                    record,
                                                                                )
                                                                            }
                                                                        >
                                                                            <EditOutlined />{" "}
                                                                            <span>
                                                                                {" "}
                                                                                Update
                                                                            </span>
                                                                        </div>
                                                                    ),
                                                                },
                                                            ];

                                                        return (
                                                            <Dropdown
                                                                menu={{
                                                                    items: items.filter(
                                                                        (x) =>
                                                                            checkActionPermission(
                                                                                x.permission,
                                                                                x,
                                                                                null,
                                                                            ),
                                                                    ),
                                                                }}
                                                            >
                                                                <Button
                                                                    icon={
                                                                        <HddFilled />
                                                                    }
                                                                >
                                                                    {isMobile ? (
                                                                        <DownOutlined />
                                                                    ) : (
                                                                        <>
                                                                            Actions{" "}
                                                                            <DownOutlined />{" "}
                                                                        </>
                                                                    )}
                                                                </Button>
                                                            </Dropdown>
                                                        );
                                                    }}
                                                />
                                            </Table>
                                        </>
                                    ),
                                };
                            })}
                        />
                    </div>
                </PageHeader>
            </div>

            <Modal
                title={`Create Shipping Country`}
                open={isShowCreateModal}
                footer={false}
                onCancel={() => setIsShowCreateModal(false)}
            >
                <CreateShippingCountry
                    onHandleRefetch={onHandleRefetch}
                    setIsShowCreateModal={setIsShowCreateModal}
                />
            </Modal>

            <Modal
                title={`Update Shipping Country`}
                open={isShowUpdateModal}
                footer={false}
                onCancel={() => setIsShowUpdateModal(false)}
            >
                <UpdateShippingCountry
                    onHandleRefetch={onHandleRefetch}
                    setIsShowUpdateModal={setIsShowUpdateModal}
                    data={updatePayload as ShippingCountryModel}
                />
            </Modal>
        </>
    );
};

export default ShippingCountry;
