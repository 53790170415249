import React from "react";

import { FILE_UPLOAD_URL, FILE_UPLOAD_URL_TOKEN } from "@/consts/storage";
import setAndGetGuestData from "@/helpers/setAndGetGuestData";
import { FormInstance } from "antd/lib";
import ChunkedUploady from "@rpldy/chunked-uploady";
import { CameraOutlined } from "@ant-design/icons";
import { Avatar, Button, Progress, type ProgressProps } from "antd";
import {
    useItemFinishListener,
    useItemProgressListener,
} from "@rpldy/chunked-uploady";
import { asUploadButton } from "@rpldy/upload-button";
import UploadDropZone from "@rpldy/upload-drop-zone";
import withPasteUpload from "@rpldy/upload-paste";
import { ReactNode } from "react";

const UploadButton = asUploadButton((props: any) => {
    return (
        <div {...props} style={{ cursor: "pointer" }}>
            <Button icon={<CameraOutlined />}></Button>
        </div>
    );
});

import ImageWithPlaceholder from "@/components/ImageWithPlaceholder";
import { isDevelopmentMode } from "@/helpers/isDevelopmentMode";

const twoColors: ProgressProps["strokeColor"] = {
    "0%": "#108ee9",
    "100%": "#87d068",
};

const PasteUploadDropZone = withPasteUpload(UploadDropZone);

export const DivUploadButton = ({
    form,
    fieldName,
    handleUpload,
}: {
    form: any;
    fieldName: string;
    button?: ReactNode;
    handleUpload: (imageUrl: string) => void;
}) => {
    useItemFinishListener((item) => {
        const { results } = item.uploadResponse;
        const data = results[results.length - 1].data.data;
        form.setFieldValue(fieldName, data.url);
        handleUpload(data.url);
    });

    const progressData = useItemProgressListener();

    return (
        <PasteUploadDropZone>
            <div style={{ cursor: "pointer", position: "relative" }}>
                <div
                    style={{
                        position: "absolute",
                        top: 50,
                        display:
                            progressData &&
                            progressData.completed === 100 &&
                            form.getFieldValue(fieldName)
                                ? "block"
                                : "none",
                        zIndex: 10,
                        width: "100%",
                    }}
                >
                    {progressData &&
                    progressData.completed === 100 &&
                    form.getFieldValue(fieldName) ? (
                        <Avatar
                            style={{
                                marginBottom: 10,
                                objectFit: "contain",
                            }}
                            size={32}
                            shape="circle"
                            src={
                                <ImageWithPlaceholder
                                    src={form.getFieldValue(fieldName)}
                                />
                            }
                        />
                    ) : (
                        <Progress
                            style={{ marginBottom: 10 }}
                            size={32}
                            type="circle"
                            percent={
                                progressData &&
                                Number(progressData.completed.toFixed(0))
                            }
                            strokeColor={twoColors}
                        />
                    )}
                </div>
                <UploadButton />
            </div>
        </PasteUploadDropZone>
    );
};

export const MoveOnLens: React.FC<{
    handleUpload: (imageUrl: string) => void;
    accept?: string;
    form: FormInstance;
}> = ({
    handleUpload,
    form,
    accept = "image/jpeg,image/png,image/jpg,image/gif,image/webp",
}) => {
    let header: { [key: string]: string } = {
        "X-GUEST-ID": setAndGetGuestData()?.guestId || "",
        "X-GUEST-SESSION": setAndGetGuestData()?.guestSession || "",
        Accept: "application/json",
    };

    if (isDevelopmentMode()) {
        header = {
            ...header,
            "X-SESSION-TOKEN": FILE_UPLOAD_URL_TOKEN,
        };
    }

    return (
        <>
            <ChunkedUploady
                accept={accept}
                multiple={false}
                chunkSize={204800}
                withCredentials={true}
                method="POST"
                destination={{
                    url: FILE_UPLOAD_URL,
                    headers: header,
                }}
            >
                <DivUploadButton
                    handleUpload={handleUpload}
                    button={<UploadButton />}
                    form={form}
                    fieldName={"url"}
                />
            </ChunkedUploady>
        </>
    );
};
