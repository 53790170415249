/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import {
    Button,
    Dropdown,
    Modal,
    Popconfirm,
    Table,
    Tag,
    Typography,
    notification,
} from "antd";
import {
    CheckCircleOutlined,
    DeleteOutlined,
    DownOutlined,
    ExclamationCircleOutlined,
    HddFilled,
    PlusOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import moment from "moment";

import { destinationWarehouseInvitationCollectionAtom } from "@/lib/core-react/store/store";
import {
    useDestinationWarehouseInvitationDelete,
    useDestinationWarehouseInvitationResend,
    useDestinationWarehouseInvitationsGet,
} from "@/lib/core-react/hooks/private/useDestinationWarehouse";
import { getError } from "@/lib/core-react/hooks/utils/errors";
import useDataFilters from "@/hooks/useDataFilters";

import {
    DestinationWarehouseMembershipInvitationCollectionModel,
    DestinationWarehouseMembershipInvitationModel,
} from "@/models/destinationWarehouseCollectionModel";
import { ApiHelperModel } from "@/models/apiHelper";
import { PaginationModel } from "@/models/pagination";
import useWindowWidth from "@/lib/core-react/hooks/public/useWindowWidth";
import checkActionPermission from "@/components/Authorized/CheckPermissions";
import { ExtendedMenuItemType } from "@/types";
import { DESTINATION_PERMISSION_ENUM } from "@/consts/permission-enum/destination-enum";
import { PageHeader } from "@ant-design/pro-layout";
import { ADMIN_USER_PERMISSION_ENUM } from "@/consts/permission-enum/admin-user-enum";
import { SendInvitation } from "../SendInvitation";

interface IProps {
    destinationWarehouseId: number;
}

export const DestinationWarehouseInvitationsTable = ({
    destinationWarehouseId,
}: IProps) => {
    const { deleteInvitation, isLoading: isLoadingDelete } =
        useDestinationWarehouseInvitationDelete();
    const { resendInvitation } = useDestinationWarehouseInvitationResend();
    const { getDestinationWarehouseInvitations } =
        useDestinationWarehouseInvitationsGet(destinationWarehouseId);
    const [{ data: invitationCollectionData, isLoading, refetch }] = useAtom(
        destinationWarehouseInvitationCollectionAtom,
    );
    const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false);
    const InvitationCollectionData =
        invitationCollectionData &&
        new DestinationWarehouseMembershipInvitationCollectionModel(
            invitationCollectionData,
        );
    const { isMobile } = useWindowWidth();

    const { filters, handleFilterChange, isFirstCall, isFetched } =
        useDataFilters();

    // Api Call
    useEffect(() => {
        if ((filters && !isFetched && isFirstCall) || refetch) {
            ApiHelperModel.makeGetRequest(
                {},
                getDestinationWarehouseInvitations,
            );
        }
    }, [destinationWarehouseId, isFirstCall, filters, isFetched, refetch]);

    // Pagination
    const paginationData = InvitationCollectionData?.getPagination();

    // Pagination Handler
    const handlePaginationChange = (pageCount: number, pageSize: number) => {
        const pageInfo = { page: pageCount, per_page: pageSize };
        handleFilterChange(pageInfo);
        ApiHelperModel.makeGetRequest(
            {
                ...filters,
                ...pageInfo,
            },
            getDestinationWarehouseInvitations,
        );
    };

    // Pagination Configuration
    const paginationConfig = PaginationModel.getPaginationConfig(
        InvitationCollectionData,
        handlePaginationChange,
    );

    const handleResendInvitation = async (id: number) => {
        try {
            await resendInvitation(destinationWarehouseId, id);
            notification.success({ message: "Invitation resent." });
            await getDestinationWarehouseInvitations();
        } catch (error) {
            notification.error({ message: getError(error) });
        }
    };

    const handleDeleteInvitation = async (id: number) => {
        try {
            await deleteInvitation(destinationWarehouseId, id);
            notification.success({ message: "Invitation deleted" });
            await getDestinationWarehouseInvitations();
        } catch (error) {
            notification.error({ message: getError(error) });
        }
    };

    const { Text } = Typography;

    const columns = [
        {
            title: "#SL",
            dataIndex: "id",
            key: "id",
            width: 70,
            align: "center" as const,
            render: (
                _: string,
                __: DestinationWarehouseMembershipInvitationModel,
                index: number,
            ) => {
                return (
                    <Text>
                        {paginationData
                            ? (paginationData.current_page - 1) *
                                  paginationData.per_page +
                              index +
                              1
                            : ""}
                    </Text>
                );
            },
        },
        {
            hidden: isMobile,
            title: "User Email",
            dataIndex: "user_email",
            key: "user_email",
            render: (
                _: string,
                record: DestinationWarehouseMembershipInvitationModel,
            ) => {
                return <Text strong>{record.getUserEmail()}</Text>;
            },
        },
        {
            hidden: isMobile,
            title: "Roles",
            dataIndex: "roles",
            key: "roles",
            render: (
                _: string,
                record: DestinationWarehouseMembershipInvitationModel,
            ) => {
                return record.getRoles() ? (
                    <div
                        style={{
                            textTransform: "capitalize",
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 10,
                            width: 200,
                        }}
                    >
                        {record
                            .getRoles()
                            .getData()
                            .map((role) => (
                                <Tag key={role.id} color="green">
                                    {role.name}
                                </Tag>
                            ))}
                    </div>
                ) : (
                    ""
                );
            },
        },
        {
            hidden: isMobile,
            title: "Status",
            dataIndex: "status",
            align: "center" as const,
            key: "status",
            render: (
                _: string,
                record: DestinationWarehouseMembershipInvitationModel,
            ) => {
                return (
                    <Tag
                        icon={
                            record.getStatus() === "accepted" ? (
                                <CheckCircleOutlined />
                            ) : (
                                <ExclamationCircleOutlined />
                            )
                        }
                        color={
                            record.getStatus() === "accepted"
                                ? "success"
                                : "warning"
                        }
                    >
                        {record.getStatus()}
                    </Tag>
                );
            },
        },
        {
            hidden: isMobile,
            title: "Expires at",
            dataIndex: "expired_at",
            key: "expired_at",
            render: (
                _: string,
                record: DestinationWarehouseMembershipInvitationModel,
            ) => {
                const expirationDate = moment(
                    record.expires_at,
                    "YYYY-MM-DD HH:mm:ss",
                );
                const currentDate = moment();

                const minutesRemaining = expirationDate.diff(
                    currentDate,
                    "minutes",
                );

                return minutesRemaining > 0 ? (
                    <Tag color="purple" icon={<ExclamationCircleOutlined />}>
                        {record.getExpiresAt()}
                    </Tag>
                ) : (
                    <Tag color="warning" icon={<ExclamationCircleOutlined />}>
                        Expired
                    </Tag>
                );
            },
        },
        {
            title: "Details",
            hidden: !isMobile,
            key: "details",
            render: (_, record) => {
                const expirationDate = moment(
                    record.expires_at,
                    "YYYY-MM-DD HH:mm:ss",
                );
                const currentDate = moment();
                const minutesRemaining = expirationDate.diff(
                    currentDate,
                    "minutes",
                );

                return (
                    <div>
                        <div style={{ margin: "10px 0px " }}>
                            <Text strong>User Email:</Text>{" "}
                            <Text>{record.getUserEmail()}</Text>
                        </div>
                        <div>
                            <Text strong>Roles:</Text>
                            {record.getRoles() ? (
                                <div
                                    style={{
                                        textTransform: "capitalize",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 5,
                                        minWidth: 100,
                                    }}
                                >
                                    {record
                                        .getRoles()
                                        .getData()
                                        .map((role) => (
                                            <Tag key={role.id} color="green">
                                                {role.name}
                                            </Tag>
                                        ))}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div style={{ margin: "10px 0px " }}>
                            <Text strong>Status:</Text>
                            <Tag
                                icon={
                                    record.getStatus() === "accepted" ? (
                                        <CheckCircleOutlined />
                                    ) : (
                                        <ExclamationCircleOutlined />
                                    )
                                }
                                color={
                                    record.getStatus() === "accepted"
                                        ? "success"
                                        : "warning"
                                }
                            >
                                {record.getStatus()}
                            </Tag>
                        </div>
                        <div>
                            <Text strong>Expires at:</Text>
                            {minutesRemaining > 0 ? (
                                <Tag color={"purple"}>
                                    {record.getExpiresAt()}
                                </Tag>
                            ) : (
                                <Tag
                                    color="warning"
                                    icon={<ExclamationCircleOutlined />}
                                >
                                    Expired
                                </Tag>
                            )}
                        </div>
                    </div>
                );
            },
        },
        {
            title: "Actions",
            key: "actions",
            width: isMobile ? 100 : 150,
            fixed: isMobile ? "right" : undefined,
            render: (
                _: string,
                record: DestinationWarehouseMembershipInvitationModel,
            ) => {
                const items: ExtendedMenuItemType[] = [
                    {
                        permission:
                            DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_MANAGE,
                        key: "1",
                        label: (
                            <div
                                onClick={() =>
                                    handleResendInvitation(record.getId())
                                }
                            >
                                <ReloadOutlined />{" "}
                                <span> Resend invitation</span>
                            </div>
                        ),
                    },
                    {
                        permission:
                            DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_MANAGE,
                        key: "2",
                        label: (
                            <Popconfirm
                                title="Delete this invitation"
                                description="Are you sure to delete this invitation?"
                                onConfirm={() =>
                                    handleDeleteInvitation(record.getId())
                                }
                                okButtonProps={{ loading: isLoadingDelete }}
                                okText={isLoadingDelete ? "Wait..." : "Yes"}
                                okType="danger"
                                cancelText="No"
                            >
                                <span onClick={(e) => e.stopPropagation()}>
                                    <DeleteOutlined />
                                    <span>Delete</span>
                                </span>
                            </Popconfirm>
                        ),
                        danger: true,
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: items.filter((x) =>
                                checkActionPermission(x.permission, x, null),
                            ),
                        }}
                    >
                        <Button icon={<HddFilled />}>
                            {isMobile ? (
                                <DownOutlined />
                            ) : (
                                <>
                                    {" "}
                                    Actions <DownOutlined />{" "}
                                </>
                            )}
                        </Button>
                    </Dropdown>
                );
            },
        },
    ];

    const onCancelModal = () => {
        setIsInviteModalOpen(false);
    };
    return (
        <>
            <PageHeader
                ghost={false}
                style={{ marginTop: "10px" }}
                extra={[
                    checkActionPermission(
                        DESTINATION_PERMISSION_ENUM.ADMIN_DW_MEMBER_INVITATION_MANAGE,
                        <Button
                            key="1"
                            type="primary"
                            onClick={() => {
                                setIsInviteModalOpen(true);
                            }}
                            icon={<PlusOutlined />}
                        >
                            Invite user
                        </Button>,
                        null,
                    ),
                ]}
            >
                <div
                    className="modalTableWrapper"
                    style={{ minHeight: "41vh" }}
                >
                    <Table
                        //@ts-ignore
                        columns={columns}
                        size="small"
                        bordered
                        loading={isLoading}
                        dataSource={InvitationCollectionData?.getData()}
                        pagination={paginationConfig}
                        rowKey="id"
                        style={{ width: "100%" }}
                    />
                </div>
            </PageHeader>

            <Modal
                title={"Inviter User"}
                open={isInviteModalOpen}
                destroyOnClose={true}
                footer={null}
                onCancel={onCancelModal}
            >
                {isInviteModalOpen && (
                    <SendInvitation
                        onHide={onCancelModal}
                        destinationWarehouseId={destinationWarehouseId}
                    />
                )}
            </Modal>
        </>
    );
};
