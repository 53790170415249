import { IPagination } from "@/types/pagination";
import {
    IAgentWarehouse,
    IOffer,
    IShipmentProductBid,
    IShipmentProductBidCollection,
    IShipmentProductBidParticipant,
    IShipmentProductBidParticipantCollection,
    IShipmentProductBidUiAction,
    IShipmentProductBidWinnerWarehouse,
} from "@/types/shipmentProductBidCollection";
import { ShipmentProductModel } from "./shipmentProductCollectionModel";

export class AgentWarehouseModel {
    object: string;
    id: number;
    name: string;
    shipping_mark: string;
    operation_status: string;
    verification_status: string;
    contact_person: string | null;
    contact_number: string | null;
    instruction: string | null;

    constructor(data: IAgentWarehouse) {
        this.object = data?.object;
        this.id = data?.id;
        this.name = data?.name;
        this.shipping_mark = data?.shipping_mark;
        this.operation_status = data?.operation_status;
        this.verification_status = data?.verification_status;
        this.contact_person = data?.contact_person;
        this.contact_number = data?.contact_number;
        this.instruction = data?.instruction;
    }
    getId = () => this.id;
    getName = () => this.name;
    getShippingMark = () => this.shipping_mark;
    getOperationStatus = () => this.operation_status;
    getVerificationStatus = () => this.verification_status;
    getContactPerson = () => this.contact_person;
    getContactNumber = () => this.contact_number;
    getInstruction = () => this.instruction;
}

export class OfferModel {
    object: string;
    unit_type: string;
    rate: number;
    rate_if_lower: number | null;
    rate_if_above: number | null;
    applicable_until: string;
    cbm_max_value: number | null;
    agent_note: string | null;
    constructor(data: IOffer) {
        this.object = data?.object;
        this.unit_type = data?.unit_type;
        this.rate = data?.rate;
        this.rate_if_lower = data?.rate_if_lower;
        this.rate_if_above = data?.rate_if_above;
        this.applicable_until = data?.applicable_until;
        this.cbm_max_value = data?.cbm_max_value;
        this.agent_note = data?.agent_note;
    }

    getUnitType = () => this.unit_type;
    getRate = () => this.rate;
    getRateIfLower = () => this.rate_if_lower;
    getRateIfAbove = () => this.rate_if_above;
    getApplicableUntil = () => this.applicable_until;
    getCbmMaxValue = () => this.cbm_max_value;
    getAgentNote = () => this.agent_note;
}

export class ShipmentProductBidParticipantModel {
    public object: string;
    public id: number;
    public agent_warehouse: AgentWarehouseModel;
    public participant_status: string;
    public offers: OfferModel[] | null;

    constructor(data: IShipmentProductBidParticipant) {
        this.object = data?.object;
        this.id = data?.id;
        this.agent_warehouse = new AgentWarehouseModel(data?.agent_warehouse);
        this.participant_status = data?.participant_status;
        this.offers =
            data.offers && data.offers.data.map((ofr) => new OfferModel(ofr));
    }
    getId = () => this.id;
    getAgentWarehouse = () => this.agent_warehouse;
    getParticipantStatus = () => this.participant_status;
    getOffers = () => this.offers;
}
export class ShipmentProductBidParticipantCollectionModel {
    public object: string;
    public data: ShipmentProductBidParticipantModel[];

    constructor(data: IShipmentProductBidParticipantCollection) {
        this.object = data?.object;
        this.data = data?.data?.map(
            (item) => new ShipmentProductBidParticipantModel(item),
        );
    }
    getData = () => this.data;
}

export class ShipmentProductBidUiActionModel {
    object: string;
    can_abandon_bid: boolean;
    can_attach_participants: boolean;
    can_choose_bid_winner: boolean;

    constructor(data: IShipmentProductBidUiAction) {
        this.object = data.object;
        this.can_abandon_bid = data.can_abandon_bid;
        this.can_attach_participants = data.can_attach_participants;
        this.can_choose_bid_winner = data.can_choose_bid_winner;
    }

    getCanAbandonBid = () => this.can_abandon_bid;
    getCanAttachParticipants = () => this.can_attach_participants;
    getCanChooseBidWinner = () => this.can_choose_bid_winner;
}

export class ShipmentProductBidWinnerWarehouseModel {
    object: string;
    id: number;
    name: string;
    alias_name: string;
    shipping_mark: string;
    operation_status: string;
    verification_status: string;
    contact_person: string;
    contact_number: string;
    instruction: string;
    created_at: string;

    constructor(data: IShipmentProductBidWinnerWarehouse) {
        this.object = data?.object;
        this.id = data?.id;
        this.name = data?.name;
        this.alias_name = data?.alias_name;
        this.shipping_mark = data?.shipping_mark;
        this.operation_status = data?.operation_status;
        this.verification_status = data?.verification_status;
        this.contact_person = data?.contact_person;
        this.contact_number = data?.contact_number;
        this.instruction = data?.instruction;
        this.created_at = data?.created_at;
    }
    getId = () => this.id;
    getName = () => this.name;
    getAliasName = () => this.alias_name;
    getShippingMark = () => this.shipping_mark;
    getOperationStatus = () => this.operation_status;
    getVerificationStatus = () => this.verification_status;
    getContactPerson = () => this.contact_person;
    getContactNumber = () => this.contact_number;
    getInstruction = () => this.instruction;
    getCreatedAt = () => this.created_at;
}

export class ShipmentProductBidModel {
    public object: string;
    public id: number;
    public shipment_product?: ShipmentProductModel;
    public status: string;
    public open_for: string;
    public winner_warehouse?: ShipmentProductBidWinnerWarehouseModel;
    public participants?: ShipmentProductBidParticipantModel[];
    public ui_action: ShipmentProductBidUiActionModel;

    constructor(data: IShipmentProductBid) {
        this.object = data?.object;
        this.id = data?.id;
        this.shipment_product =
            data?.shipment_product &&
            new ShipmentProductModel(data?.shipment_product);
        this.status = data?.status;
        this.open_for = data?.open_for;
        this.participants =
            data.participants &&
            data.participants.data.map(
                (item) => new ShipmentProductBidParticipantModel(item),
            );
        this.ui_action = new ShipmentProductBidUiActionModel(data.ui_action);
        this.winner_warehouse =
            data?.winner_warehouse &&
            new ShipmentProductBidWinnerWarehouseModel(data.winner_warehouse);
    }
    getId = () => this.id;
    getShipmentProduct = () => this.shipment_product;
    getStatus = () => this.status;
    getOpenFor = () => this.open_for;
    getWinnerWarehouse = () => this.winner_warehouse;
    getParticipants = () => this.participants;
    getUiActions = () => this.ui_action;
}

export class ShipmentProductBidCollectionModel {
    public object: string;
    public data: ShipmentProductBidModel[];
    public pagination: IPagination;

    constructor(data: IShipmentProductBidCollection) {
        this.object = data?.object;
        this.data = data?.data?.map(
            (bidModel) => new ShipmentProductBidModel(bidModel),
        );
        this.pagination = data?.pagination;
    }

    getBidCollectionTableData() {
        return this.data.map((bid) => {
            return {
                key: bid.id,
                status: bid.status,
                participants: bid.participants,
                ui_action: bid.ui_action,
            };
        });
    }
    getData = () => this.data;
    getPagination = () => this.pagination;
}
