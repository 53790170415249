import { ITranslationEntry } from "../lang";

export const UR: ITranslationEntry = {
    translations: {
        notifications: "اطلاع",
        markAllAsRead: "سب کو پڑھا ہوا شمار کریں",
        poweredBy: "طاقت کے ذریعے",
        settings: "ترتیبات",
        noNewNotification: "ابھی تک یہاں دیکھنے کے لیے کچھ نیا نہیں ہے۔",
    },
    lang: "ur",
};
