import { useDeliveryRequestDetails } from "@/lib/core-react/hooks/private/useDeliveryRequest";
import { DeliveryRequestDetailsAtom } from "@/lib/core-react/store/store";
import { DeliveryRequestModel } from "@/models/deliveryRequestCollectionModel";
import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col, Descriptions, Row, Tag, Typography } from "antd";
import { useAtom } from "jotai";
import { useEffect } from "react";
import DeliveryRequestInfo from "./deliveryRequestInfo";
import Paragraph from "antd/lib/typography/Paragraph";
import { formatString } from "@/utils/helper";
import StatusTag from "@/components/StatusTag";
import { useParams } from "react-router-dom";
import CommonError from "@/components/Error/CommonError";
import { formatDateTime } from "@/utils/helpers";
import LoaderSpin from "@/components/LoaderSpin";
import BuyProductColumns from "./BuyProductColumns";

const { Text } = Typography;

export const DeliveryRequestDetails = () => {
    const { id } = useParams();
    const { getDeliveryRequestsDetails } = useDeliveryRequestDetails();

    const [
        {
            data: deliveryRequestDetailsData,
            isLoading: deliveryRequestDetailsLoading,
            error,
            unAuthorized,
            code,
        },
    ] = useAtom(DeliveryRequestDetailsAtom);

    useEffect(() => {
        getDeliveryRequestsDetails(Number(id));
    }, []);

    const deliveryRequestRawData =
        deliveryRequestDetailsData &&
        new DeliveryRequestModel(deliveryRequestDetailsData);

    const shippingAddress =
        deliveryRequestRawData?.shipping_address?.display_address;
    const courierOption = deliveryRequestRawData?.courier_option;
    const region = deliveryRequestRawData?.region;

    if (deliveryRequestDetailsLoading) {
        return <LoaderSpin />;
    }

    if (!deliveryRequestDetailsLoading && error) {
        return (
            <CommonError
                unAuthorized={unAuthorized}
                message={error}
                code={code}
            />
        );
    }

    return (
        <div>
            <Row>
                <Col span={24}>
                    <PageHeader
                        ghost={false}
                        style={{ marginTop: "10px" }}
                        title={`Delivery Request #${deliveryRequestRawData?.request_number}`}
                        onBack={() => window.history.back()}
                    >
                        <>
                            <Row gutter={[16, 16]}>
                                {/* Invoice Details */}
                                <Col xs={24} sm={24} md={12} lg={8}>
                                    <Card title={"Basic Information"}>
                                        {deliveryRequestRawData && (
                                            <DeliveryRequestInfo
                                                deliveryRequest={
                                                    deliveryRequestRawData
                                                }
                                                isDetailsShow={true}
                                            />
                                        )}
                                    </Card>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={8}>
                                    <Card title={"User Information"}>
                                        <Paragraph>
                                            <Text>Name : </Text>
                                            <Text strong copyable>
                                                {
                                                    deliveryRequestRawData?.user
                                                        .name
                                                }
                                            </Text>
                                        </Paragraph>

                                        {deliveryRequestRawData?.user.email && (
                                            <Paragraph>
                                                <Text>Email : </Text>
                                                <Text
                                                    strong
                                                    copyable={{
                                                        text: deliveryRequestRawData
                                                            .user.email,
                                                    }}
                                                >
                                                    {
                                                        deliveryRequestRawData
                                                            .user.email
                                                    }
                                                </Text>
                                            </Paragraph>
                                        )}

                                        <Descriptions
                                            column={1}
                                            size="small"
                                            bordered
                                            title="Shipping Address"
                                            style={{ marginTop: 10 }}
                                        >
                                            {shippingAddress &&
                                                Object.entries(
                                                    shippingAddress,
                                                ).map(([key, value]) => (
                                                    <Descriptions.Item
                                                        key={key}
                                                        label={key.replace(
                                                            /_/g,
                                                            " ",
                                                        )}
                                                    >
                                                        {typeof value ===
                                                            "string" ||
                                                        typeof value ===
                                                            "number"
                                                            ? value
                                                            : "N/A"}
                                                    </Descriptions.Item>
                                                ))}
                                        </Descriptions>
                                    </Card>
                                </Col>

                                <Col xs={24} sm={24} md={12} lg={8}>
                                    <Card title={"Courier Options"}>
                                        <Descriptions
                                            column={1}
                                            size="small"
                                            bordered
                                            style={{ marginTop: 10 }}
                                        >
                                            <Descriptions.Item label="Name">
                                                {formatString(
                                                    courierOption?.name,
                                                )}
                                            </Descriptions.Item>

                                            <Descriptions.Item label="Flat amount">
                                                <Text strong>
                                                    {courierOption?.flat_amount}{" "}
                                                    {
                                                        deliveryRequestRawData
                                                            ?.region?.currency
                                                            ?.code
                                                    }
                                                </Text>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Status">
                                                <Paragraph>
                                                    <StatusTag
                                                        slug={
                                                            courierOption?.availability_status ||
                                                            ""
                                                        }
                                                        text={
                                                            courierOption?.availability_status ||
                                                            ""
                                                        }
                                                    />
                                                </Paragraph>
                                            </Descriptions.Item>
                                            <Descriptions.Item label="Created At">
                                                <Paragraph>
                                                    <Tag color="purple">
                                                        {formatDateTime(
                                                            courierOption?.created_at,
                                                        )}
                                                    </Tag>
                                                </Paragraph>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Card>
                                </Col>
                            </Row>
                            <div style={{ marginTop: 15 }}>
                                <BuyProductColumns
                                    data={
                                        deliveryRequestRawData?.getEnrollments()
                                            .data
                                    }
                                    region={region || undefined}
                                />
                            </div>
                        </>
                    </PageHeader>
                </Col>
            </Row>
        </div>
    );
};
