import { useEffect } from "react";
import {
    Form,
    Select,
    Input,
    Button,
    Radio,
    Row,
    Col,
    Card,
    FormInstance,
} from "antd";
import {
    AgentDesignationEnum,
    CompanyTypeEnum,
    NumberOfTeamMemberEnum,
    YearsOfExperienceEnum,
} from "./agentCompanyCollectionEnums";
import { AgentCompanyModel } from "@/models/agentCompanyCollectionModel";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";
import { responseTypeQuery } from "@/filters/constant";
import { IAgentCompanyUpdate } from "@/types/agentCompanyCollection";

const { Option } = Select;

interface IProps {
    company: AgentCompanyModel;
    onSubmit: (values: IAgentCompanyUpdate) => void;
    updateAgentCompanyloading: boolean;
    form: FormInstance<any>;
}
const ModificationAgentCompany = ({
    company,
    onSubmit,
    updateAgentCompanyloading,
    form,
}: IProps) => {
    const {
        allOptionsData: { countryOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.COUNTRY, {
            per_page: 500,
            ...responseTypeQuery.minimal,
        });
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            registered_country: company.registered_country,
            company_type: company.company_type,
            director_name: company.director_name,
            designation: company.designation,
            primary_name: company.primary_name,
            secondary_name: company.secondary_name,
            business_phone: company.business_phone,
            years_of_experience: company.years_of_experience,
            number_of_people_in_team: company.number_of_people_in_team,
            address: {
                country: company.getAddress().country,
                state: company.getAddress().state,
                city: company.getAddress().city,
                postal: company.getAddress().postal,
                address_1: company.getAddress().address_1,
                address_2: company.getAddress().address_2,
            },
        });
    }, [company, form]);

    const designationOptions = [
        { value: AgentDesignationEnum.Director, label: "Director" },
        { value: AgentDesignationEnum.Executive, label: "Executive" },
        { value: AgentDesignationEnum.Manager, label: "Manager" },
        { value: AgentDesignationEnum.Superviser, label: "Supervisor" },
        { value: AgentDesignationEnum.Employee, label: "Employee" },
    ];

    const companyTypeOptions = [
        { value: CompanyTypeEnum.Cross_Border, label: "Cross Border" },
    ];

    const yearsOfExperienceOptions = [
        { value: YearsOfExperienceEnum[">= 5 years"], label: ">= 5 years" },
        { value: YearsOfExperienceEnum["3-5 years"], label: "3-5 years" },
        { value: YearsOfExperienceEnum["1-2 years"], label: "1-2 years" },
        { value: YearsOfExperienceEnum["<= 1 year"], label: "<= 1 year" },
    ];

    const numberOfTeamMemberOptions = [
        {
            value: NumberOfTeamMemberEnum["Less than 10 people"],
            label: "Less than 10 people",
        },
        {
            value: NumberOfTeamMemberEnum["10 to 50 people"],
            label: "10-50 people",
        },
        {
            value: NumberOfTeamMemberEnum["50 to 100 people"],
            label: "50-100 people",
        },
        {
            value: NumberOfTeamMemberEnum["above 100 people"],
            label: "Above 100 people",
        },
    ];

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            initialValues={{
                registered_country: company?.registered_country,
                company_type: company?.company_type,
                director_name: company?.director_name,
                designation: company?.designation,
                primary_name: company?.primary_name,
                secondary_name: company?.secondary_name,
                business_phone: company?.business_phone,
                years_of_experience: company?.years_of_experience,
                number_of_people_in_team: company?.number_of_people_in_team,
                address: {
                    country: company?.address?.country,
                    state: company?.address?.state,
                    city: company?.address?.city,
                    postal: company?.address?.postal,
                    address_1: company?.address?.address_1,
                    address_2: company?.address?.address_2,
                },
            }}
        >
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="registered_country"
                        label="Business Register Country"
                        rules={[
                            {
                                required: true,
                                message:
                                    "Business register country is required",
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            options={countryOptionsData.options}
                            placeholder="Please select the OrderHandler"
                            onChange={(value, option) => {
                                if (!Array.isArray(option)) {
                                    form.setFieldsValue({
                                        registered_country: value,
                                        address: {
                                            ...form.getFieldValue("address"),
                                            country: option.label,
                                        },
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="company_type"
                        label="Company Type"
                        rules={[
                            {
                                required: true,
                                message: "Company type is required",
                            },
                        ]}
                    >
                        <Select placeholder="Select company type">
                            {companyTypeOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="director_name"
                        label="Employee Name"
                        rules={[
                            {
                                required: true,
                                message: "Employee name is required",
                            },
                        ]}
                    >
                        <Input placeholder="Enter employee name" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="designation"
                        label="Designation"
                        rules={[
                            {
                                required: true,
                                message: "Designation is required",
                            },
                        ]}
                    >
                        <Select placeholder="Select your designation">
                            {designationOptions.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="primary_name"
                        label="Business Name"
                        rules={[
                            {
                                required: true,
                                message: "Business name is required",
                            },
                        ]}
                    >
                        <Input placeholder="Enter business name" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="secondary_name"
                        label="Brand Name"
                        rules={[
                            {
                                required: true,
                                message: "Brand name is required",
                            },
                        ]}
                    >
                        <Input placeholder="Enter brand name" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="business_phone"
                        label="Business Phone"
                        rules={[
                            {
                                required: true,
                                message: "Business Phone is required",
                            },
                            {
                                message: "Please enter a valid business phone",
                            },
                        ]}
                    >
                        <Input placeholder="Enter business phone" />
                    </Form.Item>
                </Col>
            </Row>
            {/* Address Fields */}
            <Card title={"Business Address"}>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name={["address", "country"]}
                            label="Country"
                            rules={[
                                {
                                    required: true,
                                    message: "Country is required",
                                },
                            ]}
                        >
                            <Select
                                disabled
                                showSearch
                                options={countryOptionsData.options}
                                placeholder="Select a country"
                                onChange={(_value, option) => {
                                    if (!Array.isArray(option)) {
                                        form.setFieldsValue({
                                            address: {
                                                ...form.getFieldValue(
                                                    "address",
                                                ),
                                                country: String(option.label),
                                            },
                                            registered_country: option.value,
                                        });
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name={["address", "state"]}
                            label="State"
                            rules={[
                                {
                                    required: true,
                                    message: "State is Required",
                                },
                            ]}
                        >
                            <Input placeholder="Enter state" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name={["address", "city"]}
                            label="City"
                            rules={[
                                { required: true, message: "City is Required" },
                            ]}
                        >
                            <Input placeholder="Enter city" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name={["address", "postal"]}
                            label="Postal Code"
                            rules={[
                                {
                                    required: true,
                                    message: "Postal is Required",
                                },
                            ]}
                        >
                            <Input placeholder="Enter postal code" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name={["address", "address_1"]}
                            label="Address Line 1"
                            rules={[
                                {
                                    required: true,
                                    message: "Address in Required",
                                },
                            ]}
                        >
                            <Input placeholder="Enter address line 1" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            name={["address", "address_2"]}
                            label="Address Line 2"
                        >
                            <Input placeholder="Enter address line 2" />
                        </Form.Item>
                    </Col>
                </Row>
            </Card>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="years_of_experience"
                        label="Years of Experience in Cross Border Operation"
                        rules={[
                            {
                                required: true,
                                message: "Years of experience is required",
                            },
                        ]}
                    >
                        <Radio.Group>
                            {yearsOfExperienceOptions.map((option) => (
                                <Radio key={option.value} value={option.value}>
                                    {option.label}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="number_of_people_in_team"
                        label="Number of People in Your Team"
                        rules={[
                            {
                                required: true,
                                message: "Number of team members is required",
                            },
                        ]}
                    >
                        <Radio.Group>
                            {numberOfTeamMemberOptions.map((option) => (
                                <Radio key={option.value} value={option.value}>
                                    {option.label}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ width: "100%" }}
                            loading={updateAgentCompanyloading}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default ModificationAgentCompany;
