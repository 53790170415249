import { useNavigate, useLocation } from "react-router-dom";
import { useCallback } from "react";
import { parseObjectToString, parseStringToObject } from "./stringify";

const usePageQuery = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const getCurrentQuery = () => {
        const queryParams = parseStringToObject(location.search, {
            ignoreQueryPrefix: true,
        });

        return queryParams;
    };

    const updateQuery = useCallback(
        (newQueryParams: Record<string, any>) => {
            // Merge the new query parameters with the existing ones
            const updatedQueryParams = {
                ...getCurrentQuery(),
                ...newQueryParams,
            };

            // Remove any keys where the value is undefined or null
            Object.keys(updatedQueryParams).forEach((key) => {
                if (
                    updatedQueryParams[key] === null ||
                    updatedQueryParams[key] === undefined
                ) {
                    delete updatedQueryParams[key];
                }
            });

            navigate(
                `${location.pathname}?${parseObjectToString(updatedQueryParams)}`,
            );
        },
        [navigate, location],
    );

    const resetQuery = () => {
        navigate(location.pathname);
    };
    const goBack = () => {
        navigate(-1);
    };

    return { updateQuery, getCurrentQuery, resetQuery, goBack };
};

export default usePageQuery;
