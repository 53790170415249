export const statusLabel = [
    { label: "Pending", value: "shipment-pending", color: "warning" },
    { label: "Approved", value: "shipment-approved", color: "success" },
    { label: "Rejected", value: "shipment-rejected", color: "error" },
    {
        label: "Assigned agent",
        value: "shipment-assigned-agent",
        color: "processing",
    },
    {
        label: "Shipping started",
        value: "shipment-shipping-started",
        color: "processing",
    },
    {
        label: "Shipping not arrived",
        value: "shipment-not-arrived",
        color: "error",
    },
    {
        label: "Shipping cancelled",
        value: "shipment-shipping-cancelled",
        color: "error",
    },
    {
        label: "Arrived at warehouse",
        value: "shipment-arrived-at-warehouse",
        color: "processing",
    },
    {
        label: "Handover to travel agent",
        value: "shipment-handover-to-travel-agent",
        color: "processing",
    },
    {
        label: "Handover to MoveOn",
        value: "shipment-handover-to-moveon",
        color: "processing",
    },
    {
        label: "Preparing for transport",
        value: "shipment-preparing-for-transport",
        color: "processing",
    },
    {
        label: "Handover to ship",
        value: "shipment-handover-to-ship",
        color: "processing",
    },
    {
        label: "Handover to airline",
        value: "shipment-handover-to-airline",
        color: "processing",
    },
    {
        label: "Arrived at destination port",
        value: "shipment-arrived-at-destination-port",
        color: "processing",
    },
    {
        label: "Arrived at destination airport",
        value: "shipment-arrived-at-destination-airport",
        color: "processing",
    },
    {
        label: "Customs released",
        value: "shipment-customs-released",
        color: "processing",
    },
    {
        label: "Received by MoveOn",
        value: "shipment-received-by-moveon",
        color: "success",
    },
    {
        label: "Delivery requested",
        value: "shipment-delivery-requested",
        color: "success",
    },
    {
        label: "Delivery processing",
        value: "shipment-delivery-processing",
        color: "success",
    },
    {
        label: "Prepared for delivery",
        value: "shipment-prepared-for-delivery",
        color: "success",
    },
    {
        label: "Handover to courier",
        value: "shipment-handover-to-courier",
        color: "success",
    },
    {
        label: "Delivered to customer",
        value: "shipment-delivered-to-customer",
        color: "success",
    },
    {
        label: "Delivery failed",
        value: "shipment-delivery-failed",
        color: "success",
    },
    {
        label: "Delivery returned",
        value: "shipment-delivery-returned",
        color: "success",
    },
];
