import { BaseResource } from "../public";
import { ResponsePromise } from "@/lib/core/request/types/typings";
import { CurrencyCreateUpdateRequestModel } from "@/models/currency";

class CurrencyResource extends BaseResource {
    path = `/api/core/admin/currency/v1/currencies`;
    get(
        params?: string,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}${params ? `?${params}` : ""}`;
        return this.client.request("GET", path, undefined, {}, customHeaders);
    }

    create(
        payload: CurrencyCreateUpdateRequestModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}`;
        return this.client.request("POST", path, payload, {}, customHeaders);
    }

    update(
        id: number,
        payload: CurrencyCreateUpdateRequestModel,
        customHeaders: Record<string, any> = {},
    ): ResponsePromise {
        const path = `${this.path}/${id}`;
        return this.client.request("PUT", path, payload, {}, customHeaders);
    }
}

export default CurrencyResource;
