import { Descriptions, Tag } from "antd";
import {
    ActionSequenceModel,
    BuyActionModel,
} from "@/models/buyActionCollectionModel";
import { TakeActionsEnums } from "@/enums/takeActionsEnums";
import { formatString } from "@/utils/helper";
import { formatDateTime } from "@/utils/helpers";
import PriceDecreaseIssues from "./component/PriceDecreaseIssues";
import ShippingChargeIncrease from "./component/ShippingChargeIncrease";
import PriceIncreaseIssues from "./component/PriceIncreaseIssues";
import StockOutMismatchedTable from "./component/StockOutMismatchedTable";
import { Image } from "antd";
import { StatusTag } from "@/components";
interface IProps {
    data: ActionSequenceModel;
    buyActionData: BuyActionModel;
}
const ActionNeededIssuesType = ({ data, buyActionData }: IProps) => {
    const currency = buyActionData
        .getBuyProduct()
        .getStore()
        .getDefaultCurrency()
        ?.getCode();
    const currency2 = buyActionData
        .getBuyProduct()
        .getRegion()
        .getCurrency()
        .getCode();
    const templateRedColor =
        data.getType() === TakeActionsEnums.SHIPPING_CHARGE_INCREASE ||
        data.getType() === TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED ||
        data.getType() === TakeActionsEnums.PRODUCT_STOCK_OUT ||
        data.getType() === TakeActionsEnums.TAX_ADDED ||
        data.getType() === TakeActionsEnums.QUANTITY_UPDATE ||
        data.getType() === TakeActionsEnums.SERVICE_CHARGE ||
        data.getType() === TakeActionsEnums.NO_RISK_PRODUCT ||
        data.getType() === TakeActionsEnums.HIGH_TAX_POSSIBILITY ||
        data.getType() === TakeActionsEnums.PRICE_INCREASED ||
        data.getType() === TakeActionsEnums.PRICE_INCREASED;

    return (
        <>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "0.375rem",
                    padding: "0.5rem 1rem",
                    backgroundColor: "#f3f4f6",
                    borderLeft: `8px solid ${templateRedColor ? "#FF5A54" : "#00893C"}`,
                }}
            >
                {" "}
                <div>
                    Issue Type:{" "}
                    <Tag
                        color="processing"
                        style={{
                            marginTop: "3px",
                        }}
                    >
                        {formatString(data.getType())}
                    </Tag>
                </div>
                <Tag color="purple">{formatDateTime(data.getCreatedAt())}</Tag>
            </div>
            <div>
                {data.getType() === TakeActionsEnums.PRICE_DECREASED && (
                    <PriceDecreaseIssues
                        data={data}
                        currency={currency || ""}
                    />
                )}
                {data.getType() === TakeActionsEnums.PRICE_INCREASED && (
                    <PriceIncreaseIssues
                        data={data}
                        currency={currency || ""}
                    />
                )}
                {data.getType() === TakeActionsEnums.QUANTITY_UPDATE && (
                    <div
                        style={{
                            backgroundColor: "#FFEDD5",
                            marginBottom: "0.5rem",
                            marginTop: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.5rem",
                            borderRadius: "0.375rem",
                            padding: "0.75rem",
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "600", // font-semibold
                                color: "#4B5563", // text-gray-700
                            }}
                        >
                            Minimum {formatString(data.getType())} -{" "}
                            {data.getMinimumQuantity()}
                        </p>
                    </div>
                )}

                {data.type === TakeActionsEnums.PURCHASE_CONFIRMATION && (
                    <div
                        style={{
                            borderRadius: "0.375rem",
                            border: "1px solid #e5e7eb",
                            padding: "1rem",
                        }}
                    >
                        {data && data.images && data.images.data.length > 0 ? (
                            <Image.PreviewGroup>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "0.5rem",
                                        overflowX: "auto",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {data.images.data.map((image, index) => (
                                        <Image
                                            key={index}
                                            src={image.src}
                                            alt={
                                                image.alt ||
                                                "Purchase confirmation image"
                                            }
                                            title={image.title || "Image"}
                                            style={{
                                                display: "inline-block",
                                                height: "100px",
                                                width: "100px",
                                                cursor: "pointer",
                                                borderRadius: "0.375rem",
                                                objectFit: "cover",
                                                boxShadow:
                                                    "0px 1px 3px rgba(0, 0, 0, 0.1)",
                                            }}
                                            preview={{ visible: false }}
                                        />
                                    ))}
                                </div>
                            </Image.PreviewGroup>
                        ) : (
                            <p>No images available</p>
                        )}
                    </div>
                )}
                {(data.getType() === TakeActionsEnums.PRODUCT_STOCK_OUT ||
                    data.getType() === TakeActionsEnums.SKU_MISMATCHED) && (
                    <StockOutMismatchedTable
                        data={data}
                        currency={currency || ""}
                    />
                )}
                {data.getType() ===
                    TakeActionsEnums.SHIPPING_CHARGE_INCREASE && (
                    <ShippingChargeIncrease
                        data={data}
                        currency={currency || ""}
                    />
                )}
                {data.getType() === TakeActionsEnums.SHIPPING_CHANGE && (
                    <div
                        style={{
                            backgroundColor: "#FFF0E0",
                            marginBottom: "0.5rem",
                            marginTop: "0.5rem",
                            alignItems: "center",
                            borderRadius: "0.375rem",
                            padding: "0.75rem",
                        }}
                    >
                        <Descriptions
                            title="Agent Warehouse"
                            bordered
                            column={2} // Adjust columns as needed
                        >
                            {/* Agent Category Price Read */}
                            <Descriptions.Item label="Company Name">
                                {
                                    data
                                        .getActionShippingRate()
                                        ?.agent_category_price_read?.getAgentWarehouse()
                                        .alias_name
                                }
                                (
                                {data
                                    .getActionShippingRate()
                                    ?.agent_category_price_read?.getAgentWarehouse()
                                    .getName()}
                                )
                            </Descriptions.Item>
                            <Descriptions.Item label="Contact Person">
                                {
                                    data.getActionShippingRate()
                                        ?.agent_category_price_read
                                        ?.agent_warehouse.contact_person
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Phone">
                                {
                                    data.getActionShippingRate()
                                        ?.agent_category_price_read
                                        ?.agent_warehouse.contact_number
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Shipping Mark">
                                {data
                                    .getActionShippingRate()
                                    ?.agent_category_price_read?.agent_warehouse.getShippingMark()}
                            </Descriptions.Item>
                            <Descriptions.Item label="Verification Status">
                                <StatusTag
                                    text={
                                        data
                                            .getActionShippingRate()
                                            ?.agent_category_price_read?.agent_warehouse.getVerificationStatus() ||
                                        ""
                                    }
                                    slug={
                                        data
                                            .getActionShippingRate()
                                            ?.agent_category_price_read?.agent_warehouse.getVerificationStatus() ||
                                        ""
                                    }
                                />
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                            title="Destination Warehouse"
                            bordered
                            column={2}
                            style={{ marginTop: 10 }}
                        >
                            {/* Agent Category Price Read */}
                            <Descriptions.Item label="Name">
                                {
                                    data
                                        .getActionShippingRate()
                                        ?.agent_category_price_read?.getAgentWarehouse()
                                        .alias_name
                                }
                                (
                                {data
                                    .getActionShippingRate()
                                    ?.agent_category_price_read?.getAgentWarehouse()
                                    .getName()}
                                )
                            </Descriptions.Item>

                            <Descriptions.Item label="Status">
                                <StatusTag
                                    text={
                                        data
                                            .getActionShippingRate()
                                            ?.agent_category_price_read?.getDestinationWarehouse()
                                            .getStatus() || ""
                                    }
                                    slug={
                                        data
                                            .getActionShippingRate()
                                            ?.agent_category_price_read?.getDestinationWarehouse()
                                            .getStatus() || ""
                                    }
                                />
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions
                            title="Shipping"
                            bordered
                            column={2}
                            style={{ marginTop: 10 }}
                        >
                            {/* Agent Category Price Read */}
                            <Descriptions.Item label="Shipping Rate">
                                {data
                                    .getActionShippingRate()
                                    ?.agent_category_price_read?.getRate()}
                                /
                                <small>
                                    {data
                                        .getActionShippingRate()
                                        ?.agent_category_price_read?.getUnitType()}
                                </small>
                            </Descriptions.Item>
                            <Descriptions.Item label="Contain">
                                {
                                    data.getActionShippingRate()
                                        ?.agent_category_price_read?.contain
                                }
                            </Descriptions.Item>

                            <Descriptions.Item label="Shipping Mode">
                                {data
                                    .getActionShippingRate()
                                    ?.agent_category_price_read?.getShippingMode()}
                            </Descriptions.Item>
                            <Descriptions.Item label="Shipping Type">
                                {data
                                    .getActionShippingRate()
                                    ?.agent_category_price_read?.getShippingType()}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                )}
                {(data.getType() ===
                    TakeActionsEnums.LOCAL_DELIVERY_CHARGE_ADDED ||
                    data.getType() === TakeActionsEnums.PACKING_CHARGE ||
                    data.getType() === TakeActionsEnums.TAX_ADDED ||
                    data.getType() === TakeActionsEnums.SERVICE_CHARGE) && (
                    <div
                        style={{
                            backgroundColor: "#FFF5E5",
                            marginBottom: "0.5rem",
                            marginTop: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "0.375rem",
                            padding: "0.75rem",
                        }}
                    >
                        <p
                            style={{
                                fontWeight: "600",
                                color: "#4A5568",
                            }}
                        >
                            {formatString(data.getType())} - {currency2}
                            {data.getBuyCharges()?.getTotalAmount().toFixed(2)}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

export default ActionNeededIssuesType;
