import { Dispatch, SetStateAction, useEffect } from "react";
import { Button, Flex, Form, FormInstance, Select } from "antd";

import { TrackerCommentVisibilityEnum } from "@/enums/shared";

import { RichTextEditor } from "@/components/RichTextEditor";
import { SelectLabelValue } from "./utils";

// import {  UserSelect } from "./UserSelect";
import { transformEnumToLabeledValue } from "@/utils/helpers";
import useFiltersApiData from "@/filters/hooks/useFiltersApiData";
import { filterResourceEnum } from "@/filters/enum/filterResourceEnum";

interface EditorProps {
    mode: "reply" | "update" | "create";
    form: FormInstance<any>;
    onSubmit: () => void;
    loading: boolean;
    roleIds: SelectLabelValue[];
    specificUserIds: SelectLabelValue[];
    setSpecificUserIds: Dispatch<SetStateAction<SelectLabelValue[]>>;
    content: string;
    setContent: Dispatch<SetStateAction<string>>;
    onCancel?: () => void;
    id?: string;
}

export const CommentEditor = ({
    mode = "create",
    form,
    onSubmit,
    loading,
    roleIds,
    // specificUserIds,
    // setSpecificUserIds,
    content,
    setContent,
    onCancel,
}: EditorProps) => {
    useEffect(() => {
        form.setFieldValue("role_ids", roleIds);
    }, [roleIds]);

    const {
        allOptionsData: { roleOptionsData },
        onFetchFilterApi,
    } = useFiltersApiData();

    useEffect(() => {
        onFetchFilterApi(filterResourceEnum.AGENT_ROLE);
    }, []);
    return (
        <Form
            form={form}
            initialValues={{
                visibility: "public",
            }}
        >
            <Form.Item>
                <RichTextEditor
                    content={mode === "reply" ? "" : content}
                    setContent={setContent}
                />
            </Form.Item>
            {/* {mode !== "reply" && (
        <Form.Item>
          <UserSelect
            specificUserIds={specificUserIds}
            setSpecificUserIds={setSpecificUserIds}
            placeholder="Mention specific users"
          />
        </Form.Item>
      )} */}
            {mode !== "reply" && (
                <Form.Item name="role_ids">
                    <Select
                        mode="multiple"
                        placeholder="Mention users by role"
                        loading={roleOptionsData.isLoading}
                        options={roleOptionsData.options}
                    />
                </Form.Item>
            )}
            {mode !== "reply" && (
                <Form.Item
                    rules={[
                        { required: true, message: "Visibility is required" },
                    ]}
                    name="visibility"
                >
                    <Select
                        options={transformEnumToLabeledValue(
                            TrackerCommentVisibilityEnum,
                        )}
                    />
                </Form.Item>
            )}
            <Form.Item noStyle>
                <Flex gap={12} justify="end">
                    {(mode === "update" || mode === "reply") && onCancel && (
                        <Button danger onClick={onCancel}>
                            Cancel
                        </Button>
                    )}

                    <Button
                        htmlType="submit"
                        loading={loading}
                        onClick={onSubmit}
                        type="primary"
                    >
                        {mode === "create"
                            ? "Create"
                            : mode === "reply"
                              ? "Reply"
                              : mode === "update"
                                ? "Update"
                                : ""}
                    </Button>
                </Flex>
            </Form.Item>
        </Form>
    );
};
